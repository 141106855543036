import styled from 'styled-components';
import { lightTheme } from '@sketch/global-styles';

const WRAPPER_BORDER_RADIUS = {
    '16px': lightTheme.radii.small,
    '24px': lightTheme.radii.base,
    '32px': lightTheme.radii.medium,
    '64px': lightTheme.radii.xlarge,
    '88px': lightTheme.radii.xlarge,
};
const LETTER_SIZE = {
    '16px': lightTheme.fontSizes.A,
    '24px': lightTheme.fontSizes.B,
    '32px': lightTheme.fontSizes.H,
    '64px': lightTheme.fontSizes.H,
    '88px': lightTheme.fontSizes.I,
};
const ICON_SIZE = {
    '24px': '16px',
    '32px': '18px',
};
const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};

  overflow: hidden;
  border-radius: ${({ $size }) => WRAPPER_BORDER_RADIUS[$size]};
  flex-shrink: 0;
  user-select: none;

  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  margin-right: ${({ $addRightMargin }) => ($addRightMargin ? '12px' : '0')};
`;
const AvatarImage = styled.img `
  width: 100%;
  height: 100%;
  object-fit: cover;
  color: transparent;
`;
const IconWrapper = styled.div `
  width: ${({ $size }) => ICON_SIZE[$size]};
  height: ${({ $size }) => ICON_SIZE[$size]};
  line-height: 1;
  opacity: 0.7;
`;
const FirstLetter = styled.span `
  display: block;

  font-size: 0;
  line-height: 1;

  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.colors.foreground.secondary.C};

  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ $size }) => LETTER_SIZE[$size]};
`;

export { AvatarImage, FirstLetter, IconWrapper, Wrapper };

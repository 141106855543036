import { __rest } from 'tslib';
import { useQuery as useQuery$1 } from '@apollo/react-hooks';
import { captureCacheMiss } from './captureCacheMiss.js';
import { useShouldInvalidatePrevious } from './useShouldInvalidatePrevious.js';

let _usageMetricsHook = null;
const hookUsageMetrics = (usageMetricsHook) => {
    _usageMetricsHook = usageMetricsHook;
};
function useQuery(query, options) {
    // just omit shouldInvalidatePrevious prop from passing props forward
    const _a = options || {}, { shouldInvalidatePrevious } = _a, restOptions = __rest(_a, ["shouldInvalidatePrevious"]);
    const result = useQuery$1(query, restOptions);
    captureCacheMiss(query, options, result);
    if (useShouldInvalidatePrevious(options, result)) {
        Object.assign(result, { data: undefined, error: undefined });
    }
    // exclude this from production build
    if (process.env.REACT_APP_ENV === 'dev' ||
        process.env.REACT_APP_ENV === 'test') {
        _usageMetricsHook === null || _usageMetricsHook === void 0 ? void 0 : _usageMetricsHook(result, query);
    }
    return result;
}

export { hookUsageMetrics, useQuery };

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import '../Box/index.js';
import '../Form/index.js';
import { Box } from '../Box/BoxSystem.js';
import { FormError } from '../Form/FormError/FormError.js';

const ApolloError = (_a) => {
    var { error } = _a, props = __rest(_a, ["error"]);
    return (jsx(Box, Object.assign({}, props, { children: !error || !error.messages || error.messages.length < 1 ? (jsx(FormError, { children: "An unexpected error occurred." })) : (error.messages.map((msg, i) => jsx(FormError, { children: msg }, i))) })));
};

export { ApolloError };

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { CSSTransition } from 'react-transition-group';

const ModalTransition = (_a) => {
    var { show: showModal, ref } = _a, props = __rest(_a, ["show", "ref"]);
    return (jsx(CSSTransition, Object.assign({ timeout: 300, mountOnEnter: true, unmountOnExit: true, in: showModal, classNames: "modal", nodeRef: ref }, props)));
};

export { ModalTransition };

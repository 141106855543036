import { IS_EMBEDDED } from '@sketch/constants';
import { GetDocumentListSettingsDocument, GetAnnotationSettingsDocument, GetBrowserSettingsDocument, GetProjectSearchSettingsDocument } from '@sketch/gql-types';

const initializeLocalApolloState = (cache) => {
    // Initialize the DocumentLayout grid setting on cache
    cache.writeQuery({
        query: GetDocumentListSettingsDocument,
        data: {
            __typename: 'RootQueryType',
            documentsLayout: 'GRID',
            showDocumentsInProjects: true,
            showArchivedDocuments: false,
        },
    });
    // Initialize the Annotation settings on cache
    cache.writeQuery({
        query: GetAnnotationSettingsDocument,
        data: {
            __typename: 'RootQueryType',
            hideAnnotationsDots: false,
        },
    });
    // Initialize user settings on cache
    cache.writeQuery({
        query: GetBrowserSettingsDocument,
        data: {
            __typename: 'RootQueryType',
            sidebarRightOpen: !IS_EMBEDDED,
        },
    });
    // Initialize Project search settings in the cache
    cache.writeQuery({
        query: GetProjectSearchSettingsDocument,
        data: {
            __typename: 'RootQueryType',
            hideCollections: false,
        },
    });
};

export { initializeLocalApolloState };

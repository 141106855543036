import { Extension } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import HorizontalRule from '@tiptap/extension-horizontal-rule';

const SketchStarterKit = Extension.create({
    name: 'sketch-starter-kit',
    addOptions() {
        return {
            placeholder: 'Start writing or add a block...',
        };
    },
    addExtensions() {
        const extensions = [
            // Check the different nodes and marks involved in https://tiptap.dev/api/extensions/starter-kit
            StarterKit.configure({
                heading: {
                    levels: [1, 2, 3],
                },
            }),
            Link.configure({
                autolink: true,
                openOnClick: false,
                linkOnPaste: true,
            }),
            Placeholder.configure({
                placeholder: this.options.placeholder,
            }),
            Underline,
            HorizontalRule,
        ];
        return extensions;
    },
});

export { SketchStarterKit };

import React, { useCallback, useContext } from 'react'
import { IdentityProviderConfigFragment } from '@sketch/gql-types'

import { useCustomerUpdateIdentityProviderConfig } from 'modules/sso/operations'

import {
  Button,
  Dropdown,
  FileSelection,
  ModalContext,
} from '@sketch/components'
import { ManualSsoConfigurationModal } from '../../modals/ManualSsoConfigurationModal'

import SsoSamlConfigured from './SsoSamlConfigured'
import SsoSamlNotConfigured from './SsoSamlNotConfigured'

import {
  Column,
  Columns,
} from '../SsoAttributeMappingForm/SsoAttributeMappingForm.styles'

import { Edit, Description, SubTitle } from './SsoSamlConfiguration.styles'

interface WorkspaceSettingsSsoSamlProps {
  customerId: string
  identityProviderConfig?: IdentityProviderConfigFragment
}

/** Renders a Dropdown to edit the IdP details */
const ConfigurationDropdown = ({
  onUploadXmlFileSelected,
  onConfigureManuallyClick,
}: {
  onUploadXmlFileSelected: (file: File) => Promise<void>
  onConfigureManuallyClick: () => void
}) => (
  <FileSelection
    onFileSelected={onUploadXmlFileSelected}
    accept=".xml,application/samlmetadata+xml"
  >
    {({ onSelectFile, loading }) => (
      <Dropdown
        placement="bottom-end"
        toggle={
          <Button
            size="40"
            disabled={loading}
            data-testid="edit-saml-configuration"
          >
            <div>
              <Edit>Edit&hellip;</Edit>
            </div>
          </Button>
        }
      >
        <Dropdown.Item onClick={onSelectFile}>
          Upload XML file&hellip;
        </Dropdown.Item>

        <Dropdown.Item onClick={onConfigureManuallyClick}>
          Configure manually&hellip;
        </Dropdown.Item>
      </Dropdown>
    )}
  </FileSelection>
)

/**
 * Renders the  UI to upload the XML file for SSO configuration
 */
export const SsoSamlConfiguration: React.FC<WorkspaceSettingsSsoSamlProps> = props => {
  const { customerId, identityProviderConfig } = props
  const { showModal } = useContext(ModalContext)

  const children = !identityProviderConfig ? (
    <SsoSamlNotConfigured customerId={customerId} />
  ) : (
    <SsoSamlConfigured
      customerId={customerId}
      identityProviderConfig={identityProviderConfig}
    />
  )

  const {
    uploadIdentityProviderConfig,
  } = useCustomerUpdateIdentityProviderConfig()

  const onUploadXmlFileSelected = useCallback(
    async (file?: File) => await uploadIdentityProviderConfig(customerId, file),
    [customerId, uploadIdentityProviderConfig]
  )

  const onConfigureManuallyClick = () =>
    showModal(ManualSsoConfigurationModal, {
      customerId,
      identityProviderConfig,
    })

  return (
    <>
      <SubTitle>Import Identity Provider (IdP) Details</SubTitle>
      <Columns>
        <Column>
          <Description>
            To configure Sketch to work with your IdP, import your XML metadata
            file. <br /> Alternatively, enter the details manually.
          </Description>
        </Column>
        <Column>
          <ConfigurationDropdown
            onUploadXmlFileSelected={onUploadXmlFileSelected}
            onConfigureManuallyClick={onConfigureManuallyClick}
          />
        </Column>
      </Columns>

      {children}
    </>
  )
}

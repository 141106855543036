import { jsx, Fragment } from 'react/jsx-runtime';
import { createContext, useState, useMemo, useEffect, useContext, useLayoutEffect } from 'react';
import { ThemeProvider } from 'styled-components';
export { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './theme.js';
import '../hooks/index.js';
import { localStorageKeys } from '@sketch/constants';
import { getParsedItem } from '../utils.js';
import { useLocalStorage } from '../hooks/useLocalStorage.js';
import { useKonamiCode } from '../hooks/useKonamiCode.js';

const themes = {
    light: lightTheme,
    dark: darkTheme,
};
const defaultContext = {
    theme: 'light',
    overriddenTheme: null,
    selectedTheme: 'light',
    matchSystem: false,
    enableLightMode: () => null,
    enableDarkMode: () => null,
    toggleMatchSystem: () => null,
    resetToMatchSystem: () => null,
    setOverriddenTheme: () => null,
    resetOverriddenTheme: () => null,
};
const context = createContext(defaultContext);
const ThemeManager = ({ children }) => {
    const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');
    const [matchSystem, setMatchSystem] = useLocalStorage(localStorageKeys.matchSystemTheme, true);
    const [overriddenTheme, setOverriddenThemeValue] = useState(null);
    const [selectedTheme, setTheme] = useLocalStorage(localStorageKeys.theme, matchMedia.matches ? 'dark' : 'light');
    const actions = useMemo(() => {
        const toggleTheme = () => setTheme(theme => (theme === 'light' ? 'dark' : 'light'));
        const enableLightMode = () => {
            setTheme('light');
            setMatchSystem(false);
        };
        const enableDarkMode = () => {
            setTheme('dark');
            setMatchSystem(false);
        };
        const toggleMatchSystem = () => setMatchSystem(matchSystem => !matchSystem);
        const resetToMatchSystem = () => {
            setMatchSystem(true);
            window.localStorage.removeItem(localStorageKeys.theme);
        };
        const setOverriddenTheme = (theme) => {
            setOverriddenThemeValue(theme);
        };
        const resetOverriddenTheme = () => {
            setOverriddenThemeValue(null);
        };
        return {
            toggleTheme,
            enableLightMode,
            enableDarkMode,
            toggleMatchSystem,
            resetToMatchSystem,
            setOverriddenTheme,
            resetOverriddenTheme,
        };
    }, [setTheme, setMatchSystem]);
    useKonamiCode(actions.toggleTheme);
    // listening to OS System Theme setting changes, to update our theme in case `matchSystem` is on
    useEffect(() => {
        // If the user didn't check the "Match system" checkbox, we can return and ignore system theme changes
        if (!matchSystem) {
            return;
        }
        const matchSystemAutomatically = ({ matches: prefersDarkScheme, }) => {
            prefersDarkScheme ? setTheme('dark') : setTheme('light');
        };
        // We need to check initially if the theme and the system theme are in sync
        // This will fix some mismatch cases where the Appearance is set to System Theme, we close
        // the window and then update the theme in System Settings. In these cases, the theme
        // was not updated in the app, because the event listener was not triggered.
        if (getParsedItem(localStorageKeys.matchSystemTheme)) {
            matchSystemAutomatically(matchMedia);
        }
        matchMedia.addEventListener('change', matchSystemAutomatically);
        return () => {
            matchMedia.removeEventListener('change', matchSystemAutomatically);
        };
    }, [matchMedia, matchSystem, setTheme]);
    const theme = overriddenTheme || selectedTheme;
    const value = Object.assign({ theme,
        overriddenTheme,
        selectedTheme,
        matchSystem }, actions);
    return (jsx(context.Provider, Object.assign({ value: value }, { children: jsx(ThemeProvider, Object.assign({ theme: themes[theme] }, { children: children })) })));
};
const ThemeOverride = (props) => {
    const { theme, children } = props;
    const { setOverriddenTheme, resetOverriddenTheme } = useContext(context);
    useLayoutEffect(() => {
        setOverriddenTheme(theme);
        return () => {
            resetOverriddenTheme();
        };
    }, [theme, setOverriddenTheme, resetOverriddenTheme]);
    return jsx(Fragment, { children: children });
};
function useThemeContext() {
    const values = useContext(context);
    const isDarkMode = values.theme === 'dark';
    return Object.assign(Object.assign({}, values), { isDarkMode });
}

export { ThemeManager, ThemeOverride, useThemeContext };

import styled from 'styled-components';
import { Text } from '@sketch/components';

const Description = styled(Text).attrs({
    textStyle: 'copy.tertiary.standard.E',
}) ``;
const List = styled.ul `
  display: flex;
  flex-direction: column;
  padding-left: 0;
  max-height: 500px;
  overflow-y: auto;
`;
const ListItem = styled.li `
  display: flex;
  align-items: center;
  padding: 12px;

  :nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.background.secondary.B};
  }
`;
const WorkspaceName = styled.span `
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.foreground.secondary.B};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export { Description, List, ListItem, WorkspaceName };

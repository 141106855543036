import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { routes } from '@sketch/modules-common';
import { ModalContext, Section, Link } from '@sketch/components';
import './components/TokenList/index.js';
import './modals/CreateTokenModal/index.js';
import { SsoPermissionsCallout, StyledButton } from './ManagePersonalTokens.styles.js';
import { ReactComponent } from '@sketch/icons/information-16';
import './operations/index.js';
import { useGetInitialUserQuery, useGetUserSettingsQuery } from '@sketch/gql-types';
import { useGetPersonalTokens } from './operations/useGetPersonalTokens.js';
import { useSignInToWorkspace } from './operations/useSignInToWorkspace.js';
import CreateTokenModal from './modals/CreateTokenModal/CreateTokenModal.js';
import TokenList from './components/TokenList/TokenList.js';

const maybePermissionsCallout = (workspace, memberships) => {
    if (!workspace)
        return;
    const { ssoEnabled } = workspace.customer || {};
    const isSsoWorkspace = ssoEnabled && workspace.userRole !== 'GUEST';
    if (!isSsoWorkspace)
        return;
    const workspaceMembership = memberships.find(membership => membership.workspace.identifier === workspace.identifier);
    if (!workspaceMembership)
        return;
    const showSsoPermissionsCallout = isSsoWorkspace &&
        workspaceMembership.personalTokenPermissions === 'SSO_MEMBER_DENY';
    if (!showSsoPermissionsCallout)
        return;
    return (jsxs(SsoPermissionsCallout, { children: [jsx(ReactComponent, { width: 16, height: 16 }), jsxs("div", { children: ["Your tokens do not have access to the \u201C", jsx("b", { children: workspace.name }), "\u201D SSO Workspace.", jsx("br", {}), "Contact your Workspace Admin to get access."] })] }));
};
const ManagePersonalTokens = props => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { currentWorkspace } = props;
    const { showModal } = useContext(ModalContext);
    const { personalTokens, loading } = useGetPersonalTokens();
    const location = useLocation();
    const { signIn } = useSignInToWorkspace();
    const { data, loading: loadingUserData } = useGetInitialUserQuery();
    const { data: userSettingsData } = useGetUserSettingsQuery();
    useEffect(() => {
        // Users are required to authenticate before they can create tokens.
        // SSO authentication involves redirecting to an external host (their
        // Identity Provider) and then redirecting back our app on success.
        // We use the `/create` route to differentiate between loading the access
        // tokens page, and SSO login redirects - and open the `CreateTokenModal`
        // automatically on the `/create` route.
        const createRoute = routes.PERSONAL_SETTINGS_ACCESS_TOKENS_CREATE.template();
        if (location.pathname === createRoute) {
            showModal(CreateTokenModal, { redirectToTokensPage: true });
        }
    }, [location.pathname, showModal]);
    const userName = (_b = (_a = data === null || data === void 0 ? void 0 : data.me) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '';
    const userEmail = (_d = (_c = data === null || data === void 0 ? void 0 : data.me) === null || _c === void 0 ? void 0 : _c.email) !== null && _d !== void 0 ? _d : '';
    const avatarSrc = (_g = (_f = (_e = data === null || data === void 0 ? void 0 : data.me) === null || _e === void 0 ? void 0 : _e.avatar) === null || _f === void 0 ? void 0 : _f.small) !== null && _g !== void 0 ? _g : '';
    const workspaceMemberships = (userSettingsData === null || userSettingsData === void 0 ? void 0 : userSettingsData.me.workspaceMemberships.entries) || [];
    const handleCreateTokenClick = () => {
        const onSuccess = () => {
            showModal(CreateTokenModal, { redirectToTokensPage: false });
        };
        signIn({
            email: userEmail,
            name: userName,
            avatarSrc: avatarSrc,
            workspace: currentWorkspace,
            onSuccess,
        });
    };
    return (jsxs(Fragment, { children: [maybePermissionsCallout(currentWorkspace, workspaceMemberships), jsx(Section, { title: "Personal Access Tokens", text: jsxs("div", { children: ["Personal access tokens enable you to access Sketch documents using an API.", ' ', jsx(Link
                        // TODO: Update link when docs are available
                        // https://github.com/sketch-hq/Cloud/issues/12605
                        , Object.assign({ 
                            // TODO: Update link when docs are available
                            // https://github.com/sketch-hq/Cloud/issues/12605
                            href: "http://www.sketch.com/docs/workspaces/managing-your-sketch-account/#two-factor-authentication-2fa", isUnderlined: true, external: true }, { children: "Learn more" }))] }), action: jsx(StyledButton, Object.assign({ variant: "primary", onClick: handleCreateTokenClick, size: "40", disabled: loadingUserData }, { children: "Create Token..." })) }), !loading && personalTokens && personalTokens.length > 0 && (jsx(TokenList, { tokens: personalTokens }))] }));
};

export { ManagePersonalTokens as default };

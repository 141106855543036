import styled, { css } from 'styled-components';
import { inputBaseStyles, inputBorderStyle, Input, truncateStyles, Text, Form, Button, popoverStyles, Truncate, Avatar, dropdownItemStyles } from '@sketch/components';
import { ReactComponent } from '@sketch/icons/person-prism-16';
import { breakpoint } from '@sketch/global-styles';

const InvitePeopleContent = styled.div `
  overflow: visible;
  margin-bottom: 16px;
`;
const InvitePeopleInputWrapper = styled.div `
  ${inputBaseStyles}
  ${inputBorderStyle}
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  border-radius: 6px;
  padding-left: 0;
  padding-right: 4px;

  ${({ focus }) => focus &&
    css `
      /* Native focus ring */
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color;
    `}

  /* We need to set a fixed width because if a item from the list
  it's very long, the list won't be longer than the input field */
  ${breakpoint('xs') `
    max-width: 533px;
  `}
`;
// Width of the invite field + add button
// for handling autocomplete items overflow
const InvitePeopleFieldWrapper = styled.div `
  width: 100%;
  flex: 1;
`;
const LinkInviteInput = styled(Input) `
  height: 40px;

  && {
    border: none;
    background: transparent;
    box-shadow: none;
    outline: none;
    padding-left: 0;
  }

  &[type='text'] {
    font-size: ${({ theme }) => theme.fontSizes.D};
    ${truncateStyles};

    :disabled {
      background-color: ${({ theme }) => theme.colors.background.secondary.B};
    }
  }
`;
const InvitePeopleIconWrapper = styled.div `
  display: flex;
  width: 40px;
  min-width: 40px;
  height: 40px;

  align-items: center;
  justify-content: center;

  /* stylelint-disable-line scales/space */
  left: -1px;
  top: 0;
  z-index: 1;
`;
const InvitePeopleInput = styled(LinkInviteInput) `
  &&& {
    ${({ isReadyToDeleteValue, isValueSelected, }) => {
    if (isReadyToDeleteValue) {
        return css `
          caret-color: transparent;
        `;
    }
}}
  }
`;
const InvitePeopleInputCombobox = styled.div `
  flex: 2;

  max-width: 256px;

  ${breakpoint('xs') `
    max-width: 420px;
  `}
`;
const InvitePeopleInputValueSelected = styled(Text.Span).attrs({
    textStyle: 'copy.sketch.standard.D',
}) `
  position: relative;
  z-index: 1;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.sketch.F};
  line-height: 1.6;

  white-space: nowrap;
  overflow: hidden;
  max-width: 60px;
  text-overflow: ellipsis;
  padding: 0 4px;

  ${breakpoint('xxs') `
    max-width: 110px;
    `}

  ${breakpoint('xs') `
    max-width: 85%;
  `}

  ${({ deleteable }) => deleteable &&
    css `
      background-color: ${({ theme }) => theme.colors.sketch.A};
      color: ${({ theme }) => theme.colors.foreground.primary.A};
    `}
`;
const iconStyles = css `
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
`;
const PersonIcon = styled(ReactComponent) `
  ${iconStyles}
`;
const InvitePeopleFieldForm = styled(Form) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const InvitePeopleFieldEmailField = styled(Form.Field) `
  flex: 1;
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
  z-index: 1;

  /* 100% - 92px button size - 16px margin-right invite field */
  max-width: calc(100% - 108px);

  ${breakpoint('xs') `
    margin-right: 16px;
  `}
`;
const InvitePeopleFieldInviteButton = styled(Button).attrs({
    variant: 'primary',
    size: '40',
}) `
  width: 92px;
  align-self: end;
`;
const AutocompleteList = styled.ul `
  ${popoverStyles};

  /* Cannot apply margin to this component if
     we want to properly position Popper in
     InvitePeopleField component */
  margin: 0;

  /* This will set the popover to adjust to the width
  of the longest list item, not surpassing the
  width of the input  */
  width: fit-content;
  max-width: 100%;

  &:empty {
    display: none;
  }
`;
const AutocompleteItemContent = styled(Truncate) `
  display: flex;
  align-items: center;
`;
const AutocompleteItemAvatar = styled(Avatar) `
  flex-shrink: 0;
  margin-right: 12px;
  min-width: 24px; /* Tweak for flex children with width: 0; */
`;
const PrimaryText = styled(Truncate) `
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`;
const SecondaryText = styled(Truncate) `
  font-size: 0.8125rem;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`;
const AutocompleteItem = styled.li `
  ${dropdownItemStyles};

  padding-left: 12px;
  padding-right: 12px;
`;
const AutocompleteItemText = styled.div `
  /* This is for the children to overflow. 100% - width of the Avatar */
  width: calc(100% - 28px);
`;
const InvitePeopleInputHeader = styled(Form.Label) `
  margin-bottom: 6px; /* stylelint-disable-line scales/space */
`;

export { AutocompleteItem, AutocompleteItemAvatar, AutocompleteItemContent, AutocompleteItemText, AutocompleteList, InvitePeopleContent, InvitePeopleFieldEmailField, InvitePeopleFieldForm, InvitePeopleFieldInviteButton, InvitePeopleFieldWrapper, InvitePeopleIconWrapper, InvitePeopleInput, InvitePeopleInputCombobox, InvitePeopleInputHeader, InvitePeopleInputValueSelected, InvitePeopleInputWrapper, LinkInviteInput, PersonIcon, PrimaryText, SecondaryText };

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import BaseSignUpView from './BaseSignUpView.js';
import { LoadingState } from '@sketch/components';
import { useGetCampaignSignUpContentQuery } from '@sketch/gql-types';
import { defaultContent } from './defaultContent.js';

const CampaignSpecificSignUpView = (_a) => {
    var _b;
    var { campaign } = _a, props = __rest(_a, ["campaign"]);
    const { data, loading, error } = useGetCampaignSignUpContentQuery({
        variables: { campaign },
    });
    if (loading) {
        return jsx(LoadingState, {});
    }
    if (error || !((_b = data === null || data === void 0 ? void 0 : data.campaignSignUpContent) === null || _b === void 0 ? void 0 : _b.active)) {
        // Fall back to showing the role-based sign up page if:
        // - there is an error loading the campaign data
        // - there is no such campaign
        // - the campaign exists but is inactive
        return jsx(BaseSignUpView, Object.assign({ content: defaultContent }, props));
    }
    const { content } = data.campaignSignUpContent;
    return jsx(BaseSignUpView, Object.assign({ content: content }, props));
};

export { CampaignSpecificSignUpView as default };

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import '../Box/index.js';
import '../Truncate/index.js';
import { ReactComponent } from '@sketch/icons/slash-forward-20';
import { Box } from '../Box/BoxSystem.js';
import { truncateStyles } from '../Truncate/Truncate.js';

const wrapTextNodeInSpan = node => typeof node === 'string' ? jsx("span", { children: node }) : node;
const BreadcrumbItem = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (jsx(Box, Object.assign({}, props, { children: React.Children.map(children, wrapTextNodeInSpan) })));
};
BreadcrumbItem.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
const breadcrumbStyles = css `
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  min-width: 20px;
  position: relative;

  ${truncateStyles};

  &:last-child {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.foreground.secondary.C};
  }

  &:active {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  ${props => props.active &&
    css `
      color: ${({ theme }) => theme.colors.foreground.secondary.A};
    `};

  > * {
    ${truncateStyles};
  }
`;
const StyledBreadcrumbItem = styled(BreadcrumbItem) `
  ${breadcrumbStyles}
`;
const breadcrumbDividerStyles = css `
  width: 20px;
  height: 20px;

  align-self: center;
  color: ${({ theme }) => theme.colors.border.A};

  position: relative;
  top: 1px;
  flex-shrink: 0;
`;
const BreadcrumbDivider = styled(ReactComponent) `
  ${breadcrumbDividerStyles}
`;
const BreadcrumbContainer = styled.div `
  display: flex;
  align-items: center;
  padding: 4px;
`;
const Breadcrumb = (_a) => {
    var { children: unfilteredChildren, divider = null } = _a, props = __rest(_a, ["children", "divider"]);
    const children = React.Children.toArray(unfilteredChildren).filter(x => x);
    const currentDivider = divider !== null && divider !== void 0 ? divider : jsx(BreadcrumbDivider, {});
    return (jsx(BreadcrumbContainer, Object.assign({}, props, { children: React.Children.map(children, (breadcrumb, i) => {
            const isLastChild = children.length ? children.length === i + 1 : true;
            return isLastChild ? breadcrumb : [breadcrumb, currentDivider];
        }) })));
};
Breadcrumb.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    divider: PropTypes.node,
};

export { Breadcrumb, StyledBreadcrumbItem, breadcrumbDividerStyles, breadcrumbStyles };

import { breakpoint } from '@sketch/global-styles';
import { ReactComponent } from '@sketch/icons/ellipsis-16';
import styled, { css } from 'styled-components';
import '../../Button/index.js';
import '../../Truncate/index.js';
import { ButtonUnstyled } from '../../Button/ButtonUnstyled.js';
import { truncateStyles } from '../../Truncate/Truncate.js';

const EllipsisIcon = styled(ReactComponent) `
  width: 16px;
  height: 16px;
`;
const DropdownAction = styled(ButtonUnstyled) `
  display: inline-flex;
  color: currentColor;

  height: 32px;

  justify-content: center;
  align-items: center;

  border-radius: ${({ theme }) => theme.radii.large};

  :hover,
  :focus,
  :focus-within {
    background-color: ${({ theme }) => theme.colors.background.tertiary.C};
  }
`;
const ActionWrapper = styled.div `
  /* Fix positioning issue with dropdown in Safari */
  position: relative;

  opacity: 0;
  width: 0;

  margin-left: auto;
  margin-right: 0;
  display: flex;
  justify-content: center;

  transition: ${({ theme: { transitions } }) => css `
      opacity ${transitions.duration[2]} ${transitions.timing.easeInOut},
      width ${transitions.duration[2]} ${transitions.timing.easeInOut};
    `};
`;
const Icon = styled.svg `
  width: 16px;
  height: 16px;
  margin-right: 12px;
  flex-shrink: 0;

  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;

  pointer-events: none;
`;
const hoverStyle = css `
  background-color: ${({ theme }) => theme.colors.background.tertiary.C};
  color: ${({ theme }) => theme.colors.foreground.secondary.B};

  ${ActionWrapper} {
    opacity: 1;
  }

  ${ActionWrapper} {
    width: 40px;
  }

  ${DropdownAction} {
    width: 40px;
  }

  /* Fix the hover color of the icon */
  ${Icon} {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
    opacity: 1;
  }
`;
const HighlightIconWrapper = styled.div(({ theme }) => css `
    position: relative;
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    margin-right: 12px;
    position: relative;

    &::after {
      content: '';
      background-color: ${theme.colors.sketch.A};
      position: absolute;
      top: -2px;
      right: -2px;
      border-radius: 50%;
      height: 8px;
      width: 8px;
      border: 1px solid ${theme.colors.background.secondary.B};
    }
  `);
const activeStyle = css `
  background-color: ${({ theme }) => theme.colors.sketch.F};
  color: ${({ theme }) => theme.colors.sketch.A};

  ${Icon} {
    opacity: 1;

    /* Start animation for icon paths */
    path {
      animation-play-state: running;
    }
  }

  ${Icon}, ${ActionWrapper} {
    color: ${({ theme }) => theme.colors.sketch.A};
  }
`;
const menuLinkStyles = css `
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;

  background-color: transparent;

  padding: 8px 0px 8px 12px;
  border-radius: 5px;

  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.D};
  line-height: 1.2;

  color: ${({ theme }) => theme.colors.foreground.secondary.B};

  cursor: pointer;

  transition: none;

  ${breakpoint('sm') `
    height: 32px;
  `}

  ${ActionWrapper} {
    color: ${({ theme }) => theme.colors.foreground.secondary.D};
  }

  @media (hover: none) {
    ${ActionWrapper} {
      opacity: 1;
      width: 40px;
      min-width: 40px;
    }
  }

  :hover,
  :focus,
  :focus-within {
    ${hoverStyle};
  }

  &.active {
    ${activeStyle}
  }
`;
const Text = styled.span `
  ${truncateStyles};
  padding-right: 4px;

  /* Aligns the label with the icon */
  position: relative;
  top: 1px;

  pointer-events: none;
`;
const MenuLink = styled.div `
  overflow: hidden;

  ${menuLinkStyles};
  ${({ $forceHover }) => $forceHover && hoverStyle};
  ${({ $forceActive }) => ($forceActive ? activeStyle : '')};
`;

export { ActionWrapper, DropdownAction, EllipsisIcon, HighlightIconWrapper, Icon, MenuLink, Text, menuLinkStyles };

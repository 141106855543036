import { jsxs, jsx } from 'react/jsx-runtime';
import { useState } from 'react';
import { Modal, Button, Box } from '@sketch/components';
import { useSignOut, RecoveryCode, VerificationCode } from '@sketch/modules-common';
import { useLinkRegularAccountWithMfaMutation } from '@sketch/gql-types';
import { useToast } from '@sketch/toasts';

const MFAModal = ({ email, hideModal, handleClickBack, mfa, }) => {
    const { showToast } = useToast();
    const [totp, setTotp] = useState('');
    const [recovery, setRecovery] = useState('');
    const [showRecoveryCodeInput, setShowRecoveryCodeInput] = useState(false);
    const handleOnError = (error) => {
        showToast(error.message, 'negative');
    };
    const signOut = useSignOut({
        revokeSessions: 'none',
        removeDataFromSessions: 'all',
        reason: 'Account linked',
    });
    const [linkRegularAccount, { loading },] = useLinkRegularAccountWithMfaMutation({
        onCompleted: () => {
            showToast(`SSO Account linked to ${email}`, 'positive');
            signOut();
        },
        onError: handleOnError,
    });
    const handleOnSubmit = () => {
        if (!totp && !recovery) {
            showToast('Please enter a verification code or recovery code', 'negative');
            return;
        }
        linkRegularAccount({
            variables: {
                mfa_token: mfa,
                totp: showRecoveryCodeInput ? undefined : totp,
                recovery_code: showRecoveryCodeInput ? recovery : undefined,
            },
        });
    };
    const handleToggleInterface = () => setShowRecoveryCodeInput(prev => !prev);
    const footer = (jsxs(Modal.Footer, Object.assign({ leftContent: jsx(Button, Object.assign({ onClick: handleClickBack }, { children: "Go Back" })) }, { children: [jsx(Button, Object.assign({ onClick: hideModal }, { children: "Cancel" })), jsx(Button, Object.assign({ type: "submit", variant: "primary", onClick: handleOnSubmit, loading: loading }, { children: "Link Account" }))] })));
    if (showRecoveryCodeInput) {
        return (jsxs(Modal, Object.assign({ onCancel: hideModal, title: 'Enter Recovery Code' }, { children: [jsxs(Modal.Body, { children: ["Can\u2019t access your authenticator app? Enter a recovery code for", ' ', jsx("strong", { children: email }), " instead.", jsx(Box, Object.assign({ mt: 6 }, { children: jsx(RecoveryCode, { mfaToken: mfa, toggleInterface: handleToggleInterface, hideHeader: true, onChange: e => setRecovery(e.target.value) }) }))] }), footer] })));
    }
    return (jsxs(Modal, Object.assign({ onCancel: hideModal, title: 'Enter Verification Code' }, { children: [jsxs(Modal.Body, { children: ["Open your authenticator app and enter the verification code for", ' ', jsx("strong", { children: email }), " before it expires \u2014 or wait for a new one.", jsx(Box, Object.assign({ mt: 6 }, { children: jsx(VerificationCode, { onFilled: setTotp, onVerificationCodeFilled: () => { }, toggleInterface: handleToggleInterface, mfaToken: mfa, hideHeader: true, onError: () => { } }) }))] }), footer] })));
};

export { MFAModal };

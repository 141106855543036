import { __rest } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import { useState } from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import '../Box/index.js';
import '../Tooltip/index.js';
import '../Button/index.js';
import { ReactComponent } from '@sketch/icons/squares.2.dotted';
import { Flex } from '../Box/BoxSystem.js';
import { IconButton } from '../Button/IconButton.js';
import { Button } from '../Button/Button.js';
import { Tooltip } from '../Tooltip/Tooltip.js';

const ANIMATION_DURATION_IN_MILLISECONDS = 1500;
const Icon = ({ handleClick, dropdown }) => (jsxs(Flex, Object.assign({ alignItems: "center", maxHeight: 17, maxWidth: 17, padding: "5px", marginTop: "2px" }, { children: [jsx(IconButton, { type: "button", icon: ReactComponent, size: "17px", "aria-label": "Copy", title: "Copy", onClick: handleClick }), dropdown] })));
const CopyButton = styled(Button) ``;
const CopyOnLabelIconWrapper = styled.div `
  opacity: 0;
`;
const CopyOnLabelContainer = styled.div `
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &:hover {
    ${CopyOnLabelIconWrapper} {
      opacity: 1;
    }
  }
`;
const CopyToClipboard = (_a) => {
    var { title = 'Copy', onCopy = () => { }, placement = 'left', spacing = '10px', value = '', dropdown, showIcon = true, children, disabled, fullWidth, className } = _a, rest = __rest(_a, ["title", "onCopy", "placement", "spacing", "value", "dropdown", "showIcon", "children", "disabled", "fullWidth", "className"]);
    const defaultTooltip = 'Copy to clipboard';
    const [copyTooltipText, setCopyTooltipText] = useState(defaultTooltip);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const handleClick = () => {
        if (disabled) {
            return;
        }
        setCopyTooltipText('Copied');
        setTooltipVisible(true);
        if (typeof value === 'string' || typeof value === 'number') {
            copy(`${value}`);
            onCopy(`${value}`);
        }
        window.setTimeout(() => {
            setTooltipVisible(false);
            setCopyTooltipText(defaultTooltip);
        }, ANIMATION_DURATION_IN_MILLISECONDS);
    };
    const handleMouseEnter = () => setTooltipVisible(true);
    const handleMouseLeave = () => setTooltipVisible(false);
    const getPopperContent = () => {
        if (dropdown || fullWidth) {
            return children ? (jsxs(CopyOnLabelContainer, Object.assign({ onClick: handleClick, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave }, { children: [children, jsx(CopyOnLabelIconWrapper, { children: showIcon ? (jsx(Icon, { handleClick: handleClick, dropdown: dropdown })) : (dropdown) })] }))) : (jsx(Icon, { handleClick: handleClick, dropdown: dropdown }));
        }
        if (showIcon) {
            return (jsxs(IconButton, Object.assign({ type: "button", icon: ReactComponent, size: "17px", "aria-label": title, title: title, onClick: handleClick }, { children: ["\u00A0", title] })));
        }
        return (jsx(CopyButton, Object.assign({ className: className, "aria-label": title, onClick: handleClick }, rest, { children: title })));
    };
    return (jsx(Tooltip, Object.assign({ className: "CopyToClipboard", visible: tooltipVisible, placement: placement, spacing: spacing, content: copyTooltipText }, { children: getPopperContent() })));
};

export { ANIMATION_DURATION_IN_MILLISECONDS, CopyToClipboard };

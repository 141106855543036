import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { TableComponents, WorkspaceLogo, truncateStyles, Pill, Button, TruncateWithTooltip } from '@sketch/components';
import { breakpoint } from '@sketch/global-styles';

const { TableCell } = TableComponents;
const StyledWorkspaceAvatar = styled(WorkspaceLogo)(({ $isExpired }) => css `
    opacity: ${$isExpired ? 0.25 : 1};
    width: 32px;
  `);
const WorkspaceNameWrapper = styled.div `
  display: flex;
  flex-direction: column;

  ${breakpoint('sm') `
    margin-left: 12px;
  `}
`;
const WorkspaceNameRow = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const WorkspaceNameLink = styled(Link) `
  ${breakpoint('base', 'sm') `
    max-width: 160px;
  `}
`;
const WorkspaceNameBase = styled.span(({ theme, $isExpired }) => css `
    ${truncateStyles}

    font-size: ${theme.fontSizes.E};
    font-weight: ${theme.fontWeights.medium};
    color: ${$isExpired
    ? theme.colors.foreground.secondary.D
    : theme.colors.foreground.secondary.A};
  `);
const WorkspaceName = forwardRef(function WorkspaceName(props, ref) {
    return jsx(WorkspaceNameBase, Object.assign({ ref: ref }, props));
});
const Sso = styled.span(({ theme }) => css `
    font-size: ${theme.fontSizes.C};
    font-weight: ${theme.fontWeights.regular};
    color: ${theme.colors.foreground.secondary.D};

    margin-top: 1px; /* stylelint-disable-line scales/space */
    margin-left: 4px;
  `);
const WorkspaceStatus = styled.span(({ theme }) => css `
    font-size: ${theme.fontSizes.D};
    font-weight: ${theme.fontWeights.regular};
    color: ${theme.colors.foreground.secondary.D};
  `);
const WorkspaceRole = styled.div(({ theme }) => css `
    display: flex;
    flex-direction: row;

    font-size: ${theme.fontSizes.D};
    font-weight: ${theme.fontWeights.regular};
    color: ${theme.colors.foreground.secondary.D};
  `);
const StyledPill = styled(Pill) `
  margin-left: 8px;
`;
const RightTableCell = styled(TableCell) `
  text-align: right;
`;
const LeaveButton = styled(Button).attrs({
    size: '32',
    variant: 'secondary-untinted',
}) `
  box-shadow: unset;
`;
const WorkspaceListItemNameWrapper = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const WorkspaceInfoWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-left: 12px;
`;
const WorkspaceNameWithTruncate = styled(TruncateWithTooltip) `
  max-width: 265px;
`;
const SignedOut = styled.span `
  opacity: 0.45;
`;

export { LeaveButton, RightTableCell, SignedOut, Sso, StyledPill, StyledWorkspaceAvatar, WorkspaceInfoWrapper, WorkspaceListItemNameWrapper, WorkspaceName, WorkspaceNameLink, WorkspaceNameRow, WorkspaceNameWithTruncate, WorkspaceNameWrapper, WorkspaceRole, WorkspaceStatus };

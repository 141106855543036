import React from 'react'
import Helmet from 'react-helmet'
import { Route, Switch, useParams } from 'react-router'

import { useForTablet, NavbarExtended, SplitLayout } from '@sketch/components'
import {
  DynamicLoadingPage,
  Footer,
  GenericErrorView,
  routes,
} from '@sketch/modules-common'
import {
  useLayoutOverrideProps,
  useCreateLayoutPortal,
  getItem,
} from '@sketch/utils'

import {
  Sidebar as SidebarNew,
  NoWorkspaceSidebar as NoWorkspaceSidebarNew,
  SettingsSidebar,
} from '../Sidebar'

import {
  SelectorSection,
  HamburgerButton,
  HamburgerIcon,
  SidebarWrapper,
  Margin,
  HeaderPortalContainer,
  HeaderSlimPortalContainer,
  NavbarPortalContainerDesktop,
  NavbarPortalContainerMobile,
} from './WorkspaceSidebarLayout.styles'

import { WorkspaceMinimalFragment } from '@sketch/gql-types'
import { useGetWorkspaces } from 'modules/workspace/operations'
import { localStorageKeys } from '@sketch/constants'

export interface LayoutPassDownProps {
  title: string
  hideFooter?: boolean
  hasNavbar?: boolean
}

export interface WorkspaceSidebarLayoutExtraProps {
  HeaderPortal: React.FunctionComponent<React.PropsWithChildren<{}>>
  HeaderSlimPortal: React.FunctionComponent<React.PropsWithChildren<{}>>
  NavbarPortal: React.FunctionComponent<React.PropsWithChildren<{}>>
  SubheaderPortal: React.FunctionComponent<React.PropsWithChildren<{}>>
  useOverrideLayoutProps: (props: LayoutPassDownProps) => void
  workspace: WorkspaceMinimalFragment
}

interface RenderPropChildren {
  render: (props: WorkspaceSidebarLayoutExtraProps) => React.ReactElement
}

interface ComponentChildren {
  component: React.ComponentType<WorkspaceSidebarLayoutExtraProps>
}

export type WorkspaceSidebarLayoutProps = OneOf<
  RenderPropChildren,
  ComponentChildren
> & {
  title?: string
  hideFooter?: boolean
  hideHeader?: boolean
}

/**
 * In some views there is no need to have current workspace.
 * E.g. user settings, updates, discovery page and so on.
 *
 * So if there is no selected workspace, just pick first one from the list.
 * (if that list contains any items).
 */
export const getCurrentWorkspace = (
  workspaceId?: string,
  workspaces?: WorkspaceMinimalFragment[]
): WorkspaceMinimalFragment | undefined => {
  if (!workspaces) return undefined

  const lastWorkspaceId =
    workspaceId || getItem(localStorageKeys.lastWorkspaceIdKey)
  if (lastWorkspaceId)
    return workspaces.find(
      ({ identifier }) =>
        identifier.toLowerCase() === lastWorkspaceId.toLowerCase()
    )

  return workspaces[0]
}

export const WorkspaceSidebarLayout: React.FC<WorkspaceSidebarLayoutProps> = props => {
  const params = useParams<{ workspaceId?: string }>()

  const {
    getOverriddenProps,
    useOverrideLayoutProps,
  } = useLayoutOverrideProps<LayoutPassDownProps>()

  const {
    title = 'Workspace',
    hideFooter,
    hideHeader = false,
    hasNavbar = true,
    render,
    component: Component,
  } = getOverriddenProps(
    props as WorkspaceSidebarLayoutProps & LayoutPassDownProps
  )

  // Reponsiveness
  const isTabletAndBigger = useForTablet()
  const isSmallerThanTablet = !isTabletAndBigger

  const { workspaces, loading, error } = useGetWorkspaces()

  // Layout hooks
  const [HeaderPortal, setHeaderPortalContainerRef] = useCreateLayoutPortal()
  const [
    HeaderSlimPortal,
    setHeaderSlimPortalContainerRef,
  ] = useCreateLayoutPortal()
  const [NavbarPortal, setNavbarPortalContainerRef] = useCreateLayoutPortal()
  const [
    SubheaderPortal,
    setSubheaderPortalContainerRef,
  ] = useCreateLayoutPortal()

  if (loading) {
    return <DynamicLoadingPage />
  }

  if (error) {
    return <GenericErrorView error={error} />
  }

  const workspace = getCurrentWorkspace(params.workspaceId, workspaces)

  const childrenProps = {
    // TODO: once every children is using useLayoutFromContext, we can remove portals from here
    HeaderPortal,
    HeaderSlimPortal,
    NavbarPortal,
    SubheaderPortal,
    useOverrideLayoutProps,

    // "workspace!" is used to avoid ts(2345) error with render() and <Component />.
    // This will not break anything because the check is being made before
    // calling does components.
    workspace: workspace!,
  }

  const NavbarPortalContainer = isSmallerThanTablet
    ? NavbarPortalContainerMobile
    : NavbarPortalContainerDesktop

  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#F0F0F0" />
      </Helmet>
      <SplitLayout
        isRefreshedUi={true}
        title={title}
        header={({ setSidebarLeftOpen, isSidebarLeftOpen }) => (
          <NavbarExtended
            isSidebarLeftOpen={isSidebarLeftOpen}
            singleContent={hasNavbar && !isSmallerThanTablet}
          >
            {isSmallerThanTablet && (
              <SelectorSection>
                <HamburgerButton
                  aria-label="Hamburger menu button"
                  onClick={() => setSidebarLeftOpen?.(true)}
                >
                  <HamburgerIcon />
                </HamburgerButton>
              </SelectorSection>
            )}

            {!hideHeader && (
              <>
                <HeaderPortalContainer
                  id="header-portal"
                  ref={setHeaderPortalContainerRef}
                />

                <HeaderSlimPortalContainer
                  id="header-slim-portal"
                  ref={setHeaderSlimPortalContainerRef}
                />

                <NavbarPortalContainer
                  id="navbar-portal"
                  ref={setNavbarPortalContainerRef}
                />
              </>
            )}
          </NavbarExtended>
        )}
        footer={!hideFooter && <Footer />}
        sidebar={
          <SidebarWrapper>
            {!workspace || workspaces?.length === 0 ? (
              <NoWorkspaceSidebarNew />
            ) : (
              <Switch>
                <Route
                  path={[
                    routes.WORKSPACE_SETTINGS.template(),
                    routes.PERSONAL_SETTINGS.template(),
                  ]}
                  render={() => <SettingsSidebar workspace={workspace} />}
                />
                <Route render={() => <SidebarNew workspace={workspace} />} />
              </Switch>
            )}
          </SidebarWrapper>
        }
      >
        <div id="subheader-portal" ref={setSubheaderPortalContainerRef} />

        <Margin>
          {render && render(childrenProps)}
          {Component && <Component {...childrenProps} />}
        </Margin>
      </SplitLayout>
    </>
  )
}

import styled, { css } from 'styled-components'
import { ReactComponent as Workspace } from '@sketch/icons/workspace-16'
import { LinkButton, Text } from '@sketch/components'

export const LearnMoreText = styled.div`
  display: inline;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
  margin: 0;
`

export const StyledBody = styled(Text).attrs({
  textStyle: 'copy.tertiary.standard.E',
})`
  padding: 0 32px 16px 32px;
`

export const LearnMoreButton = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.sketch.A};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.E};
  cursor: pointer;
  margin-left: 24px;
  align-items: center;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: 'center';
  padding-left: 32px;
  padding-bottom: 24px;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 100%;
`

export const Container = styled.section(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    padding-top: 4px;
    border: 1px solid ${theme.colors.border.A};
    border-radius: ${theme.radii.large};
    margin-bottom: 24px;
  `
)

export const HeroWrapper = styled.div`
  border-radius: 16px 16px 0 0;
`

export const StyledIcon = styled.svg.attrs({
  role: 'img',
})`
  margin: 32px 0 0 24px;
  width: 48px;
  height: 48px;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: content;
`

export const WorkspaceIcon = styled(Workspace)`
  width: 16px;
  height: 16px;
`

export const UpsellWrapper = styled.div`
  margin-bottom: 24px;
`

export const StyledIconBulletedList = styled.svg.attrs({
  role: 'img',
})`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`

export const StyledLinkButton = styled(LinkButton)`
  color: ${({ theme }) => theme.colors.foreground.primary.A};

  &:hover {
    color: ${({ theme }) => theme.colors.foreground.primary.A};
  }
`

export const BulletedList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const BulletedListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

import { buildGetElementTiming } from './buildGetElementTiming.js';
import { customElementsObserver } from './CustomElementsObserver.js';

const getCustomElementTiming = buildGetElementTiming((handler) => {
    const cleanup = customElementsObserver.observe(entry => {
        handler(entry);
    });
    return { cleanup };
}, 'CustomElementTiming');

export { getCustomElementTiming };

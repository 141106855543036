import React from 'react'
import { Display } from '../Attribute'
import ColorVariable from './ColorVariable'
import PlainColor from './PlainColor'
import { ColorFormat } from 'modules/shares/Inspector/Sidebar/components/Color/utils'
import { Color as ColorType } from 'modules/shares/types'

export interface ColorProps extends ColorType {
  label?: string
  style?: React.CSSProperties
  display?: Display
  dirtyAttributes?: { originalValue: ColorType; originalProperty: string }
  onColorFormatChange: (f: ColorFormat) => void
  colorFormat: ColorFormat
}

/**
 * COMPONENT
 */
const Color: React.FC<ColorProps> = props => {
  const { colorVariableName } = props

  if (colorVariableName) {
    return <ColorVariable {...props} colorVariableName={colorVariableName!} />
  }

  return <PlainColor {...props} />
}

export default Color

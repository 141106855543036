import { useGetPublicWorkspaceForAccountLinkingQuery } from '@sketch/gql-types';

const useLinkAccountConfirmation = (workspaceId) => {
    const { data, loading, error } = useGetPublicWorkspaceForAccountLinkingQuery({
        variables: { workspaceId },
    });
    if (loading) {
        return { type: 'loading' };
    }
    if (error || !data) {
        return {
            type: 'error',
            message: error === null || error === void 0 ? void 0 : error.message,
        };
    }
    const linkedWorkspaces = data.me.personalWorkspace
        ? [data.me.personalWorkspace, ...data.me.workspaces]
        : data.me.workspaces;
    return {
        type: 'loaded',
        signInWorkspace: Object.assign(Object.assign({}, data.publicWorkspace), { ssoStartUrl: data.publicWorkspace.customer.ssoStartUrl }),
        linkedWorkspaces,
        ssoLinkToken: data.me.ssoLinkToken,
    };
};

export { useLinkAccountConfirmation };

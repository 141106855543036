import React from 'react'
import { Avatar, Flex, Panel, ApolloError } from '@sketch/components'

import {
  Panel as StyledPanel,
  Heading,
  NameWithAvatar,
  Content,
  MainAction,
  SecondaryAction,
  BodyPanel,
} from './shared'
import { Inviter } from '@sketch/modules-common'
import { ParsedError } from '@sketch/graphql-apollo/useMutation'

export type UserInfo = {
  userEmail: string
  userAvatar?: string | null
}
interface AcceptMembershipPanelProps {
  resourceType: 'document' | 'project'
  resourceName: string
  inviter?: Inviter | null
  acceptMembership(): void
  acceptMembershipError?: ParsedError
  acceptMembershipLoading: boolean
  userInfo: UserInfo
  onRequestAccessWithDifferentAccount?: () => void
}

const AcceptMembershipPanel = ({
  resourceType,
  resourceName,
  inviter,
  acceptMembership,
  acceptMembershipError,
  acceptMembershipLoading,
  userInfo,
  onRequestAccessWithDifferentAccount,
}: AcceptMembershipPanelProps) => {
  const { userEmail, userAvatar } = userInfo

  return (
    <StyledPanel>
      <BodyPanel>
        {inviter && (
          <Flex justifyContent="center">
            <Avatar
              size="64px"
              src={inviter?.avatar?.large || undefined}
              name={inviter.name || '?' /** Name should always be there */}
            />
          </Flex>
        )}
        <Heading
          resourceType={resourceType}
          resourceName={resourceName}
          inviter={inviter}
        />
        <Content>
          Accept this invitation using{' '}
          <NameWithAvatar name={userEmail} src={userAvatar} /> to get access to
          &ldquo;<strong>{resourceName}</strong>&rdquo; and be notified of any
          updates.
        </Content>
      </BodyPanel>
      <Panel.Footer>
        {acceptMembershipError && (
          <ApolloError
            style={{ textAlign: 'left' }}
            my={4}
            error={acceptMembershipError}
          />
        )}
        <Flex flexDirection="column" alignItems="center">
          <MainAction
            variant="primary"
            fill
            onClick={acceptMembership}
            loading={acceptMembershipLoading}
          >
            Accept Invitation
          </MainAction>
          {onRequestAccessWithDifferentAccount ? (
            <SecondaryAction fill onClick={onRequestAccessWithDifferentAccount}>
              Request access with a different email
            </SecondaryAction>
          ) : (
            ''
          )}
        </Flex>
      </Panel.Footer>
    </StyledPanel>
  )
}

export default AcceptMembershipPanel

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * useScrollTop
 * Wait for a navigation event, and then force the browser to scroll to the
 * beginning of the page
 */
const useScrollTop = (selectedElement) => {
    const element = selectedElement || window;
    const history = useHistory();
    useEffect(() => {
        const disconnect = history.listen(() => element.scrollTo(0, 0));
        return () => disconnect();
    }, [history, element]);
};

export { useScrollTop };

import React from 'react'
import styled from 'styled-components'
import { useRouteMatch } from 'react-router-dom'

import { Flex, Box } from '@sketch/components'
import { CopyCSSButton } from '../CopyCSSButton'
import {
  ComponentName,
  ComponentSubName,
} from 'modules/shares/Inspector/Sidebar/components/ComponentName'

import { getWhichCwvRoute } from '@sketch/modules-common'

import { ReactComponent as LayerStyleIcon } from '@sketch/icons/paint-brush-16'
import { ReactComponent as TextStyleIcon } from '@sketch/icons/text-format-16'
import { ReactComponent as ColorVariableIcon } from '@sketch/icons/color-fan-16'

import { Style } from 'modules/shares/types'

const PaddedContainer = styled.div(
  ({ theme }) => `
  padding: 16px 20px;
  font-size: ${theme.fontSizes.D};
  display: flex;
  align-items: center;
  width: 100%;
`
)

const IconContainer = styled.div(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  height: 36px;
  width: 36px;
  padding: 8px;
  background-color: ${theme.colors.background.tertiary.C};
  color: ${theme.colors.foreground.secondary.D};
  border-radius: ${theme.radii.large};
  margin-right: 8px;

  svg {
      height: 16px;
  }
`
)

const getIcon = (route: string) => {
  switch (route) {
    case 'CWV_LAYER_STYLES':
      return <LayerStyleIcon />
    case 'CWV_TEXT_STYLES':
      return <TextStyleIcon />
    case 'CWV_COLOR_VARIABLES':
      return <ColorVariableIcon />
    default:
      return null
  }
}

export const Header: React.FC<{
  componentName: string
  componentPath?: string
  style?: Style
}> = ({ componentName, componentPath, style }) => {
  const { path } = useRouteMatch()
  const currentRoute = getWhichCwvRoute(path)

  return (
    <PaddedContainer>
      <IconContainer>{getIcon(currentRoute)}</IconContainer>
      <Flex flex={1} mr={2} minWidth={0}>
        <Box minWidth={0}>
          <ComponentName>{componentName}</ComponentName>
          <ComponentSubName>{componentPath}</ComponentSubName>
        </Box>
      </Flex>
      {style ? (
        <Flex flex={0} justifyContent="flex-end">
          <CopyCSSButton style={style} />
        </Flex>
      ) : null}
    </PaddedContainer>
  )
}

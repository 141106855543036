import { DataProxy } from 'apollo-cache'
import { ShareInfoFragment, ShareListItemFragment } from '@sketch/gql-types'
import { SharesPagination, updateSharesCache } from '../operations/utils'
import { invalidateProjectShares } from 'modules/collections/operations'

// this is an approximation of the original sort of the BE
const getSortValue = (share: ShareInfoFragment | ShareListItemFragment) =>
  Date.parse(share.version?.createdAt || share.updatedAt!) || new Date()

export const addShareToLists = (
  share: ShareListItemFragment,
  workspaceId: string,
  cache: DataProxy
) => {
  const update = (data: SharesPagination): void => {
    const shareUpdatedAtValue = getSortValue(share)
    const olderThanItems = data.entries.findIndex(
      x => shareUpdatedAtValue > getSortValue(x)
    )

    // if the document is older than all items it should appear as the last item
    // see https://github.com/sketch-hq/cloud-frontend/pull/2152#issuecomment-684864000
    const insertAt =
      olderThanItems !== -1 ? olderThanItems : data.entries.length

    data.entries.splice(insertAt, 0, share)
    data.meta.totalCount++
  }

  if (share.project) {
    invalidateProjectShares({
      cache,
      projectIdentifier: share.project.identifier,
    })
  }

  const filters: (undefined | 'NO_PROJECT' | 'LIBRARY')[] = [undefined]

  if (!share.project) {
    filters.push('NO_PROJECT')
  }
  if (share.type === 'LIBRARY') {
    filters.push('LIBRARY')
  }
  for (const filter of filters) {
    updateSharesCache({
      cache,
      update,
      workspaceId,
      filter,
    })
  }
}

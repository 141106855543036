import styled from 'styled-components'

import { ReactComponent as ImagePlaceholder } from '@sketch/icons/image-placeholder-64'

import { Text, ButtonUnstyled } from '@sketch/components'
import MarkdownDocumentDescription from 'modules/shares/components/EditableDocumentDescription/MarkdownDocumentDescription'

import { breakpoint } from '@sketch/global-styles'

export const Wrapper = styled.div`
  margin: 24px 80px 140px 80px; /* stylelint-disable-line scales/space */

  ${breakpoint('base', 'sm')`
    margin: 0 24px 140px 24px;  /* stylelint-disable-line scales/space */
  `}
`

export const MaxWidth = styled.div<{ $width: number }>`
  display: flex;
  flex-direction: column;
  max-width: ${({ $width }) => `${$width}`}px;
  margin: 0 auto;
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0 32px 0;

  ${breakpoint('base', 'sm')`
    flex-direction: column;
  `}
`

export const Title = styled(Text.H1).attrs({
  textStyle: 'header.primary.I',
})`
  margin: 0;

  ${breakpoint('base', 'sm')`
    text-align: center;
  `}
`

export const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;

  ${breakpoint('base', 'sm')`
    margin-top: 16px;
  `}
`

export const Description = styled(MarkdownDocumentDescription)`
  max-width: 540px;
  margin-top: 56px;
  margin-right: 44px;
  margin-bottom: 24px;
  flex: 1 1 100%;

  font-size: ${({ theme }) => theme.fontSizes.F};
  line-height: ${({ theme }) => theme.lineHeights.F};
  color: ${({ theme }) => theme.colors.foreground.secondary.C};

  ${breakpoint('base', 'sm')`
    max-width: 100%;
    margin-top: 28px;
    margin-right: 0;
    margin-bottom: 0;
  `}
`

export const Content = styled.div`
  display: flex;

  ${breakpoint('base', 'sm')`
    flex-direction: column;
  `}
`

export const DetailsContainer = styled.div`
  margin-top: 56px;

  ${breakpoint('base', 'sm')`
    margin-top: 28px;
  `}
`

export const Detail = styled(Text.P).attrs({
  textStyle: 'copy.quaternary.standard.C',
})`
  display: inline-flex;
  align-items: center;
  margin: 0;
  white-space: nowrap;
  line-height: 1;

  :not(:last-child) {
    margin-right: 24px;
  }
`

export const Icon = styled.svg`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`

export const StyledButton = styled(ButtonUnstyled)`
  margin-top: 28px;
  font-size: ${({ theme }) => theme.fontSizes.B};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  transition: color ${({ theme }) => theme.transitions.duration[1]};

  :hover {
    color: ${({ theme }) => theme.colors.foreground.secondary.C};
  }
`

export const NoImageThumbnail = styled.div`
  position: relative;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  border-radius: ${({ theme }) => theme.radii.xxlarge};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.border.B};

  ${breakpoint('base', 'sm')`
    margin: 0 -24px;
    border-radius: 0;
  `}
`

export const NoImageIcon = styled(ImagePlaceholder).attrs({ role: 'img' })`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
  width: 64px;
  height: 64px;
`

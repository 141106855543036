import React from 'react'

import DocumentPresenceCreatorAvatars from 'modules/shares/components/DocumentPresenceCreatorAvatars'

import { usePresences } from '../../operations'

import { Wrapper, Copy } from './PresenceUsers.styles'
import { useUserProfile } from '@sketch/modules-common'

export interface PresenceUsersProps {
  className?: string
  shareIdentifier: string
  fallback?: () => React.ReactNode
  forceFallback?: boolean
}

const PresenceUsers = (props: PresenceUsersProps) => {
  const { className, shareIdentifier, fallback, forceFallback } = props
  const { presences = [] } = usePresences(shareIdentifier, {
    skip: forceFallback,
  })

  const { data } = useUserProfile()
  const userIdentifier = data?.me?.identifier

  const presenceLength = presences.length
  if (forceFallback || presenceLength === 0) {
    return <>{fallback?.()}</>
  }

  return (
    <Wrapper className={className}>
      <Copy>Currently active</Copy>{' '}
      <DocumentPresenceCreatorAvatars
        isEditing
        avatars={presences.map(({ user, action }) => ({
          name: userIdentifier === user.identifier ? 'You' : user.name,
          identifier: user.identifier,
          avatar: user.avatarUrl,
          action,
        }))}
      />
    </Wrapper>
  )
}

export default PresenceUsers

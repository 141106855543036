import React, { FC } from 'react'

import { Button, Tooltip } from '@sketch/components'

import BillingInfoTable from '../BillingInfoTable'

import { BillingSeatsInfoFragment } from '@sketch/gql-types'

import { StyledPluralize } from 'modules/workspace/modals/ChangeNextBillingCycleModal/ChangeNextBillingCycleModal.styles'

interface BillingForProps {
  seats: BillingSeatsInfoFragment
  onSeatScheduleChange: () => void
  isSubscriptionEnd: boolean
  isEducationPlan: boolean
}

const scheduleSeatChangesCopy = 'Schedule Seat Changes…'

const CancelledSubscriptionButton: React.FC = () => {
  const tooltipText =
    'To schedule seat changes, please subscribe again. You can add new seats in the Members tab during the time left on your subscription.'

  return (
    <Tooltip
      placement="top"
      spacing="12px"
      content={
        <>
          <Tooltip.Title>Subscription is canceled</Tooltip.Title>
          <Tooltip.Body>{tooltipText}</Tooltip.Body>
        </>
      }
    >
      <Button variant="secondary" size="32" disabled>
        {scheduleSeatChangesCopy}
      </Button>
    </Tooltip>
  )
}

const BillingFor: FC<BillingForProps> = ({
  seats,
  onSeatScheduleChange,
  isSubscriptionEnd,
  isEducationPlan,
}) => {
  return (
    <BillingInfoTable.Row>
      <BillingInfoTable.HeadingCell>Billing For</BillingInfoTable.HeadingCell>
      <BillingInfoTable.Cell>
        <strong>
          <StyledPluralize
            singular="Editor Seat"
            plural="Editor Seats"
            count={seats.scheduledSeatsTotal || seats.currentSeatsTotal}
            showCount
          />
        </strong>
      </BillingInfoTable.Cell>
      {!isEducationPlan && (
        <BillingInfoTable.Cell>
          {isSubscriptionEnd && <CancelledSubscriptionButton />}
          {!isSubscriptionEnd && (
            <Button
              variant="secondary"
              onClick={onSeatScheduleChange}
              size="32"
            >
              {scheduleSeatChangesCopy}
            </Button>
          )}
        </BillingInfoTable.Cell>
      )}
    </BillingInfoTable.Row>
  )
}

export default BillingFor

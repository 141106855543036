import { jsx } from 'react/jsx-runtime';
import styled from 'styled-components';
import '../DownloadFile/index.js';
import { dropdownItemStyles, ListItem } from './Item/DropdownItem.styles.js';
import './Item/DropdownItem.js';
import { DownloadFileAnchor } from '../DownloadFile/DownloadFileAnchor.js';

const DropdownDownloadLink = styled(DownloadFileAnchor).attrs({
    role: 'button',
}) `
  ${dropdownItemStyles}
`;
const DropdownDownload = (props) => (jsx(ListItem, { children: jsx(DropdownDownloadLink, Object.assign({ "data-unmountafterclick": true }, props)) }));

export { DropdownDownload };

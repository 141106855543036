const findUserErrors = (data) => {
    if (!data || typeof data !== 'object')
        return [];
    return Object.values(data)
        .filter(value => {
        var _a;
        return !!value &&
            typeof value === 'object' &&
            Array.isArray(value.errors) &&
            ((_a = value.errors[0]) === null || _a === void 0 ? void 0 : _a.__typename) === 'UserError';
    })
        .flatMap(value => value.errors);
};
function useErrorRedirect(options, extraOptions) {
    const { redirectErrors, onCompleted, update, refetchQueries } = options || {};
    const { onUserErrorHandler } = extraOptions;
    if (!redirectErrors) {
        // if we don't need to redirect errors
        // then we don't want to override any of the existing options
        return {};
    }
    return {
        refetchQueries: (...args) => {
            const { data } = args[0];
            if (!refetchQueries || findUserErrors(data).length > 0) {
                return [];
            }
            if (typeof refetchQueries === 'function') {
                return refetchQueries(...args) || [];
            }
            return refetchQueries || [];
        },
        update: (proxy, mutationResult) => {
            if (findUserErrors(mutationResult.data).length > 0) {
                return;
            }
            update === null || update === void 0 ? void 0 : update(proxy, mutationResult);
        },
        onCompleted: data => {
            const userErrors = findUserErrors(data);
            if (userErrors.length > 0) {
                onUserErrorHandler === null || onUserErrorHandler === void 0 ? void 0 : onUserErrorHandler(userErrors);
            }
            else {
                onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted(data);
            }
        },
    };
}

export { findUserErrors, useErrorRedirect };

import { ReactComponent as AllDocuments } from '@sketch/icons/documents-20'
import { ReactComponent as SharedWithMe } from '@sketch/icons/person-circle-20'
import { ReactComponent as LinkLibrary } from '@sketch/icons/link-library-20'
import { ReactComponent as Settings } from '@sketch/icons/gear-filled-20'
import { ReactComponent as Trash } from '@sketch/icons/trash.empty'
import { ReactComponent as FolderClosed } from '@sketch/icons/folder-closed-20'
import { ReactComponent as FolderOpen } from '@sketch/icons/folder-open-20'
import { ReactComponent as MyDraftsProject1x } from '@sketch/icons/notes-20-1x'
import { ReactComponent as MyDraftsProject2x } from '@sketch/icons/notes-20-2x'

export * from './components'
export * from './modals'

export const icons = {
  AllDocuments,
  SharedWithMe,
  LinkLibrary,
  Settings,
  Trash,
  FolderClosed,
  FolderOpen,
  /*
  Designers could not come up with one icon that looks perfect on both
  1x and 2x aspect ratio screens at the same time, so they created two.
  */
  MyDraftsProject1x,
  MyDraftsProject2x,
}

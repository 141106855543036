import styled from 'styled-components'

import { AddTextWrapper } from 'modules/projects/components/ProjectsHeader'

export {
  ProjectsHeaderText as HeaderText,
  AddButton,
  StyledAddButton,
} from 'modules/projects/components/ProjectsHeader'

export const HeaderWrapper = styled(AddTextWrapper)`
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

import { ReactComponent as AllDocuments } from '@sketch/icons/documents-prism-16'
import { ReactComponent as SharedWithMe } from '@sketch/icons/persons-2-16'
import { ReactComponent as LinkLibrary } from '@sketch/icons/link-library-prism-16'
import { ReactComponent as Trash } from '@sketch/icons/trash-empty-prism-16'
import { ReactComponent as FolderClosed } from '@sketch/icons/folder-closed-16'
import { ReactComponent as FolderOpen } from '@sketch/icons/folder-open-16'
import { ReactComponent as MyDraftsProject } from '@sketch/icons/my-drafts-prism-16'
import { ReactComponent as PersonIcon } from '@sketch/icons/person-prism-16'
import { ReactComponent as BellIcon } from '@sketch/icons/bell-prism-16'
import { ReactComponent as GearIcon } from '@sketch/icons/gear-prism-16'
import { ReactComponent as LifeBelt } from '@sketch/icons/lifebelt-16'
import { ReactComponent as Home } from '@sketch/icons/home-16'
import { ReactComponent as MailEnvelope } from '@sketch/icons/mail-envelope-16'
import { ReactComponent as Notebook } from '@sketch/icons/notebook-16'
import { ReactComponent as QuestionMark } from '@sketch/icons/question-mark-16'
import { ReactComponent as SketchLogo } from '@sketch/icons/sketch-logo-16'
import { ReactComponent as Command } from '@sketch/icons/command-16'
import { ReactComponent as ChatIcon } from '@sketch/icons/chat-bubble-16'
import { ReactComponent as ChatBubbles } from '@sketch/icons/chat-bubbles-16'
import { ReactComponent as EmailIcon } from '@sketch/icons/at-email-16'
import { ReactComponent as TemplateIcon } from '@sketch/icons/template-16'
import { ReactComponent as Money } from '@sketch/icons/money-dollar-16'
import { ReactComponent as ControlIcon } from '@sketch/icons/control-color-well-16'
import { ReactComponent as Desktop } from '@sketch/icons/device-desktop-16'
import { ReactComponent as Phone } from '@sketch/icons/device-phone-16'
import { ReactComponent as CommunityIcon } from '@sketch/icons/community-b-16'
import { ReactComponent as LockOpen } from '@sketch/icons/lock-open'
import { ReactComponent as GroupCircle } from '@sketch/icons/group-circle-16'
import { ReactComponent as Archive } from '@sketch/icons/archive-box-16'
import { ReactComponent as Key } from '@sketch/icons/key-16'

import { CompassIcon } from './animatedIcons'

export const icons = {
  AllDocuments,
  SharedWithMe,
  LinkLibrary,
  Trash,
  FolderClosed,
  FolderOpen,
  MyDraftsProject,
  PersonIcon,
  BellIcon,
  GearIcon,
  EmailIcon,
  LifeBelt,
  LockOpen,
  Home,
  MailEnvelope,
  Notebook,
  QuestionMark,
  SketchLogo,
  Command,
  ChatIcon,
  ChatBubbles,
  CompassIcon,
  TemplateIcon,
  Money,
  ControlIcon,
  Phone,
  Desktop,
  CommunityIcon,
  GroupCircle,
  Archive,
  Key,
}

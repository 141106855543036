import { parsePath } from 'history';
import qs from 'query-string';

const addQueryParametersToUrl = (url, queryParameters) => {
    const parsedUrl = qs.parseUrl(url);
    Object.keys(queryParameters).forEach(key => (parsedUrl.query[key] = queryParameters[key]));
    return qs.stringifyUrl(parsedUrl);
};
const normalizeToLocationObject = (location) => typeof location === 'string' ? parsePath(location) : location;

export { addQueryParametersToUrl, normalizeToLocationObject };

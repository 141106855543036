import React, { useState, useEffect } from 'react'
import debounce from 'debounce'

import { Tooltip } from '@sketch/components'

import {
  ScrollToTopButtonWrapper,
  ScrollToTopButton,
  ArrowIcon,
} from './ScrollToTopButton.styles'

export const ScrollToTop = () => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const scrollingContainer = document.querySelector('[data-content-scrollable]')
  const DEBOUNCE_TIMEOUT = 100

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(scrollingContainer?.scrollTop || 0)
    }

    scrollingContainer?.addEventListener(
      'scroll',
      debounce(handleScroll, DEBOUNCE_TIMEOUT)
    )

    return () => {
      scrollingContainer?.removeEventListener('scroll', handleScroll)
    }
  }, [scrollingContainer, scrollPosition])

  return (
    <ScrollToTopButtonWrapper isVisible={scrollPosition > 200}>
      <Tooltip placement="left" spacing="6px" content="Back to top">
        <ScrollToTopButton
          onClick={() => {
            if (!scrollingContainer) {
              return
            }

            scrollingContainer.scrollTop = 0
          }}
        >
          <ArrowIcon width={24} height={24} />
        </ScrollToTopButton>
      </Tooltip>
    </ScrollToTopButtonWrapper>
  )
}

import { __rest } from 'tslib';
import { jsx, jsxs } from 'react/jsx-runtime';
import { useState, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTransition, animated } from 'react-spring';
import { CardNumberElement } from '@stripe/react-stripe-js';
import '../../PaymentLogo/index.js';
import { Input } from '../Input.js';
import { invalidStyles, commonStyles } from './commonStyles.js';
import { inputBaseStyles } from '../Input.styles.js';
import { PaymentLogo } from '../../PaymentLogo/PaymentLogo.js';

const INITIAL_ANIMATION_STATE = { transform: 'scale(0)', opacity: 0 };
const FINAL_ANIMATION_STATE = { transform: 'scale(1)', opacity: 1 };
const ANIMATION_STATES = {
    initial: null,
    from: INITIAL_ANIMATION_STATE,
    enter: FINAL_ANIMATION_STATE,
    leave: INITIAL_ANIMATION_STATE,
    config: { mass: 0.5, tension: 400, friction: 20 },
    unique: true,
};
const CardWrapper = styled.div `
  width: auto;
  height: 20px;
  user-select: none;
  overflow: hidden;
`;
const CardLogo = styled(PaymentLogo) `
  :not(:last-child) {
    margin-right: 12px;
  }
`;
const CardInputWrapper = styled(Input) `
  ${inputBaseStyles};

  display: flex;
  align-items: center;
`;
CardInputWrapper.defaultProps = {
    className: 'StripeElement',
};
const BaseCardNumberInput = styled(CardNumberElement) `
  flex: 1;
  height: 40px;
  padding-top: 13px; /* stylelint-disable-line scales/space */

  &::placeholder {
    color: ${({ theme }) => theme.colors.foreground.secondary.C};
  }
`;
const SUPPORT_CREDIT_CARDS = ['amex', 'mastercard', 'visa'];
const CardNumberInput = (_a) => {
    var { className, disabled, onChange, invalid, onFocus, onBlur } = _a, props = __rest(_a, ["className", "disabled", "onChange", "invalid", "onFocus", "onBlur"]);
    const theme = useTheme();
    const [cardBrand, setCardBrand] = useState('unknown');
    const [isInputFocused, setIsInputFocused] = useState(false);
    const handleCardNumberOnChange = useCallback(callbackValues => {
        const { brand } = callbackValues;
        cardBrand !== brand && setCardBrand(brand);
        onChange && onChange(callbackValues);
    }, [cardBrand, onChange]);
    const transitions = useTransition(cardBrand, null, ANIMATION_STATES);
    const handleTransition = useCallback(({ item, key, props }) => {
        if (item === 'unknown') {
            return (jsx(animated.div, Object.assign({ style: props }, { children: SUPPORT_CREDIT_CARDS.map(card => (jsx(CardLogo, { className: card, brand: card }, card))) }), key));
        }
        return (jsx(animated.div, Object.assign({ style: props }, { children: jsx(CardLogo, { brand: item }) }), key));
    }, []);
    return (jsxs(CardInputWrapper, Object.assign({ className: className, disabled: disabled !== null && disabled !== void 0 ? disabled : false, stripeFakeFocus: isInputFocused, invalid: invalid, as: "div" }, { children: [jsx(BaseCardNumberInput, Object.assign({}, props, { options: {
                    style: invalid ? invalidStyles(theme) : commonStyles(theme),
                }, onFocus: event => {
                    onFocus === null || onFocus === void 0 ? void 0 : onFocus(event);
                    setIsInputFocused(true);
                }, onBlur: event => {
                    setIsInputFocused(false);
                    onBlur === null || onBlur === void 0 ? void 0 : onBlur(event);
                }, onChange: handleCardNumberOnChange })), jsx(CardWrapper, { children: transitions.map(handleTransition) })] })));
};

export { CardNumberInput };

import { jsx } from 'react/jsx-runtime';
import { ErrorMessage, PageLayout } from '@sketch/components';
import '../EmptyHeader/index.js';
import styled from 'styled-components';
import { ReactComponent } from '@sketch/icons/warning-triangle-64';
import { EmptyHeader } from '../EmptyHeader/EmptyHeader.js';

const StyledErrorMessage = styled(ErrorMessage) `
  flex: 1 0 auto;
`;
const EmbeddedError = (props) => {
    const title = {
        document: "We couldn't find the document.",
        artboard: "We couldn't find the artboard.",
        unknown: 'There was an error loading this content.',
    }[props.for];
    const message = props.for === 'unknown' ? null : 'It may have been moved or deleted.';
    return (jsx(StyledErrorMessage, Object.assign({ icon: jsx(ReactComponent, {}), iconSize: "medium", title: title }, { children: message })));
};
/**
 * A simple error view for embeds that fail to load.
 */
const EmbeddedErrorPage = props => {
    return (jsx(PageLayout, Object.assign({ header: jsx(EmptyHeader, {}) }, { children: jsx(EmbeddedError, Object.assign({}, props)) })));
};

export { EmbeddedError, EmbeddedErrorPage };

import { ModalBody } from './ModalBody.js';
import { ModalText } from './ModalText.js';
import { ModalFooter } from './ModalFooter.js';
import { ModalHeader } from './ModalHeader.js';
import { ModalExplainer } from './ModalExplainer.js';
import { ModalHighlight } from './ModalHighlight.js';
import { Modal as Modal$1 } from './Modal.js';

const Modal = Object.assign(Modal$1, {
    Highlight: ModalHighlight,
    Body: ModalBody,
    Footer: ModalFooter,
    Header: ModalHeader,
    Text: ModalText,
    Explainer: ModalExplainer,
});

export { Modal };

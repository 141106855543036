import styled, { keyframes, css } from 'styled-components';

const reveal = keyframes `
  0% {
    opacity: 0;
    transform: translateY(-5px)
  }
  100% {
    opacity: 1;
    transform: translateY(0)
  }
`;
const popoverStyles = css `
  ${({ contentPadding = '8px', fillContent, fillWidth = '100%', theme: { colors, radii, shadows, transitions, zIndex }, }) => css `
    display: flex;
    flex-direction: column;

    min-width: 100px;
    padding: ${contentPadding};

    /* Make the popover on top of the modal */
    z-index: ${zIndex[8]};

    border: 1px solid ${colors.border.A};
    border-radius: ${radii.xxlarge};

    background: ${colors.background.secondary.A};
    box-shadow: ${shadows.boxShadow.outer};
    background-clip: padding-box;
    ${fillContent && `width: calc(${fillWidth} + 2px);`};

    animation: ${reveal} ${transitions.duration[2]}
      ${transitions.timing.easeOutExponential};
  `}
`;
const UnstyledPopoverContent = styled.div `
  ${popoverStyles};
`;
const PopoverContent = styled(UnstyledPopoverContent) ``;

export { PopoverContent, popoverStyles };

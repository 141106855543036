import { jsx } from 'react/jsx-runtime';
import { EmbedGate as EmbedGate$1 } from '@sketch/components';
import '../../routes/index.js';
import { versionedRoutes } from '../../routes/routes.js';

/**
 * Embeddable routes whitelist.
 */
const embeddableRoutes = [
    versionedRoutes.SHARE_VIEW.LATEST,
    versionedRoutes.SHARE_VIEW.VERSION,
    versionedRoutes.ARTBOARD_DETAIL.LATEST,
    versionedRoutes.ARTBOARD_DETAIL.VERSION,
    versionedRoutes.ARTBOARD_REVISION.LATEST,
    versionedRoutes.ARTBOARD_REVISION.VERSION,
    versionedRoutes.ARTBOARD_DETAIL_UUID.LATEST,
    versionedRoutes.ARTBOARD_DETAIL_UUID.VERSION,
    versionedRoutes.SHARE_PAGE_VIEW.LATEST,
    versionedRoutes.SHARE_PAGE_VIEW.VERSION,
    versionedRoutes.SHARE_PAGE_CANVAS_VIEW.LATEST,
    versionedRoutes.SHARE_PAGE_CANVAS_VIEW.VERSION,
    versionedRoutes.PROTOTYPE_LEGACY_ROUTE_1.LATEST,
    versionedRoutes.PROTOTYPE_LEGACY_ROUTE_1.VERSION,
    versionedRoutes.PROTOTYPE_LEGACY_ROUTE_2.LATEST,
    versionedRoutes.PROTOTYPE_LEGACY_ROUTE_2.VERSION,
    versionedRoutes.PROTOTYPE_PLAYER.LATEST,
    versionedRoutes.PROTOTYPE_PLAYER.VERSION,
];
/**
 * Wrapper around EmbedGate component, the EmbedGate only renders its children if the
 * initial pathname is strictly one of the whitelisted embedded routes listed
 * above, otherwise it outputs null.
 */
const EmbedGate = ({ children }) => {
    return (jsx(EmbedGate$1, Object.assign({ embeddableRoutes: embeddableRoutes }, { children: children })));
};

export { EmbedGate };

import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, TimeAgo, Modal, ModalInjectedProps } from '@sketch/components'
import { routes } from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'
import { useVersionDelete } from 'modules/versioning/operations'

export interface DeleteVersionModalProps extends ModalInjectedProps {
  shareName: string
  creatorName: string
  createdAt: string
  versionIdentifier: string
  shareID: string
  isActive: boolean
}

export const DeleteVersionModal: React.FC<DeleteVersionModalProps> = props => {
  const {
    shareName,
    hideModal,
    versionIdentifier,
    shareID,
    isActive,
    createdAt,
    creatorName,
  } = props
  const { showToast } = useToast()
  const history = useHistory()

  const [deleteVersion, { loading }] = useVersionDelete({
    versionIdentifier,
    shareIdentifier: shareID,
    onError: message => {
      showToast(message, 'negative')
    },
    onCompleted: () => {
      showToast(`"${shareName}" version deleted`)

      // redirect to share overview
      if (isActive) {
        history.push(routes.SHARE_VIEW.create({ shareID }))
      }

      hideModal()
    },
  })

  return (
    <Modal title="Delete Document Version?" onCancel={hideModal}>
      <Modal.Body>
        <p>
          {creatorName} uploaded the &ldquo;<b>{shareName}</b>&rdquo; version{' '}
          <TimeAgo date={createdAt} capitalizeNow={false} />. You can’t undo
          this action.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} onClick={hideModal}>
          Cancel
        </Button>
        <Button
          variant="negative-secondary"
          loading={loading}
          disabled={loading}
          onClick={() => deleteVersion()}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import { jsx, jsxs } from 'react/jsx-runtime';
import { routes } from '@sketch/modules-common';
import { TableComponents, WorkspaceLogo, useForTablet, TimeAgo, Tooltip } from '@sketch/components';
import { isPersonalWorkspace } from 'cloud-frontend';
import { StyledWorkspaceAvatar, WorkspaceNameWrapper, WorkspaceNameRow, WorkspaceNameLink, WorkspaceName, WorkspaceNameWithTruncate, Sso, WorkspaceStatus, WorkspaceRole, StyledPill, WorkspaceInfoWrapper, SignedOut, LeaveButton, WorkspaceListItemNameWrapper, RightTableCell } from './WorkspaceListItem.styles.js';

const { TableCell, TableRow } = TableComponents;
const trialDateFormat = (value, unit) => `${value} ${unit}${value > 1 ? 's' : ''}`;
const WorkspaceListItemLogo = ({ workspace, }) => {
    var _a;
    const isExpired = workspace.status === 'INACTIVE';
    return isPersonalWorkspace(workspace) ? (jsx(WorkspaceLogo.Icon, { size: "32px", icon: "person" })) : (jsx(StyledWorkspaceAvatar, { workspaceName: workspace.name, src: (_a = workspace.avatar) === null || _a === void 0 ? void 0 : _a.large, size: "32px", "$isExpired": isExpired }));
};
const WorkspaceListItemName = ({ workspace, onClick, }) => {
    var _a;
    const isTabletAndBigger = useForTablet();
    const { billing } = workspace.customer || {};
    const isAdmin = workspace.userRole === 'ADMIN';
    const isGuest = workspace.userRole === 'GUEST';
    const isActive = workspace.status === 'ACTIVE';
    const isTrialing = (billing === null || billing === void 0 ? void 0 : billing.status) === 'TRIALING';
    const isExpired = workspace.status === 'INACTIVE';
    const showTrialText = !isGuest && isActive && isTrialing;
    const linksToWorkspace = isActive || isAdmin;
    return (jsxs(WorkspaceNameWrapper, { children: [jsxs(WorkspaceNameRow, { children: [linksToWorkspace ? (jsx(WorkspaceNameLink, Object.assign({ onClick: onClick, to: isGuest
                            ? routes.WORKSPACE_SHARES.create({
                                workspaceId: workspace.identifier,
                            })
                            : routes.WORKSPACE_SETTINGS.create({
                                workspaceId: workspace.identifier,
                            }) }, { children: jsx(WorkspaceName, Object.assign({ "$isExpired": isExpired }, { children: jsx(WorkspaceNameWithTruncate, { children: workspace.name }) })) }))) : (jsx(WorkspaceName, Object.assign({ "$isExpired": isExpired }, { children: jsx(WorkspaceNameWithTruncate, { children: workspace.name }) }))), ((_a = workspace.customer) === null || _a === void 0 ? void 0 : _a.ssoEnabled) && !isGuest && jsx(Sso, { children: "SSO" })] }), isTabletAndBigger && (jsxs(WorkspaceNameRow, { children: [showTrialText && billing.trialEnd && (jsxs(WorkspaceStatus, { children: [jsx(TimeAgo, { date: billing.trialEnd, formatter: trialDateFormat }), ' ', "left in trial"] })), isExpired && jsx(WorkspaceStatus, { children: "Expired" })] }))] }));
};
const WorkspaceListItemRole = ({ workspace }) => {
    const isAdmin = workspace.userRole === 'ADMIN';
    const isOwner = workspace.userIsOwner;
    const isGuest = workspace.userRole === 'GUEST';
    const isEditor = workspace.userCanEdit;
    const isPartner = workspace.userRole === 'PARTNER';
    const textRole = isGuest
        ? 'As a Guest'
        : isPartner
            ? 'As a Partner'
            : isEditor
                ? 'As an Editor'
                : 'As a Viewer';
    return (jsxs(WorkspaceRole, { children: [textRole, isAdmin && !isOwner && jsx(StyledPill, Object.assign({ variant: "admin" }, { children: "Admin" })), isOwner && jsx(StyledPill, Object.assign({ variant: "owner" }, { children: "Owner" }))] }));
};
const WorkspaceListItem = ({ membership, onLeaveWorkspace, hasAccessToWorkspace, onClick, }) => {
    const isTabletAndBigger = useForTablet();
    const { workspace } = membership;
    const workspaceInfo = isTabletAndBigger ? (jsx(WorkspaceListItemName, { workspace: workspace, onClick: onClick })) : (jsxs(WorkspaceInfoWrapper, { children: [jsx(WorkspaceListItemName, { workspace: workspace, onClick: onClick }), jsx(WorkspaceListItemRole, { workspace: workspace })] }));
    const getTooltipText = (userIsOwner, isPartner) => {
        if (isPartner)
            return 'You cannot leave a Workspace that you manage as a Sketch Partner';
        if (userIsOwner)
            return 'You cannot leave your own Workspace';
    };
    const rightCellContent = !hasAccessToWorkspace ? (jsx(SignedOut, { children: "Signed out" })) : (jsx(Tooltip, Object.assign({ content: getTooltipText(workspace.userIsOwner, workspace.userRole === 'PARTNER'), disabled: workspace.userRole !== 'PARTNER' && !workspace.userIsOwner }, { children: jsx(LeaveButton, Object.assign({ disabled: isPersonalWorkspace(workspace) ||
                workspace.userIsOwner ||
                workspace.userRole === 'PARTNER', onClick: () => onLeaveWorkspace(membership) }, { children: "Leave\u2026" })) })));
    return (jsxs(TableRow, { children: [jsx(TableCell, { children: jsxs(WorkspaceListItemNameWrapper, { children: [jsx(WorkspaceListItemLogo, { workspace: workspace }), workspaceInfo] }) }), isTabletAndBigger && (jsx(TableCell, { children: jsx(WorkspaceListItemRole, { workspace: workspace }) })), jsx(RightTableCell, { children: rightCellContent })] }));
};

export { WorkspaceListItem };

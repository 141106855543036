import styled, { CSSProperties, css, keyframes } from 'styled-components'
import { Orientation } from './types'
import { OVERLAYS_Z_INDEX } from './constants'

type ContainerProps = {
  /**
   * Hide the inspector when the camera is moving
   * We don't want to cause frame drops when the camera is moving by re-rendering React components.
   * We use css instead of unmounting the whole component because of a bug in Safari.
   * https://github.com/sketch-hq/Cloud/issues/17971
   */
  $isHidden: boolean
}
export const Container = styled.div<ContainerProps>`
  ${({ $isHidden }) => $isHidden && 'display: none;'}
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

type LayerOverlayBaseProps = {
  $width: number
  $height: number
  $x: number
  $y: number
  $hidden?: boolean
}

type LayerOverlayProps = LayerOverlayBaseProps & {
  $borderSize: number
  $color: 'red' | 'blue' | 'purple'
  $zIndex?: number
}

export const LayerOverlay = styled.div.attrs(
  ({ $x, $y, $width, $height, $hidden }: LayerOverlayProps) => {
    const style: CSSProperties = {
      transform: `translate(${$x}px, ${$y}px)`,
      width: `${$width}px`,
      height: `${$height}px`,
    }

    if ($hidden) {
      style.display = 'none'
    }

    return { style }
  }
)<LayerOverlayProps>`
  position: absolute;
  ${({ $borderSize, $color, theme }) => {
    let color = ''
    if ($color === 'red') {
      color = 'red'
    } else if ($color === 'blue') {
      color = theme.colors.inspect.guide
    } else if ($color === 'purple') {
      color = theme.colors.inspect.symbol.A
    }

    return css`
      border-width: ${$borderSize}px;
      border-color: ${color};
      border-style: solid;
    `
  }}

  ${({ $zIndex }) => $zIndex && `z-index: ${$zIndex};`}
`

type MeasurementLineProps = {
  $orientation: Orientation
  $x: number
  $y: number
  $lineSize: number
}

export const MeasurementLine = styled.div.attrs(
  ({ $x, $y, $orientation, $lineSize }: MeasurementLineProps) => {
    const style: CSSProperties = {
      transform: `translate(${$x}px, ${$y}px)`,
      width: $orientation === Orientation.Horizontal ? `${$lineSize}px` : '1px',
      height:
        $orientation === Orientation.Horizontal ? '1px' : `${$lineSize}px`,
    }
    return { style }
  }
)<MeasurementLineProps>`
  z-index: ${OVERLAYS_Z_INDEX.MEASUREMENT_LINE};
  position: absolute;
  background: rgb(5, 143, 255);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MeasurementPill = styled.div<{
  $orientation: Orientation
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 10px; /* stylelint-disable-line */
  padding: 3px 5px; /* stylelint-disable-line */
  border-radius: 6px;
  color: white;
  background: rgb(5, 143, 255);
  transform: ${({ $orientation }) =>
    $orientation === Orientation.Horizontal
      ? 'translateY(calc(-50% - 4px));'
      : 'translateX(calc(-50% - 4px));'};
`

/**
 * In order to make the loading effect designed in
 * https://www.sketch.com/s/40b54a7a-5f96-4f69-b907-ee8d2797b240/a/g1R1e0k
 *
 * We needed to make sure the border could be animated, initially it was made using
 * the CSS @property like described in https://web.dev/articles/css-border-animations#bonus-content-border-image
 * however firefox doesn't support it.
 *
 * So the work-around is:
 * - Have a element positioning the artboard
 * - Have a Clip-path limiting the area of the element visible in a new element
 * - Have the background render in a 3rd element with the correct gradient
 *
 * The 1st and 2nd elements could be merged as one, however the cursor would not be displayed when
 * in all the artboard area given the clip-path.
 */
export const LayerOverlayLoadingWrapper = styled.div.attrs(
  ({ $x, $y, $width, $height, $hidden }: LayerOverlayBaseProps) => {
    const style: CSSProperties = {
      transform: `translate(${$x}px, ${$y}px)`,
      width: `${$width}px`,
      height: `${$height}px`,
    }

    if ($hidden) {
      style.display = 'none'
    }

    return { style }
  }
)<LayerOverlayBaseProps>`
  position: absolute;
  cursor: wait;
`

export const LayerOverlayLoadingClip = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  /* 
    In this clip path the 4px is the border we wanna show
    allowing basically only the 4 pixels from each side to be
    displayed
   */
  clip-path: polygon(
    0% 0%,
    0% 100%,
    4px 100%,
    4px 4px,
    calc(100% - 4px) 4px,
    calc(100% - 4px) calc(100% - 4px),
    4px calc(100% - 4px),
    4px 100%,
    100% 100%,
    100% 0%
  );
`

const animation = keyframes`
  0%{
    transform: scale(2) rotate(0turn);
  }

  100% {
    transform: scale(2) rotate(1turn);
  }
`

export const LayerOverlayLoadingBorder = styled.div`
  content: '';
  position: absolute;

  /* This is important to make sure width and height are equal */
  aspect-ratio: 1;

  background-image: linear-gradient(
    transparent 0 25%,
    ${({ theme }) => theme.colors.inspect.guide}
  );

  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 100%;

  animation: ${animation} 3s linear infinite;

  /* This exists to make sure the corners are covered */
  transform: scale(2);
`

import { __rest } from 'tslib';
import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import React, { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { useListboxContext, ListboxOption } from '@reach/listbox';
export { ListboxInput as SelectInput, ListboxList as SelectList, ListboxOption as SelectOption, ListboxButton as SelectTrigger } from '@reach/listbox';
import { StyledChevronsUpDownIcon, NativeSelectWrapper, NativeSelect } from './NativeSelect.styles.js';
import { ListBoxStyled } from './Select.styles.js';
import '../Breakpoint/index.js';
import { Breakpoint } from '../Breakpoint/Breakpoint.js';

/**
 * Helpers
 */
const getOptions = (children) => {
    const options = [];
    React.Children.forEach(children, child => {
        var _a;
        if (child &&
            typeof child === 'object' &&
            'props' in child &&
            typeof ((_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.children) === 'string') {
            options.push({
                value: child.props.value,
                name: child.props.children,
                action: child.props.onClick,
            });
        }
    });
    return options;
};
/**
 * Returns a function that gets the currently select `<option>` value
 * This function finds the corresponding `onClick` action function and calls it
 */
const selectNativeOption = (options) => (e) => {
    var _a;
    const value = e === null || e === void 0 ? void 0 : e.target.value;
    const selectedOption = options.find(opt => opt.value === value);
    (_a = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.action) === null || _a === void 0 ? void 0 : _a.call(selectedOption);
};
const KeyboardWrapper = ({ children }) => {
    const { highlightedOptionRef } = useListboxContext();
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
    useEffect(() => {
        if (debouncedSearchTerm) {
            const el = document.querySelector(`[data-label^=${debouncedSearchTerm} i]`);
            el === null || el === void 0 ? void 0 : el.scrollIntoView({ block: 'nearest' });
            setSearchTerm('');
        }
    }, [debouncedSearchTerm]);
    useEffect(() => {
        const onKeyDown = (e) => {
            var _a;
            const lastFocusedElement = document.activeElement;
            const role = lastFocusedElement === null || lastFocusedElement === void 0 ? void 0 : lastFocusedElement.getAttribute('role');
            if (role === 'listbox') {
                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                    if (highlightedOptionRef.current) {
                        (_a = highlightedOptionRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ block: 'nearest' });
                    }
                }
                else {
                    setSearchTerm(searchTerm + e.key);
                }
            }
        };
        document.body.addEventListener('keydown', onKeyDown);
        return () => document.body.removeEventListener('keydown', onKeyDown);
    }, [highlightedOptionRef, searchTerm]);
    return jsx(Fragment, { children: children });
};
const Select = (_a) => {
    var { disabled, label, optionsForNativeSelect, placeholder, invalid, value } = _a, props = __rest(_a, ["disabled", "label", "optionsForNativeSelect", "placeholder", "invalid", "value"]);
    return (jsx(Breakpoint, Object.assign({ on: "sm" }, { children: notSmall => {
            if (notSmall || disabled) {
                return (jsxs(ListBoxStyled, Object.assign({ arrow: jsx(StyledChevronsUpDownIcon, {}), disabled: disabled, "data-invalid": invalid, value: value ? value : placeholder }, props, { "data-placeholder": placeholder && !value }, { children: [placeholder && (jsx(ListboxOption, Object.assign({ value: placeholder, "aria-disabled": "true" }, { children: placeholder }), placeholder /* We use the key to force the option to update when the placeholder changes */)), jsx(KeyboardWrapper, { children: props.children })] })));
            }
            else {
                // Mobile version - uses native html elements so that the mobile OS
                // displays their native selectors
                const options = optionsForNativeSelect || getOptions(props.children);
                const isAnOptionSelected = options.some(opt => opt.value === props.defaultValue);
                return (jsxs(NativeSelectWrapper, { children: [jsx(StyledChevronsUpDownIcon, {}), jsxs(NativeSelect, Object.assign({ "data-invalid": invalid, onChange: event => {
                                var _a;
                                selectNativeOption(options)(event);
                                (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, event.target.value);
                            } }, { children: [!optionsForNativeSelect &&
                                    (!isAnOptionSelected || label || placeholder) && (
                                // Show the label instead of the selected option
                                jsx("option", Object.assign({ value: "", disabled: true, selected: true }, { children: label || placeholder }))), options.map(({ value: optionValue, name }) => (jsx("option", Object.assign({ value: optionValue, selected: value === optionValue }, { children: name }), optionValue)))] }))] }));
            }
        } })));
};

export { Select };

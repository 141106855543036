import styled from 'styled-components';
import { Pill, Table as Table$1, TableComponents, Text } from '@sketch/components';

const CurrentPill = styled(Pill).attrs({
    variant: 'finance',
}) `
  margin-left: 4px;
`;
const Table = styled(Table$1) `
  width: 100%;
`;
const Row = TableComponents.TableRow;
const Cell = TableComponents.TableCell;
const ActiveCircle = styled.div `
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ theme: { colors } }) => colors.state.positive.A};
`;
const DeviceInfoWrapper = styled.div `
  display: flex;
`;
const DeviceInfoStatus = styled.div `
  padding: 6px; /* stylelint-disable-line scales/space */
`;
const DeviceInfoText = styled.div `
  flex: 1;
`;
const DeviceName = styled(Text.Div).attrs({
    textStyle: 'copy.primary.standard.E',
}) `
  display: flex;
  align-items: center;
`;
const DeviceLastSeen = styled(Text.Div).attrs({
    textStyle: 'copy.quaternary.standard.D',
}) ``;

export { ActiveCircle, Cell, CurrentPill, DeviceInfoStatus, DeviceInfoText, DeviceInfoWrapper, DeviceLastSeen, DeviceName, Row, Table };

import React, { useEffect, useRef } from 'react'
import { Redirect, Route, RouteProps, useLocation } from 'react-router'

import {
  GenericErrorView,
  routes,
  useUserSignedIn,
  useUserAuthorizations,
  useSignOut,
} from '@sketch/modules-common'
import { useGetInitialUserQuery } from '@sketch/gql-types'
import { LoadingState } from '@sketch/components'

interface PublicOnlyRouteProps extends RouteProps {
  redirectPath?: string
}

const PublicOnlyRoute: React.FC<PublicOnlyRouteProps> = props => {
  const { children, component, render, redirectPath, ...routeProps } = props

  const location = useLocation()
  const isSignedIn = useUserSignedIn()
  const signOut = useSignOut({
    location,
    reason: 'Error fetching InitialUser',
  })
  const { hasPersonalAuthorization } = useUserAuthorizations()

  // "useSignOut" function is not memoized making it
  // and not ideal usage inside hooks because the
  // function caller changes forcing the action
  // to be called multiple times
  const cachedSignOut = useRef(signOut)
  useEffect(() => {
    cachedSignOut.current = signOut
  }, [signOut])

  const { data, loading, error } = useGetInitialUserQuery({
    skip: !isSignedIn,
    fetchPolicy: 'network-only',
  })

  // We will use the "error" to make sure the profile is loaded
  // because we might have a old version in cache, and that will
  // be the one served
  const hasUserProfile = data?.me?.identifier && !error

  useEffect(() => {
    error && cachedSignOut.current()
  }, [error])

  const sessionExpired = !loading && !hasUserProfile

  if (!isSignedIn || sessionExpired || !hasPersonalAuthorization) {
    return <Route {...props} />
  }

  if (isSignedIn && hasUserProfile) {
    /**
     * If the user is signed-in and has valid credentials
     */
    return (
      <Redirect
        to={{
          pathname: redirectPath || routes.ENTRY.create({}),
        }}
      />
    )
  }

  return (
    <Route
      {...routeProps}
      render={() => {
        if (loading) {
          return <LoadingState />
        }

        return <GenericErrorView error="Error on PublicOnlyRoute" isInLayout />
      }}
    />
  )
}

export default PublicOnlyRoute

import { ShareListItemFragmentDoc, ShareSubscriptionStatusFragmentDoc } from '@sketch/gql-types';
import { dataIdFromObject } from '@sketch/graphql-cache';
import { ErrorHandler } from '@sketch/tracing';
import { castError } from '@sketch/utils';

const readShareListItemFromCache = ({ cache, id, }) => {
    try {
        return (cache.readFragment({
            id: dataIdFromObject({
                __typename: 'Share',
                identifier: id,
            }),
            fragment: ShareListItemFragmentDoc,
            fragmentName: 'ShareListItem',
        }) || undefined);
    }
    catch (e) {
        const err = castError(e);
        //readFragment can throw errors if there isn't sufficient data in the cache
        ErrorHandler.ignore(err, 'by design, we ignore errors when using readShareFromCache util');
        return undefined;
    }
};
const writeShareListItemToCache = ({ cache, share, }) => cache.writeFragment({
    id: dataIdFromObject(share),
    fragment: ShareListItemFragmentDoc,
    fragmentName: 'ShareListItem',
    data: share,
});
const shareSubscriptionStatus = {
    read: (cache, identifier) => {
        try {
            return cache.readFragment({
                fragment: ShareSubscriptionStatusFragmentDoc,
                fragmentName: 'ShareSubscriptionStatus',
                id: dataIdFromObject({ __typename: 'Share', identifier }),
            });
        }
        catch (e) {
            const err = castError(e);
            ErrorHandler.ignore(err);
            return null;
        }
    },
    write: (cache, share) => cache.writeFragment({
        fragment: ShareSubscriptionStatusFragmentDoc,
        fragmentName: 'ShareSubscriptionStatus',
        id: dataIdFromObject(share),
        data: share,
    }),
};
const readSharesByIdentifiers = ({ cache, shareIds, }) => {
    const shares = [];
    shareIds.forEach(identifier => {
        const share = readShareListItemFromCache({
            cache,
            id: identifier,
        });
        if (share) {
            shares.push(share);
        }
    });
    return shares;
};

export { readShareListItemFromCache, readSharesByIdentifiers, shareSubscriptionStatus, writeShareListItemToCache };

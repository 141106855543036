import React from 'react'
import { Switch, useRouteMatch } from 'react-router'

import {
  isPersonalSettingsRoute,
  isDiscoverRoute,
  routes,
  useFlag,
} from '@sketch/modules-common'
import {
  PrivateWorkspaceRoute,
  WorkspaceDocumentsView as WorkspaceDocumentsViewNew,
  WorkspaceDocumentsViewOld,
  WorkspaceDocumentsViewRoutesProps,
  PersonalAuthRoute,
  PrivateRoute,
  Updates,
  UpdatesRoutesProps,
  WorkspaceProfileEditView,
  WorkspaceSidebarLayout as WorkspaceSidebarLayoutNew,
  WorkspaceSidebarLayoutOld,
  WorkspaceSidebarLayoutExtraProps,
} from 'cloud-frontend'

import { UserSettingsView } from '@sketch/sub-app-settings'

const DiscoverView = React.lazy(
  () => import('cloud-frontend/dist/esm/modules/workspace/views/DiscoverView')
)

const WorkspaceSidebarLayoutRoutesSwitch = (
  layoutProps: WorkspaceSidebarLayoutExtraProps
) => {
  // TODO: Remove profiles FF
  // https://github.com/sketch-hq/Cloud/issues/14920
  const isProfileFFEnabled = useFlag('profiles')

  const isRefreshedUi = useFlag('ui-refresh-24')

  const WorkspaceDocumentsView = isRefreshedUi
    ? WorkspaceDocumentsViewNew
    : WorkspaceDocumentsViewOld

  return (
    <Switch>
      <PrivateWorkspaceRoute
        path={routes.WORKSPACE_DISCOVER.template()}
        render={routeProps => <DiscoverView {...layoutProps} {...routeProps} />}
      />
      <PrivateWorkspaceRoute
        exact
        path={[
          routes.WORKSPACE_PROJECT.template(),
          routes.WORKSPACE_SHARES.template(),
          routes.WORKSPACE_LIBRARIES.template(),
          routes.WORKSPACE_TRASH.template(),
          routes.WORKSPACE_DRAFTS.template(),
          routes.WORKSPACE_SHARED_WITH_ME.template(),
          routes.WORKSPACE_TRASH_PROJECT.template(),
          routes.WORKSPACE_COLLECTION.template(),
          routes.WORKSPACE_TRASH_COLLECTION.template(),
          routes.WORKSPACE_ARCHIVE.template(),
        ]}
        render={routeProps => (
          <WorkspaceDocumentsView
            {...layoutProps}
            {...(routeProps as WorkspaceDocumentsViewRoutesProps)}
          />
        )}
      />
      {isProfileFFEnabled && (
        <PrivateWorkspaceRoute
          exact
          path={routes.WORKSPACE_PROFILE_EDIT.template()}
          render={routeProps => (
            <WorkspaceProfileEditView {...layoutProps} {...routeProps} />
          )}
        />
      )}
      <PrivateWorkspaceRoute
        exact
        path={routes.WORKSPACE_TEMPLATES.template()}
        render={routeProps => (
          <WorkspaceDocumentsView
            {...layoutProps}
            {...(routeProps as WorkspaceDocumentsViewRoutesProps)}
          />
        )}
      />
      <PrivateRoute
        exact
        path={routes.UPDATES.template()}
        render={routeProps => (
          <Updates {...layoutProps} {...(routeProps as UpdatesRoutesProps)} />
        )}
      />

      <PrivateRoute
        exact
        path={routes.UPDATES_READ.template()}
        render={routeProps => (
          <Updates {...layoutProps} {...(routeProps as UpdatesRoutesProps)} />
        )}
      />
      {/* User */}
      <PersonalAuthRoute
        exact
        path={[
          routes.PERSONAL_SETTINGS.template(),
          routes.PERSONAL_SETTINGS_UNSUBSCRIBE.template(),
        ]}
      >
        <PrivateRoute
          render={routeProps => (
            <UserSettingsView
              {...layoutProps}
              {...(routeProps as WorkspaceDocumentsViewRoutesProps)}
            />
          )}
        />
      </PersonalAuthRoute>
      {/*
        Access Tokens can be created with either SSO or Personal
        credentials, so although they are under `/settings/...` we don't
        want to enforce the use of Personal credentials as it could lead to
        incorrect credentials being used when creating tokens.
      */}
      <PrivateRoute
        exact
        path={[
          routes.PERSONAL_SETTINGS_ACCESS_TOKENS.template(),
          routes.PERSONAL_SETTINGS_ACCESS_TOKENS_CREATE.template(),
          routes.APPEARANCE_SETTINGS.create({}),
        ]}
        render={routeProps => (
          <UserSettingsView
            {...layoutProps}
            {...(routeProps as WorkspaceDocumentsViewRoutesProps)}
          />
        )}
      />
    </Switch>
  )
}

export const WorkspaceSidebarLayoutRoutes = () => {
  const { path } = useRouteMatch()

  const isRefreshedUi = useFlag('ui-refresh-24')

  const WorkspaceSidebarLayout = isRefreshedUi
    ? WorkspaceSidebarLayoutNew
    : WorkspaceSidebarLayoutOld

  return (
    <WorkspaceSidebarLayout
      hideFooter={isDiscoverRoute(path)}
      hideHeader={isPersonalSettingsRoute(path)}
      render={layoutProps => (
        <WorkspaceSidebarLayoutRoutesSwitch {...layoutProps} />
      )}
    />
  )
}

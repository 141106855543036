import styled from 'styled-components';
import { breakpoint } from '@sketch/global-styles';
import { OrderedBreadcrumbs, Link, VerificationCodeInput } from '@sketch/components';

const StyledOrderedBreadcrumbs = styled(OrderedBreadcrumbs) `
  margin-top: 12px;

  ${breakpoint('base', 'xs') `
    margin-top: 0px;
  `}
`;
const Title = styled.h1 `
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.G};
  margin: 24px 0 8px 0;
`;
const Description = styled.p `
  margin-bottom: 16px;
`;
const Links = styled.div `
  display: flex;
  flex-direction: column;
`;
const StyledLink = styled(Link) `
  :not(:last-child) {
    margin-bottom: 16px;
  }
`;
const Code = styled.code `
  display: block;
  padding: 16px;
  margin-bottom: 16px;
  font-family: ${({ theme }) => theme.fonts.monospace};
  font-size: ${({ theme }) => theme.fontSizes.G};
  text-align: center;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};

  :hover {
    cursor: pointer;
  }
`;
const StyledVerificationCode = styled(VerificationCodeInput) `
  margin-bottom: 16px;
`;
const RecoveryCodes = styled.ul `
  text-align: center;
  margin: 16px 0;
  padding: 24px;
  border: 1px dashed ${({ theme }) => theme.colors.border.A};
  border-radius: ${({ theme }) => theme.radii.xlarge};
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  list-style: none;
  columns: 2;
`;
const RecoveryCode = styled.li `
  display: block;
  font-family: ${({ theme }) => theme.fonts.monospace};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.F};

  :not(:last-child, :nth-child(5)) {
    padding-bottom: 24px;
  }
`;
const Buttons = styled.div `
  display: flex;
  margin-bottom: 16px;
`;
const Columns = styled.div `
  display: flex;
  margin-bottom: 16px;

  ${breakpoint('base', 'xs') `
    flex-direction: column;
  `}
`;
const Column = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
`;
const List = styled.ol `
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 16px 0;

  ${breakpoint('base', 'xs') `
    margin-top: 24px;
    padding-left: 16px;
  `}
`;
const Item = styled.li `
  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export { Buttons, Code, Column, Columns, Description, Item, Links, List, RecoveryCode, RecoveryCodes, StyledLink, StyledOrderedBreadcrumbs, StyledVerificationCode, Title };

import { jsxs, jsx } from 'react/jsx-runtime';
import { useEditor } from '@tiptap/react';
import { SketchStarterKit } from './extensions/SketchStarterKit.js';
import './components/index.js';
import { EditorContentStyled } from './TextEditor.styles.js';
import { BubbleMenu } from './components/BubbleMenu.js';
import { FloatingMenu } from './components/FloatingMenu.js';

const TextEditor = ({ content = {}, placeholder = 'Start writing or add a block...', onBlur, editModeEnabled = true, }) => {
    const editor = useEditor({
        extensions: [SketchStarterKit.configure({ placeholder })],
        content,
        editable: editModeEnabled,
        onBlur: onBlur,
    }, [editModeEnabled]);
    /**
     * Note: a <div /> wrapper is needed in order to make the floating menu work
     * with keyboard (through tabs). Using a react fragment was fine before, but
     * at some point stopped working (I don't know the cause), basically because
     * the floating menu without this <div /> wrapper it's rendered after the
     * libraries section, but with this wrapper it's rendered just after the
     * cursor, where it should be, so tab is jumping to the menu items as expected.
     */
    return (jsxs("div", { children: [jsx(BubbleMenu, { editor: editor }), jsx(FloatingMenu, { editor: editor }), jsx(EditorContentStyled, { editor: editor })] }));
};

export { TextEditor };

import { useAnnotationQueryVariables } from 'modules/annotations/context'
import useGetAnnotations from 'modules/annotations/operations/useGetAnnotations'
import { useRef } from 'react'
import {
  GetAnnotationsQueryVariables,
  BaseAnnotationFragment,
} from '@sketch/gql-types'

export function useHasNewNotifications() {
  const hasNewNotifications = useRef<boolean>(false)
  const variables = useAnnotationQueryVariables('sidebar')
  const queryVariables = {
    ...variables,
    sort: 'NEW_FIRST',
    annotationStatus: 'ACTIVE_ONLY',
  } as GetAnnotationsQueryVariables

  const skip = !((variables.subjects?.length || 0) > 0 || variables.subject)

  const { loading, data } = useGetAnnotations({
    variables: queryVariables,
    skip,
  })

  if (!loading && data?.annotations !== undefined) {
    hasNewNotifications.current = data?.annotations.entries.some(
      (entry: BaseAnnotationFragment) =>
        Boolean(entry?.isNew) || Boolean(entry?.hasNewComments)
    )
  }

  return hasNewNotifications.current
}

import { __awaiter } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { ThemeProvider } from 'styled-components';
import { render } from 'react-dom';
import jsPDF from 'jspdf';
import '../RecoveryCodesPDFTemplate/index.js';
import { lightTheme } from '@sketch/global-styles';
import { StyledButton } from './PrintToPDFButton.styles.js';
import { RecoveryCodesPDFTemplate } from '../RecoveryCodesPDFTemplate/RecoveryCodesPDFTemplate.js';

const PrintToPDFButton = ({ codes }) => {
    const handlePrintCodes = () => __awaiter(void 0, void 0, void 0, function* () {
        const container = document.createElement('div');
        render(jsx(ThemeProvider, Object.assign({ theme: lightTheme }, { children: jsx(RecoveryCodesPDFTemplate, { recoveryCodes: codes }) })), container);
        const pdf = new jsPDF('p', 'pt', 'a4');
        pdf.html(container, {
            callback: function (pdf) {
                // Open PDF in a new tab
                const blob = pdf.output('blob');
                window.open(URL.createObjectURL(blob));
            },
        });
    });
    return (jsx(StyledButton, Object.assign({ onClick: handlePrintCodes, size: "40" }, { children: "Print Codes (PDF)" })));
};

export { PrintToPDFButton };

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useOnClickOutside, OPTION_ITEM } from '@sketch/utils';
import { useState, useRef, useEffect } from 'react';
import Measure from 'react-measure';
import '../Breakpoint/index.js';
import '../DisableBodyScroll/index.js';
import { callIfFunction } from './callIfFunction.js';
import { PageLayout, SidebarLeftContainerBackdrop, SidebarLeftContainer, ContentContainer, RightSidebarContainer, SidebarPlaceholder, SidebarBackdrop } from './SidebarLayout.styles.js';
import { useForTablet } from '../Breakpoint/Breakpoint.js';
import { DisableBodyScroll } from '../DisableBodyScroll/DisableBodyScroll.js';

const KEYS_ALLOW_ACTION = ['enter', 'space'];
const getSidebarWidth = (width, isOpen) => width === 'auto' || isOpen ? width : 0;
/**
 * COMPONENT
 * Used for document/artboard view, it provides a right sidebar to show
 * activity/inspector, also provides the left sidebar for nav (as an overlay)
 */
const SidebarLayout = props => {
    const { title, sidebarLeft, sidebarRight, header, footer, toggleSidebarRight, children, darkBackground, sidebarOnTopOfHeader, isRefreshedUi, } = props;
    // If there's no way to render the right sidebar (i.e. it's null), then
    // ensure it can never be set as open
    const isSidebarRightOpen = (props.sidebarRight === null ? false : props.isSidebarRightOpen) || false;
    const [isSidebarLeftOpen, setSidebarLeftOpen] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(getSidebarWidth('auto', isSidebarRightOpen));
    const passedProps = {
        isSidebarRightOpen,
        toggleSidebarRight,
        isSidebarLeftOpen,
        setSidebarLeftOpen,
    };
    const isTabletAndBigger = useForTablet();
    const isSmallerThanTablet = !isTabletAndBigger;
    // Close the left sidebar when is opened and clicked outside
    const sidebarLeftRef = useRef(null);
    // close sidebar left with Esc
    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            const sidebar = document.querySelector('[data-testid=side-bar-left]');
            if (e.key === 'Escape' && (sidebar === null || sidebar === void 0 ? void 0 : sidebar.contains(document.activeElement))) {
                setTimeout(() => setSidebarLeftOpen(false), 0);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // Adds the `inert` html attribute to left sidebar when it's hidden, this way
    // make the browser ignore user input events for the element, including focus
    // events and events from assistive technologies.
    // Note: we need to do it this way because React still doesn't accept this attr
    useEffect(() => {
        if (!sidebarLeftRef.current) {
            return;
        }
        if (isSidebarLeftOpen) {
            sidebarLeftRef.current.removeAttribute('inert');
        }
        else {
            sidebarLeftRef.current.setAttribute('inert', '');
        }
    }, [isSidebarLeftOpen]);
    const closeSidebar = () => {
        if (isSidebarLeftOpen) {
            // setTimeout is needed to make sure `setSidebarLeftOpen` is called after
            // any other click event listener, for example the hamburger icon click
            setTimeout(() => setSidebarLeftOpen(false), 0);
        }
    };
    useOnClickOutside(sidebarLeftRef, closeSidebar, {
        // We don't want to close the sidebar when the user is renaming a project
        includeSelectors: [OPTION_ITEM],
    });
    return (jsxs(PageLayout, Object.assign({ title: title, isSidebarRightOpen: isSidebarRightOpen, header: callIfFunction(header, passedProps), darkBackground: darkBackground }, { children: [sidebarLeft && (jsxs(Fragment, { children: [isSmallerThanTablet && isSidebarLeftOpen && (jsx(SidebarLeftContainerBackdrop, {})), jsx(SidebarLeftContainer, Object.assign({ "data-testid": "side-bar-left", ref: sidebarLeftRef, isSidebarLeftOpen: isSidebarLeftOpen, "$isRefreshedUi": isRefreshedUi }, { children: sidebarLeft }))] })), jsxs(ContentContainer, { children: [callIfFunction(children, passedProps), callIfFunction(footer, passedProps)] }), sidebarRight && (jsx(Measure, Object.assign({ onResize: ({ entry }) => {
                    entry && setSidebarWidth(entry === null || entry === void 0 ? void 0 : entry.width);
                } }, { children: ({ measureRef }) => (jsxs(Fragment, { children: [jsx(RightSidebarContainer, Object.assign({ "data-testid": "side-bar-right", "aria-hidden": !isSidebarRightOpen, ref: measureRef, "$sidebarOnTopOfHeader": sidebarOnTopOfHeader }, { children: callIfFunction(sidebarRight, passedProps) })), !isSmallerThanTablet && (jsx(SidebarPlaceholder, { style: {
                                width: getSidebarWidth(sidebarWidth, isSidebarRightOpen),
                            } })), isSmallerThanTablet && (jsx(SidebarBackdrop, Object.assign({ tabIndex: 0, onKeyPress: ({ key }) => KEYS_ALLOW_ACTION.includes(key) && (toggleSidebarRight === null || toggleSidebarRight === void 0 ? void 0 : toggleSidebarRight()), onClick: () => toggleSidebarRight === null || toggleSidebarRight === void 0 ? void 0 : toggleSidebarRight(), "data-testid": "sidebar-backdrop-close" }, { children: jsx("span", Object.assign({ className: "sr-only" }, { children: "Sidebar backdrop close" })) })))] })) }))), isSmallerThanTablet && isSidebarRightOpen && jsx(DisableBodyScroll, {})] })));
};

export { SidebarLayout };

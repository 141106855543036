import styled, { css } from 'styled-components';
import '../Segment/index.js';
import { Segment } from '../Segment/Segment.js';
import { disabledStyles } from '../Segment/Segment.styles.js';

const fieldsetReset = css `
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
`;
const Wrapper = styled.fieldset `
  ${fieldsetReset};

  display: inline-flex;
  flex-shrink: 0;
  line-height: 0;
  margin-right: 8px;
`;
const Input = styled.input `
  appearance: none;
  margin: 0;

  &:disabled + ${Segment} {
    ${disabledStyles}
  }
`;

export { Input, Wrapper };

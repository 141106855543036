import { DataProxy } from 'apollo-cache'
import { produce } from 'immer'
import {
  GetSharesDocument,
  GetSharesQuery,
  GetSharesQueryVariables,
  ShareListItemFragment,
  GetProjectSharesQuery,
  GetCollectionSharesQuery,
  GetProjectSharesDocument,
  GetCollectionSharesDocument,
  GetProjectSharesQueryVariables,
  GetCollectionSharesQueryVariables,
  GetSharesLibrariesDocument,
  GetSharesLibrariesQuery,
  GetSharesLibrariesQueryVariables,
} from '@sketch/gql-types'
import { ShareSortOrder } from 'modules/shares/hooks/useSortSettings'

type UpdatePinnedDocumentProps = {
  cache: DataProxy
  pinnedShareId: string
  shareDocumentsVariables: GetSharesQueryVariables
  sort?: ShareSortOrder
}

type UpdatePinnedDocumentProjectProps = {
  cache: DataProxy
  pinnedShareId: string
  projectSharesVariables: GetProjectSharesQueryVariables
  sort?: ShareSortOrder
}

type UpdatePinnedDocumentLibrariesProps = {
  cache: DataProxy
  pinnedShareId: string
  librariesSharesVariables: GetSharesLibrariesQueryVariables
  sort?: ShareSortOrder
}

type UpdatePinnedDocumentCollectionProps = {
  cache: DataProxy
  pinnedShareId: string
  collectionSharesVariables: GetCollectionSharesQueryVariables
  sort?: ShareSortOrder
}

export function getSortFunction(sortOrder: ShareSortOrder) {
  return (a: any, b: any) => {
    // Prioritize shares that are pinned by the current user
    if (a.pinnedByCurrentUserAt && !b.pinnedByCurrentUserAt) {
      return -1
    }
    if (!a.pinnedByCurrentUserAt && b.pinnedByCurrentUserAt) {
      return 1
    }
    if (a.pinnedByCurrentUserAt && b.pinnedByCurrentUserAt) {
      const aTime = new Date(a.pinnedByCurrentUserAt).getTime()
      const bTime = new Date(b.pinnedByCurrentUserAt).getTime()

      // Extract the suffix from the sortOrder
      const sortOrderSuffix = sortOrder.split('_').pop()

      if (sortOrderSuffix === 'ASC') {
        return aTime - bTime
      } else if (sortOrderSuffix === 'DESC') {
        return bTime - aTime
      }
    }
    // If neither or both are pinned, sort based on the sortOrder
    switch (sortOrder) {
      case 'CREATED_AT_DESC':
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      case 'CREATED_AT_ASC':
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      case 'LAST_MODIFIED_ASC':
        return a.version.createdAt
          ? new Date(a.version.createdAt).getTime() -
              new Date(b.version.createdAt).getTime()
          : 0
      case 'LAST_MODIFIED_DESC':
        return b.version.createdAt
          ? new Date(b.version.createdAt).getTime() -
              new Date(a.version.createdAt).getTime()
          : 0
      case 'NAME_ASC':
        return a.name
          .replace(/^\W+/, '')
          .localeCompare(b.name.replace(/^\W+/, ''))
      case 'NAME_DESC':
        return b.name
          .replace(/^\W+/, '')
          .localeCompare(a.name.replace(/^\W+/, ''))
      default:
        return 0
    }
  }
}

export const updateProjectShares = ({
  pinnedShareId,
  cache,
  projectSharesVariables,
  sort,
}: UpdatePinnedDocumentProjectProps) => {
  const pinnedByCurrentUserAt = new Date().toISOString()

  const existingShares = cache.readQuery<GetProjectSharesQuery>({
    query: GetProjectSharesDocument,
    variables: projectSharesVariables,
  })
  const pinnedShare = existingShares?.project.shares.entries.find(
    (entry: ShareListItemFragment) => entry.identifier === pinnedShareId
  )

  const pinned = Boolean(pinnedShare?.pinnedByCurrentUserAt)

  const newShares =
    existingShares?.project.shares.entries.filter(
      (entry: ShareListItemFragment) => entry.identifier !== pinnedShareId
    ) || []

  const newState = produce(existingShares, (draftState: any) => {
    if (pinned) {
      if (!sort) {
        return
      }
      draftState.project.shares.entries = [
        ...newShares,
        {
          ...pinnedShare,
          pinnedByCurrentUserAt: null,
        },
      ].sort(getSortFunction(sort))
    } else {
      draftState.project.shares.entries = [
        {
          ...pinnedShare,
          pinnedByCurrentUserAt,
        },
        ...newShares,
      ]
    }
  })

  cache.writeQuery({
    query: GetProjectSharesDocument,
    data: newState,
    variables: projectSharesVariables,
  })
}

export const updateCollectionShares = ({
  pinnedShareId,
  cache,
  collectionSharesVariables,
  sort,
}: UpdatePinnedDocumentCollectionProps) => {
  const pinnedByCurrentUserAt = new Date().toISOString()
  const existingShares = cache.readQuery<GetCollectionSharesQuery>({
    query: GetCollectionSharesDocument,
    variables: collectionSharesVariables,
  })

  const pinnedShare = existingShares?.project.collection.shares.entries.find(
    (entry: ShareListItemFragment) => entry.identifier === pinnedShareId
  )

  const pinned = Boolean(pinnedShare?.pinnedByCurrentUserAt)

  const newShares =
    existingShares?.project.collection.shares.entries.filter(
      (entry: ShareListItemFragment) => entry.identifier !== pinnedShareId
    ) || []

  const newState = produce(existingShares, (draftState: any) => {
    if (pinned) {
      if (!sort) {
        return
      }
      draftState.project.collection.shares.entries = [
        ...newShares,
        {
          ...pinnedShare,
          pinnedByCurrentUserAt: null,
        },
      ].sort(getSortFunction(sort))
    } else {
      draftState.project.collection.shares.entries = [
        {
          ...pinnedShare,
          pinnedByCurrentUserAt,
        },
        ...newShares,
      ]
    }
  })

  cache.writeQuery({
    query: GetCollectionSharesDocument,
    data: newState,
    variables: collectionSharesVariables,
  })
}

export const updateLibrariesShares = ({
  pinnedShareId,
  cache,
  librariesSharesVariables,
  sort,
}: UpdatePinnedDocumentLibrariesProps) => {
  const pinnedByCurrentUserAt = new Date().toISOString()

  const existingShares = cache.readQuery<GetSharesLibrariesQuery>({
    query: GetSharesLibrariesDocument,
    variables: librariesSharesVariables,
  })
  const pinnedShare = existingShares?.workspace.shares.entries.find(
    (entry: ShareListItemFragment) => entry.identifier === pinnedShareId
  )

  const pinned = Boolean(pinnedShare?.pinnedByCurrentUserAt)

  const newShares =
    existingShares?.workspace.shares.entries.filter(
      (entry: ShareListItemFragment) => entry.identifier !== pinnedShareId
    ) || []

  const newState = produce(existingShares, (draftState: any) => {
    if (pinned) {
      if (!sort) {
        return
      }
      draftState.workspace.shares.entries = [
        ...newShares,
        {
          ...pinnedShare,
          pinnedByCurrentUserAt: null,
        },
      ].sort(getSortFunction(sort))
    } else {
      draftState.workspace.shares.entries = [
        {
          ...pinnedShare,
          pinnedByCurrentUserAt,
        },
        ...newShares,
      ]
    }
  })

  cache.writeQuery({
    query: GetSharesLibrariesDocument,
    data: newState,
    variables: librariesSharesVariables,
  })
}

export function updatePinnedDocument({
  cache,
  pinnedShareId,
  shareDocumentsVariables,
  sort,
}: UpdatePinnedDocumentProps) {
  const pinnedByCurrentUserAt = new Date().toISOString()
  const existingShares = cache.readQuery<GetSharesQuery>({
    query: GetSharesDocument,
    variables: shareDocumentsVariables,
  })

  const pinnedShare = existingShares?.workspace.shares.entries.find(
    (entry: ShareListItemFragment) => entry.identifier === pinnedShareId
  )

  const newShares =
    existingShares?.workspace.shares.entries.filter(
      (entry: ShareListItemFragment) => entry.identifier !== pinnedShareId
    ) || []

  const pinned = Boolean(pinnedShare?.pinnedByCurrentUserAt)

  // The new state will sort the items by the pinnedByCurrentUserAt field
  // The newer pinned items will be on the top
  const newState = produce(existingShares, (draftState: any) => {
    if (pinned) {
      if (!sort) {
        return
      }
      draftState.workspace.shares.entries = [
        ...newShares,
        {
          ...pinnedShare,
          pinnedByCurrentUserAt: null,
        },
      ].sort(getSortFunction(sort))
    } else {
      draftState.workspace.shares.entries = [
        {
          ...pinnedShare,
          pinnedByCurrentUserAt,
        },
        ...newShares,
      ]
    }
  })

  cache.writeQuery({
    query: GetSharesDocument,
    data: newState,
    variables: shareDocumentsVariables,
  })
}

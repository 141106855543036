/**
 * mergeRefs
 *
 * code copied from https://github.com/gregberge/react-merge-refs/blob/main/src/index.tsx
 */
function mergeRefs(refs) {
    return value => {
        refs.forEach(ref => {
            if (typeof ref === 'function') {
                ref(value);
            }
            else if (ref != null) {
                const refObject = ref;
                refObject.current = value;
            }
        });
    };
}

export { mergeRefs };

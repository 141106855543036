import styled from 'styled-components';

const HelpTextContainer = styled.div `
  margin-top: 6px; /* stylelint-disable-line scales/space */
  text-align: left;
`;
const HelpText = styled.small `
  display: block;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  font-size: ${({ theme }) => theme.fontSizes.C};
`;
const ErrorList = styled.ul `
  padding: 0;
  margin: 0;
  margin-top: 4px;
  text-align: left;

  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
`;

export { ErrorList, HelpText, HelpTextContainer };

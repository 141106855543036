import { jsx, Fragment } from 'react/jsx-runtime';
import { escapeRegExp } from 'lodash';
import styled, { css } from 'styled-components';

const Mark = styled.mark(({ theme }) => css `
    background-color: ${theme.colors.sketch.E};
    color: ${theme.colors.foreground.secondary.A};
  `);
const HighlightedText = ({ search, children, }) => {
    const text = children === null || children === void 0 ? void 0 : children.toString();
    if (!text) {
        return null;
    }
    if (!search.trim()) {
        return jsx(Fragment, { children: text });
    }
    const regex = new RegExp(`(${escapeRegExp(search)})`, 'gi');
    const parts = text.split(regex);
    return (jsx(Fragment, { children: parts
            .filter(part => part)
            .map((part, i) => regex.test(part) ? (jsx(Mark, { children: part }, i)) : (jsx("span", { children: part }, i))) }));
};

export { HighlightedText };

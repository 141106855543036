import { jsx } from 'react/jsx-runtime';
import { Image as Image$1 } from '@sketch/components';
import '../../analytics/index.js';
import { useAnalytics } from '../../analytics/analytics.context.js';

/**
 * Wrapper for Image component that triggers an ananlytics event when the
 * component loads.
 */
const Image = props => {
    const { pageLoad } = useAnalytics();
    const handleImageLoad = (event) => {
        var _a;
        (_a = props.onLoad) === null || _a === void 0 ? void 0 : _a.call(props, event);
        pageLoad.observeCustomElement({
            element: event.currentTarget,
            identifier: 'image',
        });
    };
    return jsx(Image$1, Object.assign({}, props, { onLoad: handleImageLoad }));
};

export { Image };

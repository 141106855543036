import styled, { css } from 'styled-components';

const skeleton = css `
  background: ${({ theme }) => theme.colors.state.hover};
  border-radius: ${({ theme }) => theme.radii.medium};
`;
const ListHeader = styled.div(({ theme }) => css `
    padding: 8px 12px;
    line-height: 1.2;

    text-transform: uppercase;
    transition: color 125ms ease-in-out;
    user-select: none;
    font-size: ${theme.fontSizes.B};
    font-weight: ${theme.fontWeights.semibold};
    color: ${theme.colors.foreground.secondary.C};

    &:hover {
      color: ${theme.colors.foreground.secondary.A};
    }
  `);
const ListItemText = styled.span `
  font-size: 0.8125rem; /* 13px */
  line-height: 1.462; /* stylelint-disable-line scales/line-height */
  margin: 6px 12px; /* stylelint-disable-line scales/space */
`;
const ListItemIconBase = styled.svg `
  width: 16px;
  height: 16px;

  box-sizing: content-box;

  margin: 8px 12px;

  img&,
  svg& {
    /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
    /* Opacity needs to be applied to the whole object */
    color: currentColor;
    opacity: inherit;
  }

  & + ${ListItemText} {
    margin-left: 0px;
  }
`;
const ListItemIcon = styled(ListItemIconBase) `
  transition: opacity 0.2s linear;
  img&,
  svg& {
    /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
    /* Opacity needs to be applied to the whole object */
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
    opacity: 0.55;
  }
`;
const ListItemActionWrapperBase = styled.span `
  width: 16px;
  height: 16px;

  box-sizing: content-box;
  transition: opacity 0.2s linear;

  margin-left: auto;
  padding: 8px 12px;

  [role='img'] {
    display: block;
  }
`;
const ListItemActionWrapper = styled(ListItemActionWrapperBase) `
  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
`;
const listItemHoverStyle = css `
  background-color: ${({ theme }) => theme.colors.background.tertiary.B};

  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  ${ListItemIcon}, ${ListItemActionWrapper} {
    /* This color should be "Light/colors.foreground.secondary.1" but given the icon composition */
    /* Opacity needs to be applied to the whole object */
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
    opacity: 0.85;
  }
`;
const ListItemWrapperBase = styled.div `
  display: flex;
  align-items: center;
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.foreground.secondary.B};
`;
const ListItemWrapper = styled(ListItemWrapperBase) `
  background-color: none;
  transition: background-color 0.2s linear;

  &:focus,
  &:hover {
    ${listItemHoverStyle}
  }
`;
const SkeletonListItemIcon = styled(ListItemIcon) `
  ${skeleton}
`;
const SkeletonListItemText = styled(ListItemText) `
  ${skeleton}

  flex: 1;
  height: 16px;
`;

export { ListHeader, ListItemActionWrapper, ListItemActionWrapperBase, ListItemIcon, ListItemIconBase, ListItemText, ListItemWrapper, ListItemWrapperBase, SkeletonListItemIcon, SkeletonListItemText, listItemHoverStyle };

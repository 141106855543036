import * as Sentry from '@sentry/browser';
import { isProductionBuild } from '@sketch/utils';
import { NetworkStatus } from 'apollo-client';
import isEqual from 'lodash.isequal';
import { useRef } from 'react';

const apiViolationError = (initialState) => 'Violation of shouldInvalidatePrevious API contract. ' +
    `shouldInvalidatePrevious was ${initialState ? 'truthy' : 'falsy'} at first render, ` +
    `but received a ${!initialState ? 'truthy' : 'falsy'} value at later render. That should never change.`;
const useShouldInvalidatePrevious = (options, result) => {
    const { shouldInvalidatePrevious, variables } = options || {};
    const previousVariables = useRef(options === null || options === void 0 ? void 0 : options.variables);
    // capture the fact whether the shouldInvalidatePrevious was passed at first render or not
    // if it was passed, it should be always passed for this Query
    // and if it was not passed, it should never be passed
    const isShouldInvalidateTrackingOn = useRef(!!shouldInvalidatePrevious);
    if (isShouldInvalidateTrackingOn.current !== !!shouldInvalidatePrevious) {
        const message = apiViolationError(isShouldInvalidateTrackingOn.current);
        if (!isProductionBuild()) {
            throw new Error(message);
        }
        Sentry.withScope(scope => {
            scope.setExtra('options', JSON.stringify(options));
            Sentry.captureMessage(message);
        });
        return false;
    }
    if (!isShouldInvalidateTrackingOn || !shouldInvalidatePrevious) {
        return false;
    }
    if ((result.networkStatus === NetworkStatus.ready ||
        result.networkStatus === NetworkStatus.error) &&
        !isEqual(previousVariables.current, variables)) {
        previousVariables.current = variables;
    }
    const shouldInvalidateResult = (!!result.data || !!result.error) &&
        shouldInvalidatePrevious(previousVariables.current, options === null || options === void 0 ? void 0 : options.variables);
    return shouldInvalidateResult;
};

export { useShouldInvalidatePrevious };

import React, { useEffect, useRef, useCallback } from 'react'
import { InView } from 'react-intersection-observer'

import { clamp, useDebounceValue } from '@sketch/utils'
import { useFlag } from '@sketch/modules-common'
import { thumbnailForPreviewFile } from 'modules/shares/utils'

import {
  Table,
  TimeAgo,
  HighlightedText,
  TableComponents,
  LoadingPlaceholder,
  Checkbox,
} from '@sketch/components'

import {
  ShareListItemFragment,
  SharePublicationFragment,
  UnpaginatedCollectionShareFragment,
} from '@sketch/gql-types'

import {
  ImageWrapper,
  TableWrapper as TableContentWrapper,
  Name,
  TableCell,
  NameWrapper,
  Image,
  TablePlaceholderWrapper,
} from 'modules/shares/components/DocumentItem/DocumentItem.styles'
import {
  TableWrapper,
  TableCellAction,
  TableCellRegular,
  SelectAllCheck,
} from './SelectableDocumentTable.styles'

export type SelectableShare =
  | ShareListItemFragment
  | SharePublicationFragment
  | UnpaginatedCollectionShareFragment

const PAGE_SIZE = 20

const buildPlaceholderArray = (remainingSharesToLoad: number) =>
  Array(clamp(remainingSharesToLoad, 0, PAGE_SIZE))

interface DocumentTableCommonProps {
  onChange: React.ChangeEventHandler<HTMLElement>
  search: string
  name: string
  selectAll: boolean
  placeholderCount: number
}

interface DocumentListItemProps extends DocumentTableCommonProps {
  share: SelectableShare
  checked: boolean
}

const DocumentListItem = ({
  share,
  onChange,
  checked,
  search,
  name,
  selectAll,
}: DocumentListItemProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null)
  const debouncedSearch = useDebounceValue(search, 500)

  // clicking on the row will trigger the checkbox
  const handleRowClick = useCallback(() => {
    const node = checkboxRef.current
    if (node) {
      node.click()
    }
  }, [checkboxRef])

  // filter out the shares that do not match the search (for the selected ones)
  if (!share.name.toLowerCase().includes(debouncedSearch.toLowerCase())) {
    return <></>
  }

  const previewFile =
    share.__typename === 'UnpaginatedCollectionShare'
      ? share.file
      : share.version?.document?.previewFile
  const image = thumbnailForPreviewFile(previewFile)

  const handleChange = (e: React.ChangeEvent<HTMLElement>) => {
    e.preventDefault()
    // do not allow interaction when selectAll is active
    if (selectAll) {
      return
    }

    onChange(e)
  }

  return (
    <TableContentWrapper
      onClick={handleRowClick}
      data-testid="selectable-document-list-item"
    >
      <TableCell>
        <Checkbox
          value={share.identifier}
          checked={selectAll ? true : checked}
          onChange={handleChange}
          name={name}
          ref={checkboxRef}
          // do not allow checkboxes to be unticked if selectAll is active
          disabled={selectAll}
        />
      </TableCell>
      <TableCell>
        <NameWrapper>
          {image && (
            <ImageWrapper title={share.name}>
              <Image
                alt={share.name}
                src={image}
                loadingPlaceholder={<LoadingPlaceholder size="16px" />}
                height={32}
              />
            </ImageWrapper>
          )}
          <Name>
            <HighlightedText search={search}>{share.name}</HighlightedText>
          </Name>
        </NameWrapper>
      </TableCell>
      <TableCell>
        {'updatedAt' in share && share.updatedAt && (
          <TimeAgo date={share.updatedAt} />
        )}
      </TableCell>
    </TableContentWrapper>
  )
}

interface SelectableDocumenTableProps extends DocumentTableCommonProps {
  shares: SelectableShare[]
  onLoadMore: () => Promise<any>
  resetSelectedShares: () => void
  selectedItems: string[]
  placeholderCount: number
}

export const SelectableDocumentTable = ({
  shares,
  onLoadMore,
  resetSelectedShares,
  onChange,
  selectedItems,
  search,
  name,
  selectAll,
  placeholderCount,
}: SelectableDocumenTableProps) => {
  // We are still iterating on the "select all" feature and so don't want to
  // release it to customers quite yet.
  // For now we hide the checkbox behind a feature flag.
  const isSelectAllEnabled = useFlag('collections-select-all-documents')

  // Make sure the onLoadMore Reference is always the latest
  const onLoadMoreRef = useRef<() => Promise<any>>(onLoadMore)
  useEffect(() => {
    onLoadMoreRef.current = onLoadMore
  }, [onLoadMore])

  const placeholders = buildPlaceholderArray(placeholderCount)

  // Merge the items with the placeholders have them rendered together
  // This prevents the page from bumping when the placeholders are replaced
  // with actual items
  const sharesAndPlaceholders = [...shares, ...placeholders]

  // Create the InView listener warn when it starts showing the placeholders, this will load the next page
  const inViewListener =
    placeholders.length > 0 ? (
      <InView onChange={inView => inView && onLoadMoreRef.current?.()}>
        <span className="sr-only">Loading Placeholder</span>
      </InView>
    ) : null

  const handleSelectAll = (e: React.ChangeEvent<HTMLElement>) => {
    // clear selectedItems
    resetSelectedShares()
    onChange(e)
  }

  // Only show the select all checkbox if the feature flag is enabled.
  const selectAllLabel = isSelectAllEnabled ? (
    <SelectAllCheck
      checked={selectAll}
      name="selectAll"
      onChange={handleSelectAll}
      data-testid="selectable-document-selectAll"
    />
  ) : (
    ''
  )

  return (
    <TableWrapper data-testid="selectable-document-list">
      <Table
        header={[
          {
            label: selectAllLabel,
            customCell: TableComponents.TableHeaderCell,
          },
          { label: 'Document', customCell: TableComponents.TableHeaderCell },
          {
            label: 'Last Updated',
            customCell: TableComponents.TableHeaderCell,
          },
        ]}
        items={sharesAndPlaceholders}
        renderItemKey={share =>
          // if it's not a share the table will generate a index based key
          typeof share === 'object' ? share.identifier : undefined
        }
        renderItem={item => {
          if (typeof item === 'object') {
            const checked = selectedItems.includes(item.identifier)
            return (
              <DocumentListItem
                share={item}
                onChange={onChange}
                checked={checked}
                search={search}
                name={name}
                selectAll={selectAll}
                placeholderCount={placeholderCount}
              />
            )
          }

          return (
            <TablePlaceholderWrapper style={{ maxWidth: 512 }}>
              <TableCellAction>
                <Checkbox disabled />
              </TableCellAction>
              <TableCellRegular>
                <ImageWrapper />
                {inViewListener}
              </TableCellRegular>
              <TableCellRegular />
            </TablePlaceholderWrapper>
          )
        }}
        evenColumns
      />
    </TableWrapper>
  )
}

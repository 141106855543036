import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import queryString from 'query-string';
import CampaignSpecificSignUpView from './CampaignSpecificSignUpView.js';
import { useModalContext, DocumentHead } from '@sketch/components';
import { SketchLoginDeprecationModal } from '@sketch/user';
import { useSignUpFormContextForAnalytics } from './useSignUpFormContextForAnalytics.js';
import { useAnalytics, useQueryParams, routes } from '@sketch/modules-common';
import { useAcceptWorkspaceInviteMutation, useAcceptProjectMembershipInvitationMutation } from '@sketch/gql-types';
import BaseSignUpView from './BaseSignUpView.js';
import { defaultContent } from './defaultContent.js';

const SignUpView = ({ history, location, useOverrideLayoutProps, }) => {
    var _a, _b;
    const { trackEvent, trackSimpleAnalytics, removeSimpleAnalytics, trackSimpleEvent, } = useAnalytics();
    const formContextForAnalytics = useSignUpFormContextForAnalytics();
    useEffect(() => {
        trackSimpleAnalytics();
        return () => removeSimpleAnalytics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { state } = location;
    const { showModal } = useModalContext();
    const queryParams = useQueryParams();
    const maybeCampaign = queryParams['utm_campaign'];
    const linkState = { from: state === null || state === void 0 ? void 0 : state.from };
    const searchQuery = queryString.parse(((_a = linkState === null || linkState === void 0 ? void 0 : linkState.from) === null || _a === void 0 ? void 0 : _a.search) || '');
    const [acceptWorkspaceInvite] = useAcceptWorkspaceInviteMutation({
        // As a temporary solution we call the mutation and proceed without
        // handling any possible errors.
        // https://github.com/sketch-hq/Cloud/issues/10236
        onError: 'do-nothing',
    });
    const [acceptProjectInvite] = useAcceptProjectMembershipInvitationMutation({
        // As a temporary solution we call the mutation and proceed without
        // handling any possible errors.
        // https://github.com/sketch-hq/Cloud/issues/10236
        onError: 'do-nothing',
    });
    const email = decodeURIComponent((_b = queryParams === null || queryParams === void 0 ? void 0 : queryParams.email) !== null && _b !== void 0 ? _b : '');
    const onCompleted = () => {
        var _a, _b, _c, _d;
        if (queryParams.sketch === 'true') {
            showModal(SketchLoginDeprecationModal);
        }
        // Check if the user has a workspace invitation to accept
        if (((_a = linkState.from) === null || _a === void 0 ? void 0 : _a.pathname) === routes.WORKSPACE_INVITE.template() &&
            ((_b = linkState.from) === null || _b === void 0 ? void 0 : _b.search)) {
            if (searchQuery.token) {
                acceptWorkspaceInvite({
                    variables: {
                        membershipToken: searchQuery.token,
                    },
                });
            }
        }
        const projectInvitationState = state;
        if (((_c = projectInvitationState === null || projectInvitationState === void 0 ? void 0 : projectInvitationState.projectInvitation) === null || _c === void 0 ? void 0 : _c.projectMembershipIdentifier) &&
            ((_d = projectInvitationState === null || projectInvitationState === void 0 ? void 0 : projectInvitationState.projectInvitation) === null || _d === void 0 ? void 0 : _d.token)) {
            const { projectInvitation } = projectInvitationState;
            acceptProjectInvite({
                variables: {
                    input: {
                        token: projectInvitation.token,
                        projectMembershipIdentifier: projectInvitation.projectMembershipIdentifier,
                    },
                },
            });
        }
        trackEvent('SIGN UP - Sign up form success', {
            formContext: formContextForAnalytics,
        });
        trackSimpleEvent('sign_up', '/signup');
        history.push(routes.CHOOSE_YOUR_PATH.create({}));
    };
    const signUpProps = {
        email: email.length ? email : searchQuery.email,
        invitedUser: Boolean(searchQuery.token),
        onCompleted,
        useOverrideLayoutProps,
    };
    return (jsxs(Fragment, { children: [jsxs(Helmet, { children: [jsx("link", { rel: "canonical", href: "https://www.sketch.com/signup/" }), jsx("meta", { property: "og:url", content: "https://www.sketch.com/signup/" })] }), jsx(DocumentHead, { title: "Sign up: Your best work starts here" }), maybeCampaign ? (jsx(CampaignSpecificSignUpView, Object.assign({ campaign: maybeCampaign }, signUpProps))) : (jsx(BaseSignUpView, Object.assign({ content: defaultContent }, signUpProps)))] }));
};

export { SignUpView as default };

const getExpectedOperationNames = (route) => {
    switch (route.type) {
        case 'ACCEPT':
            return 'emailVerify';
        case 'SHARE_VIEW_VERSIONED':
        case 'SHARE_VIEW':
            return 'getArtboardsForDocument';
        case 'SHARE_PAGE_VIEW_VERSIONED':
        case 'SHARE_PAGE_VIEW':
            return 'getArtboardsForPage';
        case 'WORKSPACE_SETTINGS_BILLING':
            return 'getCustomer';
        case 'ARTBOARD_DETAIL_VERSIONED':
        case 'ARTBOARD_DETAIL':
        case 'ARTBOARD_REVISION_VERSIONED':
        case 'ARTBOARD_REVISION':
            return 'getDetailViewArtboard';
        case 'WORKSPACE_INVITE':
            return 'getPendingWorkspaceMembership';
        case 'WORKSPACE_CREATE':
            return 'getPersonalWorkspaceSharesCount';
        case 'WORKSPACE_DRAFTS':
        case 'WORKSPACE_PROJECT':
            return 'getProjectShares';
        case 'PROTOTYPE_PLAYER_VERSIONED':
        case 'PROTOTYPE_PLAYER':
            return 'getPresentationAssetsByArtboard';
        case 'SHARE_ACCEPT_INVITE':
            return 'getShareMembership';
        case 'ALL_DOCUMENTS':
        case 'ENTRY':
        case 'WORKSPACE_LIBRARIES':
        case 'WORKSPACE_SHARED_WITH_ME':
        case 'WORKSPACE_TEMPLATES':
        case 'WORKSPACE_SHARES':
            return 'getShares';
        case 'PERSONAL_SETTINGS':
            return 'getUserSettings';
        case 'WORKSPACE_CREATE_DONE':
            return 'getWorkspace';
        case 'WORKSPACE_SETTINGS_PEOPLE':
        case 'WORKSPACE_SETTINGS_SETTINGS':
            return 'getWorkspaceSettings';
        case 'FORGOT_PASSWORD':
        case 'RESET_PASSWORD':
        case 'SIGN_IN':
        case 'SIGN_UP':
        case 'SSO_SIGN_IN':
            // there is no need to validate these pages
            // as there are no big queries holding the page load
            return 'none';
        case 'SHARE_ACCEPT_ACCESS_REQUEST':
            return 'shareAcceptRequest';
        default:
            // For all other pages LCP values will be marked as
            // not verified
            return undefined;
    }
};
const getExpectedElementsId = (route) => {
    switch (route.type) {
        // Views showing list of artboards
        case 'SHARE_VIEW':
        case 'SHARE_VIEW_VERSIONED':
        case 'SHARE_PAGE_VIEW':
        case 'SHARE_PAGE_VIEW_VERSIONED':
            return 'image';
        // Views showing list of shares
        case 'WORKSPACE_SHARES':
        case 'WORKSPACE_SHARED_WITH_ME':
        case 'WORKSPACE_TRASH':
        case 'WORKSPACE_ARCHIVE':
        case 'WORKSPACE_DRAFTS':
        case 'WORKSPACE_LIBRARIES':
        case 'WORKSPACE_TEMPLATES':
        case 'WORKSPACE_PROJECT':
        case 'ENTRY': // this is redirected to workspace
        case 'ALL_DOCUMENTS': // legacy route
            return 'image';
        case 'ARTBOARD_DETAIL':
        case 'ARTBOARD_DETAIL_VERSIONED':
        case 'ARTBOARD_REVISION':
        case 'ARTBOARD_REVISION_VERSIONED':
            return 'blur-up-image';
        case 'PROTOTYPE_PLAYER':
        case 'PROTOTYPE_PLAYER_VERSIONED':
            return 'prototype-image';
        default:
            return undefined;
    }
};
const getRouteExpectations = (route) => {
    return {
        operations: getExpectedOperationNames(route),
        element: getExpectedElementsId(route),
    };
};

export { getRouteExpectations };

/**
 * There is an ongoing transition from `shortId: ShortID` to `identifier: UUID`
 * However, there might be a need to map from one field to another,
 * especially for already loaded types.
 *
 * e.g. While Version is still using `shortId` field as main cache key, VersionDeleted
 * provides just `identifier`, therefore there is a need to map new field to a new one
 * so that we would be able to perform cleanup.
 */
class ShortIdsMapper {
    constructor() {
        this.cacheByShortId = { Organization: {}, Version: {} };
        this.cacheByIdentifier = { Organization: {}, Version: {} };
        this.record = (object) => {
            const { identifier, shortId, __typename } = object || {};
            if (!shortId || !identifier) {
                return;
            }
            this.cacheByIdentifier[__typename][identifier] = shortId;
            this.cacheByShortId[__typename][shortId] = identifier;
        };
        this.toShortId = (typename, { identifier }) => this.cacheByIdentifier[typename][identifier];
        this.toIdentifier = (typename, { shortId }) => this.cacheByShortId[typename][shortId];
    }
}

export { ShortIdsMapper };

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import '../EmptyHeader/index.js';
import '../Embedding/index.js';
import styled from 'styled-components';
import { ErrorMessage, NotEmbedded, CenterContent, Button, IsEmbedded } from '@sketch/components';
import * as Sentry from '@sentry/browser';
import { EmptyHeader } from '../EmptyHeader/EmptyHeader.js';
import { EmbeddedErrorPage } from '../Embedding/EmbeddedErrorPage.js';

const StyledErrorMessage = styled(ErrorMessage.Generic) `
  flex: 1 0 auto;
  max-width: 500px;
  margin: auto;
`;
const GenericErrorView = ({ error, errorTitle, onClick, isInLayout, }) => {
    if (typeof error === 'string') {
        Sentry.captureException(error);
    }
    else if (error === null || error === void 0 ? void 0 : error.graphQLErrors) {
        const versionForbiddenError = error.graphQLErrors.every(e => {
            var _a;
            return [
                'USER_CANT_VIEW_OLD_SHARE_VERSIONS',
                'USER_CANT_VIEW_VERSION_HISTORY',
                'USER_CANT_VIEW_SHARE_VERSION',
            ].includes((_a = e.extensions) === null || _a === void 0 ? void 0 : _a.reason);
        });
        const authError = error.graphQLErrors.every(e => {
            var _a;
            return [
                'ACCESS_TOKEN_EXPIRED',
                'INVALID_ACCESS_TOKEN',
                'USER_NOT_LOGGED_IN',
                'TOKEN_EXPIRED',
                'NO_TOKEN',
            ].includes((_a = e.extensions) === null || _a === void 0 ? void 0 : _a.reason);
        });
        if (!versionForbiddenError && !authError) {
            Sentry.captureException(error);
        }
    }
    const onClickHandler = onClick || (() => window.location.reload());
    return (jsxs(Fragment, { children: [jsx(NotEmbedded, { children: jsxs(CenterContent, { children: [isInLayout ? null : jsx(EmptyHeader, {}), jsx(StyledErrorMessage, { title: errorTitle || 'There was an error loading this information', extra: jsx(Button, Object.assign({ variant: "primary", onClick: onClickHandler }, { children: "Refresh the page to try again" })) })] }) }), jsx(IsEmbedded, { children: jsx(EmbeddedErrorPage, { for: "unknown" }) })] }));
};

export { GenericErrorView };

import React from 'react'
import styled from 'styled-components'
import { useKey } from 'react-use'

import { MAX_ZOOM, MIN_ZOOM } from './constants'
import { ZoomTypes } from './types'
import { VerticalDivider } from 'modules/shares/components/NavbarItem'
import {
  ToggleButton,
  ToggleButtonWithExplicitControls,
  Icon,
  IconButton,
  Item,
  ItemLabel,
  ItemShortcut,
  FixedWrapper,
} from './Zoom.styles'
import {
  shortcuts,
  shortcutsText,
  keyWithoutModifier,
  keyCodeWithShift,
} from '@sketch/utils'
import { IS_EMBEDDED } from '@sketch/constants'
import {
  CheckboxInput,
  CheckmarkIcon,
  Dropdown,
  useResponsiveDropdown,
} from '@sketch/components'
import { ReactComponent as MinusIcon } from '@sketch/icons/dash-hyphen-16'
import { ReactComponent as PlusIcon } from '@sketch/icons/plus-16'

// Preset zoom levels: 1% to 1000%
const zoomRange = [0.01, 0.03, 0.05, 0.1, 0.25, 0.5, 1.0, 2.0, 4.0, 8.0, 10]

const formatZoom = (zoom: ZoomTypes) =>
  `${Math.round((zoom.factor ?? 1) * 100)}%`

interface ItemLabelCheckmarkProps {
  active?: boolean
}

const ItemLabelCheckmark: React.FC<ItemLabelCheckmarkProps> = props => (
  <>
    <CheckboxInput checked={props.active} variant="primary">
      {props.active && <CheckmarkIcon />}
    </CheckboxInput>
    <ItemLabel>{props.children}</ItemLabel>
  </>
)

interface ZoomDropdownProps {
  canZoomIn: boolean
  canZoomOut: boolean
  zoomIn: () => void
  zoomOut: () => void
  zoomToActualSize: () => void
  zoomToFit: () => void

  gridAndLayout?: GridAndLayoutTogglesProps
}

const ZoomDropdown = (props: ZoomDropdownProps) => {
  return (
    <>
      <Item onClick={props.zoomIn} disabled={!props.canZoomIn}>
        <ItemLabel>Zoom In</ItemLabel>
        {!IS_EMBEDDED && <ItemShortcut>{shortcutsText.zoomIn}</ItemShortcut>}
      </Item>

      <Item onClick={props.zoomOut} disabled={!props.canZoomOut}>
        <ItemLabel>Zoom Out</ItemLabel>
        {!IS_EMBEDDED && <ItemShortcut>{shortcutsText.zoomOut}</ItemShortcut>}
      </Item>

      <Dropdown.Divider />

      <Item onClick={props.zoomToActualSize}>
        <ItemLabel>Actual Size</ItemLabel>
        {!IS_EMBEDDED && (
          <ItemShortcut>{shortcutsText.zoomToActualSize}</ItemShortcut>
        )}
      </Item>

      <Item onClick={props.zoomToFit}>
        <ItemLabel>Fit Canvas</ItemLabel>
        {!IS_EMBEDDED && <ItemShortcut>{shortcutsText.zoomToFit}</ItemShortcut>}
      </Item>

      {props.gridAndLayout && <GridAndLayoutToggles {...props.gridAndLayout} />}
    </>
  )
}

export type ZoomProps = {
  className?: string
  zoom: ZoomTypes
  setZoom: (newZoom: ZoomTypes) => void
  minZoom?: number
  maxZoom?: number
  gridAndLayout?: GridAndLayoutTogglesProps
  /**
   * If true, the zoom controls (+ and -) will be displayed
   */
  explicitControls?: boolean
  /**
   * If true, Zoom components will use a React.fragment wrapper instead of
   * <FixedWrapper />
   */
  unstyled?: boolean
}

const Zoom: React.FC<ZoomProps> = ({
  className,
  zoom,
  setZoom,
  minZoom = MIN_ZOOM,
  maxZoom = MAX_ZOOM,
  gridAndLayout,
  explicitControls,
  unstyled,
}) => {
  // restrict zoom factors to [min, max] range
  const zoomFactors = [
    minZoom,
    ...zoomRange.filter(f => f > minZoom && f < maxZoom),
    maxZoom,
  ]

  const zoomIn = () => {
    // get current zoom level
    const current = zoom.factor ?? 1
    // find zoom value immediately above the current value
    const update = zoomFactors.find(z => z > current) ?? current
    // update zoom
    setZoom({ type: 'ZOOM_FACTOR', factor: update })
  }

  const zoomOut = () => {
    // get current zoom level
    const current = zoom.factor ?? 1
    // find zoom value immediately below the current value
    const update = zoomFactors.reverse().find(z => z < current) ?? current
    // update zoom
    setZoom({ type: 'ZOOM_FACTOR', factor: update })
  }

  const zoomToActualSize = () => setZoom({ type: 'ZOOM_FACTOR', factor: 1 })

  const zoomToFit = () => setZoom({ type: 'ZOOM_TO_FIT' })

  const canZoomIn = (zoom.factor ?? 1) < maxZoom
  const canZoomOut = (zoom.factor ?? 1) > minZoom

  useKey(keyWithoutModifier(shortcuts.zoomIn), zoomIn)
  useKey(keyWithoutModifier(shortcuts.zoomOut), zoomOut)
  useKey(keyCodeWithShift(shortcuts.zoomToActualSize), zoomToActualSize)
  useKey(keyCodeWithShift(shortcuts.zoomToFit), zoomToFit)
  useKey(keyCodeWithShift(shortcuts.toggleGrid), () => {
    gridAndLayout?.toggleGrid()
  })
  useKey(keyCodeWithShift(shortcuts.toggleLayout), () => {
    gridAndLayout?.toggleLayout()
  })

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: ZoomDropdown,
    dropdownProps: {
      canZoomIn,
      canZoomOut,
      zoomIn,
      zoomOut,
      zoomToActualSize,
      zoomToFit,
      gridAndLayout,
    },
    usePortal: true,
    placement: 'top-start',
  })

  const Wrapper = unstyled ? React.Fragment : FixedWrapper

  return (
    <Wrapper>
      {explicitControls ? (
        <ToggleButtonWithExplicitControls>
          <ToggleButton
            className={className}
            variant="strong"
            label={formatZoom(zoom)}
            fullWidth
            data-testid="zoom-toggle"
            chevrons
            {...buttonProps}
          />
          <VerticalDivider />
          <IconButton onClick={zoomOut}>
            <Icon as={MinusIcon} />
            <span className="sr-only">Zoom out</span>
          </IconButton>
          <IconButton onClick={zoomIn}>
            <Icon as={PlusIcon} />
            <span className="sr-only">Zoom in</span>
          </IconButton>
        </ToggleButtonWithExplicitControls>
      ) : (
        <ToggleButton
          className={className}
          variant="strong"
          label={formatZoom(zoom)}
          fullWidth
          data-testid="zoom-toggle"
          chevrons
          {...buttonProps}
        />
      )}

      {content}
    </Wrapper>
  )
}

type GridAndLayoutTogglesProps = {
  toggleGrid: () => void
  toggleLayout: () => void
  isGridEnabled: boolean
  isLayoutEnabled: boolean
}
function GridAndLayoutToggles({
  isGridEnabled,
  toggleGrid,
  isLayoutEnabled,
  toggleLayout,
}: GridAndLayoutTogglesProps) {
  return (
    <>
      <Dropdown.Divider />
      <Item onClick={toggleGrid}>
        <ItemLabelCheckmark active={isGridEnabled}>
          Show Grid
        </ItemLabelCheckmark>
        <ItemShortcut>{shortcutsText.toggleGrid}</ItemShortcut>
      </Item>
      <Item onClick={toggleLayout}>
        <ItemLabelCheckmark active={isLayoutEnabled}>
          Show Layout
        </ItemLabelCheckmark>
        <ItemShortcut>{shortcutsText.toggleLayout}</ItemShortcut>
      </Item>
    </>
  )
}

const StyledZoom = styled(Zoom)``

export { StyledZoom as Zoom }

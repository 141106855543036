import React from 'react'
import { Route, Switch } from 'react-router'
import Helmet from 'react-helmet'

import { routes, useQueryParams, useUserProfile } from '@sketch/modules-common'

import CommunityHeader, {
  CommunityHeaderPlaceholder,
} from '../components/CommunityHeader'

// Pages
import CommunityHomePage from '../containers/CommunityHomePage'
import CommunityListingPage from '../containers/CommunityListingPage'
import CommunitySearchPage from '../containers/CommunitySearchPage'

const CommunityHubRouteSplit = () => {
  const { search } = useQueryParams<'COMMUNITY_CANVAS'>()

  if (search) {
    return <CommunitySearchPage search={search} />
  }

  return <CommunityHomePage />
}

const CommunityView = () => {
  const { data: userData, loading } = useUserProfile()
  const user = userData?.me

  const header = loading ? (
    <CommunityHeaderPlaceholder />
  ) : (
    <CommunityHeader user={user} />
  )

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.sketch.com/community" />
        <title>The Sketch Community Canvas</title>
        <meta property="og:title" content="The Sketch Community Canvas" />
        <meta
          property="og:description"
          content="Explore a curated collection of freely-available resources, made with love by Sketch and the community."
        />
        <meta
          property="og:image"
          content="https://cdn.sketchapp.com/assets/discover/editor_visual_poster.png"
        />
      </Helmet>
      {header}
      <Switch>
        <Route
          path={routes.COMMUNITY_CANVAS.template()}
          exact
          component={CommunityHubRouteSplit}
        />
        <Route
          path={routes.COMMUNITY_CANVAS_LISTING.template()}
          exact
          component={CommunityListingPage}
        />
      </Switch>
    </>
  )
}

export default CommunityView

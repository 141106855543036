const getNameOrEmailFromMembership = (isMemberCurrentUser, name, email) => {
    if (isMemberCurrentUser) {
        if (!email) {
            return `${name} (You)`;
        }
        return `${name || email} (You)`;
    }
    return name || email || '';
};

export { getNameOrEmailFromMembership };

import { __rest } from 'tslib';
import { jsxs } from 'react/jsx-runtime';
import styled, { css } from 'styled-components';
import '../../Box/index.js';
import { withBox } from '../../Box/withBox.js';

const FormLabelBase = (_a) => {
    var { children, after } = _a, props = __rest(_a, ["children", "after"]);
    return (jsxs("label", Object.assign({}, props, { children: [children, after] })));
};
const FormLabel = styled(withBox(FormLabelBase))(({ theme }) => css `
    display: block;

    /* 6px space  does not exist in our space token array */
    /* stylelint-disable-next-line scales/space */
    margin-bottom: 6px;

    color: ${theme.colors.foreground.secondary.C};
    font-size: ${theme.fontSizes.E};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeights.C};
  `);

export { FormLabel };

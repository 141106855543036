import React from 'react'

import { routes } from '@sketch/modules-common'

import { getMemberEmail } from '../../utils'

import { useToast } from '@sketch/toasts'
import { Button, Link, Modal, ModalInjectedProps } from '@sketch/components'

import { MemberCard } from '../../components/MemberCard'

import {
  useUpdateWorkspaceMemberMutation,
  WorkspaceMembershipFragment,
} from '@sketch/gql-types'

import { ModalBody, Note, NoteTitle } from './EditorDowngradeModal.styles'
import { isBillingHidden } from '@sketch/env-config'

export interface EditorDowngradeModalProps extends ModalInjectedProps {
  member: WorkspaceMembershipFragment
}

export const EditorDowngradeModal: React.FC<EditorDowngradeModalProps> = props => {
  const { member, hideModal } = props
  const { showToast } = useToast()

  const memberName = member.user?.name
  const memberEmail = getMemberEmail(member)

  const [downgradeMember, { loading }] = useUpdateWorkspaceMemberMutation({
    variables: {
      input: {
        membershipIdentifier: member.identifier,
        accessLevel: 'VIEWER',
        /**
         * If the user is a guest we need to move it into a member,
         * if it's not a guest then we should keep the same role
         */
        role: member.role === 'GUEST' ? 'MEMBER' : undefined,
      },
    },
    onError: 'show-toast',
    onCompleted: () => {
      if (member.user?.name) {
        showToast(`${memberName} (${memberEmail}) was changed to Viewer.`)
      } else {
        showToast(`${memberEmail} was changed to Viewer.`)
      }

      hideModal()
    },
  })

  const memberNameOrEmail = member.user?.name ?? member.invite?.email

  return (
    <Modal title="Change to Viewer?" onCancel={hideModal}>
      <ModalBody>
        <p>
          Viewers can browse, inspect and comment in the web app.
          {isBillingHidden ? '' : ' Adding Viewers to a Workspace is free.'}
        </p>
        <p>
          <strong>{memberNameOrEmail}</strong> may still have private Drafts in
          this Workspace, which they will no longer be able to move or modify.
          Make sure they back up their work before you change them to a Viewer.
        </p>
        <MemberCard.BackgroundWrapper>
          <MemberCard
            name={memberName}
            email={memberEmail!}
            avatarSrc={member?.user?.avatar?.small}
          />
        </MemberCard.BackgroundWrapper>
      </ModalBody>
      {!isBillingHidden && member.role !== 'GUEST' && member.isEditor && (
        <ModalBody>
          <Note>
            <NoteTitle>No longer need this Editor Seat?</NoteTitle>
            <br />
            <p>
              Head over to your{' '}
              <Link
                variant="primary"
                to={{
                  pathname: routes.WORKSPACE_AGNOSTIC_SETTINGS_BILLING.create(
                    {}
                  ),
                }}
                onClick={() => hideModal()}
              >
                Plan Overview
              </Link>{' '}
              and schedule a change to lower the number of Editor Seats, saving
              you money on your next bill.
            </p>
          </Note>
        </ModalBody>
      )}
      <Modal.Footer>
        <Button disabled={loading} onClick={hideModal}>
          Cancel
        </Button>
        <Button
          onClick={() => downgradeMember()}
          loading={loading}
          variant="primary"
        >
          Change to Viewer
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import React from 'react'
import { useApolloClient } from 'react-apollo'

import { Button, Modal, ModalInjectedProps } from '@sketch/components'

import {
  BillingPlanFragment,
  useUndoCancelSubscriptionMutation,
} from '@sketch/gql-types'

import { dateFormat, formatPriceShort } from '@sketch/utils'
import {
  getPlanDuration,
  getPlanName,
  updateSubscriptionEndInCache,
} from '../../utils'
import { nextPricingDate } from 'modules/workspace/utils'

export interface ReactivateSubscriptionModalProps extends ModalInjectedProps {
  customerId: string
  currentPlan: BillingPlanFragment
  subscriptionEnd: string
  workspaceId: string
  isPartner?: boolean
}

export const ReactivateSubscriptionModal: React.FC<ReactivateSubscriptionModalProps> = props => {
  const {
    hideModal,
    customerId,
    currentPlan,
    subscriptionEnd,
    workspaceId,
    isPartner,
  } = props
  const client = useApolloClient()

  const [reactivate, { data, loading }] = useUndoCancelSubscriptionMutation({
    variables: { customerId },
    onError: 'show-toast',
    onCompleted: data => {
      if (!data?.undoCancelSubscription?.customer) {
        return
      }

      const { subscriptionEnd } =
        data?.undoCancelSubscription.customer.subscription || {}

      updateSubscriptionEndInCache(client, {
        workspaceId,
        subscriptionEnd,
        isPartner,
      })
    },
  })

  let content = null

  const planName = getPlanName(currentPlan.type)
  const planInterval = getPlanDuration(currentPlan.type)
  const renewalDate = dateFormat(new Date(subscriptionEnd))

  const isNewPricingEnabled = currentPlan?.product !== 'BUSINESS'

  // If the plan will be renewed before 2023 May 17th there's no need to change it
  const getPlanPrice = () => {
    if (
      isNewPricingEnabled &&
      renewalDate &&
      Date.parse(renewalDate) > nextPricingDate
    ) {
      if (currentPlan.type === 'MONTHLY') {
        return 1200
      } else if (currentPlan.type === 'YEARLY') {
        return 12000
      }
    }

    return currentPlan.invoicePrice
  }

  if (data?.undoCancelSubscription?.customer) {
    content = (
      <>
        <Modal.Header>{planName} plan reactivated</Modal.Header>
        <Modal.Body>
          <Modal.Text>
            Your <b>{planName.toLowerCase()} subscription</b> has been
            reactivated.{' '}
            <b>
              {formatPriceShort(getPlanPrice())}/{planInterval} per Editor
            </b>{' '}
            will be charged on your next renewal date, <b>{renewalDate}</b>.
            <br /> <br />
            The total cost will be based on the number of Editors on your
            workspace at the time of billing.
          </Modal.Text>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={hideModal}>Close</Button>
        </Modal.Footer>
      </>
    )
  } else {
    content = (
      <>
        <Modal.Header>
          Reactivate your {planName.toLowerCase()} plan?
        </Modal.Header>
        <Modal.Body>
          <Modal.Text>
            Your <b>{planName.toLowerCase()} subscription</b> will be
            reactivated and we’ll charge you{' '}
            <b>
              {formatPriceShort(getPlanPrice())}/{planInterval} per Editor
            </b>{' '}
            on your workspace. You won’t be charged until your next renewal date
            on <b>{renewalDate}</b>.
          </Modal.Text>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} onClick={hideModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            loading={loading}
            onClick={() => reactivate()}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </>
    )
  }

  return <Modal onCancel={hideModal}>{content}</Modal>
}

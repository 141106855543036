import styled, { css } from 'styled-components';
import '../Button/index.js';
import { ReactComponent } from '@sketch/icons/plus-16';
import { ReactComponent as ReactComponent$1 } from '@sketch/icons/minus-16';
import { ButtonUnstyled } from '../Button/ButtonUnstyled.js';

/* stylelint-disable scales/radii */
// TODO: Fix scales/radii stylelint issues - https://github.com/sketch-hq/Cloud/issues/13759
const StepperWrapper = styled.div `
  display: flex;
  align-items: center;
  flex-shrink: 0;

  border: 1px solid ${({ theme }) => theme.colors.border.A};
  border-radius: 7px;
`;
/* stylelint-enable scales/radii */
const StepperButton = styled(ButtonUnstyled) `
  padding: 10px; /* stylelint-disable-line scales/space */
  line-height: 0;

  color: ${({ theme }) => theme.colors.foreground.secondary.C};

  &:disabled {
    color: ${({ theme }) => theme.colors.state.disabled};
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }
`;
const svgStyle = css `
  width: 16px;
  height: 16px;

  color: currentColor;
`;
const StyledPlus = styled(ReactComponent) `
  ${svgStyle}
`;
const StyledMinus = styled(ReactComponent$1) `
  ${svgStyle}
`;
const StepperInput = styled.input `
  background: transparent;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  border: none;
  padding: 0;
  margin: 0 5px; /* stylelint-disable-line scales/space */
  appearance: none;
  outline: none;
  text-align: center;

  /* We need to add this rule so we can disable the Firefox browser steppers. */
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-appearance: textfield;

  /* Hide the browser steppers */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    margin: 0;
  }
`;

export { StepperButton, StepperInput, StepperWrapper, StyledMinus, StyledPlus };

import { routes, versionedRoutes } from '@sketch/modules-common';
import qs from 'query-string';
import { matchPath } from 'react-router-dom';

let _allRoutes = null;
const getAllRoutes = () => {
    if (_allRoutes)
        return _allRoutes;
    const nonVersionedRoutes = Object.entries(routes).map(([name, route]) => ({
        type: name,
        route,
        template: route.template(),
    }));
    const tempVersionedRoutes = Object.entries(versionedRoutes).map(([name, route]) => ({
        type: `${name}_VERSIONED`,
        route: route.VERSION,
        template: route.VERSION.template(),
    }));
    _allRoutes = [...nonVersionedRoutes, ...tempVersionedRoutes];
    return _allRoutes;
};
// this cache is really nearly negligible optimization
// however, we intend to use `getMatchingRoute` mostly on the initial
// load of the app where execution speeds are important.
// And it is possible that `getMatchingRoute` will be used
// from multiple locations, also having in mind that this
// function can eventually end up in a render method -
// so it makes sense to put a small performance improvement
const _cache = {};
const getMatchingRoute = (pathname) => {
    if (_cache[pathname]) {
        return _cache[pathname];
    }
    const allRoutes = getAllRoutes();
    for (const entry of allRoutes) {
        const match = matchPath(pathname, {
            path: entry.template,
            exact: true,
            strict: false,
        });
        if (match) {
            const matchedRoute = {
                match,
                pathname,
                route: entry.route,
                template: entry.template,
                type: entry.type,
            };
            _cache[pathname] = matchedRoute;
            return matchedRoute;
        }
    }
    return {
        type: 'UNKNOWN',
        match: undefined,
        pathname,
        route: undefined,
        template: undefined,
    };
};
const getKeysFromQueryParameters = (queryString) => {
    const parsedQS = qs.parse(queryString);
    return Object.keys(parsedQS);
};
/**
 * getTemplateURLWithParams
 *
 * Returns a string with a template URL matched
 * with the original URL and query string params
 * added at the end
 */
const getTemplateURLWithParams = (location, excludedRoutes = []) => {
    const queryStringKeys = getKeysFromQueryParameters(location.search);
    const queryStringKeysForURL = (queryStringKeys === null || queryStringKeys === void 0 ? void 0 : queryStringKeys.length)
        ? `?${queryStringKeys.join('&')}`
        : '';
    const matchingRoute = getMatchingRoute(location.pathname);
    const shouldNotReplaceByTemplate = excludedRoutes.find(route => route === matchingRoute.template);
    const pathname = shouldNotReplaceByTemplate
        ? matchingRoute.pathname
        : matchingRoute.template;
    return `${window.location.origin}${pathname}${queryStringKeysForURL}`;
};

export { getKeysFromQueryParameters, getMatchingRoute, getTemplateURLWithParams };

import { jsx, jsxs } from 'react/jsx-runtime';
import { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Modal, Form, Box, Input, Button } from '@sketch/components';
import { useLinkRegularAccountMutation } from '@sketch/gql-types';
import { useToast } from '@sketch/toasts';
import { useSignOut } from '@sketch/modules-common';
import { MFAModal } from './MFAModal.js';

const SIGN_IN_INITIAL_VALUES = {
    email: '',
    password: '',
};
const signInSchema = yup.object().shape({
    email: yup
        .string()
        .email('This is not a valid email')
        .required('Email can’t be blank'),
    password: yup.string().required('Password can’t be blank'),
});
const LinkNonSSOModal = ({ hideModal, workspaceName, }) => {
    const { showToast } = useToast();
    const [showTwoFactor, setShowTwoFactor] = useState(false);
    const [email, setEmail] = useState('');
    const [mfa, setMFA] = useState('');
    // this error is not exactly a ParsedError, since it has the getContext method
    const handleOnError = (error) => {
        var _a;
        if (error.includesErrorReason('MFA_REQUIRED')) {
            const token = ((_a = error
                .getContext()
                .find((c) => (c === null || c === void 0 ? void 0 : c.key) === 'mfa_token')) === null || _a === void 0 ? void 0 : _a.value) || '';
            setMFA(token);
            setShowTwoFactor(true);
            return;
        }
        showToast(error.message, 'negative');
    };
    const signOut = useSignOut({
        revokeSessions: 'none',
        removeDataFromSessions: 'all',
        reason: 'Account linked',
    });
    const [linkRegularAccount, { loading }] = useLinkRegularAccountMutation({
        onCompleted: () => {
            showToast(`SSO Account linked to ${email}`, 'positive');
            signOut();
        },
        onError: handleOnError,
    });
    const handleOnSubmit = (variables) => {
        setEmail(variables.email);
        linkRegularAccount({ variables });
    };
    if (showTwoFactor) {
        return (jsx(MFAModal, { mfa: mfa, hideModal: hideModal, email: email, handleClickBack: () => setShowTwoFactor(false) }));
    }
    return (jsx(Modal, Object.assign({ onCancel: hideModal, title: "Link Non-SSO Account" }, { children: jsx(Formik, Object.assign({ initialValues: Object.assign(Object.assign({}, SIGN_IN_INITIAL_VALUES), { email }), onSubmit: handleOnSubmit, validationSchema: signInSchema, validateOnBlur: false }, { children: formikBag => {
                const { values, errors, isSubmitting, isValid, handleChange, handleBlur, touched, } = formikBag;
                return (jsxs(Form, { children: [jsxs(Modal.Body, { children: ["Enter your non-SSO Sketch account details to switch between", ' ', jsx("strong", { children: workspaceName }), " and other Workspaces you already belong to.", jsxs(Box, Object.assign({ mt: 6 }, { children: [jsx(Form.Field, Object.assign({ label: "Email", name: "email", errorText: touched.email ? errors.email : undefined }, { children: jsx(Input, { type: "email", name: "email", onChange: handleChange, onBlur: handleBlur, value: values.email }) })), jsx(Form.Field, Object.assign({ label: "Password", name: "password", errorText: touched.password ? errors.password : undefined }, { children: jsx(Input, { type: "password", name: "password", onChange: handleChange, onBlur: handleBlur, value: values.password }) }))] }))] }), jsxs(Modal.Footer, { children: [jsx(Button, Object.assign({ onClick: hideModal }, { children: "Cancel" })), jsx(Button, Object.assign({ variant: "primary", type: "submit", onClick: () => handleOnSubmit(values), loading: isSubmitting || loading, disabled: !isValid }, { children: "Link Account" }))] })] }));
            } })) })));
};

export { LinkNonSSOModal };

import React from 'react'

import { Wrapper, StyledMemberCard } from '../Item/Item.styles'
import { Button } from '@sketch/components'
import { Member } from 'modules/workspace/types'

type ItemPartnersProps = {
  member: Member
  onRemove: (member: Member) => void
}

const ItemPartners: React.FC<ItemPartnersProps> = ({ member, onRemove }) => (
  <>
    <Wrapper data-testid="member-list-item">
      <StyledMemberCard name={member.name} email={member.email} />
      <Button variant="negative-secondary" onClick={() => onRemove(member)}>
        Remove
      </Button>
    </Wrapper>
  </>
)

export { ItemPartners }

import styled from 'styled-components';
import { breakpoint } from '@sketch/global-styles';
import { Panel, Text, WorkspaceLogo, Button, Link, FlatButton } from '@sketch/components';

const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
const StyledPanel = styled(Panel) `
  max-width: 520px;
`;
const Header = styled(Text.Span).attrs({
    textStyle: 'copy.quaternary.standard.D',
}) `
  display: inline-block;
  padding-right: 12px;
`;
const WorkspaceLineWrapper = styled.div `
  display: flex;

  :not(:last-child) {
    margin-bottom: 8px;
  }
`;
const LinkedWorkspacesAvatarsContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
const StyledWorkspaceLogo = styled(WorkspaceLogo) `
  margin-right: 12px;
`;
const WorkspaceName = styled.span `
  flex: 1;
`;
const Description = styled(Text).attrs({
    textStyle: 'copy.primary.standard.F',
}) `
  margin: 0;
  padding: 0;
  text-align: center;
`;
const StyledPanelFooter = styled(Panel.Footer) `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
  padding: 0;
  text-align: center;
`;
const StyledButton = styled(Button) `
  margin-bottom: 16px;
`;
const Container = styled.div `
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;
const LineTitle = styled(Text).attrs({
    textStyle: 'copy.tertiary.standard.E',
}) `
  display: flex;
  align-items: flex-start;
  margin: 0;
  flex: 2;

  ${breakpoint('base', 'sm') `
    margin-bottom: 8px;
  `}
`;
const LineContent = styled.div `
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 3;
`;
const Line = styled.div `
  display: flex;
  justify-items: center;
  padding: 12px 16px;
  border-radius: ${({ theme }) => theme.radii.xxlarge};

  :first-child {
    ${LineContent} {
      align-items: center;
    }
  }

  :nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.background.secondary.B};
  }

  ${breakpoint('base', 'sm') `
    flex-direction: column;
  `}
`;
const Name = styled.p `
  margin: 0;
  padding-left: 12px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
const StyledPanelBody = styled(Panel.Body) `
  max-width: 448px;
  padding: 0;
`;
const StyledLink = styled(Link) `
  margin-left: 36px;
  font-size: ${({ theme }) => theme.fontSizes.E};
`;
const StyledFlatButton = styled(FlatButton) `
  text-decoration: underline;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

export { Container, Description, Header, Line, LineContent, LineTitle, LinkedWorkspacesAvatarsContainer, Name, StyledButton, StyledFlatButton, StyledLink, StyledPanel, StyledPanelBody, StyledPanelFooter, StyledWorkspaceLogo, WorkspaceLineWrapper, WorkspaceName, Wrapper };

import { jsx } from 'react/jsx-runtime';
import { NavbarFilter, NavbarFilterOld } from './NavbarFilter.js';
import { ReactComponent } from '@sketch/icons/logo';
import { NavbarLogoWrapper, LogoBlack, NavbarBase, NavbarButton, NavbarDivider, NavbarSection, FixedWidthNavbarSection, NavbarTitle } from './Navbar.styles.js';
export { navbarTitleCss } from './Navbar.styles.js';

const NavbarLogo = ({ variant }) => (jsx(NavbarLogoWrapper, Object.assign({ "aria-haspopup": "menu", tabIndex: 0, role: "button" }, { children: variant === 'black' ? (jsx(LogoBlack, {})) : (jsx(ReactComponent, { width: "27px", height: "25px", style: { margin: 'auto' } })) })));
const Navbar = Object.assign(NavbarBase, {
    Button: NavbarButton,
    Divider: NavbarDivider,
    Section: NavbarSection,
    FixedWidthSection: FixedWidthNavbarSection,
    Logo: NavbarLogo,
    Title: NavbarTitle,
    Filter: NavbarFilter,
    FilterOld: NavbarFilterOld,
});

export { Navbar };

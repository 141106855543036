import { __rest } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import React, { createRef } from 'react';
import classNames from 'classnames';
import '../LoadingPlaceholder/index.js';
import { ButtonWrapper, StyledButton, ButtonIconWrapper as IconWrapper, LoadingWrapper } from './Button.styles.js';
import { useThemeContext } from '@sketch/global-styles';
import { LoadingPlaceholder } from '../LoadingPlaceholder/LoadingPlaceholder.js';

const isComponentWithRef = (child) => child &&
    React.isValidElement(child);
/**
 * Button style is determined by `variant` prop, there are 8 possible values:
 *
 * 1. primary
 * 2. secondary
 * 3. tertiary
 * 4. primary-untinted
 * 5. secondary-untinted
 * 6. negative
 * 7. negative-secondary
 * 8. ghost
 *
 * The tinted versions are the default ones, because we will use them quite more often than the untinted ones.
 *
 * You can also pass the colouring and size through the prop buttonStyle, like primary-small or tertiary-micro
 *
 * When dealing with Icons, you can use the `icon` prop if you will use the icon along with a text. For using just a button, please check [these][button with icon] recipes
 */
const Button = React.forwardRef(function Button(_a, ref) {
    var { loading, children, className, variant = 'secondary', size = '40', icon: Icon, contentWrapper: ContentWrapper = ButtonWrapper, type = 'button', disabled, buttonStyle } = _a, props = __rest(_a, ["loading", "children", "className", "variant", "size", "icon", "contentWrapper", "type", "disabled", "buttonStyle"]);
    const { isDarkMode } = useThemeContext();
    const childRef = createRef();
    /**
     * Renders the child component while keeping a reference to it. This way we
     * can proxy all click events sent to the button to the child.
     * @param child Child element we are about to insert
     */
    const renderChildren = (child) => {
        if (isComponentWithRef(child)) {
            return React.cloneElement(child, { ref: childRef });
        }
        return child;
    };
    const onButtonClick = () => {
        var _a, _b;
        // Send click event to the child component
        if (typeof ((_a = childRef.current) === null || _a === void 0 ? void 0 : _a.click) === 'function') {
            (_b = childRef.current) === null || _b === void 0 ? void 0 : _b.click();
        }
    };
    // unpack the buttonStyle
    let variantUnpacked = variant;
    let sizeUnpacked = size;
    if (buttonStyle) {
        const lastIndex = buttonStyle.lastIndexOf('-');
        if (lastIndex !== -1) {
            variantUnpacked = buttonStyle.slice(0, lastIndex);
            sizeUnpacked = buttonStyle.slice(lastIndex + 1);
        }
    }
    const primaryLoader = variant === 'primary' ||
        variant === 'primary-untinted' ||
        variant === 'negative';
    return (jsxs(StyledButton, Object.assign({ className: classNames('Button', className), disabled: loading || disabled, loading: loading, variant: variantUnpacked, size: sizeUnpacked, type: type, ref: ref, onClick: onButtonClick }, props, { children: [Icon && (jsx(IconWrapper, { children: jsx(Icon, { role: "img" }) })), jsx(ContentWrapper, { children: React.Children.map(children, renderChildren) }), loading && (jsx(LoadingWrapper, Object.assign({ "data-testid": "button-spinner" }, { children: jsx(LoadingPlaceholder, { primary: primaryLoader, size: "24px", dark: isDarkMode }) })))] }), window.safari && disabled ? 'disabled' : 'button'));
});

export { Button };

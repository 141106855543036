import { __rest } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import styled, { css } from 'styled-components';
import { Form } from '../Form/index.js';
import '../Box/index.js';
import '../Caption/index.js';
import { Caption as Caption$1 } from '../Caption/Caption.js';
import { Box } from '../Box/BoxSystem.js';

const RadioInput = styled.div `
  display: inline-block;
  position: relative;
  margin-right: 12px;
  cursor: pointer;
  width: 16px;
  height: 16px;

  border-radius: 50%;

  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset ${({ theme }) => theme.shadows.boxShadow.subtle};

  &:after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: white;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    opacity: 0;
  }
`;
const HiddenInput = styled.input.attrs({
    type: 'radio',
})(({ theme, disabled }) => css `
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;

    &:checked + ${RadioInput} {
      border-color: ${theme.colors.sketch.A};
      background-color: ${theme.colors.sketch.A};

      &:after {
        opacity: 1;
      }

      ${disabled &&
    css `
        border-color: ${theme.colors.border.A};
        background-color: ${theme.colors.background.secondary.B};

        &:after {
          opacity: 1;
          background-color: ${theme.colors.foreground.secondary.A};
        }
      `}
    }

    /* Use native focus ring for custom radio buttons */
    &:focus-visible + ${RadioInput} {
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color;

      /* enough space to have a 1px white line between focus ring and radio button, to give enough contrast */
      outline-offset: 3px;
    }

    &:disabled + ${RadioInput} {
      opacity: 0.5;
    }
  `);
const Container = styled(Form.Label) `
  display: flex;
  position: relative;
  align-items: flex-start;

  opacity: ${props => (props.disabled ? '0.5' : '1')};
`;
const Label = styled.span(({ $negative, theme }) => css `
    display: block;
    font-size: ${theme.fontSizes.D};
    color: ${$negative
    ? theme.colors.state.negative.A
    : theme.colors.foreground.secondary.B};
    line-height: 1.2;
  `);
const Caption = styled(Caption$1)(({ theme }) => css `
    font-size: ${theme.fontSizes.C};
    color: ${theme.colors.foreground.secondary.D};
    line-height: 1.3;
    margin: 0;
    padding-top: 4px;
  `);
const RadioButtonBase = (_a) => {
    var { label, help, className, negative, icon } = _a, props = __rest(_a, ["label", "help", "className", "negative", "icon"]);
    return (jsxs(Container, Object.assign({ disabled: props.disabled, className: className }, { children: [jsxs(Box, { children: [jsx(HiddenInput, Object.assign({}, props)), jsx(RadioInput, {})] }), jsxs(Box, { children: [jsxs(Label, Object.assign({ "$negative": negative }, { children: [icon, label] })), help && jsx(Caption, { children: help })] })] })));
};
const RadioButton = Object.assign(RadioButtonBase, {
    Input: RadioInput,
    Label: Label,
});

export { RadioButton };

import { jsxs, jsx } from 'react/jsx-runtime';
import { Input } from './DecoratedInput.styles.js';

/**
 * This is a component that simulates a disabled input.
 * It shows any component next to the "input" value so we can use it to put
 * icons.
 */
const DecoratedInput = ({ value, label, suffixComponent, }) => (jsxs(Input.Container, { children: [jsx(Input.Background, { value: "", type: "text", disabled: true }), jsxs(Input.Value.Container, { children: [jsx(Input.Value, Object.assign({ "aria-label": label }, { children: value })), suffixComponent] })] }));

export { DecoratedInput };

import { emojiAliases } from './emojiAliases.js';

const aliasesRegex = /:([\w\-_+]+):/g;
const doesEmojiExists = (emojies, key) => key in emojies;
function emojify(text) {
    return text.replace(aliasesRegex, (str, val) => {
        if (doesEmojiExists(emojiAliases, val)) {
            return emojiAliases[val];
        }
        return str;
    });
}

export { emojify };

import { jsx, Fragment } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { BREAKPOINTS_IN_PX, BREAKPOINTS_KEYS_SORTED, BREAKPOINTS_SORTED } from '@sketch/global-styles';

const Breakpoint = ({ children, on }) => (jsx(MediaQuery, Object.assign({ query: `(min-width: ${BREAKPOINTS_IN_PX[on]}px)` }, { children: children })));
/**
 *
 * SingleBreakpoint
 *
 * If you want to render something ONLY in a particular breakpoint.
 *
 * <SingleBreakpoint breakpoint="lg">
 *  <div> Only renders on LG </div>
 * </SingleBreakpoint>
 *
 * Reverse prop will do the opposite, it ONLY DOESN'T render in the selected breakpoint
 *  <SingleBreakpoint breakpoint="lg">
 *    <div> Renders in ALL breakpoints but LG </div>
 *  </SingleBreakpoint>
 */
const SingleBreakpoint = ({ children, breakpoint, reverse, }) => {
    const isBreakpointMatched = useSingleBreakpoint(breakpoint);
    const matched = reverse ? !isBreakpointMatched : isBreakpointMatched;
    if (!matched)
        return null;
    return jsx(Fragment, { children: children });
};
// All breakpoints are using min-width, so every breakpoint includes bigger
// resolutions
const useBreakpoint = (on) => useMediaQuery({ minWidth: BREAKPOINTS_IN_PX[on] });
const useSingleBreakpoint = (breakpoint) => {
    const breakpointIndex = BREAKPOINTS_KEYS_SORTED.indexOf(breakpoint);
    const breakpointMinusOnePixel = Number(BREAKPOINTS_SORTED[breakpointIndex].split('px')[0]) - 1;
    return useMediaQuery({
        minWidth: BREAKPOINTS_SORTED[breakpointIndex - 1],
        maxWidth: `${breakpointMinusOnePixel}px`,
    });
};
/** Returns true for mobile and bigger screens in portrait mode (min-width: 325px) */
const useForMobile = () => useBreakpoint('xxs');
/** Returns true for screens bigger than mobile in portrait mode (min-width: 512px) */
const useForLargerThanMobile = () => useBreakpoint('xs');
/** Returns true for tablet and bigger screens in portrait mode (min-width: 768px) */
const useForTablet = () => useBreakpoint('sm');
/** Returns true for small desktop and bigger screens in portrait mode (min-width: 1024px) */
const useForDesktop = () => useBreakpoint('md');
/** Returns true for screens bigger than 1200px in portrait mode */
const useForBigScreen = () => useBreakpoint('lg');
/** Returns true for screens bigger than 2000px in portrait mode */
const useForXXLScreen = () => useBreakpoint('xxl');
const getCurrentBreakpoint = (width) => {
    let breakpoint = 'base';
    Object.entries(BREAKPOINTS_IN_PX).forEach(breakpointEntry => {
        const breakpointName = breakpointEntry[0];
        const breakpointWidth = breakpointEntry[1];
        if (width >= breakpointWidth) {
            breakpoint = breakpointName;
        }
    });
    return breakpoint;
};
/** Retuns the current breakpoint */
const useCurrentBreakpoint = () => {
    const [breakpoint, setBreakpoint] = useState(getCurrentBreakpoint(window.innerWidth));
    useEffect(() => {
        const handleResize = () => {
            setBreakpoint(getCurrentBreakpoint(window.innerWidth));
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return breakpoint;
};

export { Breakpoint, SingleBreakpoint, useBreakpoint, useCurrentBreakpoint, useForBigScreen, useForDesktop, useForLargerThanMobile, useForMobile, useForTablet, useForXXLScreen, useSingleBreakpoint };

import { jsx, jsxs } from 'react/jsx-runtime';
import { ReactComponent } from '@sketch/icons/cross-16';
import { SKETCH_WEBSITE } from '@sketch/env-config';
import '../../routes/index.js';
import { Flex } from '@sketch/components';
import { Container, CookieCaption, CookieLink, Close } from './CookieBanner.styles.js';
import { getIsForInAppPresentation } from '../../routes/useSketchSource/useSketchSource.js';

const CookieBanner = ({ onClickClose }) => {
    const isForInAppPresentation = getIsForInAppPresentation();
    const target = isForInAppPresentation ? '_self' : '_blank';
    return (jsx(Container, { children: jsxs(Flex, Object.assign({ justifyContent: "center" }, { children: [jsxs(CookieCaption, { children: ["By using Sketch, you agree to our", ' ', jsx(CookieLink, Object.assign({ href: `${SKETCH_WEBSITE}/tos`, target: target, rel: "noopener noreferrer" }, { children: "Terms of Service" })), "."] }), jsx(Close, Object.assign({ onClick: onClickClose }, { children: jsx(ReactComponent, { width: "16px", height: "16px" }) }))] })) }));
};

export { CookieBanner as default };

import { useCallback, useEffect } from 'react';

const useDisableZoom = (isLoading) => {
    // Function to disable zoom
    const disableZoom = useCallback((e) => {
        if (isLoading) {
            e.preventDefault();
        }
    }, [isLoading]);
    useEffect(() => {
        window.addEventListener('wheel', disableZoom, { passive: false });
        window.addEventListener('touchmove', disableZoom, { passive: false });
        return () => {
            window.removeEventListener('wheel', disableZoom);
            window.removeEventListener('touchmove', disableZoom);
        };
    }, [isLoading, disableZoom]);
};

export { useDisableZoom };

import styled from 'styled-components'
import { LoadingPlaceholder, Text } from '@sketch/components'

export const Paragraph = styled.p`
  margin: 0;
`

export const MembersTitleContainer = styled.div`
  display: flex;
`

export const WorkspaceMembershipsLoading = styled(LoadingPlaceholder)`
  margin-right: 8px;
`

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin: 4px 8px 4px 0px;
  line-height: 0;
  flex-shrink: 0;
  align-self: center;
`

export const InfoTextLine = styled(Text.Div).attrs({
  textStyle: 'copy.quaternary.standard.D',
})`
  display: flex;
  margin-left: 8px;
`

export const RestrictedPublicLinksWrapper = styled.div`
  /* Section contains a padding-bottom of 16px, so it will be 24px with this wrapper */
  margin-bottom: 8px;
`

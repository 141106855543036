import { ErrorMessage as ErrorMessage$1, Title } from './ErrorMessage.js';
export { Body as ErrorMessageBody, Container as ErrorMessageContainer, Title as ErrorMessageTitle } from './ErrorMessage.js';
import { GenericError } from './GenericError.js';
export { GenericError } from './GenericError.js';

const ErrorMessage = Object.assign(ErrorMessage$1, {
    Title: Title,
    Generic: GenericError,
});

export { ErrorMessage };

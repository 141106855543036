import { jsx, jsxs } from 'react/jsx-runtime';
import Icon from '../Icon/Icon.js';
import '../ResponsiveImage/index.js';
import { useTrackEvent, useTrackEventInView } from '@sketch/modules-common';
import { Video, Container, StyledFlatButton, StyledIcon, MediaContainer, ContentContainer, Title, Content, ActionsContainer, StyledLinkButton } from './Hero.styles.js';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage.js';

/**
 * Hero
 *
 * Renders a hero that can display both video and an image
 * Used in the Discover page
 */
const Hero = ({ id, title, content, image, video, links, contentPosition = 'left', onDismiss, backgroundColor, context, }) => {
    var _a;
    // Analytics
    const { trackEvent } = useTrackEvent();
    const { ref } = useTrackEventInView(`${context} - H`, {
        type: 'load',
        target: title,
    });
    // Hero has no media, or has both video and image
    if ((!image && !video) || (!!image && !!video)) {
        return null;
    }
    const isRetinaScreen = window.devicePixelRatio > 1;
    const hasVideoContent = !image && !!video;
    const hasRetinaVideo = hasVideoContent && !!video.retina;
    // We allow a custom background color for the Hero. The color is optional and will
    // fallback to the brand.yellow. We can also define a background per video
    const heroBackgroundColor = hasVideoContent
        ? isRetinaScreen && hasRetinaVideo
            ? (_a = video.retina) === null || _a === void 0 ? void 0 : _a.backgroundColor
            : video.standard.backgroundColor
        : backgroundColor;
    const renderMediaContent = () => {
        var _a, _b;
        return hasVideoContent ? (jsx(Video, { poster: isRetinaScreen && ((_a = video === null || video === void 0 ? void 0 : video.retina) === null || _a === void 0 ? void 0 : _a.poster)
                ? video.retina.poster
                : video.standard.poster, src: isRetinaScreen && ((_b = video === null || video === void 0 ? void 0 : video.retina) === null || _b === void 0 ? void 0 : _b.src)
                ? video.retina.src
                : video.standard.src, autoPlay: true, loop: true, muted: true, controls: false, controlsList: "nofullscreen nodownload noremoteplayback", playsInline: true })) : (jsx(ResponsiveImage, { image: image }));
    };
    return (jsxs(Container, Object.assign({ ref: ref, "$contentPosition": contentPosition, "$backgroundColor": heroBackgroundColor }, { children: [onDismiss && (jsx(StyledFlatButton, Object.assign({ "data-testid": "dismiss-button", onClick: () => {
                    onDismiss(id);
                    trackEvent(`${context} - H`, {
                        type: 'dismiss',
                    });
                } }, { children: jsx(StyledIcon, { name: "close" }) }))), jsx(MediaContainer, Object.assign({ "$contentPosition": contentPosition }, { children: renderMediaContent() })), jsxs(ContentContainer, { children: [jsx(Title, { children: title }), jsx(Content, { children: content }), jsx(ActionsContainer, { children: links === null || links === void 0 ? void 0 : links.map(link => (jsx(StyledLinkButton, Object.assign({ variant: link.variant || 'primary-untinted', size: "40", icon: (link === null || link === void 0 ? void 0 : link.icon)
                                ? () => jsx(Icon, { name: link.icon, size: "16px" })
                                : undefined, href: link.url, external: true, onClick: () => trackEvent(`${context} - H`, {
                                type: 'click',
                                target: link.text,
                            }) }, { children: link.text }), link.url))) })] })] })));
};

export { Hero as default };

import { getItem } from '@sketch/utils';
import { isProduction, CURRENT_ENV } from '@sketch/env-config';

/*
  This util provides the URL Scheme that Sketch app understands,
  allowing us to interact with the Mac app

  These are the possibilities for the different environments:
  production -> sketch://sketch.cloud/s/...
  test -> sketch://test.sketch.cloud/s/...
  staging -> sketch://staging.sketch.cloud/s/...
  dev -> sketch://dev.sketch.cloud/s/...
*/
const setSketchUrlScheme = (url) => {
    const sketchAppVariant = getItem('debug.sketchAppVariant');
    if (!isEditShareURL(url)) {
        return `${getVariantProtocol(sketchAppVariant)}://${url}`;
    }
    return isProduction()
        ? `sketch://${url}`
        : `${getVariantProtocol(sketchAppVariant)}://${CURRENT_ENV}.${url}`;
};
const acceptedSketchVariants = [
    'final',
    'beta',
    'private',
    'internal',
    'experimental',
    'xcode',
    'testing',
    'feature-preview',
];
const getVariantProtocol = (sketchVariant = '') => acceptedSketchVariants.includes(sketchVariant)
    ? `sketch-${sketchVariant}`
    : 'sketch';
/**
 * This helper will check if the url belongs to the edit share deeplink. It's mostly used
 * for 'openInSketch' action
 *
 * The reason is that we only support the prefix fo `<env>.sketch.cloud in that links,
 * in any other case, prefixing them will result in a broken link even if we are logged in
 * in the same environment in web and mac apps.
 */
const isEditShareURL = (url) => url.search('sketch.cloud') !== -1;

export { getVariantProtocol, setSketchUrlScheme };

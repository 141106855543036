import { DataProxy } from 'apollo-cache'
import { removeFromPaginated, removeQuery } from '@sketch/modules-common'
import { removeTrashedShare } from 'modules/collections/operations'

export interface HandleShareDeletedProps {
  cache: DataProxy
  identifier: string
  workspaceIdentifier: string
}

export const handleShareDeleted = (props: HandleShareDeletedProps) => {
  const { cache, identifier, workspaceIdentifier } = props

  // there still can be smaller fragments in the cache
  // let's remove all of them.
  removeFromPaginated(cache, { __typename: 'Share', identifier }, key =>
    key.includes('.shares')
  )

  // Remove the share from any collections
  removeTrashedShare({ cache, identifier })

  // Remove from cache every query related with this workspace trash
  // This way the view will be refetched from scratch if needed to make sure
  // we always get the correct server information
  removeQuery(
    cache,
    key => key.includes(workspaceIdentifier) && key.includes('.workspaceTrash')
  )
}

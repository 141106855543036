import React from 'react'
import styled from 'styled-components'
import { Flex, Tooltip } from '@sketch/components'
import { useHandleCopyValue, CopiableArea } from '@sketch/modules-common'
import DirtyIconTooltip from 'modules/shares/Inspector/Sidebar/components/Style/DirtyIconTooltip'
import { Attribute, AttributeLabel, AttributeValue } from '../../components'
import { IS_SHOW_DIRTY_ATTRIBUTES_ENABLED } from '../../constants'

interface TextAttributeProps {
  label: string
  value: string
  copyValue?: string
  dirtyAttributes?: { originalValue: string; originalProperty: string }
}

const TextAttribute = ({
  label,
  value,
  copyValue,
  dirtyAttributes,
}: TextAttributeProps) => {
  // Overwrite value if dirty attributes are not enabled
  dirtyAttributes = IS_SHOW_DIRTY_ATTRIBUTES_ENABLED
    ? dirtyAttributes
    : undefined
  const {
    handleCopyValue,
    copyTooltipText,
    handleEnterCopiableArea,
    handleLeaveCopiableArea,
    copyTooltipVisible,
  } = useHandleCopyValue(copyValue ?? value)

  return (
    <CopiableArea
      onClick={handleCopyValue}
      onMouseEnter={handleEnterCopiableArea}
      onMouseLeave={handleLeaveCopiableArea}
    >
      <Tooltip
        placement="left"
        spacing="10px"
        visible={copyTooltipVisible}
        content={copyTooltipText}
      >
        <Attribute noCopy style={{ width: '100%' }}>
          <AttributeLabel>
            <Flex alignItems="center">
              {/* the extra handleEnterCopiableArea is to make sure the copy tooltip is triggered after leaving the dirty icon tooltip */}
              <div
                aria-hidden
                onMouseEnter={
                  dirtyAttributes ? handleEnterCopiableArea : undefined
                }
              >
                {label}
              </div>
              {dirtyAttributes && (
                <DirtyIconTooltip
                  originalProperty={dirtyAttributes.originalProperty}
                  originalValue={dirtyAttributes.originalValue}
                  preventLabelTooltip={handleLeaveCopiableArea}
                  isTextStyle
                />
              )}
            </Flex>
          </AttributeLabel>
          <TypefaceValue
            label={label}
            onMouseEnter={dirtyAttributes ? handleEnterCopiableArea : undefined}
          >
            {value}
          </TypefaceValue>
        </Attribute>
      </Tooltip>
    </CopiableArea>
  )
}

const TypefaceValue = styled(AttributeValue)`
  white-space: normal;
`

export default TextAttribute

import React from 'react'

import {
  StyledSubtitleTextWrapper,
  GridWrapperLeft,
  GridWrapperRight,
} from '../CollectionItem/CollectionItem.styles'

// This component shares styles with DocumentItem
import {
  Name,
  DropdownButtonWrapper,
} from 'modules/shares/components/DocumentItem/DocumentItem.styles'

import { CollectionPreviewsFragment } from '@sketch/gql-types'

interface CollectionItemGridViewEmptyStateProps {
  dropdownContent: React.ReactNode
  collection: CollectionPreviewsFragment
}

const CollectionItemGridViewEmptyState: React.FC<CollectionItemGridViewEmptyStateProps> = ({
  dropdownContent,
  collection,
}) => {
  return (
    <>
      <GridWrapperLeft />
      <GridWrapperRight>
        <Name>{collection.name}</Name>
        <StyledSubtitleTextWrapper>
          <b className="shrink">No Documents</b>
        </StyledSubtitleTextWrapper>
      </GridWrapperRight>
      {dropdownContent && (
        <DropdownButtonWrapper aria-label="Document Options">
          {dropdownContent}
        </DropdownButtonWrapper>
      )}
    </>
  )
}

export default CollectionItemGridViewEmptyState

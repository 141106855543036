import '../Box/index.js';
import '../Truncate/index.js';
import styled from 'styled-components';
import { Truncate } from '../Truncate/Truncate.js';
import { Text } from '../Box/BoxSystem.js';

const Content = styled.div `
  display: flex;
  padding: 12px;
  height: ${({ $hasWarningText }) => ($hasWarningText ? 'auto' : '64px')};
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.large};

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.background.secondary.B};
    border-radius: ${({ theme }) => theme.radii.large};
  }
`;
const Item = styled.div `
  & + & {
    margin: 0 12px;
  }

  &:nth-child(2) {
    overflow: hidden;
  }

  &:last-child {
    flex: 0 0 auto;
    margin-left: auto;
    margin-right: 0;
  }
`;
const TitleText = styled(Truncate) `
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: 0.875rem;
  line-height: 1.4;
`;
const BoldTitleText = styled(Text.Span).attrs({
    textStyle: 'copy.primary.standard.E',
}) `
  font-weight: 500;
`;
const SubtitleText = styled(Truncate) `
  width: 100%;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  font-size: ${({ theme }) => theme.fontSizes.D};
`;

export { BoldTitleText, Content, Item, SubtitleText, TitleText };

import styled, { css } from 'styled-components'

import { ButtonUnstyled, Navbar } from '@sketch/components'

import { ReactComponent as Hamburger } from '@sketch/icons/list-lines-24'
import { breakpoint } from '@sketch/global-styles'

export const HamburgerIcon = styled(Hamburger)`
  width: 24px;
  height: 24px;
`

export const HamburgerButton = styled(ButtonUnstyled)`
  padding: 8px 12px;
  line-height: 0; /* Needed to avoid extra height around the icon */

  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
`

export const SelectorSection = styled(Navbar.Section)`
  /* We dont allow this element to shrink to avoid the button being  */
  /* squashed by other menu items and effectively be hidden (in mobile) */
  flex: 0 0 auto;
  /* Prevent this section from sending it's siblings of screen */
  overflow: hidden;
`

export const SidebarWrapper = styled.section`
  display: flex;
  flex-direction: column;

  /* Limit the components width, preventing items with big name to have scroll */
  width: 100%;
  height: 100%;
`

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

export const LoadingSidebarWorkspace = styled(Navbar)`
  background: none;
`

export const Margin = styled.div`
  padding: 8px 16px 0;
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%;

  /* The z-index is needed so the navbar stays on top of the table header */
  z-index: ${({ theme }) => theme.zIndex[1]};

  ${breakpoint('sm')`
    padding: 54px 52px 0; /* stylelint-disable-line scales/space */
  `}
`

export const HeaderPortalContainer = styled.div`
  display: none;
  ${breakpoint('sm')`
    display: flex;
  `}

  min-width: 0;
  width: 100%;
`

export const HeaderSlimPortalContainer = styled.div(
  ({ theme }) => css`
    display: none;
    ${breakpoint('sm')`
      display: flex;
    `}

    align-items: center;

    min-width: 0;
    width: 100%;

    position: fixed;
    top: 0;
    z-index: ${theme.zIndex[4]};

    margin-left: -52px;
    padding-left: 32px;
  `
)

export const NavbarPortalContainerDesktop = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    min-width: 0;

    /* To align it visually (vertical) */
    margin-top: 1px; /* stylelint-disable-line scales/space */

    position: fixed;
    top: 16px;
    right: 32px;
    z-index: ${theme.zIndex[4]};
  `
)

export const NavbarPortalContainerMobile = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  overflow-x: auto;
`

import React, { useContext } from 'react'
import copy from 'copy-to-clipboard'
import { useHistory } from 'react-router'
import { usePrototypeState } from '@sketch-hq/sketch-web-renderer'

import {
  ContextMenuPassedProps,
  Dropdown,
  ModalContext,
} from '@sketch/components'
import { useToast } from '@sketch/toasts'
import { LaunchSketchAlert } from '@sketch/modules-common'
import { IS_EMBEDDED } from '@sketch/constants'

import { useVersioning } from 'modules/versioning'
import { INSPECTOR_SEGMENT } from 'modules/shares/constants'
import {
  useCreateDraftAnnotation,
  useIsAnnotationContextAvailable,
} from 'modules/annotations/hooks'

interface StatePassedContextMenu {
  artboardUUID: string | null
  pageUUID: string | null
}

interface PrototypeContextMenuProps
  extends ContextMenuPassedProps<StatePassedContextMenu> {
  initialArtboardUUID: string
}
/**
 * Menu displayed when right clicking on the prototype.
 */
export function PrototypeContextMenu(props: PrototypeContextMenuProps) {
  const {
    initialArtboardUUID,
    positionRelativeToContainer,
    additionalState,
  } = props

  const { showToast } = useToast()
  const { showModal } = useContext(ModalContext)
  const history = useHistory()

  const { currentArtboardId } = usePrototypeState()
  const { getPathname, share } = useVersioning()

  const isAnnotationsAvailable = useIsAnnotationContextAvailable()
  const handleDraftAnnotationEvent = useCreateDraftAnnotation(share.identifier)
  const artboardUUID = additionalState.artboardUUID || initialArtboardUUID

  function handleCopyLinkToArtboard() {
    const currentPathname = getPathname({
      routeKey: 'PROTOTYPE_PLAYER',
      routeParams: {
        shareID: share.identifier,
        prototypeArtboardUUID: currentArtboardId,
        currentArtboardUUID: currentArtboardId,
      },
    })

    const link = `${window.location.origin}${currentPathname}`
    copy(link)
    showToast('Link copied')
  }

  function handleEdit() {
    const pageUUID = additionalState.pageUUID || undefined

    showModal(LaunchSketchAlert, {
      shareId: share.identifier,
      pageUUID,
      action: 'openInSketch',
    })
  }

  function handleInspectArtboard() {
    const currentPathname = getPathname({
      routeKey: 'ARTBOARD_DETAIL_UUID',
      routeParams: {
        shareID: share.identifier,
        artboardUUID: artboardUUID,
      },
    })

    history.push(`${currentPathname}#${INSPECTOR_SEGMENT}`)
  }

  const handleAddAnnotation = () => {
    handleDraftAnnotationEvent(
      {
        clientX: positionRelativeToContainer.x,
        clientY: positionRelativeToContainer.y,
      },
      true
    )
  }

  const hasArtboardUUID = artboardUUID !== null

  const showInspectArtboard =
    share.userCanInspect && hasArtboardUUID && !IS_EMBEDDED

  return (
    <>
      <Dropdown.Header>PROTOTYPE</Dropdown.Header>

      {artboardUUID !== null && (
        <Dropdown.Item onClick={handleCopyLinkToArtboard}>
          Copy Link
        </Dropdown.Item>
      )}

      {showInspectArtboard && (
        <Dropdown.Item onClick={handleInspectArtboard}>
          View Artboard
        </Dropdown.Item>
      )}

      {(share.commentsEnabled || share.userCanEdit) && <Dropdown.Divider />}

      {additionalState.artboardUUID &&
        isAnnotationsAvailable &&
        share.commentsEnabled && (
          <Dropdown.Item onClick={handleAddAnnotation}>
            Add Comment…
          </Dropdown.Item>
        )}

      {share.userCanEdit && (
        <Dropdown.Item onClick={handleEdit}>Open in Mac App…</Dropdown.Item>
      )}
    </>
  )
}

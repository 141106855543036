import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useRef } from 'react';
import styled from 'styled-components';
import '../Segment/index.js';
import '../Tooltip/index.js';
import { Input, Wrapper } from './NavigationSelector.styles.js';
import { useKey } from 'react-use';
import { keyWithoutModifier } from '@sketch/utils';
import { Tooltip, TooltipShortcut } from '../Tooltip/Tooltip.js';
import { Segment } from '../Segment/Segment.js';

function NavigationSelectorItem(props) {
    const labelRef = useRef(null);
    const { name, hideTooltip, onChange, selected, item } = props;
    useKey(keyWithoutModifier('Enter'), () => {
        // We need to check if the label is focused because the key event is global
        const isFocused = labelRef.current === document.activeElement;
        isFocused && (onChange === null || onChange === void 0 ? void 0 : onChange(item));
    });
    return (jsx("label", Object.assign({ ref: labelRef, tabIndex: item.value === selected ? undefined : 0 }, { children: jsxs(Tooltip, Object.assign({ placement: "bottom-end", disabled: hideTooltip, content: jsxs(Fragment, { children: ["View as ", item.value.toLowerCase(), item.shortcut && (jsxs(TooltipShortcut, { children: ["Press ", item.shortcut] }))] }) }, { children: [jsx(Input, { className: "sr-only", name: name, value: item.value, type: "radio", onChange: () => onChange === null || onChange === void 0 ? void 0 : onChange(item), checked: item.value === selected }), jsx(Segment, { type: item.type, label: item.label, icon: item.type === 'icon' ? item.icon : undefined, active: item.value === selected })] })) })));
}
const NavigationSelector = styled(function NavigationSelectorProps(props) {
    const { className, disabled, hideTooltip = false, name, items, onChange, selected, } = props;
    return (jsxs(Wrapper, Object.assign({ className: className, disabled: disabled }, { children: [jsx("legend", Object.assign({ className: "sr-only" }, { children: name })), items.map((item, index) => (jsx(NavigationSelectorItem, { name: name, item: item, hideTooltip: hideTooltip, onChange: onChange, selected: selected }, index)))] })));
}) ``;

export { NavigationSelector };

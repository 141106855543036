import { jsxs, jsx } from 'react/jsx-runtime';
import { useState, useRef, useContext } from 'react';
import { ModalContext, Modal, UserChip, Button } from '@sketch/components';
import { SignInWithPasswordForm, MfaVerificationCodeModal } from 'cloud-frontend';
import { UserChipContainer, Label } from './SignInWithCredentialsModal.styles.js';
import { useUserProfile } from '@sketch/modules-common';

const SignInWithCredentialsModal = props => {
    const { hideModal, name, email, avatarSrc, onSuccess } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const submitButtonRef = useRef(null);
    const { showModal } = useContext(ModalContext);
    const { data } = useUserProfile();
    const isMfaEnabled = (data === null || data === void 0 ? void 0 : data.me.mfaState) === 'ENABLED';
    return (jsxs(Modal, Object.assign({ title: 'Create a Token', onCancel: hideModal }, { children: [jsxs(Modal.Body, { children: [jsx("span", { children: "Enter your password to create personal access tokens." }), jsxs(UserChipContainer, { children: [jsx(Label, { children: "Sketch Account" }), jsx(UserChip, { name: name, email: email, avatarSrc: avatarSrc })] }), jsx(SignInWithPasswordForm, { submitButtonRef: submitButtonRef, email: email, onSubmit: () => setIsLoading(true), onCompleted: data => {
                            hideModal();
                            if (isMfaEnabled && data.signIn.__typename === 'MFACredentials') {
                                showModal(MfaVerificationCodeModal, {
                                    mfaToken: data.signIn.mfaToken,
                                    onSuccess: onSuccess,
                                });
                            }
                            else {
                                onSuccess();
                            }
                        }, onError: () => setIsLoading(false), onDisableSubmit: disabled => setDisableSubmit(disabled) })] }), jsxs(Modal.Footer, { children: [jsx(Button, Object.assign({ variant: "secondary", disabled: isLoading, onClick: () => hideModal() }, { children: "Cancel" })), jsx(Button, Object.assign({ variant: "primary", disabled: isLoading || disableSubmit, loading: isLoading, onClick: () => { var _a; return (_a = submitButtonRef.current) === null || _a === void 0 ? void 0 : _a.click(); } }, { children: "Sign In" }))] })] })));
};

export { SignInWithCredentialsModal as default };

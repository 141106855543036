import React from 'react'

import { BillingPlanFragment, PaymentDetailsFragment } from '@sketch/gql-types'

import { formatPriceShort } from '@sketch/utils'
import { nextPricingDate } from 'modules/workspace/utils'

import { Spinner, AsyncButton, pluralize } from '@sketch/components'

import {
  SummaryHeaderWrapper,
  SummaryLineWrapper,
  SummaryTotalParagraph,
  BoldParagraph,
  Paragraph,
  PaymentLogo,
  PaymentInfoWrapper,
  Strong,
  PriceWrapper,
  AppliedDiscountRow,
  DiscountMessage,
  DiscountWrapper,
} from './Summary.styles'

import {
  getPlanDuration,
  isPaymentMethodCard,
  isPaymentMethodInvoice,
} from '../../utils'

type Price = number | 'Free'

const formatPrice = (price?: Price) => {
  if (price === 'Free') {
    return 'Free'
  }

  // TODO: review this <create GitHub ticket>
  return formatPriceShort(price!, { trimDecimalIfZero: false })
}

/**
 * Renders a small loading spinner for the summary prices
 */
const InlineLoading = () => (
  <>
    <Spinner size="1em" primary />
    &nbsp;&nbsp;
  </>
)

interface SummaryHeaderProps {
  plan: BillingPlanFragment
  paymentMethod?: React.ReactNode
  nextBillingCycleDate?: string
}

/**
 * SummaryHeader
 *
 * Renders the header for the Summary
 */
export const SummaryHeader: React.FC<SummaryHeaderProps> = ({
  plan,
  paymentMethod,
  nextBillingCycleDate,
}) => {
  const isNewPricingEnabled = plan?.product !== 'BUSINESS'

  const getPricePerSeat = () => {
    // If the plan will be renewed before 2023 May 17th there's no need to change it
    if (
      isNewPricingEnabled &&
      nextBillingCycleDate &&
      Date.parse(nextBillingCycleDate) > nextPricingDate
    ) {
      if (plan.type === 'MONTHLY') {
        return '$12.00'
      } else if (plan.type === 'YEARLY') {
        return '$120.00'
      }
    }

    return formatPrice(plan.invoicePrice)
  }

  return (
    <SummaryHeaderWrapper>
      <div>
        <BoldParagraph>Summary</BoldParagraph>
        <Paragraph>
          <Strong>{getPricePerSeat()}</Strong> per Seat, per{' '}
          {getPlanDuration(plan.type)}
        </Paragraph>
      </div>
      {paymentMethod}
    </SummaryHeaderWrapper>
  )
}

interface SummaryLineProps {
  description: React.ReactNode
  value?: Price
  loading?: boolean
  'data-testid'?: string
  variant?: 'default' | 'partner'
}

/**
 * SummaryLine
 *
 * Renders a summary parcel
 */
export const SummaryLine: React.FC<SummaryLineProps> = props => {
  const { description, value, loading, 'data-testid': dataTestId } = props

  return (
    <SummaryLineWrapper>
      <Paragraph>{description}</Paragraph>
      <Paragraph data-testid={dataTestId}>
        {loading && <InlineLoading />}
        {value !== undefined && <span>{formatPrice(value)}</span>}
      </Paragraph>
    </SummaryLineWrapper>
  )
}

export const SummaryTotalLine: React.FC<SummaryLineProps> = props => {
  const {
    description,
    value,
    loading,
    'data-testid': dataTestId,
    variant,
  } = props

  return (
    <SummaryLineWrapper>
      <BoldParagraph>{description}</BoldParagraph>
      <PriceWrapper>
        <BoldParagraph data-testid={dataTestId}>
          {loading && <InlineLoading />}
          {value !== undefined && <span>{formatPrice(value)}</span>}
        </BoldParagraph>
        {!!value && (
          <>
            <Paragraph>Total exclusive of Tax</Paragraph>
            {variant === 'partner' && (
              <Paragraph>and Partner discounts</Paragraph>
            )}
          </>
        )}
      </PriceWrapper>
    </SummaryLineWrapper>
  )
}

interface SummaryErrorProps {
  refetch: () => Promise<any>
}

/**
 * SummaryError
 *
 * Renders the an error state with a retry button for the
 * summary
 */
export const SummaryError: React.FC<SummaryErrorProps> = props => (
  <SummaryLineWrapper>
    <Paragraph>
      There was an error updating <br />
      the summary information.
    </Paragraph>
    <AsyncButton variant="primary" onClick={props.refetch}>
      <>Try Again</>
    </AsyncButton>
  </SummaryLineWrapper>
)

interface SummaryTotalDescribedProps {
  credit?: number
  charge?: number
}

/**
 * SummaryTotalDescribed
 *
 * Renders an text with the charged amount described
 */
export const SummaryTotalDescribed: React.FC<SummaryTotalDescribedProps> = props => {
  const { credit, charge } = props

  if ((credit || 0) > 0 && (charge || 0) > 0) {
    return (
      <SummaryTotalParagraph>
        {formatPrice(credit)} from Credit Balance, {formatPrice(charge)} from
        card.
      </SummaryTotalParagraph>
    )
  }

  return null
}

interface SummaryPaymentMethodProps {
  paymentType: 'CREDITS' | 'CREDITS-CHARGE_METHOD' | 'CHARGE_METHOD'
  chargeMethod: PaymentDetailsFragment
}

/**
 * SummaryPaymentMethod
 *
 * Renders the payments card logos according with the payment type supplied
 */
export const SummaryPaymentMethod: React.FC<SummaryPaymentMethodProps> = props => {
  const { paymentType, chargeMethod } = props
  let paymentContent = null

  if (paymentType === 'CREDITS') {
    paymentContent = (
      <>
        <PaymentLogo brand="credit" />
        &nbsp; Credits
      </>
    )
  } else if (
    paymentType === 'CREDITS-CHARGE_METHOD' &&
    isPaymentMethodCard(chargeMethod)
  ) {
    paymentContent = (
      <>
        <PaymentLogo brand="credit" />
        &nbsp;
        <PaymentLogo brand={chargeMethod.brand} />
      </>
    )
  } else if (
    paymentType === 'CREDITS-CHARGE_METHOD' &&
    isPaymentMethodInvoice(chargeMethod)
  ) {
    paymentContent = (
      <>
        <PaymentLogo brand="credit" />
        &nbsp;
        <PaymentLogo brand="invoice" />
      </>
    )
  } else if (
    paymentType === 'CHARGE_METHOD' &&
    isPaymentMethodCard(chargeMethod)
  ) {
    paymentContent = (
      <>
        <PaymentLogo brand={chargeMethod.brand} />
        &nbsp; ···{chargeMethod.last4Digits}
      </>
    )
  } else if (
    paymentType === 'CHARGE_METHOD' &&
    isPaymentMethodInvoice(chargeMethod)
  ) {
    paymentContent = (
      <>
        <PaymentLogo brand="invoice" />
        &nbsp; Invoice
      </>
    )
  }

  return (
    paymentContent && <PaymentInfoWrapper>{paymentContent}</PaymentInfoWrapper>
  )
}

interface SummaryDiscountProps {
  appliedDiscount: {
    code: string
    price: number
    percentOff: number | null
  }
  isAlreadyApplied?: boolean
  variant?: 'default' | 'partner'
}

type SummaryDiscountLineTextProps = Pick<
  SummaryDiscountProps,
  'appliedDiscount' | 'variant'
>

/**
 * SummaryDiscountLineText
 * Renders the discount line for regular or partner workspaces in the summary
 */
const SummaryDiscountLineText: React.FC<SummaryDiscountLineTextProps> = ({
  variant,
  appliedDiscount,
}) => {
  if (variant === 'partner') {
    return (
      <SummaryLineWrapper>
        <Paragraph>
          Sketch Partner Discount: {appliedDiscount.percentOff}% Off
        </Paragraph>
        <Paragraph>{formatPrice(-appliedDiscount.price)}</Paragraph>
      </SummaryLineWrapper>
    )
  }

  return (
    <AppliedDiscountRow>
      <Paragraph>{`${appliedDiscount.percentOff}% Off (${appliedDiscount.code})`}</Paragraph>
      <Paragraph>{formatPrice(-appliedDiscount.price)}</Paragraph>
    </AppliedDiscountRow>
  )
}

/**
 * SummaryDiscountLine
 *
 * Renders the applied discounts in the summary
 */
export const SummaryDiscountLine: React.FC<SummaryDiscountProps> = props => {
  const { appliedDiscount, isAlreadyApplied, variant = 'default' } = props

  return (
    <DiscountWrapper variant={variant}>
      {appliedDiscount && (
        <SummaryDiscountLineText
          appliedDiscount={appliedDiscount}
          variant={variant}
        />
      )}
      {isAlreadyApplied && variant !== 'partner' && (
        <DiscountMessage>
          We&apos;ve <strong>automatically</strong> applied this discount you
          previously used.
        </DiscountMessage>
      )}
    </DiscountWrapper>
  )
}

interface SummaryProratedProps {
  daysElapsedInCycle: number
  refundAmountForNewSeats: Price
}

/**
 * SummaryProrated
 *
 * Renders Prorated info in the summary
 */
export const SummaryProrated: React.FC<SummaryProratedProps> = ({
  daysElapsedInCycle,
  refundAmountForNewSeats,
}) => {
  return (
    <SummaryLineWrapper>
      <Paragraph>
        Refund for {daysElapsedInCycle} unused{' '}
        {pluralize('day', 'days', daysElapsedInCycle)}
      </Paragraph>
      <Paragraph>{formatPrice(-refundAmountForNewSeats)}</Paragraph>
    </SummaryLineWrapper>
  )
}

import React from 'react'
import { Button, Modal, ModalInjectedProps } from '@sketch/components'

import {
  DocumentInfoModalQuery,
  DocumentInfoModalQueryProps,
} from './DocumentInfoModalQuery'

interface DocumentInfoModalProps
  extends ModalInjectedProps,
    DocumentInfoModalQueryProps {}

export const DocumentInfoModal = (props: DocumentInfoModalProps) => {
  const { hideModal, share, versions } = props
  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>Document Settings</Modal.Header>
      <Modal.Body>
        <DocumentInfoModalQuery share={share} versions={versions} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={hideModal}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

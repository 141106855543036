import React, { useMemo } from 'react'
import { useInspectorContext } from '../../hooks'
import styled, { CSSProperties, css } from 'styled-components'
import { scaleFrame } from '@sketch/utils'
import { ArtboardOriginContent } from '../ArtboardOriginContent'

import { usePerformanceOptimizedZoom } from '../../hooks/inspector'

import { SketchElement, ElementType, Rect } from 'modules/inspector'

type TestLayerProps = {
  frame: Rect
}

const TestLayer = styled.div.attrs(({ frame }: TestLayerProps) => {
  const style: CSSProperties = {
    top: `${frame.y}px`,
    left: `${frame.x}px`,
    width: `${frame.width}px`,
    height: `${frame.height}px`,
  }
  return { style }
})<TestLayerProps>(
  ({ frame }) => css`
    position: absolute;
  `
)

export function ArtboardDetailInspectorTestOverlay() {
  const { sketchSceneRootElement } = useInspectorContext()
  const zoom = usePerformanceOptimizedZoom()

  const elements = useMemo(() => {
    if (!sketchSceneRootElement) return []
    return flatten(sketchSceneRootElement).filter(includeElement)
  }, [sketchSceneRootElement])

  return (
    <ArtboardOriginContent>
      {elements.map(element => {
        const frame = element.rootRelativeBoxRect
        const scaledFrame = scaleFrame(zoom, frame)
        return (
          <TestLayer
            key={element.elementUUID}
            frame={scaledFrame}
            data-name={element.name}
            data-id={element.elementUUID}
            data-testid={element.elementUUID}
          />
        )
      })}
    </ArtboardOriginContent>
  )
}

function flatten(element: SketchElement) {
  function traverse(
    element: SketchElement,
    result: SketchElement[] = []
  ): SketchElement[] {
    return [
      element,
      ...element.children.map(child => traverse(child, result)).flat(),
    ]
  }
  return traverse(element)
}

function includeElement(element: SketchElement) {
  const isLocked = 'isLocked' in element && element.isLocked
  return (
    element.type !== ElementType.Hotspot &&
    element.type !== ElementType.ShapeGroup &&
    !isLocked
  )
}

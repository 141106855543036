import { useLogEvent } from '@sketch-hq/sketch-web-renderer'
import * as Sentry from '@sentry/browser'
import { useDevToolsSetting } from '@sketch/devtools'

const IS_CYPRESS = 'Cypress' in window

export function WebRendererLogging() {
  const [devToolsLogToConsole] = useDevToolsSetting('webRenderer.logToConsole')
  // Determine whether to log to console:
  //  If we're in Cypress, always log to the console
  //  Else, defer to the dev tools setting (defaults to false)
  const logToConsole = IS_CYPRESS || devToolsLogToConsole

  useLogEvent((level, ...args) => {
    // In production, add a Sentry breadcrumb for warning and error logs
    if (
      process.env.REACT_APP_ENV === 'production' &&
      (level === 'warn' || level === 'error')
    ) {
      Sentry.addBreadcrumb({
        category: 'WebRenderer',
        message: `WR ${level}: ${args.join(', ')}`,
      })
    }

    if (logToConsole) {
      console[level](...args)
    }
  })

  return null
}

import styled from 'styled-components';
import { Modal } from './Modal.js';
import { ModalBody } from './ModalBody.js';
import { ModalFooter } from './ModalFooter.js';
import { ModalHeader } from './ModalHeader.js';
import { breakpoint } from '@sketch/global-styles';
import background1x from '@sketch/icons/modal-diamond@1x.png';
import background2x from '@sketch/icons/modal-diamond@2x.png';

// TODO: Unify highlight / diamond modal component
//  see: https://github.com/sketch-hq/Cloud/issues/15125
const ModalHighlight = styled(Modal) `
  background-color: ${({ theme }) => theme.colors.brand.green.default.A};
  background-position: right -120px center;

  /* stylelint-disable value-no-vendor-prefix */
  background-image: -webkit-image-set(
    url(${background1x}) 1x,
    url(${background2x}) 2x
  );
  /* stylelint-enable value-no-vendor-prefix */

  background-repeat: no-repeat;

  ${breakpoint('sm') `
    background-position: right -50px center;
  `}

  ${ModalFooter} {
    background: transparent;
  }

  ${ModalHeader} {
    background: transparent;
  }

  ${ModalBody} {
    font-size: small;
  }
`;

export { ModalHighlight };

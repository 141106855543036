import React from 'react'
import styled, { css } from 'styled-components'
import rgbTo, {
  ColorFormat,
} from 'modules/shares/Inspector/Sidebar/components/Color/utils'
import { ColorProps } from 'modules/shares/Inspector/Sidebar/components/Color/ColorDot'
import {
  Text,
  Container,
  ColorDotStyle,
} from 'modules/shares/Inspector/Sidebar/components/Color/ColorDot/ColorDot.styles'

interface ConnectedColorDotProps extends ColorProps {
  connected: boolean
}

const ColorIconUnstyled = ({
  red,
  green,
  blue,
  alpha,
  connected,
  ...props
}: ConnectedColorDotProps) => (
  <div
    role="img"
    aria-label={`rgba(${red}, ${green}, ${blue}, ${alpha})`}
    {...props}
  >
    {connected && <span />}
  </div>
)

export const ColorIcon = styled(ColorIconUnstyled)<ColorProps>(
  ({ theme }) => css`
    ${ColorDotStyle}

    & > span {
      content: '';
      display: block;
      position: absolute;
      top: 16px;
      left: 0;
      right: 0;
      margin: auto;
      width: 1px;
      height: 32px;
      background-color: ${theme.colors.border.A};
    }
  `
)

const ConnectedColorDot: React.FC<ConnectedColorDotProps> = ({
  red,
  green,
  blue,
  alpha = 1,
  format = ColorFormat.HEX,
  connected = false,
}) => {
  const colorString = rgbTo(format, { red, green, blue, alpha })

  return (
    <Container>
      <ColorIcon
        connected={connected}
        red={red}
        green={green}
        blue={blue}
        alpha={alpha}
      />
      <Text className="ColorText">{colorString}</Text>
    </Container>
  )
}

export default ConnectedColorDot

import { jsx } from 'react/jsx-runtime';
import { useState } from 'react';
import { useSetUserAuthorization, RecoveryCode, VerificationCode } from '@sketch/modules-common';

/**
 * After the user logs in successfuly in the SSO Linking account page
 * this component will  deal with the logic that toggles between the verification code
 * interface or the recovery code interface in the MFA verification step when
 * linking SSO accounts
 */
const MfaInterface = ({ mfaToken }) => {
    const [showRecoveryCode, setShowRecoveryCode] = useState(false);
    const [verificationError, setVerificationError] = useState();
    const setUserAuthorization = useSetUserAuthorization();
    const handleToggleInterface = () => {
        if (showRecoveryCode) {
            setVerificationError(undefined);
            setShowRecoveryCode(false);
        }
        else {
            setVerificationError(undefined);
            setShowRecoveryCode(true);
        }
    };
    const handleCompleted = (data) => {
        if ('validateMfaRecoveryCode' in data) {
            setUserAuthorization(data.validateMfaRecoveryCode.credentials);
        }
        else {
            setUserAuthorization(data.validateMfaTotp.credentials);
        }
    };
    const handleError = (error) => {
        // TODO: Improve returned error types from local resolvers
        // https://github.com/sketch-hq/Cloud/issues/11366
        const mfaError = error.message;
        setVerificationError(mfaError.message);
    };
    const handleVerificationCodeFilled = () => {
        // Clear error
        setVerificationError(undefined);
    };
    if (showRecoveryCode) {
        return (jsx(RecoveryCode, { onCompleted: handleCompleted, mfaToken: mfaToken, toggleInterface: handleToggleInterface }));
    }
    return (jsx(VerificationCode, { onCompleted: handleCompleted, onError: handleError, onVerificationCodeFilled: handleVerificationCodeFilled, toggleInterface: handleToggleInterface, mfaToken: mfaToken, verificationError: verificationError }));
};

export { MfaInterface as default };

import { isMac } from '@sketch/utils'
import {
  SketchElement,
  ElementType,
  isLayerElement,
  isGroupElement,
} from 'modules/inspector'

/**
 * Layer types that should not receive any user interaction in the inspector.
 */
const LAYER_TYPES_TO_DISABLED: Array<ElementType> = [ElementType.Hotspot]

/**
 * With some special conditions, some layers can not be interacted with in the inspector:
 * - When a layer is inside a group/symbol with tints, exports or styles changes.
 *   (We want to prioritize the group/symbol to be selected when clicking inside, even if another
 *   descendant layer should have gotten the click).
 * - When a layer is locked. (Context of initial implementation here => https://github.com/sketch-hq/Cloud/issues/14517)
 * - When a layer is masked by another layer.
 * - When a layer is a hotspot (usually placed on top of other elements we want to select)
 * - When the cmd/ctrl key is pressed (to allow to select what is behind a group potentially masking content)
 *   (Context of initial implementation here => https://github.com/sketch-hq/Cloud/issues/3205)
 *   Example: Imagine the following design:
 *   +----+         +----+
 *   | R1 |  Hello  | R2 |
 *   +----+         +----+
 *   With this layer structure:
 *   - RectangleGroup
 *      |__ Rectangle R1
 *      |__ Rectangle R2
 *   - Hello text layer
 *   If the user clicks on the Hello text layer, by default it would select the RectangleGroup since
 *   it is on top. But if the user presses cmd/ctrl, it will select the Hello text layer instead.
 */
export function isUserInteractionEnabledOnSketchElement(
  rootSketchElement: SketchElement,
  sketchElement: SketchElement,
  event: React.MouseEvent<HTMLElement> | undefined
) {
  const isTextLayer = sketchElement.type === 'text'
  /**
   * Layers that are inside a group with tints, exports or styles changes should not be clickable.
   * So that the click goes directly to the parent group layer with the tint, exports or style changes.
   * For example if you have a blue triangle in a symbol (symbols are marked as group layers),
   * If the group has a red tint, the triangle will be rendered red. When the user clicks on the triangle,
   * we want to select the group layer with red tint, not the triangle that would show a blue fill.
   * This is a very questionable product decision because there are other information the user may want to grab
   * from the triangle. (They can still do it through right click but not super user friendly).
   * Maybe I am missing something but feel free to challenge it if you encounter this.
   */
  const isInsideGroupLayerWeWantToPrioritize =
    isLayerElement(sketchElement) &&
    (sketchElement.isInsideGroupWithExports ||
      sketchElement.isInsideGroupWithStyles)
  /**
   * Text layers always get the click, regardless of the group they're in.
   * @see isInsideGroupLayerWeWantToPrioritize
   */
  const isLayerThatShouldLetGroupAncestorHandleClick =
    isInsideGroupLayerWeWantToPrioritize && !isTextLayer

  /**
   * Users on the Sketch app lock a layer when they don't want to select it on the canvas.
   * We are doing the same here in the inspector even though it's questionable since the use case is not
   * the same.
   * This decision was made here: https://github.com/sketch-hq/Cloud/issues/14517
   */
  const isLocked = isLayerElement(sketchElement) && sketchElement.isLocked

  const isLayerMasked =
    isLayerElement(sketchElement) && sketchElement.isLayerMasked

  const isLayerTypeWithoutUserInteraction = LAYER_TYPES_TO_DISABLED.includes(
    sketchElement.type
  )

  const hasSkipGroupKeyPressed = isMac()
    ? Boolean(event?.metaKey)
    : Boolean(event?.ctrlKey)

  const isGroupToSkipBecauseOfSpecialKeyPressed =
    isGroupElement(sketchElement) && hasSkipGroupKeyPressed

  const isRootElement = rootSketchElement === sketchElement

  const isLayerClickable =
    !isLayerMasked &&
    !isLayerThatShouldLetGroupAncestorHandleClick &&
    !isLocked &&
    !isLayerTypeWithoutUserInteraction &&
    !isGroupToSkipBecauseOfSpecialKeyPressed &&
    !isRootElement

  return isLayerClickable
}

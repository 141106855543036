import { __rest } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import React from 'react';
import { StyledDefaultSelectorButton, StyledStrongSelectorButton, StyledHeaderSelectorButton, SelectorButtonChevronsUpDown, SelectorButtonChevronVertical, SelectorButtonChevronHorizontal, SelectorButtonChevronsUpDownIconNegative } from './SelectorButton.styles.js';

const components = {
    default: StyledDefaultSelectorButton,
    strong: StyledStrongSelectorButton,
    header: StyledHeaderSelectorButton,
};
const icons = {
    chevrons: SelectorButtonChevronsUpDown,
    bottomArrow: SelectorButtonChevronVertical,
    backArrow: SelectorButtonChevronHorizontal,
    negative: SelectorButtonChevronsUpDownIconNegative,
    default: () => null,
};
/**
 * SelectorButton maps to the Primitive/Actions/Selector component in Prism Web.
 *
 * It's designed to be a multi-purpise action - sometimes acting as a Dropdown
 * toggle button where needed, and at others another type of basic button.
 *
 * Variants
 *
 *   Strong (bordered)
 *   Header (strong uppercase label)
 *
 * Icons
 *
 *   Icon display is kept as an implementation detail to increase component
 *   encapuslation, at the expense of flexibility at the call site. Icons can be
 *   toggled on and off via the boolean props `chevrons` and `backArrow`, with
 *   more added as needed.
 *
 * Note: This component does not currently implement all possible variations
 * seen in Prism Web, but we can add to it over time.
 */
const SelectorButton = React.forwardRef(function SelectorButton(_a, ref) {
    var { label, variant = 'default' } = _a, rest = __rest(_a, ["label", "variant"]);
    const Comp = components[variant];
    const Icon = icons[rest.chevronVertical
        ? 'bottomArrow'
        : rest.backArrow
            ? 'backArrow'
            : rest.chevrons
                ? rest.$colorRed
                    ? 'negative'
                    : 'chevrons'
                : 'default'];
    return (jsxs(Comp, Object.assign({ type: "button" }, rest, { ref: ref }, { children: [label, jsx(Icon, { disabled: rest.disabled })] })));
});

export { SelectorButton };

import { jsx } from 'react/jsx-runtime';
import { createContext, useState, useContext, useEffect } from 'react';

const initialContextvalues = {
    containerRef: null,
    scrollY: 0,
    isProviderAvailable: false,
};
const ContainerRefScrollContext = createContext([initialContextvalues, () => { }]);
function ContainerRefScrollProvider({ children, }) {
    const [value, setValue] = useState(initialContextvalues);
    return (jsx(ContainerRefScrollContext.Provider, Object.assign({ value: [Object.assign(Object.assign({}, value), { isProviderAvailable: true }), setValue] }, { children: children })));
}
/**
 * Saves the scroll position for the given element.
 * Can be used in cases, where the scroll needs to be restored between component
 * transitions.
 */
function useContainerRefScroll() {
    const [context, setContextValue] = useContext(ContainerRefScrollContext);
    const { containerRef, scrollY } = context;
    const setContainerRef = (containerRef) => {
        // At this point does not make snese to set the container ref if
        // ContainerRefScrollProvider was not yet initialized.
        // We don't want to throw an error here, because in some cases the
        // containing element can be lazy loaded and only available later.
        if (!context.isProviderAvailable)
            return;
        setContextValue(Object.assign(Object.assign({}, context), { containerRef }));
    };
    const saveScrollTop = () => {
        var _a;
        return ((_a = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTop) !== undefined &&
            setContextValue(Object.assign(Object.assign({}, context), { scrollY: containerRef.current.scrollTop }));
    };
    const scrollToElement = () => {
        if (!(containerRef === null || containerRef === void 0 ? void 0 : containerRef.current)) {
            return;
        }
        if (scrollY === 0) {
            // Force the initial scroll position to be at the top on initial load
            // (mainly for Safari)
            containerRef.current.scrollTop = 0;
        }
        else {
            containerRef.current.scrollTop = scrollY;
        }
    };
    // running the scroll after page updates
    useEffect(() => {
        window.requestAnimationFrame(() => {
            scrollToElement();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerRef]);
    return {
        setContainerRef,
        saveScrollTop,
    };
}

export { ContainerRefScrollProvider, useContainerRefScroll };

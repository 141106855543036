import { SharePublicationFragment, VersionFragment } from '@sketch/gql-types'
import type { ShareForDocumentItem } from 'modules/shares/components/DocumentItem'
import type { TrashedShare } from 'modules/shares/components/TrashItemsLayout'

type PreviewFileDocument = Pick<
  NonNullable<VersionFragment['document']>,
  'previewFile'
>

export type PreviewFile = Pick<
  NonNullable<PreviewFileDocument['previewFile']>,
  'thumbnails'
>

export const getShareThumbnail = (
  item: ShareForDocumentItem | TrashedShare | SharePublicationFragment
) => {
  const previewFile = item.version?.document?.previewFile
  return thumbnailForPreviewFile(previewFile)
}

export const thumbnailForPreviewFile = (
  previewFile: PreviewFile | null | undefined
) => {
  if (!previewFile?.thumbnails?.length) {
    return
  }

  const { thumbnails } = previewFile
  const largeThumbnail = thumbnails.find(thumbnail => thumbnail?.type === 'L')
    ?.url
  const fallbackThumbnail = thumbnails[0]?.url

  return largeThumbnail || fallbackThumbnail || undefined
}

import React from 'react'

import {
  useForTablet,
  useResponsiveDropdown,
  MenuIconButton,
} from '@sketch/components'
import { useUserSignedIn } from '@sketch/modules-common'
import { useVersioning } from 'modules/versioning'

import { ShareActionsDropdown } from '../ShareActionsDropdown'
import { DotsIcon } from './DocumentSettingsDropdown.styles'
import { VersioningContextReady } from 'modules/versioning/ShareVersionContext/ShareVersionContext'

import { useGetWorkspaceQuery } from '@sketch/gql-types'
import { useDuplicateShareTo } from '../../hooks/useDuplicateShareTo'
import { useDuplicateShareToDrafts } from '../../hooks/useDuplicateShareToDrafts'

interface DocumentSettingsDropdownProps {
  showAnnotationActions?: boolean
}

export const DocumentSettingsDropdown = (
  props: DocumentSettingsDropdownProps
) => {
  const { showAnnotationActions } = props
  const {
    share,
    versionShortId,
    latestVersionId,
    isViewingLatestVersion,
    currentVersion = { identifier: '' },
  } = useVersioning() as VersioningContextReady

  const versions = {
    current: { shortId: versionShortId },
    latest: { shortId: latestVersionId },
  }

  const workspaceIdentifier = share.workspace.identifier

  // Both of these mutations are quite high in the component tree in relation to where its actually used.
  // This is because the dropdown is immediatly unmounted when clicked and this causes
  // the mutation onComplete to never trigger and cancel the duplicate flow mid way.
  const [duplicateShareToDrafts] = useDuplicateShareToDrafts({
    share,
    versionIdentifier: currentVersion.identifier,
    workspaceIdentifier,
  })

  const [duplicateShareTo] = useDuplicateShareTo({
    share,
    versionIdentifier: currentVersion.identifier,
    workspaceIdentifier,
  })

  // Get current workspace to check if it's active
  const { data: workspaceData } = useGetWorkspaceQuery({
    variables: { identifier: share.workspace.identifier },
    fetchPolicy: 'cache-only',
  })

  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  const [dropdown, buttonProps] = useResponsiveDropdown({
    dropdown: ShareActionsDropdown,
    dropdownProps: {
      share,
      versions,
      isViewingLatestVersion,
      workspaceIdentifier,
      onDuplicateShareToDraftsClick: duplicateShareToDrafts,
      onDuplicateShareToClick: duplicateShareTo,
      queryVariables: {
        shareIdentifier: share.identifier,
        versionShortId,
      },
      workspaceStatus: workspaceData?.workspace.status,
      showAnnotationActions,
    },
    placement: 'bottom-end',
    usePortal: !isMobile,
    useModalRoot: isMobile,
  })

  const isActive = workspaceData?.workspace.status === 'ACTIVE'
  const isSignedIn = useUserSignedIn()

  // Hide the 3 dots dropdown if we are only going to show the Copy Link item,
  // because we already show it as a button in the navbar
  if (
    !share.userCanUpdateSettings &&
    !share.userCanInspect &&
    !isSignedIn &&
    !isActive
  ) {
    return null
  }

  return (
    <>
      <MenuIconButton data-testid="document-settings-toggle" {...buttonProps}>
        <DotsIcon role="img" />
      </MenuIconButton>
      {dropdown}
    </>
  )
}

export default DocumentSettingsDropdown

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useCallback } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useToast } from '@sketch/toasts';
import { Form, Input, Panel, Button } from '@sketch/components';
import { HelpNote } from './HelpNote.js';
import { Note, Paragraph, StyledFakeLinkButton, Title, Description, FormContainer } from './RecoveryCode.styles.js';
import { useValidateMfaRecoveryCodeMutation } from '@sketch/gql-types';

const INITIAL_VALUES = {
    recoveryCode: '',
};
const VALIDATION_SCHEMA = yup.object().shape({
    recoveryCode: yup.string().trim().required('Enter a recovery code'),
});
/**
 * Renders the UI that allows the user to enter the recovery code
 */
const RecoveryCode = ({ onCompleted, mfaToken, toggleInterface, hideHeader, onChange, }) => {
    const { showToast } = useToast();
    // Recovery Code Validation
    const [validateRecoveryCode, { loading },] = useValidateMfaRecoveryCodeMutation({
        onCompleted,
        onError: error => {
            // TODO: Improve returned error types from local resolvers
            // https://github.com/sketch-hq/Cloud/issues/11366
            const mfaError = error.message;
            showToast(mfaError.message, 'negative');
        },
    });
    const handleSubmitRecoveryCode = useCallback((values, actions) => {
        validateRecoveryCode({
            variables: {
                token: mfaToken,
                recoveryCode: values.recoveryCode,
            },
        });
        actions.setSubmitting(false);
    }, [mfaToken, validateRecoveryCode]);
    if (hideHeader) {
        return (jsxs(Fragment, { children: [jsx(Formik, Object.assign({ initialValues: INITIAL_VALUES, 
                    // blank onSubmit because we want the form to be controlled
                    onSubmit: () => { }, validationSchema: VALIDATION_SCHEMA }, { children: ({ values, handleChange, errors, touched }) => (jsx(Form, { children: jsx(Form.Field, Object.assign({ name: "text", label: "Recovery Code", errorText: touched.recoveryCode ? errors.recoveryCode : undefined }, { children: jsx(Input, { name: "recoveryCode", type: "text", value: values.recoveryCode, onChange: e => {
                                    onChange === null || onChange === void 0 ? void 0 : onChange(e);
                                    handleChange(e);
                                } }) })) })) })), jsx(Note, { children: jsxs(Paragraph, { children: ["Authenticator app working again?", ' ', jsx(StyledFakeLinkButton, Object.assign({ onClick: toggleInterface, isUnderlined: true }, { children: "Enter verification code" })), jsx("br", {}), jsx(HelpNote, {})] }) })] }));
    }
    return (jsxs(Fragment, { children: [jsx(Title, { children: "Enter Recovery Code" }), jsxs(Panel.Body, { children: [jsx(Description, { children: "Can\u2019t access your authenticator app? Enter a recovery code instead." }), jsx(FormContainer, { children: jsxs(Fragment, { children: [jsx(Formik, Object.assign({ initialValues: INITIAL_VALUES, onSubmit: handleSubmitRecoveryCode, validationSchema: VALIDATION_SCHEMA }, { children: ({ values, handleChange, errors, touched, isSubmitting }) => (jsxs(Form, { children: [jsx(Form.Field, Object.assign({ name: "text", errorText: touched.recoveryCode ? errors.recoveryCode : undefined }, { children: jsx(Input, { name: "recoveryCode", placeholder: "Enter recovery code", type: "text", value: values.recoveryCode, onChange: handleChange }) })), jsx(Button, Object.assign({ type: "submit", variant: "primary-untinted", loading: isSubmitting || loading, fill: true, size: "40" }, { children: "Submit" }))] })) })), jsx(Note, { children: jsxs(Paragraph, { children: ["Authenticator app working again?", ' ', jsx(StyledFakeLinkButton, Object.assign({ variant: "tertiary", onClick: toggleInterface, isUnderlined: true }, { children: "Enter verification code" })), jsx("br", {}), jsx(HelpNote, {})] }) })] }) })] })] }));
};

export { RecoveryCode, RecoveryCode as default };

import React from 'react'

import { ConfirmationDialog, ModalInjectedProps } from '@sketch/components'
import { Image } from './WorkspaceSettingsUpsell.styles'
import { SKETCH_WEBSITE } from '@sketch/env-config'

import DocumentsImageSrc from '@sketch/icons/highlight-image-modal.png'
import DocumentsRetinaImageSrc from '@sketch/icons/highlight-image-modal@2x.png'

const ManageUserPermissionsUpsellModal = ({
  hideModal,
}: ModalInjectedProps) => (
  <ConfirmationDialog
    hideModal={hideModal}
    title="Get Total Control Over Document Access"
    onConfirm={() =>
      window.open(`${SKETCH_WEBSITE}/support/contact/`, '_blank')
    }
    hero={
      <Image
        alt="title"
        src={DocumentsImageSrc}
        srcSet={`${DocumentsRetinaImageSrc} 2x`}
      />
    }
    confirmButton={{
      text: 'Contact Us',
      variant: 'primary',
    }}
    cancelButton={{
      text: 'Maybe later',
      variant: 'secondary',
    }}
  >
    <p>
      With the Business Plan you get the powerful permissions directory, making
      it easy to manage everybody’s document and project access. Upgrade to a
      Business Plan for flexible payment methods or terms, single sign-on (SSO),
      unlimited Workspace storage, and more.
    </p>
  </ConfirmationDialog>
)

export { ManageUserPermissionsUpsellModal }

import styled, { css } from 'styled-components';
import { breakpoint } from '@sketch/global-styles';
import { Panel, SsoTag, ErrorMessage, Text, FakeLinkButton, Button, LinkButton } from '@sketch/components';

const StyledPanel = styled(Panel) `
  max-width: 520px;
  overflow: visible;
`;
const StyledSsoTag = styled(SsoTag) `
  margin-left: 0;
  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
`;
const UberHeader = styled.div `
  max-width: 400px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  border-radius: ${({ theme }) => theme.radii.xlarge};
`;
const WorkspaceName = styled.strong `
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`;
const Title = styled.h1 `
  margin: 24px 0px 8px 0px;
  font-size: ${({ theme }) => theme.fontSizes.I};
  text-align: center;
`;
const FormContainer = styled.div `
  margin-top: 24px;
`;
const GenericErrorMessage = styled(ErrorMessage.Generic) `
  flex: 1 1 100%;
`;
const Note = styled(Text.Div).attrs({
    textStyle: 'copy.tertiary.standard.C',
}) `
  text-align: center;
  margin: 24px 0px 0px 0px;
`;
const linkCss = css `
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeights.regular};

  :not(:last-child) {
    margin-bottom: 8px;
  }
`;
const StyledFakeLinkButton = styled(FakeLinkButton) `
  ${linkCss}
`;
const Description = styled(Text).attrs({
    textStyle: 'copy.primary.standard.F',
}) `
  margin: 0;
  padding: 0;
  text-align: center;
`;
const Paragraph = styled.p `
  margin: 0;
  line-height: 1.4;
`;
const StyledButton = styled(Button) `
  flex: 1;
`;
const StyledLinkButton = styled(LinkButton) `
  flex: 1;
`;
const PanelFooter = styled(Panel.Footer) `
  display: flex;
  margin: 0 48px;
  padding: 0;

  ${breakpoint('base', 'xs') `
    margin: 0 16px;
  `}
`;

export { Description, FormContainer, GenericErrorMessage, Note, PanelFooter, Paragraph, StyledButton, StyledFakeLinkButton, StyledLinkButton, StyledPanel, StyledSsoTag, Title, UberHeader, WorkspaceName, linkCss };

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import React from 'react';
import { Form as Form$1 } from 'formik';

const Form = React.forwardRef(function Form(_a, ref) {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (jsx(Form$1, Object.assign({ ref: ref, translate: "yes" }, props, { children: children })));
});

export { Form };

import React, { useState } from 'react'
import copy from 'copy-to-clipboard'

import { Button, Tooltip, CopyToClipboard } from '@sketch/components'

import { roundUpTo, roundWithLocale } from '@sketch/utils'
import {
  TextLayerStyleProperties,
  isTextLayerStyle,
  formatCssRgbaColor,
} from 'modules/inspector'
import {
  gradientStops,
  gradientBackground,
} from '../../../components/GradientAttribute/math'

/**
 * Using TextLayerStyleProperties as this is the layer that contains
 * all the props, other layers are a subset of TextLayerStyleProperties.
 */
export type CSSStyle = TextLayerStyleProperties & {
  corners?: {
    cornerRadius?: number[]
  }
}

export const CopyCSSButton = ({ style }: { style: CSSStyle }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const cssString: string[] = []

  const getCornerCSS = () => {
    if (!style.corners) {
      return
    }

    if (style.corners?.cornerRadius) {
      const radius = style.corners?.cornerRadius
      const hasRadius = radius.some(n => n !== 0)

      if (!hasRadius) {
        return
      }

      const normalizedRadius = radius.every(r => r === radius[0])
        ? `${radius[0]}px`
        : `${radius.join('px ')}px`

      cssString.push(`border-radius: ${normalizedRadius};`)
    }
  }

  const getBorderCSS = () => {
    // ignore borders if this is a text-style
    if (style.text) {
      return
    }

    const colors: string[] = []
    const widths: string[] = []

    style.borders?.forEach(border => {
      if (!border.isEnabled) {
        return
      }
      widths.push(`${border.thickness}px`)

      if (border.color) {
        colors.push(formatCssRgbaColor(border.color))
      } else if (border.gradient) {
        // We don't really handle borders with gradient yet.
        // The old inspector data was getting a color property when there was a gradient
        // and that was what was shown. Since we don't have this prop anymore,
        // we replicate that behavior by extracting the last color of the gradient.
        const gradientStops = border.gradient.stops
        const lastGradientColor = gradientStops[gradientStops.length - 1]
        colors.push(formatCssRgbaColor(lastGradientColor.color))
      }
    })

    let borderStyle = 'solid'

    // if only the dash ([0]) is defined but not the gap ([1]), then it's a solid border
    if (
      style.borderOptions?.dashPattern?.length &&
      style.borderOptions?.dashPattern[1] !== 0
    ) {
      // so dotted borders are the ones that have a dash of only 1px, otherwise it's a dashed border
      borderStyle =
        style.borderOptions.dashPattern[0] === 1 ? 'dotted' : 'dashed'
    }

    colors.forEach((color, index) => {
      cssString.push(`border: ${widths[index]} ${borderStyle} ${color};`)
    })
  }

  const getShadowsCSS = () => {
    const innerShadows: string[] = []
    const outerShadows: string[] = []
    const shadows: string[] = []

    style.innerShadows?.forEach(
      ({ isEnabled, offsetX, offsetY, blurRadius, spread, color }) => {
        if (!isEnabled) {
          return
        }

        const rgba = formatCssRgbaColor(color)

        // order of the parameters is: x y blur spread color
        innerShadows.push(
          `${offsetX}px ${offsetY}px ${blurRadius}px ${
            spread ? spread + 'px ' : ''
          }${rgba} inset`
        )
      }
    )

    style.shadows?.forEach(
      ({ isEnabled, offsetX, offsetY, blurRadius, spread, color }) => {
        if (!isEnabled) {
          return
        }

        const rgba = formatCssRgbaColor(color)

        // order of the parameters is: x y blur spread color
        outerShadows.push(
          `${offsetX}px ${offsetY}px ${blurRadius}px ${
            spread ? spread + 'px ' : ''
          }${rgba}`
        )
      }
    )

    if (style.text && shadows.length) {
      // text shadows instead of box
      cssString.push(`text-shadow: ${shadows.join(', ')};`)
      return
    }

    if (innerShadows.length) {
      shadows.push(innerShadows.join(', '))
    }

    if (outerShadows.length) {
      shadows.push(outerShadows.join(', '))
    }

    if (shadows.length) {
      cssString.push(`box-shadow: ${shadows.join(', ')};`)
    }
  }

  const getOpacity = () => {
    cssString.push(`opacity: ${roundUpTo(style.appearance?.opacity ?? 1, 2)};`)
  }

  const getBackground = () => {
    // ignore backgrounds if this is a text-style
    if (style.text) {
      return
    }

    const gradients: string[] = []

    style.fills?.forEach(fill => {
      if (!fill.isEnabled) {
        return
      }

      if (fill.type === 'color' && fill.color) {
        const rgba = formatCssRgbaColor(fill.color)
        cssString.push(`background-color: ${rgba};`)
      } else if (fill.type === 'gradient' && fill.gradient) {
        switch (fill.gradient.type) {
          case 'radial':
            gradients.push(`radial-gradient(${gradientStops(fill.gradient)})`)
            break
          case 'angular':
            gradients.push(
              `conic-gradient(from 90deg, ${gradientStops(fill.gradient)})`
            )
            break
          default:
            gradients.push(gradientBackground(fill.gradient))
        }
      } else {
        if (fill.patternTileScale) {
          cssString.push(`background-size: ${fill.patternTileScale * 100}%;`)
        }
      }
    })

    if (gradients.length) {
      cssString.push(`background-image: ${gradients.join(', ')};`)
    }
  }

  const getText = () => {
    if (!isTextLayerStyle(style) || !style.text) {
      return
    }

    if (style.text.color) {
      const rgba = formatCssRgbaColor(style.text.color)

      cssString.push(`color: ${rgba};`)
    }

    if (style.text.font) {
      const familyName = getComputedCssFontFamilyName(
        style.text.font.family,
        style.text.font.style
      )
      cssString.push(`font-family: "${familyName}";`)
      cssString.push(`font-size: ${style.text.font.size}px;`)
      cssString.push(`font-weight: ${style.text.font.weight};`)
      cssString.push(`font-style: ${style.text.font.style};`)
    }

    const roundedLetterSpacing = roundWithLocale(
      style.text.letterSpacing ?? 0,
      2
    )

    if (roundedLetterSpacing) {
      cssString.push(`letter-spacing: ${roundedLetterSpacing}px;`)
    }

    if (style.text.paragraphStyle) {
      cssString.push(`text-align: ${style.text.paragraphStyle.alignment};`)

      if (style.text.paragraphStyle.lineHeight) {
        cssString.push(
          `line-height: ${style.text.paragraphStyle.lineHeight}px;`
        )
      }
    }

    if (style.text.transform) {
      cssString.push(`text-transform: ${style.text.transform};`)
    }

    if (style.text.decoration) {
      cssString.push(`text-decoration: ${style.text.decoration};`)
    }
  }

  if (!cssString.length) {
    getCornerCSS()
    getBorderCSS()
    getShadowsCSS()
    getOpacity()
    getBackground()
    getText()
  }

  const handleCopyClick = () => {
    copy(cssString.join('\n'))

    setTooltipVisible(true)

    window.setTimeout(() => {
      setTooltipVisible(false)
    }, CopyToClipboard.animationDuration)
  }

  return (
    <Tooltip visible={tooltipVisible} placement="left" content="Copied">
      <Button
        size="24"
        variant="secondary"
        onClick={handleCopyClick}
        disabled={!cssString.length}
      >
        Copy CSS
      </Button>
    </Tooltip>
  )
}

/**
 * We used to get this value from Sketch as "font.originalValue" but the new presentation
 * file format does not contain it anymore. So we need to compute it in the FE.
 * We don't think this is a problem because the font family name used in the code
 * is unlikely to match the font family name used in the design anyway.
 */
function getComputedCssFontFamilyName(
  fontFamily: string | undefined,
  fontStyle: string | undefined
) {
  const computedFamilyNameParts = []

  const removeSpaces = (str: string) => str.replace(/\s/g, '')

  if (fontFamily) {
    computedFamilyNameParts.push(removeSpaces(fontFamily))
  }

  if (fontStyle) {
    computedFamilyNameParts.push(removeSpaces(fontStyle))
  }

  return computedFamilyNameParts.join('-')
}

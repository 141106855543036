import React from 'react'

import { Button, Text, Modal, ModalInjectedProps } from '@sketch/components'

import { useToast } from '@sketch/toasts'

import { useUpdateRestrictedPublicLinksMutation } from '@sketch/gql-types'

interface ToggleRestrictedPublicLinksModalProps extends ModalInjectedProps {
  workspaceId: string
  arePublicLinksRestricted: boolean
}

/**
 * ToggleRestrictedPublicLinks.
 *
 * This component shows a modal to the user to confirm toggling the restricted public links
 * To properly work this component should be call through the ModalContext API.
 *
 */
export const ToggleRestrictedPublicLinksModal: React.FC<ToggleRestrictedPublicLinksModalProps> = ({
  hideModal,
  workspaceId,
  arePublicLinksRestricted,
}) => {
  const { showToast } = useToast()

  const [
    updateRestrictedPublicLinks,
    { loading },
  ] = useUpdateRestrictedPublicLinksMutation({
    onCompleted: data => {
      const newValue =
        data.updateRestrictedPublicLinks.workspace.features
          .restrictedPublicLinks
      if (newValue !== !arePublicLinksRestricted) {
        showToast('Something went wrong...', 'negative')
      } else {
        const text = newValue
          ? 'Public document links disabled'
          : 'Public document links enabled'
        showToast(text, 'positive')
      }
      hideModal()
    },
    onError: 'show-toast',
  })

  const handleOnClick = () =>
    updateRestrictedPublicLinks({
      variables: {
        workspaceId,
        enabled: !arePublicLinksRestricted,
      },
    })

  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>
        {arePublicLinksRestricted
          ? 'Enable Public Document Links?'
          : 'Disable Public Document Links?'}
      </Modal.Header>
      <Modal.Body>
        {arePublicLinksRestricted ? (
          <>
            <Text>
              Admins and Editors will be able to create and share new public
              document links again.
            </Text>
            <Modal.Explainer>
              Previously disabled public links won’t be automatically restored.
            </Modal.Explainer>
          </>
        ) : (
          <>
            <Text>
              Admins and Editors will no longer be able to create and share new
              public document links. Existing public links and embedded URLs
              will be disabled too.
            </Text>
            <Modal.Explainer>
              Public links won’t be automatically restored if this setting is
              enabled again.
            </Modal.Explainer>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={hideModal}
          type="button"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          variant={arePublicLinksRestricted ? 'primary' : 'negative'}
          loading={loading}
          onClick={handleOnClick}
        >
          {arePublicLinksRestricted ? 'Enable' : 'Disable'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import styled from 'styled-components';
import '../FormError/index.js';
import { FormError } from '../FormError/FormError.js';

const FormErrorField = styled(FormError) `
  display: block;
  text-align: center;
  margin: 20px 0;
`;

export { FormErrorField };

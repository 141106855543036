import styled, { css } from 'styled-components';
import { breakpoint } from '@sketch/global-styles';
import { TableComponents } from '@sketch/components';

const TableWrapper = styled.div `
  margin-top: 24px;

  ${TableComponents.Table} {
    width: 100%;

    ${TableComponents.TableRow} {
      cursor: pointer;
    }

    ${ /* sc-selector */TableComponents.TableCell}:last-child {
      text-align: right;

      ${breakpoint('base', 'sm') `
        text-align: left;
      `}
    }
  }
`;
const NameAndExpiryContainer = styled.div(({ expired, theme }) => css `
    display: flex;
    flex-direction: column;

    overflow: hidden;
    flex: 1;

    & span:first-child {
      font-size: ${theme.fontSizes.E};
      font-weight: ${theme.fontWeights.medium};
      color: ${expired
    ? theme.colors.foreground.secondary.D
    : theme.colors.foreground.secondary.A};
    }

    & span:last-child {
      font-size: ${theme.fontSizes.D};
      color: ${theme.colors.foreground.secondary.D};
    }
  `);

export { NameAndExpiryContainer, TableWrapper };

var LogMode;
(function (LogMode) {
    LogMode["off"] = "off";
    LogMode["memory"] = "memory";
    LogMode["console"] = "console";
})(LogMode || (LogMode = {}));
class Debug {
    constructor(logger = console.log) {
        this.logger = logger;
        this.messages = [];
        this.logMode = Debug.getLogMode();
        this.log = (message, ...optionalParams) => {
            const mode = this.logMode;
            if (mode === LogMode.off) {
                return;
            }
            if (mode === LogMode.memory || mode === LogMode.console) {
                this.messages.push([message, ...optionalParams]);
            }
            if (mode === LogMode.console) {
                this.logger(message, ...optionalParams);
            }
        };
        this.logger = logger || console.log;
    }
}
Debug.getLogMode = () => {
    const defaultMode = () => {
        switch (process.env.REACT_APP_ENV) {
            case 'production':
            case 'test':
                return LogMode.off;
            default:
                return LogMode.memory;
        }
    };
    try {
        switch (localStorage.getItem('debug.logMode')) {
            case 'off':
                return LogMode.off;
            case 'memory':
                return LogMode.memory;
            case 'console':
                return LogMode.console;
            default:
                return defaultMode();
        }
    }
    catch (_a) {
        return LogMode.memory;
    }
};
const debug = new Debug();
window.debug = debug;
const log = debug.log;

export { Debug, LogMode, log };

import styled from 'styled-components';
import '../Box/index.js';
import '../Input/index.js';
import { withBox } from '../Box/withBox.js';
import { inputBaseStyles, inputBorderStyle } from '../Input/Input.styles.js';

const TextArea = styled(withBox('textarea')) `
  ${inputBaseStyles};
  ${inputBorderStyle};
  line-height: 1.4;
`;

export { TextArea };

import { useContext, useReducer, useRef, useEffect, useCallback } from 'react';
import { get, set } from '@sketch/utils';
import { DevToolsSettingsContext } from './DevToolsContext.js';
import { isEqual } from 'lodash';

const useDevToolsSetting = (path) => {
    const context = useContext(DevToolsSettingsContext);
    const [, forceRender] = useReducer(s => s + 1, 0);
    const { settings, setSettings, subscription } = context;
    const valueRef = useRef(get(settings.current, path));
    useEffect(() => {
        const callback = () => {
            const newValue = get(settings.current, path);
            if (isEqual(newValue, valueRef.current)) {
                return;
            }
            valueRef.current = newValue;
            forceRender();
        };
        subscription.subscribe(callback);
        return () => {
            subscription.unsubscribe(callback);
        };
    }, [path, settings, subscription]);
    const setValue = useCallback((value, options) => {
        const hydratedObject = set({}, path, value);
        setSettings(hydratedObject, options);
    }, [path, setSettings]);
    return [valueRef.current, setValue];
};

export { useDevToolsSetting };

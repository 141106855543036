import { jsx, Fragment } from 'react/jsx-runtime';
import './Item/index.js';
import '../Skeleton/index.js';
import { DropdownItemWrapper } from './Item/DropdownItem.styles.js';
import { Skeleton } from '../Skeleton/Skeleton.js';

const DropdownSkeleton = (props) => {
    const { count = 1 } = props;
    return (jsx(Fragment, { children: Array.from({ length: count }).map((_, i) => (jsx(DropdownItemWrapper, { children: jsx(Skeleton, { height: "12px", width: "100%" }) }, i))) }));
};

export { DropdownSkeleton };

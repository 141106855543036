import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { useRef, useCallback, useEffect } from 'react';
import '../observable/index.js';
import { Observable, useObservable } from '../observable/observable.js';

function arraysHaveSameValues(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }
    // Sort both arrays
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();
    // Compare each element using the `every` method
    return sortedArr1.every((value, index) => value === sortedArr2[index]);
}
const isShallowEqual = (props1, props2) => {
    if (props1 === props2)
        return true;
    const props1Keys = Object.keys(props1);
    const props2Keys = Object.keys(props2);
    if (!arraysHaveSameValues(props1Keys, props2Keys)) {
        return false;
    }
    for (const key of props1Keys) {
        if (props1[key] !== props2[key]) {
            return false;
        }
    }
    return true;
};
const LayoutOverrideProps = (props) => {
    const { useOverrideLayoutProps } = props, rest = __rest(props, ["useOverrideLayoutProps"]);
    useOverrideLayoutProps(rest);
    return null;
};
const useLayoutOverrideProps = () => {
    const propsOverrideState = useRef(new Observable({}));
    // trigger rerendering on value change
    useObservable(propsOverrideState.current);
    const useOverrideLayoutProps = useCallback((props) => {
        /**
         * We are creating useOverrideLayoutProps hook on the fly.
         * So standard linting is a bit off and we have to be extra careful
         * ourselves to not introduce common bugs
         */
        /* eslint-disable react-hooks/rules-of-hooks */
        const observable = propsOverrideState.current;
        const savedProps = observable.state();
        useEffect(() => {
            return () => {
                const observable = propsOverrideState.current;
                observable.setState({});
            };
        }, []);
        // we are wrapping `observable.setState` with an useEffect hook
        // so that `observable.setState` would be executed during correct render phase
        // see more: https://github.com/sketch-hq/cloud-frontend/pull/6897#pullrequestreview-1417074287
        useEffect(() => {
            if (!isShallowEqual(props, savedProps)) {
                observable.setState(props);
            }
            /* eslint-enable*/
            /* Do not add dependencies array here to this hook */
        });
    }, []);
    const getOverriddenProps = useCallback((originalProps) => {
        const overrides = propsOverrideState.current.state();
        return Object.assign(Object.assign({}, originalProps), overrides);
    }, []);
    const OverrideLayoutProps = useCallback((props) => (jsx(LayoutOverrideProps, Object.assign({ useOverrideLayoutProps: useOverrideLayoutProps }, props))), [useOverrideLayoutProps]);
    return { useOverrideLayoutProps, getOverriddenProps, OverrideLayoutProps };
};

export { useLayoutOverrideProps };

import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { IndexLayoutContent } from '@sketch/modules-common';
import { WorkspaceLogo, LoadingState } from '@sketch/components';
import MfaInterface from './components/MFAInterface.js';
import SignInInterface from './components/SignInInterface.js';
import { Header } from '../LinkAccountConfirmationView/LinkAccountConfirmationView.styles.js';
import { UberHeader, WorkspaceName, StyledSsoTag, GenericErrorMessage, StyledPanel } from './SignInToLinkAccountView.styles.js';
import { useGetPublicWorkspaceQuery } from '@sketch/gql-types';

const FullHeader = ({ name, avatarUrl, }) => {
    return (jsxs(UberHeader, { children: [jsx(WorkspaceLogo, { size: "32px", src: avatarUrl, workspaceName: name, UNSAFE_ADD_MARGIN: true }), jsxs(Header, { children: ["Signing in to the ", jsx(WorkspaceName, { children: name }), " (", jsx(StyledSsoTag, {}), ") Workspace"] })] }));
};
const SignInToLinkAccountView = (props) => {
    var _a, _b, _c;
    const { useOverrideLayoutProps } = props;
    const { workspaceId } = useParams();
    const [mfaToken, setMfaToken] = useState('');
    useOverrideLayoutProps({ variant: 'orange' });
    const { data, loading, error } = useGetPublicWorkspaceQuery({
        variables: { identifier: workspaceId },
    });
    if (loading) {
        return jsx(LoadingState, {});
    }
    if (error || !(data === null || data === void 0 ? void 0 : data.publicWorkspace)) {
        return jsx(GenericErrorMessage, {});
    }
    const handleCompleted = (data) => {
        if (data.signIn.__typename === 'MFACredentials' && data.signIn.mfaToken) {
            setMfaToken(data.signIn.mfaToken);
        }
    };
    return (jsx(IndexLayoutContent, Object.assign({ center: "horizontal", marginTop: true, paddingHorizontal: true }, { children: jsx(StyledPanel, { children: mfaToken ? (jsx(MfaInterface, { mfaToken: mfaToken })) : (jsxs(Fragment, { children: [jsx(FullHeader, { name: data.publicWorkspace.name, avatarUrl: (_c = (_b = (_a = data.publicWorkspace) === null || _a === void 0 ? void 0 : _a.avatar) === null || _b === void 0 ? void 0 : _b.small) !== null && _c !== void 0 ? _c : null }), jsx(SignInInterface, { workspaceId: workspaceId, onCompleted: handleCompleted })] })) }) })));
};

export { FullHeader, SignInToLinkAccountView };

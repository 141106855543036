import { __awaiter } from 'tslib';
import { localStorageKeys } from '@sketch/constants';
import { ErrorHandler } from '@sketch/tracing';
import { castError } from '@sketch/utils';
import { useRef, useEffect, useState, useCallback } from 'react';
import { useLocalStorage } from 'react-use';

const DISCOVER_APP_URL = 'https://www.sketch.com/app/discover';
const LAST_UPDATED_HEADER = 'Last-Updated';
const lastViewedDateStorageKey = localStorageKeys.discoverLastViewedDate;
const createTriggerStorageEvent = (key, oldValue) => (newValue) => {
    /**
     * We dispatch this fake event to allow the useReactiveAuthorization
     * to update it's internal values and. the local-storage event listener
     * only allows the receive changes from other tabs. This work-around will
     * allow it to work always.
     */
    const storageEvent = new StorageEvent('storage', {
        key,
        storageArea: localStorage,
        newValue,
        oldValue,
    });
    window.dispatchEvent(storageEvent);
};
const useTriggerStorageEvent = (key, value) => {
    /**
     * Prevent the oldValue from triggering a new "setViewedDiscover" when changed
     * which can cause a re-render in the view
     */
    const triggerStorageEvent = useRef(createTriggerStorageEvent(key, value));
    useEffect(() => {
        triggerStorageEvent.current = createTriggerStorageEvent(key, value);
    }, [key, value]);
    return {
        triggerStorageEvent: triggerStorageEvent.current,
    };
};
const useDiscover = () => {
    var _a;
    const [lastUpdated, setLastUpdated] = useState(null);
    const [lastViewedDate, setLastViewedDate] = useLocalStorage(lastViewedDateStorageKey, (_a = localStorage.getItem(localStorageKeys.discoverLastViewedDate)) !== null && _a !== void 0 ? _a : '');
    const now = useRef(new Date().toISOString());
    // This will make sure the local storage event reacts to changes otherwise the browser ignores it
    const { triggerStorageEvent } = useTriggerStorageEvent(lastViewedDateStorageKey, lastViewedDate);
    /**
     * Given that this hook could be used more then one time
     * it's important that it stays sync with the latest local-storage
     * value, therefore we list to the events to make sure the state is synced
     */
    useEffect(() => {
        const handleStorageEvent = (event) => {
            event.key === lastViewedDateStorageKey &&
                setLastViewedDate(event.newValue);
        };
        window.addEventListener('storage', handleStorageEvent);
        return () => {
            window.removeEventListener('storage', handleStorageEvent);
        };
    }, [setLastViewedDate]);
    useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield fetch(DISCOVER_APP_URL);
                const lastUpdatedHeader = response.headers.get(LAST_UPDATED_HEADER);
                setLastUpdated(lastUpdatedHeader);
            }
            catch (e) {
                const error = castError(e);
                ErrorHandler.ignore(error, `useDiscover - Failed to fetch "${LAST_UPDATED_HEADER}" header from Discover page`);
            }
        });
        fetchData();
    }, []);
    const setViewedDiscoverLastUpdated = useCallback(() => {
        setLastViewedDate(now.current);
        triggerStorageEvent(now.current);
    }, [setLastViewedDate, triggerStorageEvent]);
    const setViewedDiscover = useCallback(() => setViewedDiscoverLastUpdated(), [
        setViewedDiscoverLastUpdated,
    ]);
    const hasNewLastUpdatedContent = lastViewedDate
        ? new Date(lastViewedDate) < new Date(lastUpdated)
        : true;
    const hasNewContent = hasNewLastUpdatedContent;
    return {
        hasNewContent,
        setViewedDiscover,
    };
};

export { useDiscover };

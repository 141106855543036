import { useContext } from 'react'
import { ModalContext } from '@sketch/components'
import {
  DocumentInfoModal,
  DocumentInfoModalQueryProps,
} from 'modules/shares/components/DocumentInfoModal'

export type { DocumentInfoModalQueryProps } from 'modules/shares/components/DocumentInfoModal'

export const useDocumentInfoModal = (props: DocumentInfoModalQueryProps) => {
  const { showModal } = useContext(ModalContext)

  const openDocumentInfoModal = () => showModal(DocumentInfoModal, props)

  return { openDocumentInfoModal }
}

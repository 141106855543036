import { __awaiter } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import { useContext } from 'react';
import * as yup from 'yup';
import { useShareUpdateMutation } from '@sketch/gql-types';
import { ToastContext } from '@sketch/toasts';
import { Form, Modal, Input, Button } from '@sketch/components';
import { Formik } from 'formik';
import { ErrorHandler } from '@sketch/tracing';

const renameShareSchema = yup.object({
    name: yup
        .string()
        .trim()
        .max(248, 'Document name can be up to 248 characters long')
        .required('A document name is required'),
});
const RenameShareModal = props => {
    const { hideModal, id, name } = props;
    const { showToast } = useContext(ToastContext);
    const onCompleted = (data) => {
        var _a, _b;
        if (!data || !data.shareUpdate) {
            showToast('Something went wrong', 'negative');
            ErrorHandler.shouldNeverHappen.invalidMutationData('shareUpdate');
            return;
        }
        showToast(`Document renamed to "${(_b = (_a = data.shareUpdate) === null || _a === void 0 ? void 0 : _a.share) === null || _b === void 0 ? void 0 : _b.name}"`);
        hideModal();
    };
    const [updateShare] = useShareUpdateMutation({
        redirectErrors: true,
        onCompleted,
        onError: 'show-toast',
    });
    const handleFormikRender = (formikBag) => {
        const { errors, values, touched, handleBlur, handleChange, isSubmitting, } = formikBag;
        return (jsxs(Form, { children: [jsx(Modal.Body, { children: jsx(Form.Field, Object.assign({ name: "name", label: "Document Name", errorText: touched.name && errors.name ? errors.name : undefined }, { children: jsx(Input, { name: "name", type: "text", placeholder: "Enter the document name...", value: values.name, onChange: handleChange, onBlur: handleBlur, disabled: isSubmitting, autoFocus: true }) })) }), jsxs(Modal.Footer, { children: [jsx(Button, Object.assign({ disabled: isSubmitting, type: "button", onClick: hideModal }, { children: "Cancel" })), jsx(Button, Object.assign({ loading: isSubmitting, type: "submit", variant: "primary", "data-testid": "submit-button" }, { children: "Rename" }))] })] }));
    };
    const handleOnSubmit = (values, formikActions) => __awaiter(void 0, void 0, void 0, function* () {
        const trimmedValues = renameShareSchema.cast(values);
        formikActions.setSubmitting(true);
        yield updateShare({
            variables: { identifier: id, share: { name: trimmedValues.name } },
        });
        formikActions.setSubmitting(false);
    });
    return (jsx(Modal, Object.assign({ title: "Rename Document", onCancel: hideModal }, { children: jsx(Formik, Object.assign({ initialValues: {
                name,
            }, onSubmit: handleOnSubmit, validationSchema: renameShareSchema, validateOnBlur: true }, { children: handleFormikRender })) })));
};

export { RenameShareModal };

import { LayoutSettings } from 'modules/inspector'

export const copyColumns = ({
  totalWidth,
  horizontalOffset,
  numberOfColumns,
  guttersOutside,
  gutterWidth,
  columnWidth,
  drawVertical,
}: Partial<LayoutSettings>) => {
  const sectionCopyValueArray: string[] = []
  if (totalWidth) {
    sectionCopyValueArray.push(`Total Width: ${totalWidth}px`)
  }
  if (horizontalOffset !== undefined) {
    sectionCopyValueArray.push(`Offset: ${horizontalOffset}px`)
  }
  if (!isNaN(numberOfColumns as number) && drawVertical) {
    sectionCopyValueArray.push(`Number of Columns: ${String(numberOfColumns)}`)
  }
  if (drawVertical && (guttersOutside === true || guttersOutside === false)) {
    sectionCopyValueArray.push(
      `Gutter Outside: ${guttersOutside ? `True` : `False`}`
    )
  }
  if (drawVertical && gutterWidth !== undefined) {
    sectionCopyValueArray.push(`Gutter Width: ${gutterWidth}px`)
  }
  if (drawVertical && columnWidth !== undefined) {
    sectionCopyValueArray.push(`Column Width: ${columnWidth}px`)
  }
  return sectionCopyValueArray.join('\n')
}

export const copyRows = ({
  gutterHeight,
  rowHeightMultiplication,
}: {
  gutterHeight?: number
  rowHeightMultiplication?: number
}) => {
  const rowHeight =
    rowHeightMultiplication && gutterHeight
      ? rowHeightMultiplication * gutterHeight
      : undefined

  let sectionCopyValue = ``
  if (gutterHeight) {
    sectionCopyValue += `Gutter Height: ${gutterHeight}px\n`
  }
  if (rowHeight) {
    sectionCopyValue += `Row Height: ${rowHeight}px`
  }
  return sectionCopyValue
}

/**
 * Transitions
 *
 * Named transitions defined in a plain object
 *
 * For use with the following CSS properties:
 *
 *     transitions
 *
 * @see https://system-ui.com/theme
 */
const easings = {
    easeIn: 'cubic-bezier(0.4, 0.0, 1, 1)',
    easeOut: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
    easeOutExponential: 'cubic-bezier(0.19, 1, 0.22, 1)',
    easeInOut: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
    spring: 'cubic-bezier(0.9, -0.85, 0.25, 1.75)',
    oscillate: 'cubic-bezier(0,.15,.4,1)',
};
const transitions = {
    duration: [
        '100ms',
        '200ms',
        '300ms',
        '400ms',
        '500ms',
        '600ms',
        '700ms',
        '800ms',
    ],
    timing: Object.assign({}, easings),
};

export { transitions };

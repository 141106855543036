import React from 'react'
import { Redirect } from 'react-router'

import { PrivateWorkspaceRouteExtraProps } from 'modules/workspace/containers'

import {
  DynamicLoadingPage,
  NotFoundView,
  RouteProps,
  routes,
} from '@sketch/modules-common'

import { HiddenProfileBanner } from '../components/HiddenProfileBanner'
import { EditingProfileNavBar } from '../components/EditingProfileNavBar'
import { WorkspaceProfileLayout } from '../containers/WorkspaceProfileLayout'
import { ProfileForm } from '../components/ProfileForm'

import { useIsProfileCreated } from '../hooks'

// GQL
import { useGetWorkspaceProfile } from '../hooks/useGetWorkspaceProfile'

import { WorkspaceSidebarLayoutExtraProps } from 'modules/workspace/components/WorkspaceSidebarLayout/WorkspaceSidebarLayout'

interface WorkspaceProfileEditViewRouteProps
  extends RouteProps<'WORKSPACE_PROFILE_EDIT'>,
    PrivateWorkspaceRouteExtraProps {}

type WorkspaceProfileEditViewProps = WorkspaceProfileEditViewRouteProps &
  WorkspaceSidebarLayoutExtraProps

const WorkspaceProfileEditView = (props: WorkspaceProfileEditViewProps) => {
  const {
    workspace,
    NavbarPortal,
    useOverrideLayoutProps,
    SubheaderPortal,
  } = props

  useOverrideLayoutProps({
    title: 'Profile',
    hasNavbar: false,
  })

  const profileResult = useGetWorkspaceProfile({
    variables: {
      workspaceIdentifier: workspace.identifier,
    },
  })

  if (profileResult.error) {
    return <NotFoundView />
  }

  const profile = profileResult.profile

  const profileData = {
    identifier: profile?.identifier || '',
    description: profile?.description || '',
    websiteUrl: profile?.websiteUrl || '',
    donateUrl: profile?.donateUrl || '',
    shortUrlName: profile?.shortUrlName || '',
  }

  return (
    <>
      <NavbarPortal>
        <EditingProfileNavBar shortUrlName={profileData.shortUrlName} />
      </NavbarPortal>

      {profile?.isPublic === false ? (
        <SubheaderPortal>
          <HiddenProfileBanner identifier={profileData.identifier} />
        </SubheaderPortal>
      ) : null}

      <WorkspaceProfileLayout
        workspaceId={workspace.identifier}
        isProfileEditRoute={true}
        profileResult={profileResult}
        profileInfo={
          <ProfileForm data={profileData} loading={profileResult.loading} />
        }
      />
    </>
  )
}

// At the moment only Admins of the workspace can access
// Profile Edit page. And this is the first component after
// PrivateWorkspaceRoute where we can check this.
// In addition we don't want to trigger useShowcaseYourDesignModal
// for non-admin members. Therefore we have a guard component here.
//
// If the profile is not created yet we also wanna prevent this page
// to be seen.
const WorkspaceProfileEditViewGuard = (
  props: WorkspaceProfileEditViewProps
) => {
  const { workspace } = props
  const workspaceId = workspace.identifier

  const { isProfileCreated, loading } = useIsProfileCreated(workspaceId)

  if (workspace.userRole !== 'ADMIN' || (!loading && !isProfileCreated)) {
    return <Redirect to={routes.WORKSPACE_SHARES.create({ workspaceId })} />
  }

  if (loading) {
    return <DynamicLoadingPage />
  }

  return <WorkspaceProfileEditView {...props} />
}

export default WorkspaceProfileEditViewGuard

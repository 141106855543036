import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { useTransition, config, animated } from 'react-spring';
import styled from 'styled-components';
import { StyledLink, ImageContainer, StyledResponsiveImage, Length, Title, Content } from './Insight.styles.js';

const BaseInsight = ({ id, image, title, source, length, url, onItemClick, imageRef, className, }) => {
    const transitions = useTransition(id, null, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.slow,
    });
    return (jsx(Fragment, { children: transitions.map(({ item, key, props }) => item && (jsx(animated.section, Object.assign({ className: className, "data-testid": "insight-item", style: props }, { children: jsxs(StyledLink, Object.assign({ href: url, rel: "noreferrer", target: "_blank", external: true, onClick: onItemClick }, { children: [jsxs(ImageContainer, Object.assign({ ref: imageRef }, { children: [jsx(StyledResponsiveImage, { image: image, withSkeletonUI: true }), length && jsxs(Length, { children: ["\u25B6 ", length] })] })), jsx(Title, { children: title }), jsx(Content, { children: source })] })) }), key))) }));
};
const Insight = styled(BaseInsight) ``;

export { Insight };

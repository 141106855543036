var OperationsSortCriteria;
(function (OperationsSortCriteria) {
    OperationsSortCriteria["DEFAULT"] = "Default";
    OperationsSortCriteria["NAME"] = "Name";
    OperationsSortCriteria["REQUEST_COUNT"] = "Request count";
    OperationsSortCriteria["TOTAL_DURATION"] = "Total duration";
    OperationsSortCriteria["MAX_DURATION"] = "Max duration";
    OperationsSortCriteria["LASt_OP_DURATION"] = "Duration";
    OperationsSortCriteria["DB_QUERY_COUNT"] = "DB query count";
    OperationsSortCriteria["DB_TOTAL_DECODE_TIME"] = "DB total decode time";
    OperationsSortCriteria["DB_TOTAL_QUERY_TIME"] = "DB total query time";
    OperationsSortCriteria["DB_TOTAL_QUEUE_TIME"] = "DB total queue time";
    OperationsSortCriteria["DB_TOTAL_TIME"] = "DB total time";
})(OperationsSortCriteria || (OperationsSortCriteria = {}));

export { OperationsSortCriteria };

import React, { useState } from 'react'

import { getMemberEmail } from 'modules/workspace/utils/members'

import { Button, Modal, ModalInjectedProps } from '@sketch/components'
import { MemberCard } from 'modules/workspace/components/MemberCard'

import { useToast } from '@sketch/toasts'
import {
  useEnablePersonalTokenUsage,
  useDisablePersonalTokenUsage,
} from './useSetPersonalTokenPermissions'

import { WorkspaceMembershipFragment } from '@sketch/gql-types'

type ChangePermissionsConfirmationModalProps = {
  member: WorkspaceMembershipFragment
  workspaceIdentifier: string
} & ModalInjectedProps

const ChangePermissionsConfirmationModal: React.FC<ChangePermissionsConfirmationModalProps> = props => {
  const { member, workspaceIdentifier, hideModal } = props

  const enablePersonalTokenUsage = useEnablePersonalTokenUsage()
  const disablePersonalTokenUsage = useDisablePersonalTokenUsage()

  const { showToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const { personalTokenPermissions } = member

  // Should never happen as the modal will only be opened for SSO members
  if (personalTokenPermissions === 'NON_SSO_MEMBER') return null

  const memberName = member.user?.name
  const memberEmail = getMemberEmail(member)

  const canUsePersonalAccessTokens =
    personalTokenPermissions === 'SSO_MEMBER_ALLOW'

  const onClickMutation = canUsePersonalAccessTokens
    ? disablePersonalTokenUsage
    : enablePersonalTokenUsage
  const successMsg = canUsePersonalAccessTokens
    ? `Personal access tokens are disabled for ${memberName}`
    : `Personal access tokens are enabled for ${memberName}`

  const primaryButtonOnClick = async () => {
    setIsLoading(true)

    const { data, errors } = await onClickMutation({
      member,
      workspaceIdentifier,
    })
    setIsLoading(false)

    if (!data || (errors && errors.length > 0)) return

    showToast(successMsg)
    hideModal()
  }

  const title = canUsePersonalAccessTokens
    ? 'Disable Personal Access Tokens?'
    : 'Enable Personal Access Tokens?'

  return (
    <Modal title={title} onCancel={hideModal}>
      <Modal.Body>
        {canUsePersonalAccessTokens
          ? 'After disabling personal access tokens, the Editor won’t able to use existing tokens or create new ones.'
          : 'After enabling personal access tokens, the Editor will be able to create tokens to access documents from Workspaces using an API.'}
        <br />
        <br />
        <MemberCard.BackgroundWrapper>
          <MemberCard
            name={memberName}
            email={memberEmail!}
            avatarSrc={member?.user?.avatar?.small}
          />
        </MemberCard.BackgroundWrapper>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={primaryButtonOnClick}
          variant="primary"
          disabled={isLoading}
        >
          {canUsePersonalAccessTokens ? 'Disable Tokens' : 'Enable Tokens'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangePermissionsConfirmationModal

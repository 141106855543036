import React, { useMemo } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'

import { localStorageKeys } from '@sketch/constants'
import { routes, deleteEntityFromCache } from '@sketch/modules-common'

import {
  Button,
  FormField,
  Input,
  Modal,
  ModalInjectedProps,
} from '@sketch/components'

import { useToast } from '@sketch/toasts'

import { getItem, removeItem } from '@sketch/utils'

import {
  useDeleteWorkspaceMutation,
  GetWorkspacesDocument,
} from '@sketch/gql-types'
import { Fields, StyledBanner, Warning } from './DeleteWorkspaceModal.styles'

interface DeleteWorkspaceModalProps extends ModalInjectedProps {
  id: string
  name: string
}

const initialValues = { name: '', password: '' }

/**
 * DeleteWorkspaceModal.
 *
 * This component shows a modal to the user with the ability of deleting a workspace.
 * To properly work this component should be call through the ModalContext API.
 *
 */
export const DeleteWorkspaceModal: React.FC<DeleteWorkspaceModalProps> = props => {
  const { id, name, hideModal } = props

  const history = useHistory()
  const { showToast } = useToast()

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .required('Workspace name is required')
          .oneOf(
            [name],
            'Workspace name should match the current workspace name'
          ),
        password: yup.string().required('Account password is required'),
      }),
    [name]
  )

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema,
    onSubmit: async ({ password }) =>
      deleteWorkspace({
        variables: { workspaceId: id, password },
      }),
  })

  const [deleteWorkspace, { loading }] = useDeleteWorkspaceMutation({
    onError: ({ message }) => {
      if (message === 'Incorrect password provided.') {
        formik.setFieldError(
          'password',
          'Incorrect password. Please check and try again'
        )

        return
      }

      showToast(message, 'negative')
    },
    update: (cache, { data }) => {
      /**
       * BohemianCoding/Cloud#1113
       * Delete the workspace from the apollo cache directly.
       *
       * It seems that re-fetching the workspaces list only helps with the workspace selector,
       * the user can still access the workspace information of a deleted workspace if we go back in the history,
       * because it's still in cache and we need to delete it.
       *
       * Currently being discussed here is the issue of "Cache invalidation & deletion" 😱.
       * https://github.com/apollographql/apollo-feature-requests/issues/4.
       *
       * There's still no correct solution for this, only work-arounds like this one.
       */
      if (data?.deleteWorkspace) {
        deleteEntityFromCache(cache, data.deleteWorkspace.deletedWorkspace)
      }
    },
    onCompleted: data => {
      const { deletedWorkspace } = data.deleteWorkspace || {}

      const storedWorkspaceId = getItem(localStorageKeys.lastWorkspaceIdKey)

      if (storedWorkspaceId === deletedWorkspace?.identifier) {
        removeItem(localStorageKeys.lastWorkspaceIdKey)
      }

      history.push(routes.ENTRY.create({}))

      showToast('Your workspace was successfully deleted.', 'positive')
      hideModal()
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GetWorkspacesDocument }],
  })

  const isDisabled = !formik.isValid

  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>Delete “{name}” Workspace?</Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <StyledBanner type="error" showIcon={false}>
            All documents in the “Sketch” Workspace will be permanently deleted
            — and access to billing information and invoices will be disabled.{' '}
            <Warning>You can’t undo this action.</Warning>
          </StyledBanner>
          <p>
            If you&apos;re sure about deleting everything, enter your Workspace
            name and account password to confirm.
          </p>
          <p>We&apos;re sorry to see you go!</p>
          <Fields>
            <FormField
              label="Workspace Name"
              name="name"
              errorText={formik.touched.name ? formik.errors.name : undefined}
            >
              <Input
                type="text"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
            </FormField>

            <FormField
              label="Your Account Password"
              name="password"
              errorText={
                formik.touched.password ? formik.errors.password : undefined
              }
            >
              <Input
                type="password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
            </FormField>
          </Fields>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={hideModal}
            type="button"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            disabled={isDisabled}
            type="submit"
            variant="negative-secondary"
            loading={loading}
          >
            Delete
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

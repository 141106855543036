import { jsx } from 'react/jsx-runtime';
import '../Tooltip/index.js';
import { StyledHelpIcon } from './LearnMoreTooltip.styles.js';
import { Tooltip } from '../Tooltip/Tooltip.js';

/**
 * Simple question mark icon with tooltip (can also be a link) to use when
 * we want to help the user to understand something
 * and optionally link to the Sketch documentation.
 */
function LearnMoreTooltip({ href, tooltipPlacement = 'top', tooltipContent, }) {
    const isLink = Boolean(href);
    const iconWithTooltip = (jsx(Tooltip, Object.assign({ tooltipContainerAs: "span", placement: tooltipPlacement, content: tooltipContent }, { children: jsx(StyledHelpIcon, { "$isLink": isLink }) })));
    if (isLink) {
        return (jsx("a", Object.assign({ target: "_blank", rel: "noopener noreferrer", href: href }, { children: iconWithTooltip })));
    }
    return iconWithTooltip;
}

export { LearnMoreTooltip };

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { TimeAgo, TableComponents, Truncate, Button, Table, useModalContext, Tabs, TabList, Tab, TabPanel } from '@sketch/components';
import { NameAndExpiryContainer, TableWrapper } from './TokenList.styles.js';
import '../../modals/TokenDetailsModal/index.js';
import '../../modals/DeleteTokenConfirmationModal/index.js';
import { dateFormat } from '@sketch/utils';
import DeleteTokenConfirmationModal from '../../modals/DeleteTokenConfirmationModal/DeleteTokenConfirmationModal.js';
import TokenDetailsModal from '../../modals/TokenDetailsModal/TokenDetailsModal.js';

const localizeDate = (dateStr) => dateFormat(new Date(dateStr));
const TokenTable = ({ tokens, showModal }) => {
    const tableHeader = [
        { label: 'Name' },
        { label: 'Last Used At' },
        { label: 'Actions' },
    ];
    const handleDeleteClick = (token) => {
        showModal(DeleteTokenConfirmationModal, { token });
    };
    const handleRowClick = (token) => {
        showModal(TokenDetailsModal, { token });
    };
    const tokenRow = (token) => {
        const now = new Date();
        const expired = !!token.expiresAt && new Date(token.expiresAt) <= now;
        const expiresAtPrefix = expired ? 'Expired on' : 'Valid until';
        const expiresAt = token.expiresAt
            ? `${expiresAtPrefix} ${localizeDate(token.expiresAt)}`
            : '';
        const lastUsedAt = token.lastUsedAt ? (jsxs(Fragment, { children: ["Used ", jsx(TimeAgo, { date: token.lastUsedAt })] })) : ('Unused');
        return (jsxs(TableComponents.TableRow, Object.assign({ "data-testid": `token-row-${token.identifier}`, onClick: () => handleRowClick(token) }, { children: [jsx(TableComponents.TableCell, { children: jsxs(NameAndExpiryContainer, Object.assign({ expired: expired }, { children: [jsx("span", { children: jsx(Truncate, Object.assign({ width: 130 }, { children: token.description })) }), jsx("span", { children: expiresAt })] })) }), jsx(TableComponents.TableCell, { children: lastUsedAt }), jsx(TableComponents.TableCell, { children: jsx(Button, Object.assign({ onClick: e => {
                            e.stopPropagation();
                            handleDeleteClick(token);
                        }, variant: "secondary-untinted" }, { children: "Delete..." })) })] })));
    };
    return (jsx(TableWrapper, { children: jsx(Table, { header: tableHeader, items: tokens, renderItem: tokenRow, hideHeader: true }) }));
};
const dateDescOrder = (token1, token2) => {
    const date1 = new Date(token1.createdAt);
    const date2 = new Date(token2.createdAt);
    return date2.getTime() - date1.getTime();
};
const TokenList = ({ tokens }) => {
    const now = new Date();
    // We show tokens ordered by their createdAt date, in descending order.
    // And all "active" tokens should appear before "expired" tokens.
    const sortedTokens = tokens.sort(dateDescOrder);
    const activeTokens = sortedTokens.filter(({ expiresAt }) => expiresAt === null || new Date(expiresAt) > now);
    const expiredTokens = sortedTokens.filter(({ expiresAt }) => expiresAt !== null && new Date(expiresAt) <= now);
    const allTokens = [...activeTokens, ...expiredTokens];
    const { showModal } = useModalContext();
    return (jsxs(Tabs, { children: [jsxs(TabList, { children: [jsxs(Tab, { children: [tokens.length, " Total"] }), jsxs(Tab, { children: [activeTokens.length, " Active"] }), jsxs(Tab, { children: [expiredTokens.length, " Expired"] })] }), jsx(TabPanel, { children: jsx(TokenTable, { tokens: allTokens, showModal: showModal }) }), jsx(TabPanel, { children: jsx(TokenTable, { tokens: activeTokens, showModal: showModal }) }), jsx(TabPanel, { children: jsx(TokenTable, { tokens: expiredTokens, showModal: showModal }) })] }));
};

export { TokenList as default };

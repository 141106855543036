import styled from 'styled-components'

import { ButtonUnstyled, Navbar } from '@sketch/components'

import { ReactComponent as Hamburger } from '@sketch/icons/list-lines-24'
import { breakpoint } from '@sketch/global-styles'

export const HamburgerIcon = styled(Hamburger)`
  width: 24px;
  height: 24px;
`

export const HamburgerButton = styled(ButtonUnstyled)`
  padding: 8px 12px;
  line-height: 0; /* Needed to avoid extra height around the icon */

  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;
`

export const SelectorSection = styled(Navbar.Section)`
  /* We dont allow this element to shrink to avoid the button being  */
  /* squashed by other menu items and effectively be hidden (in mobile) */
  flex: 0 0 auto;
  /* Prevent this section from sending it's siblings of screen */
  overflow: hidden;
`

export const SidebarWrapper = styled.section`
  display: flex;
  flex-direction: column;

  /* Limit the components width, preventing items with big name to have scroll */
  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.colors.background.secondary.B};
`

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

export const LoadingSidebarWorkspace = styled(Navbar)`
  background: none;
`

export const Margin = styled.div`
  padding: 16px 16px 0;
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%;

  /* The z-index is needed so the navbar stays on top of the table header */
  z-index: ${({ theme }) => theme.zIndex[1]};

  ${breakpoint('sm')`
    padding: 40px 40px 0;
  `}
`

export const NavbarPortalContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  overflow-x: auto;
`

import { jsx } from 'react/jsx-runtime';
import { MemberIconGroup, MemberIcon } from './PermissionGroupIcon.styles.js';
import styled from 'styled-components';

// We want to allow emojis if they are the first character, otherwise ignore all of them
// 'Hello 🌎 World 💥' renders 'HW'
// '🪃 Hello World!' renders '🪃'
const getFirstLettersOfWords = (text) => {
    const emojiRegex = /(\p{Emoji_Presentation}|\p{Extended_Pictographic})/u;
    const firstChar = Array.from(text)[0];
    if (emojiRegex.test(firstChar)) {
        return firstChar;
    }
    let result = '';
    const words = text.split(/\s+/);
    let count = 0;
    for (const word of words) {
        if (count >= 2)
            break;
        const firstCharacter = Array.from(word)[0];
        if (!emojiRegex.test(firstCharacter)) {
            result += firstCharacter;
            count++;
        }
    }
    return result;
};
const PermissionGroupIcon = ({ className, name, dark = false, size = '32px', }) => {
    const parsedName = getFirstLettersOfWords(name);
    return (jsx(MemberIconGroup, Object.assign({ className: className, "$size": size, "$dark": dark }, { children: jsx(MemberIcon, Object.assign({ "$dark": dark }, { children: parsedName })) })));
};
var PermissionGroupIcon$1 = styled(PermissionGroupIcon) ``;

export { PermissionGroupIcon$1 as default };

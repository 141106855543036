import { ColorFormat } from 'modules/shares/types'
import { capitalize, roundWithLocale } from '@sketch/utils'
import copyGradient from '../../../components/GradientAttribute/copy'
import { rgbTo } from '../../../components/Color/utils'
import {
  Fill,
  FillType,
  isGroupElementType,
  ElementType,
} from 'modules/inspector'

const fillCopy = (
  {
    isEnabled,
    type,
    color,
    gradient,
    patternFillType,
    patternTileScale,
    appearance,
  }: Fill,
  index: number,
  colorFormat?: ColorFormat,
  layerType?: ElementType
) => {
  if (!isEnabled) {
    return null
  }

  const blendMode = appearance?.blendMode
  const opacity = appearance?.opacity !== undefined ? appearance.opacity : 1

  let copyValue = null

  switch (type) {
    case FillType.Color: {
      if (color) {
        copyValue = `Color: ${rgbTo(colorFormat, color)}`
      }
      break
    }
    case FillType.Gradient: {
      if (gradient) {
        copyValue = copyGradient(gradient, appearance, colorFormat)
      }
      break
    }
    case FillType.Pattern: {
      if (patternFillType) {
        copyValue = `Type: Image\nDisplay: ${capitalize(patternFillType)}`
      }
      if (patternTileScale && typeof patternTileScale === 'number') {
        copyValue += `\nSize: ${roundWithLocale(patternTileScale * 100, 2)}%`
      }
      break
    }
    default:
      return null
  }

  copyValue = `${
    layerType && isGroupElementType(layerType) ? 'Tint' : 'Fill'
  } ${++index}\n${copyValue}`

  if (blendMode.toUpperCase() !== 'NORMAL') {
    copyValue += `\nBlend mode: ${capitalize(blendMode)}`
  }

  if (opacity < 1) {
    copyValue += `\nOpacity: ${(appearance.opacity * 100).toFixed()}%`
  }

  return copyValue
}

const copy = (
  fills: Fill[],
  colorFormat?: ColorFormat,
  layerType?: ElementType
) => {
  return fills
    .map((fill, index) => fillCopy(fill, index, colorFormat, layerType))
    .filter(f => f !== null)
    .join('\n\n')
}

export default copy

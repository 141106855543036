import { createContext } from 'react';
import { noop } from '@sketch/utils';

const defaultBannerContextValue = {
    items: [],
    showBanner: parameters => ({
        dismissBanner: noop,
    }),
    dismissBanner: () => { },
};
/**
 * Internal bannerContext.
 * The idea is not to import it directly in any other place other than this context directory.
 */
const bannerContext = createContext(defaultBannerContextValue);

export { bannerContext };

import styled from 'styled-components';
import { WorkspaceLogo as WorkspaceLogo$1, Text, Avatar } from '@sketch/components';

const Wrapper = styled.div `
  margin: 16px auto;
  position: relative;
  margin-bottom: 40px;
  padding: 0 16px;
`;
const ItemWrapper = styled.div `
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  padding: 16px 12px;
  border-radius: 6px;

  /* CSS Animation */
  &.badge-enter {
    opacity: 0;
  }
  &.badge-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  &.badge-exit {
    opacity: 1;
  }
  &.badge-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
`;
const WorkspaceLogo = styled(WorkspaceLogo$1) `
  margin-right: 16px;
`;
const Description = styled(Text.Span).attrs({
    textStyle: 'copy.secondary.standard.D',
}) `
  line-height: ${({ theme }) => theme.lineHeights.E};

  overflow: hidden;
  text-overflow: ellipsis;

  b {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
    white-space: nowrap;
  }
`;
const InviterAvatar = styled(Avatar) `
  min-width: 32px;
  margin-right: 12px;
`;

export { Description, InviterAvatar, ItemWrapper, WorkspaceLogo, Wrapper };

import { useEffect } from 'react';

const FOCUSABLE_ELEMENTS = `
a[href]:not([disabled]),
button:not([disabled]),
textarea:not([disabled]),
input:not([disabled]),
select:not([disabled]),
[tabindex='0']
`;
/**
 * useDropdownKeyEvents
 *
 * This hook should contain events to improve accessibility.
 *
 * Events implemented:
 * - When clicking ESC the dropdown should be closed and the trigger gets focused
 * - When clicking outside of the dropdown it should be hidden
 * - When clicking Tab inside the dropdown focus gets trapped (so you can loop
 *   through items without leaving until you click ESC or Enter)
 * - When a dropdown item has been clicked it should close the dropdown
 */
const useDropdownKeyEvents = ({ visible, triggerRef, dropdownElement, openedByKeyboard, setOpenedByKeyboard, hide, onHide, }) => {
    const hideWhenClicked = hide.includes('clickedInsideContent');
    const hideWhenClickedOutside = hide.includes('clickedOutsideContent');
    const hideWhenPressedEscape = hide.includes('pressedEscape');
    /**
     * Focus on first element when dropdown is opened by keyboard, this should be
     * done with events, but it's tricky because the trigger and dropdown are not
     * in the DOM at the same time, so it's easier to just use the state
     */
    useEffect(() => {
        if (!visible || !openedByKeyboard || !dropdownElement) {
            return;
        }
        const firstItem = dropdownElement === null || dropdownElement === void 0 ? void 0 : dropdownElement.querySelector(FOCUSABLE_ELEMENTS);
        if (firstItem) {
            firstItem.focus();
        }
        setOpenedByKeyboard(false);
    }, [visible, openedByKeyboard, setOpenedByKeyboard, dropdownElement]);
    useEffect(() => {
        if (!visible) {
            return;
        }
        const trigger = triggerRef.current instanceof HTMLElement ? triggerRef.current : undefined;
        /**
         * Hide the dropdown when the user has clicked the "escape" key
         */
        const handleDocumentKeyboardEvent = (event) => {
            if (event.key === 'Escape') {
                onHide('pressedEscape');
                trigger === null || trigger === void 0 ? void 0 : trigger.focus();
            }
        };
        /**
         * Hide the dropdown when the user has clicked outside of the trigger or dropdown
         */
        const handleDocumentClickOutside = (event) => {
            const target = event.target;
            const hasClickedInside = (dropdownElement === null || dropdownElement === void 0 ? void 0 : dropdownElement.contains(target)) ||
                (trigger === null || trigger === void 0 ? void 0 : trigger.contains(target)) ||
                target === dropdownElement ||
                target === trigger;
            !hasClickedInside && onHide('clickedOutsideContent');
        };
        /**
         * Traps the focus inside the dropdown when using Tab, looping through focusable elements
         */
        const handleTabInsideDropdown = (event) => {
            if (event.key !== 'Tab') {
                return;
            }
            const firstItem = dropdownElement === null || dropdownElement === void 0 ? void 0 : dropdownElement.querySelector(FOCUSABLE_ELEMENTS);
            const lastItem = Array.from((dropdownElement === null || dropdownElement === void 0 ? void 0 : dropdownElement.querySelectorAll(FOCUSABLE_ELEMENTS)) || []).pop();
            // ⇧ + Tab on the first item -> focus last item
            if (firstItem === event.target && event.shiftKey) {
                event.preventDefault();
                lastItem === null || lastItem === void 0 ? void 0 : lastItem.focus();
            }
            // Tab on the last item -> focus first item
            if (lastItem === event.target && !event.shiftKey) {
                event.preventDefault();
                if (firstItem) {
                    firstItem.focus();
                }
            }
        };
        /**
         * Hide the dropdown when any item inside of it has been clicked
         */
        const handleClickEvent = (event) => {
            const target = event.target;
            if (target.nodeName === 'BUTTON') {
                event.preventDefault();
            }
            window.requestAnimationFrame(() => {
                onHide('clickedInsideContent', target);
            });
        };
        dropdownElement === null || dropdownElement === void 0 ? void 0 : dropdownElement.addEventListener('keydown', handleTabInsideDropdown);
        hideWhenClicked &&
            (dropdownElement === null || dropdownElement === void 0 ? void 0 : dropdownElement.addEventListener('click', handleClickEvent));
        hideWhenClickedOutside &&
            document.body.addEventListener('click', handleDocumentClickOutside);
        hideWhenPressedEscape &&
            document.body.addEventListener('keydown', handleDocumentKeyboardEvent);
        return () => {
            dropdownElement === null || dropdownElement === void 0 ? void 0 : dropdownElement.removeEventListener('keydown', handleTabInsideDropdown);
            hideWhenClicked &&
                (dropdownElement === null || dropdownElement === void 0 ? void 0 : dropdownElement.removeEventListener('click', handleClickEvent));
            hideWhenClickedOutside &&
                document.body.removeEventListener('click', handleDocumentClickOutside);
            hideWhenPressedEscape &&
                document.body.removeEventListener('keydown', handleDocumentKeyboardEvent);
        };
    }, [
        visible,
        onHide,
        dropdownElement,
        triggerRef,
        hideWhenClicked,
        hideWhenClickedOutside,
        hideWhenPressedEscape,
    ]);
};

export { useDropdownKeyEvents };

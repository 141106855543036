import React from 'react'
import { useParams } from 'react-router-dom'
import { OperationVariables } from 'apollo-client'
import get from 'lodash.get'

import { ArtboardList } from '../ArtboardList'

import { ResponsiveValues } from '@sketch/global-styles'
import { useVersioning } from 'modules/versioning'
import PrototypesEmptyState from '../PrototypesEmptyState'
import { PrototypeContextProvider } from 'modules/prototype/hooks'
import { PrototypeRendererProvider } from '@sketch-hq/sketch-web-renderer'

import {
  RouteParams,
  RetryQueryProps,
  useQueryRetry,
} from '@sketch/modules-common'

// Graphql
import {
  ArtboardDetailInfoFragment,
  useGetArtboardsForDocumentQuery,
} from '@sketch/gql-types'

type RetryIfBuilder = (
  path: string[]
) => RetryQueryProps<any, OperationVariables>['retryIf']

const buildRetryIf: RetryIfBuilder = path => ({ data, error }) => {
  if (error) return true

  const artboards = get(data, path, []) as ArtboardDetailInfoFragment[]
  const files = artboards.map(a => a.files).flat()

  return files.some(file => !file?.url)
}

interface DocumentPageProps {
  search: string
  columns: ResponsiveValues<number>
}

const entriesPath = ['share', 'version', 'document', 'artboards', 'entries']
const afterPath = ['share', 'version', 'document', 'artboards', 'meta', 'after']

/**
 * Handles a list of artboards scoped to a specific share document
 * grouped by page.
 */
const DocumentPrototypes = ({ columns, search }: DocumentPageProps) => {
  const { versionShortId } = useVersioning()
  const { shareID } = useParams<RouteParams<'SHARE_VIEW'>>()

  const { data, loading, error, fetchMore } = useQueryRetry(
    useGetArtboardsForDocumentQuery({
      variables: {
        identifier: shareID,
        versionShortId,
        search,
        /**
         * Note: The `PROTOTYPE` filter value filters for Artboards that are Prototype start points.
         */
        filter: 'PROTOTYPE',
      },
    }),
    { retryIf: buildRetryIf(entriesPath) }
  )

  const userIsEditor = data?.share.userCanEdit
  const hasPrototypes =
    data?.share.version.document.artboards.meta.totalCount > 0

  if (!loading && !hasPrototypes) {
    return <PrototypesEmptyState userIsEditor={userIsEditor} />
  }

  return (
    <PrototypeRendererProvider>
      <PrototypeContextProvider>
        <ArtboardList
          error={error}
          data={data}
          filterValue={search}
          loading={loading}
          fetchMore={fetchMore}
          columns={columns}
          afterPath={afterPath}
          entriesPath={entriesPath}
          enablePrototypeCards
        />
      </PrototypeContextProvider>
    </PrototypeRendererProvider>
  )
}

export default DocumentPrototypes

import styled from 'styled-components';

const VisuallyHidden = styled.span.attrs({
    className: 'visuallyHidden',
}) `
  /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
  position: absolute !important; /* stylelint-disable-line declaration-no-important */
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
`;

export { VisuallyHidden };

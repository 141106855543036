import { jsxs, jsx } from 'react/jsx-runtime';
import { PermissionGroupIcon, Text, TruncateWithTooltip, pluralize } from '@sketch/components';
import { Wrapper, TextWrapper, TitleText } from './PermissionGroupItem.styles.js';

const PermissionGroupItem = (props) => {
    const { className, permissionGroup } = props;
    return (jsxs(Wrapper, Object.assign({ className: className, "data-testid": "permission-group-item" }, { children: [jsx(PermissionGroupIcon, { name: permissionGroup.name }), jsxs(TextWrapper, { children: [jsx(Text.H1, Object.assign({ textStyle: "prominent.secondary.E", "$reset": true }, { children: jsx(TruncateWithTooltip, Object.assign({ style: { overflowWrap: 'break-word' } }, { children: jsx(TitleText, { children: permissionGroup.name }) })) })), jsxs(Text, Object.assign({ textStyle: "copy.quaternary.standard.D" }, { children: [permissionGroup.members.meta.totalCount, ' ', pluralize('Member', 'Members', permissionGroup.members.meta.totalCount)] }))] })] })));
};

export { PermissionGroupItem as default };

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import React from 'react';
import styled, { css } from 'styled-components';

const AvatarWrapper = styled.span.attrs(props => ({
    style: {
        width: props.size,
        height: props.size,
        fontSize: props.size,
    },
})) `
  display: block;

  border-radius: 50%;
  position: relative;
  box-shadow: ${({ theme }) => theme.shadows.boxShadow.subtle};
  overflow: hidden;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
`;
const absoluteFullItems = css `
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
`;
const AvatarPlaceholder = styled.span `
  ${absoluteFullItems};

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: ${({ theme }) => theme.fontSizes.C};
  font-weight: 500;
  text-transform: uppercase;

  /* This is a valid use case to not use a design token, it's a white letter
  inside an orange circle */
  color: white;
  background-color: ${({ theme }) => theme.colors.sketch.A};
`;
const AvatarImage = styled.img `
  ${absoluteFullItems};
  background-color: ${({ theme }) => theme.colors.background.secondary.B};

  object-fit: cover;
  object-position: center;
`;
/* eslint-disable react/display-name */
/**
 * Avatar
 *
 * Shows the user avatar image if has the image source, if not the first letter of the user name
 *
 * @param props - Component Props.
 *
 * @param {string} [props.className] - Additional styling class for the AvatarWrapper.
 * @param {string} [props.name] - The user name.
 * @param {string} [props.src] - The source of the user image.
 * @param {Size} [props.size] - Size of the Avatar
 *
 * @returns {React.ReactElement} React element.
 *
 * @example
 *
 * // Example without Image
 * <Avatar name="Pieter" />
 *
 * // Example with Image
 * <Avatar name="Pieter" src="https://pieter.image.com" />
 *
 */
const Avatar = React.forwardRef((props, ref) => {
    const { className, 'data-testid': dataTestId, name, src, size } = props, otherProps = __rest(props, ["className", 'data-testid', "name", "src", "size"]);
    let content;
    if (!src) {
        content = (jsx(AvatarPlaceholder, Object.assign({ "data-testid": dataTestId }, { children: name.charAt(0) })));
    }
    else {
        content = jsx(AvatarImage, { "data-testid": dataTestId, src: src, alt: name });
    }
    return (jsx(AvatarWrapper, Object.assign({ className: className, size: size, ref: ref }, otherProps, { children: content })));
});
Avatar.defaultProps = {
    name: '',
    size: '28px',
};

export { Avatar, AvatarPlaceholder };

import { jsx } from 'react/jsx-runtime';
import { LoadingWorkspaceSidebarLayout } from '@sketch/components';
import { useFlag } from '../../flags.js';

const WorkspaceSidebarLoadingPage = () => {
    const isRefreshedUi = useFlag('ui-refresh-24');
    return jsx(LoadingWorkspaceSidebarLayout, { isRefreshedUi: isRefreshedUi });
};

export { WorkspaceSidebarLoadingPage };

import styled, { css } from 'styled-components';
import { Navbar, Heading } from '@sketch/components';
import { breakpoint } from '@sketch/global-styles';

const Wrapper = styled.div.attrs({ 'data-testid': 'index-layout' }) `
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  background: ${({ theme }) => theme.colors.background.secondary.A};
`;
const Header = styled(Navbar) `
  position: sticky;
  top: 0;
  padding: 0;
  z-index: 1;

  ${({ hasLink, $noBorder }) => ($noBorder || !hasLink) &&
    css `
      border: none;
    `}
`;
const LogoWrapper = styled.div `
  display: flex;
  justify-content: center;
  width: 56px;
  border-right: 1px solid ${({ theme }) => theme.colors.border.B};

  ${breakpoint('base', 'sm') `
    border-right: none;
  `}
`;
const FooterWrapper = styled.div `
  margin-top: auto;

  footer {
    padding-top: 40px;
  }
`;
const Main = styled.main `
  width: 100%;

  ${({ isCreatingWorkspace }) => isCreatingWorkspace
    ? css `
          width: 500px;
          margin-top: 32px;

          ${breakpoint('base', 'sm') `
            width: auto;
          `};
        `
    : css `
          margin-top: 139px;
        `}

  max-width: ${({ fullWidth, maxContainerWidth }) => fullWidth ? '100%' : maxContainerWidth ? maxContainerWidth : '500px'};

  ${breakpoint('base', 'sm') `
    padding: 0 16px;
    margin-top: 33px;
  `};
`;
const Subtitle = styled(Heading.H2) `
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.H};
  line-height: 1.4;
  text-align: center;
  font-weight: 400;

  margin-top: 8px;
  margin-bottom: 24px;

  ${breakpoint('sm') `
      font-size: 1.25rem;
      margin-top: 8px;
  `}
`;
const Title = styled.h1(({ theme }) => css `
    color: ${theme.colors.foreground.secondary.A};
    margin-top: 0px;
    margin-bottom: 0;
    font-size: ${theme.fontSizes.I};
    text-align: center;
    line-height: 1;

    + :not(${ /* sc-selector */Subtitle}) {
      margin-top: 32px;
    }

    + ${Subtitle} {
      font-size: ${theme.fontSizes.F};
    }

    ${breakpoint('base', 'sm') `
      font-size: ${theme.fontSizes.H};
    `}
  `);
const Content = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  /** Used to push the content to the right to compensate for the sidebar image on the left  and center the content to the white main area */
  margin-left: 56px;

  /** In mobile, the sidebar disappears so we don't need the margin */
  ${breakpoint('base', 'sm') `
     margin-left: 0;
  `}
`;

export { Content, FooterWrapper, Header, LogoWrapper, Main, Subtitle, Title, Wrapper };

import { useRef, useEffect } from 'react';

/**
 * Using the visibility change APIs, we track if the user switched tab at some point,
 * This is useful in case we're tracking time metrics from the page load and we want to discard
 * them in case the tab changes, to prevent to also count the time spent on another tab
 * @see https://caniuse.com/?search=visibilityState
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 * @returns a ref object that is `true` if the tab became inactive
 */
function useDidBrowserTabChange() {
    // If the user opens a page in a new tab, the tab will start as hidden
    const didBrowserTabChangeRef = useRef(document.visibilityState === 'hidden');
    useEffect(() => {
        function handleVisibilityChange() {
            if (document.visibilityState === 'hidden') {
                didBrowserTabChangeRef.current = true;
            }
        }
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    return didBrowserTabChangeRef;
}

export { useDidBrowserTabChange };

import styled, { css } from 'styled-components'
import { ReactComponent as DSIcon } from '@sketch/icons/default-ds-icon'
import { Tooltip } from '@sketch/components'

export const StyledDocumentCardIcons = styled.div`
  position: relative;
  top: 2px;

  height: 0px; /* This ensures the icons do not add extra height to the line */

  display: inline-flex;
  align-items: center;
`

const LogoBase = css`
  height: 16px;
  width: 16px;
  border-radius: ${({ theme }) => theme.radii.small};
`

export const DSLogo = styled.img`
  ${LogoBase}
`

export const PlaceholderDSLogo = styled(DSIcon)(
  ({ theme }) => css`
    ${LogoBase};
    color: ${theme.colors.foreground.secondary.D};
    background-color: ${theme.colors.background.secondary.A};
  `
)

export const StyledTooltip = styled(Tooltip)`
  display: flex;
  padding: 0 2px; /* stylelint-disable-line scales/space */
`

export const StyledIcon = styled.svg.attrs({
  role: 'img',
})<{ $isRefreshedUi: boolean }>(
  ({ theme: { colors }, $isRefreshedUi }) => css`
    ${!$isRefreshedUi &&
    css`
      margin-left: 4px;
    `}

    width: 16px;
    height: 16px;

    /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
    /* Opacity needs to be applied to the whole object */
    color: ${colors.foreground.secondary.A};
    opacity: 0.55;
  `
)

import { jsxs, jsx } from 'react/jsx-runtime';
import styled, { css } from 'styled-components';
import '../Button/index.js';
import { breakpoint } from '@sketch/global-styles';
import { LinkButton } from '../Button/LinkButton.js';
import { AnchorButton } from '../Button/AnchorButton.js';
import { ButtonUnstyled } from '../Button/ButtonUnstyled.js';
import { BaseButton } from '../Button/Button.styles.js';

const StyledLinkButton = styled(LinkButton) ``;
const StyledAnchorButton = styled(AnchorButton) ``;
const commonFooterStyles = css `
  ${ /* sc-selector */StyledAnchorButton},
  ${ /* sc-selector */ButtonUnstyled}, ${ /* sc-selector */BaseButton},
    ${StyledLinkButton} {
    flex-grow: 1;
    margin-bottom: 16px;
    margin-left: 16px;
  }

  button {
    /* force small tweaks for footer */
    min-height: 40px;
  }
`;
const Wrapper = styled.footer(({ theme, $hasLeftContent }) => css `
    ${$hasLeftContent &&
    css `
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      ${breakpoint('base', 'xs') `
        flex-direction: column-reverse;
      `}
    `}

    padding: 16px 16px env(safe-area-inset-bottom) 0;
    border-top: 1px solid ${theme.colors.border.A};
    text-align: right;

    position: sticky;
    bottom: 0;

    /* Hide the content when it scrolls */
    background: ${theme.colors.background.secondary.A};

    ${breakpoint('sm') `
      /* We need to force the radius here because of Safari, it ignores it when elements are sticky */
      border-radius: 0 0 ${theme.radii.xxxlarge} ${theme.radii.xxxlarge};
    `}
  `);
const LeftContent = styled.div `
  display: inline-flex;

  ${commonFooterStyles}
`;
const ButtonWrapper = styled.div `
  display: inline-flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  max-width: 100%;

  ${commonFooterStyles}
`;
/*
 * We had to create a second wrapper (ButtonWrapper) for the modal footer
 * in order to fix a mobile bug where the buttons would render on top of
 * each other without proper margins
 *
 * We force the buttons to be aligned to the right and have the
 * minimum possible width while keeping the border-top and background correctly
 * rendered in the desktop breakpoints
 *
 * In smaller breakpoints the buttons stack and grow inside the modal footer.
 * The footer padding (left and bottom) is replaced by a margin in the buttons
 * styles.
 *
 * This solution covers all breakpoints without having to specify them in
 * the styles with a breakpoint media query
 *
 * Please check the video in the ticket:
 * https://github.com/sketch-hq/cloud-frontend/pull/2510
 */
const ModalFooterBase = ({ children, className, leftContent }) => (jsxs(Wrapper, Object.assign({ "data-testid": "modal-footer", className: className, "$hasLeftContent": !!leftContent }, { children: [leftContent && jsx(LeftContent, { children: leftContent }), jsx(ButtonWrapper, { children: children })] })));
const ModalFooter = styled(ModalFooterBase) ``;

export { ModalFooter };

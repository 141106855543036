import React from 'react'
import { ColorFormat } from 'modules/shares/types'
import { Header, HeaderTitle, Section, Separator } from '../../components'
import { Shadows } from './Shadows'
import { Blur } from './Blur'
import { Fills } from './Fills'
import { Borders } from './Borders'
import {
  LayerStyle,
  LayerStyleProperties,
  ElementType,
} from 'modules/inspector'

const isThereContent = (subsection: Array<{ isEnabled: boolean }> = []) =>
  subsection.some(item => item.isEnabled)

export interface LayerStyleProps {
  style: LayerStyle
  onColorFormatChange: (f: ColorFormat) => void
  colorFormat: ColorFormat
  layerType?: ElementType
  hideTitle?: boolean
}

function getOriginalValuesIfDirty<TPropName extends keyof LayerStyleProperties>(
  style: LayerStyle,
  propName: TPropName
) {
  if (
    'isDirty' in style &&
    'originalSharedStyleValues' in style &&
    style.isDirty
  ) {
    return style.originalSharedStyleValues?.[propName]
  }

  return undefined
}

export const Style: React.FC<LayerStyleProps> = ({
  style,
  onColorFormatChange,
  colorFormat,
  layerType,
  hideTitle,
}) => {
  const borders =
    'borders' in style && isThereContent(style.borders) ? (
      <Borders
        borders={style.borders!}
        onColorFormatChange={onColorFormatChange}
        colorFormat={colorFormat}
        borderOptions={style.borderOptions}
        originalValues={getOriginalValuesIfDirty(style, 'borders')}
        originalValuesOptions={getOriginalValuesIfDirty(style, 'borderOptions')}
      />
    ) : null

  const fills = isThereContent(style.fills) ? (
    <Fills
      fills={style.fills!}
      onColorFormatChange={onColorFormatChange}
      colorFormat={colorFormat}
      layerType={layerType}
      originalValues={getOriginalValuesIfDirty(style, 'fills')}
    />
  ) : null

  const normalShadows = isThereContent(style.shadows) ? (
    <Shadows
      shadows={style.shadows!}
      onColorFormatChange={onColorFormatChange}
      colorFormat={colorFormat}
      originalValues={getOriginalValuesIfDirty(style, 'shadows')}
    />
  ) : null

  const innerShadows =
    'innerShadows' in style && isThereContent(style.innerShadows) ? (
      <Shadows
        title="Inner Shadows"
        shadows={style.innerShadows!}
        onColorFormatChange={onColorFormatChange}
        colorFormat={colorFormat}
        originalValues={getOriginalValuesIfDirty(style, 'innerShadows')}
      />
    ) : null

  const blur =
    'blur' in style && style.blur?.isEnabled ? (
      <Blur
        {...style.blur}
        originalValues={getOriginalValuesIfDirty(style, 'blur')}
      />
    ) : null

  const content = [fills, borders, normalShadows, innerShadows, blur].filter(
    item => item !== null
  )

  return content.length > 0 ? (
    <>
      {!hideTitle && <Separator />}
      <Section data-testid="inspector-sidebar-style">
        {!hideTitle && (
          <Header>
            <HeaderTitle>Style</HeaderTitle>
          </Header>
        )}
        {content.map((element, i) => (
          <React.Fragment key={i}>{element}</React.Fragment>
        ))}
      </Section>
    </>
  ) : null
}

export default Style

import { getAllTemplatesForVersionedRoute, routes, versionedRoutes } from '../routes.js';

/**
 * CONSTANTS
 */
const CWV_ROUTES = [
    ...getAllTemplatesForVersionedRoute('CWV_SYMBOLS'),
    ...getAllTemplatesForVersionedRoute('CWV_TEXT_STYLES'),
    ...getAllTemplatesForVersionedRoute('CWV_LAYER_STYLES'),
    ...getAllTemplatesForVersionedRoute('CWV_COLOR_VARIABLES'),
];
/**
 * HELPERS
 *
 * To idenfity routes using react router `path`. Usage:
 * ```
 * const Foo = () => {
 *   const { path } = useRouteMatch()
 *   ...
 *   return isDocumentRoute(path) ? ... : ...;
 * }
 * ```
 */
const isAllDocumentsRoute = (path) => routes.ALL_DOCUMENTS.template() === path;
const isWorkspaceRoute = (path) => routes.WORKSPACE_SHARES.template() === path;
const isDocumentRoute = (path) => versionedRoutes.SHARE_VIEW.LATEST.template() === path ||
    versionedRoutes.SHARE_VIEW.VERSION.template() === path;
const isDocumentPageRouteLegacy = (path) => versionedRoutes.SHARE_PAGE_VIEW.LATEST.template() === path ||
    versionedRoutes.SHARE_PAGE_VIEW.VERSION.template() === path;
const isDocumentPageRouteCanvas = (path) => versionedRoutes.SHARE_PAGE_CANVAS_VIEW.LATEST.template() === path ||
    versionedRoutes.SHARE_PAGE_CANVAS_VIEW.VERSION.template() === path;
const isDocumentPageRoute = (path) => isDocumentPageRouteLegacy(path) || isDocumentPageRouteCanvas(path);
const isDocumentPrototypeRoute = (path) => versionedRoutes.SHARE_PROTOTYPES.LATEST.template() === path ||
    versionedRoutes.SHARE_PROTOTYPES.VERSION.template() === path;
const isArtboardRoute = (path) => versionedRoutes.ARTBOARD_DETAIL.LATEST.template() === path ||
    versionedRoutes.ARTBOARD_DETAIL.VERSION.template() === path ||
    versionedRoutes.ARTBOARD_REVISION.LATEST.template() === path ||
    versionedRoutes.ARTBOARD_REVISION.VERSION.template() === path;
const isTrashRoute = (path) => routes.WORKSPACE_TRASH.template() === path;
const isProjectTrashRoute = (path) => routes.WORKSPACE_TRASH_PROJECT.template() === path;
const isDraftsRoute = (path) => routes.WORKSPACE_DRAFTS.template() === path;
const isCwvRoute = (path) => !!CWV_ROUTES.find(route => route === path) ||
    isDocumentRoute(path) ||
    isDocumentPageRoute(path) ||
    isDocumentPrototypeRoute(path);
const isCwvRouteOnly = (path) => !!CWV_ROUTES.find(route => route === path);
const isCwvSpecificRoute = (path, route) => !!getAllTemplatesForVersionedRoute(route).find(r => r === path);
const getWhichCwvRoute = (path) => {
    let links;
    (function (links) {
        links["SHARE_VIEW"] = "SHARE_VIEW";
        links["CWV_SYMBOLS"] = "CWV_SYMBOLS";
        links["CWV_TEXT_STYLES"] = "CWV_TEXT_STYLES";
        links["CWV_LAYER_STYLES"] = "CWV_LAYER_STYLES";
        links["CWV_COLOR_VARIABLES"] = "CWV_COLOR_VARIABLES";
    })(links || (links = {}));
    if (isCwvSpecificRoute(path, links.SHARE_VIEW)) {
        return links.SHARE_VIEW;
    }
    if (isCwvSpecificRoute(path, links.CWV_SYMBOLS)) {
        return links.CWV_SYMBOLS;
    }
    if (isCwvSpecificRoute(path, links.CWV_LAYER_STYLES)) {
        return links.CWV_LAYER_STYLES;
    }
    if (isCwvSpecificRoute(path, links.CWV_TEXT_STYLES)) {
        return links.CWV_TEXT_STYLES;
    }
    return links.CWV_COLOR_VARIABLES;
};
const isDesignSystemRoute = (path) => routes.DESIGN_SYSTEM.template() === path;
const isLibrariesRoute = (path) => routes.WORKSPACE_LIBRARIES.template() === path;
const isSharedWithMeRoute = (path) => routes.WORKSPACE_SHARED_WITH_ME.template() === path;
const isTemplatesRoute = (path) => routes.WORKSPACE_TEMPLATES.template() === path;
const isProjectRoute = (path) => routes.WORKSPACE_PROJECT.template() === path;
const isCollectionRoute = (path) => routes.WORKSPACE_COLLECTION.template() === path;
const isInspectorRoute = (path, hash) => (isDocumentRoute(path) || isArtboardRoute(path)) && hash === '#Inspect';
const isWorkspaceSettingsRoute = (path) => routes.WORKSPACE_SETTINGS.template() === path ||
    routes.WORKSPACE_SETTINGS_SETTINGS.template() === path ||
    routes.WORKSPACE_SETTINGS_PEOPLE.template() === path ||
    routes.WORKSPACE_SETTINGS_BILLING.template() === path ||
    routes.WORKSPACE_SETTINGS_PERMISSION_GROUPS.template() === path ||
    routes.WORKSPACE_SETTINGS_PROFILE.template() === path ||
    routes.WORKSPACE_SETTINGS_SSO.template() === path ||
    routes.WORKSPACE_AGNOSTIC_SETTINGS.template() === path ||
    routes.WORKSPACE_AGNOSTIC_SETTINGS_SETTINGS.template() === path ||
    routes.WORKSPACE_AGNOSTIC_SETTINGS_PEOPLE.template() === path ||
    routes.WORKSPACE_AGNOSTIC_SETTINGS_BILLING.template() === path ||
    routes.WORKSPACE_AGNOSTIC_SETTINGS_SETTINGS.template() === path ||
    routes.WORKSPACE_AGNOSTIC_SETTINGS_SSO.template() === path;
const isPersonalSettingsRoute = (path) => routes.PERSONAL_SETTINGS.template() === path ||
    routes.PERSONAL_SETTINGS_ACCESS_TOKENS.template() === path ||
    routes.PERSONAL_SETTINGS_ACCESS_TOKENS_CREATE.template() === path ||
    routes.PERSONAL_SETTINGS_DISABLE_MFA.template() === path ||
    routes.PERSONAL_SETTINGS_UNSUBSCRIBE.template() === path ||
    routes.APPEARANCE_SETTINGS.template() === path;
const isDiscoverRoute = (path) => routes.WORKSPACE_DISCOVER.template() === path;
const isUpdatesRoute = (path) => routes.UPDATES.template() === path || routes.UPDATES_READ.template() === path;

export { CWV_ROUTES, getWhichCwvRoute, isAllDocumentsRoute, isArtboardRoute, isCollectionRoute, isCwvRoute, isCwvRouteOnly, isCwvSpecificRoute, isDesignSystemRoute, isDiscoverRoute, isDocumentPageRoute, isDocumentPageRouteCanvas, isDocumentPageRouteLegacy, isDocumentPrototypeRoute, isDocumentRoute, isDraftsRoute, isInspectorRoute, isLibrariesRoute, isPersonalSettingsRoute, isProjectRoute, isProjectTrashRoute, isSharedWithMeRoute, isTemplatesRoute, isTrashRoute, isUpdatesRoute, isWorkspaceRoute, isWorkspaceSettingsRoute };

import { jsx, jsxs } from 'react/jsx-runtime';
import { useBreakpoint, Avatar } from '@sketch/components';
import { WorkspaceName, ButtonPill, AvatarWrapper } from './MyWorkspacePill.styles.js';

/**
 * This components is ported version of "My Workspace" button found on
 * marketing website.
 *
 * Like on marketing website, we are showing user avatar instead of the workspace
 * avatar. If that will ever diverge between marketing website and cloud-frontend app
 * use marketing website as the source of truth.
 */
const MyWorkspacePill = (props) => {
    var _a;
    const { user, children = jsx(WorkspaceName, { children: "My Workspace" }), } = props;
    const isDesktop = useBreakpoint('sm');
    const avatar = (jsx(Avatar, { src: (_a = user === null || user === void 0 ? void 0 : user.avatar) === null || _a === void 0 ? void 0 : _a.small, name: (user === null || user === void 0 ? void 0 : user.name) ||
            // if user name is not available, default to `My Workspace` name
            // as this link will lead to a workspace either way
            'My Workspace', size: "24px" }));
    if (!isDesktop) {
        return avatar;
    }
    return (jsxs(ButtonPill, Object.assign({ variant: "primary-untinted" }, { children: [jsx(AvatarWrapper, { children: avatar }), children] })));
};

export { MyWorkspacePill };

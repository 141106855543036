import {
  GetUserSettingsDocument,
  GetWorkspacesDocument,
  useUpgradePersonalWorkspaceMutation,
} from '@sketch/gql-types'

import { WorkspaceCreationState } from '../../types'
import {
  formatMutationInput,
  CreateWorkspaceResponse,
} from '../useCreateWorkspace/utils'

export interface UseUpgradePersonalWorkspaceProps {
  onComplete: (workspace: CreateWorkspaceResponse) => void
  onError: (error: string) => void
}

export const useUpgradePersonalWorkspace = (
  props: UseUpgradePersonalWorkspaceProps
) => {
  const { onComplete, onError } = props

  const [
    upgradePersonalWorkspaceMutation,
    ...mutation
  ] = useUpgradePersonalWorkspaceMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GetWorkspacesDocument },
      { query: GetUserSettingsDocument },
    ],
    onCompleted: data => {
      onComplete(data.upgradePersonalWorkspace.workspace)
    },
    onError: ({ message }) => {
      onError(message)
    },
  })

  const upgradePersonalWorkspace = (
    workspaceId: string,
    state: WorkspaceCreationState,
    userTosAgreed: boolean,
    userPrivacyPolicyAgreed: boolean
  ) =>
    upgradePersonalWorkspaceMutation({
      variables: {
        input: {
          ...formatMutationInput(state),
          identifier: workspaceId,
          userTosAgreed,
          userPrivacyPolicyAgreed,
        },
      },
    })

  return [upgradePersonalWorkspace, ...mutation] as const
}

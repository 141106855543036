import merge from 'lodash.merge'

import type { Resolvers } from 'apollo-client'
import type {
  LocalResolvers,
  LocalResolver,
  LocalResolverFn,
} from '@sketch/gql-types/resolvers'

// Picking random combination of Typename + FieldName
// We don't really care which care which combination it will be
// we just want to "roughly" type return type.
export type AnyResolverFn = LocalResolverFn<'Workspace', 'identifier'>

const augmentResolver = (
  fieldName: string,
  fieldResolver: LocalResolverFn<any, any>
): AnyResolverFn => {
  return (parent, args, context, info) => {
    try {
      const clientDirective = info?.field.directives?.find(
        x => x.name.value === 'client'
      )

      /**
       * If there is no @client directive added on the field
       * then always return value from an actual response.
       *
       * This is how it already should work according to Apollo docs
       * but it doesn't so we fix it ourselves
       *
       * see https://www.apollographql.com/docs/react/development-testing/client-schema-mocking/#4-toggle-on-real-data
       * > Once the feature is ready on the backend, just remove the @client directive from your query.
       * > You should now be able to see your real production data returned instead
       */
      if (!clientDirective) {
        return parent[fieldName as keyof typeof parent]
      }
      return fieldResolver(parent, args, context, info)
    } catch (err) {
      console.log('dummy resolver', err)
    }
  }
}

const augmentResolvers = (resolvers: LocalResolvers): LocalResolvers => {
  return Object.entries(resolvers)
    .map(([typeName, typeResolver]) => {
      return {
        [typeName]: Object.entries(typeResolver as LocalResolver<any>)
          .map(([fieldName, fieldResolver]) => ({
            [fieldName]: augmentResolver(fieldName, fieldResolver!),
          }))
          .reduce((l, r) => ({ ...l, ...r }), {}),
      }
    })
    .reduce((l, r) => ({ ...l, ...r }), {})
}

export const augmentAndMergeResolvers = (
  localResolvers: LocalResolvers | LocalResolvers[]
): Resolvers => {
  const resolvers = Array.isArray(localResolvers)
    ? localResolvers
    : [localResolvers]

  return merge({}, ...resolvers.map(r => augmentResolvers(r)))
}

import { useEffect } from 'react'
import { useModalContext } from '@sketch/components'

import { localStorageKeys } from '@sketch/constants'

import { getParsedItem } from '@sketch/utils'

import WorkspaceWelcomeModal from 'modules/workspace/modals/WorkspaceWelcomeModal'
import { useCanSubscribeViaStripe } from 'modules/workspace/utils'

import {
  WorkspaceMinimalFragment,
  useGetPaymentDetailsQuery,
  BillingMinimalFragment,
} from '@sketch/gql-types'

/**
 * Renders a Welcome Modal for Workspaces
 * Allows the user to subscribe right away or keep
 * using the Workspace as a Trial.
 *
 * Will only render if:
 *  - User is Owner of the Workspace
 *  - Was not dismissed before
 *  - Workspace is on trial
 *  - Workspace is not subscribed
 *  - User is not on a trial on the App Store
 */
const useWelcomeModal = (
  workspace: WorkspaceMinimalFragment,
  subscriptionDetails: Pick<BillingMinimalFragment, 'status'>
) => {
  const { showModal } = useModalContext()

  const { data } = useGetPaymentDetailsQuery({
    variables: { customerId: workspace.customer?.identifier! },
    skip: !workspace.customer?.identifier,
  })

  const { canSubscribeStripe, customerProvider } = useCanSubscribeViaStripe(
    workspace.identifier,
    workspace.customer?.identifier
  )

  const isSubscribed = data?.customer.paymentDetails.type !== 'NONE'
  const isTrial =
    customerProvider?.customer.subscriptionInfo.status === 'TRIALING'

  const wasWelcomeModalDismissed = getParsedItem(
    localStorageKeys.dismissedWelcomeModal
  )?.includes(workspace.identifier)

  const shouldRenderWelcomeModal =
    workspace.userIsOwner &&
    !wasWelcomeModalDismissed &&
    isTrial &&
    !isSubscribed &&
    canSubscribeStripe

  /** Only render the modal according to the conditions above*/
  useEffect(() => {
    if (shouldRenderWelcomeModal) {
      showModal(WorkspaceWelcomeModal, { workspaceId: workspace.identifier })
    }
  }, [showModal, shouldRenderWelcomeModal, workspace])
}

export default useWelcomeModal

import React, { useEffect, useRef } from 'react'

import { dataIdFromObject } from '@sketch/graphql-cache'

import {
  Flex,
  LoadingPlaceholder,
  AspectRatioGrid,
  InfiniteList,
  handleFetchMore,
  LoadingState,
} from '@sketch/components'
import EmptyState from 'modules/shares/components/EmptyState'
import LoadingError from '../../components/Layout/LoadingError'
import { Layout } from '../../components/Layout'
import { GroupName, Group, ListGridSeparator } from '../../components/Grid'
import { ColorVariablesListItem } from './ColorVariablesListItem'

import useScrollReset from '../../hooks/useScrollReset'
import { useSearchComponentsContext } from '../../context/SearchComponentsContext'
import { useSelectedGroupContext } from '../../context/SelectedGroupContext'
import { GridSize, useCustomGridContext } from '../../context/CustomGridContext'
import { useTrackTimeInComponentPage, useComponentLink } from '../../hooks'
import { useGetColorVariables, useGetComponentsCount } from '../../operations'
import { useGetPendingDescriptionQuery } from '@sketch/gql-types'
import { useFlag, useAnalytics, getRenderStatus } from '@sketch/modules-common'

import type { ColorVariableParsed } from '../../types'

import { groupStyles } from '../../utils'
import { Center } from '../../components/Center'
import ComponentsEmptyState from 'modules/shares/components/ComponentsEmptyState'

const ColorVariablesList = ({
  searchValue,
  path,
}: {
  searchValue: string
  path: string | null
}) => {
  useScrollReset()

  const isComponentDescriptionsOn = useFlag('components-description')

  const { trackEvent } = useAnalytics()

  const prevSearchRef = useRef<string | undefined>()

  const {
    defaultColumns,
    verticalRowSpaceGrid,
    minColumnWidth,
    setSelectedGridSize,
    selectedGridSize,
  } = useCustomGridContext()

  if (selectedGridSize === GridSize.Full) {
    setSelectedGridSize(GridSize.Medium)
  }

  const {
    shareIdentifier,
    versionIdentifier,
    documentVersion,
    compatibilityVersion,
    canEditDescriptions,
    renderStatusOfLatestVersion,
    entries,
    after,
    fetchMore,
    loading,
    error,
    hasComponentManifest,
    componentsState,
  } = useGetColorVariables({
    search: searchValue,
    path: path === 'View All' || path === '' ? null : path,
  })

  const {
    data: pendingDescriptionsData,
    loading: pendingDescriptionsLoading,
  } = useGetPendingDescriptionQuery({
    variables: {
      shareIdentifier,
      versionIdentifier: versionIdentifier || '',
    },
    skip:
      loading ||
      !versionIdentifier ||
      getRenderStatus(renderStatusOfLatestVersion) === 'ready' ||
      !isComponentDescriptionsOn,
  })

  // Allows us to link to an specific component (automatically selecting it and
  // showing the inspector data)
  useComponentLink(loading)

  useEffect(() => {
    if (searchValue) {
      trackEvent('CWV - search', { type: 'color variables' })
    }
  }, [searchValue, trackEvent])

  useEffect(() => {
    // only update the searchValue after it has finished loading
    if (!loading) {
      prevSearchRef.current = searchValue
    }
  }, [searchValue, loading])

  if (error) {
    return <LoadingError error={error} componentType="Color Variables" />
  }

  const switchingGroupToSearch =
    (path !== 'View All' || path === null) && searchValue

  const isLoadingSearch =
    loading &&
    searchValue &&
    (prevSearchRef.current === undefined ||
      prevSearchRef.current !== searchValue)

  if (
    (loading && !after) ||
    switchingGroupToSearch ||
    isLoadingSearch ||
    pendingDescriptionsLoading
  ) {
    return (
      <Flex justifyContent="center" flex="auto">
        <LoadingPlaceholder size="64px" />
      </Flex>
    )
  }

  if (!hasComponentManifest || componentsState === 'CANNOT_PROCESS') {
    return (
      <EmptyState
        title="Components aren’t available for this version"
        description=""
      />
    )
  }

  const colorGroups = groupStyles(entries)
  const groupsNames = Object.keys(colorGroups)

  if (groupsNames.length === 0) {
    if (searchValue !== '') {
      return (
        <EmptyState
          title={`No search results for "${searchValue}"`}
          description="No search results."
          icon="search"
        />
      )
    }

    if (path !== '' && path !== 'View All') {
      return (
        <EmptyState
          title={`The group "${path}" does not exist in this version.`}
          description=""
          icon="colorVariable"
        />
      )
    }

    return (
      <EmptyState
        title="No Color Variables"
        description={
          <>
            This Document does not contain any{' '}
            <a href="https://www.sketch.com/docs/designing/styling/color-variables/">
              Color Variables
            </a>
            .
          </>
        }
        icon="colorVariable"
      />
    )
  }

  const generateColorList = (color: ColorVariableParsed, groupName: string) => {
    const editingComponent = (
      pendingDescriptionsData?.collaborativeEditingSession.componentEdits || []
    ).find(
      component =>
        (component.componentUuid as string).toUpperCase() === color.uuid
    )

    const pendingDescription = {
      documentVersion: documentVersion!,
      baseVersionIdentifier: versionIdentifier,
      compatibilityVersion: compatibilityVersion!,
      canEditDescriptions,
      shareIdentifier,
      componentUuid: color.uuid,
      description: editingComponent?.description || color.description,
    }

    return (
      <ColorVariablesListItem
        key={`${color.identifier}-${versionIdentifier}`}
        color={color}
        pendingDescription={pendingDescription}
        groupName={groupName}
        searchValue={searchValue}
      />
    )
  }

  // The main difference between the search view and the normal view is that we
  // don't group items when searching, that's why we need to tweak the list generation
  if (searchValue !== '') {
    return (
      <InfiniteList
        canLoadMore={after !== null}
        onLoadMore={handleFetchMore(
          fetchMore,
          ['share', 'version', 'document', 'components', 'entries'],
          { dataIdFromObject, after: after || null }
        )}
      >
        <AspectRatioGrid
          verticalRowSpace={verticalRowSpaceGrid}
          columns={defaultColumns}
          gutterSize={32}
          minWidth={minColumnWidth}
        >
          {groupsNames.map(groupName =>
            colorGroups[groupName].map((color: ColorVariableParsed) =>
              generateColorList(color, groupName)
            )
          )}
        </AspectRatioGrid>
      </InfiniteList>
    )
  }

  return (
    <InfiniteList
      canLoadMore={after !== null}
      onLoadMore={handleFetchMore(
        fetchMore,
        ['share', 'version', 'document', 'components', 'entries'],
        { dataIdFromObject, after: after || null }
      )}
    >
      {groupsNames.map((groupName, key) => (
        <Group key={`${groupName}-${versionIdentifier}`}>
          {key !== 0 && <ListGridSeparator />}
          {groupName !== ' ' && <GroupName groups={groupName} />}
          <AspectRatioGrid
            verticalRowSpace={verticalRowSpaceGrid}
            columns={defaultColumns}
            gutterSize={32}
            minWidth={minColumnWidth}
          >
            {colorGroups[groupName].map((color: ColorVariableParsed) =>
              generateColorList(color, groupName)
            )}
          </AspectRatioGrid>
        </Group>
      ))}
    </InfiniteList>
  )
}

interface ColorVariablesProps {
  userIsEditor: boolean
}

export const ColorVariables: React.FC<ColorVariablesProps> = ({
  userIsEditor,
}) => {
  const { totalComponents, loading, componentsState } = useGetComponentsCount()
  const { search } = useSearchComponentsContext()
  const { selected, setSelected } = useSelectedGroupContext()

  useTrackTimeInComponentPage('color-variables')

  if (search) {
    setSelected('View All')
  }

  if (
    componentsState === 'CAN_PROCESS' ||
    componentsState === 'PROCESSING' ||
    loading ||
    totalComponents === false
  ) {
    return (
      <Center>
        <div>
          <LoadingState />
          <p>Loading components…</p>
        </div>
      </Center>
    )
  }

  if (totalComponents === 0) {
    return <ComponentsEmptyState userIsEditor={userIsEditor} />
  }

  return (
    <Layout>
      <ColorVariablesList searchValue={search} path={selected} />
    </Layout>
  )
}

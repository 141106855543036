import { jsx } from 'react/jsx-runtime';
import { useModalContext, Section, Button } from '@sketch/components';
import './DeleteAccountModal/index.js';
import DeleteAccountModal from './DeleteAccountModal/DeleteAccountModal.js';

const OtherOptionsPanel = ({ hasPersonalIdentity, }) => {
    const { showModal } = useModalContext();
    /* Avoid a scenario where only the "Other" title was being rendered */
    if (!hasPersonalIdentity) {
        return null;
    }
    return (jsx(Section, Object.assign({ id: "delete-account-header", title: "Other" }, { children: jsx(Button, Object.assign({ variant: "negative", size: "40", onClick: () => showModal(DeleteAccountModal) }, { children: "Delete Account\u2026" })) })));
};

export { OtherOptionsPanel };

import { breakpoint, sidebar } from '@sketch/global-styles'
import styled, { css } from 'styled-components'

import { Button, ButtonUnstyled } from '@sketch/components'
import { ReactComponent as CloseIcon } from '@sketch/icons/cross-circle-24'

import {
  FirstStepsCard,
  CARD_WIDTH,
  ActionArrow,
} from '../FirstStepsCard/FirstStepsCard.styles'

/** Padding left and right of the banner for screens bigger than "sm"  */
const BANNER_PADDING_DEFAULT = 40
/** Size of the gap between cards */
const BANNER_CARDS_GAP = 40
const SIDEBAR_WIDTH = sidebar.leftWidth

/**
 * Calculate the min width of the viewport we need to make X number of cards
 * fit in the banner without triggering the horizontal scroll.
 */
function getMinScreenWidthForNumberOfCards(numberOfCards: number) {
  const totalGapWidth = BANNER_CARDS_GAP * (numberOfCards - 1)
  const totalPaddingWidth = BANNER_PADDING_DEFAULT * 2
  const totalCardsWidth = CARD_WIDTH * numberOfCards

  return SIDEBAR_WIDTH + totalPaddingWidth + totalCardsWidth + totalGapWidth
}

const minScreenWidth4Cards = getMinScreenWidthForNumberOfCards(4)
const minScreenWidth5Cards = getMinScreenWidthForNumberOfCards(5)
const minScreenWidth6Cards = getMinScreenWidthForNumberOfCards(6)

/**
 * We change the max number of cards the banner can display based on
 * the screen size. Bigger screen can show more cards while smaller screens can only show 3.
 * Because we never want to make the banner scrollable horizontally on any desktop
 * screen, we are setting the max number of cards to whatever would fit on this screen size (up to max 6 cards)
 * instead of using the web app common breakpoints.
 */
function getCssToHideExtraCardsBasedOnScreenSize() {
  return css`
    /* Show max 3 cards by default, for screens that can't fit 4.
    Note: For this screen size we are comfortable if the banner becomes scrollable
    as we are more likely to be on mobile or tablet */
    @media (max-width: ${minScreenWidth4Cards}px) {
      ${getMaxCardCss(3)}
    }

    /* Show max 4 cards if 4 cards fit but not 5 */
    @media (min-width: ${minScreenWidth4Cards}px) and (max-width: ${minScreenWidth5Cards}px) {
      ${getMaxCardCss(4)}
    }

    /* Show max 5 cards if 5 cards fit but not 6 */
    @media (min-width: ${minScreenWidth5Cards}px) and (max-width: ${minScreenWidth6Cards}px) {
      ${getMaxCardCss(5)}
    }

    /* Show max 6 cards on the very large screens */
    @media (min-width: ${minScreenWidth6Cards}px) {
      ${getMaxCardCss(6)}
    }
  `
}

export const FirstStepsBanner = styled.div<{ isDarkMode?: boolean }>`
  display: flex;
  padding: 16px;
  width: 100%;
  background-color: ${({ theme, isDarkMode }) =>
    isDarkMode
      ? theme.colors.background.secondary.B
      : theme.colors.background.tertiary.D};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.border.B};
  min-height: 194px;
  overflow-y: auto;
  position: relative;

  ${breakpoint('sm')`
    padding: 24px ${BANNER_PADDING_DEFAULT}px;
  `}

  ${getCssToHideExtraCardsBasedOnScreenSize()}

  /* Equivalent to "gap" property that we don't want to use because of browser compatibility */
  ${/* sc-selector */ FirstStepsCard}:not(:first-child) {
    margin-left: ${BANNER_CARDS_GAP}px;
  }

  ${ActionArrow} {
    color: ${({ theme, isDarkMode }) =>
      isDarkMode
        ? // Usage of "!important" here to avoid passing "isDarkMode" to the "ActionArrow" component
          // so it overrides the colour from that component here
          `${theme.colors.foreground.secondary.B} !important;`
        : undefined};
  }
`

/**
 * Create css selector hiding all the cards that are considered "extra",
 * so we only show the provided max number of cards.
 */
function getMaxCardCss(numberMaxCardsToShow: number) {
  return css`
    & > ${FirstStepsCard} {
      &:nth-child(n + ${numberMaxCardsToShow + 1}) {
        display: none;
      }
    }
  `
}

export const DismissButtonMobile = styled(Button)`
  align-self: center;
  margin-left: 40px;

  /*
   This will allow this button to only show when the item wrapper is scrollable
  */
  display: none;

  @media (max-width: ${getMinScreenWidthForNumberOfCards(3)}px) {
    display: block;
  }
`

export const DismissButtonDesktop = styled(ButtonUnstyled)`
  /*
    In order to make the button attached to the top right corner with a scrollable
    parent we had to use "sticky" so the element stay in the same place.
  */
  position: sticky;
  top: 0px;
  right: 0px;

  /*
  Hide the button width so the scrollbar doesn't show on desktop
  */
  width: 0px;

  /*
   Given that "sticky" respects the parent bounds we needed to correct the
   button position so it matches the layer
  */
  transform: translate(0px, -4px);

  /* Correcting the element to be represented properly on flex-box */
  margin-left: auto;
  align-self: flex-start;

  /*
   This will allow this button to only show when the item wrapper in not scrollable
  */
  display: none;

  @media (min-width: ${getMinScreenWidthForNumberOfCards(3) + 1}px) {
    display: block;
  }
`

export const StyledCloseIcon = styled(CloseIcon)<{ $isDarkMode?: boolean }>`
  width: 24px;
  height: 24px;

  /* Only apply these changes to the light mode icon */
  ${({ $isDarkMode }) =>
    !$isDarkMode &&
    css`
      /*
        Because the icon background is see-through we had to fill add a background
        so it would show the icon properly.
      */
      border-radius: 100%;
      background-color: ${({ theme }) => theme.colors.background.secondary.A};
      box-shadow: inset 0px 0px 0px 3px
        ${({ theme }) => theme.colors.background.tertiary.D};
    `}
`

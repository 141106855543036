import React from 'react'

import { EditableDocumentDescription } from '../EditableDocumentDescription'
import { EditableDocumentName } from '../EditableDocumentName'
import { PersonAvatar, Tooltip } from '@sketch/components'

import { PresenceUsers } from 'modules/presence/components'

import {
  useDocumentDescriptionChange,
  useDocumentNameChange,
} from './AboutTab.hooks'
import { useToast } from '@sketch/toasts'

import {
  HeaderSection,
  Copy,
  AuthorSection,
  AuthorWrapper,
  InfoIcon,
} from './AboutTab.styles'

import { ShareAboutFragment } from '@sketch/gql-types'
import { ShareType } from 'modules/shares/components/EditInSketchButton'

const DOCUMENT_TYPE_COPY = {
  STANDARD: 'Document',
  LIBRARY: 'Library',
  TEMPLATE: 'Template',
}

const getDocumentType = (type: ShareType) => {
  if (type in DOCUMENT_TYPE_COPY) {
    return DOCUMENT_TYPE_COPY[type]
  }

  return DOCUMENT_TYPE_COPY['STANDARD']
}

interface AboutTabHeaderSectionProps {
  share: ShareAboutFragment
}

const AboutTabHeaderSection = ({ share }: AboutTabHeaderSectionProps) => {
  const { creator } = share.version || {}
  const { showToast } = useToast()
  const changeDocumentDescription = useDocumentDescriptionChange()

  const handleDocumentNameError = ({ message }: { message: string }) => {
    if (
      message.includes('name: Document names cannot include these characters:')
    ) {
      showToast(message.substring('name: '.length), 'negative')
      return
    }

    if (message.includes(`name: name can't be blank`)) {
      showToast('Please give your document a name', 'negative')
      return
    }

    showToast(message, 'negative')
  }

  const changeDocumentName = useDocumentNameChange(handleDocumentNameError)

  const descriptionEditHandler = (description: string) => {
    changeDocumentDescription({
      variables: { identifier: share.identifier, description },
    })
  }

  const nameEditHandler = (name: string) => {
    changeDocumentName({ variables: { identifier: share.identifier, name } })
  }
  return (
    <HeaderSection data-testid="about-tab-header">
      <Copy data-testid="document-type">{getDocumentType(share.type)}</Copy>
      <EditableDocumentName
        name={share.name}
        editHandler={share.userCanUpdateSettings ? nameEditHandler : undefined}
      />
      <EditableDocumentDescription
        description={share.description || ''}
        editHandler={
          share.userCanUpdateSettings ? descriptionEditHandler : undefined
        }
      />

      <PresenceUsers
        shareIdentifier={share.identifier}
        forceFallback={!share?.workspace.userIsMember}
        fallback={() => {
          if (share?.version?.kind === 'AUTO') {
            return (
              <AuthorSection>
                <Copy>Autosave</Copy>{' '}
                <Tooltip
                  placement="top"
                  content="Autosaved versions include the document's latest changes"
                >
                  <InfoIcon role="img" />
                </Tooltip>
              </AuthorSection>
            )
          }

          const creatorName = share.version?.userIsCreator
            ? 'You'
            : share.version?.creator?.name!
          const avatar = (
            <PersonAvatar
              flavour="image"
              size="16px"
              src={creator?.avatar?.small!}
              name={creatorName}
            />
          )

          return (
            <AuthorSection>
              <Copy>Last save from</Copy>{' '}
              <Tooltip
                content={
                  <AuthorWrapper>
                    {avatar} {creatorName}
                  </AuthorWrapper>
                }
                placement="bottom-start"
                spacing="0px"
                scrollable
              >
                {avatar}
              </Tooltip>
            </AuthorSection>
          )
        }}
      />
    </HeaderSection>
  )
}

export default AboutTabHeaderSection

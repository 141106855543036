import { __rest } from 'tslib';
import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import * as Sentry from '@sentry/browser';
import { SKETCH_WEBSITE } from '@sketch/env-config';
import PropTypes from 'prop-types';
import { createElement, useCallback } from 'react';
import styled from 'styled-components';

const BaseLink = styled.a `
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;
const Paragraph = styled.p `
  margin: 0;
`;
const ListParent = styled.ul `
  margin: 0;
  padding-left: 25px; /* stylelint-disable-line scales/space */
`;
const BaseInlineCode = styled.span `
  display: inline;
  font-family: ${({ theme }) => theme.fonts.monospace};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  white-space: pre-wrap;
  padding: 2px 4px; /* stylelint-disable-line scales/space */
  background-color: ${({ theme }) => theme.colors.background.tertiary.C};
  font-size: 0.75rem;
`;
const BaseCode = styled(BaseInlineCode) `
  display: block;
  padding: 8px; /* stylelint-disable-line scales/space */
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  border-radius: 2px; /* stylelint-disable-line scales/radii */
`;
const Bold = styled.strong `
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  /* Correct the font-weight given by the rule "p a" */
  ${BaseLink} {
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
  }
`;
const BlockQuote = styled.blockquote `
  margin: 0 0 0 3px; /* stylelint-disable-line scales/space */
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  border-left: 3px solid ${({ theme }) => theme.colors.border.A};
  padding-left: 12px;
  & > ${ListParent} {
    padding-left: 15px; /* stylelint-disable-line scales/space */
  }
  & ${BaseLink} {
    color: ${({ theme }) => theme.colors.sketch.D};
  }
`;
const TextWrapper = styled.pre `
  display: inline;
  white-space: pre-wrap;
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: 0;
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  > ${ /* sc-selector */Paragraph}:last-child {
    display: inline-block;
    margin-right: 3px; /* stylelint-disable-line scales/space */
  }
`;
const ColorBlob = styled.span.attrs(({ color }) => ({
    'data-testid': 'color-blob',
    style: {
        background: color,
    },
})) `
  display: inline-block;
  background: currentColor;
  margin-left: 2px; /* stylelint-disable-line scales/space */
  margin-top: -2px; /* visual correction */ /* stylelint-disable-line scales/space */
  vertical-align: middle;
  width: 14px;
  height: 14px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.border.A};
`;
const Color = ({ value }) => {
    const color = value[0] === '#' ? value : `#${value}`;
    return (jsxs(Fragment, { children: [value, jsx(ColorBlob, { color: color })] }));
};
Color.propTypes = {
    value: PropTypes.string,
};
/**
 * createMarkdownComponent.
 *
 * This function "maps" the component props to a specific type
 * according to the "componentsByType" object.
 *
 * @param {Object} props - Component to be mapped props.
 * @param {number} index - Component index.
 *
 * @returns {React.ReactElement} - [React element].
 *
 */
const createMarkdownComponent = (
//eslint-disable-next-line react/prop-types
_a, index) => {
    var 
    //eslint-disable-next-line react/prop-types
    { type } = _a, props = __rest(_a, 
    //eslint-disable-next-line react/prop-types
    ["type"]);
    // eslint-disable-next-line
    const component = componentByType[type];
    if (component) {
        return createElement(component, Object.assign({ key: index }, props));
    }
    return null;
};
/**
 * createChildMarkdownComponents.
 *
 * This function merges the element with the mapped children.
 *
 * @param {React.element|string} element - Element that will be passed the children.
 *
 * @returns {React.ReactElement} - [React element].
 */
const createChildMarkdownComponents = (element //eslint-disable-next-line react/display-name
) => elementProps => {
    return createElement(element, {}, elementProps.children.map(createMarkdownComponent));
};
/**
 * Text.
 *
 * Returns the value prop.
 *
 * @param {Object} props - Component props.
 * @param {string} [props.value] - Text value.
 *
 * @returns {string} - Value.
 */
const Text = ({ value }) => {
    return value;
};
Text.propTypes = {
    value: PropTypes.string,
};
/**
 *
 * List.
 *
 * "Wraps" elements in a list component.
 *
 * @param {Object} props - Component Props.
 * @param {Object[]} props.children - Array with objects to be mapped to react elements.
 * @param {boolean} props.ordered - True if the list is ordered, false otherwise.
 *
 * @returns {React.ReactElement} - [React element].
 */
const List = ({ children, ordered }) => {
    const tag = ordered ? 'ol' : 'ul';
    return createElement(ListParent.withComponent(tag), {}, children.map(createMarkdownComponent));
};
List.propTypes = {
    children: PropTypes.arrayOf(PropTypes.object).isRequired,
    ordered: PropTypes.bool,
};
const mentionsReplacer = (_, name) => `@${name} `;
const replaceMentions = content => content.replace(/@\[(.*?)\]\(user:(.*?)\)/g, mentionsReplacer);
/**
 * InlineCode.
 *
 * @param {Object} props - Component Props.
 * @param {string} props.value - String with the inline code value.
 *
 * @returns {React.ReactElement} - [React element].
 */
const InlineCode = ({ value }) => value && jsx(BaseInlineCode, { children: replaceMentions(value) });
InlineCode.propTypes = {
    value: PropTypes.string,
};
/**
 * Code.
 *
 * @param {Object} props - Component Props.
 * @param {string} props.value - String with the code value.
 *
 * @returns {React.ReactElement} - [React element].
 */
const Code = ({ value }) => value && jsx(BaseCode, { children: replaceMentions(value) });
Code.propTypes = {
    value: PropTypes.string,
};
const isInternalUrl = url => {
    try {
        const targetHost = new URL(url).hostname;
        const internalHost = new URL(SKETCH_WEBSITE).hostname;
        return targetHost === internalHost || `www.${targetHost}` === internalHost;
    }
    catch (error) {
        // We tried to parse something that wasn't a valid URL
        // We can safely assume its not an internal URL and capture the error
        Sentry.captureException(error);
        return false;
    }
};
/**
 * Link.
 *
 * @param {Object} props - Component Props.
 * @param {string} props.children - Array with objects to be mapped to react elements.
 * @param {string} props.url - Url that will be used in the link.
 *
 * @returns {React.ReactElement} - React element.
 */
const Link = ({ url, children }) => {
    /*
    The purpose of the onClick event handler is to stop event bubbling
    from getting to the lower elements of the tree
    */
    const handleOnClick = useCallback(event => {
        event.stopPropagation();
    }, []);
    const target = isInternalUrl(url) ? '_self' : '_blank';
    return (jsx(BaseLink, Object.assign({ href: url, target: target, rel: "noopener noreferrer", onClick: handleOnClick }, { children: children.map(createMarkdownComponent) })));
};
Link.propTypes = {
    url: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.object).isRequired,
};
const Break = () => jsx("br", {});
const componentByType = {
    break: Break,
    code: Code,
    list: List,
    text: Text,
    link: Link,
    inlineCode: InlineCode,
    color: Color,
    blockquote: createChildMarkdownComponents(BlockQuote),
    delete: createChildMarkdownComponents('del'),
    emphasis: createChildMarkdownComponents('em'),
    listItem: createChildMarkdownComponents('li'),
    paragraph: createChildMarkdownComponents(Paragraph),
    strong: createChildMarkdownComponents(Bold),
};

export { BaseCode, BaseInlineCode, BaseLink, BlockQuote, Bold, Break, Code, Color, Link, ListParent, Paragraph, TextWrapper, createMarkdownComponent };

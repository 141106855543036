import styled from 'styled-components';
import { Form, Modal, Select, Text, inputBorderStyle, NativeSelectWrapper } from '@sketch/components';

const CheckboxGroup = styled.div `
  & ${Form.Field} {
    margin-bottom: 16px;
  }
  margin-top: 16px;
`;
const StyledModal = styled(Modal) `
  & ${Modal.Body} {
    &:first-child {
      margin-top: 0;
    }
  }
`;
const StyledSelect = styled(Select) `
  min-width: 164px;
  margin-right: 16px;
`;
const HumanReadableExpiry = styled(Text.Div).attrs({
    textStyle: 'copy.quaternary.standard.C',
}) `
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ExpiryInputContainer = styled.div `
  display: flex;
  flex-direction: row;

  & input[type='date'] {
    min-width: 134px;
    color: ${({ theme }) => theme.colors.foreground.secondary.D};

    ${inputBorderStyle}
  }

  & ${NativeSelectWrapper} {
    min-width: 164px;
    margin-right: 16px;
  }
`;

export { CheckboxGroup, ExpiryInputContainer, HumanReadableExpiry, StyledModal, StyledSelect };

import React, { FC } from 'react'
import { Route, Switch, useHistory } from 'react-router'

import { useGetInitialUserQuery } from '@sketch/gql-types'

import { routes } from '@sketch/modules-common'
import { SelectDropdownLinkItem } from '@sketch/components'

import UserNavbarSection from 'modules/user/components/UserNavbarSection'

import {
  NoWorkspacesAvatar,
  NoWorkspacesDropdown,
  NoWorkspaceEmptyState,
  NoWorkspaceIcon,
  Title,
  Description,
} from './NoWorkspaceSidebar.styles'

import {
  SidebarWrapper,
  MenuWrapper,
  WorkspaceSelectorWrapper,
  FooterWrapper,
  Spacer,
  MenuListItem,
} from './Sidebar.styles'

import { useThemeContext } from '@sketch/global-styles'

import { ReactComponent as BackArrow } from '@sketch/icons/arrow-top-left-16'
import BottomMenu from './components/BottomMenu/BottomMenu'

import { UserSettingsSidebarMenu } from './SettingsSidebar'

export const NoWorkspaceSidebar: FC = () => {
  const history = useHistory()
  const { data: userData } = useGetInitialUserQuery()

  const { isDarkMode } = useThemeContext()

  const isPartner = userData?.me.isPartner

  return (
    <SidebarWrapper>
      <WorkspaceSelectorWrapper>
        <NoWorkspacesDropdown
          isPartner={isPartner}
          onClick={() => {
            if (!isPartner) {
              history.push(routes.WORKSPACE_CREATE.create({}))
            }
          }}
        >
          <NoWorkspacesAvatar />
        </NoWorkspacesDropdown>
        <UserNavbarSection noWorkspace />
      </WorkspaceSelectorWrapper>
      <MenuWrapper $isDarkMode={isDarkMode}>
        <Switch>
          <Route
            path={[
              routes.WORKSPACE_SETTINGS.template(),
              routes.PERSONAL_SETTINGS.template(),
            ]}
            render={() => (
              <>
                <MenuListItem>
                  <SelectDropdownLinkItem
                    icon={BackArrow}
                    text="Back"
                    to={routes.ENTRY.create({})}
                  />
                </MenuListItem>
                <Spacer />
                <UserSettingsSidebarMenu />
              </>
            )}
          />
          <Route
            render={() => (
              <NoWorkspaceEmptyState>
                <NoWorkspaceIcon width={24} height={24} />
                <Title>No Workspace Selected</Title>
                <Description>
                  Create or pick an existing Workspace to view your projects and
                  documents
                </Description>
              </NoWorkspaceEmptyState>
            )}
          />
        </Switch>
      </MenuWrapper>

      <FooterWrapper>
        <BottomMenu />
      </FooterWrapper>
    </SidebarWrapper>
  )
}

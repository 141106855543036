import { useRef, useState, useEffect } from 'react';

/**
 * Hook to detect if the text content of a node is truncated (by overflow: hidden).
 * This is mainly used to know if we need to show a tooltip to display the full version
 * of a text truncated by ellipses.
 *
 * The node ref returned by the hook needs to be set on the container that has the
 * overflow hidden.
 *
 * Example:
 * const [isTruncated, nodeRef] = useIsNodeContentTruncated<HTMLDivElement>()
 * return (
 * <Tooltip content={text} disabled={!isTruncated}>
 *  <div ref={nodeRef} className="truncate-text"> { text } <div>
 * </Tooltip>
 * )
 */
function useIsNodeContentTruncated() {
    const nodeRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);
    const checkIfIsTruncated = () => {
        const node = nodeRef.current;
        if (!node) {
            return;
        }
        const newIsTruncated = node.scrollWidth > node.clientWidth;
        if (newIsTruncated !== isTruncated) {
            setIsTruncated(newIsTruncated);
        }
    };
    // Re-run after each render,
    // in case the content has changed.
    useEffect(checkIfIsTruncated);
    return [isTruncated, nodeRef];
}

export { useIsNodeContentTruncated };

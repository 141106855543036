import { createContext } from 'react';
import { noop } from '@sketch/utils';

const ToastContext = createContext({
    showToast: noop,
    dismissToast: noop,
    toasts: [],
});

export { ToastContext };

import { jsx, jsxs } from 'react/jsx-runtime';
import React, { useState, useRef, useEffect } from 'react';
import './SelectList/index.js';
import { SelectDropdownButtonTrigger, ChevronsUpDownIcon } from './SelectDropdown.styles.js';
import './SubMenu/index.js';
import { MenuContextProvider } from './SubMenu/menuContext.js';
import { SelectList } from './SelectList/SelectList.js';

/**
 * SelectDropdown
 *
 * This component renders a visual looking select that shows either
 * a modal on mobile and a dropdown in tablet/desktop.
 *
 * This components receives a "items" prop that in conjunction with the
 * "renderItem" one represents the items visually on the viewport wrapper
 *
 * Usage:
 * <SelectDropdown
 *    placeholder="Select a fruit..."
 *    items={fruits}
 *    renderItem={(item, props) => <SelectDropdownItem text={item} {...props} />}
 *  />
 */
function SelectDropdown(props) {
    const { placeholder, value, renderItem, renderValueText = item => `${item}`, items, onChange, headerItem, footerItem, disabled, } = props;
    const [expandedState, setExpanded] = useState(false);
    const [forceExpanded, setForceExpanded] = useState(false);
    const buttonRef = useRef(null);
    const [activePath, setActivePath] = useState([]);
    const expanded = expandedState || forceExpanded;
    // If we close the dropdown the button goes back to focus
    useEffect(() => {
        const button = buttonRef.current;
        if (expanded) {
            return () => {
                button === null || button === void 0 ? void 0 : button.focus();
            };
        }
    }, [expanded]);
    // If the value changed then we need to close the dropdown
    useEffect(() => {
        setExpanded(false);
        setForceExpanded(false);
    }, [value]);
    const renderChildren = (item, index) => {
        const options = {
            active: item === value,
            setItem: (clickedItem = item) => {
                const path = [clickedItem];
                onChange === null || onChange === void 0 ? void 0 : onChange(clickedItem, path);
                setActivePath(path);
                setExpanded(false);
            },
            a11yProps: {
                role: 'option',
                id: `option-${index}`,
            },
        };
        return (jsx(React.Fragment, { children: renderItem(item, options) }, index));
    };
    const children = (jsx("div", Object.assign({ role: "listbox", tabIndex: -1 }, { children: items.map(renderChildren) })));
    const contextSetValue = (item, path) => {
        setExpanded(false);
        if (onChange) {
            onChange === null || onChange === void 0 ? void 0 : onChange(item, path);
            setActivePath(path);
        }
    };
    return (jsxs(MenuContextProvider, Object.assign({ value: {
            activePath,
            setValue: contextSetValue,
            setForceExpanded,
            forceExpanded,
        } }, { children: [jsxs(SelectDropdownButtonTrigger, Object.assign({ "aria-placeholder": placeholder, type: "button", "aria-haspopup": true, "aria-valuetext": value && renderValueText(value), "aria-expanded": expanded, disabled: disabled, ref: buttonRef, onClick: () => setExpanded(value => !value) }, { children: [value ? renderValueText(value) : placeholder, jsx(ChevronsUpDownIcon, {})] })), jsx(SelectList, Object.assign({ expanded: expanded, headerItem: headerItem, footerItem: footerItem, placement: "bottom-start", setExpanded: getState => {
                    setExpanded(expanded => getState(expanded, buttonRef.current));
                }, usePortal: true }, { children: children }))] })));
}

export { SelectDropdown };

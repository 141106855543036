import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useModalContext, Absolute, Flex, Text } from '@sketch/components';
import { CheckIcon, LinkButton } from './LinkNonSSO.styles.js';
import { LinkNonSSOModal } from './LinkNonSSOModal.js';

const LinkNonSSO = ({ linkedEmail, workspaceName, currentWorkspace, SSOWorkspace, }) => {
    const { showModal } = useModalContext();
    const currentWSIsSSO = (currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.type) === 'STANDARD' && (SSOWorkspace === null || SSOWorkspace === void 0 ? void 0 : SSOWorkspace.workspace);
    if (linkedEmail) {
        return (jsx(Absolute, Object.assign({ top: 2, right: 0 }, { children: jsxs(Flex, Object.assign({ alignItems: "center" }, { children: [jsx(CheckIcon, {}), jsx(Text, Object.assign({ textStyle: "copy.primary.standard.C" }, { children: currentWSIsSSO ? (jsxs(Fragment, { children: ["Linked to ", jsx("strong", { children: linkedEmail })] })) : (jsxs(Fragment, { children: ["Linked to SSO ", jsx("strong", { children: SSOWorkspace.workspace.name })] })) }))] })) })));
    }
    return (jsx(Absolute, Object.assign({ top: 2, right: 0 }, { children: jsx(LinkButton, Object.assign({ isUnderlined: true, onClick: () => showModal(LinkNonSSOModal, { workspaceName }) }, { children: "Link to Non-SSO Account..." })) })));
};

export { LinkNonSSO };

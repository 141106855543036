import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { useEffect } from 'react';
import { Hoverable } from 'react-event-as-prop';
import { Toast } from './Toast.js';

const DISMISS_TIMEOUT_MS = 5000;
const ToastContainerRaw = (_a) => {
    var { id, hovered, onDismiss, autoDismiss = true } = _a, props = __rest(_a, ["id", "hovered", "onDismiss", "autoDismiss"]);
    useEffect(() => {
        let dismissTimeout;
        const dismiss = () => {
            if (!autoDismiss) {
                return;
            }
            // Reset timeout if user hovers over notification
            if (hovered && dismissTimeout) {
                clearTimeout(dismissTimeout);
                dismissTimeout = null;
                return;
            }
            // Don't assign new timeout if timeout is already assigned
            if (dismissTimeout)
                return;
            dismissTimeout = window.setTimeout(() => onDismiss(id), DISMISS_TIMEOUT_MS);
        };
        dismiss();
        return () => {
            if (dismissTimeout)
                clearTimeout(dismissTimeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hovered]);
    return (jsx(Toast, Object.assign({ id: id, hovered: hovered, onClose: () => onDismiss(id) }, props)));
};
const ToastContainer = Hoverable(ToastContainerRaw);

export { ToastContainer };

import deburr from 'lodash.deburr';
import { emojiAliases } from './emojiAliases.js';

const DEFAULT_EMOJI = [
    { id: '+1', display: '👍' },
    { id: '-1', display: '👎' },
    { id: 'white_check_mark', display: '✅' },
    { id: '100', display: '💯' },
];
const strippedString = (string) => {
    return deburr((string || '').toLowerCase());
};
const emoji = Object.entries(emojiAliases)
    .sort(([a], [b]) => a.length - b.length)
    .map(a => ({ id: a[0], display: a[1] }));
const prefixComparatorFor = (query) => ({ id: firstId }, { id: secondId }) => {
    const firstStartsWith = `${firstId}`.startsWith(query);
    const secondStartsWith = `${secondId}`.startsWith(query);
    if (firstStartsWith && secondStartsWith) {
        return 0;
    }
    else if (firstStartsWith) {
        return -1;
    }
    return 1;
};
const idIncludes = (query) => ({ id }) => {
    return `${id}`.includes(query);
};
const namePrefixComparatorFor = (query) => ({ display: firstDisplay }, { display: secondDisplay }) => {
    const firstStartsWith = `${firstDisplay}`.toLowerCase().startsWith(query);
    const secondStartsWith = `${secondDisplay}`.toLowerCase().startsWith(query);
    if (firstStartsWith && secondStartsWith) {
        return 0;
    }
    else if (firstStartsWith) {
        return -1;
    }
    return 1;
};
const nameIncludes = (query) => ({ display }) => {
    const strippedQuery = strippedString(query);
    const strippedName = strippedString(`${display}`);
    return strippedName.includes(strippedQuery);
};
const getUsersListBySearch = (users, query) => users
    .filter(nameIncludes(query))
    .sort(namePrefixComparatorFor(query))
    .slice(0, 5);
const getEmojisListBySearch = (query) => {
    if (query.length === 0) {
        return DEFAULT_EMOJI;
    }
    return emoji
        .filter(idIncludes(query))
        .sort(prefixComparatorFor(query))
        .slice(0, 5);
};

export { getEmojisListBySearch, getUsersListBySearch, strippedString };

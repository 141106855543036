import { jsx, jsxs } from 'react/jsx-runtime';
import { useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { SKETCH_WEBSITE } from '@sketch/env-config';
import { useQueryParams, GenericErrorView, IndexLayoutContent } from '@sketch/modules-common';
import { Panel, Checkbox, LoadingState, Caption, Button } from '@sketch/components';
import '../../utils/index.js';
import { useGetPublicWorkspaceQuery } from '@sketch/gql-types';
import { goToLinkAccountUrl } from '../../utils/goToLinkAccountUrl.js';

const StyledPanel = styled(Panel) `
  max-width: 520px;
  margin: auto;
`;
const StyledCheckbox = styled(Checkbox) `
  margin-top: 28px;
`;
const SsoTosView = (props) => {
    const { useOverrideLayoutProps } = props;
    const [hasAcceptedTos, setHasAcceptedTos] = useState(false);
    const { workspaceId } = useParams();
    const queryParams = useQueryParams();
    const { data, loading, error } = useGetPublicWorkspaceQuery({
        variables: { identifier: workspaceId },
    });
    useOverrideLayoutProps({ disableLogoLink: true });
    if (loading) {
        return jsx(LoadingState, {});
    }
    if (error || !data) {
        return jsx(GenericErrorView, { isInLayout: true });
    }
    return (jsx(IndexLayoutContent, Object.assign({ center: true, paddingHorizontal: true }, { children: jsxs(StyledPanel, { children: [jsx(Panel.Title, { children: "Terms of Service" }), jsxs(Panel.Body, { children: [jsxs(Caption, { children: ["Before proceeding, please confirm you have read and agree to the", ' ', jsxs("b", { children: ["Sketch Cloud", ' ', jsx("a", Object.assign({ href: `${SKETCH_WEBSITE}/tos`, target: "_blank", rel: "noopener noreferrer" }, { children: "Terms of Service" }))] }), "."] }), jsx(StyledCheckbox, Object.assign({ name: "acceptTos", checked: hasAcceptedTos, onChange: (event) => {
                                setHasAcceptedTos(event.target.checked);
                            } }, { children: "I accept the terms of service." }))] }), jsx(Panel.Footer, { children: jsx(Button, Object.assign({ variant: "primary", disabled: !hasAcceptedTos, onClick: () => goToLinkAccountUrl(Object.assign({ url: data.publicWorkspace.customer.ssoStartUrl }, queryParams)) }, { children: "Continue" })) })] }) })));
};

export { SsoTosView };

import { jsxs, jsx } from 'react/jsx-runtime';
import '../Box/index.js';
import { ReactComponent } from '@sketch/icons/checkmark-16';
import { ReactComponent as ReactComponent$1 } from '@sketch/icons/fill-circle-16';
import { Flex } from '../Box/BoxSystem.js';

const PasswordCriteria = ({ attendToCriteria, text, }) => {
    return (jsxs(Flex, Object.assign({ mt: 1, fontSize: "C", color: attendToCriteria ? 'state.positive.A' : 'foreground.secondary.D' }, { children: [attendToCriteria ? (jsx(ReactComponent, { height: 16, width: 16, color: "state.positive.A" })) : (jsx(ReactComponent$1, { height: 16, width: 16, color: "foreground.secondary.D" })), ' ', text] })));
};

export { PasswordCriteria };

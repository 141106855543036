import { __rest } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import '../../Box/index.js';
import { Wrapper } from './BaseListRow.styles.js';
import styled from 'styled-components';
import { Box } from '../../Box/BoxSystem.js';

const StyledActionsBox = styled(Box) `
  flex: 0 0 auto;
`;
const BaseListRow = (_a) => {
    var { identifier, name, avatar, inviteStatus, actions, showStripes, ariaLabel, children } = _a, boxProps = __rest(_a, ["identifier", "name", "avatar", "inviteStatus", "actions", "showStripes", "ariaLabel", "children"]);
    return (jsxs(Wrapper, Object.assign({ "aria-label": ariaLabel || `Row of ${name}`, showStripes: showStripes }, boxProps, { children: [avatar, children, actions ? jsx(StyledActionsBox, Object.assign({ ml: "auto" }, { children: actions })) : null] }), identifier));
};

export { BaseListRow };

import { __rest } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import { useContext } from 'react';
import { useKey } from 'react-use';
import '../ModalManager/index.js';
import { keyWithoutModifier, shortcuts } from '@sketch/utils';
import { Wrapper, Button, ChevronLeft, Divider, Pages, ChevronRight } from './Pagination.styles.js';
import { ModalContext } from '../ModalManager/ModalContext.js';

/**
 * Component to replace PaginationControls, in most cases you could just
 * replace PaginationControls for Pagination, keeping the same props.
 */
const Pagination = (_a) => {
    var { current, total, onClickNext, onClickPrev, minimal, dark } = _a, rest = __rest(_a, ["current", "total", "onClickNext", "onClickPrev", "minimal", "dark"]);
    const { isModalOpen } = useContext(ModalContext);
    const showNumbers = Number.isInteger(current) && Number.isInteger(total);
    const parsedTotal = total !== undefined && total > 99 ? '99+' : total;
    const hidePagination = showNumbers && total === 1;
    const minimalUI = minimal || dark || (!current && !total);
    // Disable pagination when a modal is opened, and disable prev/next for
    // first/last page
    const disablePrev = (showNumbers && (current <= 1 || isModalOpen)) || !onClickPrev;
    const disableNext = (showNumbers && (current === total || isModalOpen)) || !onClickNext;
    // Keyboard shortcuts to go to next/prev page
    useKey(keyWithoutModifier(shortcuts.paginationPrevious), () => !disablePrev && onClickPrev());
    useKey(keyWithoutModifier(shortcuts.paginationNext), () => !disableNext && onClickNext());
    return hidePagination ? null : (jsxs(Wrapper, Object.assign({}, rest, { dark: dark }, { children: [jsx(Button, Object.assign({ onClick: onClickPrev, "data-testid": "pagination-prev", disabled: disablePrev }, { children: jsx(ChevronLeft, {}) })), minimalUI ? (jsx(Divider, {})) : (jsxs(Pages, { children: [current, " of ", parsedTotal] })), jsx(Button, Object.assign({ onClick: onClickNext, "data-testid": "pagination-next", disabled: disableNext }, { children: jsx(ChevronRight, {}) }))] })));
};

export { Pagination };

import { useState } from 'react';

function useHover() {
    const [hovered, setHovered] = useState(false);
    const eventHandlers = {
        onMouseOver: () => setHovered(true),
        onMouseEnter: () => setHovered(true),
        onMouseLeave: () => setHovered(false),
    };
    return [hovered, eventHandlers, setHovered];
}

export { useHover };

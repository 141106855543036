import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { matchPath, useLocation } from 'react-router-dom';
import { IsEmbedded } from './IsEmbedded.js';
import { NotEmbedded } from './NotEmbedded.js';

/**
 * Determines if the supplied pathname matches one of the whitelisted
 * embeddable routes.
 */
const isPathnameEmbeddable = (pathname, embeddableRoutes) => Boolean(matchPath(pathname.replace(/^\/embed/, ''), {
    path: embeddableRoutes.map(route => route.template()),
    strict: false,
    exact: true,
}));
/**
 * If rendering while embedded, the EmbedGate only renders its children if the
 * initial pathname is strictly one of the whitelisted embedded routes,
 * otherwise it outputs null. While rendering normally/un-embedded it's
 * a no-op and always renders children.
 */
const EmbedGate = props => {
    const location = useLocation();
    const embeddable = isPathnameEmbeddable(location.pathname, props.embeddableRoutes);
    return (jsxs(Fragment, { children: [jsx(IsEmbedded, { children: embeddable ? props.children : null }), jsx(NotEmbedded, { children: props.children })] }));
};

export { EmbedGate };

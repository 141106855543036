import { useLocation } from 'react-router-dom'

export interface LocationState {
  currentUserFlow?:
    | 'sign-in'
    | 'sign-up'
    | 'no-workspaces'
    | 'accept-workspace-invite'
    | 'main'
}

export function useGetCurrentUserFlow() {
  const location = useLocation<LocationState | undefined>()

  return location.state?.currentUserFlow ?? 'unknown'
}

import styled, { css } from 'styled-components';
import { breakpoint } from '@sketch/global-styles';

const Divider = styled.div `
  display: flex;
  margin: 0 8px;
  color: ${({ theme }) => theme.colors.state.disabled};
`;
const WithCompletionCrumb = css `
  content: ${({ $isCompleted, $number }) => $isCompleted ? '"✓"' : `"${$number !== null && $number !== void 0 ? $number : 0 + 1}"`};
`;
const RegularCrumb = css `
  content: counter(steps);
  counter-increment: steps;
`;
const Item = styled.li(({ theme, $isActive, $showAllInMobile, $isLink, $isCompleted, $number, }) => css `
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8125rem;
    opacity: ${$isActive || $isCompleted ? '1' : '0.5'};
    pointer-events: ${$isActive ? 'none' : 'auto'};
    cursor: ${$isLink ? 'pointer' : 'default'};

    &:last-child {
      ${Divider} {
        display: none;
      }
    }

    &::before {
      ${$number === undefined ? RegularCrumb : WithCompletionCrumb};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 21px;
      height: 18px;
      margin-right: 8px;
      font-family: ${theme.fonts.monospace};
      font-size: ${theme.fontSizes.B};
      border-radius: ${theme.radii.rounded};

      color: ${$isActive
    ? theme.colors.foreground.primary.A
    : $isCompleted
        ? theme.colors.sketch.A
        : theme.colors.foreground.secondary.B};
      background-color: ${$isActive
    ? theme.colors.sketch.A
    : $isCompleted
        ? theme.colors.sketch.F
        : theme.colors.background.tertiary.A};
    }

    ${breakpoint('base', 'sm') `
      ${!$showAllInMobile &&
    !$isActive &&
    css `
          width: 0;
          overflow: hidden;
        `}

      ${!$showAllInMobile &&
    css `
          ${Divider} {
            display: none;
          }
        `}
    `}
  `);

export { Divider, Item };

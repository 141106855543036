import styled from 'styled-components';
import { FakeLinkButton, Text, Link } from '@sketch/components';
import { ReactComponent } from '@sketch/icons/checkmark-circle-fill-16';

const LinkButton = styled(FakeLinkButton) `
  font-size: ${({ theme }) => theme.fontSizes.C};
`;
const CheckIcon = styled(ReactComponent) `
  width: 16px;
  color: ${({ theme }) => theme.colors.state.positive.A};
  margin-right: 8px;
  margin-bottom: 2px; /* stylelint-disable-line scales/space */
`;
const Note = styled(Text.Div).attrs({
    textStyle: 'copy.tertiary.standard.C',
}) `
  margin: 24px 0 0 0;
`;
const NotePhrase = styled(Text.Span) `
  margin-bottom: 8px;
  display: block;

  &:last-child {
    margin-bottom: 0;
  }
`;
const BoldLinkButton = styled(FakeLinkButton) `
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;
const StyledLink = styled(Link) `
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

export { BoldLinkButton, CheckIcon, LinkButton, Note, NotePhrase, StyledLink };

import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { NavLink, Route } from 'react-router-dom';
import { useFlag, routes } from '@sketch/modules-common';
import './GeneralSettings/index.js';
import './AppearanceSettings/index.js';
import './ManagePersonalTokens/index.js';
import { PageWrapper } from './UserSettingsView.styles.js';
import { Navbar, Segment } from '@sketch/components';
import GeneralSettings from './GeneralSettings/GeneralSettings.js';
import AppearanceSettings from './AppearanceSettings/AppearanceSettings.js';
import ManagePersonalTokens from './ManagePersonalTokens/ManagePersonalTokens.js';

const UserSettingsView = (props) => {
    const { workspace, NavbarPortal, useOverrideLayoutProps } = props;
    // TODO: Remove FF "ui-refresh-24" when its released
    // https://github.com/orgs/sketch-hq/projects/326/views/1?pane=issue&itemId=65739330
    const isRefreshedUi = useFlag('ui-refresh-24');
    useOverrideLayoutProps({
        title: 'Settings',
    });
    const isDarkModeToggleEnabled = useFlag('dark-mode-toggle');
    const personalTokenManagementEnabled = useFlag('public-api-manage-personal-tokens');
    const navbar = (jsxs(Navbar.Section, Object.assign({ ml: 2 }, { children: [jsx(Segment, { type: "text", label: "General", render: (className, children) => (jsx(NavLink, Object.assign({ exact: true, className: className, to: routes.PERSONAL_SETTINGS.create({}) }, { children: children }))) }), isDarkModeToggleEnabled && (jsx(Segment, { type: "text", label: "Appearance", render: (className, children) => (jsx(NavLink, Object.assign({ exact: true, className: className, to: routes.APPEARANCE_SETTINGS.create({}) }, { children: children }))) })), personalTokenManagementEnabled && (jsx(Segment, { type: "text", label: "Access Tokens", render: (className, children) => (jsx(NavLink, Object.assign({ exact: true, className: className, to: routes.PERSONAL_SETTINGS_ACCESS_TOKENS.create({}) }, { children: children }))) }))] })));
    return (jsxs(Fragment, { children: [!isRefreshedUi && jsx(NavbarPortal, { children: navbar }), jsxs(PageWrapper, Object.assign({ "$isRefreshedUi": isRefreshedUi }, { children: [jsx(Route, Object.assign({ exact: true, path: [
                            routes.PERSONAL_SETTINGS.template(),
                            routes.PERSONAL_SETTINGS_UNSUBSCRIBE.template(),
                        ] }, { children: jsx(GeneralSettings, { currentWorkspace: workspace }) })), jsx(Route, Object.assign({ exact: true, path: [routes.APPEARANCE_SETTINGS.template()] }, { children: jsx(AppearanceSettings, {}) })), personalTokenManagementEnabled && (jsx(Route, Object.assign({ exact: true, path: [
                            routes.PERSONAL_SETTINGS_ACCESS_TOKENS.template(),
                            routes.PERSONAL_SETTINGS_ACCESS_TOKENS_CREATE.template(),
                        ] }, { children: jsx(ManagePersonalTokens, { currentWorkspace: workspace }) })))] }))] }));
};

export { UserSettingsView };

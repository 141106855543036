import React from 'react'
import Section from '../Section'
import Header, { Title } from '../Header'
import {
  ColorFormat,
  EnablableSection,
  Style as StyleType,
  LayerType,
} from 'modules/shares/types'
import Separator from '../Separator'
import Borders from '../Borders'
import Fills from '../Fills'
import Shadows from '../Shadows'
import Blur from '../Blur'

const isThereContent = (subsection: EnablableSection[] = []) =>
  subsection.some(item => item.isEnabled)

export interface LayerStyleProps {
  style: StyleType
  onColorFormatChange: (f: ColorFormat) => void
  colorFormat: ColorFormat
  layerType?: LayerType
  hideTitle?: boolean
}

const Style: React.FC<LayerStyleProps> = ({
  style,
  onColorFormatChange,
  colorFormat,
  layerType,
  hideTitle,
}) => {
  const borders = isThereContent(style.borders) ? (
    <Borders
      borders={style.borders!}
      onColorFormatChange={onColorFormatChange}
      colorFormat={colorFormat}
      borderOptions={style.borderOptions}
      originalValues={
        style.isDirty ? style.originalSharedStyleValues?.borders : undefined
      }
      originalValuesOptions={
        style.isDirty
          ? style.originalSharedStyleValues?.borderOptions
          : undefined
      }
    />
  ) : null

  const fills = isThereContent(style.fills) ? (
    <Fills
      fills={style.fills!}
      onColorFormatChange={onColorFormatChange}
      colorFormat={colorFormat}
      layerType={layerType}
      originalValues={
        style.isDirty ? style.originalSharedStyleValues?.fills : undefined
      }
    />
  ) : null

  const normalShadows = isThereContent(style.shadows) ? (
    <Shadows
      shadows={style.shadows!}
      onColorFormatChange={onColorFormatChange}
      colorFormat={colorFormat}
      originalValues={
        style.isDirty ? style.originalSharedStyleValues?.shadows : undefined
      }
    />
  ) : null

  const innerShadows = isThereContent(style.innerShadows) ? (
    <Shadows
      title="Inner Shadows"
      shadows={style.innerShadows!}
      onColorFormatChange={onColorFormatChange}
      colorFormat={colorFormat}
      originalValues={
        style.isDirty
          ? style.originalSharedStyleValues?.innerShadows
          : undefined
      }
    />
  ) : null

  const blur = style.blur?.isEnabled ? (
    <Blur
      {...style.blur}
      originalValues={
        style.isDirty ? style.originalSharedStyleValues?.blur : undefined
      }
    />
  ) : null

  const content = [fills, borders, normalShadows, innerShadows, blur].filter(
    item => item !== null
  )

  return content.length > 0 ? (
    <>
      {!hideTitle && <Separator />}
      <Section data-testid="inspector-sidebar-style">
        {!hideTitle && (
          <Header>
            <Title>Style</Title>
          </Header>
        )}
        {content.map((element, i) => (
          <React.Fragment key={i}>{element}</React.Fragment>
        ))}
      </Section>
    </>
  ) : null
}

export default Style

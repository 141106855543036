import React from 'react'

import { StyledPill } from './DiscoverNewContentPill.styles'
import { useFlag } from '@sketch/modules-common'

const DiscoverNewContentPill: React.FC = () => {
  // TODO: Remove FF "ui-refresh-24" when its released
  // https://github.com/orgs/sketch-hq/projects/326/views/1?pane=issue&itemId=65739330
  const isRefreshedUi = useFlag('ui-refresh-24')

  return (
    <StyledPill variant="secondary" $isRefreshedUi={isRefreshedUi}>
      New
    </StyledPill>
  )
}

export default DiscoverNewContentPill

import React from 'react'
import styled from 'styled-components'

import { breakpoint } from '@sketch/global-styles'
import { OpenInMacButton } from '@sketch/modules-common'
import { isMac } from '@sketch/utils'

import DocumentListEmptyState from '../DocumentListEmptyState'
import { useBreakpoint } from '@sketch/components'

interface ComponentsEmptyStateProps {
  userIsEditor: boolean
}

const Wrapper = styled.div`
  flex: 1;
  padding: 40px;
  width: 100%;

  ${breakpoint('base', 'sm')`
    padding: 16px;
  `}
`

const ComponentsEmptyState: React.FC<ComponentsEmptyStateProps> = ({
  userIsEditor,
}) => {
  const isMacUser = isMac()
  const isMobile = !useBreakpoint('md')

  const openMacAppAction =
    isMacUser && userIsEditor && !isMobile ? <OpenInMacButton /> : null

  return (
    <Wrapper>
      <DocumentListEmptyState
        title="No components created yet"
        description="Open a document in the Mac app and create components such as Symbols, Text Styles, Layer Styles and Color Variables. "
        actions={openMacAppAction}
        // Content can be found in
        // cloud-frontend/packages/build-app/public/data/discover-resource-components-empty-data.json
        editorialContent="empty-state-components"
      />
    </Wrapper>
  )
}

export default ComponentsEmptyState

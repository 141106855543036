import styled from 'styled-components';
import { breakpoint } from '@sketch/global-styles';
import '../Box/index.js';
import { Text } from '../Box/BoxSystem.js';

const SectionElement = styled(Text.Section).attrs({
    textStyle: 'copy.tertiary.standard.E',
}) ``;
const TitleAndActionsWrapper = styled.div `
  display: flex;
`;
const Title = styled(Text.H1).attrs({
    textStyle: 'header.primary.G',
}) `
  display: flex;
  margin: 0 0 12px;
`;
const TextAndButtonWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  margin-bottom: 16px;

  ${breakpoint('sm') `
    flex-direction: row;
    align-items: center;
  `}
`;
const ActionWrapper = styled.div `
  display: flex;
  align-items: center;
  margin: 8px 0 0;

  ${breakpoint('sm') `
    margin: 0 0 0 48px;
  `}
`;
const LeftWrapper = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1 0;
`;
const RightWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 0;
`;

export { ActionWrapper, LeftWrapper, RightWrapper, SectionElement, TextAndButtonWrapper, Title, TitleAndActionsWrapper };

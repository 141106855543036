import { __rest } from 'tslib';
import { jsx, jsxs } from 'react/jsx-runtime';
import styled, { css } from 'styled-components';
import '../../Box/index.js';
import '../../Input/index.js';
import '../FormLabel/index.js';
import '../FormError/index.js';
import { FormError } from '../FormError/FormError.js';
import { FormLabel } from '../FormLabel/FormLabel.js';
import { withBox } from '../../Box/withBox.js';
import { Input } from '../../Input/Input.js';

const HelpTextContainer = styled.div `
  margin-top: 6px; /* stylelint-disable-line scales/space */
  text-align: left;
`;
const HelpText = styled.small `
  display: block;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  font-size: ${({ theme }) => theme.fontSizes.C};
`;
const ErrorList = styled.ul `
  padding: 0;
  margin: 0;
  margin-top: 4px;
  text-align: left;

  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
`;
// Helper functions
const renderErrorTextArray = (errorTextArray) => (jsx(ErrorList, { children: errorTextArray.map((errorText, i) => (jsx("li", { children: jsx(FormError, { children: errorText }) }, i))) }));
const renderErrorText = (errorText) => errorText ? renderErrorTextArray([errorText]) : null;
// Unstyled StripeFormField
const StripeFormFieldBase = (_a) => {
    var { label, errorText, help, htmlFor, children } = _a, props = __rest(_a, ["label", "errorText", "help", "htmlFor", "children"]);
    return (jsxs("div", Object.assign({}, props, { children: [label && jsx(FormLabel, Object.assign({ htmlFor: htmlFor }, { children: label })), children, errorText &&
                (Array.isArray(errorText)
                    ? renderErrorTextArray(errorText)
                    : renderErrorText(errorText)), help && (jsx(HelpTextContainer, { children: jsx(HelpText, { children: help }) }))] })));
};
const StripeFormField = styled(withBox(StripeFormFieldBase)) `
  position: relative;
  margin-bottom: 1rem; /* stylelint-disable-line scales/space */

  /*
  Force the input border to be red if there is an external error.

  E.g. stripe.createSource errors don't affect the inputs, or set
  any flag in them this way we can maintain consistency in the border.
  */
  ${Input} {
    ${({ errorText, theme }) => errorText &&
    css `
        border-color: ${theme.colors.state.negative.D};
      `};
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export { StripeFormField };

import styled from 'styled-components';
import { animated } from 'react-spring';
import { Button } from '@sketch/components';

const StyledButton = styled(Button) `
  flex: 1;
  position: relative;
`;
const CopiedCodesButtonText = styled.div `
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.state.positive.A};

  svg {
    margin-right: 4px;
  }
`;
const CopyText = styled.span `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const AnimatedDiv = styled(animated.div) `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export { AnimatedDiv, CopiedCodesButtonText, CopyText, StyledButton };

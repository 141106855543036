import styled, { keyframes } from 'styled-components';
import { Text, Link } from '@sketch/components';
import '../ResponsiveImage/index.js';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage.js';

const skeletonAnimation = keyframes `
  0% {
    opacity: 0.02;
  }
  100% {
    opacity: 0.04;
  }
`;
const Title = styled(Text.H1).attrs({
    textStyle: 'prominent.primary.E',
}) `
  margin: 0;
  white-space: break-spaces;
  transition: color ${({ theme }) => theme.transitions.duration[1]};

  ::after {
    content: ' ↗';
  }
`;
const StyledLink = styled(Link) `
  :hover {
    ${Title} {
      color: ${({ theme }) => theme.colors.foreground.secondary.C};
      text-decoration: underline;
    }
  }
`;
const ImageContainer = styled.div `
  position: relative;
  width: 100%;
  margin-bottom: 12px;
  aspect-ratio: 16/9;
`;
const StyledResponsiveImage = styled(ResponsiveImage) `
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.radii.xlarge};
  object-fit: cover;
`;
const Length = styled.div `
  position: absolute;
  bottom: 8px;
  right: 8px;
  padding: 2px 8px; /* stylelint-disable-line scales/space */
  font-size: ${({ theme }) => theme.fontSizes.B};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  background: ${({ theme }) => theme.colors.background.primary.C};
  color: ${({ theme }) => theme.colors.ui.badges.foreground};
  border-radius: ${({ theme }) => theme.radii.rounded};
`;
const Content = styled(Text).attrs({
    textStyle: 'copy.quaternary.standard.E',
}) `
  margin: 0;
`;
const Skeleton = styled.span `
  display: block;
  opacity: 0.02;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  animation: ${skeletonAnimation} 500ms infinite;
  animation-direction: alternate; /* plays the animation back and forth */
  animation-timing-function: cubic-bezier(
    0,
    0.15,
    0.4,
    1
  ); /* oscillation curve */

  ::before {
    display: block;
    content: '';
    width: 100%;
    border-radius: ${({ theme }) => theme.radii.xlarge};
    margin-bottom: 12px;
    aspect-ratio: 16/9;
    background: currentColor;
  }

  ::after {
    content: '';
    display: block;
    height: 20px;
    border-radius: ${({ theme }) => theme.radii.xlarge};
    background: currentColor;
    margin-right: 20%;
  }
`;

export { Content, ImageContainer, Length, Skeleton, StyledLink, StyledResponsiveImage, Title };

const createPopperModifiers = ({ placement = 'auto', offset = [0, 5], modifiers = [], onFirstUpdate, }) => ({
    placement,
    onFirstUpdate,
    modifiers: [
        {
            name: 'computeStyles',
            options: { gpuAcceleration: false, roundOffsets: false },
        },
        { name: 'flip', options: { padding: 0 }, enabled: true },
        {
            name: 'preventOverflow',
            options: { boundary: 'clippingParents', padding: 5 },
        },
        { name: 'offset', options: { offset } },
        { name: 'hide', enabled: false },
        ...modifiers,
    ],
});

export { createPopperModifiers };

import * as Sentry from '@sentry/browser';

/**
 * This whole class is controversial and the future of this class
 * should be determined by https://github.com/sketch-hq/Cloud/issues/2975
 *
 * Until this time avoid using it unless there is no other option
 */
class EventEmitter {
    constructor() {
        this.events = {};
        this.dispatch = (event, data) => {
            const callbacks = this.events[event];
            if (!callbacks)
                return;
            /**
             * We need to make a copy here because, event handler (callback)
             * might (and actually id does) lead to new subscriptions / un-subscriptions
             * of existing callbacks.
             * Without copying the array we might end up executing wrong callback
             * and not executing the right ones.
             */
            const nonChangingCallbacks = callbacks.slice();
            for (const callback of nonChangingCallbacks) {
                try {
                    callback(data);
                }
                catch (error) {
                    Sentry.withScope(scope => {
                        scope.setExtra('EventEmitter', event);
                        Sentry.captureException(error);
                    });
                }
            }
        };
        this.subscribe = (event, callback) => {
            let callbacks = this.events[event];
            if (!callbacks) {
                callbacks = this.events[event] = [];
            }
            callbacks.push(callback);
        };
        this.unsubscribe = (event, callback) => {
            const callbacks = this.events[event];
            if (!callbacks)
                return;
            const index = callbacks.indexOf(callback);
            if (index === -1)
                return;
            callbacks.splice(index, 1);
        };
    }
}

export { EventEmitter };

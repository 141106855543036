// popper.js sometimes sets these css attributes as a NaN
// this function clears all positioning NaN's by transforming them into the number 0
function clearNaN(style) {
    if (typeof style.left === 'number' && isNaN(style.left))
        style.left = 0;
    if (typeof style.right === 'number' && isNaN(style.right))
        style.right = 0;
    if (typeof style.top === 'number' && isNaN(style.top))
        style.top = 0;
    if (typeof style.bottom === 'number' && isNaN(style.bottom))
        style.bottom = 0;
    return style;
}

export { clearNaN };

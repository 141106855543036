import { useGetComponentsQuery, ComponentInfoFragment } from '@sketch/gql-types'

import type { GetComponent } from '../types'
// This is a valid use case to narrow down Component type
// eslint-disable-next-line no-restricted-imports
import { Symbol as SymbolComponent } from '@sketch/gql-types/expansive'

import { useGetIdentifiers } from 'modules/shares/hooks/useGetIdentifiers'
import { useComponentsState } from 'modules/shares/components/ComponentsStateContext'

const isSymbolComponent = (
  component?: ComponentInfoFragment
): component is SymbolComponent => component?.__typename === 'Symbol'

export const useGetSymbols = ({ search, path }: GetComponent) => {
  const componentsState = useComponentsState()

  const {
    shareIdentifier,
    versionShortId,
    versionIdentifier,
  } = useGetIdentifiers()

  const { loading, data, fetchMore, error } = useGetComponentsQuery({
    variables: {
      shareIdentifier,
      versionShortId,
      type: 'SYMBOL',
      search,
      path,
    },
    skip: !!(search && path),
  })

  return {
    loading,
    fetchMore,
    shareIdentifier,
    versionIdentifier,
    entries:
      data?.share?.version?.document?.components.entries.filter(
        isSymbolComponent
      ) || [],
    hasComponentManifest: data?.share?.version?.hasComponentManifest,
    after: data?.share?.version?.document?.components.meta.after,
    componentsState,
    error,
  }
}

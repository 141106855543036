import { jsx, jsxs } from 'react/jsx-runtime';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import qs from 'query-string';
import { useGetPublicWorkspaceFromTokenQuery } from '@sketch/gql-types';
import { Wrapper, ItemWrapper, WorkspaceLogo, Description, InviterAvatar } from './RedirectBadge.styles.js';
import { getRedirectStateFromLocationObject } from './utils.js';

const getFirstArgument = (field) => Array.isArray(field) ? field[0] : field;
const RedirectBadge = props => {
    const redirectState = getRedirectStateFromLocationObject(props.from);
    if (!redirectState) {
        return null;
    }
    const { search, redirectId } = redirectState;
    const query = qs.parse(search || '');
    if (redirectId === 'workspace-invite' && query.token) {
        return jsx(WorkspaceInvite, { inviteToken: getFirstArgument(query.token) });
    }
    if (redirectId === 'document-invite') {
        return jsx(DocumentInvite, Object.assign({}, props));
    }
    if (redirectId === 'project-invite') {
        return jsx(ProjectInvite, Object.assign({}, props));
    }
    return null;
};
const WorkspaceInvite = ({ inviteToken }) => {
    var _a;
    const nodeRef = useRef(null);
    const { data, error } = useGetPublicWorkspaceFromTokenQuery({
        variables: { inviteToken },
    });
    if (error) {
        return null;
    }
    const shouldShowBadge = Boolean(data === null || data === void 0 ? void 0 : data.getPublicWorkspaceFromToken.name);
    return (jsx(CSSTransition, Object.assign({ nodeRef: nodeRef, in: shouldShowBadge, timeout: 200, mountOnEnter: true, unmountOnExit: true, classNames: "badge" }, { children: jsx(Wrapper, { children: jsxs(ItemWrapper, Object.assign({ ref: nodeRef }, { children: [jsx(WorkspaceLogo, { workspaceName: data === null || data === void 0 ? void 0 : data.getPublicWorkspaceFromToken.name, src: (_a = data === null || data === void 0 ? void 0 : data.getPublicWorkspaceFromToken.avatar) === null || _a === void 0 ? void 0 : _a.small, size: "32px" }), jsxs(Description, { children: ["You\u2019ve been invited to", ' ', jsx("b", { children: data === null || data === void 0 ? void 0 : data.getPublicWorkspaceFromToken.name })] })] })) }) })));
};
const DocumentInvite = ({ documentInvitation }) => {
    var _a;
    const { documentName, inviter } = documentInvitation;
    return (jsx(Wrapper, { children: jsxs(ItemWrapper, Object.assign({ "aria-label": "redirect-badge" }, { children: [inviter && (jsx(InviterAvatar, { size: "32px", src: ((_a = inviter === null || inviter === void 0 ? void 0 : inviter.avatar) === null || _a === void 0 ? void 0 : _a.large) || undefined, name: inviter.name || '?' /** Name should always be there */ })), jsxs(Description, { children: [jsx("b", { children: inviter === null || inviter === void 0 ? void 0 : inviter.name }), " wants to share the document \u201C", jsx("b", { children: documentName }), "\u201D with you"] })] })) }));
};
const ProjectInvite = ({ projectInvitation }) => {
    var _a;
    const { projectName, inviter } = projectInvitation;
    return (jsx(Wrapper, { children: jsxs(ItemWrapper, Object.assign({ "aria-label": "redirect-badge" }, { children: [inviter && (jsx(InviterAvatar, { size: "32px", src: ((_a = inviter === null || inviter === void 0 ? void 0 : inviter.avatar) === null || _a === void 0 ? void 0 : _a.large) || undefined, name: inviter.name || '?' /** Name should always be there */ })), jsxs(Description, { children: [jsx("b", { children: inviter === null || inviter === void 0 ? void 0 : inviter.name }), " wants to share the \u201C", jsx("b", { children: projectName }), "\u201D project with you"] })] })) }));
};

export { RedirectBadge };

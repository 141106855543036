import React from 'react'
import { Portal } from 'react-portal'
import { hasRequestedAccess, isMemberCurrentUser } from './utils'
import { PrivatePermissionsAccessSelect } from './PrivatePermissionsAccessSelect'
import { ConfirmationDialog } from '@sketch/components'

import { useUpdateMember } from 'modules/shares/modals/ShareSettingsModal/ShareMember/useUpdateMember'
import { useDeleteMember } from 'modules/shares/modals/ShareSettingsModal/ShareMember/useDeleteMember'
import { capitalize } from '@sketch/utils'
import {
  ShareMembershipInfoFragment,
  ShareListItemFragment,
} from '@sketch/gql-types'
import { useUserProfile } from '@sketch/modules-common'

interface MemberPermissionsActionsProps {
  member: ShareMembershipInfoFragment
  share: Pick<
    ShareListItemFragment,
    'identifier' | 'userCanUpdateSettings' | 'name'
  >
  workspaceIdentifier: string
  workspaceMembershipIdentifier: string
}
export const MemberPermissionsActions = ({
  member,
  share,
  workspaceIdentifier,
  workspaceMembershipIdentifier,
}: MemberPermissionsActionsProps) => {
  const { data: userData } = useUserProfile(true)
  const isSameUser = isMemberCurrentUser(
    userData?.me.identifier,
    member.workspaceMembership?.user?.identifier
  )

  const {
    updateMemberConfirmDialogOpen,
    getUpdateMemberConfirmDialogProps,
    getUpdateMemberDropdownProps,
    updateMemberLevel,
  } = useUpdateMember({ share, member })

  const {
    access,
    guestCommentsEnabled,
    guestInspectEnabled,
    setCommentsEnabled,
    setInspectEnabled,
    canUpdateCommentsEnabled,
  } = getUpdateMemberDropdownProps()

  const {
    deleteMember,
    deleteMemberConfirmDialogOpen,
    getDeleteMemberConfirmDialogProps,
  } = useDeleteMember({
    share,
    member,
    isMyMembership: isSameUser,
    workspaceIdentifier,
    workspaceMembershipIdentifier,
  })

  const memberIsGuest = member.workspaceMembership?.role === 'GUEST'
  const memberCommentsEnabled =
    memberIsGuest && access.level !== 'EDIT' ? guestCommentsEnabled : true
  const memberInspectEnabled =
    memberIsGuest && access.level !== 'EDIT' ? guestInspectEnabled : true
  const disableEditPermissions = !memberIsGuest || !canUpdateCommentsEnabled

  return (
    <>
      <PrivatePermissionsAccessSelect
        level={access.level}
        setLevel={level => {
          access.setLevel(level)

          if (level === 'EDIT') {
            setCommentsEnabled(true)
            setInspectEnabled(true)
          }
        }}
        setCommentsEnabled={event =>
          setCommentsEnabled(event.target.checked ?? false)
        }
        commentsEnabled={memberCommentsEnabled}
        setInspectEnabled={event =>
          setInspectEnabled(event.target.checked ?? false)
        }
        inspectEnabled={memberInspectEnabled}
        disabled={disableEditPermissions}
        showDisabledText={!memberIsGuest}
        removeButtonText={
          hasRequestedAccess(member?.inviteStatus)
            ? 'Deny Access to Document'
            : 'Remove Selected Access'
        }
        deleteMember={deleteMember}
      />
      {updateMemberConfirmDialogOpen && (
        <Portal>
          <ConfirmationDialog
            {...getUpdateMemberConfirmDialogProps()}
            title={`Change Your Access to ${capitalize(updateMemberLevel)}?`}
            confirmButton={{ text: 'Change Access', variant: 'negative' }}
          >
            You’ll no longer be able to edit “{share.name}”, but you can still{' '}
            {updateMemberLevel.toLowerCase()} it. To get edit access again, ask
            an Admin or somebody with edit access to add you.
          </ConfirmationDialog>
        </Portal>
      )}
      {deleteMemberConfirmDialogOpen && (
        <Portal>
          <ConfirmationDialog
            {...getDeleteMemberConfirmDialogProps()}
            title="Remove Edit Access?"
            confirmButton={{ text: 'Remove Access', variant: 'negative' }}
          >
            You’ll no longer be able to edit “{share.name}”, but you can still{' '}
            view or inspect it. To get edit access again, ask an Admin or
            somebody with edit access to add you.
          </ConfirmationDialog>
        </Portal>
      )}
    </>
  )
}

import { jsx, jsxs } from 'react/jsx-runtime';
import { useRef, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useModalContext, Tooltip, Button, TimeAgo, Section } from '@sketch/components';
import './ConfirmRevokeAllSessionsModal/index.js';
import './ConfirmRevokeIndividualSessionModal/index.js';
import { countries } from '@sketch/constants';
import { getDeviceNameString } from './SessionsManagementPanel.utils.js';
import { Row, Cell, DeviceInfoWrapper, DeviceInfoStatus, ActiveCircle, DeviceInfoText, DeviceName, CurrentPill, DeviceLastSeen, Table } from './SessionsManagementPanel.styles.js';
import { useGetUserSettingsQuery } from '@sketch/gql-types';
import ConfirmRevokeIndividualSessionModal from './ConfirmRevokeIndividualSessionModal/ConfirmRevokeIndividualSessionModal.js';
import ConfirmRevokeAllSessionsModal from './ConfirmRevokeAllSessionsModal/ConfirmRevokeAllSessionsModal.js';

const SessionsManagementPanel = () => {
    var _a;
    const { data, loading } = useGetUserSettingsQuery();
    const { showModal } = useModalContext();
    const location = useLocation();
    const sectionRef = useRef(null);
    useEffect(() => {
        /**
         * We need this effect to scroll to the Sessions Management section when we have the
         * anchor #sessions in the URL. We use this to link this section directly in the
         * email we send to the user informing about a new login.
         */
        if (location.hash && location.hash === '#sessions' && sectionRef.current) {
            sectionRef.current.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [location.hash, sectionRef, data]);
    const renderSignOutButton = useCallback(session => session.isCurrentOne ? (jsx(Tooltip, Object.assign({ placement: "top", content: "This is your current session, so there\u2019s no need to sign out" }, { children: jsx(Button, Object.assign({ disabled: true, variant: "secondary-untinted", size: "32" }, { children: "Sign Out\u2026" })) }))) : (jsx(Button, Object.assign({ onClick: () => showModal(ConfirmRevokeIndividualSessionModal, session), variant: "secondary-untinted", size: "32" }, { children: "Sign Out\u2026" }))), [showModal]);
    const renderRow = useCallback(session => (jsx(Row, Object.assign({ "data-testid": "sessions-list-row" }, { children: jsx(Cell, { children: jsxs(DeviceInfoWrapper, { children: [jsx(DeviceInfoStatus, { children: jsx(ActiveCircle, {}) }), jsxs(DeviceInfoText, { children: [jsxs(DeviceName, { children: [getDeviceNameString(session.type, session.userAgent, session.deviceName), ' ', session.isCurrentOne && jsx(CurrentPill, { children: "current" })] }), jsxs(DeviceLastSeen, Object.assign({ "data-testid": "session-info" }, { children: [session.lastUpdateAt && (jsx(TimeAgo, { date: session.lastUpdateAt })), ' ', "in ", countries[session.countryCode], ' ', session.ipAddress && `(${session.ipAddress})`] }))] }), renderSignOutButton(session)] }) }) }), session.id)), [renderSignOutButton]);
    const sessions = (_a = data === null || data === void 0 ? void 0 : data.me.activeSessions) === null || _a === void 0 ? void 0 : _a.entries;
    return (jsx(Section, Object.assign({ ref: sectionRef, "data-testid": "sessions-management-section", title: "Signed In Devices", text: jsx("div", { children: "You can sign out of any active sessions, particularly devices that you don't recognize." }), action: jsx(Button, Object.assign({ onClick: () => showModal(ConfirmRevokeAllSessionsModal), size: "40" }, { children: "Sign Out of All\u2026" })) }, { children: !loading && !!sessions && (jsx(Table, { header: [{ label: 'Device Info' }], items: sessions, renderItem: renderRow, hideHeader: true })) })));
};

export { SessionsManagementPanel as default };

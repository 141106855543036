import config from '@sketch/env-config';
import Plausible from 'plausible-tracker';
import '../routes/index.js';
import { getTemplateURLWithParams } from '../routes/getMatchingRoute.js';

const defaultLocation = { pathname: '', search: '' };
let previousLocation = defaultLocation;
const isJestRunning = () => process.env.JEST_WORKER_ID !== undefined;
const isPlausibleEnabled = () => Boolean(config.analytics_id) && !isJestRunning();
/**
 * By default analytics are not tracked when running on localhost but you can enable it
 * by using REACT_APP_TRACK_LOCALHOST env variable.
 *
 * `REACT_APP_TRACK_LOCALHOST=true pnpm run start`
 */
const trackLocalhost = process.env.REACT_APP_TRACK_LOCALHOST === 'true';
const plausible = Plausible({
    domain: config.analytics_id,
    apiHost: config.analytics_uri,
    /**  window.__trackLocalhost is set from Cypress tests */
    trackLocalhost: window.__trackLocalhost || trackLocalhost,
});
const initializePlausible = (history) => {
    if (!isPlausibleEnabled()) {
        return;
    }
    const setPageView = (location = defaultLocation) => {
        const isSameLocation = location.pathname === previousLocation.pathname &&
            location.search === previousLocation.search;
        if (location.pathname === '/' || isSameLocation) {
            // We don't track '/' route as it is the initial
            // pathname for the router, but doesn't match an actual
            // route within the Cloud web application
            return;
        }
        plausible.trackPageview({
            url: getTemplateURLWithParams(location),
        });
        previousLocation = location;
    };
    // We need to set the first page where the user lands.
    // history.listen will then catch the url changes from regular
    // navigation from this point on.
    setPageView(history.location);
    history.listen(setPageView);
};
/**
 * DO NOT USE THIS DIRECTLY!
 * Import `useAnalytics` from `@sketch/modules-common` instead.
 */
const _trackEvent = ((
/**
 * eventName should be pre-defined in constants/analyticsEvents.ts
 */
eventName, eventProps, options) => {
    if (!isPlausibleEnabled()) {
        return;
    }
    plausible.trackEvent(eventName, { props: (eventProps !== null && eventProps !== void 0 ? eventProps : {}) }, Object.assign(Object.assign({}, options), { url: getTemplateURLWithParams(window.location) }));
});

export { _trackEvent, initializePlausible };

import styled from 'styled-components';

const TokenDetailsItem = styled.div `
  display: flex;
  flex-direction: column;
  margin: 20px 8px 0 0;
  width: 50%;

  & span:first-child {
    font-size: ${({ theme }) => theme.fontSizes.C};
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
  }

  & span:nth-child(2) {
    word-wrap: break-word;
  }
`;

export { TokenDetailsItem as default };

import { jsxs, jsx } from 'react/jsx-runtime';
import logo from '@sketch/icons/logo-black-pdf.png';
import { MainContainer, Header, Logo, Container, Title, Text, RecoveryCodesContainer, CodeContainer, CheckMarkBox } from './RecoveryCodesPDFTemplate.styles.js';

/**
 * RecoveryCodesPDFTemplate
 *
 * Renders the recovery codes PDF template
 */
const RecoveryCodesPDFTemplate = ({ recoveryCodes, }) => (jsxs(MainContainer, { children: [jsx(Header, { children: jsx(Logo, { src: logo, alt: "sketch-logo" }) }), jsxs(Container, { children: [jsx(Title, { children: "2FA Recovery Codes" }), jsx(Text, { children: "Use these recovery codes to sign in if you lose access to your authenticator app. You can only use each recovery code once. Store them in a safe place." }), jsx(RecoveryCodesContainer, { children: recoveryCodes.map(code => (jsxs(CodeContainer, { children: [jsx(CheckMarkBox, {}), code] }, code))) }), jsxs(Text, { children: ["Need more codes? Go to your Account at", ' ', jsx("a", Object.assign({ href: "https://www.sketch.com/" }, { children: "Sketch.com" }))] })] })] }));

export { RecoveryCodesPDFTemplate };

import { __rest } from 'tslib';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import React, { useRef, useCallback, useImperativeHandle, useEffect, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';
import { useKey } from 'react-use';
import { debounce } from 'debounce';
import { breakpoint } from '@sketch/global-styles';
import { useFocusTrap, FOCUSABLE_ELEMENTS, keyWithoutModifier } from '@sketch/utils';
import '../DisableBodyScroll/index.js';
import { ModalHeader } from './ModalHeader.js';
import { ModalBody } from './ModalBody.js';
import { DisableBodyScroll } from '../DisableBodyScroll/DisableBodyScroll.js';

const BackdropStyle = styled.div `
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex[7]};

  top: 0;
  left: 0;

  padding: 80px 0 0; /* stylelint-disable-line scales/space */

  ${breakpoint('xs') `
    padding: 5vh 0; /* stylelint-disable-line scales/space */
  `}

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  background-color: ${({ theme }) => theme.colors.overlay};

  user-select: none;

  &.modal-enter {
    opacity: 0;
  }
  &.modal-enter-active {
    opacity: 1;
    transition: opacity ${({ theme }) => theme.transitions.duration[2]};
  }
  &.modal-exit {
    opacity: 1;
  }
  &.modal-exit-active {
    opacity: 0;
    transition: opacity ${({ theme }) => theme.transitions.duration[2]};
  }
`;
const Container = styled.section(({ theme }) => css `
    margin: auto 0 0;

    width: 100%;
    max-width: 512px;
    font-size: 0.8125rem;

    max-height: 100%;
    transform: translate3d(0, 0, 0);

    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: none;

    border-radius: ${theme.radii.xxxlarge} ${theme.radii.xxxlarge} 0 0;
    background-color: ${theme.colors.background.secondary.A};
    background-clip: padding-box;

    box-shadow: 0 8px 16px 0 ${theme.colors.shadow.outer},
      0px 0px 0px 1px ${theme.colors.border.A};

    ${breakpoint('xs') `
      border-radius: ${theme.radii.xxxlarge};
      margin: auto 0;
    `}

    .modal-enter & {
      opacity: 0;
      transform: translateY(10px);
    }

    .modal-enter-active & {
      opacity: 1;
      transform: translateY(0);
      transition: ${`
        opacity  ${theme.transitions.duration[1]},
        transform  ${theme.transitions.duration[2]} ${theme.transitions.timing.easeInOut}
    `};
    }

    .modal-exit & {
      opacity: 1;
      transform: translateY(0);
    }

    .modal-exit-active & {
      opacity: 0;
      transform: translateY(10px);
      transition: ${`
      opacity  ${theme.transitions.duration[2]},
      transform  ${theme.transitions.duration[1]} ${theme.transitions.timing.easeInOut}
    `};
    }

    & p {
      margin: 0;
      padding-bottom: 20px;
    }

    & p:last-child {
      padding-bottom: 0;
    }

    /* Ensure modals without a header have some spacing above the body */
    & > ${ModalBody} {
      &:first-child {
        margin-top: 20px;
      }
    }

    /* stylelint-disable selector-type-no-unknown */
    ${ModalBody},
    ${ModalBody} + ${ModalBody} {
      border-bottom: 1px solid ${theme.colors.border.A};
      padding-top: 20px;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        border-bottom: 0;
      }
    }

    /* stylelint-enable */
    user-select: text;
  `);
const Backdrop = ({ children, onClick }) => {
    const backdropRef = useRef(null);
    const hasMouseDown = useRef(false);
    /**
     * Unintended modal closing is happening because of event
     * bubbling to Backdrop, so we need to detect when a complete
     * click (mouse down and up) is happening within Backdrop.
     * The modal will only be closed when a previous mouseDown
     * event is triggered before click.
     *
     * Learn more: https://github.com/sketch-hq/cloud-frontend/pull/2183
     */
    const handleMouseDown = useCallback((event) => {
        if (event.target !== backdropRef.current) {
            return;
        }
        hasMouseDown.current = true;
    }, []);
    const handleClick = useCallback((event) => {
        if (!onClick ||
            event.target !== backdropRef.current ||
            !hasMouseDown.current) {
            return;
        }
        onClick();
    }, [onClick]);
    return (jsx(BackdropStyle, Object.assign({ "data-testid": "modal-backdrop", ref: backdropRef }, (onClick && { onClick: handleClick }), { onMouseDown: handleMouseDown }, { children: children })));
};
/**
 * Set of Modal primitives to build modals/dialogs. These primitives work
 * together with `ModalProvider` and `ModalRoot`, that handles the logic to
 * open and close modals.
 *
 * Most basic example:
 *
 * ```jsx
 * <Modal title="Modal tile">
 *  <Modal.Body>Body text</Modal.Body>
 *  <Modal.Footer>Footer</Modal.Footer>
 * </Modal>
 * ```
 *
 * Some considerations:
 *
 * - To open/hide modals you should use the hook `useModalContext`.
 * - You'll get some useful injected props in any modal: `isModalOpen` and `hideModal`.
 */
const Modal = React.forwardRef(function Modal(_a, ref) {
    var { title, onCancel, style, children, onUpdateScroll, cancelOnClickOutside = true, autoFocusOnOpen = true } = _a, otherProps = __rest(_a, ["title", "onCancel", "style", "children", "onUpdateScroll", "cancelOnClickOutside", "autoFocusOnOpen"]);
    const containerRef = useRef(null);
    useImperativeHandle(ref, () => containerRef.current);
    useFocusTrap(containerRef);
    // Automatically focus on the first focusable element
    useEffect(() => {
        var _a;
        if (!autoFocusOnOpen) {
            return;
        }
        const firstItem = (_a = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(FOCUSABLE_ELEMENTS);
        if (firstItem) {
            firstItem.focus();
        }
    }, [autoFocusOnOpen]);
    useLayoutEffect(() => {
        // used to notify the parent component if the modal has overflow
        const updateBodyHeight = () => {
            var _a, _b, _c, _d;
            const clientHeight = (_b = (_a = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) !== null && _b !== void 0 ? _b : 0;
            const scrollHeight = (_d = (_c = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _c === void 0 ? void 0 : _c.scrollHeight) !== null && _d !== void 0 ? _d : 0;
            onUpdateScroll && onUpdateScroll(clientHeight < scrollHeight);
        };
        updateBodyHeight();
        window.addEventListener('resize', () => debounce(updateBodyHeight, 250));
        return () => window.removeEventListener('resize', updateBodyHeight);
    }, [containerRef, onUpdateScroll]);
    useKey(keyWithoutModifier('Escape'), () => {
        if (cancelOnClickOutside) {
            onCancel();
        }
    });
    return (jsxs(Fragment, { children: [jsx(DisableBodyScroll, {}), jsx(Backdrop, Object.assign({}, (cancelOnClickOutside && { onClick: onCancel }), { children: jsxs(Container, Object.assign({ role: "dialog", style: style }, otherProps, { ref: containerRef }, { children: [title && jsx(ModalHeader, { children: title }), children] })) }))] }));
});

export { Modal };

import React from 'react'

import {
  BaseListRow,
  BaseListRowInfo,
  BaseListRowAvatar,
  BaseProps,
  WorkspaceMembershipRole,
  InviteStatus,
} from '@sketch/components'

interface UserRowProps extends Pick<BaseProps, 'name' | 'identifier'> {
  email?: string
  role?: WorkspaceMembershipRole
  inviteStatus?: InviteStatus
  avatarSrc?: string | null
  isCurrentUser: boolean
}

export const UserRow = ({
  identifier,
  name,
  email,
  avatarSrc,
  role,
  inviteStatus,
  isCurrentUser,
}: UserRowProps) => (
  <BaseListRow
    avatar={
      <BaseListRowAvatar
        src={avatarSrc}
        name={`${name || email} avatar`}
      ></BaseListRowAvatar>
    }
    identifier={identifier}
    name={name}
    showStripes={false}
    pl={0}
  >
    <BaseListRowInfo
      identifier={identifier}
      title={name}
      subtitle={email}
      inviteStatus={inviteStatus}
      role={role}
      isCurrentUser={isCurrentUser}
    />
  </BaseListRow>
)

import styled from 'styled-components';
import { MentionsInput as MentionsInput$1 } from 'react-mentions';
import '../List/index.js';
import { ListItemIcon, listItemHoverStyle } from '../List/List.styles.js';
import { ListItem } from '../List/List.js';

const MentionWrapper = styled.div `
  list-style: none;
  padding: 0px 8px;

  ${ListItemIcon} {
    line-height: 1;
    opacity: 1;
  }

  /* Needed for the keyboard selection */
  & [class*='--focused'] ${ListItem} {
    ${listItemHoverStyle};
  }
`;
const MentionsInput = styled(MentionsInput$1) `
  font-size: 0.8125rem; /* 13px */
  margin: 8px 8px 12px;

  max-height: 150px;
  overflow-x: auto;

  position: relative;

  textarea {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};

    ::placeholder {
      color: ${({ theme }) => theme.colors.foreground.secondary.D};
    }
  }
`;
const MentionPopoverFakeButton = styled.div `
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`;

export { MentionPopoverFakeButton, MentionWrapper, MentionsInput };

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { Title, Description, Links, StyledLink } from '../Setup2FAModal.styles.js';

/**
 * AuthAppsStep
 *
 * Renders the first step for the 2FA Setup Modal
 */
const AuthAppsStep = () => {
    return (jsxs(Fragment, { children: [jsx(Title, { children: "Install an Authenticator App" }), jsx(Description, { children: "First, install an authenticator app on your phone. Popular authenticators include:" }), jsxs(Links, { children: [jsx(StyledLink, Object.assign({ href: "https://apps.apple.com/gb/app/google-authenticator/id388497605", target: "_blank", isUnderlined: true, external: true }, { children: "Google Authenticator" })), jsx(StyledLink, Object.assign({ href: "https://apps.apple.com/us/app/authy/id494168017", target: "_blank", isUnderlined: true, external: true }, { children: "Authy" })), jsx(StyledLink, Object.assign({ href: "https://apps.apple.com/us/app/microsoft-authenticator/id983156458", target: "_blank", isUnderlined: true, external: true }, { children: "Microsoft Authenticator" }))] })] }));
};

export { AuthAppsStep };

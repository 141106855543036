import styled, { css } from 'styled-components'

import { ReactComponent as DotsIcon } from '@sketch/icons/dots-3-horizontal-24'
import { ReactComponent as DividerSvg } from '@sketch/icons/dots-4-vertical-divider-32'
import { ReactComponent as StackPlus } from '@sketch/icons/square-stack-plus-circle-24'

import { Button, WorkspaceLogo } from '@sketch/components'
import { AvatarWrapper } from './SharingButton.styles'
import { breakpoint } from '@sketch/global-styles'

export const HeaderButton = styled(Button).attrs({
  size: '40',
  variant: 'secondary',
})``

export const HeaderButtonUntinted = styled(Button).attrs({
  size: '40',
  variant: 'secondary-untinted',
})``

export const SharingButton = styled(HeaderButton)`
  padding: 7px 12px 7px 7px; /* stylelint-disable-line scales/space */
  margin-right: 16px;

  ${WorkspaceLogo.Icon} {
    border: 1px solid ${({ theme }) => theme.colors.border.A};
    color: ${({ theme }) => theme.colors.foreground.secondary.C};
  }

  ${AvatarWrapper} {
    margin-right: 0px;
  }
`

export const CreateCollectionButton = styled(HeaderButtonUntinted).attrs({
  'aria-label': 'Create collection',
})`
  margin-right: 16px;
`

export const Dots = styled(DotsIcon)`
  width: 24px;
  height: 24px;
`

export const Divider = styled(DividerSvg)`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  width: 12px;
  height: 24px;
`

export const ButtonWrapper = styled.div<{ $headerSlim?: boolean }>`
  display: flex;
  min-width: 0;
  align-items: center;

  ${({ $headerSlim }) =>
    $headerSlim &&
    css`
      ${breakpoint('base', 'md')`
      display: none;
      `}
    `}
`

export const TextWrapper = styled.div`
  line-height: 1;
  margin-left: 12px;
`

export const CollectionIcon = styled(StackPlus)`
  width: 24px;
  height: 24px;
`

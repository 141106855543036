import { __rest } from 'tslib';
import { useGetPersonalTokensQuery } from '@sketch/gql-types';

function useGetPersonalTokens() {
    const personTokensResult = useGetPersonalTokensQuery();
    const { data: personalTokensData } = personTokensResult, rest = __rest(personTokensResult, ["data"]);
    return Object.assign(Object.assign({}, rest), { personalTokens: personalTokensData === null || personalTokensData === void 0 ? void 0 : personalTokensData.me.personalTokens });
}

export { useGetPersonalTokens };

import './polyfills'
import 'normalize.css'
import App from './containers/App'
import React from 'react'
import ReactDOM from 'react-dom'
import {
  handleResetToken,
  createSentryConfiguration,
  isDeployedInStaging,
  isDeployedInProduction,
} from '@sketch/utils'
import config, { RELEASE_INFO } from '@sketch/env-config'

import { createHistory } from './browserHistory'
import * as Sentry from '@sentry/browser'
import { ErrorLogger } from '@sketch/tracing'
import { setAutoFreeze } from 'immer'
import { initializePlausible } from '@sketch/analytics'

window.__CONFIG = config
window.__RELEASE_INFO = RELEASE_INFO

if (!isDeployedInProduction()) {
  console.log(RELEASE_INFO)
}

const history = createHistory()

/**
 * Immer AutoFreeze is disabled
 *
 * Auto freeze needs to be disabled due to
 * issues while modifying frozen objects
 * in cache mutations
 *
 * See https://github.com/sketch-hq/cloud-frontend/pull/3258
 */
setAutoFreeze(false)

const getMaxEvents = () => {
  const maxEvents = localStorage.getItem('sentry_limit')
  if (!maxEvents) {
    return undefined
  }

  const parsedMaxEvents = Number(maxEvents)
  if (isNaN(parsedMaxEvents)) {
    return undefined
  }

  return parsedMaxEvents
}

// Check for reset token presence and
// handle it to prevent referrer leak
// https://github.com/sketch-hq/Cloud/issues/3898
if (!handleResetToken(window.location)) {
  if (
    isDeployedInStaging() ||
    isDeployedInProduction() ||
    localStorage.getItem('sentry_limit')
  ) {
    const maxEvents = getMaxEvents()
    if (maxEvents) {
      console.log(
        `%c
      >> Sentry limit is set, please don't forget to clean-up localStorage.
      >> USAGE:
      __SENTRY__.logger._enabled = true;
      __SENTRY__.hub.captureMessage('My Test')
      `,
        'background: green; color: white; display: block;'
      )
    }
    Sentry.init(
      createSentryConfiguration({
        environment: RELEASE_INFO.app_env,
        release: RELEASE_INFO.release_version,
        maxEvents,
      })
    )
  }

  // Used REACT_APP_ENV instead of isDeployedInProduction to enable the bundler to remove the code and don't expose it in production
  if (process.env.REACT_APP_ENV !== 'production') {
    window.switchEnv = env => {
      window.localStorage.setItem('app_env', env)
      window.location.reload()
    }
  }

  // Expose error logger
  window.errorLogger = ErrorLogger

  initializePlausible(history)

  const rootEl = document.getElementById('root')

  ReactDOM.render(<App history={history} />, rootEl)

  if (module.hot) {
    module.hot.accept('./containers/App', () => {
      ReactDOM.render(<App history={history} />, rootEl)
    })
  }
}

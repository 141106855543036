import { localStorageKeys } from '@sketch/constants';

const createLocalStorageSubscription = () => {
    const listenerByKey = {};
    let storageEventListener;
    const createStorageEvent = (observers) => (event) => {
        const storageKey = event.key;
        const listener = observers[storageKey];
        if (!listener)
            return;
        const data = event.newValue && JSON.parse(event.newValue);
        const oldData = event.oldValue && JSON.parse(event.oldValue);
        if (JSON.stringify(oldData) === JSON.stringify(data)) {
            return;
        }
        listener(storageKey, data, oldData);
    };
    /**
     * Subscribes to changes on the local storage for a specific key.
     * It returns the subscription in case it has to be removed.
     */
    const trackStorageChanges = (keyName, listener) => {
        const storageKey = localStorageKeys[keyName];
        listenerByKey[storageKey] = listener;
        if (!storageEventListener) {
            storageEventListener = createStorageEvent(listenerByKey);
            window.addEventListener('storage', storageEventListener);
        }
        return storageEventListener;
    };
    return { trackStorageChanges };
};

export { createLocalStorageSubscription };

import styled, { css } from 'styled-components';
import { ReactComponent } from '@sketch/icons/question-mark-16';

const StyledHelpIcon = styled(ReactComponent) `
  width: 16px;
  height: 16px;
  margin-left: 4px;
  vertical-align: sub;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  ${({ $isLink }) => $isLink &&
    css `
      &:hover {
        color: ${({ theme }) => theme.colors.foreground.secondary.A};
      }
    `}
`;

export { StyledHelpIcon };

import { UserFragment } from '@sketch/gql-types'

export type Identifier = UserFragment['identifier'] | null | undefined

export const isMemberCurrentUser = (
  memberIdentifier: Identifier,
  userIdentifier: Identifier
) => memberIdentifier === userIdentifier

export const getNameOrEmailFromMembership = (
  isMemberCurrentUser: boolean,
  name: string,
  email?: string
) => {
  if (isMemberCurrentUser) {
    if (!email) {
      return `${name} (You)`
    }

    return `${name || email} (You)`
  }

  return name || email || ''
}

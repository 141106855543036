import { useEffect } from 'react';
import { getPageLoadMetrics } from '../pageLoad/getPageLoadMetrics.js';
import '../telemetry/index.js';
import { trackMetric } from '../telemetry/telemetry.js';

const initializePageLoadAnalytics = () => {
    // expose global metrics object for local debugging
    let metrics = null;
    window.__getPageLoadMetrics = () => metrics;
    const createTelemetryPayload = (id, value = 0, labels = {}) => {
        const valueInSeconds = (value !== null && value !== void 0 ? value : 0) / 1000; // convert to seconds
        const truncatedValue = Math.round(valueInSeconds * 100) / 100; // truncate to 2 decimal places
        return { id, value: truncatedValue, labels };
    };
    getPageLoadMetrics((data, name) => {
        // update metrics object
        metrics = data;
        const route = data.route.template;
        if (name === 'lcp' && data.lcp) {
            const { payload, wasRedirected } = data.lcp;
            trackMetric(createTelemetryPayload('webapp_lcp_raw_duration_seconds', payload.metric.value, { route, wasRedirected }));
            if (payload.isAfterReceivingData) {
                trackMetric(createTelemetryPayload('webapp_lcp_filtered_duration_seconds', payload.metric.value, { route, wasRedirected }));
            }
        }
        if (name === 'elementTiming' && data.elementTiming) {
            const { payload, wasRedirected } = data.elementTiming;
            trackMetric(createTelemetryPayload('webapp_element_timing_duration_seconds', payload.value, { route, wasRedirected }));
        }
        if (name === 'customElementTiming' && data.customElementTiming) {
            const { payload, wasRedirected } = data.customElementTiming;
            trackMetric(createTelemetryPayload('webapp_custom_element_timing_duration_seconds', payload.value, { route, wasRedirected }));
        }
    }, window.location.pathname);
};
const PageLoadAnalytics = () => {
    useEffect(() => {
        // We want to avoid analytics requests if runnning under Cypress
        if (window.Cypress) {
            return;
        }
        initializePageLoadAnalytics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export { PageLoadAnalytics };

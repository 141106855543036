import styled from 'styled-components';
import { Form, Text } from '@sketch/components';

const StyledForm = styled(Form) `
  margin-top: 24px;
`;
const NewPasswordCaption = styled(Text.Span).attrs({
    textStyle: 'copy.quaternary.standard.C',
}) `
  display: block;
  margin-top: 4px;
`;

export { NewPasswordCaption, StyledForm };

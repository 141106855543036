import styled, { css } from 'styled-components';
import { breakpoint } from '@sketch/global-styles';
import '../Button/index.js';
import { StyledButton } from '../Button/Button.styles.js';

const Title = styled.h1 `
  margin: 0;
`;
const ActionWrapper = styled.div `
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  /*
    This style is needed to force the buttons of the empty state
    in the mobile view to look properly. Otherwise they will wrap and 
    have a left margin, causing some poor alignment.
  */
  ${breakpoint('base', 'xs') `
    ${css `
      ${StyledButton} + ${StyledButton}, ${StyledButton} + a {
        margin-left: 0px;
      }

      ${StyledButton}:not(:last-child) {
        margin-right: 16px;
        margin-bottom: 8px;
      }
    `}  
  `}
`;

export { ActionWrapper, Title };

import { jsxs, jsx } from 'react/jsx-runtime';
import { useEffect } from 'react';
import styled from 'styled-components';
import { downloadFile, flatButtonStyles, Modal, Button } from '@sketch/components';
import '../../utils/index.js';
import { SKETCH_WEBSITE } from '@sketch/env-config';
import { setSketchUrlScheme } from '../../utils/setSketchUrlScheme/setSketchUrlScheme.js';

// Possible actions to launch Sketch app
const sketchActions = {
    addLibrary: ({ shareId }) => setSketchUrlScheme(`add-library/cloud/${shareId}`),
    newFromTemplate: ({ shareId }) => setSketchUrlScheme(`new-from-template/cloud/${shareId}`),
    openInSketch: ({ shareId, pageUUID }) => {
        let url = `sketch.cloud/s/${shareId}`;
        if (pageUUID) {
            url = `${url}/p/${pageUUID}`;
        }
        return setSketchUrlScheme(url);
    },
};
// Custom hook to group the logic to launch the Sketch app
function useSketchUrlSchema({ shareId, pageUUID, action }) {
    useEffect(() => {
        const getSketchActionUrl = (shareId, action) => sketchActions[action]({
            shareId,
            pageUUID,
        });
        downloadFile(getSketchActionUrl(shareId, action));
    }, [shareId, action, pageUUID]);
}
const Anchor = styled.a.attrs({
    variant: 'primary',
    intent: 'primary',
}) `
  ${flatButtonStyles};
  padding: 0;
`;
const LaunchSketchAlert = ({ shareId, hideModal, action, pageUUID, }) => {
    useSketchUrlSchema({ shareId, action, pageUUID });
    return (jsxs(Modal, Object.assign({ onCancel: hideModal }, { children: [jsx(Modal.Header, { children: "Launching the Sketch Mac App\u2026" }), jsxs(Modal.Body, { children: ["If nothing happens, you may need to", ' ', jsx(Anchor, Object.assign({ href: `${SKETCH_WEBSITE}/get`, target: "_blank", rel: "noopener noreferrer" }, { children: "install the Mac app" })), ' ', "and try again."] }), jsx(Modal.Footer, { children: jsx(Button, Object.assign({ onClick: hideModal }, { children: "Close" })) })] })));
};

export { LaunchSketchAlert };

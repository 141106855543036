import { useEffect, useRef, useState } from 'react'

export function useExportPreview(
  createPreviewUrl: () => Promise<string | null>
) {
  const createPreviewUrlRef = useRef(createPreviewUrl)
  createPreviewUrlRef.current = createPreviewUrl

  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null)

  useEffect(() => {
    const exportPreview = async () => {
      const createPreview = createPreviewUrlRef.current
      const url = await createPreview()
      if (url) {
        setPreviewImageUrl(url)
      }
    }

    exportPreview()
  }, [])

  return previewImageUrl
}

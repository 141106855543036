import { useState } from 'react';
import { getParsedItem, setItem } from '../utils.js';

function useLocalStorage(key, initialValue) {
    var _a;
    // State to store our value, pass initial state function to useState so logic
    // is only executed once
    const [storedValue, setStoredValue] = useState((_a = getParsedItem(key)) !== null && _a !== void 0 ? _a : initialValue);
    // Return a wrapped version of useState's setter function that persists the
    // new value to localStorage
    const setValue = (value) => {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        setItem(key, JSON.stringify(valueToStore));
    };
    return [storedValue, setValue];
}

export { useLocalStorage };

const MAX_STRING_LENGTH = 20;
/**
 * insprired by https://reactjs.org/docs/higher-order-components.html
 */
function getDisplayName(WrappedComponent) {
    try {
        const Component = WrappedComponent;
        if (typeof Component === 'string') {
            return Component;
        }
        if (Component.displayName || Component.name) {
            return Component.displayName || Component.name;
        }
        if (typeof Component === 'function') {
            const compString = Component.toString();
            return compString.length > MAX_STRING_LENGTH
                ? compString.substr(0, MAX_STRING_LENGTH) + '...'
                : compString;
        }
    }
    catch (_a) {
        // this should never happen, but in case we missed something - let's be safe
        return 'Component';
    }
    // this should never happen, but in case we missed something - let's be safe
    return 'Component';
}

export { getDisplayName };

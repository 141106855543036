import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "play-prism-32", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 32 32", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("defs", null,
        React.createElement("circle", { id: "play-prism-32_svg__a", cx: 16, cy: 16, r: 16 })),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("use", { fill: "#000", xlinkHref: "#play-prism-32_svg__a" }),
        React.createElement("path", { d: "M13.505 20.302l6.52-3.872a.5.5 0 000-.86l-6.52-3.872a.5.5 0 00-.755.43v7.744a.5.5 0 00.755.43z", fill: "#FFF", stroke: "#FFF", strokeLinejoin: "round", strokeWidth: 1.5 })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };

const getItem = (key) => {
    var _a;
    try {
        return (_a = window.localStorage.getItem(key)) !== null && _a !== void 0 ? _a : undefined;
    }
    catch (e) {
        return undefined;
    }
};
const getParsedItem = (key) => {
    try {
        const rawItem = getItem(key);
        if (rawItem === undefined)
            return undefined;
        return JSON.parse(rawItem);
    }
    catch (e) {
        return undefined;
    }
};
const setItem = (key, item) => {
    try {
        window.localStorage.setItem(key, item);
    }
    catch (e) {
        // The error will be ignored
    }
};
const setStringifiedItem = (key, item) => {
    try {
        window.localStorage.setItem(key, JSON.stringify(item));
    }
    catch (e) {
        // The error will be ignored
    }
};
const removeItem = (key) => {
    try {
        window.localStorage.removeItem(key);
    }
    catch (e) {
        // The error will be ignored
    }
};

export { getItem, getParsedItem, removeItem, setItem, setStringifiedItem };

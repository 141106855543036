import styled, { css } from 'styled-components'
import {
  Table as BaseTable,
  TableComponents,
  Filter as BaseFilter,
  Skeleton,
  Modal as BaseModal,
  LoadingPlaceholder as BaseLoadingPlaceholder,
} from '@sketch/components'
import { PermissionGroupItem } from '@sketch/modules-common'

import { StatusIcon } from 'modules/shares/components/DocumentItem/DocumentItem.styles'

export const PermissionGroup = styled(PermissionGroupItem)`
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  border-radius: ${({ theme }) => theme.radii.large};
`

export const Divider = styled.div`
  margin: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const TableWrapper = styled.div`
  max-height: 300px;
  overflow: auto;
`

export const Table = styled(BaseTable)`
  width: 100%;
` as typeof BaseTable

export const MinimumTableCell = styled(TableComponents.TableCell)`
  width: 0;
`

export const Filter = styled(BaseFilter)`
  margin: 16px 0;
`

export const ThumbnailSkeleton = styled(Skeleton)`
  width: 32px;
  height: 32px;
  border-radius: ${({ theme }) => theme.radii.medium};
  box-shadow: 0px 0px 0px 0px inset ${({ theme }) => theme.colors.border.A};
`

export const ImageWrapper = styled.div(
  ({ theme }) => css`
    /* We need to make sure the size is set to prevent the image from expanding/shrinking */
    width: 32px;
    height: 32px;

    border-radius: ${theme.radii.medium};
    background-color: ${theme.colors.state.hover};

    position: relative;
    overflow: hidden;
    padding: 16px;

    flex-shrink: 0;

    ${StatusIcon} {
      width: 16px;
      height: 16px;
    }

    /* Make sure the Wrapper is a responsive rectangle  */
    :before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    /* Create the border inside */
    :after {
      content: '';
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.border.A};
      position: absolute;

      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: ${theme.radii.medium};
    }
  `
)

export const ProjectIcon = styled(StatusIcon)`
  width: 16px;
  height: 16px;
`

export const EmptyDescriptionWrapper = styled.div`
  max-width: 500px;
  pointer-events: none;
`

export const Modal = styled(BaseModal)`
  max-width: 705px;
`

export const LoadingPlaceholder = styled(BaseLoadingPlaceholder)`
  margin: auto;
  display: flex;
`

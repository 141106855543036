import { jsx, jsxs } from 'react/jsx-runtime';
import { SKETCH_WEBSITE } from '@sketch/env-config';
import { Wrapper, StyledLink, Arrow } from './DiscoverNavBar.styles.js';

/**
 *
 * DiscoverNavBar
 *
 * Top navigation bar for the Discover View
 */
const DiscoverNavBar = () => {
    return (jsx(Wrapper, { children: jsxs(StyledLink, Object.assign({ variant: "tertiary", href: `${SKETCH_WEBSITE}/home`, external: true }, { children: ["Go to Sketch.com", jsx(Arrow, {})] })) }));
};

export { DiscoverNavBar as default };

import styled from 'styled-components';
import '../Link/index.js';
import { Link } from '../Link/Link.js';

const LinkUnstyled = styled(Link) `
  color: inherit;
  text-decoration: inherit;

  &:hover,
  &:active,
  &:link {
    color: inherit;
    text-decoration: inherit;
  }
`;

export { LinkUnstyled };

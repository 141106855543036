import styled, { css } from 'styled-components'

import { Pill } from '@sketch/components'

export const StyledPill = styled(Pill)<{ $isRefreshedUi: boolean }>`
  margin-left: auto;

  .active & {
    background-color: ${({ theme }) => theme.colors.sketch.F};
    color: ${({ theme }) => theme.colors.sketch.A};
  }

  ${({ theme, $isRefreshedUi }) =>
    $isRefreshedUi &&
    css`
      background-color: ${theme.colors.sketch.A};
      color: ${theme.colors.foreground.primary.A};

      .active & {
        background-color: ${theme.colors.sketch.A};
        color: ${theme.colors.foreground.primary.A};
      }
    `}
`

import styled from 'styled-components';
import { bannerCardWrapperBaseCss, bannerCardTitleBaseCss, bannerCardDescriptionBaseCss, bannerCardIconContainerBaseCss } from './BannerCard.styles.js';
import { breakpoint } from '@sketch/global-styles';

const BannerWrapper = styled.div `
  ${bannerCardWrapperBaseCss}
  padding: 24px;
  display: flex;
  flex-direction: row;
  flex: 1;
  max-width: 100%;
`;
const BannerTitle = styled.p `
  ${bannerCardTitleBaseCss}
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  margin-top: 16px 0 0 0;
`;
const BannerDescription = styled.p `
  ${bannerCardDescriptionBaseCss}
  margin-top: 6px;
`;
const BannerIconContainer = styled.div `
  ${bannerCardIconContainerBaseCss}
`;
const BannerBodyContainer = styled.div `
  margin-left: 12px;

  ${breakpoint('xs') `
    margin-left: 16px;
  `}
`;
const BannerTextContainer = styled.div `
  margin-bottom: 13px;

  ${breakpoint('xs') `
    margin-bottom: 16px;
  `}
`;

export { BannerBodyContainer, BannerDescription, BannerIconContainer, BannerTextContainer, BannerTitle, BannerWrapper };

import { Modal, Pill, Skeleton } from '@sketch/components'
import styled from 'styled-components'
import { ReactComponent as PlusIcon } from '@sketch/icons/plus-16'
import { ReactComponent as ArrowCircleIcon } from '@sketch/icons/clockwise-arrow-circle-16'
import { ReactComponent as ControlSwitchesIcon } from '@sketch/icons/control-switches-16'

export const StyledModalHeader = styled(Modal.Header)`
  background-color: #f9f9f9;
  /* !important to override all padding set across different breakpoints */
  padding: 0 !important; /* stylelint-disable-line declaration-no-important */
`

export const StyledModalBody = styled(Modal.Body)`
  /* !important to override all padding-top set across different selectors in Modal.tsx and ModalBody.tsx */
  padding-top: 0px !important; /* stylelint-disable-line declaration-no-important */
`

export const ExportModalSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 32px 8px 32px; /* stylelint-disable-line scales/space */
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes.E};
  background-color: ${({ theme }) => theme.colors.background.secondary.A};
`

export const HeaderActionsContainer = styled.div`
  display: flex;
  /* Overwrite crazy rule set in the button component
   https://github.com/sketch-hq/cloud-frontend/blob/52f2211a17f65ce37ee8385d6e08c023e572ac81/packages/components/src/Button/Button.styles.ts#L63
   */
  button + button {
    margin-left: 0px;
  }
`

export const PreviewImage = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
`
export const PreviewContainer = styled.div`
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
`

export const PreviewSkeleton = styled(Skeleton)`
  width: 100%;
  height: 100%;
`

export const StyledPlusIcon = styled(PlusIcon)`
  width: 16px;
  height: 16px;
`

export const StyledArrowCircleIcon = styled(ArrowCircleIcon)`
  width: 16px;
  height: 16px;
`

export const StyledControlSwitchesIcon = styled(ControlSwitchesIcon)`
  width: 16px;
  height: 16px;
`

export const ExportItemsListContainer = styled.div`
  overflow-y: auto;
  /* We need to leave 5px for the input focus border to not be clipped on safari. */
  padding-top: 5px; /* stylelint-disable-line scales/space */
`
export const FormatTagListContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const StyledPill = styled(Pill)`
  background-color: ${({ theme }) => theme.colors.background.primary.C};
`

import { jsx } from 'react/jsx-runtime';
import { GuestBadge, Pill as StyledPill } from './BaseRow/BaseListRow.styles.js';

const badgeForMemberRole = ({ role, inviteStatus, }) => {
    switch (inviteStatus) {
        case 'INVITED':
            return (jsx(StyledPill, Object.assign({ role: "status-badge", variant: "secondary" }, { children: "Invitation Pending" })));
        case 'REQUESTED':
            switch (role) {
                case 'MEMBER':
                    return null;
                default:
                    return jsx(GuestBadge, Object.assign({ role: "status-badge" }, { children: "Guest" }));
            }
        case 'ACCEPTED':
            switch (role) {
                case 'ADMIN':
                    return (jsx(StyledPill, Object.assign({ role: "status-badge", variant: "admin" }, { children: "Admin" })));
                case 'GUEST':
                    return jsx(GuestBadge, Object.assign({ role: "status-badge" }, { children: "Guest" }));
                case 'MEMBER':
                    return null;
            }
            return jsx(GuestBadge, Object.assign({ role: "status-badge" }, { children: "Guest" }));
        default:
            return null;
    }
};
const isInvitationRequested = (inviteStatus) => inviteStatus === 'REQUESTED' || inviteStatus === 'INVITED';

export { badgeForMemberRole, isInvitationRequested };

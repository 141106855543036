import React, { FC } from 'react'
import { useHistory } from 'react-router'

import { routes } from '@sketch/modules-common'

import { Dropdown } from '@sketch/components'
import UserNavbarSection from 'modules/user/components/UserNavbarSectionOld'

import {
  NoWorkspacesAvatar,
  NoWorkspacesDropdown,
  NoWorkspacesIcon,
  NoWorkspacesTitle,
  NoWorkspaceEmptyState,
  NoWorkspaceIcon,
  Title,
  Description,
} from './NoWorkspaceSidebar.styles'

import {
  SidebarWrapper,
  MenuWrapper,
  WorkspaceSelectorWrapper,
  FooterWrapper,
} from './Sidebar.styles'

import { useGetInitialUserQuery } from '@sketch/gql-types'

import BottomMenu from './components/BottomMenu/BottomMenu'

export const NoWorkspaceSidebar: FC = () => {
  const history = useHistory()
  const { data: userData } = useGetInitialUserQuery()

  const isPartner = userData?.me.isPartner

  return (
    <SidebarWrapper>
      <WorkspaceSelectorWrapper>
        <NoWorkspacesDropdown
          isPartner={isPartner}
          onClick={() => {
            if (!isPartner) {
              history.push(routes.WORKSPACE_CREATE.create({}))
            }
          }}
        >
          <NoWorkspacesAvatar />
          <NoWorkspacesTitle>No Workspaces</NoWorkspacesTitle>
          <NoWorkspacesIcon>
            <Dropdown.Chevrons />
          </NoWorkspacesIcon>
        </NoWorkspacesDropdown>
      </WorkspaceSelectorWrapper>
      <MenuWrapper>
        <NoWorkspaceEmptyState>
          <NoWorkspaceIcon width={24} height={24} />
          <Title>No Workspace Selected</Title>
          <Description>
            Create or pick an existing Workspace to view your projects and
            documents
          </Description>
        </NoWorkspaceEmptyState>
      </MenuWrapper>
      <FooterWrapper>
        <BottomMenu />
        <UserNavbarSection />
      </FooterWrapper>
    </SidebarWrapper>
  )
}

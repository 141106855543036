import React, { useState } from 'react'
import styled from 'styled-components'
import { Attribute, AttributeLabel, AttributeValue } from '../../Attribute'
import ColorDropdown from '../../ColorDropdown'
import { ColorVariablePopover } from './ColorVariablePopover'
import {
  ColorFormat,
  Color as ColorType,
  ColorVariable,
} from 'modules/shares/types'
import { Tooltip, Flex } from '@sketch/components'
import copy from 'copy-to-clipboard'
import { useHandleCopyValue } from '@sketch/modules-common'
import { rgbTo } from '../utils'
import { DirtyIconTooltip } from '../../Style/DirtyIconTooltip'
import { StyledSkeleton } from './ColorVariablePopover.styles'
import { useComponentsState } from 'modules/shares/components/ComponentsStateContext'
import { IS_SHOW_DIRTY_ATTRIBUTES_ENABLED } from '../../../constants'

const copyFormat = (format: ColorFormat, colorParams: ColorType) => {
  copy(rgbTo(format, colorParams))
}

interface ColorAttributeProps extends ColorVariable {
  label?: string
  style?: React.CSSProperties
  dirtyAttributes?: { originalValue: ColorType; originalProperty: string }
  onColorFormatChange: (f: ColorFormat) => void
  colorFormat: ColorFormat
}

export const ClickableArea = styled(AttributeLabel)<{ hideCursor?: boolean }>`
  cursor: ${({ hideCursor }) => (hideCursor ? 'default' : 'pointer')};
`

export const LabelWithWarning = styled(AttributeLabel)`
  display: flex;
`

const ColorVariableAttribute: React.FC<ColorAttributeProps> = ({
  red,
  green,
  blue,
  alpha = 1,
  colorVariableID,
  colorVariableName,
  colorVariableSourceLibraryID,
  colorVariableSourceLibraryName,
  colorVariableRemoteID,
  label = 'Color',
  onColorFormatChange,
  colorFormat,
  dirtyAttributes,
  ...props
}) => {
  // Overwrite value if dirty attributes are not enabled
  dirtyAttributes = IS_SHOW_DIRTY_ATTRIBUTES_ENABLED
    ? dirtyAttributes
    : undefined

  const componentsState = useComponentsState()
  const isIngesting = componentsState === 'PROCESSING'

  const [dropdownVisible, setDropdownVisible] = useState(false)

  const colorParams = { red, green, blue, alpha }
  const copyValue = `${rgbTo(colorFormat, colorParams)}`

  const {
    handleCopyValue,
    copyTooltipText,
    handleEnterCopiableArea,
    handleLeaveCopiableArea,
    copyTooltipVisible,
  } = useHandleCopyValue(copyValue)

  return (
    <Attribute
      noCopy
      showIcon={false}
      dropdownVisible={dropdownVisible}
      copyValue={copyValue}
      {...props}
    >
      <Tooltip
        style={{ flex: 1 }}
        placement="left"
        spacing="10px"
        visible={!isIngesting && copyTooltipVisible}
        content={copyTooltipText}
      >
        <ClickableArea
          onClick={handleCopyValue}
          onMouseEnter={handleEnterCopiableArea}
          onMouseLeave={handleLeaveCopiableArea}
          hideCursor={isIngesting}
        >
          <Flex alignItems="center">
            {/* the extra handleEnterCopiableArea is to make sure the copy tooltip is triggered after leaving the dirty icon tooltip */}
            <div
              aria-hidden
              onMouseEnter={
                dirtyAttributes ? handleEnterCopiableArea : undefined
              }
            >
              {label}
            </div>
            {dirtyAttributes && (
              <DirtyIconTooltip
                originalProperty={dirtyAttributes.originalProperty}
                originalValue={rgbTo(
                  colorFormat,
                  dirtyAttributes.originalValue
                )}
                preventLabelTooltip={handleLeaveCopiableArea}
              />
            )}
          </Flex>
        </ClickableArea>
      </Tooltip>
      <AttributeValue label={label} valueString={copyValue}>
        {isIngesting && colorVariableName ? (
          <StyledSkeleton />
        ) : (
          colorVariableName && (
            <ColorVariablePopover
              red={red}
              green={green}
              blue={blue}
              alpha={alpha}
              onColorFormatChange={onColorFormatChange}
              colorFormat={colorFormat}
              copyValue={copyValue}
              colorVariableID={colorVariableID}
              colorVariableName={colorVariableName}
              colorVariableSourceLibraryID={colorVariableSourceLibraryID}
              colorVariableSourceLibraryName={colorVariableSourceLibraryName}
              colorVariableRemoteID={colorVariableRemoteID}
              dropdown={
                <ColorDropdown
                  dropdownOpen={setDropdownVisible}
                  onChange={newFormat => {
                    copyFormat(newFormat, colorParams)
                    onColorFormatChange(newFormat)
                  }}
                />
              }
            />
          )
        )}
      </AttributeValue>
    </Attribute>
  )
}

export default ColorVariableAttribute

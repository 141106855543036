import { __awaiter } from 'tslib';
import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import '../LearnMoreTooltip/index.js';
import { EditInputWrapper, PreviewTitle, Form, EditInput, Title, Wrapper, TitleWrapper, TitleButtonWrapper, PencilButton, Pencil, Description, ActionsWrapper, HeaderSeparator } from './DocumentHeader.styles.js';
import { LearnMoreTooltip } from '../LearnMoreTooltip/LearnMoreTooltip.js';

const TitleInput = props => {
    const { value: externalValue, onEdit, placeholder, onCancel } = props;
    const [value, setValue] = useState(externalValue);
    const textAreaRef = useRef(null);
    useEffect(() => {
        var _a;
        (_a = textAreaRef.current) === null || _a === void 0 ? void 0 : _a.select();
    }, []);
    // Make the text area appear selected when opened and
    // mount the escape button hook
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onCancel();
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [onCancel]);
    const createSubmit = (form) => {
        form === null || form === void 0 ? void 0 : form.dispatchEvent(new Event('submit', { cancelable: true }));
    };
    return (jsxs(EditInputWrapper, { children: [jsx(PreviewTitle, { children: value }), jsx(Form, Object.assign({ onSubmit: e => {
                    e.preventDefault();
                    onEdit(value.trim());
                } }, { children: jsx(EditInput, { placeholder: placeholder, onChange: e => setValue(
                    // Replace double space from triggering a new line
                    e.target.value.replaceAll(/\s{2,}/g, ' ')), onBlur: e => {
                        createSubmit(e.currentTarget.form);
                    }, onKeyPress: e => {
                        // Make the "Enter" key submit the change instead of a new line
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            createSubmit(e.currentTarget.form);
                        }
                    }, ref: textAreaRef, value: value }) }))] }));
};
const DocumentHeaderBase = props => {
    const { actions, className, title, description, learnMoreTooltipTitle, learnMoreTooltipURL, onEdit, titlePlaceholder, forceEdit, onClearForceEdit, breadcrumb, separateBreadcrumb, } = props;
    const [isEditing, setEditing] = useState(forceEdit);
    const headerRef = useRef(null);
    let titleElement = jsx(Title, { children: title });
    // Set the "isEditing" true when the forceEdit is send
    useEffect(() => {
        if (forceEdit) {
            setEditing(true);
        }
    }, [forceEdit]);
    // Call "onClearForceEdit" when the edit is set to false
    useEffect(() => {
        if (isEditing && forceEdit) {
            return () => {
                onClearForceEdit === null || onClearForceEdit === void 0 ? void 0 : onClearForceEdit();
            };
        }
    }, [isEditing, forceEdit, onClearForceEdit]);
    if (isEditing && onEdit) {
        titleElement = (jsx(TitleInput, { value: title, placeholder: titlePlaceholder, onEdit: (newTitle) => __awaiter(void 0, void 0, void 0, function* () {
                if (title !== newTitle) {
                    yield onEdit(newTitle);
                }
                setEditing(false);
                // Make the button gain focus again after the change
                setTimeout(() => {
                    var _a;
                    (_a = headerRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                });
            }), onCancel: () => {
                setEditing(false);
            } }));
    }
    else if (onEdit) {
        titleElement = (jsx(Title, Object.assign({ tabIndex: 0, "aria-label": title, ref: headerRef }, { children: title })));
    }
    return (jsxs(Fragment, { children: [jsxs(Wrapper, Object.assign({ className: className, "aria-label": "Document Header" }, { children: [separateBreadcrumb && breadcrumb && jsx(Fragment, { children: breadcrumb }), jsxs(TitleWrapper, { children: [!separateBreadcrumb && breadcrumb && jsx(Fragment, { children: breadcrumb }), jsxs(TitleButtonWrapper, { children: [titleElement, onEdit && (jsxs(PencilButton, Object.assign({ onClick: () => setEditing(true), disabled: isEditing }, { children: [jsx("span", Object.assign({ className: "sr-only" }, { children: "Edit" })), jsx(Pencil, {})] })))] })] }), description && (jsxs(Description, { children: [description, learnMoreTooltipTitle && learnMoreTooltipURL && (jsx(LearnMoreTooltip, { tooltipContent: learnMoreTooltipTitle, href: learnMoreTooltipURL, tooltipPlacement: "top" }))] })), actions && jsx(ActionsWrapper, { children: actions })] })), jsx(HeaderSeparator, {})] }));
};
const DocumentHeader = styled(DocumentHeaderBase) ``;

export { DocumentHeader };

import styled from 'styled-components';

const CenterContent = styled.div `
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;

export { CenterContent };

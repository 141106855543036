import { jsx } from 'react/jsx-runtime';
import { PageLayout, LoadingState } from '@sketch/components';
import '../Footer/index.js';
import '../EmptyHeader/index.js';
import { EmptyHeader } from '../EmptyHeader/EmptyHeader.js';
import { Footer } from '../Footer/Footer.js';

const LoadingPage = () => (jsx(PageLayout, Object.assign({ header: jsx(EmptyHeader, { hideLogo: true }), footer: jsx(Footer, {}) }, { children: jsx(LoadingState, {}) })));

export { LoadingPage };

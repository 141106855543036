import React from 'react'
import { NavLinkProps } from 'react-router-dom'
import { dataIdFromObject } from '@sketch/graphql-cache'
import { BaseAnnotationFragment } from '@sketch/gql-types'
import { LoadPageSeparator, Text, handleFetchMore } from '@sketch/components'

import Annotation from '../Annotation'
import AnnotationListHeader from '../AnnotationListHeader'
import AnnotationListStatusFilter from '../AnnotationListStatusFilter'
import AnnotationInboxOptions from '../AnnotationInboxOptions'
import CommentTabMessageError from '../CommentTabMessageError'
import CommentTabMessageNoAnnotations from '../CommentTabMessageNoAnnotations'
import Link from '../Link'
import CommentSkeleton from '../CommentSkeleton'

import useGetAnnotations from '../../operations/useGetAnnotations'
import { isAnnotationNewOrHasNewComments } from '../../utils'

import {
  AnnotationInboxListWrapper,
  Separator,
  StyledButton,
} from './AnnotationInboxList.styles'

import { useAnnotationQueryVariables } from '../../context'
// eslint-disable-next-line no-restricted-imports
import { AnnotationStatusFilter } from '@sketch/gql-types/expansive'

const ENTRIES_PATH = ['annotations', 'entries']
const LOADING_PAGE_SIZE = 6
const PLACEHOLDER_COUNT = 3

const createUniqueLatestLabel = () => {
  let isLatestLabelRendered = false
  let hasAnnotationUnread = false

  return (annotation: BaseAnnotationFragment) => {
    const isUnreadNewAnnotation = isAnnotationNewOrHasNewComments(annotation)
    if (isUnreadNewAnnotation) {
      hasAnnotationUnread = true
    }

    if (
      !isLatestLabelRendered &&
      !isUnreadNewAnnotation &&
      hasAnnotationUnread
    ) {
      isLatestLabelRendered = true
      return true
    }

    return false
  }
}

interface PreventiveLinkProps extends OmitSafe<NavLinkProps, 'to'> {
  to?: NavLinkProps['to']
  $isResolved?: boolean
}

const PreventiveLink: React.FC<PreventiveLinkProps> = ({
  children,
  ...props
}) => {
  if (!props.to) {
    return <>{children}</>
  }

  return (
    <Link {...props} to={props.to}>
      {children}
    </Link>
  )
}

interface AnnotationInboxListProps {
  activeAnnotationIdentifier?: string
  resolveAnnotationLink?: (
    annotation: BaseAnnotationFragment
  ) => NavLinkProps['to'] | undefined
}

const AnnotationInboxList: React.FC<AnnotationInboxListProps> = props => {
  const { resolveAnnotationLink, activeAnnotationIdentifier } = props

  const variables = useAnnotationQueryVariables('sidebar')
  const { loading, data, error, fetchMore, refetch } = useGetAnnotations({
    variables,
  })

  const { annotationStatus } = variables

  if (loading) {
    return <CommentSkeleton count={PLACEHOLDER_COUNT} />
  }

  if (error || !data) {
    return (
      <CommentTabMessageError title="Unable to load comments">
        <Text textStyle="copy.quarternary.standard.D">
          There was an error loading comments.
        </Text>
        <StyledButton onClick={() => refetch()} variant="secondary" size="32">
          Try again
        </StyledButton>
      </CommentTabMessageError>
    )
  }

  const { entries, meta } = data.annotations

  if (entries.length === 0) {
    return (
      <CommentTabMessageNoAnnotations
        annotationStatus={annotationStatus as AnnotationStatusFilter}
        showAddCommentButton
      />
    )
  }

  const canRenderLatestLabel = createUniqueLatestLabel()

  return (
    <>
      {/* Render the annotations list */}
      {entries.map(annotation => (
        <React.Fragment key={annotation.identifier}>
          {/* Render the annotation list if it hasn't been rendered already */}
          {annotationStatus === 'ACTIVE_ONLY' &&
            canRenderLatestLabel(annotation) && (
              <Separator data-testid="annotation-viewed-separator">
                <span className="sr-only">Viewed</span>
              </Separator>
            )}

          {/* Render the annotation */}
          <PreventiveLink
            to={resolveAnnotationLink?.(annotation)}
            isActive={match =>
              !!match && annotation.identifier === activeAnnotationIdentifier
            }
          >
            <Annotation
              body={annotation.firstComment.body}
              user={annotation.firstComment.user}
              commentNumber={annotation.comments.meta.totalCount}
              createdAt={annotation.firstComment.createdAt}
              resolvedAt={annotation.resolution?.resolvedAt || undefined}
              hasNewComments={annotation.hasNewComments!}
              isNew={annotation.isNew!}
              optionsMenu={className => (
                <AnnotationInboxOptions
                  className={className}
                  annotationIdentifier={annotation.identifier}
                  notificationStatus={annotation.subscriptionStatus}
                  isResolved={!!annotation?.resolution}
                  userCanDeleteAnnotation={
                    annotation.firstComment.userCanDelete
                  }
                />
              )}
            />
          </PreventiveLink>
        </React.Fragment>
      ))}

      {meta.after && (
        <LoadPageSeparator
          key={meta.after}
          loadNewPage={handleFetchMore(fetchMore, ENTRIES_PATH, {
            dataIdFromObject,
            after: meta.after,
          })}
        />
      )}

      <CommentSkeleton
        count={Math.min(LOADING_PAGE_SIZE, meta.totalCount - entries.length)}
      />
    </>
  )
}

interface AnnotationInboxListContainerProps extends AnnotationInboxListProps {
  navbarAction?: React.ReactNode
}

const AnnotationInboxListContainer = (
  props: AnnotationInboxListContainerProps
) => {
  const { navbarAction, ...otherProps } = props
  const { annotationStatus } = useAnnotationQueryVariables('default')

  return (
    <AnnotationInboxListWrapper>
      <AnnotationListHeader action={props.navbarAction}>
        <AnnotationListStatusFilter
          annotationStatus={annotationStatus || undefined}
        />
      </AnnotationListHeader>
      <AnnotationInboxList {...otherProps} />
    </AnnotationInboxListWrapper>
  )
}

export default AnnotationInboxListContainer

import { useEffect } from 'react'
import { enableUpdates } from './updatesManager'
import { log } from '@sketch/utils'
import { useHistory } from 'react-router'
import { AugmentedHistory } from 'app-wide-types'

export const useForceAppRefresh = () => {
  // we don't really want to use `as AugmentedHistory` (particularly `as` part) here
  // but this is the only place within the whole app where we actually use AugmentedHistory type
  // and rewriting all `useHistory` hooks didn't seem worthy neither we wanted to expose augmented props
  // to other components.
  const history = useHistory() as AugmentedHistory

  useEffect(() => {
    enableUpdates({
      history,
      onForceRefreshed: () => {
        log('Sketch Cloud was updated to the latest version')
      },
    })
  })
}

import React, { useCallback, useState } from 'react'

import {
  DynamicLoadingPage,
  LaunchSketchAlert,
  NotFoundView,
  routes,
  useFlag,
  useUserSignedIn,
} from '@sketch/modules-common'

import { getEligibleWorkspacesForDuplication } from 'modules/workspace/utils/getEligibleWorkspacesForDuplication'

import { useDuplicateShareToDrafts } from 'modules/shares/hooks/useDuplicateShareToDrafts'
import { useCheckCurrentAuthorizationForWorkspace } from 'modules/sso/operations/useCheckCurrentAuthorizationForWorkspace'

import { ReactComponent as Calendar } from '@sketch/icons/calendar-16'
import { ReactComponent as Library } from '@sketch/icons/link-library-16'
import { ReactComponent as Library24 } from '@sketch/icons/link-library-24'
import { ReactComponent as Sketch } from '@sketch/icons/sketch-logo-24'
import { ReactComponent as Download } from '@sketch/icons/down-arrow-circle-16'

import ReportShareModal from 'modules/shares/components/ReportShareModal'

import {
  TimeAgo,
  Button,
  useModalContext,
  useForTablet,
  DownloadFileAnchor,
} from '@sketch/components'

import { ProfileAvatar } from '../../components/ProfileAvatar'
import MoreFromThisCreator from '../../components/MoreFromThisCreator'
import PublicationCanvas from '../../components/PublicationCanvas/PublicationCanvas'
import HeartButton from '../../components/HeartButton/HeartButton'
import CommunityHeader from '../../components/CommunityHeader'
import PublicViewHiddenProfileBanner from '../../components/PublicViewHiddenProfileBanner'

import {
  Wrapper,
  Content,
  Title,
  Description,
  MaxWidth,
  Detail,
  Icon,
  StyledButton,
  TitleContainer,
  DetailsContainer,
  ActionsWrapper,
  NoImageThumbnail,
  NoImageIcon,
} from './WorkspaceProfileDocument.styles'

import {
  InitialUserFragment,
  PublicationFragment,
  PublicationPageFragment,
  useGetSharePagesQuery,
  useGetWorkspacesForDuplicationQuery,
} from '@sketch/gql-types'
import { DownloadProps, getDownloadProps } from 'modules/shares/utils'

interface WorkspaceProfileDocumentProps {
  publication: PublicationFragment
  user?: Pick<InitialUserFragment, 'name' | 'avatar'>
  shortUrlName: string
}

type SelectedPage = PublicationPageFragment | null

interface ActionsContainerProps {
  publication: PublicationFragment
  workspaceIdentifier: string
  versionIdentifier: string
  hasNoWorkspacesAvailable: boolean
  downloadLink?: DownloadProps
}

const checkIsShareLibrary = (share: PublicationFragment['share']) =>
  share.type === 'LIBRARY'

const ActionsContainer: React.FC<ActionsContainerProps> = ({
  workspaceIdentifier,
  versionIdentifier,
  hasNoWorkspacesAvailable,
  publication,
  downloadLink,
}) => {
  const { share } = publication
  const { showModal } = useModalContext()
  const isTabletAndBigger = useForTablet()

  const [duplicateShare, { loading }] = useDuplicateShareToDrafts({
    share,
    versionIdentifier,
    workspaceIdentifier,
  })

  const isPublicationHeartsEnabled = useFlag('publication-hearts')
  const showHeartButton = isPublicationHeartsEnabled

  const isMobile = !isTabletAndBigger
  const isAbleToDuplicate = !hasNoWorkspacesAvailable && share.userCanInspect
  const isLibrary = checkIsShareLibrary(share)

  return (
    <ActionsWrapper data-testid="publication-actions">
      {showHeartButton && (
        <HeartButton publication={publication} trackHeartCount />
      )}
      {isLibrary && !isMobile && (
        <Button
          variant="tertiary"
          icon={() => <Library24 width="24" height="24" />}
          onClick={() =>
            showModal(LaunchSketchAlert, {
              shareId: share.identifier,
              action: 'addLibrary',
            })
          }
          size="40"
        >
          Add Library
        </Button>
      )}
      {downloadLink?.isAvailable && (
        <Button
          to="#"
          variant="primary-untinted"
          href={downloadLink.url}
          as={DownloadFileAnchor}
          icon={() => <Download width="24" height="24" />}
        >
          Download
        </Button>
      )}
      {isAbleToDuplicate && (
        <Button
          variant="primary-untinted"
          icon={() => <Sketch width="24" height="24" />}
          onClick={duplicateShare}
          size="40"
          loading={loading}
        >
          Remix
        </Button>
      )}
    </ActionsWrapper>
  )
}

const WorkspaceProfileDocument: React.FC<WorkspaceProfileDocumentProps> = props => {
  const { user, publication, shortUrlName } = props

  const [selectedPage, setSelectedPage] = useState<SelectedPage>(null)

  const { showModal } = useModalContext()
  const isUserSignedIn = useUserSignedIn()
  const { checkAuthorization } = useCheckCurrentAuthorizationForWorkspace()

  const share = publication.share
  const { name, description, identifier, version } = share
  const workspace = share.workspace
  const profile = workspace.profile
  const isAdmin = workspace.userRole === 'ADMIN'
  const isProfilePublic = profile?.isPublic === true
  const isAvailableToAnonymousUsers = !(!isAdmin && !isProfilePublic)
  const showHiddenProfileBanner = isAdmin && !isProfilePublic
  const downloadLink = !isUserSignedIn ? getDownloadProps(version) : undefined

  // Loading and Error is dealt with inside PublicationPagesDropdown
  useGetSharePagesQuery({
    variables: {
      identifier: share.identifier,
    },
  })

  // Get workspaces that the user can duplicate to
  const {
    data,
    loading: isLoadingWorkspaces,
  } = useGetWorkspacesForDuplicationQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isUserSignedIn,
  })

  const workspaces = data?.me.workspaces ?? []

  // Check user eligibility to duplicate a share
  const {
    currentWorkspace,
    otherWorkspaces,
  } = getEligibleWorkspacesForDuplication(
    workspaces,
    workspace.identifier,
    share.publicAccessLevel,
    { hasAccessToWorkspaceCheckFn: checkAuthorization }
  )

  const handleClick = () => {
    showModal(ReportShareModal, {
      identifier,
    })
  }

  const handlePageSelected = useCallback((page: PublicationPageFragment) => {
    setSelectedPage(page)
  }, [])

  if (isLoadingWorkspaces) {
    return <DynamicLoadingPage />
  }

  // Profile and Share should always be available
  if (!profile || !share || !version || !isAvailableToAnonymousUsers) {
    return <NotFoundView />
  }

  // Grab first and only page from entries array
  const [firstPage] = version?.document?.pages?.entries ?? []

  const updatedAt = publication.updatedAt
  const page = selectedPage ?? firstPage
  const hasPresentationFile = !!page?.presentationFile

  const hasNoWorkspacesAvailable = !otherWorkspaces.length && !currentWorkspace

  return (
    <>
      {showHiddenProfileBanner && (
        <PublicViewHiddenProfileBanner workspaceId={workspace.identifier} />
      )}

      <CommunityHeader user={user} />

      <Wrapper>
        <MaxWidth $width={1280}>
          <ProfileAvatar
            workspaceName={workspace.name}
            avatar={workspace.avatar}
            to={routes.WORKSPACE_PROFILE.create({
              shortUrlName,
            })}
            isSmall
          />
          <TitleContainer>
            <Title>{name}</Title>
            <ActionsContainer
              publication={publication}
              workspaceIdentifier={workspace.identifier}
              versionIdentifier={version.identifier}
              hasNoWorkspacesAvailable={hasNoWorkspacesAvailable}
              downloadLink={downloadLink}
            />
          </TitleContainer>
          {hasPresentationFile ? (
            <PublicationCanvas
              versionShortId={version.shortId}
              shareIdentifier={share.identifier}
              page={page}
              onPageSelected={handlePageSelected}
            />
          ) : (
            <NoImageThumbnail>
              <NoImageIcon aria-label="No Image available" />
            </NoImageThumbnail>
          )}
          <MaxWidth $width={840}>
            <Content>
              {!!description && <Description>{description}</Description>}
              <DetailsContainer>
                <div>
                  <Detail>
                    <Icon as={Calendar} /> <TimeAgo date={updatedAt} />
                  </Detail>
                  {checkIsShareLibrary(share) && (
                    <Detail>
                      <Icon as={Library} /> Library
                    </Detail>
                  )}
                </div>
                <StyledButton onClick={handleClick}>Report</StyledButton>
              </DetailsContainer>
            </Content>
            <MoreFromThisCreator
              workspaceIdentifier={workspace.identifier}
              exclude={publication.identifier}
            />
          </MaxWidth>
        </MaxWidth>
      </Wrapper>
    </>
  )
}

export default WorkspaceProfileDocument

import styled, { css } from 'styled-components';
import { Text, FakeLinkButton, Button, LinkButton } from '@sketch/components';

const Title = styled.h1 `
  margin: 24px 0px 8px 0px;
  font-size: ${({ theme }) => theme.fontSizes.I};
  text-align: center;
`;
const FormContainer = styled.div `
  margin-top: 24px;
`;
const Note = styled(Text.Div).attrs({
    textStyle: 'copy.tertiary.standard.C',
}) `
  margin: 24px 0 0 0;
`;
const linkCss = css `
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};

  :not(:last-child) {
    margin-bottom: 8px;
  }
`;
const StyledFakeLinkButton = styled(FakeLinkButton) `
  ${linkCss}
`;
const Description = styled(Text).attrs({
    textStyle: 'copy.primary.standard.F',
}) `
  margin: 0;
  padding: 0;
  text-align: center;
`;
const Paragraph = styled.p `
  display: block;

  && {
    padding-bottom: 8px;
  }

  &:last-child {
    && {
      padding-bottom: 0;
    }
  }
`;
const StyledButton = styled(Button) `
  flex: 1;
`;
const StyledLinkButton = styled(LinkButton) `
  flex: 1;
`;
const RecoveryCodeToggle = styled(Text.Span).attrs({
    textStyle: 'copy.tertiary.standard.C',
}) `
  color: ${({ theme }) => theme.colors.sketch.A};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  text-decoration: underline;
  cursor: pointer;
  transition: 100ms color linear;

  &:hover {
    color: ${({ theme }) => theme.colors.sketch.C};
  }
`;

export { Description, FormContainer, Note, Paragraph, RecoveryCodeToggle, StyledButton, StyledFakeLinkButton, StyledLinkButton, Title, linkCss };

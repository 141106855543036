import { __rest } from 'tslib';
import { useParams } from 'react-router';
import { useCallback } from 'react';
import { useGetWorkspaceQuery } from '@sketch/gql-types';
import '../index.js';
import { useAnalytics } from '../analytics.context.js';

const getUserRole = (workspace) => {
    if (workspace.userRole === 'ADMIN')
        return 'ADMIN';
    return workspace.userCanEdit ? 'EDITOR' : 'VIEWER';
};
const composeEventValue = (target, type, props) => (props === null || props === void 0 ? void 0 : props.section)
    ? `${type} - ${props.section}: ${target}`
    : `${type} - ${target}`;
const useTrackEvent = () => {
    const { workspaceId } = useParams();
    const analytics = useAnalytics();
    const { data } = useGetWorkspaceQuery({
        variables: { identifier: workspaceId },
        fetchPolicy: 'cache-only',
        skip: !workspaceId,
    });
    const currentWorkspace = data === null || data === void 0 ? void 0 : data.workspace;
    const memoizedTrackEvent = useCallback((eventName, eventProps) => {
        if (eventProps && eventProps.target && eventProps.type) {
            const { target, type } = eventProps, newEventProps = __rest(eventProps, ["target", "type"]);
            analytics.trackEvent(eventName, Object.assign(Object.assign({}, newEventProps), { type: composeEventValue(target, type, newEventProps), role: currentWorkspace
                    ? composeEventValue(target, getUserRole(currentWorkspace), newEventProps)
                    : 'Unknown' }));
            return;
        }
        analytics.trackEvent(eventName);
    }, [analytics, currentWorkspace]);
    return { trackEvent: memoizedTrackEvent };
};

export { useTrackEvent };

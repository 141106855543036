import { __awaiter } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { useState, useEffect, useCallback, useRef } from 'react';
import '../ResponsiveDropdown/index.js';
import { useResponsiveDropdown } from '../ResponsiveDropdown/hooks/useResponsiveDropdown.js';

const OFFSET = [0, 0];
const handleClick = (event) => {
    event.stopPropagation();
};
function useContextMenu(containerRef, props) {
    const { contextMenu: ContextMenu, contextMenuProps, onContextMenuToggle, onContextMenuState, } = props;
    const [position, setPosition] = useState(null);
    const [additionalState, setAdditionalState] = useState(null);
    const [content, { ref }, { setVisible, visible, update },] = useResponsiveDropdown({
        dropdown: ContextMenu,
        dropdownProps: Object.assign(Object.assign({}, contextMenuProps), { positionRelativeToContainer: position, additionalState }),
        dropdownBreakpoint: 'base',
        placement: 'bottom-start',
        offset: OFFSET,
    });
    /**
     * Update the dropdown when the position changes
     */
    useEffect(() => {
        update === null || update === void 0 ? void 0 : update();
    }, [position, update]);
    /**
     * Prevent the popup click to propagate down
     */
    useEffect(() => {
        if (!ref || typeof ref !== 'object' || !ref.current || !visible) {
            return;
        }
        const wrapperRef = ref.current;
        wrapperRef.addEventListener('mousedown', handleClick);
        return () => {
            wrapperRef.removeEventListener('mousedown', handleClick);
        };
    }, [position, ref, visible]);
    /**
     * Handler for the button
     */
    const handleContextMenu = useCallback((e) => __awaiter(this, void 0, void 0, function* () {
        e.preventDefault();
        const container = containerRef.current;
        if (!container) {
            return;
        }
        const containerRect = container.getBoundingClientRect();
        const relativeX = e.pageX - containerRect.left;
        const relativeY = e.pageY - containerRect.top;
        const position = { x: relativeX, y: relativeY };
        const additionalState = (yield (onContextMenuState === null || onContextMenuState === void 0 ? void 0 : onContextMenuState(position))) || null;
        setAdditionalState(additionalState);
        setPosition(position);
        setVisible(true);
    }), [containerRef, setVisible, onContextMenuState]);
    const cachedContextMenuToggle = useRef(onContextMenuToggle);
    useEffect(() => {
        cachedContextMenuToggle.current = onContextMenuToggle;
    }, [onContextMenuToggle]);
    useEffect(() => {
        var _a;
        (_a = cachedContextMenuToggle.current) === null || _a === void 0 ? void 0 : _a.call(cachedContextMenuToggle, visible);
    }, [visible]);
    const renderedContextMenu = position ? (jsx("div", Object.assign({ ref: ref, style: { position: 'absolute', top: position.y, left: position.x } }, { children: content }))) : null;
    return [{ handleContextMenu }, renderedContextMenu];
}

export { useContextMenu };

import { jsx } from 'react/jsx-runtime';
import { useReducer, useCallback } from 'react';
import { ToastContext } from './ToastContext.js';
import { uniqueId } from '@sketch/utils';

const reducer = (currentState, action) => {
    switch (action.type) {
        case 'ADD_TOAST': {
            const { id, variant, autoDismiss, message } = action.payload;
            return [{ id, variant, autoDismiss, message }, ...currentState];
        }
        case 'DISMISS_TOAST': {
            return currentState.filter(({ id }) => id !== action.payload.id);
        }
    }
    return currentState;
};
const ToastProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, []);
    const showToast = useCallback((message, variant = 'positive', autoDismiss = true) => {
        const id = uniqueId('toast');
        dispatch({
            type: 'ADD_TOAST',
            payload: { id, message, autoDismiss, variant },
        });
        return id;
    }, []);
    const dismissToast = useCallback((id) => {
        dispatch({ type: 'DISMISS_TOAST', payload: { id } });
    }, []);
    return (jsx(ToastContext.Provider, Object.assign({ value: {
            showToast,
            dismissToast,
            /*
            This should be removed from here, we shouldn't expose internal state.
    
            Currently we do that because the ToastsProvider is a different component from the Toasts
            main visual component.
            */
            toasts: state,
        } }, { children: children })));
};

export { ToastProvider };

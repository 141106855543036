import { mapSsoStartUrl, sourceToSsoSignInSource } from '@sketch/modules-common';

const goToLinkAccountUrl = ({ url, linkedAccountToken, source, from, }) => {
    window.location.href = mapSsoStartUrl(url, {
        source: sourceToSsoSignInSource[source !== null && source !== void 0 ? source : 'cloud'],
        from,
        /**
         * The create flag tells the SSO service that the user is ready to finish
         * the SSO flow once they either accepted the ToS or linked their account
         * with a personal one.
         */
        create: '1',
        /**
         * The linked account token if the user wants to link their personal
         * account. An empty string otherwise.
         */
        link: linkedAccountToken !== null && linkedAccountToken !== void 0 ? linkedAccountToken : '',
    });
};

export { goToLinkAccountUrl };

// For some strange reason the paragraph gets active when a list is also
// selected, that's why we need these extra conditions
const isParagraphActive = (editor) => editor.isActive('paragraph') &&
    !(editor.isActive('bulletList') || editor.isActive('orderedList'))
    ? 'is-active'
    : '';
const isTitleActive = (editor) => editor.isActive('heading', { level: 1 }) ? 'is-active' : '';
const isHeadingActive = (editor) => editor.isActive('heading', { level: 2 }) ? 'is-active' : '';
const isSubheadingActive = (editor) => editor.isActive('heading', { level: 3 }) ? 'is-active' : '';
const isBulletListActive = (editor) => editor.isActive('bulletList') ? 'is-active' : '';
const isNumberedListActive = (editor) => editor.isActive('orderedList') ? 'is-active' : '';
const isBoldActive = (editor) => editor.isActive('bold') ? 'is-active' : '';
const isItalicsActive = (editor) => editor.isActive('italic') ? 'is-active' : '';
const isUnderlineActive = (editor) => editor.isActive('underline') ? 'is-active' : '';
const isLinkActive = (editor) => editor.isActive('link') ? 'is-active' : '';

export { isBoldActive, isBulletListActive, isHeadingActive, isItalicsActive, isLinkActive, isNumberedListActive, isParagraphActive, isSubheadingActive, isTitleActive, isUnderlineActive };

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import '../Box/index.js';
import { Text } from '../Box/BoxSystem.js';

const pluralize = (singular, plural, count) => {
    if (count === 0 || count > 1)
        return plural !== null && plural !== void 0 ? plural : `${singular}s`;
    return singular;
};
const Pluralize = (_a) => {
    var { singular, plural, count, showCount, wrapperElement: WrapperElement = Text } = _a, props = __rest(_a, ["singular", "plural", "count", "showCount", "wrapperElement"]);
    return (jsx(WrapperElement, Object.assign({}, props, { children: `${showCount ? count : ''} ${pluralize(singular, plural, count)}`.trim() })));
};

export { Pluralize, pluralize };

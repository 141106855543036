import React, { useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'

import useGetAnnotationDots from '../../operations/useGetAnnotationDots'
import useMoveAnnotation from '../../operations/useMoveAnnotation'

import AnnotationOverlayDot from '../AnnotationOverlayDot'
import AnnotationOverlayActiveDot from '../AnnotationOverlayActiveDot'
import DraftAnnotation from '../DraftAnnotation'
import LegacyAnnotationDot from '../LegacyAnnotationDot'

import { useGetAnnotationContext, useAnnotationShortcuts } from '../../hooks'

import {
  GetArtboardBoundsSelector,
  GetPrefixCoordinatesSelector,
  ArtboardContainerBounds,
  ReferenceBounds,
} from '../../types'
import { DEFAULT_COORDINATES } from '../../constants'
import { AnnotationDotFragment } from '@sketch/gql-types'
import { useStableHandler } from '@sketch/utils'
import {
  getAnnotationDotFlavour,
  getCleanAnnotationCoordinates,
} from '../../utils'
import {
  useAnnotationQueryVariables,
  formatOverlayVariables,
} from '../../context'

interface AnnotationOverLayProps {
  referenceBounds: ReferenceBounds
  parentWrapper: React.RefObject<HTMLElement>
  getPrefixCoordinates?: GetPrefixCoordinatesSelector
  getArtboardBounds?: GetArtboardBoundsSelector
  getArtboardContainerBounds?: () => ArtboardContainerBounds | undefined
  onActiveAnnotation?: (annotation: AnnotationDotFragment) => void
  shouldRenderAnnotation?: (
    annotation: AnnotationDotFragment,
    isActiveAnnotation: boolean
  ) => boolean
}

const AnnotationOverLay = (props: AnnotationOverLayProps) => {
  const {
    referenceBounds,
    getPrefixCoordinates,
    getArtboardBounds,
    getArtboardContainerBounds,
    parentWrapper,
    onActiveAnnotation,
    shouldRenderAnnotation = () => true,
  } = props

  const {
    status,
    placeDraftAnnotation,
    draftAnnotation,
    setPlaceDraftAnnotation,
    activeAnnotation: activeAnnotationIdentifier,
    setActiveAnnotation,
    getDotAdditionalStyle,
    hidden,
  } = useGetAnnotationContext()

  const isAnnotationContextAvailable = status === 'available'

  const [newAnnotationIdentifier, setNewAnnotation] = useState<string | null>(
    null
  )

  const variables = useAnnotationQueryVariables('default')
  const moveAnnotation = useMoveAnnotation(variables, parentWrapper)

  const disableLoading = !isAnnotationContextAvailable || !!hidden

  const { loading, annotations, legacyAnnotations } = useGetAnnotationDots(
    formatOverlayVariables(variables),
    disableLoading
  )

  useAnnotationShortcuts({ disabled: loading })

  const selectedAnnotationIdentifier =
    newAnnotationIdentifier || activeAnnotationIdentifier

  const annotationDots = annotations.map(annotation => {
    const prefixBounds = getPrefixCoordinates?.(annotation)
    const artboardBounds = getArtboardBounds?.(annotation)

    /**
     * Since the active dots are represented by independently
     * we need to filter from rendering if it's selected or new
     *
     * We filter the new because there could be a moment where
     * the url is not updated yet, preventing double dots
     */
    const isSelected = activeAnnotationIdentifier === annotation.identifier
    const isNew = newAnnotationIdentifier === annotation.identifier
    const annotationNotVisible = !shouldRenderAnnotation?.(
      annotation,
      isSelected
    )

    if (isSelected || isNew || annotationNotVisible) {
      return null
    }

    const additionalDotStyle = getDotAdditionalStyle?.(annotation)

    return (
      <AnnotationOverlayDot
        key={annotation.identifier}
        annotationIdentifier={annotation.identifier}
        flavour={getAnnotationDotFlavour(annotation)}
        referenceBounds={referenceBounds}
        coordinates={getCleanAnnotationCoordinates(annotation?.coordinates)}
        prefixBounds={prefixBounds}
        artboardBounds={artboardBounds}
        disabled={placeDraftAnnotation}
        getArtboardContainerBounds={getArtboardContainerBounds}
        onMoveDrop={moveAnnotation}
        additionalStyle={additionalDotStyle}
      />
    )
  })

  /**
   * This effect cleans up the new annotation so
   * we can relay only on the selected one.
   *
   * We also need to make sure that the new annotation
   * is already on the API payload (selectedAnnotation)
   * before cleaning otherwise we might have issues representing
   * the popover.
   */
  useEffect(() => {
    const isSelectedNewAnnotation =
      activeAnnotationIdentifier === newAnnotationIdentifier

    isSelectedNewAnnotation && setNewAnnotation(null)
  }, [newAnnotationIdentifier, activeAnnotationIdentifier])

  const handleSubmitDraftAnnotation = useStableHandler(
    (annotation: AnnotationDotFragment) => {
      const isAlreadySaved = newAnnotationIdentifier === annotation.identifier

      // Prevent additional re-renders
      if (isAlreadySaved) {
        return
      }

      setNewAnnotation(annotation.identifier)
      setPlaceDraftAnnotation?.(false)

      if (!annotation.isOptimistic) {
        /**
         * Now that we have the final annotation
         * we should update the URL so to follow the
         * same flow as an normal annotation
         */
        setActiveAnnotation?.(annotation.identifier)
      }
    }
  )

  const handleHideActiveDotOverlay = useStableHandler(() =>
    setActiveAnnotation?.()
  )

  if (loading || !isAnnotationContextAvailable) {
    return null
  }

  return (
    <>
      {selectedAnnotationIdentifier && (
        <AnnotationOverlayActiveDot
          annotationIdentifier={selectedAnnotationIdentifier}
          referenceBounds={referenceBounds}
          getPrefixCoordinates={getPrefixCoordinates}
          getArtboardBounds={getArtboardBounds}
          getArtboardContainerBounds={getArtboardContainerBounds}
          parentWrapper={parentWrapper}
          onHide={handleHideActiveDotOverlay}
          onMoveDrop={moveAnnotation}
          onActiveAnnotation={onActiveAnnotation}
          getDotAdditionalStyle={getDotAdditionalStyle}
        />
      )}

      {draftAnnotation && (
        <DraftAnnotation
          draftAnnotation={draftAnnotation}
          referenceBounds={referenceBounds}
          parentWrapper={parentWrapper}
          onSubmit={handleSubmitDraftAnnotation}
        />
      )}

      {legacyAnnotations.map(([uuid, { count, annotation }]) => (
        <LegacyAnnotationDot
          key={uuid}
          coordinates={DEFAULT_COORDINATES}
          referenceBounds={referenceBounds}
          count={count}
          prefixBounds={getPrefixCoordinates?.(annotation)}
          disabled={placeDraftAnnotation}
        />
      ))}

      {annotationDots}
    </>
  )
}

export default React.memo(AnnotationOverLay, isEqual)

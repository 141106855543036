import styled from 'styled-components';
import { Text } from '@sketch/components';

const UserChipContainer = styled.div `
  display: flex;
  flex-direction: column;
  margin: 24px 0 16px;
`;
const Label = styled(Text.Span).attrs({
    textStyle: 'copy.tertiary.standard.E',
}) `
  margin-bottom: 6px; /* stylelint-disable-line scales/space */
`;

export { Label, UserChipContainer };

import { indexLayoutSidebar, leftWidth, rightWidth, canvasFirstRightWidth } from '@sketch/constants/sidebarSizes';

const sidebar = {
    indexLayoutSidebar,
    leftWidth,
    rightWidth,
    canvasFirstRightWidth,
};

export { sidebar };

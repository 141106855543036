import styled from 'styled-components';
import '../NavbarExtended/index.js';
import { NavbarExtended } from '../NavbarExtended/Navbar.js';

const SidebarWrapper = styled.section `
  display: flex;
  flex-direction: column;

  /* Limit the components width, preventing items with big name to have scroll */
  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.colors.background.secondary.B};
`;
const LoadingWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
const LoadingSidebarWorkspace = styled(NavbarExtended) `
  background: none;
`;

export { LoadingSidebarWorkspace, LoadingWrapper, SidebarWrapper };

import { __awaiter } from 'tslib';
import produce from 'immer';
import { get, set } from './personalTokensCache.js';
import { usePersonalTokenDeleteMutation } from '@sketch/gql-types';

const useDeletePersonalToken = (props) => {
    const { onCompleted } = props;
    const [mutation] = usePersonalTokenDeleteMutation({
        onError: 'show-toast',
        onCompleted: data => onCompleted(data.personalTokenDelete),
    });
    return (variables) => __awaiter(void 0, void 0, void 0, function* () {
        return yield mutation({
            variables,
            update: (cache, { data, errors }) => {
                if (!data || (errors && errors.length > 0))
                    return;
                const me = get(cache);
                if (!me)
                    return;
                const identifierToDelete = data.personalTokenDelete.identifier;
                const updatedMe = produce(me, user => {
                    user.personalTokens = user.personalTokens.filter(token => token.identifier !== identifierToDelete);
                });
                set(cache, updatedMe);
            },
        });
    });
};

export { useDeletePersonalToken };

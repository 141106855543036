import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { positionRight } from '@reach/popover';
import { useInView } from 'react-intersection-observer';
import '../Select/index.js';
import { TriggerButton, CommentLabel, Popover, Header } from './AccessLevelSelect.styles.js';
import { ListboxInput, ListboxList } from '@reach/listbox';
import { SelectTriggerUnstyled } from '../Select/Select.styles.js';

// We will rely on the typing from the parent components, that will
// check that the level and setLevel are one of the allowed levels
const AccessLevelSelect = ({ label, level, setLevel, disabled, commentsEnabled, hideCommentLabel, position, triggerButton, children, noAccessRedColor = true, title = 'Access', }) => {
    // Hide the dropdown when the same is not in view
    const { ref, inView } = useInView({
        threshold: 0.25,
    });
    // Usage of type "unknown" is to prevent TS2367 error
    const unknownLevel = level;
    const displayCommentingOffLabel = unknownLevel !== 'NONE' && !commentsEnabled && !hideCommentLabel;
    return (jsxs(ListboxInput
    // To solve https://github.com/sketch-hq/Cloud/issues/8122 we are using
    // intersection observer to check when the list item is out of view.
    // Since reach-ui ListboxPopover doesn't have a method to close the popover that we can use,
    // we are setting a "key" prop to the parent of the popover to force it to rerender.
    // This is a hack that forces the popover to close and stay closed when scrolling the members list.
    , Object.assign({ "data-testid": `isInView-${!!inView}`, value: unknownLevel, disabled: disabled, onChange: value => {
            if (value === unknownLevel) {
                // If the value is the same there shouldn't happen any action
                return;
            }
            setLevel(value);
        } }, { children: [jsx(SelectTriggerUnstyled, Object.assign({ "aria-disabled": disabled }, { children: triggerButton || (jsx(TriggerButton, { disabled: disabled, "data-testid": "access-level-dropdown-toggle", ref: ref, label: jsxs(Fragment, { children: [label, displayCommentingOffLabel && (jsx(CommentLabel, { children: "Commenting off" }))] }), "$colorRed": unknownLevel === 'NONE' && noAccessRedColor })) })), jsx(Popover, Object.assign({ position: position || positionRight, showModalInMobile: true }, { children: jsxs(ListboxList, Object.assign({ "data-testid": "AccessLevelSelect" }, { children: [jsx(Header, { children: title }), children] })) }))] }), `isInView-${!!inView}`));
};

export { AccessLevelSelect };

import React, { FC } from 'react'
import { RouteComponentProps, Route, Switch } from 'react-router-dom'

import {
  isTrashRoute,
  isDraftsRoute,
  isLibrariesRoute,
  isProjectRoute,
  NotFoundView,
  RouteParams,
  routes,
} from '@sketch/modules-common'
import { useLayoutPortal } from '@sketch/utils'
import { useForTablet, BannersDisplay, LoadingState } from '@sketch/components'
import { useEducationWorkspaceApproved } from 'modules/workspace/modals/EducationWorkspaceApprovedModal'

import { getSectionTitle, getProjectTitle } from './utils'

import WorkspaceSharesList from 'modules/shares/components/WorkspaceSharesList'
import TrashList from 'modules/shares/components/TrashList'
import { ArchiveList } from 'modules/shares/components/ArchiveList'
import ShareNavbar from 'modules/shares/components/ShareNavbar'
import ProjectsView from 'modules/projects/views/ProjectsView'
import ProjectsAndCollectionsView, {
  ProjectsAndCollectionsViewRouteProps,
} from 'modules/projects/views/ProjectsAndCollectionsView'
import CollectionsView from 'modules/collections/views/CollectionView'
import {
  BillingStatusBanners,
  WelcomeModalTrigger,
  PersonalWorkspaceBanners,
} from 'modules/workspace/components'
import { getCanAdministerShares } from 'modules/shares/utils'
import { SearchBar } from 'modules/shares/components/SearchBar'
import { useApolloClient } from '@apollo/react-hooks'

import {
  useGetProjectsQuery,
  useGetWorkspaceSharesCountQuery,
  useGetDocumentListSettingsQuery,
  useGetWorkspaceBillingQuery,
} from '@sketch/gql-types'
import { WorkspaceSidebarLayoutExtraProps } from 'modules/workspace/components/WorkspaceSidebarLayout/WorkspaceSidebarLayout'

import {
  NavbarTitle,
  SearchBarWrapper,
  BannerWrapper,
} from './WorkspaceDocumentsView.styles'
import { FirstStepsBanner } from '../../components/FirstStepsBanner'
import { WorkspaceSharesListHeader } from './WorkspaceSharesListHeader'

/**
 * TYPES
 */
export type WorkspaceDocumentsViewRoutesProps = RouteComponentProps<
  | RouteParams<'WORKSPACE_PROJECT'>
  | RouteParams<'WORKSPACE_SHARES'>
  | RouteParams<'WORKSPACE_TRASH'>
  | RouteParams<'WORKSPACE_ARCHIVE'>
>

export type WorkspaceDocumentsViewProps = WorkspaceDocumentsViewRoutesProps &
  WorkspaceSidebarLayoutExtraProps

type ProjectsViewProps = RouteComponentProps<
  Partial<RouteParams<'WORKSPACE_PROJECT'> | RouteParams<'WORKSPACE_DRAFTS'>>
>

type CollectionsViewProps = RouteComponentProps<
  Partial<RouteParams<'WORKSPACE_COLLECTION'>>
>

type WorkspaceArchiveRouteParams = RouteComponentProps<
  Partial<RouteParams<'WORKSPACE_ARCHIVE'>>
>

type WorkspaceTrashRouteParams = RouteComponentProps<
  Partial<RouteParams<'WORKSPACE_TRASH'>>
>

type WorkspaceSharesRouteParams = RouteComponentProps<
  Partial<RouteParams<'WORKSPACE_SHARES'>>
>

type WorkspaceLibrariesRouteParams = RouteComponentProps<
  Partial<RouteParams<'WORKSPACE_LIBRARIES'>>
>

type WorkspaceSharedWithMeRouteParams = RouteComponentProps<
  Partial<RouteParams<'WORKSPACE_SHARED_WITH_ME'>>
>

/**
 * COMPONENT
 */
const WorkspaceDocumentsView: FC<WorkspaceDocumentsViewProps> = ({
  location,
  history,
  match: { params, path },
  workspace,
  useOverrideLayoutProps,
}) => {
  const { workspaceId } = params
  const isTabletAndBigger = useForTablet()
  const isSmallerThanTablet = !isTabletAndBigger

  const NavbarPortal = useLayoutPortal('navbar-portal')
  const SubheaderPortal = useLayoutPortal('subheader-portal')

  const isWorkspaceLibrariesRoute = isLibrariesRoute(path)

  const { data, loading } = useGetProjectsQuery({
    variables: {
      workspaceId,
    },
  })

  const { data: settings } = useGetDocumentListSettingsQuery()
  const { showDocumentsInProjects, showArchivedDocuments } = settings || {}

  const workspaceSharesCount = useGetWorkspaceSharesCountQuery({
    variables: { identifier: workspaceId },
    fetchPolicy: 'cache-and-network',
  })
  const sharesCount =
    workspaceSharesCount.data?.workspace.shares.meta.totalCount ?? 0

  const billingQuery = useGetWorkspaceBillingQuery({
    variables: { identifier: workspaceId },
    skip: !workspace.customer?.identifier,
  })

  useEducationWorkspaceApproved(workspace)
  const apolloClient = useApolloClient()
  const cache = apolloClient.cache

  let projectFromCache = null

  if (isProjectRoute(path)) {
    projectFromCache = getProjectTitle(params, cache)
  }
  const sectionTitle = projectFromCache || getSectionTitle(path)

  useOverrideLayoutProps({
    title: sectionTitle || 'Documents',
    hideFooter: true,
    hasNavbar: false,
  })

  const subscription = billingQuery.data?.workspace?.customer?.billing

  if (loading && isDraftsRoute(path)) {
    return <LoadingState />
  }

  const isUserEditor = workspace.userCanEdit
  const canUserAdministerShares = getCanAdministerShares(workspace)
  const hasDraftsProject = !!data?.workspace.draftsProject.entries[0]
  const userCanRenameProject = isDraftsRoute(path)
    ? false
    : isUserEditor || canUserAdministerShares
  const userCanManageCollection = isUserEditor || canUserAdministerShares

  const isUserAdmin = workspace.userRole === 'ADMIN'
  const isMember = isUserAdmin || workspace.userRole === 'MEMBER'
  const { status, subscriptionEnd } = subscription ?? {}

  const isEducationWorkspace = workspace.type === 'STUDENT'

  if (isTrashRoute(path) && !canUserAdministerShares) {
    return <NotFoundView isInLayout />
  }

  if (isDraftsRoute(path) && !hasDraftsProject) {
    // The user is accessing /workspace/${workspaceId}/drafts but there isn't any
    // draft project in this workspace.
    return <NotFoundView isInLayout />
  }

  const navbar = (
    <ShareNavbar
      userRole={workspace.userRole}
      title={<NavbarTitle>{sectionTitle}</NavbarTitle>}
      showViewOptions={Boolean(sharesCount)}
    />
  )

  return (
    <>
      <NavbarPortal>{navbar}</NavbarPortal>

      <SubheaderPortal>
        <FirstStepsBanner />
      </SubheaderPortal>

      {/*
        Prevent PersonalWorkspaceBanners from showing along with the
        empty Workspace banners.
        */}
      {sharesCount ? (
        <PersonalWorkspaceBanners
          workspaceId={workspace.identifier}
          workspaceType={workspace.type}
          isOwner={workspace.userIsOwner}
        />
      ) : null}

      {status && (
        <BillingStatusBanners
          workspaceId={workspaceId}
          workspace={workspace}
          sharesCount={sharesCount}
          billingStatus={status}
          cancelPeriodEnd={subscriptionEnd ?? undefined}
          isUserAdmin={isUserAdmin}
          isEducationWorkspace={isEducationWorkspace}
        />
      )}

      {subscription && (
        <WelcomeModalTrigger
          subscriptionDetails={subscription}
          workspace={workspace}
        />
      )}

      {/* Mounting the banner container to render them all */}
      {!isWorkspaceLibrariesRoute && (
        <BannerWrapper $hideMargins="top">
          <BannersDisplay />
        </BannerWrapper>
      )}

      {isSmallerThanTablet && (
        <SearchBarWrapper>
          <SearchBar userRole={workspace.userRole} fullWidth />
        </SearchBarWrapper>
      )}

      <Switch>
        <Route
          exact
          path={[
            routes.WORKSPACE_PROJECT.template(),
            routes.WORKSPACE_DRAFTS.template(),
            routes.WORKSPACE_COLLECTION.template(),
          ]}
          render={(routeProps: ProjectsAndCollectionsViewRouteProps) => (
            <>
              <ProjectsAndCollectionsView
                {...routeProps}
                workspace={workspace}
                userIsEditor={isUserEditor}
                userCanRenameProject={userCanRenameProject}
                userCanManageCollection={userCanManageCollection}
              />
            </>
          )}
        />
        <Route
          exact
          path={[routes.WORKSPACE_TRASH_COLLECTION.template()]}
          render={({ match: { params } }: CollectionsViewProps) => (
            <CollectionsView
              workspace={workspace}
              projectId={params.projectId!}
              collectionId={params.collectionId!}
              userCanManageCollection={userCanManageCollection}
            />
          )}
        />
        {canUserAdministerShares && (
          <Route
            exact
            path={routes.WORKSPACE_TRASH.template()}
            render={({ match: { params } }: WorkspaceTrashRouteParams) => (
              <TrashList
                workspaceId={params.workspaceId!}
                userRole={workspace.userRole}
              />
            )}
          />
        )}
        <Route
          exact
          path={routes.WORKSPACE_TRASH_PROJECT.template()}
          render={({ match: { params } }: ProjectsViewProps) => (
            <ProjectsView
              workspace={workspace}
              projectId={'projectId' in params ? params.projectId : undefined}
              userIsEditor={isUserEditor}
              userCanRenameProject={false}
              userCanManageCollection={false}
            />
          )}
        />
        <Route
          exact
          path={routes.WORKSPACE_SHARES.template()}
          render={({ match }: WorkspaceSharesRouteParams) => (
            <>
              {isTabletAndBigger && (
                <WorkspaceSharesListHeader
                  filter={showDocumentsInProjects ? null : 'NO_PROJECT'}
                />
              )}
              <WorkspaceSharesList
                workspaceId={match.params.workspaceId!}
                workspace={workspace}
                filter={showDocumentsInProjects ? null : 'NO_PROJECT'}
                showArchived={showArchivedDocuments}
              />
            </>
          )}
        />
        <Route
          exact
          path={routes.WORKSPACE_SHARED_WITH_ME.template()}
          render={({ match }: WorkspaceSharedWithMeRouteParams) => (
            <>
              {isTabletAndBigger && (
                <WorkspaceSharesListHeader filter={'SHARED_WITH_ME'} />
              )}
              <WorkspaceSharesList
                workspaceId={match.params.workspaceId!}
                workspace={workspace}
                filter="SHARED_WITH_ME"
                showArchived={showArchivedDocuments}
              />
            </>
          )}
        />
        <Route
          exact
          path={routes.WORKSPACE_TEMPLATES.template()}
          render={({ match }: WorkspaceSharedWithMeRouteParams) => (
            <>
              {isTabletAndBigger && (
                <WorkspaceSharesListHeader filter={'TEMPLATE'} />
              )}
              <WorkspaceSharesList
                workspaceId={match.params.workspaceId!}
                workspace={workspace}
                filter="TEMPLATE"
                showArchived={showArchivedDocuments}
              />
            </>
          )}
        />
        <Route
          exact
          path={routes.WORKSPACE_LIBRARIES.template()}
          render={({ match }: WorkspaceLibrariesRouteParams) => (
            <>
              {isTabletAndBigger && (
                <WorkspaceSharesListHeader filter={'LIBRARY'} />
              )}
              <WorkspaceSharesList
                workspaceId={match.params.workspaceId!}
                workspace={workspace}
                filter="LIBRARY"
                isMember={isMember}
                showArchived={showArchivedDocuments}
                sectionTitle={sectionTitle}
              />
            </>
          )}
        />
        {workspace.features.archiveEnabled && (
          <Route
            exact
            path={routes.WORKSPACE_ARCHIVE.template()}
            render={({ match: { params } }: WorkspaceArchiveRouteParams) => (
              <ArchiveList
                workspaceId={params.workspaceId!}
                workspace={workspace}
                userRole={workspace.userRole}
              />
            )}
          />
        )}
      </Switch>
    </>
  )
}

export default WorkspaceDocumentsView

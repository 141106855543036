import { MILLISECONDS_PER_DAY, MILLISECONDS_PER_HOUR } from './constants.js';

/**
 * Contains information about a time duration and contains helper methods
 * to convert it to days, hours etc...
 */
class ElapsedTime {
    constructor(elapsedTimeInMs) {
        this.elapsedTimeInMs = elapsedTimeInMs;
    }
    /** Get the elapsed time in days */
    toDays(options) {
        var _a;
        const precision = (_a = options === null || options === void 0 ? void 0 : options.precision) !== null && _a !== void 0 ? _a : 'full';
        const diffDays = this.elapsedTimeInMs / MILLISECONDS_PER_DAY;
        return this.withPrecision(diffDays, precision);
    }
    /** Get the elapsed time in hours */
    toHours(options) {
        var _a;
        const precision = (_a = options === null || options === void 0 ? void 0 : options.precision) !== null && _a !== void 0 ? _a : 'full';
        const diffHours = this.elapsedTimeInMs / MILLISECONDS_PER_HOUR;
        return this.withPrecision(diffHours, precision);
    }
    /**
     * Transform the value to follow the provided precision.
     */
    withPrecision(value, precision) {
        if (precision === 'full') {
            return Math.floor(value);
        }
        if (precision === 'started') {
            return Math.ceil(value);
        }
        if (precision === 'rounded') {
            return Math.round(value);
        }
        // Make sure value can not be negative
        return Math.max(value, 0);
    }
}
/**
 * Get the elapsed time between two dates. The function returns an instance of
 * ElapsedTime so you can convert the time in days, hours, etc...
 */
function getElapsedTimeBetweenDate(startDate, endDate) {
    assertValidDate(startDate, 'startDate');
    assertValidDate(endDate, 'endDate');
    if (endDate.getTime() < startDate.getTime()) {
        throw new Error(`getElapsedTimeBetweenDate: endDate can not be earlier than startDate.`);
    }
    const timeDiffInMsBetweenDate = endDate.getTime() - startDate.getTime();
    return new ElapsedTime(timeDiffInMsBetweenDate);
}
function assertValidDate(date, type) {
    if (isNaN(date.getTime())) {
        throw new Error(`getElapsedTimeBetweenDate: Provided "${type}" argument is an invalid Date.`);
    }
}

export { ElapsedTime, getElapsedTimeBetweenDate };

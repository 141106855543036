import React, { useMemo } from 'react'

import { useVersioning } from 'modules/versioning'

import { useAnnotationStatusFilter } from './hooks'
import { AnnotationQueryVariablesContext } from './context'
import { AnnotationSubject } from '../../types'

interface AnnotationQueryVariablesProviderProps {
  shareIdentifier: string
  subjects: AnnotationSubject[]
}

export const AnnotationQueryVariablesProvider: React.FC<AnnotationQueryVariablesProviderProps> = ({
  children,
  subjects,
  shareIdentifier,
}) => {
  const { versionIdentifier } = useVersioning()
  const {
    annotationQuerySort,
    annotationQueryStatus,
  } = useAnnotationStatusFilter()

  const value = useMemo(
    () => ({
      shareIdentifier,
      subjects,
      versionIdentifier: versionIdentifier!,
      annotationStatus: annotationQueryStatus,
      annotationSort: annotationQuerySort,
    }),
    [
      annotationQuerySort,
      annotationQueryStatus,
      subjects,
      shareIdentifier,
      versionIdentifier,
    ]
  )

  return (
    <AnnotationQueryVariablesContext.Provider value={value}>
      {children}
    </AnnotationQueryVariablesContext.Provider>
  )
}

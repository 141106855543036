// The correct order for the breakpoint array
const BREAKPOINT_SORT_ORDER = ['xs', 'sm', 'md', 'lg', 'xl'];
const MOBILE_BREAKPOINTS = ['base', 'xxs'];
const WIDE_BREAKPOINTS = ['xxl', 'xxxl'];
// Makes sure the breakpoint array is ordered correctly
const sortBreakpointsArray = (a, b) => BREAKPOINT_SORT_ORDER.indexOf(a) - BREAKPOINT_SORT_ORDER.indexOf(b);
// Our theme breakpoints have many values we don't need. This functions clamps them
// 'base', 'xxs' and 'xs' => 'sm'
// 'xxl' and 'xxxl' => 'xl'
const clampCurrentBreakpoint = (currentBreakpoint) => {
    const isMobileBreakpoint = MOBILE_BREAKPOINTS.some(mobleBreakpoint => mobleBreakpoint === currentBreakpoint);
    if (isMobileBreakpoint) {
        return 'xs';
    }
    const isWideBreakpoint = WIDE_BREAKPOINTS.some(wideBreakpoint => wideBreakpoint === currentBreakpoint);
    if (isWideBreakpoint) {
        return 'xl';
    }
    return currentBreakpoint;
};

export { BREAKPOINT_SORT_ORDER, MOBILE_BREAKPOINTS, WIDE_BREAKPOINTS, clampCurrentBreakpoint, sortBreakpointsArray };

import React, { useState, useEffect } from 'react'
import { getItem, useDebounceValue } from '@sketch/utils'
import { LoadingPlaceholder, Input, RadioButton } from '@sketch/components'
import { useToast } from '@sketch/toasts'
import { localStorageKeys } from '@sketch/constants'
import { useGetSharesLazyQuery } from '@sketch/gql-types'
import { useGetProjects } from 'modules/projects/operations'
import { useNotificationSearchContext } from '../../../context/NotificationSearchContext'
import {
  LoadingWrapper,
  LocationWrapper,
  ProjectIcon,
  DocumentIcon,
} from './NotificationSearch.styles'
import { LocationItem, LocationType } from './types'

type NotificationSearchProps = {
  workspaceIds: string[]
}

/**
 * COMPONENT
 */
export const LocationFilter: React.FC<NotificationSearchProps> = ({
  workspaceIds,
}) => {
  const { options, setOptions } = useNotificationSearchContext()
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [shares, setShares] = useState<LocationItem[]>([])
  const [projects, setProjects] = useState<LocationItem[]>([])
  const [selected, setSelected] = useState<LocationItem | null>(null)
  const { showToast } = useToast()
  const workspaceIdentifier = getItem(localStorageKeys.lastWorkspaceIdKey) ?? ''

  const debouncedSearch = useDebounceValue(inputValue, 500)

  // makes sure that, when there's a reset, the selected items are reset as well
  useEffect(() => {
    setSelected(options.location)
  }, [options])

  useEffect(() => {
    // only get autocomplete when the debounced search updates
    getFilteredLocationList(debouncedSearch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch])

  // gets all the projects right away and then filter when we get input
  // (we don't have search for projects on the backend)
  const { projects: allProjects } = useGetProjects({
    workspaceId: workspaceIdentifier,
  })

  const projectsList = allProjects?.entries || []

  const [getShares] = useGetSharesLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      // Exclude members that have been already invited from dropdown
      const entries = data?.workspace?.shares?.entries || []
      const workspaceId = data?.workspace?.identifier

      const newshares = entries.map(entry => {
        return {
          identifier: entry.identifier,
          name: entry.name,
          type: 'share' as LocationType,
        }
      })

      setLoading(false)

      // if we're on the first workspace of the list the user has access to, replace the previous share results
      if (workspaceId === workspaceIds[0]) {
        setShares(newshares)
      } else {
        // otherwise just append to the list
        setShares([...shares, ...newshares])
      }
    },
    onError() {
      showToast('Failed to find documents.', 'negative')
      setLoading(false)
    },
  })

  const getFilteredLocationList = function (inputValue: string) {
    const isValueTooShort = inputValue.length === 0
    const isValueEmpty = inputValue.trim() === ''

    const skip = isValueTooShort || isValueEmpty

    if (skip) {
      setShares([])
      setProjects([])
      return
    }

    setLoading(true)

    // @TODO: how to get the all the workspaces, including the ones we're guests in and get notifications for, without triggering so many calls?
    workspaceIds.forEach(identifier => {
      getShares({
        variables: {
          identifier,
          search: { name: inputValue.toLowerCase(), filters: [] },
        },
      })
    })

    const filteredProjects = projectsList
      ?.filter(project =>
        project.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map(project => ({
        name: project.name,
        identifier: project.identifier,
        type: 'project' as LocationType,
      }))

    setProjects(filteredProjects)
  }

  const onItemClick = (item: LocationItem) => {
    setSelected(item)
    setOptions({ ...options, location: item })
  }

  const items = [...projects, ...shares]

  return (
    <>
      <Input
        value={inputValue}
        type="text"
        name="location"
        placeholder={'Folder or document name'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setInputValue(e.target.value)
        }
      />
      <LocationWrapper>
        {loading && (
          <LoadingWrapper>
            <LoadingPlaceholder size="16px" />
          </LoadingWrapper>
        )}
        {!loading &&
          items.map(item => (
            <RadioButton
              name="locationOption"
              label={item.name}
              checked={selected?.identifier === item.identifier}
              onChange={() => onItemClick(item)}
              key={item.identifier}
              icon={
                item.type === 'project' ? <ProjectIcon /> : <DocumentIcon />
              }
            />
          ))}
      </LocationWrapper>
    </>
  )
}

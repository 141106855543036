import { useState, useMemo, useEffect } from 'react';
import debounce from 'debounce';

/**
 * useDebounceValue
 *
 * This hook queues the change of a value until the last change passes the debounceTime.
 *
 * @param {*} originalValue - Value to be debounced.
 * @param {*} debounceTime - Debounce delay time in milliseconds.
 */
function useDebounceValue(originalValue, debounceTime) {
    const [value, setValue] = useState(originalValue);
    const debounceCall = useMemo(() => debounce(newValue => {
        setValue(newValue);
    }, debounceTime), [debounceTime]);
    useEffect(() => {
        debounceCall(originalValue);
        return () => {
            debounceCall.clear();
        };
    }, [debounceCall, originalValue]);
    return value;
}

export { useDebounceValue };

import { jsx } from 'react/jsx-runtime';
import { Link } from 'react-router-dom';
import '../../user/index.js';
import { IS_EMBEDDED } from '@sketch/constants';
import { SKETCH_WEBSITE } from '@sketch/env-config';
import { Tooltip, ExternalLink, Navbar } from '@sketch/components';
import { useUserSignedIn } from '../../user/useUserSignedIn.js';

/**
 * Logo sometimes shown on top left corner of header.
 * Links to cloud app root, sketch.com or the non-embed version of the current page in a new tab
 * based on if the user is logged in or not and if the user is using the embed mode or not.
 */
function SketchHomeButton() {
    const isSignedIn = useUserSignedIn();
    if (IS_EMBEDDED) {
        const origin = window.location.origin;
        const pathname = window.location.pathname.replace(/^\/embed/, '');
        const currentUrl = `${origin}${pathname}`;
        return (jsx(Tooltip, Object.assign({ placement: "bottom-start", content: "Open in a new tab" }, { children: jsx(ExternalLink, Object.assign({ href: currentUrl, "aria-label": "Open in a new tab" }, { children: jsx(Navbar.Logo, { variant: "black" }) })) })));
    }
    if (isSignedIn) {
        return (jsx(Link, Object.assign({ to: "/c", title: "Home" }, { children: jsx(Navbar.Logo, { variant: "black" }) })));
    }
    return (jsx(ExternalLink, Object.assign({ href: `${SKETCH_WEBSITE}/home`, title: "Home" }, { children: jsx(Navbar.Logo, { variant: "black" }) })));
}

export { SketchHomeButton };

import { useApolloClient } from 'react-apollo'
import { useHistory, useLocation } from 'react-router-dom'

import useWorkspaceSubscriptions from 'modules/workspace/operations/useWorkspaceSubscriptions'
import { isViewingWorkspace } from 'modules/projects/utils'
import { removeFromPaginated, routes } from '@sketch/modules-common'

const WorkspaceSubscriptions = () => {
  const client = useApolloClient()
  const history = useHistory()
  const location = useLocation()

  useWorkspaceSubscriptions({
    onMembershipUpdated: resource => {
      const workspaceIdentifier = resource.workspaceIdentifier
      const status = resource.status
      const wasUserRemovedFromWorkspace = status === 'NONE'

      const isUserViewingWorkspace = isViewingWorkspace(
        location.pathname,
        workspaceIdentifier
      )

      if (wasUserRemovedFromWorkspace) {
        // Remove the Workspace from the cache
        removeFromPaginated(
          client,
          { __typename: 'Workspace', identifier: workspaceIdentifier },
          () => true
        )

        // Users is viewing the workspace he is being removed from
        // so we redirect to the Entry page
        if (isUserViewingWorkspace) {
          history.push(routes.ENTRY.create({}))
        }
      }
    },
  })

  return null
}

export default WorkspaceSubscriptions

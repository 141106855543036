import { castError } from '@sketch/utils';
import { useState } from 'react';
import { useApolloClient } from 'react-apollo';
import '../utils/index.js';
import { ErrorHandler } from '@sketch/tracing';
import { readShareListItemFromCache } from '../utils/share.cache.js';

const VALID_DATATRANSFER_TYPES = ['Text', 'text/plain'];
const useShareDrop = ({ onDropped }) => {
    const [isDraggedOver, setDragged] = useState(false);
    const cache = useApolloClient();
    if (!onDropped) {
        return {
            kind: 'disabled',
        };
    }
    const onDragOver = (event) => {
        event.preventDefault();
        /*
        The dataTransfer types are expected from the dragged item, in this case the expected ones are "text",
        For this to work in IE11 we would have to use the type "text"
        https://stackoverflow.com/a/18051912
    
        IE 11 transforms "text" to "Text"
        and others from "text" to "text/plain" 🤦‍♂️
        */
        if (VALID_DATATRANSFER_TYPES.includes(event.dataTransfer.types[0])) {
            !isDraggedOver && setDragged(true);
        }
    };
    const onDragLeave = (event) => {
        event.preventDefault();
        setDragged(false);
    };
    const onDrop = (event) => {
        event.preventDefault();
        setDragged(false);
        const payload = getEventData(cache, event);
        if (!payload) {
            return;
        }
        onDropped(payload);
    };
    return {
        kind: 'enabled',
        isDraggedOver,
        onDrop,
        onDragOver,
        onDragLeave,
    };
};
const getEventData = (cache, event) => {
    const dataType = event.dataTransfer.types[0];
    let dataObject;
    try {
        dataObject = JSON.parse(event.dataTransfer.getData(dataType));
    }
    catch (e) {
        const error = castError(e);
        ErrorHandler.ignore(error);
    }
    const { shareId } = dataObject || {};
    if (!shareId) {
        return;
    }
    const share = readShareListItemFromCache({ cache, id: shareId });
    return { shareId, share };
};

export { useShareDrop };

import { jsxs, jsx } from 'react/jsx-runtime';
import { ReactComponent as ReactComponent$6 } from '@sketch/icons/forbidden-16';
import { ReactComponent } from '@sketch/icons/workspace-16';
import { ReactComponent as ReactComponent$1 } from '@sketch/icons/documents-prism-16';
import { ReactComponent as ReactComponent$2 } from '@sketch/icons/clock-16';
import { ReactComponent as ReactComponent$3 } from '@sketch/icons/document-16';
import { ReactComponent as ReactComponent$4 } from '@sketch/icons/artboard-16';
import { ReactComponent as ReactComponent$5 } from '@sketch/icons/arrow-down-to-line-16';
import { ScopeContainer, Icon } from './AccessScope.styles.js';

const allScopes = [
    'WORKSPACE_READ',
    'DOCUMENT_READ',
    'UPDATE_READ',
    'PAGE_READ',
    'ARTBOARD_READ',
    'UPDATE_EXPORT',
];
const scopeMetadata = {
    WORKSPACE_READ: {
        description: 'Access to your Workspaces',
        icon: {
            src: ReactComponent,
            alt: 'Workspace icon',
        },
    },
    DOCUMENT_READ: {
        description: 'Read-only access to documents ',
        icon: {
            src: ReactComponent$1,
            alt: 'Document icon',
        },
    },
    UPDATE_READ: {
        description: 'Read-only access to document updates',
        icon: {
            src: ReactComponent$2,
            alt: 'Update icon',
        },
    },
    PAGE_READ: {
        description: 'Read-only access to document pages',
        icon: {
            src: ReactComponent$3,
            alt: 'Page icon',
        },
    },
    ARTBOARD_READ: {
        description: 'Read-only access to page Artboards',
        icon: {
            src: ReactComponent$4,
            alt: 'Artboard icon',
        },
    },
    UPDATE_EXPORT: {
        description: 'Asset exports for document updates',
        icon: {
            src: ReactComponent$5,
            alt: 'Export icon',
        },
    },
};
const descriptionForScope = (scope) => {
    const { description } = scopeMetadata[scope];
    return description;
};
const AccessScope = props => {
    const { scope, enabled } = props;
    const { description, icon } = scopeMetadata[scope];
    const iconSrc = enabled ? icon.src : ReactComponent$6;
    const altText = enabled ? icon.alt : 'Disabled icon';
    return (jsxs(ScopeContainer, Object.assign({ enabled: enabled }, { children: [jsx(Icon, { as: iconSrc, "aria-label": altText, enabled: enabled }), jsx("span", { children: description })] }), scope));
};

export { allScopes, AccessScope as default, descriptionForScope };

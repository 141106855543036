import { jsx } from 'react/jsx-runtime';
import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS_KEYS_SORTED, breakpoint } from '@sketch/global-styles';

const RatioContainer = styled.div ``;
const setItemWidthAndMargin = ([columns, gutterSize]) => `
  width: calc(100% / ${columns} - ${(gutterSize * (columns - 1)) / columns}px);
  margin-right: ${gutterSize}px;

  :nth-child(${columns}n) {
    margin-right: 0;
  }
`;
const setItemRatioAndMargin = ([ratio, verticalRowSpace]) => `
  margin-bottom: ${verticalRowSpace}px;

  ${ratio !== 'no-ratio'
    ? `
    position: relative;

    ::before {
      content: '';
      display: block;
      padding-bottom: ${(1 / ratio) * 100}%;
    }

    /* Style "RatioContainer" only when there's an actual ratio */
    ${RatioContainer} {
      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
    }
    `
    : ''}
`;
const setWrapperNegativeMargin = ([verticalRowSpace]) => `
  margin-bottom: -${verticalRowSpace}px;
`;
const normalizeToArray = (property) => (Array.isArray(property) ? property : [property]);
const getIndexOrLastValue = (array, index) => array[index] || array[array.length - 1];
const buildResponsiveCSS = (propsFunction, cssFunction) => (props) => {
    const properties = propsFunction(props);
    const propertiesLength = properties.map(item => item.length);
    const largestBreakpointIndex = Math.min(Math.max(...propertiesLength), BREAKPOINTS_KEYS_SORTED.length);
    const selectedBreakpoints = BREAKPOINTS_KEYS_SORTED.slice(0, largestBreakpointIndex);
    let cssByBreakpoint = '';
    for (let index = 0; index < largestBreakpointIndex; index++) {
        const currentBreakpoint = selectedBreakpoints[index];
        const nextBreakpoint = selectedBreakpoints[index + 1];
        const valuesByBreakpoint = properties.map(propertyArray => getIndexOrLastValue(propertyArray, index));
        const cssInThisBreakpoint = breakpoint(currentBreakpoint, nextBreakpoint) `${cssFunction(valuesByBreakpoint)}`.join('');
        cssByBreakpoint += cssInThisBreakpoint;
    }
    return cssByBreakpoint;
};
const buildResponsiveGrid = buildResponsiveCSS(({ columns, gutterSize }) => [columns, gutterSize], setItemWidthAndMargin);
const buildWrapperMargin = buildResponsiveCSS(({ verticalRowSpace }) => [verticalRowSpace], setWrapperNegativeMargin);
const buildResponsiveItem = buildResponsiveCSS(({ ratio, verticalRowSpace }) => [ratio, verticalRowSpace], setItemRatioAndMargin);
const Item = styled.li `
  position: relative;
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : undefined)};
  ${buildResponsiveItem};
`;
const Wrapper = styled.ul `
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;

  list-style: none;
  padding: 0;
  margin: 0;

  /* Prevent the "verticalRowSpace" from the last row */
  ${buildWrapperMargin}

  ${Item} {
    ${buildResponsiveGrid};
  }
`;
const AspectRatioGrid = props => {
    const { children, columns = 4, gutterSize = 0, ratio = 'no-ratio', verticalRowSpace = 0, minWidth, className, } = props;
    const normalizedColumns = normalizeToArray(columns);
    const normalizedGutterSize = normalizeToArray(gutterSize);
    const normalizedRatio = normalizeToArray(ratio);
    const normalizedVerticalRowSpace = normalizeToArray(verticalRowSpace);
    return (jsx(Wrapper, Object.assign({ className: className, columns: normalizedColumns, gutterSize: normalizedGutterSize, verticalRowSpace: normalizedVerticalRowSpace }, { children: React.Children.map(children, childNode => (jsx(Item, Object.assign({ minWidth: minWidth, verticalRowSpace: normalizedVerticalRowSpace, ratio: normalizedRatio }, { children: jsx(RatioContainer, { children: childNode }) })))) })));
};

export { AspectRatioGrid, Item, RatioContainer };

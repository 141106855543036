import React from 'react'

import { InfiniteList, handleFetchMore } from '@sketch/components'
import {
  isMemberCurrentUser,
  thumbnailForPreviewFile,
} from 'modules/shares/utils'
import { dataIdFromObject } from '@sketch/graphql-cache'

import {
  UserPermissionsTabContent,
  buildPlaceholderArray,
  EmptyContent,
} from './UserPermissionsContent'
import { useManageUserSharePermissions } from './useManageUserPermissions'
import { UserPermissionRow } from '../../components/UserPermissionRow'

import { ReactComponent as ImagePlaceholder } from '@sketch/icons/image-placeholder-64'
import { MemberPermissionsActions } from 'modules/shares/components/AccessLevelSelect'
import { useUserProfile } from '@sketch/modules-common'

const PAGE_SIZE = 10

type SharePermissionsTabProps = {
  workspaceIdentifier: string
  workspaceMembershipIdentifier: string
  rows: number
}

export const SharePermissionsTab = ({
  workspaceIdentifier,
  workspaceMembershipIdentifier,
  rows,
}: SharePermissionsTabProps) => {
  const shares = useManageUserSharePermissions({
    workspaceIdentifier,
    workspaceMembershipIdentifier,
  })

  const { data } = useUserProfile(true)

  const total = shares.total
  const sharesLength = shares?.data?.length || 0
  const placeholderMembers = (total || PAGE_SIZE) - sharesLength

  if (shares.loading && sharesLength === 0) {
    return (
      <UserPermissionsTabContent rows={rows} onSearch={shares.setShareSearch}>
        {buildPlaceholderArray(2)}
      </UserPermissionsTabContent>
    )
  }

  if (!shares.loading && total === 0) {
    return (
      <UserPermissionsTabContent rows={rows} onSearch={shares.setShareSearch}>
        {shares.isUsingFilter ? (
          <EmptyContent
            title="No search results"
            subtitle="No document names matched your search."
          />
        ) : (
          <EmptyContent
            title="No documents"
            subtitle="This person hasn’t been added to any documents yet."
          />
        )}
      </UserPermissionsTabContent>
    )
  }

  return (
    <UserPermissionsTabContent rows={rows} onSearch={shares.setShareSearch}>
      <InfiniteList
        renderLoading={() => null}
        placeholderItems={buildPlaceholderArray(
          Math.min(PAGE_SIZE, placeholderMembers)
        )}
        onLoadMore={handleFetchMore(shares.fetchMore, shares.path, {
          dataIdFromObject,
          after: shares.after,
        })}
        canLoadMore={shares.after !== null}
        data-testid="shares-permissions-tab"
      >
        {shares.data?.map(membership => (
          <UserPermissionRow
            key={membership.identifier}
            name={membership.share?.name || ''}
            avatarSrc={thumbnailForPreviewFile(
              membership.share?.version?.document?.previewFile
            )}
            icon={ImagePlaceholder}
            isCurrentUser={isMemberCurrentUser(
              membership.workspaceMembership?.user?.identifier,
              data?.me.identifier
            )}
            actions={
              <MemberPermissionsActions
                member={membership}
                share={membership.share!}
                workspaceIdentifier={workspaceIdentifier}
                workspaceMembershipIdentifier={workspaceMembershipIdentifier}
              />
            }
          />
        ))}
      </InfiniteList>
    </UserPermissionsTabContent>
  )
}

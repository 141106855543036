import styled from 'styled-components';
import { breakpoint } from '@sketch/global-styles';
import { IndexLayoutOldTitle } from '@sketch/modules-common';
import { LinkButton } from '@sketch/components';

const FormContainer = styled.div `
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
`;
const Paragraph = styled.p `
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.E};
`;
const BottomSection = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
`;
const BreadcrumbsWrapper = styled.div `
  display: none;

  ${breakpoint('sm') `
    display: block;

    text-align: center;
    margin-top: 16px;

    + ${IndexLayoutOldTitle} {
      margin-top: 32px;
    }
  `}
`;
const StyledLinkButton = styled(LinkButton) `
  width: 100%;
  margin-top: 32px;
`;
const Line = styled.div `
  width: 100%;
  margin-top: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`;

export { BottomSection, BreadcrumbsWrapper, FormContainer, Line, Paragraph, StyledLinkButton };

import { useMemo } from 'react'
import { useInspectorContext } from './inspector'
import { useArtboardDetailContext } from './useArtboardDetailContext'
import { findArtboardElement } from 'modules/inspector'

/**
 * The Zoom component takes an optional prop `gridAndLayout` which contains
 * props to show and control the toggles for grid and layout in the zoom dropdown.
 * The result of this hook can be fed directly to the Zoom component.
 */
export function useGridAndLayoutPropForZoomComponent() {
  const {
    isInspectorActive,
    selectedElement,
    sketchSceneRootElement,
  } = useInspectorContext()

  // Show grid/layout options only if an artboard is selected or if we are in
  // the artboard detail view
  const artboardElement = findArtboardElement(
    selectedElement,
    sketchSceneRootElement
  )

  const {
    isGridEnabled,
    isLayoutEnabled,
    toggleGrid,
    toggleLayout,
  } = useArtboardDetailContext()

  const gridAndLayout = useMemo(() => {
    if (!isInspectorActive || !artboardElement) {
      return undefined
    }

    return {
      isGridEnabled,
      isLayoutEnabled,
      toggleGrid,
      toggleLayout,
    }
  }, [
    artboardElement,
    isGridEnabled,
    isInspectorActive,
    isLayoutEnabled,
    toggleGrid,
    toggleLayout,
  ])

  return gridAndLayout
}

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import DefaultTimeAgo, { defaultFormatter } from './DefaultTimeAgo.js';

const timeUnits = {
    second: 's',
    minute: 'm',
    hour: 'h',
    day: 'd',
    week: 'w',
    month: 'mo',
    year: 'y',
};
function createLongDateFormatter(capitalizeNow) {
    const now = capitalizeNow ? 'Now' : 'now';
    const formatter = (...args) => {
        const [value, unit, suffix] = args;
        if ((value === 1 && unit === 'day') || (value === 24 && unit === 'hour'))
            return 'Yesterday';
        return unit === 'second' ? now : defaultFormatter(value, unit, suffix);
    };
    return formatter;
}
function createShortDateFormatter(showSuffix, capitalizeNow) {
    const now = capitalizeNow ? 'Now' : 'now';
    const formatter = (value, unit, suffix) => {
        if (unit === 'second')
            return now;
        const formattedDate = `${value}${timeUnits[unit]}`;
        if (showSuffix) {
            return `${formattedDate} ${suffix}`;
        }
        return `${formattedDate}`;
    };
    return formatter;
}
const TimeAgo = (props) => {
    const { date, short = false, showShortDateSuffix = false, hideTitle = false, capitalizeNow = true } = props, otherProps = __rest(props, ["date", "short", "showShortDateSuffix", "hideTitle", "capitalizeNow"]);
    const formatter = short
        ? createShortDateFormatter(showShortDateSuffix, capitalizeNow)
        : createLongDateFormatter(capitalizeNow);
    return (jsx(DefaultTimeAgo, Object.assign({ date: date, title: !hideTitle ? new Date(date).toLocaleString() : undefined, formatter: formatter }, otherProps)));
};

export { TimeAgo, createLongDateFormatter, createShortDateFormatter, timeUnits };

import styled, { css } from 'styled-components';
import { ListboxButton, Listbox } from '@reach/listbox';
import '../Popover/index.js';
import '../Button/index.js';
import '../Dropdown/index.js';
import { selectBaseStyles } from './NativeSelect.styles.js';
export { selectBaseStyles } from './NativeSelect.styles.js';
import { buttonUnstyledStyles } from '../Button/ButtonUnstyled.js';
import { dropdownItemStyles } from '../Dropdown/Item/DropdownItem.styles.js';
import { popoverStyles } from '../Popover/Content/PopoverContent.js';

/**
 * Note: `ListboxButton as React.FC<ListboxButtonProps>` is needed because
 * for some reason using styled to extend Reach UI components make the types
 * to be lost, more info: https://github.com/reach/reach-ui/issues/750
 */
const SelectTriggerUnstyled = styled(ListboxButton) `
  ${buttonUnstyledStyles};
  width: 100%;
  display: block;
`;
// TODO: Make styles of Selector component self contained
//  see: https://github.com/sketch-hq/Cloud/issues/14547
/**
 * Styles for <Select />
 *
 * The styling strategy is to use Reach UI CSS selectors ([data-reach-*]),
 * because this way we can make sure the custom select works with the
 * standalone component or with the granular ones (more info:
 * https://github.com/sketch-hq/cloud-frontend/pull/3146). And finally also
 * styles the popover if it's render in a portal using the standalone component.
 *
 * There is also a weird typing loss when using styled-components with Reach UI,
 * more info: https://github.com/reach/reach-ui/issues/750
 *
 * More info about styling selectors: https://reach.tech/listbox/#listboxinput-css-selectors
 */
const selectStyles = css `
  [data-reach-listbox-popover][hidden] {
    display: none;
  }

  [data-reach-listbox-list] {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  [data-reach-listbox-list]:focus {
    box-shadow: none;
    outline: none;
  }

  [data-reach-listbox-option] {
    ${dropdownItemStyles};

    border-radius: 0;
    padding: 8px 16px;
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  [data-reach-listbox-button]:not(${SelectTriggerUnstyled}) {
    ${selectBaseStyles};
    display: flex;
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  [data-reach-listbox-button]:not(${SelectTriggerUnstyled}):focus {
    border-color: ${({ theme }) => theme.colors.sketch.A};
  }

  /* stylelint-disable-next-line no-descending-specificity */
  [data-placeholder='true'] [data-reach-listbox-button] {
    color: ${({ theme }) => theme.colors.foreground.secondary.D};
  }

  [data-reach-listbox-arrow] {
    margin-left: 8px;
    display: block;
  }

  [data-reach-listbox-group-label] {
    display: block;
    margin: 0;
    padding: 4px 8px;
    white-space: nowrap;
    user-select: none;
    font-weight: bolder;
  }

  [data-reach-listbox-popover] {
    ${popoverStyles};
    max-height: 300px;
    padding: 8px 0;
    margin-top: 4px;
    overflow: auto;

    &:empty {
      display: none;
    }

    /* The popover can be rendered in a portal, so we need to add a z-index to
      make sure it appears on modals (same z-index as [data-testid=modal-backdrop]) */
    z-index: ${({ theme }) => theme.zIndex[7]};
  }
`;
const ListBoxStyled = styled(Listbox) `
  ${selectStyles}
`;

export { ListBoxStyled, SelectTriggerUnstyled, selectStyles };

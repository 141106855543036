const RESET_TOKEN_STORAGE_KEY = 'resetToken';
const getTokenFromResetURL = (location) => {
    const isRequestPath = location.pathname.includes('reset');
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');
    return isRequestPath ? token : null;
};
const getResetToken = (location) => {
    try {
        return (window.sessionStorage.getItem(RESET_TOKEN_STORAGE_KEY) ||
            getTokenFromResetURL(location));
    }
    catch (e) {
        // Location will be used as a fall back if the resetToken is not set in storage
        return getTokenFromResetURL(location);
    }
};
const clearResetToken = () => {
    try {
        return window.sessionStorage.removeItem(RESET_TOKEN_STORAGE_KEY);
    }
    catch (e) {
        // If it can't remove from the session-storage it means that it didn't have access before
    }
};
const handleResetToken = (location) => {
    const token = getTokenFromResetURL(location);
    if (token) {
        try {
            window.sessionStorage.setItem(RESET_TOKEN_STORAGE_KEY, token);
            // Location will only be replaced if the "resetToken" is saved.
            window.location.replace(location.pathname);
        }
        catch (e) {
            /*
             * If an error occurs when saving the item in local-storage the location shouldn't be replaced
             * keeping the token in the header
             */
        }
        return true;
    }
    return false;
};

export { clearResetToken, getResetToken, handleResetToken };

import env from './appEnv.js';

/**
 * Will output the correct Sketch website depending
 * on the environment
 *
 * @example
 * <Link href={`${SKETCH_WEBSITE}/home`}
 */
const SKETCH_WEBSITE = env.sketch_website;

export { SKETCH_WEBSITE };

import styled from 'styled-components';
import '../Button/index.js';
import { ButtonUnstyled } from '../Button/ButtonUnstyled.js';

const Wrapper = styled.div `
  padding: 3px;
  border-radius: ${({ theme }) => theme.radii.xlarge};
  background-color: ${({ theme }) => theme.colors.background.tertiary.C};
  position: relative;
  display: flex;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1) inset;
`;
const Cover = styled.div `
  position: absolute;
  border-radius: ${({ theme }) => theme.radii.medium};
  background: ${({ theme }) => theme.colors.sketch.B};
  z-index: -1;
  transition: transform 0.2s linear;

  /* Position the item inside the margins */
  top: 3px;
  bottom: 3px;
`;
const Button = styled(ButtonUnstyled) `
  flex: 1;

  width: 50%;
  height: 32px;
  text-align: center;

  transition: background 0.2s linear;
  background: ${({ theme }) => `linear-gradient(to right, ${theme.colors.foreground.secondary.C} 33.33%, ${theme.colors.foreground.primary.A} 33.33% 66.66%, ${theme.colors.foreground.secondary.C} 66.66%)`};

  background-repeat: repeat-x;
  background-size: 300% 100%;

  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;

export { Button, Cover, Wrapper };

import React from 'react'

import { Sidebar as SidebarNew } from 'modules/workspace/components/Sidebar'
import { Sidebar as SidebarOld } from 'modules/workspace/components/SidebarOld'
import { useShareWorkspaceSidebar } from '../../hooks/useShareWorkspaceSidebar'
import { useFlag } from '@sketch/modules-common'

interface ShareWorkspaceSidebarProps {
  workspaceIdentifier: string
}

const ShareWorkspaceSidebar: React.FC<ShareWorkspaceSidebarProps> = ({
  workspaceIdentifier,
}) => {
  const isRefreshedUi = useFlag('ui-refresh-24')

  const { shouldRenderWorkspaceSidebar, workspace } = useShareWorkspaceSidebar(
    workspaceIdentifier
  )

  const Sidebar = isRefreshedUi ? SidebarNew : SidebarOld

  if (shouldRenderWorkspaceSidebar) {
    return <Sidebar workspace={workspace!} />
  }

  return null
}

export default ShareWorkspaceSidebar

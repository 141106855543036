import React from 'react'

import {
  Button,
  ToastLink,
  Modal,
  ModalInjectedProps,
} from '@sketch/components'

import { useToast } from '@sketch/toasts'
import {
  GetProjectsDocument,
  GetProjectsQueryVariables,
  ProjectFragment,
  TrashedProjectFragment,
  useRestoreProjectMutation,
} from '@sketch/gql-types'
import { ProjectName } from './RestoreProject.styles'
import { routes } from '@sketch/modules-common'

import { useEventDispatch } from '@sketch/utils'
import { restoreWorkspaceProject } from 'modules/projects/operations'

interface RestoreProjectProps extends ModalInjectedProps {
  // ProjectFragment should be removed once BE returns the newer types in the getTrash
  // https://github.com/sketch-hq/Cloud/issues/11226
  project: Pick<
    ProjectFragment | TrashedProjectFragment,
    '__typename' | 'identifier' | 'name'
  >
  workspaceId: string
}

const RestoreProject: React.FC<RestoreProjectProps> = props => {
  const { workspaceId, project, hideModal } = props
  const { showToast } = useToast()

  const dispatchTrashRefresh = useEventDispatch('workspaceTrashRefresh')
  const dispatchShareRefresh = useEventDispatch('workspaceShareRefresh')

  const [restoreProject, { loading }] = useRestoreProjectMutation({
    variables: { projectIdentifier: project.identifier },
    onError: 'show-toast',
    update: cache => {
      restoreWorkspaceProject(cache, workspaceId, project.identifier)
    },
    awaitRefetchQueries: true,
    refetchQueries: () => [
      /* We need to restore the project to the workspace sidebar */
      {
        variables: { workspaceId } as GetProjectsQueryVariables,
        query: GetProjectsDocument,
      },
    ],
    onCompleted: () => {
      // Force the trash view to refresh for this workspace to refresh, if visible
      // see src/modules/shares/operations/useGetTrash/useGetTrash.ts
      dispatchTrashRefresh({ workspaceIdentifier: workspaceId })

      // Force the Shares view to refresh for this workspace to refresh, if visible
      // see src/modules/shares/components/WorkspaceSharesList.tsx
      // see src/modules/projects/views/ProjectsView/ProjectsView.tsx
      dispatchShareRefresh({
        workspaceIdentifier: workspaceId,
        projectIdentifier: project.identifier,
      })

      showToast(
        <>
          “
          <ToastLink
            to={routes.WORKSPACE_PROJECT.create({
              workspaceId,
              projectId: project.identifier,
            })}
          >
            {project.name}
          </ToastLink>
          ” has been restored
        </>,
        'positive'
      )

      hideModal()
    },
  })

  return (
    <Modal title="Restore Project?" onCancel={hideModal}>
      <Modal.Body>
        Restore <ProjectName>“{project.name}”</ProjectName> and all the
        documents it contains?
      </Modal.Body>
      <Modal.Footer>
        <form
          onSubmit={e => {
            e.preventDefault()
            restoreProject()
          }}
        >
          <Button variant="secondary" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" loading={loading}>
            Restore Project
          </Button>
        </form>
      </Modal.Footer>
    </Modal>
  )
}

export default RestoreProject

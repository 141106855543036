import React, { useEffect, useRef, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { CWV_ROUTES, isDocumentPrototypeRoute } from '@sketch/modules-common'

import { useSearch } from '../../hooks/useSearch'
import { MagIcon, SearchField, SearchButton } from './DocumentNavbar.styles'

const getPlaceholderByPath = (path: string) => {
  if (CWV_ROUTES.includes(path)) {
    return 'Search Components'
  } else if (isDocumentPrototypeRoute(path)) {
    return 'Search Prototypes'
  }

  return 'Search Artboards'
}

interface DocumentNavbarProps
  extends Pick<React.ComponentProps<'input'>, 'onBlur' | 'onFocus'> {
  forceExpand?: boolean
}

const DocumentNavbar = (props: DocumentNavbarProps) => {
  const [expanded, setExpanded] = useState(false)

  // Force focus on the input when it's mounted
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    expanded && inputRef.current?.focus()
  }, [expanded])

  const { path } = useRouteMatch()

  // Search query param, shared for both artboards and components
  const { search, setSearch } = useSearch()
  const isInputExpanded = props.forceExpand || expanded || search

  useEffect(() => {
    if (!isInputExpanded) {
      return
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (
        event.currentTarget instanceof HTMLElement &&
        inputRef.current?.contains(event.currentTarget)
      ) {
        return
      }

      setExpanded(false)
    }

    window.addEventListener('click', handleClickOutside)

    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [isInputExpanded])

  if (isInputExpanded) {
    return (
      <SearchField
        ref={inputRef}
        placeholder={getPlaceholderByPath(path)}
        onChange={setSearch}
        value={search}
        expand={CWV_ROUTES.includes(path) || isDocumentPrototypeRoute(path)}
        {...props}
      />
    )
  }

  return (
    <SearchButton
      onClick={() => setExpanded(true)}
      size="32"
      data-testid="search-toogle"
    >
      <MagIcon />
    </SearchButton>
  )
}

export default DocumentNavbar

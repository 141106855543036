import { DataProxy } from 'apollo-cache'
import { FetchResult } from 'apollo-link'
import {
  TransferShareMutation,
  TransferShareMutationVariables,
  useTransferShareMutation,
  GetShareWorkspaceQuery,
  GetShareWorkspaceQueryVariables,
  GetShareWorkspaceDocument,
} from '@sketch/gql-types'
import { useApolloClient } from 'react-apollo'
import { readShareWithProjectFragment } from 'modules/projects/operations'
import { ErrorHandler } from '@sketch/tracing'
import { removeFromPaginated } from '@sketch/modules-common'
import { invalidateProjectShares } from 'modules/collections/operations'

export interface UseTransferShareProps {
  onCompleted: (data: TransferShareMutation) => void
  onError: (message: string) => void
}

export const useShareTransferToWorkspace = (props: UseTransferShareProps) => {
  const { onCompleted, onError } = props
  const client = useApolloClient()

  const handleUpdate = (
    cache: DataProxy,
    { data }: FetchResult<TransferShareMutation>
  ) => {
    if (!data) {
      return ErrorHandler.shouldNeverHappen(
        'TransferShare response should always have data'
      )
    }

    const { identifier, project } = data.transferShare.share

    // Remove this share from cache
    removeFromPaginated(cache, { __typename: 'Share', identifier }, key =>
      key.includes('.shares')
    )

    // Invalidate any cached `project.shares` records so that they are
    // refetched from the GraphQL API
    project &&
      invalidateProjectShares({
        cache,
        projectIdentifier: project.identifier,
      })
  }

  const [transfer, ...rest] = useTransferShareMutation({
    onCompleted,
    onError: graphqlError => {
      onError(graphqlError.message)
      ErrorHandler.shouldNeverHappen('transferShare')
    },
  })

  return [
    async (variables: TransferShareMutationVariables) => {
      const cachedShare = readShareWithProjectFragment(
        client,
        variables.shareIdentifier
      )

      const shareWorkspace = await client.query<
        GetShareWorkspaceQuery,
        GetShareWorkspaceQueryVariables
      >({
        query: GetShareWorkspaceDocument,
        variables: { shareIdentifier: variables.shareIdentifier },
      })

      const isWithinSameWorkspace =
        shareWorkspace.data.share?.workspace.identifier ===
        variables.workspaceIdentifier

      const isWithinSameProject =
        Boolean(variables.projectIdentifier) &&
        cachedShare?.project?.identifier === variables.projectIdentifier

      if (isWithinSameWorkspace || isWithinSameProject) {
        return
      }

      return transfer({
        variables,
        update: (...args) => handleUpdate(...args),
      })
    },
    ...rest,
  ] as const
}

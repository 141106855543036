import React from 'react'

import { UserPermissionRowPlaceholder } from '../../components/UserPermissionRow'
import {
  StyledFilter,
  EmptyStateWrapper,
  EmptyTitle,
  EmptySubtitle,
  ListWrapper,
} from './ManageUserPermissionsModal.styles'

type UserPermissionsTabContentProps = {
  children: React.ReactNode
  rows: number
  // Search handler is mandatory or otherwise some states won't
  // perform the search and we will get stacked
  onSearch: (value: string) => void
}

export const buildPlaceholderArray = (count: number) => (
  <>
    {new Array(count).fill(null).map((_, index) => (
      <UserPermissionRowPlaceholder key={index} showStripes={true} />
    ))}
  </>
)

export const UserPermissionsTabContent = ({
  children,
  rows,
  onSearch,
}: UserPermissionsTabContentProps) => {
  const placeholderText = 'Search'

  return (
    <>
      <StyledFilter
        data-test-id="search-filter"
        name="search-filter"
        placeholder={placeholderText}
        onChange={onSearch}
      />
      <ListWrapper $rows={rows}>{children}</ListWrapper>
    </>
  )
}

export const EmptyContent = ({
  title,
  subtitle,
}: {
  title: string
  subtitle: string
}) => (
  <EmptyStateWrapper>
    <EmptyTitle>{title}</EmptyTitle>
    <EmptySubtitle>{subtitle}</EmptySubtitle>
  </EmptyStateWrapper>
)

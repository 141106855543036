import styled from 'styled-components';
import { Modal } from '@sketch/components';

const TokenDetailsRow = styled.div `
  display: flex;
`;
const TokenDetailsModalBody = styled(Modal.Body) `
  font-size: ${({ theme }) => theme.fontSizes.E};
  padding-bottom: 24px;
`;
const PermissionsHeading = styled.p `
  padding: 0;
  margin-bottom: 8px;
  font-size: ${({ theme }) => theme.fontSizes.C};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

export { PermissionsHeading, TokenDetailsModalBody, TokenDetailsRow };

import styled, { css, DefaultTheme, StyledComponent } from 'styled-components'

import { ReactComponent as ResetIcon } from '@sketch/icons/clockwise-arrow-circle-16'
import { ReactComponent as ChevronVertical } from '@sketch/icons/chevron-vertical-16'

import {
  Checkbox,
  ButtonUnstyled,
  Dropdown,
  SelectInput,
  Text,
  Navbar,
  SelectPopover,
  popoverStyles,
} from '@sketch/components'

export const IconWrapper = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    color: ${colors.foreground.secondary.D};
    margin: 8px 0;
  `
)

export const StyledChevron = styled(ChevronVertical)`
  width: 12px;
  height: 12px;
`

export const StyledSelectPopover = styled(SelectPopover)`
  &[data-reach-listbox-popover] {
    ${popoverStyles}
    width: 270px;
    padding-left: 0;
    padding-right: 0;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  cursor: pointer;
`

export const StyledDropdownItem = styled(Dropdown.Item)`
  padding: 8px 12px 8px 12px;
`

export const StyledDropdownResetItem = styled(Dropdown.Item)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px 8px 12px;
`

export const Filter = styled(Navbar.FilterOld)`
  background-color: ${({ theme }) => theme.colors.background.secondary.B};

  input[type='search'] {
    /* Padding right includes the clear button and the options button */
    padding-right: 120px; /* stylelint-disable-line scales/space */
  }
`

export const StyledDropdownHeader = styled(Dropdown.Header)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 20px;
  pointer-events: auto;
`

export const IconButton = styled(ButtonUnstyled)`
  cursor: pointer;
`

export const StyledResetIcon = styled(ResetIcon)<{ $active: boolean }>`
  width: 16px;
  height: 16px;
  color: ${({ $active, theme }) =>
    $active
      ? theme.colors.foreground.secondary.A
      : theme.colors.state.disabled};
`

export const ToggleWrapper = styled(Text.Div).attrs({
  textStyle: 'copy.tertiary.standard.D',
})`
  width: 74px;
  padding: 8px 8px 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
`

export const StyledSelectInput: StyledComponent<
  typeof SelectInput,
  DefaultTheme,
  {},
  never
> = styled(SelectInput)(
  ({ theme }) => css`
    border-left: 1px solid ${theme.colors.border.B};
  `
)

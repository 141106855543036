import styled, { css } from 'styled-components';
import { breakpoint } from '@sketch/global-styles';
import '../Box/index.js';
import { Text } from '../Box/BoxSystem.js';

const OnboardingPanelBody = styled(Text.Div).attrs({
    textStyle: 'copy.tertiary.standard.E',
})(({ theme }) => css `
    font-size: ${theme.fontSizes.D};
    padding: 0 16px 16px;

    & > p {
      padding-bottom: 16px;
    }

    ${breakpoint('xs') `
      font-size: 0.75rem;
    `}
  `);

export { OnboardingPanelBody };

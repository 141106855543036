const setParagraph = (editor) => editor.chain().focus().setParagraph().run();
const setTitle = (editor) => editor.chain().focus().setHeading({ level: 1 }).run();
const setHeading = (editor) => editor.chain().focus().setHeading({ level: 2 }).run();
const setSubheading = (editor) => editor.chain().focus().setHeading({ level: 3 }).run();
const setBulletList = (editor) => editor.chain().focus().toggleBulletList().run();
const setNumberedList = (editor) => editor.chain().focus().toggleOrderedList().run();
const setBold = (editor) => editor.chain().focus().toggleBold().run();
const setItalics = (editor) => editor.chain().focus().toggleItalic().run();
const setUnderline = (editor) => editor.chain().focus().toggleUnderline().run();
const setDivider = (editor) => editor.chain().focus().setHorizontalRule().run();
const setLink = (editor, url) => {
    if (!editor) {
        return;
    }
    // cancelled
    if (url === null) {
        return;
    }
    // empty
    if (url === '') {
        editor === null || editor === void 0 ? void 0 : editor.chain().focus().extendMarkRange('link').unsetLink().run();
        return;
    }
    // update link
    editor === null || editor === void 0 ? void 0 : editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
};

export { setBold, setBulletList, setDivider, setHeading, setItalics, setLink, setNumberedList, setParagraph, setSubheading, setTitle, setUnderline };

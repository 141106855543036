import { __rest } from 'tslib';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useState, useCallback } from 'react';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import { clearNaN } from './utils.js';

const withPortal = (usePortal, content) => usePortal ? jsx(Portal, { children: content }) : content;
/** Shows a popup on the elements passed as children
 * The popup is visible when the prop `visible` is true
 *
 * Usage example on `Popper.stories.js`
 */
const Popper = (_a) => {
    var { className, children, popup, spacing = '10px', contentStyle = {}, arrowStyle = {}, visible = false, placement = 'auto', modifiers = [], usePortal = true, disableFlip = false, onTriggerFocus, onTriggerBlur, tooltipContainerAs = 'div' } = _a, restProps = __rest(_a, ["className", "children", "popup", "spacing", "contentStyle", "arrowStyle", "visible", "placement", "modifiers", "usePortal", "disableFlip", "onTriggerFocus", "onTriggerBlur", "tooltipContainerAs"]) // passed to children as props
    ;
    const [rect, setRect] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [referenceElement, setReferenceElement] = useState(null);
    const { styles, state, forceUpdate } = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [
            { name: 'arrow', options: { element: arrowElement } },
            { name: 'computeStyles', options: { gpuAcceleration: false } },
            { name: 'flip', options: { padding: 5 }, enabled: !disableFlip },
            {
                name: 'preventOverflow',
                options: { boundary: 'viewport', padding: 5 },
            },
            { name: 'offset', options: { offset: [0, spacing.split('px')[0]] } },
            ...modifiers,
        ],
    });
    const getReferenceElementRef = useCallback((node) => {
        // Calculate bounding rectangle to allow customization
        // of content dimensions when needed
        // (popup content is done in render below)
        if (node && typeof node.getBoundingClientRect === 'function' && !rect) {
            setRect(node.getBoundingClientRect());
        }
        setReferenceElement(node);
    }, [rect]);
    const referenceChildrenProps = Object.assign({ ref: getReferenceElementRef, rect }, restProps);
    const { fillParent, popperProps, show, hide, rect: excludedRect } = referenceChildrenProps, referenceDivProps = __rest(referenceChildrenProps
    // JSDom (used in tests) sometimes returns NaN for the size of elements and this was the workaround for it
    // https://github.com/sketch-hq/cloud-frontend/pull/2207#discussion_r484827523
    , ["fillParent", "popperProps", "show", "hide", "rect"]);
    // JSDom (used in tests) sometimes returns NaN for the size of elements and this was the workaround for it
    // https://github.com/sketch-hq/cloud-frontend/pull/2207#discussion_r484827523
    const mergedStyle = clearNaN((state === null || state === void 0 ? void 0 : state.placement)
        ? Object.assign(Object.assign(Object.assign({}, styles.popper), contentStyle), { willChange: undefined }) : styles.popper);
    const TooltipContainerElementType = tooltipContainerAs;
    return (jsxs(Fragment, { children: [typeof children === 'function' ? (children(referenceChildrenProps)) : (jsx(TooltipContainerElementType, Object.assign({ className: className }, referenceDivProps, { onFocus: onTriggerFocus, onBlur: onTriggerBlur }, { children: children }))), visible
                ? withPortal(usePortal, popup({
                    ref: setPopperElement,
                    rect,
                    'data-placement': (state === null || state === void 0 ? void 0 : state.placement) || placement,
                    style: mergedStyle,
                    arrowRef: setArrowElement,
                    arrowStyle: clearNaN(Object.assign(Object.assign({}, styles.arrow), arrowStyle) || {}),
                    forceUpdate,
                }))
                : null] }));
};

export { Popper };

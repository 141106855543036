import { jsx, Fragment } from 'react/jsx-runtime';
import { useContext } from 'react';
import { Banner } from '../Banner.js';
import { bannerContext } from './bannerContext.js';

const BannersDisplay = () => {
    const { items, dismissBanner } = useContext(bannerContext);
    return (jsx(Fragment, { children: items.map(({ message, type, id, dismissible }) => (jsx(Banner, Object.assign({ type: type, dismissible: dismissible, onDismiss: () => {
                dismissBanner(id);
            } }, { children: message }), id))) }));
};

export { BannersDisplay };

import { jsx } from 'react/jsx-runtime';
import React from 'react';
import '../Breakpoint/index.js';
import { useForTablet } from '../Breakpoint/Breakpoint.js';

const SearchExpandedContext = React.createContext({
    searchExpanded: false,
    setSearchExpanded: () => { },
    filtersVisible: false,
    setFiltersVisible: () => { },
});
const useSearchExpanded = () => {
    const context = React.useContext(SearchExpandedContext);
    if (!context) {
        throw new Error('useSearchExpanded must be used within a SearchExpandedProvider');
    }
    return context;
};
/**
 * Stores some info needed to place all the components in the header between
 * different portals.
 */
const SearchExpandedProvider = ({ children }) => {
    const isTabletAndBigger = useForTablet();
    // We only want to hide the search by default for mobile views
    const isMobile = !isTabletAndBigger;
    const [searchExpanded, setSearchExpanded] = React.useState(isMobile ? true : false);
    const [filtersVisible, setFiltersVisible] = React.useState(false);
    const value = React.useMemo(() => ({
        searchExpanded,
        setSearchExpanded,
        filtersVisible,
        setFiltersVisible,
    }), [searchExpanded, setSearchExpanded, filtersVisible, setFiltersVisible]);
    return (jsx(SearchExpandedContext.Provider, Object.assign({ value: value }, { children: children })));
};

export { SearchExpandedProvider, useSearchExpanded };

import { createContext } from 'react';
import { defaultValues } from './settingsState.js';
import { Subscription } from './Subscription.js';

const DevToolsSettingsContext = createContext({
    settings: { current: defaultValues },
    setSettings: () => {
        throw new Error('DevToolsSettingsContext was not provided');
    },
    subscription: new Subscription(),
});

export { DevToolsSettingsContext };

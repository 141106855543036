import produce from 'immer'

import * as webhookCache from '../webhookCache'

import {
  useWebhookDestinationUpdateMutation,
  WebhookDestinationUpdateMutation,
  WebhookDestinationUpdateMutationVariables,
} from '@sketch/gql-types'

export interface UseUpdateWebhookDestinationProps {
  onCompleted?: (data: WebhookDestinationUpdateMutation) => void
}

export const useUpdateWebhookDestination = (
  props: UseUpdateWebhookDestinationProps
) => {
  const { onCompleted } = props
  const [mutation] = useWebhookDestinationUpdateMutation({
    onError: 'show-toast',
    onCompleted,
  })

  return async (variables: WebhookDestinationUpdateMutationVariables) => {
    return await mutation({
      variables,
      update: (cache, { data, errors }) => {
        if (!data || (errors && errors.length > 0)) return

        const { workspaceIdentifier } = variables
        const workspace = webhookCache.get(cache, workspaceIdentifier)
        if (!workspace) return

        const updatedWorkspace = produce(workspace, changeset => {
          changeset.webhookDestination = data.webhookDestinationUpdate
        })

        webhookCache.set(cache, workspaceIdentifier, updatedWorkspace)
      },
    })
  }
}

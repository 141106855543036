import { jsxs, jsx } from 'react/jsx-runtime';
import { useState } from 'react';
import { useToast } from '@sketch/toasts';
import { Modal } from '@sketch/components';
import './components/index.js';
import { useGenerateMfaRecoveryCodesMutation } from '@sketch/gql-types';
import { FirstStep } from './components/FirstStep.js';
import { SecondStep } from './components/SecondStep.js';
import { ThirdStep } from './components/ThirdStep.js';

const TOTAL_STEPS = 3;
/**
 *
 * This modal allows the user to regenerate recovery codes
 * for the 2FA
 *
 * Used in Account Settings
 *
 */
const RegenerateRecoveryCodesModal = ({ hideModal, }) => {
    const [step, setStep] = useState(1);
    const { showToast } = useToast();
    const [generateMfaRecoveryCodes, { loading, data },] = useGenerateMfaRecoveryCodesMutation({
        onCompleted: () => {
            nextStep();
        },
        onError: ({ message }) => {
            showToast(message, 'negative');
        },
    });
    // Move to the next step
    const nextStep = () => {
        setStep(prev => {
            if (prev >= TOTAL_STEPS) {
                return prev;
            }
            return prev + 1;
        });
    };
    const handleNextStep = (goToStep) => {
        // go to a particular step
        if (goToStep) {
            setStep(goToStep);
            return;
        }
        // The modal should close in the last step
        if (step === 3) {
            hideModal();
            return;
        }
        nextStep();
    };
    // Handles the password confirmation form submition
    const handleSubmit = ({ password }) => {
        generateMfaRecoveryCodes({
            variables: { password },
        });
    };
    const recoveryCodes = data === null || data === void 0 ? void 0 : data.generateMfaRecoveryCodes.recoveryCodes.entries;
    return (jsxs(Modal, Object.assign({ onCancel: hideModal }, { children: [step === 1 && jsx(FirstStep, { onNextStep: handleNextStep }), step === 2 && jsx(SecondStep, { onSubmit: handleSubmit, isLoading: loading }), step === 3 && (jsx(ThirdStep, { onNextStep: handleNextStep, recoveryCodes: recoveryCodes }))] })));
};

export { RegenerateRecoveryCodesModal };

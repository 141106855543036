import { __rest } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import '../Box/index.js';
import '../LoadingPlaceholder/index.js';
import { ReactComponent } from '@sketch/icons/checkmark-16';
import { Container, CheckboxInput, HiddenInput, CheckmarkIcon, CheckboxLabel, CheckboxLabelText, IconWrapper, LoadingWrapper, SuccessWrapper, Caption } from './Checkbox.styles.js';
import { Box } from '../Box/BoxSystem.js';
import { LoadingPlaceholder } from '../LoadingPlaceholder/LoadingPlaceholder.js';

/**
 * Checkbox primitive is a lightweight wrapper around a native checkbox with a custom style and some extra capabilities like help message.
 *
 * It should be used as a controlled component using `checked` and `onChange`.
 *
 */
const Checkbox = forwardRef((_a, ref) => {
    var { checked, children, disabled, className, label, help, loading, success, variant = 'primary' } = _a, props = __rest(_a, ["checked", "children", "disabled", "className", "label", "help", "loading", "success", "variant"]);
    children = children ? children : label;
    const as = children ? 'label' : 'div';
    return (jsxs(Container, Object.assign({ className: className, as: as, disabled: disabled }, { children: [jsx(Box, Object.assign({ opacity: disabled ? 0.5 : undefined }, { children: jsxs(CheckboxInput, Object.assign({ checked: checked, variant: variant, disabled: disabled, "aria-disabled": disabled }, { children: [jsx(HiddenInput, Object.assign({}, props, { disabled: disabled || loading, type: "checkbox", "aria-label": label, checked: checked, ref: ref })), checked && jsx(CheckmarkIcon, {})] })) })), jsxs(CheckboxLabel, Object.assign({ disabled: disabled !== null && disabled !== void 0 ? disabled : false }, { children: [children && jsx(CheckboxLabelText, { children: children }), (loading || success) && (jsxs(IconWrapper, { children: [loading && (jsx(LoadingWrapper, { children: jsx(LoadingPlaceholder, { size: "16px" }) })), success && (jsx(SuccessWrapper, { children: jsx(ReactComponent, { width: 16 }) }))] })), help && jsx(Caption, { children: help })] }))] })));
});
Checkbox.displayName = 'Checkbox';

export { Checkbox };

import { useSearch } from 'modules/shares/hooks/useSearch'
import { useSearchFilters } from 'modules/shares/hooks/useSearchFilters'

import { GetProjectCollectionsQueryVariables } from '@sketch/gql-types'

type SearchArgument = GetProjectCollectionsQueryVariables['search']

/*
 * Returns the value that should be used for the `search` argument when
 * requesting `project.collections` or `collections.shares` from the GraphQL
 * API.
 *
 * Since this value depends on several factors such as a feature flag and
 * search criteria, we extracted this hook to avoid repeating the code to
 * compute the search argument throughout the code-base.
 */
export const useCollectionSearchArgument = (): SearchArgument => {
  const { filters } = useSearchFilters()
  const { searchDebounced: searchTerm } = useSearch()

  const hasSearchCriteria = !!searchTerm || filters.length > 0

  if (hasSearchCriteria) {
    return { name: searchTerm ?? null, filters }
  }

  return undefined
}

import styled from 'styled-components'
import { Link } from '@sketch/components'

export const ContentLayout = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.C};
  align-items: flex-start;
`

export const ChildrenContainer = styled.div`
  padding: ${({ theme }) => theme.space[1]}px 0;
`

export const NoticeIcon = styled.svg`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 8px;
`
export const ActionContainer = styled.div`
  margin-top: ${({ theme }) => theme.space[3]}px;
`

export const LearnMoreLink = styled(Link)`
  color: var(--notice__link-color);
  font-size: ${({ theme }) => theme.fontSizes.B};
  /* Overwrite the default margin-left for links following a button */
  &&:not(:first-child) {
    margin-left: 11px; /* stylelint-disable-line scales/space */
  }

  &:hover {
    color: var(--notice__link-color-hover);
  }
`

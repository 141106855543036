import { jsxs, jsx } from 'react/jsx-runtime';
import styled from 'styled-components';
import '../Box/index.js';
import { Box, Text } from '../Box/BoxSystem.js';

const ErrorContainer = styled(Box) `
  box-sizing: border-box;
  margin: auto 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 100%;
  justify-content: center;
`;
const GenericErrorState = () => (jsxs(ErrorContainer, { children: [jsx(Text, Object.assign({ fontWeight: "semibold", color: "foreground.secondary.B", mb: 2 }, { children: "Something went wrong" })), jsxs(Text, Object.assign({ color: "foreground.secondary.C", mt: 2 }, { children: ["We\u2019re looking into the issue. Try again later or visit our", ' ', jsx("a", Object.assign({ href: "https://status.sketch.com/" }, { children: "Status Page" })), "."] }))] }));

export { ErrorContainer, GenericErrorState as default };

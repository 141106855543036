import React from 'react'
import {
  Button,
  Dropdown,
  Flex,
  LoadingPlaceholder,
  Modal,
  ModalInjectedProps,
  useModalContext,
} from '@sketch/components'
import { useGetAnnotationSummaryQuery } from '@sketch/gql-types'
import { useStableHandler } from '@sketch/utils'

interface SummarizeAnnotationButtonProps {
  annotationIdentifier: string
}

function SummarizeAnnotationButton(props: SummarizeAnnotationButtonProps) {
  const { annotationIdentifier } = props
  const { showModal } = useModalContext()

  const handleShowModal = useStableHandler(() => {
    showModal(AnnotationSummaryModal, {
      annotationIdentifier,
      body: 'Hello',
      resolution: 'Eat my shorts',
    })
  })

  return (
    <Dropdown.Item onClick={handleShowModal}>Summarize&hellip;</Dropdown.Item>
  )
}

type AnnotationSummaryModalProps = ModalInjectedProps & {
  body: string
  resolution: string
  annotationIdentifier: string
}

function AnnotationSummaryModal({
  hideModal,
  body,
  resolution,
  annotationIdentifier,
}: AnnotationSummaryModalProps) {
  const { data } = useGetAnnotationSummaryQuery({
    variables: { identifier: annotationIdentifier },
  })

  const summary = data?.annotation?.summary
  const isReady = typeof summary === 'object'

  return (
    <Modal title="Annotation Summary" onCancel={hideModal}>
      <Modal.Body>
        {!isReady ? (
          <Flex
            alignItems="end"
            width="100%"
            height="50px"
            justifyContent="center"
          >
            <LoadingPlaceholder />
          </Flex>
        ) : (
          <>
            <p>{data?.annotation?.summary?.body}</p>
            <p>
              <strong>
                Resolution: {data?.annotation?.summary?.resolution}
              </strong>
            </p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal}>OK</Button>
      </Modal.Footer>
    </Modal>
  )
}

export { SummarizeAnnotationButton }

import { jsx } from 'react/jsx-runtime';
import { useRef, useState, useCallback, useMemo } from 'react';
import { bannerContext } from './bannerContext.js';
import { uniqueId, noop } from '@sketch/utils';

const { Provider } = bannerContext;
const BannerProvider = ({ children }) => {
    const banners = useRef({});
    const [items, setItems] = useState([]);
    const dismissBanner = useCallback((id) => {
        var _a;
        setItems(items => items.filter(itemId => itemId !== id));
        (_a = banners.current[id]) === null || _a === void 0 ? void 0 : _a.onDismiss();
        delete banners.current[id];
    }, []);
    const showBanner = useCallback((parameters) => {
        const { message, type = 'information', id = uniqueId('banner'), dismissible = false, onDismiss = noop, } = parameters;
        banners.current[id] = {
            message,
            type,
            dismissible,
            onDismiss,
        };
        setItems(items => [...items, id]);
        return {
            id,
            dismissBanner: dismissBanner.bind(null, id),
        };
    }, [dismissBanner]);
    const memoizedValue = useMemo(() => {
        return {
            showBanner,
            dismissBanner,
            items: items.map(id => {
                const { message, type, dismissible } = banners.current[id];
                return {
                    id: id,
                    type,
                    dismissible,
                    message,
                };
            }),
        };
    }, [dismissBanner, items, showBanner]);
    return jsx(Provider, Object.assign({ value: memoizedValue }, { children: children }));
};

export { BannerProvider };

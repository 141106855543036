import { __awaiter } from 'tslib';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { localStorageKeys } from '@sketch/constants';
import { setItem } from '@sketch/utils';
import { useSignInWithSsoMutation, GetWorkspaceDocument } from '@sketch/gql-types';
import { useAsync } from 'react-use';
import { useFetchInitialUser, useQueryParams, useAnalytics, createSsoAuthorization, setUserAuthorization, replaceAllAuthorizations } from '@sketch/modules-common';
import { ErrorHandler } from '@sketch/tracing';
import { useApolloClient } from '@apollo/react-hooks';

const useSsoSuccessfulSignIn = () => {
    const { location } = useHistory();
    const fetchInitialUser = useFetchInitialUser();
    const [state, setState] = useState({ type: 'loading' });
    const queryParams = useQueryParams();
    const apolloClient = useApolloClient();
    const { trackEvent } = useAnalytics();
    const [signInWithSso] = useSignInWithSsoMutation({
        onError: () => {
            setState({ type: 'error' });
        },
        onCompleted: (data) => __awaiter(void 0, void 0, void 0, function* () {
            const workspaceId = data.signInWithSso.workspaceId;
            const credentials = data.signInWithSso.credentials;
            const newAuthorization = createSsoAuthorization(workspaceId, credentials);
            const previousUser = yield fetchInitialUser();
            setUserAuthorization(apolloClient.cache, newAuthorization);
            const newUser = yield fetchInitialUser();
            if (!newUser) {
                ErrorHandler.shouldNeverHappen(`Could not get user after successful SSO sign in`);
                setState({ type: 'error' });
                return;
            }
            /**
             * If we are trying to login with an entirely different user then we first
             * sign out from all other credentials and use the new one.
             */
            if ((previousUser === null || previousUser === void 0 ? void 0 : previousUser.identifier) !== newUser.identifier) {
                replaceAllAuthorizations(newAuthorization);
            }
            setItem(localStorageKeys.lastWorkspaceIdKey, workspaceId);
            if (queryParams.source === 'cloud-popup') {
                const { data } = yield apolloClient.query({
                    query: GetWorkspaceDocument,
                    variables: { identifier: workspaceId },
                });
                const workspaceName = data.workspace.name || '';
                setState({ type: 'success-cloud-popup', workspaceName });
            }
            else {
                setState({ type: 'success-cloud', from: queryParams.from });
            }
            trackEvent('Successful SignIn', { type: 'sso' });
        }),
    });
    const signInToCloudWithSso = (exchangeToken) => {
        signInWithSso({
            variables: { exchangeToken },
        });
    };
    useAsync(() => __awaiter(void 0, void 0, void 0, function* () {
        if (state.type !== 'loading')
            return;
        try {
            if (!queryParams.token) {
                setState({ type: 'error' });
                return;
            }
            switch (queryParams.source) {
                case 'sketch':
                    setState({
                        type: 'success-sketch-app',
                        exchangeToken: queryParams.token,
                    });
                    break;
                case 'cloud':
                default:
                    signInToCloudWithSso(queryParams.token);
                    break;
            }
        }
        catch (error) {
            setState({ type: 'error' });
        }
    }), [state, location]);
    return { state };
};

export { useSsoSuccessfulSignIn };

import styled from 'styled-components'

import { LinkButton, Link, Text, truncateStyles } from '@sketch/components'

import { VersionLink } from 'modules/versioning'

import { ReactComponent as ArrowRightCircleIcon } from '@sketch/icons/arrow-right-circle-24'
import { ReactComponent as CanvasPageSvg } from '@sketch/icons/canvas-page-16'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  padding: 28px 24px 16px;
`

export const StyledPageLink = styled(Link)`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.G};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};

  display: flex;
  align-items: center;
` as typeof Link

export const Title = styled(Text.H1).attrs({
  textStyle: 'copy.primary.standard.F',
})`
  margin: 0;
  overflow: hidden;
  font-weight: 500;
  line-height: 1.4;
`

export const StyledPageLinkLabel = styled.span`
  ${truncateStyles};
`

export const StyledArrowRightCircleIcon = styled(ArrowRightCircleIcon)`
  flex: none;
  height: 24px;
  vertical-align: top;
  margin: 0 8px;
  color: ${({ theme }) => theme.colors.sketch.A};
`

export const StyledPageTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px 20px;

  @media (hover: hover) {
    ${StyledArrowRightCircleIcon} {
      opacity: 0;
      transition: opacity ${({ theme }) => theme.transitions.duration[1]};
    }

    &:hover {
      ${StyledArrowRightCircleIcon} {
        opacity: 1;
      }
    }
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${Container}:not(:first-child) & {
    border-top: 1px solid ${({ theme }) => theme.colors.border.A};
    padding-top: 36px;
  }
`

export const StyledPageCanvasLink = styled(LinkButton).attrs({ size: '32' })`
  font-size: ${({ theme }) => theme.fontSizes.D};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  padding: 0 8px;
`

export const StyledVersionLink = styled(VersionLink)`
  display: block;
`

export const FullScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

export const StyledCanvasViewIcon = styled(CanvasPageSvg)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`

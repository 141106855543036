import styled, { css } from 'styled-components'

import { breakpoint, lightTheme as theme } from '@sketch/global-styles'

import {
  ButtonUnstyled,
  Avatar as BaseAvatar,
  Dropdown,
  Pill as BasePill,
} from '@sketch/components'

import { ReactComponent as ArrowRight } from '@sketch/icons/chevron-right-16'

/**
 * Button Elements
 */

export const Button = styled(ButtonUnstyled)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 8px;

  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  border-radius: 6px;
  transition: background-color 200ms ease-in-out;

  :hover,
  &[aria-expanded='true'] {
    background-color: ${({ theme }) => theme.colors.background.tertiary.C};
  }
`

export const UserName = styled.p`
  margin-right: 12px;
  font-size: ${({ theme }) => theme.fontSizes.E};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Avatar = styled(BaseAvatar)`
  flex-shrink: 0;
  margin-right: 12px;
`

export const Pill = styled(BasePill)`
  margin-left: auto;
  flex-shrink: 0;

  .active & {
    color: ${({ theme }) => theme.colors.sketch.A};
    background: ${({ theme }) => theme.colors.sketch.F};
  }
`

export const KeyWrapper = styled(BasePill)`
  margin-left: auto;

  ${Pill} + & {
    margin-left: 4px;
  }
`

/**
 * Dropdown Styles
 */

const commonDropdownItemStyles = css`
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 8px 12px;
  line-height: 1;

  ${({ theme }) => css`
    transition: opacity ${theme.transitions.duration[2]}
        ${theme.transitions.timing.easeInOut},
      background-color ${theme.transitions.duration[2]}
        ${theme.transitions.timing.easeInOut};

    color: ${theme.colors.foreground.secondary.A};
  `};

  :hover {
    opacity: 1;
  }

  ${breakpoint('base', 'xs')`
  opacity: 1;
`}
`

export const Icon = styled.svg<{ $isActive?: boolean }>`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.sketch.A : theme.colors.foreground.secondary.D};
`

export const ArrowIcon = styled(Icon).attrs({
  as: ArrowRight,
})`
  margin-right: 0;
`

export const StyledDropdownItem = styled(Dropdown.Item)<{
  $isActive?: boolean
  disabled?: boolean
}>(
  ({ $isActive, disabled }) => css`
    ${commonDropdownItemStyles}

    background-color: ${$isActive ? theme.colors.sketch.F : undefined};
    color: ${$isActive ? theme.colors.sketch.A : undefined};
    opacity: ${disabled ? 0.5 : 1};
    cursor: pointer;
  `
)

export const StyledDropdownItemNavLink = styled(Dropdown.Item.NavLink)`
  ${commonDropdownItemStyles}

  &.active {
    ${Icon} {
      color: ${({ theme }) => theme.colors.sketch.A};
    }
  }
`

export const DropdownHeader = styled.div`
  padding: 8px 16px 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const DropdownUserName = styled.span`
  display: block;

  font-size: ${({ theme }) => theme.fontSizes.D};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  line-height: 1.462; /* stylelint-disable-line scales/line-height */

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const DropdownUserEmail = styled.span`
  display: block;

  font-size: ${({ theme }) => theme.fontSizes.C};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  line-height: 1.333; /* stylelint-disable-line scales/line-height */

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ToggleWrapper = styled.div`
  width: 100%;
  padding: 8px;
`

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { SKETCH_WEBSITE } from '@sketch/env-config';
import { useQueryParams, getIsForInAppPresentation, sourceToSsoSignInSource, IndexLayoutContent, IndexLayoutOldTitle, IndexLayoutOldSubtitle, routes } from '@sketch/modules-common';
import { Link } from '@sketch/components';
import SsoSignInForm from './SsoSignInForm.js';
import { Wrapper, SsoInformationAndFaqWrapper, StyledLink, Line, Text } from './SsoSignInForCloud.styles.js';

const SsoSignInForCloud = props => {
    var _a, _b;
    const { state } = props.location;
    const { source = 'cloud', team, shortname } = useQueryParams();
    const isForInAppPresentation = getIsForInAppPresentation();
    const teamName = team || shortname;
    const formSource = isForInAppPresentation
        ? 'sketch'
        : (_a = sourceToSsoSignInSource[source]) !== null && _a !== void 0 ? _a : 'cloud';
    const target = isForInAppPresentation ? '_self' : '_blank';
    return (jsx(IndexLayoutContent, Object.assign({ center: "horizontal", marginTop: true, paddingHorizontal: true }, { children: jsxs(Wrapper, { children: [jsx(IndexLayoutOldTitle, { children: "Enter SSO Shortname" }), jsx(IndexLayoutOldSubtitle, { children: "Enter your Workspace's single sign-on (SSO) name" }), jsx(SsoSignInForm, { source: formSource, teamName: teamName, from: (_b = state === null || state === void 0 ? void 0 : state.from) === null || _b === void 0 ? void 0 : _b.pathname }), jsxs(SsoInformationAndFaqWrapper, { children: [jsx(StyledLink, Object.assign({ href: `${SKETCH_WEBSITE}/redirect/web/sso`, target: target, rel: "noopener noreferrer", variant: "primary", isUnderlined: true, external: true }, { children: "How Single Sign-On (SSO) works" })), !isForInAppPresentation && (jsxs(Fragment, { children: [jsx(StyledLink, Object.assign({ variant: "primary", isUnderlined: true, to: { pathname: routes.SIGN_IN.create({}), state } }, { children: "Sign in with email and password" })), jsx(Line, {}), jsxs(Text, { children: ["New to Sketch?", ' ', jsx(Link, Object.assign({ variant: "primary", isUnderlined: true, to: {
                                                pathname: routes.SIGN_UP.create({}),
                                                state,
                                            } }, { children: "Create an account" }))] })] }))] })] }) })));
};

export { SsoSignInForCloud };

import { useMemo } from 'react';
import './telemetry/index.js';
import './plausible/index.js';
import './simple-analytics/index.js';
import './pageLoad/index.js';
import { customElementsObserver } from './pageLoad/elementTiming/CustomElementsObserver.js';
import { _trackEvent } from './plausible/analytics.js';
import { trackMetric } from './telemetry/telemetry.js';
import { trackSimpleAnalytics, removeSimpleAnalytics, trackSimpleEvent } from './simple-analytics/analytics.js';

const useGetAnalyticsContext = () => {
    const context = useMemo(() => ({
        pageLoad: {
            observeCustomElement: customElementsObserver.pushEntry,
        },
        trackEvent: _trackEvent,
        trackMetric,
        trackSimpleAnalytics,
        removeSimpleAnalytics,
        trackSimpleEvent,
    }), 
    /**
     * We want to make sure that analytics properties do not change between rerenders.
     * Though, we are OK that reference objects passed to those function would change.
     *
     * Doing this we make sure that `useAnalytics` would always give the same reference
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    return context;
};

export { useGetAnalyticsContext };

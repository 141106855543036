import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent } from '@sketch/icons/spinner-20';

const ANIMATION_TIME = 800;
const spin = keyframes `
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
`;
const StyledSpinner = styled(ReactComponent) `
  width: 20px;
  height: 20px;
  display: inline-block;
  animation: ${spin} ${ANIMATION_TIME}ms linear infinite;
  animation-delay: ${({ $mountDelay }) => $mountDelay}ms;

  color: ${({ theme, $primary }) => $primary ? theme.colors.sketch.A : 'white'};
`;
StyledSpinner.defaultProps = {
    'data-testid': 'spinner',
};
const Spinner = (_a) => {
    var { style, size = '22px', primary = false } = _a, props = __rest(_a, ["style", "size", "primary"]);
    /**
     * We capture when this component was mounted. This way we can apply a delay
     * to the animation based on that time and make all spinners in the app to be
     * in sync.
     *
     * Imagine animation lasts 100ms:
     *
     * 0                 100ms     150ms    200ms
     * | ----------------- | ----------------- |
     *                               |
     *                             mount
     *
     * We configure the animation to start on the previous cycle: 100ms, so we
     * set the animation-delay to -50ms. Finally, we use negative numbers to make
     * the component animate right away (instead of waiting for the next cycle).
     */
    const mountTime = React.useRef(Date.now());
    const mountDelay = -(mountTime.current % ANIMATION_TIME);
    return (jsx(StyledSpinner, Object.assign({ "data-testid": "spinner", "$primary": primary, "$mountDelay": mountDelay }, props, { style: Object.assign({ width: size, height: size }, style) })));
};

export { Spinner };

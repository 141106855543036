import { jsx, jsxs } from 'react/jsx-runtime';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { useSetUserAuthorization, routes } from '@sketch/modules-common';
import { Form, Input, Button } from '@sketch/components';
import { useValidateMfaRecoveryCodeMutation } from '@sketch/gql-types';
import { useToast } from '@sketch/toasts';

// Form validation
const validationSchema = yup.object().shape({
    code: yup.string().required('Enter a recovery code'),
});
const RecoveryCodeForm = ({ onCompleted }) => {
    const history = useHistory();
    const location = useLocation();
    const { showToast } = useToast();
    const setUserAuthorization = useSetUserAuthorization();
    const [validateRecoveryCode, { loading },] = useValidateMfaRecoveryCodeMutation({
        onCompleted: data => {
            setUserAuthorization(data.validateMfaRecoveryCode.credentials);
            onCompleted();
        },
        onError: error => {
            // TODO: Improve returned error types from local resolvers
            // https://github.com/sketch-hq/Cloud/issues/11366
            const mfaError = error.message;
            showToast(mfaError.message, 'negative');
            if (mfaError.type === 'invalid_mfa_token') {
                history.push(routes.SIGN_IN.create({}), undefined);
            }
        },
    });
    const handleSubmit = (recoveryCode) => {
        validateRecoveryCode({
            variables: {
                token: location.state.mfaToken,
                recoveryCode,
            },
        });
    };
    return (jsx(Formik, Object.assign({ initialValues: { code: '' }, onSubmit: ({ code }) => handleSubmit(code), validationSchema: validationSchema }, { children: ({ values, touched, errors, handleChange, handleBlur }) => (jsxs(Form, { children: [jsx(Form.Field, Object.assign({ name: "code", errorText: touched.code ? errors.code : undefined }, { children: jsx(Input, { type: "text", name: "code", placeholder: "Enter recovery code", value: values.code, onChange: handleChange, onBlur: handleBlur, autoFocus: true }) })), jsx(Form.Footer, { children: jsx(Button, Object.assign({ variant: "primary-untinted", type: "submit", fill: true, size: "40", disabled: loading, loading: loading }, { children: "Submit" })) })] })) })));
};

export { RecoveryCodeForm as default };

import React from 'react'
import { useKey } from 'react-use'
import { Route, useRouteMatch } from 'react-router'

import {
  routes,
  isWorkspaceRoute,
  isProjectRoute,
  isProjectTrashRoute,
  isDraftsRoute,
} from '@sketch/modules-common'
import { shortcuts, keyWithoutModifier } from '@sketch/utils'

import { useSearchFilters } from 'modules/shares/hooks/useSearchFilters'
import { useSortingSettings } from 'modules/shares/hooks/useSortSettings'
import { useHideCollectionsSetting } from 'modules/collections/hooks'

import { SearchBar } from '../SearchBarOld'
import { SortDropdown } from '../SortDropdown'
import {
  Box,
  FakeLinkButton,
  Flex,
  Navbar,
  NavigationSelector,
  Tooltip,
  useForTablet,
  useResponsiveDropdown,
} from '@sketch/components'
import { ReactComponent as BulletList } from '@sketch/icons/bullet-list-24'
import { ReactComponent as SquareGrid } from '@sketch/icons/square-grid-24'

// GraphQL
import {
  useGetDocumentListSettingsQuery,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'
// Here DocumentLayout are used in non exhaustive way
// therefore build should not break after SketchQL deployments
// eslint-disable-next-line no-restricted-imports
import { DocumentLayout } from '@sketch/gql-types/expansive'

import {
  SearchBarWrapper,
  ViewAsText,
  Divider,
  SortIcon,
  SortButton,
} from './ShareNavbar.styles'

const NavbarSection = Navbar.Section

const DOCUMENT_LAYOUT = [
  {
    type: 'icon' as const,
    label: 'Grid Layout',
    value: 'GRID' as const,
    icon: SquareGrid,
    shortcut: shortcuts.gridView,
  },
  {
    type: 'icon' as const,
    label: 'List Layout',
    value: 'LIST' as const,
    icon: BulletList,
    shortcut: shortcuts.listView,
  },
]

const ResetFiltersButton: React.FC<{}> = () => {
  const { filters, setFilters } = useSearchFilters()
  const hasFilters = filters.length > 0

  const { data, updateQuery } = useGetDocumentListSettingsQuery()
  const { showDocumentsInProjects } = data || {}

  const { hideCollections, setHideCollections } = useHideCollectionsSetting()

  const { path } = useRouteMatch()

  const canResetShowDocumentsInProjects =
    showDocumentsInProjects === false && isWorkspaceRoute(path)

  const canResetHideCollections =
    hideCollections === true &&
    (isProjectRoute(path) || isProjectTrashRoute(path) || isDraftsRoute(path))

  const reset = () => {
    setFilters([])

    if (canResetShowDocumentsInProjects) {
      updateQuery(previous => ({
        ...previous,
        showDocumentsInProjects: true,
      }))
    }

    if (canResetHideCollections) {
      setHideCollections(false)
    }
  }

  if (
    !hasFilters &&
    !canResetShowDocumentsInProjects &&
    !canResetHideCollections
  ) {
    return null
  }

  return (
    <FakeLinkButton onClick={reset} size={'tiny'} isUnderlined>
      Reset
    </FakeLinkButton>
  )
}

const SortSettings: React.FC<{}> = () => {
  const [sortDropdownContent, sortDropdownButtonProps] = useResponsiveDropdown({
    dropdown: SortDropdown,
    placement: 'bottom',
    hide: ['clickedOutsideContent'],
    usePortal: true,
  })
  const { sortLabel } = useSortingSettings()

  return (
    <Route
      path={[
        routes.WORKSPACE_SHARES.template(),
        routes.WORKSPACE_DRAFTS.template(),
        routes.WORKSPACE_LIBRARIES.template(),
        routes.WORKSPACE_SHARED_WITH_ME.template(),
        routes.WORKSPACE_TEMPLATES.template(),
        routes.WORKSPACE_PROJECT.template(),
        routes.WORKSPACE_COLLECTION.template(),
      ]}
    >
      <Flex mr={2}>
        <Tooltip content={`Sort by: ${sortLabel}`} placement="bottom">
          <SortButton data-testid="sort-dropdown" {...sortDropdownButtonProps}>
            <SortIcon />
          </SortButton>
        </Tooltip>
      </Flex>
      {sortDropdownContent}
      <Box width={24} height={24}>
        <Divider />
      </Box>
    </Route>
  )
}

interface ShareNavbarProps {
  userRole: WorkspaceMinimalFragment['userRole']
  title?: React.ReactNode
  showViewOptions?: boolean
}

const ShareNavbar: React.FC<ShareNavbarProps> = props => {
  const { userRole, title, showViewOptions = true } = props
  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  const { data, updateQuery } = useGetDocumentListSettingsQuery()
  const { documentsLayout } = data || {}

  /**
   * Manager shortcuts:
   * - Search (/)
   * - View as Grid ([)
   * - View as List (])
   */
  useKey(keyWithoutModifier(shortcuts.search), function () {
    const inputElement = document!.querySelector(
      'input[name="search"]'
    ) as HTMLInputElement

    // Needed to fire `focus` after the keydown, that avoids filling the search
    // with `/`
    setTimeout(function () {
      inputElement?.focus()
    }, 0)
  })

  useKey(
    keyWithoutModifier([shortcuts.gridView, shortcuts.listView]),
    function (event) {
      const radioElements = document!.querySelectorAll(
        'input[name="document layout"]'
      ) as NodeListOf<HTMLInputElement>

      const key = event.key as
        | typeof shortcuts.gridView
        | typeof shortcuts.listView
      const viewMode = DOCUMENT_LAYOUT.find(layout => layout.shortcut === key)

      if (!viewMode) {
        return
      }

      const radioElement = Array.from(radioElements).find(
        el => el.value === viewMode.value
      )

      if (radioElement) {
        updateQuery(previous => ({
          ...previous,
          documentsLayout: viewMode.value as DocumentLayout,
        }))
      }
    }
  )

  return (
    <>
      <NavbarSection align="start">
        {isTabletAndBigger ? (
          <>
            <SearchBarWrapper>
              <SearchBar userRole={userRole} />
            </SearchBarWrapper>
            <ResetFiltersButton />
          </>
        ) : (
          title
        )}
      </NavbarSection>

      {showViewOptions ? (
        <NavbarSection align="end">
          <>
            <SortSettings />

            {isTabletAndBigger && <ViewAsText>View as</ViewAsText>}

            <NavigationSelector
              name="document layout"
              items={DOCUMENT_LAYOUT}
              selected={documentsLayout}
              hideTooltip={isMobile}
              onChange={({ value }) => {
                updateQuery(previous => ({
                  ...previous,
                  documentsLayout: value as DocumentLayout,
                }))
              }}
            />
          </>
        </NavbarSection>
      ) : null}
    </>
  )
}

export default ShareNavbar

import React, { useEffect, useMemo } from 'react'

import { routes, useFlag, useGetMyDraftsProject } from '@sketch/modules-common'

import {
  Dropdown,
  LoadingPlaceholder,
  useModalContext,
  ToastLink,
  SelectDropdownSubMenu,
  useForTablet,
} from '@sketch/components'
import {
  MoveShareToProjectModal,
  RemoveShareFromProjectModal,
} from 'modules/projects'
import { MoveShareToCollectionModal } from 'modules/collections/modals/MoveShareToCollectionModal'
import {
  Chevron,
  SubMenuItem,
  SubMenuTrigger,
} from './ManageProjectAndCollectionActions.styles'

import { useRemoveSharesFromCollection } from 'modules/collections/operations'
import { useToast } from '@sketch/toasts'

// GraphQL
import {
  useGetShareCollectionLazyQuery,
  useGetProjectCollectionsForSelectQuery,
  CollectionForSelectFragment,
  ProjectFragment,
  ShareListItemFragment,
  useGetWorkspaceQuery,
  useGetWorkspacesDraftsProjectsQuery,
} from '@sketch/gql-types'

import { ErrorHandler } from '@sketch/tracing'

import { ReactComponent as PlusIcon } from '@sketch/icons/plus-16'
import { ReactComponent as ExclamationMark } from '@sketch/icons/exclamation-mark-16'
import { CreateCollectionModal } from 'modules/collections/modals'
import { MoveShareToMyDrafts } from 'modules/projects/modals/MoveShareToMyDrafts'

interface Share
  extends Pick<
    ShareListItemFragment,
    | 'identifier'
    | 'name'
    | 'project'
    | 'userCanUpdateSettings'
    | 'publicAccessLevel'
    | 'type'
  > {}

const MoveToProjectAction: React.FC<{ share: Share }> = ({ share }) => {
  const { showModal } = useModalContext()
  return (
    <Dropdown.Item
      onClick={() =>
        showModal(MoveShareToProjectModal, {
          share,
          currentProject: share.project,
        })
      }
    >
      Move To&hellip;
    </Dropdown.Item>
  )
}

const RemoveFromProjectAction: React.FC<{
  share: Share
  // When the 'my-drafts-revamp' FF is removed, this prop can be required
  workspaceIdentifier?: string
}> = ({ share, workspaceIdentifier }) => {
  // Remove FF when its released
  // https://github.com/sketch-hq/Cloud/issues/18785
  const hasMyDraftsRevampFFEnabled = useFlag('my-drafts-revamp')

  const { showModal } = useModalContext()

  const draftsProject = useGetMyDraftsProject(workspaceIdentifier!, {
    // When the 'my-drafts-revamp' FF is removed, we can remove the skip
    skip: !hasMyDraftsRevampFFEnabled || !workspaceIdentifier,
  })

  const isInMyDrafts = share.project?.identifier === draftsProject?.identifier

  const text = isInMyDrafts ? (
    <>Remove from My Drafts&hellip;</>
  ) : (
    <>Remove from Project&hellip;</>
  )

  return (
    <Dropdown.Item
      onClick={() => showModal(RemoveShareFromProjectModal, { share })}
    >
      {text}
    </Dropdown.Item>
  )
}

const SingleLevelActions: React.FC<{ share: Share }> = ({ share }) => (
  <>
    <MoveToProjectAction share={share} />
    {share.project && <RemoveFromProjectAction share={share} />}
  </>
)

export const CollectionsLoadingState = () => (
  <SubMenuItem
    text="Loading collections…"
    icon={({ className }) => (
      <LoadingPlaceholder className={className} size="16px" />
    )}
    disabled
  />
)

export const CollectionsErrorState = () => (
  <SubMenuItem
    text="Unable to load collections"
    icon={({ className }) => <ExclamationMark className={className} />}
    disabled
  />
)

interface MoveSubMenuProps {
  share: Share
  project: ProjectFragment | null
  workspaceIdentifier: string
  currentCollection?: CollectionForSelectFragment | null
}

const MoveSubMenu: React.FC<MoveSubMenuProps> = ({
  share,
  project,
  workspaceIdentifier,
  currentCollection,
}) => {
  const { showModal } = useModalContext()
  // Remove FF when its released
  // https://github.com/sketch-hq/Cloud/issues/18785
  const hasMyDraftsRevampFFEnabled = useFlag('my-drafts-revamp')

  const isTabletAndBigger = useForTablet()

  const draftsProject = useGetMyDraftsProject(workspaceIdentifier, {
    skip: !hasMyDraftsRevampFFEnabled,
  })

  const { data: workspaceData } = useGetWorkspaceQuery({
    variables: { identifier: workspaceIdentifier },
  })

  const hasDraftsProject = !!draftsProject

  const { loading, error, data } = useGetProjectCollectionsForSelectQuery({
    variables: { projectIdentifier: project?.identifier ?? '' },
    skip: !project,
  })

  const isAdmin = workspaceData?.workspace.userRole === 'ADMIN'

  const { data: workspacesDrafsProjects } = useGetWorkspacesDraftsProjectsQuery(
    {
      skip: !hasMyDraftsRevampFFEnabled || !isAdmin,
    }
  )

  // We check if there are other Workspaces the user can move the draft to
  const workspaces = useMemo(() => {
    return (
      workspacesDrafsProjects?.me.workspaces.filter(workspace => {
        const isEditor = workspace.userCanEdit
        const isActive = workspace.status === 'ACTIVE'

        if (!isEditor || !isActive) {
          return false
        }

        return true
      }, []) ?? []
    )
  }, [workspacesDrafsProjects?.me.workspaces])

  // If there's only a single workspace available, we hide the My Drafts option
  // because the user cannot  move a my drafts project to itself
  const singleWorkspaceDraftsAvailable = workspaces.length === 1

  const handleCreateCollection = () => {
    if (!project) {
      return
    }

    showModal(CreateCollectionModal, {
      project,
      workspaceIdentifier,
      shareIdentifier: share.identifier,
    })
  }

  const collections =
    data?.project.collectionsUnpaginated
      ?.filter(({ identifier }) => identifier !== currentCollection?.identifier)
      .map(
        collection =>
          ({
            __typename: 'Collection',
            identifier: collection.identifier,
            name: collection.name,
          } as CollectionForSelectFragment)
      ) ?? []

  return (
    <SelectDropdownSubMenu
      data-hassublevels={true}
      value="Move"
      usePortal
      breakpoint="sm"
      headerItem={!isTabletAndBigger && <Dropdown.Header>Move</Dropdown.Header>}
      renderTrigger={props => (
        <SubMenuTrigger
          text="Move"
          data-testid="move-submenu"
          additional={<Chevron />}
          data-hassublevels={true}
          {...props}
        />
      )}
      items={['MY_DRAFTS', 'PROJECT', 'COLLECTION']}
      renderItem={(item, props) => {
        if (item === 'PROJECT') {
          return (
            <SubMenuItem
              text="To Project…"
              data-hassublevels={true}
              onClick={() => {
                showModal(MoveShareToProjectModal, {
                  share,
                  currentProject: share.project,
                })
              }}
            />
          )
        }

        if (item === 'MY_DRAFTS') {
          const isInMyDrafts =
            share.project?.identifier === draftsProject?.identifier

          // Hide the My Drafts option if:
          // - there's not a Drafts Project
          // - the document is already in My Drafts and the user is not an Admin
          // - the document is already in My Drafts and the user is IS an Admin but there are no other workspaces to move to
          if (
            !hasDraftsProject ||
            (isInMyDrafts && !isAdmin) ||
            (isInMyDrafts && isAdmin && singleWorkspaceDraftsAvailable)
          ) {
            return
          }

          return (
            <SubMenuItem
              text="To My Drafts…"
              data-hassublevels={true}
              onClick={() => {
                showModal(MoveShareToMyDrafts, {
                  workspaceIdentifier,
                  share,
                  draftsProject,
                })
              }}
            />
          )
        }

        if (loading) {
          return <CollectionsLoadingState />
        }

        if (error) {
          return <CollectionsErrorState />
        }

        if (collections.length) {
          return (
            <SubMenuItem
              text="To Collection…"
              data-hassublevels={true}
              onClick={() => {
                if (!project) {
                  return
                }

                showModal(MoveShareToCollectionModal, {
                  share,
                  collections,
                  handleCreateCollection,
                  project,
                  workspaceIdentifier,
                  currentCollection,
                })
              }}
            />
          )
        }

        if (project) {
          return (
            <SubMenuItem
              onClick={handleCreateCollection}
              text="Create Collection…"
              icon={PlusIcon}
            />
          )
        }

        return null
      }}
    />
  )
}

interface RemoveSubMenuProps {
  share: Share
  project: ProjectFragment
  collection: CollectionForSelectFragment
  workspaceIdentifier: string
}

const RemoveSubMenu: React.FC<RemoveSubMenuProps> = ({
  share,
  project,
  collection,
  workspaceIdentifier,
}) => {
  const { showModal } = useModalContext()
  const { showToast } = useToast()
  const isTabletAndBigger = useForTablet()

  const removeSharesFromCollection = useRemoveSharesFromCollection({
    projectIdentifier: project.identifier,
    onCompleted: () => {
      showToast(
        <>
          Document moved to &ldquo;
          <ToastLink
            to={routes.WORKSPACE_PROJECT.create({
              projectId: project.identifier,
              workspaceId: workspaceIdentifier,
            })}
          >
            {project.name}
          </ToastLink>
          &rdquo;
        </>
      )
    },
  })

  return (
    <SelectDropdownSubMenu
      value="Remove"
      usePortal={true}
      breakpoint="sm"
      headerItem={
        !isTabletAndBigger && <Dropdown.Header>Remove</Dropdown.Header>
      }
      renderTrigger={props => (
        <SubMenuTrigger text="Remove" additional={<Chevron />} {...props} />
      )}
      items={['PROJECT', 'COLLECTION']}
      renderItem={(item, props) => {
        if (item === 'PROJECT') {
          return (
            <SubMenuItem
              text="From Project…"
              onClick={() =>
                showModal(RemoveShareFromProjectModal, { share, collection })
              }
            />
          )
        }

        return (
          <SubMenuItem
            text="From Collection"
            onClick={() => {
              removeSharesFromCollection({
                variables: {
                  identifier: collection.identifier,
                  shareIdentifiers: [share.identifier],
                },
              })
            }}
          />
        )
      }}
    />
  )
}

interface ManageProjectAndCollectionActionsProps {
  share: Share
  workspaceIdentifier: string
}

const ManageProjectAndCollectionActions: React.FC<ManageProjectAndCollectionActionsProps> = ({
  share,
  workspaceIdentifier,
}) => {
  // Remove FF when its released
  // https://github.com/sketch-hq/Cloud/issues/18785
  const hasMyDraftsRevampFFEnabled = useFlag('my-drafts-revamp')

  const [
    getCollection,
    { data, error, loading },
  ] = useGetShareCollectionLazyQuery()

  const shareIdentifier = share.identifier

  useEffect(() => {
    getCollection({ variables: { shareIdentifier } })
  }, [getCollection, shareIdentifier])

  if (loading) {
    const loadingCount = share.project ? 2 : 1
    return <Dropdown.Skeleton count={loadingCount} />
  }

  if (error) {
    ErrorHandler.shouldNeverHappen(
      'Fetching share.collection should never fail'
    )

    return <SingleLevelActions share={share} />
  }

  const maybeProject = share.project
  const maybeCollection = data?.share?.collection

  if (!hasMyDraftsRevampFFEnabled) {
    if (maybeProject) {
      return (
        <>
          <MoveSubMenu
            share={share}
            project={maybeProject}
            workspaceIdentifier={workspaceIdentifier}
            currentCollection={maybeCollection}
          />
          {maybeCollection ? (
            <RemoveSubMenu
              share={share}
              project={maybeProject}
              collection={maybeCollection}
              workspaceIdentifier={workspaceIdentifier}
            />
          ) : (
            <RemoveFromProjectAction share={share} />
          )}
        </>
      )
    }

    return <SingleLevelActions share={share} />
  }

  return (
    <>
      <MoveSubMenu
        share={share}
        project={maybeProject}
        workspaceIdentifier={workspaceIdentifier}
        currentCollection={maybeCollection}
      />
      {maybeProject && maybeCollection && (
        <RemoveSubMenu
          share={share}
          project={maybeProject}
          collection={maybeCollection}
          workspaceIdentifier={workspaceIdentifier}
        />
      )}
      {maybeProject && (
        <RemoveFromProjectAction
          share={share}
          workspaceIdentifier={workspaceIdentifier}
        />
      )}
    </>
  )
}

export default ManageProjectAndCollectionActions

import styled, { css } from 'styled-components';
import '../Box/index.js';
import { breakpoint } from '@sketch/global-styles';
import { Flex } from '../Box/BoxSystem.js';

const NavbarSection = styled(Flex) `
  display: flex;
  min-width: 0;
  height: 100%;
  min-height: 100%;
  align-items: center;
  flex: 1 0px;
  justify-content: ${({ align = 'start' }) => align === 'center' ? 'center' : `flex-${align}`};
`;
const NavbarExtendedBase = styled.div(({ theme, isSidebarLeftOpen, fixed, singleContent }) => css `
    display: flex;
    height: ${theme.header.height}px;
    position: relative;

    & & {
      display: flex;
      padding: 0;
      margin: 0;
    }

    width: 100%;
    align-items: center;
    justify-content: ${singleContent ? 'flex-end' : 'space-between'};
    background: ${theme.colors.background.secondary.B};

    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    transition-property: transform, opacity;

    transform: ${isSidebarLeftOpen
    ? `translateX(${theme.sidebar.leftWidth}px)`
    : undefined};

    /* For mobile, when the sidebar is opened we also need to move the banner (if there is any) */
    & + aside[role='alert'] {
      transition-timing-function: ease-in-out;
      transition-duration: 0.3s;
      transition-property: transform, opacity;

      transform: ${isSidebarLeftOpen
    ? `translateX(${theme.sidebar.leftWidth}px)`
    : undefined};
    }

    &:empty {
      display: none;
    }

    ${breakpoint('sm') `
      display: block;
      height: auto;
      padding: 76px 52px 0; /* stylelint-disable-line scales/space */
      transform: none;
    `};

    ${fixed &&
    css `
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
    `};
  `);

export { NavbarExtendedBase, NavbarSection };

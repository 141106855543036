import React from 'react'
import {
  OptionPill,
  RemoveIcon,
  DateIcon,
  DocumentIcon,
  UserIcon,
  ProjectIcon,
} from './SearchOption.styles'
import { SearchOptionsObject, NotificationType } from '../types'

type OptionKeys = keyof SearchOptionsObject

const OptionLabels: { [K in OptionKeys]: string } = {
  comments: 'Comments',
  downloadableAssets: 'Downloadable Assets',
  replies: 'Replies',
  mentions: 'Mentions',
  starredUpdates: 'Starred Updates',
  from: 'from',
  to: 'to',
  location: 'location',
  users: 'user',
}

interface OptionProps {
  options: SearchOptionsObject
  setOptions: (options: SearchOptionsObject) => void
}

export const OptionNotificationType: React.FC<
  OptionProps & { type: NotificationType }
> = ({ options, setOptions, type }) => {
  if (options[type] === false) {
    return null
  }

  const handleRemoveType = (type: NotificationType) => {
    setOptions({
      ...options,
      [type]: false,
    })
  }

  return (
    <OptionPill className="searchOption" onClick={() => handleRemoveType(type)}>
      {OptionLabels[type]}
      <RemoveIcon />
    </OptionPill>
  )
}

export const OptionDate: React.FC<OptionProps> = ({ options, setOptions }) => {
  const { to, from } = options

  if (from === '' && to === '') {
    return null
  }

  const handleRemove = () => {
    setOptions({
      ...options,
      to: '',
      from: '',
    })
  }

  const isSingleDate = from === to || to === '' || from === ''

  return (
    <OptionPill className="searchOption" onClick={() => handleRemove()}>
      <DateIcon />
      {from}
      {!isSingleDate && ' to '}
      {to !== from && to}
      <RemoveIcon />
    </OptionPill>
  )
}

export const OptionLocation: React.FC<OptionProps> = ({
  options,
  setOptions,
}) => {
  if (options.location === null) {
    return null
  }

  const { location } = options

  const handleRemoveType = () => {
    setOptions({
      ...options,
      location: null,
    })
    return
  }

  return (
    <>
      <OptionPill
        className="searchOption"
        key={location.identifier}
        onClick={handleRemoveType}
      >
        {location.type === 'project' ? <ProjectIcon /> : <DocumentIcon />}{' '}
        {location.name}
        <RemoveIcon />
      </OptionPill>
    </>
  )
}

export const OptionUser: React.FC<OptionProps> = ({ options, setOptions }) => {
  if (options.users.length === 0) {
    return null
  }

  const { users } = options

  const handleRemoveType = ({ identifier }: { identifier?: string }) => {
    setOptions({
      ...options,
      users: users.filter(item => item.identifier !== identifier),
    })
    return
  }

  return (
    <>
      {users.map(item => (
        <OptionPill
          className="searchOption"
          key={item.identifier}
          onClick={() => handleRemoveType({ identifier: item.identifier })}
        >
          <UserIcon /> {item.name}
          <RemoveIcon />
        </OptionPill>
      ))}
    </>
  )
}

import { __awaiter } from 'tslib';
import { createContext } from 'react';

const BASE_STATE = {
    load: () => __awaiter(void 0, void 0, void 0, function* () {
        // To be consistent with the load return type
        // we opted to throw an error here in an async function
        throw new Error('Stripe context failed to initialize');
    }),
    status: 'pending',
};
const StripeContext = createContext(BASE_STATE);

export { BASE_STATE, StripeContext };

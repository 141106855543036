import { __rest } from 'tslib';
import { jsx, jsxs } from 'react/jsx-runtime';
import React from 'react';
import warning from 'warning';
import styled from 'styled-components';
import { dasherize } from '@sketch/utils';
import '../FormLabel/index.js';
import '../FormError/index.js';
import '../../Box/index.js';
import { ErrorList, HelpTextContainer, HelpText } from './FormField.styles.js';
import { FormError } from '../FormError/FormError.js';
import { FormLabel } from '../FormLabel/FormLabel.js';
import { withBox } from '../../Box/withBox.js';

// Helper functions
const renderErrorTextArray = (errorTextArray) => (jsx(ErrorList, { children: errorTextArray.map((errorText, i) => (jsx("li", { children: jsx(FormError, { children: errorText }) }, i))) }));
const renderErrorText = (errorText) => errorText ? renderErrorTextArray([errorText]) : null;
// Unstyled FormField
const FormFieldBase = (_a) => {
    var { id, name, label, errorText, help, children, srOnlyLabel, hasError } = _a, props = __rest(_a, ["id", "name", "label", "errorText", "help", "children", "srOnlyLabel", "hasError"]);
    warning(id || name, 'Not enough information to generate a unique id. Please provide an id or a name.');
    const isInvalid = (errorText && errorText.length > 0) || hasError;
    const inputId = dasherize(id || name) + '-input'; // use id or name to generate unique id for input element
    const renderChild = (child) => React.cloneElement(child, {
        id: child.id || inputId,
        invalid: isInvalid,
    });
    return (jsxs("div", Object.assign({}, props, { children: [label && (jsx(FormLabel, Object.assign({ htmlFor: inputId }, (srOnlyLabel && { className: 'sr-only' }), { children: label }))), children && React.Children.map(children, renderChild), errorText &&
                (Array.isArray(errorText)
                    ? renderErrorTextArray(errorText)
                    : renderErrorText(errorText)), help && (jsx(HelpTextContainer, { children: jsx(HelpText, { children: help }) }))] })));
};
const FormField = styled(withBox(FormFieldBase)) `
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
`;
FormField.defaultProps = {
    mt: 0,
    mb: 6,
};

export { FormField };

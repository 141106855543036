import { jsx, jsxs } from 'react/jsx-runtime';
import React from 'react';
import styled from 'styled-components';
import '../Heading/index.js';
import '../Box/index.js';
import { Flex, Text } from '../Box/BoxSystem.js';
import { Heading } from '../Heading/Heading.js';

const iconSizeToPixels = {
    small: 42,
    medium: 64,
    large: 72,
};
const Container = styled(Flex).attrs({
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}) `
  width: 100%;
  height: 100%;
  flex: 1 0 0;
`;
const Title = styled(Heading.H3) `
  max-width: 100%;
  text-align: center;

  margin-top: 20px;
  margin-bottom: 6px; /* stylelint-disable-line scales/space */

  font-size: 1rem;
  line-height: 1;

  color: ${({ theme, $dark }) => $dark ? 'white' : theme.colors.foreground.secondary.B};
`;
const Body = styled(Text) `
  max-width: 100%;
  text-align: center;

  margin-top: 0;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  font-size: 0.875rem;
  line-height: 1.4;
  color: ${({ theme, $dark }) => $dark ? 'white' : theme.colors.foreground.secondary.D};

  b {
    font-weight: 400;
    color: ${({ theme, $dark }) => $dark ? 'white' : theme.colors.foreground.secondary.A};
  }
`;
const IconContainer = styled.div `
  width: ${props => props.iconSizePx}px;
  height: ${props => props.iconSizePx}px;

  opacity: 0.55;
  color: ${({ theme, $dark }) => $dark ? 'white' : theme.colors.foreground.secondary.B};
`;
const ErrorMessage = props => {
    const { children, dark, description, extra, icon, iconSize = 'large', title, className, } = props;
    const textContent = children || description;
    const iconSizePixels = iconSizeToPixels[iconSize];
    let iconComponent;
    if (icon && React.isValidElement(icon)) {
        iconComponent = (jsx(IconContainer, Object.assign({ iconSizePx: iconSizePixels, "$dark": dark }, { children: icon })));
    }
    else if (icon && typeof icon === 'function') {
        iconComponent = icon({ width: iconSizePixels, iconSize });
    }
    else {
        iconComponent = null;
    }
    return (jsxs(Container, Object.assign({ className: className, "$dark": dark }, { children: [iconComponent, jsx(Title, Object.assign({ "$dark": dark }, { children: title })), textContent && jsx(Body, Object.assign({ "$dark": dark }, { children: textContent })), extra] })));
};

export { Body, Container, ErrorMessage, Title };

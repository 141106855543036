import React, { useContext } from 'react';

const ModalContext = React.createContext({
    isModalOpen: false,
    showModal: () => { },
    showRestrictedModal: () => { },
    hideModal: () => { },
});
const useModalContext = () => useContext(ModalContext);

export { ModalContext, useModalContext };

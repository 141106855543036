import React from 'react'

import { Banner, Link } from '@sketch/components'

export const BusinessContactBanner = () => {
  return (
    <Banner type="information">
      Need flexibility with payment methods or terms, security and legal reviews
      — or need help with anything else?{' '}
      <Link
        href="https://www.sketch.com/support/contact/?topic=business"
        external
        isUnderlined
        variant="secondary"
        title="Contact Us"
      >
        Contact us
      </Link>
      .<br />
      <br />
      Sketch is{' '}
      <Link
        href="https://www.sketch.com/education/"
        external
        isUnderlined
        variant="secondary"
        title="Education details"
      >
        free for educational purposes
      </Link>
      .
    </Banner>
  )
}

import React from 'react'
import styled from 'styled-components'
import Opacity from '../Opacity'
import { ImageFillType } from 'modules/shares/types'
import { ReactComponent as ImagePlaceholderIcon } from '@sketch/icons/image-fill'
import { roundWithLocale } from '@sketch/utils'

export interface FillImageProps {
  fill: ImageFillType
  sizePercentage?: number
  opacity?: number
}

const Capitalized = styled.span`
  text-transform: capitalize;
`

const ImagePlaceholder = styled(ImagePlaceholderIcon)`
  width: 17px;
  height: 17px;
  opacity: 0.5;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`

const ImageFillText = styled.span`
  margin-left: 4px;
  font-size: 0.8125rem;
`

const ImageFill = ({
  fill,
  sizePercentage,
}: {
  fill: ImageFillType
  sizePercentage?: number
}) => {
  const fillText =
    fill === ImageFillType.Tile && sizePercentage
      ? `${fill} (${roundWithLocale(sizePercentage * 100, 2)}%)`
      : fill
  return (
    <ImageFillText>
      <Capitalized>{fillText}</Capitalized>
    </ImageFillText>
  )
}

const FillImage = (props: FillImageProps) => (
  <ImageContainer>
    <ImagePlaceholder role="img" />
    <ImageFill {...props} />
    <Opacity value={props.opacity} />
  </ImageContainer>
)

export default FillImage

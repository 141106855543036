import { ErrorHandler } from '@sketch/tracing';

class ApolloParsedError extends Error {
    constructor(originalError) {
        super();
        this.originalError = originalError;
    }
    get message() {
        var _a, _b;
        const error = this.originalError;
        const parsedMessage = ((_a = error.graphQLErrors) === null || _a === void 0 ? void 0 : _a.map(x => x.message).join(', ')) ||
            ((_b = error.networkError) === null || _b === void 0 ? void 0 : _b.message) ||
            error.message;
        if (parsedMessage) {
            return parsedMessage;
        }
        ErrorHandler.shouldNeverHappen('ApolloParsedError should always parse a truthy error message ');
        return 'Something is wrong, please contact our support team';
    }
    get messages() {
        const { graphqlErrorMessages, networkErrorMessage } = this;
        if (networkErrorMessage) {
            return [...graphqlErrorMessages, networkErrorMessage];
        }
        return graphqlErrorMessages;
    }
    get graphqlErrorMessages() {
        var _a;
        return (_a = this.originalError.graphQLErrors) === null || _a === void 0 ? void 0 : _a.map(x => x.message);
    }
    get networkErrorMessage() {
        var _a;
        return ((_a = this.originalError.networkError) === null || _a === void 0 ? void 0 : _a.message) || undefined;
    }
    includesErrorCode(value) {
        const error = this.originalError;
        return error.graphQLErrors.some(e => { var _a; return ((_a = e.extensions) === null || _a === void 0 ? void 0 : _a.code) === value; });
    }
    includesErrorReason(value) {
        const error = this.originalError;
        return error.graphQLErrors.some(e => { var _a; return ((_a = e.extensions) === null || _a === void 0 ? void 0 : _a.reason) === value; });
    }
    getContext() {
        const error = this.originalError;
        return error.graphQLErrors.map(e => { var _a; return (_a = e.extensions) === null || _a === void 0 ? void 0 : _a.context; }).flat();
    }
    /**
     * @deprecated
     * This field is only for backwards compatibility (particularly for .js files)
     * and it is meant only for the transition.
     */
    get graphQLErrors() {
        return this.originalError.graphQLErrors;
    }
    /**
     * @deprecated
     * This field is only for backwards compatibility (particularly for .js files)
     * and it is meant only for the transition.
     */
    get networkError() {
        return this.originalError.networkError;
    }
    /**
     * @deprecated
     * This field is only for backwards compatibility (particularly for .js files)
     * and it is meant only for the transition.
     */
    get extraInfo() {
        return this.originalError.extraInfo;
    }
    /**
     * @deprecated
     * This field is only for backwards compatibility (particularly for .js files)
     * and it is meant only for the transition.
     */
    get name() {
        return this.originalError.name;
    }
}
class UserParsedError extends Error {
    constructor(originalErrors) {
        super();
        this.originalErrors = originalErrors;
    }
    get message() {
        return this.messages.join(', ');
    }
    get messages() {
        return this.originalErrors.map(x => x.message);
    }
    get graphqlErrorMessages() {
        return this.messages;
    }
    get networkErrorMessage() {
        return undefined;
    }
    includesErrorCode(value) {
        return this.originalErrors.some(x => x.code === value);
    }
    includesErrorReason(value) {
        return false;
    }
}
class RawParsedError extends Error {
    constructor(originalError) {
        super();
        this.originalError = originalError;
    }
    get message() {
        return this.originalError.message;
    }
    get messages() {
        return [this.message];
    }
    get graphqlErrorMessages() {
        return [];
    }
    get networkErrorMessage() {
        return this.originalError.message;
    }
    includesErrorCode(value) {
        return false;
    }
    includesErrorReason(value) {
        return false;
    }
}

export { ApolloParsedError, RawParsedError, UserParsedError };

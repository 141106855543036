import { GetPersonalTokensDocument } from '@sketch/gql-types';

const get = (cache) => {
    var _a;
    try {
        return (_a = cache.readQuery({
            query: GetPersonalTokensDocument,
            variables: {},
        })) === null || _a === void 0 ? void 0 : _a.me;
    }
    catch (_b) {
        // There is no way to recover here and callers are already expecting
        // `undefined` as a possible return type, so we do nothing.
    }
};
const set = (cache, me) => {
    cache.writeQuery({
        query: GetPersonalTokensDocument,
        data: { __typename: 'RootQueryType', me },
    });
};

export { get, set };

import React from 'react'
import { useHistory, useLocation } from 'react-router'

import { useStableHandler } from '@sketch/utils'
import { useQueryParams } from '@sketch/modules-common'

import { Tooltip, useResponsiveDropdown } from '@sketch/components'

// Usage of AnnotationStatusFilter will not break the build
// eslint-disable-next-line no-restricted-imports
import { AnnotationStatusFilter } from '@sketch/gql-types/expansive'

import {
  Chevron,
  FilterButton,
  OptionsWrapper,
  RadioButton,
} from './AnnotationListStatusFilter.styles'

import { addAnnotationStatusToQueryParams } from '../../utils'

interface AnnotationListStatusFilterHeaderProps {
  annotationStatus?: AnnotationStatusFilter
}

const ANNOTATION_STATUS_LIST: AnnotationStatusFilter[] = [
  'ACTIVE_ONLY',
  'RESOLVED_ONLY',
  'ALL',
]

const ANNOTATION_STATUS_LABEL: Record<AnnotationStatusFilter, string> = {
  ACTIVE_ONLY: 'Open Comments',
  ALL: 'All Comments',
  RESOLVED_ONLY: 'Resolved Comments',
}

const ANNOTATION_STATUS_PARAMETER: Record<AnnotationStatusFilter, string> = {
  ACTIVE_ONLY: 'open',
  ALL: 'all',
  RESOLVED_ONLY: 'resolved',
}

const DROPDOWN_OFFSET = [0, 0] as [number, number]

const AnnotationListStatusFilterSelector = (
  props: AnnotationListStatusFilterHeaderProps
) => {
  const { replace } = useHistory()
  const location = useLocation()

  const queryParameters = useQueryParams<
    'SHARE_VIEW' | 'SHARE_PAGE_CANVAS_VIEW' | 'ARTBOARD_DETAIL'
  >()

  const onChange = useStableHandler(event => {
    const inputValue =
      ANNOTATION_STATUS_PARAMETER[event.target.value as AnnotationStatusFilter]

    const annotationStatus =
      inputValue === 'ACTIVE_ONLY' ? undefined : inputValue

    replace({
      ...location,
      search: addAnnotationStatusToQueryParams(
        queryParameters,
        annotationStatus
      ),
    })
  })

  return (
    <OptionsWrapper data-testid="annotation-list-status-filter-options">
      {ANNOTATION_STATUS_LIST.map(value => (
        <RadioButton
          label={ANNOTATION_STATUS_LABEL[value]}
          value={value}
          key={value}
          onChange={onChange}
          checked={props.annotationStatus === value}
        />
      ))}
    </OptionsWrapper>
  )
}

const AnnotationListStatusFilterHeader = (
  props: AnnotationListStatusFilterHeaderProps
) => {
  const [contentStatus, buttonProps] = useResponsiveDropdown({
    dropdown: AnnotationListStatusFilterSelector,
    dropdownProps: { annotationStatus: props.annotationStatus },
    placement: 'bottom-start',
    offset: DROPDOWN_OFFSET,
    usePortal: true,
  })

  return (
    <>
      <Tooltip
        content="Filter the visible comments"
        placement="bottom-start"
        disabled={!!buttonProps['aria-expanded']}
      >
        <FilterButton
          data-testid="annotation-list-status-filter"
          {...buttonProps}
        >
          {ANNOTATION_STATUS_LABEL[props.annotationStatus || 'ACTIVE_ONLY']}
          <Chevron role="img" />
        </FilterButton>
      </Tooltip>
      {contentStatus}
    </>
  )
}

export default AnnotationListStatusFilterHeader

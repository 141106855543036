import { useMutation as useMutation$1 } from '@apollo/react-hooks';
import { useFixedMutationTuple } from './useFixedMutationTuple.js';
import { useErrorRedirect } from './useErrorRedirect.js';
import { useParseErrorHandler } from './useParseErrorHandler.js';

function useMutation(mutation, options) {
    const { onError, ignoreResults } = options || {};
    const { UNSAFE_ignoreResults } = (options ||
        {});
    const { onUserErrorHandler, onApolloErrorHandler, onErrorDispatch, } = useParseErrorHandler({
        onError,
    });
    const augmentedOptions = useErrorRedirect(options, {
        onUserErrorHandler,
    });
    const originalMutationTuple = useMutation$1(mutation, Object.assign(Object.assign(Object.assign({ ignoreResults: ignoreResults || UNSAFE_ignoreResults }, options), augmentedOptions), { onError: onApolloErrorHandler }));
    const mutationTuple = useFixedMutationTuple({
        onApolloErrorHandler,
        onErrorDispatch,
        originalMutationTuple,
        redirectErrors: options.redirectErrors,
    });
    return mutationTuple;
}

export { useMutation };

import React from 'react'
import { roundWithLocale } from '@sketch/utils'
import { AttributeList } from '../Attribute'
import Header, { SubTitle } from '../Header'
import { Blur as BlurProps, BlurType } from 'modules/shares/types'
import FullCopyAttribute from '../FullCopyAttribute'
import copy from './copy'
import { getDirtyAttributes } from 'modules/shares/Inspector/Sidebar/components/Style/DirtyIconTooltip'

export const blurTypeFromString = (type: string) => {
  switch (type) {
    case 'motion':
      return BlurType.Motion
    case 'zoom':
      return BlurType.Zoom
    case 'background':
      return BlurType.Background
    default:
      return BlurType.Gaussian
  }
}

interface BlurPropsWithDirtyValues extends BlurProps {
  originalValues?: BlurProps
}

const Blur = ({
  type,
  center,
  isEnabled,
  motionAngle,
  radius,
  saturation,
  originalValues,
}: BlurPropsWithDirtyValues) => {
  const blurType = blurTypeFromString(type)

  const sectionCopyValue = copy({
    type: blurType,
    radius,
    saturation,
    motionAngle,
    center,
  })

  return isEnabled ? (
    <>
      <Header
        copyValue={sectionCopyValue}
        dirtyAttributes={getDirtyAttributes({
          originalValueKey: 'isEnabled',
          valueToDisplay: originalValues?.isEnabled ? 'enabled' : 'disabled',
          originalValues,
          labelToDisplay: 'Blur value',
        })}
      >
        <SubTitle>Blur</SubTitle>
      </Header>
      <AttributeList>
        <FullCopyAttribute
          label="Type"
          value={blurType}
          dirtyAttributes={getDirtyAttributes({
            originalValueKey: 'type',
            valueToDisplay: originalValues?.type,
            originalValues,
            labelToDisplay: 'Blur Type',
          })}
        />
        {radius ? (
          <FullCopyAttribute
            label="Radius"
            copyValue={`${radius}`}
            value={`${roundWithLocale(radius, 2)}`}
            dirtyAttributes={getDirtyAttributes({
              originalValueKey: 'radius',
              valueToDisplay: `${roundWithLocale(
                originalValues?.radius ?? 0,
                2
              )}`,
              originalValues,
            })}
          />
        ) : null}
        {saturation && blurType === BlurType.Background ? (
          <FullCopyAttribute
            label="Saturation"
            copyValue={`${saturation}`}
            value={`${roundWithLocale(saturation, 2)}%`}
            dirtyAttributes={getDirtyAttributes({
              originalValueKey: 'saturation',
              valueToDisplay: `${roundWithLocale(
                originalValues?.saturation ?? 0,
                2
              )}`,
              originalValues,
            })}
          />
        ) : null}
        {motionAngle && blurType === BlurType.Motion ? (
          <FullCopyAttribute
            label="Angle"
            copyValue={`${motionAngle}`}
            value={`${roundWithLocale(motionAngle, 2)}°`}
            dirtyAttributes={getDirtyAttributes({
              originalValueKey: 'motionAngle',
              valueToDisplay: `${roundWithLocale(
                originalValues?.motionAngle ?? 0,
                2
              )}°`,
              originalValues,
              labelToDisplay: 'Motion Angle',
            })}
          />
        ) : null}
        {blurType === BlurType.Zoom &&
        center &&
        ((center.x && center.y) || center.x === 0 || center.y === 0) ? (
          <FullCopyAttribute
            label="Angle"
            copyValue={`(${center.x}, ${center.y})`}
            value={`(${roundWithLocale(center.x, 2)}, ${roundWithLocale(
              center.y,
              2
            )})`}
            dirtyAttributes={getDirtyAttributes({
              originalValueKey: 'center',
              valueToDisplay: `(${roundWithLocale(
                originalValues?.center?.x ?? 0,
                2
              )}, ${roundWithLocale(originalValues?.center?.y ?? 0, 2)})`,
              originalValues,
              labelToDisplay: 'Angle',
            })}
          />
        ) : null}
      </AttributeList>
    </>
  ) : null
}

export default Blur

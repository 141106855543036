import styled, { css } from 'styled-components';
import { breakpoint } from '@sketch/global-styles';

const Container = styled.div `
  ${props => css `
      grid-column: auto / span ${(props === null || props === void 0 ? void 0 : props.xxs) || (props === null || props === void 0 ? void 0 : props.xs) || 12};
      --grid-columns: ${(props === null || props === void 0 ? void 0 : props.xxs) || (props === null || props === void 0 ? void 0 : props.xs) || 12};

      ${breakpoint('xs') `
        grid-column: auto / span ${(props === null || props === void 0 ? void 0 : props.xs) || 12};
        --grid-columns: ${(props === null || props === void 0 ? void 0 : props.xs) || 12};
      `}

      ${breakpoint('sm') `
        grid-column: auto / span ${props === null || props === void 0 ? void 0 : props.sm};
        --grid-columns: ${props === null || props === void 0 ? void 0 : props.sm};
      `}

      ${breakpoint('md') `
        grid-column: auto / span ${props === null || props === void 0 ? void 0 : props.md};
        --grid-columns: ${props === null || props === void 0 ? void 0 : props.md};
      `}
    
      ${breakpoint('lg') `
        grid-column: auto / span ${props === null || props === void 0 ? void 0 : props.lg};
        --grid-columns: ${props === null || props === void 0 ? void 0 : props.lg};
      `}

      ${breakpoint('xl') `
        grid-column: auto / span ${props === null || props === void 0 ? void 0 : props.xl};
        --grid-columns: ${props === null || props === void 0 ? void 0 : props.xl};
      `}
    `};
`;

export { Container };

import { jsx, jsxs } from 'react/jsx-runtime';
import { clampCurrentBreakpoint, sortBreakpointsArray } from '../../utils/clampCurrentBreakpoint.js';
import { useCurrentBreakpoint } from '@sketch/components';
import { BREAKPOINTS_IN_PX } from '@sketch/global-styles';
import { Placeholder, Picture, ImageContainer } from './ResponsiveImage.styles.js';
import { usePreloadImage } from '@sketch/utils';

/**
 * ResponsiveImage
 *
 * This component allows us to render one image per breakpoint.
 *
 * "image" param can be a regular string (the image source) or
 * an object of breakpoints with the url for each of them.
 *
 * Breakpoints are optional. Example:
 *  const image = { source: { md: 'x.png', xl: 'y.png }}
 *  -  x.png will be used or mobile and tablet
 *  -  y.png will be used for desktop and wide
 *
 * It also only loads images when they are just below the fold
 * to avoid loading data we don't need
 *
 * It has an optional skeleton ui while the image is loading
 */
const ResponsiveImage = ({ image, withSkeletonUI = false, className, }) => {
    var _a, _b, _c;
    const currentBreakpoint = useCurrentBreakpoint();
    const actualBreakpoint = clampCurrentBreakpoint(currentBreakpoint);
    const srcSet = image.__typename === 'SingleImage'
        ? image.src // Only one source available to load
        : image.source[actualBreakpoint]; // Load the src for the current breakpoint
    const { ref, isLoaded } = usePreloadImage({
        srcSet,
    });
    if (!isLoaded && withSkeletonUI) {
        return (jsx(Placeholder, { "data-testid": "image-placeholder", className: className, ref: ref }));
    }
    // We are not using a "srcset", its just a regular image
    if (image.__typename === 'SingleImage') {
        return (jsx("img", { className: className, srcSet: image.src, alt: image.alt || undefined }));
    }
    // Below this point we have image with sources for each breakpoint
    // We prepare to generate a <picture> with multiple <sources />
    const imageSourceSet = image.source;
    const largestSrcPossible = (_c = (_b = (_a = imageSourceSet.xl) !== null && _a !== void 0 ? _a : imageSourceSet.lg) !== null && _b !== void 0 ? _b : imageSourceSet.md) !== null && _c !== void 0 ? _c : imageSourceSet.sm;
    // Generate a <source /> tag for each breakpoint (used in <picture /> tag)
    // and fitler the GQL key out (this is temporary, until we have a CMS)
    const sourceSetBreakpoints = Object.keys(imageSourceSet).filter(source => source !== '__typename');
    const sourceTags = sourceSetBreakpoints
        .sort(sortBreakpointsArray)
        .map(sourceBreakpoint => {
        const breakpointImageSource = imageSourceSet[sourceBreakpoint];
        return (jsx("source", { media: `(max-width: ${BREAKPOINTS_IN_PX[sourceBreakpoint]}px)`, srcSet: breakpointImageSource }, sourceBreakpoint));
    });
    return (jsxs(Picture, { children: [sourceTags.map(source => source), jsx(ImageContainer, { className: className, srcSet: largestSrcPossible, alt: image.alt || undefined })] }));
};

export { ResponsiveImage as default };

import { ReactText } from 'react'
import { LayerCorners } from 'modules/inspector'
import { capitalize } from '@sketch/utils'

interface Args {
  radius: ReactText
  type: LayerCorners['type']
}

const copy = ({ radius, type }: Args) =>
  `Radius: ${radius}${type ? `\nStyle: ${capitalize(type)}` : ''}`

export default copy

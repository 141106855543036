import styled, { css } from 'styled-components';
import { variant, textAlign, fontSize, lineHeight, color, fontWeight, verticalAlign, fontStyle } from 'styled-system';
import '../Truncate/index.js';
import { truncateStyles } from '../Truncate/Truncate.js';

const textCss = css `
  ${({ $reset }) => $reset &&
    css `
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
    `}

  ${() => variant({
    prop: 'textStyle',
    scale: 'textStyles',
    variants: {
        // can be blank, it's just to enable the newer API
        primary: {},
    },
})}

  text-transform: ${({ textTransform }) => textTransform};
  word-wrap: break-word;
  overflow-wrap: break-word;

  ${({ overflow }) => overflow === 'ellipsis' &&
    css `
      ${truncateStyles};
    `};

  ${textAlign}
  ${fontSize}
  ${lineHeight};
  ${color}
  ${fontWeight}
  ${verticalAlign}
  ${fontStyle}
`;
function withText(Comp) {
    const styledComp = styled(Comp) `
    ${textCss}
  `;
    styledComp.displayName = 'Text';
    return styledComp;
}

export { withText };

import React from 'react'
import { WrapperList } from 'modules/user/views/Updates/Updates.styles'
import { Skeleton } from './NotificationListItem.styles'
import { useFlag } from '@sketch/modules-common'

export const NotificationListItemSkeleton = ({
  pageSize,
}: {
  pageSize: number
}) => {
  // TODO: Remove FF "ui-refresh-24" when its released
  // https://github.com/orgs/sketch-hq/projects/326/views/1?pane=issue&itemId=65739330
  const isRefreshedUi = useFlag('ui-refresh-24')

  return (
    <WrapperList data-testId="skeleton-loading" $isRefreshedUi={isRefreshedUi}>
      {[...Array(pageSize)].map((_, index) => (
        <Skeleton key={index} $isRefreshedUi={isRefreshedUi} />
      ))}
    </WrapperList>
  )
}

import React, { useEffect, useRef } from 'react'

import * as Sentry from '@sentry/browser'
import { IsEmbedded, NotEmbedded, useForTablet } from '@sketch/components'
import { IS_EMBEDDED } from '@sketch/constants'
import { ArtboardDetailInfoFragment, VersionFragment } from '@sketch/gql-types'
import { EmbeddedError, routes } from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'
import { ErrorHandler } from '@sketch/tracing'
import { ApolloError } from 'apollo-client'
import { GraphQLError } from 'graphql'
import { Redirect } from 'react-router'

import { usePlaceDraftAnnotation } from 'modules/annotations/hooks'
import { CustomFontsMessage } from 'modules/shares/components/CustomFontsMessage'
import {
  DocumentMobileToolbar,
  isMobileToolboxItem,
} from 'modules/shares/components/DocumentMobileToolbar'
import { DocumentSidebarLayoutExtraProps } from 'modules/shares/components/DocumentSidebarLayout'
import { ComponentDescriptionContextProvider } from 'modules/shares/ComponentsWebView/context'
import { Pagination } from 'modules/shares/types'
import { ShareWithoutVersion } from 'modules/versioning'

import {
  ArtboardDetailHeader,
  ArtboardDetailHeaderEmbedded,
} from '../ArtboardDetailHeader'
import { ArtboardDetailCanvas } from '../ArtboardDetailCanvas'
import { useCanvasZoom } from 'components/WebRendererCanvas/hooks'
import { useGridAndLayoutPropForZoomComponent } from '../../hooks'
import { ArtboardDetailPaginationControls } from '../ArtboardDetailPaginationControls'
import { Panel } from 'modules/shares/components/Panel'
import { DraftAnnotationNotifier } from 'modules/annotations/components'
import { OnboardingPanelFloatingPanels } from 'modules/shares/PageCanvasView/OnboardingPanelFloatingPanels'
import { Zoom } from 'components/Zoom'
import { MIN_ZOOM, MAX_ZOOM } from 'components/WebRendererCanvas/constants'

function hasGraphQLErrorCode(
  apolloError: ApolloError,
  errorCode: string
): GraphQLError | undefined {
  return apolloError.graphQLErrors.find(
    error => error.extensions?.code === errorCode
  )
}

export interface ArtboardDetailProps {
  share: ShareWithoutVersion
  currentVersion: VersionFragment | undefined
  artboard: ArtboardDetailInfoFragment | undefined
  isViewingLatestVersion: boolean
  isPrototypePlayEnabled: boolean
  pagination: Pagination | undefined
  artboardIdAtLatestVersion: string | undefined
  error: ApolloError | undefined
  loading: boolean
  layoutProps: DocumentSidebarLayoutExtraProps
}

const FloatingZoom = () => {
  const [zoom, setZoom] = useCanvasZoom()
  const gridAndLayoutPropsForZoomComponent = useGridAndLayoutPropForZoomComponent()
  const preventiveGridAndLayout = gridAndLayoutPropsForZoomComponent

  return (
    <Zoom
      zoom={zoom}
      setZoom={setZoom}
      minZoom={MIN_ZOOM}
      maxZoom={MAX_ZOOM}
      gridAndLayout={preventiveGridAndLayout}
      explicitControls
    />
  )
}

export const ArtboardDetail = ({
  share,
  currentVersion,
  artboard,
  isPrototypePlayEnabled,
  error,
  loading,
  pagination,
  layoutProps,
  isViewingLatestVersion,
  artboardIdAtLatestVersion,
}: ArtboardDetailProps) => {
  const {
    HeaderPortal,
    SidebarRightPortal,
    useOverrideLayoutProps,
    toggleSidebarRight,
    setSidebarLeftOpen,
    isSidebarRightOpen,
  } = layoutProps

  const canvasRef = useRef<HTMLDivElement>(null)
  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  const { showToast } = useToast()

  useOverrideLayoutProps({
    title: artboard?.name,
  })

  const [placeDraftAnnotation, setPlacingDraftAnnotation] =
    usePlaceDraftAnnotation() || []

  useEffect(() => {
    if (error && !IS_EMBEDDED) {
      if (hasGraphQLErrorCode(error, 'NOT_FOUND')) {
        showToast('This artboard does not exist.', 'negative')
      } else if (!error.networkError) {
        showToast(
          'Oops, something went wrong while loading the artboard.',
          'negative'
        )
        Sentry.captureException(error)
        ErrorHandler.shouldNeverHappen(
          `Something went wrong while fetching getDetailViewArtboard query: '${error.message}'`
        )
      }
    }
  }, [error, showToast])

  const [zoom, setZoom] = useCanvasZoom()
  const pageUUID = artboard?.page?.uuid
  const artboardUUID = artboard?.uuid

  const gridAndLayoutForZoomComponent = useGridAndLayoutPropForZoomComponent()
  const isValidPagination =
    Number.isInteger(pagination?.current) && Number.isInteger(pagination?.total)

  const MOBILE_TOOLBAR_ITEMS = [
    'zoom',
    artboard?.isFlowHome && 'prototype',
    'add-comment',
    'comments',
    'versions',
  ].filter(isMobileToolboxItem)

  return (
    <ComponentDescriptionContextProvider>
      <HeaderPortal>
        <NotEmbedded>
          <ArtboardDetailHeader
            share={share}
            artboard={artboard}
            artboardLatestVersion={artboardIdAtLatestVersion!}
            setSidebarLeftOpen={setSidebarLeftOpen}
            toggleSidebarRight={toggleSidebarRight}
            isSidebarRightOpen={isSidebarRightOpen}
            isViewingLatestVersion={isViewingLatestVersion}
            isPrototypePlayEnabled={isPrototypePlayEnabled}
            userCanOpenInApp={!!currentVersion?.document?.userCanOpenInApp}
          />

          {isValidPagination && (
            <ArtboardDetailPaginationControls
              current={pagination!.current!}
              total={pagination!.total!}
            />
          )}

          <Panel
            share={share}
            version={currentVersion || undefined}
            userCanSeeComments
            userCanInspect={share.userCanInspect}
            SidebarRightPortal={SidebarRightPortal}
            artboard={artboard}
          />

          <OnboardingPanelFloatingPanels />

          <FloatingZoom />
        </NotEmbedded>
        <IsEmbedded>
          {!error && !loading && (
            <ArtboardDetailHeaderEmbedded
              share={share}
              artboard={artboard}
              pagination={pagination}
              currentZoom={zoom}
              onZoomChanged={setZoom}
              showPrototypePlayButton={
                (artboard && artboard.isFlowHome && isPrototypePlayEnabled) ||
                false
              }
            />
          )}
        </IsEmbedded>
      </HeaderPortal>

      <>
        <CustomFontsMessage
          userCanEdit={share.userCanEdit}
          shareIdentifier={share.identifier}
          hasMissingFonts={!!artboard?.hasMissingFonts}
        />
        {error ? (
          <>
            <NotEmbedded>
              <Redirect
                to={routes.SHARE_VIEW.create({
                  shareID: share.identifier,
                })}
              />
            </NotEmbedded>
            <IsEmbedded>
              <EmbeddedError for="artboard" />
            </IsEmbedded>
          </>
        ) : (
          pageUUID &&
          artboardUUID && (
            <>
              {!isMobile && <DraftAnnotationNotifier canvasRef={canvasRef} />}

              <ArtboardDetailCanvas
                pageUUID={pageUUID}
                artboardUUID={artboardUUID}
                ref={canvasRef}
              />
            </>
          )
        )}
      </>

      {!IS_EMBEDDED && isMobile && (
        <DocumentMobileToolbar
          zoom={zoom}
          setZoom={setZoom}
          isPrototypePlayEnabled={isPrototypePlayEnabled}
          shareIdentifier={share.identifier}
          items={MOBILE_TOOLBAR_ITEMS}
          artboardUUID={artboard?.uuid || ''}
          isDraftAnnotationEnabled={placeDraftAnnotation}
          setPlaceDraftAnnotation={setPlacingDraftAnnotation}
          gridAndLayout={gridAndLayoutForZoomComponent}
        />
      )}
    </ComponentDescriptionContextProvider>
  )
}

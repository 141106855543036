import React, { useState } from 'react'
import { matchPath, useLocation } from 'react-router'
import { DataProxy } from 'apollo-cache'
import { dateFormat, formatByteCount, useEventDispatch } from '@sketch/utils'
import {
  DocumentInfo as DocumentInfoWrapper,
  DocumentInfoTitle,
  DocumentInfoItemWrapper,
  DocumentInfoItemTitle,
  Separator,
  StyledRadioButton,
} from './DocumentInfoModal.styles'
import { Box, Text } from '@sketch/components'
import { useToast } from '@sketch/toasts'
import { removeFromPaginated, routes } from '@sketch/modules-common'
import {
  ShareInfoFragment,
  VersionFragment,
  useGetWorkspaceQuery,
  useShareTypeChangeMutation,
} from '@sketch/gql-types'
// eslint-disable-next-line no-restricted-imports
import { ShareType } from '@sketch/gql-types/expansive'

/**
 * TYPES
 */
interface DocumentInfoItemProps {
  title: string
  text?: string | null
}

export interface ShareForDocumentInfo
  extends Pick<ShareInfoFragment, 'identifier' | 'createdAt' | 'type'> {
  workspace: Pick<
    NonNullable<ShareInfoFragment['workspace']>,
    'name' | 'identifier'
  >
}

type VersionDocument = NonNullable<VersionFragment['document']>
export interface VersionForDocumentInfo
  extends Pick<VersionFragment, 'createdAt'> {
  document: Pick<VersionDocument, 'colorspace' | 'size'> | null
}

export interface VersionsForDocumentInfo {
  currentVersion: VersionForDocumentInfo | null
  latestVersion: VersionForDocumentInfo | null
}

export interface DocumentInfoProps {
  share: ShareForDocumentInfo
  version: VersionsForDocumentInfo
}

/**
 * COMPONENTS
 */
const DocumentInfoItem = ({ title, text }: DocumentInfoItemProps) => {
  if (!text) return null

  return (
    <DocumentInfoItemWrapper>
      <DocumentInfoItemTitle>{title}</DocumentInfoItemTitle>
      <Text>{text}</Text>
    </DocumentInfoItemWrapper>
  )
}

const DocumentInfo = ({ share, version }: DocumentInfoProps) => {
  const { createdAt, workspace } = share
  const { currentVersion, latestVersion } = version
  const sizeInBytes = currentVersion?.document?.size
  const lastUpdate = latestVersion?.createdAt

  const { showToast } = useToast()
  const location = useLocation()
  const [shareType, setShareType] = useState(share.type)
  const dispatchSharesRefresh = useEventDispatch('workspaceShareRefresh')

  // Get current workspace to check if it's active
  const { data: workspaceData } = useGetWorkspaceQuery({
    variables: { identifier: share.workspace.identifier },
    fetchPolicy: 'cache-only',
  })
  const isInactive = workspaceData?.workspace.status === 'INACTIVE'

  const onUpdateType = (cache: DataProxy, shareSection: string) => {
    // Remove all possible awareness of this Share from the filter query
    removeFromPaginated(
      cache,
      { __typename: 'Share', identifier: share.identifier },
      key => key.includes(`"filter":"${shareSection}"`)
    )

    refetchShares()
  }

  const refetchShares = () => {
    // Refactor this and send an event to listen in the
    // library view when removing the FF
    // https://github.com/sketch-hq/Cloud/issues/14156
    const isLibraryView = matchPath(location.pathname, {
      path: routes.WORKSPACE_LIBRARIES.template(),
      exact: true,
    })

    const isTemplatesView = matchPath(location.pathname, {
      path: routes.WORKSPACE_TEMPLATES.template(),
      exact: true,
    })

    if (!isLibraryView && !isTemplatesView) {
      return []
    }

    dispatchSharesRefresh({
      workspaceIdentifier: workspace.identifier,
    })
  }

  const [updateShareType] = useShareTypeChangeMutation({
    redirectErrors: true,
    onCompleted: ({ shareUpdate }) => {
      setShareType(shareUpdate!.share!.type)
    },
    onError: () => {
      setShareType(share.type)
      showToast(
        'Unable to update document settings. Please try again later',
        'negative'
      )
    },
    update: (cache, { data }) =>
      onUpdateType(cache, data?.shareUpdate?.share?.type as string),
  })

  const handleShareTypeChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newShareType = e.target.value as ShareType

    setShareType(newShareType)

    await updateShareType({
      variables: {
        identifier: share.identifier,
        type: newShareType,
      },
    })
  }

  return (
    <>
      <Separator />

      <DocumentInfoTitle>Set As</DocumentInfoTitle>
      <Box mt={5}>
        <StyledRadioButton
          name="shareType"
          label="Document"
          help="A collaborative design document (default)"
          value="STANDARD"
          checked={shareType === 'STANDARD'}
          onChange={handleShareTypeChange}
          disabled={isInactive}
        />

        <StyledRadioButton
          name="shareType"
          label="Library"
          help="A group of shareable Symbols, Styles and Color Variables"
          value="LIBRARY"
          checked={shareType === 'LIBRARY'}
          onChange={handleShareTypeChange}
          disabled={isInactive}
        />

        <StyledRadioButton
          name="shareType"
          label="Template"
          help="A reusable Workspace document with pre-populated content"
          value="TEMPLATE"
          checked={shareType === 'TEMPLATE'}
          onChange={handleShareTypeChange}
          disabled={isInactive}
        />
      </Box>

      <Separator />

      <DocumentInfoTitle>Document Info</DocumentInfoTitle>
      <DocumentInfoWrapper>
        <DocumentInfoItem
          title="Created On"
          text={dateFormat(new Date(createdAt as string))}
        />
        <DocumentInfoItem title="Located In" text={workspace.name} />

        <DocumentInfoItem
          title="Last Updated On"
          text={dateFormat(new Date(lastUpdate as string))}
        />
        <DocumentInfoItem
          title="Color Profile"
          text={currentVersion?.document?.colorspace || 'Unmanaged'}
        />
        <DocumentInfoItem
          title="File Size"
          text={formatByteCount(sizeInBytes as number)}
        />
      </DocumentInfoWrapper>
    </>
  )
}

export default DocumentInfo

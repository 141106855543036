import React, { useState } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'

import { positionDefault } from '@reach/popover'
import { useListboxContext } from '@reach/listbox'
import { shortcuts } from '@sketch/utils'
import {
  routes,
  isWorkspaceRoute,
  isProjectRoute,
  isProjectTrashRoute,
  isDraftsRoute,
  RouteParams,
} from '@sketch/modules-common'
import {
  useSearchFilters,
  ShareSearchFilter,
} from 'modules/shares/hooks/useSearchFilters'

import { useSearch } from 'modules/shares/hooks/useSearch'
import { useHideCollectionsSetting } from 'modules/collections/hooks'

import {
  Dropdown,
  SelectTriggerUnstyled,
  Tooltip,
  TooltipShortcut,
  useForTablet,
  Modal,
  useModalContext,
  Button,
  Box,
  useSearchExpanded,
  SelectInput,
} from '@sketch/components'

// Graphql
import {
  useGetDocumentListSettingsQuery,
  useGetWorkspaceQuery,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

import {
  IconButton,
  StyledCheckbox,
  StyledDropdownHeader,
  StyledDropdownItem,
  StyledResetIcon,
  StyledSelectPopover,
  FilterWrapper,
  Filter as SearchInput,
  FilterButton,
  FilterIcon,
} from './SearchBar.styles'

export const getPlaceholderByPath = (path: string) => {
  switch (path) {
    case routes.WORKSPACE_TRASH.template():
      return 'Search all documents in Trash'
    case routes.WORKSPACE_LIBRARIES.template():
    case routes.DESIGN_SYSTEM.template():
      return 'Search Libraries'
    case routes.WORKSPACE_SHARED_WITH_ME.template():
      return 'Search Shared with Me'
    case routes.WORKSPACE_TEMPLATES.template():
      return 'Search Templates'
    case routes.WORKSPACE_ARCHIVE.template():
      return 'Search archived projects'
    case routes.WORKSPACE_DRAFTS.template():
      return 'Search My Drafts'
    default:
      return 'Search'
  }
}

const SyncFiltersVisibilityToContext: React.FC = () => {
  const { isExpanded } = useListboxContext()
  const { setFiltersVisible } = useSearchExpanded()

  React.useEffect(() => {
    setFiltersVisible(isExpanded)
  }, [isExpanded, setFiltersVisible])

  return null
}

const FiltersMobileModal: React.FC = ({ children }) => {
  const { hideModal } = useModalContext()

  return (
    <Modal onCancel={hideModal}>
      <Modal.Body>
        {children}
        <Box pt={6}>
          <Button variant="secondary" fill onClick={hideModal}>
            Done
          </Button>
        </Box>
      </Modal.Body>
    </Modal>
  )
}

interface FiltersProps {
  userCanToggleProjects: boolean
}

const Filters: React.FC<FiltersProps> = ({
  userCanToggleProjects,
}: FiltersProps) => {
  const isTabletAndBigger = useForTablet()
  const { showModal } = useModalContext()
  const { path, params } = useRouteMatch<RouteParams<'WORKSPACE_SHARES'>>()

  const { filters, setFilters } = useSearchFilters()
  const hasFilters = filters.length > 0

  const { data, updateQuery } = useGetDocumentListSettingsQuery()
  const { showDocumentsInProjects = true } = data || {}
  const { hideCollections, setHideCollections } = useHideCollectionsSetting()

  const workspaceId = params.workspaceId

  const { data: workspaceData } = useGetWorkspaceQuery({
    variables: { identifier: workspaceId },
    fetchPolicy: 'cache-only',
  })

  const isArchiveEnabled =
    workspaceData?.workspace.features.archiveEnabled ?? false

  const canResetShowDocumentsInProjects = isWorkspaceRoute(path)
  const canResetHideCollections =
    isProjectRoute(path) || isProjectTrashRoute(path) || isDraftsRoute(path)

  const toggleFilter = (filter: ShareSearchFilter) => {
    if (filters.includes(filter)) {
      setFilters(filters.filter(x => x !== filter))
    } else {
      setFilters([filter, ...filters])
    }
  }

  const clearFilters = () => {
    setFilters([])
  }

  const reset = () => {
    clearFilters()

    if (canResetShowDocumentsInProjects) {
      updateQuery(previous => ({
        ...previous,
        showDocumentsInProjects: true,
      }))
    }

    if (canResetHideCollections) {
      setHideCollections(false)
    }
  }

  const resetActive =
    hasFilters ||
    (canResetShowDocumentsInProjects && showDocumentsInProjects === false) ||
    (canResetHideCollections && hideCollections === true)

  const filterContent = (
    <>
      <StyledDropdownHeader>
        <span>Documents</span>
        <Tooltip content="Reset" placement="top" disableWhenTouchDevice>
          <IconButton onClick={reset}>
            <StyledResetIcon $active={resetActive} />
          </IconButton>
        </Tooltip>
      </StyledDropdownHeader>

      <StyledDropdownItem>
        <StyledCheckbox
          checked={filters.length === 0}
          label="All documents"
          onChange={() => clearFilters()}
        />
      </StyledDropdownItem>

      <StyledDropdownItem>
        <StyledCheckbox
          checked={filters.includes('HAS_PROTOTYPES')}
          label="With prototypes"
          onChange={() => toggleFilter('HAS_PROTOTYPES')}
        />
      </StyledDropdownItem>

      <StyledDropdownItem>
        <StyledCheckbox
          checked={filters.includes('I_CAN_EDIT')}
          label="You can edit"
          onChange={() => toggleFilter('I_CAN_EDIT')}
        />
      </StyledDropdownItem>

      <Switch>
        <Route
          path={[
            routes.WORKSPACE_SHARES.template(),
            routes.WORKSPACE_LIBRARIES.template(),
            routes.WORKSPACE_SHARED_WITH_ME.template(),
            routes.WORKSPACE_TEMPLATES.template(),
          ]}
        >
          {userCanToggleProjects && (
            <ToggleProjectsCheckbox isArchiveEnabled={isArchiveEnabled} />
          )}
        </Route>

        <Route
          path={[
            routes.WORKSPACE_PROJECT.template(),
            routes.WORKSPACE_TRASH_PROJECT.template(),
            routes.WORKSPACE_DRAFTS.template(),
          ]}
          exact
        >
          <ToggleCollectionsCheckbox />
        </Route>
      </Switch>
    </>
  )

  // show modal if mobile
  if (!isTabletAndBigger) {
    return (
      <FilterWrapper>
        <SelectInput>
          {({ isExpanded }) => (
            <Tooltip content={`Options`} placement="bottom">
              <FilterButton
                $isActive={isExpanded || hasFilters}
                data-testid="filter-dropdown"
                onClick={() =>
                  showModal(FiltersMobileModal, {
                    children: filterContent,
                  })
                }
              >
                <FilterIcon />
              </FilterButton>
            </Tooltip>
          )}
        </SelectInput>
      </FilterWrapper>
    )
  }

  return (
    <SelectInput
      data-testid="filter-dropdown"
      value="Filter"
      onKeyUp={() => null}
    >
      {({ isExpanded }) => (
        <>
          <SyncFiltersVisibilityToContext />
          <SelectTriggerUnstyled>
            <FilterWrapper>
              <Tooltip
                content={`Options`}
                placement="bottom"
                disabled={isExpanded}
              >
                <FilterButton
                  data-testid="filter-dropdown"
                  $isActive={isExpanded || hasFilters}
                >
                  <FilterIcon />
                </FilterButton>
              </Tooltip>
            </FilterWrapper>
          </SelectTriggerUnstyled>
          <StyledSelectPopover position={positionDefault}>
            {filterContent}
          </StyledSelectPopover>
        </>
      )}
    </SelectInput>
  )
}

interface ToggleProjectsCheckboxProps {
  isArchiveEnabled: boolean
}

const ToggleProjectsCheckbox: React.FC<ToggleProjectsCheckboxProps> = ({
  isArchiveEnabled,
}) => {
  const { search } = useSearch()
  const { data, updateQuery } = useGetDocumentListSettingsQuery()
  const { showDocumentsInProjects, showArchivedDocuments = false } = data || {}

  return (
    <>
      <Dropdown.Divider />

      <StyledDropdownItem>
        <StyledCheckbox
          checked={showDocumentsInProjects}
          label="Include documents in projects"
          onChange={() =>
            updateQuery(previous => ({
              ...previous,
              showDocumentsInProjects: !showDocumentsInProjects,
            }))
          }
        />
      </StyledDropdownItem>

      {isArchiveEnabled && (
        <StyledDropdownItem>
          <StyledCheckbox
            checked={showArchivedDocuments || !!search}
            disabled={!!search}
            label="Include archived documents"
            onChange={() =>
              updateQuery(previous => ({
                ...previous,
                showArchivedDocuments: !showArchivedDocuments,
              }))
            }
          />
        </StyledDropdownItem>
      )}
    </>
  )
}

const ToggleCollectionsCheckbox: React.FC<{}> = () => {
  const { hideCollections, setHideCollections } = useHideCollectionsSetting()

  const toggleHideCollections = () => {
    setHideCollections(!hideCollections)
  }

  return (
    <>
      <Dropdown.Divider />

      <StyledDropdownItem>
        <StyledCheckbox
          checked={hideCollections}
          label="Hide Collections"
          help="Documents in collections will appear in the project."
          onChange={toggleHideCollections}
        />
      </StyledDropdownItem>
    </>
  )
}

export interface SearchBarProps {
  userRole: WorkspaceMinimalFragment['userRole']
  fullWidth?: boolean
}

export const SearchBar: React.FC<SearchBarProps> = ({
  userRole,
  fullWidth,
}: SearchBarProps) => {
  const { search, setSearch } = useSearch()
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const userCanToggleProjects = userRole !== 'GUEST'
  const { path } = useRouteMatch()
  const inputPlaceholder = getPlaceholderByPath(path)

  return (
    <Tooltip
      content={
        <>
          Search
          <TooltipShortcut>
            Press {shortcuts.search.toUpperCase()}
          </TooltipShortcut>
        </>
      }
      disableWhenTouchDevice
      placement="bottom-start"
      visible={tooltipVisible}
    >
      <div
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <SearchInput
          onChange={setSearch}
          value={search}
          name="search"
          placeholder={inputPlaceholder}
          fullWidth={!!fullWidth}
        >
          <Route
            path={[
              routes.WORKSPACE_SHARES.template(),
              routes.WORKSPACE_DRAFTS.template(),
              routes.WORKSPACE_LIBRARIES.template(),
              routes.WORKSPACE_SHARED_WITH_ME.template(),
              routes.WORKSPACE_TEMPLATES.template(),
              routes.WORKSPACE_PROJECT.template(),
              routes.WORKSPACE_TRASH_PROJECT.template(),
              routes.WORKSPACE_COLLECTION.template(),
              routes.WORKSPACE_TRASH_COLLECTION.template(),
            ]}
          >
            <div onMouseEnter={() => setTooltipVisible(false)}>
              <Filters userCanToggleProjects={userCanToggleProjects} />
            </div>
          </Route>
        </SearchInput>
      </div>
    </Tooltip>
  )
}

import styled from 'styled-components';
import '../../Button/index.js';
import '../../Input/index.js';
import { ReactComponent } from '@sketch/icons/globe-24';
import { ReactComponent as ReactComponent$1 } from '@sketch/icons/checkmark-24';
import { ReactComponent as ReactComponent$2 } from '@sketch/icons/trash-empty-24';
import { Input as Input$1 } from '../../Input/Input.js';
import { Button as Button$1 } from '../../Button/Button.js';

const Form = styled.form `
  display: flex;
  align-items: center;
`;
const Input = styled(Input$1) `
  && {
    width: 240px;
  }

  height: 32px;
  font-size: 0.8125rem;
`;
const Button = styled(Button$1).attrs({ size: '32' }) `
  width: 40px;
  height: 32px;

  && {
    margin-left: 8px;
  }

  &:disabled {
    opacity: 0.6;
  }
`;
const UrlIcon = styled(ReactComponent) `
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`;
const SubmitIcon = styled(ReactComponent$1) `
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.state.positive.A};
`;
const CancelIcon = styled(ReactComponent$2) `
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.state.negative.A};
`;

export { Button, CancelIcon, Form, Input, SubmitIcon, UrlIcon };

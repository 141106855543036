import { __awaiter } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { useRef, useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { IconPlaceholder } from './Icon.styles.js';
import { useIsMountedRef } from '@sketch/utils';

const ICON_MAP = {
    notebook: () => import('@sketch/icons/notebook-16'),
    diagonal: () => import('@sketch/icons/arrow-top-right-diagonal-16'),
    personsTwo: () => import('@sketch/icons/persons-two-16'),
    sketch: () => import('@sketch/icons/sketch-logo-16'),
    macAppDownload: () => import('@sketch/icons/mac-app-download-32'),
    persons: () => import('@sketch/icons/persons-2-48'),
    shield: () => import('@sketch/icons/shield-32'),
    document: () => import('@sketch/icons/document-upload-48'),
    workspaces: () => import('@sketch/icons/workspaces-24'),
    colorFan: () => import('@sketch/icons/color-fan-32'),
    bookOpen: () => import('@sketch/icons/book-open-32'),
    extension: () => import('@sketch/icons/extension-16'),
    star: () => import('@sketch/icons/star-16'),
    close: () => import('@sketch/icons/close'),
    timeline: () => import('@sketch/icons/tag-timeline-32'),
    calendar: () => import('@sketch/icons/calendar-16'),
    overlays: () => import('@sketch/icons/prototyping-overlays-32'),
    duplicateDocs: () => import('@sketch/icons/duplicate-document-32'),
    overrides: () => import('@sketch/icons/overrides-32'),
    annotations: () => import('@sketch/icons/annotations-32'),
    arrowRight: () => import('@sketch/icons/arrow-circle-right-16'),
    smartLayout: () => import('@sketch/icons/smart-layout-32'),
};
const Icon = ({ name, size = '16px', className, }) => {
    const ImportedIconRef = useRef();
    const [loading, setLoading] = useState(false);
    const isMounted = useIsMountedRef();
    useEffect(() => {
        setLoading(true);
        const importIcon = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                ImportedIconRef.current = (yield ICON_MAP[name]()).ReactComponent;
            }
            catch (err) {
                Sentry.captureMessage(`Discover page icon not found. Icon name used: ${name}`);
            }
            finally {
                isMounted.current && setLoading(false);
            }
        });
        importIcon();
    }, [name, isMounted]);
    if (!loading && ImportedIconRef.current) {
        const { current: ImportedIcon } = ImportedIconRef;
        return (jsx(ImportedIcon, { className: className, role: "img", "aria-roledescription": "section icon", width: size, height: size }));
    }
    return jsx(IconPlaceholder, { width: size, height: size });
};

export { Icon as default };

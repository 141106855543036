import { __awaiter } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { useState, useCallback, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { captureException } from '@sentry/core';
import { StripeContext } from './StripeContext.js';
import { regular } from '@sketch/global-styles/fonts';

const StripeElementsProvider = props => {
    const { children, stripe, status, load } = props;
    return (jsx(Elements, Object.assign({ stripe: stripe || null, options: {
            fonts: [
                {
                    src: `url(${regular}) format('woff2')`,
                    family: 'Marfa',
                    display: 'optional',
                    style: 'normal',
                    weight: 'normal',
                },
            ],
        } }, { children: jsx(ElementsConsumer, { children: ({ stripe, elements }) => (jsx(StripeContext.Provider, Object.assign({ value: {
                    status,
                    load,
                    // Make sure the stripe elements are consistent with the context provider
                    elements: elements || undefined,
                    stripe: stripe || undefined,
                } }, { children: children }))) }) })));
};
const StripeProvider = props => {
    const { children, stripeKey } = props;
    const [stripe, setStripe] = useState(undefined);
    const [status, setStatus] = useState('pending');
    const load = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const stripe = yield loadStripe(stripeKey);
            if (stripe) {
                setStripe(stripe);
                setStatus('success');
                return stripe;
            }
            throw new Error('Stripe failed to initialize');
        }
        catch (error) {
            setStatus('error');
            captureException(error);
            throw error;
        }
    }), [stripeKey]);
    return (jsx(StripeElementsProvider, Object.assign({ stripe: stripe, load: load, status: status }, { children: children })));
};
const useStripe = () => useContext(StripeContext);

export { StripeElementsProvider, StripeProvider, useStripe };

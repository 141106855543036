import styled from 'styled-components';
import { breakpoint } from '@sketch/global-styles';

const getBackgroundColor = (type, theme) => {
    switch (type) {
        case 'positive':
            return theme.colors.brand.green.default.C;
        case 'neutral':
            return theme.colors.state.neutral.F;
    }
};
const getColor = (type, theme) => {
    switch (type) {
        case 'positive':
            return theme.colors.brand.green.foreground;
        case 'neutral':
            return theme.colors.state.warning;
    }
};
const getBorderColor = (type, theme) => {
    switch (type) {
        case 'positive':
            return theme.colors.state.positive.E;
        case 'neutral':
            return theme.colors.state.neutral.E;
    }
};
const IconWrapper = styled.div `
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
  line-height: 0;
  flex-shrink: 0;
`;
const Wrapper = styled.div `
  ${({ theme, $rounded }) => $rounded ? `border-radius: ${theme.radii.large}` : ''};

  ${({ theme, $type, $borders }) => $borders.map(b => `border-${b}: 1px solid ${getBorderColor($type, theme)};`)};

  background-color: ${({ theme, $type }) => getBackgroundColor($type, theme)};
  padding: ${({ $size }) => ($size === 'tiny' ? '8px' : '16px')} 12px;
  display: flex;
  align-items: center;

  ${breakpoint('base', 'xs') `
    align-items: start;
  `}

  ${IconWrapper} {
    color: ${({ theme, $type }) => getColor($type, theme)};
  }
`;
const Description = styled.span `
  font-size: ${({ theme }) => theme.fontSizes.D};
  color: ${({ theme }) => theme.colors.foreground.secondary.B};
  font-weight: 400;
  line-height: 1.4;
`;
const BannerContent = styled.div `
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;

  ${breakpoint('base', 'xs') `
    flex-direction: column;
    align-items: flex-start;
  `}
`;
const DescriptionRow = styled.div `
  display: flex;
`;
const ButtonWrapper = styled.div `
  margin-left: 24px;

  ${breakpoint('base', 'xs') `
    margin: 12px 0 0 16px;
    padding-left: 6px;
  `}
`;

export { BannerContent, ButtonWrapper, Description, DescriptionRow, IconWrapper, Wrapper };

import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useBreakpoint, Dropdown } from '@sketch/components'

import { useVersioning } from 'modules/versioning'

import { IS_EMBEDDED } from '@sketch/constants'
import {
  isCwvRouteOnly,
  isDocumentRoute,
  isDocumentPageRoute,
} from '@sketch/modules-common'

import {
  DropdownItem,
  ViewWrapper,
  PrototypeIcon,
  StyledSeparator,
  ComponentsIcon,
  PageIcon,
} from '../HeaderNavigation/NavigationItem.styles'

import {
  COMPONENTS_TEXT,
  PAGES_TEXT,
  PROTOTYPES_TEXT,
} from '../HeaderNavigation/PagesNavigationItem/constants'
import PageList from '../PageList'

interface ViewsSelectorProps {
  shareIdentifier: string
  isMobile: boolean
}

const ViewsSelector: React.FC<ViewsSelectorProps> = ({
  shareIdentifier,
  isMobile,
}) => {
  const { path } = useRouteMatch()

  const { getPathname } = useVersioning()!

  const copyPerItem = {
    pages: PAGES_TEXT,
    components: COMPONENTS_TEXT,
    prototypes: PROTOTYPES_TEXT,
  }

  return (
    <ViewWrapper data-testid="views-dropdown">
      {isMobile && <StyledSeparator />}
      {!isMobile && <Dropdown.Header>Views</Dropdown.Header>}
      {!isMobile && (
        <DropdownItem
          key={PAGES_TEXT}
          to={getPathname({
            routeKey: 'SHARE_VIEW',
            routeParams: { shareID: shareIdentifier },
          })}
          isActive={() => isDocumentRoute(path) || isDocumentPageRoute(path)}
        >
          <PageIcon />
          {copyPerItem['pages']}
        </DropdownItem>
      )}
      <DropdownItem
        to={getPathname({
          routeKey: 'CWV_SYMBOLS',
          routeParams: { shareID: shareIdentifier },
        })}
        isActive={() => isCwvRouteOnly(path)}
      >
        <ComponentsIcon />
        {copyPerItem['components']}
      </DropdownItem>
      <DropdownItem
        to={getPathname({
          routeKey: 'SHARE_PROTOTYPES',
          routeParams: { shareID: shareIdentifier },
        })}
      >
        <PrototypeIcon />
        {copyPerItem['prototypes']}
      </DropdownItem>
    </ViewWrapper>
  )
}

interface PagesDropdownProps {
  shareIdentifier: string
}

/**
 * Views selector (Pages, Components and Prototypes) for header navigation.
 * For mobile views, also includes pages list.
 */
export const PagesDropdown: React.FC<PagesDropdownProps> = ({
  shareIdentifier,
}) => {
  const isMobile = !useBreakpoint('xs')

  const showPagesList = isMobile || IS_EMBEDDED

  return (
    <>
      {showPagesList && (
        <div>
          <Dropdown.Header>{PAGES_TEXT}</Dropdown.Header>
          <PageList isDropdown />
        </div>
      )}

      {!IS_EMBEDDED && (
        <ViewsSelector shareIdentifier={shareIdentifier} isMobile={isMobile} />
      )}
    </>
  )
}

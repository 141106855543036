import styled from 'styled-components';

const Image = styled.img `
  width: ${({ theme }) => theme.sidebar.indexLayoutSidebar}px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export { Image };

import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const getColor = ({ variant, theme, hover = false }) => {
    const colors = {
        primary: theme.colors.sketch.A,
        secondary: theme.colors.foreground.secondary.A,
        tertiary: theme.colors.foreground.secondary.C,
        // `quaternary` is used for prototypes, so it's used in dark mode always,
        // that's why we can't use theme values
        quaternary: 'hsl(0, 0%, 100%)',
        hover: {
            primary: theme.colors.sketch.C,
            secondary: theme.colors.foreground.secondary.A,
            tertiary: theme.colors.foreground.secondary.D,
            // `quaternary` is used for prototypes, so it's used in dark mode always,
            // that's why we can't use theme values
            quaternary: 'hsla(0, 0%, 100%, .7)', // colors.foreground.primary.B
        },
    };
    if (hover) {
        return colors.hover[variant];
    }
    return colors[variant];
};
const baseLinkStyle = css(({ theme, $isUnderlined, $variant, $size }) => css `
    color: ${getColor({ theme, variant: $variant })};
    font-weight: 600;
    text-decoration: ${$isUnderlined ? 'underline' : undefined};
    cursor: pointer;

    :hover {
      color: ${getColor({ theme, variant: $variant, hover: true })};
    }

    ${$size === 'small' &&
    css `
      font-size: ${theme.fontSizes.E};
    `};

    ${$size === 'smaller' &&
    css `
      font-size: ${theme.fontSizes.D};
    `};

    ${$size === 'tiny' &&
    css `
      font-size: ${theme.fontSizes.D};
    `};
  `);
const InternalLink = styled(Link) `
  ${baseLinkStyle}
`;
const ExternalLink = styled.a `
  ${baseLinkStyle}
`;

export { ExternalLink, InternalLink, baseLinkStyle };

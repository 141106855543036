import { __awaiter } from 'tslib';
import { GetInitialUserDocument } from '@sketch/gql-types';
import { ErrorHandler } from '@sketch/tracing';
import { useApolloClient } from 'react-apollo';
import { castError } from '@sketch/utils';

/**
 *
 * A promisified getInitialUser, used to fetch the user data always using the network
 * Useful when we need to fetch user data after manually setting authorizations
 *
 * Example: useSsoSuccessfulSignIn  / VerificationCodeView
 */
const useFetchInitialUser = () => {
    const apolloClient = useApolloClient();
    return () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield apolloClient.query({
                query: GetInitialUserDocument,
                fetchPolicy: 'network-only',
            });
            return data === null || data === void 0 ? void 0 : data.me;
        }
        catch (e) {
            const err = castError(e);
            ErrorHandler.ignore(err, 'Unable to get initial user');
            return undefined;
        }
    });
};

export { useFetchInitialUser };

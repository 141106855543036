class Subscription {
    constructor() {
        this.listeners = [];
        this.subscribe = (callback) => {
            this.listeners.push(callback);
        };
        this.unsubscribe = (callback) => {
            const index = this.listeners.indexOf(callback);
            if (index === -1)
                return;
            this.listeners.splice(index, 1);
        };
        this.notify = () => {
            const listeners = [...this.listeners];
            for (const callback of listeners) {
                callback();
            }
        };
    }
}

export { Subscription };

import styled, { keyframes, css } from 'styled-components';
import '../Box/index.js';
import { breakpoint } from '@sketch/global-styles';
import { Text } from '../Box/BoxSystem.js';

// Animations
const blink = keyframes `
  0% { border-color: black;}
  50% { border-color: black;}
  51% { border-color: transparent;}
  100% { border-color: transparent;}
`;
const Wrapper = styled.div `
  position: relative;
`;
const HiddenInput = styled.input `
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: transparent;
  border: 0;
  outline: none;
`;
const Digits = styled.div `
  display: flex;
  justify-content: center;
`;
const Error = styled(Text).attrs({
    textStyle: 'copy.negative.standard.C',
}) `
  padding-top: 8px;
`;
const Digit = styled.span `
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 68px;
  width: 100%;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: 32px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-align: center;
  background: ${({ theme, $isActive }) => $isActive
    ? `${theme.colors.background.secondary.A}`
    : `${theme.colors.background.secondary.B}`};
  border: ${({ theme }) => `1px solid ${theme.colors.border.B}`};
  border-radius: ${({ theme }) => theme.radii.large};
  box-shadow: inset 0px 1px 2px 0px ${({ theme }) => theme.colors.shadow.inner};
  opacity: ${({ $isDisabled }) => ($isDisabled ? `0.5` : `1`)};

  /* Transition timings and duration */
  transition: ${({ theme }) => css `
    border ${theme.transitions.duration[1]} ${theme.transitions.timing.easeInOut},
    background ${theme.transitions.duration[4]} ${theme.transitions.timing.easeInOut};
    `};

  :not(:last-child) {
    margin-right: 8px;
  }

  /* Keep an Aspect Ratio of 0.8*/
  ::before {
    float: left;
    padding-top: 124%;
    content: '';
  }
  ::after {
    display: block;
    content: '';
    clear: both;
  }

  ${({ theme }) => breakpoint('base', 'xs') `
    font-size: ${theme.fontSizes.H};
  `}

  /* Error state */
  ${({ theme, $hasError }) => {
    if ($hasError) {
        return css `
        border: 2px solid ${theme.colors.state.negative.A};
      `;
    }
}}

  /* Active state */
    ${({ theme, $isActive, $isDisabled }) => {
    if ($isActive && !$isDisabled) {
        return css `
        border: 2px solid ${theme.colors.sketch.A};

        /* Blinking cursor */
        ::after {
          content: '';
          margin: 0;
          padding: 0;
          border-right: 1px solid black;
          animation: ${blink} 1s linear infinite;
        }
      `;
    }
}}
`;

export { Digit, Digits, Error, HiddenInput, Wrapper };

import React, { FC } from 'react'
import { withRouter } from 'react-router-dom'
import { IS_EMBEDDED } from '@sketch/constants'
import { RouteProps } from '@sketch/modules-common'
import { Location, History } from 'history'

import {
  useVersioning,
  isUpgradeToLatestNeeded,
  UpgradeToLatestVersion,
} from 'modules/versioning'
import { useGetSurroundingArtboardsQuery } from '@sketch/gql-types'
import { Pagination, PaginationControls } from '@sketch/components'

interface LocationState {
  fromOverview?: boolean
}

interface ArtboardPaginationControlsProps
  extends RouteProps<'ARTBOARD_REVISION'> {
  location: Location<LocationState>
  history: History
  current: number
  total: number
  showNumbers?: boolean
  onlyKeyboardNavigation?: boolean
}

const ArtboardPaginationControls: FC<ArtboardPaginationControlsProps> = ({
  match,
  location,
  history,
  current,
  total,
  ...rest
}) => {
  const {
    versionShortId,
    getPathname,
    setArtboardThumbnail,
    artboardThumbnail,
  } = useVersioning()
  const { shareID, permanentArtboardShortId } = match.params

  const fromOverview = location.state?.fromOverview

  const navigateTo = (pathname: string) =>
    history.push({
      pathname: pathname,
      state: { fromOverview },
    })

  const { data: artboardData, error } = useGetSurroundingArtboardsQuery({
    variables: {
      permanentArtboardShortId: permanentArtboardShortId,
      documentVersionShortId: versionShortId,
      shareIdentifier: shareID,
    },
  })

  if (isUpgradeToLatestNeeded(error)) {
    return <UpgradeToLatestVersion error={error} />
  }

  const nextArtboardShortId =
    artboardData?.artboard?.nextArtboard?.permanentArtboardShortId

  const previousArtboardShortId =
    artboardData?.artboard?.previousArtboard?.permanentArtboardShortId

  const currentPage = current ? current + 1 : 1

  const clearThumbnail = () =>
    artboardThumbnail !== '' && setArtboardThumbnail('')

  const handleClickNext = nextArtboardShortId
    ? () => {
        const nextPathname = getPathname({
          routeKey: 'ARTBOARD_DETAIL',
          routeParams: {
            shareID: shareID,
            permanentArtboardShortId: nextArtboardShortId,
          },
        })

        clearThumbnail()
        navigateTo(nextPathname)
      }
    : undefined

  const handleClickPrevious = previousArtboardShortId
    ? () => {
        const previousPathname = getPathname({
          routeKey: 'ARTBOARD_DETAIL',
          routeParams: {
            shareID: shareID,
            permanentArtboardShortId: previousArtboardShortId,
          },
        })
        clearThumbnail()
        navigateTo(previousPathname)
      }
    : undefined

  /*
    TODO: Remove keyboard-only functionality when the new pagination UI is in place
    (https://github.com/sketch-hq/Cloud/issues/13346)
  */
  if (IS_EMBEDDED && !rest.onlyKeyboardNavigation) {
    return (
      <Pagination
        current={currentPage}
        total={total}
        onClickNext={handleClickNext}
        onClickPrev={handleClickPrevious}
      />
    )
  }

  return (
    <PaginationControls
      current={currentPage}
      total={total}
      onClickNext={handleClickNext}
      onClickPrevious={handleClickPrevious}
      {...rest}
    />
  )
}

export default withRouter(ArtboardPaginationControls)

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import React from 'react';
import { ListItem, DropdownItemStyle, DropdownItemAnchorStyle, DropdownItemNavLinkStyle } from './DropdownItem.styles.js';
export { ListItem } from './DropdownItem.styles.js';

const DropdownItem = React.forwardRef(function DropdownItem(_a, ref) {
    var { unmountAfterClick = true } = _a, props = __rest(_a, ["unmountAfterClick"]);
    return (jsx(ListItem, Object.assign({ ref: ref }, { children: jsx(DropdownItemStyle, Object.assign({ tabIndex: 0, type: "button" // needs to be data- so it can be picked up on the DOM
            , "data-unmountafterclick": unmountAfterClick }, props, { children: props.children })) })));
});
const DropdownItemAnchor = (props) => (jsx(ListItem, { children: jsx(DropdownItemAnchorStyle, Object.assign({}, props, { children: props.children })) }));
const DropdownItemNavLink = (_a) => {
    var { unmountAfterClick = true } = _a, props = __rest(_a, ["unmountAfterClick"]);
    return (jsx(ListItem, { children: jsx(DropdownItemNavLinkStyle, Object.assign({ "data-unmountafterclick": unmountAfterClick }, props, { children: props.children })) }));
};

export { DropdownItem, DropdownItemAnchor, DropdownItemNavLink };

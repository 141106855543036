import React from 'react'
import { useFlag } from '@sketch/modules-common'

/**
 * This HOC is to facilitate the usage of ui-refresh-24 FF within styled components.
 *
 * This will be removed once the FF is fully rolled out:
 * https://github.com/sketch-hq/Cloud/issues/18870
 */
export const withRefreshedUiFF = (
  Component: React.ComponentType<{ $isRefreshedUi?: boolean }>
) => {
  const Wrapper: React.FC = props => {
    const isRefreshedUi = useFlag('ui-refresh-24')

    return <Component $isRefreshedUi={isRefreshedUi} {...props} />
  }

  return Wrapper
}

import React from 'react'

import { useModalContext, useResponsiveDropdown } from '@sketch/components'

import { ManageCollectionModal } from '../../modals'
import { CollectionDropdownContent } from '../CollectionDropdown'

import {
  HeaderButton,
  Dots,
  ButtonWrapper,
} from 'modules/projects/components/ProjectHeader/ProjectHeader.styles'

import { CollectionHeaderActionsProps } from './types'

export const CollectionHeaderActions: React.FC<CollectionHeaderActionsProps> = ({
  project,
  workspace,
  collection,
  userCanManageCollection,
}) => {
  const { showModal } = useModalContext()

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: CollectionDropdownContent,
    dropdownProps: {
      workspace,
      project,
      collection,
      userCanManageCollection,
    },
    usePortal: true,
    placement: 'bottom-start',
  })

  const handleManageCollection = () => {
    showModal(ManageCollectionModal, {
      workspaceIdentifier: workspace.identifier,
      project,
      collection,
    })
  }

  return (
    <ButtonWrapper>
      <HeaderButton onClick={handleManageCollection}>
        Manage Collection
      </HeaderButton>
      <>
        <HeaderButton {...buttonProps} data-testid="collection-other-actions">
          <span className="sr-only">Other Actions</span>
          <Dots />
        </HeaderButton>
        {content}
      </>
    </ButtonWrapper>
  )
}

import React from 'react'
import {
  TextAlignImg as Icon,
  TextAlignContainer as Container,
} from './TextAlignValue'
import { TextTransform as TextTransformEnum } from 'modules/shares/types'

import { ReactComponent as Lowercase } from '@sketch/icons/textformat-lowercase-16'
import { ReactComponent as Uppercase } from '@sketch/icons/textformat-uppercase-16'

export const TextTransform = ({
  transform,
  label,
}: {
  transform: TextTransformEnum
  label: string
}) => {
  const renderImage = () => {
    switch (transform) {
      case TextTransformEnum.Lowercase:
        return <Icon as={Lowercase} />
      case TextTransformEnum.Uppercase:
        return <Icon as={Uppercase} />
      default:
        return null
    }
  }
  return (
    <Container>
      {renderImage()}
      <span aria-label={`${label} ${transform}`}>{transform}</span>
    </Container>
  )
}

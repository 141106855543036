import React from 'react'

import { thumbnailForPreviewFile } from 'modules/shares/utils'

import {
  Breakpoint,
  Checkbox,
  LoadingPlaceholder,
  SingleBreakpoint,
  Text,
  useResponsiveDropdown,
} from '@sketch/components'

import {
  Image,
  ImageWrapper,
  Name,
  NameWrapper,
  TableWrapper,
} from 'modules/shares/components/DocumentItem/DocumentItem.styles'
import {
  StyledDropdownButton,
  DropdownWrapper,
  DropdownButtonEllipsis,
  StyledTableCellCheckbox,
  StyledTableCellDocument,
  StyledTableCellFixed,
  StyledTableCellButton,
  DetailsWrapper,
} from './LargeDocumentListItem.styles'
import { ShareWithSizeFragment } from '@sketch/gql-types'
import LargeDocumentItemDropdown from '../LargeDocumentItemDropdown'
import { formatStorageNumber } from '../../../../utils/storage'

export interface CommonProps {
  onChange: (identifier: string) => void
  isSelectAll?: boolean
}

const DROPDOWN_MODIFIERS = [
  {
    name: 'computeStyles',
    options: { adaptive: true, gpuAcceleration: true },
  },
]

interface LargeDocumentListItemProps extends CommonProps {
  shareWithSize: ShareWithSizeFragment
  checked: boolean
}

const LargeDocumentListItem: React.FC<LargeDocumentListItemProps> = ({
  shareWithSize,
  onChange,
  checked,
  isSelectAll,
}: LargeDocumentListItemProps) => {
  const share = shareWithSize.share
  const size = shareWithSize.size

  const previewFile = share.version?.document?.previewFile
  const image = thumbnailForPreviewFile(previewFile)

  const showOpenInSketchItem =
    share.version?.document?.userCanOpenInApp ?? false

  const numberOfVersions = share.versions?.meta.totalCount ?? 0

  const handleChange = () => {
    // do not allow interaction when selectAll is active
    if (isSelectAll) {
      return
    }

    onChange(share.identifier)
  }

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: LargeDocumentItemDropdown,
    dropdownProps: {
      identifier: share.identifier,
      showOpenInSketchItem,
    },
    modifiers: DROPDOWN_MODIFIERS,

    placement: 'bottom-end',
  })

  return (
    <TableWrapper
      data-testid="selectable-document-list-item"
      onClick={handleChange}
    >
      <StyledTableCellCheckbox>
        <Checkbox
          onChange={handleChange}
          // We are stopping propagation to prevent the row from being selected / deselected
          // when the checkbox is clicked. The row onClick handler will handle the selection.
          onClick={event => event?.stopPropagation()}
          value={share.identifier}
          checked={isSelectAll ? true : checked}
          name={share.name}
          // do not allow checkboxes to be unticked if selectAll is active
          disabled={isSelectAll}
        />
      </StyledTableCellCheckbox>
      <StyledTableCellDocument>
        <NameWrapper>
          {image && (
            <ImageWrapper title={share.name}>
              <Image
                alt={share.name}
                src={image}
                loadingPlaceholder={<LoadingPlaceholder size="16px" />}
                height={32}
              />
            </ImageWrapper>
          )}

          <DetailsWrapper>
            <Name>{share.name}</Name>
            <SingleBreakpoint breakpoint="xs">
              <Text textStyle="copy.quaternary.standard.D">
                {numberOfVersions} Versions - {formatStorageNumber(size)} GB
              </Text>
            </SingleBreakpoint>
          </DetailsWrapper>
        </NameWrapper>
      </StyledTableCellDocument>
      <Breakpoint on="xs">
        <StyledTableCellFixed>{numberOfVersions}</StyledTableCellFixed>
        <StyledTableCellFixed>{formatStorageNumber(size)}</StyledTableCellFixed>
      </Breakpoint>
      <StyledTableCellButton>
        <DropdownWrapper onClick={event => event.stopPropagation()}>
          <StyledDropdownButton {...buttonProps}>
            <DropdownButtonEllipsis aria-label="Document options" />
            <span className="sr-only">Document Settings</span>
          </StyledDropdownButton>
          {content}
        </DropdownWrapper>
      </StyledTableCellButton>
    </TableWrapper>
  )
}

export default LargeDocumentListItem

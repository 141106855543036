import React from 'react'
import { useForTablet, Navbar } from '@sketch/components'
import { ShareWithoutVersion } from 'modules/versioning'
import BackButton, { useBackButton } from 'modules/shares/components/BackButton'
import HeaderBadge from 'modules/shares/components/HeaderBadge'
import * as S from './PrototypeHeader.styles'
import { PrototypeFlowSelector } from '../PrototypeFlowSelector'
import { PrototypeControls } from '../PrototypeControls'
import { ArtboardLink } from '../ArtboardLink'
import { ResizeModeSelector } from '../ResizeModeSelector'
import { NavbarEnterFullScreenButton } from '../NavbarEnterFullScreenButton'
import { SharePrototype } from '../SharePrototype'
import { usePrototypeContext } from '../../hooks'
import { AnnotationButton } from '../AnnotationButton'
import { AnnotationMobileMenu } from '../AnnotationMobileMenu'
import { useIsAnnotationContextAvailable } from 'modules/annotations/hooks'

type PrototypeHeaderProps = {
  setSidebarLeftOpen?: React.Dispatch<React.SetStateAction<boolean>>
  share: ShareWithoutVersion
}

export const PrototypeHeader: React.FC<PrototypeHeaderProps> = ({
  setSidebarLeftOpen,
  share,
}) => {
  const isTabletAndBigger = useForTablet()
  const { link: shouldRenderBackButton } = useBackButton()
  const { isFullscreen } = usePrototypeContext()

  /**
   * On Annotation context the "disabled" checks if the user
   * has commenting permissions if it has not there's no
   * point on showing this
   */
  const annotationContextAvailable = useIsAnnotationContextAvailable()
  const showAnnotationItems = annotationContextAvailable

  if (isFullscreen) {
    return null
  }

  return (
    <Navbar data-testid="prototype-header">
      <Navbar.Section>
        <HeaderBadge
          workspace={share.workspace}
          setSidebarLeftOpen={setSidebarLeftOpen}
        />
        {isTabletAndBigger && shouldRenderBackButton && (
          <S.BackButtonItem>
            <BackButton />
          </S.BackButtonItem>
        )}
        <PrototypeFlowSelector />
      </Navbar.Section>

      {!isTabletAndBigger && (
        <S.Item>
          <ArtboardLink />
        </S.Item>
      )}

      {!isTabletAndBigger && showAnnotationItems && (
        <S.Item>
          <AnnotationMobileMenu />
        </S.Item>
      )}

      {isTabletAndBigger && (
        <Navbar.Section align="end">
          <S.Item>
            <PrototypeControls />
          </S.Item>
          <S.Item>
            <ResizeModeSelector />
          </S.Item>
          <S.Item>
            <SharePrototype />
          </S.Item>
          <S.Item>
            <ArtboardLink />
          </S.Item>
          <S.Item>
            <NavbarEnterFullScreenButton />
          </S.Item>

          {showAnnotationItems && (
            <S.Item>
              <AnnotationButton />
            </S.Item>
          )}
        </Navbar.Section>
      )}
    </Navbar>
  )
}

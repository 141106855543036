import React, { useState } from 'react'

import {
  Button,
  Text,
  Checkbox,
  Modal,
  ModalInjectedProps,
} from '@sketch/components'

import { useToast } from '@sketch/toasts'

import { useUpdateRestrictedUploadsStateMutation } from '@sketch/gql-types'

interface DisableUploadsModalProps extends ModalInjectedProps {
  workspaceId: string
}

/**
 * DisableUploadsModal.
 *
 * This component shows a modal to the user with the ability of enabling the "restricted uploads" feature.
 * To properly work this component should be call through the ModalContext API.
 *
 */
export const DisableUploadsModal: React.FC<DisableUploadsModalProps> = ({
  hideModal,
  workspaceId,
}) => {
  const [hasAcceptedNotice, setAccepted] = useState(false)
  const { showToast } = useToast()

  const [
    enableRestrictedUploads,
    { loading },
  ] = useUpdateRestrictedUploadsStateMutation({
    variables: {
      workspaceId,
      enableRestrictedUploads: true,
    },
    onCompleted: data => {
      showToast('Document uploads are now disabled for Editors', 'positive')
      hideModal()
    },
    onError: 'show-toast',
  })

  const handleOnClick = () => hasAcceptedNotice && enableRestrictedUploads({})

  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>Restrict Uploads to this Workspace?</Modal.Header>
      <Modal.Body>
        <Text>
          If you prefer not to use the Workspace to store your documents, you
          can restrict document uploads and save them all locally instead.
        </Text>
        <label className="sr-only" htmlFor="notice-check">
          Restrict document uploads
        </label>
        <Checkbox
          id="notice-check"
          checked={hasAcceptedNotice}
          help="Editors will be notified that they can continue to use the Mac app as usual,
            but won’t be able to update existing or upload new documents to the Workspace."
          onChange={() => setAccepted(!hasAcceptedNotice)}
        >
          Restrict document uploads
        </Checkbox>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={hideModal}
          type="button"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={!hasAcceptedNotice}
          loading={loading}
          onClick={handleOnClick}
        >
          Restrict Uploads
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

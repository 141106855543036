import { jsx, jsxs } from 'react/jsx-runtime';
import { ReactComponent } from '@sketch/icons/information-outline-16';
import { Wrapper, BannerContent, DescriptionRow, IconWrapper, Description, ButtonWrapper } from './BannerSection.styles.js';

const BannerSection = props => {
    const { className, children, type = 'neutral', icon = jsx(ReactComponent, {}), button, rounded = true, borders = [], size = 'smaller', } = props;
    return (jsx(Wrapper, Object.assign({ "data-testid": "banner-section", className: className, "$type": type, "$rounded": rounded, "$borders": borders, "$size": size }, { children: jsxs(BannerContent, { children: [jsxs(DescriptionRow, { children: [icon && jsx(IconWrapper, { children: icon }), jsx(Description, { children: children })] }), button && jsx(ButtonWrapper, { children: button })] }) })));
};

export { BannerSection };

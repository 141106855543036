import '../../routes/index.js';
import { routes } from '../../routes/routes.js';
import { normalizeToLocationObject } from '../../routes/utils/route.js';

const getRedirectIdFromPathname = (pathname) => {
    if (routes.WORKSPACE_INVITE.template() === pathname) {
        return 'workspace-invite';
    }
    else if (routes.SHARE_ACCEPT_INVITE_SIGN_IN.template() === pathname ||
        routes.SHARE_ACCEPT_INVITE_SIGN_UP.template() === pathname) {
        return 'document-invite';
    }
    else if (routes.PROJECT_ACCEPT_INVITE.template() === pathname) {
        return 'project-invite';
    }
};
/**
 * Parse the history location object and return
 * an enriched "RedirectState" object easier to work with.
 */
function getRedirectStateFromLocationObject(location) {
    const { pathname, search } = normalizeToLocationObject(location);
    const redirectId = getRedirectIdFromPathname(pathname);
    if (!pathname || !redirectId) {
        return null;
    }
    return {
        redirectId,
        pathname,
        search,
    };
}

export { getRedirectStateFromLocationObject };

import styled, { css } from 'styled-components';
import { Heading } from '@sketch/components';
import { breakpoint } from '@sketch/global-styles';

/**
 * IndexLayoutContent provides default spacing values and it is intended to use
 * at the views which are rendered inside IndexLayout.
 *
 * It is _optional_ to use this component and if any particular view needs different values
 * it is possible to define a custom container at that view.
 */
const IndexLayoutContent = styled.div `
  width: 100%;
  height: 100%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  ${({ paddingHorizontal }) => paddingHorizontal
    ? css `
          padding: 0px 16px;
        `
    : null}

  ${({ center }) => {
    switch (center) {
        case true:
            return css `
          justify-content: center;
          align-items: center;
        `;
        case 'vertical':
            return css `
          justify-content: center;
        `;
        case 'horizontal':
            return css `
          align-items: center;
        `;
        default:
            return '';
    }
}}

  ${({ marginTop }) => marginTop
    ? css `
          margin-top: 139px;
          ${breakpoint('base', 'sm') `
            margin-top: 33px;
          `};
        `
    : ''}
`;
const IndexLayoutSubtitle = styled(Heading.H2) `
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.H};
  line-height: 1.4;
  text-align: center;
  font-weight: 400;

  margin-top: 8px;
  margin-bottom: 24px;

  ${breakpoint('sm') `
      font-size: 1.25rem;
      margin-top: 8px;
  `}
`;
const IndexLayoutTitle = styled.h1(({ theme }) => css `
    margin-top: 0px;
    margin-bottom: 0;
    font-size: ${theme.fontSizes.I};
    text-align: center;
    line-height: 1;

    + :not(${ /* sc-selector */IndexLayoutSubtitle}) {
      margin-top: 32px;
    }

    + ${IndexLayoutSubtitle} {
      font-size: ${theme.fontSizes.F};
    }

    ${breakpoint('base', 'sm') `
      font-size: ${theme.fontSizes.H};
    `}
  `);

export { IndexLayoutContent, IndexLayoutSubtitle, IndexLayoutTitle };

import produce from 'immer';
import { GetUserSettingsDocument } from '@sketch/gql-types';
import { removeFromPaginated } from '@sketch/modules-common';

/**
 * This is an exact copy of cloud-frontend/src/modules/workspace/operations/useRemoveWorkspaceMember/userCache.ts
 * Once we have transitioned the workspace settings to the sub-app-settings, we will be able to remove userCache.ts
 */
const removeFromUserMemberships = (cache, memberId) => {
    try {
        const currentState = cache.readQuery({
            query: GetUserSettingsDocument,
        });
        if (!currentState) {
            return;
        }
        cache.writeQuery({
            query: GetUserSettingsDocument,
            data: produce(currentState, draftState => {
                if (draftState.me.workspaceMemberships) {
                    draftState.me.workspaceMemberships.entries = draftState.me.workspaceMemberships.entries.filter(x => x.identifier !== memberId);
                }
            }),
        });
    }
    catch (err) {
        // It is possible that query consisting "me.organizationMemberships" wasn't loaded,
        // therefore - there is nothing to
    }
};
const removeUserFromWorkspace = (cache, memberId, workspaceId) => {
    removeFromPaginated(cache, {
        identifier: memberId,
        __typename: 'WorkspaceMembership',
    }, {
        identifier: workspaceId,
        __typename: 'Workspace',
    });
};

export { removeFromUserMemberships, removeUserFromWorkspace };

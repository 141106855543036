const formatPrice = (value, { trimDecimalIfZero = true } = {}) => {
    const fullPrice = new Intl.NumberFormat(['en'], {
        style: 'currency',
        currency: 'usd',
        minimumFractionDigits: 2,
    }).format(value);
    return trimDecimalIfZero ? fullPrice.replace(/\D00$/, '') : fullPrice;
};
const formatPriceShort = (value, options) => formatPrice(parseInt(value, 10) / 100, options);

export { formatPrice, formatPriceShort };

import { useMemo } from 'react'
import { SketchElement, isLayerElement } from 'modules/inspector'

/**
 * Check if at least one layer in the artboard has exportable assets. This does not
 * count exportable assets at the artboard level.
 * This is use to decide if we want to show the "No exportable assets" message
 * or if we prefer to not show anything when the selected layer does not have any
 * exportable assets.
 * @see https://github.com/sketch-hq/Cloud/issues/6669#issuecomment-748183677
 *
 * Note: This hook is called from ArtboardDetailInspectorProvider and the value is stored
 * in the inspector context so you can read it directly from the `useInspectorContext` instead
 * of calling useHasAnyLayerWithExportableAssets somewhere else.
 */
export function useHasAnyLayerWithExportableAssets(
  sketchElementRootElement: SketchElement | null
) {
  return useMemo(() => {
    if (!sketchElementRootElement) {
      return false
    }
    return checkHasAnyLayerWithExportableAssets(sketchElementRootElement)
  }, [sketchElementRootElement])
}

/**
 * Traverse the tree until we find a layer with an exportable asset.
 */
function checkHasAnyLayerWithExportableAssets(
  sketchElement: SketchElement
): boolean {
  const hasExports = Boolean(sketchElement.exportFormats?.length)

  // We don't care about exports at the artboard/page level
  if (hasExports && isLayerElement(sketchElement)) {
    return true
  }

  for (const child of sketchElement.children) {
    const result = checkHasAnyLayerWithExportableAssets(child)
    if (result) {
      return true
    }
  }

  return false
}

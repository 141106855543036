import styled from 'styled-components';
import { ReactComponent } from '@sketch/icons/chevron-vertical-16';

const DropdownChevron = styled(ReactComponent).attrs({
    role: 'img',
}) `
  width: 16px;
  height: 16px;
`;

export { DropdownChevron };

import { WorkspaceMinimalFragment } from '@sketch/gql-types'

import { useIsProfileCreated } from 'modules/community/hooks'

import {
  useRefetchWorkspaceSettingsOnRouteChange,
  useWorkspaceSettings,
} from 'modules/workspace/operations'

import {
  useCanSubscribeViaStripe,
  workspaceSettingsPermissions,
} from 'modules/workspace/utils'

import { CanRenderOptions } from 'modules/workspace/types'

export const useMenuPermissions = ({
  workspace,
}: {
  workspace: WorkspaceMinimalFragment
}) => {
  const { isProfileCreated } = useIsProfileCreated(workspace.identifier)

  const { data: workspaceSettingsData, refetch } = useWorkspaceSettings(
    workspace
  )

  useRefetchWorkspaceSettingsOnRouteChange(refetch, workspace.identifier)

  const { workspace: workspaceSettings, customer } = workspaceSettingsData || {}

  const { source, customerProvider } = useCanSubscribeViaStripe(
    workspace.identifier,
    customer?.id
  )
  const {
    isFinance,
    isGuest,
    showWorkspaceSettingsBilling,
    showWorkspaceSettingsGeneral,
    showWorkspaceSettingsSSO,
    showWorkspaceSettingsPeople,
    showWorkspacePermissionGroup,
    showWorkspaceSettingsProfile,
  } = workspaceSettingsPermissions({
    workspace,
    customerProvider,
    settings: workspaceSettings,
    customer,
    source,
    isProfileCreated,
  })

  const canRender: CanRenderOptions = {
    generalMenu: showWorkspaceSettingsGeneral,
    billingMenu: showWorkspaceSettingsBilling,
    permissionGroups: showWorkspacePermissionGroup,
    SSOMenu: showWorkspaceSettingsSSO,
    peopleMenu: showWorkspaceSettingsPeople,
    profileMenu: showWorkspaceSettingsProfile,
  }

  return { isFinance, isGuest, canRender }
}

import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import {
  Button,
  Text,
  Link,
  pluralize,
  Banner,
  Section,
  useModalContext,
  ExternalLink,
} from '@sketch/components'
import { routes } from '@sketch/modules-common'

import { SKETCH_WEBSITE, isBillingHidden } from '@sketch/env-config'

import { dateFormat } from '@sketch/utils'

import {
  isWorkspaceSubscriptionActive,
  isWorkspaceSubscriptionTrialling,
  hasPaymentDetails,
  useCanSubscribeViaStripe,
} from 'modules/workspace/utils'

import { useWorkspaceSettingsPeopleContext } from 'modules/workspace/views/WorkspaceSettingsView/WorkspaceSettingsPeopleContext'
import { useMembershipsPanel } from 'modules/workspace/components/MembershipsPanel/hooks'

import {
  MembershipsPanel,
  MemberActions,
  WorkspaceSettingsSubscribePanel,
  WorkspaceSettingsDetailsPanel,
} from 'modules/workspace/components'

import { WorkspaceSettingsUpsellBanner } from 'modules/workspace/components/Upsell'

// Modals
import {
  AddSeatsModal,
  InviteMembersNotAllowedModal,
  InviteMembersModal,
  DeleteWorkspaceModal,
} from 'modules/workspace/modals'

import {
  Paragraph,
  MembersTitleContainer,
  WorkspaceMembershipsLoading,
  StyledBanner,
  DeleteWorkspaceText,
  FontWeightSemibold,
} from './WorkspaceSettingsPeople.styles'

// GQL
// Usage of BillingStatus enum is needed to make the expected strings match the BE ones
// eslint-disable-next-line no-restricted-imports
import { BillingStatus, WorkspaceMembership } from '@sketch/gql-types/expansive'
import {
  BillingPlanFragment,
  BillingSeatsInfoFragment,
  PaymentDetailsFragment,
  useGetWorkspaceMembershipsQuery,
} from '@sketch/gql-types'

interface WorkspaceSettingsPeopleProps {
  workspaceId: string
  name: string
  avatar: string | undefined
  seats: BillingSeatsInfoFragment
  billingStatus: BillingStatus
  nextBillingCycleDate?: string
  customerId: string
  currentPlan?: BillingPlanFragment
  paymentDetails?: PaymentDetailsFragment
  isSsoActive: boolean
  subscriptionEnd?: string
  role: WorkspaceMembership['role']
  isEducationWorkspace: boolean
  isBusinessWorkspace?: boolean
  isIOS?: boolean
  showUpsell?: boolean
  userDirectoryEnabled?: boolean
  source?: string
  showWorkspacePermissionGroup?: boolean
}

const MAC_SUBSCRIPTION_CANCEL_LINK =
  'https://support.apple.com/en-gb/guide/app-store/fire5f3a0745/mac#fire5200a6a8'

const IOS_SUBSCRIPTION_CANCEL_LINK =
  'https://support.apple.com/en-us/guide/iphone/iph3dfd91de/ios#iphb698a93f0'

const IOS_SUBSCRIPTION_DOCUMENTATION_LINK =
  'https://support.apple.com/en-us/guide/iphone/iph3dfd91de/ios'

const MAC_SUBSCRIPTION_DOCUMENTATION_LINK =
  'https://support.apple.com/en-us/guide/app-store/fire5f3a0745/mac'

const WorkspaceSettingsPeople: React.FC<WorkspaceSettingsPeopleProps> = props => {
  const {
    workspaceId,
    customerId,
    name,
    avatar,
    seats,
    currentPlan,
    nextBillingCycleDate,
    billingStatus,
    paymentDetails,
    isSsoActive,
    subscriptionEnd,
    role,
    isEducationWorkspace,
    isBusinessWorkspace,
    isIOS,
    showUpsell,
    userDirectoryEnabled,
    source,
    showWorkspacePermissionGroup,
  } = props
  const { showModal, showRestrictedModal } = useModalContext()

  const { order, orderDirection } = useWorkspaceSettingsPeopleContext()

  const isUserAdministrator = role === 'ADMIN'
  const isFinance = role === 'FINANCE'
  const isPartner = role === 'PARTNER'

  const scheduledSeats = seats.scheduledSeatsTotal ?? undefined

  const isOnTrial = isWorkspaceSubscriptionTrialling(billingStatus)
  const isSubscriptionActive = isWorkspaceSubscriptionActive(billingStatus)
  const customerHasPaymentDetails = hasPaymentDetails(paymentDetails)

  const onInviteMember = () => {
    if (isSsoActive) {
      showModal(InviteMembersNotAllowedModal, { workspaceId })
    } else {
      showRestrictedModal(InviteMembersModal, {
        customerId,
        isOnTrial,
        nextBillingCycleDate: nextBillingCycleDate!,
        plan: currentPlan!,
        seats,
        workspaceId,
        order,
        orderDirection,
        paymentDetails: paymentDetails!,
        isScheduledToBeCancelled: !!subscriptionEnd,
        isEducationWorkspace,
        isPartneredWorkspace,
        partner,
        isIOS,
      })
    }
  }

  // Workspace memberships
  const {
    data: membershipsData,
    loading: isLoadingMembership,
  } = useMembershipsPanel(workspaceId)
  const membershipsCount =
    membershipsData?.workspace.memberships.totalMemberships?.meta.totalCount ||
    undefined

  // Since Workspace memberships are lazy loaded, explicitly filter by PARTNER
  // to make sure this membership is verified
  const {
    data: partnersData,
    loading: isLoadingPartner,
  } = useGetWorkspaceMembershipsQuery({
    variables: {
      workspaceId,
      role: 'PARTNER',
    },
  })

  const workspaceHasPartner = !!partnersData?.workspace.memberships.list
    ?.entries.length

  const isPartneredWorkspace =
    (workspaceHasPartner &&
      partnersData?.workspace.memberships.list?.entries[0].acceptedAt !=
        null) ||
    false

  const partner = workspaceHasPartner
    ? partnersData?.workspace.memberships.list?.entries[0].user
    : null

  const headerCount = isPartner ? seats.currentSeatsTotal : membershipsCount
  const headerCopy = isPartner ? 'Seat' : 'Person'
  const headerCopyPlural = isPartner ? 'Seats' : 'People'
  const getMembersTitleCopy = () => (
    <MembersTitleContainer>
      {isLoadingMembership ? (
        <WorkspaceMembershipsLoading size="24px" />
      ) : (
        headerCount
      )}{' '}
      {pluralize(headerCopy, headerCopyPlural, headerCount || 1)} in {name}
    </MembersTitleContainer>
  )

  const getMembersTabCopy = () => {
    if (!isUserAdministrator && !isFinance && !isPartner) {
      return
    }

    const availableSeatsCopy = ` of which ${seats.availableSeats} ${pluralize(
      'is',
      'are',
      seats.availableSeats
    )} not in use.`
    const editorSeatsCountCopy = `${seats.currentSeatsTotal} Editor ${pluralize(
      'Seat',
      'Seats',
      seats.currentSeatsTotal
    )}`

    if (isOnTrial) {
      return (
        <Paragraph data-testid="members-tab-copy">
          After your trial, you will begin paying for{' '}
          <b>{editorSeatsCountCopy}</b>
          {seats.availableSeats > 0 ? availableSeatsCopy : null}
        </Paragraph>
      )
    } else {
      return (
        <Paragraph data-testid="members-tab-copy">
          You currently{' '}
          {isEducationWorkspace || isBillingHidden ? 'have' : 'pay for'}{' '}
          <b>{editorSeatsCountCopy}</b>
          {seats.availableSeats > 0 && ','} <br />
          {seats.availableSeats > 0 ? availableSeatsCopy : null}
        </Paragraph>
      )
    }
  }

  const getDocumentationPage = (source?: string) => {
    if (source === 'ios') return IOS_SUBSCRIPTION_DOCUMENTATION_LINK

    return MAC_SUBSCRIPTION_DOCUMENTATION_LINK
  }

  const getCancelSubscriptionLink = (source?: string) => {
    if (source === 'ios') return IOS_SUBSCRIPTION_CANCEL_LINK

    return MAC_SUBSCRIPTION_CANCEL_LINK
  }

  const renderMemberActions = () => {
    // When the subscription is managed by a partner, only render the
    // "Add Seats" buttons for partners
    // When there's no Partner, only render the buttons for Admin or Finance
    // users
    const canRenderButtons =
      !isLoadingPartner &&
      isSubscriptionActive &&
      (isPartner || isFinance || isUserAdministrator)

    if (!canRenderButtons) return

    const actions = []

    // Education Workspaces, Partnered Workspaces (if the current user is not the Partner)
    // and iOS Subscription Workspaces don't have the Add Seat button

    const skipAddSeatsButton =
      isEducationWorkspace ||
      (workspaceHasPartner && !isPartner) ||
      isIOS ||
      isBillingHidden

    // Add Seats / Manage Seats Button
    if (!skipAddSeatsButton) {
      actions.push(
        <Button
          key="seatsButton"
          onClick={() =>
            showModal(AddSeatsModal, {
              seats,
              plan: currentPlan!,
              workspaceId,
              nextBillingCycleDate,
              customerId,
              isOnTrial,
              paymentDetails: paymentDetails,
              isScheduledToBeCancelled: !!subscriptionEnd,
              variant: isPartner ? 'partner' : 'default',
            })
          }
          size="40"
          variant={isPartner ? 'primary' : 'secondary'}
        >
          {isPartner ? 'Manage Seats…' : 'Add Seats…'}
        </Button>
      )
    }

    // Invite Members Button
    if (!isFinance && !isPartner) {
      actions.push(
        <Button
          key="inviteButton"
          onClick={() => onInviteMember()}
          size="40"
          variant="primary"
        >
          Invite Members…
        </Button>
      )
    }

    return actions
  }

  const renderDeleteWorkspacePanel =
    isIOS || (isEducationWorkspace && !customerHasPaymentDetails)
  const educationBannerExpireDate = nextBillingCycleDate ?? subscriptionEnd
  const isIOSSubscriptionIsActive = isIOS && isSubscriptionActive
  const { canSubscribeStripe } = useCanSubscribeViaStripe(
    workspaceId,
    customerId
  )
  const subscriptionDocumentationLink = getDocumentationPage(source)
  const subscriptionCancelLink = getCancelSubscriptionLink(source)
  const showIOSBanner = isIOSSubscriptionIsActive && !canSubscribeStripe

  const showSubscribePanel =
    isUserAdministrator &&
    !customerHasPaymentDetails &&
    !isEducationWorkspace &&
    !isIOS &&
    isOnTrial &&
    canSubscribeStripe

  const showWorkspaceDetails =
    (!isPartner && !isUserAdministrator) ||
    (isEducationWorkspace && !customerHasPaymentDetails)

  const showScheduledSeatsChange =
    !!scheduledSeats &&
    isUserAdministrator &&
    !isEducationWorkspace &&
    !isBillingHidden

  const deleteWorkspaceText = isIOSSubscriptionIsActive ? (
    <DeleteWorkspaceText>
      To delete this Workspace and all documents, first cancel your subscription
      in the App Store.
      <br />
      <Link href={subscriptionCancelLink} external>
        Learn More
      </Link>
    </DeleteWorkspaceText>
  ) : (
    <>
      Delete the Workspace, including all documents and members it contains.
      Proceed with caution.
    </>
  )

  const expiresIn30Days =
    new Date(educationBannerExpireDate!).getTime() - new Date().getTime() <
    30 * 24 * 60 * 60 * 1000

  return (
    <>
      {isEducationWorkspace &&
        isUserAdministrator &&
        isSubscriptionActive &&
        !expiresIn30Days && (
          <StyledBanner type="information">
            Your Education Workspace is free until{' '}
            {dateFormat(new Date(educationBannerExpireDate!))}. <br />
            <Link href={`${SKETCH_WEBSITE}/support/contact/`} external>
              Get in touch
            </Link>{' '}
            if you have any questions or need for an extension.
          </StyledBanner>
        )}
      {isUserAdministrator &&
        isEducationWorkspace &&
        isSubscriptionActive &&
        expiresIn30Days &&
        !customerHasPaymentDetails && (
          <StyledBanner type="information">
            Your free Education Workspace{' '}
            <FontWeightSemibold>
              will expire on {dateFormat(new Date(educationBannerExpireDate!))}
            </FontWeightSemibold>
            .{' '}
            <Link href={`${SKETCH_WEBSITE}/support/contact/`} external>
              Get in touch
            </Link>{' '}
            with us to ask for an extension, or if you have any questions.
          </StyledBanner>
        )}
      {isEducationWorkspace && isUserAdministrator && !isSubscriptionActive && (
        <StyledBanner type="error">
          Your free Education Workspace has expired. To keep using Sketch, you
          can{' '}
          <Link
            variant="secondary"
            href={`${SKETCH_WEBSITE}/store/education/`}
            external
            isUnderlined
          >
            ask for an extension
          </Link>{' '}
          or{' '}
          <Link
            variant="secondary"
            isUnderlined
            to={routes.WORKSPACE_SUBSCRIBE.create({
              workspaceId,
            })}
          >
            subscribe to Sketch
          </Link>{' '}
          in case you’re no longer eligible. Questions?{' '}
          <Link
            variant="secondary"
            href={`${SKETCH_WEBSITE}/support/contact/`}
            external
            isUnderlined
          >
            Get in touch
          </Link>{' '}
        </StyledBanner>
      )}
      {showIOSBanner && (
        <StyledBanner type="information" data-testid="subscription-info-banner">
          Head over to the App Store to{' '}
          <ExternalLink
            data-testid="apple-subscriptions-documentation"
            href={subscriptionDocumentationLink}
          >
            manage your subscription
          </ExternalLink>{' '}
          and billing details.
        </StyledBanner>
      )}

      {showWorkspaceDetails && (
        <Section title="Workspace">
          <WorkspaceSettingsDetailsPanel
            name={name}
            id={workspaceId}
            avatar={avatar}
            isUserAdministrator={isUserAdministrator}
          />
        </Section>
      )}

      {showUpsell && !workspaceHasPartner && (
        <WorkspaceSettingsUpsellBanner
          localStorageKey="upsell-business-people"
          bannerText="Need seamless and secure sign-in, effortless onboarding, or more flexible payment options? Upgrade to our Business Plan."
        />
      )}

      {showSubscribePanel && (
        <WorkspaceSettingsSubscribePanel workspaceId={workspaceId} />
      )}
      <Section
        title={getMembersTitleCopy()}
        text={getMembersTabCopy()}
        action={renderMemberActions()}
        align={isPartner ? 'center' : 'bottom'}
      >
        {/* Banner warning the user that has scheduled seats */}
        {showScheduledSeatsChange && (
          <>
            <br />
            <Banner type="warning">
              <b>Scheduled change!</b> You’ve scheduled to have {scheduledSeats}{' '}
              seats starting on{' '}
              {nextBillingCycleDate &&
                dateFormat(new Date(nextBillingCycleDate))}
              {'. '}
              <RouterLink
                to={routes.WORKSPACE_SETTINGS_BILLING.create({
                  workspaceId: workspaceId,
                })}
              >
                See Details
              </RouterLink>
            </Banner>
          </>
        )}

        {!isFinance && !isPartner && (
          <MembershipsPanel
            workspaceId={workspaceId}
            isBusinessWorkspace={isBusinessWorkspace}
            partnersNotVisible={workspaceHasPartner && !isPartner}
            renderSecondaryActions={
              isUserAdministrator
                ? member => (
                    <MemberActions
                      workspaceName={name}
                      workspaceId={workspaceId}
                      member={member}
                      billingStatus={billingStatus}
                      customerId={customerId}
                      seats={seats}
                      plan={currentPlan!}
                      paymentDetails={paymentDetails}
                      isSsoActive={isSsoActive}
                      hasPartner={!!workspaceHasPartner}
                      isPartneredWorkspace={isPartneredWorkspace}
                      isEducationWorkspace={isEducationWorkspace}
                      isIOS={isIOS}
                      showUpsell={showUpsell}
                      userDirectoryEnabled={userDirectoryEnabled}
                      showWorkspacePermissionGroup={
                        showWorkspacePermissionGroup
                      }
                    />
                  )
                : undefined
            }
            userDirectoryEnabled={userDirectoryEnabled}
          />
        )}
      </Section>

      {/* "data-testid="danger-zone-panel"" is used to keep consistency with the older panels in tests */}
      {renderDeleteWorkspacePanel && (
        <Section
          data-testid="danger-zone-panel"
          title="Delete Workspace"
          text={deleteWorkspaceText}
          action={
            isIOSSubscriptionIsActive ? null : (
              <Button
                onClick={() =>
                  showModal(DeleteWorkspaceModal, { id: workspaceId, name })
                }
                size="32"
                variant="negative-secondary"
              >
                Delete Workspace…
              </Button>
            )
          }
        />
      )}
      {isPartner && (
        <Section
          title="Leave Workspace"
          text={
            <Text textStyle="copy.tertiary.standard.E">
              If you no longer want to manage <b>“{name}”</b> as a Sketch
              Partner, get in touch with their Workspace Admin.{' '}
              <a
                href={`${SKETCH_WEBSITE}/support/partners/how-to-remove-yourself-from-a-workspace/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
            </Text>
          }
        ></Section>
      )}
    </>
  )
}

export default WorkspaceSettingsPeople

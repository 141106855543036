import { VersionLink } from 'modules/versioning'
import styled, { css } from 'styled-components'
import { Flex, Text, tabStyle } from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

export const MobileContainer = styled(Flex)(
  ({ theme }) => css`
    position: sticky;
    top: 0;
    left: 0;
    z-index: ${theme.zIndex[2]};

    display: block;
    margin: -16px -12px 0;
    padding: 16px;

    background-color: ${theme.colors.background.secondary.B};

    ${breakpoint('sm')`
      margin: -16px -24px 0;
    `};
  `
)

export const MobileNavigationLink = styled(VersionLink)<{ selected: boolean }>`
  ${tabStyle}
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: normal;

  &:first-of-type {
    margin-top: 8px;
  }
`

export const MobileNavigationHeader = styled(Text.Span).attrs({
  textStyle: 'caption.quaternary.B',
})`
  text-transform: uppercase;
  margin-left: 8px;
  margin-top: 12px;
`

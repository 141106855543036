import { jsx } from 'react/jsx-runtime';
import '../LoadingPlaceholder/index.js';
import '../NavbarExtended/index.js';
import '../SplitLayout/index.js';
import { SidebarWrapper, LoadingSidebarWorkspace, LoadingWrapper } from './LoadingWorkspaceSidebarLayout.styles.js';
import { SplitLayout } from '../SplitLayout/SplitLayout.js';
import { NavbarExtended } from '../NavbarExtended/Navbar.js';
import { LoadingPlaceholder } from '../LoadingPlaceholder/LoadingPlaceholder.js';

const LoadingWorkspaceSidebarLayout = ({ isRefreshedUi, }) => (jsx(SplitLayout, Object.assign({ isRefreshedUi: isRefreshedUi, header: jsx(NavbarExtended, {}), sidebar: jsx(SidebarWrapper, { children: jsx(LoadingSidebarWorkspace, {}) }), footer: null }, { children: jsx(LoadingWrapper, { children: jsx(LoadingPlaceholder, { size: "64px" }) }) })));

export { LoadingWorkspaceSidebarLayout };

import {
  useRevisionSubscriptions,
  UseRevisionSubscriptionsProps,
} from 'modules/activity/operations'

export const RevisionSubscriptions = (props: UseRevisionSubscriptionsProps) => {
  // Trigger the artboards list refetch when there are new artboard revisions,
  // to be able to show the `See latest update` badge
  useRevisionSubscriptions(props)
  return null
}

import { jsxs, jsx } from 'react/jsx-runtime';
import styled from 'styled-components';
import '../Avatar/index.js';
import { Avatar } from '../Avatar/Avatar.js';

const Border = styled.div `
  display: flex;
  flex-direction: row;
  border: 1px dashed ${({ theme }) => theme.colors.border.A};
  align-items: center;
  padding: 8px;
  background: ${({ theme }) => theme.colors.background.secondary.B};
  border-radius: 6px;
`;
const Email = styled.span `
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.E};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin: 0 8px;
`;
const UserChip = ({ name, email, avatarSrc }) => {
    return (jsxs(Border, Object.assign({ "data-testid": "user-chip" }, { children: [jsx(Avatar, { name: name, src: avatarSrc, size: 24 }), jsx(Email, { children: email })] })));
};

export { UserChip };

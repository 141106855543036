import { jsx } from 'react/jsx-runtime';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { StyledHeader } from './Header.styles.js';
import '../Breakpoint/index.js';
import { useForTablet } from '../Breakpoint/Breakpoint.js';

const HEADER_SLIM_PORTAL_ID = 'header-slim-portal';
const useShouldShowStickyHeader = () => {
    // We only want to show the slim header when the portal exists
    const [shouldShowStickyHeader, setShouldShowStickyHeader] = React.useState(false);
    // We only want to show the slim header for tablet and desktop views
    const isTabletAndBigger = useForTablet();
    React.useEffect(() => {
        setShouldShowStickyHeader(!!document.getElementById(HEADER_SLIM_PORTAL_ID));
    }, []);
    return isTabletAndBigger && shouldShowStickyHeader;
};
const Header = ({ children }) => {
    const { ref, inView } = useInView({
        initialInView: true,
    });
    const shouldShowStickyHeader = useShouldShowStickyHeader();
    return (jsx(StyledHeader, Object.assign({ ref: ref, "$sticky": !inView && shouldShowStickyHeader }, { children: children })));
};

export { Header };

const signUpContentFromJson = (json) => {
    return {
        __typename: 'SignUpContent',
        title: json.title,
        tint: json.tint,
        introduction: json.introduction,
        features: json.features.map(featureFromJson),
        callToAction: json.callToAction,
        relatedContent: relatedContentFromJson(json.relatedContent),
        heroImage: heroFromJson(json.heroImage),
    };
};
const featureFromJson = (json) => {
    return {
        __typename: 'SignUpFeature',
        icon: imageFromJson(json.icon),
        description: json.description,
    };
};
const imageFromJson = (json) => {
    return {
        __typename: 'SignUpImage',
        src: json.src,
        alt: json.alt,
    };
};
const relatedContentFromJson = (json) => {
    if (json) {
        return {
            __typename: 'SignUpRelatedContent',
            title: json.title,
            description: json.description,
            thumbnail: {
                __typename: 'SignUpImage',
                src: json.thumbnail.src,
                alt: json.thumbnail.alt,
            },
            url: json.url,
        };
    }
    return null;
};
const heroFromJson = (json) => {
    if (json) {
        return imageFromJson(json);
    }
    return null;
};

export { signUpContentFromJson };

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { Link as Link$1 } from 'react-router-dom';
import '../Link/index.js';
import { Button } from './Button.js';
import { Link } from '../Link/Link.js';

const LinkButton = (_a) => {
    var { children, external } = _a, props = __rest(_a, ["children", "external"]);
    if (external) {
        return (jsx(Button, Object.assign({ as: linkProps => (
            // We need the "variant" to reach Link so we pass it here
            jsx(Link, Object.assign({}, linkProps, { variant: props.variant }))), external: true }, props, { children: children })));
    }
    return (jsx(Button, Object.assign({}, props, { as: Link$1 }, { children: children })));
};

export { LinkButton };

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import React from 'react';
import styled from 'styled-components';
import { useImage } from '@sketch/utils';
import { AvatarImage, FirstLetter, Wrapper } from './WorkspaceLogo.styles.js';

/**
 * WorkspaceLogo
 *
 * This components renders the Workspace Avatar in a square format
 * In case the workspace has a logo it shows it, otherwise it shows [A] (First workspace name letter)
 *
 * This workspace has 3 sizes (28px size is now @deprecated):
 * - 24px
 * - 32px
 * - 64px
 *
 * We reduced the sizes to 3 to keep the variables to the minimum
 *
 * We should strive to match the layout implemented in https://www.sketch.com/s/5a711544-9ad3-40ed-b98c-a8e656b7ff42
 * and in case we update this we should warn the design team to update it.
 */
const WorkspaceLogo = React.forwardRef(function WorkspaceLogo(props, ref) {
    const { src, className, size, workspaceName, onClick, 'data-testid': dataTestId = 'workspace-logo', UNSAFE_ADD_MARGIN } = props, rest = __rest(props, ["src", "className", "size", "workspaceName", "onClick", 'data-testid', "UNSAFE_ADD_MARGIN"]);
    const imageLoaded = useImage(src || undefined);
    let children = null;
    if (imageLoaded && src) {
        children = (jsx(AvatarImage, { src: src, alt: `${workspaceName} logo`, "data-testid": "workspace-logo-avatar" }));
    }
    else {
        const firstLetter = workspaceName ? workspaceName[0] : 'A';
        children = (jsx(FirstLetter, Object.assign({ "$size": size, "data-testid": "workspace-logo-letter" }, { children: firstLetter })));
    }
    return (jsx(Wrapper, Object.assign({ className: className, onClick: onClick, "data-testid": dataTestId, ref: ref, "$size": size, "$addRightMargin": UNSAFE_ADD_MARGIN }, rest, { children: children })));
});
var WorkspaceLogoBase = styled(WorkspaceLogo) ``;

export { WorkspaceLogoBase as default };

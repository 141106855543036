import styled from 'styled-components';
import '../Box/index.js';
import { Text } from '../Box/BoxSystem.js';

const ModalText = styled(Text.Span).attrs({
    textStyle: 'copy.tertiary.standard.D',
}) `
  > b {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }
`;

export { ModalText };

import React from 'react'
import { ToastLink } from '@sketch/components'

import { useToast } from '@sketch/toasts'
import { routes } from '@sketch/modules-common'
import { useCollectionSearchArgument } from 'modules/collections/hooks'

import {
  addCollectionToProject,
  invalidateCollectionsExcept,
  removeSharesFromCollection,
  removeSharesFromProject,
  updateShareCollection,
} from './utils'

import { useCreateCollectionMutation } from '@sketch/gql-types'
import { readShareCollection } from 'modules/projects/operations/utils'

interface UseCreateCollectionProps {
  projectIdentifier: string
  workspaceIdentifier: string
  hideModal?: () => void
}

export const useCreateCollection = ({
  projectIdentifier,
  workspaceIdentifier,
  hideModal,
}: UseCreateCollectionProps) => {
  const { showToast } = useToast()

  const search = useCollectionSearchArgument()

  const [
    createCollection,
    { loading: isLoadingCreateCollection },
  ] = useCreateCollectionMutation({
    onCompleted: ({ createCollection }) => {
      const { name, identifier } = createCollection.collection

      showToast(
        <>
          &quot;
          <ToastLink
            to={routes.WORKSPACE_COLLECTION.create({
              workspaceId: workspaceIdentifier,
              projectId: projectIdentifier,
              collectionId: identifier,
            })}
          >
            {name}
          </ToastLink>
          &quot; collection created
        </>
      )

      hideModal?.()
    },
    onError: 'show-toast',
  })

  const createCollectionWithCacheUpdate: typeof createCollection = (
    options = {}
  ) => {
    const shareIdentifiers = options.variables?.shareIdentifiers ?? []
    return createCollection({
      ...options,
      update: (cache, { data }) => {
        if (!data) {
          return
        }

        // Add the collection to the list of collections for the project
        const collection = data.createCollection.collection

        addCollectionToProject({
          cache,
          projectIdentifier,
          search,
          collection,
        })

        if (shareIdentifiers.length === 0) {
          return
        }

        // The shares that were added to the collection
        const shares = shareIdentifiers.map(identifier => ({ identifier }))

        // Remove the shares from the project root
        removeSharesFromProject(cache, projectIdentifier, shares)

        // Remove the shares from their current collection if it exists
        // and add them to the new one
        for (const share of shares) {
          const currentCollection = readShareCollection(cache, share.identifier)
          const currentCollectionIdentifier =
            currentCollection?.share?.collection?.identifier

          if (
            currentCollectionIdentifier &&
            currentCollectionIdentifier !== collection.identifier
          ) {
            removeSharesFromCollection({
              cache,
              projectIdentifier,
              collectionIdentifier: currentCollectionIdentifier,
              search,
              sharesToRemove: [share],
            })
          }

          updateShareCollection(cache, share.identifier, collection)
        }

        invalidateCollectionsExcept({
          cache,
          projectIdentifier,
          search,
        })
      },
    })
  }

  return {
    createCollection: createCollectionWithCacheUpdate,
    loading: isLoadingCreateCollection,
  }
}

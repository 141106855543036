/**
 * Clamp
 *
 * Clamp numbers between a min and a max value
 *
 *
 * @param num Number to clamp
 * @param min Minimum number
 * @param max Maximum number
 *
 * @returns A number clamped to a range of max and min values
 */
const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export { clamp };

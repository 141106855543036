import React from 'react'

import { dateFormat } from '@sketch/utils'
import { PLAN_TYPE_LABEL } from 'modules/workspace/utils'

import { Pluralize } from '@sketch/components'
import BillingInfoTable from '../BillingInfoTable'

import { ErrorPill, Description, StyledButton } from './BillingCycle.styles'

import {
  BillingSeatsInfoFragment,
  BillingPlanFragment,
} from '@sketch/gql-types'

interface BillingCycleProps {
  subscriptionEnd?: string
  nextBillingCycleDate?: string
  seats: BillingSeatsInfoFragment
  isPartner?: boolean
  isWorkspaceOnTrial: boolean
  customerHasPaymentDetails: boolean
  currentPlan?: BillingPlanFragment

  onCancelSeatScheduleChange: () => void
}

const getHeaderCopy = (isWorkspaceOnTrial: boolean, isEducationPlan: boolean) =>
  isWorkspaceOnTrial || isEducationPlan
    ? 'First Billing Date'
    : 'Next Billing Date'

/**
 * BillingCycle
 *
 * Rends the "Next Billing Cycle" panel in the Plan Overview Panel (Workspace Settings)
 */
const BillingCycle: React.FC<BillingCycleProps> = props => {
  const {
    subscriptionEnd,
    nextBillingCycleDate,
    seats,
    isWorkspaceOnTrial,
    isPartner,
    onCancelSeatScheduleChange,
    customerHasPaymentDetails,
    currentPlan,
  } = props

  const isScheduled = !!seats.scheduledSeatsTotal
  const isEducationPlan = currentPlan?.product === 'STUDENT'
  const partnerDescription =
    'Your subscription is scheduled to be canceled at the end of the current billing period. You can keep using the Workspace until'
  const userDescription = 'You can keep using the Workspace until'

  if (subscriptionEnd) {
    return (
      <BillingInfoTable.Row>
        <BillingInfoTable.HeadingCell>
          Next Billing Date
        </BillingInfoTable.HeadingCell>
        <BillingInfoTable.Cell data-testid="plan-next-cycle">
          <div>
            <ErrorPill data-testid="warning-subscription-badge">
              {isPartner ? 'Cancelation Scheduled' : 'Subscription Canceled'}
            </ErrorPill>
            <br />
            <span>
              {isPartner ? partnerDescription : userDescription}{' '}
              {dateFormat(new Date(subscriptionEnd))}
            </span>
          </div>
        </BillingInfoTable.Cell>
      </BillingInfoTable.Row>
    )
  }

  return (
    <BillingInfoTable.Row>
      <BillingInfoTable.HeadingCell>
        {getHeaderCopy(isWorkspaceOnTrial, isEducationPlan)}
      </BillingInfoTable.HeadingCell>
      <BillingInfoTable.Cell data-testid="plan-next-cycle">
        <Description>
          <strong>{dateFormat(new Date(nextBillingCycleDate!))}</strong>
          <br />
          {isWorkspaceOnTrial && !customerHasPaymentDetails && (
            <>If you subscribe, you’ll be billed on this date</>
          )}
          {isWorkspaceOnTrial && customerHasPaymentDetails && (
            <>
              You&apos;ll be billed{' '}
              {PLAN_TYPE_LABEL[currentPlan!.type].toLowerCase()} for Editor
              Seats you&apos;re using at the end of your trial.
              <br />
              <br />
            </>
          )}
          {!isWorkspaceOnTrial && isScheduled && !isEducationPlan && (
            <>
              You have scheduled a change to have {seats.scheduledSeatsTotal}{' '}
              Editor seats on the date above.{' '}
              <StyledButton onClick={onCancelSeatScheduleChange}>
                Cancel scheduled change?
              </StyledButton>
              <br />
              <br />
            </>
          )}
          {isEducationPlan && customerHasPaymentDetails && (
            <>
              You’ll be billed for{' '}
              {seats.scheduledSeatsTotal ?? seats.currentSeatsTotal} Editor{' '}
              <Pluralize
                singular="Seat"
                plural="Seats"
                count={seats.scheduledSeatsTotal ?? seats.currentSeatsTotal}
                wrapperElement={React.Fragment}
              />{' '}
              at the end of your Education plan.
              <br />
              <br />
            </>
          )}
        </Description>
      </BillingInfoTable.Cell>
    </BillingInfoTable.Row>
  )
}

export default BillingCycle

import styled from 'styled-components';
import { Text, FlatButton, LinkButton } from '@sketch/components';

const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  max-width: 595px;
  width: 100%;
  text-align: center;
`;
const TextInbox = styled(Text.Div).attrs({
    textStyle: 'copy.primary.standard.E',
}) `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 8px;
`;
const StyledLinkButton = styled(FlatButton) `
  display: inline-block;
  padding: 0;
  text-decoration: underline;
  line-height: 1.4;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;
const DoThisLaterButton = styled(LinkButton) `
  margin: 44px auto;
`;

export { DoThisLaterButton, StyledLinkButton, TextInbox, Wrapper };

import React from 'react'

import CollectionShareDrop from 'modules/collections/components/CollectionShareDrop'

import {
  Content,
  Copy,
  DocumentProcessing,
} from './CollectionItemEmptyState.styles'
import { StyledSubtitleTextWrapper } from '../CollectionItem/CollectionItem.styles'

// This component shares styles with DocumentItem
import {
  Name,
  DropdownButtonWrapper,
} from 'modules/shares/components/DocumentItem/DocumentItem.styles'

import { CollectionPreviewsFragment } from '@sketch/gql-types'

interface CollectionItemGridViewEmptyStateProps {
  dropdownContent: React.ReactNode
  collection: CollectionPreviewsFragment
  projectIdentifier: string
  workspaceIdentifier: string
}

const CollectionItemGridViewEmptyState: React.FC<CollectionItemGridViewEmptyStateProps> = ({
  dropdownContent,
  collection,
  projectIdentifier,
  workspaceIdentifier,
}) => {
  return (
    <>
      <CollectionShareDrop
        collection={collection}
        projectIdentifier={projectIdentifier}
        workspaceIdentifier={workspaceIdentifier}
      >
        <Content>
          <DocumentProcessing />
          <Copy>Drag documents here to add them to the collection.</Copy>
        </Content>
      </CollectionShareDrop>
      <Name>{collection.name}</Name>
      <StyledSubtitleTextWrapper>
        <b className="shrink">No Documents</b>
      </StyledSubtitleTextWrapper>
      {dropdownContent && (
        <DropdownButtonWrapper aria-label="Document Options">
          {dropdownContent}
        </DropdownButtonWrapper>
      )}
    </>
  )
}

export default CollectionItemGridViewEmptyState

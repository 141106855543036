import styled, { css } from 'styled-components'
import { breakpoint } from '@sketch/global-styles'
import tabletResizer from '@sketch/icons/tablet-resizer-23.svg'

export const Wrapper = styled.div<{
  isDesktopAndBigger: boolean
}>(
  ({ isDesktopAndBigger }) => css`
    display: flex;
    flex-direction: ${isDesktopAndBigger ? 'row' : 'column'};
    flex: auto;
    min-width: 0;
  `
)

export const Sidebar = styled.div<{
  $isRefreshedUi: boolean
}>(
  ({ theme, $isRefreshedUi }) => css`
    position: fixed;
    height: calc(100% - 48px); /* 48px is the topbar height */

    padding: 8px;
    border-right: 1px solid ${theme.colors.border.A};
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    ${$isRefreshedUi &&
    css`
      border-right: none;
      background: ${theme.colors.background.secondary.A};
    `}
  `
)

export const SidebarResizeHandle = styled.div`
  display: none;
  position: absolute;
  width: 10px;
  top: 0;
  left: -5px;
  bottom: 0;

  ${breakpoint('md')`
    display: block;
    cursor: col-resize;
  `};

  /* Only for touch devices */
  @media (hover: none) {
    &:after {
      content: '';
      position: absolute;
      top: -38px;
      left: -18px;
      width: 44px;
      height: 44px;
      background: url(${tabletResizer}) no-repeat center/23px;
      z-index: 50;
    }
  }
`

export const Content = styled.div<{ $isRefreshedUi: boolean }>`
  position: relative;

  display: flex;
  flex-direction: column;
  flex: auto;

  min-width: 0;
  margin-bottom: 44px;
  padding-left: 12px;
  padding-right: 12px;

  ${breakpoint('sm')`
    margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
  `};

  ${({ $isRefreshedUi, theme }) =>
    $isRefreshedUi &&
    css`
      background: ${theme.colors.background.secondary.B};

      ${breakpoint('sm')`
        margin-top: 0px;
        padding-top: 8px;
        padding-left: 24px;
        padding-right: 24px;
      `};
    `}
`

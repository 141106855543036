import styled from 'styled-components'

import { Form } from '@sketch/components'

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  & ${Form.Field} {
    width: 100%;
    margin: 0 16px 0 0;
  }

  & button[type='submit'] {
    height: 40px;
  }
`

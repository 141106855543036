/**
 * Cross-browser normalized Fullscreen Web API.
 */
function isDefinedFunc(val) {
    return val !== undefined && typeof val === 'function';
}
/**
 * Returns a reference to the active fullscreen Element, or null if the
 * browser is not in fullscreen. This is the recommended way to check whether
 * the fullscreen mode is active or not.
 */
function fullscreenElement() {
    return (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement ||
        null);
}
/**
 * Exit fullscreen mode.
 */
function exitFullscreen() {
    if (isDefinedFunc(document.exitFullscreen)) {
        return document.exitFullscreen();
    }
    else if (isDefinedFunc(document.mozCancelFullScreen)) {
        return document.mozCancelFullScreen();
    }
    else if (isDefinedFunc(document.webkitExitFullscreen)) {
        return document.webkitExitFullscreen();
    }
    else if (isDefinedFunc(document.msExitFullscreen)) {
        return document.msExitFullscreen();
    }
    else {
        return Promise.reject('Fullscreen not available');
    }
}
/**
 * Request fullscreen mode.
 */
function requestFullscreen(el, options) {
    if (isDefinedFunc(el.requestFullscreen)) {
        return el.requestFullscreen(options);
    }
    else if (isDefinedFunc(el.webkitRequestFullscreen)) {
        return el.webkitRequestFullscreen(options);
    }
    else if (isDefinedFunc(el.mozRequestFullScreen)) {
        return el.mozRequestFullScreen(options);
    }
    else if (isDefinedFunc(el.msRequestFullscreen)) {
        return el.msRequestFullscreen(options);
    }
    else {
        return Promise.reject('Fullscreen not available');
    }
}
/**
 * Normalized name for the fullscreenchange event.
 */
const changeEvent = ('fullscreenEnabled' in document && 'fullscreenchange') ||
    ('webkitFullscreenEnabled' in document && 'webkitfullscreenchange') ||
    ('mozFullScreenEnabled' in document && 'mozfullscreenchange') ||
    ('msFullscreenEnabled' in document && 'MSFullscreenChange') ||
    null;
/**
 * Does the current browser support the Fullscreen Web API?
 */
const fullscreenEnabled = document.fullscreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.msFullscreenEnabled ||
    false;

export { changeEvent, exitFullscreen, fullscreenElement, fullscreenEnabled, requestFullscreen };

import styled, { css } from 'styled-components';
import '../Box/index.js';
import '../Caption/index.js';
import '../Checkbox/index.js';
import '../Button/index.js';
import '../Pill/index.js';
import '../SelectorButton/index.js';
import '../Banner/index.js';
import '../RadioButton/index.js';
import '../Select/index.js';
import '../SelectPopover/index.js';
import { RadioButton as RadioButton$1 } from '../RadioButton/RadioButton.js';
import { Checkbox as Checkbox$1 } from '../Checkbox/Checkbox.js';
import { CheckboxLabel } from '../Checkbox/Checkbox.styles.js';
import { Caption } from '../Caption/Caption.js';
import { SelectPopover } from '../SelectPopover/SelectPopover.js';
import { ListboxOption } from '@reach/listbox';
import { SelectorButton } from '../SelectorButton/SelectorButton.js';
import { Text, Flex } from '../Box/BoxSystem.js';
import { DropdownActionButton } from '../Button/DropdownActionButton.js';
import { Banner } from '../Banner/Banner.js';
import { Pill } from '../Pill/Pill.js';

const hoverStyle = css `
  padding: 8px 12px;

  border-radius: ${({ theme }) => theme.radii.large};

  :hover {
    ${({ disabled, theme }) => !disabled && `background: ${theme.colors.background.tertiary.C}`};
  }
`;
const RadioButton = styled(RadioButton$1) `
  padding: 8px 12px;
  margin: 0;
`;
const Checkbox = styled(Checkbox$1) `
  ${hoverStyle}
  margin: 0 8px;
  line-height: 1;

  ${CheckboxLabel} {
    line-height: 1;
  }

  ${Caption} {
    font-size: ${({ theme }) => theme.fontSizes.C};
  }

  ${({ disabled }) => disabled &&
    css `
      cursor: initial;
    `}
`;
const FullSeparator = styled.div `
  border-top: 1px solid ${({ theme }) => theme.colors.border.B};
  margin: 8px 0;
`;
const Popover = styled(SelectPopover) `
  width: 256px;
  padding: 8px 0px;

  &[data-reach-listbox-popover] {
    background: ${({ theme }) => theme.colors.background.secondary.A};
    max-height: none;
  }
`;
const Option = styled(ListboxOption) `
  :hover {
    background-color: none;
  }

  &&& {
    background-color: transparent;
    border-radius: ${({ theme }) => theme.radii.large};
    margin: 0 8px;
    padding: 0px;
    width: calc(100% - 16px);

    ${({ disabled }) => disabled &&
    css `
        cursor: initial;
        label {
          /* We want the opacity of the text will be normal,
          the radio button will be handling it's own opacity */
          opacity: 1;
        }
      `}
  }
`;
// This button doesn't need to be focusable because his parent is already focusable
const TriggerButton = styled(SelectorButton).attrs({
    chevrons: true,
    tabIndex: -1,
}) `
  text-align: right;
  height: auto;

  ${({ $colorRed, theme }) => $colorRed &&
    css `
      color: ${theme.colors.state.negative.A};

      :hover {
        color: ${theme.colors.state.negative.A};
      }
    `}
`;
const CommentLabel = styled(Text.Div).attrs({
    textStyle: 'copy.quaternary.standard.C',
}) `
  white-space: nowrap;
`;
const Header = styled.span `
  margin: 0;
  display: block;

  font-size: 0.6875rem;
  font-weight: 600;
  line-height: 1.182; /* stylelint-disable-line scales/line-height */
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  padding: 10px 20px 10px; /* stylelint-disable-line scales/space */

  text-transform: uppercase;
`;
const RemoveButton = styled(DropdownActionButton) `
  color: ${({ theme }) => theme.colors.state.negative.A};

  :hover {
    color: ${({ theme }) => theme.colors.state.negative.A};
    background-color: ${({ theme }) => theme.colors.background.tertiary.C};
  }
`;
const WorkspaceAccessLevelWrapper = styled(Flex) `
  align-items: flex-start;
  padding: 12px 8px 12px 16px;
`;
const AccessLevelSelectBanner = styled(Banner).attrs({
    type: 'warning',
    showIcon: false,
}) `
  padding: 14px 16px; /* stylelint-disable-line scales/space */
  margin: 8px;
`;
const StyledPill = styled(Pill) `
  margin-left: 8px;
`;

export { AccessLevelSelectBanner, Checkbox, CommentLabel, FullSeparator, Header, Option, Popover, RadioButton, RemoveButton, StyledPill, TriggerButton, WorkspaceAccessLevelWrapper };

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import React, { useRef, useState, useMemo, useEffect } from 'react';
import { animated, useTransition, config } from 'react-spring';
import styled, { css } from 'styled-components';
import pick from 'lodash.pick';
import omit from 'lodash.omit';

/*
 * there were a lot of discussions regarding this solution:
 * choosing between two approaches:
 *  1. do not calculate heights of toasts
 *  2. calculate heights of toasts
 * it is possible to follow up the discussion here:
 * https://bohemiancoding.slack.com/archives/CESQLT4RZ/p1554369552237100
 * https://github.com/awkward/sketch-frontend/pull/629
 */
// values are just a random value, used to animate slide in of an child item
const INITIAL_Y_OFFSET = -500;
const INITIAL_X_OFFSET = 300;
const BaseToastsContainerCss = css `
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
`;
const ToastsContainerCss = css `
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const ToastsContainer = styled.div `
  ${BaseToastsContainerCss};
  ${ToastsContainerCss};
`;
const AnimationWrapper = styled(animated.div) `
  float: right;
  max-width: 50%;
  height: 0;
  margin: unset;
  text-align: center;
  will-change: transform;
  pointer-events: all;
`;
const leaveBehindStyles = {
    position: 'relative',
    zIndex: -1,
};
const calculateHeights = (childKeys, refs, currentHeights) => {
    const getHeight = (key) => {
        if (!refs || !refs.current[key]) {
            return undefined;
        }
        return refs.current[key].clientHeight;
    };
    const heights = childKeys.map(key => ({
        [key]: getHeight(key) || currentHeights[key],
    }));
    const updatedHeights = heights.reduce((acc, curr) => (Object.assign(Object.assign({}, acc), curr)), {});
    const result = Object.assign(Object.assign({}, currentHeights), updatedHeights);
    return result;
};
const ToastsDisplay = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    const refs = useRef({});
    const [heights, setHeights] = useState({});
    const childKeys = useMemo(() => React.Children.map(children, child => child.key), [children]);
    useEffect(() => {
        setHeights(heights => calculateHeights(childKeys, refs, heights));
    }, [childKeys]);
    useEffect(() => {
        if (childKeys.length === 0) {
            return undefined;
        }
        const handleResize = () => setHeights(heights => calculateHeights(childKeys, refs, heights));
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [childKeys]);
    const onDestroy = ({ key }) => {
        refs.current = pick(childKeys, refs.current);
        setHeights(heights => omit(heights, [key]));
    };
    const transition = useTransition(children, childKeys, {
        trail: 100,
        config: config.stiff,
        // onDestroyed is badly typed. In reality it is passing an item, not a boolean
        onDestroyed: onDestroy,
        from: { opacity: 1, x: INITIAL_X_OFFSET, y: INITIAL_Y_OFFSET },
        enter: { opacity: 1, x: 0, y: 0 },
        leave: (item) => ({
            opacity: 0,
            xy: [0, -1 * heights[item.key]],
        }),
    }
    // Use a type without `Partial` of TransitionProps
    );
    const onRefUpdate = (key) => (ref) => {
        if (!ref) {
            return;
        }
        refs.current[key] = ref;
    };
    return (jsx(ToastsContainer, Object.assign({}, props, { children: transition.map(({ item, state, props: { opacity, x, y } }) => {
            return (jsx(AnimationWrapper, Object.assign({ style: Object.assign({ height: y.interpolate(y => Math.max(0, (heights[item.key] || 0) + y)), opacity: opacity.interpolate(opacity => opacity === 1 ? undefined : opacity), transform: x === null || x === void 0 ? void 0 : x.interpolate(x => state !== 'leave' && x !== 0 ? `translateX(${x}px)` : undefined), paddingBottom: y === null || y === void 0 ? void 0 : y.interpolate(y => y > 0 ? `${y}px` : undefined) }, (state === 'leave' ? leaveBehindStyles : {})) }, { children: jsx("div", Object.assign({ ref: onRefUpdate(item.key), style: { padding: `5px 0` } }, { children: item })) }), item.key));
        }) })));
};

export { ToastsDisplay };

import { isProduction } from '@sketch/env-config';

const isJestRunning = () => process.env.JEST_WORKER_ID !== undefined;
const PRODUCTION_HOSTNAME = 'sketch.com';
const NON_PRODUCTION_HOSTNAME = 'localhost.sketch.com';
const PIXEL_ID = 'simpleanalytics-pixel';
const hostname = isProduction() ? PRODUCTION_HOSTNAME : NON_PRODUCTION_HOSTNAME;
const trackSimpleAnalytics = () => {
    if (isJestRunning()) {
        return;
    }
    if (document.getElementById(PIXEL_ID)) {
        // do not allow the scripts to be attached more than once
        return;
    }
    // attach the simple analytics pixel
    const image = document.createElement('img');
    image.id = PIXEL_ID;
    image.src = `https://queue.simpleanalyticscdn.com/noscript.gif?hostname=${hostname}`;
    image.referrerPolicy = 'no-referrer-when-downgrade';
    image.width = 1;
    image.height = 1;
    document.body.appendChild(image);
};
const removeSimpleAnalytics = () => {
    const pixel = document.getElementById(PIXEL_ID);
    if (pixel) {
        document.body.removeChild(pixel);
    }
};
const trackSimpleEvent = (eventName, path) => {
    const image = document.createElement('img');
    image.src = `
https://queue.simpleanalyticscdn.com/simple.gif?hostname=${hostname}&path=${path}&unique=false&type=event&event=${eventName}&dev=${!isProduction()}&time=${Date.now()}`;
    image.referrerPolicy = 'no-referrer-when-downgrade';
    image.width = 1;
    image.height = 1;
    document.body.appendChild(image);
    // remove the image after 1 second
    setTimeout(() => document.body.removeChild(image), 1000);
};

export { removeSimpleAnalytics, trackSimpleAnalytics, trackSimpleEvent };

import React from 'react'
import { PermissionGroupIcon, PersonAvatar, Pill } from '@sketch/components'

import {
  useGetShareMembershipsQuery,
  PublicWorkspaceFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

import type { TransferDocumentInfo } from 'modules/projects/modals/MoveShareToProject/types'

import {
  Title,
  Row,
  List,
  Name,
  Permission,
  PlaceholderAvatar,
  PlaceholderWorkspaceAvatar,
  ErrorText,
  MoreMembers,
  StyledProjectRow,
  StyledWorkspaceRow,
  Section,
} from './ShareAccessList.styles'

import { getPermissionsLabel } from '../../utils'

interface ShareAccessListProps {
  shareIdentifier: string
  membershipCount?: number
  // For displaying a different project and/or workspace
  // E.g. when moving the share to a different project
  destination?: TransferDocumentInfo
  // For displaying only the workspace row.
  // E.g. removing the list of permissions when moving a share to a different workspace
  hideMembersList?: boolean

  // Users is moving a document to My Drafts and so we hide
  // the first item of the access list (the project)
  isMovingToDrafts?: boolean
}

const PERSONAL_MIN_MEMBERSHIPS = 1
const MEMBERSHIPS_PAGE_SIZE = 20
const MEMBERSHIPS_LIMIT = 50

const Wrapper: React.FC = ({ children }) => (
  <Section aria-label="access-list">
    <Title>These people will have access:</Title>
    <List>{children}</List>
  </Section>
)

const PersonPlaceholder = () => (
  <Row>
    <PlaceholderAvatar size="24px" flavour="color" name="" />
    <span className="sr-only">placeholder item</span>
  </Row>
)

const createPersonPlaceholder = (size: number) =>
  new Array(size)
    .fill(null)
    .map((_, index) => <PersonPlaceholder key={index} />)

const ShareAccessList: React.FC<ShareAccessListProps> = props => {
  const {
    shareIdentifier,
    membershipCount,
    destination,
    hideMembersList,
    isMovingToDrafts,
  } = props

  const { error, data, loading } = useGetShareMembershipsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      shareIdentifier,
      limit: MEMBERSHIPS_LIMIT,
    },
  })

  const { share } = data || {}
  const memberships = share?.members?.entries || []

  if (loading && memberships.length === 0) {
    return (
      <Wrapper>
        <Row>
          <PlaceholderWorkspaceAvatar size="24px" workspaceName="  " />
        </Row>
        {createPersonPlaceholder(membershipCount || PERSONAL_MIN_MEMBERSHIPS)}
      </Wrapper>
    )
  }

  if (error || !share || !share.members) {
    return (
      <Wrapper>
        <ErrorText>There was a problem loading the access list</ErrorText>
      </Wrapper>
    )
  }

  const { workspace: originWorkspace } = share

  const workspace = destination?.workspace
    ? destination?.workspace
    : originWorkspace

  return (
    <Wrapper>
      {isMovingToDrafts ? null : destination?.project.workspaceAccessLevel ===
        'NONE' ? (
        <StyledProjectRow
          project={destination?.project}
          workspace={workspace as PublicWorkspaceFragment}
          actions={<Permission>Default</Permission>}
        />
      ) : (
        <StyledWorkspaceRow
          showAllMembers
          workspace={workspace as WorkspaceMinimalFragment}
          actions={<Permission>Default</Permission>}
        />
      )}

      {!hideMembersList && (
        <>
          {memberships.map(member => {
            if (member?.__typename === 'PermissionGroupRelationShare') {
              const { identifier, permissionGroup, accessLevel } = member

              return (
                <Row key={identifier}>
                  <PermissionGroupIcon
                    name={permissionGroup.name}
                    size="24px"
                  />

                  <Name>{permissionGroup.name}</Name>
                  <Permission>{getPermissionsLabel(accessLevel)}</Permission>
                </Row>
              )
            }

            const {
              identifier,
              workspaceMembership,
              privateAccessLevel,
              email,
            } = member

            const { user } = workspaceMembership || {}
            const name = user?.name || email || ''
            const role = workspaceMembership?.role

            return (
              <Row key={identifier}>
                {user?.avatar?.small ? (
                  <PersonAvatar
                    size="24px"
                    flavour="image"
                    src={user.avatar.small}
                    name={name}
                  />
                ) : (
                  <PersonAvatar
                    size="24px"
                    flavour="color"
                    name={name}
                    color="default"
                  />
                )}
                <Name>
                  {name}{' '}
                  {role === 'GUEST' && <Pill variant="guest">Guest</Pill>}
                </Name>
                <Permission>
                  {getPermissionsLabel(privateAccessLevel)}
                </Permission>
              </Row>
            )
          })}

          {share.members.meta.totalCount > MEMBERSHIPS_LIMIT && (
            <MoreMembers>{`${
              share.members.meta.totalCount - MEMBERSHIPS_LIMIT
            } More`}</MoreMembers>
          )}
        </>
      )}

      {!hideMembersList &&
        loading &&
        createPersonPlaceholder(
          Math.min(
            MEMBERSHIPS_PAGE_SIZE,
            share.members.meta.totalCount - share.members.entries.length
          )
        )}
    </Wrapper>
  )
}

export default ShareAccessList

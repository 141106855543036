import styled, { css } from 'styled-components';
import { breakpoint } from '@sketch/global-styles';
import '../Button/index.js';
import { LinkButton } from '../Button/LinkButton.js';

const CloseIconContainer = styled.div `
  position: absolute;
  right: 16px;
  top: 16px;
`;
const NewFeatureHighlightContainerSidebar = styled.div `
  position: absolute;
  right: -8px;
  bottom: -16px;
  left: initial;
  top: initial;

  ${breakpoint('sm') `${css `
    left: -8px;
    top: -8px;
  `}`}
`;
const NewFeatureHighlightContainerHeader = styled.div `
  position: absolute;
  right: -8px;
  bottom: -16px;
  left: initial;
  top: initial;

  ${breakpoint('sm') `${css `
    left: -8px;
    top: -8px;
  `}`}
`;
const StyledLinkButton = styled(LinkButton) `
  ${({ theme, variant }) => variant === 'secondary' &&
    css `
      color: ${theme.colors.sketch.A};
    `}
`;
const ArrowWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;

  /* Forcing the size to be 0 allows the element to center on itself */
  width: 0;
  height: 0;
`;

export { ArrowWrapper, CloseIconContainer, NewFeatureHighlightContainerHeader, NewFeatureHighlightContainerSidebar, StyledLinkButton };

import styled from 'styled-components';
import '../Box/index.js';
import { Text } from '../Box/BoxSystem.js';

const DropdownItemDescription = styled(Text.Div).attrs({
    textStyle: 'copy.quaternary.standard.D',
}) `
  margin: 4px 0 0 0;
  line-height: 1.2;
  white-space: initial;

  ${({ $isDisabled }) => $isDisabled && 'opacity: 0.55;'}
`;

export { DropdownItemDescription };

import React from 'react'

import { ReactComponent as ArtboardIcon } from '@sketch/icons/artboard-16'
import { ReactComponent as TextIcon } from '@sketch/icons/text-format-16'
import { ReactComponent as ShapeIcon } from '@sketch/icons/squares-2-16'
import { ReactComponent as GroupIcon } from '@sketch/icons/folder-open-16'
import { ReactComponent as BitmapIcon } from '@sketch/icons/image-16'
import { ReactComponent as SliceIcon } from '@sketch/icons/square-knife-16'
import { ReactComponent as SymbolIcon } from '@sketch/icons/symbol-16'

import { ExportableIcon } from '../../ExportableIcon'
import { ElementType } from 'modules/inspector'

import * as S from './ElementIcon.styles'

export interface ElementIconProps {
  maskColor?: string
  elementType: ElementType
  isLayerMasked: boolean
  isLayerExportable: boolean
}
export function ElementIcon({
  elementType,
  maskColor,
  isLayerMasked,
  isLayerExportable,
}: ElementIconProps) {
  return (
    <S.ElementIconContainer>
      {isLayerMasked && <S.StyledMaskedIcon />}
      <ContextMenuLayerIcon elementType={elementType} />
      {/* Slice already has an exportable icon present on the icon */}
      {isLayerExportable && elementType !== 'slice' && (
        <ExportableIcon maskColor={maskColor} />
      )}
    </S.ElementIconContainer>
  )
}

interface ContextMenuLayerIconProps {
  elementType: ElementType
}

// Gets the element icon url based on the element type.
const ContextMenuLayerIcon = ({ elementType }: ContextMenuLayerIconProps) => {
  switch (elementType) {
    case 'artboard':
      return <ArtboardIcon width={17} height={17} />
    case 'bitmap':
      return <BitmapIcon width={17} height={17} />
    case 'text':
      return <TextIcon width={17} height={17} />
    case 'group':
      return <GroupIcon width={17} height={17} />
    case 'slice':
      return <SliceIcon width={17} height={17} />
    case 'symbolInstance':
      return <SymbolIcon width={17} height={17} />
    default:
      return <ShapeIcon width={17} height={17} />
  }
}

import { useCallback, useEffect, useRef } from 'react'
import { SketchElement, Point } from 'modules/inspector'
import { useIsCameraMoving } from '@sketch-hq/sketch-web-renderer'
import { useFindElementFromCanvasRelativePositionInScreenSpace } from '../useFindElementFromCanvasRelativePositionInScreenSpace'
import { findPointRelativeToCanvasInScreenSpaceFromMouseEvent } from './findPointRelativeToCanvasInScreenSpaceFromMouseEvent'

/**
 * Hook creating the handler for the mousemove events on the canvas.
 * It also watches for camera movements and recompute the hovered position
 * if the scene has changed after a pan or zoom from the user.
 */
export function useCanvasMouseMoveHandler(
  /** This should only be enabled when the inspector is open and ready */
  isEnabled: boolean,
  sketchSceneRootElement: SketchElement | null,
  onMouseMove: (element: SketchElement | null) => void,
  doesLayerElementAllowUserInteraction?: (
    rootElement: SketchElement,
    element: SketchElement,
    event: React.MouseEvent<HTMLElement> | undefined
  ) => boolean
) {
  /**
   * Store the last mouse position so we can reuse it to recalculate the hovered element
   * if the user moves the camera. This is needed for example when the user scrolls,
   * making the scene move behind the cursor without moving it. With the scene moved,
   * another element is likely to be hovered.
   */
  const lastMousePositionRelativeToCanvasInScreenSpaceRef = useRef<Point | null>(
    null
  )

  const isCameraMoving = useIsCameraMoving()
  const findTargetElementFromCanvasRelativePositionInScreen = useFindElementFromCanvasRelativePositionInScreenSpace(
    sketchSceneRootElement
  )

  const mouseMoveHandler = useCallback(
    (reactEvent: React.MouseEvent<HTMLElement>) => {
      const pointRelativeToCanvasInScreenSpace = findPointRelativeToCanvasInScreenSpaceFromMouseEvent(
        reactEvent
      )

      if (!pointRelativeToCanvasInScreenSpace || !sketchSceneRootElement) {
        return null
      }

      lastMousePositionRelativeToCanvasInScreenSpaceRef.current = pointRelativeToCanvasInScreenSpace
      // Save resources but not recomputed the selected element while user
      // is zooming or panning the camera.
      if (isCameraMoving) {
        return null
      }
      const matchingElement = findTargetElementFromCanvasRelativePositionInScreen(
        pointRelativeToCanvasInScreenSpace,
        element =>
          doesLayerElementAllowUserInteraction?.(
            sketchSceneRootElement,
            element,
            reactEvent
          ) ?? true
      )
      onMouseMove(matchingElement)
    },
    [
      doesLayerElementAllowUserInteraction,
      findTargetElementFromCanvasRelativePositionInScreen,
      isCameraMoving,
      onMouseMove,
      sketchSceneRootElement,
    ]
  )

  // Recompute the hovered element before and after the camera has moved.
  useEffect(() => {
    if (!isEnabled || !sketchSceneRootElement) {
      // Don't run the hook if we are not on the inspector view
      return
    }
    // When the camera becomes stable again, recompute the hovered element
    if (!isCameraMoving) {
      const pointRelativeToCanvasInScreenSpace =
        lastMousePositionRelativeToCanvasInScreenSpaceRef.current

      if (!pointRelativeToCanvasInScreenSpace) {
        return
      }

      const matchingElement = findTargetElementFromCanvasRelativePositionInScreen(
        pointRelativeToCanvasInScreenSpace,
        element => {
          return (
            doesLayerElementAllowUserInteraction?.(
              sketchSceneRootElement,
              element,
              undefined
            ) ?? true
          )
        }
      )

      onMouseMove(matchingElement)
    }

    // Only recompute each time the camera changes the isCameraMoving state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCameraMoving])

  return mouseMoveHandler
}

import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import {
  Button,
  Link,
  Section,
  useModalContext,
  useStripe,
} from '@sketch/components'

import {
  PlanOverviewPanel,
  BillingHistoryPanel,
  BillingWorkspacePanel,
  PanelSeparator,
  PaymentInformationPanel,
  WorkspaceSettingsSubscribePanel,
  PartnersPanel,
  WorkspaceStoragePanel,
} from 'modules/workspace/components'
import { WorkspaceSettingsUpsellBanner } from 'modules/workspace/components/Upsell'

// Modals
import {
  CancelScheduleChangeModal,
  CancelWorkspaceSubscriptionModal,
  ChangeNextBillingCycleModal,
  ReactivateSubscriptionModal,
} from 'modules/workspace/modals'

import {
  BillingPlanFragment,
  BillingSeatsInfoFragment,
  BillingDetailsFragment,
  PaymentDetailsFragment,
  WorkspaceMembershipFragment,
  GetWorkspaceStorageDetailsQuery,
} from '@sketch/gql-types'

// Usage of BillingStatus enum is needed to make the expected strings match the BE ones
// eslint-disable-next-line no-restricted-imports
import { BillingStatus } from '@sketch/gql-types/expansive'

import {
  isWorkspaceSubscriptionActive,
  isWorkspaceSubscriptionTrialling,
  hasPaymentDetails,
} from 'modules/workspace/utils'
import { dateFormat } from '@sketch/utils'
import { useFlag } from '@sketch/modules-common'

import {
  PartnersBanner,
  EducationBanner,
} from './WorkspaceSettingsBilling.styles'

interface BillingProps {
  currentPlan?: BillingPlanFragment
  credits: number
  seats: BillingSeatsInfoFragment
  workspaceId: string
  workspaceName: string
  customerId: string
  subscriptionStatus: BillingStatus
  nextBillingCycleDate?: string
  projectedCost?: number
  subscriptionEnd?: string
  billingDetails: BillingDetailsFragment
  paymentDetails?: PaymentDetailsFragment
  trialEnd: string
  pendingSCAToken?: string
  isWorkspaceSubscribed?: boolean
  isSsoActive?: boolean
  onWorkspaceSettingRefresh: () => Promise<void>
  isFinance?: boolean
  isPartner?: boolean
  isAdmin: boolean
  partner: WorkspaceMembershipFragment | null
  isEducationWorkspace: boolean
  showUpsell?: boolean
  storageDetails?: GetWorkspaceStorageDetailsQuery
  hasUnlimitedStorage: boolean
}

/**
 *
 * Billing
 *
 * Renders the "Billing" page content in "Workspace Settings"
 */
export const WorkspaceSettingsBilling: React.FC<BillingProps> = props => {
  // TODO: remove this flag when feature is shipped
  // https://github.com/sketch-hq/Cloud/issues/17947
  const isWorkspaceStorageFFEnabled = useFlag('workspace-storage')

  const {
    currentPlan,
    credits,
    seats,
    workspaceId,
    workspaceName,
    customerId,
    projectedCost,
    subscriptionStatus,
    subscriptionEnd,
    nextBillingCycleDate,
    billingDetails,
    paymentDetails,
    trialEnd,
    pendingSCAToken,
    isSsoActive,
    onWorkspaceSettingRefresh,
    isFinance,
    isPartner,
    isAdmin,
    showUpsell,
    partner,
    isEducationWorkspace,
    storageDetails,
    hasUnlimitedStorage,
  } = props

  const { hash } = useLocation()

  const { load: loadStripe } = useStripe()
  const { showModal } = useModalContext()

  // Load stripe in order previously
  useEffect(() => {
    loadStripe()
  }, [loadStripe])

  const isWorkspaceOnTrial = isWorkspaceSubscriptionTrialling(
    subscriptionStatus
  )
  const isSubscriptionActive = isWorkspaceSubscriptionActive(subscriptionStatus)
  const customerHasPaymentDetails = hasPaymentDetails(paymentDetails)

  const isPartneredWorkspace = isPartner || (partner && !!partner.acceptedAt)

  const canCancelSubscription =
    isSubscriptionActive &&
    !subscriptionEnd &&
    nextBillingCycleDate &&
    customerHasPaymentDetails &&
    !isFinance &&
    !(!!isPartneredWorkspace && !isPartner)

  // Handle scroll to Sketch Partner section
  const sketchPartnerSectionRef = useRef<HTMLDivElement | null>(null)
  const handleScrollPosition = () => {
    if (hash === '#sketch-partner') {
      window.requestAnimationFrame(() => {
        if (
          !sketchPartnerSectionRef.current ||
          !sketchPartnerSectionRef.current.scrollIntoView
        )
          return
        sketchPartnerSectionRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      })
    }
  }

  const educationBannerExpireDate = nextBillingCycleDate ?? subscriptionEnd

  const shouldRenderPaymentDetails =
    (isEducationWorkspace && !customerHasPaymentDetails) ||
    (isPartneredWorkspace && !isPartner)
      ? false
      : credits !== 0 || (paymentDetails && paymentDetails.type !== 'NONE')

  const shouldRenderPartnersPanel =
    (!isEducationWorkspace || customerHasPaymentDetails) && !isPartner

  const shouldRenderPlanOverview =
    (!isEducationWorkspace || customerHasPaymentDetails) &&
    (!isPartneredWorkspace || isPartner)

  const shouldRenderPartnerBanner =
    !isPartner && partner && partner.acceptedAt && partner?.user?.name

  const shouldRenderEducationBanner =
    isEducationWorkspace && !customerHasPaymentDetails

  const shouldRenderWorkspaceStorage =
    isWorkspaceStorageFFEnabled && storageDetails

  const canDeleteLargeFiles = isAdmin

  return (
    <>
      {shouldRenderEducationBanner && (
        <EducationBanner type="information">
          Your Education Workspace is free until{' '}
          {dateFormat(new Date(educationBannerExpireDate!))}. <br />
          <Link href={`${SKETCH_WEBSITE}/support/contact/`} external>
            Get in touch
          </Link>{' '}
          if you have any questions or need for an extension.
        </EducationBanner>
      )}

      {shouldRenderPartnerBanner && (
        <PartnersBanner type="information">
          {partner!.user!.name} (Sketch Partner) manages billing in this
          Workspace.
          <br />
          If you have any questions, please email{' '}
          <Link external href="mailto:partners@sketch.com" isUnderlined>
            partners@sketch.com
          </Link>
        </PartnersBanner>
      )}

      {!customerHasPaymentDetails &&
        !isEducationWorkspace &&
        isWorkspaceOnTrial && (
          <PanelSeparator>
            <WorkspaceSettingsSubscribePanel workspaceId={workspaceId} />
          </PanelSeparator>
        )}

      {isPartner && (
        <PanelSeparator>
          <BillingWorkspacePanel
            workspaceName={workspaceName}
            workspaceId={workspaceId}
          />
        </PanelSeparator>
      )}

      {shouldRenderPlanOverview && (
        <PanelSeparator>
          <PlanOverviewPanel
            workspaceId={workspaceId}
            customerId={customerId}
            currentPlan={currentPlan}
            seats={seats}
            subscriptionStatus={subscriptionStatus}
            customerHasPaymentDetails={customerHasPaymentDetails}
            subscriptionEnd={subscriptionEnd}
            nextBillingCycleDate={nextBillingCycleDate}
            projectedCost={projectedCost}
            trialEndDate={trialEnd}
            isPartner={isPartner}
            isSsoActive={isSsoActive}
            paymentDetails={paymentDetails}
            onWorkspaceSettingRefresh={onWorkspaceSettingRefresh}
            onReactivateSubscription={() => {
              showModal(ReactivateSubscriptionModal, {
                currentPlan: currentPlan!,
                subscriptionEnd: subscriptionEnd!,
                customerId,
                workspaceId,
                isPartner,
              })
            }}
            onSeatScheduleChange={() => {
              currentPlan &&
                showModal(ChangeNextBillingCycleModal, {
                  currentPlan,
                  customerId,
                  currentSeats: seats.currentSeatsTotal,
                  scheduledSeats: seats.scheduledSeatsTotal || undefined,
                  workspaceId,
                  variant: isPartner ? 'partner' : 'default',
                  nextBillingCycleDate,
                })
            }}
            onCancelSeatScheduleChange={() => {
              nextBillingCycleDate &&
                showModal(CancelScheduleChangeModal, {
                  workspaceId,
                  currentSeats: seats.currentSeatsTotal,
                  customerId,
                  currentPlan,
                })
            }}
          />
        </PanelSeparator>
      )}

      {showUpsell && (
        <WorkspaceSettingsUpsellBanner
          localStorageKey="upsell-business-billing"
          bannerText="Need greater flexibility in payment methods or terms? Upgrade to our Business plan."
        />
      )}

      {shouldRenderWorkspaceStorage && (
        <PanelSeparator>
          <WorkspaceStoragePanel
            workspaceId={workspaceId}
            storageDetails={storageDetails}
            hasUnlimitedStorage={hasUnlimitedStorage}
            canDeleteLargeFiles={canDeleteLargeFiles}
          />
        </PanelSeparator>
      )}

      {shouldRenderPaymentDetails && (
        <PanelSeparator>
          <PaymentInformationPanel
            onWorkspaceSettingRefresh={onWorkspaceSettingRefresh}
            credits={credits}
            customerId={customerId}
            billingDetails={billingDetails}
            paymentDetails={paymentDetails}
            subscriptionStatus={subscriptionStatus}
            pendingSCAToken={pendingSCAToken}
            currentPlan={currentPlan}
            isSsoActive={isSsoActive}
          />
        </PanelSeparator>
      )}
      <PanelSeparator>
        <BillingHistoryPanel
          customerId={customerId}
          workspaceId={workspaceId}
          trialEndDate={trialEnd}
          isWorkspaceOnTrial={isWorkspaceOnTrial}
          cancelAtPeriodEnd={subscriptionEnd}
          customerHasPaymentDetails={customerHasPaymentDetails}
          isEducationWorkspace={isEducationWorkspace}
          nextBillingCycleDate={nextBillingCycleDate}
          onLoadComplete={handleScrollPosition}
          isPartner={isPartner}
          partnerAcceptAt={partner?.acceptedAt}
          partnerName={partner?.user?.name}
        />
      </PanelSeparator>

      {shouldRenderPartnersPanel && (
        <PanelSeparator ref={sketchPartnerSectionRef}>
          <PartnersPanel
            workspaceIdentifier={workspaceId}
            isSubscriptionActive={isSubscriptionActive}
            partner={partner!}
          />
        </PanelSeparator>
      )}

      {canCancelSubscription && (
        <PanelSeparator>
          {/* "data-testid="danger-zone-panel"" is used to keep consistency with the older panels in tests */}
          <Section
            data-testid="danger-zone-panel"
            title="Cancel Subscription"
            text="After canceling, you'll be able to use Sketch until the end of the current billing period."
            action={
              <Button
                onClick={() =>
                  showModal(CancelWorkspaceSubscriptionModal, {
                    customerId,
                    nextBillingCycleDate: nextBillingCycleDate!,
                    workspaceId,
                    isPartner,
                  })
                }
                size="40"
                variant="negative-secondary"
              >
                Cancel Subscription&hellip;
              </Button>
            }
          />
        </PanelSeparator>
      )}
    </>
  )
}

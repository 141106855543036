import { __rest } from 'tslib';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { useLocation, NavLink } from 'react-router-dom';
import { useHover, useIsNodeContentTruncated } from '@sketch/utils';
import { Icon, HighlightIconWrapper, MenuLink, Text, ActionWrapper, DropdownAction, EllipsisIcon } from './Item.styles.js';
import '../../Button/index.js';
import '../../Tooltip/index.js';
import '../../ResponsiveDropdown/index.js';
import { ButtonUnstyled } from '../../Button/ButtonUnstyled.js';
import { Tooltip } from '../../Tooltip/Tooltip.js';
import { useResponsiveDropdown } from '../../ResponsiveDropdown/hooks/useResponsiveDropdown.js';

const SelectDropdownItem = React.forwardRef(function SelectDropdownItem(props, ref) {
    const { active, action, additional, className, iconClassName, forceHover, icon, text, textNodeRef, showActionsOnHover } = props, buttonProps = __rest(props, ["active", "action", "additional", "className", "iconClassName", "forceHover", "icon", "text", "textNodeRef", "showActionsOnHover"]);
    const [hovered, hoverEventListeners] = useHover();
    const renderIcon = () => {
        if (!icon)
            return;
        if (typeof icon === 'object' && icon.icon) {
            const iconElement = (jsx(Icon, { className: iconClassName, as: icon.icon, role: "image" }));
            return icon.highlight ? (jsx(HighlightIconWrapper, { children: iconElement })) : (iconElement);
        }
        return (jsx(Icon, { className: iconClassName, as: icon }));
    };
    return (jsxs(MenuLink, Object.assign({ ref: ref, as: ButtonUnstyled, "aria-label": text, className: classNames(className, { active }), "$forceHover": forceHover }, buttonProps, (showActionsOnHover && hoverEventListeners), { children: [renderIcon(), jsx(Text, Object.assign({ ref: textNodeRef }, { children: text })), action && (jsx(ActionWrapper, Object.assign({}, (!showActionsOnHover && hoverEventListeners), { onClick: event => event.stopPropagation() }, { children: showActionsOnHover ? hovered && action : action }))), additional] })));
});
const SelectDropdownButtonItem = props => {
    const { action, additional, className, iconClassName, forceHover, icon, text } = props, buttonProps = __rest(props, ["action", "additional", "className", "iconClassName", "forceHover", "icon", "text"]);
    const [isNodeContentTruncated, truncatedNodeRef] = useIsNodeContentTruncated();
    return (jsx(Tooltip, Object.assign({ placement: "right", content: text, disabled: !isNodeContentTruncated }, { children: props => (jsx(SelectDropdownItem, Object.assign({ action: action, additional: additional, className: className, iconClassName: iconClassName, forceHover: forceHover, icon: icon, text: text, textNodeRef: truncatedNodeRef }, buttonProps, props))) })));
};
const SelectDropdownLinkItem = forwardRef((props, ref) => {
    const { action, additional, className, iconClassName, forceHover, forceActive, icon, text, showActionsOnHover } = props, linkProps = __rest(props, ["action", "additional", "className", "iconClassName", "forceHover", "forceActive", "icon", "text", "showActionsOnHover"]);
    const [isNodeContentTruncated, truncatedNodeRef,] = useIsNodeContentTruncated();
    const [hovered, hoverEventListeners] = useHover();
    /* This additional connect to the react-router-context would be unnecessary using the "component" prop */
    /* But react-router doesn't export the "LinkAnchor" component */
    const location = useLocation();
    const renderIcon = () => {
        if (!icon)
            return;
        if (typeof icon === 'object' && icon.icon) {
            const iconVariant = (location.pathname === linkProps.to && (icon === null || icon === void 0 ? void 0 : icon.activeIcon)) || icon.icon;
            const iconElement = jsx(Icon, { className: iconClassName, as: iconVariant });
            return icon.highlight ? (jsx(HighlightIconWrapper, { children: iconElement })) : (iconElement);
        }
        return (jsx(Icon, { className: iconClassName, as: icon }));
    };
    return (jsx(Tooltip, Object.assign({ placement: "right", content: text, disabled: !isNodeContentTruncated || hovered }, { children: jsxs(MenuLink, Object.assign({ as: NavLink, activeClassName: "active", className: className, "$forceHover": forceHover, "$forceActive": forceActive, ref: ref, "data-unmountafterclick": "true" }, (showActionsOnHover && hoverEventListeners), linkProps, { children: [renderIcon(), jsx(Text, Object.assign({ ref: truncatedNodeRef }, { children: text })), action && (jsx(ActionWrapper, Object.assign({}, (!showActionsOnHover && hoverEventListeners), { onClick: event => event.stopPropagation() }, { children: showActionsOnHover ? hovered && action : action }))), additional] })) })));
});
SelectDropdownLinkItem.displayName = 'LinkItem';
const SelectDropdownExternalLinkItem = props => {
    const { action, additional, iconClassName, forceHover, icon, text } = props, anchorProps = __rest(props, ["action", "additional", "iconClassName", "forceHover", "icon", "text"]);
    const [isNodeContentTruncated, truncatedNodeRef] = useIsNodeContentTruncated();
    return (jsx(Tooltip, Object.assign({ placement: "right", content: text, disabled: !isNodeContentTruncated }, { children: props => (jsxs(MenuLink, Object.assign({ as: "a", rel: "noopener noreferrer", target: "_blank", "$forceHover": forceHover }, anchorProps, props, { children: [icon && jsx(Icon, { className: iconClassName, as: icon }), jsx(Text, Object.assign({ ref: truncatedNodeRef }, { children: text })), action && jsx(ActionWrapper, { children: action }), additional] }))) })));
};
const ActionPopoverWrapper = ({ children }) => jsx("div", { children: children });
const SelectDropdownActionDropdown = props => {
    const { content, text } = props;
    const [dropdownContent, buttonProps] = useResponsiveDropdown({
        dropdown: ActionPopoverWrapper,
        dropdownProps: { children: content },
        placement: 'bottom',
        usePortal: true,
    });
    return (jsxs(Fragment, { children: [jsxs(DropdownAction, Object.assign({ "data-testid": "project-options" }, buttonProps, { children: [jsx(EllipsisIcon, {}), jsx("span", Object.assign({ className: "sr-only" }, { children: text }))] })), dropdownContent] }));
};

export { SelectDropdownActionDropdown, SelectDropdownButtonItem, SelectDropdownExternalLinkItem, SelectDropdownItem, SelectDropdownLinkItem };

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { BaseListRow } from './BaseListRow.js';
import { BaseListRowPlaceholderAvatar as PlaceholderAvatar } from './BaseListRow.styles.js';

const BaseListRowPlaceholder = (_a) => {
    var { name = 'Placeholder item', showStripes = false } = _a, boxProps = __rest(_a, ["name", "showStripes"]);
    return (jsx(BaseListRow, Object.assign({ name: name, avatar: jsx(PlaceholderAvatar, { size: "32px", name: "", flavour: "color" }), showStripes: showStripes }, boxProps, { children: jsx("span", Object.assign({ className: "sr-only" }, { children: name })) })));
};

export { BaseListRowPlaceholder };

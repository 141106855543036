import React, { useMemo } from 'react'
import styled from 'styled-components'

import { Section } from '../../components/Section'
import { Attribute } from '../../components/Attribute'
import { Separator } from '../../components/Separator'

import {
  ElementType,
  SketchElement,
  SketchLayerElement,
  isLayerElement,
} from 'modules/inspector'
import { ElementIcon } from './ElementIcon'
import { ComponentName, ComponentSubName } from '../../components/ComponentName'

import * as S from './Overview.styles'

const OverlaySection = styled(Section)`
  width: 100%;
  padding-right: 20px;

  position: sticky;
  top: 0;

  background: var(
    --background-color,
    ${({ theme }) => theme.colors.background.secondary.A}
  );

  z-index: 1;

  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.border.A};

  & + ${Separator} {
    /* Hide the first separator so it doesn't show when rendering  */
    visibility: hidden;
  }
`

const OverlayAttribute = styled(Attribute)`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  padding-right: 0;
`

type OverviewProps = {
  sketchElement: SketchElement
}
/**
 * Overview info of the given sketchElement. The overviews shows an icon
 * corresponding to the element type, the name and sometimes the symbol name path
 * or a "copy css" button when relevant.
 */
export const Overview: React.FC<OverviewProps> = ({ sketchElement }) => {
  const {
    displayedName,
    symbolNamePath,
  } = useDisplayedLayerNameAndOptionalPath(sketchElement)
  if (!sketchElement) {
    return null
  }

  /**
   * We want to display as symbol the elements that are symbol instances
   * or the artboards that are symbol masters when the element is an artboard.
   */
  const isSymbol =
    sketchElement.type === ElementType.SymbolMaster ||
    sketchElement.type === ElementType.SymbolInstance

  return (
    <OverlaySection data-testid="inspector-sidebar-overview">
      <OverlayAttribute>
        <ElementIcon
          elementType={sketchElement.type}
          // Layers that are exportable gets an extra icon on top of the
          // normal layer type icon.
          hasExports={sketchElement.isExportable}
          isSymbol={isSymbol}
        />
        <S.OverviewComponentName>
          <ComponentName>{displayedName}</ComponentName>
          {symbolNamePath && (
            <ComponentSubName>{symbolNamePath}</ComponentSubName>
          )}
        </S.OverviewComponentName>
        {isLayerElement(sketchElement) && (
          <OverviewCopyCSSButton layerElement={sketchElement} />
        )}
      </OverlayAttribute>
    </OverlaySection>
  )
}

type OverviewCopyCSSButtonProps = {
  layerElement: SketchLayerElement
}

function OverviewCopyCSSButton({ layerElement }: OverviewCopyCSSButtonProps) {
  if (
    layerElement.type === ElementType.Slice ||
    layerElement.type === ElementType.Hotspot
  ) {
    return null
  }

  const cornerRadius = layerElement.corners?.radius

  const cssValuesToCopy = {
    ...layerElement.style,
    corners: cornerRadius ? { cornerRadius } : undefined,
  }

  return <S.OverviewCopyCSSButton style={cssValuesToCopy} />
}

/**
 * The name displayed in the overview is sometimes more than just the layer name.
 * Symbols can be named in Sketch with Slashes in the name.
 * E.g. `Primitive/Icon/Arrow/Right`. Slashes in symbol names act like groups,
 * and the name gets divided into symbolMasterNamePath and symbolMasterShortName
 * if that's the case.
 */
function useDisplayedLayerNameAndOptionalPath(
  sketchElement: SketchElement | undefined
): { displayedName: string; symbolNamePath: string | undefined } {
  return useMemo(() => {
    if (!sketchElement) {
      return { displayedName: '', symbolNamePath: undefined }
    }

    if (sketchElement.type !== 'symbolMaster') {
      return {
        displayedName: sketchElement.name,
        symbolNamePath: undefined,
      }
    }

    if (
      sketchElement.symbolMasterNamePath?.length &&
      sketchElement.symbolMasterShortName
    ) {
      const symbolNamePathWithDocument = `This Document/${sketchElement.symbolMasterNamePath.join(
        '/'
      )}`
      return {
        displayedName: sketchElement.symbolMasterShortName,
        symbolNamePath: symbolNamePathWithDocument,
      }
    }

    return {
      displayedName: sketchElement.name,
      symbolNamePath: 'This Document',
    }
  }, [sketchElement])
}

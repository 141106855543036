import { __awaiter } from 'tslib';
import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { useState } from 'react';
import styled from 'styled-components';
import { Avatar, Spinner, FileSelection, Dropdown } from '@sketch/components';
import { useToast } from '@sketch/toasts';
import { validateImage, noop, castError } from '@sketch/utils';
import './utils.js';
import { useUserAvatarDeleteMutation, useUserAvatarCreateMutation } from '@sketch/gql-types';

const AvatarWrapper = styled.div `
  position: relative;
  display: inline-block;
`;
const Overlay = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.8125rem;
  text-align: center;
  cursor: pointer;

  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: opacity
    ${({ theme }) => `${theme.transitions.duration[2]} ${theme.transitions.timing.easeInOut}`};

  &:hover {
    opacity: 1;
  }
`;
const AvatarUploadContainer = props => {
    const { userName, userHasAvatar, userAvatarSrc } = props;
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const { showToast } = useToast();
    const [deleteImage, { loading: deleteLoading }] = useUserAvatarDeleteMutation({
        redirectErrors: true,
        onError: 'show-toast',
    });
    const [uploadImage, { loading: uploadLoading }] = useUserAvatarCreateMutation({
        redirectErrors: true,
        onError: 'show-toast',
    });
    const handleDeleteAvatar = () => {
        deleteImage();
    };
    const handleFileOnChange = (file) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield validateImage(file);
            /* Mutation will handle the errors from this promise */
            yield uploadImage({ variables: { file } }).catch(noop);
        }
        catch (e) {
            const error = castError(e);
            showToast(error.message, 'negative');
        }
    });
    const isLoading = deleteLoading || uploadLoading;
    const avatar = (jsxs(AvatarWrapper, { children: [jsx(Avatar, { src: userAvatarSrc, name: userName, size: '64px', "data-testid": "user-update-avatar" }), jsx(Overlay, Object.assign({ active: isDropdownOpen || isLoading }, { children: isLoading ? (jsx(Spinner, {})) : (jsxs(Fragment, { children: ["Update", jsx("br", {}), "Image"] })) }))] }));
    return (jsx(FileSelection, Object.assign({ onFileSelected: handleFileOnChange, accept: "image/*" }, { children: ({ onSelectFile, loading: fileIsLoading }) => userHasAvatar ? (jsxs(Dropdown, Object.assign({ onToggle: setDropdownOpen, toggle: avatar, disabled: fileIsLoading || isLoading }, { children: [jsx(Dropdown.Item, Object.assign({ onClick: onSelectFile }, { children: "Upload Image" })), jsx(Dropdown.Item, Object.assign({ "data-testid": "user-remove-avatar", onClick: handleDeleteAvatar }, { children: "Remove Current Image" }))] }))) : (jsx("div", Object.assign({ onClick: onSelectFile }, { children: avatar }))) })));
};

export { AvatarUploadContainer as default };

import { jsx } from 'react/jsx-runtime';
import React, { useState } from 'react';
import styled from 'styled-components';

const Options = styled.ul `
  display: flex;
  padding: 0;
  margin: 0;

  li {
    display: inline-block;
  }

  ${({ shouldDrawSeparator, theme }) => shouldDrawSeparator
    ? `border-bottom: 1px solid ${theme.colors.background.primary.B}`
    : ''}
`;
const TabListOld = ({ children, selectedTabIndex = 0, shouldDrawSeparator = false, onTabSelected, shouldSelectTab = () => true, className, }) => {
    const tabs = React.Children.toArray(children);
    const [selectedTab, setSelectedTab] = useState(selectedTabIndex);
    return (jsx(Options, Object.assign({ shouldDrawSeparator: shouldDrawSeparator, className: className }, { children: tabs.map((tab, index) => {
            return React.cloneElement(tab, {
                onTabSelected: () => {
                    if (!shouldSelectTab(index))
                        return;
                    setSelectedTab(index);
                    onTabSelected === null || onTabSelected === void 0 ? void 0 : onTabSelected(index);
                },
                selected: selectedTab === index,
            });
        }) })));
};
TabListOld.tabType = 'TabList';

export { TabListOld };

import jwtDecode from 'jwt-decode';
import isEqual from 'lodash.isequal';
import * as Sentry from '@sentry/browser';
import { UserFeatureFlagsFragmentDoc, GetUserCredentialsDocument } from '@sketch/gql-types';
import { areAuthorizationIdsEqual, isPersonalAuthorization, isSsoAuthorization, getSessionIdFromToken } from './authorizations.js';
import { getParsedItem, getItem, setStringifiedItem, log } from '@sketch/utils';
import { localStorageKeys } from '@sketch/constants';
import { dataIdFromObject } from '@sketch/graphql-cache';

const updateFeatureFlagsFromToken = (cache, newAuthorization) => {
    const tokenDecode = jwtDecode(newAuthorization.fragment.authToken);
    /**
     * Safeguarding ourself from non-expected
     * format tokens
     */
    if (!tokenDecode.sub || !tokenDecode.flags) {
        return;
    }
    const userProfileId = dataIdFromObject({
        __typename: 'User',
        identifier: tokenDecode.sub,
    });
    cache.writeFragment({
        fragment: UserFeatureFlagsFragmentDoc,
        id: userProfileId,
        fragmentName: 'UserFeatureFlags',
        data: {
            __typename: 'User',
            identifier: tokenDecode.sub,
            featureFlags: tokenDecode.flags,
        },
    });
};
const getUserAuthorization = (cache) => {
    try {
        const data = cache.readQuery({
            query: GetUserCredentialsDocument,
        });
        return (data === null || data === void 0 ? void 0 : data.userCredentials) || null;
    }
    catch (e) {
        return null;
    }
};
const setUserAuthorization = (cache, authorization) => {
    addOrUpdateAuthorization(cache, authorization);
    setApolloCredentials(cache, authorization.fragment);
};
const getAllAuthorizations = () => { var _a; return (_a = getParsedItem(localStorageKeys.userAllAuthorizations)) !== null && _a !== void 0 ? _a : []; };
const setAllAuthorizations = (authorizations) => {
    const oldValue = getItem(localStorageKeys.userAllAuthorizations);
    setStringifiedItem(localStorageKeys.userAllAuthorizations, authorizations);
    /**
     * We dispatch this fake event to allow the useReactiveAuthorization
     * to update it's internal values and. the local-storage event listener
     * only allows the receive changes from other tabs. This work-around will
     * allow it to work always.
     */
    setTimeout(() => {
        const storageEvent = new StorageEvent('storage', {
            key: localStorageKeys.userAllAuthorizations,
            storageArea: localStorage,
            newValue: JSON.stringify(authorizations),
            oldValue,
        });
        window.dispatchEvent(storageEvent);
    });
};
const getActiveAuthorization = (cache) => {
    const credentials = getUserAuthorization(cache);
    const allAuthorizations = getAllAuthorizations();
    return allAuthorizations.find(auth => isEqual(auth.fragment, credentials));
};
const removeActiveAuthorization = (cache) => {
    const activeAuth = getActiveAuthorization(cache);
    if (!activeAuth)
        return;
    removeAuthorizationByAuthToken(cache, activeAuth.fragment.authToken);
    setFallbackAuthorizationId(cache);
};
const removeAuthorizationByAuthToken = (cache, authToken) => {
    const allAuthorizations = getAllAuthorizations();
    const index = allAuthorizations.findIndex(auth => auth.fragment.authToken === authToken);
    const removedAuth = allAuthorizations.splice(index, 1)[0];
    setAllAuthorizations(allAuthorizations);
    const activeAuthorization = getUserAuthorization(cache);
    const isAuthorizationActive = (activeAuthorization === null || activeAuthorization === void 0 ? void 0 : activeAuthorization.authToken) === authToken;
    if (isAuthorizationActive) {
        setFallbackAuthorizationId(cache);
    }
    return removedAuth;
};
/**
 * Replace all authorizations by the one provided. This is useful to entirely
 * switch accounts.
 */
const replaceAllAuthorizations = (authorization) => {
    setAllAuthorizations([authorization]);
};
/**
 * Add or Replace an already existent authorization with the newly negotiated tokens.
 *
 * @param cache Apollo cache's instance
 * @param newAuthorization New Authorization
 */
const addOrUpdateAuthorization = (cache, newAuthorization) => {
    const allAuthorizations = getAllAuthorizations();
    const authorizationsWithTheSameIdentifier = [];
    /**
     * Exclude from the all authorizations saved the authorization
     * that is going to be added.
     */
    const clearedAuthorizations = allAuthorizations.filter(authorization => {
        const isAuthorizationEqual = areAuthorizationIdsEqual(authorization, newAuthorization);
        if (isAuthorizationEqual) {
            authorizationsWithTheSameIdentifier.push(authorization);
        }
        try {
            /**
             * Given that the tokens refresh every 60m and they
             * contain the FF information, might as well updated them
             */
            updateFeatureFlagsFromToken(cache, newAuthorization);
        }
        catch (error) {
            /**
             * If something happens with this update it's not as relevant
             * as the token being updated so we discard interrupting the
             * function flow
             */
            Sentry.captureException(error);
        }
        return !isAuthorizationEqual;
    });
    /**
     * We add the new/updated authorization at the beginning
     * of the list to promote the newer tokens when sending
     * as secondary tokens.
     *
     * https://github.com/sketch-hq/Cloud/issues/15988
     */
    clearedAuthorizations.unshift(newAuthorization);
    setAllAuthorizations(clearedAuthorizations);
    const activeAuthorization = getUserAuthorization(cache);
    const isNewAuthorizationActive = authorizationsWithTheSameIdentifier.find(({ fragment }) => (activeAuthorization === null || activeAuthorization === void 0 ? void 0 : activeAuthorization.authToken) === fragment.authToken);
    /**
     * If the current active authorization was the updated one
     * then we should reflect it on apollo
     */
    if (isNewAuthorizationActive) {
        setApolloCredentials(cache, newAuthorization.fragment);
    }
};
/**
 * Sets the active authorization for a workspace. If the workspace has SSO
 * enabled then we try setting the current session as the one with authorization
 * for that workspace. We use a personal session otherwise.
 */
const setActiveAuthorizationIdForWorkspace = (workspace, cache) => {
    const { ssoEnabled, identifier } = workspace;
    if (ssoEnabled) {
        setActiveAuthorizationId({ type: 'sso', workspaceId: identifier }, cache);
    }
    else {
        setActiveAuthorizationId({ type: 'personal' }, cache);
    }
};
/**
 * Configures active credentials picking one from the list of sessions.
 * This is used as a default value in case the current route doesn't set any
 * active credentials.
 */
const setFallbackAuthorizationId = (cache) => {
    const allAuthorizations = getAllAuthorizations();
    const personalAuthorization = allAuthorizations.find(isPersonalAuthorization);
    const auth = personalAuthorization !== null && personalAuthorization !== void 0 ? personalAuthorization : allAuthorizations[0];
    setApolloCredentials(cache, (auth === null || auth === void 0 ? void 0 : auth.fragment) || null);
};
const setActiveAuthorizationId = (authId, cache) => {
    var _a;
    const allAuthorizations = getAllAuthorizations();
    const activeAuthorization = allAuthorizations.find(auth => areAuthorizationIdsEqual(auth, authId));
    const personalAuthorization = allAuthorizations.find(isPersonalAuthorization);
    /**
     * We fallback first to personal authorization as it's the default token we
     * used for everything before introducing SSO. Then, if there is no personal
     * token we just get the first stored authorization.
     */
    const auth = (_a = activeAuthorization !== null && activeAuthorization !== void 0 ? activeAuthorization : personalAuthorization) !== null && _a !== void 0 ? _a : allAuthorizations[0];
    log(`Session -> Setting active auth to ${authId.type}. Using ${auth === null || auth === void 0 ? void 0 : auth.type}`);
    setApolloCredentials(cache, auth === null || auth === void 0 ? void 0 : auth.fragment);
};
const setApolloCredentials = (cache, credentials) => {
    const previousCredentials = getUserAuthorization(cache);
    /**
     * If both credentials are exactly the same we avoid updating them. This
     * prevents Apollo from firing update events that all subscriptions would
     * receive.
     */
    if (isEqual(previousCredentials, credentials)) {
        return;
    }
    cache.writeQuery({
        query: GetUserCredentialsDocument,
        data: {
            __typename: 'RootQueryType',
            userCredentials: credentials,
        },
    });
};
/**
 * Find the authorization of type personal (user/password)
 * among all the authorizations of the user.
 */
function getPersonalAuthorization() {
    const allAuths = getAllAuthorizations();
    const personalAuth = allAuths.find(isPersonalAuthorization);
    return personalAuth || null;
}
/**
 * Find the authorization of type SSO corresponding to the provided
 * workspace Id among all the authorizations of the user.
 */
function getSsoAuthorizationForWorkspace(workspaceId) {
    const allAuths = getAllAuthorizations();
    const ssoAuths = allAuths.filter(isSsoAuthorization);
    const workspaceSsoAuth = ssoAuths.find(auth => auth.workspaceId === workspaceId);
    return workspaceSsoAuth || null;
}
/**
 * Find the authorization based on the session id
 */
function getAuthorizationFromSessionId(sessionId) {
    const allAuths = getAllAuthorizations();
    return allAuths.find(auth => {
        const id = getSessionIdFromToken(auth);
        return sessionId === id;
    });
}

export { addOrUpdateAuthorization, getActiveAuthorization, getAllAuthorizations, getAuthorizationFromSessionId, getPersonalAuthorization, getSsoAuthorizationForWorkspace, getUserAuthorization, removeActiveAuthorization, removeAuthorizationByAuthToken, replaceAllAuthorizations, setActiveAuthorizationId, setActiveAuthorizationIdForWorkspace, setAllAuthorizations, setApolloCredentials, setFallbackAuthorizationId, setUserAuthorization };

import { useGetProjectsQuery } from '@sketch/gql-types';

function useGetMyDraftsProject(workspaceId, options) {
    var _a;
    const workspaceResult = useGetProjectsQuery(Object.assign({ variables: { workspaceId } }, options));
    const { data: workspaceData } = workspaceResult;
    const draftsProject = (_a = workspaceData === null || workspaceData === void 0 ? void 0 : workspaceData.workspace.draftsProject) === null || _a === void 0 ? void 0 : _a.entries[0];
    return draftsProject;
}

export { useGetMyDraftsProject };

import { useState, useEffect } from 'react';

const useKonamiCode = (handler) => {
    const [keys, setKeys] = useState([]);
    const isKonamiCode = keys.join(' ') ===
        'ArrowUp ArrowUp ArrowDown ArrowDown ArrowLeft ArrowRight ArrowLeft ArrowRight b a';
    useEffect(() => {
        let timeout;
        const handleKeydown = (e) => {
            setKeys(currentKeys => [...currentKeys, e.key]);
            // Clear timeout since key was just pressed
            clearTimeout(timeout);
            // Reset keys if 2s passes so user can try again
            timeout = setTimeout(() => setKeys([]), 2000);
        };
        document.addEventListener('keydown', handleKeydown);
        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, []);
    // Reset keys when konami code triggers successfully
    useEffect(() => {
        if (isKonamiCode) {
            handler();
            setKeys([]);
        }
    }, [isKonamiCode, handler]);
    return isKonamiCode;
};

export { useKonamiCode };

import * as Sentry from '@sentry/browser'
import { getAssetsVersion } from '@sketch/env-config'
import {
  RouteKeys,
  RouteParams,
  routes,
  VersionedRouteKeys,
  VersionedRouteParam,
  versionedRoutes,
} from '@sketch/modules-common'
import { matchPath } from 'react-router-dom'
import { log } from '@sketch/utils'

import { recordRefresh, onRefreshed } from './localStorage'
import { AugmentedHistory } from 'app-wide-types'

const UPDATE_CHECK_INTERVAL = 29 * 60 * 1000 // 29 minutes

const matchRefreshablePath = (pathname: string) => {
  const match = <T extends RouteKeys>(route: T) => {
    const result = matchPath<RouteParams<T>>(pathname, {
      path: routes[route].template(),
      exact: true,
    })
    if (!result) return result

    return { ...result, kind: route }
  }
  const matchVersioned = <T extends VersionedRouteKeys, U>(
    route: T,
    kind: U
  ) => {
    const result = matchPath<VersionedRouteParam<T>>(pathname, {
      path: versionedRoutes[route].VERSION.template(),
      exact: true,
    })
    if (!result) return result

    return { ...result, kind }
  }

  return (
    match('SHARE_VIEW') ||
    matchVersioned('SHARE_VIEW', 'SHARE_VIEW_VERSION') ||
    match('SHARE_PROTOTYPES') ||
    matchVersioned('SHARE_PROTOTYPES', 'SHARE_PROTOTYPES_VERSION') ||
    match('ARTBOARD_DETAIL') ||
    matchVersioned('ARTBOARD_DETAIL', 'ARTBOARD_DETAIL_VERSION') ||
    match('PROTOTYPE_PLAYER') ||
    matchVersioned('PROTOTYPE_PLAYER', 'PROTOTYPE_PLAYER_VERSION') ||
    match('DOCUMENTS') ||
    match('PERSONAL_PROJECT') ||
    match('WORKSPACE_PROJECT') ||
    match('WORKSPACE_SETTINGS') ||
    match('WORKSPACE_SHARES') ||
    match('ROOT_LEGACY') ||
    match('ENTRY')
  )
}

const shouldForceRefresh = (currentPath: string, nextPath: string): boolean => {
  const currentMatch = matchRefreshablePath(currentPath)
  const nextMatch = matchRefreshablePath(nextPath)
  if (!currentMatch || !nextMatch) return false

  // We have custom logic to update routes between versions, e.g. when
  // new document is uploaded. It is harder to grasp what will happen
  // in each scenario, so simply don't force refresh the app in these
  // scenarios as we want to play safe.
  if (currentMatch.kind === nextMatch.kind + '_VERSION') return false
  if (nextMatch.kind === currentMatch.kind + '_VERSION') return false

  switch (nextMatch.kind) {
    case 'ARTBOARD_DETAIL':
    case 'ARTBOARD_DETAIL_VERSION': {
      if (currentMatch.kind === nextMatch.kind) {
        // if user is navigating between artboard
        // it is generally a good time to refresh the app
        return (
          nextMatch.params.permanentArtboardShortId !==
          currentMatch.params.permanentArtboardShortId
        )
      }
      return nextMatch.kind !== currentMatch.kind
    }
    case 'DOCUMENTS': {
      if (currentMatch.kind === nextMatch.kind) {
        return nextMatch.params.filter !== currentMatch.params.filter
      }
      return false
    }
    case 'PERSONAL_PROJECT':
    case 'WORKSPACE_PROJECT': {
      if (currentMatch.kind === nextMatch.kind) {
        return nextMatch.params.projectId !== currentMatch.params.projectId
      }
      return nextMatch.kind !== currentMatch.kind
    }
    default:
      // in all other cases refresh the app if user navigated from
      // one page view to another
      return nextMatch.kind !== currentMatch.kind
  }
}

/**
 * It might happen that setAppToBeRefreshed would be called multiple times
 * e.g. when user stayed away for twice or more times than UPDATE_CHECK_INTERVAL.
 * However, in all cases we need only a single setAppToBeRefreshed working
 */
let isSetAppToBeRefreshed = false

const setAppToBeRefreshed = (history: AugmentedHistory) => {
  if (isSetAppToBeRefreshed) return
  isSetAppToBeRefreshed = true

  // we are interested only in the `push` events as the `replace` events already
  // indicates that the event shouldn't be considered as a new page.
  history.onPush(({ pathname }) => {
    if (!pathname) return

    const refresh = shouldForceRefresh(history.location.pathname, pathname)

    if (refresh) {
      // here we are doing the same as history package internally:
      // https://github.com/jamsinclair/history/blob/3f69f9e07b0a739419704cffc3b3563133281548/modules/createBrowserHistory.js#L181-L182
      window.location.href = pathname

      recordRefresh()
    }
  })
}

/**
 * It might happen that enableUpdates would be called multiple times
 * e.g. when hot reloads happen. However, in all cases we need only a single
 * updates listener enabled.
 */
let isUpdatesEnabled = false

interface EnableUpdatesProps {
  history: AugmentedHistory
  onForceRefreshed: () => void
}

export const enableUpdates = async ({
  onForceRefreshed,
  history,
}: EnableUpdatesProps) => {
  if (isUpdatesEnabled) return

  isUpdatesEnabled = true

  let updateTimer = 0

  onRefreshed(onForceRefreshed)

  try {
    const initialAssetsVersion = await getAssetsVersion()
    if (initialAssetsVersion) {
      log(`Version hash of current assets:${initialAssetsVersion}:`)
      // Clear previous update intervals (if any)
      if (updateTimer) {
        clearInterval(updateTimer)
      }

      // Set a regular interval for checking updates
      updateTimer = window.setInterval(async () => {
        try {
          const assetsVersion = await getAssetsVersion()
          if (assetsVersion && assetsVersion !== initialAssetsVersion) {
            log(
              `There is an update. Setting app to refresh after navigation from the document view.`
            )
            updateTimer && clearInterval(updateTimer)
            setAppToBeRefreshed(history)
          }
        } catch (error) {
          Sentry.captureException(error)
        }
      }, UPDATE_CHECK_INTERVAL)
    } else {
      log('Version hash of current assets not found.')
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}

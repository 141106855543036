import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { stringify } from 'query-string'

import { useQueryParams } from '@sketch/modules-common'
import { PublicationListingFragment } from '@sketch/gql-types'

import { PERIOD_ORDER, SORT_ORDER, DOCUMENT_TYPE_ORDER } from '../../constants'

type Modifiers = Pick<
  PublicationListingFragment['preferences'],
  'sort' | 'period' | 'documentType'
>

type OptionalModifiers = {
  [K in keyof Modifiers]?: Modifiers[K] | undefined
}

type SetModifiers = {
  [K in keyof Modifiers]?: NonNullable<Modifiers[K]>
}

const DEFAULT_MODIFIERS = {
  sort: 'UPDATED_AT_DESC',
  period: 'FOREVER',
  documentType: 'ALL_DOCUMENT_TYPES',
} as { [K in keyof Modifiers]: NonNullable<Modifiers[K]> }

interface URLQueryModifiers {
  sort?: typeof SORT_ORDER[number] | null | undefined
  period?: typeof PERIOD_ORDER[number] | null | undefined
  'document-type'?: typeof DOCUMENT_TYPE_ORDER[number] | null | undefined
}

export const useCommunityModifiers = (baseModifiers?: OptionalModifiers) => {
  const history = useHistory()
  const queryParams = useQueryParams<
    'COMMUNITY_CANVAS' | 'COMMUNITY_CANVAS_LISTING'
  >()

  /**
   * Make sure there is default value for both [sort, period]
   * either the default from the listing or the default from the query
   */
  const defaultPeriod = baseModifiers?.period ?? DEFAULT_MODIFIERS.period
  const defaultSort = baseModifiers?.sort ?? DEFAULT_MODIFIERS.sort
  const defaultDocumentType =
    baseModifiers?.documentType ?? DEFAULT_MODIFIERS.documentType

  /**
   * Validate if the a query [sort, period] exists on the queryParams
   * and if they are valid values
   */
  const queryPeriod = (queryParams.period &&
  (PERIOD_ORDER as string[]).includes(queryParams.period)
    ? queryParams.period
    : null) as URLQueryModifiers['period']

  const querySort = (queryParams.sort &&
  ([...SORT_ORDER] as string[]).includes(queryParams.sort)
    ? queryParams.sort
    : null) as URLQueryModifiers['sort']

  const queryDocumentType = (queryParams['document-type'] &&
  ([...DOCUMENT_TYPE_ORDER] as string[]).includes(queryParams['document-type'])
    ? queryParams['document-type']
    : null) as URLQueryModifiers['document-type']

  const period = queryPeriod ?? defaultPeriod
  const sort = querySort ?? defaultSort
  const documentType = queryDocumentType ?? defaultDocumentType
  const search = queryParams.search

  const modifiers = useMemo(() => ({ period, sort, documentType }), [
    period,
    sort,
    documentType,
  ])

  const setModifiers = useCallback(
    (newModifiers: SetModifiers) => {
      /**
       * Update the new values maintaining the
       * existing ones
       */
      const queryParameters: URLQueryModifiers = {
        sort: newModifiers.sort ?? modifiers.sort,
        period: newModifiers.period ?? modifiers.period,
        'document-type': newModifiers.documentType ?? modifiers.documentType,
      }

      /**
       * Remove the default values from the URL
       * so its always clean and aligned with the
       * default values
       */
      if (queryParameters.period === defaultPeriod) {
        delete queryParameters.period
      }

      if (queryParameters.sort === defaultSort) {
        delete queryParameters.sort
      }

      if (queryParameters['document-type'] === defaultDocumentType) {
        delete queryParameters['document-type']
      }

      history.push({
        search: stringify({ ...queryParameters, search }),
      })
    },
    [
      defaultPeriod,
      defaultSort,
      defaultDocumentType,
      history,
      search,
      modifiers,
    ]
  )

  return [modifiers, setModifiers] as const
}

import React from 'react'

import CommunityWrapper from '../../components/CommunityWrapper/'
import { CommunityListingHeaderPlaceholder } from '../../components/CommunityListingHeader'
import { PublicationGridPlaceholder } from '../../components/PublicationGrid'

const CommunityListingPagePlaceholder = () => (
  <CommunityWrapper>
    <CommunityListingHeaderPlaceholder />
    <PublicationGridPlaceholder />
  </CommunityWrapper>
)

export default CommunityListingPagePlaceholder

import React from 'react'

import {
  DocumentHead,
  DocumentHeaderOld,
  ErrorMessage,
  handleFetchMore,
  useForTablet,
  useModalContext,
} from '@sketch/components'
import { dataIdFromObject } from '@sketch/graphql-cache'

import {
  WorkspaceMinimalFragment,
  useGetProjectsListQuery,
  useGetWorkspaceUploadsRestrictedForQuery,
} from '@sketch/gql-types'

import LoadingState from '../LoadingState'
import ShareEmptyState from '../ShareEmptyState'
import { useSearch } from '../../hooks/useSearch'

import ProjectItemsLayout from '../ProjectItemsLayout'
import ProjectDropdown from 'modules/projects/components/ProjectDropdown'
import { RenameProject } from 'modules/projects'
import { useOnEvent } from '@sketch/utils'

const ENTRIES_PATH = ['workspace', 'archived', 'entries']

interface ArchiveListProps {
  workspaceId: string
  workspace: WorkspaceMinimalFragment
  userRole: WorkspaceMinimalFragment['userRole']
}

export function ArchiveList({
  workspaceId,
  workspace,
  userRole,
}: ArchiveListProps) {
  const { searchDebounced: search } = useSearch()
  const isTabletAndBigger = useForTablet()

  const { showModal } = useModalContext()

  const { loading, error, data, fetchMore, refetch } = useGetProjectsListQuery({
    variables: {
      workspaceId,
      archived: 'ARCHIVED',
      searchTerm: search,
    },
  })

  useOnEvent('workspaceShareRefresh', ({ workspaceIdentifier }) => {
    if (workspaceIdentifier !== workspaceId) {
      // Not the workspace visible ignore then
      return
    }

    refetch()
  })

  const uploadsRestrictedForResult = useGetWorkspaceUploadsRestrictedForQuery({
    variables: { identifier: workspaceId },
    shouldInvalidatePrevious: (prev, curr) =>
      prev?.identifier !== curr?.identifier,
  })

  if (loading) {
    return <LoadingState />
  }

  if (!data || error) {
    return <ErrorMessage.Generic />
  }

  const totalCount = data?.workspace.projects.meta.totalCount || 0
  const documentHeader = isTabletAndBigger && (
    <>
      <DocumentHead title="Archive" />
      <DocumentHeaderOld
        title="Archived Projects"
        description="Use the Archive to store projects that you no longer need to access frequently."
        learnMoreTooltipTitle="Learn more about archive"
        learnMoreTooltipURL="https://www.sketch.com/redirect/web/archive-projects"
      />
    </>
  )

  if (!loading && totalCount === 0) {
    const uploadsRestrictedFor =
      uploadsRestrictedForResult.data?.workspace?.uploadsRestrictedFor ??
      // we should never need to default back to 'NONE', as we just checked
      // that uploadsRestrictedForResult is not in a loading state anymore
      // So there should be a valid data, but just in case, default it back
      // to 'NONE' either way.
      'NONE'

    return (
      <>
        {documentHeader}

        <ShareEmptyState
          search={search}
          workspace={workspace}
          workspaceHasDocuments={!!totalCount}
          uploadsRestrictedFor={uploadsRestrictedFor}
          filter="ARCHIVE_PROJECTS"
        />
      </>
    )
  }

  const { after } = data.workspace.projects.meta
  const loadMoreHandler = handleFetchMore(fetchMore, ENTRIES_PATH, {
    dataIdFromObject,
    after,
  })

  const items = data.workspace.projects.entries

  return (
    <>
      {documentHeader}

      <ProjectItemsLayout
        projects={items}
        onLoadMore={loadMoreHandler}
        totalCount={totalCount}
        renderDropdown={project => (
          <ProjectDropdown
            workspace={workspace}
            project={project}
            onRename={() => {
              showModal(RenameProject, {
                workspaceId,
                projectId: project.identifier,
                projectName: project.name,
              })
            }}
          />
        )}
        workspaceId={workspaceId}
      />
    </>
  )
}

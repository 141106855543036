/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  PRMarinaNodeArtboard,
  PRMarinaLayer,
  PRMarinaLayerStyle,
  PRMarinaNode,
  PRMarinaRect,
} from '@sketch-hq/sketch-web-renderer'
import {
  ArtboardPreset,
  Color,
  GridSettings,
  LayerStyle,
  ElementType,
  LayoutSettings,
  SketchArtboardElement,
  SketchElement,
  SketchElementBase,
  SketchElementType,
  SketchLayerElement,
  LayerStyleProperties,
  GroupStyleProperties,
  SketchSymbolMasterArtboardElement,
  SketchSymbolInstanceLayerElement,
  SketchLayerElementBase,
  TextLayerStyleProperties,
  WithPotentialSharedStyle,
  WithSharedStyle,
} from 'modules/inspector'
import {
  transformAttributedString,
  transformCorners,
  transformExportFormatsComponent,
  transformPresentationAppearance,
  transformPresentationBlur,
  transformPresentationBorderOptions,
  transformPresentationBorders,
  transformPresentationFileColor,
  transformPresentationFills,
  transformPresentationShadows,
  transformSharedStyleReference,
  transformSymbolMasterReference,
  transformTextAttributes,
} from './presentationFileTransformUtils'
import {
  mapLayerType,
  mapVerticalTextAlignment,
} from './presentationFileTransformUtils/presentationMarinaEnumsMapping'
import { computeSymbolMasterNames } from './utils'

/** Props needed to create the element node */
type CreateSketchElementContext = {
  parentSymbolLayerElement?: SketchSymbolInstanceLayerElement
  artboardAncestorElement?: SketchElement
  /**
   * The bounds of the container node that can be Page is we are looking at the page view
   * or artboard if we are looking at an individual artboard.
   */
  containerNodeBounds: PRMarinaRect
  isMaskedByOtherLayer: boolean
  isInsideLockedParent: boolean
  isInsideGroupWithExports: boolean
  isInsideGroupWithStyles: boolean
}

export function createSketchElement(
  marinaNode: PRMarinaNode,
  context: CreateSketchElementContext
): SketchElement {
  if (marinaNode.isPage()) {
    return {
      ...createSketchLayerElement(marinaNode, context),
      type: ElementType.Page,
    }
  }

  if (marinaNode.isArtboard()) {
    return createSketchArtboardElement(marinaNode, context.containerNodeBounds)
  }

  return createSketchLayerElement(marinaNode, context)
}

export function getSketchElementBaseProperties(
  marinaNode: PRMarinaNode,
  context: Pick<
    CreateSketchElementContext,
    | 'containerNodeBounds'
    | 'parentSymbolLayerElement'
    | 'artboardAncestorElement'
  >
): SketchElementBase {
  const {
    containerNodeBounds,
    parentSymbolLayerElement,
    artboardAncestorElement,
  } = context
  const prRectBounds = marinaNode.getAbsoluteLayerBounds()
  const prMarinaLayer = marinaNode.getLayer()
  const layerSize = prMarinaLayer?.bounds
  const elementUUID = marinaNode.getLayerUUID()

  const exportsProps = getExportsProps(
    marinaNode,
    Boolean(parentSymbolLayerElement)
  )

  return {
    prMarinaNode: marinaNode,
    identifier: marinaNode.getIdentifier(),
    name: marinaNode.getLayerName(),
    elementUUID: elementUUID,
    children: [],
    // We want the bounds to be relative to the artboard and not the canvas if the root of the tree is just an artboard.
    rootRelativeBoxRect: {
      x: prRectBounds.x - containerNodeBounds.x,
      y: prRectBounds.y - containerNodeBounds.y,
      width: prRectBounds.width,
      height: prRectBounds.height,
    },
    layerSize: {
      width: layerSize?.width ?? 0,
      height: layerSize?.height ?? 0,
    },
    artboardAncestorElement,
    ...exportsProps,
  }
}

function getExportsProps(
  marinaNode: PRMarinaNode,
  isInsideSymbolInstance: boolean
): Pick<SketchElementBase, 'isExportable' | 'exportFormats' | 'exportId'> {
  // Exports is not supported inside symbol instances. This could potentially
  // be supported in the future but in the current state, the data is present
  // in the presentation file but we want to ignore it. The main reasons are:
  // - This is the legacy behavior. We don't want to change it for now.
  // - We would need to adapt the code to be able to fetch assets from a different document
  // when the symbol is external
  if (isInsideSymbolInstance) {
    return {
      exportFormats: [],
      isExportable: false,
      exportId: undefined,
    }
  }

  const prExportFormats = marinaNode.getExportFormats()
  const nodeUUID = marinaNode.getLayerUUID()

  const exportFormats = prExportFormats
    ? transformExportFormatsComponent(prExportFormats)
    : []
  const isExportable = exportFormats.length > 0
  const exportId = nodeUUID

  return {
    exportFormats,
    isExportable,
    exportId,
  }
}

/** ARTBOARD */

export function createSketchArtboardElement(
  marinaNode: PRMarinaNode,
  /**
   * The bounds of the root artboard.
   */
  containerNodeBounds: PRMarinaRect
): SketchArtboardElement {
  const prMarinaArtboard = marinaNode.getArtboard()
  const prMarinaLayer = marinaNode.getLayer()

  if (!prMarinaArtboard) {
    throw new Error('Artboard component must be present')
  }

  if (!prMarinaLayer) {
    throw new Error('LayerInfo component must be present')
  }

  const type = mapLayerType(prMarinaLayer.type.value)

  if (type !== ElementType.Artboard && type !== ElementType.SymbolMaster) {
    throw new Error('The layer type must be Artboard or SymbolMaster')
  }

  const artboardElement = {
    ...getSketchElementBaseProperties(marinaNode, {
      containerNodeBounds: containerNodeBounds,
    }),
    identifier: marinaNode.getIdentifier(),
    gridSettings: getGridSettings(prMarinaArtboard),
    layoutSettings: getLayoutSettings(prMarinaArtboard),
    preset: getArtboardPreset(prMarinaArtboard),
    backgroundColor: getArtboardBackgroundColor(prMarinaLayer),
    hasFragmentPending: marinaNode.hasFragmentPending(),
  }

  if (type === ElementType.Artboard) {
    return { ...artboardElement, type: ElementType.Artboard }
  }

  const symbolMasterElement: SketchSymbolMasterArtboardElement = {
    ...artboardElement,
    type: ElementType.SymbolMaster,
    ...computeSymbolMasterNames(artboardElement.name),
  }
  return symbolMasterElement
}

function getGridSettings(prMarinaArtboard: PRMarinaNodeArtboard): GridSettings {
  const defaultGridSettings: GridSettings = {
    isEnabled: false,
    size: 20,
    thickLineStep: 10,
  }
  const prArtboardGrid = prMarinaArtboard.getGrid()

  const gridSettings: GridSettings | undefined = prArtboardGrid
    ? {
        isEnabled: prArtboardGrid.isEnabled,
        size: prArtboardGrid.size,
        thickLineStep: prArtboardGrid.thickGridTimes,
      }
    : undefined

  return {
    ...defaultGridSettings,
    ...gridSettings,
  }
}

function getLayoutSettings(
  prMarinaArtboard: PRMarinaNodeArtboard
): LayoutSettings {
  const prArtboardLayout = prMarinaArtboard.getLayout()
  const defaultLayoutSettings: LayoutSettings = {
    columnWidth: 60,
    drawHorizontal: false,
    drawHorizontalLines: false,
    drawVertical: true,
    gutterHeight: 12,
    gutterWidth: 20,
    guttersOutside: true,
    horizontalOffset: 0,
    isEnabled: false,
    numberOfColumns: 5,
    numberOfRows: 0,
    rowHeightMultiplication: 5,
    totalWidth: 0,
  }

  const layoutSettings: LayoutSettings | undefined = prArtboardLayout
    ? {
        columnWidth: prArtboardLayout.columnWidth,
        drawHorizontal: prArtboardLayout.drawHorizontal,
        drawHorizontalLines: prArtboardLayout.drawHorizontalLines,
        drawVertical: prArtboardLayout.drawVertical,
        gutterHeight: prArtboardLayout.gutterHeight,
        gutterWidth: prArtboardLayout.gutterWidth,
        guttersOutside: prArtboardLayout.gutterOutside,
        horizontalOffset: prArtboardLayout.horizontalOffset,
        isEnabled: prArtboardLayout.isEnabled,
        numberOfColumns: prArtboardLayout.columnsCount,
        numberOfRows: 0,
        rowHeightMultiplication: prArtboardLayout.rowHeightMultiplication,
        totalWidth: prArtboardLayout.totalWidth,
      }
    : undefined

  return {
    ...defaultLayoutSettings,
    ...layoutSettings,
  }
}

function getArtboardPreset(
  prMarinaArtboard: PRMarinaNodeArtboard
): ArtboardPreset {
  const presetBounds = prMarinaArtboard.templateBounds

  return {
    name: prMarinaArtboard.templateName,
    width: presetBounds.width,
    height: presetBounds.height,
  }
}

function getArtboardBackgroundColor(
  prMarinaLayer: PRMarinaLayer
): Color | undefined {
  const artboardOverridableStyle = prMarinaLayer.getStyle()
  const layerStyle = artboardOverridableStyle?.getLayerStyle()
  const artboardFill = layerStyle?.fills.get(0)
  const prMarinaColor = artboardFill?.getColor()

  if (!prMarinaColor) {
    return undefined
  }

  return transformPresentationFileColor(prMarinaColor)
}

/** LAYER */

export function createSketchLayerElement(
  marinaNode: PRMarinaNode,
  context: CreateSketchElementContext
): SketchLayerElement {
  const {
    isMaskedByOtherLayer,
    containerNodeBounds,
    parentSymbolLayerElement,
    artboardAncestorElement,
  } = context
  const prMarinaLayer = marinaNode.getLayer()

  if (!prMarinaLayer) {
    throw Error('LayerInfo component must be present')
  }

  const type = mapLayerType(prMarinaLayer.type.value)

  if (
    type === ElementType.Artboard ||
    type === ElementType.SymbolMaster ||
    type === ElementType.Page ||
    type === ElementType.Undefined
  ) {
    throw Error(
      `Illegal layer type "${type}" encountered while creating SketchLayerElement. (Layer name: ${marinaNode.getName()}, uuid: ${marinaNode.getLayerUUID()}, Artboard ancestor: ${
        artboardAncestorElement?.name
      })`
    )
  }

  const style = getLayerStyle(prMarinaLayer, type)

  const prCorners = prMarinaLayer.getCorners()
  const corners = prCorners ? transformCorners(prCorners) : undefined

  const prAttributedString = prMarinaLayer.getAttributedString()
  const text = prAttributedString
    ? transformAttributedString(prAttributedString)
    : undefined

  const isLocked = prMarinaLayer.locked || context.isInsideLockedParent
  // Omit type because we don't know yet if it's a symbol instance or not yet.
  const layerElement: SketchLayerElementBase = {
    ...getSketchElementBaseProperties(marinaNode, {
      containerNodeBounds,
      parentSymbolLayerElement: parentSymbolLayerElement,
      artboardAncestorElement,
    }),
    isLayerMasked: isMaskedByOtherLayer,
    parentSymbolLayerElement: parentSymbolLayerElement,
    corners,
    rotation: prMarinaLayer.rotation,
    appearance: style?.appearance,
    text,
    isLocked,
    isInsideGroupWithExports: context.isInsideGroupWithExports,
    isInsideGroupWithStyles: context.isInsideGroupWithStyles,
  }

  if (type === ElementType.Hotspot || type === ElementType.Slice) {
    return { ...layerElement, type, style: undefined }
  }

  if (type === ElementType.Group) {
    return {
      ...layerElement,
      type,
      style: style as GroupStyleProperties,
    }
  }

  if (type === ElementType.Text) {
    return {
      ...layerElement,
      type,
      style: style as WithPotentialSharedStyle<TextLayerStyleProperties>,
    }
  }

  if (type === ElementType.SymbolInstance) {
    const prSymbolMasterReference = marinaNode.getSymbolMasterReference()

    if (!prSymbolMasterReference) {
      throw Error(
        'SymbolInstance element encountered without a SymbolMasterReference'
      )
    }

    return {
      ...layerElement,
      type,
      style: style as GroupStyleProperties,
      symbolMaster: transformSymbolMasterReference(prSymbolMasterReference),
    }
  }

  return {
    ...layerElement,
    type,
    style: style as WithPotentialSharedStyle<LayerStyleProperties>,
  }
}

/* UTILS */

function getLayerStyle(
  prMarinaLayer: PRMarinaLayer,
  type: SketchElementType
): LayerStyle | undefined {
  const prStyle = prMarinaLayer.getStyle()
  const prLayerStyle = prStyle?.getLayerStyle()

  if (!prStyle || !prLayerStyle) {
    return
  }

  const prOriginalSharedStyleValues = prStyle.getOriginalSharedStyleValues()
  const prSharedStyleReference = prStyle.getSharedStyleReference()
  const isDirty = prStyle.isDirty

  const basicStyleProps = getStyleProperties(prLayerStyle)

  const originalSharedStyleValues = prOriginalSharedStyleValues
    ? getStyleProperties(prOriginalSharedStyleValues)
    : undefined

  const sharedStyleReference = prSharedStyleReference
    ? transformSharedStyleReference(prSharedStyleReference)
    : undefined

  if (type === ElementType.Group) {
    const groupStyle: GroupStyleProperties = {
      isDirty,
      fills: basicStyleProps.fills ?? [],
      shadows: basicStyleProps.shadows ?? [],
      appearance: basicStyleProps.appearance,
    }

    return groupStyle
  }

  if (sharedStyleReference) {
    const styleWithSharedStyle: WithSharedStyle<LayerStyleProperties> = {
      ...basicStyleProps,
      isDirty,
      originalSharedStyleValues,
      sharedStyleReference,
    }
    return styleWithSharedStyle
  }

  return basicStyleProps
}

export function getStyleProperties(prMarinaLayerStyle: PRMarinaLayerStyle) {
  const prBlur = prMarinaLayerStyle.getBlur()
  const prFills = prMarinaLayerStyle.fills
  const prAppearance = prMarinaLayerStyle.appearance
  const prBorders = prMarinaLayerStyle.borders
  const prShadows = prMarinaLayerStyle.shadows
  const prBorderOptions = prMarinaLayerStyle.borderOptions
  const prInnerShadows = prMarinaLayerStyle.innerShadows

  const blur = prBlur ? transformPresentationBlur(prBlur) : undefined
  const fills = prFills ? transformPresentationFills(prFills) : []
  const shadows = prShadows ? transformPresentationShadows(prShadows) : []
  const appearance = prAppearance
    ? transformPresentationAppearance(prAppearance)
    : undefined

  const prVerticalTextAlignment = prMarinaLayerStyle.verticalTextAlignment
  const prText = prMarinaLayerStyle.getTextAttributes()

  return {
    fills,
    blur,
    appearance,
    borders: prBorders ? transformPresentationBorders(prBorders) : [],
    borderOptions: prBorderOptions
      ? transformPresentationBorderOptions(prBorderOptions)
      : undefined,
    shadows,
    innerShadows: prInnerShadows
      ? transformPresentationShadows(prInnerShadows)
      : [],
    verticalAlignment: mapVerticalTextAlignment(prVerticalTextAlignment.value),
    text: prText ? transformTextAttributes(prText)?.attributes : undefined,
  } as const
}

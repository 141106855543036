import styled, { css } from 'styled-components';
import { style, space, system, width, height, border, maxWidth, minWidth, maxHeight, minHeight, color, display, flex, flexWrap, justifyContent, alignItems, position, top, bottom, borderRadius, right, left, zIndex, textAlign, fontSize, fontWeight, verticalAlign, fontStyle, order } from 'styled-system';
import { getDisplayName } from '@sketch/utils';

var _a;
const overflowX = style({
    prop: 'overflowX',
    cssProperty: 'overflowX',
});
const spaceConfig = ((_a = space.config) !== null && _a !== void 0 ? _a : {});
const unsafeSpaceConfig = Object.entries(spaceConfig).reduce((config, [prop, propConfig]) => (Object.assign(Object.assign({}, config), { [`UNSAFE_${prop}`]: propConfig })), {});
const unsafeSpace = system(unsafeSpaceConfig);
// // The following props were removed intentionally
// // read https://github.com/sketch-hq/Cloud/issues/2329
// // for more details
// // ------------------------------------------------
// // use withFlex to get the following properties:
// DisplayProps,
// FlexProps,
// FlexWrapProps,
// JustifyContentProps,
// AlignItemsProps,
// // ------------------------------------------------
// // use withAbsolute to get the following properties:
// PositionProps,
// TopProps,
// BottomProps,
// RightProps,
// LeftProps,
// ZIndexProps,
// // ------------------------------------------------
// // use withText to get the following properties:
// FontWeightProps,
// TextAlignProps,
// VerticalAlignProps,
// FontSizeProps,
// FontStyleProps,
// // ------------------------------------------------
// // The following properties weren't used as much
// // so it isn't added to Box component.
// // use styled-component to change these properties
// OrderProps
const boxCss = css `
  ${overflowX}
  ${unsafeSpace}
  ${space}
  ${width}
  ${height}
  ${border}
  ${maxWidth}
  ${minWidth}
  ${maxHeight}
  ${minHeight}
  ${color}
`;
/*
 * Deprecated - the following props will be removed in the future!!
 * use one of (or combination of) withFlex, withAbsolute, withText
 *
 * These properties were left here just for backwards compatibility
 * and they should be accessed only from components written in JavaScript
 * (not TypeScript) files.
 */
const boxLegacyCss = css `
  ${display}
  ${flex}
  ${flexWrap}
  ${justifyContent}
  ${alignItems}

  ${position}
  ${top}
  ${bottom}
  ${borderRadius}
  ${right}
  ${left}
  ${zIndex}

  ${textAlign}
  ${fontSize}
  ${fontWeight}
  ${verticalAlign}
  ${fontStyle}

  ${order}
`;
function withBox(Comp) {
    const styledComp = styled(Comp) `
    ${boxCss}
    ${boxLegacyCss}
  `;
    styledComp.displayName = `withBox(${getDisplayName(Comp)})`;
    return styledComp;
}

export { withBox };

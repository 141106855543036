import styled, { css } from 'styled-components'

import { TruncateWithTooltip, Link, Tooltip } from '@sketch/components'
import { Image as ImageUnstyled } from '@sketch/modules-common'
import { VersionLink } from 'modules/versioning'
import { ReactComponent as ChevronRight } from '@sketch/icons/chevron-right-16'
import { ReactComponent as ExclamationMark } from '@sketch/icons/exclamation-mark-alt-16'

import { Header } from '../../components'

const componentStyles = css(
  ({ theme }) => css`
    flex: auto;
    min-width: 0;

    display: flex;
    align-items: center;

    padding: 8px;
    margin: 12px 20px 0;

    border: 1px solid ${theme.colors.border.A};
    border-radius: ${theme.radii.medium};
    color: ${theme.colors.foreground.secondary.A};
    font-weight: 400;

    &[data-testid='inspector-component-no-link'] {
      margin-left: 0;
    }

    ${Header} > &[data-testid="inspector-component-no-link"] {
      margin-left: 20px;
    }
  `
)

export const Container = styled.div`
  ${componentStyles}
`

export const ContainerWithoutLink = styled.div`
  ${componentStyles};
  margin-right: 0;
`

export const LinkStyled = styled(Link)`
  ${componentStyles}
`

export const VersionLinkStyled = styled(VersionLink)`
  ${componentStyles}
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 0;
  line-height: 1;
`

export const Chevron = styled(ChevronRight)`
  flex: none;
  height: 16px;
  margin-left: auto;
  padding-left: 8px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const Preview = styled(ImageUnstyled)<{
  needsContrastingBackground: boolean
  isTextStyle: boolean
}>(
  ({ needsContrastingBackground, isTextStyle, theme }) => css`
    position: relative;
    flex: none;

    width: 24px;
    height: 24px;

    margin-right: 8px;
    border-radius: 5px;
    background-color: ${needsContrastingBackground && isTextStyle
      ? theme.colors.background.tertiary.A
      : theme.colors.background.tertiary.C};

    &:after {
      content: '';
      display: ${isTextStyle ? 'block' : 'none'};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      box-shadow: inset 0 0 0 1px ${theme.colors.border.A};
      border-radius: 5px;
    }

    img {
      object-fit: cover;
      object-position: left;
    }
  `
)

export const Name = styled(TruncateWithTooltip)<{ isDirty?: boolean }>(
  ({ theme, isDirty }) => css`
    color: ${theme.colors.foreground.secondary.A};
    font-size: ${theme.fontSizes.D};
    font-style: ${isDirty ? 'italic' : 'normal'};
  `
)

export const Path = styled(TruncateWithTooltip)`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  font-size: ${({ theme }) => theme.fontSizes.B};
  margin-top: 2px; /* stylelint-disable-line scales/space */
`

export const DirtyLayerWarningTooltipHeader = styled.span`
  color: ${({ theme }) => theme.colors.foreground.primary.A};
`

export const DirtyLayerWarningTooltipContent = styled.span`
  color: ${({ theme }) => theme.colors.foreground.primary.B};
`

export const ExclamationMarkIcon = styled(ExclamationMark)`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

// Make the container element of the tooltip content flex so that the
// container uses the same height as its content (the warning icon).
export const StyledDirtyLayerTooltip = styled(Tooltip)`
  display: flex;
`

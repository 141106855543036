import styled from 'styled-components';

const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
const Text = styled.p `
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.E};
  text-align: center;

  :not(:last-child) {
    margin-top: 32px;
  }
`;
const Line = styled.div `
  width: 100%;
  margin-top: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`;

export { Line, Text, Wrapper };

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Modal, Separator, Form, PasswordInput, Button } from '@sketch/components';
import { useUserDeleteMutation } from '@sketch/gql-types';
import { useSignOut } from '@sketch/modules-common';

const DELETE_ACCOUNT_SCHEMA = yup.object().shape({
    password: yup.string().required('Password can’t be blank'),
});
const DeleteAccountModal = ({ hideModal }) => {
    const signOut = useSignOut({
        revokeSessions: 'none',
        reason: 'Deleted account',
    });
    const [deleteUser] = useUserDeleteMutation({
        redirectErrors: false,
        onError: 'unsafe-throw-exception',
    });
    const onSubmit = (values, actions) => {
        deleteUser({ variables: { password: values.password } })
            .then(({ data }) => {
            if (!data || !data.userDelete)
                return;
            actions.setSubmitting(false);
            if (data.userDelete.errors && data.userDelete.errors.length > 0) {
                actions.setStatus(data.userDelete.errors.map(e => e === null || e === void 0 ? void 0 : e.message).join());
            }
            if (data.userDelete.successful) {
                if (hideModal && typeof hideModal === 'function') {
                    hideModal();
                }
                signOut();
            }
        })
            .finally(() => {
            actions.setSubmitting(false);
        });
    };
    return (jsx(Modal, Object.assign({ title: "Delete Your Account and All Documents?", onCancel: hideModal }, { children: jsx(Formik, Object.assign({ initialValues: { password: '' }, validationSchema: DELETE_ACCOUNT_SCHEMA, onReset: hideModal, onSubmit: onSubmit }, { children: ({ isSubmitting, handleReset, values, errors, touched, handleChange, handleBlur, status, }) => (jsxs(Fragment, { children: [jsxs(Modal.Body, { children: [jsx("p", { children: "Deleting your account will also delete all documents you may have shared. You can\u2018t undo this action." }), jsx(Separator, { mt: 5, mb: 5 }), jsx(Form, Object.assign({ id: "delete-account-form" }, { children: jsx(Form.Field, Object.assign({ name: "password", label: "Enter Your Password to Confirm", errorText: touched.password ? errors.password : undefined }, { children: jsx(PasswordInput, { name: "password", disabled: isSubmitting, value: values.password, onChange: handleChange, onBlur: handleBlur, "data-testid": "password-input" }) })) })), status && (jsx(Form.Error, Object.assign({ style: { display: 'block', margin: '20px 0 0 0' }, "data-testid": "password-error" }, { children: status })))] }), jsxs(Modal.Footer, { children: [jsx(Button, Object.assign({ type: "reset", disabled: isSubmitting, onClick: handleReset, variant: "secondary" }, { children: "Cancel" })), jsx(Button, Object.assign({ type: "submit", variant: "negative-secondary", loading: isSubmitting, disabled: isSubmitting, form: "delete-account-form", "data-testid": "submit-button" }, { children: "Delete" }))] })] })) })) })));
};

export { DeleteAccountModal as default };

import { Gradient } from 'modules/shares/types'
import rgbTo, {
  ColorFormat,
} from 'modules/shares/Inspector/Sidebar/components/Color/utils'
import { capitalize } from '@sketch/utils'
import { gradientAngle } from './math'

const gradientStopsCopy: (
  gradient: Gradient,
  colorFormat?: ColorFormat
) => string = (gradient, colorFormat) => {
  const stops = gradient.stops
    .sort((lhs, rhs) => lhs.position - rhs.position)
    .map((stop, i) => {
      const opacity = stop?.color?.alpha !== undefined ? stop?.color?.alpha : 1

      return (
        `\nColor Stop ${++i}` +
        `\nColor: ${rgbTo(colorFormat, stop.color)}` +
        (opacity !== 1 ? `\nOpacity: ${(opacity * 100).toFixed()}%` : ``) +
        `\nPosition: ${Math.round(stop.position * 100)}%\n`
      )
    })
  return stops.join('')
}

const copy = (
  gradient: Gradient,
  opacity: number = 1,
  colorFormat?: ColorFormat
) => {
  let gradientCopyValue = `Type: ${capitalize(
    gradient.type
  )}\nAngle: ${gradientAngle(gradient)}˚`

  gradientCopyValue += `\nOpacity: ${(opacity * 100).toFixed()}%`

  gradientCopyValue += `\n`
  gradientCopyValue += gradientStopsCopy(gradient, colorFormat)

  return gradientCopyValue
}

export default copy

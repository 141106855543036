import styled from 'styled-components';
import { IndexLayoutOldSubtitle } from '@sketch/modules-common';
import { Text } from '@sketch/components';

const FormContainer = styled.div `
  margin: 32px 0 40px;
`;
const LinkDescription = styled(Text).attrs({
    textStyle: 'copy.tertiary.standard.C',
}) ``;
const Subtitle = styled(IndexLayoutOldSubtitle) `
  max-width: 400px;
  margin: 8px auto 0;
`;
const Wrapper = styled.div `
  height: 100%;
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export { FormContainer, LinkDescription, Subtitle, Wrapper };

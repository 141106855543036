import React from 'react'
import { ReactComponent as CommentExclamationMarkIcon } from '@sketch/icons/comment-exclamation-64'
import { ErrorMessageProps } from '@sketch/components'
import InfoMessage from '../InfoMessage'

const CommentTabMessageError: React.FC<Pick<ErrorMessageProps, 'title'>> = ({
  title,
  children,
}) => (
  <InfoMessage title={title} icon={CommentExclamationMarkIcon}>
    {children}
  </InfoMessage>
)

export default CommentTabMessageError

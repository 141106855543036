import React from 'react'
import styled from 'styled-components'

import { Flex } from '@sketch/components'
import FullCopyAttribute from '../FullCopyAttribute'
import { getDirtyAttributes } from '../Style/DirtyIconTooltip'

import { BorderOptions } from 'modules/shares/types'

import { ReactComponent as JoinRoundSvg } from '@sketch/icons/border-join-round-16'
import { ReactComponent as JoinBevelSvg } from '@sketch/icons/border-join-bevel-16'
import { ReactComponent as JoinMitterSvg } from '@sketch/icons/border-join-miter-16'
import { ReactComponent as CapRoundSvg } from '@sketch/icons/border-cap-round-16'
import { ReactComponent as CapButtSvg } from '@sketch/icons/border-cap-butt-16'
import { ReactComponent as CapSquareSvg } from '@sketch/icons/corner-inside-square-16'

const Capitalized = styled.span`
  text-transform: capitalize;
`

const LineStyleImg = styled.svg`
  width: 17px;
  height: 17px;
  margin-right: 4px;
  opacity: 50%;
`

const capImages = {
  round: CapRoundSvg,
  butt: CapButtSvg,
  square: CapSquareSvg,
}

const joinImages = {
  round: JoinRoundSvg,
  miter: JoinMitterSvg,
  bevel: JoinBevelSvg,
}

const BorderOptionsSection = ({
  borderOptions,
  originalValues,
}: {
  borderOptions: BorderOptions
  originalValues?: BorderOptions
}) => {
  return (
    <>
      {borderOptions.dashPattern && (
        <>
          <FullCopyAttribute
            label="Dash"
            value={`${borderOptions.dashPattern[0] ?? 0}px`}
            dirtyAttributes={
              originalValues?.dashPattern
                ? {
                    originalValue: `${originalValues.dashPattern[0] ?? 0}px`,
                    originalProperty: 'Dash',
                  }
                : undefined
            }
          />
          <FullCopyAttribute
            label="Gap"
            value={`${borderOptions.dashPattern[1] ?? 0}px`}
            dirtyAttributes={
              originalValues?.dashPattern
                ? {
                    originalValue: `${originalValues.dashPattern[1] ?? 0}px`,
                    originalProperty: 'Gap',
                  }
                : undefined
            }
          />
        </>
      )}
      <Capitalized>
        <FullCopyAttribute
          label="Cap style"
          value={
            <Flex alignItems="center">
              <LineStyleImg as={capImages[borderOptions.lineCapStyle]} />
              {borderOptions.lineCapStyle}
            </Flex>
          }
          copyValue={borderOptions.lineCapStyle}
          dirtyAttributes={getDirtyAttributes({
            originalValueKey: 'lineCapStyle',
            valueToDisplay: originalValues?.lineCapStyle,
            originalValues,
            labelToDisplay: 'Cap style',
          })}
        />
      </Capitalized>
      {borderOptions.isEnabled && (
        <Capitalized>
          <FullCopyAttribute
            label="Join style"
            value={
              <Flex alignItems="center">
                <LineStyleImg as={joinImages[borderOptions.lineJoinStyle]} />
                {borderOptions.lineJoinStyle}
              </Flex>
            }
            copyValue={borderOptions.lineJoinStyle}
            dirtyAttributes={getDirtyAttributes({
              originalValueKey: 'lineJoinStyle',
              valueToDisplay: originalValues?.lineJoinStyle,
              originalValues,
              labelToDisplay: 'Join style',
            })}
          />
        </Capitalized>
      )}
    </>
  )
}

export default BorderOptionsSection

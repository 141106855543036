import styled, { css } from 'styled-components';
import { getDisplayName } from '@sketch/utils';
import { left, top, right, bottom, zIndex } from 'styled-system';

const absoluteCss = css `
  position: absolute;
  ${left}
  ${top}
  ${right}
  ${bottom}
  ${zIndex}
`;
function withAbsolute(Comp) {
    const styledComp = styled(Comp) `
    ${absoluteCss}
  `;
    styledComp.displayName = `withAbsolute(${getDisplayName(Comp)})`;
    return styledComp;
}

export { withAbsolute };

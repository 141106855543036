import { OnboardingPanelBody } from './OnboardingPanelBody.js';
import { OnboardingPanelFooter } from './OnboardingPanelFooter.js';
import { OnboardingPanelHeader } from './OnboardingPanelHeader.js';
import { OnboardingPanel as OnboardingPanel$1 } from './OnboardingPanel.js';

const OnboardingPanel = Object.assign(OnboardingPanel$1, {
    Body: OnboardingPanelBody,
    Footer: OnboardingPanelFooter,
    Header: OnboardingPanelHeader,
});

export { OnboardingPanel };

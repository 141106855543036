import { createStatic } from 'styled-components-breakpoint';

// px should always be divided by 16 to get proper breakpoint values in em, source: https://css-tricks.com/em-based-media-queries-are-based-on/
function pxToEm(px) {
    return `${px / 16}em`;
}
const BREAKPOINTS_IN_PX = {
    /*
    Because `styled-components-breakpoint` works with GTE values,
    we should have the smallest width possible.
  
    So we can make mobile only CSS like
  
    breakpoint('base', 'xs')`
      background-color: blue;
    `
    */
    base: 0,
    xxs: 325,
    xs: 512,
    sm: 768,
    md: 1024,
    lg: 1200,
    xl: 1600,
    xxl: 2000,
    xxxl: 2300,
};
const sortedBreakpoints = Object.entries(BREAKPOINTS_IN_PX).sort(([, a], [, b]) => a - b);
const BREAKPOINTS_SORTED = [];
const BREAKPOINTS_KEYS_SORTED = [];
const BREAKPOINTS_IN_EM = {};
sortedBreakpoints.forEach(([key, value]) => {
    BREAKPOINTS_SORTED.push(`${value}px`);
    BREAKPOINTS_KEYS_SORTED.push(key);
    BREAKPOINTS_IN_EM[key] = pxToEm(value);
});
const breakpoints = createStatic(BREAKPOINTS_IN_PX);
const breakpoint = breakpoints.breakpoint;

export { BREAKPOINTS_IN_EM, BREAKPOINTS_IN_PX, BREAKPOINTS_KEYS_SORTED, BREAKPOINTS_SORTED, breakpoint };

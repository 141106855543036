import styled, { css } from 'styled-components';
import { breakpoint } from '@sketch/global-styles';
import { LinkButton, FlatButton, Skeleton } from '@sketch/components';
import Icon from '../Icon/Icon.js';

const StyledIcon = styled(Icon)(({ theme: { transitions: { timing, duration }, }, }) => css `
    opacity: 70%;
    transition: opacity ${duration[2]} ${timing.easeInOut};
    path {
      color: white;
    }
  `);
const Container = styled.div `
  position: relative;
  display: flex;
  justify-content: ${({ $contentPosition }) => $contentPosition === 'right' ? 'flex-end' : 'flex-start'};
  align-items: center;

  height: 480px;
  padding: 64px; /* stylelint-disable-line scales/space */
  border-radius: ${({ theme }) => theme.radii.xxlarge};

  background-color: ${({ $backgroundColor, theme }) => $backgroundColor ? $backgroundColor : theme.colors.brand.yellow.default.A};

  ${breakpoint('base', 'lg') `
    height: 364px;
    padding 40px;
  `}

  ${breakpoint('base', 'md') `
    height: auto;
    padding 32px;
    flex-direction: column;
    justify-content: space-between;
  `}

  ${breakpoint('base', 'sm') `
    padding 24px;
  `};
`;
const ContentContainer = styled.div `
  width: 50%;

  ${breakpoint('base', 'md') `
    width: 100%;
    text-align: center;
  `}
`;
const Title = styled.h1 `
  font-size: ${({ theme }) => theme.fontSizes.I};
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  /* This needs to be hard coded (dark) because the hero background is yellow
  and we don't want to change the text color between themes */
  color: hsl(0, 0%, 0%);
  margin: 0 0 12px 0;

  ${({ theme }) => breakpoint('base', 'md') `
    font-size: ${theme.fontSizes.G};
  `}
`;
const Content = styled.p `
  font-size: ${({ theme }) => theme.fontSizes.F};

  /* This needs to be hard coded (dark) because the hero background is yellow
  and we don't want to change the text color between themes */
  color: hsla(0, 0%, 0%, 0.7);
  margin: 0;
`;
const ActionsContainer = styled.div `
  margin-top: 24px;
`;
const StyledLinkButton = styled(LinkButton) `
  white-space: break-spaces;
  /* Increase specificity to override Button "& + a { }" style*/
  && {
    margin-left: 0;
  }

  margin: 0 16px;

  /* This is needed because of the margin when the container size fits more
  than one button in a row*/
  @media (min-width: 1024px) and (max-width: 1070px) {
    &:not(:first-child) {
      margin: 16px 0;
    }
  }

  @media (max-width: 421px) {
    margin: 8px;
  }
`;
const StyledFlatButton = styled(FlatButton) `
  position: absolute;
  top: 32px;
  right: 32px;
  padding: 0;
  z-index: 1;

  ${StyledIcon} {
    :hover {
      opacity: 100%;
    }
  }

  svg {
    width: 32px;
    height: 32px;
  }

  div {
    height: 16px;
  }

  ${breakpoint('base', 'sm') `
    top: 16px;
    right: 16px;

    svg {
      width: 24px;
      height: 24px;
    }
  `}
`;
const MediaContainer = styled.div `
  display: flex;

  height: 100%;
  width: 50%;
  justify-content: center;
  position: absolute;
  pointer-events: none;

  ${breakpoint('base', 'md') `
    position: relative;
    height: auto;
    width: 100%;
    max-width: 440px;
  `}

  ${({ $contentPosition }) => $contentPosition === 'right'
    ? css `
          left: 0;
        `
    : css `
          right: 0;
        `}

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const Video = styled.video `
  width: 100%;
  height: 100%;
`;
const HeroSkeleton = styled(Skeleton) `
  height: 480px;
  border-radius: ${({ theme }) => theme.radii.xxlarge};

  ${breakpoint('base', 'lg') `
    height: 364px;
  `}
`;

export { ActionsContainer, Container, Content, ContentContainer, HeroSkeleton, MediaContainer, StyledFlatButton, StyledIcon, StyledLinkButton, Title, Video };

import { __awaiter } from 'tslib';
import { useApolloClient } from 'react-apollo';
import { useFetchInitialUser } from './useFetchInitialUser.js';
import { useUserProfile } from './useUserProfile.js';
import './multisession/index.js';
import { createPersonalAuthorization } from './multisession/authorizations.js';
import { setUserAuthorization, getAllAuthorizations, replaceAllAuthorizations } from './multisession/multisession.js';

const useSetUserAuthorization = () => {
    const client = useApolloClient();
    const fetchInitialUser = useFetchInitialUser();
    const { data: currentUserData } = useUserProfile();
    return (credentials) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const newAuthorization = createPersonalAuthorization(credentials);
        /* Save the user credentials in the apollo cache */
        setUserAuthorization(client.cache, newAuthorization);
        const allAuths = getAllAuthorizations();
        if (allAuths.length > 1) {
            const newUser = yield fetchInitialUser();
            if (((_a = currentUserData === null || currentUserData === void 0 ? void 0 : currentUserData.me) === null || _a === void 0 ? void 0 : _a.identifier) !== (newUser === null || newUser === void 0 ? void 0 : newUser.identifier)) {
                replaceAllAuthorizations(newAuthorization);
            }
        }
    });
};

export { useSetUserAuthorization };

import { jsxs, jsx } from 'react/jsx-runtime';
import { Modal, WorkspaceLogo, Button } from '@sketch/components';
import { Description, List, ListItem, WorkspaceName } from './LinkedWorkspacesModal.styles.js';

const LinkedWorkspacesModal = ({ hideModal, userEmail, workspaces, }) => {
    return (jsxs(Modal, Object.assign({ title: `${workspaces.length} Workspaces`, onCancel: hideModal }, { children: [jsxs(Modal.Body, { children: [jsxs(Description, { children: [userEmail, " has access to:"] }), jsx(List, { children: workspaces.map(workspace => {
                            var _a;
                            return (jsxs(ListItem, { children: [jsx(WorkspaceLogo, { size: "24px", workspaceName: workspace.name, src: (_a = workspace.avatar) === null || _a === void 0 ? void 0 : _a.small }, workspace.identifier), jsx(WorkspaceName, { children: workspace.name })] }, workspace.identifier));
                        }) })] }), jsx(Modal.Footer, { children: jsx(Button, Object.assign({ variant: "primary-untinted", onClick: hideModal }, { children: "Close" })) })] })));
};

export { LinkedWorkspacesModal as default };

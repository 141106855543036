import { fragmentTypes } from '@sketch/gql-types';
import { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory';
import { cacheRedirects } from './redirects.js';
import { dataIdFromObject } from './dataIdFromObject.js';
import { ShortIdsMapper } from './ShortIdsMapper.js';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: fragmentTypes,
});
const redirects = cacheRedirects;
const createAugmentedCache = (options = {}) => {
    const merged = Object.assign({ 
        // default values
        fragmentMatcher,
        dataIdFromObject, cacheRedirects: redirects }, options);
    const shortIdsMapper = new ShortIdsMapper();
    const augmented = Object.assign(Object.assign({}, merged), { dataIdFromObject: object => {
            var _a;
            const { __typename } = object;
            switch (__typename) {
                case 'Organization':
                case 'Version': {
                    shortIdsMapper.record(Object.assign(Object.assign({}, object), { __typename }));
                    break;
                }
            }
            return (_a = merged.dataIdFromObject) === null || _a === void 0 ? void 0 : _a.call(merged, object);
        } });
    return Object.assign(new InMemoryCache(augmented), { shortIdsMapper });
};
const createCache = createAugmentedCache;

export { createAugmentedCache, createCache };

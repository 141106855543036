import '../settings/index.js';
import { readSettings } from '../settings/settings.localStorage.js';

const getDummyDataSettings = () => {
    const { dummyData } = readSettings();
    return {
        delaySeconds: (dummyData === null || dummyData === void 0 ? void 0 : dummyData.delaySeconds) || 0,
        logRequests: (dummyData === null || dummyData === void 0 ? void 0 : dummyData.logRequests) || false,
    };
};

export { getDummyDataSettings };

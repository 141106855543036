import React, { CSSProperties, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { ColorFormat } from 'modules/shares/types'
import { gradientBackground, gradientAngle } from './math'
import checkerboardBackground from '@sketch/icons/inspector/checkerboard.svg'
import Opacity from '../Attribute/components/Opacity'
import { default as BaseRadialIcon } from './components/RadialGradientIcon'
import { default as BaseAngularIcon } from './components/AngularGradientIcon'
import { roundWithLocale } from '@sketch/utils'
import GradientPopover from './components/GradientPopover'
import { Tooltip, Flex } from '@sketch/components'
import copy from './copy'
import { ClickableArea } from 'modules/shares/Inspector/Sidebar/components/Color/PlainColor'
import DirtyIconTooltip from 'modules/shares/Inspector/Sidebar/components/Style/DirtyIconTooltip'
import { useHandleCopyValue } from '@sketch/modules-common'

import { Attribute, AttributeValue as BaseAttributeValue } from '../Attribute'
import { Appearance, Gradient } from 'modules/inspector'
import { IS_SHOW_DIRTY_ATTRIBUTES_ENABLED } from '../../constants'

const iconStyles = css`
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 999px;
  top: 0;
`

const Value = styled(BaseAttributeValue)`
  overflow: visible;
`

const RadialIcon = styled(BaseRadialIcon)`
  ${iconStyles};
`

const AngularIcon = styled(BaseAngularIcon)`
  ${iconStyles};
`

interface GradientProps {
  gradient: Gradient
  style?: CSSProperties
}

const GradientIcon = styled(({ gradient, style, ...props }: GradientProps) => (
  <div
    style={{ ...style, background: gradientBackground(gradient) }}
    {...props}
  />
))<{ gradient: Gradient }>`
  ${iconStyles};
`
const Border = styled.div`
  ${iconStyles};
  border: 1px solid ${({ theme }) => theme.colors.border.A};
`
const Background = styled.div`
  ${iconStyles};
  background-image: url(${checkerboardBackground});
  background-position: center center;
  background-size: cover;
  top: 0;
  left: 0;
`

interface GradientPreviewProps {
  className?: string
  gradient: Gradient
  opacity: number
}

export const GradientPreview = styled(
  ({ className, gradient, opacity }: GradientPreviewProps) => {
    const icon =
      gradient.type === 'radial' ? (
        <RadialIcon gradient={gradient} opacity={opacity} />
      ) : gradient.type === 'angular' ? (
        <AngularIcon gradient={gradient} />
      ) : (
        <GradientIcon gradient={gradient} data-testid="gradient-preview" />
      )

    // A border does not cover the full gradient when we apply the border and
    // gradient on the same element. This leads to the opaque border rendering
    // incorrect colors.
    // We work around this by manually drawing the borders on top of the
    // background and gradient.
    return (
      <div className={className}>
        <Background />
        {icon}
        <Border />
      </div>
    )
  }
)`
  ${iconStyles};
  position: relative;
  margin-right: 4px;
  display: inline-block;
`

const Container = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
  cursor: pointer;
  /* stylelint-disable-next-line scales/space */
  margin: -1px 0; /* Needed to adjust gradient height to 19px as any other attribute */
`

const GradientContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  border-radius: 999px;
  padding: 1px 4px 1px 1px; /* stylelint-disable-line scales/space */
  line-height: normal; /* stylelint-disable-line scales/line-height */
`

const GradientType = styled.span`
  text-transform: capitalize;
`

function getOriginalValue(
  originalGradient: Gradient | undefined,
  originalParentPropAppearanceValue: Appearance | undefined
): string {
  const originalValues = []

  if (originalGradient?.type) {
    originalValues.push(originalGradient?.type)
  }

  if (
    originalGradient?.from !== undefined &&
    originalGradient?.to !== undefined
  ) {
    originalValues.push(
      `${roundWithLocale(gradientAngle(originalGradient), 0)}°`
    )
  }

  if (originalParentPropAppearanceValue?.opacity) {
    const originalOpacity = Math.round(
      originalParentPropAppearanceValue.opacity * 100
    )
    originalValues.push(`${originalOpacity}% opacity`)
  }

  const numberOfStops = originalGradient?.stops?.length ?? 0
  if (numberOfStops > 0) {
    originalValues.length > 0
      ? originalValues.push('and has different color stops')
      : originalValues.push('has different color stops')
  }

  return originalValues.join(', ')
}

interface GradientAttributeProps {
  gradient: Gradient
  parentPropAppearance?: Appearance
  onColorFormatChange: (f: ColorFormat) => void
  colorFormat: ColorFormat
  dirtyAttributes?: {
    originalGradientValue?: Gradient
    originalParentPropAppearanceValue?: Appearance
  }
}

export const GradientAttribute: React.FC<GradientAttributeProps> = ({
  gradient,
  onColorFormatChange,
  colorFormat,
  /**
   * The appearance object of the property using the gradient (fill or border appearance)
   */
  parentPropAppearance,
  dirtyAttributes,
  ...props
}) => {
  // Overwrite value if dirty attributes are not enabled
  dirtyAttributes = IS_SHOW_DIRTY_ATTRIBUTES_ENABLED
    ? dirtyAttributes
    : undefined
  const [isPopoverOpen, setPopoverOpen] = useState(false)

  // Every time the gradient changes the popover should hide
  useEffect(() => {
    setPopoverOpen(false)
  }, [gradient])

  const gradientCopyValue = copy(gradient, parentPropAppearance, colorFormat)

  const {
    handleCopyValue,
    copyTooltipText,
    handleEnterCopiableArea,
    handleLeaveCopiableArea,
    copyTooltipVisible,
  } = useHandleCopyValue(gradientCopyValue)

  return (
    <Attribute {...props}>
      <Tooltip
        style={{ width: '33%' }}
        placement="left"
        spacing="10px"
        visible={copyTooltipVisible}
        content={copyTooltipText}
      >
        <Flex>
          <div>
            <ClickableArea
              aria-hidden
              onClick={handleCopyValue}
              onMouseEnter={handleEnterCopiableArea}
              onMouseLeave={handleLeaveCopiableArea}
            >
              Gradient
            </ClickableArea>
          </div>
          {dirtyAttributes && (
            <DirtyIconTooltip
              originalProperty="Gradient"
              originalValue={getOriginalValue(
                dirtyAttributes.originalGradientValue,
                dirtyAttributes.originalParentPropAppearanceValue
              )}
              preventLabelTooltip={handleLeaveCopiableArea}
            />
          )}
        </Flex>
      </Tooltip>
      <Value label="Gradient" valueString={gradientCopyValue}>
        <GradientPopover
          visible={isPopoverOpen}
          gradient={gradient}
          onColorFormatChange={onColorFormatChange}
          colorFormat={colorFormat}
          onClickOutside={() => setPopoverOpen(false)}
          copyValue={gradientCopyValue}
          toggle={
            <Container>
              <GradientContainer onClick={() => setPopoverOpen(!isPopoverOpen)}>
                <GradientPreview
                  gradient={gradient}
                  opacity={parentPropAppearance?.opacity ?? 1}
                />
                <span>
                  <GradientType>{gradient.type}</GradientType> (
                  {roundWithLocale(gradientAngle(gradient), 0)}˚)
                </span>
              </GradientContainer>
              <Opacity value={parentPropAppearance?.opacity ?? 1} />
            </Container>
          }
        />
      </Value>
    </Attribute>
  )
}

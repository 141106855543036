import { jsx, jsxs } from 'react/jsx-runtime';
import '../Skeleton/index.js';
import { SkeletonWrapper, SkeletonRow } from './SkeletonSidebarList.styles.js';
import { Skeleton } from '../Skeleton/Skeleton.js';

/**
 *
 * SkeletonSidebarList
 *
 * Simple Skeleton loader for the Sidebar menus
 *
 */
const SkeletonSidebarList = ({ numberOfItems = 2, 'data-testid': dataTestId, className, }) => {
    const items = Array.from(Array(numberOfItems).keys());
    return (jsx(SkeletonWrapper, Object.assign({ "data-testid": dataTestId, className: className }, { children: items.map(key => (jsxs(SkeletonRow, { children: [jsx(Skeleton, { height: "16px", width: "16px" }), jsx(Skeleton, { height: "16px" })] }, key))) })));
};

export { SkeletonSidebarList };

import styled from 'styled-components';

const NavbarLinkWrapper = styled.div `
  position: relative;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  opacity: ${props => (props.disabled ? '0.4' : 1)};
  line-height: 1;

  :hover {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }
`;

export { NavbarLinkWrapper };

import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Button,
  CenterContent,
  ErrorMessage,
  PageLayout,
} from '@sketch/components'
import {
  Footer,
  EmptyHeader,
  DynamicLoadingPage,
  GenericErrorView,
  routes,
} from '@sketch/modules-common'

import { useSignInToUnauthorizedWorkspace } from 'modules/sso/operations'
import { useGetWorkspacesQuery } from '@sketch/gql-types'

import { ReactComponent as LockIcon } from '@sketch/icons/lock-64'

interface WrongAuthorizationViewProps {
  workspaceIdentifier?: string
  email?: string
}

const Description = ({ hasWorkspace }: { hasWorkspace: boolean }) => {
  return hasWorkspace ? (
    <>
      This document is part of another workspace that you are already a member
      of. <br />
      Please sign in to view this document
    </>
  ) : (
    <>
      To access this document, you need to log into the account that has access
      to it.
    </>
  )
}

export const WrongAuthorizationView = (props: WrongAuthorizationViewProps) => {
  const { workspaceIdentifier } = props
  /**
   * Skipping this query will make all data/error and loading values to be
   * undefined/false.
   */
  const { data, loading, error } = useGetWorkspacesQuery({
    skip: !workspaceIdentifier,
  })

  const { signIn, signInToPersonalAccount } = useSignInToUnauthorizedWorkspace()

  const history = useHistory()

  const handleOnSignIn = useCallback(() => {
    const workspaces = data?.me?.workspaces || []

    const workspace = workspaces.find(
      workspace => workspace?.identifier === workspaceIdentifier,
      []
    )

    const onCompleted = () => {
      const currentLocation = history.location
      history.push(routes.ENTRY.create({}))
      history.replace(currentLocation)
    }

    if (workspace) {
      signIn({
        workspace,
        redirectBackToCurrentUrl: true,
        onCompleted,
      })
    } else {
      signInToPersonalAccount({
        workspaceName: 'Personal',
        onCompleted,
      })
    }
  }, [data, history, signInToPersonalAccount, signIn, workspaceIdentifier])

  if (loading) {
    return <DynamicLoadingPage />
  }

  if (error) {
    return <GenericErrorView />
  }

  return (
    <PageLayout header={<EmptyHeader />} footer={<Footer />}>
      <CenterContent>
        <ErrorMessage.Generic
          title="Sign in to view this document"
          icon={<LockIcon />}
          iconSize="large"
          extra={
            <Button variant="primary" onClick={handleOnSignIn}>
              Sign In
            </Button>
          }
        >
          <Description hasWorkspace={true} />
        </ErrorMessage.Generic>
      </CenterContent>
    </PageLayout>
  )
}

import React from 'react';

const MenuContext = React.createContext({
    setValue: (item, path) => { },
    activePath: [],
    setForceExpanded: (force) => { },
    forceExpanded: false,
});
const useSelectDropdownMenu = () => {
    return React.useContext(MenuContext);
};
const MenuContextProvider = MenuContext.Provider;

export { MenuContextProvider, useSelectDropdownMenu };

import React from 'react'
import styled from 'styled-components'
import { roundWithLocale } from '@sketch/utils'
import Header, { SubTitle } from '../Header'
import GradientAttribute from '../GradientAttribute'
import {
  ColorFormat,
  Gradient,
  Border,
  BorderOptions,
  FillType,
  Color as ColorType,
} from 'modules/shares/types'
import { AttributeList } from '../Attribute'
import Color from '../Color'
import FullCopyAttribute from '../FullCopyAttribute'
import copy from './copy'
import BorderOptionsSection from './BorderOptionsSection'
import { getDirtyAttributes } from 'modules/shares/Inspector/Sidebar/components/Style/DirtyIconTooltip'

const Capitalized = styled.span`
  text-transform: capitalize;
`

export interface BordersProps {
  borders: Border[]
  onColorFormatChange: (f: ColorFormat) => void
  colorFormat: ColorFormat
  borderOptions?: BorderOptions
  originalValues?: Border[]
  originalValuesOptions?: BorderOptions
}

const isBlendModeVisible = (blendMode: string) =>
  blendMode.toUpperCase() !== 'NORMAL'

const removeOpacity = (gradient: Gradient): Gradient => {
  if (gradient.appearance) {
    gradient.appearance.opacity = 1
  }
  return gradient
}

interface renderBorderProps extends Border {
  originalValue?: Border
}

const renderBorder = (
  {
    isEnabled,
    fillType,
    gradient,
    color,
    position,
    thickness,
    appearance,
    originalValue,
  }: renderBorderProps,
  {
    onColorFormatChange,
    colorFormat,
  }: {
    onColorFormatChange: (f: ColorFormat) => void
    colorFormat: ColorFormat
  },
  i: number
) => {
  if (!isEnabled) {
    return null
  }

  const blendMode = appearance && appearance.blendMode
  let colorOrGradient = null
  if (fillType === FillType.Color && color) {
    colorOrGradient = (
      <Color
        {...color}
        onColorFormatChange={onColorFormatChange}
        colorFormat={colorFormat}
        dirtyAttributes={
          originalValue?.color
            ? {
                originalValue: originalValue?.color as ColorType,
                originalProperty: 'color',
              }
            : undefined
        }
      />
    )
  } else if (fillType === FillType.Gradient && gradient) {
    colorOrGradient = (
      <GradientAttribute
        gradient={removeOpacity(gradient)}
        onColorFormatChange={onColorFormatChange}
        colorFormat={colorFormat}
      />
    )
  }

  return (
    <AttributeList key={`InspectorBorder${i}`} data-testid="border">
      {colorOrGradient}
      {isBlendModeVisible(blendMode) ? (
        <FullCopyAttribute
          label="Blend Mode"
          value={<Capitalized>{blendMode}</Capitalized>}
          copyValue={blendMode}
          dirtyAttributes={getDirtyAttributes({
            originalValueKey: 'blendMode',
            valueToDisplay: originalValue?.blendMode,
            originalValues: originalValue,
            labelToDisplay: 'Blend Mode',
          })}
        />
      ) : null}
      {position ? (
        <FullCopyAttribute
          label="Position"
          value={<Capitalized>{position}</Capitalized>}
          copyValue={position}
          dirtyAttributes={getDirtyAttributes({
            originalValueKey: 'position',
            valueToDisplay: originalValue?.position,
            originalValues: originalValue,
          })}
        />
      ) : null}
      <FullCopyAttribute
        label="Width"
        value={`${roundWithLocale(thickness, 2)}`}
        copyValue={`${thickness}`}
        dirtyAttributes={getDirtyAttributes({
          originalValueKey: 'thickness',
          valueToDisplay: `${roundWithLocale(
            originalValue?.thickness ?? 0,
            2
          )}`,
          originalValues: originalValue,
          labelToDisplay: 'Width',
        })}
      />
    </AttributeList>
  )
}

const Borders = ({
  borders,
  onColorFormatChange,
  colorFormat,
  borderOptions,
  originalValues,
  originalValuesOptions,
}: BordersProps) => {
  const renderedBorders: Array<JSX.Element> = []
  const reversedBorders = [...borders].reverse()
  const reversedOriginalValues = originalValues
    ? [...originalValues].reverse()
    : undefined

  const dirtyHeaderAttributes =
    originalValues && originalValues[0] === null
      ? { originalValue: 'disabled', originalProperty: 'Borders value' }
      : undefined

  reversedBorders.forEach((border, index) => {
    const renderedBorder = renderBorder(
      {
        ...border,
        originalValue: reversedOriginalValues
          ? reversedOriginalValues[index]
          : undefined,
      },
      {
        onColorFormatChange,
        colorFormat,
      },
      index
    )
    renderedBorder && renderedBorders.push(renderedBorder)
  })

  const sectionCopyValue = copy(reversedBorders, colorFormat)

  return renderedBorders.length > 0 ? (
    <>
      <Header
        copyValue={sectionCopyValue}
        dirtyAttributes={dirtyHeaderAttributes}
      >
        <SubTitle>Borders</SubTitle>
      </Header>
      {renderedBorders}
      {borderOptions && (
        <BorderOptionsSection
          borderOptions={borderOptions}
          originalValues={originalValuesOptions}
        />
      )}
    </>
  ) : null
}

export default Borders

import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { useContext } from 'react';
import { useModalContext, Modal, Link, Button } from '@sketch/components';
import { ToastContext } from '@sketch/toasts';
import { useRevokeSessionMutation, GetUserSettingsDocument } from '@sketch/gql-types';
import '../ConfirmRevokeAllSessionsModal/index.js';
import { Title, Description } from './ConfirmRevokeIndividualSessionModal.styles.js';
import { countries } from '@sketch/constants';
import { getBrowserFromUserAgent, dateFormat } from '@sketch/utils';
import produce from 'immer';
import { getAuthorizationFromSessionId, useSignOut } from '@sketch/modules-common';
import ConfirmRevokeAllSessionsModal from '../ConfirmRevokeAllSessionsModal/ConfirmRevokeAllSessionsModal.js';

const ConfirmRevokeIndividualSessionModal = ({ hideModal, id, countryCode, deviceName, ipAddress, isCurrentOne, lastUpdateAt, userAgent, type, }) => {
    const { showModal } = useModalContext();
    const auth = getAuthorizationFromSessionId(id);
    const signOut = useSignOut({
        revokeSessions: 'none',
        removeDataFromSessions: isCurrentOne && auth ? [auth.fragment.authToken] : 'none',
        reason: 'Revoking session in Sessions Management',
        redirectBackAfterLoginAgain: false,
    });
    const [revokeSession, { loading }] = useRevokeSessionMutation({
        variables: {
            id,
        },
        redirectErrors: false,
        onCompleted: result => {
            var _a;
            if ((_a = result.revokeSession) === null || _a === void 0 ? void 0 : _a.successful) {
                showToast('Successfully signed out of device', 'positive');
                if (isCurrentOne) {
                    signOut();
                }
                hideModal();
            }
        },
        update: (cache, { data, errors }) => {
            if (!(data === null || data === void 0 ? void 0 : data.revokeSession.successful) || (errors && errors.length > 0)) {
                return;
            }
            const cachedQuery = cache.readQuery({
                query: GetUserSettingsDocument,
                variables: {},
            });
            if (!(cachedQuery === null || cachedQuery === void 0 ? void 0 : cachedQuery.me))
                return;
            const updatedMe = produce(cachedQuery.me, user => {
                if (!user.activeSessions || !user.activeSessions.entries)
                    return;
                user.activeSessions.entries = user.activeSessions.entries.filter(session => (session === null || session === void 0 ? void 0 : session.id) !== id);
            });
            cache.writeQuery({
                query: GetUserSettingsDocument,
                data: { __typename: 'RootQueryType', me: updatedMe },
            });
        },
        onError: 'show-toast',
    });
    const { showToast } = useContext(ToastContext);
    const sessionTypeLabel = {
        WEB: 'Sketch Web App',
        MAC_APP: 'Sketch App',
    };
    return (jsxs(Modal, Object.assign({ onCancel: hideModal }, { children: [jsxs(Modal.Body, { children: [jsx(Title, { children: "Sign Out of Device?" }), jsxs(Description, { children: [type && (jsxs(Fragment, { children: [jsx("strong", { children: "Type: " }), sessionTypeLabel[type], " (", type === 'WEB'
                                        ? getBrowserFromUserAgent(userAgent || '')
                                        : deviceName, ")", jsx("br", {})] })), lastUpdateAt && (jsxs(Fragment, { children: [jsx("strong", { children: "Time: " }), dateFormat(new Date(lastUpdateAt), 'en-GB', {
                                        day: 'numeric',
                                        month: 'long',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    }), jsx("br", {})] })), countryCode && (jsxs(Fragment, { children: [jsx("strong", { children: "Location: " }), countries[countryCode], jsx("br", {})] })), ipAddress && (jsxs(Fragment, { children: [jsx("strong", { children: "IP Address: " }), ipAddress, jsx("br", {})] })), jsx("br", {}), "If you didn't sign in with this device, you should change your password and", ' ', jsx(Link, Object.assign({ onClick: e => {
                                    e.preventDefault();
                                    showModal(ConfirmRevokeAllSessionsModal);
                                }, isUnderlined: true, external: true }, { children: "sign out of all devices" })), "."] })] }), jsxs(Modal.Footer, { children: [jsx(Button, Object.assign({ onClick: hideModal, size: "40", variant: "secondary", disabled: loading }, { children: "Cancel" })), jsx(Button, Object.assign({ onClick: () => revokeSession(), size: "40", variant: "primary", loading: loading }, { children: "Sign Out" }))] })] })));
};

export { ConfirmRevokeIndividualSessionModal as default };

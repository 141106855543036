import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  IndexLayoutOld,
  IndexLayoutOldTitle,
  IndexLayoutOldSubtitle,
  DynamicLoadingPage,
  GenericErrorView,
  routes,
  useFlag,
  useQueryParams,
} from '@sketch/modules-common'

import { useScrollTop } from '@sketch/utils'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import { Link, OrderedBreadcrumbs } from '@sketch/components'
import SignUpBreadcrumbs from 'modules/user/components/SignUpBreadcrumbs'
import { useCreateWorkspace } from 'modules/workspace/operations/useCreateWorkspace'
import { useToast } from '@sketch/toasts'
import {
  useGetWorkspaces,
  useUpgradePersonalWorkspace,
} from 'modules/workspace/operations'

import {
  CreateWorkspaceForm,
  FormValues,
} from '../../components/CreateWorkspaceForm'

import {
  useWorkspaceState,
  useFormSource,
  useAnalyticsFormContext,
} from './CreateWorkspaceView.hooks'

import { BreadcrumbsContainer } from './CreateWorkspaceView.styles'

import {
  useGetInitialUserQuery,
  WorkspaceMinimalFragment,
  useGetPersonalWorkspaceSharesCountQuery,
} from '@sketch/gql-types'

const SIGN_IN_WORKSPACE_CRUMBS = [
  { content: 'Sign In' },
  { content: 'About You' },
  { content: 'Create Workspace' },
]

const PARTNER_WORKSPACE_CRUMBS = [
  { content: 'Create Workspace' },
  { content: 'Set Up a Subscription' },
]

interface TitleAndDescriptionProps {
  isUpgradingPersonalWorkspace: boolean
}

const TitleAndDescription = ({
  isUpgradingPersonalWorkspace,
}: TitleAndDescriptionProps) => {
  if (isUpgradingPersonalWorkspace) {
    return (
      <>
        <IndexLayoutOldTitle>Upgrade Personal Workspace</IndexLayoutOldTitle>
        <IndexLayoutOldSubtitle>
          Get the full set of collaboration tools and access to the
          Mac&nbsp;app.{' '}
          <Link
            variant="secondary"
            href={`${SKETCH_WEBSITE}/support/switch-to-a-subscription/`}
            isUnderlined
            external
          >
            Learn More
          </Link>
        </IndexLayoutOldSubtitle>
      </>
    )
  } else {
    return (
      <>
        <IndexLayoutOldTitle>Create Your Workspace</IndexLayoutOldTitle>
        <IndexLayoutOldSubtitle>
          Your Workspace is where you&apos;ll save your work <br />
          and collaborate with others.
        </IndexLayoutOldSubtitle>
      </>
    )
  }
}

const CreateWorkspaceView: React.FC = () => {
  const { showToast } = useToast()
  const { name = '', upgrade = 'false' } = useQueryParams<'WORKSPACE_CREATE'>()
  const [isWorkspaceCreated, setIsWorkspaceCreated] = useState(false)
  const history = useHistory()
  const { fromSignIn, fromSignUp } = useFormSource()
  const currentUserFlow = useAnalyticsFormContext()
  const { data, loading, error } = useGetPersonalWorkspaceSharesCountQuery()
  const { data: userData } = useGetInitialUserQuery()
  const isPartnerSupport = useFlag('partner-and-resellers-support')

  const {
    data: workspaceData,
    loading: workspaceLoading,
    error: workspaceError,
  } = useGetWorkspaces({})

  /**
   * Initiate the Workspace State, and start with any value that is present in the
   * URL query string (with sane defaults). Also cap the values according to
   * the business/backend rules: max 500 seats, and workspace name can not be longer
   * than 100 chars
   */
  const [state, listeners] = useWorkspaceState({
    details: { name: name.substring(0, 100) },
  })

  const isPartner = (userData?.me?.isPartner && isPartnerSupport) || false

  const handleWorkspaceCreated = (workspace: WorkspaceMinimalFragment) => {
    setIsWorkspaceCreated(true)
    history.push(
      routes.WORKSPACE_CREATE_DONE.create({
        workspaceId: workspace.identifier,
      }),
      {
        currentUserFlow: currentUserFlow,
      }
    )
  }

  const handleWorkspaceCreateError = (error: string) => {
    showToast(error, 'negative')
  }

  const [createWorkspace] = useCreateWorkspace({
    onComplete: handleWorkspaceCreated,
    onError: handleWorkspaceCreateError,
  })

  const [upgradePersonalWorkspace] = useUpgradePersonalWorkspace({
    onComplete: handleWorkspaceCreated,
    onError: handleWorkspaceCreateError,
  })

  useScrollTop()

  if (loading || workspaceLoading) {
    return <DynamicLoadingPage />
  }

  if (error || workspaceError || !data) {
    return <GenericErrorView />
  }

  const noWorkspaces =
    workspaceData?.me.workspaces.length === 0 &&
    !workspaceData?.me.personalWorkspace

  const isUpgradingPersonalWorkspace =
    upgrade === 'true' && data.me.personalWorkspace !== null

  const handleOnCreateWorkspace = async (values: FormValues) => {
    const { tosAgreed, privacyPolicyAgreed, ...details } = values
    listeners.addWorkspaceDetails(details)

    const workspaceState = {
      details,
      members: state.members,
      billing: state.billing,
    }

    if (isUpgradingPersonalWorkspace || details.upgrade === 'Upgrade') {
      await upgradePersonalWorkspace(
        data.me.personalWorkspace?.identifier ?? '',
        workspaceState,
        tosAgreed,
        privacyPolicyAgreed
      )
    } else {
      if (!isPartner) {
        await createWorkspace(workspaceState)
      } else {
        const ownerMember = workspaceState.members.find(item => !item.isOwner)
        history.push(routes.WORKSPACE_SUBSCRIBE_PARTNER.template(), {
          name: workspaceState.details.name,
          logo: workspaceState.details.logo,
          ownerEmail: ownerMember?.email,
        })
      }
    }
  }

  const headerLink = noWorkspaces ? 'entry' : 'workspace-settings'

  return (
    <IndexLayoutOld
      headerLink={headerLink}
      variant="orange"
      isCreatingWorkspace
    >
      {fromSignIn && !isPartner && (
        <BreadcrumbsContainer>
          <OrderedBreadcrumbs
            crumbs={SIGN_IN_WORKSPACE_CRUMBS}
            currentCrumb={2}
          />
        </BreadcrumbsContainer>
      )}

      {isPartner && (
        <BreadcrumbsContainer>
          <OrderedBreadcrumbs
            crumbs={PARTNER_WORKSPACE_CRUMBS}
            currentCrumb={0}
          />
        </BreadcrumbsContainer>
      )}
      {fromSignUp && <SignUpBreadcrumbs showWorkspaceCrumbs currentCrumb={2} />}

      <TitleAndDescription
        isUpgradingPersonalWorkspace={isUpgradingPersonalWorkspace}
      />
      <CreateWorkspaceForm
        state={state}
        isPartner={isPartner}
        showUpgradeCallout={!isUpgradingPersonalWorkspace}
        isUpgradingPersonalWorkspace={isUpgradingPersonalWorkspace}
        personalWorkspace={data.me.personalWorkspace}
        isWorkspaceCreated={isWorkspaceCreated}
        onCreateWorkspace={handleOnCreateWorkspace}
        onMembersUpdate={listeners.updateWorkspaceMembers}
        onMembersDelete={listeners.deleteWorkspaceMembersPartners}
      />
    </IndexLayoutOld>
  )
}

export default CreateWorkspaceView

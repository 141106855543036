import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useCallback } from 'react';
import styled from 'styled-components';
import { mapSsoStartUrl, routes } from '@sketch/modules-common';
import { Link } from '@sketch/components';
import { ErrorHandler } from '@sketch/tracing';
import { useGetSsoStartUrlLazyQuery } from '@sketch/gql-types';

const StyledLink = styled(Link) `
  color: ${({ theme }) => theme.colors.state.negative.A};
`;
const mapResponse = ({ source, from, data, }) => {
    var _a;
    return ({
        url: (data === null || data === void 0 ? void 0 : data.ssoStartUrl.successful)
            ? mapSsoStartUrl(data.ssoStartUrl.url, { source, from })
            : undefined,
        errors: (_a = data === null || data === void 0 ? void 0 : data.ssoStartUrl.errors.filter(e => !!e).map(e => {
            if (e.code === 'NOT_FOUND') {
                return (jsxs(Fragment, { children: ["The shortname is incorrect or the Workspace isn\u2019t configured for SSO. Check the shortname,", ' ', jsx(StyledLink, Object.assign({ to: routes.SIGN_IN.create({}), isUnderlined: true }, { children: "sign in with your email and password." }))] }));
            }
            else {
                return e.message;
            }
        })) !== null && _a !== void 0 ? _a : [],
    });
};
const useGetSsoStartUrl = ({ source, from, onNetworkError, onCompleted, }) => {
    const [getSsoStartUrl, { data, loading }] = useGetSsoStartUrlLazyQuery({
        fetchPolicy: 'network-only',
        onError: () => {
            ErrorHandler.apollo('GetSsoStartUrl', {});
            onNetworkError('Something went wrong');
        },
        onCompleted: () => {
            onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted();
        },
    });
    const getUrl = useCallback(({ teamName }) => {
        getSsoStartUrl({ variables: { teamName: teamName.trim() } });
    }, [getSsoStartUrl]);
    const response = mapResponse({ source, from, data });
    return { getUrl, response, isLoading: loading };
};

export { useGetSsoStartUrl };

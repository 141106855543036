import { useGetWorkspacesQuery, GetWorkspacesQuery } from '@sketch/gql-types'
import { QueryHookOptions } from '@sketch/graphql-apollo'

interface UseGetWorkspacesProps
  extends OmitSafe<QueryHookOptions<GetWorkspacesQuery, {}>, 'variables'> {}

const getWorkspaces = (data: GetWorkspacesQuery | undefined) => {
  if (!data?.me) {
    return undefined
  }

  const workspaces = [
    ...(data.me.personalWorkspace ? [data.me.personalWorkspace] : []),
    ...data.me.workspaces,
  ]
  return workspaces
}

export const useGetWorkspaces = (props?: UseGetWorkspacesProps) => {
  const result = useGetWorkspacesQuery({
    ...props,
  })

  const workspaces = getWorkspaces(result.data)

  return { ...result, workspaces }
}

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import styled from 'styled-components';
import '../Box/index.js';
import { Flex, Text } from '../Box/BoxSystem.js';

const scoreToColor = {
    0: 'state.negative.A',
    1: 'state.negative.A',
    2: 'sketch.A',
    3: '#007BB3',
    4: 'state.positive.A',
};
const scoreToLabel = {
    0: 'Weak',
    1: 'Weak',
    2: 'Okay',
    3: 'Good',
    4: 'Great!',
};
const StrengthBar = styled.div `
  flex: 1;
  height: 4px;
  margin-right: 2px; /* stylelint-disable-line scales/space */
  background-color: ${({ theme }) => theme.colors.border.A};

  &:last-child {
    margin-right: 0;
  }

  ${({ score, evaluatePassword, theme }) => {
    if (evaluatePassword) {
        switch (score) {
            case 0:
            case 1:
                return `
          &:nth-child(1) {
            background-color: ${theme.colors.state.negative.A};
          }
        `;
            case 2:
                return `
          &:nth-child(1), &:nth-child(2) {
            background-color: ${theme.colors.sketch.A};
          }
        `;
            case 3:
                return `
          &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            background-color: #007BB3;
          }
        `;
            case 4:
                return `
        background-color: ${theme.colors.state.positive.A};
        `;
        }
    }
}}
`;
const formatSuggestionsMessage = ({ suggestions, warning, }) => {
    const messages = [warning, ...suggestions];
    const finalMessage = messages
        // Remove empty strings (warning can be empty)
        .filter(Boolean)
        // Remove the dot in the end if it has to avoid duplicate dots after join
        .map(message => message[message.length - 1] === '.' ? message.slice(0, -1) : message)
        // Putting all messages together with a dot and a space between them
        .join('. ');
    // returns with a dot in the end if there's at least one message
    return messages.length ? `${finalMessage}.` : null;
};
const PasswordStrengthMeter = ({ password, strength: { score, feedback }, }) => {
    // Only show visual feedback if there's at least one character
    const evaluatePassword = password.length > 0;
    const attendsToLengthCriteria = password.length >= 8;
    const feedbackMessage = formatSuggestionsMessage(feedback);
    return (jsxs(Fragment, { children: [jsxs(Flex, Object.assign({ mt: 5, mb: 2 }, { children: [jsxs(Text.Span, Object.assign({ textStyle: "copy.quaternary.standard.C", mr: 1 }, { children: ["Password Strength:", ' '] })), evaluatePassword && (jsx(Text.Span, Object.assign({ textStyle: "copy.quaternary.standard.C", color: scoreToColor[score] }, { children: scoreToLabel[score] })))] })), jsxs(Flex, Object.assign({ width: "100%" }, { children: [jsx(StrengthBar, { score: score, evaluatePassword: evaluatePassword }), jsx(StrengthBar, { score: score, evaluatePassword: evaluatePassword }), jsx(StrengthBar, { score: score, evaluatePassword: evaluatePassword }), jsx(StrengthBar, { score: score, evaluatePassword: evaluatePassword })] })), jsxs(Flex, Object.assign({ mt: 2 }, { children: [score <= 1 && feedbackMessage && attendsToLengthCriteria && (jsx(Text, Object.assign({ textStyle: "copy.quaternary.standard.C", color: "state.negative.A", "data-testid": "password-feedback" }, { children: formatSuggestionsMessage(feedback) }))), !attendsToLengthCriteria && (jsx(Text, Object.assign({ textStyle: "copy.quaternary.standard.C", color: "foreground.secondary.D", "data-testid": "password-instructions" }, { children: "Secure your account with a unique password that doesn't include names, dates and/or repeating characters." })))] }))] }));
};

export { PasswordStrengthMeter };

import React from 'react'
import { useFlag } from '@sketch/modules-common'

import { default as TrashListNew, TrashListProps } from './TrashList'
import TrashListOld from './TrashListOld'

export default function TrashList(props: TrashListProps) {
  const isRefreshedUi = useFlag('ui-refresh-24')

  return isRefreshedUi ? (
    <TrashListNew {...props} />
  ) : (
    <TrashListOld {...props} />
  )
}

import { jsx, jsxs } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import { Portal } from 'react-portal';
import { ListboxPopover, useListboxContext } from '@reach/listbox';
import '../Breakpoint/index.js';
import { Modal } from '../Modal/index.js';
import '../ModalManager/index.js';
import { ModalBody, ModalFooter, CancelButton } from './SelectPopover.styles.js';
import { ModalTransition } from '../ModalManager/ModalTransition.js';
import { useBreakpoint } from '../Breakpoint/Breakpoint.js';

const MobileSelectPopover = props => {
    const { children, isExpanded } = props;
    // Reach doesn't seem to like the modal opening so
    // we need to trick it into opening
    const [expanded, setExpanded] = useState(isExpanded);
    // When it expands it opens the modal
    // but the closing is done by when the ModalBody is clicked
    useEffect(() => {
        setExpanded(isExpanded);
    }, [isExpanded]);
    const handleClose = () => {
        setExpanded(false);
    };
    return (jsx(Portal, { children: jsx(ModalTransition, Object.assign({ show: expanded }, { children: jsxs(Modal, Object.assign({ onCancel: handleClose }, { children: [jsx(ModalBody, Object.assign({ onClick: handleClose }, { children: jsx(ListboxPopover, Object.assign({ className: "cleanListBox", portal: false }, { children: children })) })), jsx(ModalFooter, { children: jsx(CancelButton, Object.assign({ variant: "tertiary", small: true, onClick: handleClose, fill: true }, { children: "Cancel" })) })] })) })) }));
};
const SelectPopover = props => {
    const { className, children, position, showModalInMobile } = props;
    // Get the Listbox "isExpanded" value
    const { isExpanded } = useListboxContext();
    const isDesktop = useBreakpoint('sm');
    const showDesktopModal = isDesktop || !showModalInMobile;
    if (showDesktopModal) {
        return (jsx(ListboxPopover, Object.assign({ className: className, position: position }, { children: children })));
    }
    return (jsx(MobileSelectPopover, Object.assign({ isExpanded: isExpanded }, { children: children })));
};

export { SelectPopover };

import { jsx } from 'react/jsx-runtime';
import React from 'react';
import styled, { css } from 'styled-components';
import '../Navbar/index.js';
import '../Banner/index.js';
import { Navbar } from '../Navbar/Navbar.js';
import { useBanner } from '../Banner/context/useBanner.js';

const StyledHeader = styled.header(({ theme, $hideShadow }) => css `
    width: 100%;
    position: sticky;
    top: 0;
    z-index: ${theme.zIndex[4]};

    ${$hideShadow &&
    css `
      ${Navbar} {
        box-shadow: none;
      }
    `}
  `);
const HeaderOld = React.forwardRef(function Header({ children, className }, ref) {
    const { items } = useBanner();
    return (jsx(StyledHeader, Object.assign({ className: className, ref: ref, "$hideShadow": items.length > 0 }, { children: children })));
});

export { HeaderOld };

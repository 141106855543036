import React, { useEffect } from 'react'
import { useHistory, Redirect } from 'react-router-dom'

import { useModalContext } from '@sketch/components'

import {
  routes,
  IndexLayoutOldTitle,
  IndexLayoutOldSubtitle,
  useQueryParams,
  IndexLayoutExtraProps,
  IndexLayoutContent,
  useSetUserAuthorization,
  SignInForm,
} from '@sketch/modules-common'

import { useToast } from '@sketch/toasts'
import { Confirm2FADisabledModal } from '@sketch/user'

import { Wrapper, StyledLink } from './DisableMFAView.styles'

import {
  useSignInAndDisableMfaMutation,
  GetInitialUserDocument,
} from '@sketch/gql-types'

export interface DisableMFAViewProps extends IndexLayoutExtraProps {}
/**
 * Part of the Disable 2Fa Flow, this component
 * renders a confirmation page to disable 2FA
 */
const DisableMFAView = (props: DisableMFAViewProps) => {
  const { useOverrideLayoutProps } = props
  const history = useHistory()
  const { showToast } = useToast()
  const { showModal } = useModalContext()
  const { token } = useQueryParams<'PERSONAL_SETTINGS_DISABLE_MFA'>()

  const setUserAuthorization = useSetUserAuthorization()

  const [signInAndDisableMfa, { loading }] = useSignInAndDisableMfaMutation({
    refetchQueries: () => [
      {
        query: GetInitialUserDocument,
      },
    ],
    awaitRefetchQueries: true,
    update: (_, { data }) => {
      if (!data) {
        return
      }

      setUserAuthorization(data.signInAndDisableMfa.credentials)
    },
    onCompleted: () => {
      history.push(routes.PERSONAL_SETTINGS.create({}))
      showModal(Confirm2FADisabledModal)
    },
    onError: 'show-toast',
  })

  /**
   * This page requires a token to be present in the URL
   */
  useEffect(() => {
    if (!token) {
      showToast('Something went wrong', 'negative')
    }
  }, [token, showToast])

  useOverrideLayoutProps({ variant: 'orange' })

  if (!token) {
    return <Redirect to={routes.ENTRY.create({})} push={false} />
  }

  return (
    <IndexLayoutContent center="horizontal" marginTop paddingHorizontal>
      <Wrapper>
        <IndexLayoutOldTitle>Sign in and Disable 2FA</IndexLayoutOldTitle>
        <IndexLayoutOldSubtitle>
          Enter your password to disable two-factor <br /> authentication (2FA).
        </IndexLayoutOldSubtitle>
        <SignInForm
          submitCopy="Sign In and Disable 2FA"
          isLoading={loading}
          onSubmit={values => {
            signInAndDisableMfa({
              variables: {
                email: values.email,
                password: values.password,
                disableMfaToken: token!,
              },
            })
          }}
        />
        <StyledLink
          variant="secondary"
          to={{
            pathname: routes.ENTRY.create({}),
          }}
          isUnderlined
        >
          Cancel
        </StyledLink>
      </Wrapper>
    </IndexLayoutContent>
  )
}

export default DisableMFAView

import { jsx } from 'react/jsx-runtime';
import { useRef, useMemo, useEffect } from 'react';
import { useModalContext, ModalContext } from '../ModalContext.js';

const ModalRestrictor = ({ children }) => {
    const { showModal: rootShowModal, showRestrictedModal: rootShowRestrictedModal, hideModal: rootHideModal, modal: rootModalComponent, isModalOpen, } = useModalContext();
    // Save the reference of the opened modal (in case its opened)
    const modalRef = useRef();
    const onUnmount = useRef();
    // Mimic the provider methods to make sure they match
    const methods = useMemo(() => {
        const showModal = (modal, props) => {
            modalRef.current = modal;
            rootShowModal(modal, props);
        };
        const showRestrictedModal = (modal, props) => {
            modalRef.current = modal;
            rootShowRestrictedModal(modal, props);
        };
        const hideModal = () => {
            rootHideModal();
            modalRef.current = undefined;
        };
        return {
            showModal,
            showRestrictedModal,
            hideModal,
        };
    }, [rootShowModal, rootHideModal, rootShowRestrictedModal]);
    // When this trigger unmounts check if there is any onUmount hook pending
    useEffect(() => {
        return () => {
            var _a;
            (_a = onUnmount.current) === null || _a === void 0 ? void 0 : _a.call(onUnmount);
        };
    }, []);
    useEffect(() => {
        // If a modal opens with the same component as the saved one we set the unmounting component hook
        // otherwise we clear it
        if (isModalOpen && rootModalComponent === modalRef.current) {
            onUnmount.current = () => {
                methods.hideModal();
            };
            return () => {
                onUnmount.current = undefined;
            };
        }
    }, [isModalOpen, rootModalComponent, methods]);
    const state = useMemo(() => (Object.assign(Object.assign({}, methods), { isModalOpen, modal: rootModalComponent })), [methods, isModalOpen, rootModalComponent]);
    return jsx(ModalContext.Provider, Object.assign({ value: state }, { children: children }));
};

export { ModalRestrictor };

const defaultPermissionsValues = {
    __typename: 'Permissions',
    audience: null,
    categories: null,
    roles: null,
};
const defaultLinkValues = {
    __typename: 'Link',
    icon: null,
    text: null,
    variant: null,
};
const defaultSizeValues = {
    __typename: 'Size',
    lg: null,
    md: null,
    sm: null,
    xl: null,
    xs: null,
};
const defaultSectionSubtitleValues = {
    __typename: 'SectionSubTitle',
    visibility: {
        __typename: 'Visibility',
        hiddenForFF: null,
        hideOnBreakpoint: null,
    },
};
const defaultItemMobileValues = {
    __typename: 'ItemMobile',
    description: null,
    icon: null,
};
const defaultResponsiveImageValues = {
    __typename: 'ResponsiveImage',
    alt: null,
    source: {
        __typename: 'ResponsiveSource',
        xs: null,
        sm: null,
        md: null,
        lg: null,
        xl: null,
    },
};
const defaultSingleImageValues = {
    __typename: 'SingleImage',
    alt: null,
};
const defaultVideoValues = {
    __typename: 'Video',
    standard: {
        __typename: 'VideoSource',
        backgroundColor: null,
        src: null,
        poster: null,
    },
    retina: {
        __typename: 'VideoSource',
        backgroundColor: null,
        src: null,
        poster: null,
    },
};

export { defaultItemMobileValues, defaultLinkValues, defaultPermissionsValues, defaultResponsiveImageValues, defaultSectionSubtitleValues, defaultSingleImageValues, defaultSizeValues, defaultVideoValues };

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useModalContext, Modal, Button } from '@sketch/components';
import { Title, Description } from '../RegenerateRecoveryCodesModal.styles.js';

/**
 * Renders the first step for the Regenerate Recovery Codes modal
 */
const FirstStep = ({ onNextStep }) => {
    const { hideModal } = useModalContext();
    return (jsxs(Fragment, { children: [jsxs(Modal.Body, { children: [jsx(Title, { children: "Generate New Recovery Codes?" }), jsxs(Description, { children: ["If you generate new codes the old ones will become invalid.", jsx("br", {}), " Store your new codes somewhere safe!"] })] }), jsxs(Modal.Footer, { children: [jsx(Button, Object.assign({ onClick: hideModal, size: "40" }, { children: "Cancel" })), jsx(Button, Object.assign({ variant: "primary", onClick: () => onNextStep(), size: "40" }, { children: "Generate New Codes" }))] })] }));
};

export { FirstStep };

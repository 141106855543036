import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { useLocation } from 'react-router-dom';
import '../../routes/index.js';
import { Navbar, Text, LinkButton, Link } from '@sketch/components';
import { Header, LogoWrapper } from './IndexLayout.styles.js';
import { useFlag } from '../../flags.js';
import { routes } from '../../routes/routes.js';

const headerLinkProps = {
    'sign-up': {
        link: routes.SIGN_UP.create({}),
        text: 'New to Sketch?',
        buttonCopy: 'Create an Account',
    },
    'sign-in': {
        link: routes.SIGN_IN.create({}),
        text: 'Already have an account?',
        buttonCopy: 'Sign In',
    },
    entry: {
        link: routes.ENTRY.create({}),
        text: null,
        buttonCopy: 'Back',
    },
    'workspace-settings': {
        link: routes.WORKSPACE_AGNOSTIC_SETTINGS_PEOPLE.create({}),
        text: null,
        buttonCopy: 'Back to Workspace',
    },
    'back-sign-in': {
        link: routes.SIGN_IN.create({}),
        text: null,
        buttonCopy: 'Back to Sign In',
    },
};
const HeaderLink = ({ headerLink }) => {
    const { state } = useLocation();
    if (headerLink === 'none-with-border') {
        return jsx(Fragment, {});
    }
    return (jsxs(Navbar.Section, Object.assign({ align: "start", ml: 2 }, { children: [headerLinkProps[headerLink].text && (jsx(Text, Object.assign({ textStyle: "copy.secondary.standard.D", mr: 4 }, { children: headerLinkProps[headerLink].text }))), jsx(LinkButton, Object.assign({ variant: "secondary", size: "32", to: {
                    pathname: headerLinkProps[headerLink].link,
                    state,
                } }, { children: headerLinkProps[headerLink].buttonCopy }))] })));
};
const IndexLayoutHeader = ({ headerLink, blockLogoLink, isCreatingWorkspace, }) => {
    const isRefreshedUi = useFlag('ui-refresh-24');
    const noBorder = isRefreshedUi && isCreatingWorkspace;
    return (jsxs(Header, Object.assign({ hasLink: headerLink !== 'none', "$noBorder": noBorder }, { children: [jsx(LogoWrapper, Object.assign({ "data-testid": "sketch-logo" }, { children: blockLogoLink ? (jsx(Navbar.Logo, { variant: "black" })) : (jsx(Link, Object.assign({ to: routes.ENTRY.create({}) }, { children: jsx(Navbar.Logo, { variant: "black" }) }))) })), headerLink && headerLink !== 'none' && (jsx(HeaderLink, { headerLink: headerLink }))] })));
};

export { HeaderLink, IndexLayoutHeader };

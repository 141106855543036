import { jsx } from 'react/jsx-runtime';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import './parsers/index.js';
import { TextWrapper } from './Components.js';
import { defaultMarkdownParser } from './parsers/defaultMarkdownParser.js';

const MarkdownBase = React.forwardRef(function MarkdownBase(props, ref) {
    const { className, children, 'data-testid': dataTestId, parser = defaultMarkdownParser, style, } = props;
    const markdown = useMemo(() => {
        return parser(children);
    }, [children, parser]);
    return (jsx(TextWrapper, Object.assign({ ref: ref, style: style, className: className, "data-testid": dataTestId }, { children: markdown })));
});
const Markdown = styled(MarkdownBase) `
  /* Prevents text for causing overflow */
  overflow-wrap: break-word;
  hyphens: auto;

  /* Prevents links for causing overflow */
  & a {
    white-space: normal;
    word-break: break-all;
  }
`;

export { Markdown, MarkdownBase };

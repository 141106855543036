import React from 'react'
import { useLocation } from 'react-router-dom'

import {
  routes,
  useFlag,
  useGetNewNotificationsCount,
  useUserProfile,
  useSignOut,
} from '@sketch/modules-common'

import { ReactComponent as KeyIcon } from '@sketch/icons/key-8'
import { ReactComponent as SignOutIcon } from '@sketch/icons/arrow-right-rectangle-16'
import { ReactComponent as PersonIcon } from '@sketch/icons/person-prism-16'
import { ReactComponent as BellIcon } from '@sketch/icons/bell-prism-16'

import { Link, useResponsiveDropdown } from '@sketch/components'

import { AppearanceMenu } from './AppearanceMenu'

import {
  DropdownHeader,
  UserName,
  StyledDropdownItemNavLink,
  StyledDropdownItem,
  Icon,
  Button,
  Avatar,
  Pill,
  DropdownUserName,
  DropdownUserEmail,
  KeyWrapper,
  ToggleWrapper,
} from './UserDropdown.styles'

import { UserFragment } from '@sketch/gql-types'

const DROPDOWN_STYLE = {
  width: '216px',
}

const formatUnreadNotifications = (unreadCounter: number) => {
  return unreadCounter > 99 ? '99+' : `${unreadCounter}`
}

interface NotificationsPillProps {
  unreadNotifications: number
}

export const NotificationsPill = ({
  unreadNotifications,
}: NotificationsPillProps) =>
  unreadNotifications > 0 ? (
    <Pill data-testid="user-unseen-notifications" variant="secondary">
      {formatUnreadNotifications(unreadNotifications)}
    </Pill>
  ) : null

interface DropdownContentProps {
  name: string
  email: string
  unreadNotifications: number
}

const DropdownContent = (props: DropdownContentProps) => {
  const { name, email, unreadNotifications } = props

  const isDarkModeToggleEnabled = useFlag('dark-mode-toggle')

  const signOut = useSignOut({
    redirectBackAfterLoginAgain: false,
    reason: 'Logout button',
  })

  return (
    <>
      <DropdownHeader>
        <DropdownUserName>{name}</DropdownUserName>
        <DropdownUserEmail>{email}</DropdownUserEmail>
      </DropdownHeader>

      <StyledDropdownItemNavLink to={routes.UPDATES.create({})}>
        <Icon as={BellIcon} /> Updates
        <NotificationsPill unreadNotifications={unreadNotifications} />
      </StyledDropdownItemNavLink>

      <StyledDropdownItemNavLink to={routes.PERSONAL_SETTINGS.create({})}>
        <Icon as={PersonIcon} /> Account
      </StyledDropdownItemNavLink>

      {isDarkModeToggleEnabled && <AppearanceMenu />}

      <StyledDropdownItem onClick={signOut}>
        <Icon as={SignOutIcon} /> Sign Out
      </StyledDropdownItem>
    </>
  )
}

interface AuthenticatedUserDropdownProps {
  className?: string
  user: UserFragment
  unreadNotifications: number
}

const AuthenticatedUserDropdown = (props: AuthenticatedUserDropdownProps) => {
  const { className, user, unreadNotifications } = props

  const { avatar, email, name } = user
  const isSuperAdmin = !!user?.isSuperadmin

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: DropdownContent,
    dropdownStyle: DROPDOWN_STYLE,
    dropdownProps: {
      email: email || '',
      name: name || '',
      unreadNotifications,
    },
    placement: 'top',
  })

  return (
    <>
      <Button className={className} data-testid="user-avatar" {...buttonProps}>
        <Avatar src={avatar!.small!} name={name!} size="24px" />
        <UserName>{user.name}</UserName>
        <NotificationsPill unreadNotifications={unreadNotifications} />
        {isSuperAdmin && (
          <KeyWrapper>
            <KeyIcon data-testid="super-admin-badge" width={8} />
          </KeyWrapper>
        )}
      </Button>
      {content}
    </>
  )
}

const UnAuthenticatedUserDropdown = () => {
  const location = useLocation()

  return (
    <Button
      as={Link}
      to={{ pathname: routes.SIGN_IN.create({}), state: { from: location } }}
    >
      <Icon as={PersonIcon} />
      <UserName>Sign in</UserName>
    </Button>
  )
}

const PreventiveUserDropdown = (
  props: Partial<AuthenticatedUserDropdownProps>
) => {
  const { user, unreadNotifications } = props

  const content = !user ? (
    <UnAuthenticatedUserDropdown />
  ) : (
    <AuthenticatedUserDropdown
      user={user}
      unreadNotifications={unreadNotifications || 0}
    />
  )

  return <ToggleWrapper>{content}</ToggleWrapper>
}

const ConnectedUserDropdown: React.FC = () => {
  const { data } = useUserProfile()
  const newNotificationsCount = useGetNewNotificationsCount({
    fetchPolicy: 'cache-only',
  })

  return (
    <PreventiveUserDropdown
      user={data?.me}
      unreadNotifications={newNotificationsCount}
    />
  )
}

export { ConnectedUserDropdown }
export default PreventiveUserDropdown

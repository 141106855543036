import React, { useEffect, useRef, useState } from 'react'
import * as yup from 'yup'
import { useApolloClient } from 'react-apollo'
import { Formik } from 'formik'
import { v1 as uuid } from 'uuid'

import { renderPreview } from './utils'
import { useEventDispatch } from '@sketch/utils'

import {
  invalidateProjectShares,
  OPTIMISTIC_COLLECTION_ID,
  removeCollectionFromProject,
  useCreateCollection,
} from 'modules/collections/operations'

import { pluralize } from '@sketch/components'

import CollectionItemGridViewEmptyState from '../CollectionItemEmptyState'
import CollectionShareDrop from '../CollectionShareDrop'

import {
  InputWrapper,
  Pencil,
  StyledInput,
  StyledSubtitleTextWrapper,
  Container,
  GridWrapper,
  GridWrapperLeft,
  GridWrapperRight,
} from './CollectionItem.styles'

// Shared
// This component shares the same util as ProjectItem
import { getButtonProps } from 'modules/shares/components/ProjectItem/utils'

// This component shares styles with DocumentItem
import {
  Name as CollectionName,
  DropdownButtonWrapper,
} from 'modules/shares/components/DocumentItem/DocumentItem.styles'

import { CollectionItemProps } from './types'

const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().trim(),
})

interface NameProps {
  name: string | null
  isEditing: boolean
  onCancel: () => void
  onSubmit: ({ name }: FormValues) => void
}

interface FormValues {
  name: string | null
}

const Name: React.FC<NameProps> = ({ name, isEditing, onCancel, onSubmit }) => {
  const inputField = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputField?.current && isEditing) {
      inputField.current.focus()
    }
  })

  const handleSubmit = () => {
    // Unfocus the input will submit the values
    inputField.current?.blur()
  }

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    // Avoid click navigation to collection
    //  - If we are editing the name, this is an optimistic collection
    //    and linking to it would cause an error
    event.preventDefault()
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const name = event.target.value

    if (!name.length) {
      onCancel()

      return
    }

    onSubmit({ name })
  }

  if (!isEditing) {
    return <CollectionName>{name}</CollectionName>
  }

  return (
    <Formik
      validationSchema={VALIDATION_SCHEMA}
      initialValues={{
        name,
      }}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange }) => {
        return (
          <InputWrapper>
            <StyledInput
              ref={inputField}
              autoFocus
              autoComplete="off"
              name="name"
              placeholder="Collection name"
              value={values.name ?? ''}
              onClick={handleClick}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={e => {
                // 'Enter' and 'Escape' will blur the input which will submit the value
                if (e.key === 'Enter') {
                  e.preventDefault()
                  handleSubmit()
                }

                if (e.key === 'Escape') {
                  e.preventDefault()
                  onCancel()
                }
              }}
            />
            <Pencil />
          </InputWrapper>
        )
      }}
    </Formik>
  )
}

export const CollectionItemGridView = (props: CollectionItemProps) => {
  const {
    className,
    renderDropdown,
    onClick,
    collection,
    projectIdentifier,
    workspaceIdentifier,
  } = props
  const cache = useApolloClient()
  const [isEditing, setIsEditing] = useState(false)
  const dispatchSharesRefresh = useEventDispatch('workspaceShareRefresh')

  const { createCollection } = useCreateCollection({
    projectIdentifier,
    workspaceIdentifier,
  })

  const previewImage = renderPreview(props, 'grid')
  const a11yProps = onClick ? getButtonProps(onClick) : {}
  const dropdownContent = renderDropdown?.()
  const documentsNumber = collection.shareCount
  // Not ideal, but using a @client field was creating a nasty bug
  // that seems to originate in Apollo:
  // https://github.com/apollographql/apollo-client/issues/4529
  const isOptimistic = collection.identifier === OPTIMISTIC_COLLECTION_ID

  useEffect(() => {
    setIsEditing(isOptimistic)
  }, [isOptimistic])

  const handleSubmit = ({ name }: FormValues) => {
    if (!name) return

    const shareIdentifiers = collection.previews.map(
      preview => preview.shareIdentifier
    )

    // Delete placeholder collection
    removeCollectionFromProject({
      cache,
      collectionIdentifier: collection.identifier,
      projectIdentifier,
    })

    createCollection({
      variables: {
        projectIdentifier,
        name,
        shareIdentifiers,
      },
      optimisticResponse: {
        __typename: 'RootMutationType',
        createCollection: {
          __typename: 'CreateCollectionResponse',
          collection: {
            ...collection,
            identifier: uuid(),
            __typename: 'Collection',
            name,
          },
        },
      },
    })

    setIsEditing(false)
  }

  const handleCancel = () => {
    removeCollectionFromProject({
      cache,
      collectionIdentifier: collection.identifier,
      projectIdentifier,
    })

    // Force a refresh of the project view if the user is currently viewing
    // it. Since we need to refetch the shares for the project after the cache
    // invalidations.
    invalidateProjectShares({ cache, projectIdentifier })
    dispatchSharesRefresh({
      workspaceIdentifier,
      projectIdentifier,
    })

    setIsEditing(false)
  }

  if (!collection.previews?.length) {
    return (
      <Container>
        <CollectionShareDrop
          collection={collection}
          projectIdentifier={projectIdentifier}
          workspaceIdentifier={workspaceIdentifier}
        >
          <GridWrapper className={className} title={collection.name}>
            <CollectionItemGridViewEmptyState
              dropdownContent={dropdownContent}
              collection={collection}
            />
          </GridWrapper>
        </CollectionShareDrop>
      </Container>
    )
  }

  const name = isOptimistic ? null : collection.name

  return (
    <Container>
      <CollectionShareDrop
        collection={collection}
        projectIdentifier={projectIdentifier}
        workspaceIdentifier={workspaceIdentifier}
      >
        <GridWrapper
          className={className}
          title={collection.name}
          {...a11yProps}
        >
          <GridWrapperLeft>{previewImage}</GridWrapperLeft>
          <GridWrapperRight>
            <Name
              name={name}
              isEditing={isEditing}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
            <StyledSubtitleTextWrapper>
              <b className="shrink">
                {`${documentsNumber} ${pluralize(
                  'Document',
                  'Documents',
                  documentsNumber
                )}`}
              </b>
            </StyledSubtitleTextWrapper>
          </GridWrapperRight>
          {dropdownContent && (
            <DropdownButtonWrapper aria-label="Document Options">
              {dropdownContent}
            </DropdownButtonWrapper>
          )}
        </GridWrapper>
      </CollectionShareDrop>
    </Container>
  )
}

import { jsx } from 'react/jsx-runtime';
import styled from 'styled-components';
import '../Button/index.js';
import { ReactComponent as ReactComponent$1 } from '@sketch/icons/eye-24';
import { ReactComponent } from '@sketch/icons/eye-strikethrough-24';
import { ButtonUnstyled } from '../Button/ButtonUnstyled.js';

const TogglePasswordButton = styled(ButtonUnstyled).attrs({
    type: 'button',
}) `
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 48px;

  svg {
    opacity: 0.55;
  }

  ${({ showPassword, theme }) => showPassword &&
    `
    background-color: ${theme.colors.sketch.F};
    border-radius: 6px;
  `}
`;
const StrikeEyeIcon = styled(ReactComponent) `
  color: ${({ theme }) => theme.colors.sketch.A};
`;
const ToggleShowPasswordButton = ({ onClick, showPassword, disabled = false, }) => {
    return (jsx(TogglePasswordButton, Object.assign({ "data-testid": "eye-icon", onClick: onClick, disabled: disabled, showPassword: showPassword }, { children: showPassword ? (jsx(StrikeEyeIcon, { width: 24, height: 24, focusable: false, "aria-hidden": true })) : (jsx(ReactComponent$1, { width: 24, height: 24, focusable: false, "aria-hidden": true })) })));
};

export { ToggleShowPasswordButton };

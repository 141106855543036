// From https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
const hash = (string) => {
    let hash = 0;
    if (string.length === 0)
        return hash;
    for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
};

export { hash };

import { __rest } from 'tslib';
import { jsx, jsxs } from 'react/jsx-runtime';
import { useContext, Fragment } from 'react';
import styled from 'styled-components';
import { useKey } from 'react-use';
import { keyWithoutModifier, shortcuts } from '@sketch/utils';
import '../Box/index.js';
import '../Button/index.js';
import '../ModalManager/index.js';
import { ReactComponent } from '@sketch/icons/circle.chevron.right';
import { Text, Flex } from '../Box/BoxSystem.js';
import { IconButton } from '../Button/IconButton.js';
import { ModalContext } from '../ModalManager/ModalContext.js';

const ChevronLeft = styled(ReactComponent) `
  transform: rotate(180deg);
`;
const PaginationControlsNumbers = styled(Text.Span).attrs({
    textStyle: 'copy.primary.standard.D',
}) `
  font-family: ${({ theme }) => theme.fonts.buttons};
  margin-top: -1px; /* HACK: vertical align with buttons */ /* stylelint-disable-line scales/space */
  font-variant-numeric: tabular-nums;
`;
const PaginationControlsButton = styled(IconButton).attrs({
    size: 20,
}) `
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  margin-left: 9px; /* stylelint-disable-line scales/space */
  margin-right: 9px; /* stylelint-disable-line scales/space */

  &:hover {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  :disabled {
    opacity: 0.5;
  }
`;
const PaginationControls = (_a) => {
    var { total, current, onClickNext, onClickPrevious, showNumbers = true, onlyKeyboardNavigation = false } = _a, props = __rest(_a, ["total", "current", "onClickNext", "onClickPrevious", "showNumbers", "onlyKeyboardNavigation"]);
    const { isModalOpen } = useContext(ModalContext);
    const disablePrevious = current <= 1 || !onClickPrevious || isModalOpen;
    const disableNext = current === total || !onClickNext || isModalOpen;
    // Keyboard shortcuts to navigate to prev/next artboard
    useKey(keyWithoutModifier(shortcuts.paginationPrevious), () => {
        if (!disablePrevious) {
            onClickPrevious();
        }
    });
    useKey(keyWithoutModifier(shortcuts.paginationNext), () => {
        if (!disableNext) {
            onClickNext();
        }
    });
    return (jsx(Fragment, { children: !onlyKeyboardNavigation && (jsxs(Flex, Object.assign({ alignItems: "center" }, props, { children: [jsx(PaginationControlsButton, { "data-testid": "pagination-previous", icon: ChevronLeft, onClick: onClickPrevious, disabled: disablePrevious }), showNumbers && (jsxs(PaginationControlsNumbers, { children: [current, "/", total] })), jsx(PaginationControlsButton, { "data-testid": "pagination-next", icon: ReactComponent, onClick: onClickNext, disabled: disableNext })] }))) }));
};

export { PaginationControls };

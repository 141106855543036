import WorkspacesIcon from '@sketch/icons/workspaces-24.svg';
import ChatBubbleIcon from '@sketch/icons/chat-bubble-16.svg';
import InterfaceIcon from '@sketch/icons/interface-24.svg';

// The Sign up page has dynamic content on the left side that is based on
//     - Marketing Campaign (CampaignSpecificSignUpView)
//     - Professional Role selected in the form [REMOVED]
//
// However, professional role field is being removed and its dynamic content.
// We are keeping the object below with its original structure because BaseSignUpView
// uses it for both this content AND the Marketing Campaign content.
const defaultContent = {
    __typename: 'SignUpContent',
    title: 'Sketch is your all-in-one design toolkit',
    tint: 'ORANGE',
    introduction: 'Sketch gives you all the tools you need for truly collaborative design. From early ideas to pixel-perfect artwork, playable prototypes and handoff, it all starts here.',
    features: [
        {
            __typename: 'SignUpFeature',
            description: 'Get access to our native Mac app and Workspaces',
            icon: {
                __typename: 'SignUpImage',
                src: InterfaceIcon,
                alt: 'interface icon',
            },
        },
        {
            __typename: 'SignUpFeature',
            description: 'Online Workspace to store and share your work',
            icon: {
                __typename: 'SignUpImage',
                src: WorkspacesIcon,
                alt: 'workspaces icon',
            },
        },
        {
            __typename: 'SignUpFeature',
            description: 'Powerful sharing and collaboration tools',
            icon: {
                __typename: 'SignUpImage',
                src: ChatBubbleIcon,
                alt: 'chat bubble icon',
            },
        },
    ],
    callToAction: 'Sign Up',
    relatedContent: null,
    heroImage: null,
};

export { defaultContent };

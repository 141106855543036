import { __rest } from 'tslib';

const convertSize = (size) => {
    const columnProps = {};
    if (!size)
        return columnProps;
    const { __typename } = size, remainingSizeProps = __rest(size, ["__typename"]);
    Object.entries(remainingSizeProps).forEach(([key, value]) => {
        columnProps[key] = value || undefined;
    });
    return columnProps;
};

export { convertSize };

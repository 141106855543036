import React, { FC } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useApolloClient } from '@apollo/react-hooks'
import produce from 'immer'

import { routes, useUserProfile, useFlag } from '@sketch/modules-common'

import { useRemoveWorkspaceMember } from '../../operations'

import { MemberCard } from '../../components/MemberCard'
import { ConfirmationDialog, ModalInjectedProps } from '@sketch/components'
import { useToast } from '@sketch/toasts'

import { getMemberEmail, getMemberName } from '../../utils'

import {
  WorkspaceMembershipFragment,
  GetWorkspacesDocument,
  GetWorkspacesQuery,
  GetWorkspacesQueryVariables,
} from '@sketch/gql-types'

const StrongBreak = styled.strong`
  word-break: break-all;
`

const StyledMemberCard = styled(MemberCard)`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`
const Note = styled.p`
  padding-top: 20px;
`
interface MemberProps {
  member: WorkspaceMembershipFragment
  workspaceId: string
}

const MemberCopy: FC<Pick<MemberProps, 'member'>> = ({ member }) => {
  const memberName = getMemberName(member)
  // @TODO: Remove this FF https://github.com/sketch-hq/Cloud/issues/18785
  const hasMyDraftsRevampFFEnabled = useFlag('my-drafts-revamp')

  const firstParagraph = hasMyDraftsRevampFFEnabled ? (
    <>
      After you remove <StrongBreak>{memberName}</StrongBreak>, they won{'’'}t
      be able to view, comment or create documents in this Workspace.
    </>
  ) : (
    <>
      After you remove <StrongBreak>{memberName}</StrongBreak> they won{'’'}t be
      able to view or comment on documents in this Workspace anymore.
    </>
  )

  if (hasMyDraftsRevampFFEnabled) {
    return (
      <>
        <p>{firstParagraph}</p>
        {member.role !== 'GUEST' && (
          <p>
            If <StrongBreak>{memberName}</StrongBreak> has any drafts, they will
            be moved to a private project that any Workspace Admin can access.
          </p>
        )}
      </>
    )
  }

  return (
    <>
      <p>{firstParagraph}</p>
      {member.role !== 'GUEST' && (
        <p>
          <StrongBreak>{memberName}</StrongBreak> may still have private drafts
          in this Workspace. Make sure they back up their work before you remove
          them.
        </p>
      )}
    </>
  )
}

type RemoveWorkspaceMemberModalProps = MemberProps & ModalInjectedProps

export const RemoveWorkspaceMemberModal: FC<RemoveWorkspaceMemberModalProps> = props => {
  const {
    member,
    hideModal,
    isModalOpen,
    workspaceId: currentWorkspaceId,
  } = props
  const { showToast } = useToast()
  const history = useHistory()
  const { data: profile } = useUserProfile()
  const { cache } = useApolloClient()
  // @TODO: Remove this FF https://github.com/sketch-hq/Cloud/issues/18785
  const hasMyDraftsRevampFFEnabled = useFlag('my-drafts-revamp')

  const currentLoggedUserId = profile?.me.identifier
  const memberId = member?.user?.identifier
  const memberName = member.user?.name
  const memberEmail = getMemberEmail(member)

  const [removeMember, { loading }] = useRemoveWorkspaceMember({
    variables: { membershipId: member.identifier },
    onError: message => showToast(message, 'negative'),
    onCompleted: () => {
      if (currentLoggedUserId === memberId) {
        // The user is removing himself from the Workspace
        // First we remove current Workspace from cache
        const currentWorkspacesInState = cache.readQuery<
          GetWorkspacesQuery,
          GetWorkspacesQueryVariables
        >({
          query: GetWorkspacesDocument,
        })

        const newWorkspaces = produce(currentWorkspacesInState, draft => {
          if (currentWorkspacesInState?.me.workspaces) {
            const nextAvailableWorkspaces = currentWorkspacesInState?.me.workspaces.filter(
              workspace => workspace.identifier !== currentWorkspaceId
            )

            draft!.me.workspaces = nextAvailableWorkspaces
          }
        }) as GetWorkspacesQuery

        cache.writeQuery<GetWorkspacesQuery, GetWorkspacesQueryVariables>({
          query: GetWorkspacesDocument,
          data: newWorkspaces,
        })

        const currentWorkspaceName = currentWorkspacesInState?.me.workspaces.filter(
          workspace => workspace.identifier === currentWorkspaceId
        )[0].name

        // Then we redirect him to the entry point of the app
        showToast(`You left the "${currentWorkspaceName}" Workspace`)
        history.push(routes.ENTRY.create({}))
        hideModal()
        return
      }

      showToast(`${getMemberName(member)} was removed from this workspace`)
      hideModal()
    },
  })

  const note = hasMyDraftsRevampFFEnabled ? (
    <Note>
      After removing this Member you can assign their
      {member.isEditor ? ' unused Editor Seat ' : ' role '} to someone else.
    </Note>
  ) : (
    <Note>
      After removing this Member you can assign their role to somebody else.
    </Note>
  )

  return (
    <ConfirmationDialog
      title="Remove this Member?"
      hideModal={hideModal}
      isModalOpen={isModalOpen}
      onConfirm={removeMember}
      confirmButton={{
        text: 'Remove',
        loading,
        variant: 'negative-secondary',
      }}
    >
      <>
        <MemberCopy member={member} />
        <MemberCard.BackgroundWrapper>
          <StyledMemberCard
            name={memberName}
            email={memberEmail!}
            avatarSrc={member?.user?.avatar?.small}
          />
        </MemberCard.BackgroundWrapper>
        {member.isEditor && note}
      </>
    </ConfirmationDialog>
  )
}

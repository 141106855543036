import '../routes/index.js';
import './elementTiming/index.js';
import { getLCP } from './getLCP.js';
import { getRouteExpectations } from './getRouteExpectations.js';
import { getMatchingRoute } from '../routes/getMatchingRoute.js';
import { getElementTiming } from './elementTiming/getElementTiming.js';
import { getCustomElementTiming } from './elementTiming/getCustomElementTiming.js';

const getPageLoadMetrics = (onMetricsUpdate, pathname) => {
    const route = getMatchingRoute(pathname);
    const routeExpectations = getRouteExpectations(route);
    const metrics = {
        route,
        routeExpectations,
        elementTiming: undefined,
        lcp: undefined,
        customElementTiming: undefined,
    };
    const getWasRedirected = () => {
        return pathname !== window.location.pathname ? 'true' : 'false';
    };
    if (routeExpectations.element) {
        getElementTiming(payload => {
            const source = 'elementTiming';
            metrics.elementTiming = {
                payload,
                wasRedirected: getWasRedirected(),
            };
            onMetricsUpdate(metrics, source);
        }, routeExpectations.element);
        getCustomElementTiming(payload => {
            const source = 'customElementTiming';
            metrics.customElementTiming = {
                payload,
                wasRedirected: getWasRedirected(),
            };
            onMetricsUpdate(metrics, source);
        }, routeExpectations.element);
    }
    getLCP(payload => {
        const source = 'lcp';
        metrics.lcp = {
            payload,
            wasRedirected: getWasRedirected(),
        };
        onMetricsUpdate(metrics, source);
    }, routeExpectations.operations);
};

export { getPageLoadMetrics };

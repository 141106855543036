import styled from 'styled-components'

import { Dropdown, SelectorButton, MenuIconButton } from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'

export const ToggleButton = styled(SelectorButton)`
  font-variant-numeric: tabular-nums;
  padding: 4px 28px 4px 8px;
  height: 32px;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  min-width: 74px;
  width: auto;
  text-align: left;
`

export const ToggleButtonWithExplicitControls = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  border-radius: 5px;

  ${ToggleButton} {
    border: none;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`

export const IconButton = styled(MenuIconButton)`
  width: 32px;
  padding: 4px;
  border-radius: 0;

  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

export const Icon = styled.svg.attrs({
  role: 'img',
})`
  && {
    width: 16px;
  }
`

export const Item = styled(Dropdown.Item)`
  display: flex;
  align-items: center;
`

export const ItemLabel = styled.span`
  margin-right: 16px;
  line-height: 1;
`

export const ItemShortcut = styled.span`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  margin-left: auto;

  @media (hover: none) and (pointer: coarse) {
    /* Hide this so mobile devices should not see it */
    display: none;
  }
`

export const FixedWrapper = styled.div`
  position: fixed;
  bottom: 59px; /* 51px mobile toolbar + 8px extra space */
  right: 8px;

  z-index: 100;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  border-radius: 6px;

  ${breakpoint('sm')`
    bottom: 8px;
    right: auto;
    left: 8px;
  `}
`

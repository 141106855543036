import styled from 'styled-components';
import '../Link/index.js';
import { Link } from '../Link/Link.js';

const ToastLink = styled(Link) `
  word-break: break-all;
  white-space: normal;
`;

export { ToastLink };

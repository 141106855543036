import styled, { css } from 'styled-components'
import { PresenceUsers as BasePresenceUsers } from 'modules/presence/components'
import BaseVersionItem, { StarWrapper } from '../VersionItem'

export const VersionItem = styled(BaseVersionItem)<{ $isStarred?: boolean }>`
  ${StarWrapper} {
    /* This was added to prevent the star from bouncing in safari bug */
    transform: translate(0, 0);
  }

  ${({ $isStarred }) =>
    !$isStarred &&
    css`
      ${StarWrapper} {
        opacity: 0;
        margin-left: -24px;
        transform: scale(0, 0);
        transition: 100ms opacity linear 50ms, 100ms margin linear 50ms,
          100ms transform linear 50ms;
      }
    `}

  &:hover {
    ${StarWrapper} {
      margin-left: 0;
      opacity: 1;
      transform: scale(1, 1);
    }
  }
`

export const SharePresenceUsers = styled(BasePresenceUsers)`
  position: relative;
  width: 100%;

  padding: 6px 12px 7px 20px; /* stylelint-disable-line scales/space */
  line-height: 1;
  justify-content: space-between;

  font-size: 0.8125rem; /* 13px */

  ::before {
    content: '';
    display: inline-block;
    width: 8px;

    position: absolute;
    top: 16px;
    left: 0px;

    margin-right: 12px;

    vertical-align: top;
    border-top: 1px solid ${({ theme }) => theme.colors.border.B};
  }
`

export const Wrapper = styled.div`
  max-height: 100%;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`

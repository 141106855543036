import { jsxs, jsx } from 'react/jsx-runtime';
import React from 'react';
import { Wrapper, AdditionalText, AvatarWrapper, AvatarItemWrapper } from './AvatarStack.styles.js';

const MAXIMUM_AVATAR_COUNT = 3;
const AvatarStack = React.forwardRef(function AvatarStack(props, ref) {
    const { className, size, totalCount, children } = props;
    const showAdditionalText = totalCount > MAXIMUM_AVATAR_COUNT;
    return (jsxs(Wrapper, Object.assign({ className: className, ref: ref, "$size": size, "$hasBackground": showAdditionalText, "aria-label": "Avatar stack" }, { children: [showAdditionalText && (jsxs(AdditionalText, { children: ["+", totalCount - 1] })), jsx(AvatarWrapper, { children: React.Children.map(children.slice(0, MAXIMUM_AVATAR_COUNT), item => (jsx(AvatarItemWrapper, { children: item }))) })] })));
});

export { AvatarStack };

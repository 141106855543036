import React from 'react'
import { SKETCH_WEBSITE } from '@sketch/env-config'
import { routes } from '@sketch/modules-common'

import { NoticeContentTemplateWithOptionalActionAndLearnMore } from '../NoticeContentTemplates'
import { WorkspaceSidebarNoticeContainer } from '../WorkspaceSidebarNoticeContainer'
import { WorkspaceNoticeComponentProps } from '../types'
import { ReactComponent as PersonalWorkspaceIcon } from '@sketch/icons/workspaces-24'
import * as S from './WorkspaceSidebarNoticePersonalWorkspace.styles'
import { canManageSubscription } from '../workspaceStatusUtils'

type WorkspaceSidebarNoticePersonalWorkspaceProps = WorkspaceNoticeComponentProps

export function WorkspaceSidebarNoticePersonalWorkspace({
  workspace,
}: WorkspaceSidebarNoticePersonalWorkspaceProps) {
  const upgradeRoute = routes.WORKSPACE_CREATE.create({
    query: { name: workspace.name, upgrade: 'true' },
  })

  // We don't care about FINANCE role for personal workspaces since they can't exist.
  const hasRoleToPerformAction = canManageSubscription(workspace.userRole)

  return (
    <WorkspaceSidebarNoticeContainer>
      <NoticeContentTemplateWithOptionalActionAndLearnMore
        action={
          hasRoleToPerformAction
            ? {
                label: 'Upgrade',
                link: { to: upgradeRoute },
              }
            : undefined
        }
        learnMoreLink={{
          href: `${SKETCH_WEBSITE}/support/switch-to-subscription/`,
          external: true,
        }}
        icon={PersonalWorkspaceIcon}
      >
        <S.ContentContainer>
          <span> We’ll soon retire legacy Personal Workspaces </span>
          <S.UpgradeParagraph>
            <S.FontWeightRegular>
              Upgrade to a full Workspace to keep using Sketch
            </S.FontWeightRegular>
          </S.UpgradeParagraph>
        </S.ContentContainer>
      </NoticeContentTemplateWithOptionalActionAndLearnMore>
    </WorkspaceSidebarNoticeContainer>
  )
}

import React from 'react'
import { useFlag } from '@sketch/modules-common'

import { ArchiveList as ArchiveListNew, ArchiveListProps } from './ArchiveList'
import { ArchiveList as ArchiveListOld } from './ArchiveListOld'

export function ArchiveList(props: ArchiveListProps) {
  const isRefreshedUi = useFlag('ui-refresh-24')

  return isRefreshedUi ? (
    <ArchiveListNew {...props} />
  ) : (
    <ArchiveListOld {...props} />
  )
}

import React, { useContext } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { DataProxy } from 'apollo-cache'

import { ToastContext } from '@sketch/toasts'

import { useDeleteProjectMutation } from '@sketch/gql-types'
import { routes } from '@sketch/modules-common'
import {
  ToastLink,
  ConfirmationDialog,
  ModalInjectedProps,
} from '@sketch/components'
import { deleteWorkspaceProject } from 'modules/projects/operations'

import { ProjectName } from './DeleteProject.styles'

import { useEventDispatch } from '@sketch/utils'

interface DeleteProjectProps extends RouteComponentProps, ModalInjectedProps {
  projectId: string
  projectName: string
  workspaceId: string
}

const DeleteProject: React.FC<DeleteProjectProps> = props => {
  const { hideModal, isModalOpen, projectId, projectName, workspaceId } = props
  const { showToast } = useContext(ToastContext)

  const dispatchTrashRefresh = useEventDispatch('workspaceTrashRefresh')
  const dispatchShareRefresh = useEventDispatch('workspaceShareRefresh')

  const handleOnComplete = async () => {
    // Force the trash view to refresh for this workspace to refresh, if visible
    // see src/modules/shares/operations/useGetTrash/useGetTrash.ts
    dispatchTrashRefresh({ workspaceIdentifier: workspaceId })

    // Force the Shares view to refresh for this workspace to refresh, if visible
    // see src/modules/shares/components/WorkspaceSharesList.tsx
    // see src/modules/projects/views/ProjectsView/ProjectsView.tsx
    dispatchShareRefresh({
      workspaceIdentifier: workspaceId,
      projectIdentifier: projectId,
    })

    showToast(
      <>
        &quot;{projectName}&quot; has been moved to{' '}
        <ToastLink
          to={routes.WORKSPACE_TRASH.create({
            workspaceId,
          })}
        >
          Trash
        </ToastLink>
      </>
    )

    hideModal()
  }

  const handleUpdate = (cache: DataProxy) => {
    deleteWorkspaceProject(cache, workspaceId, projectId)
  }

  const [deleteProject, { loading }] = useDeleteProjectMutation({
    redirectErrors: true,
    variables: { projectId },
    update: handleUpdate,
    onCompleted: handleOnComplete,
    onError: 'show-toast',
  })

  return (
    <ConfirmationDialog
      title="Delete Project?"
      hideModal={hideModal}
      isModalOpen={isModalOpen}
      onConfirm={deleteProject}
      confirmButton={{
        text: 'Delete Project',
        loading,
        disabled: loading,
      }}
    >
      Move <ProjectName>“{projectName}”</ProjectName> to Trash?
      <br />
      <br />
      All its documents will be permanently deleted after being in Trash for 90
      days. Members with access can still see it in Trash.
    </ConfirmationDialog>
  )
}

export default withRouter(DeleteProject)

import React, { useState } from 'react'
import { SelectDropdown } from '@sketch/components'
import {
  WorkspacesItem,
  WorkspaceLogo,
} from 'modules/projects/modals/MoveShareToProject/OtherWorkspacesMenu/OtherWorkspacesMenu.styles'
import { DuplicationWorkspaceFragment } from '@sketch/gql-types'

interface ChooseTargetWorkspaceDuplicationProps {
  loading: boolean
  workspaces: DuplicationWorkspaceFragment[]
  onWorkspaceSelect: (workspace: DuplicationWorkspaceFragment | null) => void
}

export function ChooseTargetWorkspaceDuplication(
  props: ChooseTargetWorkspaceDuplicationProps
) {
  const { loading, workspaces, onWorkspaceSelect } = props
  const [
    selectedWorkspace,
    setSelectedWorkspace,
  ] = useState<DuplicationWorkspaceFragment | null>(null)

  return (
    <SelectDropdown
      placeholder="Select a Workspace..."
      disabled={loading}
      items={workspaces}
      value={selectedWorkspace}
      renderItem={(workspace, { active, a11yProps }) => (
        <WorkspacesItem
          active={active}
          text={workspace?.name}
          {...a11yProps}
          icon={({ className }) => (
            <WorkspaceLogo
              className={className}
              workspaceName={workspace?.name}
              src={workspace?.avatar?.small}
              size="24px"
            />
          )}
          onClick={() => {
            setSelectedWorkspace(workspace)
            onWorkspaceSelect(workspace)
          }}
        />
      )}
      renderValueText={value => value!.name}
    />
  )
}

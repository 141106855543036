import { __rest } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import { Wrapper, Icon, ChildrenWrapper, CloseWrapper } from './Toast.styles.js';
import { ReactComponent as ReactComponent$3 } from '@sketch/icons/cross-16';
import { ReactComponent } from '@sketch/icons/information-16';
import { ReactComponent as ReactComponent$1 } from '@sketch/icons/checkmark-circle-fill-16';
import { ReactComponent as ReactComponent$2 } from '@sketch/icons/exclamation-mark-fill-16';

const testIds = {
    default: 'toast-default',
    info: 'toast-info',
    negative: 'toast-negative',
    positive: 'toast-positive',
};
const icons = {
    info: ReactComponent,
    positive: ReactComponent$1,
    negative: ReactComponent$2,
};
const Toast = (_a) => {
    var { children, id, variant, onClose } = _a, props = __rest(_a, ["children", "id", "variant", "onClose"]);
    return (jsxs(Wrapper, Object.assign({ id: id, "data-testid": testIds[variant], role: "alert" }, props, { children: [variant !== 'default' && jsx(Icon, { variant: variant, as: icons[variant] }), jsx(ChildrenWrapper, { children: children }), onClose && (jsx(CloseWrapper, Object.assign({ "data-testid": "toast-close", onClick: onClose }, { children: jsx(ReactComponent$3, { width: 16, height: 16 }) })))] })));
};

export { Toast };

import { useGetComponentsQuery, ComponentInfoFragment } from '@sketch/gql-types'

import type { GetComponent, ColorVariableParsed } from '../types'
// This is a valid use case to narrow down Component type
// eslint-disable-next-line no-restricted-imports
import { ColorVariable } from '@sketch/gql-types/expansive'

import { rgba } from 'polished'

import { useGetIdentifiers } from 'modules/shares/hooks/useGetIdentifiers'
import { useComponentsState } from 'modules/shares/components/ComponentsStateContext'

interface RgbaColor {
  red: number
  green: number
  blue: number
  alpha: number
}

const isColorVariableComponent = (
  component?: ComponentInfoFragment
): component is ColorVariable => component?.__typename === 'ColorVariable'

export const useGetColorVariables = ({ search, path }: GetComponent) => {
  const componentsState = useComponentsState()

  const {
    shareIdentifier,
    versionShortId,
    versionIdentifier,
  } = useGetIdentifiers()

  const { loading, data, fetchMore, error } = useGetComponentsQuery({
    variables: {
      shareIdentifier,
      versionShortId,
      type: 'COLOR_VARIABLE',
      search,
      path,
    },
    skip: !!(search && path),
  })

  return {
    loading,
    fetchMore,
    shareIdentifier,
    versionIdentifier,
    documentVersion: data?.share?.version?.document?.documentVersion,
    compatibilityVersion:
      data?.share?.version?.document?.coeditCompatibilityVersion,
    canEditDescriptions:
      data?.share?.version?.document?.canEditDescriptions ?? false,
    renderStatusOfLatestVersion:
      data?.share?.latestVersionOfAnyState?.document?.renderStatus ?? null,
    entries:
      data?.share?.version?.document?.components.entries
        .filter(isColorVariableComponent)
        .map(
          color =>
            ({
              ...color,
              rgbaValue: rgba(color.rgbaValue as RgbaColor),
              alpha: color.rgbaValue.alpha,
            } as ColorVariableParsed)
        ) || [],
    hasComponentManifest: data?.share?.version?.hasComponentManifest,
    after: data?.share?.version?.document?.components.meta.after,
    componentsState,
    error,
  }
}

import produce from 'immer';

function deepCacheSearch(value, additionalParameters) {
    const { predicate, callback, previousValues = [] } = additionalParameters;
    if (Array.isArray(value)) {
        value.forEach((item, index) => {
            if (predicate(item)) {
                callback(value, { index, type: 'array', previousValues });
            }
            else {
                deepCacheSearch(item, Object.assign(Object.assign({}, additionalParameters), { previousValues: [...previousValues, { value, index }] }));
            }
        });
    }
    else if (value && typeof value === 'object') {
        Object.keys(value).forEach(key => {
            const actualValue = value[key];
            if (predicate(actualValue)) {
                callback(value, { key, type: 'object', previousValues });
            }
            else {
                deepCacheSearch(actualValue, Object.assign(Object.assign({}, additionalParameters), { previousValues: [...previousValues, { value, key }] }));
            }
        });
    }
}
const broadcastChanges = apolloClient => {
    var _a, _b;
    /**
     * It appears that sometimes changes are not broadcasted
     * when we use client.restore, therefore we need somehow
     * to trigger it manually
     * see https://github.com/sketch-hq/Cloud/issues/5411
     *
     * These lines were the result of reverse engineering
     * what happens when we use client.writeQuery
     * and it works in combination
     * if just one of these two lines gets executed
     * it doesn't do anything meaningful
     */
    if ((_a = apolloClient === null || apolloClient === void 0 ? void 0 : apolloClient.cache) === null || _a === void 0 ? void 0 : _a.broadcastWatches) {
        apolloClient.cache.broadcastWatches();
    }
    if ((_b = apolloClient === null || apolloClient === void 0 ? void 0 : apolloClient.queryManager) === null || _b === void 0 ? void 0 : _b.broadcastQueries) {
        apolloClient.queryManager.broadcastQueries();
    }
};
const updateEntityFromCache = (client, entry, callback) => {
    const dataIdFromObject = (client.cache || client).config.dataIdFromObject;
    // get entry id
    const id = dataIdFromObject(entry);
    const predicate = ref => ref && ref.type === 'id' && ref.id === id;
    // delete all entry references from cache
    const newCache = produce(Object.assign({}, client.extract()), cacheData => {
        deepCacheSearch(cacheData, {
            predicate,
            callback,
        });
    });
    client.restore(newCache);
    broadcastChanges(client);
};
const deleteEntityFromCache = (cache, entry) => {
    const dataIdFromObject = (cache.cache || cache).config.dataIdFromObject;
    // get entry id
    const id = dataIdFromObject(entry);
    const callback = (value, { type, index, key }) => {
        if (type === 'array') {
            value.splice(index, 1);
        }
        else if (type === 'object') {
            delete value[key];
        }
    };
    updateEntityFromCache(cache, entry, callback);
    // delete entry from cache (and trigger UI refresh)
    cache.data.delete(id);
};

export { broadcastChanges, deleteEntityFromCache, updateEntityFromCache };

import styled from 'styled-components'
import { Text } from '@sketch/components'
import { ReactComponent as Document } from '@sketch/icons/document-64'

export const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 16px;
  background-color: ${({ theme }) => theme.colors.background.secondary.A};
`

export const Copy = styled(Text).attrs({
  textStyle: 'copy.quaternary.standard.D',
})`
  margin-top: 16px;
  text-align: center;
  white-space: break-spaces;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`

export const DocumentProcessing = styled(Document)`
  width: 64px;
  height: 64px;
  /* different colour + opacity hack while we don't have this icon with separate paths */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.3;
`

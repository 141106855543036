import { VersionListItemFragment } from '@sketch/gql-types'

export const isNewStarMoreRecent = (
  latestStar: VersionListItemFragment | undefined,
  newStarCreatedAt: string
) => {
  // if there's no previous star,
  // then this new save we're trying to promote should always take priority
  if (latestStar === undefined) {
    return true
  }

  return (
    new Date(latestStar.createdAt).getTime() <
    new Date(newStarCreatedAt).getTime()
  )
}

export const isSketchVersionGreaterThan = (a: string, b: string) =>
  a.localeCompare(b, undefined, { numeric: true }) === 1

import React from 'react'

import { Dropdown, useResponsiveDropdown } from '@sketch/components'
import { useTrackEvent } from '@sketch/modules-common'

// Usage of PublicationPeriod_Local and PublicationSortOrder will not break the build
// eslint-disable-next-line no-restricted-imports
import {
  PublicationDocumentType,
  PublicationPeriod_Local,
  PublicationSortOrder,
} from '@sketch/gql-types/expansive'

import {
  DOCUMENT_TYPE_LABEL,
  DOCUMENT_TYPE_ORDER,
  PERIOD_LABEL,
  PERIOD_ORDER,
  SORT_LABEL,
  SORT_ORDER,
} from '../../constants/modifiers'

import { RadioButton, Selector } from './CommunityModifier.styles'

type CommunityPeriodModifierProps = {
  activeValue: PublicationPeriod_Local
  setValue: (value: PublicationPeriod_Local) => void
  modifier: 'PERIOD'
}
type CommunitySortModifierProps = {
  activeValue: PublicationSortOrder
  setValue: (value: PublicationSortOrder) => void
  modifier: 'SORT'
}
type CommunityDocumentTypeModifierProps = {
  activeValue: PublicationDocumentType
  setValue: (value: PublicationDocumentType) => void
  modifier: 'DOCUMENT_TYPE'
}

type CommunityModifierProps =
  | CommunityPeriodModifierProps
  | CommunitySortModifierProps
  | CommunityDocumentTypeModifierProps

type CommunitySortDropdownProps = OmitSafe<
  CommunitySortModifierProps,
  'modifier'
>
type CommunityPeriodDropdownProps = OmitSafe<
  CommunityPeriodModifierProps,
  'modifier'
>
type CommunityDocumentTypeDropdownProps = OmitSafe<
  CommunityDocumentTypeModifierProps,
  'modifier'
>

const CommunitySortDropdown = ({
  activeValue,
  setValue,
}: CommunitySortDropdownProps) => {
  const values = SORT_ORDER.map(value => {
    const label = SORT_LABEL[value]

    return (
      <RadioButton
        key={value}
        label={label}
        value={value}
        checked={value === activeValue}
        onChange={() => setValue(value)}
      />
    )
  })

  return (
    <>
      <Dropdown.Header>Sort</Dropdown.Header>
      {values}
    </>
  )
}

const CommunityPeriodDropdown = ({
  activeValue,
  setValue,
}: CommunityPeriodDropdownProps) => {
  const values = PERIOD_ORDER.map(value => {
    const label = PERIOD_LABEL[value]

    return (
      <RadioButton
        key={value}
        label={label}
        value={value}
        checked={value === activeValue}
        onChange={() => setValue(value)}
      />
    )
  })

  return (
    <>
      <Dropdown.Header>Period</Dropdown.Header>
      {values}
    </>
  )
}

const CommunityDocumentTypeDropdown = ({
  activeValue,
  setValue,
}: CommunityDocumentTypeDropdownProps) => {
  const values = DOCUMENT_TYPE_ORDER.map(value => {
    const label = DOCUMENT_TYPE_LABEL[value]

    return (
      <RadioButton
        key={value}
        label={label}
        value={value}
        checked={value === activeValue}
        onChange={() => setValue(value)}
      />
    )
  })

  return (
    <>
      <Dropdown.Header>Types</Dropdown.Header>
      {values}
    </>
  )
}

const CommunitySortModifier = (props: CommunitySortModifierProps) => {
  const [content, triggerProps] = useResponsiveDropdown({
    dropdown: CommunitySortDropdown,
    dropdownProps: {
      activeValue: props.activeValue,
      setValue: props.setValue,
    },
    placement: 'bottom-end',
  })

  return (
    <>
      <Selector
        label={SORT_LABEL[props.activeValue]}
        chevronVertical
        {...triggerProps}
      />
      {content}
    </>
  )
}

const CommunityPeriodModifier = (props: CommunityPeriodModifierProps) => {
  const [content, triggerProps] = useResponsiveDropdown({
    dropdown: CommunityPeriodDropdown,
    dropdownProps: {
      activeValue: props.activeValue,
      setValue: props.setValue,
    },
    placement: 'bottom-end',
  })

  return (
    <>
      <Selector
        label={PERIOD_LABEL[props.activeValue]}
        chevronVertical
        {...triggerProps}
      />
      {content}
    </>
  )
}

const CommunityDocumentTypeModifier = (
  props: CommunityDocumentTypeModifierProps
) => {
  const [content, triggerProps] = useResponsiveDropdown({
    dropdown: CommunityDocumentTypeDropdown,
    dropdownProps: {
      activeValue: props.activeValue,
      setValue: props.setValue,
    },
    placement: 'bottom-end',
  })

  return (
    <>
      <Selector
        label={DOCUMENT_TYPE_LABEL[props.activeValue]}
        chevronVertical
        {...triggerProps}
      />
      {content}
    </>
  )
}

const CommunityModifier = (props: CommunityModifierProps) => {
  const { trackEvent } = useTrackEvent()

  const handleSetValue = (
    selectedFilter: CommunityModifierProps['activeValue']
  ) => {
    // Analytics
    trackEvent('COMMUNITY - Filter', {
      type: `Clicks - ${props.modifier}`,
      target: selectedFilter,
    })

    if (props.modifier === 'SORT') {
      return props.setValue(
        selectedFilter as CommunitySortModifierProps['activeValue']
      )
    } else if (props.modifier === 'DOCUMENT_TYPE') {
      return props.setValue(
        selectedFilter as CommunityDocumentTypeModifierProps['activeValue']
      )
    } else {
      return props.setValue(
        selectedFilter as CommunityPeriodModifierProps['activeValue']
      )
    }
  }

  if (props.modifier === 'SORT') {
    return <CommunitySortModifier {...props} setValue={handleSetValue} />
  } else if (props.modifier === 'DOCUMENT_TYPE') {
    return <CommunityDocumentTypeModifier {...props} />
  }

  return <CommunityPeriodModifier {...props} setValue={handleSetValue} />
}

export default CommunityModifier

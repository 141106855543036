const errorPreventiveFragmentRead = (cache, readOptions) => {
    try {
        return cache.readFragment(readOptions);
    }
    catch (e) {
        return null;
    }
};

export { errorPreventiveFragmentRead as default };

import styled from 'styled-components';
import '../Box/index.js';
import { Box } from '../Box/BoxSystem.js';

const Separator = styled(Box).attrs({
    'data-testid': 'component-separator',
}) `
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.border.A};
`;

export { Separator };

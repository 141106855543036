import { jsx } from 'react/jsx-runtime';
import styled, { css } from 'styled-components';

const SsoBadge = styled.span(({ theme }) => css `
    margin-left: 8px;
    line-height: 1.2;
    font-size: ${theme.fontSizes.C};
    font-weight: ${theme.fontWeights.regular};

    a:not(.active) & {
      color: ${theme.colors.foreground.secondary.D};
    }
  `);
const SsoTag = ({ className }) => (jsx(SsoBadge, Object.assign({ className: className }, { children: "SSO" })));

export { SsoTag };

import styled, { css } from 'styled-components';
import { ButtonUnstyled } from './ButtonUnstyled.js';

const flatButtonStyles = css `
  ${({ theme: { transitions, fontWeights, fonts, colors }, small, variant = 'secondary', }) => css `
    display: inline-block;
    padding: ${small ? `4px 0.6875rem 0.3125rem` : `0.375rem 16px 8px`};

    transition: all ${transitions.duration[1]};
    text-align: center;
    white-space: nowrap;
    position: relative;
    user-select: none;
    font-size: ${small ? '0.875rem' : 'inherit'};
    line-height: 1.4;
    font-weight: ${fontWeights.medium};
    border: 1px solid transparent;

    &[disabled] {
      opacity: 0.4;
      pointer-events: none;
    }

    ${variant === 'primary' &&
    css `
      font-family: ${fonts.buttons};
      color: ${colors.sketch.B};

      &:hover,
      &:focus,
      &:active {
        color: ${colors.sketch.A};
      }
    `}

    ${variant === 'secondary' &&
    css `
      font-family: ${fonts.buttons};
      color: ${colors.foreground.secondary.B};

      &:hover,
      &:focus,
      &:active {
        color: ${colors.foreground.secondary.A};
      }
    `}


    ${variant === 'negative' &&
    css `
      font-family: ${fonts.buttons};
      color: ${colors.state.negative.B};

      &:hover,
      &:focus,
      &:active {
        color: ${colors.state.negative.A};
      }
    `}
  `}
`;
const FlatButton = styled(ButtonUnstyled) `
  ${flatButtonStyles};
`;

export { FlatButton, flatButtonStyles };

import { useState, useRef, useEffect } from 'react';

class Observable {
    constructor(initialState) {
        this.subscribe = (listener) => {
            this.listeners.push(listener);
            return () => {
                this.listeners.splice(this.listeners.indexOf(listener), 1);
            };
        };
        this.setState = (newState) => {
            this._state = newState;
            this.listeners.forEach(listener => listener(this._state));
        };
        this.state = () => {
            return this._state;
        };
        this._state = initialState;
        this.listeners = [];
    }
}
const useObservable = (store) => {
    const [state, setState] = useState(store.state());
    const stateCheck = useRef(state);
    stateCheck.current = state;
    useEffect(() => {
        const unsubscribe = store.subscribe(setState);
        // working on LayoutPortals it was noticed that there is chance that some extra code (e.g. from another component)
        // will be executed  after invoking useEffect hook itself, but before function passed to the useEffect is executed.
        //
        // And there was a case where state would change in this short gap. Therefore this extra check to make sure we are
        // dealing with the latest state
        const freshState = store.state();
        const capturedState = stateCheck.current;
        if (freshState !== capturedState) {
            setState(freshState);
        }
        return () => {
            unsubscribe();
        };
    }, [store]);
    return state;
};

export { Observable, useObservable };

import { jsx } from 'react/jsx-runtime';
import { NavbarFilter } from '../Navbar/NavbarFilter.js';
import { ReactComponent } from '@sketch/icons/logo';
import { NavbarExtendedBase, NavbarSection } from './Navbar.styles.js';
import { NavbarLogoWrapper, LogoBlack, NavbarButton, NavbarDivider, FixedWidthNavbarSection, NavbarTitle } from '../Navbar/Navbar.styles.js';

const NavbarLogo = ({ variant }) => (jsx(NavbarLogoWrapper, Object.assign({ "aria-haspopup": "menu", tabIndex: 0, role: "button" }, { children: variant === 'black' ? (jsx(LogoBlack, {})) : (jsx(ReactComponent, { width: "27px", height: "25px", style: { margin: 'auto' } })) })));
const NavbarExtended = Object.assign(NavbarExtendedBase, {
    Button: NavbarButton,
    Divider: NavbarDivider,
    Section: NavbarSection,
    FixedWidthSection: FixedWidthNavbarSection,
    Logo: NavbarLogo,
    Title: NavbarTitle,
    Filter: NavbarFilter,
});

export { NavbarExtended };

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import '../../shares/index.js';
import { SelectDropdownLinkItem } from '@sketch/components';
import { useShareDrop } from '../../shares/hooks/useShareDrop.js';

// TODO: Review react/display-name ESLint issues
//  see: https://github.com/sketch-hq/Cloud/issues/14818
// eslint-disable-next-line react/display-name
const DroppableLinkItem = forwardRef((props, ref) => {
    const { forceHighlight = false, onDropped } = props, linkProps = __rest(props, ["forceHighlight", "onDropped"]);
    const _a = useShareDrop({
        onDropped,
    }), { isDraggedOver, kind } = _a, dragEvents = __rest(_a, ["isDraggedOver", "kind"]);
    return (jsx(SelectDropdownLinkItem, Object.assign({ forceHover: isDraggedOver, forceActive: forceHighlight, ref: ref }, linkProps, dragEvents)));
});

export { DroppableLinkItem };

import { jsxs, jsx } from 'react/jsx-runtime';
import styled from 'styled-components';
import { ListItemWrapper, ListItemIcon, ListItemText, ListItemActionWrapper, SkeletonListItemIcon, SkeletonListItemText } from './List.styles.js';
export { ListHeader, ListItemIcon } from './List.styles.js';

const BaseListItem = props => {
    const { action, className, icon, children } = props;
    return (jsxs(ListItemWrapper, Object.assign({ className: className }, { children: [icon && jsx(ListItemIcon, { role: "img", as: icon }), jsx(ListItemText, { children: children }), action && jsx(ListItemActionWrapper, { children: action })] })));
};
const BaseListItemSkeleton = ({ className, icon, }) => (jsxs(ListItemWrapper, Object.assign({ className: className }, { children: [icon && jsx(SkeletonListItemIcon, { as: "span" }), jsx(SkeletonListItemText, {})] })));
const ListItem = styled(BaseListItem) ``;
const ListItemSkeleton = styled(BaseListItemSkeleton) ``;

export { ListItem, ListItemSkeleton };

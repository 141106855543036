import { useRef, useEffect } from 'react';
import { useAnalytics } from '../analytics.context.js';

/**
 * Send a tracking event the very first time value changes. If the value changes again after,
 * the tracking event will not be sent again.
 * This is typically used to send a tracking event when the user starts typing
 * in a form input field.
 */
function useTrackFirstValueChange(fieldValue, eventName, eventProps) {
    const { trackEvent } = useAnalytics();
    const alreadyTrackedRef = useRef(false);
    useEffect(() => {
        // Track only the first edit
        if (fieldValue && !alreadyTrackedRef.current) {
            if (eventProps) {
                trackEvent(eventName, eventProps);
            }
            else {
                trackEvent(eventName);
            }
            alreadyTrackedRef.current = true;
        }
    }, [fieldValue, eventName, eventProps, trackEvent]);
}

export { useTrackFirstValueChange };

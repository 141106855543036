import React from 'react'
import { roundWithLocale } from '@sketch/utils'
import {
  Section,
  Separator,
  Header,
  HeaderTitle,
  AttributeList,
  AttributeColumns,
  FullCopyAttribute,
  Color,
} from '../../components'
import copy from './copy'
import { ColorFormat, Preset, Rect } from 'modules/shares/types'
import { Text } from '@sketch/components'
import { Color as ColorType } from 'modules/inspector'

const isPresetResized = ({
  frame,
  preset,
}: {
  frame: Rect
  preset?: Preset
}) => {
  if (!preset) {
    return false
  }

  return frame.width !== preset.width || frame.height !== preset.height
}

interface ArtboardOverviewProps {
  preset?: Preset
  frame: Rect
  color?: ColorType
  onColorFormatChange: (f: ColorFormat) => void
  colorFormat: ColorFormat
}

export const ArtboardOverview: React.FC<ArtboardOverviewProps> = ({
  preset,
  frame,
  color,
  onColorFormatChange,
  colorFormat,
}) => {
  const sectionCopyValue = copy({
    artboardWidth: frame.width,
    artboardHeight: frame.height,
    color,
    colorFormat,
  })

  const template = preset?.name || 'Custom'
  const isResized = isPresetResized({ frame, preset })

  return (
    <>
      <Separator />
      <Section data-testid="inspector-sidebar-artboard-size">
        <Header copyValue={sectionCopyValue}>
          <HeaderTitle>Artboard</HeaderTitle>
        </Header>
        <AttributeColumns>
          <FullCopyAttribute
            display="block"
            label="Width"
            value={roundWithLocale(frame.width, 2)}
          />
          <FullCopyAttribute
            display="block"
            label="Height"
            value={roundWithLocale(frame.height, 2)}
          />
        </AttributeColumns>
        <AttributeList>
          <FullCopyAttribute
            label="Template"
            value={
              <div>
                {template}
                {isResized && (
                  <Text textStyle="copy.quaternary.standard.C"> (Resized)</Text>
                )}
              </div>
            }
            copyValue={template}
          />
        </AttributeList>
        {color && (
          <>
            <AttributeList>
              <Color
                label="Background"
                {...color}
                onColorFormatChange={onColorFormatChange}
                colorFormat={colorFormat}
              />
            </AttributeList>
          </>
        )}
      </Section>
    </>
  )
}

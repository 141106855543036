import { BREAKPOINTS_SORTED } from '../breakpoints.js';
import { space } from './space.js';
import * as typography from './typography.js';
import * as borders from './borders.js';
import * as index from './variants/index.js';
import { light, dark } from './colors.js';
import { shadows } from './shadows.js';
import { zIndex } from './zIndex.js';
import { transitions } from './transitions.js';

const breakpoints = BREAKPOINTS_SORTED.slice(2);
/**
 * Common theme properties
 *
 * Theme keys are based on the System UI spec. Variants are groupings of theme
 * values around specific components or site areas.
 *
 * @see https://system-ui.com/theme
 */
const common = Object.assign(Object.assign(Object.assign(Object.assign({ 
    // System UI keys
    space,
    breakpoints,
    shadows,
    transitions }, typography), borders), index), { 
    // Custom
    zIndex });
const lightTheme = Object.assign(Object.assign({}, common), light);
const darkTheme = Object.assign(Object.assign({}, common), dark);

export { darkTheme, lightTheme };

import React from 'react'

import { Flex } from '@sketch/components'

import { BillingPlanFragment } from '@sketch/gql-types'

import {
  CardWrapper,
  Cents,
  Currency,
  PlanName,
  Price,
  Unit,
  Small,
  OuterCardWrapper,
  StyledRadioButton,
} from 'modules/workspace/components/PlanPicker/components/CardProvisionNewPlans/Card.styles'
import { CurrentPlanPill } from './ChangeBillingPlanModal.styles'

// Helper functions
const calculatePrice = (value: number, output?: 'CENTS' | 'UNITS') => {
  const price = (value / 100).toFixed(2)
  const [units, cents] = `${price}`.split('.')

  if (output === 'CENTS') {
    return cents === '00' ? '' : cents
  } else if (output === 'UNITS') {
    return units
  }

  return price.replace(/\D00$/, '')
}

interface CardProps {
  isDisabled: boolean
  isSelected: boolean
  isCurrent: boolean
  onChange: (clickedPlan: BillingPlanFragment) => void
  plan: BillingPlanFragment
}

const Card: React.FC<CardProps> = ({
  isDisabled,
  isSelected,
  isCurrent,
  onChange,
  plan,
}) => {
  const priceUnit = calculatePrice(plan.invoicePrice, 'UNITS')
  const priceCents = calculatePrice(plan.invoicePrice, 'CENTS')

  const renderPlanPrice = ({ isSelected }: { isSelected: boolean }) => {
    return (
      <Price>
        <Currency isSelected={isSelected}>$</Currency>
        <Unit isSelected={isSelected}>{priceUnit}</Unit>
        {priceCents && <Cents>{priceCents}</Cents>}
      </Price>
    )
  }

  return (
    <OuterCardWrapper>
      <CardWrapper
        isSelected={isSelected}
        tabIndex={-1}
        isDisabled={isDisabled}
        aria-disabled={isDisabled}
      >
        <StyledRadioButton
          data-testid={`${plan.id}-radio-button`}
          checked={isSelected}
          disabled={isDisabled}
          name="plan"
          onChange={() => onChange(plan)}
          type="radio"
          value={plan.id}
        />

        <PlanName>{plan.type}</PlanName>
        {renderPlanPrice({ isSelected })}
        <Small isSelected={isSelected}>per Editor</Small>

        {isCurrent && <CurrentPlanPill>Current Plan</CurrentPlanPill>}
      </CardWrapper>
    </OuterCardWrapper>
  )
}

interface ChangeBillingPlanPickerProps {
  selectedPlan?: BillingPlanFragment
  currentPlan?: BillingPlanFragment
  plans: BillingPlanFragment[]
  onChange: (clickedPlan: BillingPlanFragment) => void
}

/**
 * This component is an adaptation of PlanPicker component.
 * We decided to "copy" the component because it has a few things different
 * from the original implementation and as this modal isn't the final
 * solution (we plan to have a single flow instead of this modal to change
 * the current plan) it makes no sense to spend time modifying the component
 * to make it flexible.
 * If for some reason we decided to keep this component instead of going for
 * a single flow, don't forget "merge" this component with PlanPicker.
 */
export const ChangeBillingPlanPicker: React.FC<ChangeBillingPlanPickerProps> = ({
  onChange,
  plans,
  selectedPlan,
  currentPlan,
}) => {
  return (
    <Flex>
      {plans.map(plan => (
        <Card
          key={plan.id}
          onChange={onChange}
          plan={plan}
          isSelected={plan.type === selectedPlan?.type}
          isDisabled={false}
          // We're temporaly comparing the types instead of compating
          // the ids because we have a few legacy plans.
          isCurrent={plan.type === currentPlan?.type}
        />
      ))}
    </Flex>
  )
}

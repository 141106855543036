import { jsx, Fragment } from 'react/jsx-runtime';
import { merge } from 'lodash';
import { useMemo, useEffect, useRef, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { removeItem } from '@sketch/utils';
import { DevToolsSettingsContext } from './DevToolsContext.js';
import { storeSettings, storeSettingsDebounced, readSettings } from './settings.localStorage.js';
import { mergeSettingsWithDefaultState } from './settingsState.js';
import { Subscription } from './Subscription.js';

const DevToolsSettingsProviderImpl = props => {
    const { storedState, children } = props;
    const subscription = useMemo(() => new Subscription(), []);
    // TODO: remove this after ~month of usage on dev environment
    // https://linear.app/sketch/issue/CLC-2411/remove-useeffect-removing-old-local-storage-keys
    useEffect(() => {
        removeItem('user:anonymousFlags');
        removeItem('devToolsUsageMetrics');
        removeItem('devToolsFeatureFlags');
    }, []);
    const settingsPartialRef = useRef(storedState);
    const settingsRef = useRef(mergeSettingsWithDefaultState(storedState));
    const setSettings = useCallback((value, options) => {
        var _a, _b;
        const newPartialState = merge({}, settingsPartialRef.current, value);
        // we want to replace flags overrides object as a whole
        // as otherwise we couldn't remove properties from the state
        if (((_a = value.featureFlags) === null || _a === void 0 ? void 0 : _a.overrides) !== undefined) {
            newPartialState.featureFlags.overrides = (_b = value.featureFlags) === null || _b === void 0 ? void 0 : _b.overrides;
        }
        settingsPartialRef.current = newPartialState;
        const newFullState = mergeSettingsWithDefaultState(newPartialState);
        settingsRef.current = newFullState;
        subscription.notify();
        if (options === null || options === void 0 ? void 0 : options.immediately) {
            storeSettings(newPartialState);
        }
        else {
            storeSettingsDebounced(newPartialState);
        }
    }, [subscription]);
    return (jsx(DevToolsSettingsContext.Provider, Object.assign({ value: { setSettings, settings: settingsRef, subscription } }, { children: children })));
};
const useDevToolsUrlParam = () => {
    const { search } = useLocation();
    const { replace } = useHistory();
    const shouldDevToolsBeTurnedOn = useMemo(() => {
        if (!search.includes('dev-tools')) {
            return false;
        }
        const params = new URLSearchParams(search);
        const isDevToolsParamEnabled = params.get('dev-tools');
        if (isDevToolsParamEnabled === 'true') {
            storeSettings({ general: { isTurnedOn: true } });
            return true;
        }
        else {
            storeSettings(undefined);
        }
        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (search.includes('dev-tools')) {
            // Remove "dev-tools" query params from URL
            replace({
                search: '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { shouldDevToolsBeTurnedOn };
};
const DevToolsSettingsProviderTurnedOnGuard = props => {
    var _a;
    const { children } = props;
    const { shouldDevToolsBeTurnedOn } = useDevToolsUrlParam();
    const storedState = useMemo(() => readSettings(), []);
    if (shouldDevToolsBeTurnedOn) {
        storedState.general.isTurnedOn = true;
    }
    if (((_a = storedState.general) === null || _a === void 0 ? void 0 : _a.isTurnedOn) !== true) {
        return jsx(Fragment, { children: children });
    }
    return (jsx(DevToolsSettingsProviderImpl, Object.assign({ storedState: storedState }, { children: children })));
};
const DevToolsSettingsProviderEnvGuard = props => {
    const { children } = props;
    if (!(process.env.REACT_APP_ENV === 'dev' ||
        process.env.REACT_APP_ENV === 'test')) {
        return jsx(Fragment, { children: children });
    }
    return (jsx(DevToolsSettingsProviderTurnedOnGuard, { children: children }));
};
const DevToolsSettingsProvider = DevToolsSettingsProviderEnvGuard;

export { DevToolsSettingsProvider, DevToolsSettingsProviderEnvGuard, DevToolsSettingsProviderImpl, DevToolsSettingsProviderTurnedOnGuard };

const castError = (maybeError) => {
    if (maybeError instanceof Error)
        return maybeError;
    if (!maybeError)
        return new Error('Unknown empty error');
    if (typeof maybeError !== 'object')
        return new Error('Unknown error');
    const message = maybeError === null || maybeError === void 0 ? void 0 : maybeError.message;
    const name = maybeError === null || maybeError === void 0 ? void 0 : maybeError.name;
    if (typeof message === 'string') {
        if (typeof name === 'string') {
            return maybeError;
        }
        return new Error(message);
    }
    return new Error('Unknown error');
};

export { castError };

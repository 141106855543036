import { __rest } from 'tslib';
import { jsxs } from 'react/jsx-runtime';
import { ExternalLink, InternalLink } from './Link.styles.js';

/**
 * A Link component that renders a Prism styled 'react-router-dom' Link.
 *
 * This component extends 'react-router-dom' Link component.
 *
 * Variants:
 *
 *  - 'primary' -> Beige link
 *  - 'secondary' -> Black link
 *  - 'tertiary' -> Grey link
 *
 * Examples:
 *
 * Internal Link
 *
 * ```jsx
 * <Link
 *    to={...}
 *    variant="secondary"
 *    isUnderlined
 * />
 * ```
 *
 * External Link
 *
 * ```jsx
 * <Link
 *    href={...}
 *    variant="primary"
 *    isUnderlined
 *    external
 * />
 * ```
 */
const Link = (_a) => {
    var { variant = 'primary', isUnderlined = false, external, href, children, className, onClick, icon, size, target, 'data-testid': dataTestId } = _a, props = __rest(_a, ["variant", "isUnderlined", "external", "href", "children", "className", "onClick", "icon", "size", "target", 'data-testid']);
    if (external) {
        return (jsxs(ExternalLink, Object.assign({ "$variant": variant, "$size": size, "$isUnderlined": isUnderlined, href: href || '', className: className, rel: "noopener noreferrer", target: target ? target : '_blank', onClick: onClick, "data-testid": dataTestId }, props, { children: [icon, children] })));
    }
    return (jsxs(InternalLink, Object.assign({ "$variant": variant, "$size": size, "$isUnderlined": isUnderlined, className: className, onClick: onClick, target: target, "data-testid": dataTestId }, props, { children: [icon, children] })));
};

export { Link };

import { getItem } from '@sketch/utils';

const environments = typeof __ENVIRONMENTS__ !== 'undefined'
    ? __ENVIRONMENTS__
    : (() => {
        throw new Error('Environments are not defined, double check the build process');
    })();
const getEnvironment = (env = 'production') => {
    if (environments.main.name === env) {
        return environments.main;
    }
    const pickedEnv = (environments.extra || {})[env];
    if (pickedEnv)
        return { name: env, values: pickedEnv };
    return environments.main;
};
const getCurrentEnv = () => {
    if (process.env.REACT_APP_ENV === 'production') {
        return getEnvironment('production');
    }
    const appEnv = getItem('app_env');
    if (appEnv) {
        console.warn(`Using '${appEnv}' environment from local storage.`);
        console.warn(`Use localStorage.removeItem('app_env') to reset.`);
        return getEnvironment(appEnv);
    }
    return getEnvironment(process.env.REACT_APP_ENV);
};
const currentEnv = getCurrentEnv();
const CURRENT_ENV = currentEnv.name;
// Utility to check if the current target (backend) environment is the production environment.
const isProduction = () => CURRENT_ENV === 'production';
var env = currentEnv.values;

export { CURRENT_ENV, env as default, environments, isProduction };

import { jsx, jsxs } from 'react/jsx-runtime';
import { useModalContext, TimeAgo, Modal, Button } from '@sketch/components';
import '../../components/AccessScope/index.js';
import '../../components/Divider/index.js';
import '../DeleteTokenConfirmationModal/index.js';
import '../../components/TokenDetailsItem/index.js';
import { TokenDetailsModalBody, TokenDetailsRow, PermissionsHeading } from './TokenDetailsModal.styles.js';
import { dateFormat } from '@sketch/utils';
import DeleteTokenConfirmationModal from '../DeleteTokenConfirmationModal/DeleteTokenConfirmationModal.js';
import AccessScope, { allScopes } from '../../components/AccessScope/AccessScope.js';
import TokenDetailsItem from '../../components/TokenDetailsItem/TokenDetailsItem.js';
import Divider from '../../components/Divider/Divider.styles.js';

const localizeDate = (dateStr) => dateFormat(new Date(dateStr));
const TokenDetailsModal = props => {
    const { token, hideModal } = props;
    const { showModal } = useModalContext();
    const handleConfirmDeleteClick = () => {
        hideModal();
        showModal(DeleteTokenConfirmationModal, { token });
    };
    const permissions = allScopes.map(scope => {
        const enabled = token.accessScopes.includes(scope);
        return jsx(AccessScope, { scope: scope, enabled: enabled }, scope);
    });
    const expiresAt = token.expiresAt ? localizeDate(token.expiresAt) : 'Never';
    const lastUsedAt = token.lastUsedAt ? (jsx(TimeAgo, { date: token.lastUsedAt })) : ('Unused');
    return (jsxs(Modal, Object.assign({ title: "Token Details", onCancel: hideModal }, { children: [jsxs(TokenDetailsModalBody, { children: [jsxs(TokenDetailsRow, { children: [jsxs(TokenDetailsItem, { children: [jsx("span", { children: "DESCRIPTION" }), jsx("span", { children: token.description })] }), jsxs(TokenDetailsItem, { children: [jsx("span", { children: "CREATED ON" }), jsx("span", { children: localizeDate(token.createdAt) })] })] }), jsxs(TokenDetailsRow, { children: [jsxs(TokenDetailsItem, { children: [jsx("span", { children: "EXPIRES ON" }), jsx("span", { children: expiresAt })] }), jsxs(TokenDetailsItem, { children: [jsx("span", { children: "LAST USED" }), jsx("span", { children: lastUsedAt })] })] }), jsx(Divider, {}), jsx(PermissionsHeading, { children: "PERMISSIONS" }), permissions] }), jsxs(Modal.Footer, { children: [jsx(Button, Object.assign({ onClick: handleConfirmDeleteClick, variant: "negative-secondary" }, { children: "Delete..." })), jsx(Button, Object.assign({ onClick: hideModal }, { children: "Close" }))] })] })));
};

export { TokenDetailsModal as default };

import { useModalContext } from '@sketch/components'
import { useVersioning } from 'modules/versioning'

import SharingModal from '../../../modals/SharingModal'

export const useShareModal = () => {
  const { share } = useVersioning()
  const { showModal } = useModalContext()

  return () => {
    showModal(SharingModal, {
      identifier: share.identifier,
      name: share.name,
    })
  }
}

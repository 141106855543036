import { useCallback } from 'react'

import {
  useSetZoom,
  useZoom,
  useZoomToFit,
} from '@sketch-hq/sketch-web-renderer'

import { ZoomTypes } from 'components/Zoom'

/**
 * Handles interop between the zoom API exposed by the Web Renderer and the
 * ZoomTypes used by the Zoom component.
 */
export const useCanvasZoom = () => {
  /**
   * @TODO This conversion of the zoom value to two decimal places is ugly, but
   * required due to the float comparison inside the generic Zoom.tsx component.
   * We can remove it when we move to a specialized Zoom control for the Canvas.
   * @see https://github.com/sketch-hq/Cloud/issues/11188
   */
  const zoom = +(useZoom() ?? 1).toFixed(2)
  const setCanvasZoom = useSetZoom()
  const zoomToFit = useZoomToFit()

  const setZoom = useCallback(
    (zoom: ZoomTypes) => {
      zoom.type === 'ZOOM_TO_FIT'
        ? zoomToFit()
        : setCanvasZoom(zoom.factor ?? 1)
    },
    [setCanvasZoom, zoomToFit]
  )

  return [{ type: 'ZOOM_FACTOR', factor: zoom }, setZoom] as const
}

import styled from 'styled-components';

const RowIcon = styled.div `
  width: 32px;
  height: 32px;
  border-radius: ${({ theme }) => theme.radii.medium};
  background-color: ${({ theme }) => theme.colors.background.tertiary.B};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }
`;

export { RowIcon };

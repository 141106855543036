import styled, { css } from 'styled-components';
import { ReactComponent } from '@sketch/icons/magnifying-glass-24';
import { ReactComponent as ReactComponent$1 } from '@sketch/icons/cross-circle-18';
import { breakpoint } from '@sketch/global-styles';
import '../Button/index.js';
import '../Input/index.js';
import { ButtonUnstyled } from '../Button/ButtonUnstyled.js';
import { Input } from '../Input/Input.js';

const Wrapper = styled.div `
  display: flex;
  gap: 8px;
`;
const iconStyles = css `
  cursor: pointer;
  color: ${({ theme }) => theme.colors.foreground.secondary.B};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  &:active {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }
`;
const LoupeIconContainer = styled(ButtonUnstyled) `
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  z-index: 1;

  width: 24px;
  height: 24px;

  margin: auto;
`;
const LoupeIcon = styled(ReactComponent).attrs({
    role: 'img',
    'aria-label': 'Search',
})(({ theme }) => css `
    width: 24px;
    height: 24px;

    transition: all
      ${`${theme.transitions.duration[2]} ${theme.transitions.timing.easeInOut}`};

    ${iconStyles};

    color: ${({ theme }) => theme.colors.foreground.secondary.D};
  `);
const ClearButton = styled(ButtonUnstyled) `
  width: 24px;
  height: 24px;
  margin: 4px 8px;
`;
const CrossIcon = styled(ReactComponent$1).attrs({
    role: 'img',
    'aria-label': 'Clear',
})(({ theme }) => css `
    width: 16px;
    height: 16px;
    margin: 0 -3px 0 8px; /* stylelint-disable-line scales/space */

    ${iconStyles}
  `);
const SearchInput = styled(Input)(({ theme }) => css `
    height: 40px;

    ${breakpoint('sm') `
      height: 32px;
    `}

    /* &[type='search'] is needed because inputs already styles that selector,
    so we need to overwrite them */
    &[type='search'] {
      font-size: ${theme.fontSizes.D};
      border-radius: ${theme.radii.large};
      border: 1px solid ${theme.colors.border.B};
      padding-left: 40px;
      padding-right: 40px;
      background: ${theme.colors.background.secondary.A};

      ::-webkit-search-decoration,
      ::-webkit-search-cancel-button {
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-appearance: none;
        appearance: none;
      }

      &::placeholder {
        color: ${theme.colors.state.disabled};
      }

      &:disabled {
        border: 1px dashed ${theme.colors.border.A};
        background: ${theme.colors.background.secondary.B};
      }

      opacity: 1;
      width: 100%;
    }
  `);
const Form = styled.form(({ theme, width }) => css `
    width: 100%;
    border-radius: ${theme.radii.large};

    transition: all
      ${`${theme.transitions.duration[2]} ${theme.transitions.timing.easeInOut}`};

    ${typeof width !== 'undefined' &&
    breakpoint('sm') `
      width: ${width}px;
    `};
  `);
const ClearButtonAndChildrenWrapper = styled.div `
  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const Container = styled.div `
  position: relative;
  max-width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '280px')};
  height: 40px;

  ${breakpoint('sm') `
    height: 32px;
    width: 280px;
  `}
`;

export { ClearButton, ClearButtonAndChildrenWrapper, Container, CrossIcon, Form, LoupeIcon, LoupeIconContainer, SearchInput, Wrapper, iconStyles };

import { jsxs, Fragment } from 'react/jsx-runtime';
import React, { useState } from 'react';

const Tabs = ({ children, selectedTabIndex, onTabSelected, shouldSelectTab = () => true, }) => {
    const [selectedTab, setSelectedTab] = useState(selectedTabIndex || 0);
    const elements = React.Children.toArray(children);
    let tabList;
    const tabPanels = [];
    elements.forEach(element => {
        if (!element || !React.isValidElement(element))
            return null;
        if ((element === null || element === void 0 ? void 0 : element.type.tabType) === 'TabList') {
            tabList = React.cloneElement(element, {
                onTabSelected: (index) => {
                    if (!shouldSelectTab(index))
                        return;
                    setSelectedTab(index);
                    if (onTabSelected)
                        onTabSelected(index);
                },
                shouldSelectTab,
                selectedTabIndex,
            });
        }
        else if ((element === null || element === void 0 ? void 0 : element.type.tabType) === 'TabPanel') {
            tabPanels.push(React.cloneElement(element));
        }
    });
    return (jsxs(Fragment, { children: [tabList, tabPanels[selectedTab]] }));
};

export { Tabs };

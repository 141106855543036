import React from 'react'
import Helmet from 'react-helmet'
import { useParams } from 'react-router'

import { useForTablet, Navbar, SplitLayout } from '@sketch/components'
import {
  DynamicLoadingPage,
  Footer,
  GenericErrorView,
} from '@sketch/modules-common'
import { useLayoutOverrideProps, useCreateLayoutPortal } from '@sketch/utils'

import {
  Sidebar as SidebarOld,
  NoWorkspaceSidebar as NoWorkspaceSidebarOld,
} from '../SidebarOld'

import {
  SelectorSection,
  HamburgerButton,
  HamburgerIcon,
  SidebarWrapper,
  Margin,
  NavbarPortalContainer,
} from './WorkspaceSidebarLayout.styles'

import { useGetWorkspaces } from 'modules/workspace/operations'

import {
  LayoutPassDownProps,
  WorkspaceSidebarLayoutProps,
  getCurrentWorkspace,
} from '../WorkspaceSidebarLayout'

export const WorkspaceSidebarLayoutOld: React.FC<WorkspaceSidebarLayoutProps> = props => {
  const params = useParams<{ workspaceId?: string }>()

  const {
    getOverriddenProps,
    useOverrideLayoutProps,
  } = useLayoutOverrideProps<LayoutPassDownProps>()

  const {
    title = 'Workspace',
    hideFooter,
    hasNavbar = true,
    render,
    component: Component,
  } = getOverriddenProps(
    props as WorkspaceSidebarLayoutProps & LayoutPassDownProps
  )

  // Reponsiveness
  const isTabletAndBigger = useForTablet()
  const isSmallerThanTablet = !isTabletAndBigger

  const { workspaces, loading, error } = useGetWorkspaces()

  // Layout hooks
  const [NavbarPortal, setNavbarPortalContainerRef] = useCreateLayoutPortal()
  const [
    SubheaderPortal,
    setSubheaderPortalContainerRef,
  ] = useCreateLayoutPortal()

  // These 2 layout portals are only here to keep the same structure as the new WorkspaceSidebarLayout
  const [HeaderPortal] = useCreateLayoutPortal()
  const [HeaderSlimPortal] = useCreateLayoutPortal()

  if (loading) {
    return <DynamicLoadingPage />
  }

  if (error) {
    return <GenericErrorView error={error} />
  }

  const workspace = getCurrentWorkspace(params.workspaceId, workspaces)

  const childrenProps = {
    HeaderPortal,
    HeaderSlimPortal,
    NavbarPortal,
    SubheaderPortal,
    useOverrideLayoutProps,

    // "workspace!" is used to avoid ts(2345) error with render() and <Component />.
    // This will not break anything because the check is being made before
    // calling does components.
    workspace: workspace!,
  }

  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#F0F0F0" />
      </Helmet>
      <SplitLayout
        isRefreshedUi={false}
        title={title}
        header={({ setSidebarLeftOpen, isSidebarLeftOpen }) => (
          <Navbar
            isSidebarLeftOpen={isSidebarLeftOpen}
            singleContent={hasNavbar && !isSmallerThanTablet}
          >
            {isSmallerThanTablet && (
              <SelectorSection>
                <HamburgerButton
                  aria-label="Hamburger menu button"
                  onClick={() => setSidebarLeftOpen?.(true)}
                >
                  <HamburgerIcon />
                </HamburgerButton>
              </SelectorSection>
            )}

            <NavbarPortalContainer
              ref={ref => {
                setNavbarPortalContainerRef(ref)
              }}
            />
          </Navbar>
        )}
        footer={!hideFooter && <Footer />}
        sidebar={
          <SidebarWrapper>
            {!workspace || workspaces?.length === 0 ? (
              <NoWorkspaceSidebarOld />
            ) : (
              <SidebarOld workspace={workspace} />
            )}
          </SidebarWrapper>
        }
      >
        <div ref={ref => setSubheaderPortalContainerRef(ref)} />

        <Margin>
          {render && render(childrenProps)}
          {Component && <Component {...childrenProps} />}
        </Margin>
      </SplitLayout>
    </>
  )
}

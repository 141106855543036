import { errorStyles } from './consoleTheme.js';
import * as sentry from '@sentry/browser';
import throttle from 'lodash.throttle';

let verbose = false;
try {
    if (localStorage.getItem('verbose_errors') === 'true') {
        verbose = true;
    }
}
catch (_a) {
    // If can't access local storage it will keep
    // the default value of false for verbose
}
const throttleTime = 5000;
const errorsCounter = {
    ignored: 0,
    forbidden: 0,
    apollo: 0,
    batchTotal: 0,
    total: 0,
};
// Array of errors that will be sent to Sentry in a batch
const reportableErrors = [];
// Array of errors that will be used only locally
const verboseErrors = [];
const sendReportableErrors = (error) => {
    sentry.withScope(scope => {
        // Output the total number of errors in the time frame defined
        scope.setTag('throttledErrors', `${reportableErrors.length}`);
        // Add extra information regarding all the session errors count
        scope.setExtra('errorsCounter', errorsCounter);
        // Set a maximum number of 50 errors to report
        const errorsExtra = reportableErrors.slice(0, 50);
        scope.setExtra('errors', errorsExtra);
        // Capture error on Sentry
        sentry.captureException(new Error(error.reason));
        // Clear array of errors
        reportableErrors.length = 0;
        // Increase the batch total which tells us how many
        // error batches were sent to sentry in total
        errorsCounter.batchTotal++;
    });
};
const throttleReportableErrors = throttle(sendReportableErrors, throttleTime, {
    leading: true,
    trailing: true,
});
const reportError = (error) => {
    reportableErrors.push(error);
    throttleReportableErrors(error);
};
const increaseCounter = (error) => {
    errorsCounter.total++;
    switch (error.kind) {
        case 'apolloError':
            errorsCounter.apollo++;
            break;
        case 'forbiddenError':
            errorsCounter.forbidden++;
            break;
        case 'ignoredError':
            errorsCounter.ignored++;
            break;
    }
    updateReadOnlyCounter();
};
const getGroupMessageTitle = (message, maxLength = 100) => message.replaceAll('\n', ' ').substr(0, maxLength) +
    (message.length > maxLength ? '…' : '');
const trackVerboseError = (error) => {
    verboseErrors.push(error);
    increaseCounter(error);
    if (!verbose) {
        if (verboseErrors.length > 100) {
            // Remove first errors to cap number of errors
            // at 100 if verbose mode is off
            verboseErrors.splice(0, verboseErrors.length - 100);
        }
        return;
    }
    if (error.kind === 'ignoredError') {
        const title = getGroupMessageTitle(error.error.message);
        console.groupCollapsed(`[IgnoredError]: %c${title} 👇`, errorStyles);
        console.log(error.error);
        console.log(error.reason);
        console.groupEnd();
    }
    if (error.kind === 'forbiddenError') {
        console.log(`[ForbiddenError]: %c${error.reason}`, errorStyles);
    }
    if (error.kind === 'apolloError') {
        const title = getGroupMessageTitle(error.error.message);
        console.group(`[ApolloError]: %c${title} 👇`, errorStyles);
        console.log(error.error);
        console.log(error.query);
        console.log(error.variables);
        console.groupEnd();
    }
};
const shouldNeverHappenRaw = (reason) => {
    const forbiddenError = {
        kind: 'forbiddenError',
        reason,
        timestamp: Date.now(),
    };
    trackVerboseError(forbiddenError);
    reportError(forbiddenError);
    return null;
};
const shouldNeverHappen = Object.assign(shouldNeverHappenRaw, {
    invalidMutationData: (mutationName) => {
        shouldNeverHappenRaw(`Mutation "${mutationName}" should return valid data`);
    },
});
const ErrorHandler = {
    // Actions
    ignore: (error, reason) => {
        const ignoredError = {
            kind: 'ignoredError',
            error,
            reason,
            timestamp: Date.now(),
        };
        trackVerboseError(ignoredError);
    },
    shouldNeverHappen,
    apollo: (query, variables) => (error) => {
        const apolloError = {
            kind: 'apolloError',
            error,
            query,
            variables,
            timestamp: Date.now(),
        };
        trackVerboseError(apolloError);
        return null;
    },
};
function mapTimestamps(array) {
    return array.map(x => (Object.assign(Object.assign({}, x), { timestamp: new Date(x.timestamp).toISOString() })));
}
const ErrorLogger = {
    // Displays verbose value observing ErrorLogger object
    // This value is read only and can be change only by setVerbose method.
    // We are assigning this value at the top of the object in order to appear
    // first when opening browser developer tools.
    _verbose: undefined,
    // Displays throttleTime value observing ErrorLogger object
    // This value is read only and cannot be changed.
    // We are assigning this value at the top of the object in order to appear
    // first when opening browser developer tools.
    _throttleTime: undefined,
    // Display counter of how many errors of each kind occurred observing ErrorLogger object
    // This value is read only and cannot be changed.
    // We are assigning this value at the top of the object in order to appear
    // first when opening browser developer tools.
    _counter: undefined,
    // Verbose
    setVerbose: (display = !verbose) => {
        Object.defineProperty(ErrorLogger, '_verbose', {
            writable: false,
            value: display,
        });
        verbose = display;
        return display ? 'Verbose mode activated' : 'Verbose mode deactivated';
    },
    // Get errors
    get: {
        all: () => [...verboseErrors],
        ignored: () => verboseErrors.filter(x => x.kind === 'ignoredError'),
        apollo: () => verboseErrors.filter(x => x.kind === 'apolloError'),
        forbidden: () => verboseErrors.filter(x => x.kind === 'forbiddenError'),
    },
    // Log errors
    log: {
        all: () => console.table(mapTimestamps(ErrorLogger.get.all())),
        ignored: () => console.table(mapTimestamps(ErrorLogger.get.ignored())),
        apollo: () => console.table(mapTimestamps(ErrorLogger.get.apollo())),
        forbidden: () => console.table(mapTimestamps(ErrorLogger.get.forbidden())),
    },
    // Reset
    reset: () => {
        throttleReportableErrors.cancel();
        verboseErrors.length = 0;
        reportableErrors.length = 0;
        verbose = false;
        errorsCounter.total = 0;
        errorsCounter.ignored = 0;
        errorsCounter.apollo = 0;
        errorsCounter.forbidden = 0;
        errorsCounter.batchTotal = 0;
        updateReadOnlyProps();
    },
};
const updateReadOnlyField = (fieldName, value) => {
    Object.defineProperty(ErrorLogger, fieldName, {
        writable: false,
        configurable: true,
        enumerable: true,
        value,
    });
};
const updateReadOnlyCounter = () => {
    const counter = Object.assign({}, errorsCounter);
    Object.freeze(counter);
    updateReadOnlyField('_counter', counter);
};
const updateReadOnlyVerbose = () => updateReadOnlyField('_verbose', verbose);
const updateReadOnlyThrottleTime = () => updateReadOnlyField('_throttleTime', throttleTime);
const updateReadOnlyProps = () => {
    updateReadOnlyCounter();
    updateReadOnlyVerbose();
    updateReadOnlyThrottleTime();
};
updateReadOnlyProps();
// Prevent external users from using the error handling tool to dispatch Sentry messages
if (!(process.env.REACT_APP_ENV === 'production')) {
    Object.assign(ErrorLogger, { capture: ErrorHandler });
}

export { ErrorHandler, ErrorLogger };

import { useMemo } from 'react';
import { useGetInitialUserQuery } from '@sketch/gql-types';
import { defaultFlags } from '@sketch/constants';
import '../settings/index.js';
import { useDevToolsSetting } from '../settings/useDevToolsSetting.js';

const validFlagKeys = Object.keys(defaultFlags);
const formatArrayIntoObject = (flags) => flags.reduce((acc, value) => (Object.assign(Object.assign({}, acc), { [value]: true })), {});
const useBackendFlags = () => {
    var _a, _b;
    const { data } = useGetInitialUserQuery({
        fetchPolicy: 'cache-only',
    });
    const flagsKey = (_b = (_a = data === null || data === void 0 ? void 0 : data.me) === null || _a === void 0 ? void 0 : _a.featureFlags) === null || _b === void 0 ? void 0 : _b.join(',');
    // flagsData is an array and it is used as a dependency
    // to other React Hooks. However, it is possible that the
    // to this array will change between rerenders, which cause
    // React Hooks to rerun and particularly `useEffect` causes
    // infinite loop. Here we are making that reference to flagsData
    // would change only when underlying data is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const flagsData = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.me) === null || _a === void 0 ? void 0 : _a.featureFlags; }, [flagsKey]);
    const backendFlags = useMemo(() => {
        var _a;
        const featureFlags = (_a = flagsData === null || flagsData === void 0 ? void 0 : flagsData.map(f => f)) !== null && _a !== void 0 ? _a : [];
        return Object.assign(Object.assign({}, defaultFlags), formatArrayIntoObject(featureFlags));
    }, [flagsData]);
    return backendFlags;
};
const getOverrides = (baseFlags, backendFlags) => {
    const overrides = Object.fromEntries(Object.entries(baseFlags).filter(([flagKey, value]) => {
        var _a;
        if (!validFlagKeys.includes(flagKey))
            return false;
        const backendValue = (_a = backendFlags[flagKey]) !== null && _a !== void 0 ? _a : false;
        return backendValue !== value;
    }));
    return overrides;
};
const useDevToolsFlags = () => {
    const [storedFlags, setStoredFlags] = useDevToolsSetting('featureFlags.overrides');
    const [isDevToolsTurnedOn] = useDevToolsSetting('general.isTurnedOn');
    const backendFlags = useBackendFlags();
    if (!isDevToolsTurnedOn) {
        return {
            backendFlags,
            clearLocalFlagOverride: () => { },
            isOverridden: false,
            localFlagOverrides: {},
            setLocalFlagOverride: () => { },
        };
    }
    const localFlagOverrides = getOverrides(storedFlags, backendFlags);
    const setLocalFlagOverride = (flag, value) => {
        const newOverrides = getOverrides(Object.assign(Object.assign({}, storedFlags), { [flag]: value }), backendFlags);
        setStoredFlags(newOverrides);
    };
    const clearLocalFlagOverride = (flag) => {
        const newOverrides = Object.fromEntries(Object.entries(localFlagOverrides).filter(([flagKey]) => flagKey !== flag));
        setStoredFlags(newOverrides);
    };
    const isOverridden = Object.keys(localFlagOverrides).length > 0;
    return {
        localFlagOverrides: localFlagOverrides,
        setLocalFlagOverride,
        isOverridden,
        backendFlags,
        clearLocalFlagOverride,
    };
};

export { useDevToolsFlags };

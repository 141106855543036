import React from 'react'
import { Header, TitleSkeleton } from './CommunityListingHeader.styles'

const CommunityListingHeaderPlaceholder = () => (
  <Header>
    <TitleSkeleton />
  </Header>
)

export default CommunityListingHeaderPlaceholder

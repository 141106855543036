import React, { useContext } from 'react'

import { LaunchSketchAlert } from '@sketch/modules-common'
import { Tooltip, ModalContext } from '@sketch/components'

import {
  AppIcon,
  ActionButton,
  TextButtonWrapper,
} from './EditInSketchButton.styles'

export type ShareType = 'STANDARD' | 'LIBRARY' | 'TEMPLATE'
type Action = React.ComponentProps<typeof LaunchSketchAlert>['action']

export interface BaseButtonProps {
  text: string
  action: Action
  disabled?: boolean
  shareId: string
}

interface ExtendedButton
  extends Pick<BaseButtonProps, 'disabled' | 'shareId'> {}

const BaseButton = ({ text, action, disabled, shareId }: BaseButtonProps) => {
  const { showModal } = useContext(ModalContext)

  const ui = (
    <ActionButton
      size="32"
      onClick={() =>
        showModal(LaunchSketchAlert, {
          shareId,
          action,
        })
      }
      disabled={disabled}
      data-testid="edit-in-sketch"
    >
      <TextButtonWrapper>{text}</TextButtonWrapper>
      <AppIcon alt="Sketch" />
    </ActionButton>
  )

  return !disabled ? (
    ui
  ) : (
    <Tooltip
      content="Select the latest update of this document to open it in the Mac app"
      placement="bottom"
    >
      {ui}
    </Tooltip>
  )
}

const EditInSketchButton = ({ shareId, disabled }: ExtendedButton) => (
  <BaseButton
    text="Edit in"
    action="openInSketch"
    disabled={disabled}
    shareId={shareId}
  />
)

const OpenTemplateButton = ({ shareId, disabled }: ExtendedButton) => (
  <BaseButton
    text="New from Template"
    action="newFromTemplate"
    disabled={disabled}
    shareId={shareId}
  />
)

export { EditInSketchButton, OpenTemplateButton, BaseButton as default }

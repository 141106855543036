import produce from 'immer';
import { broadcastChanges } from './apolloCache.js';

const removeQuery = (client, scopeValidator) => {
    // DataProxy doesn't expose the extract and restore methods that's why we force the type
    const untypedClient = client;
    // delete all entry references from cache using the scopeValidator
    const newCache = produce(Object.assign({}, untypedClient.extract()), (cacheData) => {
        const cacheKeys = Object.keys(cacheData);
        cacheKeys.forEach(key => {
            if (scopeValidator(key, cacheData[key])) {
                delete cacheData[key];
            }
        });
    });
    untypedClient.restore(newCache);
    broadcastChanges(untypedClient);
};

export { removeQuery as default };

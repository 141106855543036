import React from 'react'

import { PublicWorkspaceFragment } from '@sketch/gql-types'
import { Navbar, NotEmbedded, IsEmbedded } from '@sketch/components'
import { SketchHomeButton, useFlag } from '@sketch/modules-common'

import { SidebarLayoutChildrenProps } from './types'
import HeaderBadge from '../HeaderBadge/HeaderBadge'
import { LayoutHeaderBackButton } from './LayoutHeaderBackButton'

interface LayoutHeaderProps
  extends Pick<SidebarLayoutChildrenProps, 'setSidebarLeftOpen'> {
  workspace: PublicWorkspaceFragment
  hideHeaderBadge?: boolean
  hideBackButton?: boolean
  children: React.ReactNode
}

export const LayoutHeader = ({
  workspace,
  setSidebarLeftOpen,
  hideHeaderBadge,
  hideBackButton,
  children,
}: LayoutHeaderProps) => {
  const isRefreshedUi = useFlag('ui-refresh-24')

  return (
    <Navbar
      $isRefreshedUi={isRefreshedUi}
      data-testid="document-sidebar-layout-header"
      isDocumentView
    >
      <NotEmbedded>
        {!hideHeaderBadge && (
          <HeaderBadge
            workspace={workspace}
            setSidebarLeftOpen={setSidebarLeftOpen}
          />
        )}

        {!hideBackButton && <LayoutHeaderBackButton />}

        {children}
      </NotEmbedded>

      <IsEmbedded>
        {!hideHeaderBadge && <SketchHomeButton />}

        {!hideBackButton && <LayoutHeaderBackButton />}

        {children}
      </IsEmbedded>
    </Navbar>
  )
}

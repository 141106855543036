import styled from 'styled-components';
import { skeletonFadeStyles } from '@sketch/components';

const Wrapper = styled.div `
  position: relative;
`;
const Picture = styled.picture `
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
`;
const Placeholder = styled.div `
  width: 100%;
  height: 100%;

  ${skeletonFadeStyles};
`;
const ImageContainer = styled.img `
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export { ImageContainer, Picture, Placeholder, Wrapper };

import { __rest } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import styled, { css } from 'styled-components';
import '../Button/index.js';
import { Dropdown } from './Dropdown.js';
import { ReactComponent } from '@sketch/icons/dots-3-horizontal-24';
import { Button } from '../Button/Button.js';

const DropdownButton = styled(Button).attrs({
    variant: 'secondary',
    size: '32',
}) `
  background-clip: padding-box;
  padding: 4px 8px;

  opacity: 0;
  transform: translate3d(0, -5px, 0);

  transition: ${({ theme }) => `
    opacity ${theme.transitions.duration[0]} ${theme.transitions.timing.easeInOut},
    transform ${theme.transitions.duration[0]} ${theme.transitions.timing.easeInOut};
  `};
`;
const showDropdownButtonStyles = css `
  ${DropdownButton} {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
const DropdownButtonWrapper = styled.div `
  position: absolute;

  top: 9px;
  right: 9px;
  z-index: 1;

  /* Prevent the button from hiding when the dropdown is open */
  [aria-expanded='true'] {
    ${showDropdownButtonStyles};
  }

  :focus-within {
    ${showDropdownButtonStyles};
  }
`;
const EllipsisIcon = styled(ReactComponent) `
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`;
const handleDropdownWrapperClick = (event) => {
    /* Work-around to prevent Dropdown items from triggering any parent link */
    event.stopPropagation();
};
const showDropdownStyles = css `
  @media (hover: none) {
    ${showDropdownButtonStyles}
  }

  &:hover,
  &:focus,
  &:focus-within {
    ${showDropdownButtonStyles}
  }
`;
const CardDropdown = props => {
    const { children, placement = 'bottom', buttonLabel = 'Card Settings', toggle = (jsxs(DropdownButton, Object.assign({ "aria-label": buttonLabel }, { children: [jsx(EllipsisIcon, {}), jsx("span", Object.assign({ className: "sr-only" }, { children: buttonLabel }))] }))) } = props, rest = __rest(props, ["children", "placement", "buttonLabel", "toggle"]);
    return (jsx(DropdownButtonWrapper, Object.assign({ onClick: handleDropdownWrapperClick }, { children: jsx(Dropdown, Object.assign({ placement: placement, toggle: toggle }, rest, { children: children })) })));
};

export { CardDropdown, DropdownButton, showDropdownStyles };

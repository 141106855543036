const { pow, round } = Math;
const scaleFrame = (factor, frame) => ({
    x: frame.x * factor,
    y: frame.y * factor,
    width: frame.width * factor,
    height: frame.height * factor,
});
/**
 * Round up to X decimal places.
 * Adapted from https://stackoverflow.com/questions/15762768/javascript-math-round-to-two-decimal-places
 */
const roundUpTo = (n, digits) => {
    let negative = false;
    if (n < 0) {
        negative = true;
        n = n * -1;
    }
    const multiplicator = pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    let result = (round(n) / multiplicator).toFixed(digits);
    if (digits > 0)
        result = result.replace(/\.?0?0?$/, '');
    return negative ? `-${result}` : result;
};
const roundWithLocale = (n, digits) => parseFloat(roundUpTo(n, digits)).toLocaleString();

export { roundUpTo, roundWithLocale, scaleFrame };

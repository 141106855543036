import { __rest } from 'tslib';
import { useMemo } from 'react';
import { ErrorHandler } from '@sketch/tracing';
import { ApolloParsedError, UserParsedError, RawParsedError } from './ParsedError.js';
import { findUserErrors } from './useErrorRedirect.js';

const getMutationResult = (originalState, redirectErrors) => {
    const { error } = originalState, rest = __rest(originalState, ["error"]);
    if (error) {
        return Object.assign(Object.assign({}, rest), { error: new ApolloParsedError(error) });
    }
    if (redirectErrors) {
        const userErrors = findUserErrors(originalState.data);
        if (userErrors.length > 0) {
            return Object.assign(Object.assign({}, rest), { error: new UserParsedError(userErrors) });
        }
    }
    return Object.assign(Object.assign({}, rest), { error: undefined });
};
const getMutateFunction = (props) => {
    const { onApolloErrorHandler, onErrorDispatch, originalMutateFn } = props;
    const mutateFn = options => {
        if (!onApolloErrorHandler) {
            // here we are allowing originalMutateFn to throw exceptions
            return originalMutateFn(options);
        }
        return new Promise(res => {
            let isResolved = false;
            const resolve = (data) => {
                res(data);
                isResolved = true;
            };
            onErrorDispatch(error => {
                if (!isResolved)
                    resolve({ error });
            });
            originalMutateFn(options).then(result => {
                if (result !== undefined) {
                    if (!isResolved)
                        resolve(result);
                    return;
                }
                if (result === undefined) {
                    // if onError handler is passed and error occurs, Apollo's useMutation return `undefined` as direct
                    // function response. Which isn't expected, nor allowed following TypeScript annotations.
                    //
                    // However, before reaching this point of the code, we have already checked that `onError` is passed and
                    // error received through the `onError` callback should be already returned as the as direct function response.
                    //
                    // However, if this does not happen (although, that should _never_ happen), we have a last resort safeguard,
                    // to ping our sentry and indicate that we have a bug in our code.
                    setTimeout(() => {
                        if (!isResolved) {
                            ErrorHandler.shouldNeverHappen('bug in useMutation proxy - incorrectly resolved direct mutation response');
                            resolve({
                                error: new RawParsedError(new Error('Something is wrong, please contact our support team')),
                            });
                        }
                    }, 500);
                }
            });
        });
    };
    return mutateFn;
};
const useFixedMutationTuple = (props) => {
    const { originalMutationTuple, onErrorDispatch, onApolloErrorHandler, redirectErrors, } = props;
    const [originalMutateFn, originalState] = originalMutationTuple;
    const mutateFn = useMemo(() => getMutateFunction({
        originalMutateFn,
        onApolloErrorHandler,
        onErrorDispatch,
    }), [onApolloErrorHandler, onErrorDispatch, originalMutateFn]);
    const state = getMutationResult(originalState, redirectErrors !== null && redirectErrors !== void 0 ? redirectErrors : false);
    return [mutateFn, state];
};

export { getMutationResult, useFixedMutationTuple };

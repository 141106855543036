import { __rest } from 'tslib';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import React, { useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { Mention } from 'react-mentions';
import { getEmojisListBySearch, getUsersListBySearch } from '@sketch/utils';
import { strippedString } from './utils.js';
import '../Breakpoint/index.js';
import '../Truncate/index.js';
import '../PersonAvatar/index.js';
import '../List/index.js';
import '../ResponsiveDropdown/index.js';
import { MentionWrapper, MentionPopoverFakeButton, MentionsInput } from './SuggestionsInput.styles.js';
import { useResponsiveDropdown } from '../ResponsiveDropdown/hooks/useResponsiveDropdown.js';
import { ListItem } from '../List/List.js';
import { PersonAvatar } from '../PersonAvatar/PersonAvatar.js';
import { Truncate } from '../Truncate/Truncate.js';
import { useBreakpoint } from '../Breakpoint/Breakpoint.js';

const POPOVER_MODIFIERS = [
    /**
     * This modifier will allow the popover to be the same
     * width as the reference element
     */
    {
        name: 'makeSameWidth',
        phase: 'beforeWrite',
        enabled: true,
        fn({ state }) {
            const { popper, reference } = state.elements || {};
            if (!(reference && reference instanceof HTMLElement)) {
                return;
            }
            const { width } = reference.getBoundingClientRect();
            popper.style.width = `${width}px`;
        },
    },
    {
        name: 'computeStyles',
        options: { gpuAcceleration: true },
    },
];
const POPOVER_OFFSET = [0, 0];
const MENTIONS_STYLE = {
    suggestions: {
        top: 'auto',
        bottom: '1.5em',
        marginTop: '0px',
        background: 'none',
        width: '100%',
        position: 'sticky',
    },
    '&multiLine': {
        control: {
            minHeight: 0,
            lineHeight: '1.25rem',
            position: 'relative',
        },
        highlighter: {
            padding: 0,
            border: '0px solid transparent',
            lineHeight: '1.25rem',
            strong: {
                fontWeight: 'bold',
            },
        },
        input: {
            marginLeft: 0,
            padding: 0,
            border: '0px solid transparent',
            outline: 'none',
            lineHeight: '1.25rem',
        },
    },
};
const MentionsPopoverWrapper = ({ children }) => (jsx(MentionWrapper, { children: children }));
const MentionsPopover = ({ children }) => {
    const [content, button, { setVisible, update }] = useResponsiveDropdown({
        dropdown: MentionsPopoverWrapper,
        dropdownProps: { children },
        usePortal: true,
        placement: 'top-start',
        modifiers: POPOVER_MODIFIERS,
        /* Force the breakpoint to always show popover, even on mobile */
        dropdownBreakpoint: 'base',
        offset: POPOVER_OFFSET,
    });
    useEffect(() => {
        setVisible === null || setVisible === void 0 ? void 0 : setVisible(true);
        update === null || update === void 0 ? void 0 : update();
    }, [setVisible, update]);
    return (jsxs(Fragment, { children: [jsx(MentionPopoverFakeButton, { ref: button.ref }), content] }));
};
const customHighlightDisplay = (display, query) => {
    const strippedDisplay = strippedString(display);
    const strippedQuery = strippedString(query);
    const i = strippedDisplay.indexOf(strippedQuery);
    if (i === -1) {
        return display;
    }
    return (jsxs(Fragment, { children: [display.substring(0, i), jsx("b", { children: display.substring(i, i + query.length) }), display.substring(i + query.length)] }));
};
function renderEmojiSuggestion(entry) {
    const Icon = (props) => (jsx("div", Object.assign({}, props, { children: entry.display })));
    return jsx(ListItem, Object.assign({ icon: React.createFactory(Icon) }, { children: entry.id }));
}
const renderUserSuggestion = (entry, search) => {
    if (!('avatar' in entry)) {
        return null;
    }
    return (jsx(ListItem, Object.assign({ icon: ({ className }) => (jsx(PersonAvatar, { className: className, size: "16px", name: entry.display || '', flavour: "image", src: entry.avatar })) }, { children: jsx(Truncate, { children: customHighlightDisplay(entry.display || '', search) }) })));
};
const SuggestionsInputUnstyled = (props) => {
    const { mentionableUsers, style } = props, otherProps = __rest(props, ["mentionableUsers", "style"]);
    const isDesktop = useBreakpoint('sm');
    const mentionCallback = useRef(null);
    const searchEmoji = useCallback((search) => {
        return getEmojisListBySearch(search.toLowerCase());
    }, []);
    const searchUser = useCallback((search) => getUsersListBySearch(mentionableUsers, search.toLowerCase()), [mentionableUsers]);
    useEffect(() => {
        if (!mentionCallback.current) {
            return;
        }
        const [search, callback] = mentionCallback.current();
        callback(searchUser(search));
    }, [searchUser, mentionableUsers]);
    const userMention = (jsx(Mention, { trigger: "@", markup: "@[__display__](user:__id__)", data: (search, callback) => {
            callback(searchUser(search));
            mentionCallback.current = () => [search, callback];
        }, displayTransform: (id, display) => `@${display}`, renderSuggestion: renderUserSuggestion, appendSpaceOnAdd: true }, "user-mention"));
    const emojiMention = (jsx(Mention, { trigger: ":", markup: "__display__", regex: /($a)/, data: searchEmoji, renderSuggestion: renderEmojiSuggestion, appendSpaceOnAdd: true }, "emoji-mention"));
    return (jsx(MentionsInput, Object.assign({ placeholder: "Write a comment...", allowSpaceInQuery: true, customSuggestionsContainer: children => (jsx(MentionsPopover, { children: children })) }, otherProps, { style: MENTIONS_STYLE }, { children: isDesktop ? [userMention, emojiMention] : [userMention] })));
};
const SuggestionsInput = styled(SuggestionsInputUnstyled) ``;

export { SuggestionsInput, renderEmojiSuggestion, renderUserSuggestion };

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import React from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { mergeRefs } from '@sketch/utils';
import '../Button/index.js';
import '../Tooltip/index.js';
import { Button } from '../Button/Button.js';
import { Tooltip } from '../Tooltip/Tooltip.js';

const IconButton = styled(Button).attrs({
    size: '32',
})(({ theme, $isHighlighted }) => css `
    padding: 4px 8px;
    box-shadow: none;
    border: none;
    position: relative;
    width: 40px;
    background-color: transparent;

    [role='img'] {
      color: ${theme.colors.foreground.secondary.A};
      opacity: 0.55;
      width: 24px;
    }

    :hover {
      [role='img'] {
        opacity: 1;
      }
    }

    & + & {
      margin: 0;
    }

    &[aria-expanded='true'],
    &[aria-current='true'],
    &.active {
      background-color: ${theme.colors.sketch.F};

      &:hover {
        background-color: ${theme.colors.sketch.F};
      }

      [role='img'] {
        color: ${theme.colors.sketch.A};
        opacity: 1;
      }
    }

    ${$isHighlighted &&
    css `
      &::after {
        content: '';
        background-color: ${theme.colors.sketch.A};
        position: absolute;
        top: 4px;
        right: 7px;
        border-radius: 50%;
        height: 8px;
        width: 8px;
        border: 1px solid ${theme.colors.background.secondary.B};
      }
    `}
  `);
const MenuIconButton = React.forwardRef(function MenuIconButton(_a, externalRef) {
    var { active, description, className } = _a, otherProps = __rest(_a, ["active", "description", "className"]);
    if (description) {
        return (jsx(Tooltip, Object.assign({ content: description, placement: "bottom", disableWhenTouchDevice: true }, { children: (_a) => {
                var { ref: tooltipRef } = _a, tooltipProps = __rest(_a, ["ref"]);
                return (jsx(IconButton, Object.assign({ className: classNames(className, active && 'active'), ref: mergeRefs([externalRef, tooltipRef]) }, otherProps, tooltipProps)));
            } })));
    }
    return (jsx(IconButton, Object.assign({ ref: externalRef, className: classNames(className, active && 'active') }, otherProps)));
});

export { MenuIconButton };

import styled, { css } from 'styled-components'

import {
  Button,
  Text,
  truncateLines,
  TableComponents,
  Image as ImageUnstyled,
  ImageBaseComponent,
} from '@sketch/components'

import { ReactComponent as EllipsisIcon } from '@sketch/icons/dots-3-horizontal-24'
import { ReactComponent as InformationIcon } from '@sketch/icons/information-outline-16'

import { breakpoint } from '@sketch/global-styles'

/* stylelint-disable no-descending-specificity */
const commonIconContainerStyles = css`
  position: absolute;

  display: flex;
  padding: 8px;

  background-color: ${({ theme }) => theme.colors.background.secondary.A};
  z-index: ${({ theme }) => theme.zIndex[1]};

  ::before,
  ::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
  }
`

export const TopLeftIcons = styled.div(
  ({ theme }) => css`
    ${commonIconContainerStyles}

    top: 0;
    left: 0;
    border-radius: 0 0 ${theme.radii.xxlarge} 0;
    box-shadow: ${theme.colors.thumbnails.shadowIcons};

    /* These are two small inverter borders to create the inverted rounded corners the designs has*/
    /* Top Right Inverted Border */
    ::before {
      top: 0;
      right: -20px;
      border-radius: ${theme.radii.xlarge} 0 0 0;
      box-shadow: 0 -10px 0 0 ${theme.colors.background.secondary.A};
    }

    /* Bottom Left Inverted Border */
    ::after {
      bottom: -20px;
      left: 0;
      border-radius: ${theme.radii.xlarge} 0 0 0;
      box-shadow: -10px 0 0 0 ${theme.colors.background.secondary.A};
    }
  `
)

export const BottomRightIcons = styled.div(
  ({ theme }) => css`
    ${commonIconContainerStyles}

    justify-content: space-evenly;
    gap: 8px;

    bottom: 0;
    right: 0;
    border-radius: ${theme.radii.xxlarge} 0 0 0;
    box-shadow: ${theme.colors.thumbnails.shadowIcons};

    /* These are two small inverter borders to create the inverted rounded corners the designs has*/
    /* Top Right Inverted Border */
    ::before {
      top: -20px;
      right: 0;
      border-radius: 0 0 ${theme.radii.xlarge} 0;
      box-shadow: 0 10px 0 0 ${theme.colors.background.secondary.A};
    }

    /* Bottom Left Inverted Border */
    ::after {
      bottom: 0;
      left: -20px;
      border-radius: 0 0 ${theme.radii.xlarge} 0;
      box-shadow: 10px 0 0 0 ${theme.colors.background.secondary.A};
    }
  `
)

export const StatusIcon = styled.svg.attrs({
  role: 'img',
})`
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;

  margin: auto;
`

export const InfoIcon = styled(InformationIcon)`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  width: 16px;
  height: 16px;
  vertical-align: text-top;
`

export const AutosaveWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

export const ImageWrapper = styled.div<{ $isRefreshedUi?: boolean }>`
  position: relative;
  overflow: hidden;
  padding: 16px;

  flex-shrink: 0;

  /* Make sure the Wrapper is a responsive rectangle  */
  :before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  :after {
    content: '';

    /*
      We can remove this whole bit when the FF is released.
      Thumbnails should not have borders in the UI Refresh
    */
    ${({ $isRefreshedUi }) =>
      !$isRefreshedUi &&
      css`
        /* Create the border inside */
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.border.A};
      `}

    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const Image = styled(ImageUnstyled)`
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const ImageElement = styled(ImageBaseComponent).attrs({
  draggable: false,
})`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;

  /* stylelint-disable font-family-no-missing-generic-family-keyword */
  font-family: 'object-fit: contain;';
`

export const Name = styled.h1<{ $isRefreshedUi?: boolean }>`
  /* Fix the default margin of the element */
  margin: 0;

  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4;
  white-space: break-spaces;
  word-break: break-word;

  ${({ $isRefreshedUi }) =>
    $isRefreshedUi &&
    css`
      text-align: center;
    `}
`

export const SubtitleTextWrapper = styled(Text.Span).attrs({
  textStyle: 'copy.quaternary.standard.C',
})<{ $isRefreshedUi?: boolean }>`
  display: inline-flex;
  align-content: center;
  gap: 8px;

  font-weight: 400;

  ${({ $isRefreshedUi }) =>
    $isRefreshedUi &&
    css`
      justify-content: center;
    `}

  b {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }
`

/**
 * Dropdown Section
 */
export const DropdownButton = styled(Button).attrs({
  variant: 'secondary',
  size: '32',
})`
  background-clip: padding-box;
  padding: 4px 8px;
`

export const DropdownButtonEllipsis = styled(EllipsisIcon)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

export const DropdownButtonWrapper = styled.div`
  position: absolute;

  top: 9px;
  right: 9px;

  opacity: 0;
  transform: translate3d(0, -5px, 0);
  transition: ${({ theme }) => `
    opacity ${theme.transitions.duration[0]} ${theme.transitions.timing.easeInOut},
    transform ${theme.transitions.duration[0]} ${theme.transitions.timing.easeInOut};
  `};

  /* Prevent the button from hiding when the dropdown is open */
  [aria-expanded='true'] ${DropdownButton} {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &:has([aria-expanded='true']),
  :focus-within {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

export const GridFocusStyles = css`
  ${DropdownButtonWrapper} {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

export const NameWrapper = styled.div<{ type?: 'table' }>`
  display: flex;
  align-items: center;
  white-space: pre-wrap;

  ${({ type }) =>
    type === 'table' &&
    css`
      & > div {
        display: flex;
        align-items: center;
      }
    `}
`

/**
 * Wrapper Section
 */
export const commonWrapper = css`
  & .shrink {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .keep-size {
    flex-shrink: 0;
  }

  & .wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  &[role='button'] {
    cursor: pointer;
  }
`
export const GridWrapper = styled.section<{ $isRefreshedUi?: boolean }>(
  ({ theme, $isRefreshedUi }) => css`
    ${commonWrapper};

    display: flex;
    flex-direction: column;

    position: relative;

    && ${ImageWrapper} {
      border-radius: ${theme.radii.xxlarge};

      background-color: ${$isRefreshedUi
        ? theme.colors.background.tertiary.D
        : theme.colors.background.secondary.B};

      /* Margin from image wrapper to the document name */
      margin-bottom: ${$isRefreshedUi
        ? 8
        : 6}px; /* stylelint-disable-line scales/space */

      ${$isRefreshedUi &&
      css`
        box-shadow: ${theme.colors.thumbnails.shadow};
        border: 4px solid ${theme.colors.background.secondary.A};
        transition: box-shadow ${theme.transitions.duration[2]}
          ${theme.transitions.timing.easeInOut};

        &:hover {
          box-shadow: ${theme.colors.thumbnails.shadowHover};
        }
      `}

      /* Maintain the radius in the inside border */
      ::after {
        border-radius: ${theme.radii.xxlarge};
      }
    }

    ${StatusIcon} {
      width: 64px;
      height: 64px;
    }

    ${Name} {
      margin-bottom: 2px; /* stylelint-disable-line scales/space */
    }

    @media (hover: none) {
      ${GridFocusStyles}
    }

    &:hover,
    &:focus,
    &:focus-within {
      ${GridFocusStyles}
    }
  `
)

export const TableWrapper = styled(TableComponents.TableRow)(
  ({ theme }) => css`
    ${commonWrapper};

    && ${ImageWrapper} {
      /* We need to make sure the size is set to prevent the image from expanding/shrinking */
      width: 32px;
      height: 32px;

      border-radius: ${theme.radii.medium};
      background-color: ${theme.colors.state.hover};

      /* Margin from image wrapper to the document name */
      margin-right: 12px;

      /* Maintain the radius in the inside border */
      ::after {
        border-radius: ${theme.radii.medium};
      }
    }

    ${StatusIcon} {
      width: 16px;
      height: 16px;
    }

    ${breakpoint('base', 'sm')`
    overflow: auto;
    width: 100vw;

    ${TableComponents.TableCellSticky} {
      z-index: 2;
      right: 0;
    }

    ${TableComponents.TableHeaderCell}:last-child {
      right: 0;
      width: 60px;

      ${breakpoint('base', 'sm')`
        color: ${theme.colors.background.secondary.A}; /** Just for hiding the text */
      `}
    }
  `}
  `
)

export const TableCell = styled(TableComponents.TableCell)`
  flex: 1;

  :last-child {
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 12px;
    min-width: 64px;
  }
`

export const DropdownWrapper = styled.span`
  line-height: 1;
`

export const TableAvatarNames = styled.span`
  word-break: break-word;
  ${truncateLines(2)};

  margin-left: 8px;
`

export const GridPlaceholderWrapper = styled(GridWrapper)(
  ({ theme, $isRefreshedUi }) => css`
    margin-bottom: 44px;

    ${/* sc-selector */ ImageWrapper}:after {
      box-shadow: none;
    }

    ${$isRefreshedUi &&
    css`
      && ${ImageWrapper} {
        background-color: ${theme.colors.background.tertiary.C};
      }
    `}
  `
)

export const TablePlaceholderWrapper = styled(TableWrapper)`
  ${/* sc-selector */ ImageWrapper}:after {
    box-shadow: none;
  }
`

import { __rest, __awaiter } from 'tslib';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import * as yup from 'yup';
import { routes } from '@sketch/modules-common';
import { Form, Input, Checkbox, SelectOption, ModalContext, Modal, Button } from '@sketch/components';
import '../../components/Divider/index.js';
import '../../components/AccessScope/index.js';
import '../CopyTokenModal/index.js';
import { CheckboxGroup, HumanReadableExpiry, ExpiryInputContainer, StyledSelect, StyledModal } from './CreateTokenModal.styles.js';
import { dateFormat } from '@sketch/utils';
import '../../operations/index.js';
import { allScopes, descriptionForScope } from '../../components/AccessScope/AccessScope.js';
import { useCreatePersonalToken } from '../../operations/useCreatePersonalToken.js';
import CopyTokenModal from '../CopyTokenModal/CopyTokenModal.js';
import Divider from '../../components/Divider/Divider.styles.js';

const INITIAL_VALUES = {
    description: '',
    accessScopes: [],
    expiresAt: undefined,
    expiryDropdown: undefined,
};
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
tomorrow.setHours(0);
tomorrow.setMinutes(0);
tomorrow.setSeconds(0);
tomorrow.setMilliseconds(0);
const minExpiresAt = tomorrow;
const maxExpiresAt = new Date('9999-12-31');
const VALIDATION_SCHEMA = yup.object().shape({
    description: yup
        .string()
        .required('Enter a token description')
        .max(255, 'Maximum 255 characters'),
    expiresAt: yup
        .date()
        .nullable()
        .test('is selected', 'Select an expiry date', value => {
        return value !== undefined;
    })
        .min(minExpiresAt, 'Select a future date')
        .max(maxExpiresAt, 'Select a future date (max 9999-12-31)'),
    accessScopes: yup
        .array()
        .of(yup.string())
        .min(1, 'Select one or more options'),
});
const DescriptionInput = ({ touched, errors, values, handleChange, handleBlur, }) => (jsx(Form.Field, Object.assign({ name: "description", label: "Token Description", errorText: touched.description ? errors.description : undefined }, { children: jsx(Input, { type: "text", name: "description", placeholder: "Enter a description...", maxLength: 255, value: values.description, onChange: handleChange, onBlur: handleBlur }) })));
const AccessScopeCheckbox = (props) => {
    const { label } = props, inputProps = __rest(props, ["label"]);
    return (jsx(Form.Field, Object.assign({ name: "accessScopes" }, { children: jsx(Checkbox, Object.assign({ name: "accessScopes" }, inputProps, { children: label })) })));
};
const AccessScopesCheckboxes = (props) => {
    const { accessScopes, } = props.values;
    const checkboxes = allScopes.map(scope => {
        const checked = accessScopes.includes(scope);
        const description = descriptionForScope(scope);
        return (jsx(AccessScopeCheckbox, { "data-testid": `access-scope-checkbox-${scope}`, value: scope, checked: checked, label: description, onChange: props.handleChange }, scope));
    });
    return (jsxs(Fragment, { children: [jsx("div", Object.assign({ id: "access-scope-checkbox-group" }, { children: "Token Permissions:" })), jsx(CheckboxGroup, Object.assign({ role: "group", "aria-labelledby": "access-scope-checkbox-group" }, { children: checkboxes })), props.touched.accessScopes && props.errors.accessScopes && (jsx(Form.Error, { children: props.errors.accessScopes }))] }));
};
var ExpiryOption;
(function (ExpiryOption) {
    ExpiryOption["Never"] = "NEVER";
    ExpiryOption["InSevenDays"] = "IN_SEVEN_DAYS";
    ExpiryOption["InThirtyDays"] = "IN_THIRTY_DAYS";
    ExpiryOption["InNinetyDays"] = "IN_NINETY_DAYS";
    ExpiryOption["CustomDate"] = "CUSTOM_DATE";
})(ExpiryOption || (ExpiryOption = {}));
const dateFromExpiryOption = (expiryOption) => {
    const now = new Date();
    switch (expiryOption) {
        case ExpiryOption.InSevenDays:
            now.setDate(now.getDate() + 7);
            return now;
        case ExpiryOption.InThirtyDays:
            now.setDate(now.getDate() + 30);
            return now;
        case ExpiryOption.InNinetyDays:
            now.setDate(now.getDate() + 90);
            return now;
        case ExpiryOption.Never:
            return null;
        case ExpiryOption.CustomDate:
            return undefined;
    }
};
const expiryOptionFromString = (optionStr) => {
    switch (optionStr) {
        case 'NEVER':
            return ExpiryOption.Never;
        case 'IN_SEVEN_DAYS':
            return ExpiryOption.InSevenDays;
        case 'IN_THIRTY_DAYS':
            return ExpiryOption.InThirtyDays;
        case 'IN_NINETY_DAYS':
            return ExpiryOption.InNinetyDays;
        case 'CUSTOM_DATE':
            return ExpiryOption.CustomDate;
        default:
            return undefined;
    }
};
const humanReadableExpiry = (expiryDate) => {
    if (!expiryDate)
        return;
    const formatted = dateFormat(expiryDate);
    return jsxs(HumanReadableExpiry, { children: ["Expires on ", formatted] });
};
const ExpiryInput = (props) => {
    const [expiryOption, setExpiryOption] = useState();
    const onSelectChange = (optionStr) => {
        const selectedOption = expiryOptionFromString(optionStr);
        // this should never happen but not sure of a better way to force
        // typescript to accept that the options of the <Select /> are always
        // ExpiryOption
        if (!selectedOption)
            return;
        setExpiryOption(selectedOption);
        if (selectedOption === ExpiryOption.CustomDate) {
            // When the user selects `CustomDate` we set `values.expiresAt` back to
            // undefined. We also set `touched.expiresAt` to false to make sure we
            // don't show an error until they finish interacting with the date picker
            props.setTouched({ expiresAt: false });
        }
        const expiryDate = dateFromExpiryOption(selectedOption);
        const event = { target: { name: 'expiresAt', value: expiryDate } };
        props.handleChange(event);
        props.validateField('expiresAt');
    };
    return (jsxs(Fragment, { children: [jsx("label", { children: "Expires In" }), jsxs(ExpiryInputContainer, Object.assign({ "data-testid": "expiry-container" }, { children: [jsxs(StyledSelect, Object.assign({ placeholder: "Select an option...", onChange: onSelectChange, value: expiryOption || '' }, { children: [jsx(SelectOption, Object.assign({ value: ExpiryOption.InSevenDays }, { children: "7 days" })), jsx(SelectOption, Object.assign({ value: ExpiryOption.InThirtyDays }, { children: "30 days" })), jsx(SelectOption, Object.assign({ value: ExpiryOption.InNinetyDays }, { children: "90 days" })), jsx(SelectOption, Object.assign({ value: ExpiryOption.Never }, { children: "Never Expires" })), jsx(SelectOption, Object.assign({ value: ExpiryOption.CustomDate }, { children: "Custom..." }))] })), expiryOption === ExpiryOption.CustomDate ? (jsx(Form.Field, Object.assign({ name: "expiresAt" }, { children: jsx(Input, { min: minExpiresAt.toISOString().slice(0, 10), max: maxExpiresAt.toISOString().slice(0, 10), type: "date", name: "expiresAt", value: props.values.expiresAt || '', onChange: props.handleChange, onBlur: props.handleBlur }) }))) : (humanReadableExpiry(props.values.expiresAt))] })), props.touched.expiresAt && props.errors.expiresAt && (jsx(Form.Error, { children: props.errors.expiresAt }))] }));
};
const CreateTokenModal = props => {
    const { hideModal, redirectToTokensPage } = props;
    const { showModal } = useContext(ModalContext);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const closeModal = () => {
        if (redirectToTokensPage) {
            history.push(routes.PERSONAL_SETTINGS_ACCESS_TOKENS.create({}));
        }
        else {
            hideModal();
        }
    };
    const createPersonalToken = useCreatePersonalToken({
        onCompleted: ({ personalToken, secret }) => {
            closeModal();
            showModal(CopyTokenModal, { token: personalToken, secret });
        },
    });
    const handleOnSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        // <input type="date" /> will only give us a date in the format YYYY-MM-DD
        // whereas the GraphQL schema expects an ISO8601 date-time string that
        // includes the time:
        //   e.g. 2022-03-01T00:00:00.00Z
        //
        // Here we convert the string to a `Date` and then get the full ISO8601
        // string to make sure it includes both the date **and** time.
        // Date.toISOString() gives a string in the following ISO8601 compliant
        // format:
        //   YYYY-MM-DDTHH:mm:ss.sssZ
        const expiresAt = values.expiresAt
            ? new Date(values.expiresAt).toISOString()
            : null;
        setLoading(true);
        yield createPersonalToken({
            accessScopes: values.accessScopes,
            description: values.description,
            expiresAt,
        });
        setLoading(false);
    });
    return (jsx(StyledModal, Object.assign({ title: "Create a Token", onCancel: closeModal }, { children: jsx(Formik, Object.assign({ initialValues: INITIAL_VALUES, validationSchema: VALIDATION_SCHEMA, onSubmit: handleOnSubmit }, { children: formikProps => (jsxs(Form, { children: [jsxs(Modal.Body, { children: [jsx("p", { children: "Now give your token a description, an expiry date and select what you want to use it for." }), jsx(DescriptionInput, Object.assign({}, formikProps)), jsx(ExpiryInput, Object.assign({}, formikProps)), jsx(Divider, {}), jsx(AccessScopesCheckboxes, Object.assign({}, formikProps))] }), jsxs(Modal.Footer, { children: [jsx(Button, Object.assign({ variant: "secondary", onClick: closeModal, disabled: loading }, { children: "Cancel" })), jsx(Button, Object.assign({ variant: "primary", type: "submit", disabled: loading || Object.keys(formikProps.errors).length > 0 }, { children: "Create Token" }))] })] })) })) })));
};

export { CreateTokenModal as default };

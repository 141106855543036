import { __rest } from 'tslib';
import { isDeployedInProduction } from '@sketch/utils';
import { readSettingsState } from '@sketch/devtools';
import '../../../routes/index.js';
import { addQueryParametersToUrl } from '../../../routes/utils/route.js';

const sourceToSsoSignInSource = {
    sketch: 'sketch',
    'cloud-popup': 'cloud-popup',
    cloud: 'cloud',
};
/**
 * This value is obtained from the dev-tools values and
 * will allow users testing on devtools available envs to have access
 * to the redirect
 */
const debugSettingsState = readSettingsState();
const isSSODebugActive = debugSettingsState.sso.debug;
const mapSsoStartUrl = (url, _a = {}) => {
    var { source = 'cloud', from } = _a, moreParams = __rest(_a, ["source", "from"]);
    const queryParameters = Object.assign({ source }, moreParams);
    if (!isDeployedInProduction() && isSSODebugActive) {
        /**
         * The debug parameter tells the SSO service that when the sign-in flow
         * finishes it should not redirect the user back to a FE URL but just return
         * the URL in plain text.
         *
         * This makes it easier for us, devs, to use SSO from localhost. If not
         * present, the SSO service would redirect the browser to dev.sketch.cloud.
         */
        Object.assign(queryParameters, { debug: 'true' });
    }
    if (from) {
        Object.assign(queryParameters, { from });
    }
    return addQueryParametersToUrl(url, queryParameters);
};

export { mapSsoStartUrl, sourceToSsoSignInSource };

import React from 'react'
import {
  BaseListRowInfo,
  BaseListRowIconAvatar,
  BaseListRowAvatar,
} from '@sketch/components'
import { BaseUserPermissionRow } from './UserPermissionRow.styles'

interface UserPermissionsRowProps {
  name: string
  actions: React.ReactNode
  avatarSrc?: string | null
  icon?: React.FunctionComponent
  isCurrentUser: boolean
}

export const UserPermissionRow = ({
  avatarSrc,
  name,
  actions,
  icon: Icon,
  isCurrentUser,
}: UserPermissionsRowProps) => {
  let avatar

  if (avatarSrc) {
    avatar = <BaseListRowAvatar src={avatarSrc} name={name} square={true} />
  } else if (Icon) {
    avatar = (
      <BaseListRowIconAvatar mr={3}>
        <Icon />
      </BaseListRowIconAvatar>
    )
  }

  return (
    <BaseUserPermissionRow
      name={name}
      avatar={avatar}
      actions={actions}
      showStripes={true}
    >
      <BaseListRowInfo title={name} isCurrentUser={isCurrentUser} />
    </BaseUserPermissionRow>
  )
}

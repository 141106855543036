import { jsxs, jsx } from 'react/jsx-runtime';
import { ConfirmationDialog, Link } from '@sketch/components';
import { useToast } from '@sketch/toasts';
import { deleteEntityFromCache, useGetHasDraftsProject, routes } from '@sketch/modules-common';
import './useLeaveWorkspace/index.js';
import { useLeaveWorkspace } from './useLeaveWorkspace/useLeaveWorkspace.js';

/**
 * LeaveWorkspaceModal
 *
 * This component is visually a Modal that Prompts the user
 * with a confirmation to leave the workspace
 */
const LeaveWorkspaceModal = props => {
    const { hideModal, isModalOpen, workspace, membershipId } = props;
    const { showToast } = useToast();
    const [leaveWorkspace, { loading }] = useLeaveWorkspace({
        variables: { membershipId },
        onError: message => showToast(message, 'negative'),
        onCompleted: () => {
            showToast(`You have been removed from "${workspace.name}"`);
            hideModal();
        },
        update: cache => {
            /* Remove all the workspace information from the cache */
            deleteEntityFromCache(cache, {
                __typename: 'Workspace',
                identifier: workspace.identifier,
            });
        },
    });
    const hasDraftsProject = useGetHasDraftsProject(workspace.identifier);
    return (jsxs(ConfirmationDialog, Object.assign({ title: "Are you sure you want to leave the Workspace?", hideModal: hideModal, isModalOpen: isModalOpen, onConfirm: leaveWorkspace, confirmButton: {
            text: 'Leave',
            loading,
            disabled: loading,
            variant: 'negative',
        } }, { children: [jsxs("p", { children: ["After you leave you won't be able to view, comment on, or upload documents to \"", jsx("strong", { children: workspace.name }), "\" anymore."] }), hasDraftsProject && (jsxs("p", { children: ["Make sure you back up any private work in", jsxs(Link, Object.assign({ to: routes.WORKSPACE_DRAFTS.create({
                            workspaceId: workspace.identifier,
                        }), onClick: () => hideModal() }, { children: [' ', "My Drafts", ' '] })), "before you leave."] }))] })));
};

export { LeaveWorkspaceModal as default };

import React from 'react'

import {
  Dropdown,
  MenuIconButton,
  useResponsiveDropdown,
} from '@sketch/components'

import { ReactComponent as DotsIcon } from '@sketch/icons/dots-3-horizontal-24'
import { usePrototypeComments } from 'modules/prototype/hooks'
import {
  useAnnotationOverlayContext,
  useIsAnnotationContextAvailable,
} from 'modules/annotations/hooks'

const AnnotationMobileMenuDropdown = () => {
  const [commentsEnabled, setCommentsEnabled] = usePrototypeComments()
  const { setPlaceDraftAnnotation } = useAnnotationOverlayContext()!

  return (
    <>
      <Dropdown.Header>Prototype</Dropdown.Header>
      <Dropdown.Item
        onClick={() => {
          setCommentsEnabled(!commentsEnabled)
        }}
      >
        {commentsEnabled ? 'Hide Comments' : 'Show Comments'}
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          setPlaceDraftAnnotation(true)
        }}
      >
        Add a comment
      </Dropdown.Item>
    </>
  )
}

export const AnnotationMobileMenu = () => {
  const areAnnotationsAvailable = useIsAnnotationContextAvailable()
  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: AnnotationMobileMenuDropdown,
  })

  if (!areAnnotationsAvailable) {
    return null
  }

  return (
    <>
      <MenuIconButton {...buttonProps}>
        <DotsIcon role="img" />
        <span className="sr-only">Prototype</span>
      </MenuIconButton>
      {content}
    </>
  )
}

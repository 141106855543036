import * as Sentry from '@sentry/browser'

// Valid usage to cast a component type
// eslint-disable-next-line
import { ComponentFilterType } from '@sketch/gql-types/expansive'

import { useGetComponentGroupsQuery } from '@sketch/gql-types'
import type { ComponentsTree } from '../types'

import { useGetIdentifiers } from 'modules/shares/hooks/useGetIdentifiers'

export interface GetComponentsGroupsResult {
  status: 'LOADING' | 'READY' | 'ERROR'
  tree: ComponentsTree[]
}

export const useGetComponentsGroups = (
  type?: string
): GetComponentsGroupsResult => {
  const { shareIdentifier, versionShortId } = useGetIdentifiers()

  const { data, loading, error } = useGetComponentGroupsQuery({
    variables: {
      uuid: shareIdentifier,
      type: type as ComponentFilterType,
      versionShortId,
    },
    skip: !type || type === 'SHARE_VIEW' || !shareIdentifier || !versionShortId,
  })

  if (loading) {
    return {
      status: 'LOADING',
      tree: [],
    }
  }

  if (error) {
    // log the error to sentry
    if (typeof error === 'string') {
      Sentry.captureException(error)
    } else if (error?.graphQLErrors) {
      const versionForbiddenError = error.graphQLErrors.some(e =>
        [
          'USER_CANT_VIEW_OLD_SHARE_VERSIONS',
          'USER_CANT_VIEW_VERSION_HISTORY',
          'USER_CANT_VIEW_SHARE_VERSION',
        ].includes(e.extensions?.reason)
      )

      if (!versionForbiddenError) {
        Sentry.captureException(error)
      }
    }

    return {
      status: 'ERROR',
      tree: [],
    }
  }

  try {
    return {
      status: 'READY',
      tree: JSON.parse(
        data?.share?.version?.document?.componentGroups?.data ?? '[]'
      ) as ComponentsTree[],
    }
  } catch (e) {
    return {
      status: 'ERROR',
      tree: [],
    }
  }
}

import React, { useLayoutEffect } from 'react';
import { flushSync } from 'react-dom';
import isEqual from 'react-fast-compare';
import { createPopper } from '@popperjs/core';

/**
 * This file is a copy of
 * https://github.com/floating-ui/react-popper/blob/master/src/usePopper.js
 *
 * It was imported to the project to tackle
 * https://github.com/sketch-hq/Cloud/issues/13849
 *
 * Because of the canvas way the canvas has been assembled
 * it allows elements to have negative positions.
 *
 * Because popper by default renders elements on top:0 and left:0
 * as you can see on the original file, this forced the issue you see on the ticket.
 * Basically forcing the poppers initial position to be visible.
 *
 * By sending no positions we prevent this "overflow" for occurring
 */
const EMPTY_MODIFIERS = [];
const usePopper = (referenceElement, popperElement, options) => {
    var _a, _b, _c;
    const prevOptions = React.useRef();
    const optionsWithDefaults = {
        onFirstUpdate: options.onFirstUpdate,
        placement: options.placement || 'bottom',
        strategy: options.strategy || 'absolute',
        modifiers: options.modifiers || EMPTY_MODIFIERS,
    };
    const [state, setState] = React.useState({
        styles: {
            popper: {
                position: optionsWithDefaults.strategy,
            },
            arrow: {
                position: 'absolute',
            },
        },
        attributes: {},
    });
    const updateStateModifier = React.useMemo(() => ({
        name: 'updateState',
        enabled: true,
        phase: 'write',
        fn: ({ state }) => {
            const elements = Object.keys(state.elements);
            flushSync(() => {
                setState({
                    styles: Object.fromEntries(elements.map(element => [
                        element,
                        state.styles[element] || {},
                    ])),
                    attributes: Object.fromEntries(elements.map(element => [element, state.attributes[element]])),
                });
            });
        },
        requires: ['computeStyles'],
    }), []);
    const popperOptions = React.useMemo(() => {
        const newOptions = {
            onFirstUpdate: optionsWithDefaults.onFirstUpdate,
            placement: optionsWithDefaults.placement,
            strategy: optionsWithDefaults.strategy,
            modifiers: [
                ...optionsWithDefaults.modifiers,
                updateStateModifier,
                { name: 'applyStyles', enabled: false },
            ],
        };
        if (isEqual(prevOptions.current, newOptions)) {
            return prevOptions.current || newOptions;
        }
        else {
            prevOptions.current = newOptions;
            return newOptions;
        }
    }, [
        optionsWithDefaults.onFirstUpdate,
        optionsWithDefaults.placement,
        optionsWithDefaults.strategy,
        optionsWithDefaults.modifiers,
        updateStateModifier,
    ]);
    const popperInstanceRef = React.useRef(null);
    useLayoutEffect(() => {
        var _a;
        if (popperInstanceRef.current) {
            (_a = popperInstanceRef.current) === null || _a === void 0 ? void 0 : _a.setOptions(popperOptions);
        }
    }, [popperOptions]);
    useLayoutEffect(() => {
        if (referenceElement == null || popperElement == null) {
            return;
        }
        const createPopper$1 = options.createPopper || createPopper;
        const popperInstance = createPopper$1(referenceElement, popperElement, popperOptions);
        popperInstanceRef.current = popperInstance;
        return () => {
            popperInstance.destroy();
            popperInstanceRef.current = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [referenceElement, popperElement, options.createPopper]);
    return {
        state: (_a = popperInstanceRef.current) === null || _a === void 0 ? void 0 : _a.state,
        styles: state === null || state === void 0 ? void 0 : state.styles,
        attributes: state === null || state === void 0 ? void 0 : state.attributes,
        update: (_b = popperInstanceRef.current) === null || _b === void 0 ? void 0 : _b.update,
        forceUpdate: (_c = popperInstanceRef.current) === null || _c === void 0 ? void 0 : _c.forceUpdate,
    };
};

export { usePopper };

import React from 'react'

import { Redirect } from 'react-router-dom'

import {
  NotFoundView,
  routes,
  RouteProps,
  IndexLayoutExtraProps,
  IndexLayoutContent,
  useUserProfile,
} from '@sketch/modules-common'
import AcceptMembershipPanel from './AcceptMembershipPanel'
import {
  ShareViewerHasUnacceptedMembershipInviteFragmentDoc,
  useGetShareMembershipByShareShortIdQuery,
} from '@sketch/gql-types'
import { LoadingState, Flex } from '@sketch/components'
import useAcceptShareMembershipInvitation from '../operations/useAcceptShareMembershipInvitation'
import { useToast } from '@sketch/toasts'

export interface AcceptShareInviteByShareShortIdViewProps
  extends RouteProps<'SHARE_INVITE'>,
    IndexLayoutExtraProps {}

export const AcceptShareInviteByShareShortIdView = (
  props: AcceptShareInviteByShareShortIdViewProps
) => {
  const { match, history, useOverrideLayoutProps } = props

  const { showToast } = useToast()

  useOverrideLayoutProps({ variant: 'orange' })

  const shareIdentifier = match?.params?.shareID
  const { data: profile } = useUserProfile()

  const { data, error, loading } = useGetShareMembershipByShareShortIdQuery({
    variables: { shareIdentifier },
  })

  const [
    acceptShareMembership,
    {
      loading: acceptShareMembershipLoading,
      error: acceptShareMembershipError,
    },
  ] = useAcceptShareMembershipInvitation({
    variables: { shareIdentifier },
    update: cache => {
      cache.writeFragment({
        id: `Share::${shareIdentifier}`,
        fragment: ShareViewerHasUnacceptedMembershipInviteFragmentDoc,
        data: {
          userHasUnacceptedInvitationToView: false,
          __typename: 'Share',
        },
      })
    },
    onCompleted: () => {
      showToast('Invite successfully accepted')
      history.push(routes.SHARE_VIEW.create({ shareID: shareIdentifier }))
    },
    onError: 'do-nothing', // we use acceptShareMembershipError to show the error message
  })

  if (loading) {
    return <LoadingState />
  }

  if (error || !data?.shareMembership || !data?.share) {
    return <NotFoundView isInLayout />
  }

  if (data.shareMembership.inviteStatus === 'ACCEPTED') {
    return (
      <Redirect
        to={routes.SHARE_VIEW.create({
          shareID: shareIdentifier,
        })}
      />
    )
  }

  const documentName = data?.share?.name ?? ''
  const inviter = data?.shareMembership?.inviter || undefined
  const userEmail = profile?.me.email ?? ''
  const userAvatar = profile?.me.avatar?.small ?? ''

  return (
    <IndexLayoutContent center="horizontal" marginTop paddingHorizontal>
      <Flex maxWidth={470} width="100%" mt={14} alignSelf="center">
        <AcceptMembershipPanel
          inviter={inviter}
          resourceType="document"
          resourceName={documentName}
          acceptMembership={() =>
            acceptShareMembership({
              variables: { shareIdentifier },
            })
          }
          acceptMembershipLoading={acceptShareMembershipLoading}
          acceptMembershipError={acceptShareMembershipError}
          userInfo={{
            userEmail,
            userAvatar,
          }}
        />
      </Flex>
    </IndexLayoutContent>
  )
}

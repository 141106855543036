import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import '../Box/index.js';
import { Spinner } from './Spinner.js';
import { Flex } from '../Box/BoxSystem.js';

const SpinnerFlex = forwardRef((_a, forwardedRef) => {
    var { primary, size } = _a, props = __rest(_a, ["primary", "size"]);
    return (jsx(Flex, Object.assign({ ref: forwardedRef, justifyContent: "center", alignItems: "center" }, props, { children: jsx(Spinner, { primary: primary, size: size }) })));
});
SpinnerFlex.displayName = 'SpinnerFlex';

export { SpinnerFlex };

import { getItem } from '@sketch/utils';
import { localStorageKeys } from '@sketch/constants';
import env from './appEnv.js';

const isBillingHidden = (() => {
    if (env.hide_billing)
        return true;
    if ((process.env.REACT_APP_ENV === 'dev' ||
        process.env.REACT_APP_ENV === 'test') &&
        getItem(localStorageKeys.hideBilling) === 'true') {
        return true;
    }
    return false;
})();

export { isBillingHidden };

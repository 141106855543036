import { useGetUserNotificationsCountQuery } from '@sketch/gql-types';
import { ErrorHandler } from '@sketch/tracing';

// GraphQl
const useGetNewNotificationsCount = (props = {}) => {
    var _a, _b, _c, _d;
    const { fetchPolicy = 'cache-first' } = props;
    const { data } = useGetUserNotificationsCountQuery({
        fetchPolicy,
        onError: error => {
            ErrorHandler.shouldNeverHappen(`Unexpected error fetching me.notifications.meta: '${error.message}'`);
        },
    });
    const unreadNotificationsCount = (_b = (_a = data === null || data === void 0 ? void 0 : data.me.unreadNotificationsCount) === null || _a === void 0 ? void 0 : _a.meta.totalCount) !== null && _b !== void 0 ? _b : 0;
    const newNotificationsCount = (_d = (_c = data === null || data === void 0 ? void 0 : data.me.newNotificationsCount) === null || _c === void 0 ? void 0 : _c.meta.totalCount) !== null && _d !== void 0 ? _d : 0;
    return unreadNotificationsCount + newNotificationsCount;
};

export { useGetNewNotificationsCount };

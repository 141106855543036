import { __awaiter } from 'tslib';
import { getNetworkRequestHeaders } from '@sketch/modules-common';
import { signUpContentFromJson } from './utils.js';

const HEADERS = Object.assign(Object.assign({}, getNetworkRequestHeaders()), { 'Content-Type': 'application/json' });
const campaignSignUpContentLocalResolver = (_, { campaign }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const response = yield fetch(`/data/signup/campaign/${campaign}.json`, {
        headers: HEADERS,
    });
    if (response.status === 200) {
        const json = yield response.json();
        return {
            __typename: 'CampaignSignUpContent',
            active: (_a = json.active) !== null && _a !== void 0 ? _a : true,
            content: signUpContentFromJson(json),
        };
    }
    return null;
});

export { campaignSignUpContentLocalResolver };

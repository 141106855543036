import styled from 'styled-components';
import '../Link/index.js';
import { Link as Link$1 } from '../Link/Link.js';

const List = styled.ol `
  display: inline-block;
  counter-reset: steps;
  padding: 0;
  margin: 0;
  z-index: 1;
  line-height: 1;
`;
const Link = styled(Link$1) `
  font-weight: 400;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`;

export { Link, List };

import React from 'react'
import { Location } from 'history'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useApolloClient } from 'react-apollo'

import { useToast } from '@sketch/toasts'
import { routes } from '@sketch/modules-common'

import {
  GetProjectsDocument,
  GetProjectsQuery,
  GetProjectsQueryVariables,
  SubscriptionShareFragment,
} from '@sketch/gql-types'

import { useEventDispatch } from '@sketch/utils'
import { getCanAdminister } from '../../utils'

import useShareSubscriptions from '../../operations/useShareSubscriptions'
import useProjectSubscriptions from 'modules/projects/operations/useProjectSubscriptions'
import { matchKnownPath } from './utils'
import { isViewingCurrentProject } from 'modules/projects/utils'
import { invalidateProjectShares } from 'modules/collections/operations'
interface WorkspaceTrashRefreshPayload {
  workspaceIdentifier: string
}

interface WorkspaceShareRefreshPayload {
  workspaceIdentifier: string
  projectIdentifier?: string
  collectionIdentifier?: string
}

declare module '@sketch/utils' {
  export interface EventsMap {
    workspaceTrashRefresh: WorkspaceTrashRefreshPayload
    workspaceShareRefresh: WorkspaceShareRefreshPayload
  }
}

interface FormatTrashLink {
  (parameters: { name: string; workspaceId: string }): React.ReactElement
}

const formatTrashLink: FormatTrashLink = parameters => {
  const link = routes.WORKSPACE_TRASH.create({
    workspaceId: parameters.workspaceId,
  })

  return (
    <>
      &quot;{parameters.name}&quot; has been moved to{' '}
      <Link to={link}>Trash</Link>
    </>
  )
}

const formatShareTrashedLink = (share: SubscriptionShareFragment) => {
  if (getCanAdminister(share)) {
    return formatTrashLink({
      name: share.name,
      workspaceId: share.workspace.identifier,
    })
  }

  return <>{`"${share.name}" has been deleted`}</>
}

const isInSharesView = (location: Location, shareIdentifier: string) => {
  const isUserViewingShare = matchKnownPath(location, 'SHARE_VIEW')
  return isUserViewingShare?.params.shareID === shareIdentifier
}

const ShareSubscriptions = () => {
  const { showToast } = useToast()

  const apolloClient = useApolloClient()
  const location = useLocation()
  const history = useHistory()

  const dispatchTrashRefresh = useEventDispatch('workspaceTrashRefresh')
  const dispatchSharesRefresh = useEventDispatch('workspaceShareRefresh')

  useShareSubscriptions({
    onEmptiedTrash: resource => {
      // Force the trash view to refresh for this workspace to refresh, if visible
      // see src/modules/shares/operations/useGetTrash/useGetTrash.ts
      dispatchTrashRefresh({
        workspaceIdentifier: resource.workspaceIdentifier,
      })
    },
    onRestored: resource => {
      if (!isInSharesView(location, resource.shareIdentifier)) {
        // If its not the share in view ¯\_(ツ)_/¯ we don't care
        return
      }

      // Force the Shares view to refresh for this workspace to refresh, if visible
      // see src/modules/shares/components/WorkspaceSharesList.tsx
      // see src/modules/projects/views/ProjectsView/ProjectsView.tsx
      dispatchSharesRefresh({
        workspaceIdentifier: resource.share.workspace.identifier,
        projectIdentifier: resource.share.project?.identifier,
      })

      if (resource.previousShare?.deletedAt === null) {
        // If the cached share has already the deletedAt null it means that it was already notified
        return
      }

      showToast(`"${resource.share.name}" has been restored`)
    },
    onDeleted: resource => {
      if (!isInSharesView(location, resource.shareIdentifier)) {
        // If its not the share in view ¯\_(ツ)_/¯ we don't care
        return
      }

      // Force the trash view to refresh for this workspace to refresh, if visible
      // see src/modules/shares/operations/useGetTrash/useGetTrash.ts
      dispatchTrashRefresh({
        workspaceIdentifier: resource.share.workspace.identifier,
      })

      if (resource.previousShare?.deletedAt) {
        // If the cached share has already the deletedAt with value it means that it was already notified
        return
      }

      showToast(formatShareTrashedLink(resource.share), 'negative')
    },
    onDeletedPermanently: resource => {
      if (!isInSharesView(location, resource.shareIdentifier)) {
        // If its not the share in view ¯\_(ツ)_/¯ we don't care
        return
      }

      const { previousShare } = resource

      history.push(
        routes.WORKSPACE_SHARES.create({
          workspaceId: resource.workspaceIdentifier,
        })
      )

      if (!previousShare) {
        return
      }

      showToast(
        `"${previousShare.name}" has been permanently deleted`,
        'negative'
      )
    },
    onShareProjectChanged: resource => {
      // Share was removed from a project and is now orphan
      if (!resource.share.project) {
        return
      }

      invalidateProjectShares({
        cache: apolloClient.cache,
        projectIdentifier: resource.share.project.identifier,
      })

      dispatchSharesRefresh({
        workspaceIdentifier: resource.share.workspace.identifier,
        projectIdentifier: resource.share.project.identifier,
      })
    },
  })

  const refetchProjects = (workspaceId: string) => {
    apolloClient.query<GetProjectsQuery, GetProjectsQueryVariables>({
      query: GetProjectsDocument,
      variables: { workspaceId },
      fetchPolicy: 'network-only',
    })
  }

  const invalidateCache = (
    workspaceIdentifier: string,
    projectIdentifier: string
  ) => {
    // Force the trash view to refresh for this workspace to refresh, if visible
    // see src/modules/shares/operations/useGetTrash/useGetTrash.ts
    dispatchTrashRefresh({
      workspaceIdentifier: workspaceIdentifier,
    })

    // Force the Shares view to refresh for this workspace to refresh, if visible
    // see src/modules/shares/components/WorkspaceSharesList.tsx
    // see src/modules/projects/views/ProjectsView/ProjectsView.tsx
    dispatchSharesRefresh({
      workspaceIdentifier: workspaceIdentifier,
      projectIdentifier: projectIdentifier,
    })
  }

  const isInWorkspaceRoute = (workspaceIdentifier: string) => {
    const isUserViewingWorkspace = matchKnownPath(location, 'WORKSPACE')

    return workspaceIdentifier === isUserViewingWorkspace?.params.workspaceId
  }

  const isInWorkspaceProject = (projectShortId: string) => {
    const isUserViewingProject =
      matchKnownPath(location, 'WORKSPACE_PROJECT') ||
      matchKnownPath(location, 'WORKSPACE_TRASH_PROJECT') ||
      undefined

    return projectShortId === isUserViewingProject?.params.projectId
  }

  useProjectSubscriptions({
    onCreated: resource => {
      // Refetch the projects list for this workspace
      // We can't warranty the same order as BE so the best way is to fresh the data
      refetchProjects(resource.workspaceIdentifier)
    },
    onChanged: resource => {
      // Refetch the projects list for this workspace
      // We can't warranty the same order as BE so the best way is to fresh the data
      refetchProjects(resource.workspaceIdentifier)
    },
    onDeleted: resource => {
      // Invalidate the documents cache
      invalidateCache(resource.workspaceIdentifier, resource.project.identifier)

      if (!isInWorkspaceRoute(resource.workspaceIdentifier)) {
        return
      }

      // Refetch the projects list for this workspace
      // We can't warranty the same order as BE so the best way is to fresh the data
      refetchProjects(resource.workspaceIdentifier)

      if (!isInWorkspaceProject(resource.project.identifier)) {
        return
      }

      if (resource.previousProject?.deletedAt) {
        // If the cached project has already the deletedAt it means that it was already notified
        return
      }

      showToast(`"${resource.project.name}" has been deleted`, 'negative')
    },
    onRestored: resource => {
      // Invalidate the documents cache
      invalidateCache(resource.workspaceIdentifier, resource.project.identifier)

      if (!isInWorkspaceRoute(resource.workspaceIdentifier)) {
        return
      }

      // Refetch the projects list for this workspace
      // We can't warranty the same order as BE so the best way is to fresh the data
      refetchProjects(resource.workspaceIdentifier)

      if (!isInWorkspaceProject(resource.project.identifier)) {
        return
      }

      if (resource.previousProject?.deletedAt === null) {
        // If the cached project has already the deletedAt null it means that it was already notified
        return
      }

      showToast(`"${resource.project.name}" has been restored`)
    },
    onDeletedPermanently: ({ previousProject, workspaceIdentifier }) => {
      if (
        previousProject &&
        !isInWorkspaceProject(previousProject.identifier)
      ) {
        return
      }

      history.push(
        routes.WORKSPACE_SHARES.create({
          workspaceId: workspaceIdentifier,
        })
      )

      if (!previousProject) {
        return
      }

      showToast(
        `"${previousProject.name}" has been permanently deleted`,
        'negative'
      )
    },
    onWorkspaceAccessLevelChanged: ({
      workspaceIdentifier,
      userMembership,
      projectIdentifier,
    }) => {
      if (userMembership === null) {
        // User loses access to the project
        if (
          isViewingCurrentProject(window.location.pathname, projectIdentifier)
        ) {
          history.push(
            routes.WORKSPACE_SHARES.create({ workspaceId: workspaceIdentifier })
          )
        }

        refetchProjects(workspaceIdentifier)

        // Force the Shares view to refresh for All Documents to refresh, if visible
        // see src/modules/shares/components/WorkspaceSharesList.tsx
        dispatchSharesRefresh({
          workspaceIdentifier,
        })
      }
    },
    onArchive: resource => {
      // Invalidate the documents cache
      invalidateCache(resource.workspaceIdentifier, resource.project.identifier)

      if (!isInWorkspaceRoute(resource.workspaceIdentifier)) {
        return
      }

      // Refetch the projects list for this workspace
      // We can't warranty the same order as BE so the best way is to fresh the data
      refetchProjects(resource.workspaceIdentifier)
    },
    onUnArchive: resource => {
      // Invalidate the documents cache
      invalidateCache(resource.workspaceIdentifier, resource.project.identifier)

      if (!isInWorkspaceRoute(resource.workspaceIdentifier)) {
        return
      }

      // Refetch the projects list for this workspace
      // We can't warranty the same order as BE so the best way is to fresh the data
      refetchProjects(resource.workspaceIdentifier)
    },
  })

  return null
}

export default ShareSubscriptions

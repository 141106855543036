import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import styled from 'styled-components';
import { tintColors } from '@sketch/global-styles';
import { Text, OverLayWrapper, ImageWrapper, Image } from './PersonAvatar.styles.js';

const COLOR_KEYS = Object.keys(tintColors); /* Allow the casting to work */
// Allow from the name prop get the user initial letters
const getNameLetters = (name) => name
    .split(/ +/)
    .map(block => block[0])
    .slice(0, 2);
// Choose a random color when none of the prism ones are passed
const pickRandomColor = () => Math.max(1, Math.random() * COLOR_KEYS.length).toFixed(0);
/**
 * PersonalAvatar
 */
const NonStyledPersonAvatar = props => {
    const { className, name, size, overlay } = props;
    if (props.flavour === 'color') {
        const color = props.color || 'random';
        return (jsxs(Text, Object.assign({ className: className, "$size": size, "$color": color === 'random' ? pickRandomColor() : color }, { children: [jsx(Fragment, { children: getNameLetters(name).map((letter, index) => (jsx("span", { children: letter }, letter || index))) }), overlay && jsx(OverLayWrapper, { children: overlay })] })));
    }
    return (jsxs(ImageWrapper, Object.assign({ className: className, "$size": size }, { children: [jsx(Image, { alt: `${name} avatar`, src: props.src }), overlay && jsx(OverLayWrapper, { children: overlay })] })));
};
const PersonAvatar = styled(NonStyledPersonAvatar) ``;

export { PersonAvatar };

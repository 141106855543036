import { defaultPermissionsValues, defaultLinkValues, defaultSizeValues, defaultSectionSubtitleValues, defaultItemMobileValues, defaultResponsiveImageValues, defaultSingleImageValues } from '@sketch/modules-common';
import { defaultSectionValues, defaultItemValues } from './defaults.js';

const defaultValues = {
    DiscoverSection: defaultSectionValues,
    DiscoverItem: defaultItemValues,
    Permissions: defaultPermissionsValues,
    Link: defaultLinkValues,
    Size: defaultSizeValues,
    SectionSubTitle: defaultSectionSubtitleValues,
    ItemMobile: defaultItemMobileValues,
    ResponsiveImage: defaultResponsiveImageValues,
    SingleImage: defaultSingleImageValues,
};
/**
 * Adds the required default values for the given __typename.
 */
const decorateItemWithDefaultValues = (item) => {
    const itemKeys = Object.keys(item);
    return itemKeys.reduce((acc, curr) => {
        const itemValue = item[curr];
        if (Array.isArray(itemValue)) {
            const newItems = visitItems(itemValue);
            return Object.assign(Object.assign({}, acc), { [curr]: newItems });
            // Search of values with __typename
        }
        else if (typeof itemValue === 'object') {
            const newValue = Object.assign(Object.assign({}, defaultValues[itemValue.__typename]), itemValue);
            return Object.assign(Object.assign({}, acc), { [curr]: newValue });
        }
        return Object.assign(Object.assign({}, acc), { [curr]: itemValue });
    }, {});
};
/**
 * Iterate over all properties of a given object, and call the decorate function
 * on the ones with a __typename.
 */
const visitItems = (items) => {
    return items.map(item => {
        if (item && typeof item === 'object') {
            const itemWithDefaultValues = decorateItemWithDefaultValues(item);
            return Object.assign(Object.assign({}, defaultValues[item.__typename]), itemWithDefaultValues);
        }
        return item;
    });
};

export { visitItems };

import styled, { css } from 'styled-components';
import '../../Button/index.js';
import { Modal } from '../../Modal/index.js';
import { Button } from '../../Button/Button.js';

const SelectDropdownCancelButton = styled(Button) `
  &&& {
    margin: 8px 0 0;

    &:only-child {
      margin: 0;
    }
  }
`;
const SelectDropdownModalHeader = styled(Modal.Header) `
  padding: 10px 8px 0; /* stylelint-disable-line scales/space */
`;
const SelectDropdownModalBody = styled(Modal.Body) `
  &&& {
    margin-top: 0;
    padding: 0 8px 8px;
    border-bottom: none;
  }
`;
const SelectDropdownModalFooter = styled.div(({ theme, hasOverflow }) => css `
    display: flex;
    flex-direction: column;
    padding: 8px;

    border-top: ${hasOverflow
    ? `1px solid ${theme.colors.border.A}`
    : undefined};
    text-align: right;

    position: sticky;
    bottom: 0;

    /* Hide the content when it scrolls */
    background: ${theme.colors.background.secondary.A};
  `);
const SelectDropdownItemWrapper = styled.div `
  :not(:empty) {
    padding: 8px;

    max-height: 40vh;
    overflow-y: auto;

    & + & {
      border-top: 1px solid ${({ theme }) => theme.colors.border.B};
    }
  }
`;

export { SelectDropdownCancelButton, SelectDropdownItemWrapper, SelectDropdownModalBody, SelectDropdownModalFooter, SelectDropdownModalHeader };

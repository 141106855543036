import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import React, { useState, useRef } from 'react';
import '../SelectList/index.js';
import { useSelectDropdownMenu, MenuContextProvider } from './menuContext.js';
import '../../Breakpoint/index.js';
import { useBreakpoint } from '../../Breakpoint/Breakpoint.js';
import { SelectList } from '../SelectList/SelectList.js';

/**
 * Beware of using this component because of mobile viewports!
 * Read more on the onClick trigger below.
 */
function SelectDropdownSubMenu(props) {
    const { items, setItem, value, headerItem, footerItem, disabled = false, usePortal = false, breakpoint = 'sm', renderTrigger, renderItem, } = props;
    const [expanded, setExpanded] = useState(false);
    const [forceExpanded, setForceExpanded] = useState(false);
    const buttonRef = useRef(null);
    const shouldRenderDropdown = useBreakpoint(breakpoint);
    const context = useSelectDropdownMenu();
    const showDropdown = (forceExpanded || expanded) && !disabled;
    const parentActive = context.activePath.includes(value);
    const children = (jsx(Fragment, { children: items.map((item, index) => (jsx(React.Fragment, { children: renderItem(item, {
                active: parentActive && context.activePath.includes(item),
                onClick: () => {
                    context.setValue(item, [value, item]);
                    setExpanded(() => false);
                    // Call the external "setItem" in case it exists
                    setItem === null || setItem === void 0 ? void 0 : setItem(item);
                },
            }) }, index))) }));
    const triggerOnClick = () => {
        !shouldRenderDropdown && setExpanded(value => !value);
    };
    return (jsxs("div", Object.assign({ onMouseEnter: () => {
            shouldRenderDropdown && !expanded && setExpanded(() => true);
            if (forceExpanded) {
                setForceExpanded(false);
                context.setForceExpanded(false);
            }
        }, onMouseLeave: () => {
            shouldRenderDropdown && expanded && setExpanded(() => false);
        } }, { children: [renderTrigger({
                active: parentActive,
                onClick: triggerOnClick,
                forceHover: expanded,
                ref: buttonRef,
            }), jsx(SelectList, Object.assign({ expanded: showDropdown, placement: "right", headerItem: headerItem, footerItem: footerItem, setExpanded: getState => {
                    setExpanded(getState(expanded, buttonRef.current));
                }, usePortal: usePortal, breakpoint: breakpoint }, { children: jsx(MenuContextProvider, Object.assign({ value: Object.assign(Object.assign({}, context), { setValue: (item, path) => {
                            context.setValue(item, [value, ...path]);
                            setExpanded(() => false);
                        }, forceExpanded, setForceExpanded: (forceExpanded) => {
                            setForceExpanded(forceExpanded);
                            context.setForceExpanded(forceExpanded);
                        } }) }, { children: children })) }))] })));
}

export { SelectDropdownSubMenu };

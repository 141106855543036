import { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { routes, RouteParams } from '@sketch/modules-common'
import { ShareWithoutVersion } from 'modules/versioning/ShareVersionContext/ShareVersionContext'
import { useSharingModal } from 'modules/shares/hooks/useSharingModal'
import {
  useDocumentInfoModal,
  DocumentInfoModalQueryProps,
} from 'modules/shares/hooks/useDocumentInfoModal'

/**
 * DocumentView can be accessed with a ?settings=:share-id URL query param
 * which is used as a deeplink to the document settings modal.
 * This param is checked when the components mounts and is removed from
 * the URL instantly after.
 */
export function useHandleSettingsQueryParamIfNeeded(
  share: ShareWithoutVersion,
  versions: DocumentInfoModalQueryProps['versions']
) {
  const history = useHistory()
  const location = useLocation()
  const { shareID } = useParams<
    RouteParams<'SHARE_VIEW'> | RouteParams<'SHARE_PAGE_VIEW'>
  >()
  const { openShareSettingsModal } = useSharingModal(share)
  const { openDocumentInfoModal } = useDocumentInfoModal({
    share,
    versions,
  })

  const handleSettingsUrlParam = () => {
    const urlParams = new URLSearchParams(location.search)
    const showSettings = urlParams.get('settings') === shareID

    if (showSettings) {
      /* Prevent a refresh from showing the settings again */
      history.replace(routes.SHARE_VIEW.create({ shareID }))

      const userCanSeeSettings = share.userCanUpdateSettings

      if (userCanSeeSettings) {
        const shouldOpenSharingModal = urlParams.get('tab') === 'sharing'
        if (shouldOpenSharingModal) {
          openShareSettingsModal({ closeOnRouteChange: false })
        } else {
          openDocumentInfoModal()
        }
      }
    }
  }

  // Run on component mounts only
  useEffect(() => {
    handleSettingsUrlParam()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

import React from 'react'
import { dataIdFromObject } from '@sketch/graphql-cache'

import { handleFetchMore, InfiniteList } from '@sketch/components'

import { UserPermissionRow } from '../../components/UserPermissionRow'

import {
  UserPermissionsTabContent,
  buildPlaceholderArray,
  EmptyContent,
} from './UserPermissionsContent'
import { useManageUserProjectPermissions } from './useManageUserPermissions'

import { ReactComponent as FolderIcon } from '@sketch/icons/folder-closed-16'
import { ReactComponent as TwoPersonsIcon } from '@sketch/icons/persons-two-16'
import { ReactComponent as LockIcon } from '@sketch/icons/lock-16'
import { EditProjectMemberPermissionsAccessSelect } from 'modules/projects/components/ProjectMemberPermissionsAccessSelect/ProjectMemberPermissionsAccessSelect'

import { useUpdateProjectMember } from 'modules/projects/hooks/useUpdateProjectMember/useUpdateProjectMember'
import { UserAccessProjectMembershipFragment } from '@sketch/gql-types'
import { useUserProfile } from '@sketch/modules-common'

type ProjectPermissionsTabProps = {
  workspaceIdentifier: string
  workspaceMembershipIdentifier: string
  rows: number
}

const ProjectPermissionsRow = ({
  membership,
  workspaceIdentifier,
  workspaceMembershipIdentifier,
}: {
  membership: UserAccessProjectMembershipFragment
  workspaceIdentifier: string
  workspaceMembershipIdentifier: string
}) => {
  const { accessLevelProps } = useUpdateProjectMember({ member: membership })
  const { data } = useUserProfile(true)

  const projectIcon = {
    GUESTS: TwoPersonsIcon,
    INVITED_WORKSPACE_MEMBERS: LockIcon,
    WORKSPACE_MEMBERS: FolderIcon,
  }

  const isCurrentUser =
    data?.me.identifier === membership.workspaceMembership.user?.identifier

  return (
    <UserPermissionRow
      key={membership.identifier}
      name={membership.project?.name}
      icon={projectIcon[membership.project.privacyIcon]}
      isCurrentUser={isCurrentUser}
      actions={
        <EditProjectMemberPermissionsAccessSelect
          project={membership.project}
          member={membership}
          onAction={() => {
            /* There's no point of showing anything here because this only triggers onCancel */
          }}
          workspaceIdentifier={workspaceIdentifier}
          workspaceMembershipIdentifier={workspaceMembershipIdentifier}
          {...accessLevelProps}
        />
      }
    />
  )
}

export const ProjectPermissionsTab = ({
  workspaceIdentifier,
  rows,
  workspaceMembershipIdentifier,
}: ProjectPermissionsTabProps) => {
  const projects = useManageUserProjectPermissions({
    workspaceIdentifier,
    workspaceMembershipIdentifier,
  })

  const total = projects.total
  const projectsLength = projects?.data?.length || 0

  if (projects.loading && projectsLength === 0) {
    return (
      <UserPermissionsTabContent
        rows={rows}
        onSearch={projects.setProjectSearch}
      >
        {buildPlaceholderArray(2)}
      </UserPermissionsTabContent>
    )
  }

  if (!projects.loading && total === 0) {
    return (
      <UserPermissionsTabContent
        rows={rows}
        onSearch={projects.setProjectSearch}
      >
        {projects.isUsingFilter ? (
          <EmptyContent
            title="No search results"
            subtitle="No project names matched your search."
          />
        ) : (
          <EmptyContent
            title="No projects"
            subtitle="This person hasn’t been added to any projects yet."
          />
        )}
      </UserPermissionsTabContent>
    )
  }

  return (
    <UserPermissionsTabContent rows={rows} onSearch={projects.setProjectSearch}>
      <InfiniteList
        renderLoading={() => null}
        placeholderItems={buildPlaceholderArray(total - projectsLength)}
        onLoadMore={handleFetchMore(projects.fetchMore, projects.path, {
          dataIdFromObject,
          after: projects.after,
        })}
        canLoadMore={projects.after !== null}
        data-testid="projects-permissions-tab"
      >
        {projects.data?.map(membership => (
          <ProjectPermissionsRow
            key={membership.identifier}
            membership={membership}
            workspaceIdentifier={workspaceIdentifier}
            workspaceMembershipIdentifier={workspaceMembershipIdentifier}
          />
        ))}
      </InfiniteList>
    </UserPermissionsTabContent>
  )
}

import { Form as Form$1 } from './Form.js';
import './FormFooter/index.js';
import './FormField/index.js';
import './FormLabel/index.js';
import './FormError/index.js';
import './FormErrorField/index.js';
import { FormFooter } from './FormFooter/FormFooter.js';
import { FormField } from './FormField/FormField.js';
export { FormField } from './FormField/FormField.js';
import { StripeFormField } from './FormField/StripeFormField.js';
export { StripeFormField } from './FormField/StripeFormField.js';
import { FormLabel } from './FormLabel/FormLabel.js';
export { FormLabel } from './FormLabel/FormLabel.js';
import { FormError } from './FormError/FormError.js';
export { FormError } from './FormError/FormError.js';
import { FormErrorField } from './FormErrorField/FormErrorField.js';

const Form = Object.assign(Form$1, {
    Footer: FormFooter,
    Field: FormField,
    StripeField: StripeFormField,
    Label: FormLabel,
    Error: FormError,
    ErrorField: FormErrorField,
});

export { Form };

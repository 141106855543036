import * as Sentry from '@sentry/browser';
import { getMainDefinition } from 'apollo-utilities';

const getOperationName = (query) => {
    var _a;
    try {
        const node = getMainDefinition(query);
        if (node.kind === 'FragmentDefinition') {
            return { name: node.name.value, operation: 'fragment' };
        }
        return { name: (_a = node.name) === null || _a === void 0 ? void 0 : _a.value, operation: node.operation };
    }
    catch (_b) {
        return { name: undefined, operation: undefined };
    }
};
const captureCacheMiss = (query, options, result) => {
    var _a;
    try {
        if (result.data &&
            !result.loading &&
            !result.error &&
            Object.keys(result.data).length === 0) {
            Sentry.withScope(scope => {
                const { name, operation } = getOperationName(query);
                scope
                    .setExtra('options', JSON.stringify(options))
                    .setExtra('operation_name', name)
                    .setExtra('operation_type', operation);
                Sentry.captureMessage(`Cache miss noticed using ${operation} ${JSON.stringify(name || query).slice(0, 500)}`);
            });
        }
    }
    catch (err) {
        (_a = Sentry === null || Sentry === void 0 ? void 0 : Sentry.captureException) === null || _a === void 0 ? void 0 : _a.call(Sentry, err);
    }
};

export { captureCacheMiss };

import isFunction from 'lodash.isfunction';

const renderConditionally = (children, state) => {
    if (isFunction(children)) {
        return children(state) || null;
    }
    return state ? children : null;
};

export { renderConditionally };

import { __rest } from 'tslib';
import { jsx, jsxs } from 'react/jsx-runtime';
import React, { useRef, useEffect, useImperativeHandle } from 'react';
import styled, { css } from 'styled-components';
import '../DocumentHead/index.js';
import '../ErrorBoundary/index.js';
import '../HeaderOld/index.js';
import { useContainerRefScroll } from '@sketch/utils';
import { DocumentHead } from '../DocumentHead/DocumentHead.js';
import { HeaderOld } from '../HeaderOld/Header.js';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary.js';

const PageContent = styled.div `
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: space-between;

  /* stylelint-disable declaration-block-no-duplicate-properties */
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  /* stylelint-enable */
`;
const focusedContainerCss = css `
  height: 100vh;

  /* Workaround to make 100vh work on safari, see polyfills.js */
  height: calc(var(--vh, 1vh) * 100);

  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  overflow: auto;

  :focus {
    outline: none;
  }
`;
const FocusedContainerDiv = styled.div `
  ${focusedContainerCss}

  background-color: ${({ darkBackground, theme }) => darkBackground ? theme.colors.background.secondary.A : 'transparent'};
`;
const FocusedContainer = React.forwardRef(function FocusedContainer({ darkBackground, children }, ref) {
    const focusRef = useRef(null);
    const { setContainerRef } = useContainerRefScroll();
    useEffect(() => {
        setContainerRef(focusRef);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusRef]);
    // Allow keyboard scroll for the main area
    useEffect(() => {
        var _a, _b;
        // #split-layout-content is the wrapper that has scroll
        const content = (_a = focusRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('[data-content-scrollable]');
        // We want to focus on the scrolling area only if there are not another
        // element focused, we know it because if there's no focus then
        // document.activeElement points to <body/>
        if (!content || ((_b = document.activeElement) === null || _b === void 0 ? void 0 : _b.tagName) !== 'BODY') {
            return;
        }
        content.focus({ preventScroll: false });
    }, []);
    useImperativeHandle(ref, () => (Object.assign(Object.assign({}, focusRef.current), { scrollTo: (...args) => {
            var _a;
            (_a = focusRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(...args);
        } })));
    return (jsx(FocusedContainerDiv, Object.assign({ ref: focusRef, darkBackground: darkBackground }, { children: children })));
});
const PageLayout = React.forwardRef(function PageLayout(_a, ref) {
    var { className, title, header, children, footer, showHeader = true, darkBackground = false, isRefreshedUi = false } = _a, props = __rest(_a, ["className", "title", "header", "children", "footer", "showHeader", "darkBackground", "isRefreshedUi"]);
    return (jsxs(FocusedContainer, Object.assign({ ref: ref, darkBackground: darkBackground }, { children: [jsx(DocumentHead, { title: title }), showHeader && jsx(HeaderOld, { children: header }), jsxs(ErrorBoundary, { children: [jsx(PageContent, Object.assign({ className: className }, { children: children })), footer] })] })));
});

export { PageLayout };

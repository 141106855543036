import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useModalContext, Section, Link, Banner } from '@sketch/components';
import '../../modals/Setup2FAModal/index.js';
import '../../modals/Disable2FAModal/index.js';
import '../../modals/RegenerateRecoveryCodesModal/index.js';
import { Status, Shield, StyledLink, StyledButton, StatusList } from './TwoFactorAuthenticationPanel.styles.js';
import { RegenerateRecoveryCodesModal } from '../../modals/RegenerateRecoveryCodesModal/RegenerateRecoveryCodesModal.js';
import { Setup2FAModal } from '../../modals/Setup2FAModal/Setup2FAModal.js';
import { Disable2FAModal } from '../../modals/Disable2FAModal/Disable2FAModal.js';

/**
 * Renders the Panel with the Two Factor Authentication controls
 * Allows the user to enable,disable and generate new codes the 2FA
 */
const TwoFactorAuthenticationPanel = ({ mfaState, isVerified, }) => {
    const { showModal } = useModalContext();
    const renderMfaState = () => {
        if (mfaState === 'DISABLED') {
            return null;
        }
        if (mfaState === 'ENABLED') {
            return (jsxs(Fragment, { children: [jsxs(Status, { children: [jsx(Shield, { status: mfaState }), " 2FA Enabled"] }), jsxs("div", { children: ["Lost your recovery codes?", jsx(StyledLink, Object.assign({ onClick: e => {
                                    e.preventDefault();
                                    showModal(RegenerateRecoveryCodesModal);
                                }, isUnderlined: true, external: true }, { children: "Generate new" }))] })] }));
        }
    };
    const handleMFA = () => {
        if (mfaState === 'DISABLED') {
            showModal(Setup2FAModal);
        }
        if (mfaState === 'ENABLED') {
            showModal(Disable2FAModal);
        }
    };
    const getButtonCopy = () => {
        if (mfaState === 'ENABLED') {
            return 'Disable 2FA…';
        }
        return 'Enable 2FA…';
    };
    const variant = mfaState === 'DISABLED' ? 'primary' : 'negative-secondary';
    return (jsx(Section, Object.assign({ title: "Two-Factor Authentication (2FA)", text: jsxs("div", { children: ["Two-factor authentication protects your account by adding an extra security step when you sign in.", ' ', jsx(Link, Object.assign({ href: "http://www.sketch.com/docs/workspaces/managing-your-sketch-account/#two-factor-authentication-2fa", target: "_blank", isUnderlined: true, external: true }, { children: "Learn more" }))] }), action: jsx(StyledButton, Object.assign({ variant: variant, onClick: handleMFA, size: "40", disabled: !isVerified }, { children: getButtonCopy() })) }, { children: isVerified ? (jsx(StatusList, { children: renderMfaState() })) : (jsx(Banner, Object.assign({ type: "warning" }, { children: "Verify your email to enable two-factor authentication (2FA)." }))) })));
};

export { TwoFactorAuthenticationPanel };

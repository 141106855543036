import React from 'react'

import ProjectName from 'components/ProjectName'
import { BreadcrumbItem, BreadcrumbsContainer } from './Breadcrumbs.styles'
import {
  ProjectFragment,
  ShareInfoFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

export interface WorkspaceForBreadcrumbs
  extends Pick<WorkspaceMinimalFragment, 'name'> {}
export interface ProjectFragmentForBreadcrumbs
  extends Pick<ProjectFragment, 'type' | 'name' | 'identifier'> {}

export interface ShareForBreadcrumbs
  extends Pick<ShareInfoFragment, 'name' | 'identifier'> {
  workspace: WorkspaceForBreadcrumbs | null
  project: ProjectFragmentForBreadcrumbs | null
}

export interface BreadcrumbsProps {
  workspace?: WorkspaceForBreadcrumbs
  project?: ProjectFragmentForBreadcrumbs
  share?: ShareForBreadcrumbs
  className?: string
}

const BreadcrumbsContent = ({
  share,
  workspace,
  project,
}: BreadcrumbsProps) => {
  const breadcrumbWorkspace = workspace || share?.workspace
  const breadcrumbProject = project || share?.project

  return (
    <>
      {breadcrumbWorkspace && (
        <BreadcrumbItem
          key={breadcrumbWorkspace.name}
          data-testid="share-settings-owner-name"
        >
          {breadcrumbWorkspace.name}
        </BreadcrumbItem>
      )}
      {breadcrumbProject?.name && (
        <BreadcrumbItem key={breadcrumbProject.identifier}>
          <ProjectName project={breadcrumbProject} />
        </BreadcrumbItem>
      )}
      {share?.name && (
        <BreadcrumbItem key={share.identifier}>{share.name}</BreadcrumbItem>
      )}
    </>
  )
}

export const Breadcrumbs = ({
  share,
  workspace,
  project,
  className,
}: BreadcrumbsProps) => (
  <BreadcrumbsContainer className={className}>
    <BreadcrumbsContent share={share} workspace={workspace} project={project} />
  </BreadcrumbsContainer>
)

function isPublicAccessLevel(value) {
    const none = 'NONE';
    const view = 'VIEW';
    return value === none || value === view;
}
function isPrivateAccessLevel(value) {
    const edit = 'EDIT';
    const view = 'VIEW';
    return value === edit || value === view;
}
function isWorkspaceAccessLevel(value) {
    const editLevel = 'DEFAULT';
    const viewLevel = 'VIEW';
    return value === editLevel || value === viewLevel;
}
const getTriggerCopyFromAccessLevel = (level) => {
    switch (level) {
        case 'DEFAULT':
            return 'Default';
        case 'EDIT':
            return 'Edit';
        case 'VIEW':
            return 'View';
        case 'NONE':
            return 'No Access';
        case 'PENDING':
            return 'Request Pending';
    }
};

export { getTriggerCopyFromAccessLevel, isPrivateAccessLevel, isPublicAccessLevel, isWorkspaceAccessLevel };

import React from 'react'

import { Tooltip } from '@sketch/components'
import { VersionLink } from 'modules/versioning'

import { ReactComponent as PlayIcon16 } from '@sketch/icons/play-16'
import { ReactComponent as PlayIcon24 } from '@sketch/icons/play-24'
import { PlayButtonNavbar, PlayButtonToolbar } from './PlayPrototypeLink.styles'

interface PlayPrototypeLinkProps {
  isPrototypePlayEnabled: boolean
  shareIdentifier: string
  artboardUUID: string
  representation: 'toolbar' | 'navbar'
}

const PlayPrototypeLink = (props: PlayPrototypeLinkProps) => {
  const {
    isPrototypePlayEnabled,
    shareIdentifier,
    artboardUUID,
    representation,
  } = props

  const content =
    representation === 'navbar' ? (
      <PlayButtonNavbar data-testid="play-prototype" as="span">
        <PlayIcon24 role="img" />
        <span className="sr-only">Play prototype</span>
      </PlayButtonNavbar>
    ) : (
      <PlayButtonToolbar data-testid="play-prototype" as="span">
        Prototype <PlayIcon16 role="img" />
      </PlayButtonToolbar>
    )

  if (isPrototypePlayEnabled) {
    return (
      <VersionLink
        to={{
          routeKey: 'PROTOTYPE_PLAYER',
          routeParams: {
            shareID: shareIdentifier,
            currentArtboardUUID: artboardUUID,
            prototypeArtboardUUID: artboardUUID,
          },
        }}
      >
        {content}
      </VersionLink>
    )
  }

  return (
    <Tooltip
      spacing="14px"
      content={
        <span>
          Prototype playback is not available when browsing artboard versions
        </span>
      }
      placement="bottom"
    >
      {content}
    </Tooltip>
  )
}

export default PlayPrototypeLink

import { jsxs, jsx } from 'react/jsx-runtime';
import { useRef, useState, useEffect } from 'react';
import { Wrapper, HiddenInput, Digits, Digit, Error } from './VerificationCodeInput.styles.js';

/**
 * VerificationCode
 *
 * Renders the 6-digit 2FA verification code inputs
 * By default it renders 6 digit inputs
 */
const VerificationCodeInput = ({ autoFocus = true, size = 6, isDisabled, error, onFilled, className, }) => {
    const hiddenInput = useRef(null);
    const [value, setValue] = useState('');
    const [isFocused, setIsFocused] = useState(autoFocus);
    const [localError, setLocalError] = useState(error);
    /* Autofocus hidden input to allow the user to start typing right away */
    useEffect(() => {
        if (hiddenInput.current && autoFocus) {
            hiddenInput.current.focus();
            hiddenInput.current.scrollIntoView();
        }
    }, [autoFocus]);
    /* Set local error with "error" prop if it changes */
    useEffect(() => {
        if (error) {
            setLocalError(error);
        }
        // Clear the input value to allow the user to quickly type a new one again
        const timeout = setTimeout(() => setValue(''), 500);
        return () => {
            clearTimeout(timeout);
        };
    }, [error]);
    /* Trigger onFilled when user fills the last digit */
    useEffect(() => {
        if (value.length === size) {
            onFilled === null || onFilled === void 0 ? void 0 : onFilled(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [size, value]);
    // Always send the cursor to the last character when focusing the input
    useEffect(() => {
        if (isFocused) {
            const timeout = setTimeout(() => {
                var _a;
                (_a = hiddenInput.current) === null || _a === void 0 ? void 0 : _a.setSelectionRange(value.length, value.length);
            }, 1);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [isFocused, value.length]);
    const handleChange = (event) => {
        // Filter string and get only digits, slice for the first 6 characters
        const onlyDigits = event.target.value.replace(/\D/g, '').slice(0, size);
        setValue(onlyDigits);
        // Clear error
        if (value.length < size) {
            setLocalError(undefined);
        }
    };
    /* Focus on the hidden input when the user clicks the digits*/
    const handleClick = () => {
        if (hiddenInput.current) {
            hiddenInput.current.focus();
            // Scroll to the input to make sure it is visible
            // Avoids the keyboard from covering the input in a mobile device
            window.requestAnimationFrame(() => {
                hiddenInput.current.scrollIntoView();
            });
        }
    };
    const handleFocus = () => {
        setIsFocused(true);
    };
    const handleBlur = () => {
        setIsFocused(false);
    };
    return (jsxs(Wrapper, Object.assign({ className: className }, { children: [jsx(HiddenInput, { "data-testid": "verification-code", type: "text", "aria-label": "Verification Code", inputMode: "numeric", autoComplete: "one-time-code", pattern: "\\d{6}", ref: hiddenInput, onChange: handleChange, onFocus: handleFocus, onBlur: handleBlur, disabled: isDisabled, value: value }), jsx(Digits, Object.assign({ onClick: handleClick }, { children: [...Array(size)].map((_, index) => {
                    // Keep the next empty digit in active state
                    // If its last digit, keep it active even if filled
                    const isActive = index + 1 === size && value.length === size
                        ? true
                        : value.length === index;
                    return (jsx(Digit, Object.assign({ "data-testid": "verification-code-digit", onClick: handleClick, "$isActive": isFocused && isActive, "$isDisabled": isDisabled, "$hasError": !!localError && !!value.length }, { children: value[index] }), index));
                }) })), !!localError && jsx(Error, { children: localError })] })));
};

export { VerificationCodeInput };

import React from 'react'

import {
  ToggleWrapper,
  IconWrapper,
  StyledChevron,
  RemoveFromInviteItem,
} from './RoleDropdown.styles'
import { Dropdown, Checkbox, FlatButton, RadioButton } from '@sketch/components'
import MemberBadge from '../MemberBadge/MemberBadge'

interface DropdownToggleProps {
  showBadges?: boolean
  isAdmin?: boolean
  isOwner?: boolean
  disabled?: boolean
  isEmbedded?: boolean
}

/**
 * DropdownToggle
 *
 * Renders a clickable text for the dropdown component
 */
export const DropdownToggle: React.FC<DropdownToggleProps> = ({
  children,
  disabled,
  isEmbedded,
  showBadges,
  isAdmin,
  isOwner,
}) => {
  return (
    <ToggleWrapper type="button" disabled={disabled} $isEmbedded={isEmbedded}>
      {children}
      {showBadges && (
        <>
          {isOwner && <MemberBadge badge="owner" />}
          {isAdmin && <MemberBadge badge="admin" />}
        </>
      )}

      <IconWrapper>
        <StyledChevron />
      </IconWrapper>
    </ToggleWrapper>
  )
}

interface RoleDropdownProps {
  className?: string
  isEditor: boolean
  isAdmin: boolean
  isOwner?: boolean
  isRemovable?: boolean
  isDisabled?: boolean
  /** Disables the Editor Radio button */
  isEditorDisabled?: boolean
  /** Control badges visibility */
  showBadges?: boolean
  /** Controll admin checkbox visibility */
  showAdminToggle?: boolean
  /** To use within an input field */
  isEmbedded?: boolean
  onEditor?: () => void
  onViewer?: () => void
  onToggleAdmin?: () => void
  onRemove?: () => void
}

const getToggleCopy = (isEditor: boolean, isEmbeded?: boolean) => {
  const viewerOrEditor = isEditor ? 'Editor' : 'Viewer'

  return isEmbeded ? `Add as ${viewerOrEditor}` : viewerOrEditor
}
/**
 * RoleDropdown
 *
 * Renders a button to:
 * * Select a user role (Editor / Viewer)
 * * Remove a user
 */
export const RoleDropdown: React.FC<RoleDropdownProps> = ({
  onViewer,
  onEditor,
  onToggleAdmin,
  onRemove,
  isEditor,
  isAdmin,
  isOwner,
  isRemovable,
  isDisabled,
  className,
  showBadges,
  showAdminToggle,
  isEmbedded,
  isEditorDisabled,
}) => {
  const handleEditor = () => onEditor?.()
  const handleViewer = () => onViewer?.()
  const handleRemove = () => onRemove?.()
  const handleAdminToggle = () => onToggleAdmin?.()

  const toggleCopy = getToggleCopy(isEditor, isEmbedded)

  return (
    <Dropdown
      className={className}
      disabled={isDisabled}
      placement="bottom-end"
      contentPadding="12px 0"
      maxWidth="266px"
      usePortal
      toggle={
        <DropdownToggle
          data-testid="role-dropdown"
          disabled={isDisabled}
          showBadges={showBadges}
          isEmbedded={isEmbedded}
          isAdmin={isAdmin}
          isOwner={isOwner}
        >
          {toggleCopy}
        </DropdownToggle>
      }
    >
      <Dropdown.Header>Access</Dropdown.Header>
      <Dropdown.Item className={!isEditor ? 'active' : ''}>
        <Dropdown.ItemDescription>
          <RadioButton
            checked={!isEditor}
            onChange={handleViewer}
            name="selected"
            label="Viewer"
            help="Browse, inspect and comment on documents in the web app only"
          />
        </Dropdown.ItemDescription>
      </Dropdown.Item>
      <Dropdown.Item className={isEditor ? 'active' : ''}>
        <Dropdown.ItemDescription>
          <RadioButton
            checked={isEditor}
            onChange={handleEditor}
            name="selected"
            label="Editor"
            help="All of the above, and create and edit documents in the Mac app"
            disabled={isEditorDisabled}
          />
        </Dropdown.ItemDescription>
      </Dropdown.Item>

      {showAdminToggle && (
        <>
          <Dropdown.Divider />
          <Dropdown.Header>Role</Dropdown.Header>
          <Dropdown.Item onClick={handleAdminToggle}>
            <Dropdown.ItemDescription>
              <Checkbox
                checked={isAdmin}
                onChange={handleAdminToggle}
                alt="checkbox"
                label={isAdmin ? 'Remove as Admin' : 'Admin'}
                help={
                  isAdmin
                    ? 'Can no longer manage the Workspace, add or remove members, and edit roles'
                    : 'Can manage the Workspace, add or remove members, and edit roles'
                }
              />
            </Dropdown.ItemDescription>
          </Dropdown.Item>
        </>
      )}

      {isRemovable && (
        <>
          <Dropdown.Divider />
          <RemoveFromInviteItem onClick={handleRemove}>
            <FlatButton variant="negative" size="40">
              Remove from Invites…
            </FlatButton>
          </RemoveFromInviteItem>
        </>
      )}
    </Dropdown>
  )
}

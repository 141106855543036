import { __rest } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import styled, { css } from 'styled-components';
import { breakpoint } from '@sketch/global-styles';
import '../Box/index.js';
import '../Heading/index.js';
import { Box } from '../Box/BoxSystem.js';
import { Heading } from '../Heading/Heading.js';

const cardResting = css `
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.background.secondary.A};
`;
const PanelContainer = styled(Box) `
  background-clip: padding-box;
  overflow: hidden;
`;
const PanelSectionContainer = styled.section `
  background-clip: padding-box;
`;
const PanelHeaderOuter = styled(Box) `
  padding: 16px 16px 0;

  ${breakpoint('sm') `
    padding: 44px 48px 0;
  `}

  justify-content: space-between;
  align-items: center;
`;
const PanelTitle = styled(Heading.H1)(({ theme }) => css `
    display: block;
    width: 100%;

    font-size: ${theme.fontSizes.G};
    color: inherit;

    margin: 0;
    text-transform: none;
    word-break: break-word;

    span {
      display: block;
    }
  `);
const PanelHeader = (_a) => {
    var { children, extra } = _a, props = __rest(_a, ["children", "extra"]);
    return (jsxs(PanelHeaderOuter, Object.assign({}, props, { children: [jsx(PanelTitle, { children: children }), extra] })));
};
const PanelBody = styled.div `
  padding: 16px;

  ${breakpoint('sm') `
    padding: 0 48px;

    ${PanelHeaderOuter} + & {
        padding-top: 16px;
    }
  `};

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  &:last-child {
    border-bottom: none;
  }
`;
const PanelFooter = styled.div(({ theme }) => css `
    padding: 16px;

    background-color: ${theme.colors.background.secondary.A};
    border-radius: 0 0 12px 12px; /* stylelint-disable-line scales/radii */
    border-bottom: none;

    text-align: right;
  `);
const Panel = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (jsx(PanelContainer, Object.assign({}, props, { children: children })));
};
const PanelSection = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (jsx(PanelSectionContainer, Object.assign({}, props, { children: children })));
};

export { Panel, PanelBody, PanelFooter, PanelHeader, PanelSection, cardResting };

import { jsxs, jsx } from 'react/jsx-runtime';
import { keyWithoutModifier } from '@sketch/utils';
import { useState } from 'react';
import styled from 'styled-components';
import { useKey } from 'react-use';
import '../Button/index.js';
import { Modal } from '../Modal/index.js';
import { Button } from '../Button/Button.js';

const HeroWrapper = styled.div `
  border-radius: 16px 16px 0 0;
`;
const ConfirmationDialog = ({ title, hideModal, cancelButton: cancelButtonProp, onHide, confirmButton: confirmButtonProp, onConfirm, onCancelAction, children, hero, }) => {
    const [isTabPressed, setIsTabPressed] = useState(false);
    const cancelButton = Object.assign({ text: 'Cancel', disabled: false }, cancelButtonProp);
    const confirmButton = Object.assign({ variant: 'negative' }, confirmButtonProp);
    const handleCancel = () => {
        onHide === null || onHide === void 0 ? void 0 : onHide();
        hideModal();
        if (onCancelAction) {
            // This callback serves to execute an action
            // right after the modal is closed.
            // Needed to open chained modals in ProjectSharingModal.tsx
            onCancelAction();
            return;
        }
    };
    const handleConfirmOnEnterPressed = () => {
        // In order to mantain "Cancel" and "Confirm" buttons accessible when
        // navigating with "tab", it's only possible to confirm when "Enter" is
        // pressed before using "tab" navigation.
        // The usage of "tab" will disable this functionality.
        if (!isTabPressed)
            onConfirm();
    };
    const handleTabPressed = () => {
        setIsTabPressed(true);
    };
    useKey(keyWithoutModifier('Enter'), handleConfirmOnEnterPressed, {
        event: 'keyup',
    });
    useKey(keyWithoutModifier('Tab'), handleTabPressed, { event: 'keyup' });
    return (jsxs(Modal, Object.assign({ onCancel: handleCancel }, { children: [hero && jsx(HeroWrapper, { children: hero }), jsx(Modal.Header, { children: title }), jsx(Modal.Body, { children: children }), jsxs(Modal.Footer, { children: [jsx(Button, Object.assign({ onClick: handleCancel, disabled: cancelButton.disabled, variant: cancelButton.variant }, { children: cancelButton.text })), !confirmButton.isHidden && (jsx(Button, Object.assign({ "data-testid": "confirmation-button", variant: confirmButton.variant, loading: confirmButton.loading, disabled: confirmButton.disabled, onClick: onConfirm }, { children: confirmButton.text })))] })] })));
};

export { ConfirmationDialog };

class CustomElementsObserver {
    constructor() {
        this.entries = [];
        this.listeners = [];
        this.observe = (callback, options = {}) => {
            const { buffer = true } = options;
            this.listeners.push(callback);
            if (buffer) {
                this.entries.forEach(x => callback(x));
            }
            return () => {
                this.listeners.splice(this.listeners.indexOf(callback), 1);
            };
        };
        this.pushEntry = (entry) => {
            const fullEntry = Object.assign(Object.assign({}, entry), { startTime: performance.now() });
            this.entries.push(fullEntry);
            this.listeners.forEach(callback => callback(fullEntry));
        };
    }
}
const customElementsObserver = new CustomElementsObserver();

export { CustomElementsObserver, customElementsObserver };

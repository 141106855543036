import { __awaiter } from 'tslib';
import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useRef, useState } from 'react';

/**
 * This component lets the user select a file when performing any other action,
 * like pressing a button or selecting an item in a dropdown button.
 */
const FileSelection = ({ children, onFileSelected, accept, }) => {
    const fileUploadRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const onFileChanged = (event) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        event.preventDefault();
        const file = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (file) {
            setLoading(true);
            yield onFileSelected(file);
            setLoading(false);
        }
    });
    return (jsxs(Fragment, { children: [jsx("input", { "data-testid": "file-selection", type: "file", accept: accept, ref: fileUploadRef, onChange: onFileChanged, hidden: true }), children({
                onSelectFile: () => {
                    var _a;
                    if (fileUploadRef.current)
                        fileUploadRef.current.value = '';
                    (_a = fileUploadRef.current) === null || _a === void 0 ? void 0 : _a.click();
                },
                loading,
            })] }));
};

export { FileSelection };

import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useGetIdentifiers } from 'modules/shares/hooks/useGetIdentifiers'
import { MobileHeader } from './MobileHeader'
import { useForBigScreen } from '@sketch/components'

export const Header: React.FC = () => {
  const { path } = useRouteMatch()
  const { shareIdentifier, versionShortId } = useGetIdentifiers()
  const isBigScreen = useForBigScreen()
  const isSmallerThanBigScreen = !isBigScreen

  if (isSmallerThanBigScreen) {
    return (
      <MobileHeader
        shareID={shareIdentifier}
        path={path}
        versionShortId={versionShortId}
      />
    )
  }

  return null
}

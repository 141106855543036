import { createContext, useContext } from 'react';
import { ErrorHandler } from '@sketch/tracing';

const defaultFailFn = () => {
    const message = 'AnalyticsProvider was not mounted but useAnalytics was used.';
    if (process.env.REACT_APP_ENV === 'dev' ||
        process.env.REACT_APP_ENV === 'test') {
        throw new Error(message);
    }
    ErrorHandler.shouldNeverHappen(message);
};
const defaultFailContext = {
    trackEvent: defaultFailFn,
    trackMetric: defaultFailFn,
    trackSimpleAnalytics: defaultFailFn,
    removeSimpleAnalytics: defaultFailFn,
    trackSimpleEvent: defaultFailFn,
    pageLoad: {
        observeCustomElement: defaultFailFn,
    },
};
const analyticsContext = createContext(defaultFailContext);
const useAnalytics = () => {
    const analytics = useContext(analyticsContext);
    return analytics;
};
const AnalyticsProvider = analyticsContext.Provider;

export { AnalyticsProvider, defaultFailContext, useAnalytics };

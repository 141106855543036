import React from 'react'

import { routes } from '@sketch/modules-common'

import {
  BaseResponsiveDropdownProps,
  useResponsiveDropdown,
  SelectDropdownItem,
  SelectDropdownLinkItem,
} from '@sketch/components'

import { icons } from '../../icons'

import { Chevron } from '../BottomMenu/BottomMenu.styles'
import {
  DropdownHeader,
  DropdownWrapper,
  DropdownItem,
} from '../HelpMenu/HelpMenu.styles'

import { WorkspaceMinimalFragment } from '@sketch/gql-types'
import { useIsProfileCreated } from 'modules/community/hooks'

interface SettingsMenuProps {
  workspace: WorkspaceMinimalFragment
  permissions: {
    isAdmin: boolean
    showWorkspaceSettingsBilling: boolean
    showWorkspaceSettingsGeneral: boolean
    showWorkspaceSettingsPeople: boolean
    showWorkspacePermissionGroup: boolean
    showWorkspaceSettingsSSO: boolean
  }
}

interface SettingsMenuContentProps extends BaseResponsiveDropdownProps {
  showWorkspaceSettingsBilling?: boolean
  showWorkspaceSettingsGeneral?: boolean
  showWorkspaceSettingsPeople?: boolean
  showWorkspacePermissionGroup?: boolean
  showWorkspaceSettingsSSO?: boolean
  isAdmin?: boolean
  workspaceId: WorkspaceMinimalFragment['identifier']
}
/**
 *
 * SettingsMenuContent
 *
 * Renders the content for the "SettingsMenu" menu used on the "Sidebar"
 */
const SettingsMenuContent: React.FC<SettingsMenuContentProps> = ({
  workspaceId,
  showWorkspaceSettingsBilling,
  showWorkspaceSettingsGeneral,
  showWorkspaceSettingsPeople,
  showWorkspacePermissionGroup,
  showWorkspaceSettingsSSO,
  isAdmin,
  currentVariant,
}) => {
  const { isProfileCreated } = useIsProfileCreated(workspaceId)
  const isModalVariant = currentVariant === 'modal'

  return (
    <DropdownWrapper>
      {isModalVariant && <DropdownHeader>Settings</DropdownHeader>}
      {showWorkspaceSettingsGeneral && (
        <DropdownItem>
          <SelectDropdownLinkItem
            icon={icons.GearIcon}
            text="General"
            to={routes.WORKSPACE_SETTINGS_SETTINGS.create({ workspaceId })}
          />
        </DropdownItem>
      )}
      {showWorkspaceSettingsPeople && (
        <DropdownItem>
          <SelectDropdownLinkItem
            icon={icons.EmailIcon}
            text="People"
            to={routes.WORKSPACE_SETTINGS_PEOPLE.create({ workspaceId })}
          />
        </DropdownItem>
      )}
      {showWorkspacePermissionGroup && (
        <DropdownItem>
          <SelectDropdownLinkItem
            icon={icons.GroupCircle}
            text="Groups"
            data-testid="workspace-sidebar-settings-groups-link"
            to={routes.WORKSPACE_SETTINGS_PERMISSION_GROUPS.create({
              workspaceId,
            })}
          />
        </DropdownItem>
      )}
      {isProfileCreated && isAdmin && (
        <SelectDropdownLinkItem
          icon={icons.ControlIcon}
          text="Profile"
          to={routes.WORKSPACE_SETTINGS_PROFILE.create({ workspaceId })}
        />
      )}
      {showWorkspaceSettingsBilling && (
        <DropdownItem>
          <SelectDropdownLinkItem
            icon={icons.Money}
            text="Billing"
            to={routes.WORKSPACE_SETTINGS_BILLING.create({ workspaceId })}
          />
        </DropdownItem>
      )}
      {showWorkspaceSettingsSSO && (
        <DropdownItem>
          <SelectDropdownLinkItem
            icon={icons.Key}
            text="Single Sign-On"
            to={routes.WORKSPACE_SETTINGS_SSO.create({ workspaceId })}
          />
        </DropdownItem>
      )}
    </DropdownWrapper>
  )
}

/**
 *
 * SettingsMenu
 *
 * Renders the Settings menu used in the "BottomMenu" on the "Sidebar"
 *
 */
const SettingsMenu: React.FC<SettingsMenuProps> = ({
  workspace,
  permissions,
}) => {
  const workspaceId = workspace.identifier
  const {
    showWorkspaceSettingsBilling,
    showWorkspaceSettingsGeneral,
    showWorkspaceSettingsPeople,
    showWorkspacePermissionGroup,
    showWorkspaceSettingsSSO,
    isAdmin,
  } = permissions

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: SettingsMenuContent,
    dropdownProps: {
      workspaceId,
      showWorkspaceSettingsBilling,
      showWorkspaceSettingsGeneral,
      showWorkspaceSettingsPeople,
      showWorkspacePermissionGroup,
      showWorkspaceSettingsSSO,
      isAdmin,
    },
    placement: 'right',
    showOnHover: true,
  })

  return (
    <>
      <SelectDropdownItem
        icon={icons.GearIcon}
        text="Settings"
        additional={<Chevron />}
        {...buttonProps}
      />
      {content}
    </>
  )
}

export default SettingsMenu

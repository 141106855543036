import { jsx } from 'react/jsx-runtime';
import { useQueryParams, getIsForInAppPresentation } from '@sketch/modules-common';
import './SsoSignInForCloud/index.js';
import './RedirectingToIdentityProvider/index.js';
import { RedirectingToIdentityProvider } from './RedirectingToIdentityProvider/RedirectingToIdentityProvider.js';
import { SsoSignInForCloud } from './SsoSignInForCloud/SsoSignInForCloud.js';

function SsoSignInView(props) {
    const { useOverrideLayoutProps } = props;
    useOverrideLayoutProps({ variant: 'orange' });
    const { team, shortname } = useQueryParams();
    const isForInAppPresentation = getIsForInAppPresentation();
    if (isForInAppPresentation && (team || shortname)) {
        // SSO Sign in when trying to login with SSO in the Sketch native app.
        return jsx(RedirectingToIdentityProvider, {});
    }
    // SSO Sign in when trying to login with SSO in the web app.
    return jsx(SsoSignInForCloud, Object.assign({}, props));
}

export { SsoSignInView };

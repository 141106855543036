import {
  useGetWebhookDestinationQuery,
  GetWebhookDestinationQuery,
  GetWebhookDestinationQueryVariables,
} from '@sketch/gql-types'

export const useGetWebhookDestination = (
  variables: GetWebhookDestinationQueryVariables
) => {
  const webhookDestinationResult = useGetWebhookDestinationQuery({
    variables,
  })
  const { data: webhookDestinationData, ...rest } = webhookDestinationResult

  return { ...rest, ...normalizeWebhookDestination(webhookDestinationData) }
}

function normalizeWebhookDestination(
  webhookDestinationData: GetWebhookDestinationQuery | undefined
) {
  if (!webhookDestinationData) return {}

  const { webhookDestination } = webhookDestinationData.workspace

  return { webhookDestination }
}

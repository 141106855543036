import remarkParse from 'remark';
import stringify from 'remark-stringify';
import strip from 'strip-markdown';
import { emoji } from '../tokenizers/emoji.js';

const parser = remarkParse()
    .use(emoji)
    .use(strip)
    .use(stringify, { gfm: false });
const stripMarkdownParser = content => parser.processSync(content).contents;

export { stripMarkdownParser };

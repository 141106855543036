import { jsx, Fragment } from 'react/jsx-runtime';
import { createContext, useContext } from 'react';

/**
 * The intended usage of the feature flags is that they always start as
 * turned off (so initial value is always "false"). And are only turned on
 * when we receive a response from the BE (e.g. see User.featureFlags node)
 *
 * If there is a need to turn on particular feature flags before user
 * authenticates consider to rely on a different BE call/response and create
 * a dedicated React context for it using this buildFlags function.
 *
 * However, under any circumstances, never set default value to "true".
 *
 * see https://github.com/sketch-hq/Cloud/issues/5514
 */
const buildFlags = (defaultFlags) => {
    const FlagsContext = createContext(defaultFlags);
    const FlagsProvider = FlagsContext.Provider;
    const useFlag = (name) => {
        const flags = useContext(FlagsContext) || defaultFlags;
        const isEnabled = !!flags[name];
        return isEnabled;
    };
    const Flag = props => {
        const { name, render, fallbackRender, children } = props;
        const isEnabled = useFlag(name);
        if (isEnabled) {
            if (children)
                return jsx(Fragment, { children: children });
            /**
             * there was an extensive discussion whether that should be
             * render() or React.createElement(render).
             * As a conclusion we should use render() as otherwise we are
             * unmounting and mounting our children unexpectedly.
             *
             * https://bohemiancoding.slack.com/archives/CE5JYREUD/p1565861656366700
             */
            if (render)
                return render();
            return null;
        }
        if (fallbackRender)
            return fallbackRender();
        return null;
    };
    return { Flag, useFlag, FlagsContext, FlagsProvider };
};

export { buildFlags };

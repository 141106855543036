import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { inviteRoutes as routes } from './inviteRoutes'
import {
  AcceptProjectInviteByTokenView,
  AcceptShareInviteByInviteTokenView,
  AcceptShareInviteByShareShortIdView,
  acceptShareInviteByInviteTokenRoutes,
} from 'cloud-frontend'
import { useLayoutRouteProps } from 'routing/useLayoutRouteProps'
import { withIndexLayout } from 'layouts/withIndexLayout'

const acceptShareByTokenKeys = Object.keys(
  acceptShareInviteByInviteTokenRoutes
) as (keyof typeof acceptShareInviteByInviteTokenRoutes)[]

export const AcceptInviteRoutes = withIndexLayout(layoutProps => {
  const { to } = useLayoutRouteProps(layoutProps, routes)

  return (
    <Switch>
      <Route {...to('SHARE_INVITE', AcceptShareInviteByShareShortIdView)} />
      <Route {...to('PROJECT_ACCEPT_INVITE', AcceptProjectInviteByTokenView)} />

      {/* TODO: move and flatten out AcceptShareInviteByInviteTokenView routing from AcceptShareInviteByInviteTokenView to AcceptInviteRoutes
       * see: https://github.com/sketch-hq/Cloud/issues/17153
       */}
      <Route
        {...to(acceptShareByTokenKeys, AcceptShareInviteByInviteTokenView)}
      />
    </Switch>
  )
})

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import React from 'react';
import { Container } from './Column.styles.js';

const Column = (_a) => {
    var { children, asListItem } = _a, props = __rest(_a, ["children", "asListItem"]);
    const elements = React.Children.toArray(children);
    return (jsx(Container, Object.assign({}, props, { as: asListItem ? 'li' : undefined }, { children: elements.map(element => {
            if (!element || !React.isValidElement(element))
                return null;
            // Inner <Grid /> components should have full height, in order to
            // maintain the aspect of the Column they are being rendered.
            if ((element === null || element === void 0 ? void 0 : element.type.name) === 'Grid')
                return React.cloneElement(element, {
                    fullHeight: true,
                });
            return element;
        }) })));
};

export { Column };

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { FakeLinkButton as FakeLinkButton$1 } from './FakeLinkButton.styles.js';

/**
 * Button imitating the appearance of a link. It looks like a link but it's
 * a button.
 */
function FakeLinkButton(_a) {
    var { children, isUnderlined = false, variant = 'primary', size, onClick, className } = _a, otherButtonProps = __rest(_a, ["children", "isUnderlined", "variant", "size", "onClick", "className"]);
    return (jsx(FakeLinkButton$1, Object.assign({}, otherButtonProps, { className: className, onClick: onClick, "$size": size, "$variant": variant, "$isUnderlined": isUnderlined }, { children: children })));
}

export { FakeLinkButton };

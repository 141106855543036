import styled, { css } from 'styled-components';
import { breakpoint } from '@sketch/global-styles';

const Container = styled.div(({ gutter, fullHeight }) => css `
    list-style: none;
    margin: 0;
    padding: 0;

    display: grid;
    grid-template-columns: repeat(var(--grid-columns), 1fr);

    grid-gap: ${`${gutter === null || gutter === void 0 ? void 0 : gutter.xs}px`};
    width: 100%;

    /*
  Inner <Grid /> components should have full height, in order to maintain the
  aspect of the Column they are being rendered.

  fullHeight is a private prop. <Column /> component iterates over all its
  children and passes this prop to all <Grid /> components.
  */
    ${fullHeight &&
    css `
      height: 100%;
    `}

    ${breakpoint('sm') `
      grid-gap: ${`${gutter === null || gutter === void 0 ? void 0 : gutter.sm}px`};
    `}

    ${breakpoint('md') `
      grid-gap: ${`${gutter === null || gutter === void 0 ? void 0 : gutter.md}px`};
    `}

    ${breakpoint('lg') `
      grid-gap: ${`${gutter === null || gutter === void 0 ? void 0 : gutter.lg}px`};
    `}

    ${breakpoint('xl') `
      grid-gap: ${`${gutter === null || gutter === void 0 ? void 0 : gutter.xl}px`};
    `}
  `);

export { Container };

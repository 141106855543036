import jwtDecode from 'jwt-decode';

const createPersonalAuthorization = (fragment) => ({
    type: 'personal',
    fragment,
});
const createSsoAuthorization = (workspaceId, fragment) => ({
    type: 'sso',
    workspaceId,
    fragment,
});
const isPersonalAuthorization = (auth) => (auth === null || auth === void 0 ? void 0 : auth.type) === 'personal';
const isSsoAuthorization = (auth) => (auth === null || auth === void 0 ? void 0 : auth.type) === 'sso';
const areAuthorizationIdsEqual = (auth1, auth2) => {
    switch (auth1.type) {
        case 'personal':
            return auth2.type === 'personal';
        case 'sso':
            return auth2.type === 'sso' && auth1.workspaceId === auth2.workspaceId;
    }
};
const getSessionIdFromToken = (auth) => {
    const payload = jwtDecode(auth.fragment.authToken);
    if (!payload)
        return null;
    return payload.ses;
};

export { areAuthorizationIdsEqual, createPersonalAuthorization, createSsoAuthorization, getSessionIdFromToken, isPersonalAuthorization, isSsoAuthorization };

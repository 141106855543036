import React from 'react'
import { Redirect } from 'react-router'

import { localStorageKeys } from '@sketch/constants'
import {
  routes,
  DynamicLoadingPage,
  GenericErrorView,
  useUserAuthorizations,
} from '@sketch/modules-common'

import { getItem } from '@sketch/utils'

import { FallbackWorkspaceRedirectRoute } from './FallbackWorkspaceRedirectRoute'
import WorkspaceAgnosticRedirectRoute from './WorkspaceAgnosticRedirectRoute'
import { NoWorkspaceView } from 'modules/workspace/views/NoWorkspaceView'
import { useGetWorkspaces } from 'modules/workspace/operations'

export const WorkspaceRedirectRoute = () => {
  const userAuthorizations = useUserAuthorizations()

  const lastWorkspaceId = getItem(localStorageKeys.lastWorkspaceIdKey)

  const { authorizations, hasAccessToWorkspace } = userAuthorizations
  const isUserAuthenticated = authorizations.length > 0

  const { loading, data, error, called } = useGetWorkspaces({
    skip: !isUserAuthenticated,
  })

  /**
   * If there's no user session then the user should be
   * redirected to the sign-in page
   */
  if (!isUserAuthenticated) {
    return <Redirect to={routes.SIGN_IN.create({})} />
  }

  if (loading || !called) {
    return <DynamicLoadingPage />
  }

  if (!data || error) {
    return <GenericErrorView error={error} />
  }

  if (data.me.workspaces.length === 0 && !data.me.personalWorkspace) {
    return <NoWorkspaceView />
  }

  /**
   * If there's a user session but no saved last workspace then the user should
   * go to whatever its fallback workspace is
   */
  if (isUserAuthenticated && !lastWorkspaceId) {
    return (
      <FallbackWorkspaceRedirectRoute
        workspaces={data.me.workspaces}
        personalWorkspace={data.me.personalWorkspace ?? undefined}
      />
    )
  }

  /**
   * Otherwise the user should be redirected to the last seen workspace
   */

  const lastWorkspace = data.me.workspaces.find(
    workspace => workspace.identifier === lastWorkspaceId
  )

  if (lastWorkspace && hasAccessToWorkspace(lastWorkspace)) {
    // Finance or Partner role does not have access to documents, so it should
    // be redirected to workspace settings
    if (
      lastWorkspace.userRole === 'FINANCE' ||
      lastWorkspace.userRole === 'PARTNER'
    ) {
      return (
        <WorkspaceAgnosticRedirectRoute
          workspaceId={lastWorkspace.identifier}
          fallbackRoute={routes.WORKSPACE_SETTINGS_PEOPLE.create({
            workspaceId: lastWorkspace.identifier,
          })}
        />
      )
    } else {
      return (
        <WorkspaceAgnosticRedirectRoute
          workspaceId={lastWorkspace.identifier}
          fallbackRoute={routes.WORKSPACE_SHARES.create({
            workspaceId: lastWorkspace.identifier,
          })}
        />
      )
    }
  }

  /**
   * Similar to the case above, but for Personal Workspaces
   * This allows the WORKSPACE_AGNOSTIC_SETTINGS to work in Personal Workspaces
   */
  const personalWorkspaceId = data.me.personalWorkspace?.identifier
  const ownsPersonalWorkspace = !!data.me.personalWorkspace?.userIsOwner

  if (!lastWorkspace && personalWorkspaceId && ownsPersonalWorkspace) {
    return (
      <WorkspaceAgnosticRedirectRoute
        workspaceId={personalWorkspaceId}
        fallbackRoute={routes.WORKSPACE_SETTINGS_PEOPLE.create({
          workspaceId: personalWorkspaceId,
        })}
      />
    )
  }

  /**
   * If the user:
   *  - doesn't have the id of the last workspace seen OR
   *  - has no access to the last workspace seen
   * then it should see the fallback workspace page.
   */
  return (
    <FallbackWorkspaceRedirectRoute
      workspaces={data.me.workspaces}
      personalWorkspace={data.me.personalWorkspace ?? undefined}
    />
  )
}

import styled from 'styled-components';

const Title = styled.h1 `
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.G};
  margin: 24px 0 8px 0;
`;
const Description = styled.p `
  margin-bottom: 16px;
`;

export { Description, Title };

import styled, { css } from 'styled-components'
import { FlatButton } from '@sketch/components'

// the any here is just to avoid a type error when passing the ref to the styled component
export const OptionsTruncated = styled.div<{ ref: any }>`
  max-width: 90%;
  margin-top: 8px;
  display: flex;

  & > * {
    align-items: center;
  }
`

export const MoreOptions = styled(FlatButton).attrs({
  variant: 'primary',
})<{
  left: number
}>(
  ({ theme, left }) => css`
    position: absolute;
    top: 40px;
    left: ${left}px;
    text-decoration: underline;
    font-size: ${theme.fontSizes.C};
    height: 32px;
    padding-left: 0;
    /* background to hide the default ellipsis */
    background-color: ${theme.colors.background.secondary.A};
    vertical-align: middle;
    padding-top: 8px;
  `
)

export const HideOptions = styled(FlatButton).attrs({
  variant: 'primary',
})(
  ({ theme }) => css`
    text-decoration: underline;
    font-size: ${theme.fontSizes.C};
    height: 32px;
  `
)

export const OptionsAll = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;
`

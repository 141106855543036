import styled from 'styled-components';
import { breakpoint } from '@sketch/global-styles';
import { Button, Text } from '@sketch/components';

const StyledButton = styled(Button) `
  margin-left: 16px;

  ${breakpoint('base', 'sm') `
    margin-left: 0px;
  `}
`;
const SsoPermissionsCallout = styled(Text.Div).attrs({
    textStyle: 'copy.tertiary.standard.D',
}) `
  display: flex;
  border-radius: ${({ theme }) => theme.radii.large};
  border: 1px solid ${({ theme }) => theme.colors.border.B};
  padding: 12px 16px;
  margin-bottom: 48px;

  & svg {
    margin: 4px 8px 0 0;
  }
`;

export { SsoPermissionsCallout, StyledButton };

import { jsx } from 'react/jsx-runtime';
import { PrototypeLoadingPlaceholder } from '@sketch/components';
import styled from 'styled-components';

/**
 * TODO: reuse code between Layout and LoadingPage
 * https://github.com/sketch-hq/Cloud/issues/3543
 *
 * Also, if you are changing Container or LoadingStateContainer
 * change PrototypeView as well
 */
const Container = styled.div `
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  height: 100%;

  /* This needs to be hard coded (dark) because the background doesn't changge between themes */
  background-color: #212121;
`;
const LoadingStateContainer = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;
const PrototypeLoadingPage = ({ progress, }) => {
    return (jsx(Container, { children: jsx(LoadingStateContainer, { children: jsx(PrototypeLoadingPlaceholder, { progress: progress }) }) }));
};

export { PrototypeLoadingPage };

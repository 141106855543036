import React, { FC } from 'react'
import styled from 'styled-components'

import { Link, showDropdownStyles } from '@sketch/components'

import Artboard from '../Artboard'
import PrototypeCardDropdown from '../PrototypeCardDropdown'

import { ReactComponent as PlayIcon } from '@sketch/icons/play-prism-32'
import { LocationDescriptorObject } from 'history'
import { useVersioning } from 'modules/versioning'

type PlayProps = {
  size: number
}

const Play = styled(PlayIcon)<PlayProps>`
  opacity: 0;
  position: absolute;
  margin: auto;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  box-sizing: content-box;

  transition: opacity 200ms linear;
`

const Prototype = styled(Artboard)`
  ${showDropdownStyles};

  :hover,
  :focus,
  :focus-within {
    ${Play} {
      opacity: 1;
    }
  }
`

export interface PrototypeCardProps {
  artboardUUID: string
  title: string
  src: string
  showTitle?: boolean
  showDropdown?: boolean
  iconSize?: 32 | 48
}

const PrototypeCard: FC<PrototypeCardProps> = ({
  artboardUUID,
  title,
  src,
  showTitle,
  showDropdown = true,
  iconSize = 48,
}) => {
  const { getPathname, share } = useVersioning()

  const pathname: string = getPathname({
    routeKey: 'PROTOTYPE_PLAYER',
    routeParams: {
      shareID: share.identifier,
      prototypeArtboardUUID: artboardUUID,
      currentArtboardUUID: artboardUUID,
    },
  })

  const to: LocationDescriptorObject = { pathname }

  return (
    <Link data-testid="prototype-card-link" to={to}>
      <Prototype
        title={title}
        imageSrc={src}
        showTitle={showTitle}
        overlay={
          <>
            {showDropdown && <PrototypeCardDropdown pathname={pathname} />}
            <Play size={iconSize} />
          </>
        }
      />
    </Link>
  )
}

export default PrototypeCard

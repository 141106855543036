import styled, { css } from 'styled-components'
import { Footer as BaseFooter } from '@sketch/modules-common'
import { Link, TableComponents } from '@sketch/components'
import { withRefreshedUiFF } from 'utils'

import { DocumentGridItem, GridFocusStyles } from '../DocumentItem'

import { breakpoint } from '@sketch/global-styles'

const headerStyles = css<{ $isRefreshedUi?: boolean }>(
  ({ theme, $isRefreshedUi }) => css`
    top: 54px; /* Height of the Header + the Margin */
    margin-bottom: 30px; /* stylelint-disable-line scales/space */
    /* Allows the last row to be half visible when scrolling */
    background: ${$isRefreshedUi
      ? theme.colors.background.secondary.B
      : theme.colors.background.secondary.A};
    border-bottom: 1px solid ${theme.colors.border.A};
    z-index: ${theme.zIndex[2]};

    ::before {
      position: absolute;
      content: '';
      background: ${$isRefreshedUi
        ? theme.colors.background.secondary.B
        : theme.colors.background.secondary.A};
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      z-index: -1;

      /* Create the spacing of the margin */
      padding-top: 16px;
      box-sizing: content-box;

      ${breakpoint('sm')`
        padding-top: 40px;
      `}
    }

    ${breakpoint('sm')`
      position: sticky;
      top: ${
        $isRefreshedUi ? 92 : 78
      }px; /* Height of the Header + the Margin */
    `}
  `
)

export const HeaderTableCell = withRefreshedUiFF(styled(
  TableComponents.TableHeaderCell
)`
  ${headerStyles}
`)

export const ActionTableCell = withRefreshedUiFF(styled(
  TableComponents.TableHeaderCell
)`
  ${headerStyles};

  width: 64px;

  /* Just for hiding the text */
  background: ${({ theme, $isRefreshedUi }) =>
    $isRefreshedUi
      ? theme.colors.background.secondary.B
      : theme.colors.background.secondary.A};
`)

export const TableWrapper = styled.div<{
  $hideProjects: boolean
  $hideCollections?: boolean
}>(
  ({ theme, $hideProjects, $hideCollections }) => css`
    margin: 0 -16px 16px;
    table-layout: fixed;

    ${TableComponents.TableCellSticky} {
      /* AvatarStack has a z-index of 2 to actually show the stacked
      logos, so we need to do this to prevent the avatar go over the sticky cell */
      z-index: 2; /* stylelint-disable-line scales/z-indices */
      right: 0;
    }

    ${breakpoint('sm')`
    margin: 0 -40px 40px;
  `}

    /* Compensate for the Margin and make the table scroll without blank sides */
    ${TableComponents.Table} {
      min-width: 800px;
      padding-left: 16px;

      ${breakpoint('sm')`
        padding: 0 40px;
      `}
    }

    ${TableComponents.TableHeaderCell} {
      &:nth-child(1),
      &:nth-child(4) {
        width: 30%;
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: 20%;
      }
    }

    ${TableComponents.TableRow} {
      cursor: pointer;
    }

    ${TableComponents.TableRow} td:last-child {
      text-align: right;
      width: 60px;

      ${breakpoint('lg')`
        width: 60px;
      `}
    }

    ${breakpoint('base', 'sm')`
    overflow: auto;
    width: 100vw;

    ${TableComponents.TableHeaderCell}:not:last-child {
      min-width: 120px;
      max-width: 200px;
    }

    ${TableComponents.TableHeaderCell}:last-child {
      right: 0;
      width: 60px;

      ${breakpoint('base', 'sm')`
        color: ${theme.colors.background.secondary.A}; /** Just for hiding the text */
      `}
    }
  `}

    ${$hideProjects &&
    css`
      ${TableComponents.TableHeaderCell}:nth-child(2) {
        display: none;
      }

      ${TableComponents.TableRow} ${TableComponents.TableCell}:nth-child(2) {
        display: none;
      }

      /* We need this to make the action column here
        to be at the end of the table */
      && {
        ${TableComponents.TableHeaderCell} {
          width: 33%;
        }
      }
    `}

    ${$hideCollections &&
    css`
      ${TableComponents.TableHeaderCell}:nth-child(3) {
        display: none;
      }

      ${TableComponents.TableRow} ${TableComponents.TableCell}:nth-child(3) {
        display: none;
      }

      /* We need this to make the action column here
        to be at the end of the table */
      && {
        ${TableComponents.TableHeaderCell} {
          width: 33%;
        }
      }
    `}
  `
)

export const FocusLink = styled(Link)`
  display: block; /* Needed to avoid cutting focus ring when using a keyboard */

  &:focus ${DocumentGridItem} {
    ${GridFocusStyles};
  }
`

export const Footer = styled(BaseFooter)`
  margin-top: auto;
`

import styled, { css } from 'styled-components'

import { Text } from '@sketch/components'

import { ReactComponent as WorkspaceIcon } from '@sketch/icons/workspaces-24'

export const NoWorkspacesDropdown = styled.div<{ isPartner?: boolean }>`
  cursor: ${({ isPartner }) => (isPartner ? 'default' : 'pointer')};
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
`

export const NoWorkspacesAvatar = styled.div`
  ${({ theme }) => css`
    height: 24px;
    width: 24px;

    background-color: ${theme.colors.background.tertiary.B};
    border-radius: 4px;
  `}
`

export const NoWorkspacesTitle = styled(Text.Span).attrs({
  textStyle: 'copy.quaternary.standard.E',
})`
  ${({ theme }) => css`
    flex: 1;
    margin-left: 12px;
    font-weight: ${theme.fontWeights.medium};
  `}
`

export const NoWorkspacesIcon = styled.div`
  ${({ theme }) => css`
    width: 8px;
    margin: 4px 8px 0 0;
    color: ${theme.colors.state.disabled};
  `}
`

export const NoWorkspaceEmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0px 16px;
`

export const NoWorkspaceIcon = styled(WorkspaceIcon)`
  opacity: 0.5;
`

export const Title = styled(Text).attrs({
  textStyle: 'copy.primary.standard.C',
})`
  margin-top: 8px;
`

export const Description = styled(Text).attrs({
  textStyle: 'copy.quaternary.standard.C',
})`
  margin-top: 4px;
`

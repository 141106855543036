import React, { FC, useMemo, useState } from 'react'
import {
  Button,
  Modal,
  ModalInjectedProps,
  RadioButton,
  SelectDropdown,
  SelectDropdownItem,
  Text,
  ToastLink,
  WorkspaceLogo,
} from '@sketch/components'

import {
  DraftsWorkspaceFragment,
  ProjectFragment,
  ShareListItemFragment,
  TransferShareMutationVariables,
  useGetShareQuery,
  useGetWorkspaceQuery,
  useGetWorkspacesDraftsProjectsQuery,
} from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'
import { routes } from '@sketch/modules-common'
import {
  DangerRadioButton,
  HeaderItem,
  Item,
  MovingTextWrapper,
  Note,
  StyledSeparator,
  WarningWrapper,
} from './MoveShareToMyDrafts.styles'
import ShareAccessList from 'modules/shares/components/ShareAccessList'
import { useMoveShareTo } from 'modules/shares/operations/useMoveShareTo'
import { getWorkspaceMoveCompletedToast } from '../MoveShareToProject/MoveShareToProject'
import { ErrorHandler } from '@sketch/tracing'

type MembershipsTransferOperation = TransferShareMutationVariables['membershipsOperation']

export interface MoveShareToMyDraftsProps extends ModalInjectedProps {
  workspaceIdentifier: string
  share: Pick<ShareListItemFragment, 'identifier' | 'name'>
  draftsProject: ProjectFragment
}

interface SelectOtherWorkspaceDraftsProps {
  loading: boolean
  showTransferOptions: boolean
  ssoEnabled: boolean
  isDisabled: boolean
  workspaces: DraftsWorkspaceFragment[]

  selectedWorkspace: DraftsWorkspaceFragment | undefined
  setSelectedWorkspace: React.Dispatch<
    React.SetStateAction<DraftsWorkspaceFragment | undefined>
  >

  membershipsOperation: string
  setMembershipsOperation: React.Dispatch<React.SetStateAction<string>>
}

const SelectOtherWorkspaceDrafts: React.FC<SelectOtherWorkspaceDraftsProps> = ({
  loading: isLoading,
  selectedWorkspace,
  setSelectedWorkspace,
  membershipsOperation,
  setMembershipsOperation,
  showTransferOptions,
  ssoEnabled,
  workspaces,
  isDisabled,
}) => {
  const handleTransferMembershipsOptionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMembershipsOperation(e.target.value)
  }

  const placeholder = 'Select a Workspace…'
  const disabled = isLoading || isDisabled
  const hasNoElligibleDraftsProjects = !workspaces?.length

  return (
    <>
      <SelectDropdown
        placeholder={placeholder}
        disabled={disabled}
        items={workspaces}
        value={selectedWorkspace}
        onChange={workspace => {
          setSelectedWorkspace(workspace)
        }}
        headerItem={
          <>
            <HeaderItem>Workspaces</HeaderItem>
            {hasNoElligibleDraftsProjects && (
              <Item text="No Workspaces available" disabled />
            )}
          </>
        }
        renderValueText={workspace => workspace.name}
        renderItem={(workspace, { setItem, a11yProps }) => (
          <SelectDropdownItem
            text={workspace.name}
            icon={({ className }) => (
              <WorkspaceLogo
                className={className}
                workspaceName={workspace.name}
                src={workspace.avatar?.small}
                size="24px"
              />
            )}
            onClick={() => setItem(workspace)}
            {...a11yProps}
          />
        )}
      />
      <Note>
        As an Admin you can move documents to My Drafts in this or other
        Workspaces.{' '}
      </Note>

      {/* We only show this text when the destination workspace is different from the current one  */}
      {showTransferOptions && (
        <MovingTextWrapper>
          <Text>Do you want to continue sharing this document?</Text>
          <RadioButton
            name="transfer-option"
            label="Yes, share it with the same people"
            checked={membershipsOperation === 'TRANSFER_NON_SSO'}
            value="TRANSFER_NON_SSO"
            onChange={handleTransferMembershipsOptionChange}
          />
          <DangerRadioButton
            name="transfer-option"
            label="No, remove previously invited people"
            checked={membershipsOperation === 'DELETE_ALL'}
            value="DELETE_ALL"
            onChange={handleTransferMembershipsOptionChange}
          />
          {ssoEnabled ? (
            <WarningWrapper>
              People with a Single Sign-On (SSO) account won’t be transferred.
            </WarningWrapper>
          ) : null}
        </MovingTextWrapper>
      )}
    </>
  )
}

export const MoveShareToMyDrafts: FC<MoveShareToMyDraftsProps> = props => {
  const [
    selectedWorkspace,
    setSelectedWorkspace,
  ] = useState<DraftsWorkspaceFragment>()
  const [membershipsOperation, setMembershipsOperation] = useState('')

  const { showToast } = useToast()

  const { workspaceIdentifier, share, draftsProject, hideModal } = props

  const { data } = useGetWorkspaceQuery({
    variables: { identifier: workspaceIdentifier },
  })

  const isAdminCurrentWorkspace = data?.workspace.userRole === 'ADMIN'
  const isEditorCurrentWorkspace = data?.workspace.userCanEdit

  const {
    data: workspacesDrafsProjects,
    loading: isLoadingWorkspaceDrafts,
  } = useGetWorkspacesDraftsProjectsQuery({
    skip: !isAdminCurrentWorkspace,
  })

  // Query Share to grab some workspace and project data that the "share" prop doesn't have
  const { data: shareData, loading: isLoadingShare } = useGetShareQuery({
    variables: { shortId: share.identifier },
  })

  const currentWorkspace = shareData?.share?.workspace
  const isInDrafts = shareData?.share?.project?.type === 'PERSONAL_DRAFTS'

  // We only allow the user to select workspaces that are active and the user is an Editor
  // We also filter out Workspaces that share is already in the My Drafts project
  // because we cannot move a drafts document to the My Drafts project it is already in
  const workspaces = useMemo(() => {
    return (
      workspacesDrafsProjects?.me.workspaces.filter(workspace => {
        const isEditor = workspace.userCanEdit
        const isActive = workspace.status === 'ACTIVE'

        const shareIsInWorkspace =
          shareData?.share?.workspace.identifier === workspace.identifier

        // If we are trying to move a document that is already in the drafts document
        // of the workspace, we filter the workspace out (you cant move a draft document to a draft project that is already in)
        const shareIsDraftInWorkspace = isInDrafts && shareIsInWorkspace

        if (!isEditor || !isActive || shareIsDraftInWorkspace) {
          return false
        }

        return true
      }, []) ?? []
    )
  }, [
    isInDrafts,
    shareData?.share?.workspace.identifier,
    workspacesDrafsProjects?.me.workspaces,
  ])

  const singleWorkspaceDraftsAvailable = workspaces.length === 1

  const targetWorkspace = singleWorkspaceDraftsAvailable
    ? workspaces[0]
    : selectedWorkspace

  const targetDraftsProject = targetWorkspace?.draftsProject.entries[0]

  const {
    moveShareToProject,
    moveShareToWorkspace,
    loading: isLoadingMoveShare,
  } = useMoveShareTo({
    onWorkspaceTransferCompleted: data => {
      const { share: updatedShare } = data.transferShare
      const updatedShareWorkspace = updatedShare.workspace

      showToast(
        getWorkspaceMoveCompletedToast(
          updatedShare.project,
          updatedShareWorkspace
        )
      )
      hideModal()
    },
    onProjectTransferCompleted: project => {
      showToast(
        <>
          Document moved to &ldquo;
          <ToastLink
            to={routes.WORKSPACE_PROJECT.create({
              projectId: project.identifier,
              workspaceId: workspaceIdentifier,
            })}
          >
            {project.name}
          </ToastLink>
          &rdquo;
        </>
      )
      hideModal()
    },
  })

  const handleClick = () => {
    const sameWorkspace =
      targetWorkspace?.identifier === currentWorkspace?.identifier

    const isNonAdminEditor =
      !isAdminCurrentWorkspace && isEditorCurrentWorkspace

    if (sameWorkspace || isNonAdminEditor) {
      moveShareToProject({
        shareId: share.identifier,
        projectId: draftsProject.identifier,
      })

      return
    }

    if (!targetWorkspace || !targetDraftsProject) {
      ErrorHandler.shouldNeverHappen(
        'No target Workspace or Drafts project to transfer document to'
      )

      return
    }

    moveShareToWorkspace({
      shareIdentifier: share.identifier,
      projectIdentifier: targetDraftsProject.identifier,
      workspaceIdentifier: targetWorkspace.identifier,
      membershipsOperation: membershipsOperation as MembershipsTransferOperation,
    })
  }

  const loading =
    isLoadingMoveShare || isLoadingShare || isLoadingWorkspaceDrafts

  const showTransferOptions = Boolean(
    targetDraftsProject &&
      currentWorkspace &&
      targetWorkspace.identifier !== currentWorkspace.identifier
  )

  const shareTotalCount = shareData?.share?.memberships?.meta.totalCount
  const ssoEnabled = !!currentWorkspace?.customer?.ssoEnabled

  const singleDifferentWorkspace =
    singleWorkspaceDraftsAvailable &&
    targetWorkspace?.identifier !== currentWorkspace?.identifier

  const isAdminWithSelectedWorkspace =
    isAdminCurrentWorkspace && selectedWorkspace

  const showWorkspaceSelect =
    isAdminCurrentWorkspace &&
    (!singleWorkspaceDraftsAvailable ||
      // Shows the Workspace Select when the only available workspace
      // is not the same workspace the user is in
      singleDifferentWorkspace)

  const showAccessList =
    !!shareTotalCount &&
    (!isAdminCurrentWorkspace ||
      isAdminWithSelectedWorkspace ||
      singleWorkspaceDraftsAvailable)

  // Move is disabled if user is admin and:
  // - didn't select a workspace when viable
  // - didn't select the permission option
  const isMoveDisabled =
    (isAdminCurrentWorkspace &&
      showTransferOptions &&
      !membershipsOperation.length) ||
    (isAdminCurrentWorkspace &&
      !singleWorkspaceDraftsAvailable &&
      !selectedWorkspace)

  return (
    <Modal title="Move Document to My Drafts" onCancel={hideModal}>
      <Modal.Body>
        <Text>
          {isAdminCurrentWorkspace && !singleWorkspaceDraftsAvailable ? (
            <>
              Choose which Workspace to move <strong>“{share.name}”</strong> and
              all its comments to.
            </>
          ) : (
            <>
              If you move <strong>“{share.name}”</strong> to My Drafts,
              everybody with permissions will be able to access it.
            </>
          )}
        </Text>
        {showWorkspaceSelect && (
          <SelectOtherWorkspaceDrafts
            loading={loading}
            showTransferOptions={showTransferOptions}
            selectedWorkspace={targetWorkspace}
            setSelectedWorkspace={setSelectedWorkspace}
            membershipsOperation={membershipsOperation}
            setMembershipsOperation={setMembershipsOperation}
            ssoEnabled={ssoEnabled}
            workspaces={workspaces}
            isDisabled={singleWorkspaceDraftsAvailable}
          />
        )}
        {showAccessList && (
          <>
            <StyledSeparator />
            <ShareAccessList
              shareIdentifier={share.identifier}
              membershipCount={shareTotalCount}
              destination={{
                project: selectedWorkspace
                  ? selectedWorkspace.draftsProject.entries[0]
                  : draftsProject,
                workspace: selectedWorkspace ? selectedWorkspace : undefined,
              }}
              hideMembersList={membershipsOperation === 'DELETE_ALL'}
              isMovingToDrafts
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal}>Cancel</Button>
        <Button
          variant="primary"
          loading={loading}
          disabled={isMoveDisabled}
          onClick={handleClick}
        >
          Move Document
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

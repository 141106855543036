import { jsx } from 'react/jsx-runtime';
import './components/Crumb/index.js';
import { List, Link } from './OrderedBreadcrumbs.styles.js';
import { Crumb } from './components/Crumb/Crumb.js';

const OrderedBreadcrumbs = ({ crumbs, currentCrumb = 0, className, showAllInMobile, }) => {
    if (!crumbs.length) {
        return null;
    }
    return (jsx(List, Object.assign({ className: className }, { children: crumbs.map((crumb, index) => (jsx(Crumb, Object.assign({ number: index + 1, isActive: currentCrumb === index, isCompleted: index < currentCrumb, showAllInMobile: showAllInMobile }, { children: currentCrumb !== index && currentCrumb > index && crumb.url ? (jsx(Link, Object.assign({ to: crumb.url, variant: "secondary" }, { children: crumb.content }))) : (crumb.content) }), index))) })));
};

export { OrderedBreadcrumbs };

import React, { useMemo } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { routes, useFlag } from '@sketch/modules-common'

import { useGetDocumentListSettingsQuery } from '@sketch/gql-types'
import { useHideCollectionsSetting } from 'modules/collections/hooks'

import { Button } from '@sketch/components'

import {
  Wrapper,
  Title,
  Description,
  CallToActionWrapper,
  IconWrapper,
  PageFiller,
} from './EmptyState.styles'

// Icon
import { ReactComponent as DocumentIcon } from '@sketch/icons/document-64'
import { ReactComponent as DocumentPlaceholderIcon } from '@sketch/icons/document-placeholder-64'
import { ReactComponent as LinkLibraryIcon } from '@sketch/icons/link-library-64'
import { ReactComponent as PersonCircle } from '@sketch/icons/person-circle-64'
import { ReactComponent as SearchIcon } from '@sketch/icons/search-64'
import { ReactComponent as NotesIcon } from '@sketch/icons/notes.64'
import { ReactComponent as TrashBinIcon } from '@sketch/icons/trash-bin-64'
import { ReactComponent as WorkspaceIcon } from '@sketch/icons/workspace-64'
import { ReactComponent as SymbolIcon } from '@sketch/icons/symbol-64'
import { ReactComponent as LayerStyleIcon } from '@sketch/icons/paint-brush-64'
import { ReactComponent as TextStyleIcon } from '@sketch/icons/text-format-64'
import { ReactComponent as ColorVariableIcon } from '@sketch/icons/color-swatch-64'
import { ReactComponent as UploadIcon } from '@sketch/icons/uploading-64'
import { ReactComponent as TemplateIcon } from '@sketch/icons/template-64'
import { ReactComponent as GroupCircle } from '@sketch/icons/group-circle-64'

const ICON_BY_NAME = {
  document: DocumentIcon,
  documentPlaceholder: DocumentPlaceholderIcon,
  link: LinkLibraryIcon,
  search: SearchIcon,
  notes: NotesIcon,
  'person-circle': PersonCircle,
  'trash-bin': TrashBinIcon,
  workspace: WorkspaceIcon,
  symbol: SymbolIcon,
  layerStyle: LayerStyleIcon,
  textStyle: TextStyleIcon,
  colorVariable: ColorVariableIcon,
  upload: UploadIcon,
  template: TemplateIcon,
  'group-circle': GroupCircle,
}

interface EmptyStateLayoutProps {
  title: React.ReactNode
  description: React.ReactNode
  callToAction?: React.ReactNode
  icon?: keyof typeof ICON_BY_NAME
}

const EmptyStateLayout: React.FC<EmptyStateLayoutProps> = props => {
  const { title, description, callToAction, icon } = props

  const Icon = ICON_BY_NAME[icon || 'document']

  return (
    <PageFiller>
      <Wrapper>
        <IconWrapper>
          <Icon />
          <span className="sr-only">{icon} icon</span>
        </IconWrapper>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        {callToAction && (
          <CallToActionWrapper>{callToAction}</CallToActionWrapper>
        )}
      </Wrapper>
    </PageFiller>
  )
}

const ResetSearchButton: React.FC<{}> = () => {
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const { updateQuery } = useGetDocumentListSettingsQuery()
  const { setHideCollections } = useHideCollectionsSetting()

  const isWorkspaceSharesRoute = useRouteMatch({
    path: routes.WORKSPACE_SHARES.template(),
    exact: true,
  })
  const isProjectRoute = useRouteMatch({
    path: [
      routes.WORKSPACE_PROJECT.template(),
      routes.WORKSPACE_TRASH_PROJECT.template(),
      routes.WORKSPACE_DRAFTS.template(),
    ],
    exact: true,
  })

  const resetSearch = () => {
    searchParams.delete('search')
    searchParams.delete('filters')
    history.replace({ search: searchParams.toString() })

    if (isWorkspaceSharesRoute) {
      updateQuery(previous => ({
        ...previous,
        showDocumentsInProjects: true,
      }))
    }

    if (isProjectRoute) {
      setHideCollections(false)
    }
  }

  return (
    <Button variant="tertiary" onClick={resetSearch}>
      Reset Search
    </Button>
  )
}

interface EmptyStateProps extends EmptyStateLayoutProps {
  search?: string
  filters?: string[]
}

const EmptyState: React.FC<EmptyStateProps> = ({
  search,
  filters = [],
  ...props
}) => {
  const isDesignSystemsOn = useFlag('design-systems')

  const isArchiveRoute = useRouteMatch({
    path: routes.WORKSPACE_ARCHIVE.template(),
    exact: true,
  })
  const isWorkspaceLibrariesRoute = useRouteMatch({
    path: routes.WORKSPACE_LIBRARIES.template(),
    exact: true,
  })
  const isDesignSystems = useRouteMatch({
    path: routes.DESIGN_SYSTEM.template(),
  })

  if (search || filters.length > 0) {
    const isLibrariesRoute = isWorkspaceLibrariesRoute || isDesignSystems
    const entityName =
      isDesignSystemsOn && isLibrariesRoute
        ? 'libraries'
        : isArchiveRoute
        ? 'projects'
        : 'documents'

    const title = `No ${entityName} matched your filter`
    const description = `Try adjusting your search term or filters to find the ${entityName} you're looking for`
    const callToAction = <ResetSearchButton />

    return (
      <EmptyStateLayout
        icon="search"
        title={title}
        description={description}
        callToAction={callToAction}
      />
    )
  }

  return <EmptyStateLayout {...props} />
}

export default EmptyState

import { ColorFormat } from 'modules/shares/types'
import useLocalStorage from 'react-use/lib/useLocalStorage'

export function useUserColorFormat() {
  const [userColor, setUserColor] = useLocalStorage(
    'inspector_color_format',
    ColorFormat.HEX
  )

  return [userColor ?? ColorFormat.HEX, setUserColor] as const
}

import React from 'react'
import copy from 'copy-to-clipboard'
import { useLocation, useRouteMatch } from 'react-router-dom'

import { routes, isProjectTrashRoute } from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'

import {
  Dropdown,
  useModalContext,
  useResponsiveDropdown,
  Box,
  useForTablet,
} from '@sketch/components'
import {
  DeleteCollectionModal,
  ManageCollectionModal,
} from 'modules/collections/modals'
import { MoveAllToAction } from './MoveAllToAction'

import {
  DropdownButton,
  DropdownButtonEllipsis,
} from 'modules/shares/components/DocumentItem/DocumentItem.styles'

import {
  CollectionPreviewsFragment,
  ProjectFragment,
  ProjectInSidebarAndHeaderFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'
import { CollectionHeaderActionsProps } from '../CollectionHeader/types'

interface CopyLinkProps {
  workspaceId: WorkspaceMinimalFragment['identifier']
  projectId: ProjectFragment['identifier']
  collectionId: CollectionPreviewsFragment['identifier']
}

interface DeleteCollectionProps {
  collection: CollectionPreviewsFragment
  project: ProjectFragment
  workspaceId: WorkspaceMinimalFragment['identifier']
}

interface EditCollectionActionProps {
  workspaceIdentifier: WorkspaceMinimalFragment['identifier']
  collection: CollectionPreviewsFragment
  project: ProjectInSidebarAndHeaderFragment
}

export const EditCollectionAction: React.FC<EditCollectionActionProps> = ({
  workspaceIdentifier,
  project,
  collection,
}) => {
  const { showModal } = useModalContext()

  const handleEditCollection = () => {
    showModal(ManageCollectionModal, {
      workspaceIdentifier,
      project,
      collection,
    })
  }

  return (
    <Dropdown.Item unmountAfterClick onClick={handleEditCollection}>
      Edit&hellip;
    </Dropdown.Item>
  )
}

export const CopyCollectionAction: React.FC<CopyLinkProps> = ({
  workspaceId,
  projectId,
  collectionId,
}) => {
  const { showToast } = useToast()

  return (
    <Dropdown.Item
      onClick={() => {
        const projectLink = routes.WORKSPACE_COLLECTION.create({
          workspaceId,
          projectId,
          collectionId,
        })

        copy(`${window.location.origin}${projectLink}`)
        showToast('Link copied')
      }}
    >
      Copy Link
    </Dropdown.Item>
  )
}

export const DeleteCollectionAction: React.FC<DeleteCollectionProps> = ({
  collection,
  project,
  workspaceId,
}) => {
  const { showModal } = useModalContext()
  const location = useLocation()

  // Only redirect to the project if we are currently viewing the collection
  // that will be deleted
  const isViewingCollection =
    location.pathname ===
    routes.WORKSPACE_COLLECTION.create({
      workspaceId,
      projectId: project.identifier,
      collectionId: collection.identifier,
    })

  const redirectTo = isViewingCollection
    ? routes.WORKSPACE_PROJECT.create({
        workspaceId,
        projectId: project.identifier,
      })
    : undefined

  return (
    <Dropdown.Item
      intent="negative"
      onClick={() =>
        showModal(DeleteCollectionModal, {
          workspaceIdentifier: workspaceId,
          collection,
          project,
          redirectTo,
        })
      }
    >
      Remove&hellip;
    </Dropdown.Item>
  )
}

export const CollectionDropdownContent: React.FC<CollectionHeaderActionsProps> = ({
  userCanManageCollection,
  workspace,
  collection,
  project,
}) => {
  const { path } = useRouteMatch()
  const isInTrash = isProjectTrashRoute(path)
  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  return (
    <Box width={isMobile ? '100%' : 212}>
      <Dropdown.Header>Collection</Dropdown.Header>
      {userCanManageCollection && !isInTrash && (
        <EditCollectionAction
          workspaceIdentifier={workspace.identifier}
          collection={collection}
          project={project}
        />
      )}
      <CopyCollectionAction
        workspaceId={workspace.identifier}
        projectId={project.identifier}
        collectionId={collection.identifier}
      />
      <MoveAllToAction
        workspaceId={workspace.identifier}
        projectId={project.identifier}
        originCollection={collection}
      />
      {userCanManageCollection && (
        <>
          <Dropdown.Divider />
          <DeleteCollectionAction
            collection={collection}
            project={project}
            workspaceId={workspace.identifier}
          />
        </>
      )}
    </Box>
  )
}

const CollectionDropdown: React.FC<CollectionHeaderActionsProps> = ({
  userCanManageCollection,
  workspace,
  collection,
  project,
}) => {
  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: CollectionDropdownContent,
    dropdownProps: {
      userCanManageCollection,
      workspace,
      collection,
      project,
    },
    placement: 'bottom-end',
    usePortal: true,
  })

  return (
    <div onClick={event => event.stopPropagation()}>
      <DropdownButton data-testid="collection-dropdown" {...buttonProps}>
        <DropdownButtonEllipsis aria-label="Collection Settings" />
        <span className="sr-only">Collection Settings</span>
      </DropdownButton>
      {content}
    </div>
  )
}

export default CollectionDropdown

import React, { FC, useMemo } from 'react'

// Config
import config from '@sketch/env-config'

import { MAX_ZOOM, MIN_ZOOM } from './constants'

import { useDevToolsSetting } from '@sketch/devtools'

import { useSafariGPUWarning, useSketchWebMetrics } from './hooks'

import { PageCanvasStatus } from 'modules/shares/PageCanvasView/components/PageCanvasStatus'

import {
  DefaultFeatureFlags,
  useStatus,
  WebRendererMode,
  CanvasRendererReact,
} from '@sketch-hq/sketch-web-renderer'

import { useDisableZoom } from '@sketch/modules-common'

import { parseRGBA } from './utils'

import {
  CanvasRenderReactWrapper,
  CanvasStatusWrapper,
} from './WebRendererCanvas.styles'

import { PresentationFileFragment } from '@sketch/gql-types'
import { InitialCameraPosition } from 'modules/shares/PageCanvasView/hooks'

type WebRenderProps = React.ComponentProps<typeof CanvasRendererReact>

interface WebRendererCanvasProps {
  pageUUID: string
  page: PresentationFileFragment
  versionShortId: string
  shareIdentifier: string
  backgroundColor?: string
  webRenderCursor?: WebRenderProps['cursor']
  webRenderContainerProps?: WebRenderProps['containerProps']
  initialPan?: InitialCameraPosition['initialPan']
  initialZoom?: InitialCameraPosition['initialZoom']
  /**
   * Optionally pass in an Artboard UUID to render a specific Artboard, rather
   * than a whole page. Artboard UUIDs map 1:1 with "fragments".
   */
  artboardUUID?: string
  featureFlags?: Partial<typeof DefaultFeatureFlags>
  /**
   * Blur canvas if we have everything to render but something else needed
   * for the view is loading.
   */
  isWaitingForMoreData?: boolean
}

const IS_CYPRESS = 'Cypress' in window
const IS_DEV_OR_TEST_ENV =
  process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'test'

// Certain web renderer features can be enabled/disabled using feature flags
const SKETCH_WEB_FEATURE_FLAGS: typeof DefaultFeatureFlags = {
  ...DefaultFeatureFlags,
  // The dirty rects rendering is a new feature that
  // we're currently testing, but want to keep disabled in prod
  useDirtyRectsRendering: false,
}

export const WebRendererCanvas: FC<WebRendererCanvasProps> = props => {
  const status = useStatus()

  const [devToolsReleaseMode] = useDevToolsSetting('webRenderer.releaseMode')
  const [devToolsShowTilesBorders] = useDevToolsSetting(
    'webRenderer.showTilesBorders'
  )

  useSafariGPUWarning()
  useDisableZoom(status?.type !== 'READY')

  const {
    pageUUID,
    page,
    backgroundColor,
    versionShortId,
    shareIdentifier,
    children,
    webRenderCursor,
    webRenderContainerProps,
    initialPan,
    initialZoom,
    artboardUUID,
    featureFlags,
    isWaitingForMoreData = false,
  } = props

  const presentationFile = page.presentationFile

  const artboardFilePath = useMemo(() => {
    if (!artboardUUID) {
      return ''
    }
    return `${config.web_renderer_image_domain}/presentation/p/${pageUUID}/f/${artboardUUID}?token=${presentationFile?.imageToken}`

    // Don't recompute the filePath if only the presentationFile.imageToken has changed.
    // See https://github.com/sketch-hq/Cloud/issues/17935

    // "versionShortId" was added to force the "artboardFilePath" to be recreated given it was not had in consideration
    // when changing versions on the sidebar when they had already been loaded
    // https://github.com/sketch-hq/Cloud/issues/11944

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artboardUUID, pageUUID, versionShortId])

  const filePath = artboardUUID
    ? artboardFilePath
    : presentationFile?.downloadUrl ?? undefined

  const imagesURLFormat = `${config.web_renderer_image_domain}/presentation/p/${pageUUID}/i/:imageId?token=${presentationFile?.imageToken}&format=:format`
  const fragmentsURLFormat = `${config.web_renderer_image_domain}/presentation/p/${pageUUID}/f/:fragmentId?token=${presentationFile?.imageToken}`

  useSketchWebMetrics({
    shareID: shareIdentifier,
    pageUUID,
    versionShortId,
    filePath,
  })

  // Jumping through some hoops here to stabalize the initialPan object so
  // it does not return a new object reference on every render - not essential
  // but nice to have
  const isIntialPanUndefined = initialPan === undefined
  const initialPanX = initialPan?.x || 0
  const initialPanY = initialPan?.y || 0
  const initialPanCenterPointInViewport =
    initialPan?.centerPointInViewport || false

  const initialPanMemo = useMemo(() => {
    if (isIntialPanUndefined) return undefined

    return {
      x: initialPanX,
      y: initialPanY,
      centerPointInViewport: initialPanCenterPointInViewport,
    }
  }, [
    isIntialPanUndefined,
    initialPanX,
    initialPanY,
    initialPanCenterPointInViewport,
  ])

  const backgroundColorRGBA = useMemo(() => {
    return backgroundColor ? parseRGBA(backgroundColor) : undefined
  }, [backgroundColor])

  /**
   * Choosing the WR Mode:
   * - Always "debug" in Cypress
   * - If we're in dev or test envs defer to the dev tools value
   * - Else "release"
   */
  const mode = IS_CYPRESS
    ? WebRendererMode.debug
    : IS_DEV_OR_TEST_ENV
    ? devToolsReleaseMode
      ? WebRendererMode.release
      : WebRendererMode.debug
    : WebRendererMode.release

  const showTilesBorders = IS_DEV_OR_TEST_ENV ? devToolsShowTilesBorders : false

  /**
   * Only enable this flag in Cypress.
   * @see https://github.com/sketch-hq/Cloud/issues/11052
   * @see https://docs.cypress.io/faq/questions/using-cypress-faq#Is-there-any-way-to-detect-if-my-app-is-running-under-Cypress
   */
  const preserveDrawingBuffer = IS_CYPRESS

  const featureFlagsMemo = useMemo(() => {
    if (!featureFlags) return SKETCH_WEB_FEATURE_FLAGS
    return {
      ...SKETCH_WEB_FEATURE_FLAGS,
      ...featureFlags,
    }
  }, [featureFlags])

  return (
    <>
      <CanvasRenderReactWrapper $isWaitingForMoreData={isWaitingForMoreData}>
        <CanvasRendererReact
          initialPan={initialPanMemo}
          initialZoom={initialZoom}
          filePath={filePath}
          imagesURLFormat={imagesURLFormat}
          fragmentsURLFormat={fragmentsURLFormat}
          mode={mode}
          locateFile={`${process.env.WEB_RENDERER_ASSETS_FOLDER}/{file}`}
          minimumZoomLevel={MIN_ZOOM}
          maximumZoomLevel={MAX_ZOOM}
          backgroundColor={backgroundColorRGBA}
          preserveDrawingBuffer={preserveDrawingBuffer}
          showTilesBorders={showTilesBorders}
          featureFlags={featureFlagsMemo}
          containerProps={webRenderContainerProps}
          cursor={webRenderCursor}
        >
          {children}
        </CanvasRendererReact>
      </CanvasRenderReactWrapper>
      {status?.type !== 'READY' ? (
        <CanvasStatusWrapper data-testid="loading-canvas-overlay">
          <PageCanvasStatus status={status} />
        </CanvasStatusWrapper>
      ) : null}
    </>
  )
}

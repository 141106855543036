import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { downloadFile } from './downloadFile.js';

const DownloadFileAnchor = (_a) => {
    var { href, children } = _a, props = __rest(_a, ["href", "children"]);
    return (jsx("a", Object.assign({ href: href, onClick: e => {
            e.preventDefault();
            downloadFile(href !== null && href !== void 0 ? href : '');
        } }, props, { children: children })));
};

export { DownloadFileAnchor };

import { jsx, jsxs } from 'react/jsx-runtime';
import { ErrorMessage, NotEmbedded, CenterContent, Link } from '@sketch/components';
import styled from 'styled-components';
import { ReactComponent } from '@sketch/icons/logo';
import '../../user/index.js';
import { IsSignedIn } from '../../user/IsSignedIn/IsSignedIn.js';

const StyledErrorMessage = styled(ErrorMessage) `
  flex: 1 0 auto;
  align-self: center;
`;
const InvalidInvitationView = () => (jsx(IsSignedIn, { children: (isSignedIn) => (jsx(NotEmbedded, { children: jsx(CenterContent, { children: jsxs(StyledErrorMessage, Object.assign({ title: "This invitation is no longer valid", icon: () => jsx(ReactComponent, { width: "46px", height: "46px" }) }, { children: [jsx("p", { children: "Please contact the owner of the document." }), isSignedIn ? (jsx(Link, Object.assign({ to: "/documents" }, { children: "Go to your documents overview" }))) : (jsxs("span", { children: [jsx(Link, Object.assign({ to: "/signin" }, { children: "Sign in to Sketch" })), " or", ' ', jsx(Link, Object.assign({ to: "/signup" }, { children: "Create an account" }))] }))] })) }) })) }));

export { InvalidInvitationView };

import { useEffect } from 'react';

const MODAL_SELECTOR = '[data-testid=modal-root]';
const DROPDOWN_SELECTOR = '[data-testid=dropdown-options]';
const OPTION_ITEM = '[data-option-item]';
const SELECT_POPOVER_SELECTOR = '[data-reach-popover]';
const useOnClickOutside = (
/**
 * A ref to the main target element. Any clicks inside this element won't
 * trigger the callback.
 */
ref, 
/**
 * Callback invoked everytime there's a click outside the main target.
 */
handleClickOutside, { includeRefs = [], includeSelectors = [], disabled = false } = {}) => {
    useEffect(() => {
        // If disabled, don't attach any listener
        if (disabled)
            return;
        const handleClick = (e) => {
            const target = e.target;
            if ([ref, ...includeRefs].some(ref => { var _a; return (_a = ref.current) === null || _a === void 0 ? void 0 : _a.contains(target); })) {
                return;
            }
            // Check to see if the click target is nested within one of the
            // includeSelectors. The `closest()` method is used here to ensure the
            // most appropriate instance of the selector match is chosen, in case
            // there are multiple in the DOM
            if (includeSelectors
                .map(selector => { var _a; return !!((_a = target === null || target === void 0 ? void 0 : target.closest) === null || _a === void 0 ? void 0 : _a.call(target, selector)); })
                .includes(true)) {
                return;
            }
            handleClickOutside(e);
        };
        document.body.addEventListener('click', handleClick, { capture: true });
        return () => {
            var _a;
            (_a = document === null || document === void 0 ? void 0 : document.body) === null || _a === void 0 ? void 0 : _a.removeEventListener('click', handleClick, {
                capture: true,
            });
        };
    }, [includeRefs, handleClickOutside, includeSelectors, ref, disabled]);
};

export { DROPDOWN_SELECTOR, MODAL_SELECTOR, OPTION_ITEM, SELECT_POPOVER_SELECTOR, useOnClickOutside };

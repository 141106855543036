import React from 'react'

import { Button, Section, useModalContext } from '@sketch/components'

import {
  InfoTextLine,
  IconWrapper,
  RestrictedPublicLinksWrapper,
} from './WorkspaceSettingsGeneral.styles'
import { ReactComponent as InformationIcon } from '@sketch/icons/information-16'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import { useFlag, useUserProfile } from '@sketch/modules-common'

import {
  WorkspaceSettingsDetailsPanel,
  WebhookConfigurationForm,
} from 'modules/workspace/components'

import {
  DeleteWorkspaceModal,
  DisableUploadsModal,
  EnableUploadsModal,
  ToggleRestrictedPublicLinksModal,
} from 'modules/workspace/modals'

const createContactLink = (details: {
  email: string
  topic: string
  subject: string
  summary: string
}) => {
  const params = new URLSearchParams(details)
  return `${SKETCH_WEBSITE}/support/contact/?${params}`
}

interface WorkspaceSettingsGeneralProps {
  workspaceId: string
  name: string
  avatar: string | undefined
  areUploadsRestricted: boolean
  canUseRestrictedUploads: boolean
  arePublicLinksRestricted: boolean
  userIsOwner?: boolean
}

const WorkspaceSettingsGeneral: React.FC<WorkspaceSettingsGeneralProps> = props => {
  const {
    avatar,
    workspaceId,
    name,
    areUploadsRestricted,
    canUseRestrictedUploads,
    arePublicLinksRestricted,
    userIsOwner,
  } = props

  const { showModal } = useModalContext()
  const { data: userData } = useUserProfile()

  const isPersonalTokenManagementEnabled = useFlag(
    'public-api-manage-personal-tokens'
  )

  const restrictedUploadsContactLink = createContactLink({
    email: userData?.me.email || '',
    topic: 'other',
    subject: 'Restricted Uploads',
    summary: "I'm interested in restricting document uploads on my Workspace.",
  })

  return (
    <>
      <Section title="Workspace">
        <WorkspaceSettingsDetailsPanel
          name={name}
          id={workspaceId}
          avatar={avatar}
          isUserAdministrator={true}
        />
      </Section>

      {isPersonalTokenManagementEnabled && (
        <Section title="Advanced">
          <WebhookConfigurationForm workspaceIdentifier={workspaceId} />
        </Section>
      )}

      <hr />

      <RestrictedPublicLinksWrapper>
        <Section
          title="Public Document Links"
          text={
            arePublicLinksRestricted
              ? 'Shared public document links are currently disabled.'
              : 'Admins and Editors can create and share public document links.'
          }
          action={
            <Button
              onClick={() =>
                showModal(ToggleRestrictedPublicLinksModal, {
                  workspaceId,
                  arePublicLinksRestricted,
                })
              }
              tiny
              variant={
                arePublicLinksRestricted ? 'secondary' : 'negative-secondary'
              }
            >
              {arePublicLinksRestricted
                ? 'Enable Public Links…'
                : 'Disable Public Links…'}
            </Button>
          }
        />
      </RestrictedPublicLinksWrapper>

      {!canUseRestrictedUploads && (
        <Section
          title="Restrict Document Uploads"
          text={
            <p>
              Request to{' '}
              <a href={restrictedUploadsContactLink}>
                restrict document uploads
              </a>{' '}
              for Editors in this Workspace.
            </p>
          }
        />
      )}

      {canUseRestrictedUploads && (
        <>
          {!areUploadsRestricted && (
            <Section
              title="Restrict Document Uploads"
              text="Restrict uploads to this Workspace and save your Sketch documents locally instead. Make sure you tell your Members."
              action={
                <Button
                  onClick={() =>
                    showModal(DisableUploadsModal, { workspaceId })
                  }
                  size="32"
                  variant="secondary"
                >
                  Restrict Uploads…
                </Button>
              }
            >
              <InfoTextLine>
                <IconWrapper>
                  <InformationIcon />
                </IconWrapper>
                <p>
                  Admins can still edit and save documents to the Workspace when
                  this setting is enabled.
                </p>
              </InfoTextLine>
            </Section>
          )}

          {areUploadsRestricted && (
            <Section
              title="Enable Document Uploads"
              text={
                <div>
                  Allow members to upload documents to the Workspace again
                  <InfoTextLine>
                    <IconWrapper>
                      <InformationIcon />
                    </IconWrapper>
                    <p>
                      Document uploads are currently restricted for Editors .
                    </p>
                  </InfoTextLine>
                </div>
              }
              action={
                <Button
                  onClick={() => showModal(EnableUploadsModal, { workspaceId })}
                  size="32"
                  variant="secondary"
                >
                  Enable Uploads…
                </Button>
              }
            />
          )}
        </>
      )}

      {/* "data-testid="danger-zone-panel"" is used to keep consistency with the older panels in tests */}
      {userIsOwner && (
        <>
          <hr />
          <Section
            data-testid="danger-zone-panel"
            title="Delete Workspace"
            text="Delete the Workspace, including all documents and members it contains. Proceed with caution."
            action={
              <Button
                onClick={() =>
                  showModal(DeleteWorkspaceModal, { id: workspaceId, name })
                }
                size="32"
                variant="negative-secondary"
              >
                Delete Workspace…
              </Button>
            }
          />
        </>
      )}
    </>
  )
}

export default WorkspaceSettingsGeneral

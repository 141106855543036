import styled, { css } from 'styled-components';
import '../Box/index.js';
import '../Caption/index.js';
import { ReactComponent } from '@sketch/icons/check-12';
import { Box } from '../Box/BoxSystem.js';
import { Caption as Caption$1 } from '../Caption/Caption.js';

const checkboxSize = '16px';
const CheckmarkIcon = styled(ReactComponent) `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  color: white;
  pointer-events: none;
`;
const Container = styled(Box) `
  display: flex;
  position: relative;
  margin-bottom: 0;
  line-height: 1.4;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
const CheckboxInput = styled.span(({ theme, checked, variant, disabled }) => css `
    display: inline-block;
    vertical-align: middle;
    position: relative;

    width: ${checkboxSize};
    height: ${checkboxSize};
    padding: 0;
    margin-right: 12px;

    &:not(:last-child) {
      margin-right: 12px;
    }

    transition: background-color
      ${`${theme.transitions.duration[1]} ${theme.transitions.timing.easeOut}`};

    border-width: 1px;
    border-style: solid;
    border-color: ${theme.colors.border.B};
    background: ${theme.colors.background.secondary.A};
    border-radius: 3px;

    cursor: ${disabled ? 'not-allowed' : 'pointer'};

    align-items: flex-start;
    justify-content: center;
    flex-shrink: 0;

    /* enough space to have a 1px white line between focus ring and radio button, to give enough contrast */
    outline-offset: 3px;

    ${checked &&
    variant === 'primary' &&
    css `
      border-color: ${theme.colors.sketch.A};
      background-color: ${theme.colors.sketch.A};
    `}

    ${checked &&
    variant === 'untinted' &&
    css `
      border-color: ${theme.colors.background.primary.B};
      background-color: ${theme.colors.background.primary.B};
    `}
  `);
const HiddenInput = styled.input `
  position: absolute;
  width: 100%;
  height: 100%;
  appearance: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
const CheckboxLabel = styled(Box)(({ theme, disabled }) => css `
    font-weight: ${theme.fontWeights.regular};
    opacity: ${disabled ? 0.5 : 1};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    line-height: 1.6;
    color: ${theme.colors.foreground.secondary.B};
  `);
const CheckboxLabelText = styled.span(({ theme }) => css `
    font-size: ${theme.fontSizes.D};
  `);
const Caption = styled(Caption$1)(({ theme }) => css `
    margin: 0;
    font-size: ${theme.fontSizes.D};
    color: ${theme.colors.foreground.secondary.D};
    line-height: 1.4;
    padding-top: 2px; /* stylelint-disable-line scales/space */
  `);
const IconWrapper = styled.div `
  display: inline-flex;
  height: 16px;
  width: 16px;
  position: relative;
`;
const LoadingWrapper = styled.div `
  display: inline-block;
  position: absolute;

  top: -1px;
  left: 8px;
`;
const SuccessWrapper = styled.div(({ theme }) => css `
    position: absolute;
    display: inline-block;

    top: 4px;
    left: 8px;

    color: ${theme.colors.state.positive.A};
  `);

export { Caption, CheckboxInput, CheckboxLabel, CheckboxLabelText, CheckmarkIcon, Container, HiddenInput, IconWrapper, LoadingWrapper, SuccessWrapper };

import { jsx } from 'react/jsx-runtime';
import { useModalContext, Button } from '@sketch/components';
import '../LaunchSketchAlert/index.js';
import { LaunchSketchAlert } from '../LaunchSketchAlert/LaunchSketchAlert.js';

const OpenMacAppButton = (props) => {
    const { label, buttonStyle = 'primary-32' } = props;
    const { showModal } = useModalContext();
    return (jsx(Button, Object.assign({ buttonStyle: buttonStyle, onClick: () => showModal(LaunchSketchAlert, {
            shareId: '',
            action: 'openInSketch',
        }) }, { children: label || 'Open the Mac App' })));
};

export { OpenMacAppButton as default };

import React, { useState } from 'react'
import {
  RouteComponentProps,
  NavLink,
  Route,
  useRouteMatch,
} from 'react-router-dom'
import {
  Segment,
  Navbar,
  BannersDisplay,
  BannerPageWrapper,
  Flex,
  DocumentHeader,
  useBreakpoint,
} from '@sketch/components'
import {
  RouteParams,
  routes,
  useGetNewNotificationsCount,
  useFlag,
} from '@sketch/modules-common'

import NotificationsListByType from 'modules/activity/components/Notifications/NotificationsListByType'
import { WorkspaceSidebarLayoutExtraProps } from 'modules/workspace/components/WorkspaceSidebarLayout/WorkspaceSidebarLayout'
import {
  useNotificationMarkAllAsReadMutation,
  GetNotificationsDocument,
  ParsedError,
  useGetNotificationsQuery,
  GetUserNotificationsCountDocument,
} from '@sketch/gql-types'
import {
  notificationMarkAllAsReadOptimisticResponse,
  getNotificationsQueryOptions,
} from '@sketch/user'
import {
  MarkAllAsReadButton,
  StyledMarkAsReadIcon,
  StyledSkeleton,
  SearchWrapper,
} from './Updates.styles'
import { NotificationsErrorDisplay } from 'modules/activity/components/Notifications/NotificationsErrorBoundary'
import { NotificationsPill } from 'modules/user/components/UserNavbarSection/UserDropdown/UserDropdown'
import { NotificationSearch as NotificationSearchNew } from 'modules/activity/components/Notifications/NotificationSearch/NotificationSearch'
import { NotificationSearch as NotificationSearchOld } from 'modules/activity/components/Notifications/NotificationSearch/NotificationSearchOld'
/**
 * TYPES
 */
export type UpdatesRoutesProps = RouteComponentProps<RouteParams<'UPDATES'>>

type UpdatesViewProps = UpdatesRoutesProps & WorkspaceSidebarLayoutExtraProps

/**
 * MAIN COMPONENT
 */
export const Updates: React.FC<UpdatesViewProps> = ({
  HeaderPortal,
  NavbarPortal,
  HeaderSlimPortal,
  useOverrideLayoutProps,
}) => {
  // TODO: Remove FF "ui-refresh-24" when its released
  // https://github.com/orgs/sketch-hq/projects/326/views/1?pane=issue&itemId=65739330
  const isRefreshedUi = useFlag('ui-refresh-24')

  const NotificationSearch = isRefreshedUi
    ? NotificationSearchNew
    : NotificationSearchOld

  const isTabletOrBigger = useBreakpoint('sm')

  const [error, setError] = useState<ParsedError | null>(null)

  const { data, loading } = useGetNotificationsQuery(
    getNotificationsQueryOptions
  )

  const { path } = useRouteMatch()

  const [markAllNotificationsAsRead] = useNotificationMarkAllAsReadMutation({
    redirectErrors: true,
    onError: setError,
    optimisticResponse: {
      __typename: 'RootMutationType',
      ...notificationMarkAllAsReadOptimisticResponse,
    },
    UNSAFE_ignoreResults: true,
    refetchQueries: [
      { query: GetNotificationsDocument },
      { query: GetUserNotificationsCountDocument },
    ],
    awaitRefetchQueries: true,
  })

  const unreadNotifications = useGetNewNotificationsCount({
    fetchPolicy: 'cache-only',
  })

  const hasNotifications = data?.me.notifications?.entries.length !== 0
  const hasUnreadNotifications = data?.me?.hasUnreadNotifications || false

  useOverrideLayoutProps({
    title: 'Updates',
    hasNavbar: false,
  })

  const navbar = (
    <Navbar.Section ml={2}>
      <Segment
        type="text"
        label="Unread"
        render={(className, children) => (
          <NavLink exact className={className} to={routes.UPDATES.create({})}>
            <Flex alignItems="center">
              {children}
              {hasUnreadNotifications && (
                <Flex pr={2} alignItems="center">
                  <NotificationsPill
                    unreadNotifications={unreadNotifications}
                    withNumberDisplay
                  />
                </Flex>
              )}
            </Flex>
          </NavLink>
        )}
      />
      <Segment
        type="text"
        label="Read"
        disabled={!hasNotifications}
        render={(className, children) => (
          <NavLink
            exact
            className={className}
            to={routes.UPDATES_READ.create({})}
          >
            {children}
          </NavLink>
        )}
      />
      {path !== routes.UPDATES_READ.template() && (
        <Segment
          type="text"
          label="Mark all as read"
          render={(className, children) => {
            if (loading) {
              return <StyledSkeleton width="150px" height="32px" />
            }

            return (
              <MarkAllAsReadButton
                onClick={markAllNotificationsAsRead}
                disabled={!hasNotifications || !hasUnreadNotifications}
              >
                <StyledMarkAsReadIcon />
                Mark all as read
              </MarkAllAsReadButton>
            )
          }}
        />
      )}
    </Navbar.Section>
  )

  return (
    <>
      {isRefreshedUi ? (
        <>
          <HeaderPortal>
            <DocumentHeader
              title="Updates"
              description="Keep track of all comments and notifications about documents you’re working on."
              actions={<>{navbar}</>}
            />
          </HeaderPortal>
          <HeaderSlimPortal>
            <DocumentHeader title="Updates" />
          </HeaderSlimPortal>
          {isTabletOrBigger && (
            <NavbarPortal>
              <SearchWrapper>
                <NotificationSearch />
              </SearchWrapper>
            </NavbarPortal>
          )}
        </>
      ) : (
        <NavbarPortal>{navbar}</NavbarPortal>
      )}

      <Route exact path={[routes.UPDATES.template()]}>
        {error ? (
          <NotificationsErrorDisplay />
        ) : (
          <>
            <BannerPageWrapper $hideMargins="top">
              <BannersDisplay />
            </BannerPageWrapper>
            <NotificationsListByType />
          </>
        )}
      </Route>
      <Route exact path={[routes.UPDATES_READ.template()]}>
        {/* Mounting the banner container to render them all */}
        <BannerPageWrapper $hideMargins="top">
          <BannersDisplay />
        </BannerPageWrapper>
        <NotificationsListByType />
      </Route>
    </>
  )
}

import { useReducer, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import {
  WorkspaceDetails,
  WorkspaceMembers,
  WorkspaceCreationState,
} from '../../types'
import { formatOwnerToMember } from 'modules/workspace/operations/useCreateWorkspace/utils'
import { useUserProfile } from '@sketch/modules-common'

type Action =
  | { type: 'add-workspace-details'; payload: WorkspaceDetails }
  | { type: 'update-workspace-members'; payload: WorkspaceMembers }
  | { type: 'delete-workspace-members-partners'; payload: WorkspaceMembers }

const preventiveEditorRole = (members: WorkspaceMembers) => {
  const [owner, ...otherMembers] = members
  return [{ ...owner, isEditor: true }, ...otherMembers]
}

const reducer = (
  state: WorkspaceCreationState,
  action: Action
): WorkspaceCreationState => {
  const nextState = { ...state }

  switch (action.type) {
    case 'add-workspace-details': {
      return {
        ...nextState,
        details: action.payload,
      }
    }
    case 'delete-workspace-members-partners': {
      return {
        ...nextState,
        members: action.payload,
      }
    }
    case 'update-workspace-members': {
      const hasEditors = action.payload.some(({ isEditor }) => isEditor)

      /**
       * This logic prevents workspaces from missing an editor.
       * According to our business logic, all workspaces must have, at
       * least, one editor. If the last editor member is deleted
       * the owner will have this role by default
       *
       * Added in:
       * https://github.com/sketch-hq/Cloud/issues/4699
       */
      const members = !hasEditors
        ? preventiveEditorRole(action.payload)
        : action.payload

      return {
        ...nextState,
        members,
      }
    }
    default: {
      return state
    }
  }
}

export const useWorkspaceState = (initialState = {}) => {
  const { data } = useUserProfile()

  const user = data!.me

  const [state, dispatch] = useReducer(reducer, {
    members: [formatOwnerToMember(user)],
    billing: {
      email: user.email ?? '',
      name: user.name ?? '',
    },
    details: { name: '' },
    ...initialState,
  })

  /**
   * These will be the triggers that each tab will
   * call during the checkout flow to update the
   * common state between all steps.
   */
  const eventTriggers = useMemo(() => {
    const addWorkspaceDetails = ({ name, logo }: WorkspaceDetails) => {
      const type = 'add-workspace-details'

      dispatch({ type, payload: { name, logo } })
    }

    const deleteWorkspaceMembersPartners = (members: WorkspaceMembers) => {
      const type = 'delete-workspace-members-partners'

      dispatch({ type, payload: members })
    }

    const updateWorkspaceMembers = (members: WorkspaceMembers) => {
      const type = 'update-workspace-members'

      dispatch({ type, payload: members })
    }

    return {
      addWorkspaceDetails,
      updateWorkspaceMembers,
      deleteWorkspaceMembersPartners,
    }
  }, [])

  return [state, eventTriggers] as const
}

interface LocationState {
  // Form loaded immediately after creating the account
  fromSignUp?: boolean
  fromSignIn?: boolean
  // Form loaded after NoWorkspaceView view
  fromNoWorkspaces?: boolean
}

/**
 * CreateWorkspaceView is used in multiple user flows (sign-up, sign-in, or
 * normal workspace creation). Read history state to check if the form
 * is rendered as part of one of them.
 */
export function useFormSource() {
  const location = useLocation<LocationState | undefined>()

  const fromSignIn = location.state?.fromSignIn ?? false
  const fromSignUp = location.state?.fromSignUp ?? false
  const fromNoWorkspaces = location.state?.fromNoWorkspaces ?? false

  return {
    fromSignIn,
    fromSignUp,
    fromNoWorkspaces,
  }
}

/**
 * CreateWorkspaceView is used in multiple user flows (sign-up, sign-in, or
 * normal workspace creation). We send the returned 'formContext' value with each
 * analytics events to identify in which context the event was sent.
 */
export function useAnalyticsFormContext() {
  const { fromSignIn, fromSignUp, fromNoWorkspaces } = useFormSource()

  if (fromSignIn) {
    return 'sign-in'
  }

  if (fromSignUp) {
    return 'sign-up'
  }

  if (fromNoWorkspaces) {
    return 'no-workspaces'
  }

  return 'main'
}

import React, { useContext } from 'react'
import {
  ArtboardDetailInfoFragment,
  useGetArtboardsForDocumentQuery,
} from '@sketch/gql-types'
import { useParams } from 'react-router-dom'
import { OperationVariables } from 'apollo-client'
import get from 'lodash.get'
import { useSearchComponentsContext } from 'modules/shares/ComponentsWebView/context/SearchComponentsContext'
import { ResponsiveValues } from '@sketch/global-styles'
import { ArtboardList } from 'modules/shares/components/ArtboardList'
import { ShareVersionContext } from 'modules/versioning'
import {
  RetryQueryProps,
  useQueryRetry,
  RouteParams,
} from '@sketch/modules-common'

type RetryIfBuilder = (
  path: string[]
) => RetryQueryProps<any, OperationVariables>['retryIf']

const buildRetryIf: RetryIfBuilder = path => ({ data, error }) => {
  if (error) return true

  const artboards = get(data, path, []) as ArtboardDetailInfoFragment[]
  const files = artboards.map(a => a.files).flat()

  return files.some(file => !file?.url)
}

interface DocumentPageProps {
  search: string
  columns: ResponsiveValues<number>
}

/**
 * Handles a list of artboards scoped to a specific share document
 * grouped by page.
 */
export const DocumentArtboards = ({ search, columns }: DocumentPageProps) => {
  const entriesPath = ['share', 'version', 'document', 'artboards', 'entries']
  const afterPath = [
    'share',
    'version',
    'document',
    'artboards',
    'meta',
    'after',
  ]

  const { versionShortId } = useContext(ShareVersionContext)!
  const { shareID } = useParams<RouteParams<'SHARE_VIEW'>>()
  const { search: searchComponent } = useSearchComponentsContext()

  const { data, loading, error, fetchMore } = useQueryRetry(
    useGetArtboardsForDocumentQuery({
      variables: {
        identifier: shareID,
        versionShortId,
        search: searchComponent,
      },
    }),
    { retryIf: buildRetryIf(entriesPath) }
  )

  return (
    <>
      <ArtboardList
        fetchMore={fetchMore}
        afterPath={afterPath}
        entriesPath={entriesPath}
        data={data}
        loading={loading}
        error={error}
        columns={columns}
        filterValue={search}
      />
    </>
  )
}

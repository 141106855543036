import styled from 'styled-components';
import { Input as Input$1 } from './Input.js';
import '../Box/index.js';
import '../Truncate/index.js';
import { Text } from '../Box/BoxSystem.js';
import { truncateStyles } from '../Truncate/Truncate.js';

const InputContainer = styled.div `
  display: flex;
  flex: 1;
`;
const InputBackground = styled(Input$1) `
  display: block;
  width: 100%;
`;
const InputValueContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-left: -100%;
  padding: 12px;
  z-index: 1;
`;
const InputValue = styled(Text.Span).attrs({
    textStyle: 'copy.quaternary.standard.E',
}) `
  ${truncateStyles}
`;
const Input = {
    Container: InputContainer,
    Background: InputBackground,
    Value: Object.assign(InputValue, {
        Container: InputValueContainer,
    }),
};

export { Input, InputValue };

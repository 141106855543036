import { useContext } from 'react';
import { mapSsoStartUrl, routes } from '@sketch/modules-common';
import { ErrorHandler } from '@sketch/tracing';
import { ModalContext } from '@sketch/components';
import '../modals/SignInWithCredentialsModal/index.js';
import SignInWithCredentialsModal from '../modals/SignInWithCredentialsModal/SignInWithCredentialsModal.js';

const useSignInToWorkspace = () => {
    const { showModal } = useContext(ModalContext);
    const signIn = ({ email, name, onSuccess, avatarSrc, workspace, }) => {
        const { ssoEnabled, ssoStartUrl } = (workspace === null || workspace === void 0 ? void 0 : workspace.customer) || {};
        const isGuestWorkspace = workspace && workspace.userRole === 'GUEST';
        if (workspace && ssoEnabled && !ssoStartUrl) {
            ErrorHandler.shouldNeverHappen(`Workspace with id ${workspace.identifier} is configured with SSO but does not have any ssoStartUrl field.`);
            return;
        }
        if (ssoEnabled && !isGuestWorkspace) {
            window.location.href = mapSsoStartUrl(ssoStartUrl, {
                from: routes.PERSONAL_SETTINGS_ACCESS_TOKENS_CREATE.create({}),
            });
        }
        else {
            showModal(SignInWithCredentialsModal, {
                name,
                email,
                onSuccess,
                avatarSrc,
            });
        }
    };
    return { signIn };
};

export { useSignInToWorkspace };

import remarkParse from 'remark';
import remarkDisabledBlock from 'remark-disable-tokenizers';
import { emoji, mention, newline, link, color } from '@sketch/utils';
import { createMarkdownComponent } from '../Components.js';

const parser = remarkParse()
    .use(emoji)
    .use(mention)
    .use(newline)
    .use(link)
    .use(color)
    .use(remarkDisabledBlock, {
    block: [
        'atxHeading',
        'definition',
        'footnote',
        'html',
        'setextHeading',
        'table',
    ],
    inline: ['html', 'reference', 'link'],
});
const defaultMarkdownParser = content => parser.parse(content).children.map(createMarkdownComponent);

export { defaultMarkdownParser };

import styled, { css } from 'styled-components';
import { ReactComponent } from '@sketch/icons/shield-16';
import { breakpoint } from '@sketch/global-styles';
import { Text, Button, Link } from '@sketch/components';

const StatusList = styled.div `
  margin-top: 24px;
`;
const Shield = styled(ReactComponent) `
  margin-right: 8px;
  width: 16px;
  height: 16px;

  ${({ theme, status }) => {
    if (status === 'ENABLED') {
        return css `
        color: ${theme.colors.state.positive.A};
      `;
    }
}}
`;
const Status = styled(Text.Div).attrs({
    textStyle: 'copy.primary.standard.E',
}) `
  position: relative;
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-bottom: 8px;
`;
const StyledButton = styled(Button) `
  margin-left: 16px;

  ${breakpoint('base', 'sm') `
    margin-left: 0px;
  `}
`;
const StyledLink = styled(Link) `
  font-size: ${({ theme }) => theme.fontSizes.E};
  margin-left: 8px;
`;

export { Shield, Status, StatusList, StyledButton, StyledLink };

import { jsxs, jsx } from 'react/jsx-runtime';
import { BannerWrapper, BannerIconContainer, BannerBodyContainer, BannerTextContainer, BannerTitle, BannerDescription } from './LandscapeBannerCard.styles.js';

/**
 * Landscape version of the BannerCard. This component does not support yet the dismiss option since
 * it is not needed for now but we may need it in the future.
 */
function LandscapeBannerCard({ className, title, icon, description, action, 'data-testid': dataTestId, }) {
    return (jsxs(BannerWrapper, Object.assign({ className: className, "data-testid": dataTestId }, { children: [jsx(BannerIconContainer, { children: icon }), jsxs(BannerBodyContainer, { children: [jsxs(BannerTextContainer, { children: [jsx(BannerTitle, { children: title }), jsx(BannerDescription, { children: description })] }), action] })] })));
}

export { LandscapeBannerCard };

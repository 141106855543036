import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Modal, ModalInjectedProps } from '@sketch/components'

import { useToast } from '@sketch/toasts'

// Operations
import { useDeleteCollection } from 'modules/collections/operations'

import { CollectionPreviewsFragment, ProjectFragment } from '@sketch/gql-types'

interface DeleteCollectionModalProps extends ModalInjectedProps {
  workspaceIdentifier: string
  collection: Pick<CollectionPreviewsFragment, 'identifier' | 'name'>
  project: Pick<ProjectFragment, 'identifier' | 'name'>
  redirectTo?: string
}

export const DeleteCollectionModal: React.FC<DeleteCollectionModalProps> = ({
  workspaceIdentifier,
  collection,
  project,
  hideModal,
  redirectTo,
}) => {
  const { showToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const deleteCollection = useDeleteCollection({
    workspaceIdentifier,
    projectIdentifier: project.identifier,
    onCompleted: () => {
      hideModal()

      // on the redirect scenario, we get the toast from the useCollectionDeleted subscription
      if (redirectTo) {
        history.push(redirectTo)
      } else {
        showToast(`“${collection.name}” collection removed`)
      }
    },
  })

  const onClick = () => {
    setIsLoading(true)
    deleteCollection({
      variables: { identifier: collection.identifier },
    })
  }

  return (
    <Modal title="Remove Collection?" onCancel={hideModal}>
      <Modal.Body>
        Documents in the collection will be moved to the “{project.name}”{' '}
        project. No documents will be deleted.
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={isLoading} onClick={hideModal}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="negative-secondary"
          disabled={isLoading}
          onClick={onClick}
        >
          Remove
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteCollectionModal

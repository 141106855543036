/**
 * getKeys
 *
 * Return an array with the property names of a given object.
 * The difference between this funciont and Object.keys is that Object.keys by
 * default returns a `string[]`, however in some cases we want to keep the
 * already specified type.
 *
 * @SEE: https://github.com/microsoft/TypeScript/pull/12253#issuecomment-353494273
 *
 * e.g.:
 *
 * ```
 * const routes = {
 *    ROOT: {},
 *    SHARE: {},
 * }
 *
 * const typedKeys = getKeys(routes); // ('ROOT' | 'SHARE')[]
 * const untypedKeys = Object.keys(routes) // string[]
 * ````
 *
 */
const getKeys = (o) => Object.keys(o);

export { getKeys };

import React from 'react'

import {
  Button,
  DownloadFileAnchor,
  ErrorMessage,
  Modal,
  ModalInjectedProps,
} from '@sketch/components'
import { useShareRestoreWithUi } from './RestoreShareModal'
import { canRestoreShare, getDownloadProps } from 'modules/shares/utils'

// ShareListItemFragment should be removed once BE returns the newer types in the getTrash
// https://github.com/sketch-hq/Cloud/issues/11226
import {
  ShareListItemFragment,
  TrashedShareFragment,
  useGetShareQuery,
} from '@sketch/gql-types'

interface ShareForShareTrashedModal
  extends Pick<
    ShareListItemFragment | TrashedShareFragment,
    'identifier' | 'name'
  > {}

export interface ShareTrashedModalProps extends ModalInjectedProps {
  share: ShareForShareTrashedModal
}

export const ShareTrashedModal: React.FC<ShareTrashedModalProps> = props => {
  const {
    share: { identifier, name },
    hideModal,
  } = props

  const [restoreShare, { loading }] = useShareRestoreWithUi({
    identifier,
    onCompleted: () => {
      hideModal()
    },
  })

  // TODO: check if we want to fetch smaller fragment here - we don't need most of data here
  //  see: https://github.com/sketch-hq/Cloud/issues/12680
  const { data, error } = useGetShareQuery({
    variables: { shortId: identifier },
  })

  const share = data?.share

  const download = share && getDownloadProps(share.version)

  return (
    <Modal title="Cannot Open Document in Trash" onCancel={hideModal}>
      <Modal.Body>
        <p>
          You cannot open “{name}” because it&apos;s in the Workspace Trash.
        </p>
      </Modal.Body>
      {/* Basically, this should never happen, but if it does - handle the error */}
      {error && (
        <Modal.Body>
          <ErrorMessage.Generic description="There was a problem loading the document. Please try again." />
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button disabled={loading} onClick={hideModal} variant="secondary">
          Close
        </Button>
        {download?.isAvailable && (
          <Button
            to="#"
            variant="secondary"
            href={download.url}
            as={DownloadFileAnchor}
          >
            Download
          </Button>
        )}
        {share && canRestoreShare(share) && (
          <Button
            data-testid="restore-document"
            variant="secondary"
            loading={loading}
            disabled={loading}
            onClick={() => restoreShare()}
          >
            Restore
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

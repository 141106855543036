import React, { useRef, useEffect, useState } from 'react'
import {
  FilterOld as Filter,
  Truncate,
  Text,
  Breakpoint,
} from '@sketch/components'
import { useNotificationSearchContext } from '../../../../context/NotificationSearchContext'
import {
  OptionsTruncated,
  MoreOptions,
  HideOptions,
  OptionsAll,
} from './Searchbar.styles'
import {
  OptionDate,
  OptionLocation,
  OptionNotificationType,
  OptionUser,
} from './SearchOption'

const Options = () => {
  const { options, setOptions } = useNotificationSearchContext()

  return (
    <>
      <Text
        as="span"
        textStyle="copy.primary.standard.C"
        // this isn't a search option, we just want to get its width
        className="searchOption"
      >
        Showing:
      </Text>
      <OptionNotificationType
        type="comments"
        options={options}
        setOptions={setOptions}
      />
      <OptionNotificationType
        type="downloadableAssets"
        options={options}
        setOptions={setOptions}
      />
      <OptionNotificationType
        type="replies"
        options={options}
        setOptions={setOptions}
      />
      <OptionNotificationType
        type="mentions"
        options={options}
        setOptions={setOptions}
      />
      <OptionNotificationType
        type="starredUpdates"
        options={options}
        setOptions={setOptions}
      />
      <OptionDate options={options} setOptions={setOptions} />
      <OptionLocation options={options} setOptions={setOptions} />
      <OptionUser options={options} setOptions={setOptions} />
    </>
  )
}

const OptionsPanel = () => {
  const { options, isOptionsEmpty } = useNotificationSearchContext()
  const truncateRef = useRef<HTMLDivElement>()
  const [hiddenOptions, setHiddenOptions] = useState(0)
  const [visibleOptionsWidth, setVisibleOptionsWidth] = useState(0)
  const [showAllFilters, setShowAllFilters] = useState(false)

  useEffect(() => {
    if (!truncateRef.current) {
      return
    }

    const truncateWidth = truncateRef.current.clientWidth
    const options = document.querySelectorAll('.searchOption')

    // 54 as the starting point to get the perfect overflow point
    let optionsWidth = 54
    let hiddenCounter = 0
    options.forEach(option => {
      optionsWidth += option.clientWidth + 8

      if (optionsWidth > truncateWidth) {
        hiddenCounter += 1
      } else {
        setVisibleOptionsWidth(optionsWidth)
      }
    })

    setHiddenOptions(hiddenCounter)
    if (hiddenCounter === 0) {
      setShowAllFilters(false)
    }
  }, [truncateRef, options])

  if (isOptionsEmpty()) {
    return <></>
  }

  if (showAllFilters) {
    return (
      <OptionsAll>
        <Options />
        <HideOptions onClick={() => setShowAllFilters(false)}>
          hide last {hiddenOptions}
        </HideOptions>
      </OptionsAll>
    )
  }

  return (
    <OptionsTruncated ref={truncateRef}>
      <Truncate>
        <Options />
      </Truncate>
      {hiddenOptions > 0 && (
        <MoreOptions
          left={visibleOptionsWidth}
          onClick={() => setShowAllFilters(true)}
        >
          show {hiddenOptions} more&hellip;
        </MoreOptions>
      )}
    </OptionsTruncated>
  )
}

export const Searchbar: React.FC<{
  disabled?: boolean
}> = ({ disabled }) => {
  const { search, setSearch, resetAll } = useNotificationSearchContext()
  return (
    <>
      <Filter
        disabled={disabled}
        onChange={setSearch}
        onSubmit={() => setSearch(search)}
        onClear={resetAll}
        value={search}
        placeholder="Search updates"
      />
      <Breakpoint on="sm">
        {/* we don't show this menu in mobile */}
        <OptionsPanel />
      </Breakpoint>
    </>
  )
}

import { jsx, jsxs } from 'react/jsx-runtime';
import { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../Header/index.js';
import '../HeaderOld/index.js';
import { FullPageLayout, Wrapper, Sidebar, Content, ContentContainer } from './SplitLayout.styles.js';
import { useOnClickOutside, MODAL_SELECTOR, DROPDOWN_SELECTOR, OPTION_ITEM } from '@sketch/utils';
import { callIfFunction } from '../SidebarLayout/callIfFunction.js';
import '../HeaderContext/index.js';
import { Header } from '../Header/Header.js';
import { HeaderOld } from '../HeaderOld/Header.js';
import { SearchExpandedProvider } from '../HeaderContext/Header.context.js';

/**
 * COMPONENT
 * Used for documents view (homepage), it provides a left sidebar to show the
 * main navigation
 */
const SplitLayout = ({ title, sidebar, header, footer, children, isRefreshedUi, }) => {
    // Only used for mobile
    const [isSidebarLeftOpen, setSidebarLeftOpen] = useState(false);
    const closeSidebar = () => {
        if (isSidebarLeftOpen) {
            // setTimeout is needed to make sure `setSidebarLeftOpen` is called after
            // any other click event listener, for example the hamburger icon click
            setTimeout(() => setSidebarLeftOpen(false), 0);
        }
    };
    // Close the left sidebar when the user clicks outside of it
    const sidebarRef = useRef(null);
    useOnClickOutside(sidebarRef, closeSidebar, {
        includeSelectors: [MODAL_SELECTOR, DROPDOWN_SELECTOR, OPTION_ITEM],
    });
    // Make sure the sidebar is closed every time the route changes
    const location = useLocation();
    useEffect(() => {
        closeSidebar();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    const Header$1 = isRefreshedUi ? Header : HeaderOld;
    return (jsx(SearchExpandedProvider, { children: jsx(FullPageLayout, Object.assign({ title: title, showHeader: false }, { children: jsxs(Wrapper, { children: [sidebar && (jsx(Sidebar, Object.assign({ ref: sidebarRef, isSidebarOpen: isSidebarLeftOpen, "$isRefreshedUi": isRefreshedUi }, { children: callIfFunction(sidebar) }))), jsxs(Content, Object.assign({ "data-testid": "page-content", "$isRefreshedUi": isRefreshedUi }, { children: [jsx(Header$1, { children: callIfFunction(header, {
                                    isSidebarLeftOpen,
                                    setSidebarLeftOpen,
                                }) }), jsx(ContentContainer, Object.assign({ isSidebarOpen: isSidebarLeftOpen }, { children: callIfFunction(children) })), callIfFunction(footer)] }))] }) })) }));
};

export { SplitLayout };

/**
 * Keyboard shortcuts, meant to be used with `keyWithoutModifier`,
 * `keyWithModifier` or `keyCodeWithShift`.
 */
const shortcuts = {
    // Documents view
    search: '/',
    gridView: '[',
    listView: ']',
    // Artboard view
    paginationPrevious: 'ArrowLeft',
    paginationNext: 'ArrowRight',
    // Canvas and Artboard view
    addComment: 'n',
    // Panels (Canvas and Artboard view)
    about: 'd',
    inspect: 'i',
    comment: 'c',
    version: 'v',
    // Zoom (Canvas and Artboard view)
    zoomIn: '+',
    zoomOut: '-',
    zoomToActualSize: 'Digit0',
    zoomToFit: 'Digit1',
    toggleGrid: 'KeyG',
    toggleLayout: 'KeyH',
    // Prototype
    prototypePrevious: 'ArrowLeft',
    prototypeNext: 'ArrowRight',
    prototypeRestart: 'r',
    prototypeBack: 'PageUp',
    prototypeFirstFlow: 'PageDown',
    prototypeSetToActualSize: 'z',
    prototypeSetToFit: 'Digit0',
    prototypeSetToFillWidth: 'Digit1',
};
/**
 * Shortcut text to be displayed in the UI
 */
const shortcutsText = {
    // Documents view
    search: shortcuts.search,
    gridView: shortcuts.gridView,
    listView: shortcuts.listView,
    // Artboard view
    paginationPrevious: '',
    paginationNext: '',
    // Canvas and Artboard view
    addComment: shortcuts.addComment.toUpperCase(),
    // Panels (Canvas and Artboard view)
    about: shortcuts.about.toUpperCase(),
    inspect: shortcuts.inspect.toUpperCase(),
    comment: shortcuts.comment.toUpperCase(),
    version: shortcuts.version.toUpperCase(),
    // Zoom (Canvas and Artboard view)
    zoomIn: shortcuts.zoomIn,
    zoomOut: shortcuts.zoomOut,
    zoomToActualSize: 'Shift 0',
    zoomToFit: 'Shift 1',
    toggleGrid: 'Shift G',
    toggleLayout: 'Shift H',
    // Prototype
    prototypePrevious: '',
    prototypeNext: '',
    prototypeRestart: '',
    prototypeBack: '',
    prototypeFirstFlow: '',
    prototypeSetToActualSize: '',
    prototypeSetToFit: '',
    prototypeSetToFillWidth: '',
};
/**
 * These handlers are ready to use with `useKey` hook.
 * Example: useKey(shortcutsHandlers.search, (event) => {...})
 *
 * IMPORTANT NOTE: for some reason these handlers are not working as expected
 * in Zoom components, it's certainly a problem of the Zoom and not these
 * handlers, so we should investigate what is causing the issue, for now I
 * leave this a reference for all our shortcut handlers.
 */
// export const shortcutsHandlers: Record<
//   keyof typeof shortcuts,
//   (event: KeyboardEvent) => boolean
// > = {
//   // Documents view
//   search: keyWithoutModifier(shortcuts.search),
//   gridView: keyWithoutModifier([shortcuts.gridView, shortcuts.listView]),
//   // same as the previous, as it's managed by the same handler
//   listView: keyWithoutModifier([shortcuts.gridView, shortcuts.listView]),
//   // Artboard view
//   paginationPrevious: keyWithoutModifier(shortcuts.paginationPrevious),
//   paginationNext: keyWithoutModifier(shortcuts.paginationNext),
//   // Canvas and Artboard view
//   addComment: keyWithoutModifier(shortcuts.addComment),
//   // Panels (Canvas and Artboard view)
//   about: keyWithoutModifier(shortcuts.about),
//   inspect: keyWithoutModifier(shortcuts.inspect),
//   comment: keyWithoutModifier(shortcuts.comment),
//   version: keyWithoutModifier(shortcuts.version),
//   // Zoom (Canvas and Artboard view)
//   zoomIn: keyWithoutModifier(shortcuts.zoomIn),
//   zoomOut: keyWithoutModifier(shortcuts.zoomOut),
//   zoomToActualSize: keyCodeWithShift(shortcuts.zoomToActualSize),
//   zoomToFit: keyCodeWithShift(shortcuts.zoomToFit),
//   toggleGrid: keyCodeWithShift(shortcuts.toggleGrid),
//   toggleLayout: keyCodeWithShift(shortcuts.toggleLayout),
//   // Prototype
//   prototypePrevious: keyWithoutModifier(shortcuts.prototypePrevious),
//   prototypeNext: keyWithoutModifier(shortcuts.prototypeNext),
//   prototypeRestart: keyWithoutModifier(shortcuts.prototypeRestart),
//   prototypeBack: keyWithoutModifier(shortcuts.prototypeBack),
//   prototypeFirstFlow: keyWithoutModifier(shortcuts.prototypeFirstFlow),
//   prototypeSetToActualSize: keyCodeWithShift(
//     shortcuts.prototypeSetToActualSize
//   ),
//   prototypeSetToFit: keyCodeWithShift(shortcuts.prototypeSetToFit),
//   prototypeSetToFillWidth: keyCodeWithShift(shortcuts.prototypeSetToFillWidth),
// }

export { shortcuts, shortcutsText };

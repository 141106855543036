import { __rest } from 'tslib';
import { useContext } from 'react';
import { bannerContext } from './bannerContext.js';

const useBanner = () => {
    const exposedFields = __rest(useContext(bannerContext), []);
    return exposedFields;
};

export { useBanner };

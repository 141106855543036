import { withBox } from './withBox.js';
import { withFlex as withFlex$1 } from './withFlex.js';
import { withText as withText$1 } from './withText.js';
import { withAbsolute as withAbsolute$1 } from './withAbsolute.js';

function withFlex(Comp) {
    return withFlex$1(withBox(Comp));
}
function withText(Comp) {
    return withText$1(withBox(Comp));
}
function withAbsolute(Comp) {
    return withAbsolute$1(withBox(Comp));
}
const Box = withBox('div');
const Absolute = withAbsolute('div');
const Flex = Object.assign(withFlex('div'), {
    Form: withFlex('form'),
});
/**
 * Text component
 *
 * Used to render a text using `textStyle` prop that matches the text styles
 * from Prism (note: for now text styles in the FE don't match 100% text styles
 * in Prism doc, that will change when text overrides is available for the
 * mac app)
 *
 * As a last option, Text can also accept these props: `color`, `fontSize`,
 * `fontWeight`, `lineHeight`, `fontStyle`, `textAlign`, `verticalAlign`,
 * `textTransform` or `overflow`.
 */
const Text = Object.assign(withText('p'), {
    P: withText('p'),
    Span: withText('span'),
    Label: withText('label'),
    Div: withText('div'),
    Section: withText('section'),
    H1: withText('h1'),
    H2: withText('h2'),
    H3: withText('h3'),
    H4: withText('h4'),
    H5: withText('h5'),
    H6: withText('h6'),
    Li: withText('li'),
});

export { Absolute, Box, Flex, Text };

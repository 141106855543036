import styled from 'styled-components'
import { ReactComponent as MagGlass } from '@sketch/icons/magnifying-glass-24'

import {
  Button,
  Filter,
  FilterContainer,
  FilterSearchInput,
  Input,
} from '@sketch/components'

export const SearchField = styled(Filter)<{
  expand: boolean
}>`
  ${FilterContainer}, ${FilterSearchInput} {
    height: 32px;
  }

  ${Input} {
    position: relative;
    width: 100%;
    background: none;
    max-width: ${({ expand }) => (expand ? '100%' : '196px')};
    border-color: ${({ theme }) => theme.colors.border.A};
  }
`

export const MagIcon = styled(MagGlass)`
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
`

export const SearchButton = styled(Button)`
  box-shadow: none;
  display: flex; /* Remove the line-height from pushing the button up */
`

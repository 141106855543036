import styled, { css } from 'styled-components';
import '../../Box/index.js';
import { Box } from '../../Box/BoxSystem.js';

const dropdownDividerStyles = css(({ theme }) => css `
    display: block;
    margin: 8px 0;
    height: 1px;
    background: ${theme.colors.border.B};
  `);
const DropdownDivider = styled(Box) `
  ${dropdownDividerStyles};
`;

export { DropdownDivider, dropdownDividerStyles };

import { jsx } from 'react/jsx-runtime';
import { useListboxContext } from '@reach/listbox';
import { Option as Option$1, RadioButton } from './AccessLevelSelect.styles.js';

const Option = props => {
    const { disabled, 'data-testid': dataTestId, label, help, level, negative, } = props;
    const { value } = useListboxContext();
    return (jsx(Option$1, Object.assign({ "data-testid": dataTestId, name: "AccessLevelOption", value: level, label: label, disabled: disabled, "$negative": negative }, { children: jsx(RadioButton, { name: "access-level", help: help, label: label, checked: level === value, onChange: () => { }, disabled: disabled, negative: negative }) })));
};

export { Option };

import React from 'react'

import { Route, RouteProps as ReactRouteProps } from 'react-router-dom'
import { ColorVariables, LayerStyles, TextStyles, Symbols } from './pages'
import { getAllTemplatesForVersionedRoute } from '@sketch/modules-common'
import { ComponentsStateProvider } from 'modules/shares/components/ComponentsStateContext'

interface CwvRoutesProps extends ReactRouteProps {
  userIsEditor: boolean
}

export const CwvRoutes: React.FC<CwvRoutesProps> = ({ userIsEditor }) => (
  <ComponentsStateProvider>
    <Route exact path={getAllTemplatesForVersionedRoute('CWV_SYMBOLS')}>
      <Symbols userIsEditor={userIsEditor} />
    </Route>
    <Route exact path={getAllTemplatesForVersionedRoute('CWV_TEXT_STYLES')}>
      <TextStyles userIsEditor={userIsEditor} />
    </Route>
    <Route exact path={getAllTemplatesForVersionedRoute('CWV_LAYER_STYLES')}>
      <LayerStyles userIsEditor={userIsEditor} />
    </Route>
    <Route exact path={getAllTemplatesForVersionedRoute('CWV_COLOR_VARIABLES')}>
      <ColorVariables userIsEditor={userIsEditor} />
    </Route>
  </ComponentsStateProvider>
)

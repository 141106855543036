import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { getItem, removeItem } from '@sketch/utils'
import {
  isPaymentMethodInvoice,
  useCanSubscribeViaStripe,
} from 'modules/workspace/utils'

import { localStorageKeys } from '@sketch/constants'
import { routes } from '@sketch/modules-common'
import { SKETCH_WEBSITE } from '@sketch/env-config'

import { Button, LinkButton } from '@sketch/components'

import { useToast } from '@sketch/toasts'

import EmptyState from '../EmptyState'

// GQL
import {
  GetWorkspacesDocument,
  useDeleteWorkspaceMutation,
  WorkspaceMinimalFragment,
  useGetPaymentDetailsQuery,
  PaymentDetailsFragment,
} from '@sketch/gql-types'

// Operations
const invoiceWSCopyDelete =
  'To add documents, contact us to renew your subscription and activate the Workspace again. Alternatively, delete the Workspace if you no longer need it.'
const renewCopyDelete =
  'To add documents, renew your subscription to activate the Workspace again. Alternatively, delete the Workspace if you no longer need it.'
const copyMembersNoDelete =
  'To add documents, an Admin needs to renew the subscription — which will activate the Workspace again.'
const copyEducationRenewal =
  'To add documents, either request an extension for your Education Workspace — or subscribe to Sketch.'
const copyEducationRenewalNonAdmin =
  'To add documents, an Admin needs to either request an extension for your Education Workspace — or subscribe to Sketch.'
const copySketchMAS =
  'To add documents, renew your subscription to activate the Workspace again.'

const StyledLinkButton = styled(LinkButton)`
  color: ${({ theme }) => theme.colors.foreground.primary.A};
  &:hover {
    color: ${({ theme }) => theme.colors.foreground.primary.A};
  }
`

interface ExpiredWorkspaceActionsProps {
  workspaceId: string
  canSubscribeStripe: boolean
  canRenewSubscription: boolean
  canDeleteWorkspace: boolean
  showContactUs: boolean
  showExtendEducation: boolean
}

const ExpiredWorkspaceActions: React.FC<ExpiredWorkspaceActionsProps> = ({
  workspaceId,
  canSubscribeStripe,
  canRenewSubscription,
  canDeleteWorkspace,
  showContactUs,
  showExtendEducation,
}) => {
  const history = useHistory()
  const { showToast } = useToast()

  const showRenewSubscription = canRenewSubscription && canSubscribeStripe

  const renewSubscriptionCopy = showExtendEducation
    ? 'Subscribe…'
    : 'Renew Subscription…'
  const [deleteWorkspace, { loading }] = useDeleteWorkspaceMutation({
    variables: { workspaceId },
    onError: 'show-toast',
    onCompleted: data => {
      const { deletedWorkspace } = data.deleteWorkspace || {}

      // Remove last visited workspace from local storage
      const storedWorkspaceId = getItem(localStorageKeys.lastWorkspaceIdKey)

      if (storedWorkspaceId === deletedWorkspace?.identifier) {
        removeItem(localStorageKeys.lastWorkspaceIdKey)
      }

      showToast('Your workspace was successfully deleted.', 'positive')
      history.push(routes.ENTRY.create({}))
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GetWorkspacesDocument }],
  })

  return (
    <>
      {canDeleteWorkspace && canSubscribeStripe && (
        <Button
          variant="negative-secondary"
          onClick={() => deleteWorkspace()}
          loading={loading}
        >
          Delete Workspace…
        </Button>
      )}
      {showRenewSubscription && (
        <Button
          variant={showExtendEducation ? 'secondary' : 'primary'}
          onClick={() =>
            history.push(
              routes.WORKSPACE_SUBSCRIBE.create({
                workspaceId,
              })
            )
          }
          disabled={loading}
        >
          {renewSubscriptionCopy}
        </Button>
      )}
      {showExtendEducation && (
        <Button
          variant="primary"
          onClick={() => window.open(`${SKETCH_WEBSITE}/store/education/`)}
          loading={loading}
        >
          Request Extension…
        </Button>
      )}
      {showContactUs && (
        <StyledLinkButton
          external
          variant="primary"
          href={`${SKETCH_WEBSITE}/support/contact/`}
        >
          Contact Us…
        </StyledLinkButton>
      )}
    </>
  )
}

interface RenewSubEmptyStateProps {
  workspace: WorkspaceMinimalFragment
}

const getPermissions = (
  workspace: WorkspaceMinimalFragment,
  paymentDetails?: PaymentDetailsFragment
) => {
  const { userRole } = workspace
  const isInvoice = paymentDetails && isPaymentMethodInvoice(paymentDetails)

  const canAdminister = userRole === 'ADMIN' || userRole === 'FINANCE'

  const canDeleteWorkspace =
    userRole === 'ADMIN' && workspace?.type !== 'STUDENT'
  const canRenewSubscription = !isInvoice && canAdminister
  const showContactUs = !!isInvoice && canAdminister
  const showExtendEducation = workspace?.type === 'STUDENT' && canAdminister
  const educationNonAdminUsers = workspace?.type === 'STUDENT' && !canAdminister

  return {
    canRenewSubscription,
    showContactUs,
    canDeleteWorkspace,
    showExtendEducation,
    educationNonAdminUsers,
  }
}

const RenewSubEmptyState: React.FC<RenewSubEmptyStateProps> = ({
  workspace,
}) => {
  const { identifier, customer } = workspace
  const { data, loading } = useGetPaymentDetailsQuery({
    variables: { customerId: workspace.customer!.identifier },
    fetchPolicy: 'network-only',
  })

  const { canSubscribeStripe } = useCanSubscribeViaStripe(
    identifier,
    customer?.identifier
  )

  if (loading) return null

  const paymentDetails = data?.customer?.paymentDetails
  const {
    canRenewSubscription,
    canDeleteWorkspace,
    showContactUs,
    showExtendEducation,
    educationNonAdminUsers,
  } = getPermissions(workspace, paymentDetails)

  let copy = canRenewSubscription ? renewCopyDelete : invoiceWSCopyDelete

  if (!canDeleteWorkspace) copy = copyMembersNoDelete
  if (showExtendEducation) copy = copyEducationRenewal
  if (educationNonAdminUsers) copy = copyEducationRenewalNonAdmin
  if (!canSubscribeStripe) copy = copySketchMAS

  return (
    <EmptyState
      icon="document"
      title="Your Workspace doesn’t have any documents yet"
      description={<>{copy}</>}
      callToAction={
        <ExpiredWorkspaceActions
          workspaceId={identifier}
          canSubscribeStripe={canSubscribeStripe}
          canRenewSubscription={canRenewSubscription}
          canDeleteWorkspace={canDeleteWorkspace}
          showContactUs={showContactUs}
          showExtendEducation={showExtendEducation}
        />
      }
    />
  )
}

export default RenewSubEmptyState

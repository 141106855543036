import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useModalContext, Link } from '@sketch/components';
import '../../../components/PrintToPDFButton/index.js';
import '../../../components/CopyCodesButton/index.js';
import '../../RegenerateRecoveryCodesModal/index.js';
import { Title, Description, RecoveryCodes, RecoveryCode, Buttons, StyledVerificationCode } from '../Setup2FAModal.styles.js';
import { RegenerateRecoveryCodesModal } from '../../RegenerateRecoveryCodesModal/RegenerateRecoveryCodesModal.js';
import { PrintToPDFButton } from '../../../components/PrintToPDFButton/PrintToPDFButton.js';
import { CopyCodesButton } from '../../../components/CopyCodesButton/CopyCodesButton.js';

/**
 * CodesStep
 *
 * Renders the third step for the 2FA Setup Modal
 */
const CodesStep = ({ onFilled, recoveryCodes, error, }) => {
    const { showModal, hideModal } = useModalContext();
    const handleGenerateNewCodes = (e) => {
        e.preventDefault();
        hideModal();
        showModal(RegenerateRecoveryCodesModal);
    };
    if (recoveryCodes.length) {
        return (jsxs(Fragment, { children: [jsx(Title, { children: "Save Your Recovery Codes" }), jsx(Description, { children: "Use these recovery codes to sign in if you lose access to your authenticator app. Store them somewhere safe!" }), jsx(Link, Object.assign({ onClick: handleGenerateNewCodes, isUnderlined: true, external: true }, { children: "Generate new recovery codes" })), jsx(RecoveryCodes, Object.assign({ "data-testid": "recovery-codes-list" }, { children: recoveryCodes.map((code, index) => (jsx(RecoveryCode, { children: code }, `${code}${index}`))) })), jsxs(Buttons, { children: [jsx(PrintToPDFButton, Object.assign({ codes: recoveryCodes }, { children: "Print Codes (PDF)" })), jsx(CopyCodesButton, { codes: recoveryCodes })] })] }));
    }
    return (jsxs(Fragment, { children: [jsx(Title, { children: "Enter the Verification Code" }), jsx(Description, { children: "Open your authenticator app and enter the code before it expires \u2014 or wait for a new one to appear." }), jsx(StyledVerificationCode, { onFilled: onFilled, error: error }), jsx(Link, Object.assign({ href: "http://www.sketch.com/docs/workspaces/managing-your-sketch-account/#troubleshooting", isUnderlined: true, external: true }, { children: "Code not working?" }))] }));
};

export { CodesStep };

import { renderLineHeight } from './utils'
import { ColorFormat } from 'modules/shares/types'
import { capitalize } from '@sketch/utils'
import { rgbTo } from '../../components/Color/utils'
import { TextAttributes, VerticalTextAlignment } from 'modules/inspector'

export const copySection = ({
  font,
  color,
  colorFormat,
  letterSpacing,
  paragraphStyle,
  verticalAlignment,
}: TextAttributes & {
  colorFormat: ColorFormat
  verticalAlignment?: VerticalTextAlignment
}) => {
  const { family, weight, size, style } = font || {}
  const { spacing, lineHeight, alignment } = paragraphStyle || {}

  const sectionCopyArray = []
  if (family) {
    sectionCopyArray.push(`Typeface: ${family}`)
  }
  if (weight) {
    sectionCopyArray.push(`Weight: ${weight}`)
  }
  if (size || size === 0) {
    sectionCopyArray.push(`Size: ${size}`)
  }
  if (style) {
    sectionCopyArray.push(`Style: ${style}`)
  }
  if (letterSpacing || letterSpacing === 0) {
    sectionCopyArray.push(`Character: ${letterSpacing}`)
  }
  if (lineHeight) {
    const lineHeightFormatted = renderLineHeight(
      lineHeight,
      size ? lineHeight / size : undefined
    )
    sectionCopyArray.push(`Line Height: ${lineHeightFormatted}`)
  }
  if (spacing) {
    sectionCopyArray.push(`Paragraph: ${spacing}`)
  }
  if (color) {
    sectionCopyArray.push(`Color: ${rgbTo(colorFormat, color)}`)
  }
  if (alignment) {
    sectionCopyArray.push(`Horizontal: ${capitalize(alignment)}`)
  }
  if (verticalAlignment) {
    sectionCopyArray.push(`Vertical: ${capitalize(verticalAlignment)}`)
  }
  return sectionCopyArray.join('\n')
}

export const copyAlignment = ({
  paragraphStyle,
  verticalAlignment,
}: TextAttributes & {
  verticalAlignment?: VerticalTextAlignment
}) => {
  const { alignment } = paragraphStyle || {}
  const alignmentCopyArray = []

  if (alignment) {
    alignmentCopyArray.push(`Horizontal: ${capitalize(alignment)}`)
  }
  if (verticalAlignment) {
    alignmentCopyArray.push(`Vertical: ${capitalize(verticalAlignment)}`)
  }
  return alignmentCopyArray.join('\n')
}

import React from 'react'

import { useCollectionDropShares } from 'modules/collections/hooks'

import { CollectionPreviewsFragment } from '@sketch/gql-types'

import {
  DroppableArea,
  OnDraggedOverTooltip,
} from './CollectionShareDrop.styles'

interface CollectionShareDropProps {
  collection: CollectionPreviewsFragment
  projectIdentifier: string
  workspaceIdentifier: string
  children: React.ReactNode
}

const CollectionShareDrop: React.FC<CollectionShareDropProps> = ({
  collection,
  projectIdentifier,
  workspaceIdentifier,
  children,
}) => {
  const { isDraggedOver = false, ...dropEvents } = useCollectionDropShares({
    collection,
    projectIdentifier,
    workspaceIdentifier,
  })

  return (
    <>
      {isDraggedOver && (
        <OnDraggedOverTooltip>Add to collection</OnDraggedOverTooltip>
      )}
      <DroppableArea $isDraggedOver={isDraggedOver} {...dropEvents}>
        {children}
      </DroppableArea>
    </>
  )
}

export default CollectionShareDrop

/** Concatenate the value to the unit and pluralize the unit if needed */
function createStringWithTimeUnit(value, unit) {
    if (value === 1) {
        return `${value} ${unit}`;
    }
    // Pluralize if more than one value
    return `${value} ${unit}s`;
}

export { createStringWithTimeUnit };

import React from 'react'

import { PublicationListingFragment } from '@sketch/gql-types'

import CommunityModifier from '../CommunityModifier'
import { useCommunityModifiers } from '../../hooks'

import {
  Header,
  ModifiersWrapper,
  Title,
} from './CommunityListingHeader.styles'

const DEFAULT_LISTING_MODIFIERS = ['SORT', 'PERIOD', 'DOCUMENT_TYPE'] as const

type CommunityListingHeaderProps = OmitSafe<
  PublicationListingFragment,
  '__typename' | 'preferences'
> & {
  preferences?: PublicationListingFragment['preferences']
}

const CommunityListingHeader = (props: CommunityListingHeaderProps) => {
  const { title, modifiers, preferences } = props

  const [{ sort, period, documentType }, setModifier] = useCommunityModifiers(
    preferences
  )

  const modifiersElements = (modifiers || DEFAULT_LISTING_MODIFIERS).map(
    modifier => {
      switch (modifier) {
        case 'DOCUMENT_TYPE':
          return (
            <CommunityModifier
              modifier="DOCUMENT_TYPE"
              key="DOCUMENT_TYPE"
              activeValue={documentType}
              setValue={documentType => setModifier({ documentType })}
            />
          )
        case 'PERIOD':
          return (
            <CommunityModifier
              modifier="PERIOD"
              key="PERIOD"
              activeValue={period}
              setValue={period => setModifier({ period })}
            />
          )
        case 'SORT':
          return (
            <CommunityModifier
              modifier="SORT"
              key="SORT"
              activeValue={sort}
              setValue={sort => setModifier({ sort })}
            />
          )
        default:
          return null
      }
    }
  )

  return (
    <Header>
      <Title as="h1">{title}</Title>
      <ModifiersWrapper>{modifiersElements}</ModifiersWrapper>
    </Header>
  )
}

export default CommunityListingHeader

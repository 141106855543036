import React from 'react'
import { DropdownActionButton, useModalContext } from '@sketch/components'
import {
  ProjectFragment,
  useRemoveProjectMemberMutation,
  ProjectMembershipFragment,
  RemoveProjectMemberMutation,
} from '@sketch/gql-types'
import { DataProxy } from 'apollo-cache'
import { removeProjectMembership } from 'modules/projects/operations/utils/projectMemberships.cache'
import {
  ConfirmRemoveMemberModal,
  RemoveProjectAccessModal,
} from '../RemoveMemberModal'

interface RemoveMemberButtonProps {
  project: ProjectFragment
  member: Pick<ProjectMembershipFragment, 'identifier' | 'workspaceMembership'>
  text?: string
  onCancel?: () => void
  onComplete?: (
    cache: DataProxy,
    data?: RemoveProjectMemberMutation | null
  ) => void
}

export const RemoveMemberButton: React.FC<RemoveMemberButtonProps> = function (
  props
) {
  const { project, member, children, onCancel, onComplete } = props
  const { showModal, hideModal } = useModalContext()
  const { identifier: membershipIdentifier, workspaceMembership } = member
  const memberName = workspaceMembership.user?.name || ''

  const [removeMemberFromProject] = useRemoveProjectMemberMutation({
    variables: { membershipIdentifier },
    update(cache, { data }) {
      removeProjectMembership({
        cache,
        projectIdentifier: project.identifier,
        membershipIdentifier,
      })

      onComplete?.(cache, data)
    },
    onError(errorResponse) {
      if (errorResponse.includesErrorCode('CONFIRMATION_REQUIRED')) {
        showModal(ConfirmRemoveMemberModal, {
          projectName: project.name,
          onConfirm: async () => {
            await removeMemberFromProject({
              variables: {
                membershipIdentifier,
                confirmed: true,
              },
            })
            hideModal()
          },
          onCancelAction: () => {
            onCancel?.()
          },
        })

        return
      }

      if (errorResponse.includesErrorCode('EXISTING_SHARE_MEMBERSHIPS')) {
        showModal(RemoveProjectAccessModal, {
          memberName,
          projectName: project.name,
          onConfirm: async (removeShareMemberships: boolean) => {
            await removeMemberFromProject({
              variables: {
                membershipIdentifier,
                removeShareMemberships,
                confirmed: true,
              },
            })
            hideModal()
          },
          onCancelAction: () => {
            onCancel?.()
          },
        })

        return
      }
    },
  })

  return (
    <DropdownActionButton
      variant="negative"
      onClick={() => removeMemberFromProject()}
    >
      {children}
    </DropdownActionButton>
  )
}

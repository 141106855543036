import styled, { css } from 'styled-components'
import { Light } from 'react-syntax-highlighter'

import {
  Button,
  ButtonBaseStyle,
  ButtonPrimaryStyle,
  Text,
  Dropdown,
  Separator,
  Select,
} from '@sketch/components'
import foundation from 'react-syntax-highlighter/dist/cjs/styles/hljs/foundation'
import {
  DropdownTarget,
  DropdownContentContainer,
} from 'modules/workspace/containers/WorkspacesDropdown/WorkspacesDropdown.styles'

export const ExportContainer = styled.div`
  min-height: 112px;
  padding-top: 16px;
`

export const ExportContainerSeparator = styled(Separator)`
  margin: 0 -16px;
`

export const ExplanationText = styled(Text).attrs({
  textStyle: 'copy.quaternary.standard.C',
})`
  margin: 12px 0;
`

export const ExportButton = styled(Button)<{ $narrowSidebar?: boolean }>(
  ({ $narrowSidebar }) => css`
    justify-content: flex-start;
    padding: 6px ${$narrowSidebar ? 4 : 8}px; /* stylelint-disable-line scales/space */
    margin-bottom: 8px;
  `
)

export const ButtonText = styled.span`
  margin-left: 8px;
`

export const ToggleButtonText = styled(ButtonText)`
  line-height: 1.4;
`

export const ModalDescription = styled.p`
  padding-top: 4px;
`

export const FormatSelect = styled(Select)`
  flex-grow: 1;
  margin-right: 8px;
`

export const DownloadLink = styled.a(
  ({ theme }) => css`
    ${ButtonBaseStyle}
    ${ButtonPrimaryStyle}

    font-size: ${theme.fontSizes.D};
    padding: 10px 12px; /* stylelint-disable-line scales/space */
    min-height: 38px;
  `
)

export const ModalSeparator = styled(Separator)<{ format: string }>(
  ({ format }) => css`
    margin: ${format !== 'AMAZON' ? '24px 0' : '0 0 24px 0'};
  `
)

export const LinksTitle = styled(Text.Span).attrs({
  textStyle: 'copy.tertiary.standard.E',
})``

const ToggleBase = css`
  height: 40px;
  border-radius: ${({ theme }) => theme.radii.large};
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  background: ${({ theme }) => theme.colors.background.secondary.B};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  padding: 0;
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.colors.shadow.inner} inset;
`

export const ColorFormatButton = styled(DropdownTarget).attrs({
  'data-testid': 'export-tokens-color-format-toggle-button',
})`
  ${ToggleBase}
  width: 94px;
  margin-right: 8px;
  padding-right: 8px;
`

export const ColorFormatDropdownContainer = styled(DropdownContentContainer)`
  width: 191px;
  padding: 0;
`

export const ToggleButton = styled(DropdownTarget).attrs({
  'data-testid': 'export-tokens-share-toggle-button',
})`
  ${ToggleBase}
  width: 342px;
`

export const DropdownItem = styled(Dropdown.Item)<{ active?: boolean }>(
  ({ theme, active }) => css`
    background-color: ${active ? theme.colors.sketch.F : undefined};
    color: ${active ? theme.colors.sketch.A : undefined};
  `
)

export const DropdownContainer = styled(DropdownContentContainer)`
  width: 342px;
  padding: 0;
`

export const SyntaxHighlighter = styled(Light)<{ language: string }>(
  ({ theme, language }) => css`
    /* very specific 201px so that the height of the modal is the same as on the JSON*/
    max-height: ${language === 'json' ? 175 : 201}px;
    border-radius: ${theme.radii.base};
    overflow-y: auto;
    background-color: #f9f9f9;
    padding: 2px; /* stylelint-disable-line scales/space */
  `
)

export const syntaxHighlighterTheme = {
  ...foundation,
  hljs: {
    ...foundation.hljs,
    padding: '0 0.5em',
    background: '#F9F9F9',
  },
}

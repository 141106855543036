import React, { useState } from 'react'

import * as yup from 'yup'
import { Formik, FormikProps } from 'formik'

import { Button, LoadingPlaceholder, Form, Input } from '@sketch/components'

import { StyledForm } from './WebhookConfigurationForm.styles'

// hooks
import { useToast } from '@sketch/toasts'
import { useDeleteWebhookDestination } from 'modules/workspace/operations/useDeleteWebhookDestination'
import { useGetWebhookDestination } from 'modules/workspace/operations/useGetWebhookDestination'
import { useUpdateWebhookDestination } from 'modules/workspace/operations/useUpdateWebhookDestination'

type Values = {
  url: string
}

type WebhookConfigurationFormProps = {
  workspaceIdentifier: string
}

const VALIDATION_SCHEMA = yup.object().shape({
  url: yup.string().url('Enter a valid URL'),
})

const UrlInput = (props: FormikProps<Values>) => (
  <Form.Field name="url" label="Webhook URL">
    <Input
      type="text"
      name="url"
      placeholder="http://domain.com/POST"
      value={props.values.url}
      onChange={props.handleChange}
      onBlur={props.handleBlur}
    />
  </Form.Field>
)

type SubmitButtonProps = {
  savedUrl: string
  requestInFlight: boolean
} & FormikProps<Values>

const SubmitButton = (props: SubmitButtonProps) => {
  const hasNotChanged = props.values.url === props.savedUrl
  const isDisabled =
    props.requestInFlight ||
    hasNotChanged ||
    Object.keys(props.errors).length > 0

  const hasEmptied = props.savedUrl !== '' && props.values.url === ''
  const shouldDelete = !hasNotChanged && hasEmptied

  const variant = shouldDelete ? 'negative' : 'secondary'
  const buttonText = shouldDelete ? 'Delete' : 'Save'

  return (
    <Button
      variant={variant}
      type="submit"
      size="40"
      data-testid="webhook-configuration-form-submit"
      disabled={isDisabled}
    >
      {buttonText}
    </Button>
  )
}

const FormErrors = (props: FormikProps<Values>) => {
  if (!props.touched.url || !props.errors.url) return null

  return <Form.Error>{props.errors.url}</Form.Error>
}

export const WebhookConfigurationForm: React.FC<WebhookConfigurationFormProps> = ({
  workspaceIdentifier,
}) => {
  const { showToast } = useToast()

  const deleteWebhookDestination = useDeleteWebhookDestination({
    onCompleted: () => showToast('Webhook configuration deleted'),
  })
  const updateWebhookDestination = useUpdateWebhookDestination({
    onCompleted: () => showToast('Webhook configuration updated'),
  })
  const { webhookDestination, loading } = useGetWebhookDestination({
    workspaceIdentifier,
  })
  const [requestInFlight, setRequestInFlight] = useState<boolean>(false)

  if (loading) return <LoadingPlaceholder />

  const savedUrl = webhookDestination ? webhookDestination.url : ''

  const initialValues: Values = { url: savedUrl }

  const handleOnSubmit = async (values: Values) => {
    const shouldDelete = values.url === '' && initialValues.url !== ''

    setRequestInFlight(true)
    try {
      if (shouldDelete) {
        await deleteWebhookDestination({ workspaceIdentifier })
        return
      }

      await updateWebhookDestination({ workspaceIdentifier, url: values.url })
    } finally {
      setRequestInFlight(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={handleOnSubmit}
    >
      {formikProps => (
        <>
          <StyledForm>
            <UrlInput {...formikProps} />
            <SubmitButton
              requestInFlight={requestInFlight}
              savedUrl={savedUrl}
              {...formikProps}
            />
          </StyledForm>
          <FormErrors {...formikProps} />
          <p>
            A POST request will be sent to this URL when an event occurs in the
            Workspace
          </p>
        </>
      )}
    </Formik>
  )
}

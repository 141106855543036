import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { ErrorMessage, Link, NotEmbedded, CenterContent, IsEmbedded } from '@sketch/components';
import '../EmptyHeader/index.js';
import styled from 'styled-components';
import { ReactComponent } from '@sketch/icons/logo';
import '../../user/index.js';
import '../Embedding/index.js';
import '../../routes/index.js';
import { routes } from '../../routes/routes.js';
import { IsSignedIn } from '../../user/IsSignedIn/IsSignedIn.js';
import { EmptyHeader } from '../EmptyHeader/EmptyHeader.js';
import { EmbeddedErrorPage } from '../Embedding/EmbeddedErrorPage.js';

const StyledErrorMessage = styled(ErrorMessage) `
  flex: 1 0 auto;
  align-self: center;
`;
const RenderNotFoundState = ({ title = 'We cannot find the page you’re looking for.', additionalMessage, isSignedIn, }) => (jsxs(StyledErrorMessage, Object.assign({ title: title, icon: () => jsx(ReactComponent, { width: "46px", height: "46px" }) }, { children: [additionalMessage, isSignedIn ? (jsx(Link, Object.assign({ to: routes.ENTRY.create({}) }, { children: "Go to your documents overview" }))) : (jsxs("span", { children: [jsx(Link, Object.assign({ to: routes.SIGN_IN.create({}) }, { children: "Sign in to Sketch" })), " or", ' ', jsx(Link, Object.assign({ to: routes.SIGN_UP.create({}) }, { children: "Create an account" }))] }))] })));
const NotFoundState = props => (jsx(IsSignedIn, { children: (isSignedIn) => (jsx(RenderNotFoundState, { title: props.title, additionalMessage: props.additionalMessage, isSignedIn: isSignedIn })) }));
const NotFoundView = props => (jsxs(Fragment, { children: [jsx(NotEmbedded, { children: jsxs(CenterContent, { children: [props.isInLayout ? null : jsx(EmptyHeader, {}), jsx(NotFoundState, { title: props.title, additionalMessage: props.additionalMessage })] }) }), jsx(IsEmbedded, { children: jsx(EmbeddedErrorPage, { for: "unknown" }) })] }));

export { NotFoundState, NotFoundView, RenderNotFoundState };

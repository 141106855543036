import ApolloClient from 'apollo-client'
import { WritableDraft } from 'immer/dist/internal'

import {
  AnnotationArtboardInfoFragment,
  AnnotationArtboardInfoFragmentDoc,
  AnnotationSubjectFragment,
  BaseAnnotationFragment,
  GetAnnotationDotsQuery,
  GetAnnotationDotsQueryVariables,
  GetAnnotationsQuery,
  GetAnnotationsQueryVariables,
} from '@sketch/gql-types'

import {
  errorPreventiveFragmentRead,
  removeFromPaginated,
} from '@sketch/modules-common'
import { dataIdFromObject } from '@sketch/graphql-cache'
import { getCachedQueriesByOperationName } from '@sketch/modules-common/utils'

import {
  getAnnotationDot,
  getBaseAnnotation,
  updateAnnotationsAllOrder,
  updateAnnotationsDotsQuery,
  updateAnnotationsInboxOrder,
  updateAnnotationsQuery,
  isSameAnnotationSubject,
  getCacheInputFromAnnotationSubject,
} from './cache'
import { AnnotationSubjectInput, CacheAnnotationSubjectInput } from '../types'

interface AdditionalProps {
  shareIdentifier: string
  versionIdentifier?: string | null
}

const isAnnotationSubjectInVariablesContext = (
  queryInput: AnnotationSubjectInput,
  cacheAnnotationSubject: CacheAnnotationSubjectInput
) => {
  const { type, permanentId } = queryInput

  if (cacheAnnotationSubject?.type === 'ARTBOARD') {
    return (
      (type === 'ARTBOARD' &&
        cacheAnnotationSubject.permanentId === permanentId) ||
      (type === 'PAGE' &&
        cacheAnnotationSubject.permanentPageId === permanentId)
    )
  }

  return cacheAnnotationSubject?.permanentId === permanentId
}

const normalizeSubjectVariables = (
  variables: Pick<GetAnnotationsQueryVariables, 'subject' | 'subjects'>
) => {
  if (variables.subjects) {
    return variables.subjects
  }

  if (variables.subject) {
    return [variables.subject]
  }

  return []
}

export const doesAnnotationBelongToQueryVariables = (
  { shareIdentifier, versionIdentifier }: AdditionalProps,
  variables: GetAnnotationsQueryVariables | GetAnnotationDotsQueryVariables,
  cacheAnnotationSubject: CacheAnnotationSubjectInput
) => {
  if (
    variables.shareIdentifier !== shareIdentifier ||
    variables.versionIdentifier !== versionIdentifier
  ) {
    return false
  }

  const subjects = normalizeSubjectVariables(variables)
  const inContextSubjects = subjects.filter(subject =>
    isAnnotationSubjectInVariablesContext(subject, cacheAnnotationSubject)
  )

  return inContextSubjects.length > 0
}

export const createNewAnnotationInCache = (
  apollo: ApolloClient<object>,
  additionalProps: AdditionalProps,
  annotation: BaseAnnotationFragment
) => {
  /**
   * In order to successfully add an annotation to the list
   * we need to:
   * [programmatically]
   * - add the annotation to its annotations listing [1]
   * - add to the annotation dots query [2]
   *
   * [fragment BaseAnnotation refetch]
   * - update the subject annotation counters
   */

  const annotationSubject = getCacheInputFromAnnotationSubject(annotation)
  if (annotationSubject === null) {
    /**
     * If the subject is "UnprocessedSubject" which should never be the case (for FE)
     * "getCacheInputFromAnnotationSubject" will return null.
     * Since FE can't handle this state, there is no need to proceed with the
     * cache operations
     */
    return
  }

  /**
   * Get the cached/live queries that use supply annotation list data
   * and update them with the new annotation, checking if its subject
   * matches any of the query subjects.
   *
   * We check if the annotation subject and validate if its the same queried one
   * or belongs to it (artboards and pages).
   */
  const cachedListsQueriesToAdd = getCachedQueriesByOperationName(
    apollo,
    'getAnnotations',
    ({ variables }) =>
      doesAnnotationBelongToQueryVariables(
        additionalProps,
        variables,
        annotationSubject
      ) && variables.annotationStatus !== 'RESOLVED_ONLY'
  )

  const addNewAnnotationQuery = ({
    annotations,
  }: WritableDraft<GetAnnotationsQuery>) => {
    /**
     * Double check if the annotation already exists
     * because if it does we shouldn't re-add it
     */
    const doesAnnotationAlreadyExist = annotations.entries.find(
      ({ identifier }) => annotation.identifier === identifier
    )

    if (!doesAnnotationAlreadyExist) {
      annotations.entries.unshift(annotation)
      annotations.meta.totalCount++
    }
  }

  /**
   * Iterate over the queries that are related with this annotation subject
   * and add it
   */
  cachedListsQueriesToAdd.forEach(({ variables }) => {
    if (
      variables.sort === 'NEW_FIRST' &&
      variables.annotationStatus === 'ACTIVE_ONLY'
    ) {
      updateAnnotationsInboxOrder(apollo, variables, annotation)
    } else {
      updateAnnotationsQuery(apollo, variables, addNewAnnotationQuery)
    }
  })

  /**
   * Get the cached/live queries that use supply annotation dots data
   * and update them with the new annotation, checking if its subject
   * matches any of the query subjects.
   *
   * We check if the annotation subject and validate if its the same queried one
   * or belongs to it (artboards and pages).
   */
  const cachedDotQueriesToAdd = getCachedQueriesByOperationName(
    apollo,
    'getAnnotationDots',
    ({ variables }) =>
      doesAnnotationBelongToQueryVariables(
        additionalProps,
        variables,
        annotationSubject
      ) && variables.annotationStatus !== 'RESOLVED_ONLY'
  )

  /**
   * Iterate over the queries that are related with this annotation subject
   * and add it
   */
  cachedDotQueriesToAdd.forEach(({ variables }) => {
    updateAnnotationsDotsQuery(apollo, variables, addNewAnnotationQuery)
  })
}

export const moveAnnotationInCache = (
  apollo: ApolloClient<object>,
  additionalProps: AdditionalProps,
  annotation: BaseAnnotationFragment,
  cachedAnnotation?: BaseAnnotationFragment
) => {
  /**
   * In order to successfully move an annotation
   * we need to:
   * [programmatically]
   * - check if the subject has changed and remove/add to its query [1]
   * - update the older annotation counter [2]
   *
   * [fragment BaseAnnotation refetch]
   * - update new subject annotation counters
   */

  /**
   * [1] check if the subject has changed and remove/add to its query
   *
   * We are fetching the current annotation from cache (before applying the subscription data)
   * to double-check if the subject (artboard/page) have changed, if it has we need to update the
   * queries
   */

  const annotationSubject = getCacheInputFromAnnotationSubject(annotation)

  if (annotationSubject === null) {
    /**
     * If the subject is "UnprocessedSubject", which should never happen (for FE)
     * "getCacheInputFromAnnotationSubject" will return null, because FE can't represent this
     * no need to proceed with the cache operations
     */
    return
  }

  if (
    cachedAnnotation &&
    isSameAnnotationSubject(annotation, cachedAnnotation)
  ) {
    /**
     * If the annotation.currentArtboard is the same this
     * means the annotation only was moved inside the artboard
     * so we don't need to add additional logic.
     *
     * If it's a move in a page annotation this will still be null and
     * the logic still applies
     */
    return
  }

  const cachedListsQueriesToAdd = getCachedQueriesByOperationName(
    apollo,
    'getAnnotations',
    ({ variables }) =>
      doesAnnotationBelongToQueryVariables(
        additionalProps,
        variables,
        annotationSubject
      )
  )

  cachedListsQueriesToAdd.forEach(({ variables }) => {
    if (
      variables.sort === 'NEW_FIRST' &&
      variables.annotationStatus === 'ACTIVE_ONLY'
    ) {
      // Add to the Active dot list
      updateAnnotationsInboxOrder(apollo, variables, annotation)
    } else {
      updateAnnotationsAllOrder(apollo, variables, annotation)
    }
  })

  const cachedDotQueriesToAdd = getCachedQueriesByOperationName(
    apollo,
    'getAnnotationDots',
    ({ variables }) =>
      doesAnnotationBelongToQueryVariables(
        additionalProps,
        variables,
        annotationSubject
      )
  )

  const addAnnotation = ({
    annotations,
  }: WritableDraft<GetAnnotationsQuery>) => {
    /**
     * Double check if the annotation already exists
     * because if it does we shouldn't re-add it
     */
    const doesAnnotationAlreadyExist = annotations.entries.find(
      ({ identifier }) => annotation.identifier === identifier
    )

    if (!doesAnnotationAlreadyExist) {
      annotations.entries.unshift(annotation)
      annotations.meta.totalCount++
    }
  }

  cachedDotQueriesToAdd.forEach(({ variables }) => {
    // Add to the Active dot list
    updateAnnotationsDotsQuery(apollo, variables, addAnnotation)
  })

  /**
   * Clean up time!
   *
   * In here we start to remove the old annotation references from the queries
   * of the previous relatable subjects, if the query already existed on the cache
   * because if it didn't... then there's nothing to do
   */
  if (!cachedAnnotation) {
    return
  }

  const annotationToRemove = getCacheInputFromAnnotationSubject(
    cachedAnnotation
  )

  if (!annotationToRemove) {
    return
  }

  const deleteAnnotation = ({
    annotations,
  }: WritableDraft<GetAnnotationsQuery>) => {
    annotations.entries = annotations.entries.filter(
      ({ identifier }) => identifier !== annotation.identifier
    )
    annotations.meta.totalCount--
  }

  const cachedListsQueriesToRemove = getCachedQueriesByOperationName(
    apollo,
    'getAnnotations',
    query => {
      const annotationBelongsToQuery = doesAnnotationBelongToQueryVariables(
        additionalProps,
        query.variables,
        annotationToRemove
      )

      return (
        annotationBelongsToQuery && !cachedListsQueriesToAdd.includes(query)
      )
    }
  )

  cachedListsQueriesToRemove.forEach(({ variables }) => {
    updateAnnotationsQuery(apollo, variables, deleteAnnotation)
  })

  const cachedDotQueriesToRemove = getCachedQueriesByOperationName(
    apollo,
    'getAnnotationDots',
    query => {
      const annotationBelongsToQuery = doesAnnotationBelongToQueryVariables(
        additionalProps,
        query.variables,
        annotationToRemove
      )

      return annotationBelongsToQuery && !cachedDotQueriesToAdd.includes(query)
    }
  )

  console.log(cachedDotQueriesToRemove)

  cachedDotQueriesToRemove.forEach(({ variables }) => {
    updateAnnotationsDotsQuery(apollo, variables, deleteAnnotation)
  })

  /**
   * [2] update the older annotation counter
   */
  if (cachedAnnotation?.currentSubject?.__typename === 'Artboard') {
    const {
      documentVersionShortId,
      permanentArtboardShortId,
    } = cachedAnnotation.currentSubject

    const artboardId = dataIdFromObject({
      __typename: 'Artboard',
      documentVersionShortId,
      permanentArtboardShortId,
    })!

    const artboardCounters = errorPreventiveFragmentRead<AnnotationArtboardInfoFragment>(
      apollo,
      {
        fragment: AnnotationArtboardInfoFragmentDoc,
        fragmentName: 'AnnotationArtboardInfo',
        id: artboardId,
      }
    )

    artboardCounters &&
      apollo.writeFragment<AnnotationArtboardInfoFragment>({
        fragment: AnnotationArtboardInfoFragmentDoc,
        fragmentName: 'AnnotationArtboardInfo',
        id: artboardId,
        data: {
          ...artboardCounters,
          annotationCount: Math.max(artboardCounters.annotationCount - 1, 0),
          unreadCount: Math.max(artboardCounters.unreadCount - 1, 0),
        },
      })
  }
}

export const resolveAnnotation = (
  apollo: ApolloClient<object>,
  additionalProps: AdditionalProps,
  annotation: AnnotationSubjectFragment
) => {
  /**
   * In order to successfully resolve an annotation
   * we need to:
   * [programmatically]
   * - remove the annotation from all the active queries [1]
   * - add the annotation to the resolved queries [2]
   *
   * [fragment BaseAnnotation refetch]
   * - Update the subject annotation counters
   * - Update the resolution status
   */

  const { identifier } = annotation

  // [1] Remove the annotation from all the active queries
  removeFromPaginated(apollo, { __typename: 'Annotation', identifier }, key =>
    key.includes('ACTIVE_ONLY')
  )

  // [2] Add the annotation to the resolved queries
  const annotationSubject = getCacheInputFromAnnotationSubject(annotation)

  if (annotationSubject === null) {
    /**
     * If the subject is "UnprocessedSubject" which should never be the case (for FE)
     * "getCacheInputFromAnnotationSubject" will return null.
     * Since FE can't handle this state, there is no need to proceed with the
     * cache operations
     */
    return
  }

  /**
   * To prevent including the "BaseAnnotation" fragment on the
   * "resolveAnnotation" mutation, it would force a huge optimistic response,
   * it was opted to get the base annotation fragment isolated.
   *
   * Since this fragment will only be relevant when the resolved sidebar is visible,
   * this doesn't occur when you are resolving the annotation on the active sidebar (filter)
   *
   * If the user is resolving a annotation (in the popover) with the resolved sidebar visible on the side
   * the "annotationComments" query already includes the "BaseAnnotation" fragment
   * to make sure the sidebar is updated has it included
   */
  const baseAnnotation = getBaseAnnotation(apollo, annotation.identifier)
  if (baseAnnotation) {
    const cachedListQueriesToAdd = getCachedQueriesByOperationName(
      apollo,
      'getAnnotations',
      query => {
        const annotationBelongsToQuery = doesAnnotationBelongToQueryVariables(
          additionalProps,
          query.variables,
          annotationSubject
        )

        return (
          annotationBelongsToQuery &&
          query.variables.annotationStatus === 'RESOLVED_ONLY'
        )
      }
    )

    cachedListQueriesToAdd.forEach(({ variables }) => {
      updateAnnotationsQuery(apollo, variables, ({ annotations }) => {
        annotations.entries.unshift(baseAnnotation)
        annotations.meta.totalCount++
      })
    })
  }

  /*
   * `getBaseAnnotation` will return null if the user is not viewing the
   * `Comments` tab in the sidebar.
   *
   * To ensure that the annotation dots are updated correctly, we read and
   * update them separately.
   */
  const annotationDot = getAnnotationDot(apollo, annotation.identifier)
  if (annotationDot) {
    const cachedDotQueriesToAdd = getCachedQueriesByOperationName(
      apollo,
      'getAnnotationDots',
      query => {
        const annotationBelongsToQuery = doesAnnotationBelongToQueryVariables(
          additionalProps,
          query.variables,
          annotationSubject
        )

        return (
          annotationBelongsToQuery &&
          query.variables.annotationStatus === 'RESOLVED_ONLY'
        )
      }
    )

    cachedDotQueriesToAdd.forEach(({ variables }) => {
      updateAnnotationsDotsQuery(apollo, variables, ({ annotations }) => {
        annotations.entries.push(annotationDot)
        annotations.meta.totalCount++
      })
    })
  }
}

export const unResolveAnnotation = (
  apollo: ApolloClient<object>,
  additionalProps: AdditionalProps,
  annotation: AnnotationSubjectFragment
) => {
  /**
   * In order to successfully un-resolve an annotation
   * we need to:
   * [programmatically]
   * - remove the annotation from all the resolved queries [1]
   * - add the annotation to the active queries [2]
   *
   * [fragment BaseAnnotation refetch]
   * - Update the subject annotation counters
   * - Update the resolution status
   */
  const { identifier } = annotation

  // [1] Remove the annotation from all the resolved queries
  removeFromPaginated(apollo, { __typename: 'Annotation', identifier }, key =>
    key.includes('RESOLVED_ONLY')
  )

  // [2] Add the annotation to the active queries
  const annotationSubject = getCacheInputFromAnnotationSubject(annotation)

  if (annotationSubject === null) {
    /**
     * If the subject is "UnprocessedSubject" which should never be the case (for FE)
     * "getCacheInputFromAnnotationSubject" will return null.
     * Since FE can't handle this state, there is no need to proceed with the
     * cache operations
     */
    return
  }

  /**
   * To prevent including the "BaseAnnotation" fragment on the
   * "unresolveAnnotation" mutation, it would force a huge optimistic response,
   * it was opted to get the base annotation fragment isolated.
   *
   * Since this fragment will only be relevant when the active sidebar is visible,
   * this doesn't occur when you are un-resolving the annotation on the resolved sidebar (filter)
   *
   * If the user is un-resolving a annotation (in the popover) with the active sidebar visible on the side
   * the "annotationComments" query already includes the "BaseAnnotation" fragment
   * to make sure the sidebar is updated has it included
   */
  const baseAnnotation = getBaseAnnotation(apollo, annotation.identifier)
  if (baseAnnotation) {
    const cachedListsQueriesToAdd = getCachedQueriesByOperationName(
      apollo,
      'getAnnotations',
      ({ variables }) =>
        doesAnnotationBelongToQueryVariables(
          additionalProps,
          variables,
          annotationSubject
        ) && variables.annotationStatus !== 'RESOLVED_ONLY'
    )

    cachedListsQueriesToAdd.forEach(({ variables }) => {
      if (
        variables.sort === 'NEW_FIRST' &&
        variables.annotationStatus === 'ACTIVE_ONLY'
      ) {
        // Add to the Active dot list
        updateAnnotationsInboxOrder(apollo, variables, baseAnnotation)
      } else {
        updateAnnotationsAllOrder(apollo, variables, baseAnnotation)
      }
    })
  }

  /*
   * `getBaseAnnotation` will return null if the user is not viewing the
   * `Comments` tab in the sidebar.
   *
   * To ensure that the annotation dots are updated correctly, we read and
   * update them separately.
   */
  const annotationDot = getAnnotationDot(apollo, annotation.identifier)
  if (annotationDot) {
    const cachedDotQueriesToAdd = getCachedQueriesByOperationName(
      apollo,
      'getAnnotationDots',
      ({ variables }) =>
        doesAnnotationBelongToQueryVariables(
          additionalProps,
          variables,
          annotationSubject
        ) && variables.annotationStatus !== 'RESOLVED_ONLY'
    )

    const addAnnotation = ({
      annotations,
    }: WritableDraft<GetAnnotationDotsQuery>) => {
      /**
       * Double check if the annotation already exists
       * because if it does we shouldn't re-add it
       */
      const doesAnnotationAlreadyExist = annotations.entries.find(
        ({ identifier }) => annotation.identifier === identifier
      )

      if (!doesAnnotationAlreadyExist) {
        annotations.entries.unshift(annotationDot)
        annotations.meta.totalCount++
      }
    }

    cachedDotQueriesToAdd.forEach(({ variables }) => {
      // Add to the Active dot list
      updateAnnotationsDotsQuery(apollo, variables, addAnnotation)
    })
  }
}

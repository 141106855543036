import { jsx, jsxs } from 'react/jsx-runtime';
import styled from 'styled-components';
import { Text, IconWrapper, Icon, Wrapper } from './Segment.styles.js';

const WrapperComponent = ({ className, render, children }) => {
    return render(className, children);
};
const SegmentUnstyled = props => {
    const { active, className, type, label, icon, render = (className, children) => (jsx("span", Object.assign({ className: className }, { children: children }))), disabled, } = props;
    const children = type === 'text' ? (jsx(Text, { children: label })) : (jsxs(IconWrapper, { children: [jsx("span", Object.assign({ className: "sr-only" }, { children: label })), jsx(Icon, { as: icon })] }));
    return (jsx(Wrapper, Object.assign({ className: className, "$active": active, render: render, as: WrapperComponent, "$disabled": disabled }, { children: children })));
};
const Segment = styled(SegmentUnstyled) ``;

export { Segment };

const isProductionBuild = () => process.env.NODE_ENV === 'production';
// Utility to check if the current frontend built is running on staging environment (intended to be released on dev.sketch.cloud)
const isDeployedInDev = () => process.env.REACT_APP_ENV === 'dev' && isProductionBuild();
// Utility to check if the current frontend built is running on staging environment (intended to be released on test.sketch.cloud)
const isDeployedInTest = () => process.env.REACT_APP_ENV === 'test' && isProductionBuild();
// Utility to check if the current frontend built is running on staging environment (intended to be released on staging.sketch.cloud)
const isDeployedInStaging = () => process.env.REACT_APP_ENV === 'staging' && isProductionBuild();
// Utility to check if the current frontend built is production ready (intended to be released on www.sketch.cloud)
const isDeployedInProduction = () => process.env.REACT_APP_ENV === 'production' && isProductionBuild();

export { isDeployedInDev, isDeployedInProduction, isDeployedInStaging, isDeployedInTest, isProductionBuild };

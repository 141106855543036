import React from 'react'

import { routes } from '@sketch/modules-common'

import { Button, Link, Modal, ModalInjectedProps } from '@sketch/components'

export interface InviteMembersNotAllowedModalProps extends ModalInjectedProps {
  workspaceId: string
}

export const InviteMembersNotAllowedModal: React.FC<InviteMembersNotAllowedModalProps> = ({
  workspaceId,
  hideModal,
}) => {
  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>Invite Members to Your Workspace</Modal.Header>
      <Modal.Body>
        <p>
          This workspace is configured to use Single Sign On, new users should
          be given access via your configured Identity Provider. Only admins and
          users given access via the Identity Provider will be able to access
          the workspace.{' '}
          <Link
            onClick={hideModal}
            to={routes.WORKSPACE_SETTINGS_SSO.create({ workspaceId })}
          >
            SSO Configuration
          </Link>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal}>Done</Button>
      </Modal.Footer>
    </Modal>
  )
}

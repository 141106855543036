import React from 'react'
import styled from 'styled-components'
import { Label, Value, Display } from '../../Attribute'
import { Tooltip } from '@sketch/components'
import ColorDot from '../ColorDot/ColorDot'
import ColorDropdown from '../../ColorDropdown'
import rgbTo, { ColorFormat } from '../utils'
import { Color as ColorType } from 'modules/shares/types'
import copy from 'copy-to-clipboard'
import { useHandleCopyValue } from '@sketch/modules-common'
import DirtyIconTooltip from 'modules/shares/Inspector/Sidebar/components/Style/DirtyIconTooltip'

/**
 * TYPES
 */
export interface PlainColorProps extends ColorType {
  label?: string
  style?: React.CSSProperties
  display?: Display
  dirtyAttributes?: { originalValue: ColorType; originalProperty: string }
  onColorFormatChange: (f: ColorFormat) => void
  colorFormat: ColorFormat
}

/**
 * STYLES
 */
export const ClickableArea = styled(Label)`
  cursor: pointer;
  width: 100%;
  display: flex;
`

const LabelWithWarning = styled(Label)`
  display: flex;
  width: 33.33%;
  align-content: center;
`

const FlexValue = styled(Value)`
  display: flex;
`

/**
 * UTILS
 */
const copyFormat = (format: ColorFormat, colorParams: ColorType) => {
  copy(rgbTo(format, colorParams))
}

/**
 * COMPONENT
 */
const PlainColor: React.FC<PlainColorProps> = props => {
  const {
    red,
    green,
    blue,
    alpha = 1,
    label = 'Color',
    onColorFormatChange,
    colorFormat,
    dirtyAttributes,
  } = props
  const colorParams = { red, green, blue, alpha }
  const copyValue = rgbTo(colorFormat, colorParams)

  const {
    handleCopyValue,
    copyTooltipText,
    handleEnterCopiableArea,
    handleLeaveCopiableArea,
    copyTooltipVisible,
  } = useHandleCopyValue(copyValue)

  const dirtyColorValues = []

  if (dirtyAttributes) {
    dirtyColorValues.push(rgbTo(colorFormat, dirtyAttributes.originalValue))

    if (
      dirtyAttributes.originalValue?.alpha &&
      colorFormat === ColorFormat.HEX
    ) {
      dirtyColorValues.push(
        `opacity ${dirtyAttributes.originalValue?.alpha * 100}%`
      )
    }
  }

  return (
    <Tooltip
      placement="left"
      spacing="10px"
      visible={copyTooltipVisible}
      content={copyTooltipText}
    >
      <ClickableArea
        onClick={handleCopyValue}
        onMouseEnter={handleEnterCopiableArea}
        onMouseLeave={handleLeaveCopiableArea}
      >
        {label && (
          <LabelWithWarning>
            {/* the extra handleEnterCopiableArea is to make sure the copy tooltip is triggered after leaving the dirty icon tooltip */}
            <div
              aria-hidden
              onMouseEnter={
                dirtyAttributes ? handleEnterCopiableArea : undefined
              }
            >
              {label}
            </div>
            {dirtyAttributes && (
              <DirtyIconTooltip
                originalProperty={dirtyAttributes.originalProperty}
                originalValue={dirtyColorValues.join(', ')}
                preventLabelTooltip={handleLeaveCopiableArea}
              />
            )}
          </LabelWithWarning>
        )}
        <FlexValue
          valueString={copyValue}
          label="Color"
          onMouseEnter={dirtyAttributes ? handleEnterCopiableArea : undefined}
        >
          <ColorDot
            red={red}
            green={green}
            blue={blue}
            alpha={alpha}
            format={colorFormat}
          />
          <ColorDropdown
            onChange={newFormat => {
              copyFormat(newFormat, colorParams)
              onColorFormatChange(newFormat)
            }}
          />
        </FlexValue>
      </ClickableArea>
    </Tooltip>
  )
}

export default PlainColor

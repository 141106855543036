import '../../analytics/index.js';
import { useTrackFirstValueChange } from '../../analytics/plausible/useTrackFirstValueChange.js';

/**
 * Trigger analytics events when user starts editing some
 * fields of the form
 */
function useTrackEditedFields(values, formContext) {
    const eventProps = {
        formContext: formContext,
    };
    useTrackFirstValueChange(values.name, 'SIGN UP - "name" field edited', eventProps);
    useTrackFirstValueChange(values.email, 'SIGN UP - "email" field edited', eventProps);
    useTrackFirstValueChange(values.password, 'SIGN UP - "password" field edited', eventProps);
}

export { useTrackEditedFields };

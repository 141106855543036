import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import { timeConstants, getRelativeDate } from '@sketch/utils';

function defaultFormatter(value, _unit, suffix) {
    const unit = value !== 1 ? _unit + 's' : _unit;
    return value + ' ' + unit + ' ' + suffix;
}
function dateParser(date) {
    const parsed = new Date(date);
    if (!Number.isNaN(parsed.valueOf())) {
        return parsed;
    }
    const parts = String(date).match(/\d+/g);
    if (parts == null || parts.length <= 2) {
        return parsed;
    }
    else {
        const [firstP, secondP, ...restPs] = parts.map(x => parseInt(x));
        const correctedParts = [firstP, secondP - 1, ...restPs];
        const isoDate = new Date(Date.UTC(...correctedParts));
        return isoDate;
    }
}
const defaultNow = () => Date.now();
function DefaultTimeAgo(_a) {
    var { date, formatter = defaultFormatter, component = 'time', live = true, minPeriod = 0, maxPeriod = timeConstants.WEEK, title, now = defaultNow } = _a, passDownProps = __rest(_a, ["date", "formatter", "component", "live", "minPeriod", "maxPeriod", "title", "now"]);
    const [timeNow, setTimeNow] = useState(now());
    useEffect(() => {
        if (!live) {
            return;
        }
        const tick = () => {
            const then = dateParser(date).valueOf();
            if (!then) {
                console.warn('[react-timeago] Invalid Date provided');
                return 0;
            }
            const seconds = Math.round(Math.abs(timeNow - then) / 1000);
            const unboundPeriod = seconds < timeConstants.MINUTE
                ? 1000
                : seconds < timeConstants.HOUR
                    ? 1000 * timeConstants.MINUTE
                    : seconds < timeConstants.DAY
                        ? 1000 * timeConstants.HOUR
                        : 1000 * timeConstants.WEEK;
            const period = Math.min(Math.max(unboundPeriod, minPeriod * 1000), maxPeriod * 1000);
            if (period) {
                return setTimeout(() => {
                    setTimeNow(now());
                }, period);
            }
            return 0;
        };
        const timeoutId = tick();
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [date, live, maxPeriod, minPeriod, now, timeNow]);
    const Component = component;
    const then = dateParser(date).valueOf();
    if (!then) {
        return null;
    }
    const seconds = Math.round(Math.abs(timeNow - then) / 1000);
    const suffix = then < timeNow ? 'ago' : 'from now';
    const [value, unit] = getRelativeDate(seconds);
    const passDownTitle = typeof title === 'undefined'
        ? typeof date === 'string'
            ? date
            : dateParser(date).toISOString().substr(0, 16).replace('T', ' ')
        : title;
    const spreadProps = Component === 'time'
        ? Object.assign(Object.assign({}, passDownProps), { dateTime: dateParser(date).toISOString() }) : passDownProps;
    const nextFormatter = defaultFormatter.bind(null, value, unit, suffix);
    return (jsx(Component, Object.assign({}, spreadProps, { title: passDownTitle }, { children: formatter(value, unit, suffix, then, nextFormatter, now) })));
}

export { DefaultTimeAgo as default, defaultFormatter };

import styled, { css } from 'styled-components'
import { ButtonUnstyled } from '@sketch/components'
import { ReactComponent as DocumentUnstyled } from '@sketch/icons/document-16'
import { ReactComponent as FolderUnstyled } from '@sketch/icons/folder-closed-16'
import { ReactComponent as CalendarUnstyled } from '@sketch/icons/calendar-16'
import { ReactComponent as PersoUserUnstyled } from '@sketch/icons/person-16'
import { ReactComponent as CrossUnstyled } from '@sketch/icons/cross-16'

export const OptionPill = styled(ButtonUnstyled)(
  ({ theme }) => css`
    cursor: pointer;
    height: 32px;
    border-radius: ${theme.radii.large};
    border: none;
    background-color: ${theme.colors.state.hover};
    color: ${theme.colors.foreground.secondary.A};
    font-size: ${theme.fontSizes.D};
    padding: 8px 12px;
    display: inline-flex;

    :first-of-type {
      margin-left: 8px;
    }

    &:not(:last-of-type) {
      margin-right: 8px;
    }
  `
)

export const RemoveIcon = styled(CrossUnstyled)(
  ({ theme }) => css`
    width: 13px;
    color: ${theme.colors.foreground.secondary.A};
    margin-left: 4px;
  `
)

const IconStyle = css`
  width: 13px;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  margin-right: 4px;
`

export const DocumentIcon = styled(DocumentUnstyled)`
  ${IconStyle}
`
export const ProjectIcon = styled(FolderUnstyled)`
  ${IconStyle}
`
export const DateIcon = styled(CalendarUnstyled)`
  ${IconStyle}
`
export const UserIcon = styled(PersoUserUnstyled)`
  ${IconStyle}
`

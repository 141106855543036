import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import styled from 'styled-components';
import { SKETCH_WEBSITE } from '@sketch/env-config';
import { linkCss } from './RecoveryCode.styles.js';
import { Link } from '@sketch/components';

const StyledLink = styled(Link) `
  ${linkCss}
`;
/**
 * Common links used in both Verification Code and Recovery Code UI
 */
const HelpNote = () => {
    return (jsxs(Fragment, { children: ["Lost your recovery codes?", ' ', jsx(StyledLink, Object.assign({ external: true, isUnderlined: true, href: `${SKETCH_WEBSITE}/support/contact/` }, { children: "Contact us" })), jsx("br", {})] }));
};

export { HelpNote, HelpNote as default };

import { __rest } from 'tslib';
import { useLeaveWorkspaceMutation } from '@sketch/gql-types';
import { removeFromUserMemberships, removeUserFromWorkspace } from '../../../../../common/workspaceMembershipsCache.js';

/**
 * useLeaveWorkspace
 *
 * This is mostly the same as useRemoveWorkspaceMember but we use
 * leaveWorkspaceMutation which does not query for the billing field.
 *
 * We don't need the billing field when leaving a workspace and this solves
 * a permissions error due to Guests not being able to query billing field
 *
 */
const useLeaveWorkspace = (options) => {
    const { update: updateBase, onError: onErrorBase, variables } = options, rest = __rest(options, ["update", "onError", "variables"]);
    const onError = ({ message }) => {
        onErrorBase === null || onErrorBase === void 0 ? void 0 : onErrorBase(message);
    };
    const update = (cache, mutationResult) => {
        const { data } = mutationResult;
        if (!(data === null || data === void 0 ? void 0 : data.removeWorkspaceMember)) {
            return;
        }
        const { workspace } = data.removeWorkspaceMember;
        const { membershipId } = variables;
        removeFromUserMemberships(cache, membershipId);
        removeUserFromWorkspace(cache, membershipId, workspace.identifier);
        updateBase && updateBase(cache, mutationResult);
    };
    return useLeaveWorkspaceMutation(Object.assign({ update,
        onError,
        variables }, rest));
};

export { useLeaveWorkspace };

import styled, { css } from 'styled-components';
import { Link, Text } from '@sketch/components';
import { breakpoint } from '@sketch/global-styles';

const StyledLink = styled(Link) `
  display: block;
  margin-top: 24px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.E};
`;
const FormContainer = styled.div `
  max-width: 400px;
  margin: 0 auto;
`;
const DimText = styled(Text).attrs({
    textStyle: 'copy.quaternary.standard.E',
}) `
  max-width: 400px;
  margin: 24px auto 0;
  text-align: center;

  ${StyledLink} {
    display: inline-block;
    margin: 0;
    color: ${({ theme }) => theme.colors.foreground.secondary.D};
  }
`;
const TextSignUp = styled.p `
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  margin-top: 32px;
  font-size: ${({ theme }) => theme.fontSizes.E};
  text-align: center;
`;
const Line = styled.div `
  width: 100%;
  margin-top: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`;
const Column = styled.div ``;
const Container = styled.div `
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 20px;

  ${breakpoint('md') `
    flex-direction: row;
    gap: 80px;
    padding: 0 32px;
  `}

  ${breakpoint('lg') `
    gap: 145px;
  `}

  ${Column} {
    max-width: 490px;
  }
`;
const Title = styled(Text.H1) `
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  margin: 0 0 16px 0;
  font-size: ${({ theme }) => theme.fontSizes.I};
  line-height: 1;
`;
const Subtitle = styled(Text.P) `
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  margin-bottom: 24px;
`;
const Feature = styled.div `
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  display: flex;
  align-items: top;
  margin-bottom: 12px;
  line-height: 1.4;
`;
const FeatureImg = styled.img `
  margin-right: 8px;
  width: 24px;
  height: 24px;

  // Invert the icon depending on the Dark Mode setting
  ${({ isDarkModeActive }) => isDarkModeActive
    ? css `
          filter: saturate(0) invert(1);
        `
    : null}
`;
const OperatingSystemWarning = styled(Text).attrs({
    textStyle: 'copy.quaternary.standard.C',
}) `
  text-align: center;
  margin-top: 12px;
`;
const RelatedContentTitle = styled.h1(({ theme }) => css `
    font-size: ${theme.fontSizes.F};
    font-weight: ${theme.fontWeights.medium};
    color: ${theme.colors.foreground.secondary.A};
    margin: 0;
  `);
const RelatedContentDescription = styled.p(({ theme }) => css `
    font-size: ${theme.fontSizes.E};
    font-weight: ${theme.fontWeights.regular};
    color: ${theme.colors.foreground.secondary.D};
    line-height: ${theme.lineHeights.E};
    margin: 4px 0 0 0;
  `);
const RelatedContentImg = styled.img(({ theme }) => css `
    width: 64px;
    height: 64px;
    margin-right: 16px;
    border-radius: ${({ theme }) => theme.radii.xlarge};
  `);
const RelatedContentLink = styled(Link) `
  display: flex;
  flex-direction: row;
  margin-top: 28px;
  white-space: break-spaces;

  :hover {
    ${RelatedContentTitle} {
      color: ${({ theme }) => theme.colors.foreground.secondary.C};
      text-decoration: underline;
    }
  }
`;
const FullWidthImage = styled.img `
  width: 100%;
  margin-top: 28px;
`;
const RequiredOSVersion = styled.div `
  margin-bottom: 24px;
  padding: 16px;
  font-size: ${({ theme }) => theme.fontSizes.C};
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  background-color: ${({ theme }) => theme.colors.background.tertiary.C};
  border-radius: ${({ theme }) => theme.radii.large};
`;

export { Column, Container, DimText, Feature, FeatureImg, FormContainer, FullWidthImage, Line, OperatingSystemWarning, RelatedContentDescription, RelatedContentImg, RelatedContentLink, RelatedContentTitle, RequiredOSVersion, StyledLink, Subtitle, TextSignUp, Title };

import styled, { css } from 'styled-components';

const ScopeContainer = styled.div(({ enabled, theme }) => css `
    display: flex;
    margin-bottom: 16px;

    & :last-child {
      margin-bottom: 0;
    }

    span {
      line-height: 1.2;
      color: ${enabled
    ? theme.colors.foreground.secondary.C
    : theme.colors.state.disabled};
    }
  `);
const Icon = styled.svg.attrs({
    role: 'img',
})(({ enabled }) => css `
    width: 16px;
    height: 16px;
    margin-right: 8px;
    opacity: ${enabled ? '1' : '0.3'};
  `);

export { Icon, ScopeContainer };

import React, { useState, useEffect } from 'react'
import { Portal } from 'react-portal'

import { MobileNavigationHeader } from '../Header/Header.styles'
import { Truncate, Modal, ModalTransition } from '@sketch/components'
import {
  SelectDropdownModalHeader,
  ModalBody,
  SelectDropdownModalFooter,
  SelectDropdownCancelButton,
  Wrapper,
  BackButton,
  BackIcon,
  Trigger,
} from './MobileGroupsDropdown.styles'
import { ChevronsUpDownIcon } from '@sketch/components/dist/esm/SelectDropdown/SelectDropdown.styles'

interface MobileDropdownProps {
  type: string
  selected: string
  level?: number
  handleBack: () => void
  handleCloseMenu?: () => void
}

/**
 * Shows the component groups sidebar for mobile views, it's similar to
 * <SelectDropdown /> from projects, but requires some exceptions
 * (rendering a collapsible tree instead of a regular list for example), so I
 * decided to duplicate and tweak instead of creating a new abstraction.
 */
export const MobileGroupsDropdown: React.FC<MobileDropdownProps> = ({
  type,
  selected,
  children,
  level = 0,
  handleBack,
  handleCloseMenu,
}) => {
  const [hasOverflow, setHasOverflow] = useState(false)
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)
  const closeMenu = () => {
    setMobileMenuVisible(false)
    handleCloseMenu?.()
  }
  const openMenu = () => setMobileMenuVisible(true)

  // Close the menu when an item is selected
  useEffect(() => {
    if (mobileMenuVisible) {
      closeMenu()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const header =
    level === 0 ? (
      type
    ) : (
      <BackButton onClick={handleBack}>
        <BackIcon />
        {type}
      </BackButton>
    )

  return (
    <Wrapper>
      <Trigger
        type="button"
        aria-haspopup
        aria-expanded={mobileMenuVisible}
        onClick={openMenu}
      >
        <Truncate>{selected}</Truncate>
        <ChevronsUpDownIcon />
      </Trigger>
      <Portal>
        <ModalTransition show={mobileMenuVisible}>
          <Modal onCancel={closeMenu} onUpdateScroll={setHasOverflow}>
            <SelectDropdownModalHeader as={MobileNavigationHeader}>
              {header}
            </SelectDropdownModalHeader>
            <ModalBody>{children}</ModalBody>
            <SelectDropdownModalFooter hasOverflow={hasOverflow}>
              <SelectDropdownCancelButton
                variant="tertiary"
                small
                onClick={closeMenu}
                fill
              >
                Cancel
              </SelectDropdownCancelButton>
            </SelectDropdownModalFooter>
          </Modal>
        </ModalTransition>
      </Portal>
    </Wrapper>
  )
}

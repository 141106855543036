import { ReactComponent } from '@sketch/icons/chevrons-up-down-24';
import styled from 'styled-components';
import '../Select/index.js';
import '../Truncate/index.js';
import { selectBaseStyles } from '../Select/NativeSelect.styles.js';
import { truncateStyles } from '../Truncate/Truncate.js';

const SelectDropdownButtonTrigger = styled.button `
  ${selectBaseStyles}

  position: relative;
  text-align: left;

  min-height: 40px;
  padding-right: 44px;
  line-height: 1;

  cursor: pointer;

  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  box-shadow: 0px 0px 0px 2px transparent;

  transition: box-shadow 125ms ease-in-out;

  /* Prevent a large name from increasing the height */
  ${truncateStyles};

  &[aria-valuetext] {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  &[aria-expanded='true'],
  :focus-visible {
    /* Native focus ring */
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }

  :disabled {
    cursor: default;
  }
`;
const ChevronsUpDownIcon = styled(ReactComponent) `
  width: 24px;
  height: 24px;
  margin: auto;

  position: absolute;
  top: 0;
  right: 8px;
  bottom: 0;
`;

export { ChevronsUpDownIcon, SelectDropdownButtonTrigger };

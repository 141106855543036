import styled, { css } from 'styled-components';
import '../Box/index.js';
import { withBox } from '../Box/withBox.js';

const buttonUnstyledStyles = css `
  border: none;
  background-color: transparent;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  text-align: unset;

  &[disabled] {
    pointer-events: none;
  }

  &:focus-visible {
    outline-offset: -2px; /* To avoid cutting focus ring */
  }
`;
const ButtonUnstyled = styled(withBox('button')) `
  ${buttonUnstyledStyles};
`;
ButtonUnstyled.defaultProps = {
    p: 0,
    type: 'button',
};

export { ButtonUnstyled, buttonUnstyledStyles };

import { useGetUserCredentialsQuery } from '@sketch/gql-types';

const useUserSignedIn = () => {
    var _a;
    const { data } = useGetUserCredentialsQuery({
        fetchPolicy: 'cache-only',
    });
    return !!((_a = data === null || data === void 0 ? void 0 : data.userCredentials) === null || _a === void 0 ? void 0 : _a.authToken);
};

export { useUserSignedIn };

import React from 'react'

import { Dropdown, useModalContext } from '@sketch/components'

import ChangeTokenPermissionsConfirmationModal from './ChangeTokenPermissionsConfirmationModal'

import { WorkspaceMembershipFragment } from '@sketch/gql-types'

interface TokenPermissionsToggleProps {
  member: WorkspaceMembershipFragment
  workspaceIdentifier: string
}

const TokenPermissionsToggle: React.FC<TokenPermissionsToggleProps> = props => {
  const { showModal } = useModalContext()
  const { member, workspaceIdentifier } = props
  const { personalTokenPermissions } = member

  // Admins can only toggle the permissions of SSO workspace members,
  // If the member is not an SSO member, we don't show the toggle
  if (personalTokenPermissions === 'NON_SSO_MEMBER') return null

  const handleTogglePersonalTokensClick = () => {
    showModal(ChangeTokenPermissionsConfirmationModal, {
      member,
      workspaceIdentifier,
    })
  }

  const callToAction =
    personalTokenPermissions === 'SSO_MEMBER_DENY'
      ? 'Enable Personal Access Tokens...'
      : 'Disable Personal Access Tokens...'

  return (
    <Dropdown.Item onClick={handleTogglePersonalTokensClick}>
      {callToAction}
    </Dropdown.Item>
  )
}

export default TokenPermissionsToggle

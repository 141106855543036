import { jsx, jsxs } from 'react/jsx-runtime';
import { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Input, Form, Button, LoadingState } from '@sketch/components';
import { useToast } from '@sketch/toasts';
import { StyledFormField, StyledErrorField } from './SsoSignInForm.styles.js';
import { useGetSsoStartUrl } from '../useGetSsoStartUrl.js';

// Validation
const signInSchema = yup.object().shape({
    teamName: yup.string().trim().required('Enter your Workspace name'),
});
const INITIAL_VALUES = {
    teamName: '',
};
const TeamNameField = ({ touched, errors, values, handleChange, handleBlur, }) => (jsx(StyledFormField, Object.assign({ name: "teamName", label: "SSO Shortname", errorText: touched.teamName ? errors.teamName : undefined }, { children: jsx(Input, { name: "teamName", type: "text", placeholder: "SSO-Shortname", value: values.teamName, onChange: handleChange, onBlur: handleBlur, autoFocus: true }) })));
const Errors = ({ errors }) => errors.length > 0 ? jsx(StyledErrorField, { children: errors[0] }) : null;
const Submit = ({ isLoading, disabled }) => (jsx(Form.Footer, { children: jsx(Button, Object.assign({ variant: "primary", type: "submit", disabled: isLoading || disabled, loading: isLoading, size: "40", fill: true }, { children: "Continue" })) }));
const SsoSignInForm = ({ source, from, teamName, }) => {
    const [showServerErrors, setShowServerErrors] = useState(false);
    const { showToast } = useToast();
    const { getUrl, response, isLoading } = useGetSsoStartUrl({
        source,
        from,
        onNetworkError: error => showToast(error, 'negative'),
        onCompleted: () => {
            setShowServerErrors(true);
        },
    });
    if (response.url) {
        window.location.href = response.url;
    }
    if (teamName && (response.url || isLoading)) {
        return jsx(LoadingState, {});
    }
    return (jsx(Formik, Object.assign({ initialValues: Object.assign(Object.assign({}, INITIAL_VALUES), { teamName: teamName || '' }), onSubmit: getUrl, validationSchema: signInSchema, validateOnBlur: false }, { children: formikProps => (jsxs(Form, { children: [jsx(TeamNameField, Object.assign({}, formikProps, { handleChange: (e) => {
                        if (showServerErrors) {
                            setShowServerErrors(false);
                        }
                        return formikProps.handleChange(e);
                    } })), jsx(Errors, { errors: showServerErrors ? response.errors : [] }), jsx(Submit, { isLoading: isLoading, 
                    // If we have a teamName we don't need to wait for the form to be dirty
                    disabled: !(formikProps.isValid && (teamName || formikProps.dirty)) })] })) })));
};

export { Errors, SsoSignInForm as default };

import { useState, useEffect } from 'react';
import { useEffectOnce } from 'react-use';

/**
 * Special version of useEffect that runs the provided effect only if the
 * current tab has the provided visibility.
 */
const useEffectOnTabVisibility = (visibility, effect, deps) => {
    const [tabVisibility, setTabVisibility] = useState(document.visibilityState);
    useEffectOnce(() => {
        const onVisibilityChange = () => {
            setTabVisibility(document.visibilityState);
        };
        document.addEventListener('visibilitychange', onVisibilityChange);
        return () => document.removeEventListener('visibilitychange', onVisibilityChange);
    });
    useEffect(() => {
        if (tabVisibility !== visibility)
            return;
        effect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effect, tabVisibility, ...deps]);
};

export { useEffectOnTabVisibility };

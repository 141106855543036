import { useRef, useEffect } from 'react';

const createProxyHandler = (values) => {
    const getObjectValue = (key) => {
        const objectsArray = values.current;
        const typedKey = key;
        let returnValue = null;
        for (const object of objectsArray) {
            if (!object) {
                /**
                 * This array item can be null or false
                 * because of conditional items, so let's move to the next one
                 */
                continue;
            }
            const value = object[typedKey];
            if (!value) {
                /**
                 * There's no value in the pair [object key]
                 * but there can be in another object on the array
                 */
                continue;
            }
            /**
             * If the value is a function we can group with
             * other functions on other array items calling them
             * when the proxy element is called
             */
            if (typeof value === 'function') {
                /**
                 * We return because there's no point on doing the same for the other
                 * items
                 */
                return (...args) => {
                    var _a;
                    (_a = values.current) === null || _a === void 0 ? void 0 : _a.forEach(object => {
                        if (object &&
                            object[typedKey] &&
                            typeof object[typedKey] === 'function') {
                            object[typedKey](...args);
                        }
                    });
                };
            }
            /**
             * Save the value in case any
             * of the array items doesn't have them defined
             * if it has it will replace it
             */
            returnValue = value;
        }
        return returnValue;
    };
    return {
        ownKeys() {
            var _a;
            const objectKeys = (_a = values.current) === null || _a === void 0 ? void 0 : _a.map(object => {
                if (!object) {
                    return [];
                }
                return Object.keys(object);
            });
            return (objectKeys === null || objectKeys === void 0 ? void 0 : objectKeys.flat()) || [];
        },
        getOwnPropertyDescriptor(target, key) {
            const value = getObjectValue(key);
            return { configurable: true, enumerable: true, value };
        },
        get(target, key) {
            return getObjectValue(key);
        },
    };
};
/**
 * useObjectProxyAccumulator
 *
 * This hook allows objects to be passed as arguments and be combined
 * into a proxy object that will read from the passed objects given the accessed properties
 *
 * This allows a continuos ref to be passed and not a dynamic value
 */
const useObjectProxyAccumulator = (...values) => {
    const cacheValues = useRef(values);
    useEffect(() => {
        cacheValues.current = values;
    }, [values]);
    return useRef(new Proxy({}, createProxyHandler(cacheValues)));
};

export { useObjectProxyAccumulator };

import { useAnalytics, ComponentType } from '@sketch/modules-common/analytics'
import { useEffect, useRef } from 'react'

export const useTrackTimeInComponentPage = (type: ComponentType) => {
  const mountTime = useRef<number>(Date.now())
  const { trackMetric } = useAnalytics()

  useEffect(() => {
    const initialTime = mountTime.current

    // Avoid tracking time if Cypress is runnning
    if ((window as any).Cypress) {
      return
    }

    return () => {
      const finishedTime = Math.floor((Date.now() - initialTime) / 1000)

      trackMetric({
        id: 'webapp_cwv_time_spent_duration_seconds',
        value: finishedTime,
        labels: { type },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

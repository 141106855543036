import React, { ReactNode } from 'react'
import { useRouteMatch } from 'react-router'

import { useFlag, isProjectTrashRoute } from '@sketch/modules-common'

import {
  Dropdown,
  useForTablet,
  Tooltip,
  useModalContext,
  useResponsiveDropdown,
} from '@sketch/components'

import {
  CopyProjectAction,
  DeleteProjectAction,
  DeleteProjectPermanentlyAction,
  LeaveProjectAction,
  RenameProjectAction,
  RestoreProjectAction,
  PinProjectAction,
} from '../ProjectDropdownActions'

import ProjectMembershipsTooltip from '../ProjectMembershipsTooltip'

import SharingButton from './SharingButton'

import {
  HeaderButton,
  Dots,
  ButtonWrapper,
  CollectionIcon,
  CreateCollectionButton,
} from './ProjectHeader.styles'

import { useGetProjectMembershipsQuery } from '@sketch/gql-types'
import { ProjectHeaderActionsProps } from './types'
import { CreateCollectionModal } from 'modules/collections/modals'

import ArchiveProjectModal from '../../modals/ArchiveProjectModal'
import UnarchiveProjectModal from '../../modals/UnarchiveProjectModal'

const ActionDropdownWrapper = ({ children }: { children: ReactNode }) => (
  <div onClick={event => event.stopPropagation()}>
    <Dropdown.Header>Project</Dropdown.Header>
    {children}
  </div>
)

export const ProjectHeaderActions: React.FC<ProjectHeaderActionsProps> = ({
  project,
  workspaceAccessLevel,
  workspace,
  userCanRenameAndDeleteProject,
  userCanManageCollection,
  onRenameProject,
}) => {
  const { identifier: workspaceId, features } = workspace

  // Remove this FF when released
  // https://github.com/sketch-hq/Cloud/issues/18702
  const hasGuestsInProjects = useFlag('guests-in-project')

  const { path } = useRouteMatch()
  const isTrashedProjectView = isProjectTrashRoute(path)

  const { showModal } = useModalContext()

  const { error, loading, data } = useGetProjectMembershipsQuery({
    variables: { projectIdentifier: project.identifier },
  })

  const handleCreateCollection = () => {
    showModal(CreateCollectionModal, {
      project,
      workspaceIdentifier: workspaceId,
    })
  }

  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  let dropdownAction = null

  // The `Leave...` button is being added as part of the guests-in-projects feature,
  // only users with the feature flag enabled AND who can leave the project
  // should see the button.
  const showLeaveAction = hasGuestsInProjects && project.userCanLeave

  // Only show the divider if there will be buttons beneath it
  const showDivider = userCanRenameAndDeleteProject || showLeaveAction
  const showArchive =
    (workspace.userCanAdminister || workspace.userCanEdit) &&
    features.archiveEnabled

  const pinned = project.pinnedByCurrentUserAt
  const isArchived = !!project.archivedAt

  if (project.deletedAt) {
    dropdownAction = (
      <>
        <CopyProjectAction project={project} workspaceId={workspaceId} />
        <RestoreProjectAction project={project} workspaceId={workspaceId} />
        <Dropdown.Divider />
        {showLeaveAction && (
          <LeaveProjectAction
            projectIdentifier={project.identifier}
            projectName={project.name}
          />
        )}
        <DeleteProjectPermanentlyAction
          project={project}
          workspaceId={workspaceId}
        />
      </>
    )
  } else {
    dropdownAction = (
      <>
        <CopyProjectAction project={project} workspaceId={workspaceId} />
        {userCanRenameAndDeleteProject && (
          <RenameProjectAction onRename={() => onRenameProject(true)} />
        )}
        {!isArchived && (
          <PinProjectAction
            projectIdentifier={project.identifier}
            pinned={pinned}
            workspaceId={workspaceId}
          />
        )}
        {showArchive &&
          (project.archivedAt ? (
            <Dropdown.Item
              onClick={() => {
                showModal(UnarchiveProjectModal, {
                  projectIdentifier: project.identifier,
                  projectName: project.name,
                  workspaceIdentifier: workspaceId,
                })
              }}
            >
              Unarchive&hellip;
            </Dropdown.Item>
          ) : (
            <Dropdown.Item
              onClick={() => {
                showModal(ArchiveProjectModal, {
                  project,
                  workspaceIdentifier: workspaceId,
                })
              }}
            >
              Archive&hellip;
            </Dropdown.Item>
          ))}
        {showDivider && <Dropdown.Divider />}
        {showLeaveAction && (
          <LeaveProjectAction
            projectIdentifier={project.identifier}
            projectName={project.name}
          />
        )}
        {userCanRenameAndDeleteProject && (
          <DeleteProjectAction project={project} workspaceId={workspaceId} />
        )}
      </>
    )
  }

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: ActionDropdownWrapper,
    dropdownProps: { children: dropdownAction },
    usePortal: true,
    placement: 'bottom-start',
  })

  const createCollectionButton = (
    <Tooltip placement="top" content="Easily organize project documents">
      <CreateCollectionButton onClick={handleCreateCollection}>
        <CollectionIcon />
        {!isMobile && <>Create Collection&hellip;</>}
      </CreateCollectionButton>
    </Tooltip>
  )

  if (project.type === 'PERSONAL_DRAFTS') {
    if (!userCanManageCollection) {
      return null
    }

    return <ButtonWrapper>{createCollectionButton}</ButtonWrapper>
  }

  return (
    <ButtonWrapper>
      {!project.archivedAt && (
        <>
          <ProjectMembershipsTooltip
            workspace={workspace}
            memberships={data?.project.members}
            shouldShowWorkspace={workspaceAccessLevel !== 'NONE'}
            workspaceAccessLevel={workspaceAccessLevel}
            disabled={loading || !!error}
          >
            <SharingButton
              project={project}
              workspace={workspace}
              userCanRenameAndDeleteProject={userCanRenameAndDeleteProject}
              memberships={data?.project.members}
            />
          </ProjectMembershipsTooltip>

          {userCanManageCollection &&
            !isTrashedProjectView &&
            createCollectionButton}
        </>
      )}
      <>
        <HeaderButton data-testid="project-actions" {...buttonProps}>
          <span className="sr-only">Other Actions</span>
          <Dots />
        </HeaderButton>
        {content}
      </>
    </ButtonWrapper>
  )
}

import React, { useCallback } from 'react'

import { Dropdown } from '@sketch/components'
import {
  useSortingSettings,
  SortBy,
  SortOrder,
} from 'modules/shares/hooks/useSortSettings'

import {
  ArrowContainerTooltip,
  ArrowDownIcon,
  ArrowUpIcon,
  StyledDropdownItem,
} from './SortDropdown.styles'

interface DropdownItemProps {
  label: string
  itemSortBy: SortBy
}

const MAP_SORT_ORDER_TO_LABEL: Record<SortBy, Record<SortOrder, string>> = {
  'created-at': {
    asc: 'Show Older First',
    desc: 'Show Newer First',
  },
  'last-modified': {
    asc: 'Show Older First',
    desc: 'Show Newer First',
  },
  name: {
    asc: 'Order by A-Z',
    desc: 'Order by Z-A',
  },
}

const DropdownItem: React.FC<DropdownItemProps> = ({ label, itemSortBy }) => {
  const { sortBy, sortOrder, setSortBy, setSortOrder } = useSortingSettings()
  const selected = sortBy === itemSortBy
  const handleClick = useCallback(() => {
    if (selected) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(itemSortBy)
    }
  }, [selected, setSortOrder, sortOrder, itemSortBy, setSortBy])

  return (
    <StyledDropdownItem
      className={selected ? 'active' : ''}
      onClick={handleClick}
    >
      <div>{label}</div>
      {selected && (
        <ArrowContainerTooltip
          content={MAP_SORT_ORDER_TO_LABEL[sortBy][sortOrder]}
          placement="top"
        >
          {sortOrder === 'asc' ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </ArrowContainerTooltip>
      )}
    </StyledDropdownItem>
  )
}

export const SortDropdown = () => {
  return (
    <>
      <Dropdown.Header>Sort By</Dropdown.Header>
      <DropdownItem label="Name" itemSortBy="name" />
      <DropdownItem label="Date created" itemSortBy="created-at" />
      <DropdownItem label="Last updated" itemSortBy="last-modified" />
    </>
  )
}

import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "bell-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fillRule: "evenodd", fill: "currentColor" },
        React.createElement("path", { d: "M14 18a2 2 0 11-4 0zM12 4a1.5 1.5 0 011.5 1.5V7h-3V5.5A1.5 1.5 0 0112 4z" }),
        React.createElement("path", { d: "M12 6a5 5 0 015 5v2.224c0 .637.4 1.205 1 1.419l.336.12a1 1 0 01.664.942V16a1 1 0 01-1 1H6a1 1 0 01-1-1v-.295a1 1 0 01.664-.942l.336-.12c.6-.214 1-.782 1-1.419V11a5 5 0 015-5z", fillOpacity: 0.15 }),
        React.createElement("path", { d: "M12 6a5 5 0 015 5v2.224c0 .637.4 1.205 1 1.419l.336.12a1 1 0 01.664.942V16a1 1 0 01-1 1H6a1 1 0 01-1-1v-.295a1 1 0 01.664-.942l.336-.12c.6-.214 1-.782 1-1.419V11a5 5 0 015-5zm0 1.5A3.5 3.5 0 008.5 11v2.224c0 .89-.391 1.712-1.037 2.272l9.079.004a3.01 3.01 0 01-1.036-2.086l-.006-.19V11A3.5 3.5 0 0012 7.5z", fillRule: "nonzero" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };

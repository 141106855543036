import React from 'react'

import { PanelSeparator } from 'modules/workspace/components'
import { UpgradePersonalWorkspacePanel } from 'modules/workspace/components/UpgradePersonalWorkspacePanel'

interface PersonalWorkspaceSettingsPeopleProps {
  name: string
}

export const PersonalWorkspaceSettingsPeople = ({
  name,
}: PersonalWorkspaceSettingsPeopleProps) => {
  return (
    <PanelSeparator>
      <UpgradePersonalWorkspacePanel name={name} />
    </PanelSeparator>
  )
}

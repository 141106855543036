import React, { useState } from 'react'
import {
  Button,
  ModalInjectedProps,
  Tabs,
  Tab,
  TabPanel,
} from '@sketch/components'
import {
  WorkspaceMinimalFragment,
  ProjectInSidebarAndHeaderFragment,
} from '@sketch/gql-types'
import { ProjectSharingHeader } from './ProjectSharingHeader'
import { WorkspacePanel } from './Workspace/WorkspacePanel'
import { GuestsPanel } from './Guests/GuestsPanel'
import { LeaveProjectButton } from './LeaveProjectButton'
import {
  StyledModal as Modal,
  StyledTabList as TabList,
  TabPanelWrapper,
} from 'modules/shares/modals/SharingModal/SharingModal.styles'
import { GuestsTitle } from 'modules/shares/modals/SharingModal/GuestsPanel'
import useProjectMemberships from './useProjectMemberships'

interface ProjectSharingModalProps extends ModalInjectedProps {
  project: ProjectInSidebarAndHeaderFragment
  workspace?: WorkspaceMinimalFragment
}
type WorkspaceAccessLevel = ProjectInSidebarAndHeaderFragment['workspaceAccessLevel']

export const ProjectSharingModal: React.FC<ProjectSharingModalProps> = ({
  project,
  workspace,
  hideModal,
}) => {
  const [
    workspaceAccessLevel,
    setWorkspaceAccessLevel,
  ] = useState<WorkspaceAccessLevel>(project.workspaceAccessLevel)

  const hasAccess = workspaceAccessLevel === 'DEFAULT'

  const { guestsCount } = useProjectMemberships(project.identifier)

  if (!workspace) {
    return null
  }

  return (
    <Modal onCancel={hideModal}>
      <ProjectSharingHeader
        project={project}
        workspaceId={workspace.identifier}
        workspaceName={workspace.name}
      />
      <Modal.Body>
        <Tabs>
          <TabList shouldDrawSeparator>
            <Tab>Workspace Members {!hasAccess && `(Restricted)`}</Tab>
            <Tab>
              <GuestsTitle guestsCount={guestsCount} />
            </Tab>
          </TabList>
          <TabPanel>
            <TabPanelWrapper>
              <WorkspacePanel
                workspace={workspace}
                project={project}
                setWorkspaceAccessLevel={setWorkspaceAccessLevel}
                hasAccess={hasAccess}
              />
            </TabPanelWrapper>
          </TabPanel>
          <TabPanel>
            <TabPanelWrapper>
              <GuestsPanel workspace={workspace} project={project} />
            </TabPanelWrapper>
          </TabPanel>
        </Tabs>
      </Modal.Body>
      <Modal.Footer
        leftContent={
          project.userCanLeave ? (
            <LeaveProjectButton project={project} />
          ) : undefined
        }
      >
        <Button variant="primary" onClick={hideModal}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import styled, { css } from 'styled-components'

import { Text, ImageBaseComponent } from '@sketch/components'
import { Image as ImageUnstyled } from '@sketch/modules-common'

export const ArtboardImageWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    position: relative;

    width: 100%;
    aspect-ratio: 1;

    /* Fallback for browsers not supporting aspect-ratio, mainly Safari 14 or below */
    @supports not (aspect-ratio: auto) {
      padding-top: 100%;
      height: 0;

      & > div {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        width: 100%;
        height: auto;
      }
    }

    margin-bottom: 6px; /* stylelint-disable-line scales/space */

    border-radius: 6px;
    overflow: hidden;

    background-color: ${({ theme }) => theme.colors.background.tertiary.D};

    /* Create the border inside */
    ::after {
      border-radius: 6px;

      content: '';
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.border.A};
      position: absolute;

      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    transition: background-color ${theme.transitions.duration[0]}
      ${theme.transitions.timing.easeInOut};
  `
)

export const ArtboardTitle = styled(Text.H1).attrs({
  textStyle: 'copy.primary.standard.E',
})`
  margin: -4px;
  font-weight: 500;
  line-height: 1.429; /* stylelint-disable-line scales/line-height */

  text-overflow: ellipsis;
  overflow: hidden;

  /* Making sure that the title width fills the entire parent, otherwise if the title contains emojis,
  the browser might calculate the width incorrectly, and cut the right or left side of the text
  @see https://github.com/sketch-hq/Cloud/issues/10087 */
  flex: 1;

  /* Giving a bit of padding to the title, otherwise Safari might cut off the top and bottom sides
  The browser calculates the text boundaries incorrectly when it contains emojis */
  padding: 4px;
`

export const ArtboardWrapper = styled.section`
  :hover {
    ${ArtboardImageWrapper} {
      background-color: ${({ theme }) => theme.colors.background.tertiary.C};
    }
  }
`

export const Image = styled(ImageUnstyled)`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`

export const ImageComponent = styled(ImageBaseComponent)`
  max-width: 100%;
  max-height: 100%;

  width: auto;
  height: auto;
`

export const CommentCopy = styled.span<{ $hasNewComments?: boolean }>`
  margin-top: 2px; /* stylelint-disable-line scales/space */
  display: block;

  font-size: 0.75rem; /* 12px */
  font-weight: 400;

  line-height: 1.333; /* stylelint-disable-line scales/line-height */

  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  ${({ $hasNewComments }) =>
    $hasNewComments &&
    css`
      color: ${({ theme }) => theme.colors.sketch.A};
    `}
`

import React from 'react'
import {
  AccessLevelSelect,
  AccessLevelSelectOption,
  AccessLevelSelectCheckbox,
  AccessLevelSelectRemoveButton,
  AccessLevelSelectSeparator,
  AccessLevelSelectProps,
  PrivateOrPendingAccessLevel,
} from '@sketch/modules-common'

// Usage of PublicAccessLevel and PrivateAccessLevel will not break the build
// eslint-disable-next-line no-restricted-imports
import { PrivateAccessLevel } from '@sketch/gql-types/expansive'
import { Tooltip } from '@sketch/components'

export interface PrivatePermissionsAccessSelectProps
  extends AccessLevelSelectProps<
    PrivateOrPendingAccessLevel,
    PrivateAccessLevel
  > {
  level: PrivateAccessLevel | 'PENDING'
  deleteMember?: () => void
  removeButtonText?: string
  showDisabledText?: boolean
}

export const PrivatePermissionsAccessSelect = ({
  level,
  setLevel,
  setCommentsEnabled,
  commentsEnabled,
  setInspectEnabled,
  inspectEnabled,
  disabled,
  showDisabledText,
  deleteMember,
  removeButtonText,
  hideCommentLabel,
}: PrivatePermissionsAccessSelectProps) => (
  <AccessLevelSelect
    level={level}
    setLevel={setLevel}
    commentsEnabled={commentsEnabled}
    hideCommentLabel={hideCommentLabel}
  >
    <AccessLevelSelectOption
      data-testid="access-level-dropdown-view"
      level="VIEW"
      label="View"
      help="View document in the web app"
    />
    <AccessLevelSelectOption
      data-testid="access-level-dropdown-edit"
      level="EDIT"
      label="Edit"
      help="Edit and view the document"
    />
    <AccessLevelSelectSeparator />
    <Tooltip
      placement="bottom-end"
      content="Workspace Members can always download"
      disabled={!disabled || !showDisabledText}
    >
      <AccessLevelSelectCheckbox
        data-testid="inspect-enabled"
        label="Can download and inspect"
        onChange={setInspectEnabled}
        checked={inspectEnabled}
        disabled={disabled}
      />
    </Tooltip>
    <Tooltip
      placement="bottom-end"
      content="Workspace Members can always comment"
      disabled={!disabled || !showDisabledText}
    >
      <AccessLevelSelectCheckbox
        data-testid="comments-enabled"
        label="Can comment"
        onChange={setCommentsEnabled}
        checked={commentsEnabled}
        disabled={disabled}
      />
    </Tooltip>
    {deleteMember && removeButtonText && (
      <>
        <AccessLevelSelectSeparator />
        <AccessLevelSelectRemoveButton onClick={() => deleteMember()}>
          {removeButtonText}
        </AccessLevelSelectRemoveButton>
      </>
    )}
  </AccessLevelSelect>
)

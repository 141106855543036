import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useState, useEffect, useRef, useMemo } from 'react';
import { Portal } from 'react-portal';
import { useHistory } from 'react-router-dom';
import { ModalContext } from './ModalContext.js';
import { ModalTransition } from './ModalTransition.js';

const ModalPortal = ({ children }) => {
    const [documentElement, setDocumentElement] = useState(null);
    useEffect(() => {
        // Save the modal-root node when mounting (if exists) so the portal open in a specific location
        setDocumentElement(document && document.getElementById('modal-root'));
    }, []);
    return jsx(Portal, Object.assign({ node: documentElement }, { children: children }));
};
const ModalProvider = props => {
    const { children, onShowModal, onHideModal } = props;
    const history = useHistory();
    const [modal, setModal] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeOnRouteChange = useRef(true);
    const methods = useMemo(() => {
        const auxShowModal = (modal, props) => {
            setModal([modal, props]);
            setIsModalOpen(true);
        };
        // This is usually the first step, either opens a modal or overrides one
        const showModal = (modal, props, options) => {
            var _a;
            closeOnRouteChange.current = (_a = options === null || options === void 0 ? void 0 : options.closeOnRouteChange) !== null && _a !== void 0 ? _a : true;
            auxShowModal(modal, Object.assign(Object.assign({}, props), { cancelOnClickOutside: true }));
        };
        // This method is the same as showModal one but forces the "cancelOnClickOutside" to be undefined
        const showRestrictedModal = (modal, props) => {
            auxShowModal(modal, props);
        };
        // This modal closes the modal
        const hideModal = () => {
            setIsModalOpen(false);
        };
        return {
            showModal,
            showRestrictedModal,
            hideModal,
            /* This type is overrided to make sure the types pass, but prevent functionality to break */
            onCancel: hideModal,
        };
    }, []);
    useEffect(() => {
        if (isModalOpen) {
            // This method will be called when any modal is shown
            onShowModal === null || onShowModal === void 0 ? void 0 : onShowModal();
            return () => {
                // This method will be called when any modal is hidden
                onHideModal === null || onHideModal === void 0 ? void 0 : onHideModal();
            };
        }
    }, [isModalOpen, onShowModal, onHideModal]);
    useEffect(() => {
        // We need to check if we use it without a router. This is mostly for testing
        if (!history)
            return;
        const unlistenRouteChanges = history.listen(() => {
            if (!closeOnRouteChange.current) {
                return;
            }
            methods.hideModal();
        });
        return unlistenRouteChanges;
    }, [history, methods, closeOnRouteChange]);
    const [Modal, modalProps] = modal || [];
    const state = useMemo(() => (Object.assign(Object.assign({}, methods), { isModalOpen, modal: Modal })), [methods, isModalOpen, Modal]);
    return (jsxs(ModalContext.Provider, Object.assign({ value: state }, { children: [children, jsx(ModalPortal, { children: jsx(ModalTransition, Object.assign({ show: isModalOpen, onExited: () => {
                        // We wait to have the animation finish to remove the components from state
                        setModal(undefined);
                    } }, { children: jsx(Fragment, { children: Modal && jsx(Modal, Object.assign({}, state, modalProps)) }) })) })] })));
};

export { ModalProvider };

import React from 'react'

import { routes } from '@sketch/modules-common'

import { Button, LinkButton } from '@sketch/components'

import BillingInfoTable from '../BillingInfoTable'
import { EditButton } from './EditButton'

import { formatPriceShort, dateFormat } from '@sketch/utils'
import { PLAN_TYPE_LABEL } from 'modules/workspace/utils'

// GQL
import {
  BillingPlanFragment,
  BillingSeatsInfoFragment,
  PaymentDetailsFragment,
} from '@sketch/gql-types'

// Usage of BillingStatus enum is needed to make the expected strings match the BE ones
// eslint-disable-next-line no-restricted-imports
import { BillingStatus } from '@sketch/gql-types/expansive'

interface CurrentPlanProps {
  workspaceId: string
  customerId: string
  isWorkspaceOnTrial: boolean
  subscriptionStatus: BillingStatus
  customerHasPaymentDetails: boolean
  currentPlan?: BillingPlanFragment
  trialEndDate: string
  isSubscriptionEnd: boolean
  isPartner?: boolean
  isNewPricingEnabled: boolean
  seats: BillingSeatsInfoFragment
  renewalDate: string
  paymentDetails?: PaymentDetailsFragment
  onWorkspaceSettingRefresh: () => Promise<void>
  onReactivateSubscription: () => void
}

const Header: React.FC = () => (
  <BillingInfoTable.HeadingCell>Current Plan</BillingInfoTable.HeadingCell>
)

export const CurrentPlan = ({
  workspaceId,
  customerId,
  isWorkspaceOnTrial,
  customerHasPaymentDetails,
  currentPlan,
  trialEndDate,
  isSubscriptionEnd,
  isPartner,
  seats,
  renewalDate,
  paymentDetails,
  onWorkspaceSettingRefresh,
  onReactivateSubscription,
  subscriptionStatus,
}: CurrentPlanProps) => {
  const canResubscribe =
    isPartner || (!isPartner && paymentDetails?.type !== 'INVOICE')

  if (isWorkspaceOnTrial && currentPlan && customerHasPaymentDetails) {
    return (
      <BillingInfoTable.Row>
        <Header />
        <BillingInfoTable.Cell>
          <div>
            <strong>Trial</strong>
            <br />
            Ends on {dateFormat(new Date(trialEndDate))}
          </div>
        </BillingInfoTable.Cell>
        {isSubscriptionEnd && canResubscribe && (
          <BillingInfoTable.Cell>
            <Button
              variant="primary"
              size="32"
              onClick={onReactivateSubscription}
            >
              Subscribe
            </Button>
          </BillingInfoTable.Cell>
        )}
        {!isSubscriptionEnd && !isPartner && (
          <EditButton
            customerId={customerId}
            currentPlan={currentPlan}
            seats={seats}
            paymentDetails={paymentDetails}
            subscriptionStatus={subscriptionStatus}
            onWorkspaceSettingRefresh={onWorkspaceSettingRefresh}
          />
        )}
      </BillingInfoTable.Row>
    )
  }

  if (isWorkspaceOnTrial) {
    /**
     * The workspace is only on trial. After that, the workspace will be
     * de-activated
     */
    return (
      <BillingInfoTable.Row>
        <Header />
        <BillingInfoTable.Cell>
          <div>
            <strong>Trial</strong>
            <br />
            Ends on {dateFormat(new Date(trialEndDate))}
          </div>
        </BillingInfoTable.Cell>
        <BillingInfoTable.Cell>
          <LinkButton
            data-testid="setup-subscription-button"
            variant="primary"
            size="32"
            to={routes.WORKSPACE_SUBSCRIBE.create({
              workspaceId,
            })}
          >
            Set Up a Subscription
          </LinkButton>
        </BillingInfoTable.Cell>
      </BillingInfoTable.Row>
    )
  }
  /**
   * The workspace is definitely not on trial
   */
  const isEducationPlan = currentPlan?.product === 'STUDENT'

  return (
    <BillingInfoTable.Row>
      <Header />

      <BillingInfoTable.Cell>
        {!isEducationPlan ? (
          <div>
            <strong data-testid="plan-name">
              {PLAN_TYPE_LABEL[currentPlan!.type]}
            </strong>
            <br />
            <span data-testid="plan-price-per-editor">
              <strong>{formatPriceShort(currentPlan!.invoicePrice)}</strong> per
              Seat, per {currentPlan?.type === 'YEARLY' ? 'year' : 'month'}
            </span>
          </div>
        ) : (
          <div>
            <strong data-testid="plan-name">Education </strong>
            <br />
            <span data-testid="plan-price-per-editor">
              Ends on {dateFormat(new Date(renewalDate))}
            </span>
          </div>
        )}
      </BillingInfoTable.Cell>
      {isSubscriptionEnd && canResubscribe && (
        <BillingInfoTable.Cell>
          <Button
            variant="primary"
            size="32"
            onClick={onReactivateSubscription}
          >
            {isPartner ? 'Reactivate' : 'Subscribe'}
          </Button>
        </BillingInfoTable.Cell>
      )}
      {!isSubscriptionEnd && !isPartner && (
        <EditButton
          customerId={customerId}
          subscriptionStatus={subscriptionStatus}
          currentPlan={currentPlan}
          seats={seats}
          paymentDetails={paymentDetails}
          onWorkspaceSettingRefresh={onWorkspaceSettingRefresh}
        />
      )}
    </BillingInfoTable.Row>
  )
}

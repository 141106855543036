import produce from 'immer'

import * as webhookCache from '../webhookCache'

import {
  useWebhookDestinationDeleteMutation,
  WebhookDestinationDeleteMutation,
  WebhookDestinationDeleteMutationVariables,
} from '@sketch/gql-types'

export interface UseDeleteWebhookDestinationProps {
  onCompleted?: (data: WebhookDestinationDeleteMutation) => void
}

export const useDeleteWebhookDestination = (
  props: UseDeleteWebhookDestinationProps = {}
) => {
  const { onCompleted } = props
  const [mutation] = useWebhookDestinationDeleteMutation({
    onError: 'show-toast',
    onCompleted,
  })

  return async (variables: WebhookDestinationDeleteMutationVariables) => {
    return await mutation({
      variables,
      update: (cache, { data, errors }) => {
        if (!data || (errors && errors.length > 0)) return

        const { workspaceIdentifier } = variables

        const workspace = webhookCache.get(cache, workspaceIdentifier)
        if (!workspace) return

        const updatedWorkspace = produce(workspace, changeset => {
          changeset.webhookDestination = null
        })

        webhookCache.set(cache, workspaceIdentifier, updatedWorkspace)
      },
    })
  }
}

import { useState } from 'react';
import { uniqueId } from 'lodash';

/**
 * useDropdownIdentifiers
 *
 * This is a simple state for storing the trigger and dropdown
 * identifiers
 */
const useDropdownIdentifiers = () => {
    const [triggerId] = useState(uniqueId('trigger'));
    const [dropdownId] = useState(uniqueId('dropdown'));
    return [triggerId, dropdownId];
};

export { useDropdownIdentifiers };

import { useState, useEffect } from 'react';
import { localStorageKeys } from '@sketch/constants';
import { getParsedItem, removeItem, setStringifiedItem } from '@sketch/utils';
import '../../routes/index.js';
import { useQueryParams } from '../../routes/useQueryParams.js';

// The whole logic in this file is *extremely* similar to the one in the website repo.
const PARAM_KEYS = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
];
const NOW_DATE = new Date();
const MAX_DATA_EXPIRY_DAYS = 30;
const SEARCH_ENGINES = [
    'google',
    'baidu',
    'bing',
    'duckduckgo',
    'naver',
    'ecosia',
    'yahoo',
    'yandex',
    'qwant',
    'sogou',
    'so',
    'seznam',
    '360',
    'daum',
    'ask',
    'aol',
    'najdi',
    'rambler',
];
// Get the numeric difference of days between 2 dates
const dayDiff = (fromDate, toDate) => {
    if (!fromDate || !toDate)
        return -1;
    const oneDayMs = 1000 * 60 * 60 * 24;
    return Math.ceil(Math.abs(toDate - fromDate) / oneDayMs);
};
// Check if stored data is beyond the max expiry date
const isStoredDataExpired = () => {
    const storedData = getParsedItem(localStorageKeys.signUpMetadata) || {};
    // We consider non-existent data expired so we can get the new data if available
    if (Object.keys(storedData).length === 0 || !storedData.retrieved_on)
        return true;
    const dataDate = new Date(storedData.retrieved_on).getTime();
    const dataDaysOld = dayDiff(dataDate, NOW_DATE.getTime());
    return dataDaysOld >= MAX_DATA_EXPIRY_DAYS;
};
// Check for filled properties in provided data object
const hasData = (data) => {
    if (!data)
        return false;
    const propsWithData = Object.keys(data).filter(prop => data[prop] !== null);
    // The date the data was retrieved on doesn't count as data
    return (propsWithData.length > 1 ||
        (propsWithData.length === 1 && propsWithData[0] !== 'retrieved_on'));
};
const searchEngineRegExp = (searchEngine) => new RegExp(`^(?:https?://)?(?:www.)?${searchEngine}\\..+$`);
const isReferrerSearchEngine = (referrer) => SEARCH_ENGINES.some(searchEngine => searchEngineRegExp(searchEngine).test(referrer));
const isDirectTraffic = (referrer) => referrer === '';
const isReferralTraffic = (referrer) => !isReferrerSearchEngine(referrer) && !isDirectTraffic(referrer);
const transformForBackend = (data) => {
    if (!data)
        return null;
    const backendData = {};
    Object.keys(data).forEach(prop => {
        if (prop !== 'retrieved_on') {
            const newPropName = prop.replace(/^utm_/, '');
            backendData[newPropName] =
                data[prop];
        }
    });
    return backendData;
};
// Remove the metadata form the localstorage
const clearMetadata = () => {
    removeItem(localStorageKeys.signUpMetadata);
};
/**
 * Returns signup data from UTM params
 */
const useSignUpMetadata = () => {
    const [storage, setStorage] = useState();
    const queryParams = useQueryParams();
    useEffect(() => {
        const data = {
            retrieved_on: NOW_DATE.toString(),
        };
        PARAM_KEYS.forEach(prop => {
            data[prop] = queryParams[prop] || null;
        });
        // Organic traffic
        // That's traffic coming from search engines (defined at the top). It's only relevant when
        // no utm_params are present in the URL.
        if (!hasData(data) && isReferrerSearchEngine(document.referrer)) {
            data.utm_medium = 'organic';
            data.utm_source = document.referrer;
        }
        // Direct traffic
        // That's traffic coming directly to the website. It's only relevant when
        // no utm_params are present in the URL.
        if (!hasData(data) && isDirectTraffic(document.referrer)) {
            data.utm_medium = 'direct';
        }
        // Referral traffic
        // That's traffic that's neither direct nor organic. Meaning, these users come from a website
        // that's not a search engine.
        if (!hasData(data) && isReferralTraffic(document.referrer)) {
            data.utm_medium = 'referral';
            data.utm_source = document.referrer;
        }
        if (isStoredDataExpired() ||
            !hasData(getParsedItem(localStorageKeys.signUpMetadata))) {
            setStringifiedItem(localStorageKeys.signUpMetadata, data);
        }
        setStorage(transformForBackend(getParsedItem(localStorageKeys.signUpMetadata)));
    }, [queryParams]);
    return { metadata: storage, clearMetadata };
};

export { useSignUpMetadata };

import isEmpty from 'lodash.isempty';

const uploadingStatus = ['CREATED'];
const processingStatus = ['UPLOADED', 'RENDERING', 'PROCESSING'];
const readyStatus = ['FINISHED'];
const failedStatus = ['ERRORED'];
const getRenderStatus = (renderStatus) => {
    if (renderStatus === null)
        return 'loading';
    switch (true) {
        case isUploading(renderStatus):
            return 'uploading';
        case isProcessing(renderStatus):
            return 'processing';
        case hasFailed(renderStatus):
            return 'failed';
        default:
            return 'ready';
    }
};
const isUploading = (renderStatus) => uploadingStatus.includes(renderStatus);
const isProcessing = (renderStatus) => processingStatus.includes(renderStatus);
const isReady = (renderStatus) => readyStatus.includes(renderStatus);
const hasFailed = (renderStatus) => failedStatus.includes(renderStatus);
const failedThreshold = (lastUpdate) => {
    const hoursDiff = Math.abs(new Date(lastUpdate).getTime() - new Date().getTime()) / 36e5;
    if (hoursDiff > 2) {
        return true;
    }
    return false;
};
const preRenderfarmStatus = (hasFiles, lastUpdate) => {
    if (!hasFiles) {
        if (failedThreshold(lastUpdate)) {
            return 'ERRORED';
        }
        return 'PROCESSING';
    }
    return 'FINISHED';
};
/**
 * Extract the render status from the document version (fetched in getVersion query)
 */
const getVersionRenderStatus = (version) => {
    var _a, _b, _c;
    const previewFiles = ((_b = (_a = version === null || version === void 0 ? void 0 : version.document) === null || _a === void 0 ? void 0 : _a.previewFile) === null || _b === void 0 ? void 0 : _b.thumbnails) || [];
    if (!version || isEmpty(version)) {
        return getRenderStatus(null);
    }
    const renderStatusType = ((_c = version.document) === null || _c === void 0 ? void 0 : _c.renderStatus) ||
        preRenderfarmStatus(!!previewFiles.length, version.updatedAt || '');
    return getRenderStatus(renderStatusType);
};

export { getRenderStatus, getVersionRenderStatus, hasFailed, isProcessing, isReady, isUploading, preRenderfarmStatus };

import React, { useEffect, useRef, useState } from 'react'
import { NavLinkProps } from 'react-router-dom'
import ResizeObserver from 'resize-observer-polyfill'

import {
  NavigationLink,
  UnlinkedNavigationItem,
  ItemLabel,
  ProjectIcon,
  CollectionIcon,
  DocumentIcon,
  PageIcon,
  ArtboardIcon,
  PrototypeIcon,
  PrototypeStartPointIcon,
  ComponentsIcon,
  TemplateIcon,
  ItemTooltip,
  DSIcon,
} from './NavigationItem.styles'

export const icons = {
  project: ProjectIcon,
  collection: CollectionIcon,
  document: DocumentIcon,
  template: TemplateIcon,
  page: PageIcon,
  artboard: ArtboardIcon,
  components: ComponentsIcon,
  prototype: PrototypeIcon,
  prototypeStartPoint: PrototypeStartPointIcon,
  layer: PrototypeIcon,
  designSystem: DSIcon,
}

interface NavigationItemProps {
  icon?: keyof typeof icons
  label: React.ReactNode
  to?: NavLinkProps['to']
  collapsed?: boolean
  secondary?: boolean
}

const NavigationItem: React.FC<NavigationItemProps> = props => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const labelRef = useRef<HTMLSpanElement>(null)

  const { secondary } = props

  useEffect(() => {
    setShowTooltip(!!props.collapsed)

    if (!labelRef.current) {
      return
    }

    const checkOverflow = () => {
      const ref = labelRef.current
      const hasOverflow = (ref?.offsetWidth ?? 0) < (ref?.scrollWidth ?? 0)
      setShowTooltip(!!props.collapsed || hasOverflow)
    }

    const observer = new ResizeObserver(checkOverflow)
    observer.observe(labelRef.current)

    return () => observer.disconnect()
  }, [props.collapsed])

  const Icon = props.icon ? icons[props.icon] : null

  const content = (
    <>
      {Icon && <Icon />}
      {!props.collapsed && (
        <ItemLabel noIcon={Icon === null} ref={labelRef}>
          {props.label}
        </ItemLabel>
      )}
      {showTooltip && (
        <ItemTooltip content={props.label} placement="bottom">
          <></>
        </ItemTooltip>
      )}
    </>
  )

  return props.to ? (
    <NavigationLink
      to={props.to}
      data-testid="navigation-item"
      $secondary={secondary}
    >
      {content}
    </NavigationLink>
  ) : (
    <UnlinkedNavigationItem
      data-testid="navigation-item"
      $secondary={secondary}
    >
      {content}
    </UnlinkedNavigationItem>
  )
}

export default NavigationItem

import React, { FC } from 'react'
import { RouteParams } from '@sketch/modules-common'

import {
  useVersioning,
  isUpgradeToLatestNeeded,
  UpgradeToLatestVersion,
} from 'modules/versioning'
import { useGetSurroundingArtboardsQuery } from '@sketch/gql-types'
import { PaginationControls } from '@sketch/components'
import { useHistory, useLocation, useRouteMatch } from 'react-router'

interface LocationState {
  fromOverview?: boolean
}

interface ArtboardPaginationControlsProps {
  current: number
  total: number
  showNumbers?: boolean
}

/**
 * Navigate between artboards using keyboard left/right arrows
 */
export const ArtboardDetailPaginationControls: FC<ArtboardPaginationControlsProps> = ({
  current,
  total,
  ...rest
}) => {
  const match = useRouteMatch<RouteParams<'ARTBOARD_REVISION'>>()
  const location = useLocation<LocationState>()
  const history = useHistory()

  const {
    versionShortId,
    getPathname,
    setArtboardThumbnail,
    artboardThumbnail,
  } = useVersioning()
  const { shareID, permanentArtboardShortId } = match.params

  const fromOverview = location.state?.fromOverview

  const navigateTo = (pathname: string) =>
    history.push({
      pathname: pathname,
      state: { fromOverview },
    })

  const { data: artboardData, error } = useGetSurroundingArtboardsQuery({
    variables: {
      permanentArtboardShortId: permanentArtboardShortId,
      documentVersionShortId: versionShortId,
      shareIdentifier: shareID,
    },
  })

  if (isUpgradeToLatestNeeded(error)) {
    return <UpgradeToLatestVersion error={error} />
  }

  const nextArtboardShortId =
    artboardData?.artboard?.nextArtboard?.permanentArtboardShortId

  const previousArtboardShortId =
    artboardData?.artboard?.previousArtboard?.permanentArtboardShortId

  const currentPage = current ? current + 1 : 1

  const clearThumbnail = () =>
    artboardThumbnail !== '' && setArtboardThumbnail('')

  const handleClickNext = nextArtboardShortId
    ? () => {
        const nextPathname = getPathname({
          routeKey: 'ARTBOARD_DETAIL',
          routeParams: {
            shareID: shareID,
            permanentArtboardShortId: nextArtboardShortId,
          },
        })

        clearThumbnail()
        navigateTo(nextPathname)
      }
    : undefined

  const handleClickPrevious = previousArtboardShortId
    ? () => {
        const previousPathname = getPathname({
          routeKey: 'ARTBOARD_DETAIL',
          routeParams: {
            shareID: shareID,
            permanentArtboardShortId: previousArtboardShortId,
          },
        })
        clearThumbnail()
        navigateTo(previousPathname)
      }
    : undefined

  return (
    <PaginationControls
      current={currentPage}
      total={total}
      onClickNext={handleClickNext}
      onClickPrevious={handleClickPrevious}
      onlyKeyboardNavigation={true}
      {...rest}
    />
  )
}

import { LoadingPage as LoadingPage$1 } from './LoadingPage.js';
import { PrototypeLoadingPage } from './PrototypeLoadingPage.js';
import { ArtboardDetailsLoadingPage } from './ArtboardDetailsLoadingPage.js';
import { SidebarLoadingPage } from './SidebarLoadingPage.js';
import { SsoLoadingPage } from './SsoLoadingPage.js';
import { WorkspaceSubscribeLoadingPage } from './WorkspaceSubscribeLoadingPage.js';
import { IndexLayoutLoading } from './IndexLayoutLoading.js';
import { WorkspaceSidebarLoadingPage } from './WorkspaceSidebarLoadingPage.js';

// TODO: Split LoadingPage and DynamicLoadingPage https://github.com/sketch-hq/Cloud/issues/16626
const LoadingPage = Object.assign(LoadingPage$1, {
    Prototype: PrototypeLoadingPage,
    Artboard: ArtboardDetailsLoadingPage,
    Sidebar: SidebarLoadingPage,
    Sso: SsoLoadingPage,
    WorkspaceSubscribe: WorkspaceSubscribeLoadingPage,
    IndexLayout: IndexLayoutLoading,
    WorkspaceSidebar: WorkspaceSidebarLoadingPage,
});

export { LoadingPage };

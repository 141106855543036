import { isMac } from '../isMac.js';

const isInputFocused = (event) => {
    const target = event.target;
    if (target instanceof HTMLInputElement ||
        target instanceof HTMLTextAreaElement) {
        return true;
    }
    return false;
};
const isModifierKeyPressed = (event, navigator = window.navigator) => (isMac(navigator) ? event.metaKey : event.ctrlKey);
// To use with useKey hook: useKey(keyWithoutModifier(['=', '+']), console.log)
const keyWithoutModifier = (key, navigator = window.navigator) => {
    return (event) => {
        if (isInputFocused(event))
            return false;
        if (Array.isArray(key)) {
            return key.includes(event.key) && !isModifierKeyPressed(event, navigator);
        }
        else {
            return key === event.key && !isModifierKeyPressed(event, navigator);
        }
    };
};
// To use with useKey hook: useKey(keyWithModifier('/'), console.log)
const keyWithModifier = (key, navigator = window.navigator) => {
    return (event) => {
        if (isInputFocused(event))
            return false;
        if (Array.isArray(key)) {
            return key.includes(event.key) && isModifierKeyPressed(event, navigator);
        }
        else {
            return key === event.key && isModifierKeyPressed(event, navigator);
        }
    };
};
// To use with useKey hook: useKey(keyCodeWithShift('Digit0'), console.log)
const keyCodeWithShift = (code) => {
    return (event) => {
        if (isInputFocused(event))
            return false;
        if (Array.isArray(code)) {
            return code.includes(event.code) && event.shiftKey;
        }
        else {
            return code === event.code && event.shiftKey;
        }
    };
};

export { isInputFocused, keyCodeWithShift, keyWithModifier, keyWithoutModifier };

import { jsx, jsxs } from 'react/jsx-runtime';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useToast } from '@sketch/toasts';
import { useModalContext, Modal, Form, PasswordInput, Button } from '@sketch/components';
import '../Confirm2FADisabledModal/index.js';
import { Title, Description } from './Disable2FAModal.styles.js';
import { useDisableMfaMutation } from '@sketch/gql-types';
import { Confirm2FADisabledModal } from '../Confirm2FADisabledModal/Confirm2FADisabledModal.js';

const INITIAL_VALUES = {
    password: '',
};
const VALIDATION_SCHEMA = yup.object().shape({
    password: yup.string().required('Password can’t be blank'),
});
const Disable2FAModal = ({ hideModal, }) => {
    const { showModal } = useModalContext();
    const { showToast } = useToast();
    const [disableMFA, { loading }] = useDisableMfaMutation({
        onCompleted: () => {
            showModal(Confirm2FADisabledModal);
        },
        onError: ({ message }) => {
            showToast(message, 'negative');
        },
    });
    const handleSubmit = (values) => {
        disableMFA({
            variables: {
                password: values.password,
            },
        });
    };
    return (jsx(Modal, Object.assign({ onCancel: hideModal }, { children: jsx(Formik, Object.assign({ initialValues: INITIAL_VALUES, validationSchema: VALIDATION_SCHEMA, onSubmit: handleSubmit }, { children: ({ values, errors, touched, handleChange, handleBlur, dirty }) => (jsxs(Form, { children: [jsxs(Modal.Body, { children: [jsx(Title, { children: "Disable Two-Factor Authentication (2FA)?" }), jsx(Description, { children: "Enter your password to disable two-factor authentication (2FA)." }), jsx(Form.Field, Object.assign({ name: "password", label: "Password", errorText: touched.password ? errors.password : undefined }, { children: jsx(PasswordInput, { name: "password", value: values.password, placeholder: "Password", onChange: handleChange, onBlur: handleBlur }) }))] }), jsxs(Modal.Footer, { children: [jsx(Button, Object.assign({ onClick: hideModal, disabled: loading, size: "40" }, { children: "Cancel" })), jsx(Button, Object.assign({ type: "submit", variant: "primary", loading: loading, disabled: !dirty || loading, size: "40" }, { children: "Disable 2FA" }))] })] })) })) })));
};

export { Disable2FAModal };

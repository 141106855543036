import { castError } from '@sketch/utils'
import React, { useState } from 'react'
import { isApolloError } from 'apollo-client'

import { getMemberEmail } from '../../utils/members'
import { getPaymentTypeByProratedAmounts } from '../../utils/payments'
import {
  confirmPendingSetupIntent,
  getAppliedDiscount,
  ERROR_MESSAGE,
} from '../../utils'

import {
  Button,
  Modal,
  ModalInjectedProps,
  useStripe,
} from '@sketch/components'
import { useToast } from '@sketch/toasts'
import { ErrorHandler } from '@sketch/tracing'
import Discount from 'modules/workspace/components/Discount'

import {
  SummaryError,
  SummaryHeader,
  SummaryLine,
  SummaryPaymentMethod,
  SummaryTotalDescribed,
  SummaryTotalLine,
  SummaryDiscountLine,
  SummaryProrated,
} from '../../components/Summary'
import { ModalWarning } from '../../components/ModalWarning'
import { MemberCard } from '../../components/MemberCard'

import { DiscountWrapper } from './ViewerUpgradeModal.styles'

import {
  BillingPlanFragment,
  WorkspaceMembershipFragment,
  PaymentDetailsFragment,
  BillingSeatsInfoFragment,
  useGetSeatsUpdateBillingSimulationQuery,
  useUpdateWorkspaceMemberMutation,
  useApplyCouponMutation,
  CouponFragment,
} from '@sketch/gql-types'
import { isBillingHidden } from '@sketch/env-config'

type DiscountError =
  | {
      code: string
      reason: string
    }
  | undefined

interface ViewerUpgradeModalProps extends ModalInjectedProps {
  member: WorkspaceMembershipFragment
  isOnTrial: boolean
  customerId: string
  seats: BillingSeatsInfoFragment
  plan: BillingPlanFragment
  paymentDetails: PaymentDetailsFragment
  showBillingSummary?: boolean
}

/*
 * ViewerUpgradeModal
 *
 * Renders a modal that allows the user to
 * upgrade a viewer to editor
 */
export const ViewerUpgradeModal: React.FC<ViewerUpgradeModalProps> = props => {
  const {
    isOnTrial,
    member,
    hideModal,
    seats,
    plan,
    customerId,
    paymentDetails,
    showBillingSummary = true,
  } = props
  const { showToast } = useToast()
  const { load: loadStripe } = useStripe()
  const [isLoading, setIsLoading] = useState(false)
  const [discountError, setDiscountError] = useState<DiscountError>(undefined)
  const [isDiscountAlreadyApplied, setIsDiscountAlreadyApplied] = useState<
    boolean | undefined
  >(undefined)

  const hasFreeSeats = seats.availableSeats !== 0

  const memberName = member.user?.name
  const memberEmail = getMemberEmail(member)

  const {
    loading: isSummaryLoading,
    error,
    data,
    refetch,
  } = useGetSeatsUpdateBillingSimulationQuery({
    variables: {
      customerId,
      totalSeats: seats.currentSeatsTotal + 1,
    },
    skip: hasFreeSeats || !showBillingSummary,
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (isDiscountAlreadyApplied === undefined) {
        setIsDiscountAlreadyApplied(
          !!data?.seatsUpdateBillingSimulation.couponInfo
        )
      }
    },
  })

  const [updateWorkspaceMember, { loading }] = useUpdateWorkspaceMemberMutation(
    {
      onError: 'show-toast',
    }
  )

  const [
    applyCoupon,
    { loading: isLoadingApplyCoupon },
  ] = useApplyCouponMutation({
    onError: 'unsafe-throw-exception',
  })

  const appliedDiscount = getAppliedDiscount(
    data?.seatsUpdateBillingSimulation?.couponInfo || undefined
  )

  // TEMPORARILY DISABLED DISCOUNTS UI
  // https://github.com/sketch-hq/cloud-frontend/pull/6249
  // const isDiscountFieldVisible =
  //   !hasFreeSeats && hasPaymentDetails(paymentDetails) && false
  const isDiscountFieldVisible = false

  const handleApplyDiscount = async (discountCode: string) => {
    try {
      await applyCoupon({
        variables: {
          customerId,
          promotionCode: discountCode,
        },
      })

      refetch()
      setDiscountError(undefined)
    } catch (e) {
      const error = castError(e)
      if (isApolloError(error)) {
        const code = error?.graphQLErrors[0]?.extensions
          ?.code as CouponFragment['errorCode']

        const isValidCode = !!code && Object.keys(ERROR_MESSAGE).includes(code)

        setDiscountError({
          code: discountCode,
          reason: isValidCode ? ERROR_MESSAGE[code!] : ' is invalid',
        })
      } else {
        setDiscountError({
          code: '',
          reason: error.message,
        })
      }
    }
  }

  const handleUpdateWorkspaceMember = async () => {
    try {
      setIsLoading(true)
      const response = await updateWorkspaceMember({
        variables: {
          input: {
            membershipIdentifier: member.identifier,
            accessLevel: 'EDITOR',
            /**
             * If the user is a guest we need to move it into a member,
             * if it's not a guest then we should keep the same role
             */
            role: member.role === 'GUEST' ? 'MEMBER' : undefined,
          },
        },
      })

      // in some cases if error happens, response can be undefined,
      // even though it shouldn't be following TypeScript definitions
      if (!response) {
        ErrorHandler.shouldNeverHappen(
          'updateWorkspaceMember should always return data'
        )
        return
      }

      const { data } = response

      const { pendingScaToken } = data?.updateWorkspaceMember || {}

      /**
       * If "pendingScaToken" is present on the payload we need to do the
       * challenge with stripe to make sure the card is not INCOMPLETE
       *
       * this can throw an exception
       */
      if (pendingScaToken) {
        const stripe = await loadStripe()

        await confirmPendingSetupIntent(stripe, pendingScaToken)
      }

      if (member.user?.name) {
        showToast(`${memberName} (${memberEmail}) was changed to Editor.`)
      } else {
        showToast(`${memberEmail} was changed to Editor.`)
      }

      hideModal()
    } catch (e) {
      const error = castError(e)
      showToast(error.message, 'negative')
    } finally {
      setIsLoading(false)
    }
  }

  const { prorated, extraSeatsAmount } =
    data?.seatsUpdateBillingSimulation || {}

  const paymentType = getPaymentTypeByProratedAmounts(
    prorated?.chargeAmount,
    prorated?.creditsAmount
  )

  return (
    <Modal title="Change to Editor" onCancel={hideModal}>
      <Modal.Body>
        {!isBillingHidden
          ? 'You only pay for members that need to edit Sketch files. '
          : ''}
        An Editor can use the Sketch Mac app and upload files to this Workspace.
        <br />
        <br />
        <MemberCard.BackgroundWrapper>
          <MemberCard
            name={memberName}
            email={memberEmail!}
            avatarSrc={member?.user?.avatar?.small}
          />
        </MemberCard.BackgroundWrapper>
      </Modal.Body>
      {showBillingSummary && (
        <Modal.Body data-testid="change-to-editor-billing-summary">
          {error ? (
            <SummaryError refetch={() => refetch()} />
          ) : (
            <>
              <SummaryHeader
                plan={plan}
                paymentMethod={
                  // Wait for the BE response to show the proper payment method
                  prorated && (
                    <SummaryPaymentMethod
                      chargeMethod={paymentDetails}
                      paymentType={paymentType}
                    />
                  )
                }
              />
              <SummaryLine
                description={
                  hasFreeSeats ? '1 Editor (pre-paid seat)' : '1 Editor upgrade'
                }
                loading={isSummaryLoading}
                value={extraSeatsAmount || 0}
              />

              {isDiscountFieldVisible && (
                <DiscountWrapper>
                  <Discount
                    onApplyDiscountCode={handleApplyDiscount}
                    error={discountError}
                    isDisabled={isLoadingApplyCoupon}
                    isLoading={isLoadingApplyCoupon}
                  />
                </DiscountWrapper>
              )}
              {appliedDiscount && (
                <SummaryDiscountLine
                  appliedDiscount={appliedDiscount}
                  isAlreadyApplied={isDiscountAlreadyApplied}
                />
              )}
              {prorated && (
                <SummaryProrated
                  daysElapsedInCycle={prorated.daysElapsedInCycle}
                  refundAmountForNewSeats={
                    prorated.proratedRefundAmountForNewSeats
                  }
                />
              )}
              <SummaryTotalLine
                description="Total due"
                loading={isSummaryLoading}
                value={prorated?.amount || 0}
              />
              <SummaryTotalDescribed
                charge={prorated?.chargeAmount}
                credit={prorated?.creditsAmount}
              />
              <ModalWarning
                title="You’re in trial period"
                description="You will not be charged by seats and editors added during your trial period. After the trial ends, you’ll get charged by all editors and seats you have."
                show={isOnTrial}
              />
            </>
          )}
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button disabled={loading} onClick={hideModal}>
          Cancel
        </Button>
        <Button
          onClick={handleUpdateWorkspaceMember}
          loading={loading || isLoading}
          disabled={isSummaryLoading || !!error}
          variant="primary"
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

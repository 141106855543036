import React from 'react'
import { Navbar } from '@sketch/components'
import { PrototypeControls } from '../PrototypeControls'
import * as S from './PrototypeFooter.styles'
import { usePrototypeContext } from '../../hooks'
import { NavbarEnterFullScreenButton } from '../NavbarEnterFullScreenButton'
import { ResizeModeSelector } from '../ResizeModeSelector'
import { AnnotationButton } from '../AnnotationButton'
import { AddAnnotationButton } from '../AddAnnotationButton'
import { useIsAnnotationContextAvailable } from 'modules/annotations/hooks'

export function PrototypeFooter() {
  const { isFullscreen } = usePrototypeContext()

  /**
   * On Annotation context the "disabled" checks if the user
   * has commenting permissions if it has not there's no
   * point on showing this
   */
  const annotationContextAvailable = useIsAnnotationContextAvailable()

  if (isFullscreen) {
    return null
  }

  return (
    <S.FooterNavbar data-testid="prototype-footer">
      <Navbar.Section align="end">
        <S.Item>
          <PrototypeControls />
        </S.Item>
        <S.Item>
          <ResizeModeSelector />
        </S.Item>

        {annotationContextAvailable && (
          <>
            <S.ItemEnd>
              <AddAnnotationButton />
              <AnnotationButton />
            </S.ItemEnd>
            <S.Divider />
          </>
        )}

        <S.Item>
          <NavbarEnterFullScreenButton />
        </S.Item>
      </Navbar.Section>
    </S.FooterNavbar>
  )
}

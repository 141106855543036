import { jsxs, jsx } from 'react/jsx-runtime';
import '../LoadingPlaceholder/index.js';
import { PrototypeLoadingPlaceholder as PrototypeLoadingPlaceholder$1 } from './PrototypeLoadingPlaceholder.styles.js';
import { LoadingPlaceholder } from '../LoadingPlaceholder/LoadingPlaceholder.js';

/**
 * Loading placeholder displayed on top of the dark prototype background.
 * You can pass an optional % progress that will only be shown if defined
 * and more than 0.
 */
function PrototypeLoadingPlaceholder({ progress = 0, text = 'Loading', }) {
    const percentageProgress = Math.floor(progress * 100);
    // Don't show percentage when progress is 0
    const loadingText = progress
        ? `${text} ${percentageProgress}%...`
        : `${text}...`;
    return (jsxs(PrototypeLoadingPlaceholder$1, { children: [jsx(LoadingPlaceholder, { size: "64px", dark: true }), jsxs("div", { children: [" ", loadingText, " "] })] }));
}

export { PrototypeLoadingPlaceholder };

import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "license-diamond-monochrome-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "currentColor", fillRule: "evenodd" },
        React.createElement("path", { d: "M18.795 4.75c.902 0 1.24.067 1.572.245.275.147.49.363.638.638.178.332.245.67.245 1.572v9.59c0 .902-.067 1.24-.245 1.572-.147.275-.363.49-.638.638-.332.178-.67.245-1.572.245H5.205c-.902 0-1.24-.067-1.572-.245a1.532 1.532 0 01-.638-.638c-.178-.332-.245-.67-.245-1.572v-9.59c0-.902.067-1.24.245-1.572.147-.275.363-.49.638-.638.332-.178.67-.245 1.572-.245z", stroke: "currentColor", strokeWidth: 1.5, fillOpacity: 0.15 }),
        React.createElement("rect", { x: 5, y: 11, width: 6, height: 1.5, rx: 0.75 }),
        React.createElement("rect", { x: 5, y: 8, width: 4, height: 1.5, rx: 0.75 }),
        React.createElement("path", { d: "M21 7.907V11h-2.978a.152.152 0 01-.048-.007l-.043-.023a.152.152 0 01-.054-.17l.023-.042 1.649-1.972a.076.076 0 00-.076-.12l-.036.018-2.536 2.274a.152.152 0 01-.252-.09v-.048l.227-1.371a.076.076 0 00-.125-.07l-.019.024-.858 1.73a.38.38 0 01-.1.126l-.07.045-1.121.565a.076.076 0 00.004.138l.03.006h1c.1 0 .196.04.266.11l.048.056 3.45 5.06a.076.076 0 00.138-.048l-.008-.029-2.39-4.82a.228.228 0 01.145-.32l.06-.009H21v5.766l-.829.941a.228.228 0 01-.322.02l-.02-.02-5.754-6.528a.304.304 0 01-.022-.374L16.5 8.293a.304.304 0 01.216-.129l3.25-.364h.068l.966.107z" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };

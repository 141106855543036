import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { useCallback } from 'react';
import { VerificationCodeInput, Panel } from '@sketch/components';
import { HelpNote } from './HelpNote.js';
import { FormContainer, Note, Paragraph, RecoveryCodeToggle, Title, Description } from './RecoveryCode.styles.js';
import { useValidateMfaTotpMutation } from '@sketch/gql-types';

/**
 * Renders the UI that allows the user to enter the verification code
 * This only renders if the user has mfaState === 'ENABLED' which is
 * returned after the user Signs in
 */
const VerificationCode = ({ onCompleted, onError, onVerificationCodeFilled, toggleInterface, mfaToken, verificationError, onFilled, hideHeader, }) => {
    // Verification Code Validation
    const [validateMfaTotp] = useValidateMfaTotpMutation({
        onCompleted,
        onError,
    });
    const handleVerificationCodeFilled = useCallback((code) => {
        if (onFilled) {
            onVerificationCodeFilled();
            onFilled(code);
            return;
        }
        onVerificationCodeFilled();
        // Code from the Authenticator App
        validateMfaTotp({
            variables: {
                token: mfaToken,
                totp: code,
            },
        });
    }, [mfaToken, validateMfaTotp, onVerificationCodeFilled, onFilled]);
    const form = (jsxs(FormContainer, { children: [jsx(VerificationCodeInput, { onFilled: handleVerificationCodeFilled, error: verificationError }), jsxs(Note, { children: [jsxs(Paragraph, { children: ["Can\u2019t access your authenticator app?", ' ', jsx(RecoveryCodeToggle, Object.assign({ onClick: toggleInterface, isUnderlined: true }, { children: "Use a recovery code" }))] }), jsx(Paragraph, { children: jsx(HelpNote, {}) })] })] }));
    if (hideHeader) {
        return form;
    }
    return (jsxs(Fragment, { children: [jsx(Title, { children: "Enter Verification Code" }), jsxs(Panel.Body, { children: [jsx(Description, { children: "Open your authenticator app and enter the code before it expires \u2014 or wait for a new one." }), form] })] }));
};

export { VerificationCode, VerificationCode as default };

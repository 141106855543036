import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import { noop } from '@sketch/utils';
import '../Popover/index.js';
import { Popover } from '../Popover/Popover.js';

const useDropdownState = ({ onToggle = noop }) => {
    const [visible, setVisible] = useState(false);
    const show = useCallback(() => {
        if (!visible) {
            onToggle(true);
            setVisible(true);
        }
    }, [onToggle, visible]);
    const hide = useCallback(() => {
        if (visible) {
            onToggle(false);
            setVisible(false);
        }
    }, [onToggle, visible]);
    const toggleDropdown = useCallback(() => {
        visible ? hide() : show();
    }, [hide, show, visible]);
    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape')
                hide();
        };
        window.addEventListener('keydown', handleEscape);
        return () => window.removeEventListener('keydown', handleEscape);
    });
    return { visible, show, hide, toggleDropdown };
};
const StatelessDropdown = (_a) => {
    var { style = {}, minWidth = '160px', maxWidth = '352px', contentPadding = '0.5rem 0', spacing, children, toggleDropdown, toggle, visible, className, onClickOutside, usePortal = false, contentStyle } = _a, props = __rest(_a, ["style", "minWidth", "maxWidth", "contentPadding", "spacing", "children", "toggleDropdown", "toggle", "visible", "className", "onClickOutside", "usePortal", "contentStyle"]);
    const handleObservableChange = (inView) => {
        if (visible && !inView) {
            toggleDropdown();
        }
    };
    return (
    /**
     * We use this InView intersection observer component to automatically close
     * the dropdown when this one gets hidden outside the viewport.
     * (This can happen when scrolling the page).
     */
    jsx(InView
    // Close dropdown when more than 25% of it is hidden (i.e. outside of the viewport).
    , Object.assign({ 
        // Close dropdown when more than 25% of it is hidden (i.e. outside of the viewport).
        threshold: 0.75, onChange: handleObservableChange, className: className }, { children: ({ ref }) => (jsx(Popover, Object.assign({ popup: jsx("div", Object.assign({ ref: ref, style: { minWidth, maxWidth } }, { children: children })), spacing: spacing, style: style, contentPadding: contentPadding, onClickOutside: onClickOutside, usePortal: usePortal, contentStyle: contentStyle, onClick: (e) => {
                e.stopPropagation();
                e.preventDefault();
                toggleDropdown();
            }, className: className, visible: visible }, props, { children: toggle }))) })));
};
/**
 * The Dropdown is used in place of a Select when we want to have more customizeable options.
 *
 * It can have different placements, you can check all of them via the Properties table controls.
 */
const Dropdown = (_a) => {
    var { children, toggle, style = {}, minWidth = '160px', maxWidth = '352px', onToggle = noop, disabled = false, usePortal = false, hideOnClick = true } = _a, props = __rest(_a, ["children", "toggle", "style", "minWidth", "maxWidth", "onToggle", "disabled", "usePortal", "hideOnClick"]);
    const { visible, hide, toggleDropdown } = useDropdownState({
        onToggle,
    });
    const optionsRef = useRef(null);
    const tabPressCounter = useRef(0);
    const previouslyFocused = useRef();
    const findFirstElement = useCallback((element) => {
        return element === null || element === void 0 ? void 0 : element.querySelector('a:not(:disabled), button:not(:disabled)');
    }, []);
    useEffect(() => {
        const refInstance = optionsRef === null || optionsRef === void 0 ? void 0 : optionsRef.current;
        if (!refInstance) {
            return;
        }
        // after opening a dropdown and pressing tab, force focus
        // on first focusable element of the dropdown options
        const handlePressTab = (event) => {
            var _a;
            if (event.key === 'Tab' && visible) {
                if (!(previouslyFocused === null || previouslyFocused === void 0 ? void 0 : previouslyFocused.current) && document.activeElement) {
                    previouslyFocused.current = document.activeElement;
                }
                event.preventDefault();
                const optionsLength = refInstance ? refInstance === null || refInstance === void 0 ? void 0 : refInstance.childElementCount : 0;
                // skip the separator
                if ((refInstance === null || refInstance === void 0 ? void 0 : refInstance.children[tabPressCounter.current % optionsLength].localName) === 'div') {
                    tabPressCounter.current = tabPressCounter.current + 1;
                }
                // loop through the dropdown options
                (_a = findFirstElement(refInstance === null || refInstance === void 0 ? void 0 : refInstance.children[tabPressCounter.current % optionsLength])) === null || _a === void 0 ? void 0 : _a.focus();
                tabPressCounter.current = tabPressCounter.current + 1;
            }
        };
        // only use the event listener if the dropdown is globally positioned
        if (usePortal) {
            document.addEventListener('keydown', handlePressTab);
        }
        return () => {
            var _a;
            (_a = previouslyFocused === null || previouslyFocused === void 0 ? void 0 : previouslyFocused.current) === null || _a === void 0 ? void 0 : _a.focus();
            return document.removeEventListener('keydown', handlePressTab);
        };
    }, [optionsRef, visible, usePortal, previouslyFocused, findFirstElement]);
    const isVisible = !disabled && visible;
    return (jsx(StatelessDropdown, Object.assign({ visible: isVisible, toggleDropdown: toggleDropdown, toggle: typeof toggle === 'function' ? toggle(isVisible) : toggle, style: style, minWidth: minWidth, maxWidth: maxWidth, onClickOutside: hide, usePortal: usePortal }, props, { children: jsx(DropdownOptionsList, Object.assign({ ref: optionsRef, onClick: hideOnClick ? hide : e => e.preventDefault(), "data-testid": "dropdown-options" }, { children: children })) })));
};
const DropdownOptionsList = styled.ul `
  padding: 0;
  margin: 0;
`;

export { Dropdown, StatelessDropdown, useDropdownState };

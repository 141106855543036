import {
  useCreateWorkspaceMutation,
  GetUserSettingsDocument,
  GetWorkspacesDocument,
} from '@sketch/gql-types'

import { WorkspaceCreationState } from '../../types'
import { formatMutationInput, CreateWorkspaceResponse } from './utils'

export interface UseCreateWorkspaceProps {
  onComplete: (workspace: CreateWorkspaceResponse) => void
  onError: (error: string) => void
}

export const useCreateWorkspace = (props: UseCreateWorkspaceProps) => {
  const { onComplete, onError } = props

  const [createWorkspaceMutation, ...mutation] = useCreateWorkspaceMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GetWorkspacesDocument },
      { query: GetUserSettingsDocument },
    ],
    onCompleted: data => {
      onComplete(data.createWorkspace.workspace)
    },
    onError: ({ message }) => {
      onError(message)
    },
  })

  const createWorkspace = (state: WorkspaceCreationState) =>
    createWorkspaceMutation({
      variables: { input: formatMutationInput(state) },
    })

  return [createWorkspace, ...mutation] as const
}

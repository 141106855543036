import { useMemo } from 'react'
import { relativeMeasure } from 'modules/shares/PageCanvasView/utils'

import { ArtboardDimensions, Coordinates, ReferenceBounds } from '../types'

interface UseDotCoordinateStyleProps {
  coordinates: Coordinates
  referenceBounds: ReferenceBounds
  prefixBounds?: Coordinates | null
  artboardBounds?: ArtboardDimensions | null
}

const getDotCoordinates = (
  coordinates: Coordinates,
  artboardDimentions?: ArtboardDimensions | null
) =>
  !artboardDimentions
    ? coordinates
    : // Pin the dot to the edge of the Artboard if the annotation
      // moves beyond the Artboard's bounds
      {
        x:
          coordinates.x < 0
            ? 0
            : Math.min(coordinates.x, artboardDimentions.width),
        y:
          coordinates.y < 0
            ? 0
            : Math.min(coordinates.y, artboardDimentions.height),
      }

const useDotCoordinateStyle = ({
  coordinates,
  referenceBounds,
  prefixBounds,
  artboardBounds,
}: UseDotCoordinateStyleProps) => {
  return useMemo(() => {
    const annotationX = relativeMeasure(
      (prefixBounds?.x || 0) + getDotCoordinates(coordinates, artboardBounds).x,
      referenceBounds.width
    )

    const annotationY = relativeMeasure(
      (prefixBounds?.y || 0) + getDotCoordinates(coordinates, artboardBounds).y,
      referenceBounds.height
    )

    const style = {
      top: `${annotationY}%`,
      left: `${annotationX}%`,
      position: 'absolute',
    } as const

    return style

    /*
      Since React uses the `Object.is` algorithm, object dependencies would need
      to refer to the same object in memory. Using primitives instead
      (e.g. `artboardBounds?.height`, `coordinates.y`, etc.)

      TODO: Implement  and use `useDeepMemo` utility hook
      (https://github.com/sketch-hq/Cloud/issues/14326)
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    artboardBounds?.height,
    artboardBounds?.width,
    coordinates.x,
    coordinates.y,
    prefixBounds?.x,
    prefixBounds?.y,
    referenceBounds.height,
    referenceBounds.width,
  ])
}

export default useDotCoordinateStyle

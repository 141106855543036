import { RouteParams } from '@sketch/modules-common'
import { useVersioning } from 'modules/versioning'
import { useCallback } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

type UseReplaceUrlProps = {
  currentArtboardUUID?: string
  prototypeArtboardUUID?: string
}

/**
 * Returns a function that replaces route params if supplied, otherwise the
 * current values are left unchanged.
 */
export function useReplaceUrl() {
  const { getPathname } = useVersioning()
  const { replace } = useHistory()
  const { search } = useLocation()

  const params = useParams<RouteParams<'PROTOTYPE_PLAYER'>>()

  return useCallback(
    (props: UseReplaceUrlProps) => {
      const pathname = getPathname({
        routeKey: 'PROTOTYPE_PLAYER',
        routeParams: {
          shareID: params.shareID,
          prototypeArtboardUUID:
            props.prototypeArtboardUUID || params.prototypeArtboardUUID,
          currentArtboardUUID:
            props.currentArtboardUUID || params.currentArtboardUUID,
        },
      })

      replace({
        pathname,
        search,
      })
    },
    [
      replace,
      getPathname,
      params.shareID,
      params.currentArtboardUUID,
      params.prototypeArtboardUUID,
      search,
    ]
  )
}

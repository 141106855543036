import { __awaiter } from 'tslib';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { castError } from '@sketch/utils';
import { Formik } from 'formik';
import * as yup from 'yup';
import './AvatarUploadContainer/index.js';
import { useModalContext, Form, Input, DecoratedInput } from '@sketch/components';
import { ChangeEmailModal } from '@sketch/user';
import '../ChangePasswordModal/index.js';
import { FieldRow, NameField, SaveNameButton, NameFieldCaption, EmailField, PendingEmailPill, EditEmailButton, NewEmailCaption, ResendLink, PasswordField, EditPasswordButton } from './SettingsForm.styles.js';
import { useToast } from '@sketch/toasts';
import { useEmailResendChangeConfirmationMutation } from '@sketch/gql-types';
import AvatarUploadContainer from './AvatarUploadContainer/AvatarUploadContainer.js';
import { ChangePasswordModal } from '../ChangePasswordModal/ChangePasswordModal.js';

const settingsSchema = yup.object().shape({
    name: yup.string().trim().required('Enter your preferred name'),
});
const SettingsForm = props => {
    const { userName, userEmail, userNewEmail, userHasAvatar, userAvatarSrc, userHasPersonalAuthorization, isSignedInWithSso, onUserUpdate, } = props;
    const { showModal } = useModalContext();
    const { showToast } = useToast();
    const [resendConfirmation, { loading: isResendingConfirmation },] = useEmailResendChangeConfirmationMutation({
        redirectErrors: false,
        onError: 'unsafe-throw-exception',
    });
    const handleOnSubmit = (values, actions) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield onUserUpdate({ variables: { input: values } });
        }
        catch (e) {
            // Error handling will be done by the parent component
        }
        finally {
            actions.setSubmitting(false);
        }
    });
    const handleResendConfirmation = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isResendingConfirmation || !userNewEmail) {
            return;
        }
        try {
            const { data } = yield resendConfirmation();
            const result = data && data.emailResendChangeConfirmation;
            if (result && result.errors && result.errors.length > 0) {
                throw result.errors[0];
            }
            showToast(`Please confirm your new email address using the link we sent to '${userNewEmail}'`, 'positive');
        }
        catch (e) {
            const error = castError(e);
            showToast(error.message, 'negative');
        }
    });
    return (jsx(Formik, Object.assign({ validationSchema: settingsSchema, initialValues: {
            name: userName,
        }, onSubmit: handleOnSubmit, 
        // Keep user name updated when checking account settings
        // with an SSO workspace selected.
        enableReinitialize: true }, { children: formik => {
            const { handleChange, handleBlur, values, errors, isSubmitting, } = formik;
            return (jsxs(Form, Object.assign({ style: { display: 'block' } }, { children: [jsxs(FieldRow, { children: [jsx(AvatarUploadContainer, { userName: userName, userHasAvatar: userHasAvatar, userAvatarSrc: userAvatarSrc }), jsx(NameField, Object.assign({ name: "name", label: "Name", errorText: errors.name }, { children: jsx(Input, { name: "name", type: "text", placeholder: "Your preferred name", value: values.name, disabled: !userHasPersonalAuthorization, onChange: handleChange, onBlur: handleBlur }) })), jsx(SaveNameButton, Object.assign({ type: "submit", variant: "secondary", loading: isSubmitting, disabled: isSubmitting || values.name === userName }, { children: "Save" }))] }), isSignedInWithSso && (jsx(NameFieldCaption, { children: "In SSO Workspaces, your name is set by the identity provider" })), userHasPersonalAuthorization && (jsxs(Fragment, { children: [jsxs(FieldRow, { children: [jsx(EmailField, Object.assign({ name: "email", label: "Email" }, { children: jsx(DecoratedInput, { value: userNewEmail !== null && userNewEmail !== void 0 ? userNewEmail : userEmail, label: "Email", suffixComponent: userNewEmail && (jsx(PendingEmailPill, { children: "Pending" })) }) })), jsx(EditEmailButton, Object.assign({ onClick: () => showModal(ChangeEmailModal, {
                                            initialEmail: userNewEmail !== null && userNewEmail !== void 0 ? userNewEmail : userEmail,
                                        }) }, { children: "Edit\u2026" }))] }), userNewEmail && (jsxs(NewEmailCaption, { children: ["Your current email address is ", jsx("b", { children: userEmail }), " \u2014", jsx(ResendLink, Object.assign({ onClick: handleResendConfirmation }, { children: "Resend Confirmation" }))] })), jsxs(FieldRow, { children: [jsx(PasswordField, Object.assign({ name: "password", label: "Password" }, { children: jsx(Input, { name: "password", type: "text", value: "\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022", disabled: true }) })), jsx(EditPasswordButton, Object.assign({ onClick: () => showModal(ChangePasswordModal, {
                                            initialEmail: userEmail,
                                        }, { closeOnRouteChange: false }) }, { children: "Edit\u2026" }))] })] }))] })));
        } })));
};

export { SettingsForm as default };

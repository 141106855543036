import styled from 'styled-components';
import { Panel as Panel$1, PanelSection, PanelHeader, PanelBody, PanelFooter } from './Panel.js';

const Panel = Object.assign(Panel$1, {
    Section: PanelSection,
    Title: PanelHeader,
    Header: PanelHeader,
    Body: styled(PanelBody) ``,
    Footer: PanelFooter,
});

export { Panel };

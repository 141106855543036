// This constants translate how many seconds is on X unit
const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const YEAR = DAY * 365;
const MONTH = YEAR / 12;
const MILLISECONDS_PER_HOUR = HOUR * 1000;
const MILLISECONDS_PER_DAY = 24 * MILLISECONDS_PER_HOUR;

export { DAY, HOUR, MILLISECONDS_PER_DAY, MILLISECONDS_PER_HOUR, MINUTE, MONTH, WEEK, YEAR };

import styled, { keyframes } from 'styled-components';
import '../Box/index.js';
import '../Link/index.js';
import { ReactComponent } from '@sketch/icons/arrow-circle-right-16';
import { breakpoint } from '@sketch/global-styles';
import { Text, Box } from '../Box/BoxSystem.js';
import { Link } from '../Link/Link.js';

const skeletonAnimation = keyframes `
  0% {
    opacity: 0.02;
  }
  100% {
    opacity: 0.04;
  }
`;
const TitleContainer = styled.div `
  display: flex;
  padding: 12px 0;
  justify-content: space-between;
  align-items: self-end;
`;
const Title = styled(Text.H2).attrs({
    textStyle: 'header.primary.H',
}) `
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0;

  ${({ theme }) => breakpoint('base', 'xs') `
    font-size: ${theme.fontSizes.G};
  `}

  /* Creates a bigger hitbox so the tooltip appears when hovering the subtitle too */
  ::after {
    display: ${({ $hasSubtitleSibling }) => $hasSubtitleSibling ? 'block' : 'none'};
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200%;
  }
`;
const SubTitle = styled(Text.H3).attrs({
    textStyle: 'subheader.quaternary.bold.C',
}) `
  display: flex;
  margin: 0 0 4px 0;
  min-height: 15px;
  text-transform: uppercase;
`;
const ArrowCircle = styled(ReactComponent) `
  margin-left: 4px;
`;
const StyledLink = styled(Link) `
  display: inline-block;

  :hover {
    ${Title} {
      opacity: 0.8;
    }

    ${ArrowCircle} {
      opacity: 0.7;
    }
  }
`;
const Skeleton = styled(Box) `
  margin: 12px 0;
  opacity: 0.02;

  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  animation: ${skeletonAnimation} 500ms infinite;
  animation-direction: alternate; /* plays the animation back and forth */
  animation-timing-function: cubic-bezier(
    0,
    0.15,
    0.4,
    1
  ); /* oscillation curve */

  ::before {
    content: '';
    display: block;
    width: 100px;
    height: 14px;
    margin-bottom: 6px;
    border-radius: ${({ theme }) => theme.radii.xlarge};
    background: currentColor;
  }

  ::after {
    content: '';
    display: block;
    width: 250px;
    height: 27px;
    border-radius: ${({ theme }) => theme.radii.xlarge};
    background: currentColor;
  }
`;

export { ArrowCircle, Skeleton, StyledLink, SubTitle, Title, TitleContainer };

import styled from 'styled-components'

export const StyledPageCanvas = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /*
   * Some versions of safari ignore height: 100% in some flexbox context.
   * So we use flex-grow:1 to force the element to take all the available space.
   */
  flex-grow: 1;
  /*
   * Continue propagation of flex flow to the child canvas
   * so that the height of the canvas is automatically 100% of the parent.
   https://github.com/sketch-hq/Cloud/issues/10223
   */
  display: flex;
`

export const PageCanvasWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`

import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { Box, Flex, LoadingPlaceholder } from '@sketch/components'
import InspectorError from 'modules/shares/Inspector/Sidebar/components/Inspector/InspectorError'
import Style from 'modules/shares/Inspector/Sidebar/components/Style'
import Appearance from 'modules/shares/Inspector/Sidebar/components/Appearance'
import Color, {
  ColorProps,
} from 'modules/shares/Inspector/Sidebar/components/Color'
import Text, {
  TextStyleProps,
} from 'modules/shares/Inspector/Sidebar/components/Text'
import Separator from 'modules/shares/Inspector/Sidebar/components/Separator'
import { Header } from './Header'
import { EditableComponentDescription } from '../ComponentDescription'

import {
  useInspectContext,
  useComponentsDescriptionContext,
} from '../../context'
import {
  useGetInspectorComponentDataQuery,
  useEditDescriptionsMutation,
} from '@sketch/gql-types'
import { useLocalStorage } from 'react-use'

import { ColorFormat, Appearance as AppearanceType } from 'modules/shares/types'

import { useAnalytics } from '@sketch/modules-common'
import UnselectedPlaceholder from '../UnselectedPlaceholder'
import { getGroupType } from '../../utils'
import { useEventDispatch } from '@sketch/utils'

declare module '@sketch/utils' {
  export interface EventsMap {
    versionIsTriggeredFromComponentButNotSymbol: string
  }
}

/**
 * TYPES
 */
interface CommonComponentProps {
  componentName: string
  componentPath: string
  description?: string
  disabled: boolean
  onEdit: (description: string) => void
}

type InspectorLayerStyleProps = CommonComponentProps &
  Pick<TextStyleProps, 'style' | 'colorFormat' | 'onColorFormatChange'>

type InspectorTextStyleProps = CommonComponentProps &
  Pick<TextStyleProps, 'style' | 'colorFormat' | 'onColorFormatChange'>

type InspectorColorVarProps = CommonComponentProps & ColorProps

/**
 * STYLES
 */
const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: calc(var(--vh, 1vh) * 100);
  padding: 16px;

  word-wrap: break-word;
  overflow-y: scroll;
  width: 100%;
`

const Container = styled.div.attrs({
  'data-testid': 'cwv-inspector-sidebar',
})`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
`

/**
 * COMPONENTS
 */
const InspectorLayerStyle: React.FC<InspectorLayerStyleProps> = ({
  style,
  colorFormat,
  onColorFormatChange,
  componentName,
  componentPath,
  description,
  disabled,
  onEdit,
}) => {
  const { trackEvent } = useAnalytics()

  useEffect(() => {
    trackEvent('CWV - inspect', { type: 'layer styles' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Header
        componentName={componentName}
        componentPath={componentPath}
        style={style}
      />
      <EditableComponentDescription
        description={description}
        onEdit={onEdit}
        disabled={disabled}
      />
      <Appearance {...(style!.appearance as AppearanceType)} />
      <Style
        style={style!}
        onColorFormatChange={onColorFormatChange}
        colorFormat={colorFormat}
      />
    </Container>
  )
}

const InspectorTextStyle: React.FC<InspectorTextStyleProps> = ({
  style,
  colorFormat,
  onColorFormatChange,
  componentName,
  componentPath,
  description,
  disabled,
  onEdit,
}) => {
  const TEXT_PLACEHOLDER =
    'Just zoom, export, view and quickly get feedback with Sketch.'

  const { trackEvent } = useAnalytics()

  useEffect(() => {
    trackEvent('CWV - inspect', { type: 'text styles' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const shouldShowNonTextStyles = Boolean(
    style?.blur ||
      style?.fills?.length ||
      style?.borders?.length ||
      style?.shadows?.length
  )

  return (
    <Container>
      <Header
        componentName={componentName}
        componentPath={componentPath}
        style={style}
      />
      <EditableComponentDescription
        description={description}
        onEdit={onEdit}
        disabled={disabled}
      />
      <Text
        attributedString={{
          string: TEXT_PLACEHOLDER,
          attributes: [
            {
              attributes: style!.text!,
              length: TEXT_PLACEHOLDER.length,
              location: 0,
            },
          ],
        }}
        style={style}
        onColorFormatChange={onColorFormatChange}
        colorFormat={colorFormat}
      />
      {shouldShowNonTextStyles && (
        <Style
          style={style!}
          onColorFormatChange={onColorFormatChange}
          colorFormat={colorFormat}
        />
      )}
    </Container>
  )
}

const InspectorColorVar: React.FC<InspectorColorVarProps> = ({
  colorFormat,
  onColorFormatChange,
  componentName,
  componentPath,
  description,
  disabled,
  onEdit,
  ...rest
}) => {
  const { trackEvent } = useAnalytics()

  useEffect(() => {
    trackEvent('CWV - inspect', { type: 'color variables' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Header componentName={componentName} componentPath={componentPath} />
      <EditableComponentDescription
        description={description}
        onEdit={onEdit}
        disabled={disabled}
      />
      <Separator />
      <Box py={4} px={5}>
        <Color
          {...rest}
          onColorFormatChange={onColorFormatChange}
          colorFormat={colorFormat}
        />
      </Box>
    </Container>
  )
}

/**
 * MAIN COMPONENT
 */
export const Inspector: React.FC = () => {
  const { selectedItem } = useInspectContext()

  const {
    updateDescripton,
    componentsDescription: { selected, descriptions },
  } = useComponentsDescriptionContext()

  const onVersionTriggered = useEventDispatch(
    'versionIsTriggeredFromComponentButNotSymbol'
  )

  const [editDescription] = useEditDescriptionsMutation({
    onCompleted: () => {
      onVersionTriggered(descriptions[selected!].shareIdentifier!)
    },
    onError: 'show-toast',
  })

  const { path } = useRouteMatch()

  const CWVRoute = getGroupType(path)

  const [colorFormat, setColorFormat] = useLocalStorage(
    'inspector_color_format',
    ColorFormat.HEX
  )

  const { data, error, loading } = useGetInspectorComponentDataQuery({
    variables: {
      componentId: selectedItem ?? undefined,
    },
    skip: !selectedItem,
  })

  if (!selectedItem) {
    return <UnselectedPlaceholder groupType={CWVRoute} />
  }

  if (error) {
    return (
      <CenteredContainer>
        <InspectorError error={error.name} reason={error.message} />
      </CenteredContainer>
    )
  }

  if (loading) {
    return (
      <Flex
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <LoadingPlaceholder />
      </Flex>
    )
  }

  const inspectorData = JSON.parse(data?.inspectorData?.inspector?.data ?? '{}')
  const style = inspectorData?.value

  const isTextStyle = !!style.text
  const isColorVar = style?.alpha !== undefined
  const isLayerStyle = !isTextStyle && !isColorVar

  const componentNameAndPath = inspectorData.name.split('/')
  const componentName = componentNameAndPath.pop()
  const componentPath = componentNameAndPath.join('/')

  const selectedDescription = descriptions?.[selected || '']
  const description = selectedDescription?.description ?? undefined

  const onEdit = (description: string) => {
    const componentSelected = selected!
    const component = descriptions[componentSelected]

    editDescription({
      variables: {
        baseVersionIdentifier: component.baseVersionIdentifier!,
        compatibilityVersion: component.compatibilityVersion!,
        documentVersion: component.documentVersion!,
        shareIdentifier: component.shareIdentifier!,
        edits: [
          {
            componentUuid: componentSelected,
            description,
          },
        ],
      },
    })

    updateDescripton(componentSelected, description)
  }

  switch (true) {
    case isLayerStyle:
      return (
        <InspectorLayerStyle
          style={style}
          colorFormat={colorFormat!}
          onColorFormatChange={setColorFormat}
          componentName={componentName}
          componentPath={componentPath}
          description={description}
          disabled={!selectedDescription.canEditDescriptions}
          onEdit={onEdit}
        />
      )
    case isTextStyle:
      return (
        <InspectorTextStyle
          style={style}
          colorFormat={colorFormat!}
          onColorFormatChange={setColorFormat}
          componentName={componentName}
          componentPath={componentPath}
          description={description}
          disabled={!selectedDescription.canEditDescriptions}
          onEdit={onEdit}
        />
      )
    case isColorVar:
      return (
        <InspectorColorVar
          colorFormat={colorFormat!}
          onColorFormatChange={setColorFormat}
          componentName={componentName}
          componentPath={componentPath}
          description={description}
          disabled={!selectedDescription.canEditDescriptions}
          onEdit={onEdit}
          {...style}
        />
      )
    default:
      return null
  }
}

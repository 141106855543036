import React from 'react'

import { useCanvasZoom } from 'components/WebRendererCanvas/hooks'

import PublicationPageSelector from '../PublicationPageSelector'

import { StyledZoom } from './PublicationCanvasControls.styles'

import { PublicationPageFragment } from '@sketch/gql-types'

interface PublicationCanvasControlsProps {
  identifier: string
  page: PublicationPageFragment
  onPageSelected: (page: PublicationPageFragment) => void
}

const PublicationCanvasControls: React.FC<PublicationCanvasControlsProps> = ({
  identifier,
  page,
  onPageSelected,
}) => {
  const [zoom, setZoom] = useCanvasZoom()

  return (
    <>
      <PublicationPageSelector
        identifier={identifier}
        page={page}
        onPageSelected={onPageSelected}
      />
      <StyledZoom zoom={zoom} setZoom={setZoom} unstyled />
    </>
  )
}

export default PublicationCanvasControls

import { jsx } from 'react/jsx-runtime';
import { useHistory } from 'react-router';
import { useBreakpoint, Section, InfiniteList } from '@sketch/components';
import { useSignInToUnauthorizedWorkspace } from 'cloud-frontend';
import { WorkspaceListItem } from './Item/WorkspaceListItem.js';
import { StyledTable } from './WorkspaceList.styles.js';
import { useUserAuthorizations } from '@sketch/modules-common';

const WorkspaceList = ({ memberships, linkButton, onLoadMore, onLeaveWorkspace, }) => {
    const history = useHistory();
    const isMobileAndSmaller = !useBreakpoint('xs');
    const { hasAccessToWorkspace } = useUserAuthorizations();
    const { signIn } = useSignInToUnauthorizedWorkspace();
    const handleWorkspaceClick = (event, workspace) => {
        if (!hasAccessToWorkspace(workspace)) {
            event.preventDefault();
            const selectedWorkspace = memberships.find(membership => membership.workspace.identifier === workspace.identifier);
            if (selectedWorkspace) {
                const redirectURL = event.currentTarget.getAttribute('href');
                signIn({ workspace, onCompleted: () => history.push(redirectURL) });
            }
        }
    };
    const tableHeaders = [
        { label: 'Workspace Name' },
        { label: 'Role' },
        { label: 'Leave Workspace' },
    ].filter(header => {
        // Remove "Role" column from the table header in mobile
        if (isMobileAndSmaller && header.label === 'Role') {
            return null;
        }
        return header;
    });
    return (jsx(Section, Object.assign({ title: "Workspaces", text: "Workspaces are where you\u2019ll save your work and collaborate with others.", action: linkButton }, { children: jsx(InfiniteList, Object.assign({ onLoadMore: onLoadMore }, { children: jsx(StyledTable, { header: tableHeaders, hideHeader: true, items: memberships, "data-testid": "workspace-list", renderItem: (membership) => {
                    return (jsx(WorkspaceListItem, { membership: membership, onLeaveWorkspace: onLeaveWorkspace, hasAccessToWorkspace: hasAccessToWorkspace(membership.workspace) === true, onClick: (event) => handleWorkspaceClick(event, membership.workspace) }));
                } }) })) })));
};

export { WorkspaceList as default };

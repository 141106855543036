import styled, { keyframes, css } from 'styled-components';
import { breakpoint } from '@sketch/global-styles';
import '../NavbarExtended/index.js';
import '../DocumentHeader/index.js';
import '../Truncate/index.js';
import { ActionsWrapper } from '../DocumentHeader/DocumentHeader.styles.js';
import { WorkspaceLogo } from '../WorkspaceLogo/index.js';
import { DocumentHeader } from '../DocumentHeader/DocumentHeader.js';
import { truncateStyles } from '../Truncate/Truncate.js';
import { NavbarExtended } from '../NavbarExtended/Navbar.js';

const NAVBAR_PORTAL_WIDTH = 524;
const SIDEBAR_LEFT_WIDTH = 232;
const slide = keyframes `
    0% {
      opacity: 0;
      transform: translateY(-185px)
    }
    100% {
      opacity: 1;
      transform: translateY(0)
    }
  `;
const StyledHeader = styled.header(({ theme, $sticky }) => css `
    /* For mobile we still want sticky header */
    ${breakpoint('base', 'sm') `
      width: 100%;
      position: sticky;
      top: 0;
      z-index: ${theme.zIndex[4]};
    `}

    #header-slim-portal {
      display: none;
    }

    ${DocumentHeader} {
      margin-bottom: 0;
    }

    /* Overwrite existing styles for the slim header */
    ${$sticky &&
    css `
      h1 {
        font-size: ${theme.fontSizes.F};
        margin-right: 16px;
        font-weight: 500;
        ${truncateStyles};
      }

      ${NavbarExtended} {
        display: flex;
        min-width: 0;
        flex-direction: row;

        #header-slim-portal {
          display: flex;
          height: 68px;
          backdrop-filter: blur(15px);
          box-shadow: ${theme.colors.headerSticky.shadow};
          background-color: ${theme.colors.headerSticky.background};
          animation: ${slide} ${theme.transitions.duration[2]}
            ${theme.transitions.timing.easeInOut};

          ${DocumentHeader} {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: calc(100% - ${SIDEBAR_LEFT_WIDTH}px);
            margin: 0;
            flex-shrink: 0;
            align-self: auto;
            max-width: 100%;

            /* NavPortal is fixed on the top right of the screen, we need to
            reserve that space */
            padding-right: ${NAVBAR_PORTAL_WIDTH}px;

            ${breakpoint('lg') `
              padding-right: ${NAVBAR_PORTAL_WIDTH}px;
            `}

            div {
              min-width: 0;
              margin: 0;
            }
          }

          ${ActionsWrapper} {
            flex-shrink: 0;
          }

          div {
            min-width: 0;
          }

          ${WorkspaceLogo},
          ${WorkspaceLogo.Icon},
          span[aria-label='Avatar stack'],
          button[aria-label='Edit title'],
          div[aria-label='Back to project'] {
            display: none;
          }

          button {
            font-size: ${theme.fontSizes.D};
            height: 30px;
            padding-left: 8px;
            padding-right: 8px;
            margin: 0 8px 0 0;
          }
        }

        #navbar-portal {
          flex-direction: row;
          align-items: center;
        }

        ${NavbarExtended} {
          background-color: transparent;
          backdrop-filter: none;
          padding: 0;
          height: auto;
          width: auto;
          position: static;
        }
      }
    `}
  `);
const SlimHeader = styled.div ``;

export { SlimHeader, StyledHeader };

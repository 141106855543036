import { getOperationName } from 'apollo-link';

function getCachedQueriesByOperationName(apolloClient, operationName, filter = () => true) {
    const queries = apolloClient.queryManager.queryStore.getStore();
    return Object.values(queries).filter(query => {
        return operationName === getOperationName(query.document) && (filter === null || filter === void 0 ? void 0 : filter(query));
    });
}

export { getCachedQueriesByOperationName };

// Usage of PublicationPeriod_Local and PublicationSortOrder_Local will not break the build
// eslint-disable-next-line no-restricted-imports
import {
  PublicationDocumentType,
  PublicationPeriod_Local,
  PublicationSortOrder,
} from '@sketch/gql-types/expansive'

export const PERIOD_ORDER: PublicationPeriod_Local[] = [
  'DAY',
  'WEEK',
  'MONTH',
  'YEAR',
  'FOREVER',
]
export const DOCUMENT_TYPE_ORDER: PublicationDocumentType[] = [
  'ALL_DOCUMENT_TYPES',
  'LIBRARY',
  'STANDARD',
  'TEMPLATE',
]
export const SORT_ORDER = [
  'UPDATED_AT_DESC',
  'MOST_REACTED_DESC',
  'NAME_ASC',
] as PublicationSortOrder[]

export const PERIOD_LABEL: Record<PublicationPeriod_Local, string> = {
  DAY: 'Today',
  WEEK: 'This week',
  YEAR: 'This year',
  MONTH: 'This month',
  FOREVER: 'All time',
}
export const DOCUMENT_TYPE_LABEL: Record<PublicationDocumentType, string> = {
  LIBRARY: 'Libraries',
  STANDARD: 'Documents',
  TEMPLATE: 'Templates',
  ALL_DOCUMENT_TYPES: 'All Types',
}
export const SORT_LABEL: Record<PublicationSortOrder, string> = {
  UPDATED_AT_DESC: 'Recently created',
  RANDOM: 'Random',
  MOST_REACTED_DESC: 'Most loved',
  NAME_ASC: 'Alphabetically',
  LATEST_FIRST: 'Recently created',
}

import styled, { css } from 'styled-components'

export const ContentWrapper = styled.div<{ $isRefreshedUi?: boolean }>`
  max-width: 640px;
  flex: 1;

  ${({ $isRefreshedUi }) =>
    $isRefreshedUi &&
    css`
      margin-top: 24px;
    `}
`

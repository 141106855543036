import { jsx, jsxs } from 'react/jsx-runtime';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useModalContext, Form, Modal, PasswordInput, Button } from '@sketch/components';
import { Title, Description, FormWrapper } from '../RegenerateRecoveryCodesModal.styles.js';

const VALIDATION_SCHEMA = yup.object().shape({
    password: yup.string().required('Password can’t be blank'),
});
const INITIAL_VALUES = {
    password: '',
};
/**
 * Renders the second step for the Regenerate Recovery Codes modal
 */
const SecondStep = ({ onSubmit, isLoading, }) => {
    const { hideModal } = useModalContext();
    return (jsx(Formik, Object.assign({ initialValues: INITIAL_VALUES, validationSchema: VALIDATION_SCHEMA, onSubmit: onSubmit }, { children: ({ values, errors, touched, dirty, isValid, handleChange, handleBlur, }) => (jsxs(Form, { children: [jsxs(Modal.Body, { children: [jsx(Title, { children: "Enter Your Password" }), jsx(Description, { children: "Enter your password to generate a new set of recovery codes." }), jsx(FormWrapper, { children: jsx(Form.Field, Object.assign({ name: "password", label: "Password", errorText: touched.password ? errors.password : undefined }, { children: jsx(PasswordInput, { name: "password", placeholder: "Password", value: values.password, onChange: handleChange, onBlur: handleBlur }) })) })] }), jsxs(Modal.Footer, { children: [jsx(Button, Object.assign({ onClick: hideModal, size: "40" }, { children: "Cancel" })), jsx(Button, Object.assign({ type: "submit", variant: "primary", size: "40", loading: isLoading, disabled: !(dirty && isValid) }, { children: "Submit" }))] })] })) })));
};

export { SecondStep };

import { useGetUserCredentialsQuery } from '@sketch/gql-types';
import { useEffect } from 'react';
import config from '@sketch/env-config';
import '../telemetry/index.js';
import { setConfig } from '../telemetry/telemetry.js';

const TelemetryConfig = () => {
    // keep auth token in sync with the one in cache
    const { data } = useGetUserCredentialsQuery({
        fetchPolicy: 'cache-only',
    });
    useEffect(() => {
        var _a;
        setConfig({
            apiHost: config['telemetry_uri'],
            authToken: (_a = data === null || data === void 0 ? void 0 : data.userCredentials) === null || _a === void 0 ? void 0 : _a.authToken,
        });
    }, [data]);
    return null;
};

export { TelemetryConfig };

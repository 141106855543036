import { __awaiter } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import { useState, useRef, useEffect } from 'react';
import { InputWrapper, Preview, Form, EditInput, Text, Wrapper, PencilButton, Pencil } from './EditableText.styles.js';

const Input = props => {
    const { value: externalValue, onEdit, placeholder, previewElement: PreviewElement, onCancel, allowNewLines, noExtraStyling, } = props;
    const [value, setValue] = useState(externalValue);
    const textAreaRef = useRef(null);
    useEffect(() => {
        var _a;
        (_a = textAreaRef.current) === null || _a === void 0 ? void 0 : _a.select();
    }, []);
    // Make the text area appear selected when opened and
    // mount the escape button hook
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                onCancel();
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [onCancel]);
    const createSubmit = (form) => {
        form === null || form === void 0 ? void 0 : form.dispatchEvent(new Event('submit', { cancelable: true }));
    };
    return (jsxs(InputWrapper, { children: [jsx(PreviewElement, Object.assign({ as: Preview }, { children: value === '' ? placeholder : value })), jsx(Form, Object.assign({ onSubmit: e => {
                    e.preventDefault();
                    onEdit(value.trim());
                } }, { children: jsx(PreviewElement, { noExtraStyling: noExtraStyling, as: EditInput, "data-testid": "editabletext-input", name: "text", placeholder: placeholder, onChange: (e) => setValue(
                    // Replace double space from triggering a new line
                    allowNewLines
                        ? e.target.value
                        : e.target.value.replaceAll(/\s{2,}/g, ' ')), onBlur: (e) => {
                        createSubmit(e.currentTarget.form);
                    }, onKeyPress: (e) => {
                        // Make the "Enter" key submit the change instead of a new line
                        if (e.key === 'Enter' && !(e.shiftKey && allowNewLines)) {
                            e.preventDefault();
                            createSubmit(e.currentTarget.form);
                        }
                    }, ref: textAreaRef, value: value }) }))] }));
};
const EditableText = props => {
    const { forceEdit, element: Element, value = '', placeholder, onEdit, onClearForceEdit, allowNewLines, noExtraStyling, removePlaceholderMarginBottom, } = props;
    const [isEditing, setEditing] = useState(forceEdit);
    const headerRef = useRef(null);
    let text = (jsx(Text, Object.assign({ as: Element, tabIndex: 0, "aria-label": value, ref: headerRef, noExtraStyling: noExtraStyling, removePlaceholderMarginBottom: removePlaceholderMarginBottom, onClick: () => {
            // Only allow since click for edit mode in non-touch devices
            if ('ontouchstart' in document.documentElement === false) {
                setEditing(true);
            }
        }, onKeyPress: (event) => {
            // Allow the Enter and " " chars to enter in edit mode
            if (['Enter', ' '].includes(event.key)) {
                setEditing(true);
            }
        }, placeholder: placeholder }, { children: value })));
    // Set the "isEditing" true when the forceEdit is send
    useEffect(() => {
        if (forceEdit) {
            setEditing(true);
        }
    }, [forceEdit]);
    // Call "onClearForceEdit" when the edit is set to false
    useEffect(() => {
        if (isEditing && forceEdit) {
            return () => {
                onClearForceEdit === null || onClearForceEdit === void 0 ? void 0 : onClearForceEdit();
            };
        }
    }, [isEditing, forceEdit, onClearForceEdit]);
    if (isEditing) {
        text = (jsx(Input, { noExtraStyling: noExtraStyling, value: value, placeholder: placeholder, previewElement: Element, onEdit: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                if (value !== newValue) {
                    yield onEdit(newValue);
                }
                setEditing(false);
            }), onCancel: () => {
                setEditing(false);
            }, allowNewLines: allowNewLines }));
    }
    return (jsxs(Wrapper, Object.assign({ noExtraStyling: noExtraStyling }, { children: [text, !noExtraStyling && (jsxs(PencilButton, Object.assign({ "aria-label": "Edit", tabIndex: -1, onClick: () => setEditing(true), disabled: isEditing }, { children: [jsx("span", Object.assign({ className: "sr-only" }, { children: "Edit" })), jsx(Pencil, {})] })))] })));
};

export { EditableText };

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import styled from 'styled-components';
import { lightTheme } from '@sketch/global-styles';
import '../Box/index.js';
import { getDisplayName } from '@sketch/utils';
import { Text } from '../Box/BoxSystem.js';

const headingTextStyles = {
    hero: {
        fontSize: '2.375rem',
        lineHeight: '3rem',
        fontWeight: lightTheme.fontWeights.bold,
    },
    large: {
        fontSize: lightTheme.fontSizes.H,
        lineHeight: '2.25rem',
        fontWeight: lightTheme.fontWeights.bold,
    },
    medium: {
        fontSize: lightTheme.fontSizes.G,
        lineHeight: '2rem',
        fontWeight: lightTheme.fontWeights.medium,
    },
    small: {
        fontSize: lightTheme.fontSizes.F,
        lineHeight: '1.5rem',
        fontWeight: lightTheme.fontWeights.medium,
    },
    smaller: {
        fontSize: lightTheme.fontSizes.E,
        lineHeight: '1.25rem',
        fontWeight: lightTheme.fontWeights.medium,
    },
    tiny: {
        fontSize: lightTheme.fontSizes.D,
        lineHeight: '1.25rem',
        fontWeight: lightTheme.fontWeights.medium,
    },
};
function withHeading(Comp, defaultSize) {
    const Heading = (_a) => {
        var { size } = _a, rest = __rest(_a, ["size"]);
        const props = Object.assign(Object.assign({}, headingTextStyles[size || defaultSize]), rest);
        return jsx(Comp, Object.assign({}, props));
    };
    Heading.displayName = `withHeading(${getDisplayName(Comp)})`;
    return styled(Heading) ``;
}
const H0 = withHeading(Text.H1, 'hero');
const H1 = withHeading(Text.H1, 'large');
const H2 = withHeading(Text.H2, 'medium');
const H3 = styled(withHeading(Text.H3, 'small')) `
  margin-top: 48px;
  margin-bottom: 48px;
`;
const H4 = withHeading(Text.H4, 'smaller');
const H5 = withHeading(Text.H5, 'tiny');
const H6 = withHeading(Text.H6, 'tiny');
const Heading = {
    H0,
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
};

export { Heading, headingTextStyles };

import { useEffect } from 'react'

export default function useScrollReset() {
  useEffect(() => {
    const scrollingContainer = document.querySelector(
      '[data-content-scrollable]'
    )

    if (scrollingContainer) {
      scrollingContainer.scrollTop = 0
    }
  }, [])

  return
}

import styled from 'styled-components';
import { ReactComponent } from '@sketch/icons/cross-16';
import '../Box/index.js';
import '../Button/index.js';
import { ButtonUnstyled } from '../Button/ButtonUnstyled.js';
import { Text } from '../Box/BoxSystem.js';

const StyledCrossIcon = styled(ReactComponent) `
  width: 16px;
  height: 16px;
`;
const CloseButton = styled(ButtonUnstyled) `
  align-self: center;
  display: flex;
  opacity: 0.4;

  :hover {
    opacity: 0.85;
  }
`;
const Container = styled.section `
  background-color: ${({ theme }) => theme.colors.background.secondary.A};
  border-radius: ${({ theme }) => theme.radii.medium};
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  z-index: 1;
`;
const Header = styled.header `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.colors.background.secondary.A};
  border-radius: ${({ theme }) => `
    ${theme.radii.medium} ${theme.radii.medium} 0px 0px
  `};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`;
const Title = styled(Text).attrs({
    textStyle: 'copy.primary.standard.D',
}) `
  margin: 0;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 1.6;
`;
const Content = styled.div `
  padding: 16px;
`;

export { CloseButton, Container, Content, Header, StyledCrossIcon, Title };

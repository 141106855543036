import { useToast } from '@sketch/toasts'
import { useShareDelete } from 'modules/shares/operations'
import React from 'react'
import { Link } from '@sketch/components'
import { routes } from '@sketch/modules-common'

interface UseShareDeleteWithUiProps {
  onCompleted?: () => void
}

export const useShareDeleteWithUi = (props: UseShareDeleteWithUiProps = {}) => {
  const { onCompleted } = props
  const { showToast } = useToast()

  return useShareDelete({
    onCompleted: ({ name, workspace }) => {
      const workspaceId = workspace.identifier

      showToast(
        <>
          &quot;
          {name}
          &quot; has been moved to{' '}
          <Link
            to={routes.WORKSPACE_TRASH.create({
              workspaceId,
            })}
          >
            Trash
          </Link>
        </>
      )

      onCompleted?.()
    },
    onError: message => showToast(message, 'negative'),
  })
}

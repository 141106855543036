import styled from 'styled-components'

export const NoticeIcon = styled.svg`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 8px;
`

export const ContentLayout = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.C};
  align-items: flex-start;
`

import styled from 'styled-components';

const TextWrapper = styled.div `
  margin-left: 12px;
  overflow: hidden;
`;
const Wrapper = styled.div `
  display: flex;
  align-items: center;
`;
const TitleText = styled.span `
  overflow-wrap: break-word;
`;

export { TextWrapper, TitleText, Wrapper };

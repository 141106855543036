import React from 'react'
import * as yup from 'yup'

import { Checkbox } from '@sketch/components'
import { useFormikContext } from 'formik'

import FormField from '../FormField'
import CountryField from '../CountryField'
import { Section } from '../CreateWorkspaceCommon'
import { Wrapper } from './ShippingDetailsForm.styles'
import { ShippingDetailsFormFields } from './types'
import { ResponsiveInlineField } from '../BillingDetailsForm/BillingDetailsForm.styles'
import { H3 } from 'modules/workspace/components/BillingDetailsForm/BillingDetailsForm.styles'

const REQUIRED_STATE_COUNTRIES = ['US']

export const validationSchema = yup.object().shape({
  name: yup.string().trim().required('Name is required'),
  email: yup
    .string()
    .email('Email appears invalid')
    .trim()
    .required('Email is required'),
  address: yup.string().trim().required('Address is required'),
  country: yup.string().required('Country is required'),
  city: yup.string().trim().required('City is required'),
  state: yup.string().when('country', {
    is: 'US',
    then: schema => schema.trim().required('State is required'),
    otherwise: schema => schema.trim(),
  }),
  postalCode: yup.string().trim().required('Postal Code is required'),
  taxId: yup.string().trim(),
})

interface ShippingDetailsFormProps {
  shippingInfoAsBilling: boolean
  setShippingInfoAsBilling: React.Dispatch<React.SetStateAction<boolean>>
}

const ShippingDetailsForm = <
  ShippingFormValues extends ShippingDetailsFormFields
>({
  shippingInfoAsBilling,
  setShippingInfoAsBilling,
}: ShippingDetailsFormProps) => {
  const { values } = useFormikContext<ShippingFormValues>()
  const isStateMandatory = REQUIRED_STATE_COUNTRIES.includes(
    values.shippingCountry
  )
  return (
    <Wrapper>
      <H3>Shipping Information</H3>
      <Checkbox
        name="shippingInfo"
        id="shipping-info"
        variant="untinted"
        checked={shippingInfoAsBilling}
        onChange={() => setShippingInfoAsBilling(!shippingInfoAsBilling)}
      >
        Shipping information is the same as billing
      </Checkbox>
      {!shippingInfoAsBilling && (
        <Section>
          <FormField<ShippingFormValues>
            label="Company Name"
            name="shippingName"
          />
          <FormField<ShippingFormValues>
            label="Address"
            name="shippingAddress"
          />
          <FormField<ShippingFormValues> label="City" name="shippingCity" />
          <ResponsiveInlineField>
            <FormField<ShippingFormValues>
              label={`State or Province ${
                isStateMandatory ? '' : ' (Optional)'
              }`}
              name="shippingState"
            />
            <FormField<ShippingFormValues>
              label="ZIP or Postal Address"
              name="shippingPostalCode"
            />
          </ResponsiveInlineField>
          <CountryField name="shippingCountry" />
        </Section>
      )}
    </Wrapper>
  )
}

export default ShippingDetailsForm

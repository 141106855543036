import React from 'react'

import {
  Dropdown,
  useResponsiveDropdown,
  MenuIconButton,
} from '@sketch/components'

import {
  useHideAnnotationDots,
  useIsAnnotationContextAvailable,
} from 'modules/annotations/hooks'

import { ReactComponent as DotsIcon } from '@sketch/icons/dots-3-horizontal-24'
import { AnnotationDropdownActions } from 'modules/annotations/components'

interface DetailDropdownProps {
  shareIdentifier: string
  permanentArtboardId: string
  permanentArtboardShortId: string
  subscriptionStatus: string
}

const DetailDropdown = (props: DetailDropdownProps) => {
  const [hideAnnotations] = useHideAnnotationDots() || []

  return (
    <>
      <Dropdown.Header>Artboard</Dropdown.Header>

      {hideAnnotations !== undefined && (
        <AnnotationDropdownActions
          shareIdentifier={props.shareIdentifier}
          subscriptionStatus={props.subscriptionStatus}
          permanentArtboardId={props.permanentArtboardId}
          permanentArtboardShortId={props.permanentArtboardShortId}
        />
      )}
    </>
  )
}

export const ArtboardDetailSettingsDropdown = (props: DetailDropdownProps) => {
  const isAnnotationContextAvailable = useIsAnnotationContextAvailable()

  const [dropdown, buttonProps] = useResponsiveDropdown({
    dropdown: DetailDropdown,
    dropdownProps: props,
    placement: 'bottom-end',
  })

  if (!isAnnotationContextAvailable) {
    return null
  }

  return (
    <>
      <MenuIconButton data-testid="artboard-settings-toggle" {...buttonProps}>
        <DotsIcon role="img" />
        <span className="sr-only">Artboard Settings</span>
      </MenuIconButton>
      {dropdown}
    </>
  )
}

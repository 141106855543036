import { defaultDataIdFromObject } from 'apollo-cache-inmemory';

const dataIdFromObject = (object) => {
    switch (object.__typename) {
        case 'Artboard': {
            const artboard = object;
            return `${artboard.__typename}::${artboard.permanentArtboardShortId}::${artboard.documentVersionShortId}`;
        }
        case 'Organization':
        case 'Version': {
            const shortIdObject = object;
            return `${shortIdObject.__typename}::${shortIdObject.shortId}`;
        }
        case 'Plans': {
            /*
             * "defaultDataIdFromObject" will return null if the object doesn't contain
             * by default an "id" or "_id".
             *
             * Since the "Plans" object doesn't have an ID associated with it, it won't work for "defaultDataIdFromObject",
             * that's why we are forcing one here. So we register the object in cache and access it later on.
             */
            return `${object.__typename}`;
        }
        case 'CollectionSharePreview': {
            const collectionSharePreview = object;
            return `${collectionSharePreview.__typename}::${collectionSharePreview.shareIdentifier}`;
        }
        default: {
            const anyObject = object;
            if (anyObject.identifier) {
                return defaultDataIdFromObject({
                    __typename: anyObject.__typename,
                    id: anyObject.identifier,
                });
            }
            return defaultDataIdFromObject(object);
        }
    }
};

export { dataIdFromObject };

import styled from 'styled-components'

import { Zoom } from 'components/Zoom'

export const StyledZoom = styled(Zoom)`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 70px;

  box-shadow: 0px 1px 2px 0px ${({ theme }) => theme.colors.shadow.outer};
  font-size: ${({ theme }) => theme.fontSizes.D};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  line-height: 1.4; /* vertically center icon with text */

  /* 
   The hover state uses a transparent black color rgba(0,0,0,0.04) 
   causing some issues when a artboard is below the button 
   this linear-gradient fill the background with the proper color 
   and background-blend-mode multiplies the color to blend it properly 
  */
  background-blend-mode: multiply;
  background-image: linear-gradient(
    ${({ theme }) => theme.colors.background.secondary.A},
    ${({ theme }) => theme.colors.background.secondary.A}
  );

  /* Avoid the control from being covered by the canvas loading */
  z-index: 1;
`

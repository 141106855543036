import React, { FC, useEffect, useRef } from 'react'
import copy from 'copy-to-clipboard'
import { useLocalStorage } from 'react-use'

import { Checkbox, Dropdown, useResponsiveDropdown } from '@sketch/components'
import { useToast } from '@sketch/toasts'
import { localStorageKeys } from '@sketch/constants'

import {
  CheckboxWrapper,
  DropdownButtonEllipsis,
  DropdownButton,
} from './PrototypeCardDropdown.styles'
import { PrototypeResizeMode } from '@sketch-hq/sketch-web-renderer'
import { useSyncedUrlPrototypeResizeMode } from 'modules/prototype/hooks'

const DEFAULT_INITIAL_STATE = {
  showHotspots: true,
  hideNavigation: false,
}

export interface PrototypeDropdownProps {
  pathname: string
}

const CheckboxClickPreventer = (
  props: React.ComponentProps<typeof Checkbox> & {
    onChange: (event: HTMLElementEventMap['change']) => void
  }
) => {
  const wrapper = useRef<HTMLInputElement>(null)
  const { onChange } = props

  /**
   * We need to do use native events because
   * the useResponseDropdown event to close the dropdown when clicked
   * is mounted on the dom element and not using the react onClick APIs
   *
   * This causes some conflict as react event callbacks are run after the
   * DOM elements have already been called. By mounting the event on DOM using the "addEventListener"
   * we are safeguarding this
   */
  useEffect(() => {
    const wrapperRef = wrapper.current

    const handleClick = (event: Event) => {
      event.stopPropagation()
    }

    const handleChange = (event: HTMLElementEventMap['change']) => {
      onChange?.(event)
    }

    wrapperRef?.addEventListener('change', handleChange)
    wrapperRef?.addEventListener('click', handleClick)

    return () => {
      wrapperRef?.removeEventListener('change', handleChange)
      wrapperRef?.removeEventListener('click', handleClick)
    }
  }, [onChange])

  return (
    <div ref={wrapper}>
      <CheckboxWrapper>
        <Checkbox {...props} />
      </CheckboxWrapper>
    </div>
  )
}

export const PrototypeDropdown = (props: PrototypeDropdownProps) => {
  const { pathname } = props
  const { showToast } = useToast()
  const { resizeMode } = useSyncedUrlPrototypeResizeMode()

  const [storedValues, setStoredValues] = useLocalStorage(
    localStorageKeys.prototypeSharingSettings,
    DEFAULT_INITIAL_STATE
  )

  const values = storedValues || DEFAULT_INITIAL_STATE
  const { hideNavigation, showHotspots } = values

  const copyLink = (
    showHotspots: boolean,
    hideNavigation: boolean,
    event: React.SyntheticEvent<Element, Event>
  ) => {
    const queryString = getQueryString(showHotspots, hideNavigation, resizeMode)

    const link = `${window.location.origin}${pathname}${queryString}`
    copy(link)
    showToast('Prototype link copied', 'positive')
  }

  return (
    <>
      <Dropdown.Header>COPY PROTOTYPE LINK</Dropdown.Header>
      <form>
        <CheckboxClickPreventer
          label="Show Hotspots"
          onChange={event => {
            if (!(event.target instanceof HTMLInputElement)) {
              return
            }

            setStoredValues({
              ...values,
              showHotspots: event.target.checked,
            })
          }}
          checked={showHotspots}
        />

        <CheckboxClickPreventer
          label="Show Toolbar and Navigation"
          onChange={event => {
            if (!(event.target instanceof HTMLInputElement)) {
              return
            }

            setStoredValues({
              ...values,
              hideNavigation: !event.target.checked,
            })
          }}
          checked={!hideNavigation}
        />
      </form>

      <Dropdown.Divider />
      <Dropdown.Item
        onClick={event => copyLink(showHotspots, hideNavigation, event)}
      >
        Copy Link
      </Dropdown.Item>
    </>
  )
}

const PrototypeCardDropdown: FC<PrototypeDropdownProps> = ({ pathname }) => {
  const [dropdown, buttonProps] = useResponsiveDropdown({
    dropdown: PrototypeDropdown,
    dropdownProps: { pathname },
  })

  return (
    <>
      <DropdownButton {...buttonProps}>
        <DropdownButtonEllipsis aria-label="Prototype sharing" />
        <span className="sr-only">Prototype sharing</span>
      </DropdownButton>

      {dropdown}
    </>
  )
}

function getQueryString(
  hotspots: boolean,
  fullscreen: boolean,
  resizeMode: PrototypeResizeMode
) {
  const params = []

  if (hotspots === false) {
    params.push('hotspots=false')
  }

  if (fullscreen) {
    params.push('fullscreen=true')
  }

  if (resizeMode !== PrototypeResizeMode.Fit) {
    params.push(`resizeMode=${resizeMode}`)
  }

  return params.length ? `?${params.join('&')}` : ''
}

export default PrototypeCardDropdown

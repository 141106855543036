import { jsx } from 'react/jsx-runtime';
import { ReactComponent } from '@sketch/icons/cross-20';
import { Navbar } from '@sketch/components';
import { SidebarLoadingPage } from './SidebarLoadingPage.js';

const CrossButton = () => jsx(Navbar.Button, { icon: ReactComponent });
const ArtboardDetailsLoadingPage = () => {
    return jsx(SidebarLoadingPage, { logo: CrossButton, footer: null });
};

export { ArtboardDetailsLoadingPage };

import React, { useCallback, useMemo, useState } from 'react'
import {
  Button,
  Dropdown,
  Modal,
  ModalInjectedProps,
  Tooltip,
} from '@sketch/components'

import * as S from './ExportModal.styles'
import { useExportPreview } from './useExportPreview'
import { ExportSettings } from '@sketch-hq/sketch-web-renderer'
import { v1 as uuid } from 'uuid'
import { ExportPrefixSuffixSettings, ExportSettingsItem } from './types'
import { ExportItem } from './ExportItem'
import {
  ANDROID_EXPORT_SETTINGS_PRESET,
  DEFAULT_EXPORT_SETTINGS,
  IOS_EXPORT_SETTINGS_PRESET,
} from './constants'

interface ExportModalProps extends ModalInjectedProps {
  exportAndDownload: (exportList: ExportSettingsItem[]) => Promise<void>
  createPreviewUrl: () => Promise<string | null>
}

function createNewExportSettingsItem() {
  const prefixSuffix: ExportPrefixSuffixSettings = {
    type: 'suffix',
    content: '',
  }
  return {
    ...DEFAULT_EXPORT_SETTINGS,
    id: uuid(),
    prefixSuffix,
  }
}

type ExportsPreset = 'IOS' | 'ANDROID'
const presetMap: Record<ExportsPreset, ExportSettingsItem[]> = {
  IOS: IOS_EXPORT_SETTINGS_PRESET,
  ANDROID: ANDROID_EXPORT_SETTINGS_PRESET,
}
function createNewExportSettingsListFromPreset(
  preset: ExportsPreset
): ExportSettingsItem[] {
  // Doing a deep copy to avoid updating the preset.
  return presetMap[preset].map(item => JSON.parse(JSON.stringify(item)))
}

export function ExportModal({
  hideModal,
  exportAndDownload,
  createPreviewUrl,
}: ExportModalProps) {
  const [isExporting, setIsExporting] = useState(false)
  const [exportList, setExportList] = useState<ExportSettingsItem[]>([
    createNewExportSettingsItem(),
  ])

  const previewUrl = useExportPreview(createPreviewUrl)

  const handleExport = useCallback(() => {
    if (exportList.length) {
      setIsExporting(true)
      // setTimeout used as a hack to show a loading state even
      // though UI is frozen.
      setTimeout(async () => {
        await exportAndDownload(exportList)
        setIsExporting(false)
        hideModal()
      }, 10)
    }
  }, [exportAndDownload, exportList, hideModal])

  const updateItem = useCallback(
    (id: string, update: Partial<ExportSettings>) => {
      setExportList(prevList => {
        const index = prevList.findIndex(item => item.id === id)
        if (index === -1) {
          return prevList
        }
        const newList = [...prevList]
        const newItem = { ...newList[index], ...update }
        newList[index] = newItem
        return newList
      })
    },
    []
  )

  const addItem = useCallback(() => {
    setExportList(prevList => [...prevList, createNewExportSettingsItem()])
  }, [])

  const removeItem = useCallback((id: string) => {
    setExportList(prevList => {
      return prevList.filter(item => item.id !== id)
    })
  }, [])

  // Get the unique list of file formats we are exporting.
  const formatTagList = useMemo(() => {
    const set = exportList.reduce((acc, item) => {
      acc.add(item.format)
      return acc
    }, new Set<string>())

    return Array.from(set)
  }, [exportList])

  const enableReset = useMemo(() => {
    const isInDefaultState =
      exportList.length === 1 &&
      exportList[0].format === DEFAULT_EXPORT_SETTINGS.format &&
      exportList[0].backingScale === DEFAULT_EXPORT_SETTINGS.backingScale &&
      exportList[0].prefixSuffix.content === ''
    return !isInDefaultState
  }, [exportList])

  return (
    <Modal autoFocusOnOpen={false} onCancel={hideModal}>
      <S.StyledModalHeader as="div">
        <S.FormatTagListContainer>
          {formatTagList.map(format => {
            return (
              <S.StyledPill variant="black" key={format}>
                {format.toUpperCase()}
              </S.StyledPill>
            )
          })}
        </S.FormatTagListContainer>

        <S.PreviewContainer>
          {!previewUrl && <S.PreviewSkeleton />}
          {previewUrl && (
            <S.PreviewImage
              src={previewUrl}
              // The preview is generated at the scale devicePixelRatio.
              srcSet={`${previewUrl} ${window.devicePixelRatio}x`}
              alt="preview"
            />
          )}
        </S.PreviewContainer>
        <S.ExportModalSubHeader>
          <span>Export Formats</span>
          <S.HeaderActionsContainer>
            <Tooltip placement="top" content="Reset">
              <Button
                variant="ghost"
                disabled={!enableReset}
                onClick={() => setExportList([createNewExportSettingsItem()])}
              >
                <S.StyledArrowCircleIcon />
              </Button>
            </Tooltip>
            <Dropdown
              usePortal={true}
              placement="bottom-start"
              minWidth={'auto'}
              toggle={
                <Tooltip placement="top" content="Export presets">
                  <Button variant="ghost">
                    <S.StyledControlSwitchesIcon />
                  </Button>
                </Tooltip>
              }
            >
              <Dropdown.Item
                minWidth={'auto'}
                onClick={() =>
                  setExportList(createNewExportSettingsListFromPreset('IOS'))
                }
              >
                iOS
              </Dropdown.Item>
              <Dropdown.Item
                minWidth={'auto'}
                onClick={() =>
                  setExportList(
                    createNewExportSettingsListFromPreset('ANDROID')
                  )
                }
              >
                Android
              </Dropdown.Item>
            </Dropdown>
            <Tooltip placement="top" content="Add new Export option">
              <Button variant="ghost" onClick={addItem}>
                <S.StyledPlusIcon />
              </Button>
            </Tooltip>
          </S.HeaderActionsContainer>
        </S.ExportModalSubHeader>
      </S.StyledModalHeader>

      <S.StyledModalBody>
        <S.ExportItemsListContainer>
          {exportList.map(exportSetting => {
            return (
              <ExportItem
                key={exportSetting.id}
                updateItem={updateItem}
                exportSettingsItem={exportSetting}
                removeItem={removeItem}
                allowRemove={exportList.length > 1}
              />
            )
          })}
        </S.ExportItemsListContainer>
      </S.StyledModalBody>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal}>
          Cancel
        </Button>
        <Button loading={isExporting} variant="primary" onClick={handleExport}>
          Export
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import { useGetProjectsQuery } from '@sketch/gql-types';

/**
 * Hook to know if the workspace has a drafts project or not.
 * (Guest don't have any Drafts folder in the workspace by default)
 */
function useGetHasDraftsProject(workspaceId) {
    var _a;
    const workspaceResult = useGetProjectsQuery({
        variables: { workspaceId },
    });
    const { data: workspaceData } = workspaceResult;
    const hasDraftsProject = Boolean((_a = workspaceData === null || workspaceData === void 0 ? void 0 : workspaceData.workspace.draftsProject) === null || _a === void 0 ? void 0 : _a.entries[0]);
    return hasDraftsProject;
}

export { useGetHasDraftsProject };

import React, { useEffect } from 'react'
import { RouteComponentProps, Redirect } from 'react-router-dom'

import {
  GenericErrorView,
  routes,
  RouteParams,
  useFlag,
} from '@sketch/modules-common'

import { WelcomeModalTrigger } from 'modules/workspace/components'
import { LoadingState } from '@sketch/components'
import { DiscoverNavBar, useDiscover } from '@sketch/discover'

import {
  useGetWorkspaceBillingQuery,
  useGetDiscoverQuery,
} from '@sketch/gql-types'
import { WorkspaceSidebarLayoutExtraProps } from 'modules/workspace/components/WorkspaceSidebarLayout/WorkspaceSidebarLayout'

import { Iframe as IframeNew, IframeOld } from './DiscoverView.styles'

export type DiscoverViewRoutesProps = RouteComponentProps<
  RouteParams<'WORKSPACE_DISCOVER'>
>

type DiscoverViewProps = DiscoverViewRoutesProps &
  WorkspaceSidebarLayoutExtraProps

/**
 * Discover View
 *
 * Renders the view for the Discover page
 * Route: /workspace/{workspaceId}/discover
 */
const DiscoverView = ({
  workspace,
  NavbarPortal,
  useOverrideLayoutProps,
}: DiscoverViewProps) => {
  const isRefreshedUi = useFlag('ui-refresh-24')

  useOverrideLayoutProps({
    title: 'Discover',
  })

  // Sets discover content hash to local storage
  const { setViewedDiscover } = useDiscover()

  useEffect(() => {
    setViewedDiscover()
  }, [setViewedDiscover])

  const {
    data: discoverData,
    loading: discoverLoading,
    error: discoverError,
  } = useGetDiscoverQuery()

  const billingQuery = useGetWorkspaceBillingQuery({
    variables: { identifier: workspace.identifier },
    skip: !workspace?.customer?.identifier,
  })

  if (discoverLoading) {
    return <LoadingState />
  }

  if (discoverError || !discoverData) {
    return <GenericErrorView isInLayout />
  }

  const isFinance = workspace!.userRole === 'FINANCE'

  if (isFinance) {
    return <Redirect to={routes.ENTRY.create({})} />
  }

  const subscription = billingQuery.data?.workspace?.customer?.billing

  const Iframe = isRefreshedUi ? IframeNew : IframeOld

  return (
    <>
      <NavbarPortal>
        <DiscoverNavBar />
      </NavbarPortal>

      <Iframe title="Sketch Discover" src="https://sketch.com/discover/" />

      {subscription && (
        <WelcomeModalTrigger
          subscriptionDetails={subscription}
          workspace={workspace}
        />
      )}
    </>
  )
}

export default DiscoverView

import { jsx } from 'react/jsx-runtime';
import { useState } from 'react';
import { Section, Checkbox } from '@sketch/components';
import { useToast } from '@sketch/toasts';
import { useUserUpdateMutation } from '@sketch/gql-types';

const EmailNotificationsPanel = ({ receiveEmailNotifications, }) => {
    const { showToast } = useToast();
    const [shouldReceiveEmailNotifications, setShouldReceiveEmailNotifications,] = useState(receiveEmailNotifications);
    const [updateUser, { data, loading, error }] = useUserUpdateMutation({
        redirectErrors: false,
        onCompleted: data => {
            const { errors } = data.userUpdate || {};
            if ((errors === null || errors === void 0 ? void 0 : errors.length) > 0) {
                const errorMessage = errors.map(error => error === null || error === void 0 ? void 0 : error.message).join('; ');
                showToast(errorMessage, 'negative');
            }
        },
        onError: error => {
            // We revert the checked state
            setShouldReceiveEmailNotifications(!shouldReceiveEmailNotifications);
            showToast(error.message, 'negative');
        },
    });
    return (jsx(Section, Object.assign({ title: "Email Notifications" }, { children: jsx(Checkbox, { value: `${shouldReceiveEmailNotifications}`, name: "receiveCommentsNotifications", checked: shouldReceiveEmailNotifications, loading: loading, success: !!data && !error, label: "Comments", onChange: () => {
                setShouldReceiveEmailNotifications(!shouldReceiveEmailNotifications);
                updateUser({
                    variables: {
                        input: {
                            receiveEmailNotifications: !shouldReceiveEmailNotifications,
                        },
                    },
                });
            }, help: "Any comments made in documents that you follow" }) })));
};

export { EmailNotificationsPanel };

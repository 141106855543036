import styled from 'styled-components'

import { Zoom } from 'components/Zoom'
import { Text, Filter } from '@sketch/components'
import { AddAnnotationButton } from 'modules/annotations/components'
import { CancelButton } from 'modules/annotations/components/AddAnnotationButton/AddAnnotationButton.styles'

export const Wrapper = styled.aside`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;

  background: ${({ theme }) => theme.colors.background.secondary.B};
  border-top: 1px solid ${({ theme }) => theme.colors.border.A};
  padding: 8px;

  position: sticky;
  bottom: 0px;
  left: 0;

  ${Zoom} {
    margin-left: auto;
    flex: 0;
    flex-shrink: 0;
  }

  ${Filter} + ${Zoom} {
    margin-left: 8px;
  }

  ${AddAnnotationButton}, ${CancelButton} {
    margin-left: 8px;
  }
`

export const TextToolbar = styled(Text.Span).attrs({
  textStyle: 'copy.primary.standard.D',
})`
  font-weight: 500;
`

import React from 'react'
import { useLocation, Redirect } from 'react-router'

import { routes } from '@sketch/modules-common'

import LoadingState from 'modules/shares/components/LoadingState'

import ProjectShares, {
  ProjectSharesProps,
} from '../../components/ProjectShares'
import { useGetProjects } from '../../operations'
import { WorkspaceMinimalFragment } from '@sketch/gql-types'

interface ProjectsViewProps extends OmitSafe<ProjectSharesProps, 'projectId'> {
  workspace: WorkspaceMinimalFragment
  projectId?: string
  userIsEditor: boolean
  userCanRenameProject: boolean
  userCanManageCollection: boolean
  setProjectIdentifier?: (projectId: string) => void
}

/**
 * ProjectsView
 *
 * This view tries to determine if the project is a draft project or a normal project.
 * and renders the ProjectShares for the appropriate project.
 */
const ProjectsView = ({
  workspace,
  projectId: projectIdFromParams,
  userIsEditor,
  userCanRenameProject,
  userCanManageCollection,
  setProjectIdentifier,
}: ProjectsViewProps) => {
  const location = useLocation()
  const { identifier: workspaceId } = workspace

  const { draftsProject, loading } = useGetProjects({ workspaceId })

  if (loading) {
    return <LoadingState />
  }

  const draftProjectId = draftsProject?.entries?.[0]?.identifier
  const draftsRoute = routes.WORKSPACE_DRAFTS.create({ workspaceId })

  const isDraftsRoute = location.pathname === draftsRoute
  const isDraftRouteUsingProjectId =
    draftsProject && draftProjectId === projectIdFromParams

  if (isDraftRouteUsingProjectId) {
    // The draft project can also be accessed using the URL format of a normal project.
    // This is accepted but we need to redirect to the proper draft route for consistency
    //  but also so that the "Drafts" menu NavLink can set the active state.
    // i.e.: If project id "HELLO" is a draft project: workspace/ID/p/HELLO -> workspace/ID/drafts
    return <Redirect to={routes.WORKSPACE_DRAFTS.create({ workspaceId })} />
  }

  const projectId = isDraftsRoute ? draftProjectId! : projectIdFromParams!

  if (projectId !== projectIdFromParams) {
    // If the projectId has changed, call `setProjectIdentifier` so that
    // subscriptions are updated.
    setProjectIdentifier?.(projectId)
  }

  return (
    <ProjectShares
      workspace={workspace}
      projectId={projectId}
      userIsEditor={userIsEditor}
      userCanRenameProject={userCanRenameProject}
      userCanManageCollection={userCanManageCollection}
    />
  )
}

export default ProjectsView

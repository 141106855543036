import styled, { css } from 'styled-components'
import { Label } from '../../Attribute'
import { VersionLink } from 'modules/versioning'
import {
  Text,
  Popover,
  truncateStyles,
  Link,
  Skeleton,
} from '@sketch/components'
import { ReactComponent as ChevronRight } from '@sketch/icons/chevron-right-16'

export const ClickableArea = styled(Label)`
  cursor: pointer;
  min-height: auto;
`

export const Trigger = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.border.A};
  border-radius: ${({ theme }) => theme.radii.medium};
  line-height: 1;
  box-sizing: border-box;
  cursor: pointer;
  max-width: 100%;
  padding: 8px;
`

export const ColorInfo = styled.div`
  ${truncateStyles};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1;
`

export const ColorName = styled(Text.Div).attrs({
  textStyle: 'copy.primary.standard.D',
})`
  ${truncateStyles};
  word-break: break-all;
`

export const ColorNameInPopover = styled(ColorName)`
  font-weight: 500;
`

export const ColorPath = styled.div(
  ({ theme }) => css`
    ${truncateStyles};

    margin-top: 4px;
    word-break: break-all;
    color: ${theme.colors.foreground.secondary.D};
    font-size: ${theme.fontSizes.B};
  `
)

export const ColorPathInPopover = styled(ColorPath)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.C};
  `
)

export const PopupVariableInfo = styled.div`
  display: flex;
  padding: 16px;
  width: 300px;
`

export const ColorAttributeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 300px;
  border-top: 1px solid ${({ theme }) => theme.colors.border.B};
  padding: 12px 16px;

  & > div:first-child {
    flex: 1;
    min-width: 0;
  }
`

const linkStyles = css`
  flex: auto;
  min-width: 0;

  display: flex;
  align-items: center;

  font-weight: 400;
`

export const LinkStyled = styled(Link)`
  ${linkStyles}
`

export const VersionLinkStyled = styled(VersionLink)`
  ${linkStyles}
`

export const IconWrapper = styled.div(
  ({ theme }) => css`
    flex: none;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 36px;
    height: 36px;
    border-radius: 6px;
    margin-right: 8px;
    background: ${theme.colors.background.tertiary.C};

    svg {
      width: 16px;
      color: ${theme.colors.foreground.secondary.D};
    }
  `
)

// We want to do this as it will adjust to the width of the space calculated
// by it's parent with flex: 2
export const StyledPopover = styled(Popover)`
  width: 100%;
`

export const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 40px;
`

export const Chevron = styled(ChevronRight)`
  flex: none;
  height: 16px;
  margin-left: auto;
  padding-left: 8px;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`

import { __rest } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import { forwardRef, useState } from 'react';
import styled from 'styled-components';
import '../Box/index.js';
import '../Input/index.js';
import { ToggleShowPasswordButton } from './ToggleShowPasswordButton.js';
import { Input } from '../Input/Input.js';
import { Flex } from '../Box/BoxSystem.js';

const StyledPasswordInput = styled(Input) `
  /*
    This overrides the right padding in order to have no text
    under the button to toggle the visibility.
  */
  padding-right: 48px !important; /* stylelint-disable-line declaration-no-important */
`;
/* eslint-disable-next-line react/display-name */
const PasswordInput = forwardRef((_a, ref) => {
    var props = __rest(_a, []);
    const [showPassword, setShowPassword] = useState(false);
    return (jsxs(Flex, Object.assign({ position: "relative" }, { children: [jsx(StyledPasswordInput, Object.assign({ type: showPassword ? 'text' : 'password', id: "password", name: "password", ref: ref }, props)), jsx(ToggleShowPasswordButton, { showPassword: showPassword, onClick: () => setShowPassword(value => !value), disabled: props.disabled })] })));
});

export { PasswordInput };

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { Button } from './Button.js';

const AnchorButton = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (jsx(Button, Object.assign({}, props, { as: "a" }, { children: children })));
};

export { AnchorButton };

/**
 * @file The values exposed of all the enums need to be manually converted to our own enum values
 * and that's what is done in this file.
 */

import {
  PRMarinaBlendMode,
  PRMarinaLayerBlurType,
  PRMarinaLayerBorderPosition,
  PRMarinaLayerFillType,
  PRMarinaLayerGradientType,
  PRMarinaLayerHorizontalTextAlignment,
  PRMarinaLayerLineCap,
  PRMarinaLayerLineJoin,
  PRMarinaLayerTextDecoration,
  PRMarinaLayerTextTransform,
  PRMarinaLayerVerticalTextAlignment,
  PRMarinaExportFormatType,
  PRMarinaLayerType,
  PRMarinaLayerCornerStyle,
  PRMarinaVisibleScaleType,
  PRMarinaLayerImageFillType,
} from '@sketch-hq/sketch-web-renderer'
import {
  BlendMode,
  BlurType,
  BorderPosition,
  FillType,
  GradientType,
  LineCapStyle,
  LineJoinStyle,
  VerticalTextAlignment,
  HorizontalTextAlignment,
  TextDecoration,
  TextTransform,
  ExportFormatType,
  ElementType,
  CornerStyle,
  VisibleScaleType,
  ImageFillType,
} from 'modules/inspector'

export function mapBlur(value: PRMarinaLayerBlurType): BlurType {
  switch (value) {
    case PRMarinaLayerBlurType.Motion:
      return BlurType.Motion
    case PRMarinaLayerBlurType.Zoom:
      return BlurType.Zoom
    case PRMarinaLayerBlurType.Background:
      return BlurType.Background
    case PRMarinaLayerBlurType.Gaussian:
      return BlurType.Gaussian
  }
}

export function mapFillType(value: PRMarinaLayerFillType): FillType {
  switch (value) {
    case PRMarinaLayerFillType.Color:
      return FillType.Color
    case PRMarinaLayerFillType.Gradient:
      return FillType.Gradient
    case PRMarinaLayerFillType.Pattern:
      return FillType.Pattern
  }
}

export function mapImageFillType(value: PRMarinaLayerImageFillType) {
  switch (value) {
    case PRMarinaLayerImageFillType.Stretch:
      return ImageFillType.Stretch
    case PRMarinaLayerImageFillType.Tile:
      return ImageFillType.Tile
    case PRMarinaLayerImageFillType.Fit:
      return ImageFillType.Fit
    case PRMarinaLayerImageFillType.Fill:
      return ImageFillType.Fill
  }
}

export function mapBlendMode(value: PRMarinaBlendMode): BlendMode {
  switch (value) {
    case PRMarinaBlendMode.Normal:
      return BlendMode.Normal
    case PRMarinaBlendMode.DestAtop:
      return BlendMode.DestAtop
    case PRMarinaBlendMode.Clear:
      return BlendMode.Clear
    case PRMarinaBlendMode.Source:
      return BlendMode.Source
    case PRMarinaBlendMode.Darken:
      return BlendMode.Darken
    case PRMarinaBlendMode.Multiply:
      return BlendMode.Multiply
    case PRMarinaBlendMode.ColorBurn:
      return BlendMode.ColorBurn
    case PRMarinaBlendMode.Lighten:
      return BlendMode.Lighten
    case PRMarinaBlendMode.Screen:
      return BlendMode.Screen
    case PRMarinaBlendMode.ColorDodge:
      return BlendMode.ColorDodge
    case PRMarinaBlendMode.Overlay:
      return BlendMode.Overlay
    case PRMarinaBlendMode.SoftLight:
      return BlendMode.SoftLight
    case PRMarinaBlendMode.HardLight:
      return BlendMode.HardLight
    case PRMarinaBlendMode.Difference:
      return BlendMode.Difference
    case PRMarinaBlendMode.Exclusion:
      return BlendMode.Exclusion
    case PRMarinaBlendMode.Hue:
      return BlendMode.Hue
    case PRMarinaBlendMode.Saturation:
      return BlendMode.Saturation
    case PRMarinaBlendMode.Color:
      return BlendMode.Color
    case PRMarinaBlendMode.Luminosity:
      return BlendMode.Luminosity
    case PRMarinaBlendMode.PlusLighter:
      return BlendMode.PlusLighter
    case PRMarinaBlendMode.PlusDarker:
      return BlendMode.PlusDarker
  }
}

export function mapBorderPosition(
  value: PRMarinaLayerBorderPosition
): BorderPosition {
  switch (value) {
    case PRMarinaLayerBorderPosition.Center:
      return BorderPosition.Center
    case PRMarinaLayerBorderPosition.Inside:
      return BorderPosition.Inside
    case PRMarinaLayerBorderPosition.Outside:
      return BorderPosition.Outside
  }
}

export function mapLineCapStyle(value: PRMarinaLayerLineCap): LineCapStyle {
  switch (value) {
    case PRMarinaLayerLineCap.Butt:
      return LineCapStyle.Butt
    case PRMarinaLayerLineCap.Round:
      return LineCapStyle.Round
    case PRMarinaLayerLineCap.Square:
      return LineCapStyle.Square
  }
}

export function mapLineJoinStyle(value: PRMarinaLayerLineJoin): LineJoinStyle {
  switch (value) {
    case PRMarinaLayerLineJoin.Miter:
      return LineJoinStyle.Miter
    case PRMarinaLayerLineJoin.Round:
      return LineJoinStyle.Round
    case PRMarinaLayerLineJoin.Bevel:
      return LineJoinStyle.Bevel
  }
}

export function mapGradientType(
  value: PRMarinaLayerGradientType
): GradientType {
  switch (value) {
    case PRMarinaLayerGradientType.Linear:
      return GradientType.Linear
    case PRMarinaLayerGradientType.Radial:
      return GradientType.Radial
    case PRMarinaLayerGradientType.Angular:
      return GradientType.Angular
  }
}

export function mapVerticalTextAlignment(
  value: PRMarinaLayerVerticalTextAlignment
): VerticalTextAlignment {
  switch (value) {
    case PRMarinaLayerVerticalTextAlignment.Top:
      return VerticalTextAlignment.Top
    case PRMarinaLayerVerticalTextAlignment.Middle:
      return VerticalTextAlignment.Middle
    case PRMarinaLayerVerticalTextAlignment.Bottom:
      return VerticalTextAlignment.Bottom
  }
}

export function mapHorizontalTextAlignment(
  value: PRMarinaLayerHorizontalTextAlignment
): HorizontalTextAlignment {
  switch (value) {
    case PRMarinaLayerHorizontalTextAlignment.Left:
      return HorizontalTextAlignment.Left
    case PRMarinaLayerHorizontalTextAlignment.Right:
      return HorizontalTextAlignment.Right
    case PRMarinaLayerHorizontalTextAlignment.Center:
      return HorizontalTextAlignment.Center
    case PRMarinaLayerHorizontalTextAlignment.Justified:
      return HorizontalTextAlignment.Justified
  }
}

export function mapTextTransform(
  value: PRMarinaLayerTextTransform
): TextTransform | undefined {
  switch (value) {
    case PRMarinaLayerTextTransform.None:
      return undefined
    case PRMarinaLayerTextTransform.Uppercase:
      return TextTransform.Uppercase
    case PRMarinaLayerTextTransform.Lowercase:
      return TextTransform.Lowercase
  }
}

export function mapTextDecoration(
  value: PRMarinaLayerTextDecoration
): TextDecoration | undefined {
  switch (value) {
    case PRMarinaLayerTextDecoration.None:
      return undefined
    case PRMarinaLayerTextDecoration.Underline:
      return TextDecoration.Underline
    case PRMarinaLayerTextDecoration.LineThrough:
      return TextDecoration.LineThrough
  }
}

export function mapLayerType(value: PRMarinaLayerType): ElementType {
  switch (value) {
    case PRMarinaLayerType.Artboard:
      return ElementType.Artboard
    case PRMarinaLayerType.SymbolMaster:
      return ElementType.SymbolMaster
    case PRMarinaLayerType.SymbolInstance:
      return ElementType.SymbolInstance
    case PRMarinaLayerType.Hotspot:
      return ElementType.Hotspot
    case PRMarinaLayerType.Bitmap:
      return ElementType.Bitmap
    case PRMarinaLayerType.Group:
      return ElementType.Group
    case PRMarinaLayerType.Oval:
      return ElementType.Oval
    case PRMarinaLayerType.Polygon:
      return ElementType.Polygon
    case PRMarinaLayerType.Rectangle:
      return ElementType.Rectangle
    case PRMarinaLayerType.ShapeGroup:
      return ElementType.ShapeGroup
    case PRMarinaLayerType.ShapePath:
      return ElementType.ShapePath
    case PRMarinaLayerType.Star:
      return ElementType.Star
    case PRMarinaLayerType.Text:
      return ElementType.Text
    case PRMarinaLayerType.Triangle:
      return ElementType.Triangle
    case PRMarinaLayerType.Slice:
      return ElementType.Slice
  }
}

export function mapExportFormatType(
  value: PRMarinaExportFormatType
): ExportFormatType {
  switch (value) {
    // None is theoretically possible according to the types, but in practice
    // we should never see it in the data
    case PRMarinaExportFormatType.None:
    case PRMarinaExportFormatType.PNG:
      return ExportFormatType.PNG
    case PRMarinaExportFormatType.JPG:
      return ExportFormatType.JPG
    case PRMarinaExportFormatType.TIFF:
      return ExportFormatType.TIFF
    case PRMarinaExportFormatType.WEBP:
      return ExportFormatType.WEBP
    case PRMarinaExportFormatType.PDF:
      return ExportFormatType.PDF
    case PRMarinaExportFormatType.EPS:
      return ExportFormatType.EPS
    case PRMarinaExportFormatType.SVG:
      return ExportFormatType.SVG
    case PRMarinaExportFormatType.HEIC:
      return ExportFormatType.HEIC
  }
}

export function mapCornerStyle(value: PRMarinaLayerCornerStyle): CornerStyle {
  switch (value) {
    case PRMarinaLayerCornerStyle.Rounded:
      return CornerStyle.Rounded
    case PRMarinaLayerCornerStyle.Smooth:
      return CornerStyle.Smooth
    case PRMarinaLayerCornerStyle.Angled:
      return CornerStyle.Angled
    case PRMarinaLayerCornerStyle.InsideSquare:
      return CornerStyle.InsideSquare
    case PRMarinaLayerCornerStyle.InsideArc:
      return CornerStyle.InsideArc
  }
}

export function mapVisibleScaleType(
  value: PRMarinaVisibleScaleType
): VisibleScaleType {
  switch (value) {
    // None is theoretically possible according to the types, but in practice
    // we should never see it in the data
    case PRMarinaVisibleScaleType.None:
    case PRMarinaVisibleScaleType.Scale:
      return VisibleScaleType.Scale
    case PRMarinaVisibleScaleType.Width:
      return VisibleScaleType.Width
    case PRMarinaVisibleScaleType.Height:
      return VisibleScaleType.Height
  }
}

import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

// We trigger the inView 100px below the fold
// so the image starts loading a bit sooner
const DEFAULT_ROOT_MARGIN = '0px 0px 100px 0px';
const usePreloadImage = ({ srcSet, rootMargin = DEFAULT_ROOT_MARGIN, }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin,
    });
    // Pre-load the image when they are just below the fold
    // This avoids loading unnecessary data
    const shouldLoadImage = srcSet && inView && !isLoaded;
    useEffect(() => {
        if (shouldLoadImage) {
            const preLoadImage = new Image();
            preLoadImage.srcset = srcSet;
            preLoadImage.onload = () => {
                setIsLoaded(true);
            };
        }
    }, [shouldLoadImage, srcSet]);
    return {
        ref,
        isLoaded,
    };
};

export { usePreloadImage };

import React from 'react'

import { ReactComponent as ImagePlaceholder } from '@sketch/icons/document-64'

import { LoadingPlaceholder } from '@sketch/components'

import { getThumbnail } from 'modules/shares/ComponentsWebView/utils'

import { ImageElement } from 'modules/shares/components/DocumentItem/DocumentItem.styles'
import {
  StyledStatusIcon,
  Thumbnail,
  ThumbnailWrapper,
  NoThumbnailWrapper,
} from './CollectionItem.styles'

import { CollectionItemProps } from './types'
import { ShareThumbnailsFragment } from '@sketch/gql-types'

type PreviewThumbnail = NonNullable<
  NonNullable<ShareThumbnailsFragment['thumbnails']>[0]
>

export const renderPreview = (
  props: CollectionItemProps,
  layout: 'grid' | 'list'
) => {
  const { collection } = props

  // Only show the first 4 previews
  const previews = collection.previews.slice(0, 4)

  return (
    <ThumbnailWrapper aria-label={`Collection ${collection.name}`}>
      {previews.map(({ name, file }, index) => {
        const thumbnails = (file?.thumbnails || []).filter(
          thumb => !!thumb
        ) as PreviewThumbnail[]

        if (thumbnails.length === 0) {
          return (
            <NoThumbnailWrapper data-testid="collection-no-thumb" key={index}>
              <StyledStatusIcon
                key={index}
                as={ImagePlaceholder}
                aria-label="No Image available"
              />
            </NoThumbnailWrapper>
          )
        } else {
          return (
            <Thumbnail
              key={index}
              alt={name}
              customImageElement={ImageElement}
              src={getThumbnail(thumbnails)}
              loadingPlaceholder={<LoadingPlaceholder size="16px" />}
            />
          )
        }
      })}
    </ThumbnailWrapper>
  )
}

import { __awaiter, __rest } from 'tslib';
import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { SKETCH_WEBSITE } from '@sketch/env-config';
import { passwordStrengthValidation, Checkbox, Form, Input, CreatePasswordInput, Link, Button } from '@sketch/components';
import '../../libs/authentication/index.js';
import '../../analytics/index.js';
import { useTrackEditedFields } from './useTrackEditedFields.js';
import { TERMS_OF_SERVICE_PRIVACY_POLICY_ERROR, SIGN_UP_INITIAL_VALUES } from './constants.js';
import { useSignUpMetadata } from './useSignUpMetadata.js';
import { useSignUpMutation, GetInitialUserDocument } from '@sketch/gql-types';
import { useAnalytics } from '../../analytics/analytics.context.js';
import { setUserAuthorization } from '../../libs/authentication/multisession/multisession.js';
import { createPersonalAuthorization } from '../../libs/authentication/multisession/authorizations.js';

const signUpSchema = yup.object().shape({
    name: yup.string().trim().required('Enter your first name'),
    email: yup
        .string()
        .email('This is not a valid email')
        .required('Enter your email address'),
    password: yup
        .string()
        .min(8, 'Should be 8 or more characters')
        .max(72, 'Should be 72 or less characters')
        .required('Create a new password')
        .test('passwordStrength', 'The password strength should be at least Okay', passwordStrengthValidation),
    'terms-privacy-policy': yup.boolean(),
    'marketing-consent': yup.boolean(),
});
const StyledCheckbox = styled(Checkbox) `
  /*
  Prevent the TOS link from being "blocked" by the input.
  */
  input {
    width: auto;
  }
`;
const Fields = ({ values, errors, touched, handleChange, handleBlur, formContextForAnalytics, }) => {
    useTrackEditedFields(values, formContextForAnalytics);
    const { trackEvent } = useAnalytics();
    return (jsxs(Fragment, { children: [jsx(Form.Field, Object.assign({ name: "name", label: "Your first name", errorText: touched.name ? errors.name : undefined }, { children: jsx(Input, { type: "text", name: "name", value: values.name, onChange: handleChange, onBlur: handleBlur }) })), jsx(Form.Field, Object.assign({ name: "email", label: "Email", errorText: touched.email ? errors.email : undefined }, { children: jsx(Input, { name: "email", type: "email", value: values.email, onChange: handleChange, onBlur: handleBlur, autoFocus: !!values.email }) })), jsx(CreatePasswordInput, { name: "password", value: values.password, onChange: handleChange, onBlur: handleBlur, invalid: touched.password && !!errors.password }), jsx(Form.Field, Object.assign({ name: "terms-privacy-policy", mt: 2, mb: 3 }, { children: jsxs(StyledCheckbox, Object.assign({ name: "terms-privacy-policy", variant: "untinted", checked: Boolean(values['terms-privacy-policy']), onChange: handleChange, onBlur: handleBlur }, { children: ["I\u2019ve read and agree to the", ' ', jsx(Link, Object.assign({ variant: "primary", isUnderlined: true, external: true, href: `${SKETCH_WEBSITE}/tos`, target: "_blank", rel: "noopener noreferrer", onClick: () => {
                                trackEvent('SIGN UP - "Terms of Service" link opened', {
                                    formContext: formContextForAnalytics,
                                });
                            } }, { children: "Terms of Service" })), ' ', "and", ' ', jsx(Link, Object.assign({ variant: "primary", isUnderlined: true, external: true, href: `${SKETCH_WEBSITE}/privacy`, target: "_blank", rel: "noopener noreferrer", onClick: () => {
                                trackEvent('SIGN UP - "Privacy Policy" link opened', {
                                    formContext: formContextForAnalytics,
                                });
                            } }, { children: "Privacy Policy" }))] })) })), jsx(Form.Field, Object.assign({ name: "marketing-consent", mb: 8 }, { children: jsx(StyledCheckbox, Object.assign({ name: "marketing-consent", variant: "untinted", checked: Boolean(values['marketing-consent']), onChange: handleChange, onBlur: handleBlur }, { children: "Get helpful resources, our Sketch newsletter, invites to events and more" })) }))] }));
};
const Errors = ({ apiError, status }) => status || apiError ? (jsx(Form.ErrorField, { children: status || (apiError === null || apiError === void 0 ? void 0 : apiError.message) || '' })) : null;
const DefaultFormFields = formProps => {
    const { trackEvent } = useAnalytics();
    return (jsxs(Fragment, { children: [jsx(Fields, Object.assign({}, formProps)), jsx(Errors, Object.assign({}, formProps)), jsx(Submit, { disabled: !(formProps.isValid && formProps.dirty), callToAction: formProps.callToAction, isSubmitting: formProps.isSubmitting, onClick: () => {
                    const submitCount = formProps.submitCount + 1;
                    trackEvent('SIGN UP - Sign up form submitted', {
                        submitCount: submitCount,
                        formContext: formProps.formContextForAnalytics,
                    });
                } })] }));
};
const Submit = ({ isSubmitting, disabled, onClick, callToAction = 'Sign Up', }) => (jsx(Form.Footer, { children: jsx(Button, Object.assign({ variant: "primary", type: "submit", fill: true, size: "48", disabled: isSubmitting || disabled, loading: isSubmitting, onClick: onClick }, { children: callToAction })) }));
const SignUpForm = ({ email = '', invitedUser, onCompleted, onError, formContextForAnalytics, children = DefaultFormFields, callToAction = 'Sign Up', }) => {
    const { metadata, clearMetadata: cleanSignUpMetadata } = useSignUpMetadata();
    const [signUp, { loading, error: apiError }] = useSignUpMutation({
        redirectErrors: true,
        update: (cache, { data }) => {
            if (!(data === null || data === void 0 ? void 0 : data.signUp)) {
                return;
            }
            /* Save the user credentials in the apollo cache */
            setUserAuthorization(cache, createPersonalAuthorization(data.signUp.credentials));
            /* Save the user profile information */
            cache.writeQuery({
                query: GetInitialUserDocument,
                data: {
                    __typename: 'RootQueryType',
                    me: data.signUp.user,
                },
            });
        },
        onCompleted: () => {
            cleanSignUpMetadata();
            onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted();
        },
        onError: ({ message }) => {
            onError === null || onError === void 0 ? void 0 : onError(message);
        },
    });
    const handleFormSubmit = (values, actions) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!values['terms-privacy-policy']) {
                actions.setStatus(TERMS_OF_SERVICE_PRIVACY_POLICY_ERROR);
                return;
            }
            actions.setStatus();
            const { 'terms-privacy-policy': tosAgreed, 'marketing-consent': marketingConsent } = values, otherValues = __rest(values, ['terms-privacy-policy', 'marketing-consent']);
            yield signUp({
                variables: {
                    input: Object.assign(Object.assign({}, otherValues), { 
                        /**
                         * We have unified both values on the UI
                         * however BE still receives them distinctly
                         *
                         * https://github.com/sketch-hq/Cloud/issues/15774
                         */
                        privacyPolicyAgreed: tosAgreed, tosAgreed,
                        marketingConsent, createWorkspace: !invitedUser, metadata }),
                },
            });
        }
        catch (e) {
            // The error handling should be taken care of by Apollo
        }
        finally {
            actions.setSubmitting(false);
        }
    });
    return (jsx(Fragment, { children: jsx(Formik, Object.assign({ initialValues: Object.assign(Object.assign({}, SIGN_UP_INITIAL_VALUES), { email }), onSubmit: handleFormSubmit, validationSchema: signUpSchema, validateOnBlur: false, enableReinitialize: true }, { children: formikbag => (jsx(Form, { children: children(Object.assign(Object.assign({}, formikbag), { isSubmitting: loading, apiError,
                    callToAction,
                    formContextForAnalytics })) })) })) }));
};

export { DefaultFormFields, Errors, Fields, Submit, SignUpForm as default };

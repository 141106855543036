import { jsxs, jsx } from 'react/jsx-runtime';
import '../Box/index.js';
import { Title, ActionWrapper } from './EmptyState.styles.js';
import { Text } from '../Box/BoxSystem.js';

const EmptyState = (props) => {
    const { className, title, description, actions } = props;
    return (jsxs("div", Object.assign({ className: className }, { children: [jsx(Text, Object.assign({ textStyle: "prominent.primary.F", as: Title }, { children: title })), jsx(Text, Object.assign({ textStyle: "copy.quaternary.standard.E" }, { children: description })), actions && jsx(ActionWrapper, { children: actions })] })));
};

export { EmptyState as default };

import styled from 'styled-components';

const TokenSecret = styled.div `
  max-width: 80%;
  word-wrap: break-word;
  font-family: ${({ theme }) => theme.fonts.monospace};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`;
const CopyTokenContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background.secondary.B};
  padding: 16px;
  margin: 24px 0 16px 0;

  & button {
    border: none;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.sketch.A};
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }
`;
const TokenDetailsRow = styled.div `
  display: flex;
`;
const PermissionsHeading = styled.div `
  padding: 0;
  margin: 24px 0 8px 0;
  font-size: ${({ theme }) => theme.fontSizes.C};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;
const Callout = styled.div `
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.state.neutral.E};
  font-size: ${({ theme }) => theme.fontSizes.D};
  border-radius: ${({ theme }) => theme.radii.large};
  color: hsla(0, 0%, 0%, 0.85);

  & div:first-child {
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    margin-bottom: 4px;

    /* This is a valid use case to not use a design token, because the
    background is always yellow */
    color: #000;
  }
`;

export { Callout, CopyTokenContainer, PermissionsHeading, TokenDetailsRow, TokenSecret };

/**
 * Check for WASM support in the current browser.
 * @see https://stackoverflow.com/questions/47879864/how-can-i-check-if-a-browser-supports-webassembly
 */
const isWASMSupported = () => {
    try {
        const wasmModule = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
        return (wasmModule instanceof WebAssembly.Module &&
            new WebAssembly.Instance(wasmModule) instanceof WebAssembly.Instance);
    }
    catch (e) {
        return false;
    }
};

export { isWASMSupported };

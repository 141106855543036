import { useCallback } from 'react';
import { useGetBrowserSettingsQuery, GetBrowserSettingsDocument } from '@sketch/gql-types';

const useBrowserSettings = () => {
    const { data, client } = useGetBrowserSettingsQuery();
    const updateBrowserSettings = useCallback((values) => {
        client.writeQuery({
            query: GetBrowserSettingsDocument,
            data: Object.assign({ __typename: 'RootQueryType' }, values),
        });
    }, [client]);
    return [data, updateBrowserSettings];
};

export { useBrowserSettings };

import { jsx } from 'react/jsx-runtime';
import classNames from 'classnames';
import styled, { useTheme } from 'styled-components';
import { Input } from '../Input.js';
import { invalidStyles, commonStyles } from './commonStyles.js';
import { inputBaseStyles } from '../Input.styles.js';

const BaseInput = styled(Input) `
  ${inputBaseStyles};
`;
const createInput = (Element) => {
    const BaseElement = styled(Element) `
    flex: 1;
    height: 40px;
    padding-top: 2px; /* stylelint-disable-line scales/space */
  `;
    const StripeInput = (props) => {
        var _a;
        const theme = useTheme();
        return (jsx(BaseInput
        /*
      Stripe sometimes removes the className from the wrapper div.
      Forcing it like this will prevent this behaviour
      */
        , Object.assign({ 
            /*
          Stripe sometimes removes the className from the wrapper div.
          Forcing it like this will prevent this behaviour
          */
            className: classNames('StripeElement', props.className), disabled: (_a = props.disabled) !== null && _a !== void 0 ? _a : false, invalid: props.invalid, as: "div" }, { children: jsx(BaseElement, Object.assign({ options: {
                    style: props.invalid ? invalidStyles(theme) : commonStyles(theme),
                } }, props)) })));
    };
    return StripeInput;
};

export { createInput as default };

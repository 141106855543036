import { jsxs, jsx } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StepperWrapper, StepperButton, StyledMinus, StepperInput, StyledPlus } from './Stepper.styles.js';

const formatValueToPixels = (value) => `${value}`.length * 10;
/**
 * Stepper
 *
 * Renders a number input that allows the user
 * to click a "+" and "-" button to increase or
 * decrease the value
 *
 * Allow for a maximum and minimum based range of values
 */
const StepperUnstyled = ({ className, value, onChange, max = Infinity, min = -Infinity, isDisabled, }) => {
    const [cachedValue, setCachedValue] = useState(value);
    useEffect(() => {
        setCachedValue(() => value);
    }, [value]);
    const setValue = (valueFunc) => {
        setCachedValue(value => {
            const newValue = Math.min(Math.max(valueFunc(value) || 0, min), max);
            onChange(newValue);
            return newValue;
        });
    };
    return (jsxs(StepperWrapper, Object.assign({ className: className }, { children: [jsxs(StepperButton, Object.assign({ disabled: isDisabled || cachedValue === min, onClick: () => setValue(value => value - 1) }, { children: [jsx(StyledMinus, {}), jsx("span", Object.assign({ className: "sr-only" }, { children: "Decrement" }))] })), jsxs("label", { children: [jsx("span", Object.assign({ className: "sr-only" }, { children: "Seats stepper" })), jsx(StepperInput, { name: "extraSeats", type: "number", min: min, max: max, "data-testid": "stepper-input", 
                        /**
                         *  Force the value to be string, so we can have a clear representation
                         *  "01" -> 1 (input value)
                         *  01 -> 01 (input value)
                         */
                        value: `${cachedValue}`, onChange: ({ target }) => {
                            // Make sure there are no left side zeros
                            setValue(() => parseInt(target.value.replace(/^0+/, '')));
                        }, style: { width: formatValueToPixels(cachedValue) }, disabled: isDisabled })] }), jsxs(StepperButton, Object.assign({ disabled: isDisabled || cachedValue === max, onClick: () => setValue(value => value + 1) }, { children: [jsx(StyledPlus, {}), jsx("span", Object.assign({ className: "sr-only" }, { children: "Increment" }))] }))] })));
};
const Stepper = styled(StepperUnstyled) ``;

export { Stepper };

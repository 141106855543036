import { jsxs, jsx } from 'react/jsx-runtime';
import { Modal, Button } from '@sketch/components';
import { noop } from '@sketch/utils';

const SketchLoginDeprecationModal = props => {
    const { hideModal } = props;
    return (jsxs(Modal, Object.assign({ onCancel: noop }, { children: [jsx(Modal.Header, { children: "This login method is no longer supported" }), jsx(Modal.Body, { children: "We\u2019ve updated our login processes to make them more secure. Please update your Mac app to version 53 or higher, in order to sign in with Cloud." }), jsxs(Modal.Footer, { children: [jsx(Button, Object.assign({ onClick: hideModal }, { children: "Cancel" })), jsx(Button, Object.assign({ variant: "primary", onClick: () => {
                            window.open('https://www.sketch.com/updates/');
                            hideModal();
                        } }, { children: "See Mac Updates" }))] })] })));
};

export { SketchLoginDeprecationModal };

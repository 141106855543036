import styled, { css } from 'styled-components';
import '../List/index.js';
import { ReactComponent } from '@sketch/icons/chevron-vertical-16';
import { breakpoint } from '@sketch/global-styles';
import { ListHeader } from '../List/List.styles.js';

const cellHeights = {
    standard: 56,
    large: 64,
};
const tableReset = css `
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: none;
  border-collapse: inherit;
  border-spacing: 0;
  border-color: inherit;
  text-align: left;
  font-weight: inherit;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
`;
const TableHeaderStyle = styled.tr `
  ${tableReset};
  padding-bottom: 8px;
`;
const TableHead = styled.thead `
  ${tableReset};
`;
const TableHeaderCell = styled(ListHeader).attrs({ as: 'th' })(({ $cursor }) => css `
    /* 16px (8px + 8px) because TableHeader don't show margin */
    padding: 8px 12px 16px;
    cursor: ${$cursor ? 'pointer' : 'default'};
  `);
const TableCell = styled.td(({ theme, cellHeight = 'standard' }) => css `
    ${tableReset};
    padding-left: 12px;
    line-height: 1.4;
    height: ${cellHeights[cellHeight]}px;
    font-size: ${theme.fontSizes.D};
    font-weight: ${theme.fontWeights.regular};
    color: ${theme.colors.foreground.secondary.D};
  `);
const TableRow = styled.tr(({ cellHeight = 'standard' }) => css `
    ${tableReset};
    border-radius: ${({ theme }) => theme.radii.large};
    height: ${cellHeights[cellHeight]}px;

    /* Animate the background change */
    transition: background 250ms ease-in-out;

    ${ /* sc-selector */TableCell}:nth-child(1) {
      border-top-left-radius: ${({ theme }) => theme.radii.large};
      border-bottom-left-radius: ${({ theme }) => theme.radii.large};
    }

    ${ /* sc-selector */TableCell}:nth-last-child(1) {
      padding-right: 12px;
      border-top-right-radius: ${({ theme }) => theme.radii.large};
      border-bottom-right-radius: ${({ theme }) => theme.radii.large};
    }
  `);
const TableBody = styled.tbody `
  ${tableReset};
`;
const TableStyle = styled.table `
  ${tableReset};

  ${({ $evenColumns }) => $evenColumns &&
    css `
      table-layout: fixed;
      width: 100%;
    `}
  ${({ $hideHeader }) => $hideHeader &&
    css `
      ${TableHead} {
        display: none;
        visibility: collapse;
      }
    `}

  ${ /* sc-selector */TableRow} {
    :nth-child(odd) {
      background-color: ${({ theme }) => theme.colors.background.secondary.B};
      z-index: -1; /* Fix for scrollbar going under odd rows */
    }

    :nth-child(odd) td {
      background-color: ${({ theme }) => theme.colors.background.secondary.B};
    }

    :nth-child(even) td {
      background-color: ${({ theme }) => theme.colors.background.secondary.A};
    }

    :hover {
      background-color: ${({ theme }) => theme.colors.state.hover};
    }
  }
`;
const StatusHeader = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 100px;
`;
const StyledSortIcon = styled(ReactComponent) `
  width: 14px;
  height: 14px;
  margin-left: 8px;
  transition: transform 0.1s linear;
`;
const StickyStyledTableCell = styled(TableCell) `
  ${breakpoint('base', 'sm') `
    position: sticky;
    right: 0;

    ::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background-color: inherit;
      content: '\\00a0';
      height: 100%;
      margin-right: 8px;
      box-shadow: -2.5px 0px 4px -0.3px rgb(0 0 0 / 10%);
    }
  `}
`;
const StickyStyledTableHeader = styled(TableHeaderCell) `
  position: sticky;
  right: 0;
  width: 60px;
`;

export { StatusHeader, StickyStyledTableCell, StickyStyledTableHeader, StyledSortIcon, TableBody, TableCell, TableHead, TableHeaderCell, TableHeaderStyle, TableRow, TableStyle };

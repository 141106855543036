import { __awaiter } from 'tslib';
import get from 'lodash.get';
import set from 'lodash.set';
import { ErrorHandler } from '@sketch/tracing';
import { castError, excludeError } from '@sketch/utils';

function handleFetchMore(fetchMore, entriesPath, { after = null, fetchLatest, variables = {}, dataIdFromObject, preserveAfter = false, afterPath, }) {
    return () => __awaiter(this, void 0, void 0, function* () {
        if (!fetchLatest && !after)
            return Promise.resolve();
        try {
            return yield (fetchMore === null || fetchMore === void 0 ? void 0 : fetchMore({
                variables: Object.assign({ after: fetchLatest ? null : after }, variables),
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEntries = get(fetchMoreResult, entriesPath, []);
                    const newEntriesIds = newEntries.map(dataIdFromObject);
                    const oldEntries = get(previousResult, entriesPath, []).filter((x) => !newEntriesIds.includes(dataIdFromObject(x)));
                    const merged = fetchLatest
                        ? [...newEntries, ...oldEntries]
                        : [...oldEntries, ...newEntries];
                    if (preserveAfter) {
                        fetchMoreResult = set(fetchMoreResult, afterPath !== null && afterPath !== void 0 ? afterPath : [], get(previousResult, afterPath !== null && afterPath !== void 0 ? afterPath : []));
                    }
                    return set(fetchMoreResult, entriesPath, merged);
                },
            }));
        }
        catch (e) {
            const err = castError(e);
            if (excludeError(err)) {
                ErrorHandler.ignore(err, 'Workaround for an issue where apollo attempts to access a query which has already unmounted.');
                return null;
            }
            throw err;
        }
    });
}

export { handleFetchMore };

import { ApolloLink } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';

const createOperationsTimestampLink = ({ timestampsRef = {}, } = {}) => {
    const apolloLink = new ApolloLink((operation, forward) => {
        return forward(operation).map(result => {
            var _a, _b, _c;
            const { query } = operation;
            const mainDefinition = getMainDefinition(query);
            const definitionName = (_b = (_a = mainDefinition.name) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : 'unknown';
            if (timestampsRef[definitionName] === undefined) {
                timestampsRef[definitionName] =
                    ((_c = window === null || window === void 0 ? void 0 : window.performance) === null || _c === void 0 ? void 0 : _c.now()) || Number.POSITIVE_INFINITY;
            }
            return result;
        });
    });
    return { apolloLink, timestampsRef };
};
const { apolloLink: operationsTimestampLink, timestampsRef, } = createOperationsTimestampLink();

export { createOperationsTimestampLink, operationsTimestampLink, timestampsRef };

import styled from 'styled-components'

export const ContentContainer = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`

export const FontWeightRegular = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`

export const UpgradeParagraph = styled.p`
  margin-top: ${({ theme }) => theme.space[1]}px;
`

import React from 'react'

import { Button, Text, Modal, ModalInjectedProps } from '@sketch/components'

import { useToast } from '@sketch/toasts'

import { useUpdateRestrictedUploadsStateMutation } from '@sketch/gql-types'

interface EnableUploadsModalProps extends ModalInjectedProps {
  workspaceId: string
}

/**
 * EnableUploadsModal.
 *
 * This component shows a modal to the user with the ability of enabling the "restricted uploads" feature.
 * To properly work this component should be call through the ModalContext API.
 *
 */
export const EnableUploadsModal: React.FC<EnableUploadsModalProps> = ({
  hideModal,
  workspaceId,
}) => {
  const { showToast } = useToast()

  const [
    enableRestrictedUploads,
    { loading },
  ] = useUpdateRestrictedUploadsStateMutation({
    variables: {
      workspaceId,
      enableRestrictedUploads: false,
    },
    onCompleted: data => {
      showToast('Document uploads are now enabled for Editors', 'positive')
      hideModal()
    },
    onError: 'show-toast',
  })

  const handleOnClick = () => enableRestrictedUploads({})

  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>Enable Document Uploads to this Workspace?</Modal.Header>
      <Modal.Body>
        <Text>
          Once enabled, Editors will be able to update existing and upload new
          documents to the Workspace again.
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={hideModal}
          type="button"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button variant="primary" loading={loading} onClick={handleOnClick}>
          Enable Uploads
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

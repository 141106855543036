import React from 'react'

import {
  WorkspaceLogo,
  Truncate,
  pluralize,
  ProjectLogo,
  ListRowTitleText,
  ListRowBoldTitleText,
  ListRowSubtitleText,
} from '@sketch/components'
import PermissionWrapper from 'modules/shares/modals/ShareSettingsModal/PermissionWrapper'
import ProjectMembershipsTooltip from 'modules/projects/components/ProjectMembershipsTooltip'

// GraphQL
import {
  ProjectFragment,
  useGetProjectMembershipsQuery,
  useGetProjectMembershipsCountQuery,
  PublicWorkspaceFragment,
} from '@sketch/gql-types'
import { useFlag } from '@sketch/modules-common'

export interface ProjectRowProps {
  project: Pick<ProjectFragment, 'identifier' | 'name' | 'workspaceAccessLevel'>
  workspace: Pick<PublicWorkspaceFragment, 'identifier' | 'name' | 'avatar'>
  actions: React.ReactNode
  className?: string
}

export const ProjectRow = ({
  project,
  workspace,
  actions,
  className,
}: ProjectRowProps) => {
  const isProjectAvailableToWorkspace =
    project.workspaceAccessLevel === 'DEFAULT'

  const { data, loading, error } = useGetProjectMembershipsQuery({
    variables: { projectIdentifier: project.identifier },
    skip: isProjectAvailableToWorkspace,
  })

  const projectIcon =
    project.workspaceAccessLevel === 'DEFAULT' ? (
      <WorkspaceLogo
        src={workspace.avatar?.small}
        workspaceName={workspace.name}
        size="32px"
      />
    ) : (
      <ProjectLogo />
    )

  const membersCount = data?.project.members?.meta.totalCount

  return (
    <PermissionWrapper
      key={project.identifier}
      className={className}
      aria-label={`Project row of ${project.name}`}
    >
      {projectIcon}
      <Truncate>
        <ListRowTitleText aria-label={`from ${project.name} project`}>
          from <ListRowBoldTitleText>{project.name}</ListRowBoldTitleText>{' '}
          project
        </ListRowTitleText>
        <ProjectMembershipsTooltip
          workspace={workspace}
          memberships={data?.project.members}
          workspaceAccessLevel={project.workspaceAccessLevel}
          shouldShowWorkspace={false}
          disabled={
            loading ||
            Boolean(error) ||
            isProjectAvailableToWorkspace ||
            membersCount === 0
          }
        >
          <ProjectCountSummary
            projectIdentifier={project.identifier}
            isRestricted={!isProjectAvailableToWorkspace}
          />
        </ProjectMembershipsTooltip>
      </Truncate>
      {actions}
    </PermissionWrapper>
  )
}

interface ProjectCountSummaryProps {
  projectIdentifier: string
  isRestricted: boolean
}

const ProjectCountSummary = (props: ProjectCountSummaryProps) => {
  const { projectIdentifier, isRestricted } = props
  // Remove this FF when released
  // https://github.com/sketch-hq/Cloud/issues/18702
  const hasGuestsInProjects = useFlag('guests-in-project')

  const {
    data: countData,
    loading: countLoading,
    error: countError,
  } = useGetProjectMembershipsCountQuery({
    variables: {
      projectIdentifier,
    },
  })

  if (countLoading || !countData || countError) {
    return null
  }

  const allCounts = countData.project.membershipCount

  const memberCount = allCounts.admin + allCounts.member
  const memberText = pluralize('Project Member', 'Project Members', memberCount)

  const guestCount = allCounts.guest
  const guestText = pluralize('Guest', 'Guests', guestCount)

  let text = isRestricted
    ? `${memberCount} ${memberText}`
    : `All Workspace Members`

  if (hasGuestsInProjects && guestCount) {
    text += ` + ${guestCount} ${guestText}`
  }

  return <ListRowSubtitleText>{text}</ListRowSubtitleText>
}

import { __rest } from 'tslib';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useState, useRef, useLayoutEffect } from 'react';
import { Pill, Popper } from '@sketch/components';
import { AutocompleteItem, AutocompleteItemContent, AutocompleteItemAvatar, AutocompleteItemText, PrimaryText, SecondaryText, InvitePeopleFieldWrapper, AutocompleteList, InvitePeopleInputHeader, InvitePeopleInputWrapper, InvitePeopleIconWrapper, PersonIcon, InvitePeopleInputValueSelected, InvitePeopleInputCombobox, InvitePeopleInput } from './InvitePeopleField.styles.js';
import '../PermissionGroupItem/index.js';
import PermissionGroupItem from '../PermissionGroupItem/PermissionGroupItem.js';

const AutoCompleteUser = ({ item, index, getItemProps, }) => {
    var _a, _b, _c, _d, _e;
    return (jsx(AutocompleteItem, Object.assign({ "data-testid": "autocomplete-list-item" }, getItemProps({ item, index }), { children: jsxs(AutocompleteItemContent, { children: [jsx(AutocompleteItemAvatar, { size: 32, src: ((_b = (_a = item.user) === null || _a === void 0 ? void 0 : _a.avatar) === null || _b === void 0 ? void 0 : _b.small) || '', name: ((_c = item === null || item === void 0 ? void 0 : item.user) === null || _c === void 0 ? void 0 : _c.name) || '' }), jsxs(AutocompleteItemText, { children: [jsxs(PrimaryText, { children: [(_d = item === null || item === void 0 ? void 0 : item.user) === null || _d === void 0 ? void 0 : _d.name, item.role === 'GUEST' && (jsxs(Fragment, { children: [' ', jsx(Pill, Object.assign({ variant: "guest" }, { children: "Guest" }))] })), item.role === 'ADMIN' && (jsxs(Fragment, { children: [' ', jsx(Pill, Object.assign({ variant: "admin" }, { children: "Admin" }))] }))] }), jsx(SecondaryText, { children: (_e = item === null || item === void 0 ? void 0 : item.user) === null || _e === void 0 ? void 0 : _e.email })] })] }) }), item.identifier));
};
const AutoCompleteGroup = ({ item, index, getItemProps, }) => (jsx(AutocompleteItem, Object.assign({ "data-testid": "autocomplete-list-item" }, getItemProps({ item, index }), { children: jsx(PermissionGroupItem, { permissionGroup: item }) }), item.identifier));
const InvitePeopleField = ({ containerRef, action, dynamicAction, placeholder: inputPlaceholder, autocomplete: { items, formik: { handleBlur }, combobox: { getComboboxProps, getInputProps, getMenuProps, isOpen, getItemProps, reset: resetCombobox, selectedItem, }, valueSelected, isValueSelected, }, className, label, data1pIgnore, }) => {
    const [deleteSelectedValue, setDeleteSelectedValue] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const updateRef = useRef(null);
    useLayoutEffect(() => {
        var _a;
        (_a = updateRef.current) === null || _a === void 0 ? void 0 : _a.call(updateRef);
    }, [isOpen]);
    const handleOnKeyDown = (e) => {
        const isUserDeleting = e.key === 'Backspace' || e.key === 'Delete';
        // If user do anything but delete when value is selected
        // for deletion, restore the state
        if (deleteSelectedValue && !isUserDeleting) {
            setDeleteSelectedValue(false);
            return;
        }
        if (isUserDeleting && isValueSelected) {
            // If user has the input ready to be deleted,
            // the next time the user presses backspace or deleted
            // it will be deleted
            if (!deleteSelectedValue) {
                e.preventDefault();
                setDeleteSelectedValue(true);
                return;
            }
            // If the value is ready to be deleted and the user presses
            // again the deletion key, it will be deleted
            setDeleteSelectedValue(false);
            resetCombobox();
        }
    };
    const _a = getInputProps({
        onBlur: handleBlur,
        placeholder: inputPlaceholder,
        autoComplete: 'off',
        type: 'text',
        name: 'invitation',
        onKeyDown: handleOnKeyDown,
    }), { value: inputValue, placeholder } = _a, inputProps = __rest(_a, ["value", "placeholder"]);
    return (jsx(InvitePeopleFieldWrapper, Object.assign({ className: className }, { children: jsxs(Popper, Object.assign({ placement: "bottom-start", "data-testid": "autocomplete-member", visible: true, usePortal: true, spacing: "-1px", popup: (_a) => {
                var { rect } = _a, popperProps = __rest(_a, ["rect"]);
                const menuProps = getMenuProps();
                const update = updateRef;
                update.current = popperProps.forceUpdate;
                // Match Popup Width with Reference Size
                const popupWidth = (rect === null || rect === void 0 ? void 0 : rect.width) || 'auto';
                popperProps.style.width = popupWidth;
                return (jsx(AutocompleteList, Object.assign({}, popperProps, menuProps, { ref: ref => {
                        menuProps.ref(ref);
                        popperProps.ref(ref);
                    } }, { children: isOpen &&
                        items.map((item, index) => {
                            if (item.__typename === 'PermissionGroup') {
                                return (jsx(AutoCompleteGroup, { index: index, item: item, getItemProps: getItemProps }, item.identifier));
                            }
                            return (jsx(AutoCompleteUser, { index: index, item: item, getItemProps: getItemProps }, item.identifier));
                        }) })));
            } }, { children: [label && jsx(InvitePeopleInputHeader, { children: label }), jsxs(InvitePeopleInputWrapper, Object.assign({ focus: isFocused, ref: containerRef }, { children: [jsx(InvitePeopleIconWrapper, { children: jsx(PersonIcon, {}) }), isValueSelected && (jsx(InvitePeopleInputValueSelected, Object.assign({ "aria-label": "Selected user", deleteable: deleteSelectedValue }, { children: valueSelected }))), jsx(InvitePeopleInputCombobox, Object.assign({}, getComboboxProps(), { children: jsx(InvitePeopleInput, Object.assign({ "data-1p-ignore": data1pIgnore, isReadyToDeleteValue: deleteSelectedValue }, inputProps, { value: isValueSelected ? '' : inputValue, placeholder: isValueSelected ? '' : placeholder, onFocus: () => setIsFocused(true), onBlur: () => setIsFocused(false) })) })), dynamicAction ? dynamicAction({ selectedItem }) : action] }))] })) })));
};

export { InvitePeopleField };

import { bindReporter } from 'web-vitals/dist/modules//lib/bindReporter';
import { getVisibilityWatcher } from 'web-vitals/dist/modules/lib/getVisibilityWatcher.js';
import { initMetric as initMetric$1 } from 'web-vitals/dist/modules/lib/initMetric.js';
import { onHidden } from 'web-vitals/dist/modules/lib/onHidden.js';

const initMetric = initMetric$1;
const reportedMetricIDs = new Set();
function isRectInViewport(rect) {
    const innerHeight = window.innerHeight || document.documentElement.clientHeight;
    const innerWidth = window.innerWidth || document.documentElement.clientWidth;
    return (
    // is the top left corner in the viewport
    (0 <= rect.top &&
        rect.top <= innerHeight &&
        0 <= rect.left &&
        rect.left <= innerWidth) ||
        // or the bottom right corner in the viewport
        (0 <= rect.bottom &&
            rect.bottom <= innerHeight &&
            0 <= rect.right &&
            rect.right <= innerWidth));
}
const getElementsToWaitFor = (expectedElement) => {
    return Array.from(document.querySelectorAll(`[elementtiming="${expectedElement}"]`))
        .map(el => ({
        element: el,
        isRectInViewport: isRectInViewport(el.getBoundingClientRect()),
    }))
        .filter(x => x.isRectInViewport)
        .map(x => x.element);
};
const buildGetElementTiming = (observeFn, name) => {
    const getElementTiming = (onReport, expectedElement) => {
        const visibilityWatcher = getVisibilityWatcher();
        const metric = initMetric(name);
        let report;
        let wasReported = false;
        let remainingElements;
        let stopListening;
        const entryHandler = (entry) => {
            if (wasReported || entry.identifier !== expectedElement) {
                return;
            }
            // The startTime attribute returns the value of the renderTime if it is not 0,
            // and the value of the loadTime otherwise.
            const value = entry.startTime;
            // If the page was hidden prior to paint time of the entry,
            // ignore it and stop listening for the new events - we will not report anything here
            if (value >= visibilityWatcher.firstHiddenTime) {
                stopListening === null || stopListening === void 0 ? void 0 : stopListening();
                return;
            }
            if (!remainingElements) {
                remainingElements = getElementsToWaitFor(expectedElement);
            }
            const element = entry.element;
            const elementIndex = remainingElements.indexOf(element);
            if (elementIndex < 0) {
                return;
            }
            metric.value = value;
            metric.entries.push(entry);
            remainingElements.splice(elementIndex, 1);
            if (remainingElements.length !== 0) {
                return;
            }
            report === null || report === void 0 ? void 0 : report();
        };
        const po = observeFn(entryHandler);
        if (po) {
            // we are interested in specific element, however, it is possible that there will be a number of elements
            // of the same type (e.g. list of artboard images).
            // Here we are allowing to report that elements have loaded if these events occur close to each other (max 200 ms apart).
            // However, everything after that we are considering as events created by future loading
            report = bindReporter(metric => {
                try {
                    if (!wasReported) {
                        wasReported = true;
                        onReport === null || onReport === void 0 ? void 0 : onReport(metric);
                    }
                }
                finally {
                    stopListening();
                }
            }, metric, true);
            stopListening = () => {
                if (!reportedMetricIDs.has(metric.id)) {
                    reportedMetricIDs.add(metric.id);
                    // po.takeRecords().map(entryHandler as PerformanceEntryHandler)
                    po.cleanup();
                }
            };
            ['keydown', 'click'].forEach(type => {
                window.addEventListener(type, stopListening, {
                    once: true,
                    capture: true,
                });
            });
            onHidden(stopListening, true);
        }
    };
    return getElementTiming;
};

export { buildGetElementTiming };

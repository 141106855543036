import { jsxs, jsx } from 'react/jsx-runtime';
import { useState } from 'react';
import { Portal } from 'react-portal';
import { SelectDropdownItemWrapper, SelectDropdownModalHeader, SelectDropdownModalBody, SelectDropdownModalFooter, SelectDropdownCancelButton } from './SelectList.styles.js';
import { lightTheme } from '@sketch/global-styles';
import '../../Dropdown/index.js';
import { Modal } from '../../Modal/index.js';
import '../../ModalManager/index.js';
import '../../Breakpoint/index.js';
import { useBreakpoint } from '../../Breakpoint/Breakpoint.js';
import { StatelessDropdown } from '../../Dropdown/Dropdown.js';
import { ModalTransition } from '../../ModalManager/ModalTransition.js';

function SelectList(props) {
    const { expanded, children, headerItem, footerItem, placement = 'bottom-start', breakpoint = 'md', setExpanded, usePortal, } = props;
    const shouldRenderDropdown = useBreakpoint(breakpoint);
    const [hasOverflow, setHasOverflow] = useState(false);
    if (shouldRenderDropdown) {
        return expanded ? (jsxs(StatelessDropdown, Object.assign({ visible: expanded, toggleDropdown: () => { }, placement: placement, contentPadding: "0", minWidth: "180px", maxWidth: "320px", contentStyle: {
                borderRadius: lightTheme.radii.xxlarge,
            }, onClickOutside: e => {
                setExpanded((state, button) => {
                    // If it's the button the click handler then no need to override the state
                    if (e.target === button) {
                        return state;
                    }
                    return false;
                });
            }, usePortal: usePortal }, { children: [jsxs(SelectDropdownItemWrapper, { children: [headerItem, children] }), footerItem && (jsx(SelectDropdownItemWrapper, { children: footerItem }))] }))) : null;
    }
    else {
        const handleClose = () => setExpanded(() => false);
        return (jsx(Portal, { children: jsx(ModalTransition, Object.assign({ show: expanded }, { children: jsxs(Modal, Object.assign({ onCancel: handleClose, onUpdateScroll: setHasOverflow }, { children: [headerItem ? (jsx(SelectDropdownModalHeader, Object.assign({ as: "div" }, { children: headerItem }))) : null, jsx(SelectDropdownModalBody, { children: children }), jsxs(SelectDropdownModalFooter, Object.assign({ hasOverflow: hasOverflow }, { children: [footerItem, jsx(SelectDropdownCancelButton, Object.assign({ variant: "tertiary", size: "40", onClick: handleClose, fill: true }, { children: "Cancel" }))] }))] })) })) }));
    }
}

export { SelectList };

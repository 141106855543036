import styled from 'styled-components';
import { ReactComponent } from '@sketch/icons/arrow-top-right-diagonal-16';
import { Link } from '@sketch/components';

const Wrapper = styled.div `
  display: flex;
  justify-content: flex-start;
  flex-basis: 100%;
  margin-left: 20px;
`;
const Arrow = styled(ReactComponent) `
  width: 16px;
  height: 16px;
  vertical-align: middle;
`;
const StyledLink = styled(Link) `
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.D};
  opacity: 0.7;
`;

export { Arrow, StyledLink, Wrapper };

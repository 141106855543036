import { jsxs, jsx } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import { useTransition, config } from 'react-spring';
import { StyledButton, AnimatedDiv, CopiedCodesButtonText, CopyText } from './CopyCodesButton.styles.js';
import { ReactComponent } from '@sketch/icons/check-16';

const CopyCodesButton = ({ codes }) => {
    const [copied, setCopied] = useState(false);
    useEffect(() => {
        if (copied) {
            const timer = setTimeout(() => setCopied(false), 2000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [copied]);
    const handleClick = () => {
        if (copied)
            return;
        const parsedRecoveryCodes = codes.join().replaceAll(',', ' \n');
        navigator.clipboard.writeText(parsedRecoveryCodes);
        setCopied(true);
    };
    const transitions = useTransition(copied, null, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.wobbly,
    });
    return (jsxs(StyledButton, Object.assign({ onClick: handleClick, smaller: true }, { children: [transitions.map(({ item, key, props }) => item && (jsx(AnimatedDiv, Object.assign({ style: props }, { children: jsxs(CopiedCodesButtonText, { children: [jsx(ReactComponent, { width: "16px" }), " Copied"] }) }), key))), !copied && jsx(CopyText, { children: "Copy to Clipboard" })] })));
};

export { CopyCodesButton };

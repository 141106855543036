import { jsxs, jsx } from 'react/jsx-runtime';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form, Input, UrlIcon, Button, SubmitIcon, CancelIcon } from './BubbleMenuLink.styles.js';

const validationSchema = yup.object().shape({
    url: yup.string().url().trim().required("A URL can't be blank"),
});
const HTTP = 'http://';
const BubbleMenuLink = ({ currentUrl, onAdd, onRemove, isLinkActive, }) => {
    // This is a super small form, so better to use `useFormik` instead of Formik
    // components (that avoids using React Context)
    const formik = useFormik({
        initialValues: { url: currentUrl || '' },
        validationSchema,
        onSubmit: values => {
            onAdd(values.url);
        },
    });
    return (jsxs(Form, Object.assign({ onClick: event => event.stopPropagation(), onSubmit: formik.handleSubmit }, { children: [jsx(Input, { id: "url", name: "url", type: "url", icon: jsx(UrlIcon, {}), placeholder: HTTP, value: formik.values.url, onChange: formik.handleChange, onFocus: event => {
                    // Add protocol to input field when empty
                    if (!event.target.value) {
                        formik.setFieldValue('url', HTTP);
                    }
                }, onPaste: () => {
                    // Empty input value before pasting a url
                    formik.setFieldValue('url', '');
                }, autoFocus: true }), jsx(Button, Object.assign({ type: "submit", disabled: !formik.values.url || !formik.isValid }, { children: jsx(SubmitIcon, {}) })), jsx(Button, Object.assign({ type: "button", disabled: !isLinkActive || !formik.values.url || formik.values.url === HTTP, onClick: () => {
                    formik.resetForm();
                    onRemove();
                } }, { children: jsx(CancelIcon, {}) }))] })));
};

export { BubbleMenuLink };

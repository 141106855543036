import { jsx } from 'react/jsx-runtime';
import { useEffect } from 'react';
import { useGetDiscoverResourcesQuery } from '@sketch/gql-types';
import { ErrorHandler } from '@sketch/tracing';
import * as Sentry from '@sentry/browser';
import '../../components/DiscoverInsightList/index.js';
import { DiscoverInsightListSkeleton } from '../../components/DiscoverInsightList/DiscoverInsightListSkeleton.js';
import DiscoverInsightList from '../../components/DiscoverInsightList/DiscoverInsightList.js';

const RESOURCE_TYPE_TO_API_TYPE = {
    'empty-state-collection': 'EMPTY_STATE_COLLECTION',
    'empty-state-libraries': 'EMPTY_STATE_LIBRARIES',
    'empty-state-my-drafts': 'EMPTY_STATE_MY_DRAFTS',
    'empty-state-project': 'EMPTY_STATE_PROJECT',
    'empty-state-shared-with-me': 'EMPTY_STATE_SHARED_WITH_ME',
    'empty-state-templates': 'EMPTY_STATE_TEMPLATES',
    'empty-state-components': 'EMPTY_STATE_COMPONENTS',
    'empty-state-prototypes': 'EMPTY_STATE_PROTOTYPES',
};
const DiscoverResources = ({ type }) => {
    const { loading, error, data } = useGetDiscoverResourcesQuery({
        variables: { type: RESOURCE_TYPE_TO_API_TYPE[type] },
    });
    useEffect(() => {
        const { title, items } = (data === null || data === void 0 ? void 0 : data.discoverResources) || {};
        if (loading) {
            return;
        }
        if (error) {
            Sentry.captureException(error);
            ErrorHandler.shouldNeverHappen(`Discover Resource [${type}] is returning an error: ${error.message}`);
            return;
        }
        if (!title || !items) {
            ErrorHandler.shouldNeverHappen(`Discover Resource [${type}] is returning invalid content. No "title" or "items" supplied`);
        }
    }, [data, error, type, loading]);
    if (loading || error || !data) {
        return jsx(DiscoverInsightListSkeleton, {});
    }
    /**
     * If the content is invalid for some reason we should
     * continue showing the loading state to prevent a blank space
     * from being seen
     */
    const { title, subTitle, links, items } = data.discoverResources;
    if (!title || !items) {
        return jsx(DiscoverInsightListSkeleton, {});
    }
    const titleLink = (links === null || links === void 0 ? void 0 : links[0])
        ? { url: links[0].url, text: links[0].text }
        : undefined;
    return (jsx(DiscoverInsightList, { items: items, title: title, subTitle: subTitle === null || subTitle === void 0 ? void 0 : subTitle.text, titleLink: titleLink }));
};

export { DiscoverResources as default };

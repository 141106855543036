import React from 'react'

import { SKETCH_WEBSITE } from '@sketch/env-config'
import { useForTablet, DocumentHeader } from '@sketch/components'
import { useLayoutPortal } from '@sketch/utils'

const HEADER_COPY = {
  SHARED_WITH_ME: {
    title: 'Shared with Me',
    description: 'All documents that are directly shared with you.',
    learnMoreTooltipTitle: 'Learn more about Shared with Me',
    learnMoreTooltipURL: `${SKETCH_WEBSITE}/docs/sharing-and-collaborating/sharing-and-viewing-documents/shared-with-me/#how-to-access-shared-with-me`,
  },
  LIBRARY: {
    title: 'Libraries',
    description:
      "Libraries are documents with components that can be shared across other documents. To add a Library, select Use as Library in a document's settings.",
    learnMoreTooltipTitle: 'Learn more about Libraries',
    learnMoreTooltipURL: `${SKETCH_WEBSITE}/docs/libraries/`,
  },
  TEMPLATE: {
    title: 'Templates',
    description:
      'Templates are reusable Workspace documents with pre-populated content.',
    learnMoreTooltipTitle: 'Learn more about Templates',
    learnMoreTooltipURL: `${SKETCH_WEBSITE}/docs/symbols-and-styles/templates/`,
  },
  NO_PROJECT: {
    title: 'All Documents',
    description:
      'All the documents in your Workspace — in sync and available anywhere.',
    learnMoreTooltipTitle: 'Learn more',
    learnMoreTooltipURL: `${SKETCH_WEBSITE}/docs/sharing-and-collaborating/using-your-workspace/viewing-documents/`,
  },
}

interface WorkspaceSharesListHeaderProps {
  filter?: 'LIBRARY' | 'NO_PROJECT' | 'SHARED_WITH_ME' | 'TEMPLATE' | null
}

export const WorkspaceSharesListHeader: React.FC<WorkspaceSharesListHeaderProps> = ({
  filter,
}) => {
  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  const HeaderPortal = useLayoutPortal('header-portal')
  const HeaderSlimPortal = useLayoutPortal('header-slim-portal')

  const headerCopy = filter ? HEADER_COPY[filter] : HEADER_COPY['NO_PROJECT']

  if (isMobile) {
    return null
  }

  return (
    <>
      <HeaderPortal>
        <DocumentHeader
          title={headerCopy.title}
          description={headerCopy.description}
          learnMoreTooltipTitle={headerCopy.learnMoreTooltipTitle}
          learnMoreTooltipURL={headerCopy.learnMoreTooltipURL}
        />
      </HeaderPortal>
      <HeaderSlimPortal>
        <DocumentHeader title={headerCopy.title} />
      </HeaderSlimPortal>
    </>
  )
}

import React from 'react'
import styled from 'styled-components'
import { Gradient } from 'modules/shares/types'
import { hash } from '@sketch/utils'

interface RadialGradientIconProps {
  size?: number
  gradient: Gradient
}

const RadialGradientIcon = ({
  size = 17,
  gradient,
  ...props
}: RadialGradientIconProps) => {
  const stopColors: string[] = []

  const stops = gradient.stops
    .sort((lhs, rhs) => lhs.position - rhs.position)
    // Remove duplicated stops (same position)
    .filter(
      (stop, index, self) =>
        index === self.findIndex(t => t.position === stop.position)
    )
    .map(stop => {
      const StopElement = styled.stop`
        stop-color: rgb(
          ${stop.color.red},
          ${stop.color.green},
          ${stop.color.blue}
        );
        stop-opacity: ${stop.color.alpha};
      `
      stopColors.push(
        `${stop.color.red}-${stop.color.green}-${stop.color.blue}-${stop.color.alpha}`
      )

      return (
        <StopElement key={stop.position} offset={`${stop.position * 100}%`} />
      )
    })
  const uniqueHash = hash(stopColors.join('-'))

  const opacity = gradient.appearance ? gradient.appearance.opacity : 1

  return (
    <svg
      height={size}
      width={size}
      {...props}
      style={{ opacity }}
      data-testid="radial-gradient-icon"
    >
      <defs>
        <radialGradient
          id={`radialGradient-${uniqueHash}`}
          cx="50%"
          cy="50%"
          r="50%"
          fx="50%"
          fy="50%"
        >
          {stops}
        </radialGradient>
      </defs>
      <ellipse
        cx={size / 2}
        cy={size / 2}
        rx={size / 2}
        ry={size / 2}
        fill={`url(#radialGradient-${uniqueHash})`}
      />
    </svg>
  )
}

export default RadialGradientIcon

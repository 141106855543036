import { buildGetElementTiming } from './buildGetElementTiming.js';
import { observe } from 'web-vitals/dist/modules/lib/observe';

const getElementTiming = buildGetElementTiming((handler) => {
    const po = observe('element', entry => {
        handler(entry);
    });
    return {
        cleanup: () => {
            po === null || po === void 0 ? void 0 : po.disconnect();
        },
    };
}, 'ElementTiming');

export { getElementTiming };

import styled, { css } from 'styled-components'
import { Margin } from 'modules/workspace/components/WorkspaceSidebarLayout/WorkspaceSidebarLayout.styles'
import { ReactComponent as DSIcon } from '@sketch/icons/design-system-16'

export const StyledDSIcon = styled(DSIcon)`
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  height: 16px;
  margin-right: 8px;
`

export const ContentWrapper = styled(Margin)``

export const Header = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.border.B};
  `
)

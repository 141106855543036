import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useVersioning } from 'modules/versioning'
import { useAnalytics, RouteParams } from '@sketch/modules-common'

type GoToArtboardEventActionType = 'keyPressEnter' | 'doubleClick' | 'CTA'

/**
 * Returns an onGoToArtboard callback.
 */
export function useHandleArtboardsInteractions() {
  const history = useHistory()
  const getArtboardViewUrlFromUUID = useGetArtboardViewUrlFromUUID()
  const { trackEvent } = useAnalytics()

  const onGoToArtboard = useCallback(
    (uuid: string, actionType: GoToArtboardEventActionType) => {
      trackEvent('CANVAS - open artboard', { actionType })
      history.push(getArtboardViewUrlFromUUID(uuid))
    },
    [trackEvent, history, getArtboardViewUrlFromUUID]
  )

  return {
    onGoToArtboard,
  }
}

/**
 * Provide a function that takes an artboard UUID and builds the URL
 * to access the artboard.
 */
function useGetArtboardViewUrlFromUUID() {
  const { getPathname } = useVersioning()
  const { shareID } = useParams<RouteParams<'SHARE_PAGE_CANVAS_VIEW'>>()

  const getArtboardUrlFromUUID = useCallback(
    (artboardUUID: string) => {
      return getPathname({
        routeKey: 'ARTBOARD_DETAIL_UUID',
        routeParams: {
          shareID: shareID,
          artboardUUID: artboardUUID,
        },
      })
    },
    [getPathname, shareID]
  )

  return getArtboardUrlFromUUID
}

import * as React from "react";
import { forwardRef } from "react";
const ReactComponent = ({ title, titleId, ...props }, ref) => React.createElement("svg", { "data-testid": "graduate-cap-24", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", ref: ref, "aria-labelledby": titleId, ...props },
    title ? React.createElement("title", { id: titleId }, title) : null,
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M18.25 11.25v3.864a3.01 3.01 0 01-.687 1.912C16.336 18.51 14.48 19.25 12 19.25s-4.336-.741-5.563-2.224a3.01 3.01 0 01-.687-1.912V11.25h0", stroke: "#000", strokeWidth: 1.5, strokeLinejoin: "round" }),
        React.createElement("path", { d: "M5.75 23c.828 0 1.5-.172 1.5-1 0-.828-.672-2.5-1.5-2.5s-1.5 1.672-1.5 2.5c0 .828.672 1 1.5 1z", fill: "#000" }),
        React.createElement("path", { stroke: "#000", strokeWidth: 1.5, d: "M5.75 21v-9.25L12 8" }),
        React.createElement("path", { d: "M12.744 2.925l9.186 5.25a.375.375 0 010 .65l-9.186 5.25a1.5 1.5 0 01-1.488 0L2.07 8.825a.375.375 0 010-.65l9.186-5.25a1.5 1.5 0 011.488 0z", stroke: "#000", strokeWidth: 1.5, fillOpacity: 0.15, fill: "#000" }),
        React.createElement("path", { d: "M12 9c.828 0 1.5-.448 1.5-1s-.672-1-1.5-1-1.5.448-1.5 1 .672 1 1.5 1z", fill: "#000" })));
const ForwardRef = forwardRef(ReactComponent);
export { ForwardRef as ReactComponent };

import { jsx } from 'react/jsx-runtime';
import { AccessLevelSelect as AccessLevelSelect$1 } from '@sketch/components';
import { getTriggerCopyFromAccessLevel } from './utils.js';

// We will rely on the typing from the parent components, that will
// check that the level and setLevel are one of the allowed levels
const AccessLevelSelect = ({ level, setLevel, disabled, commentsEnabled, hideCommentLabel, position, triggerButton, children, noAccessRedColor = true, title = 'Access', }) => {
    const label = getTriggerCopyFromAccessLevel(level);
    return (jsx(AccessLevelSelect$1, Object.assign({ label: label }, {
        level,
        setLevel,
        disabled,
        commentsEnabled,
        hideCommentLabel,
        position,
        triggerButton,
        children,
        noAccessRedColor,
        title,
    })));
};

export { AccessLevelSelect };

import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useForTablet, useForBigScreen, SidebarLayout } from '@sketch/components';
import '../../routes/index.js';
import '../../browser/index.js';
import { useBrowserSettings } from '../../browser/useBrowserSettings.js';
import { isCwvRouteOnly } from '../../routes/utils/paths.js';

const DocumentSidebarLayoutOld = (_a) => {
    var rest = __rest(_a, []);
    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
    const isTabletAndBigger = useForTablet();
    const isDesktopAndBigger = useForBigScreen();
    const toggleMobileSidebar = () => setIsMobileSidebarOpen(isOpen => !isOpen);
    const [data, updateBrowserSettings] = useBrowserSettings();
    const isSidebarRightOpen = data === null || data === void 0 ? void 0 : data.sidebarRightOpen;
    // For Components Web View pages on tablet we hide the sidebar because we
    // prioritise the left menu sidebar
    const { path } = useRouteMatch();
    const shouldHideRightSidebar = isTabletAndBigger && !isDesktopAndBigger && isCwvRouteOnly(path);
    const toggleSidebarRight = () => {
        updateBrowserSettings({
            sidebarRightOpen: !isSidebarRightOpen,
        });
    };
    useEffect(() => {
        if (isSidebarRightOpen && shouldHideRightSidebar) {
            toggleSidebarRight === null || toggleSidebarRight === void 0 ? void 0 : toggleSidebarRight();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const isOpen = isTabletAndBigger ? isSidebarRightOpen : isMobileSidebarOpen;
    const toggle = isTabletAndBigger ? toggleSidebarRight : toggleMobileSidebar;
    return (jsx(SidebarLayout, Object.assign({ isSidebarRightOpen: isOpen, toggleSidebarRight: toggle }, rest)));
};

export { DocumentSidebarLayoutOld };

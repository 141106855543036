import { ColorFormat } from 'modules/shares/types'
import { roundUpTo } from '@sketch/utils'

interface Color {
  red: number
  green: number
  blue: number
  alpha?: number
}

const rgbTo = (format: ColorFormat = ColorFormat.HEX, color: Color) => {
  switch (format) {
    case ColorFormat.HEX:
      return rgbToHex(color)
    case ColorFormat.RGB:
      return rgbToString(color)
    case ColorFormat.HSL:
      return rgbToHsl(color)
    case ColorFormat.OBJC_NSCOLOR:
      return rgbToObjCNSColor(color)
    case ColorFormat.SWIFT_NSCOLOR:
      return rgbToSwiftNSColor(color)
    case ColorFormat.OBJC_UICOLOR:
      return rgbToObjCUIColor(color)
    case ColorFormat.SWIFT_UICOLOR:
      return rgbToSwiftUIColor(color)
    default:
      return rgbToString(color)
  }
}

const componentToHex = (c: number) => {
  const hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

const rgbToHex = ({ red, green, blue }: Color) => {
  const hexColor =
    '#' + componentToHex(red) + componentToHex(green) + componentToHex(blue)
  return hexColor.toUpperCase()
}

const rgbToString = ({ red, green, blue, alpha }: Color) => {
  const rgbValue = `${red}, ${green}, ${blue}`

  return alpha === undefined || alpha === 1
    ? `rgb(${rgbValue})`
    : `rgba(${rgbValue}, ${roundUpTo(alpha, 2)})`
}

// Formula extracted from https://css-tricks.com/converting-color-spaces-in-javascript/
const rgbToHsl = ({ red, green, blue, alpha }: Color) => {
  // Make r, g, and b fractions of 1
  red /= 255
  green /= 255
  blue /= 255

  // Find greatest and smallest channel values
  const cmin = Math.min(red, green, blue),
    cmax = Math.max(red, green, blue),
    delta = cmax - cmin
  let h = 0,
    s = 0,
    l = 0

  // Calculate hue
  // No difference
  if (delta === 0) h = 0
  // Red is max
  else if (cmax === red) h = ((green - blue) / delta) % 6
  // Green is max
  else if (cmax === green) h = (blue - red) / delta + 2
  // Blue is max
  else h = (red - green) / delta + 4

  h = Math.round(h * 60)

  // Make negative hues positive behind 360°
  if (h < 0) h += 360

  // Calculate lightness
  l = (cmax + cmin) / 2

  // Calculate saturation
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))

  // Multiply l and s by 100
  s = +(s * 100).toFixed(0)
  l = +(l * 100).toFixed(0)

  const hslValue = `${h}, ${s}%, ${l}%`

  return alpha === undefined || alpha === 1
    ? `hsl(${hslValue})`
    : `hsla(${hslValue}, ${roundUpTo(alpha, 2)})`
}

const formatAppleColor = (color: number) => {
  return `${Math.round(color)}.0 / 255.0`
}

const rgbToObjCNSColor = ({ red, green, blue, alpha }: Color) => {
  if (alpha && alpha < 1) {
    return (
      `[NSColor ` +
      `colorWithCalibratedRed: ${formatAppleColor(red)} ` +
      `green: ${formatAppleColor(green)} ` +
      `blue: ${formatAppleColor(blue)} ` +
      `alpha: ${roundUpTo(alpha, 2)}]`
    )
  } else {
    return (
      `[NSColor ` +
      `colorWithCalibratedRed: ${formatAppleColor(red)} ` +
      `green: ${formatAppleColor(green)} ` +
      `blue: ${formatAppleColor(blue)}]`
    )
  }
}

const rgbToSwiftNSColor = ({ red, green, blue, alpha }: Color) => {
  if (alpha && alpha < 1) {
    return (
      `NSColor(` +
      `red: ${formatAppleColor(red)}, ` +
      `green: ${formatAppleColor(green)}, ` +
      `blue: ${formatAppleColor(blue)}, ` +
      `alpha: ${roundUpTo(alpha, 2)})`
    )
  } else {
    return (
      `NSColor(` +
      `red: ${formatAppleColor(red)}, ` +
      `green: ${formatAppleColor(green)}, ` +
      `blue: ${formatAppleColor(blue)})`
    )
  }
}

const rgbToObjCUIColor = ({ red, green, blue, alpha }: Color) => {
  return (
    `[UIColor ` +
    `colorWithRed: ${formatAppleColor(red)} ` +
    `green: ${formatAppleColor(green)} ` +
    `blue: ${formatAppleColor(blue)} ` +
    `alpha: ${alpha ? roundUpTo(alpha, 2) : 1}]`
  )
}

const rgbToSwiftUIColor = ({ red, green, blue, alpha }: Color) => {
  return (
    `UIColor(` +
    `red: ${formatAppleColor(red)}, ` +
    `green: ${formatAppleColor(green)}, ` +
    `blue: ${formatAppleColor(blue)}, ` +
    `alpha: ${alpha ? roundUpTo(alpha, 2) : 1})`
  )
}

export {
  rgbTo,
  rgbToString,
  rgbToHex,
  rgbToHsl,
  rgbToObjCNSColor,
  rgbToSwiftNSColor,
  rgbToObjCUIColor,
  rgbToSwiftUIColor,
}

import styled from 'styled-components'

export const FakeButton = styled.div`
  position: absolute;
  right: 8px;
  top: ${({ theme }) => theme.header.height}px;
`

export const PanelWrapper = styled.aside`
  display: flex;
  flex-direction: column;

  width: ${({ theme }) => theme.sidebar.canvasFirstRightWidth}px;
  margin: -8px 0;
  overflow-y: auto;

  ::after {
    /*

    The goal of this after is to make a subtle edge effect when
    rending the floating sidebars full height as we see in this design
    https://www.sketch.com/s/40b54a7a-5f96-4f69-b907-ee8d2797b240/a/Oe83yEk

    */
    content: '';
    display: block;
    position: absolute;

    height: 20px;

    /*
      We are the position to be bottom of the viewport so we only show when the user has the sidebar full-height
      also to prevent JS calculations for a simple detail
     */
    top: calc((var(--vh, 1vh) * 100) - 88px);
    left: 0px;

    /*
      Prevent the scroll-bar from getting sprayed by the this overlay at the bottom
    */
    right: 16px;

    opacity: 0.84;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      ${({ theme }) => theme.colors.background.secondary.A} 100%
    );
  }
`

export const MobileWrapper = styled.div`
  width: 80vw;
`

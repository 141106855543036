import styled from 'styled-components';
import { Button } from '@sketch/components';

const ButtonPill = styled(Button).attrs({
    variant: 'primary-untinted',
}) `
  padding: 8px 10px;
`;
const AvatarWrapper = styled.div `
  margin-right: 8px;
`;
const WorkspaceName = styled.span `
  font-size: ${({ theme }) => theme.fontSizes.E};
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.ui.buttons.foreground};

  &:hover {
    color: ${({ theme }) => theme.colors.ui.buttons.foregroundHover};
  }

  &:active {
    color: ${({ theme }) => theme.colors.ui.buttons.foregroundActive};
  }
`;

export { AvatarWrapper, ButtonPill, WorkspaceName };

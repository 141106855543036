import styled from 'styled-components';

const Image = styled.img `
  width: 56px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export { Image };

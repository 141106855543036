import styled from 'styled-components';
import { Form } from '@sketch/components';

const StyledFormField = styled(Form.Field) `
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const StyledErrorField = styled(Form.ErrorField) `
  font-size: ${({ theme }) => theme.fontSizes.C};
  text-align: left;
`;

export { StyledErrorField, StyledFormField };

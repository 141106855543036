import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { Container } from './Grid.styles.js';

const DEFAULT_GUTTER = {
    xl: 40,
    lg: 20,
    md: 20,
    sm: 20,
    xs: 16,
};
const Grid = (_a) => {
    var { children, gutter = DEFAULT_GUTTER, asList } = _a, props = __rest(_a, ["children", "gutter", "asList"]);
    return (jsx(Container, Object.assign({ as: asList ? 'ul' : undefined, gutter: gutter }, props, { children: children })));
};

export { Grid };

import { jsx } from 'react/jsx-runtime';
import React, { useRef, useEffect, useCallback } from 'react';
import { InView } from 'react-intersection-observer';

const LoadPageSeparator = (props) => {
    const { loadNewPage } = props;
    const loadNewPageRef = useRef(props.loadNewPage);
    useEffect(() => {
        loadNewPageRef.current = loadNewPage;
    }, [loadNewPage]);
    const onChange = useCallback((inView) => {
        if (!inView)
            return;
        loadNewPageRef.current();
    }, []);
    return (jsx(InView, Object.assign({ onChange: onChange }, { children: jsx(React.Fragment, {}) })));
};

export { LoadPageSeparator };

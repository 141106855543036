import { __rest, __awaiter } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { useState } from 'react';
import { useIsMountedRef } from '@sketch/utils';
import { Button } from './Button.js';

const AsyncButton = (_a) => {
    var { disabled, onClick } = _a, props = __rest(_a, ["disabled", "onClick"]);
    const [loading, setLoading] = useState(false);
    /**
     * This state will prevent a state to be set if the button is no longer mounted
     * Preventing the react error:
     * "Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in %s.%s"
     */
    const isMountedRef = useIsMountedRef();
    return (jsx(Button, Object.assign({}, props, { disabled: disabled || loading, loading: loading, onClick: (...args) => __awaiter(void 0, void 0, void 0, function* () {
            const promise = onClick(...args);
            setLoading(true);
            try {
                yield promise;
            }
            catch (error) {
                // Ignore it's not this components responsibility
                // to handle errors
            }
            finally {
                isMountedRef.current && setLoading(false);
            }
        }) })));
};

export { AsyncButton };

import styled, { css } from 'styled-components';
import { Navbar } from '@sketch/components';

const PageWrapper = styled.div `
  width: 100%;
  max-width: 640px;

  ${({ $isRefreshedUi }) => $isRefreshedUi &&
    css `
      margin-top: 24px;
    `}
`;
const SelectorSection = styled(Navbar.Section) `
  flex: 0 1 auto;
  /* Prevent this section from sending it's siblings of screen */
  overflow: hidden;
`;

export { PageWrapper, SelectorSection };

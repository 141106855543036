import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import React from 'react';
import { Content, Item } from './ListRow.styles.js';

// This component is the base implementation for the different rows
const ListRowWrapper = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (jsx(Content, Object.assign({}, props, { children: React.Children.map(children, item => (jsx(Item, { children: item }))) })));
};

export { ListRowWrapper };

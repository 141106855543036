import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { isMac } from '@sketch/utils';
import '../../Tooltip/index.js';
import { TooltipShortcut } from '../../Tooltip/Tooltip.js';

const META_KEY = isMac() ? '⌘' : 'Ctrl';
const OPTION_KEY = isMac() ? '⌥' : 'Alt';
const ParagraphTooltip = () => (jsxs(Fragment, { children: ["Paragraph", jsxs(TooltipShortcut, { children: [META_KEY, " ", OPTION_KEY, " 0"] })] }));
const TitleTooltip = () => (jsxs(Fragment, { children: ["Title", jsxs(TooltipShortcut, { children: [META_KEY, " ", OPTION_KEY, " 1"] })] }));
const HeadingTooltip = () => (jsxs(Fragment, { children: ["Heading", jsxs(TooltipShortcut, { children: [META_KEY, " ", OPTION_KEY, " 2"] })] }));
const SubheadingTooltip = () => (jsxs(Fragment, { children: ["Subheading", jsxs(TooltipShortcut, { children: [META_KEY, " ", OPTION_KEY, " 3"] })] }));
const BulletListTooltip = () => (jsxs(Fragment, { children: ["Bullet list", jsxs(TooltipShortcut, { children: [META_KEY, " \u21E7 8"] })] }));
const NumberedListTooltip = () => (jsxs(Fragment, { children: ["Numbered list", jsxs(TooltipShortcut, { children: [META_KEY, " \u21E7 7"] })] }));
const BoldTooltip = () => (jsxs(Fragment, { children: ["Bold", jsxs(TooltipShortcut, { children: [META_KEY, " B"] })] }));
const ItalicsTooltip = () => (jsxs(Fragment, { children: ["Italics", jsxs(TooltipShortcut, { children: [META_KEY, " I"] })] }));
const UnderlineTooltip = () => (jsxs(Fragment, { children: ["Underline", jsxs(TooltipShortcut, { children: [META_KEY, " U"] })] }));
const DividerTooltip = () => jsx(Fragment, { children: "Divider" });
const LinkTooltip = () => (jsxs(Fragment, { children: ["Link", jsxs(TooltipShortcut, { children: [META_KEY, " K"] })] }));

export { BoldTooltip, BulletListTooltip, DividerTooltip, HeadingTooltip, ItalicsTooltip, LinkTooltip, NumberedListTooltip, ParagraphTooltip, SubheadingTooltip, TitleTooltip, UnderlineTooltip };

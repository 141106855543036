import { jsx, jsxs } from 'react/jsx-runtime';
import styled, { css } from 'styled-components';
import '../Button/index.js';
import { ButtonUnstyled } from '../Button/ButtonUnstyled.js';

const DEFAULT_SIZE = 24;
const DotWrapper = styled.div(({ size }) => css `
    position: relative;
    width: ${size}px;
    height: ${size}px;
  `);
const NewFeatureHighlightBase = css(({ theme }) => css `
    position: absolute;
    border-radius: 50%;
    background-color: ${theme.colors.sketch.A};
  `);
const DotGlow = styled.div(({ size }) => css `
    ${NewFeatureHighlightBase};
    top: 0;
    left: 0;
    width: ${size}px;
    height: ${size}px;
    opacity: 15%;
  `);
const DotCenter = styled.div(({ size }) => css `
    ${NewFeatureHighlightBase};
    top: 25%;
    left: 25%;
    width: ${size / 2}px;
    height: ${size / 2}px;
  `);
const NewFeatureHighlight = ({ onClick, size = DEFAULT_SIZE, }) => (jsx(ButtonUnstyled, Object.assign({ onClick: onClick }, { children: jsxs(DotWrapper, Object.assign({ size: size }, { children: [jsx(DotGlow, { size: size }), jsx(DotCenter, { size: size })] })) })));

export { NewFeatureHighlight };

import React from 'react'

import { Dropdown } from '@sketch/components'
import { useFlag } from '@sketch/modules-common'

import AnnotationSubscriptionStatus from '../AnnotationSubscriptionStatus'
import CopyAnnotationLink from '../CopyAnnotationLink'
import DeleteCommentOption from '../DeleteCommentOption'

import { useGetAnnotationCommentsQuery } from '@sketch/gql-types'

// This is a valid use case to narrow down Component type
// eslint-disable-next-line no-restricted-imports
import { NotificationSubscriptionStatus } from '@sketch/gql-types/expansive'
import { SummarizeAnnotationButton } from '../SummarizeAnnotationButton'

interface AnnotationOptionsDropdownProps {
  annotationIdentifier: string
  notificationStatus: NotificationSubscriptionStatus
  userCanDeleteAnnotation?: boolean
  isResolved?: boolean
}

const AnnotationOptionsDropdown = (props: AnnotationOptionsDropdownProps) => {
  const {
    annotationIdentifier,
    notificationStatus,
    userCanDeleteAnnotation,
    isResolved,
  } = props

  const annotationSummaryFF = useFlag('annotations-summary')

  if (isResolved) {
    return (
      <>
        <CopyAnnotationLink annotationIdentifier={annotationIdentifier} />
        {annotationSummaryFF && (
          <>
            <Dropdown.Divider />
            <SummarizeAnnotationButton
              annotationIdentifier={annotationIdentifier}
            />
          </>
        )}
        {userCanDeleteAnnotation && (
          <>
            <Dropdown.Divider />
            <DeleteCommentOption
              annotationIdentifier={annotationIdentifier}
              isAnnotation
              hasReplies
            />
          </>
        )}
      </>
    )
  }

  return (
    <>
      <>
        <AnnotationSubscriptionStatus
          annotationIdentifier={annotationIdentifier}
          notificationStatus={notificationStatus}
          variant="dropdown"
        />
        <Dropdown.Divider />
      </>

      <CopyAnnotationLink annotationIdentifier={annotationIdentifier} />

      {annotationSummaryFF && (
        <>
          <Dropdown.Divider />
          <SummarizeAnnotationButton
            annotationIdentifier={annotationIdentifier}
          />
        </>
      )}

      {userCanDeleteAnnotation && (
        <>
          <Dropdown.Divider />
          <DeleteCommentOption
            annotationIdentifier={annotationIdentifier}
            isAnnotation
            hasReplies
          />
        </>
      )}
    </>
  )
}

interface ConnectedAnnotationOptionsDropdownProps {
  annotationIdentifier: string
}

export const ConnectedAnnotationOptionsDropdown = ({
  annotationIdentifier,
}: ConnectedAnnotationOptionsDropdownProps) => {
  /**
   *  We use the "useGetAnnotationCommentsQuery" with the
   *  "cache-only" fetch policy to make "listen" to the request
   *  made by "AnnotationCommentsList".
   *
   *  All there request logic is made by "AnnotationCommentsList"
   */
  const { data } = useGetAnnotationCommentsQuery({
    variables: { annotationIdentifier },
    fetchPolicy: 'cache-only',
  })

  const annotation = data?.annotation
  const userCanDeleteAnnotation = annotation?.firstComment.userCanDelete

  if (!annotation) {
    return null
  }

  return (
    <AnnotationOptionsDropdown
      annotationIdentifier={annotationIdentifier}
      notificationStatus={annotation?.subscriptionStatus}
      userCanDeleteAnnotation={userCanDeleteAnnotation}
    />
  )
}

export default AnnotationOptionsDropdown

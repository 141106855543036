import styled from 'styled-components'

import { breakpoint } from '@sketch/global-styles'
import { ButtonUnstyled, Text } from '@sketch/components'

import { ReactComponent as DividerSvg } from '@sketch/icons/dots-4-vertical-divider-32'
import { ReactComponent as SortIconSvg } from '@sketch/icons/arrow-up-down-24'

export const SearchBarWrapper = styled.div`
  width: 100%;
  margin-left: 8px;
  margin-right: 12px;

  ${breakpoint('md')`
    width: 480px;
  `}

  form {
    max-width: initial;
  }
`

export const ViewAsText = styled(Text.Span).attrs({
  textStyle: 'copy.quaternary.standard.D',
})`
  flex-shrink: 0;
  margin-right: 8px;
`

export const Divider = styled(DividerSvg)`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  height: 24px;
`

export const SortIcon = styled(SortIconSvg)`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  height: 24px;
  width: 24px;
`

export const SortButton = styled(ButtonUnstyled)`
  cursor: pointer;
  width: 40px;
  height: 32px;

  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.radii.large};

  &:hover {
    background-color: ${({ theme }) => theme.colors.state.hover};

    ${SortIcon} {
      color: ${({ theme }) => theme.colors.foreground.secondary.A};
    }
  }
`

import React from 'react'
import truncate from 'lodash.truncate'
import { Avatar, Flex, ApolloError } from '@sketch/components'
import {
  Panel as StyledPanel,
  Heading,
  Content,
  BodyPanel,
  MainAction,
  SecondaryAction,
  NameWithAvatar,
  PanelFooter,
  InvitedEmail,
} from './shared'
import { Inviter } from '@sketch/modules-common'
import { ParsedError } from '@sketch/graphql-apollo/useMutation'
import {
  HasPersonalIdentity,
  HasNoPersonalIdentity,
} from 'containers/HasPersonalIdentity'

import { UserInfo } from './AcceptMembershipPanel'

interface SwitchAccountOrAcceptMembershipPanelProps {
  resourceName: string
  resourceType: 'document' | 'project'
  invitedEmail?: string
  inviter?: Inviter | null
  acceptMembership(): void
  acceptMembershipLoading?: boolean
  acceptMembershipError?: ParsedError
  userInfo: UserInfo
  onSwitchAccount?: () => void
  onRequestAccess: () => void
}

const SwitchAccountOrAcceptMembershipPanel = ({
  resourceType,
  resourceName,
  inviter,
  invitedEmail,
  acceptMembershipError,
  userInfo,
  onSwitchAccount,
  onRequestAccess,
}: SwitchAccountOrAcceptMembershipPanelProps) => {
  const { userEmail, userAvatar } = userInfo

  return (
    <StyledPanel>
      <BodyPanel>
        {inviter && (
          <Flex justifyContent="center">
            <Avatar
              size="64px"
              src={inviter?.avatar?.large || undefined}
              name={inviter.name || '?' /** Name should always be there */}
            />
          </Flex>
        )}
        <Heading
          resourceType={resourceType}
          resourceName={resourceName}
          inviter={inviter}
        />
        <HasPersonalIdentity>
          <Content>
            To access the {resourceType} now, switch to{' '}
            <InvitedEmail>
              <strong>{invitedEmail}</strong>
            </InvitedEmail>
            . Alternatively, you can request access using{' '}
            <NameWithAvatar name={userEmail} src={userAvatar} />
          </Content>
        </HasPersonalIdentity>
        <HasNoPersonalIdentity>
          <Content>
            To access
            <strong>{truncate(resourceName, { length: 100 })}</strong>&rdquo;
            now, switch to{' '}
            <InvitedEmail>
              <strong>{invitedEmail}</strong>
            </InvitedEmail>
            . <br />
            You cannot accept this invitation using your SSO account. You’re
            currently signed in as{' '}
            <NameWithAvatar name={userEmail} src={userAvatar} />
          </Content>
        </HasNoPersonalIdentity>
      </BodyPanel>
      <PanelFooter>
        {acceptMembershipError && (
          <ApolloError
            error={acceptMembershipError}
            style={{ textAlign: 'left' }}
            my={4}
          />
        )}
        <Flex flexDirection="column" alignItems="center">
          <MainAction variant="primary" fill onClick={onSwitchAccount}>
            Switch Account
          </MainAction>
          <HasPersonalIdentity>
            <SecondaryAction
              variant="secondary-untinted"
              fill
              onClick={onRequestAccess}
            >
              Request access as {userEmail}
            </SecondaryAction>
          </HasPersonalIdentity>
        </Flex>
      </PanelFooter>
    </StyledPanel>
  )
}

export default SwitchAccountOrAcceptMembershipPanel

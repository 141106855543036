import { useSetUserAuthorization } from '../useSetUserAuthorization.js';
import '../../../analytics/index.js';
import { useSignInMutation, GetInitialUserDocument, GetUserSettingsDocument } from '@sketch/gql-types';
import { useAnalytics } from '../../../analytics/analytics.context.js';

const useSignIn = ({ onCompleted, onError, }) => {
    const setUserAuthorization = useSetUserAuthorization();
    const { trackEvent } = useAnalytics();
    const signInMutation = useSignInMutation({
        refetchQueries: ({ data }) => data.signIn.__typename === 'SignInCredentials'
            ? [
                {
                    query: GetInitialUserDocument,
                },
                {
                    query: GetUserSettingsDocument,
                },
            ]
            : [],
        awaitRefetchQueries: true,
        update: (_, { data }) => {
            if ((data === null || data === void 0 ? void 0 : data.signIn.__typename) !== 'SignInCredentials')
                return;
            setUserAuthorization(data.signIn.credentials);
        },
        onCompleted: data => {
            onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted(data);
            trackEvent('Successful SignIn', { type: 'password' });
        },
        onError: error => onError === null || onError === void 0 ? void 0 : onError(error.message),
    });
    return signInMutation;
};

export { useSignIn };

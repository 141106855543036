import { useRef, useState, useEffect } from 'react';
import { useFormikContext } from 'formik';

const FormikScrollToError = props => {
    const { formRef } = props;
    const { errors, isSubmitting } = useFormikContext();
    const cachedErrors = useRef(errors);
    const [wasSubmitting, setWasSubmitting] = useState(isSubmitting);
    /**
     * Locally cache the error the error so they don't trigger
     * the focus on the input when the errors update.
     */
    useEffect(() => {
        cachedErrors.current = errors;
    }, [errors, isSubmitting]);
    /**
     * Focus the first input with error by the form input children order
     */
    useEffect(() => {
        const errorKeys = Object.keys(cachedErrors.current);
        if (wasSubmitting && !isSubmitting && errorKeys.length > 0) {
            const field = formRef.current.querySelector(errorKeys
                .map(error => `input[name="${error}"], label[for="${error}"]`)
                .join(', '));
            if (field) {
                field.scrollIntoView({ behavior: 'smooth' });
                field.focus({ preventScroll: true });
            }
        }
    }, [formRef, isSubmitting, wasSubmitting]);
    /**
     * Save the previous state of the "validation" field
     */
    useEffect(() => () => setWasSubmitting(isSubmitting), [isSubmitting]);
    return null;
};

export { FormikScrollToError };

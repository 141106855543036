import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { Modal, Link, Button } from '@sketch/components';
import '../../../components/PrintToPDFButton/index.js';
import '../../../components/CopyCodesButton/index.js';
import { Title, Description, RecoveryCodes, RecoveryCode, Buttons } from '../RegenerateRecoveryCodesModal.styles.js';
import { PrintToPDFButton } from '../../../components/PrintToPDFButton/PrintToPDFButton.js';
import { CopyCodesButton } from '../../../components/CopyCodesButton/CopyCodesButton.js';

/**
 * Renders the third step for the Regenerate Recovery Codes modal
 */
const ThirdStep = ({ onNextStep, recoveryCodes, }) => {
    return (jsxs(Fragment, { children: [jsxs(Modal.Body, { children: [jsx(Title, { children: "Your New Recovery Codes" }), jsx(Description, { children: "Use these recovery codes to sign in if you lose access to the authentication app. Store them somewhere safe!" }), jsx(RecoveryCodes, Object.assign({ "data-testid": "recovery-codes-list" }, { children: recoveryCodes.map((code, index) => (jsx(RecoveryCode, { children: code }, `${code}${index}`))) })), jsxs(Buttons, { children: [jsx(PrintToPDFButton, Object.assign({ codes: recoveryCodes }, { children: "Print Codes (PDF)" })), jsx(CopyCodesButton, { codes: recoveryCodes })] }), jsx(Link, Object.assign({ onClick: e => {
                            e.preventDefault();
                            onNextStep(1);
                        }, isUnderlined: true, external: true }, { children: "Generate new recovery codes" }))] }), jsx(Modal.Footer, { children: jsx(Button, Object.assign({ variant: "primary", onClick: () => onNextStep(), size: "40" }, { children: "Finish" })) })] }));
};

export { ThirdStep };

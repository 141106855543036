import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';

/**
 * Renders a simple external link wrapper that opens in a new tab.
 */
const ExternalLink = (_a) => {
    var { title, children, 'aria-label': ariaLabel } = _a, rest = __rest(_a, ["title", "children", 'aria-label']);
    return (jsx("a", Object.assign({}, rest, { title: title, "aria-label": ariaLabel || title, rel: "noopener noreferrer", target: "_blank" }, { children: children })));
};

export { ExternalLink };

import { __rest } from 'tslib';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { ErrorMessage } from './ErrorMessage.js';
import { ReactComponent } from '@sketch/icons/logo';

const GenericError = props => (jsx(ErrorMessage, Object.assign({}, props)));
GenericError.defaultProps = {
    title: 'Something went wrong',
    /* "iconSize" is not a known property of SVG logos so needs to be extracted */
    icon: function Icon(_a) {
        var { iconSize } = _a, props = __rest(_a, ["iconSize"]);
        return jsx(ReactComponent, Object.assign({ width: "46px", height: "46px" }, props));
    },
    iconSize: 'small',
    description: (jsxs(Fragment, { children: ["We\u2019re looking into the issue. Try again later or visit our", ' ', jsx("a", Object.assign({ href: "https://status.sketch.com/" }, { children: "Status Page" })), "."] })),
};

export { GenericError };

import { useLocation } from 'react-router';
import { getRedirectStateFromLocationObject } from '@sketch/modules-common';

const redirectIdToAnalyticsContextMap = {
    /**
     * Listing 'document-invite' here but the document invite works in a different way
     * and does not use location.state.from and therefore is not a real possible scenario
     * as part of useFormContextForAnalytics().
     */
    'document-invite': 'share invite',
    'project-invite': 'project invite',
    'workspace-invite': 'workspace invite',
};
/**
 * Returns the value of the signup form context we need to send with Plausible
 * signup events ({ formContext } prop of the events).
 * This tells us if the user signs up in the context of an invite (project/workspace/document)or not.
 */
function useSignUpFormContextForAnalytics() {
    const { state } = useLocation();
    if (state === null || state === void 0 ? void 0 : state.from) {
        const redirectState = getRedirectStateFromLocationObject(state.from);
        if (redirectState) {
            return redirectIdToAnalyticsContextMap[redirectState.redirectId];
        }
    }
    return 'main';
}

export { useSignUpFormContextForAnalytics };

import React from 'react'

import { CANVAS_VIEW_DOCS } from '../../constants'

import { PageCanvasError } from 'modules/shares/PageCanvasView/components/PageCanvasError'
import { useSketchWebSupport } from '../../hooks'

type PageCanvasSupportGateProps = {
  children: React.ReactElement
}

/**
 * Handles showing alternative content if the Web Renderer is not supported.
 */
export function PageCanvasSupportGate({
  children,
}: PageCanvasSupportGateProps) {
  const {
    supported,
    detail: { webGL, wasm },
  } = useSketchWebSupport()

  if (supported) return children

  if (!webGL)
    return (
      <PageCanvasError
        title="Canvas view not available"
        description={
          <>
            Canvas view requires WebGL, but it‘s either disabled or not
            supported by your browser. <a href={CANVAS_VIEW_DOCS}>Learn more</a>{' '}
            about the canvas view beta.
          </>
        }
      />
    )

  if (!wasm)
    return (
      <PageCanvasError
        title="Canvas view not available"
        description={
          <>
            Canvas view requires WebAssembly, but it‘s either disabled or not
            supported by your browser. <a href={CANVAS_VIEW_DOCS}>Learn more</a>{' '}
            about the canvas view beta.
          </>
        }
      />
    )

  // Note: No real chance of rendering `null` here, as long as all scenarios are
  // handled above. Just added for an explicit return type.
  return null
}

import React, { useState } from 'react'
import { validateImage, castError } from '@sketch/utils'
import { LoadingPlaceholder, Button, FileSelection } from '@sketch/components'
import {
  HeaderBanner,
  HeaderOptions,
  HeaderWrapper,
} from './DesignSystem.styles'
import { useToast } from '@sketch/toasts'
import { useUpdateDesignSystemMutation } from '@sketch/gql-types'

export const Header = ({
  headerURL,
  designSystemId,
  userIsGuest,
}: {
  headerURL: string | null
  designSystemId: string
  userIsGuest: boolean
}) => {
  const { showToast } = useToast()
  const [newURL, setNewUrl] = useState<string | null | undefined>(undefined)

  const [updateMutation, { loading }] = useUpdateDesignSystemMutation({
    onCompleted: ({ updateDesignSystem }) => {
      setNewUrl(updateDesignSystem.header)
    },
    onError: 'show-toast',
  })

  const handleRemove = () => {
    updateMutation({
      variables: {
        identifier: designSystemId,
        input: {
          header: null,
        },
      },
    })
  }
  const handleUploadNew = async (file: File) => {
    try {
      await validateImage(file, 'DesignSystemHeader')

      updateMutation({
        variables: {
          identifier: designSystemId,
          input: {
            header: file,
          },
        },
      })
    } catch (e) {
      const error = castError(e)
      showToast(error.message, 'negative')
    }
  }

  //   undefined is used to indicate that the header hasn't been removed or updated at all
  //   we want the null of the newURL to be considered; when you had a header and remove it,
  //   you want to see the placeholder and not the previous header (the one on the props)
  const url = newURL !== undefined ? newURL : headerURL

  return (
    <HeaderWrapper>
      <HeaderBanner $loading={loading} bgSrc={url}>
        {loading && <LoadingPlaceholder size="64px" />}
      </HeaderBanner>
      {!userIsGuest && (
        <HeaderOptions>
          <FileSelection
            onFileSelected={handleUploadNew}
            accept=".png, .jpg, .jpeg"
          >
            {({ onSelectFile }) => (
              <Button
                variant="secondary-untinted"
                size="24"
                disabled={loading}
                onClick={onSelectFile}
              >
                {url ? `Change header` : `Upload Header`}
              </Button>
            )}
          </FileSelection>
          {url && (
            <Button
              variant="secondary-untinted"
              size="24"
              disabled={loading}
              onClick={handleRemove}
            >
              Remove
            </Button>
          )}
        </HeaderOptions>
      )}
    </HeaderWrapper>
  )
}

import React from 'react'
import { useTheme } from 'styled-components'

import { WebRendererCanvas } from 'components/WebRendererCanvas'
import { PageCanvasSupportGate } from 'modules/shares/PageCanvasView/components/PageCanvasSupportGate'
import { CanvasRendererProvider } from '@sketch-hq/sketch-web-renderer'
import {
  OriginContent,
  RepresentativeArtboardsInfoOverlay,
} from 'modules/canvas/components'

import PublicationCanvasControls from '../PublicationCanvasControls'

import { CanvasContainer } from './PublicationCanvas.styles'

import { PublicationPageFragment } from '@sketch/gql-types'

interface PublicationCanvasProps {
  shareIdentifier: string
  versionShortId: string
  page: PublicationPageFragment
  onPageSelected: (page: PublicationPageFragment) => void
}

const PublicationCanvas: React.FC<PublicationCanvasProps> = ({
  shareIdentifier,
  versionShortId,
  page,
  onPageSelected,
}) => {
  const theme = useTheme()

  if (!page.uuid) return null

  return (
    <CanvasRendererProvider>
      <PageCanvasSupportGate>
        <CanvasContainer>
          <WebRendererCanvas
            pageUUID={page.uuid}
            page={page}
            versionShortId={versionShortId}
            shareIdentifier={shareIdentifier}
            backgroundColor={theme.colors.background.secondary.B}
          >
            <OriginContent>
              <RepresentativeArtboardsInfoOverlay disableHover />
            </OriginContent>
          </WebRendererCanvas>
          <PublicationCanvasControls
            identifier={shareIdentifier}
            page={page}
            onPageSelected={onPageSelected}
          />
        </CanvasContainer>
      </PageCanvasSupportGate>
    </CanvasRendererProvider>
  )
}

export default PublicationCanvas

import React, { FC, useEffect } from 'react'

import {
  Box,
  Caption,
  ErrorMessage,
  InfiniteList,
  UnstyledList,
  useBreakpoint,
} from '@sketch/components'

import NotificationListItem from './NotificationListItem'
import { NotificationListItemSkeleton } from './NotificationListItemSkeleton'
import { NotificationSearch as NotificationSearchNew } from './NotificationSearch/NotificationSearch'
import { NotificationSearch as NotificationSearchOld } from './NotificationSearch/NotificationSearchOld'
import {
  WrapperList,
  EmptyStateWrapper,
} from 'modules/user/views/Updates/Updates.styles'

import { ReactComponent as BellIcon } from '@sketch/icons/bell-filled-64'

import { NotificationInfoFragment } from '@sketch/gql-types'
import { useNotificationSearchContext } from '../../context/NotificationSearchContext'
import { useFlag } from '@sketch/modules-common'

/**
 * TYPES
 */
interface NotificationsListProps {
  loading: boolean
  loadMore: () => Promise<any>
  entries?: NotificationInfoFragment[]
  subscribeToMore: () => void
  markNotificationAsRead: (id: string) => void
  markAllNotificationAsSeen: () => void
  pageSize?: number
}

/**
 * MAIN COMPONENT
 */
const NotificationsList: FC<NotificationsListProps> = ({
  subscribeToMore,
  markAllNotificationAsSeen,
  markNotificationAsRead,
  entries,
  loading,
  loadMore,
  pageSize = 20,
}) => {
  // TODO: Remove FF "ui-refresh-24" when its released
  // https://github.com/orgs/sketch-hq/projects/326/views/1?pane=issue&itemId=65739330
  const isRefreshedUi = useFlag('ui-refresh-24')

  const NotificationSearch = isRefreshedUi
    ? NotificationSearchNew
    : NotificationSearchOld

  const isTabletOrBigger = useBreakpoint('sm')

  const { search, isOptionsEmpty } = useNotificationSearchContext()

  useEffect(() => {
    subscribeToMore()
    markAllNotificationAsSeen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasNotifications = entries?.length !== 0

  const renderList = () => {
    if (loading && !entries)
      return <NotificationListItemSkeleton pageSize={pageSize} />

    // case when switching on and off search criteria
    // we don't want to show the skeleton without the search bar
    if (loading && !hasNotifications) {
      return (
        <>
          {!isRefreshedUi && <NotificationSearch disabled />}
          <NotificationListItemSkeleton pageSize={pageSize} />
        </>
      )
    }

    if (!hasNotifications && !search && isOptionsEmpty()) {
      return (
        <>
          {!isRefreshedUi && <NotificationSearch disabled />}
          <Box width="100%" height="100%" px={6} UNSAFE_py="50px">
            <ErrorMessage
              icon={<BellIcon />}
              iconSize="medium"
              title="No unread updates"
            />
          </Box>
        </>
      )
    }

    const { unreadNotifications } = splitNotificationTypes(entries)

    const hasUnreadNotifications = unreadNotifications.length > 0

    const renderNotification = (notification: NotificationInfoFragment) => (
      <li key={notification.identifier}>
        <NotificationListItem
          isRead={!!notification.isRead}
          notification={notification}
          markNotificationAsRead={() => {
            markNotificationAsRead(notification.identifier)
          }}
          isVisuallyRead={notification.isRead || false}
        />
      </li>
    )

    return (
      <>
        {isRefreshedUi ? (
          !isTabletOrBigger && <NotificationSearch />
        ) : (
          <NotificationSearch />
        )}
        {hasUnreadNotifications ? (
          <InfiniteList
            onLoadMore={loadMore}
            renderLoading={() => <NotificationListItemSkeleton pageSize={5} />}
          >
            <WrapperList $isRefreshedUi={isRefreshedUi}>
              <UnstyledList>
                {unreadNotifications.map(renderNotification)}
              </UnstyledList>
            </WrapperList>
          </InfiniteList>
        ) : (
          <EmptyStateWrapper>
            <Caption fontSize="E" textAlign="center" m={0}>
              {search ? (
                <ErrorMessage
                  icon={<BellIcon />}
                  iconSize="medium"
                  title="No notifications matching the search criteria"
                />
              ) : (
                <ErrorMessage
                  icon={<BellIcon />}
                  iconSize="medium"
                  title="No unread updates"
                />
              )}
            </Caption>
          </EmptyStateWrapper>
        )}
      </>
    )
  }

  return renderList()
}

export default NotificationsList

function splitNotificationTypes(
  notifications: NotificationInfoFragment[] = []
) {
  const notificationsFormat: {
    unreadNotifications: NotificationInfoFragment[]
    readNotifications: NotificationInfoFragment[]
  } = {
    unreadNotifications: [],
    readNotifications: [],
  }

  return (
    notifications.reduce(
      ({ unreadNotifications, readNotifications }, notification) => {
        if (notification.isRead) {
          readNotifications.push(notification)
        } else {
          unreadNotifications.push(notification)
        }

        return { unreadNotifications, readNotifications }
      },
      notificationsFormat
    ) || notificationsFormat
  )
}

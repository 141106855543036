import { jsx, jsxs } from 'react/jsx-runtime';
import styled from 'styled-components';
import { SKETCH_WEBSITE } from '@sketch/env-config';
import '../../routes/index.js';
import { getIsForInAppPresentation } from '../../routes/useSketchSource/useSketchSource.js';

const FooterWrapper = styled.footer `
  width: 100%;
  font-size: 0.8125rem;
  padding: 48px 16px 16px;
  transition: all
    ${({ theme }) => `${theme.transitions.duration[2]} ${theme.transitions.timing.easeOut}`};

  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
  }

  li {
    margin: 8px 12px;
  }

  a,
  span {
    transition: color ${({ theme }) => theme.transitions.duration[1]};
    white-space: nowrap;
    text-decoration: none;

    color: ${({ theme }) => theme.colors.foreground.secondary.D};
  }

  li a:hover,
  li span:hover {
    cursor: pointer;
    color: inherit;
  }
`;
const Footer = ({ className, extraContent, }) => {
    const isForInAppPresentation = getIsForInAppPresentation();
    const target = isForInAppPresentation ? '_self' : '_blank';
    return (jsx(FooterWrapper, Object.assign({ className: className }, { children: jsxs("ul", { children: [jsx("li", { children: jsx("a", Object.assign({ href: `${SKETCH_WEBSITE}/tos`, target: target, rel: "noopener noreferrer" }, { children: "Terms of Service" })) }), jsx("li", { children: jsx("a", Object.assign({ href: `${SKETCH_WEBSITE}/support/contact`, target: target, rel: "noopener noreferrer" }, { children: "Support" })) }), extraContent, jsx("li", { children: jsxs("a", Object.assign({ href: `${SKETCH_WEBSITE}/home`, target: target, rel: "noopener noreferrer" }, { children: ["\u00A9 ", new Date().getFullYear(), " Sketch B.V."] })) })] }) })));
};

export { Footer };

import { __rest, __awaiter } from 'tslib';
import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import debounce from 'debounce';
import '../Box/index.js';
import { Form } from '../Form/index.js';
import '../PasswordInput/index.js';
import { PasswordCriteria } from './PasswordCriteria.js';
import { PasswordStrengthMeter } from './PasswordStrengthMeter.js';
import { loadZxcvbn } from './CreatePasswordInput.utils.js';
import { PasswordInput } from '../PasswordInput/PasswordInput.js';
import { Text } from '../Box/BoxSystem.js';

const PasswordTipsArea = styled.div `
  /*
    Removing this component with JSX is introducing a bug probably related to
    ReactContext. So we're just hidding it with CSS instead of removing.
  */
  display: ${({ show }) => (show ? 'block' : 'none')};
  margin-top: 8px;
`;
const CreatePasswordInput = (_a) => {
    var { label = 'Create a password', onChange, value } = _a, props = __rest(_a, ["label", "onChange", "value"]);
    const [password, setPassword] = useState((value === null || value === void 0 ? void 0 : value.toString()) || '');
    const [showTips, setShowTips] = useState(false);
    const [strength, setStrength] = useState();
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.addEventListener('focus', () => setShowTips(true));
        }
    }, [inputRef]);
    useEffect(() => {
        function updateStrength() {
            return __awaiter(this, void 0, void 0, function* () {
                const zxcvbn = yield loadZxcvbn();
                if (!zxcvbn)
                    return;
                setStrength(zxcvbn.default(password));
            });
        }
        updateStrength();
    }, [password]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updatePassword = useCallback(debounce((password) => setPassword(password), 250), []);
    const handleChange = (e) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(e);
        updatePassword(e.target.value);
    };
    const attendToLengthCriteria = password.length >= 8;
    const attendToStrengthCriteria = strength ? strength.score >= 2 : false;
    return (jsxs(Form.Field, Object.assign({ label: label, name: "password" }, { children: [jsx(PasswordInput, Object.assign({ ref: inputRef, onChange: handleChange }, props)), jsxs(PasswordTipsArea, Object.assign({ show: showTips }, { children: [jsx(Text.Span, Object.assign({ mt: 2, mb: 0, textStyle: "copy.quaternary.standard.C" }, { children: "Your password must have:" })), jsx(PasswordCriteria, { attendToCriteria: attendToLengthCriteria, text: "8 or more characters" }), jsx(PasswordCriteria, { attendToCriteria: attendToStrengthCriteria, text: jsxs(Fragment, { children: [jsx(Text.Span, Object.assign({ mr: 1, color: attendToStrengthCriteria ? 'state.positive.A' : 'sketch.C' }, { children: "Okay" })), "or better strength"] }) }), strength && (jsx(PasswordStrengthMeter, { password: password, strength: strength }))] }))] })));
};

export { CreatePasswordInput };

import styled, { css } from 'styled-components';
import '../../Box/index.js';
import { Box } from '../../Box/BoxSystem.js';

const DropdownHeader = styled(Box)(({ theme: { colors, fontWeights, fontSizes } }) => css `
    margin-left: 20px;
    padding: 8px 0;

    color: ${colors.foreground.secondary.D};
    font-size: ${fontSizes.B};
    font-weight: ${fontWeights.semibold};

    text-align: left;
    text-transform: uppercase;
    pointer-events: none;
  `);

export { DropdownHeader };

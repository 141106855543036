import { useCollectionSearchArgument } from 'modules/collections/hooks'

import {
  invalidateProjectShares,
  removeSharesFromCollection,
  invalidateProjectCollections,
} from './utils'
import { readShareWithProjectFragment } from 'modules/projects/operations/utils/project.cache'

// GraphQL
import {
  useRemoveSharesFromCollectionMutation,
  RemoveSharesFromCollectionMutation,
  ShareListItemFragment,
} from '@sketch/gql-types'

interface UseRemoveSharesFromCollectionProps {
  projectIdentifier: string
  onCompleted?: (data: RemoveSharesFromCollectionMutation) => void
}

export const useRemoveSharesFromCollection = ({
  projectIdentifier,
  onCompleted,
}: UseRemoveSharesFromCollectionProps) => {
  const search = useCollectionSearchArgument()

  const [mutation] = useRemoveSharesFromCollectionMutation({
    onCompleted,
    onError: 'show-toast',
  })

  const mutationWithCacheUpdate: typeof mutation = (options = {}) => {
    const shareIdentifiers = options.variables?.shareIdentifiers ?? []

    return mutation({
      ...options,
      update: (cache, { data }) => {
        if (!data) {
          return
        }

        const { collection } = data.removeSharesFromCollection

        const shares = shareIdentifiers
          .map(identifier => readShareWithProjectFragment(cache, identifier))
          .filter(maybeShare => maybeShare !== null) as ShareListItemFragment[]

        // Remove the shares from the collection
        removeSharesFromCollection({
          cache,
          projectIdentifier,
          collectionIdentifier: collection.identifier,
          search,
          sharesToRemove: shares,
        })

        // Invalidate any cached `project.shares` and `project.collections`
        // records so that they are refetched from the GraphQL API
        invalidateProjectShares({ cache, projectIdentifier })
        invalidateProjectCollections({ cache, projectIdentifier })
      },
    })
  }

  return mutationWithCacheUpdate
}

import styled from 'styled-components';
import { breakpoint } from '@sketch/global-styles';
import '../Filter/index.js';
import '../FilterOld/index.js';
import { Filter } from '../Filter/Filter.js';
import { Filter as Filter$1 } from '../FilterOld/FilterOld.js';

const NavbarFilter = styled(Filter) ``;
const NavbarFilterOld = styled(Filter$1) `
  min-width: 170px;

  input[type='text'] {
    background: white;
  }

  ${breakpoint('xs') `
    max-width: 300px;
  `}
`;

export { NavbarFilter, NavbarFilterOld };

import { useRef, useEffect } from 'react';

const MountHandle = ({ onMount, onUnmount }) => {
    const mountRef = useRef();
    mountRef.current = onMount;
    const unmountRef = useRef();
    unmountRef.current = onUnmount;
    useEffect(() => {
        mountRef.current && mountRef.current();
        return () => {
            if (unmountRef.current) {
                unmountRef.current();
            }
        };
    }, []);
    return null;
};

export { MountHandle };

const notificationMarkAllAsReadOptimisticResponse = {
    notificationMarkAllAsRead: {
        __typename: 'NotificationMarkAllAsReadPayload',
        successful: true,
        errors: [],
    },
};
const getNotificationsQueryOptions = {
    fetchPolicy: 'network-only',
    // Ignore `FORBIDDEN` errors for notifications where you've lost access to the share
    // ref: https://github.com/sketch-hq/Sketch/issues/23202
    errorPolicy: 'all',
};

export { getNotificationsQueryOptions, notificationMarkAllAsReadOptimisticResponse };

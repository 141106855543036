import { jsx, Fragment } from 'react/jsx-runtime';
import { useLocation, matchPath } from 'react-router-dom';
import { EMBEDDED_SIGNIN_POPUP_WINDOW_NAME } from '@sketch/constants';
import '../../routes/index.js';
import { routes } from '../../routes/routes.js';

/**
 * If rendering inside a sign-in popup window, only sign-in routes are allowed.
 * Otherwise it will render children without restriction.
 */
const EmbedSignInGate = props => {
    const location = useLocation();
    if (window.name === EMBEDDED_SIGNIN_POPUP_WINDOW_NAME) {
        const isSignInRoute = matchPath(location.pathname, routes.SIGN_IN.template());
        return isSignInRoute ? jsx(Fragment, { children: props.children }) : null;
    }
    return jsx(Fragment, { children: props.children });
};

export { EmbedSignInGate };

import { jsx } from 'react/jsx-runtime';
import React from 'react';
import RouteBasedLoadingPage from './RouteBasedLoadingPage.js';

const LoadingPageContext = React.createContext({
    component: RouteBasedLoadingPage,
});
const LoadingPageProvider = ({ component = RouteBasedLoadingPage, children }) => {
    return (jsx(LoadingPageContext.Provider, Object.assign({ value: { component } }, { children: children })));
};

export { LoadingPageContext, LoadingPageProvider };

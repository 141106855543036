import React from 'react'

import {
  AccessLevelSelect,
  AccessLevelSelectOption,
  AccessLevelSelectCheckbox,
  AccessLevelSelectSeparator,
  AccessLevelSelectHeader,
  AccessLevelSelectStyledPill,
  AccessLevelSelectProps,
  useFlag,
} from '@sketch/modules-common'

import { Tooltip } from '@sketch/components'

// Usage of PublicAccessLevel and PrivateAccessLevel will not break the build
// eslint-disable-next-line no-restricted-imports
import { PublicAccessLevel } from '@sketch/gql-types/expansive'

interface PublicPermissionsAccessSelectProps
  extends AccessLevelSelectProps<PublicAccessLevel, PublicAccessLevel> {
  levelDisabled?: boolean
  published?: boolean
  publicationDisabled?: boolean
  setPublicationEnabled?: (e: React.ChangeEvent<HTMLInputElement>) => void
  optionsDisabled?: boolean
}

export const PublicPermissionsAccessSelect = (
  props: PublicPermissionsAccessSelectProps
) => {
  const {
    level,
    setLevel,
    setCommentsEnabled,
    commentsEnabled,
    setInspectEnabled,
    inspectEnabled,
    hideCommentLabel,
    optionsDisabled,
    published,
    publicationDisabled,
    setPublicationEnabled,
    levelDisabled,
    disabled,
  } = props

  const isPublishToCommunityEnabled = useFlag('publish-community')

  return (
    <AccessLevelSelect
      title="Public Access"
      level={level}
      setLevel={setLevel}
      commentsEnabled={commentsEnabled}
      hideCommentLabel={hideCommentLabel}
      noAccessRedColor={false}
      disabled={disabled}
    >
      <Tooltip
        disabled={!levelDisabled}
        placement="right"
        spacing="-50px"
        content="Only Admins can change this setting"
      >
        <AccessLevelSelectOption
          data-testid="public-access-level-none"
          level="NONE"
          label="No Access"
          help="Only people invited to the document have access"
          disabled={levelDisabled}
        />
      </Tooltip>
      <AccessLevelSelectOption
        data-testid="public-access-level-view"
        level="VIEW"
        label="View"
        help="View document in the web app"
        disabled={levelDisabled}
      />
      <AccessLevelSelectSeparator />
      <AccessLevelSelectCheckbox
        data-testid="inspect-enabled"
        label="Can download and inspect"
        onChange={setInspectEnabled}
        checked={inspectEnabled}
        disabled={optionsDisabled}
      />
      <AccessLevelSelectCheckbox
        data-testid="comments-enabled"
        label="Can comment"
        onChange={setCommentsEnabled}
        checked={commentsEnabled}
        disabled={optionsDisabled}
      />

      {isPublishToCommunityEnabled && (
        <>
          <AccessLevelSelectSeparator />
          <AccessLevelSelectHeader>
            Public Profile{' '}
            <AccessLevelSelectStyledPill variant="secondary">
              Admin
            </AccessLevelSelectStyledPill>
          </AccessLevelSelectHeader>
          <AccessLevelSelectCheckbox
            data-testid="community-enabled"
            label="Publish to profile"
            help="Can be publicly viewed and downloaded"
            onChange={setPublicationEnabled}
            checked={published}
            disabled={publicationDisabled}
          />
        </>
      )}
    </AccessLevelSelect>
  )
}

import React, { useContext, useEffect } from 'react'
import { useUserSignedIn } from '@sketch/modules-common'

import { Dropdown, ModalContext } from '@sketch/components'
import DownloadOptions from './DownloadOptions'
import RenameAction from './RenameAction'
import CopyLink from './CopyLink'
import ManageProjectAndCollectionActions from './ManageProjectAndCollectionActions'
import { DeleteShareModal } from '../DeleteShareModal'
import LeaveShareModal from '../LeaveShareModal'
import { ExportDesignTokensModal } from 'modules/shares/modals/ExportDesignTokensModal'
import { DuplicateShareOption } from '../DuplicateShareOption'
import { getEligibleWorkspacesForDuplication } from 'modules/workspace/utils/getEligibleWorkspacesForDuplication/getEligibleWorkspacesForDuplication'

import {
  GetShareQueryVariables,
  ShareListItemFragment,
  GetShareDocument,
  useGetWorkspacesForDuplicationQuery,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

import { AnnotationDropdownActions } from 'modules/annotations/components'
import { useSharingModal } from 'modules/shares/hooks/useSharingModal'
import { useDocumentInfoModal } from 'modules/shares/hooks/useDocumentInfoModal'
import { useComponentsState } from 'modules/shares/components/ComponentsStateContext'
import { useApolloClient } from '@apollo/react-hooks'
import {
  DropdownOptions,
  useGetShareDropdownOptions,
} from 'modules/shares/operations/useGetShareDropdownOptions'
import { useHideAnnotationDots } from 'modules/annotations/hooks'
import { useCheckCurrentAuthorizationForWorkspace } from 'modules/sso/operations/useCheckCurrentAuthorizationForWorkspace'
import { PinDocumentWrapper as PinDocument } from './PinDocument'

export interface ShareForShareDropdown
  extends Pick<
    ShareListItemFragment,
    | 'identifier'
    | 'name'
    | 'project'
    | 'userCanUpdateSettings'
    | 'publicAccessLevel'
    | 'type'
    | 'pinnedByCurrentUserAt'
    | 'collection'
  > {}

export interface VersionsForDropdown {
  current: { shortId: string }
  latest: { shortId: string }
}

// This is just to preload the data if it wasn't loaded already
// It should give faster rendering speeds when any of the options is chosen
const usePrefetchGetShare = (identifier: string) => {
  const client = useApolloClient()

  useEffect(() => {
    const variables: GetShareQueryVariables = {
      shortId: identifier,
    }
    client.query({
      query: GetShareDocument,
      variables,
      fetchPolicy: 'cache-first',
    })
  }, [client, identifier])
}

interface LoadingDropdownOptions extends ShareActionsDropdownProps {
  dropdownOptions: DropdownOptions
  workspaceStatus?: WorkspaceMinimalFragment['status']
}

const DownloadOptionsLoading = (props: LoadingDropdownOptions) => {
  const { dropdownOptions, workspaceStatus } = props

  if (dropdownOptions.loading) return <Dropdown.Skeleton count={2} />
  if (dropdownOptions.error) return null

  const share = { ...props.share, ...dropdownOptions.data.share }

  return (
    <DownloadOptions
      document={dropdownOptions.data.document}
      share={share}
      download={dropdownOptions.data.download}
      isViewingLatestVersion={props.isViewingLatestVersion}
      workspaceStatus={workspaceStatus}
    />
  )
}

const LeaveShareLoading = (props: LoadingDropdownOptions) => {
  const { dropdownOptions } = props
  const { showModal } = useContext(ModalContext)

  if (dropdownOptions.loading) return <Dropdown.Skeleton />
  if (dropdownOptions.error) return null

  const share = { ...props.share, ...dropdownOptions.data.share }
  if (!share.userCanLeaveShare) return null

  return (
    <>
      <Dropdown.Divider />
      <Dropdown.Item
        intent="negative"
        onClick={() =>
          showModal(LeaveShareModal, {
            shareIdentifier: share.identifier!,
            shareName: share.name!,
          })
        }
      >
        Leave&hellip;
      </Dropdown.Item>
    </>
  )
}

const RenameShareLoading = (props: LoadingDropdownOptions) => {
  const { dropdownOptions, share } = props

  if (dropdownOptions.loading) return <Dropdown.Skeleton />
  if (dropdownOptions.error) return null

  if (!dropdownOptions.data.share.userCanEdit) return null

  const shareIdentifier = share?.identifier
  const shareName = share?.name

  return (
    <>
      <Dropdown.Divider />
      <RenameAction id={shareIdentifier} name={shareName}>
        Rename&hellip;
      </RenameAction>
    </>
  )
}

const AnnotationDropdownActionsLoading = (props: LoadingDropdownOptions) => {
  const { dropdownOptions } = props

  const [hideAnnotations] = useHideAnnotationDots() || []

  if (hideAnnotations === undefined) {
    return null
  }

  if (dropdownOptions.loading) return <Dropdown.Skeleton />
  if (dropdownOptions.error) return null

  if (!dropdownOptions.data.share.commentsEnabled) return null

  const { identifier, subscriptionStatus } = dropdownOptions.data.share

  return (
    <>
      <Dropdown.Divider />
      <AnnotationDropdownActions
        shareIdentifier={identifier}
        subscriptionStatus={subscriptionStatus}
      />
    </>
  )
}

const DuplicateShareLoading = (props: LoadingDropdownOptions) => {
  const {
    share,
    dropdownOptions,
    workspaceIdentifier,
    onDuplicateShareToDraftsClick,
    onDuplicateShareToClick,
  } = props

  const {
    error: getWorkspacesError,
    loading: getWorkspacesLoading,
    data: getWorkspacesData,
  } = useGetWorkspacesForDuplicationQuery()

  const { checkAuthorization } = useCheckCurrentAuthorizationForWorkspace()

  if (dropdownOptions.loading || getWorkspacesLoading) {
    return <Dropdown.Skeleton />
  }

  if (dropdownOptions.error || getWorkspacesError || !getWorkspacesData) {
    return null
  }

  const workspaces = getWorkspacesData!.me.workspaces
  const userCanInspect = dropdownOptions.data.share.userCanInspect

  const {
    userCanDuplicate,
    currentWorkspace,
    otherWorkspaces,
  } = getEligibleWorkspacesForDuplication(
    workspaces,
    dropdownOptions.data.share.workspace.identifier,
    dropdownOptions.data.share.publicAccessLevel,
    { hasAccessToWorkspaceCheckFn: checkAuthorization }
  )

  const isLoggedInWithProperSSOCredentials = currentWorkspace
    ? checkAuthorization(currentWorkspace)
    : true

  if (
    !userCanInspect ||
    !userCanDuplicate ||
    !isLoggedInWithProperSSOCredentials ||
    !dropdownOptions.data.document.sketchVersion
  ) {
    return null
  }

  const sketchVersion = dropdownOptions.data.document.sketchVersion!

  return (
    <DuplicateShareOption
      workspaceIdentifier={workspaceIdentifier}
      sketchVersion={sketchVersion}
      moreThanOneEligible={!currentWorkspace && Boolean(otherWorkspaces.length)}
      onDuplicateShareToDraftsClick={onDuplicateShareToDraftsClick}
      onDuplicateShareToClick={onDuplicateShareToClick}
      share={share}
    />
  )
}

export interface ShareActionsDropdownProps {
  share: ShareForShareDropdown
  workspaceIdentifier: string
  isViewingLatestVersion?: boolean
  versions: VersionsForDropdown
  onDuplicateShareToDraftsClick: () => void
  onDuplicateShareToClick: () => void
  queryVariables: any
  workspaceStatus?: WorkspaceMinimalFragment['status']
  showAnnotationActions?: boolean
}

export const ShareActionsDropdown: React.FC<ShareActionsDropdownProps> = props => {
  const {
    share,
    versions,
    workspaceIdentifier,
    queryVariables,
    workspaceStatus,
    showAnnotationActions,
  } = props

  const isSignedIn = useUserSignedIn()
  const dropdownOptions = useGetShareDropdownOptions({
    share,
    version: versions.current,
  })
  usePrefetchGetShare(share.identifier)
  const componentsState = useComponentsState()

  const { showModal } = useContext(ModalContext)
  const { openShareSettingsModal } = useSharingModal(share)
  const { openDocumentInfoModal } = useDocumentInfoModal({ share, versions })

  const shareIdentifier = share?.identifier
  const shareName = share?.name
  const pinned = dropdownOptions.data?.share.pinnedByCurrentUserAt
  const isActive = workspaceStatus === 'ACTIVE'

  if (!share.userCanUpdateSettings) {
    return (
      <div data-testid="share-dropdown">
        <Dropdown.Header>Document</Dropdown.Header>
        <DownloadOptionsLoading
          {...props}
          dropdownOptions={dropdownOptions}
          workspaceStatus={workspaceStatus}
        />
        {isSignedIn && isActive && (
          <DuplicateShareLoading {...props} dropdownOptions={dropdownOptions} />
        )}
        {isActive && (
          <PinDocument
            pinned={pinned}
            shareIdentifier={shareIdentifier}
            workspaceIdentifier={workspaceIdentifier}
            queryVariables={queryVariables}
          />
        )}
        <Dropdown.Divider />
        <CopyLink shareIdentifier={share.identifier} />
        {isActive && (
          <>
            <RenameShareLoading {...props} dropdownOptions={dropdownOptions} />
            {showAnnotationActions && (
              <AnnotationDropdownActionsLoading
                {...props}
                dropdownOptions={dropdownOptions}
              />
            )}
            <LeaveShareLoading {...props} dropdownOptions={dropdownOptions} />
          </>
        )}
      </div>
    )
  }

  return (
    <div data-testid="share-dropdown">
      <Dropdown.Header>Document</Dropdown.Header>
      <DownloadOptionsLoading {...props} dropdownOptions={dropdownOptions} />
      {isSignedIn && isActive && (
        <DuplicateShareLoading {...props} dropdownOptions={dropdownOptions} />
      )}
      {isActive && (
        <PinDocument
          pinned={pinned}
          shareIdentifier={shareIdentifier}
          workspaceIdentifier={workspaceIdentifier}
          queryVariables={queryVariables}
        />
      )}
      <Dropdown.Divider />
      <Dropdown.Item onClick={() => openDocumentInfoModal()}>
        Settings&hellip;
      </Dropdown.Item>
      {isActive && (
        <Dropdown.Item onClick={() => openShareSettingsModal()}>
          Share&hellip;
        </Dropdown.Item>
      )}
      <CopyLink shareIdentifier={share.identifier} />
      {isActive && (
        <>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() =>
              showModal(ExportDesignTokensModal, {
                shareIdentifier: share.identifier!,
                versionShortId: versions.current.shortId!,
                userCanEditExportUrl: share.userCanUpdateSettings,
                componentsState,
              })
            }
          >
            Export Design Tokens&hellip;
          </Dropdown.Item>
        </>
      )}
      {isActive && (
        <>
          <Dropdown.Divider />
          <RenameAction id={shareIdentifier} name={shareName}>
            Rename&hellip;
          </RenameAction>
          <ManageProjectAndCollectionActions
            share={share}
            workspaceIdentifier={workspaceIdentifier}
          />
          {showAnnotationActions && (
            <AnnotationDropdownActionsLoading
              {...props}
              dropdownOptions={dropdownOptions}
            />
          )}
          <Dropdown.Divider />
          <Dropdown.Item
            intent="negative"
            onClick={() =>
              showModal(DeleteShareModal, {
                name: shareName,
                shareShortId: shareIdentifier,
              })
            }
          >
            Delete&hellip;
          </Dropdown.Item>
        </>
      )}
    </div>
  )
}

import styled from 'styled-components'

import { Pill } from '@sketch/components'

export const StyledPill = styled(Pill)`
  margin-left: auto;

  .active & {
    background-color: ${({ theme }) => theme.colors.sketch.F};
    color: ${({ theme }) => theme.colors.sketch.A};
  }
`

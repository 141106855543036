import styled from 'styled-components';

const SkeletonWrapper = styled.ul `
  display: flex;
  flex-direction: column;
  margin: 0 12px;
  padding: 0;
  list-style: none;
`;
const SkeletonRow = styled.li `
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  [aria-busy='true']:first-child {
    margin-right: 12px;
    flex-shrink: 0;
  }
`;

export { SkeletonRow, SkeletonWrapper };

import React from 'react'
import { Header, LeftContent } from './CommunityHeader.styles'
import LogoLink from '../LogoLink'

const CommunityHeaderPlaceholder = () => (
  <Header>
    <LeftContent>
      <LogoLink />
    </LeftContent>
  </Header>
)

export default CommunityHeaderPlaceholder

import { __rest } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import styled, { css } from 'styled-components';
import { ButtonUnstyled } from './ButtonUnstyled.js';

const UnstyledIconButton = (_a) => {
    var { icon: Icon, size, children, type = 'button' } = _a, props = __rest(_a, ["icon", "size", "children", "type"]);
    return (jsxs(ButtonUnstyled, Object.assign({ type: type }, props, { children: [Icon && (jsx(Icon, { width: size, height: size, focusable: false, "aria-hidden": true })), children] })));
};
UnstyledIconButton.defaultProps = {
    size: '1em',
    buttonSize: '1.75rem',
    type: 'button',
};
/**
 * @deprecated ⚠️ Use with caution. We are trying to think a proper
 * usage of this component. If you need a way to create a button with just an Icon inside,
 * please follow the guide on Storybook
 *
 */
const IconButton = styled(UnstyledIconButton) `
  display: flex;

  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  transition: color ${({ theme }) => theme.transitions.duration[1]};

  > * {
    pointer-events: none;
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.foreground.secondary.B};

    ${({ buttonSize, theme }) => buttonSize &&
    `
        box-shadow: ${theme.shadows.boxShadow.raised};
        border: 1px solid ${theme.colors.border.A};
        background-clip: padding-box;
      `};
  }

  &:active {
    color: ${({ theme }) => theme.colors.foreground.secondary.C};

    ${({ buttonSize, theme }) => buttonSize &&
    `
        box-shadow: ${theme.shadows.boxShadow.lifted};
        border: 1px solid ${theme.colors.border.A};
        background-clip: padding-box;
      `};
  }

  ${({ buttonSize, theme }) => buttonSize &&
    css `
      border-radius: 3px;
      box-shadow: ${theme.shadows.boxShadow.subtle};
      border: 1px solid ${theme.colors.border.A};
      background-clip: padding-box;
      min-height: auto;
      background-color: ${theme.colors.foreground.secondary.A};
      transition: all ${theme.transitions.duration[1]};
      width: ${buttonSize};
      height: ${buttonSize};
    `};

  ${({ disabled }) => disabled &&
    css `
      color: rgba(0, 0, 0, 0.1);
    `};

  ${({ theme, active }) => active &&
    css `
      color: ${theme.colors.sketch.A};

      &:hover,
      &:focus {
        color: ${theme.colors.sketch.A};
      }

      &:active {
        color: ${theme.colors.sketch.A};
      }
    `};
`;

export { IconButton };

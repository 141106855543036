import '../../emojify/index.js';
import { emojify } from '../../emojify/emojify.js';

function locateEmoji(value, fromIndex) {
    return value.indexOf(':', fromIndex);
}
/**
 * TokenizeEmoji.
 *
 * This function is responsible for finding the emoji block and converting to text.
 *
 * @param {Function} eat - Replace the match by an object.
 * @param {string} value - Emoji value caught.
 * @param {boolean} silent - Whether to detect or consume.
 *
 * @returns {Object} - Returns a element of type text with the emoji symbol.
 */
function tokenizeEmoji(eat, value, silent) {
    const match = /^:([\w|-])+:/.exec(value);
    if (match) {
        if (silent) {
            return true;
        }
        return eat(match[0])({
            type: 'text',
            value: emojify(match[0]) || match[0],
        });
    }
}
tokenizeEmoji.notInLink = true;
tokenizeEmoji.locator = locateEmoji;
/**
 * Emoji plugin for the `remark-js`.
 */
function emoji() {
    const Parser = this.Parser;
    const tokenizers = Parser.prototype.inlineTokenizers;
    const methods = Parser.prototype.inlineMethods;
    tokenizers.emoji = tokenizeEmoji;
    /* Add emoji tokenizer before the text tokenizer */
    methods.splice(methods.indexOf('text'), 0, 'emoji');
}

export { emoji };

import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useRouteMatch } from 'react-router'

import AboutTab from '../AboutTab'
import {
  ArtboardAnnotations,
  ShareAnnotations,
} from 'modules/annotations/containers'
import {
  ShareVersions,
  ArtboardRevisions,
} from 'modules/versioning/components/'
import { ShareWithoutVersion } from 'modules/versioning/ShareVersionContext/ShareVersionContext'
import InspectorRestricted from '../../ComponentsWebView/components/InspectorRestricted'
import { useInspectContext } from '../../ComponentsWebView/context/InspectContext'
import { useShareSidebarTab, getActivePanels } from '../ShareSidebarTabContext'
import { PanelWrapper, FakeButton, MobileWrapper } from './Panel.styles'
import { useResponsiveDropdown, useForTablet } from '@sketch/components'
import { isArtboardRoute } from '@sketch/modules-common'
import { usePanelShortcuts } from './hooks'
import { useHideAnnotationDots } from 'modules/annotations/hooks/'
import { ArtboardDetailInfoFragment, VersionFragment } from '@sketch/gql-types'

// This import needs to be specific to prevent circular-dependencies
import { ArtboardDetailInspector } from 'modules/shares/ArtboardDetailView/components/ArtboardDetailInspector'

const ChildrenRender: React.FC = ({ children }) => (
  <PanelWrapper data-testid="panel-component">{children}</PanelWrapper>
)

const DROPDOWN_MODIFIERS = [
  {
    name: 'preventOverflow' as const,
    options: { boundary: 'clippingParents', padding: 8 },
  },
]

interface PanelProps {
  share: ShareWithoutVersion
  version?: VersionFragment
  artboard?: ArtboardDetailInfoFragment
  userCanSeeComments: boolean
  userCanInspect?: boolean
  SidebarRightPortal: React.FunctionComponent<React.PropsWithChildren<{}>>
}

/**
 * This component renders the right floating panel that contains info that
 * before we showed in tabs in the right sidebar, like document info, version
 * list, comments or the inspector.
 */
export const Panel: React.FC<PanelProps> = ({
  userCanInspect,
  userCanSeeComments,
  share,
  version,
  artboard,
  SidebarRightPortal,
}) => {
  /**
   * Enables this shortcuts:
   * - `d` to toggle the document info panel
   * - `i` to toggle the inspector panel
   * - `c` to toggle the comments panel
   * - `v` to toggle the versions panel
   */
  usePanelShortcuts()

  // Used to keep track of the active segment to prevent a transition glitch
  // when the panel disappears, that happens when the panel content is hide
  // before the transition ends
  const lastActiveSegment = useRef<string | null>(null)

  const [hideAnnotations, setHideAnnotations] = useHideAnnotationDots() || []

  // We need to overwrite `hideAnnotations` when the user is inspecting (to
  // avoid showing comments dots while inspecting, that can be annoying), and
  // `prevHideAnnotations` is needed to preserve the previous state of
  // `hideAnnotations` and restore it when the user exits the inspector
  // panel (moves to another or hides it)
  const prevHideAnnotations = useRef<boolean | undefined>(hideAnnotations)

  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  const { path } = useRouteMatch()
  const isArtboardPath = isArtboardRoute(path)

  const activePanels = useMemo(
    () =>
      getActivePanels({
        path,
        canInspect: userCanInspect,
        canComment: userCanSeeComments,
      }),
    [path, userCanInspect, userCanSeeComments]
  )

  const [segments, setSegments] = useState(activePanels)

  // Save the panels the user has access to
  useEffect(() => {
    setSegments(activePanels)
  }, [activePanels])

  const { activeSegment, setActiveSegment } = useShareSidebarTab(segments)
  const { selectedItem, handleSelectItem } = useInspectContext()

  // Enable the "Inspector" panel when the "selectItem" is set
  useEffect(() => {
    if (selectedItem) {
      setActiveSegment('Inspect')
    }
  }, [selectedItem, setActiveSegment])

  // When the panel is changed the inspected item should be unselected
  useEffect(() => {
    if (activeSegment === 'Inspect') {
      return () => {
        handleSelectItem(null)
      }
    }
  }, [activeSegment, handleSelectItem])

  // Hide comment dots when the user is inspecting, and restore the state when
  // the panel changes
  useEffect(() => {
    if (activeSegment === 'Inspect') {
      setHideAnnotations?.(true)

      return () => {
        if (prevHideAnnotations.current !== undefined) {
          setHideAnnotations?.(prevHideAnnotations.current)
        }
      }
    } else {
      // Keep track of `hideAnnotations` changes only when the user is not
      // inspecting, to be able to recover the state when the user exits the
      // inspector panel (moves to another or hides it)
      prevHideAnnotations.current = hideAnnotations
    }
  }, [activeSegment, hideAnnotations, setHideAnnotations])

  // Render the selected tab
  let tab = null

  // Cache the last active segment to prevent a visual bug when closing the panel
  const getActiveSegment = () => activeSegment || lastActiveSegment.current

  switch (getActiveSegment()) {
    case 'About': {
      lastActiveSegment.current = 'About'
      tab = <AboutTab shareIdentifier={share.identifier} />
      break
    }
    case 'Comment': {
      lastActiveSegment.current = 'Comment'
      tab = isArtboardPath ? (
        artboard && (
          <ArtboardAnnotations
            share={share}
            showAddAnnotationButton={!isMobile}
            permanentPageIdentifier={artboard.page!.identifier}
            permanentArtboardIdentifier={artboard.uuid}
            permanentArtboardShortId={artboard.shortId}
            artboardNotificationStatus={artboard.subscriptionStatus}
          />
        )
      ) : (
        <ShareAnnotations share={share} showAddAnnotationButton={!isMobile} />
      )
      break
    }
    case 'Version': {
      lastActiveSegment.current = 'Version'
      tab = isArtboardPath ? (
        artboard && (
          <ArtboardRevisions
            shareIdentifier={share.identifier}
            permanentArtboardIdentifier={artboard.uuid}
            permanentArtboardShortId={artboard.permanentArtboardShortId}
            artboardRevisionIdentifier={artboard.revisionIdentifier}
          />
        )
      ) : (
        <ShareVersions shareIdentifier={share.identifier} />
      )
      break
    }
    case 'Inspect': {
      lastActiveSegment.current = 'Inspect'
      tab = userCanInspect ? (
        <ArtboardDetailInspector share={share} currentVersion={version} />
      ) : (
        <InspectorRestricted />
      )
      break
    }
  }

  const [
    dropdown,
    button,
    { visible, setVisible, update },
  ] = useResponsiveDropdown({
    dropdown: ChildrenRender,
    dropdownProps: { children: tab },
    offset: [8, 8],
    placement: 'bottom-end',
    usePortal: false,
    dropdownStyle: {
      /**
       * This is currently a work-around for limiting the dropdown content
       * to the browser full height, popper should do this automatically but is failing
       * so.
       */
      maxHeight: 'calc(100vh - 66px)',
    },
    modifiers: DROPDOWN_MODIFIERS,
    hide: [],
  })

  // Sync activeSegment with dropdown visibility
  useEffect(() => {
    if (!!activeSegment !== visible) {
      update?.()
      setVisible(state => !state)
    }
  }, [activeSegment, visible, setVisible, update])

  return isMobile ? (
    <SidebarRightPortal>
      <MobileWrapper>{tab}</MobileWrapper>
    </SidebarRightPortal>
  ) : (
    <>
      {/* Not visible because we toggle the visibility programatically, but is
      needed in order to make the dropdown work */}
      <FakeButton {...button} />

      {dropdown}
    </>
  )
}

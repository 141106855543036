import React, { useEffect, useRef } from 'react'

import { ReactComponent as FullStorageIcon } from '@sketch/icons/drive-exclamation-mark24'
import { ReactComponent as StorageIcon } from '@sketch/icons/drive-24'

import {
  Button,
  Link,
  Text,
  pluralize,
  useModalContext,
} from '@sketch/components'

import Panel from '../Panel'
import { ProgressBar } from '../ProgressBar'

import {
  Detail,
  StorageDetails,
  Strong,
  StyledBanner,
  Right,
  Total,
  BottomSection,
} from './WorkspaceStoragePanel.styles'
import {
  GetWorkspaceStorageDetailsQuery,
  useGetWorkspaceStorageDetailsQuery,
} from '@sketch/gql-types'
import ReviewLargeDocumentsModal from 'modules/workspace/modals/ReviewLargeDocumentsModal'
import { formatStorageNumber } from 'modules/workspace/utils/storage'

interface WorkspaceStoragePanelProps {
  workspaceId: string
  storageDetails: GetWorkspaceStorageDetailsQuery
  // This flag is true for Business workspaces and the UI is slightly different
  hasUnlimitedStorage: boolean
  canDeleteLargeFiles: boolean
}

export const WorkspaceStoragePanel: React.FC<WorkspaceStoragePanelProps> = props => {
  const { showModal } = useModalContext()

  const {
    workspaceId,
    storageDetails,
    hasUnlimitedStorage,
    canDeleteLargeFiles,
  } = props
  const { limit, used, shareCount } = storageDetails.workspaceStorage ?? {
    limit: 0,
    used: 0,
    shareCount: 0,
  }

  const { refetch } = useGetWorkspaceStorageDetailsQuery({
    variables: {
      identifier: workspaceId,
    },
    fetchPolicy: 'network-only',
  })

  // This block will allows us to have zero dependencies on the useEffect below
  const mutationRef = useRef(refetch)
  useEffect(() => {
    mutationRef.current = refetch
  }, [refetch])

  // We want to keep the storage values always up to date when the user visits the billing page
  // this will update values if the user emptied the trash, saved a new file or added seats to the workspace
  useEffect(() => {
    mutationRef.current()
  }, [])

  const handleReviewLargeDocuments = () => {
    showModal(ReviewLargeDocumentsModal, {
      workspaceId,
    })
  }

  // "limit" is null for Business workspaces, however we use "hasUnlimitedStorage"
  // for that particular case. We fallback to 0 for easier calculations from here
  const total = limit ?? 0

  const totalFormated = formatStorageNumber(total)
  const usedFormated = formatStorageNumber(used)
  const freeClamped = formatStorageNumber(Math.max(0, total - used))

  const isFull = used >= total
  const Icon = isFull && !hasUnlimitedStorage ? FullStorageIcon : StorageIcon
  const usedSpacePercentage = (used / total) * 100
  const showReviewLargeFilesButton = !hasUnlimitedStorage && canDeleteLargeFiles

  return (
    <Panel>
      <Panel.Title>Workspace Storage</Panel.Title>
      {isFull && !hasUnlimitedStorage && (
        <StyledBanner type="error">
          Workspace storage limit reached. Delete some documents you don’t need
          to free up space.
        </StyledBanner>
      )}
      {hasUnlimitedStorage ? (
        <Text textStyle={'copy.tertiary.standard.E'}>
          You have a Business subscription with unlimited storage.{' '}
          <Link
            href="https://www.sketch.com/redirect/app/unlimited-storage"
            external
          >
            Learn More
          </Link>
        </Text>
      ) : (
        <Text textStyle={'copy.tertiary.standard.E'}>
          Each Editor in a Workspace raises its total storage capacity by 50 GB.
          If you reach the Workspace storage limit, delete some documents you no
          longer need.{' '}
          <Link
            href="https://www.sketch.com/redirect/app/reducing-file-size"
            external
          >
            Learn More
          </Link>
        </Text>
      )}
      <Panel.Body>
        <StorageDetails>
          <Total data-testid="total-storage">
            <Icon width="24" height="24" />
            {hasUnlimitedStorage ? (
              <>&nbsp;Unlimited</>
            ) : (
              <>&nbsp;{totalFormated}</>
            )}
          </Total>
          <Right>
            <Detail data-testid="used-storage">
              <Strong>Used</Strong>&nbsp;{usedFormated}
            </Detail>
            <Detail data-testid="free-storage">
              <Strong>Free</Strong>
              {hasUnlimitedStorage ? (
                <>&nbsp;Unlimited</>
              ) : (
                <>&nbsp;{freeClamped}</>
              )}
            </Detail>
          </Right>
        </StorageDetails>
        {!hasUnlimitedStorage && <ProgressBar progress={usedSpacePercentage} />}
      </Panel.Body>
      <BottomSection>
        <Text textStyle={'copy.tertiary.standard.C'}>
          Your Workspace currently contains{' '}
          <strong>
            {`${shareCount} ${pluralize('Document', 'Documents', shareCount)}`}
          </strong>
          .
        </Text>
        {showReviewLargeFilesButton && (
          <Button
            variant="secondary"
            onClick={handleReviewLargeDocuments}
            size="32"
          >
            Review Large Documents…
          </Button>
        )}
      </BottomSection>
    </Panel>
  )
}

import styled from 'styled-components'

export const Iframe = styled.iframe`
  flex: 1;
  border: none;

  /** We remove the page internal padding to make the iframe look as embedded as possible */
  margin: -48px -52px 0; /* stylelint-disable-line scales/space */
`

export const IframeOld = styled.iframe`
  flex: 1;
  border: none;

  /** We remove the page internal padding to make the iframe look as embedded as possible */
  margin: -40px -40px 0;
`

import styled, { css } from 'styled-components';
import '../Box/index.js';
import '../Button/index.js';
import { ReactComponent } from '@sketch/icons/chevron-right-16';
import { Box } from '../Box/BoxSystem.js';
import { ButtonUnstyled } from '../Button/ButtonUnstyled.js';

const Wrapper = styled(Box)(({ theme: { fontSizes, colors }, dark }) => css `
    display: inline-flex;
    align-items: center;

    border: 1px solid ${colors.border.A};
    border-radius: 5px;

    font-size: ${fontSizes.D};

    ${dark &&
    css `
      background: ${colors.background.primary.B};
      border-color: white;

      > button {
        color: ${colors.foreground.primary.A};

        &:disabled {
          color: ${colors.foreground.primary.B};
        }
      }

      /* divider */
      > div {
        border-color: hsla(0, 0%, 100%, 0.3);
      }
    `}
  `);
const ChevronRight = styled(ReactComponent) `
  width: 16px;
`;
const ChevronLeft = styled(ChevronRight) `
  transform: rotate(180deg);
`;
const Button = styled(ButtonUnstyled)(({ theme }) => css `
    display: flex;
    flex: none;
    justify-content: center;
    align-items: center;

    width: 30px;
    height: 30px;

    cursor: pointer;
    color: ${theme.colors.foreground.secondary.D};

    &:disabled {
      color: ${theme.colors.state.disabled};
    }

    &:hover {
      background: ${theme.colors.state.hover};
    }
  `);
const Pages = styled.div `
  width: 52px; /* Enough space to fit "1 of 99+"" */
  text-align: center;
  font-variant-numeric: tabular-nums; /* Same size numbers */
`;
const Divider = styled.div(({ theme }) => css `
    height: 13px;
    width: 0;
    border-right: 2px dotted ${theme.colors.background.tertiary.A};
  `);

export { Button, ChevronLeft, ChevronRight, Divider, Pages, Wrapper };

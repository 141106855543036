import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTrackEvent } from './useTrackEvent.js';

const useTrackEventInView = (event, eventProps) => {
    const [wasSeen, setWasSeen] = useState(false);
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    const { trackEvent } = useTrackEvent();
    useEffect(() => {
        if (!wasSeen && inView) {
            trackEvent(event, eventProps);
            setWasSeen(true);
        }
    }, [wasSeen, event, eventProps, inView, trackEvent]);
    return { ref };
};

export { useTrackEventInView };

import React from 'react'
import styled from 'styled-components'
import { getTriggerCopyFromAccessLevel } from '@sketch/modules-common'
import { WorkspaceMemberRow } from 'modules/projects/components/MemberRow'
import { EditProjectMemberPermissionsAccessSelect } from 'modules/projects/components/ProjectMemberPermissionsAccessSelect/ProjectMemberPermissionsAccessSelect'
import { useUpdateProjectMember } from 'modules/projects/hooks/useUpdateProjectMember/useUpdateProjectMember'
import { ProjectFragment, ProjectMembershipFragment } from '@sketch/gql-types'

/**
 * This is a copy of the component found in
 * modules/projects/modals/ProjectSharingModal
 * which will soon be deprecated
 */

const PermissionText = styled.span<{ noAccess?: boolean }>`
  padding: 0 12px;
  font-size: ${({ theme }) => theme.fontSizes.D};
  color: ${({ theme, noAccess }) =>
    noAccess
      ? theme.colors.state.negative.A
      : theme.colors.foreground.secondary.D};
`

interface GuestMemberRowProps {
  member: ProjectMembershipFragment
  project: ProjectFragment
  editable?: boolean
}

export const GuestMemberRow = ({
  member,
  project,
  editable,
}: GuestMemberRowProps) => {
  const { accessLevelProps } = useUpdateProjectMember({ member })

  return (
    <WorkspaceMemberRow
      workspaceMembership={member.workspaceMembership}
      inviteStatus={member.inviteStatus}
      actions={
        editable ? (
          <EditProjectMemberPermissionsAccessSelect
            project={project}
            member={member}
            {...accessLevelProps}
            // we don't need the onAction callback in this case
            onAction={() => {}}
          />
        ) : (
          <PermissionText>
            {getTriggerCopyFromAccessLevel(
              member.privateAccessLevel === 'EDIT' ? 'EDIT' : 'VIEW'
            )}
          </PermissionText>
        )
      }
    />
  )
}

import { useMemo, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

/**
 * Hook to retrieve a value that is persisted both in the URL
 * params and in the local storage. If one of those values is
 * outdated, it's gonna be updated with the other value.
 * The URL params are always gonna have priority over the values
 * saved in the local storage.
 * @param paramName
 * @param opts
 *
 * It returns an array with two values:
 * - The value of the parameter
 * - The function to update it's value
 *
 * Notice that it's a generic function but it doesn't parse
 * the value inside of it, so only use it's generic type if
 * you're using types derivated from string.
 *
 * The following options are supported:
 *  * skip: boolean
 *    - skip setting the value in the URL and localstorage. This is useful if
 *    you want to disable the parameter for some reason, e.g. it is behind a
 *    feature flag.
 */
const usePersistedURLQueryParam = (paramName, opts) => {
    const { skip = false } = opts !== null && opts !== void 0 ? opts : {};
    // URL Params
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const paramsValue = searchParams.get(paramName);
    const setParamValue = useCallback((newValue) => {
        if (skip) {
            return;
        }
        searchParams.set(paramName, newValue);
        history.replace({ search: searchParams.toString() });
    }, [searchParams, history, paramName, skip]);
    // LocalStorage
    const storageKey = `query-${paramName}`;
    const [storageValue, setStorageValue] = useLocalStorage(storageKey);
    useEffect(() => {
        if (skip) {
            return;
        }
        if (!paramsValue) {
            if (storageValue) {
                // We don't have a URL param value but have a localstorage one, so
                // let's update the URL param
                setParamValue(storageValue);
            }
        }
        else {
            if (!storageValue) {
                // We have a URL param value but it's not stored in the localstorage,
                // so let's update the localstorage
                setStorageValue(paramsValue);
            }
            else {
                // Here we have both values, so let's check if they're equal and update
                // the localstorage if they're different
                if (storageValue !== paramsValue) {
                    setStorageValue(paramsValue);
                }
            }
        }
    }, [paramsValue, storageValue, setParamValue, setStorageValue, skip]);
    const setNewValue = useCallback((newValue) => {
        if (skip) {
            return;
        }
        setParamValue(newValue);
        setStorageValue(newValue);
    }, [setParamValue, setStorageValue, skip]);
    return [paramsValue, setNewValue];
};

export { usePersistedURLQueryParam };

import { __awaiter } from 'tslib';
import debounce from 'debounce';
import * as Sentry from '@sentry/browser';

let config = undefined;
// Set telemetry configuration
const setConfig = (newConfig) => {
    config = Object.assign({}, newConfig);
};
// Send metrics to telemetry backend service
const sendMetrics = (metrics) => __awaiter(void 0, void 0, void 0, function* () {
    if (!config) {
        return;
    }
    const data = {
        meta: {
            client: 'webapp',
            version: window.COMMIT_HASH,
        },
        data: {
            metrics,
        },
    };
    const headers = {
        'content-type': 'application/json',
    };
    if (config.authToken) {
        headers.authorization = `bearer ${config.authToken}`;
    }
    try {
        const response = yield fetch(config.apiHost, {
            method: 'POST',
            mode: 'cors',
            headers,
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            const text = yield response.text();
            throw new Error(`${response.status}: ${text}`);
        }
    }
    catch (error) {
        Sentry.captureException(error, { extra: { data } });
    }
});
// Collect and send metrics. Sending is debounced by 1 second so
// `trackMetric` calls in quick succession are batched together
const buildTrackMetric = () => {
    const metrics = [];
    const send = debounce(() => {
        sendMetrics(metrics);
        metrics.length = 0;
    }, 1000);
    const track = (metric) => {
        metrics.push(metric);
        send();
    };
    return track;
};
const trackMetric = buildTrackMetric();

export { setConfig, trackMetric };

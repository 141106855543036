import styled from 'styled-components'
import { Wrapper as StyledModalWarningWrapper } from 'modules/workspace/components/ModalWarning/ModalWarning.styles'

export const StyledDraftsWarningContainer = styled.div`
  /* Overwrite the default margin-top of the ModalWarning component */
  ${StyledModalWarningWrapper} {
    margin-top: 24px;
  }

  p {
    padding: 0;
  }
`

export const Separator = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border.A};
  margin-bottom: 24px;

  ${StyledDraftsWarningContainer} + & {
    margin-top: 24px;
  }
`

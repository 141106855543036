import { jsx, jsxs } from 'react/jsx-runtime';
import { useSignOut, useQueryParams, IndexLayoutContent } from '@sketch/modules-common';
import { Panel, Caption, Button } from '@sketch/components';
import styled from 'styled-components';

const ErrorMessageContainer = styled.pre `
  max-height: 144px;
  overflow: auto;

  margin-top: 16px;
  padding: 8px 24px;

  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background.secondary.B};
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  line-height: 1.4;
  font-size: 0.75rem;
`;
const StyledPanel = styled(Panel) `
  max-width: 520px;
`;
const SsoSignInErrorView = (props) => {
    const signOut = useSignOut({
        revokeSessions: 'none',
        reason: 'SSO Error',
    });
    const { teamName, message } = useQueryParams();
    return (jsx(IndexLayoutContent, Object.assign({ center: true }, { children: jsxs(StyledPanel, { children: [jsx(Panel.Title, { children: "Configuration Error" }), jsxs(Panel.Body, { children: [jsxs(Caption, { children: ["We could not sign you into the ", jsx("b", { children: teamName }), " workspace. Please contact the Workspace Administrator to resolve this issue:"] }), jsx(ErrorMessageContainer, { children: jsx("p", { children: message }) })] }), jsx(Panel.Footer, { children: jsx(Button, Object.assign({ variant: "secondary", onClick: signOut }, { children: "Sign Out" })) })] }) })));
};

export { SsoSignInErrorView };

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useState } from 'react';
import QRCode from 'qrcode.react';
import { Link, Tooltip } from '@sketch/components';
import { useHandleCopyValue } from '@sketch/modules-common';
import Logo from '@sketch/icons/logo-qr-code.svg';
import { Title, Description, Columns, Column, List, Item, Code } from '../Setup2FAModal.styles.js';

const QR_CODE_OPTIONS = {
    src: Logo,
    width: 52,
    height: 52,
    excavate: true,
};
/**
 * QRCodeStep
 *
 * Renders the second step for the 2FA Setup Modal
 */
const QRCodeStep = ({ otpAuthUri }) => {
    const [showQRCode, setShowQRCode] = useState(true);
    // Get the secret from the full URI
    const secret = otpAuthUri === null || otpAuthUri === void 0 ? void 0 : otpAuthUri.split('=')[2];
    const { handleCopyValue, copyTooltipText, handleEnterCopiableArea, handleLeaveCopiableArea, copyTooltipVisible, } = useHandleCopyValue(secret.replace(/\s/g, ''));
    const handleMouseEnterLabel = () => !secret ? null : handleEnterCopiableArea();
    const handleMouseLeaveLabel = () => !secret ? null : handleLeaveCopiableArea();
    return (jsxs(Fragment, { children: [jsx(Title, { children: "Scan the QR Code" }), jsx(Description, { children: "Next, link your Sketch account to the authenticator app you just installed:" }), showQRCode ? (jsxs(Fragment, { children: [jsxs(Columns, { children: [jsx(Column, { children: jsx(QRCode, { "data-testid": "qr-code-image", value: otpAuthUri, imageSettings: QR_CODE_OPTIONS, renderAs: "svg", level: "H", size: 144 }) }), jsxs(List, { children: [jsx(Item, { children: "Open the authenticator app " }), jsx(Item, { children: "Tap Scan a QR code " }), jsx(Item, { children: "Scan this code with your phone camera " })] })] }), jsx(Link, Object.assign({ onClick: e => {
                            e.preventDefault();
                            setShowQRCode(false);
                        }, isUnderlined: true, external: true }, { children: "Or type in the code" }))] })) : (jsxs(Fragment, { children: [jsx(Code, Object.assign({ "data-testid": "mfa-activation-code", onClick: handleCopyValue, onMouseEnter: handleMouseEnterLabel, onMouseLeave: handleMouseLeaveLabel }, { children: jsx(Tooltip, Object.assign({ placement: "bottom", visible: copyTooltipVisible, content: copyTooltipText }, { children: secret })) })), jsx(Link, Object.assign({ onClick: e => {
                            e.preventDefault();
                            setShowQRCode(true);
                        }, isUnderlined: true, external: true }, { children: "Scan the code instead" }))] }))] }));
};

export { QRCodeStep };

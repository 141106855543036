import { jsx, jsxs } from 'react/jsx-runtime';
import { Redirect } from 'react-router-dom';
import { IndexLayoutContent, IndexLayoutOldTitle, IndexLayoutOldSubtitle, routes, setSketchUrlScheme } from '@sketch/modules-common';
import { Button, LoadingState } from '@sketch/components';
import { Wrapper } from './SsoSuccessfulSigninView.styles.js';
import { useSsoSuccessfulSignIn } from './useSsoSuccessfulSignIn.js';

const SSOSuccessPopup = ({ workspaceName, useOverrideLayoutProps, }) => {
    useOverrideLayoutProps({ variant: 'orange' });
    return (jsx(IndexLayoutContent, Object.assign({ center: "horizontal", marginTop: true, paddingHorizontal: true }, { children: jsxs(Wrapper, { children: [jsxs(IndexLayoutOldTitle, { children: ["You are now signed in into \"", workspaceName, "\""] }), jsx(IndexLayoutOldSubtitle, { children: "Please close this window and resume your work on the main one" }), jsx(Button, Object.assign({ variant: "primary-untinted", onClick: window.close }, { children: "Close window" }))] }) })));
};
const SsoSuccessfulSignInView = (props) => {
    var _a;
    const { state } = useSsoSuccessfulSignIn();
    switch (state.type) {
        case 'loading':
            return jsx(LoadingState, {});
        case 'success-cloud-popup':
            return jsx(SSOSuccessPopup, Object.assign({}, props, { workspaceName: state.workspaceName }));
        case 'success-cloud':
            return jsx(Redirect, { to: (_a = state.from) !== null && _a !== void 0 ? _a : routes.ENTRY.create({}) });
        case 'success-sketch-app':
            window.location.href = setSketchUrlScheme(`sketch.cloud/signin/sso/success?token=${state.exchangeToken}`);
            return null;
        case 'error':
            return jsx(Redirect, { to: routes.SSO_SIGN_IN.create({}) });
    }
};

export { SsoSuccessfulSignInView as default };

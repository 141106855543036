import { __awaiter } from 'tslib';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import * as yup from 'yup';
import { Formik } from 'formik';
import { passwordStrengthValidation, Modal, Form, PasswordInput, CreatePasswordInput, Button } from '@sketch/components';
import { useToast } from '@sketch/toasts';
import { useUserPasswordUpdateMutation } from '@sketch/gql-types';
import { ErrorHandler } from '@sketch/tracing';
import { StyledForm } from './ChangePasswordModal.styles.js';
import { useSignOut } from '@sketch/modules-common';

const SCHEMA = yup.object().shape({
    password: yup.string().required('Current Password can’t be blank'),
    newPassword: yup
        .string()
        .min(8, 'Password is too short (minimum is 8 characters)')
        .max(72, 'Password is too long (maximum is 72 characters')
        .required('New Password can’t be blank')
        .test('passwordStrength', 'The password strength should be at least Okay', passwordStrengthValidation),
    confirmNewPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), undefined], 'Passwords don’t match')
        .required('Confirm New Password can’t be blank'),
});
const ChangePasswordModal = ({ hideModal, }) => {
    const signOut = useSignOut({
        revokeSessions: 'none',
        reason: 'Changing password',
    });
    const { showToast } = useToast();
    const [updatePassword] = useUserPasswordUpdateMutation({
        redirectErrors: true,
        onCompleted: data => {
            if (!data || !data.userPasswordUpdate) {
                return ErrorHandler.shouldNeverHappen('Could not update password');
            }
            signOut();
            showToast('Your password has been updated. Please sign in');
            hideModal();
        },
        onError: error => {
            showToast(error.message, 'negative');
        },
    });
    const handleSubmit = (values, actions) => __awaiter(void 0, void 0, void 0, function* () {
        yield updatePassword({
            variables: {
                currentPassword: values.password,
                newPassword: values.newPassword,
            },
        });
        actions.setSubmitting(false);
    });
    return (jsx(Modal, Object.assign({ title: "Change Your Password", onCancel: hideModal }, { children: jsx(Formik, Object.assign({ initialValues: {
                password: '',
                newPassword: '',
                confirmNewPassword: '',
            }, validationSchema: SCHEMA, onReset: hideModal, onSubmit: handleSubmit }, { children: ({ isSubmitting, handleReset, values, errors, touched, handleChange, handleBlur, isValid, dirty, }) => (jsxs(Fragment, { children: [jsx(Modal.Body, { children: jsxs(StyledForm, Object.assign({ id: "change-password-form" }, { children: [jsx(Form.Field, Object.assign({ name: "password", label: "Password", errorText: touched.password ? errors.password : undefined }, { children: jsx(PasswordInput, { name: "password", "aria-label": "Password", value: values.password, disabled: isSubmitting, onChange: handleChange, onBlur: handleBlur }) })), jsx(CreatePasswordInput, { name: "newPassword", label: "New Password", "data-testid": "new-password-input", "aria-label": "New Password", value: values.newPassword, disabled: isSubmitting, onChange: handleChange, onBlur: handleBlur }), jsx(Form.Field, Object.assign({ name: "confirmNewPassword", label: "Confirm New Password", errorText: touched.confirmNewPassword
                                        ? errors.confirmNewPassword
                                        : undefined }, { children: jsx(PasswordInput, { name: "confirmNewPassword", "aria-label": "Confirm New Password", value: values.confirmNewPassword, disabled: isSubmitting, onChange: handleChange, onBlur: handleBlur }) }))] })) }), jsxs(Modal.Footer, { children: [jsx(Button, Object.assign({ type: "reset", variant: "secondary", disabled: isSubmitting, onClick: handleReset }, { children: "Cancel" })), jsx(Button, Object.assign({ type: "submit", variant: "primary", loading: isSubmitting, disabled: isSubmitting || !isValid || !dirty, form: "change-password-form" }, { children: "Change Password" }))] })] })) })) })));
};

export { ChangePasswordModal };

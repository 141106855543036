import { localStorageKeys } from '@sketch/constants';
import debounce from 'debounce';
import { getParsedItem, removeItem, setStringifiedItem } from '@sketch/utils';

const readSettings = () => {
    var _a;
    const storedDevToolsState = getParsedItem(localStorageKeys.devTools);
    if (typeof storedDevToolsState === 'boolean') {
        return { general: { isTurnedOn: storedDevToolsState } };
    }
    if (((_a = storedDevToolsState === null || storedDevToolsState === void 0 ? void 0 : storedDevToolsState.general) === null || _a === void 0 ? void 0 : _a.isTurnedOn) === undefined) {
        return { general: { isTurnedOn: false } };
    }
    return storedDevToolsState;
};
const storeSettings = (value) => {
    if (value === undefined) {
        removeItem(localStorageKeys.devTools);
        return;
    }
    setStringifiedItem(localStorageKeys.devTools, value);
};
const storeSettingsDebounced = debounce(storeSettings, 500, false);

export { readSettings, storeSettings, storeSettingsDebounced };

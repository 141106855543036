import { __rest } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Wrapper, IconWrapper, Prefix, InputWithBox, RightIconWrapper } from './Input.styles.js';

const InputBase = React.forwardRef(function StyledInput(props, ref) {
    const [prefixWidth, setPrefixWidth] = useState(0);
    const { icon: Icon, rightIcon: RightIcon, value, prefix, small } = props, remainingProps = __rest(props, ["icon", "rightIcon", "value", "prefix", "small"]);
    const prefixRef = useRef(null);
    // Calculate prefix element width so we can set the input padding properly
    useEffect(() => {
        const prefixElement = prefixRef === null || prefixRef === void 0 ? void 0 : prefixRef.current;
        if (prefixElement) {
            setPrefixWidth(prefixElement.offsetWidth);
        }
    }, [prefix]);
    const isValueEmpty = !(value === null || value === void 0 ? void 0 : value.toString().length);
    const hasIcon = isValueEmpty && !!Icon;
    const hasRightIcon = !!RightIcon;
    const canRenderPrefix = !!prefix && !hasIcon;
    const $prefixWidth = canRenderPrefix ? prefixWidth : undefined;
    return (jsxs(Wrapper, { children: [hasIcon && jsx(IconWrapper, { children: Icon }), canRenderPrefix && (jsx(Prefix, Object.assign({ ref: prefixRef, small: small }, { children: prefix }))), jsx(InputWithBox, Object.assign({ ref: ref, hasIcon: hasIcon, "$hasRightIcon": hasRightIcon, value: value, small: small, "$prefixWidth": $prefixWidth }, remainingProps)), hasRightIcon && (jsx(RightIconWrapper, Object.assign({ "$small": small }, { children: RightIcon })))] }));
});
const Input = styled(InputBase) ``;

export { Input };

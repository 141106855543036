import { YEAR, MONTH, WEEK, DAY, HOUR, MINUTE } from './constants.js';
import * as constants from './constants.js';
export { constants as timeConstants };

const getRelativeDateComposition = (seconds) => {
    /**
     * This util is based on the NSDate+BCFoundation.m code
     * https://github.com/sketch-hq/Sketch/blob/c0a3cb5a87877a58e619991a7925a363bcab4630/Modules/BCFoundation/Source/NSDate%2BBCFoundation.m
     */
    const year = seconds / YEAR;
    const month = ((year - Math.floor(year)) * YEAR) / MONTH;
    const weekOfMonth = ((month - Math.floor(month)) * MONTH) / WEEK;
    const day = ((weekOfMonth - Math.floor(weekOfMonth)) * WEEK) /
        DAY;
    const hour = ((day - Math.floor(day)) * DAY) / HOUR;
    const minute = ((hour - Math.floor(hour)) * HOUR) / MINUTE;
    const second = (minute - Math.floor(minute)) * MINUTE;
    return {
        year: Math.floor(year),
        month: Math.floor(month),
        weekOfMonth: Math.floor(weekOfMonth),
        day: Math.floor(day),
        hour: Math.floor(hour),
        minute: Math.floor(minute),
        second: Math.floor(second),
    };
};
const getRelativeDate = (seconds) => {
    const compositeDate = getRelativeDateComposition(seconds);
    if (compositeDate.year > 0) {
        return [
            compositeDate.year + (compositeDate.month > 6 ? 1 : 0),
            'year',
        ];
    }
    else if (compositeDate.month > 0) {
        return [
            compositeDate.month +
                (compositeDate.day + compositeDate.weekOfMonth * 7 > 14 ? 1 : 0),
            'month',
        ];
    }
    else if (compositeDate.weekOfMonth > 0) {
        return [
            compositeDate.weekOfMonth + (compositeDate.day % 7 > 3 ? 1 : 0),
            'week',
        ];
    }
    else if (compositeDate.day > 0) {
        return [
            compositeDate.day + (compositeDate.hour > 11 ? 1 : 0),
            'day',
        ];
    }
    else if (compositeDate.hour > 0) {
        return [
            compositeDate.hour + (compositeDate.minute > 30 ? 1 : 0),
            'hour',
        ];
    }
    else if (compositeDate.minute > 0) {
        return [
            compositeDate.minute + (compositeDate.second > 30 ? 1 : 0),
            'minute',
        ];
    }
    else {
        return [compositeDate.second, 'second'];
    }
};

export { getRelativeDate, getRelativeDateComposition };

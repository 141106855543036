import { useState } from 'react'
import {
  useGetAccessShareMembershipsQuery,
  useGetAccessProjectMembershipsQuery,
  useGetAccessPermissionGroupMembershipsQuery,
} from '@sketch/gql-types'
import { WatchQueryFetchPolicy } from 'apollo-client'
import { useDebounceValue } from '@sketch/utils'

export interface UseManageUserPermissionsProps {
  workspaceIdentifier: string
  workspaceMembershipIdentifier: string
  fetchPolicy?: WatchQueryFetchPolicy
  fetchPermissionGroup?: boolean
}

export const useManageUserSharePermissions = ({
  workspaceIdentifier,
  workspaceMembershipIdentifier,
  fetchPolicy,
}: UseManageUserPermissionsProps) => {
  const [shareSearch, setShareSearch] = useState('')

  const debouncedShareSearch = useDebounceValue(shareSearch, 300)

  // Shares data
  const {
    data: accessShareMembershipsData,
    loading,
    fetchMore,
  } = useGetAccessShareMembershipsQuery({
    variables: {
      workspaceIdentifier,
      workspaceMembershipIdentifier,
      shareSearch: { name: debouncedShareSearch },
    },
    fetchPolicy: fetchPolicy || 'cache-first',
  })

  const data =
    accessShareMembershipsData?.workspace?.workspaceMembership?.shareMemberships
      .entries

  const total =
    accessShareMembershipsData?.workspace?.workspaceMembership?.shareMemberships
      ?.meta.totalCount ?? 0

  const path = [
    'workspace',
    'workspaceMembership',
    'shareMemberships',
    'entries',
  ]

  const after =
    accessShareMembershipsData?.workspace.workspaceMembership?.shareMemberships
      .meta.after || null

  const isUsingFilter = shareSearch !== '' && total > 0

  return {
    data,
    loading,
    total,
    path,
    after,
    fetchMore,
    setShareSearch,
    isUsingFilter,
  }
}

export const useManageUserProjectPermissions = ({
  workspaceIdentifier,
  workspaceMembershipIdentifier,
  fetchPolicy,
}: UseManageUserPermissionsProps) => {
  const [projectSearch, setProjectSearch] = useState('')

  const debouncedShareSearch = useDebounceValue(projectSearch, 300)

  // Projects data
  const {
    data: accessProjectMembershipsData,
    loading,
    fetchMore,
  } = useGetAccessProjectMembershipsQuery({
    variables: {
      workspaceIdentifier,
      workspaceMembershipIdentifier,
      projectSearch: { name: debouncedShareSearch },
    },
    fetchPolicy: fetchPolicy || 'cache-first',
  })

  const data =
    accessProjectMembershipsData?.workspace.workspaceMembership
      ?.projectMemberships.entries

  const total =
    accessProjectMembershipsData?.workspace.workspaceMembership
      ?.projectMemberships.meta.totalCount ?? 0

  const path = [
    'workspace',
    'workspaceMembership',
    'projectMemberships',
    'entries',
  ]

  const after =
    accessProjectMembershipsData?.workspace.workspaceMembership
      ?.projectMemberships.meta.after || null

  const isUsingFilter = projectSearch !== '' && total > 0

  return {
    data,
    loading,
    total,
    path,
    after,
    fetchMore,
    setProjectSearch,
    isUsingFilter,
  }
}

export const useManageUserPermissionGroupPermissions = ({
  workspaceIdentifier,
  workspaceMembershipIdentifier,
  fetchPolicy,
  fetchPermissionGroup,
}: UseManageUserPermissionsProps) => {
  const [groupSearch, setGroupSearch] = useState('')
  const debouncedGroupSearch = useDebounceValue(groupSearch, 300)

  // Group data
  const {
    data: accessProjectMembershipsData,
    loading,
    fetchMore,
  } = useGetAccessPermissionGroupMembershipsQuery({
    variables: {
      workspaceIdentifier,
      workspaceMembershipIdentifier,
      permissionGroupSearch: debouncedGroupSearch,
    },
    fetchPolicy: fetchPolicy || 'cache-first',
    skip: !fetchPermissionGroup,
  })

  const data =
    accessProjectMembershipsData?.workspace.workspaceMembership
      ?.permissionGroupMemberships.entries

  const total =
    accessProjectMembershipsData?.workspace.workspaceMembership
      ?.permissionGroupMemberships.meta.totalCount ?? 0

  const path = [
    'workspace',
    'workspaceMembership',
    'permissionGroupMemberships',
    'entries',
  ]

  const after =
    accessProjectMembershipsData?.workspace.workspaceMembership
      ?.permissionGroupMemberships.meta.after || null

  const isUsingFilter = groupSearch !== '' && total > 0

  return {
    data,
    loading,
    total,
    path,
    after,
    fetchMore,
    setGroupSearch,
    isUsingFilter,
  }
}

export const useManageUserPermissions = ({
  workspaceIdentifier,
  workspaceMembershipIdentifier,
  fetchPolicy,
  fetchPermissionGroup,
}: UseManageUserPermissionsProps) => {
  const shares = useManageUserSharePermissions({
    workspaceIdentifier,
    workspaceMembershipIdentifier,
    fetchPolicy,
  })

  const projects = useManageUserProjectPermissions({
    workspaceIdentifier,
    workspaceMembershipIdentifier,
    fetchPolicy,
  })

  const permissionGroups = useManageUserPermissionGroupPermissions({
    workspaceIdentifier,
    workspaceMembershipIdentifier,
    fetchPolicy,
    fetchPermissionGroup,
  })

  return {
    shares,
    projects,
    permissionGroups,
  }
}

import { jsxs, jsx } from 'react/jsx-runtime';
import '../Footer/index.js';
import { Wrapper, Content, Main, FooterWrapper } from './IndexLayout.styles.js';
import { IndexLayoutHeader } from './IndexLayoutHeader.js';
import Sidebar from './Sidebar.js';
import { Footer } from '../Footer/Footer.js';

const IndexLayout = ({ className, children, headerLink, blockLogoLink, variant, isCreatingWorkspace, fullWidth, maxContainerWidth, showFooter = true, }) => {
    return (jsxs(Wrapper, Object.assign({ className: className }, { children: [jsx(IndexLayoutHeader, { headerLink: headerLink, blockLogoLink: blockLogoLink, isCreatingWorkspace: isCreatingWorkspace }), variant && jsx(Sidebar, { variant: variant }), jsxs(Content, { children: [jsx(Main, Object.assign({ isCreatingWorkspace: isCreatingWorkspace, fullWidth: fullWidth, maxContainerWidth: maxContainerWidth }, { children: children })), showFooter && (jsx(FooterWrapper, { children: jsx(Footer, {}) }))] })] })));
};

export { IndexLayout };

import { useCallback, useContext } from 'react'
import { ExportModal } from './ExportModal'
import { ModalContext } from '@sketch/components'
import { useExportNode } from '@sketch-hq/sketch-web-renderer'
import { ExportSettingsItem } from './types'
import { useExportAndDownloadLayer } from './useExportAndDownloadLayer'
import { ErrorHandler } from '@sketch/tracing'

export function useExportModal() {
  const { showModal } = useContext(ModalContext)

  const exportNode = useExportNode()
  const exportAndDownloadLayer = useExportAndDownloadLayer()

  const openExportModal = useCallback(
    (nodeIdentifier: bigint, elementName: string) => {
      const exportAndDownload = async (exportList: ExportSettingsItem[]) => {
        return await exportAndDownloadLayer(
          nodeIdentifier,
          elementName,
          exportList
        )
      }

      const createPreviewUrl = async () => {
        try {
          const data = await exportNode(nodeIdentifier, {
            format: 'png',
            backingScale: window.devicePixelRatio,
          })

          return URL.createObjectURL(data)
        } catch (e) {
          ErrorHandler.ignore(e as Error)
          return null
        }
      }

      showModal(ExportModal, { exportAndDownload, createPreviewUrl })
    },
    [exportAndDownloadLayer, exportNode, showModal]
  )

  return { openExportModal }
}

import { __rest } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import React, { useRef, useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import get from 'lodash.get';
import set from 'lodash.set';
import { isFunction } from 'util';
import '../Box/index.js';
import { Spinner } from '../Spinner/index.js';
import { ErrorHandler } from '@sketch/tracing';
import { useIsMountedRef, excludeError } from '@sketch/utils';
import { Box, Flex } from '../Box/BoxSystem.js';

/**
 * @deprecated use @see{ handleFetchMore }
 */
const loadMore = (fetchMore, after, entriesPath) => () => {
    if (!after)
        return Promise.resolve();
    return fetchMore({
        variables: {
            after,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
            return set(fetchMoreResult, entriesPath, [
                ...get(previousResult, entriesPath, []),
                ...get(fetchMoreResult, entriesPath, []),
            ]);
        },
    });
};
const InfiniteList = (_a) => {
    var { canLoadMore = false, children, renderLoading, reversed, onLoadMore, wrappingComponent: Wrapper = Box, placeholderItems = null, listRef } = _a, props = __rest(_a, ["canLoadMore", "children", "renderLoading", "reversed", "onLoadMore", "wrappingComponent", "placeholderItems", "listRef"]);
    // On large displays, more than one page is requested. In this case,
    // scrollToBottom on componentDidMount is fallible.
    // userTriggeredScroll will be handy to help distinguish when the scroll is
    // triggered by the user or by the observable on the first load.
    const userTriggeredScroll = useRef();
    const itemsEndRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const isInViewStillVisible = useRef();
    const isMountedRef = useIsMountedRef();
    const scrollToBottom = () => {
        window.requestAnimationFrame(() => {
            if (!itemsEndRef.current || !itemsEndRef.current.scrollIntoView)
                return;
            itemsEndRef.current.scrollIntoView({ block: 'nearest' });
        });
    };
    const handleScroll = () => {
        userTriggeredScroll.current = true;
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { capture: true });
        if (reversed)
            scrollToBottom();
        return () => {
            window.removeEventListener('scroll', handleScroll, { capture: true });
        };
    }, [reversed]);
    useEffect(() => {
        if (!canLoadMore) {
            return;
        }
        if (!loading && isInViewStillVisible.current) {
            loadMoreItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canLoadMore, loading]);
    const loadMoreItems = () => {
        setLoading(true);
        return onLoadMore()
            .catch(e => {
            if (excludeError(e)) {
                ErrorHandler.ignore(e, 'Workaround for an issue where apollo attempts to access a query which has already unmounted.');
                return null;
            }
            throw e;
        })
            .finally(() => {
            if (!isMountedRef.current) {
                return;
            }
            if (userTriggeredScroll.current) {
                userTriggeredScroll.current = false;
            }
            else if (reversed) {
                scrollToBottom();
            }
            setLoading(false);
        });
    };
    const onChangeObserver = (inView) => {
        isInViewStillVisible.current = inView;
        if (!inView)
            return;
        loadMoreItems();
    };
    const listItems = [
        children,
        jsxs(Flex, Object.assign({ justifyContent: "center" }, { children: [jsx(InView, Object.assign({ onChange: onChangeObserver }, { children: null })), loading &&
                    (renderLoading && isFunction(renderLoading) ? (renderLoading()) : (jsx(Spinner, { primary: true })))] }), `infinite-list-loader`),
        jsx(React.Fragment, { children: placeholderItems }, "placeholders"),
    ];
    return (jsxs(Wrapper, Object.assign({ ref: listRef }, props, { children: [reversed ? listItems.reverse() : listItems, jsx("div", { ref: itemsEndRef })] })));
};

export { InfiniteList, loadMore };

import React, { FC, useEffect } from 'react'
import styled, { css } from 'styled-components'

import {
  Box,
  Flex,
  Caption,
  LoadingPlaceholder,
  AsyncButton,
  ErrorMessage,
  InfiniteList,
} from '@sketch/components'

import NotificationListItem from './NotificationListItem'
import {
  Wrapper,
  LoadingWrapper,
} from 'modules/user/views/Updates/Updates.styles'

import { ReactComponent as BellIcon } from '@sketch/icons/bell-filled-64'

import { NotificationInfoFragment } from '@sketch/gql-types'

import { breakpoint } from '@sketch/global-styles'

/**
 * TYPES
 */
interface NotificationsListProps {
  loading: boolean
  loadMore: () => Promise<any>
  entries?: NotificationInfoFragment[]
  subscribeToMore: () => void
  markNotificationAsRead: (id: string) => void
  markAllNotificationAsSeen: () => void
  markAllNotificationsAsRead: () => void
  hasUnreadNotifications?: boolean
}

/**
 * STYLES
 */
const MarkAllAsReadButton = styled(AsyncButton).attrs({ size: '32' })`
  margin-left: 12px;
`

const LoadMoreIndicator = styled(props => (
  <Flex py={2} justifyContent="center" {...props}>
    <LoadingPlaceholder primary />
  </Flex>
))`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.border.A};
`

const NotificationsHeading = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin: 24px 12px 24px 24px;
    font-size: ${theme.fontSizes.G};
    font-weight: ${theme.fontWeights.bold};

    &:first-of-type {
      margin-top: 0;
    }

    ${breakpoint('xs')`
      margin-left: 0;
    `}
  `
)

/**
 * MAIN COMPONENT
 */
const NotificationsList: FC<NotificationsListProps> = ({
  subscribeToMore,
  hasUnreadNotifications,
  markAllNotificationAsSeen,
  markNotificationAsRead,
  entries,
  markAllNotificationsAsRead,
  loading,
  loadMore,
}) => {
  useEffect(() => {
    subscribeToMore()
    markAllNotificationAsSeen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderList = () => {
    if (loading && !entries)
      return (
        <LoadingWrapper>
          <LoadingPlaceholder size="64px" />
        </LoadingWrapper>
      )

    if (entries?.length === 0) {
      return (
        <Box width="100%" height="100%" px={6} UNSAFE_py="50px">
          <ErrorMessage
            icon={<BellIcon />}
            iconSize="medium"
            title="No updates"
            description="No unread comments or notifications yet."
          />
        </Box>
      )
    }

    const { unreadNotifications, readNotifications } = splitNotificationTypes(
      entries
    )

    const renderNotification = (notification: NotificationInfoFragment) => (
      <NotificationListItem
        key={notification.identifier}
        isRead={!!notification.isRead}
        notification={notification}
        markNotificationAsRead={() => {
          markNotificationAsRead(notification.identifier)
        }}
        isVisuallyRead={notification.isRead || false}
      />
    )

    return (
      <InfiniteList
        onLoadMore={loadMore}
        renderLoading={() => <LoadMoreIndicator />}
      >
        <Wrapper>
          <NotificationsHeading data-testid="notifications-unread-title">
            <div>Unread</div>
            <MarkAllAsReadButton
              onClick={markAllNotificationsAsRead}
              disabled={!hasUnreadNotifications}
            >
              Mark All as Read
            </MarkAllAsReadButton>
          </NotificationsHeading>
          {unreadNotifications.length ? (
            unreadNotifications.map(renderNotification)
          ) : (
            <Box px={2} py={6}>
              <Caption fontSize="E" textAlign="center" m={0}>
                {'No unread notifications.'}
              </Caption>
            </Box>
          )}

          <NotificationsHeading data-testid="notifications-read-title">
            Read
          </NotificationsHeading>
          {readNotifications.length ? (
            readNotifications.map(renderNotification)
          ) : (
            <Box px={2} py={6}>
              <Caption fontSize="E" textAlign="center" m={0}>
                {'No read notifications.'}
              </Caption>
            </Box>
          )}
        </Wrapper>
      </InfiniteList>
    )
  }

  return renderList()
}

export default NotificationsList

function splitNotificationTypes(
  notifications: NotificationInfoFragment[] = []
) {
  const notificationsFormat: {
    unreadNotifications: NotificationInfoFragment[]
    readNotifications: NotificationInfoFragment[]
  } = {
    unreadNotifications: [],
    readNotifications: [],
  }

  return (
    notifications.reduce(
      ({ unreadNotifications, readNotifications }, notification) => {
        if (notification.isRead) {
          readNotifications.push(notification)
        } else {
          unreadNotifications.push(notification)
        }

        return { unreadNotifications, readNotifications }
      },
      notificationsFormat
    ) || notificationsFormat
  )
}

import { jsxs, jsx } from 'react/jsx-runtime';
import { Modal, CopyToClipboard, Button } from '@sketch/components';
import '../../components/AccessScope/index.js';
import '../../components/Divider/index.js';
import '../../components/TokenDetailsItem/index.js';
import { CopyTokenContainer, TokenSecret, TokenDetailsRow, PermissionsHeading, Callout } from './CopyTokenModal.styles.js';
import { dateFormat } from '@sketch/utils';
import Divider from '../../components/Divider/Divider.styles.js';
import TokenDetailsItem from '../../components/TokenDetailsItem/TokenDetailsItem.js';
import AccessScope from '../../components/AccessScope/AccessScope.js';

const CopyTokenModal = props => {
    const { hideModal, token, secret } = props;
    const tokenValue = btoa(`${token.identifier}:${secret}`);
    const expiresAt = token.expiresAt
        ? dateFormat(new Date(token.expiresAt))
        : 'Never';
    return (jsxs(Modal, Object.assign({ title: "Token Details", onCancel: hideModal }, { children: [jsxs(Modal.Body, { children: [jsx("div", { children: "Tokens are linked to your account. You\u2019ll only see this token once, so copy and store it in a safe place." }), jsxs(CopyTokenContainer, { children: [jsx(TokenSecret, Object.assign({ "data-testid": "token-secret" }, { children: tokenValue })), jsx(CopyToClipboard, { title: "Copy", value: tokenValue, showIcon: false })] }), jsx(Divider, {}), jsxs(TokenDetailsRow, { children: [jsxs(TokenDetailsItem, { children: [jsx("span", { children: "DESCRIPTION" }), jsx("span", { children: token.description })] }), jsxs(TokenDetailsItem, { children: [jsx("span", { children: "EXPIRES ON" }), jsx("span", { children: expiresAt })] })] }), jsx(PermissionsHeading, { children: "PERMISSIONS" }), token.accessScopes.map(scope => (jsx(AccessScope, { scope: scope, enabled: true }, scope))), jsxs(Callout, { children: [jsx("div", { children: "Before you go..." }), jsx("div", { children: "Make sure you copy and store this token now \u2014 you won\u2019t be able to retrieve it after you close this dialog." })] })] }), jsx(Modal.Footer, { children: jsx(Button, Object.assign({ variant: "primary", onClick: hideModal }, { children: "Finish" })) })] })));
};

export { CopyTokenModal as default };

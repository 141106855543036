import { DataProxy } from 'apollo-cache'
import produce from 'immer'

import {
  errorPreventiveCacheRead,
  readShareListItemFromCache,
  removeFromPaginated,
  removeQuery,
} from '@sketch/modules-common'

import { dataIdFromObject } from '@sketch/graphql-cache'

import {
  CollectionForSelectFragment,
  CollectionPreviewsFragment,
  CollectionSharePreviewFragment,
  GetCollectionSharesDocument,
  GetCollectionSharesQuery,
  GetCollectionSharesQueryVariables,
  GetProjectCollectionsDocument,
  GetProjectCollectionsQuery,
  GetProjectCollectionsQueryVariables,
  GetProjectCollectionsForSelectDocument,
  GetProjectCollectionsForSelectQuery,
  GetProjectCollectionsForSelectQueryVariables,
  GetProjectSharesDocument,
  GetProjectSharesQuery,
  GetProjectSharesQueryVariables,
  GetShareCollectionDocument,
  GetShareCollectionQuery,
  GetShareCollectionQueryVariables,
  ShareListItemFragment,
  ShareInfoWithCollectionFragment,
  ShareInfoWithCollectionFragmentDoc,
} from '@sketch/gql-types'

type SearchParams = GetProjectCollectionsQueryVariables['search']

export const OPTIMISTIC_COLLECTION_ID = 'optimistic-identifier'

const PLACEHOLDER_FILE: CollectionSharePreviewFragment['file'] = {
  __typename: 'File',
  identifier: 'placeholder-file',
  thumbnails: [],
}

interface AddSharesToCollectionProps {
  cache: DataProxy
  projectIdentifier: string
  collection: CollectionForSelectFragment
  search: SearchParams
  sharesToAdd: ShareListItemFragment[]
  updateShareCount?: boolean
}

export const addSharesToCollection = ({
  cache,
  projectIdentifier,
  collection,
  sharesToAdd,
  search,
  updateShareCount = false,
}: AddSharesToCollectionProps) => {
  // Move the shares to the given collection
  updateCollectionShares({
    cache,
    projectIdentifier,
    collectionIdentifier: collection.identifier,
    recipe: ({ project }) => {
      const { collection } = project
      const { shares } = collection
      // Only add shares that are not in the collection yet
      const newShares = sharesToAdd.filter(
        share => !shares.entries.find(s => s.identifier === share.identifier)
      )
      shares.entries.push(...newShares)
      shares.entries?.sort(sortByCreatedDesc)
      shares.meta.totalCount += newShares.length
    },
  })

  addSharesToCollectionPreviews({
    cache,
    projectIdentifier,
    collectionIdentifier: collection.identifier,
    search,
    sharesToAdd,
    updateShareCount,
  })

  // update the collection for each cached share
  for (const share of sharesToAdd) {
    updateShareCollection(cache, share.identifier, collection)
  }
}

interface RemoveSharesFromCollectionProps {
  cache: DataProxy
  projectIdentifier: string
  collectionIdentifier: string
  search: SearchParams
  sharesToRemove: Pick<ShareListItemFragment, 'identifier'>[]
  updateShareCount?: boolean
}

export const removeSharesFromCollection = ({
  cache,
  projectIdentifier,
  collectionIdentifier,
  search,
  sharesToRemove,
  updateShareCount = true,
}: RemoveSharesFromCollectionProps) => {
  const identifiers = new Set(sharesToRemove.map(s => s.identifier))
  // Remove the shares from the given collection
  for (const share of sharesToRemove) {
    removeFromPaginated(
      cache,
      { __typename: 'Share', identifier: share.identifier },
      key => key.includes(collectionIdentifier) && key.includes('.shares')
    )
  }

  // Remove the shares from the previews of the collection
  removeSharesFromCollectionPreviews({
    cache,
    projectIdentifier,
    collectionIdentifier,
    search,
    shareIdentifiers: identifiers,
    updateShareCount,
  })

  // Set share.collection to null for each share
  for (const identifier of identifiers) {
    updateShareCollection(cache, identifier, null)
  }
}

interface InvalidateCollectionSharesProps {
  cache: DataProxy
  collectionIdentifier: string
}

export const invalidateCollectionShares = ({
  cache,
  collectionIdentifier,
}: InvalidateCollectionSharesProps) => {
  removeQuery(cache, key => {
    return key.includes(collectionIdentifier) && key.includes('.shares')
  })
}

export const invalidateShareAfterCollectionUpdate = ({
  cache,
  shareIdentifier,
}: {
  cache: DataProxy
  shareIdentifier: string
}) => {
  removeQuery(cache, key => {
    return key.includes(shareIdentifier)
  })
}

interface InvalidateProjectSharesProps {
  cache: DataProxy
  projectIdentifier: string
}

export const invalidateProjectShares = ({
  cache,
  projectIdentifier,
}: InvalidateProjectSharesProps) => {
  removeQuery(cache, key => {
    return key.includes(projectIdentifier) && key.includes('.shares')
  })
}

interface InvalidateProjectCollectionsProps {
  cache: DataProxy
  projectIdentifier: string
}

export const invalidateProjectCollections = ({
  cache,
  projectIdentifier,
}: InvalidateProjectCollectionsProps) => {
  removeQuery(cache, key => {
    return key.includes(projectIdentifier) && key.includes('.collections')
  })
}

interface InvalidateCollectionsExceptProps {
  cache: DataProxy
  projectIdentifier: string
  search: SearchParams
}

/*
 * Invalidate all cached `project.collections` for the given project **except**
 * for the given search criteria.
 * When the user changes the list of collections (e.g. by creating a new
 * collection) we manually update `project.collections(search: ...)` with the
 * current search criteria and then use this funciton to invalidate any other
 * cached `project.collections`.
 * This ensures that the UI is up to date, and that if the user changes their
 * search criteria `project.collections` will be re-fetched.
 */
export const invalidateCollectionsExcept = ({
  cache,
  projectIdentifier,
  search,
}: InvalidateCollectionsExceptProps) => {
  const searchTerm = search?.name || ''
  const filters = search?.filters || []

  const currentFiltersMatch =
    filters.length === 0
      ? '"filters":[]'
      : `"filters":["${filters.join('","')}"]`
  const currentSearchTermMatch = `"name":"${searchTerm}"`
  removeQuery(cache, key => {
    if (!key.includes(projectIdentifier) || !key.includes('.collections(')) {
      return false
    }

    if (searchTerm.length === 0 && filters.length === 0) {
      return key.includes('"filters":')
    }

    return (
      !key.includes(currentSearchTermMatch) ||
      !key.includes(currentFiltersMatch)
    )
  })
}

interface UpdateCollectionMetadataProps {
  cache: DataProxy
  projectIdentifier: string
  collection: Pick<
    CollectionPreviewsFragment,
    'identifier' | 'name' | 'description'
  >
}

export const updateCollectionMetadata = ({
  cache,
  projectIdentifier,
  collection: { identifier, name, description },
}: UpdateCollectionMetadataProps) => {
  updateCollectionShares({
    cache,
    projectIdentifier,
    collectionIdentifier: identifier,
    recipe: cachedValue => {
      const collectionToUpdate = cachedValue.project.collection
      collectionToUpdate.name = name
      collectionToUpdate.description = description
    },
  })

  updateProjectCollectionsForSelect(cache, projectIdentifier, ({ project }) => {
    const collectionToUpdate = project.collectionsUnpaginated.find(
      c => c.identifier === identifier
    )
    if (!collectionToUpdate) {
      return
    }
    collectionToUpdate.name = name
  })

  sortProjectCollections({ cache, projectIdentifier })
}

interface AddCollectionToProjectProps {
  cache: DataProxy
  projectIdentifier: string
  collection: CollectionPreviewsFragment
  search?: SearchParams
}

export const addCollectionToProject = (props: AddCollectionToProjectProps) => {
  addCollectionToProjectCollections(props)
  addCollectionToProjectCollectionsForSelect(props)
}

interface RemoveCollectionFromProjectProps {
  cache: DataProxy
  projectIdentifier: string
  collectionIdentifier: string
}

export const removeCollectionFromProject = (
  props: RemoveCollectionFromProjectProps
) => {
  const shares = getCollectionShares(props)

  // Remove the reference to the collection from the shares
  for (const share of shares) {
    updateShareCollection(props.cache, share.identifier, null)
  }

  // Remove the collection from the project
  removeCollectionFromProjectCollections(props)
  removeCollectionFromProjectCollectionsForSelect(props)
}

interface SortProjectCollectionsProps {
  cache: DataProxy
  projectIdentifier: string
}

export const sortProjectCollections = ({
  cache,
  projectIdentifier,
}: SortProjectCollectionsProps) => {
  updateProjectCollections({
    cache,
    projectIdentifier,
    recipe: ({ project }) => {
      const { collections } = project
      collections.entries.sort(sortByNameAsc)
    },
  })
  updateProjectCollectionsForSelect(cache, projectIdentifier, ({ project }) => {
    const { collectionsUnpaginated } = project
    collectionsUnpaginated.sort(sortByNameAsc)
  })
}

interface UpdateCollectionSharesProps {
  cache: DataProxy
  projectIdentifier: string
  collectionIdentifier: string
  recipe: (cachedObject: GetCollectionSharesQuery) => void
}

const updateCollectionShares = ({
  cache,
  projectIdentifier,
  collectionIdentifier,
  recipe,
}: UpdateCollectionSharesProps) => {
  const cacheArgs = {
    query: GetCollectionSharesDocument,
    variables: {
      projectIdentifier,
      collectionIdentifier,
      after: null,
      search: { name: '', filters: [] },
      sortOrder: 'LAST_MODIFIED_DESC' as const,
    },
  }
  const cachedCollection = errorPreventiveCacheRead<
    GetCollectionSharesQuery,
    GetCollectionSharesQueryVariables
  >(cache, cacheArgs)

  if (!cachedCollection) {
    return
  }

  const collection = produce(cachedCollection, recipe)

  cache.writeQuery<GetCollectionSharesQuery, GetCollectionSharesQueryVariables>(
    { ...cacheArgs, data: collection }
  )

  return collection
}

interface GetCollectionSharesProps {
  cache: DataProxy
  projectIdentifier: string
  collectionIdentifier: string
}

const getCollectionShares = ({
  cache,
  projectIdentifier,
  collectionIdentifier,
}: GetCollectionSharesProps) => {
  const cacheArgs = {
    query: GetCollectionSharesDocument,
    variables: {
      projectIdentifier,
      collectionIdentifier,
      after: null,
      search: { name: '', filters: [] },
    },
  }
  const cachedValue = errorPreventiveCacheRead<
    GetCollectionSharesQuery,
    GetCollectionSharesQueryVariables
  >(cache, cacheArgs)

  return cachedValue?.project.collection.shares.entries ?? []
}

interface AddSharesToProjectProps {
  cache: DataProxy
  projectIdentifier: string
  sharesToAdd: ShareListItemFragment[]
}

export const addSharesToProject = ({
  cache,
  projectIdentifier,
  sharesToAdd,
}: AddSharesToProjectProps) => {
  updateProjectShares({
    cache,
    projectIdentifier,
    recipe: ({ project }) => {
      const { shares } = project
      // Only add shares that are not in the project yet
      const newShares = sharesToAdd
        .filter(
          share => !shares.entries.find(s => s.identifier === share.identifier)
        )
        // no need to have a collection defined
        .map(share => ({ ...share, collection: null }))
      shares.entries.push(...newShares)
      shares.entries.sort(sortByCreatedDesc)
      shares.meta.totalCount += newShares.length
    },
  })
}

interface RemoveTrashedShareProps {
  cache: DataProxy
  identifier: string
}

export const removeTrashedShare = ({
  cache,
  identifier,
}: RemoveTrashedShareProps) => {
  const id = dataIdFromObject({ __typename: 'Share', identifier })
  if (!id) {
    return
  }

  const shareWithCollection = cache.readFragment<ShareInfoWithCollectionFragment>(
    {
      fragment: ShareInfoWithCollectionFragmentDoc,
      fragmentName: 'ShareInfoWithCollection',
      id,
    }
  )
  if (!shareWithCollection) {
    return
  }

  const { collection, project } = shareWithCollection

  if (!collection || !project) {
    return
  }

  removeSharesFromCollection({
    cache,
    projectIdentifier: project.identifier,
    collectionIdentifier: collection.identifier,
    search: null,
    sharesToRemove: [{ identifier }],
  })
}

export const removeSharesFromProject = (
  cache: DataProxy,
  projectIdentifier: string,
  sharesToRemove: Pick<ShareListItemFragment, 'identifier'>[]
) => {
  for (const share of sharesToRemove) {
    removeFromPaginated(
      cache,
      { __typename: 'Share', identifier: share.identifier },
      key =>
        key.includes(projectIdentifier) &&
        key.includes('.shares') &&
        key.includes('NO_COLLECTION')
    )
  }
}

interface UpdateProjectSharesProps {
  cache: DataProxy
  projectIdentifier: string
  recipe: (cachedObject: GetProjectSharesQuery) => void
}

const updateProjectShares = ({
  cache,
  projectIdentifier,
  recipe,
}: UpdateProjectSharesProps) => {
  const cacheArgs = {
    query: GetProjectSharesDocument,
    variables: {
      shortId: projectIdentifier,
      after: null,
      search: {
        name: null,
        isCurrentVersionDownloadable: null,
        filters: ['NO_COLLECTION' as const],
      },
      sortOrder: 'LAST_MODIFIED_DESC' as const,
    },
  }
  const cachedProject = errorPreventiveCacheRead<
    GetProjectSharesQuery,
    GetProjectSharesQueryVariables
  >(cache, cacheArgs)

  if (!cachedProject) {
    return
  }

  const project = produce(cachedProject, recipe)

  cache.writeQuery<GetProjectSharesQuery, GetProjectSharesQueryVariables>({
    ...cacheArgs,
    data: project,
  })

  return project
}

const sortByCreatedDesc = (
  current: ShareListItemFragment,
  next: ShareListItemFragment
) =>
  Date.parse(next.version?.createdAt as string) -
  Date.parse(current.version?.createdAt as string)

const sortByNameAsc = (current: { name: string }, next: { name: string }) =>
  current.name.localeCompare(next.name, undefined, {
    numeric: true,
    sensitivity: 'base',
  })

export const updateShareCollection = (
  cache: DataProxy,
  shareIdentifier: string,
  collection: CollectionForSelectFragment | null
) => {
  const cacheArgs = {
    query: GetShareCollectionDocument,
    variables: { shareIdentifier },
  }
  const cachedValue = errorPreventiveCacheRead<
    GetShareCollectionQuery,
    GetShareCollectionQueryVariables
  >(cache, cacheArgs)

  if (!cachedValue) {
    return
  }

  const updatedShare = produce(cachedValue, ({ share }) => {
    if (!share) {
      return
    }

    share.collection = collection
  })

  cache.writeQuery<GetShareCollectionQuery, GetShareCollectionQueryVariables>({
    ...cacheArgs,
    data: updatedShare,
  })
}

interface AddSharesToCollectionPreviewsProps {
  cache: DataProxy
  projectIdentifier: string
  collectionIdentifier: string
  search: SearchParams
  sharesToAdd: ShareListItemFragment[]
  updateShareCount?: boolean
}

const addSharesToCollectionPreviews = ({
  cache,
  projectIdentifier,
  collectionIdentifier,
  search,
  sharesToAdd,
  updateShareCount = false,
}: AddSharesToCollectionPreviewsProps) => {
  updateCollectionPreviews({
    cache,
    projectIdentifier,
    collectionIdentifier,
    search,
    recipe: collection => {
      const newShares = sharesToAdd.filter(
        share =>
          !collection.previews.find(p => p.shareIdentifier === share.identifier)
      )

      const previewsToAdd = newShares.map(share => ({
        __typename: 'CollectionSharePreview' as const,
        shareIdentifier: share.identifier,
        name: share.name,
        file: share.version?.document?.previewFile ?? null,
      }))

      collection.previews = collection.previews.concat(previewsToAdd)
      if (updateShareCount) {
        collection.shareCount += previewsToAdd.length
      }
    },
  })
}

interface RemoveSharesFromCollectionPreviewsProps {
  cache: DataProxy
  shareIdentifiers: Set<string>
  projectIdentifier: string
  collectionIdentifier: string
  search: SearchParams
  updateShareCount?: boolean
}
const removeSharesFromCollectionPreviews = ({
  cache,
  shareIdentifiers,
  projectIdentifier,
  collectionIdentifier,
  search,
  updateShareCount = true,
}: RemoveSharesFromCollectionPreviewsProps) => {
  updateCollectionPreviews({
    cache,
    projectIdentifier,
    collectionIdentifier,
    search,
    recipe: collection => {
      const initialCount = collection.previews.length
      collection.previews = collection.previews.filter(
        p => !shareIdentifiers.has(p.shareIdentifier)
      )
      if (updateShareCount) {
        const numRemoved = initialCount - collection.previews.length
        collection.shareCount -= numRemoved
      }
    },
  })
}

interface UpdateCollectionPreviewsProps {
  cache: DataProxy
  projectIdentifier: string
  collectionIdentifier: string
  search?: GetProjectCollectionsQueryVariables['search']
  recipe: (cachedObject: CollectionPreviewsFragment) => void
}

const updateCollectionPreviews = ({
  cache,
  projectIdentifier,
  collectionIdentifier,
  search,
  recipe,
}: UpdateCollectionPreviewsProps) => {
  const cachedValue = errorPreventiveCacheRead<
    GetProjectCollectionsQuery,
    GetProjectCollectionsQueryVariables
  >(cache, {
    query: GetProjectCollectionsDocument,
    variables: { projectIdentifier, search, after: null },
  })

  if (!cachedValue) {
    return
  }

  const updatedProject = produce(cachedValue, ({ project }) => {
    const { collections } = project
    const collection = collections.entries.find(
      c => c.identifier === collectionIdentifier
    )

    if (!collection) {
      return
    }

    recipe(collection)
  })

  cache.writeQuery<
    GetProjectCollectionsQuery,
    GetProjectCollectionsQueryVariables
  >({
    query: GetProjectCollectionsDocument,
    variables: { projectIdentifier, after: null },
    data: updatedProject,
  })
}

const addCollectionToProjectCollections = ({
  cache,
  projectIdentifier,
  search,
  collection,
}: AddCollectionToProjectProps) => {
  updateProjectCollections({
    cache,
    projectIdentifier,
    search,
    recipe: ({ project }) => {
      const { collections } = project

      // Skip adding the collection if it is already in the project
      const existingCollection = collections.entries.find(
        c => c.identifier === collection.identifier
      )
      if (existingCollection) {
        return
      }

      collections.entries.push(collection)
      collections.entries.sort(sortByNameAsc)
      collections.meta.totalCount++
    },
  })
}

export const removeCollectionFromProjectCollections = ({
  cache,
  projectIdentifier,
  collectionIdentifier,
}: RemoveCollectionFromProjectProps) => {
  removeFromPaginated(
    cache,
    { __typename: 'Collection', identifier: collectionIdentifier },
    key => key.includes(projectIdentifier) && key.includes('.collections')
  )
}

interface UpdateProjectCollectionsProps {
  cache: DataProxy
  projectIdentifier: string
  search?: SearchParams
  recipe: (cachedObject: GetProjectCollectionsQuery) => void
}

const updateProjectCollections = ({
  cache,
  projectIdentifier,
  search,
  recipe,
}: UpdateProjectCollectionsProps) => {
  const cacheArgs = {
    query: GetProjectCollectionsDocument,
    variables: { projectIdentifier, search, after: null },
  }
  const cachedValue = errorPreventiveCacheRead<
    GetProjectCollectionsQuery,
    GetProjectCollectionsQueryVariables
  >(cache, cacheArgs)

  if (!cachedValue) {
    return
  }

  const updated = produce(cachedValue, recipe)

  cache.writeQuery<
    GetProjectCollectionsQuery,
    GetProjectCollectionsQueryVariables
  >({ ...cacheArgs, data: updated })
}

const addCollectionToProjectCollectionsForSelect = ({
  cache,
  projectIdentifier,
  collection,
}: AddCollectionToProjectProps) => {
  const unpaginatedCollection = {
    __typename: 'UnpaginatedCollection' as const,
    identifier: collection.identifier,
    name: collection.name,
  }

  updateProjectCollectionsForSelect(cache, projectIdentifier, ({ project }) => {
    const { collectionsUnpaginated } = project

    // Skip adding the collection if it is already in the project
    const existingCollection = collectionsUnpaginated.find(
      c => c.identifier === collection.identifier
    )
    if (existingCollection) {
      return
    }

    collectionsUnpaginated.push(unpaginatedCollection)
    collectionsUnpaginated.sort(sortByNameAsc)
  })
}

export const removeCollectionFromProjectCollectionsForSelect = ({
  cache,
  projectIdentifier,
  collectionIdentifier,
}: RemoveCollectionFromProjectProps) => {
  updateProjectCollectionsForSelect(cache, projectIdentifier, ({ project }) => {
    project.collectionsUnpaginated = project.collectionsUnpaginated.filter(
      collection => collection.identifier !== collectionIdentifier
    )
  })
}

const updateProjectCollectionsForSelect = (
  cache: DataProxy,
  projectIdentifier: string,
  recipe: (cachedObject: GetProjectCollectionsForSelectQuery) => void
) => {
  const cacheArgs = {
    query: GetProjectCollectionsForSelectDocument,
    variables: { projectIdentifier },
  }
  const cachedValue = errorPreventiveCacheRead<
    GetProjectCollectionsForSelectQuery,
    GetProjectCollectionsForSelectQueryVariables
  >(cache, cacheArgs)

  if (!cachedValue) {
    return
  }

  const updated = produce(cachedValue, recipe)

  cache.writeQuery<
    GetProjectCollectionsForSelectQuery,
    GetProjectCollectionsForSelectQueryVariables
  >({ ...cacheArgs, data: updated })
}

interface CreateOptimisticCollectionProps {
  cache: DataProxy
  shareIds: string[]
}
export const createOptimisticCollection = ({
  cache,
  shareIds,
}: CreateOptimisticCollectionProps) => {
  const previews: CollectionSharePreviewFragment[] = []

  shareIds.forEach(identifier => {
    const share = readShareListItemFromCache({
      cache,
      id: identifier,
    })

    if (!share) {
      return
    }

    previews.push({
      __typename: 'CollectionSharePreview',
      shareIdentifier: share.identifier,
      name: share.name,
      file: share.version?.document?.previewFile ?? PLACEHOLDER_FILE,
    })
  })

  const optimisticCollection = {
    identifier: OPTIMISTIC_COLLECTION_ID,
    name: '',
    description: '',
    previews,
    shareCount: shareIds.length,
  }

  return optimisticCollection
}

interface TransferAllSharesProps {
  cache: DataProxy
  originIdentifier: string
  targetIdentifier: string
  projectIdentifier: string
}

export const transferAllShares = ({
  cache,
  projectIdentifier,
  originIdentifier,
  targetIdentifier,
}: TransferAllSharesProps) => {
  const cachedValue = errorPreventiveCacheRead<
    GetProjectCollectionsQuery,
    GetProjectCollectionsQueryVariables
  >(cache, {
    query: GetProjectCollectionsDocument,
    variables: { projectIdentifier, after: null },
  })

  if (!cachedValue) {
    return
  }

  const updatedProject = produce(cachedValue, ({ project }) => {
    const { collections } = project
    const originCollection = collections.entries.find(
      c => c.identifier === originIdentifier
    )

    if (!originCollection) {
      return
    }

    const targetCollection = collections.entries.find(
      c => c.identifier === targetIdentifier
    )

    if (targetCollection) {
      targetCollection.shareCount += originCollection.shareCount
      targetCollection.previews = targetCollection.previews.concat(
        originCollection.previews
      )
    }

    originCollection.previews = []
    originCollection.shareCount = 0
  })

  cache.writeQuery<
    GetProjectCollectionsQuery,
    GetProjectCollectionsQueryVariables
  >({
    query: GetProjectCollectionsDocument,
    variables: { projectIdentifier, after: null },
    data: updatedProject,
  })
}

import styled from 'styled-components'
import { breakpoint } from '@sketch/global-styles'

export const PlansWrapper = styled.section`
  display: flex;

  ${breakpoint('base', 'sm')`
      justify-content: space-between;
  `}
`

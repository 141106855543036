import styled, { css } from 'styled-components'

import * as Collapsible from '@radix-ui/react-collapsible'
import { ButtonUnstyled, Pill } from '@sketch/components'

import arrowDownIcon from '@sketch/icons/chevron-vertical-16.svg'
import { breakpoint } from '@sketch/global-styles'

export const SidebarWrapper = styled.nav`
  display: flex;
  flex-direction: column;

  /* Limit the components width, preventing items with big name to have scroll */
  width: 100%;
  /* Enables scroll on the sidebar instead on the frame, causing double scrollbars
  in some scenarios. More info: https://github.com/sketch-hq/Cloud/issues/9511 */
  height: 100%;
  flex: 1;

  background-color: ${({ theme }) => theme.colors.background.secondary.B};
`

export const WorkspaceSelectorWrapper = styled.aside`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const MenuWrapper = styled.div`
  padding: 16px 8px;
  width: 100%;
  flex: 1;
  overflow-y: auto;

  /*
  Forcing the elements on this container to be stacked vertically
  and that their size or ability to grow is respected
  */
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.foreground.secondary.D};
    font-size: ${theme.fontSizes.B};
    font-weight: ${theme.fontWeights.semibold};
    text-transform: uppercase;
    margin: 0 0 4px 12px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    ${breakpoint('base', 'sm')`
      margin-bottom: 8px;

      & button > ::before {
        top: -5px;
    }`}
  `}
`

export const FooterWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border.B};
`

export const StyledPill = styled(Pill)`
  margin-left: auto;
  margin-right: 12px;

  .active & {
    background-color: ${({ theme }) => theme.colors.sketch.F};
    color: ${({ theme }) => theme.colors.sketch.A};
  }
`

export const CollapsibleTrigger = styled(Collapsible.Trigger)`
  border: none;
  background: none;
`

export const CollapsibleIcon = styled(ButtonUnstyled)<{
  selected?: boolean
  isOpen?: boolean
}>(
  ({ theme, selected, isOpen }) => css`
    position: relative;
    flex: none;
    border: none;
    background: none;
    color: ${({ theme }) => theme.colors.foreground.secondary.D};
    border-radius: 6px;
    width: 40px;

    ${breakpoint('sm')`
      height: 32px;
    `};

    :hover {
      background-color: ${theme.colors.background.tertiary.C};
    }

    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 16px;
      height: 16px;
      mask: url(${arrowDownIcon}) no-repeat 50% 50%;
      mask-size: cover;
      background-color: ${selected
        ? theme.colors.sketch.A
        : theme.colors.foreground.secondary.D};
      transition-timing-function: ease-in-out;
      transition-duration: 0.3s;
      transition-property: transform;
      will-change: transform;

      /* Closed icon needs a rotation */
      transform: ${isOpen
        ? 'translate(-50%, -50%)'
        : 'translate(-50%, -50%) rotate(-180deg)'};
    }
  `
)

export const MenuListItem = styled.li`
  list-style: none;
`

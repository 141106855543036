import { jsxs, jsx } from 'react/jsx-runtime';
import { useGetWorkspaceMembershipsCountQuery } from '@sketch/gql-types';
import { ListRowWrapper, WorkspaceLogo, Truncate } from '@sketch/components';
import { WorkspaceNameText, WorkspaceHelpText } from './WorkspaceRow.styles.js';

const WorkspaceRow = ({ workspace, actions, className, showAllMembers, warningText, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { data } = useGetWorkspaceMembershipsCountQuery({
        variables: { workspaceId: workspace.identifier },
    });
    const totalCount = ((_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.workspace) === null || _a === void 0 ? void 0 : _a.memberships) === null || _b === void 0 ? void 0 : _b.totalMemberships) === null || _c === void 0 ? void 0 : _c.meta.totalCount) || 1;
    const guestsCount = ((_f = (_e = (_d = data === null || data === void 0 ? void 0 : data.workspace) === null || _d === void 0 ? void 0 : _d.memberships) === null || _e === void 0 ? void 0 : _e.totalGuests) === null || _f === void 0 ? void 0 : _f.meta.totalCount) || 0;
    const membersCount = totalCount - guestsCount;
    const helpText = membersCount > 1 ? 'Workspace Members' : 'Workspace Member';
    const renderHelpText = showAllMembers
        ? 'All Workspace Members'
        : `${membersCount} ${helpText}`;
    return (jsxs(ListRowWrapper, Object.assign({ "aria-label": `Row of ${workspace.name}`, className: className, "$hasWarningText": Boolean(warningText) }, { children: [jsx(WorkspaceLogo, { src: (_g = workspace.avatar) === null || _g === void 0 ? void 0 : _g.small, workspaceName: workspace.name, size: "32px" }), jsxs(Truncate, { children: [jsx(WorkspaceNameText, { children: workspace.name }), jsx(WorkspaceHelpText, { children: renderHelpText }), warningText] }), actions] }), workspace.identifier));
};

export { WorkspaceRow };

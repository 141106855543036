import { dataIdFromObject } from '@sketch/graphql-cache'
import { DataProxy } from 'apollo-cache'

import {
  useEnablePersonalTokenUsageMutation,
  useDisablePersonalTokenUsageMutation,
  WorkspaceMembershipFragment,
  WorkspaceMembershipFragmentDoc,
} from '@sketch/gql-types'

interface TogglePersonalTokenUsageVariables {
  member: WorkspaceMembershipFragment
  workspaceIdentifier: string
}

type PersonalTokenPermissions = WorkspaceMembershipFragment['personalTokenPermissions']

const updateCachedMember = (
  cache: DataProxy,
  member: WorkspaceMembershipFragment,
  personalTokenPermissions: PersonalTokenPermissions
) => {
  cache.writeFragment({
    id: dataIdFromObject(member) as string,
    fragment: WorkspaceMembershipFragmentDoc,
    data: { ...member, personalTokenPermissions },
  })
}

const useEnablePersonalTokenUsage = () => {
  const [mutation] = useEnablePersonalTokenUsageMutation({
    onError: 'show-toast',
  })

  return async ({
    member,
    workspaceIdentifier,
  }: TogglePersonalTokenUsageVariables) => {
    return await mutation({
      variables: {
        userIdentifier: member.user!.identifier,
        workspaceIdentifier,
      },
      update: (cache, { data, errors }) => {
        if (!data || (errors && errors.length > 0)) return

        updateCachedMember(cache, member, 'SSO_MEMBER_ALLOW')
      },
    })
  }
}

const useDisablePersonalTokenUsage = () => {
  const [mutation] = useDisablePersonalTokenUsageMutation({
    onError: 'show-toast',
  })

  return async ({
    member,
    workspaceIdentifier,
  }: TogglePersonalTokenUsageVariables) => {
    return await mutation({
      variables: {
        userIdentifier: member.user!.identifier,
        workspaceIdentifier,
      },
      update: (cache, { data, errors }) => {
        if (!data || (errors && errors.length > 0)) return

        updateCachedMember(cache, member, 'SSO_MEMBER_DENY')
      },
    })
  }
}

export { useEnablePersonalTokenUsage, useDisablePersonalTokenUsage }

import React from 'react'

import { Tooltip, TimeAgo } from '@sketch/components'
import { dateFormat } from '@sketch/utils'
import { CommentDateWrapper } from './CommentDate.styles'

export interface CommentDateProps {
  label?: string
  date: string
  className?: string
}

const CommentDate: React.FC<CommentDateProps> = ({
  date,
  label,
  className,
}) => {
  return (
    <Tooltip content={dateFormat(new Date(date))} placement="bottom-start">
      {props => (
        <CommentDateWrapper className={className} {...props}>
          {label && `${label} `}
          <TimeAgo date={date} short />
        </CommentDateWrapper>
      )}
    </Tooltip>
  )
}

export default CommentDate

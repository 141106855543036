import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';
import { Container, Header, Title, CloseButton, StyledCrossIcon, Content } from './DismissableCard.styles.js';

// Globals
const TRANSITION_STEPS = {
    from: {
        opacity: 0,
        // added the zIndex to fix a glitch while animating the opacity
        zIndex: 1,
    },
    enter: {
        opacity: 1,
        zIndex: 1,
    },
    leave: {
        opacity: 0,
        zIndex: 1,
    },
};
/**
 * DismissableCard
 *
 * Renders a "Card"-like container with header for the title
 * and can render any content. It also can be dismissed by presssing a "X".
 *
 * TODO: Needs a Storybook story and unit tests
 */
const DismissableCard = ({ className, title, children, isVisible: isInitiallyVisible, onDismiss, }) => {
    const [isVisible, setIsVisible] = useState(isInitiallyVisible);
    const transitions = useTransition(isVisible, null, TRANSITION_STEPS);
    // This component at the time of implementation
    // didn't need to keep track of its visible state
    // from the outside, only its initial visiblity state.
    // However for more flexibility we added this useEffect
    useEffect(() => {
        setIsVisible(isInitiallyVisible);
    }, [isInitiallyVisible]);
    function handleDismiss(event) {
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        setIsVisible(false);
        onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss();
    }
    return (jsx(Fragment, { children: transitions.map(({ item, props }) => item && (jsx(animated.div, Object.assign({ style: props }, { children: jsxs(Container, Object.assign({ className: className }, { children: [jsxs(Header, { children: [jsx(Title, { children: title }), onDismiss && (jsxs(CloseButton, Object.assign({ onClick: handleDismiss }, { children: [jsx("span", Object.assign({ className: "sr-only" }, { children: "Close banner" })), jsx(StyledCrossIcon, {})] })))] }), jsx(Content, { children: children })] })) }), "dismissable-card"))) }));
};

export { DismissableCard };

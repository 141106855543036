import styled from 'styled-components'

export const ContentContainer = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`

export const FontWeightRegular = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`

export const NoticeIcon = styled.svg`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 8px;
`

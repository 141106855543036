import React from 'react'
import truncate from 'lodash.truncate'
import styled, { css } from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import {
  Text,
  Avatar,
  truncateLines,
  Button,
  FlatButton,
  Panel as BasePanel,
} from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'
import { Inviter } from '@sketch/modules-common'

export const headingTextForInvite = (
  resourceType: 'document' | 'project',
  resourceName?: string,
  inviterName?: string
) => {
  const formattedDocumentName = `“${resourceName}”`

  if (inviterName) {
    return (
      <>
        <strong>{inviterName}</strong> wants to share a {resourceType} with you
      </>
    )
  }

  return `You have been invited to join ${
    resourceName ? formattedDocumentName : `this ${resourceType}`
  }`
}

export const Panel = styled(BasePanel)`
  max-width: 100%;
`

export const BodyPanel = styled(BasePanel.Body)`
  ${breakpoint('xs')`
    padding: 16px;
  `}
`

export const MainAction = styled(Button)`
  && {
    margin-bottom: 16px;
  }
`

export const SecondaryAction = styled(FlatButton).attrs({
  variant: 'secondary-untinted',
})(
  ({ theme }) => css`
    && {
      margin-left: 0;
    }

    font-size: ${theme.fontSizes.E};
    border: 1px solid ${theme.colors.border.B};
    border-radius: ${theme.radii.medium};
    width: 100%;
  `
)

export const Line = styled.div`
  width: 100%;
  margin-top: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`

export const SSOLink = styled(RouterLink)`
  margin-top: 24px;
  font-size: 0.875rem;
  text-align: center;
  display: block;
`

interface HeadingProps {
  resourceType: 'document' | 'project'
  resourceName?: string
  inviter?: Inviter | null
}

export const Content = styled(Text).attrs({
  'aria-label': 'content-explanation',
  textStyle: 'copy.tertiary.standard.E',
})`
  word-wrap: break-word;

  strong {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`

export const ContentTitle = styled(Content)`
  margin-top: ${({ theme }) => theme.space[4]}px;
  margin-bottom: ${({ theme }) => theme.space[8]}px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.F};
  word-wrap: break-word;
`

export const Heading = ({
  resourceType,
  resourceName,
  inviter,
}: HeadingProps) => (
  <ContentTitle>
    {headingTextForInvite(
      resourceType,
      truncate(resourceName, { length: 80 }),
      inviter?.name || undefined
    )}
  </ContentTitle>
)

const WrapperName = styled.div`
  display: inline-flex;
  white-space: pre-wrap;
  align-items: baseline;
  margin-left: 4px;
`

const AvatarName = styled.span`
  word-break: break-word;
  ${truncateLines(3)};
  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  margin-left: 8px;
`

const StyledAvatar = styled(Avatar)`
  align-self: center;
`

export const NameWithAvatar = ({
  name,
  src,
}: {
  name: string
  src?: string | null
}) => (
  <WrapperName>
    <StyledAvatar size="16px" src={src || ''} name={name} />
    <AvatarName>
      <strong>{name}</strong>
    </AvatarName>
  </WrapperName>
)

export const PanelFooter = styled(BasePanel.Footer)``

export const InvitedEmail = styled.span`
  background-color: ${({ theme }) => theme.colors.sketch.F};
`

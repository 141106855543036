import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import styled, { css } from 'styled-components';
import '../Tooltip/index.js';
import { useIsNodeContentTruncated } from '@sketch/utils';
import { Tooltip } from '../Tooltip/Tooltip.js';

const truncateStyles = css `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
/**
 * Usage: <Truncate>long text to truncate</Truncate>
 */
const Truncate = styled.div `
  max-width: ${({ width }) => (width ? `${width}px` : '100%')};
  ${truncateStyles};
`;
const TooltipStyled = styled(Tooltip) `
  overflow: hidden;
  max-width: 100%;
`;
const truncateLines = (lines = 2) => css `
  display: -webkit-box; /* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
  overflow: hidden;
`;
/**
 * Usage:
 *  <TruncateWithTooltip placement="bottom">
 *    Text to truncate with a tooltip with the whole text showing at the bottom
 *  </TruncateWithTooltip>
 *
 * Note: You can use any prop from Tooltip or Truncate
 */
const TruncateWithTooltip = (_a) => {
    var { children, width } = _a, props = __rest(_a, ["children", "width"]);
    const [isTruncated, nodeRef] = useIsNodeContentTruncated();
    return (jsx(TooltipStyled, Object.assign({ content: children, disabled: !isTruncated }, props, { children: jsx(Truncate, Object.assign({ ref: nodeRef, width: width }, { children: children })) })));
};

export { Truncate, TruncateWithTooltip, truncateLines, truncateStyles };

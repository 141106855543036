import styled from 'styled-components';

const MainContainer = styled.div `
  width: 595px;
  display: flex;
  flex-direction: column;
`;
const Header = styled.header `
  display: flex;
  padding: 24px 0 16px;
  border-bottom: 1px solid #e6e6e6;
`;
const Logo = styled.img `
  height: 32px;
  margin: 0 auto;
`;
const Container = styled.div `
  width: 440px;
  display: flex;
  flex-direction: column;
  align-self: center;
`;
const Title = styled.h1 `
  margin: 20px 0 8px;
  padding: 0;

  font-size: ${({ theme }) => theme.fontSizes.G};
  font-weight: 700;
  text-align: center;
  line-height: 1.2;
`;
const Text = styled.p `
  width: 440px;
  margin: 0 auto;

  font-size: ${({ theme }) => theme.fontSizes.C};
  text-align: center;
  color: #737373;
  line-height: 1.3;

  a {
    color: #737373;
  }
`;
const RecoveryCodesContainer = styled.div `
  width: 302px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 24px;
  padding: 20px 36px;
  margin: 16px auto;

  font-size: ${({ theme }) => theme.fontSizes.C};
  text-align: center;

  border: 1px dashed #dfdfdf;
  border-radius: 7px; /* stylelint-disable-line scales/radii */
  background: ${({ theme }) => theme.colors.background.secondary.B};
`;
const CodeContainer = styled.div `
  display: flex;
  line-height: 1.3;
  align-items: center;
`;
const CheckMarkBox = styled.div `
  width: 12px;
  height: 12px;
  margin-right: 6px; /* stylelint-disable-line scales/space */
  border: 1px solid #dfdfdf;
  border-radius: 2px; /* stylelint-disable-line scales/radii */
  background: #ffffff;
`;

export { CheckMarkBox, CodeContainer, Container, Header, Logo, MainContainer, RecoveryCodesContainer, Text, Title };

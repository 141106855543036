import styled from 'styled-components'
import { Separator as BaseSeparator, Button } from '@sketch/components'
import AnnotationListWrapper from '../AnnotationListWrapper'

export const AnnotationInboxListWrapper = styled(AnnotationListWrapper).attrs({
  'data-testid': 'annotation-list',
})``

export const Separator = styled(BaseSeparator)`
  margin: 8px 12px;
`

export const StyledButton = styled(Button)`
  margin-top: 16px;
`

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useState } from 'react';
import { SignInForm, useQueryParams, routes } from '@sketch/modules-common';
import { SKETCH_WEBSITE } from '@sketch/env-config';
import { Panel, Link } from '@sketch/components';
import { Title, Description, FormContainer, PanelFooter, StyledLinkButton, StyledButton } from '../SignInToLinkAccountView.styles.js';

const SIGN_IN_FORM_ID = 'SignInForm';
const FormFields = formProps => (jsxs(Fragment, { children: [jsx(SignInForm.Fields, Object.assign({}, formProps)), jsx(SignInForm.Errors, Object.assign({}, formProps)), jsx("input", { type: "submit", id: SIGN_IN_FORM_ID, hidden: true })] }));
/**
 *
 * Renders the Sign In form in the first step for the SSO Account linking flow
 */
const SignInInterface = ({ workspaceId, onCompleted, }) => {
    const [signInLoading, setSignInLoading] = useState(false);
    const queryParams = useQueryParams();
    return (jsxs(Fragment, { children: [jsx(Title, { children: "Link to Existing Sketch Account?" }), jsxs(Panel.Body, { children: [jsxs(Description, { children: ["Want to quickly switch between Workspaces? Link your single sign-on (SSO) account to your other Sketch accounts. \u00A0", jsx(Link, Object.assign({ href: `${SKETCH_WEBSITE}/redirect/web/sso`, target: "_blank", rel: "noopener noreferrer", variant: "primary", isUnderlined: true, external: true }, { children: "Learn more" }))] }), jsx(FormContainer, { children: jsx(SignInForm, Object.assign({ onCompleted: onCompleted }, { children: formikBag => {
                                setSignInLoading(formikBag.isSubmitting);
                                return jsx(FormFields, Object.assign({}, formikBag));
                            } })) })] }), jsxs(PanelFooter, { children: [jsx(StyledLinkButton, Object.assign({ to: routes.SSO_TOS.create({
                            workspaceId,
                            query: queryParams,
                        }), variant: "secondary", disabled: signInLoading, size: "40" }, { children: "Don't Link" })), jsx(StyledButton, Object.assign({ variant: "primary", loading: signInLoading, disabled: signInLoading, size: "40" }, { children: jsx("label", Object.assign({ htmlFor: SIGN_IN_FORM_ID }, { children: "Next" })) }))] })] }));
};

export { FormFields, SignInInterface as default };

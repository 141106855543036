import { __awaiter } from 'tslib';
import { useRef, useReducer, useEffect } from 'react';

// Using a create reducer function allows the State generic type to be inferred
// properly in the component function body
function createReducer() {
    return (state, action) => {
        switch (action.type) {
            case 'loading':
                return { status: 'loading' };
            case 'complete':
                return { data: action.data, status: 'complete' };
            case 'error':
                return { error: action.error, status: 'error' };
            default:
                return state;
        }
    };
}
/**
 * useFetch hook for loading JSON, with the following features:
 * - Only invokes when the `url` changes and is truthy
 * - Aborts requests in progress when url changes or component unmounts
 * - Support for a validator function to smoke test the returned data
 * - Pass in TypeScript generic for the returned data type
 */
function useFetchJSON(url, options = {}) {
    const optionsRef = useRef(options); // Freeze the options in a ref
    const [state, dispatch] = useReducer(createReducer(), {
        status: 'loading',
    });
    useEffect(() => {
        if (!url)
            return;
        dispatch({ type: 'loading' });
        const controller = new AbortController();
        (function invokeFetch(url) {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const res = yield fetch(url, Object.assign(Object.assign({}, optionsRef.current), { signal: controller.signal }));
                    if (!res.ok)
                        throw Error(res.statusText);
                    const data = (yield res.json());
                    const validator = (_b = (_a = optionsRef.current) === null || _a === void 0 ? void 0 : _a.validator) !== null && _b !== void 0 ? _b : (() => true);
                    if (!validator(data))
                        throw Error('JSON is invalid');
                    dispatch({ type: 'complete', data });
                }
                catch (error) {
                    if (controller.signal.aborted)
                        return;
                    if (error instanceof Error)
                        dispatch({ type: 'error', error });
                }
            });
        })(url);
        return () => {
            controller.abort();
        };
    }, [url]);
    return state;
}

export { useFetchJSON };

import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '@sketch/global-styles';
import { SectionElement, TitleAndActionsWrapper, LeftWrapper, Title, TextAndButtonWrapper, ActionWrapper, RightWrapper } from './Section.styles.js';

const getId = (id) => {
    if (!id)
        return undefined;
    return { id };
};
const SectionUnstyled = React.forwardRef(function Section(props, ref) {
    const { className, children, title, text, action, align = 'bottom', 'data-testid': dataTestId, id, } = props;
    return (jsxs(SectionElement, Object.assign({ ref: ref, className: className, "data-testid": dataTestId }, getId(id), { children: [jsxs(TitleAndActionsWrapper, { children: [jsxs(LeftWrapper, { children: [jsx(Title, { children: title }), (text || action) && (jsx(Fragment, { children: jsxs(TextAndButtonWrapper, { children: [text, action && align === 'bottom' && (jsx(ActionWrapper, { children: action }))] }) }))] }), action && align === 'center' && (jsx(RightWrapper, { children: jsx(ActionWrapper, { children: action }) }))] }), children] })));
});
const Section = styled(SectionUnstyled) `
  & + & {
    margin-top: 40px;

    ${breakpoint('sm') `
      margin-top: 64px;
    `}
  }
`;

export { Section };

import styled, { css } from 'styled-components';
import '../../Box/index.js';
import '../../Avatar/index.js';
import '../../PersonAvatar/index.js';
import '../../Tooltip/index.js';
import '../../Truncate/index.js';
import '../../Pill/index.js';
import { Flex, Text } from '../../Box/BoxSystem.js';
import { Avatar as Avatar$1 } from '../../Avatar/Avatar.js';
import { Pill } from '../../Pill/Pill.js';
import { Tooltip } from '../../Tooltip/Tooltip.js';
import { truncateStyles, Truncate } from '../../Truncate/Truncate.js';
import { PersonAvatar } from '../../PersonAvatar/PersonAvatar.js';

/**
 * We set the padding defaults as 12px 8px 12px 16px
 * But using the API of Box we can set them depending on the
 * context.
 *
 * A potential use case could be removing the left padding in order to
 * align the avatar with an upper component
 */
const Wrapper = styled(Flex) `
  position: relative;

  align-items: center;
  padding: ${({ pl = 4, pt = 3, pr = 2, pb = 3, theme: { space } }) => `${space[pt]}px ${space[pr]}px ${space[pb]}px ${space[pl]}px`};

  height: ${({ height = 64 }) => height}px;

  ${({ showStripes }) => showStripes &&
    css `
      &:nth-child(odd) {
        background-color: ${({ theme }) => theme.colors.background.secondary.B};
        border-radius: ${({ theme }) => theme.radii.large};
      }
    `}

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0px;
  }
`;
/**
 * This avatar can be used for users and also projects/shares.
 * For this last scenario you might want to tackle the empty src
 * cases separately, as this will render the default state for the Avatar
 * (colored background with a letter)
 *
 * Custom properties:
 *
 * - invitationNeeded is for the avatars of the people that didn't accepted the
 * invitation yet, it will gray them out a bit
 * - mr for setting the margin right on the avatar. This is useful if you need
 * extra separation in some scenarios
 * - size is for setting a custom size
 * - square is for rendering a square with rounded corners instead of a circle
 */
const Avatar = styled(Avatar$1) `
  display: flex;
  margin-right: ${({ theme, mr }) => theme.space[mr || 3]}px;
  min-width: ${({ size }) => size};
  ${({ invitationRequested }) => invitationRequested &&
    css `
      opacity: 50%;
    `};
  ${({ theme, square }) => square &&
    css `
      border-radius: ${theme.radii.medium};
    `}
`;
Avatar.defaultProps = {
    size: '32px',
};
const StyledPill = styled(Pill) `
  margin-left: 8px;
`;
const GuestBadge = styled(Pill).attrs({
    variant: 'guest',
}) `
  margin-left: 8px;
`;
const TooltipWithTruncatedContent = styled(Tooltip) `
  overflow: hidden;
  display: flex;
`;
const Subtitle = styled(Text.Div).attrs({
    textStyle: 'copy.quaternary.standard.D',
}) `
  ${truncateStyles}
  width: 100%;
`;
const StyledTruncate = styled(Truncate) `
  font-weight: 500;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  ${({ invitationRequested }) => invitationRequested &&
    css `
      color: ${({ theme }) => theme.colors.foreground.secondary.D};
    `}
`;
const PlaceholderAvatar = styled(PersonAvatar) `
  background-color: ${({ theme }) => theme.colors.background.tertiary.B};
`;
PlaceholderAvatar.defaultProps = {
    flavour: 'color',
};
/**
 * This avatar will be used basically for projects or shares
 * in the permission modals. It will render an icon over a
 * grey background. It will require to pass as children the svg icon
 *
 */
const BaseIconAvatar = styled(Flex) `
  width: 32px;
  height: 32px;
  border-radius: ${({ theme }) => theme.radii.medium};
  background-color: ${({ theme }) => theme.colors.background.tertiary.B};
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.colors.foreground.secondary.B};
  }
`;

export { Avatar, BaseIconAvatar as BaseListRowIconAvatar, PlaceholderAvatar as BaseListRowPlaceholderAvatar, GuestBadge, StyledPill as Pill, StyledTruncate, Subtitle, TooltipWithTruncatedContent, Wrapper };

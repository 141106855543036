import React from 'react'

import {
  Flex,
  LoadingPlaceholder,
  Text,
  useModalContext,
} from '@sketch/components'
import { TroubleshootingConsentModal } from 'modules/workspace/modals/TroubleshootingConsentModal'

import { icons } from '../../icons'

// GQL
import { useGetWorkspaceSupportAccessQuery } from '@sketch/gql-types'

import { dateFormat } from '@sketch/utils'

import {
  DropdownItem,
  StyledSelectDropdownButtonItem,
} from './TroubleshootingConsentMenuItem.styles'

interface TroubleshootingConsentMenuItemProps {
  workspaceId?: string
  workspaceName?: string
}

export const TroubleshootingConsentMenuItem: React.FC<TroubleshootingConsentMenuItemProps> = ({
  workspaceId,
  workspaceName,
}) => {
  const { showModal } = useModalContext()
  const { data, loading, error } = useGetWorkspaceSupportAccessQuery({
    variables: {
      identifier: workspaceId!,
    },
    skip: !workspaceId,
  })

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" w="100%" h={48}>
        <LoadingPlaceholder />
      </Flex>
    )
  }

  if (error) {
    return null
  }

  return (
    <DropdownItem>
      <StyledSelectDropdownButtonItem
        onClick={() =>
          showModal(TroubleshootingConsentModal, {
            workspaceId: workspaceId!,
            workspaceName: workspaceName!,
            supportAccessGrantedUntil:
              data?.workspace.supportAccessGrantedUntil,
          })
        }
        icon={icons.LockOpen}
        text={
          <>
            <Text.Li textStyle="copy.secondary.standard.D">
              Support Access…
            </Text.Li>
            {data?.workspace.supportAccessGrantedUntil ? (
              <Text
                textStyle="copy.quaternary.standard.D"
                color="state.positive.A"
                fontSize="C"
              >
                Enabled until{' '}
                {dateFormat(
                  new Date(data?.workspace.supportAccessGrantedUntil),
                  undefined,
                  {
                    dateStyle: 'medium',
                    timeZone: 'UTC',
                  }
                )}
              </Text>
            ) : (
              <Text textStyle="copy.quaternary.standard.D" fontSize="C">
                Disabled
              </Text>
            )}
          </>
        }
      />
    </DropdownItem>
  )
}

import React from 'react'
import { useFlag } from '@sketch/modules-common'

import { Box, Flex, Text, UnstyledList } from '@sketch/components'
import {
  StyledDocumentCardIcons,
  StyledIcon,
  PlaceholderDSLogo,
  DSLogo,
  StyledTooltip,
} from './DocumentCardIcons.styles'

import { DocumentSharingStatus } from 'modules/shares/utils/getDocumentSharingStatus'

import { ReactComponent as SymbolIcon } from '@sketch/icons/link-library-16'
import { ReactComponent as TwoPersonsIcon } from '@sketch/icons/persons-two-16'
import { ReactComponent as LockIcon } from '@sketch/icons/lock-16'
import { ReactComponent as GlobeIcon } from '@sketch/icons/globe-16'
import { ReactComponent as DSIcon } from '@sketch/icons/design-system-16'
import { ReactComponent as TemplatesIcon } from '@sketch/icons/templates-16'
import { ReactComponent as CommunityIcon } from '@sketch/icons/community-16'
import { ReactComponent as PinIcon } from '@sketch/icons/pin-16'
import { ShareType } from 'modules/shares/components/EditInSketchButton'

export interface DesignSystemForLibraryList {
  name: string
  preview: string | null
}

interface IconWithTooltipProps {
  children: React.ReactNode
  content: React.ReactNode
  className?: string
}

const IconTooltip = ({
  children,
  content,
  className,
}: IconWithTooltipProps) => {
  return (
    <StyledTooltip
      placement="bottom-start"
      content={content}
      className={className}
    >
      {children}
    </StyledTooltip>
  )
}

export const sharedStatusToIconMap = (
  isRefreshedUi: boolean
): Record<DocumentSharingStatus, React.ReactNode> => {
  return {
    ANYONE_WITH_LINK: (
      <IconTooltip content={<span>Anyone with the link can access</span>}>
        <StyledIcon
          as={GlobeIcon}
          aria-label="Publicly shared icon"
          $isRefreshedUi={isRefreshedUi}
        />
      </IconTooltip>
    ),
    GUESTS: (
      <IconTooltip content={<span>Selected Guests can access</span>}>
        <StyledIcon
          as={TwoPersonsIcon}
          aria-label="Guests icon"
          $isRefreshedUi={isRefreshedUi}
        />
      </IconTooltip>
    ),
    SELECTED_WORKSPACE_MEMBERS: (
      <IconTooltip
        content={<span>Selected people in your Workspace can access</span>}
      >
        <StyledIcon
          as={LockIcon}
          aria-label="Lock icon"
          $isRefreshedUi={isRefreshedUi}
        />
      </IconTooltip>
    ),
    ONLY_YOU: (
      <IconTooltip content={<span>Only you can access</span>}>
        <StyledIcon
          as={LockIcon}
          aria-label="Lock icon"
          $isRefreshedUi={isRefreshedUi}
        />
      </IconTooltip>
    ),
    WORKSPACE_MEMBERS: null,
    PUBLICLY_VISIBLE: (
      <IconTooltip content={<span>Publicly available</span>}>
        <StyledIcon
          as={CommunityIcon}
          aria-label="Publicly shared icon"
          $isRefreshedUi={isRefreshedUi}
        />
      </IconTooltip>
    ),
  }
}

const DesignSystemsIcon = ({
  designSystems,
}: {
  designSystems: DesignSystemForLibraryList[]
}) => {
  if (!designSystems.length) {
    return null
  }

  return (
    <>
      <Text fontWeight={600} mb={1}>
        Part of
      </Text>
      <Box mt={2}>
        <UnstyledList>
          {designSystems.map(designSystem => (
            <li key={designSystem.name}>
              <Flex mt={1}>
                <Box width={16} mr={2}>
                  {designSystem.preview ? (
                    <DSLogo src={designSystem.preview} />
                  ) : (
                    <PlaceholderDSLogo />
                  )}
                </Box>
                <Text color="foreground.primary.B">{designSystem.name}</Text>
              </Flex>
            </li>
          ))}
        </UnstyledList>
      </Box>
    </>
  )
}

export const PinnedIcon = () => {
  // TODO: Remove FF "ui-refresh-24" when its released
  // https://github.com/orgs/sketch-hq/projects/326/views/1?pane=issue&itemId=65739330
  const isRefreshedUi = useFlag('ui-refresh-24')

  return (
    <IconTooltip content={<span>Pinned to the top</span>}>
      <StyledIcon
        as={PinIcon}
        aria-label="Pin icon"
        $isRefreshedUi={isRefreshedUi}
      />
    </IconTooltip>
  )
}

export const TemplateIcon = () => {
  // TODO: Remove FF "ui-refresh-24" when its released
  // https://github.com/orgs/sketch-hq/projects/326/views/1?pane=issue&itemId=65739330
  const isRefreshedUi = useFlag('ui-refresh-24')

  return (
    <IconTooltip content={<span>Template</span>}>
      <StyledIcon
        as={TemplatesIcon}
        aria-label="Template icon"
        $isRefreshedUi={isRefreshedUi}
      />
    </IconTooltip>
  )
}

interface LibraryIconProps {
  showDesignSystems: boolean
  designSystems?: DesignSystemForLibraryList[]
}

export const LibraryIcon: React.FC<LibraryIconProps> = ({
  showDesignSystems,
  designSystems,
}) => {
  // TODO: Remove FF "ui-refresh-24" when its released
  // https://github.com/orgs/sketch-hq/projects/326/views/1?pane=issue&itemId=65739330
  const isRefreshedUi = useFlag('ui-refresh-24')

  return (
    <>
      <IconTooltip content={<span>Library</span>}>
        <StyledIcon
          as={SymbolIcon}
          aria-label="Library icon"
          $isRefreshedUi={isRefreshedUi}
        />
      </IconTooltip>
      {showDesignSystems && (
        <IconTooltip
          content={<DesignSystemsIcon designSystems={designSystems ?? []} />}
        >
          <StyledIcon
            as={DSIcon}
            aria-label="Design System icon"
            $isRefreshedUi={isRefreshedUi}
          />
        </IconTooltip>
      )}
    </>
  )
}

export interface DocumentCardIconsProps {
  documentSharingStatus: DocumentSharingStatus
  designSystems?: DesignSystemForLibraryList[]
  type?: ShareType
  pinned?: string | null
}

const DocumentCardIcons = ({
  documentSharingStatus,
  designSystems,
  type,
  pinned,
}: DocumentCardIconsProps) => {
  // TODO: Remove FF "ui-refresh-24" when its released
  // https://github.com/orgs/sketch-hq/projects/326/views/1?pane=issue&itemId=65739330
  const isRefreshedUi = useFlag('ui-refresh-24')

  const isDesignSystemsOn = useFlag('design-systems')
  const isLibrary = type === 'LIBRARY'
  const isTemplate = type === 'TEMPLATE'

  if (
    !isLibrary &&
    !isTemplate &&
    documentSharingStatus === 'WORKSPACE_MEMBERS' &&
    !pinned
  ) {
    // No icons to show
    return null
  }

  const sharingStatusIcon = sharedStatusToIconMap(isRefreshedUi)[
    documentSharingStatus
  ]
  const showDesignSystems = !!(isDesignSystemsOn && designSystems?.length)

  return (
    <StyledDocumentCardIcons data-testid="document-card-icon-container">
      {pinned && <PinnedIcon />}
      {sharingStatusIcon}
      {isLibrary && (
        <LibraryIcon
          showDesignSystems={showDesignSystems}
          designSystems={designSystems}
        />
      )}
      {isTemplate && <TemplateIcon />}
    </StyledDocumentCardIcons>
  )
}

export default DocumentCardIcons

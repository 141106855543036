/**
 * @file
 * The types coming from the web-renderer are most of the time not usable directly in the frontend.
 * Some property name can vary, enums are different, arrays are EmbindVectors etc. This file contains
 * functions to transform the types coming from the web-renderer to the types we use in SketchScene.
 */

import {
  EmbindVector,
  PRMarinaAppearance,
  PRMarinaAttributedString,
  PRMarinaBlur,
  PRMarinaBorder,
  PRMarinaBorderOptions,
  PRMarinaColor,
  PRMarinaCorners,
  PRMarinaExportFormat,
  PRMarinaExportFormats,
  PRMarinaFill,
  PRMarinaGradient,
  PRMarinaShadowStyle,
  PRMarinaSharedStyleReference,
  PRMarinaSymbolMasterReference,
  PRMarinaTextAttributes,
  convertEmbindVectorToArray,
} from '@sketch-hq/sketch-web-renderer'
import {
  Appearance,
  Blur,
  BorderOptions,
  Color,
  ExportFormat,
  Fill,
  Gradient,
  GradientStops,
  LayerCorners,
  Shadow,
  SharedStyleReference,
  SymbolMaster,
  SymbolMasterBase,
  TextAttributedString,
  TextPartialString,
} from 'modules/inspector'
import {
  mapBlendMode,
  mapBlur,
  mapBorderPosition,
  mapCornerStyle,
  mapExportFormatType,
  mapFillType,
  mapGradientType,
  mapHorizontalTextAlignment,
  mapImageFillType,
  mapLineCapStyle,
  mapLineJoinStyle,
  mapTextDecoration,
  mapTextTransform,
  mapVisibleScaleType,
} from './presentationMarinaEnumsMapping'
import { computeSymbolMasterNames } from '../utils'

export function transformPresentationFileColor(
  prMarinaColor: PRMarinaColor
): Color {
  const colorValue = prMarinaColor.value
  const colorVar = prMarinaColor.getVariableReference()
  // Color components in the presentation file are between 0 and 1, but the
  // FE expects values between 0 and 255. The rounding is required to avoid
  // fractional component values.
  return {
    blue: Math.round(colorValue.b * 255),
    green: Math.round(colorValue.g * 255),
    red: Math.round(colorValue.r * 255),
    alpha: colorValue.a,
    colorVariableID: colorVar?.id,
    colorVariableName: colorVar?.name,
    colorVariableRemoteID: colorVar?.remoteId,
    colorVariableSourceLibraryID: colorVar?.sourceLibraryId,
    colorVariableSourceLibraryName: colorVar?.sourceLibraryName,
  }
}

export function transformPresentationBlur(prBlur: PRMarinaBlur): Blur {
  // Value is between 0 and 2 where 2 is 100% and 0 is -100%
  const prSaturation = prBlur.saturation
  // Convert to positive or negative percentage (between -100% and 100%)
  // We want 0.68 to be -32% and 1.49 to be 49%
  const saturation = Math.round((prSaturation - 1) * 100)

  return {
    type: mapBlur(prBlur.type.value),
    isEnabled: prBlur.isEnabled,
    center: prBlur.center,
    motionAngle: prBlur.motionAngle,
    radius: prBlur.radius,
    saturation: saturation,
  }
}

export function transformPresentationFills(
  prFills: EmbindVector<PRMarinaFill>
): Fill[] {
  return convertEmbindVectorToArray(prFills).map(prFill => {
    const fillType = mapFillType(prFill.fillType.value)
    const prColor = prFill.getColor()
    const appearance = prFill.appearance
    const prGradient = prFill.getGradient()
    const patternFillType = mapImageFillType(prFill.patternFillType.value)

    return {
      isEnabled: prFill.isEnabled,
      type: fillType,
      color: prColor ? transformPresentationFileColor(prColor) : undefined,
      appearance: transformPresentationAppearance(appearance),
      gradient: prGradient
        ? transformPresentationGradient(prGradient)
        : undefined,
      patternFillType: patternFillType,
      patternTileScale: prFill.patternTileScale,
    }
  })
}

export function transformPresentationAppearance(
  prAppearance: PRMarinaAppearance
): Appearance {
  const blendMode = mapBlendMode(prAppearance.blendMode.value)

  return {
    blendMode: blendMode,
    opacity: prAppearance.opacity,
  }
}

export function transformPresentationBorders(
  prBorders: EmbindVector<PRMarinaBorder>
) {
  return convertEmbindVectorToArray(prBorders).map(prBorder => {
    const borderPosition = mapBorderPosition(prBorder.position.value)
    const prGradient = prBorder.getGradient()
    const borderFillType = mapFillType(prBorder.fillType.value)
    const borderColor = prBorder.getColor()

    return {
      appearance: transformPresentationAppearance(prBorder.appearance),
      isEnabled: prBorder.isEnabled,
      thickness: prBorder.thickness,
      position: borderPosition,
      color: borderColor
        ? transformPresentationFileColor(borderColor)
        : undefined,
      gradient: prGradient
        ? transformPresentationGradient(prGradient)
        : undefined,
      fillType: borderFillType,
    }
  })
}

function transformPresentationGradient(prGradient: PRMarinaGradient): Gradient {
  const gradientType = mapGradientType(prGradient.type.value)
  const gradientStops: GradientStops[] = convertEmbindVectorToArray(
    prGradient.stops
  ).map(prGradientStop => ({
    color: transformPresentationFileColor(prGradientStop.color),
    position: prGradientStop.position,
  }))

  return {
    from: prGradient.from,
    to: prGradient.to,
    type: gradientType,
    stops: gradientStops,
  }
}

export function transformPresentationBorderOptions(
  prBorderOptions: PRMarinaBorderOptions
): BorderOptions {
  const lineCapStyle = mapLineCapStyle(prBorderOptions.lineCapStyle.value)
  const lineJoinStyle = mapLineJoinStyle(prBorderOptions.lineJoinStyle.value)
  const dashPattern = convertEmbindVectorToArray(prBorderOptions.dashPattern)
  return {
    isEnabled: true,
    dashPattern:
      dashPattern.length === 2 ? [dashPattern[0], dashPattern[1]] : [],
    lineCapStyle: lineCapStyle,
    lineJoinStyle: lineJoinStyle,
  }
}

export function transformPresentationShadows(
  prShadows: EmbindVector<PRMarinaShadowStyle>
): Shadow[] {
  return convertEmbindVectorToArray(prShadows).map(prShadow => ({
    isEnabled: prShadow.isEnabled,
    blurRadius: prShadow.radius,
    color: transformPresentationFileColor(prShadow.color),
    offsetX: prShadow.offset.x,
    offsetY: prShadow.offset.y,
    spread: prShadow.spread,
    appearance: transformPresentationAppearance(prShadow.appearance),
  }))
}

export function transformCorners(prCorners: PRMarinaCorners): LayerCorners {
  return {
    type: mapCornerStyle(prCorners.style.value),
    radius: convertEmbindVectorToArray(prCorners.radius),
  }
}

export function transformAttributedString(
  prAttributedString: PRMarinaAttributedString
): TextAttributedString {
  return {
    string: prAttributedString.text,
    attributes: convertEmbindVectorToArray(prAttributedString.attributes).map(
      transformTextAttributes
    ),
  }
}

export function transformTextAttributes(
  prTextAttributes: PRMarinaTextAttributes
): TextPartialString {
  return {
    length: prTextAttributes.range.length,
    location: prTextAttributes.range.location,
    attributes: {
      font: {
        family: prTextAttributes.font.family,
        size: prTextAttributes.font.size,
        style: prTextAttributes.font.style,
        weight: String(prTextAttributes.font.weight),
      },
      color: transformPresentationFileColor(prTextAttributes.color),
      letterSpacing: prTextAttributes.letterSpacing,
      paragraphStyle: {
        alignment: mapHorizontalTextAlignment(
          prTextAttributes.paragraphStyle.alignment.value
        ),
        spacing: prTextAttributes.paragraphStyle.spacing,
        lineHeight: prTextAttributes.paragraphStyle.maxLineHeight,
      },
      decoration: mapTextDecoration(prTextAttributes.decoration.value),
      transform: mapTextTransform(prTextAttributes.transform.value),
    },
  }
}

export function transformSharedStyleReference(
  value: PRMarinaSharedStyleReference
): SharedStyleReference {
  return {
    id: value.id,
    remoteId: value.remoteId,
    isForeign: value.isForeign,
    documentId: value.documentId,
    documentName: value.documentName,
    name: value.name,
  }
}

export function transformSymbolMasterReference(
  value: PRMarinaSymbolMasterReference
): SymbolMaster {
  const base: SymbolMasterBase = {
    name: value.name,
    symbolMasterId: value.symbolId,
    artboardUuid: value.objectId,
    ...computeSymbolMasterNames(value.name),
  }

  if (value.isForeign) {
    return {
      ...base,
      isForeign: true,
      documentUuid: value.documentId,
      documentName: value.documentName,
    }
  } else {
    return {
      ...base,
      isForeign: false,
    }
  }
}

export function transformExportFormat(
  value: PRMarinaExportFormat
): ExportFormat {
  return {
    fileFormat: mapExportFormatType(value.format.value),
    scale: value.scale,
    absoluteSize: value.absoluteSize,
    visibleScaleType: mapVisibleScaleType(value.visibleScaleType.value),
  }
}

export function transformExportFormatsComponent(value: PRMarinaExportFormats) {
  return convertEmbindVectorToArray(value.formats).map(transformExportFormat)
}

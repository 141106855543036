import React from 'react'
import { AttributeDisplay } from '../Attribute'
import ColorVariable from './ColorVariable'
import PlainColor from './PlainColor'
import { ColorFormat } from 'modules/shares/types'
import { Color as ColorType } from 'modules/inspector'

export interface ColorProps extends ColorType {
  label?: string
  style?: React.CSSProperties
  display?: AttributeDisplay
  dirtyAttributes?: { originalValue: ColorType; originalProperty: string }
  onColorFormatChange: (f: ColorFormat) => void
  colorFormat: ColorFormat
}

/**
 * COMPONENT
 */
export const Color: React.FC<ColorProps> = props => {
  const { colorVariableName } = props

  if (colorVariableName) {
    return <ColorVariable {...props} colorVariableName={colorVariableName!} />
  }

  return <PlainColor {...props} />
}

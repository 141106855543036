import { useEffect } from 'react';

const DoMutation = ({ mutate, children }) => {
    useEffect(() => {
        mutate();
    }, [mutate]);
    if (typeof children === 'function') {
        return children();
    }
    else {
        return children;
    }
};

export { DoMutation };

import React, { useState, useEffect, useMemo } from 'react'
import copy from 'copy-to-clipboard'
import * as Sentry from '@sentry/browser'
import json from 'react-syntax-highlighter/dist/cjs/languages/hljs/json'
import { useAnalytics } from '@sketch/modules-common'

import {
  Flex,
  Box,
  Text,
  Tooltip,
  LoadingPlaceholder,
  Button,
  Dropdown,
  CopyToClipboard,
  Modal,
  SelectOption,
  useModalContext,
  ModalInjectedProps,
} from '@sketch/components'

import {
  ModalDescription,
  ModalSeparator as Separator,
  SyntaxHighlighter,
  syntaxHighlighterTheme,
  FormatSelect,
  ToggleButtonText,
  LinksTitle,
  ToggleButton,
  DropdownItem,
  DropdownContainer,
  DownloadLink,
  ColorFormatButton,
  ColorFormatDropdownContainer,
} from './ExportColorTokens.styles'
import {
  DropdownInnerContainer,
  DropdownChevrons,
} from 'modules/workspace/containers/WorkspacesDropdown/WorkspacesDropdown.styles'

import {
  useGetShareDesignTokenExportQuery,
  useUpdateSharePublicTokenExportMutation,
} from '@sketch/gql-types'

import { ReactComponent as LibraryIcon } from '@sketch/icons/link-sharing-24'

// importing the PublicTokenExport type to help with type inference
// eslint-disable-next-line no-restricted-imports
import {
  PublicTokenExport,
  TokenExportFormat,
  TokenColorFormat,
} from '@sketch/gql-types/expansive'

SyntaxHighlighter.registerLanguage('json', json)

enum ColorFormatDisplay {
  RGBA = 'RGB',
  HSLA = 'HSL',
  HEX = 'Hex',
}

interface ExportColorTokensModalProps extends ModalInjectedProps {
  shareIdentifier: string
  userAccessLevel: string
  versionShortId: string
}

export const ExportColorTokensModal = ({
  shareIdentifier,
  userAccessLevel,
  versionShortId,
}: ExportColorTokensModalProps) => {
  const { trackEvent } = useAnalytics()

  const { hideModal } = useModalContext()
  const [format, setFormat] = useState<TokenExportFormat>('AMAZON')
  const [colorFormat, setColorFormat] = useState<TokenColorFormat>('RGBA')
  const [publicTokenExportUrl, setPublicTokenExportUrl] = useState<
    string | null
  >(null)

  const [publicTokenExport, setPublicTokenExport] = useState<PublicTokenExport>(
    'DISABLED'
  )
  const [tooltipVisible, setTooltipVisible] = useState(false)

  // triple checking that we have the right color format for W3C
  useEffect(() => {
    if (format === 'W3C' && colorFormat !== 'HEX') {
      setColorFormat('HEX')
    }
  }, [format, colorFormat])

  const isW3C = format === 'W3C'

  const { loading, data, error } = useGetShareDesignTokenExportQuery({
    variables: {
      shareIdentifier,
      versionShortId,
      format,
      colorFormat: !isW3C ? colorFormat : 'HEX',
    },
    skip: !shareIdentifier,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    setPublicTokenExport(data?.share?.publicTokenExport ?? 'DISABLED')
    setPublicTokenExportUrl(data?.share?.publicTokenExportUrl ?? null)
  }, [data])

  const filename = useMemo(() => {
    if (!data) {
      return undefined
    }

    const downloadUrlSplit =
      data?.share?.version?.document?.tokenExport?.downloadUrl.split('/') ?? []

    const filenameWithParams = downloadUrlSplit[downloadUrlSplit.length - 1]

    return filenameWithParams?.substring(0, filenameWithParams?.indexOf('?'))
  }, [data])

  const [
    mutatePublicTokenExport,
    { loading: mutationLoading },
  ] = useUpdateSharePublicTokenExportMutation({
    onError: error => Sentry.captureException(error),
    onCompleted: ({ shareUpdate }) =>
      setPublicTokenExportUrl(shareUpdate?.share?.publicTokenExportUrl ?? null),
  })

  const handleCopyClick = () => {
    if (!publicTokenExportUrl) {
      return
    }

    copy(publicTokenExportUrl)

    try {
      trackEvent('CWV - export color tokens public URL copied', {})
    } catch (error) {
      Sentry.captureException(error)
    }

    setTooltipVisible(true)

    window.setTimeout(() => {
      setTooltipVisible(false)
    }, CopyToClipboard.animationDuration)
  }

  const handlePublicLinkStatus = (
    publicTokenExportStatus: PublicTokenExport
  ) => {
    setPublicTokenExport(publicTokenExportStatus)

    trackEvent('CWV - export color tokens public URL status changes', {
      enabled: publicTokenExportStatus,
    })

    mutatePublicTokenExport({
      variables: {
        shareIdentifier,
        shareParams: { publicTokenExport: publicTokenExportStatus },
        format,
        colorFormat,
        tokenTypes: ['COLOR_VARIABLE'],
      },
    })
  }

  if (!shareIdentifier) {
    return null
  }

  if (error) {
    return (
      <Modal title="Export Color Variables as Tokens" onCancel={hideModal}>
        <Modal.Body>
          <Flex mt={5}>
            <p>An error occurred while loading the Tokens.</p>
          </Flex>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={hideModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const handleFormatChange = (format: TokenExportFormat) => {
    setFormat(format)

    if (isW3C) {
      setColorFormat('HEX')
    }
  }
  const handleColorFormatChange = (format: TokenColorFormat) =>
    setColorFormat(format)

  const unformattedCode = data?.share?.version?.document?.tokenExport?.data

  let code = ''
  let type = ''

  switch (format) {
    case 'AMAZON':
    case 'W3C':
      try {
        code = JSON.stringify(JSON.parse(unformattedCode ?? '{}'), null, 2)
        type = 'application/json; charset=UTF-8'
      } catch (e) {
        code = unformattedCode ?? ''
        type = 'text; charset=UTF-8'
      }
      break

    default:
      code = unformattedCode ?? ''
      type = 'text/css; charset=UTF-8'
  }

  const blob = new Blob([code], {
    type,
  })

  // Only Editors + Viewers with Editing rights can edit this dropdown
  const disabledDropdown =
    !!mutationLoading || userAccessLevel !== 'EDIT' || loading

  const getOptionText = (publicTokenExport: PublicTokenExport) => {
    switch (publicTokenExport) {
      case 'LATEST_STARRED':
        return 'Enabled for latest starred version'
      case 'LATEST':
        return 'Enabled for latest document update'
      default:
        return 'Disabled'
    }
  }

  return (
    <Modal title="Export Color Variables as Tokens" onCancel={hideModal}>
      <Modal.Body>
        <ModalDescription>
          Color Tokens allow you to extract the Color Variables from your design
          files in various formats so that you can use them directly in your
          codebase while keeping a single source of truth.
        </ModalDescription>
        <Flex width="100%" justifyContent="space-between">
          <FormatSelect value={format} onChange={handleFormatChange}>
            <SelectOption value="AMAZON">View as JSON</SelectOption>
            <SelectOption value="CSS">View as CSS</SelectOption>
            <SelectOption value="W3C">
              View as Design Tokens File (beta)
            </SelectOption>
          </FormatSelect>
          {format !== 'W3C' && (
            <Dropdown
              placement="bottom-start"
              disableFlip
              usePortal
              toggle={
                <ColorFormatButton>
                  <ToggleButtonText>
                    {ColorFormatDisplay[colorFormat]}
                  </ToggleButtonText>
                  <DropdownChevrons />
                </ColorFormatButton>
              }
            >
              <ColorFormatDropdownContainer>
                <Dropdown.Header>Format Colors As</Dropdown.Header>
                <DropdownItem
                  active={colorFormat === 'RGBA'}
                  onClick={() => handleColorFormatChange('RGBA')}
                >
                  RGB
                </DropdownItem>
                <DropdownItem
                  active={colorFormat === 'HEX'}
                  onClick={() => handleColorFormatChange('HEX')}
                >
                  Hex
                </DropdownItem>
                <DropdownItem
                  active={colorFormat === 'HSLA'}
                  onClick={() => handleColorFormatChange('HSLA')}
                >
                  HSL
                </DropdownItem>
              </ColorFormatDropdownContainer>
            </Dropdown>
          )}
          {!loading && filename && blob ? (
            <DownloadLink
              onClick={() =>
                trackEvent('CWV - export color tokens download', {
                  format,
                })
              }
              href={window.URL.createObjectURL(blob)}
              download={filename}
            >
              Download
            </DownloadLink>
          ) : (
            <Button variant="primary" disabled size="32">
              Download
            </Button>
          )}
        </Flex>
        {loading ? (
          //   different loading heights depending on JSON and everything else
          <Flex
            mt={5}
            height={format === 'AMAZON' ? 219 : 195}
            justifyContent="center"
          >
            <LoadingPlaceholder />
          </Flex>
        ) : (
          <>
            <SyntaxHighlighter
              language={format === 'AMAZON' ? 'json' : ''}
              useInlineStyles={format === 'AMAZON'}
              style={syntaxHighlighterTheme}
            >
              {code}
            </SyntaxHighlighter>
            {format === 'AMAZON' && (
              <Text textStyle="copy.quaternary.standard.C">
                JSON format compatible with{' '}
                <a
                  href="https://amzn.github.io/style-dictionary/#/tokens"
                  rel="noreferrer"
                  target="_blank"
                >
                  Amazon Style Dictionary
                </a>
                .
              </Text>
            )}
          </>
        )}

        <Separator format={format} />
        <LinksTitle>Public Link</LinksTitle>
        <Flex width="100%" justifyContent="space-between" mt={2}>
          <Box mr={4}>
            <Dropdown
              disabled={disabledDropdown}
              placement="bottom"
              disableFlip
              usePortal
              toggle={
                <ToggleButton>
                  <DropdownInnerContainer>
                    <LibraryIcon height={24} />
                    <ToggleButtonText>
                      {getOptionText(publicTokenExport)}
                    </ToggleButtonText>
                    <DropdownChevrons />
                  </DropdownInnerContainer>
                </ToggleButton>
              }
            >
              <DropdownContainer>
                <DropdownItem
                  active={publicTokenExport === 'LATEST'}
                  onClick={() => handlePublicLinkStatus('LATEST')}
                >
                  {getOptionText('LATEST')}
                </DropdownItem>
                <DropdownItem
                  active={publicTokenExport === 'LATEST_STARRED'}
                  onClick={() => handlePublicLinkStatus('LATEST_STARRED')}
                >
                  {getOptionText('LATEST_STARRED')}
                </DropdownItem>
                <DropdownItem
                  active={publicTokenExport === 'DISABLED'}
                  onClick={() => handlePublicLinkStatus('DISABLED')}
                >
                  {getOptionText('DISABLED')}
                </DropdownItem>
              </DropdownContainer>
            </Dropdown>
          </Box>
          <Tooltip visible={tooltipVisible} placement="top" content="Copied">
            <Button
              size="40"
              disabled={
                publicTokenExport === 'DISABLED' ||
                loading ||
                !publicTokenExportUrl
              }
              onClick={handleCopyClick}
            >
              Copy Link
            </Button>
          </Tooltip>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={hideModal}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import { jsxs, jsx } from 'react/jsx-runtime';
import styled, { css } from 'styled-components';
import '../../Button/index.js';
import { ReactComponent } from '@sketch/icons/ellipsis-16';
import { ButtonUnstyled } from '../../Button/ButtonUnstyled.js';

const EllipsisIcon = styled(ReactComponent) `
  width: 16px;
  height: 16px;
`;
const DropdownAction = styled(ButtonUnstyled)(({ theme }) => css `
    height: 32px;
    width: 40px;
    display: inline-flex;
    color: currentColor;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.radii.large};

    :hover,
    :focus,
    :focus-within {
      background-color: ${theme.colors.background.tertiary.C};
    }
  `);
const EllipsisDropdownAction = ({ label, }) => {
    return (jsxs(DropdownAction, { children: [jsx(EllipsisIcon, { "aria-label": label }), jsx("span", Object.assign({ className: "sr-only" }, { children: label }))] }));
};

export { DropdownAction, EllipsisDropdownAction };

import React, { FC } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'

import {
  RouteParams,
  routes,
  DynamicLoadingPage,
  GenericErrorView,
} from '@sketch/modules-common'

import { WorkspaceRedirectRoute } from 'modules/workspace/containers/WorkspaceRedirectRoute'
import { useGetWorkspaces } from 'modules/workspace/operations'

type UserRouteProps = RouteComponentProps<
  Partial<RouteParams<'DOCUMENTS'> & RouteParams<'PERSONAL_PROJECT'>>
>

const replaceRoutePathname = (
  workspaceId: string,
  routeProps: UserRouteProps
): string => {
  const { match } = routeProps

  if (match.params.projectId) {
    return routes.WORKSPACE_PROJECT.create({
      workspaceId,
      projectId: match.params.projectId,
    })
  }

  if (match.url === routes.TRASH.create({})) {
    return routes.WORKSPACE_TRASH.create({ workspaceId })
  }

  if (match.url === routes.LIBRARIES.create({})) {
    return routes.WORKSPACE_LIBRARIES.create({ workspaceId })
  }

  return routes.WORKSPACE_SHARES.create({ workspaceId })
}

// This component is mean to be used only when user was already authenticated
// Otherwise `useFlag` might work incorrectly
export const UserToWorkspaceRedirect: FC<UserRouteProps> = props => {
  const {
    location: { search, state },
  } = props

  const { data, loading, error } = useGetWorkspaces()

  if (loading || !data?.me) {
    return <DynamicLoadingPage />
  }

  if (!data?.me || error) {
    return <GenericErrorView error={error} />
  }

  const { personalWorkspace } = data.me

  if (!personalWorkspace) {
    return <WorkspaceRedirectRoute />
  }

  const newPathname = replaceRoutePathname(personalWorkspace.identifier, props)

  return (
    <Redirect
      to={{
        search,
        state,
        pathname: newPathname,
      }}
      push={false}
    />
  )
}

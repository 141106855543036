import { useRef, useEffect } from 'react';
import { useGetInitialUserQuery, GetInitialUserDocument } from '@sketch/gql-types';
import isEqual from 'lodash.isequal';
import { useUserAuthorizations } from './useUserAuthorizations.js';

const useUserProfile = (cacheOnly = true, hookOptions) => {
    const { activeAuthorization } = useUserAuthorizations();
    const previousActiveAuthorization = useRef(activeAuthorization);
    const apolloBag = useGetInitialUserQuery(Object.assign(Object.assign({}, hookOptions), { fetchPolicy: cacheOnly ? 'cache-only' : 'cache-and-network' }));
    const { client } = apolloBag;
    // console.log('apolloBag', apolloBag)
    // Compare the previous and current activeAuthorization
    // If the user changes from Regular to SSO workspace,
    // they should be different
    const hasActiveAuthorizationChanged = !isEqual(previousActiveAuthorization.current, activeAuthorization);
    // If the "activeAuthorization" changed we refetch the user profile
    // this allows us to keep the name of the user up to date
    // when moving from Regular to SSO workspaces
    useEffect(() => {
        if (hasActiveAuthorizationChanged) {
            client.query({
                query: GetInitialUserDocument,
                fetchPolicy: 'network-only',
            });
        }
    }, [hasActiveAuthorizationChanged, client]);
    // Update previousActiveAuthorization reference with the activeAuthorization
    useEffect(() => {
        if (isEqual(previousActiveAuthorization.current, activeAuthorization)) {
            return;
        }
        previousActiveAuthorization.current = activeAuthorization;
    }, [activeAuthorization]);
    return apolloBag;
};
const useProfileInfo = () => {
    var _a, _b, _c, _d, _e;
    const { data } = useUserProfile();
    return {
        hasPersonalIdentity: (_b = (_a = data === null || data === void 0 ? void 0 : data.me) === null || _a === void 0 ? void 0 : _a.hasPersonalIdentity) !== null && _b !== void 0 ? _b : false,
        userEmail: (_c = data === null || data === void 0 ? void 0 : data.me.email) !== null && _c !== void 0 ? _c : undefined,
        userAvatar: (_e = (_d = data === null || data === void 0 ? void 0 : data.me.avatar) === null || _d === void 0 ? void 0 : _d.small) !== null && _e !== void 0 ? _e : undefined,
    };
};

export { useProfileInfo, useUserProfile };

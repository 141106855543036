import React, { useEffect } from 'react'
import {
  Banner,
  Dropdown,
  FlatButton,
  useResponsiveDropdown,
} from '@sketch/components'
import { useHover } from '@sketch/utils'

import { InformationIcon } from './PermissionMemberInputWarning.styles'

const DROPDOWN_STYLE = {
  width: 230,
}

const InputWarning = () => (
  <>
    <Dropdown.Header>Access</Dropdown.Header>
    <Dropdown.Item.ListItem>
      <Banner type="warning" showIcon={false}>
        User access level is determined by the Member′s Workspace role.
      </Banner>
    </Dropdown.Item.ListItem>
  </>
)

const PermissionMemberInputWarning = () => {
  const [isHover, buttonProps] = useHover()
  const [inputWarningContent, { ref }, { setVisible }] = useResponsiveDropdown({
    dropdown: InputWarning,
    dropdownStyle: DROPDOWN_STYLE,
    placement: 'bottom-end',
  })

  useEffect(() => {
    setVisible(isHover)
  }, [isHover, setVisible])

  return (
    <>
      <FlatButton ref={ref} {...buttonProps}>
        <InformationIcon />
        <span className="sr-only">Members permissions</span>
      </FlatButton>
      {inputWarningContent}
    </>
  )
}

export default PermissionMemberInputWarning

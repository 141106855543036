import { jsxs, jsx } from 'react/jsx-runtime';
import { useState, useContext, useCallback } from 'react';
import { Modal, Checkbox, Button } from '@sketch/components';
import { ToastContext } from '@sketch/toasts';
import { useRevokeActiveSessionsMutation, GetUserSettingsDocument } from '@sketch/gql-types';
import { Title, Description } from './ConfirmRevokeAllSessionsModal.styles.js';
import { useSignOut } from '@sketch/modules-common';

const ConfirmRevokeAllSessionsModal = ({ hideModal, }) => {
    const [revokeCurrentSessionAlso, setRevokeCurrentSessionAlso] = useState(false);
    const signOut = useSignOut({
        revokeSessions: 'none',
        removeDataFromSessions: revokeCurrentSessionAlso ? 'all' : 'none',
        reason: 'Revoking session in Sessions Management',
    });
    const [revokeSessions, { loading }] = useRevokeActiveSessionsMutation({
        redirectErrors: false,
        onCompleted: result => {
            var _a;
            if ((_a = result.revokeActiveSessions) === null || _a === void 0 ? void 0 : _a.successful) {
                showToast('All devices successfully signed out ', 'positive');
                hideModal();
                if (revokeCurrentSessionAlso) {
                    signOut();
                }
            }
        },
        refetchQueries: revokeCurrentSessionAlso
            ? []
            : [{ query: GetUserSettingsDocument }],
        awaitRefetchQueries: !revokeCurrentSessionAlso,
        onError: 'show-toast',
    });
    const { showToast } = useContext(ToastContext);
    const handleRevokeSessions = useCallback(() => {
        revokeSessions({
            variables: {
                exceptCurrentOne: !revokeCurrentSessionAlso,
            },
        });
    }, [revokeCurrentSessionAlso, revokeSessions]);
    return (jsxs(Modal, Object.assign({ onCancel: hideModal }, { children: [jsxs(Modal.Body, { children: [jsx(Title, { children: "Sign Out of All Devices?" }), jsx(Description, { children: "You'll be signed out of all active sessions. If you didn't sign in with one or more devices, you should change your password." }), jsx(Checkbox, Object.assign({ name: "accessScopes", checked: revokeCurrentSessionAlso, onChange: () => setRevokeCurrentSessionAlso(state => !state) }, { children: "Sign out of the current session too" }))] }), jsxs(Modal.Footer, { children: [jsx(Button, Object.assign({ onClick: hideModal, size: "40", variant: "secondary", disabled: loading }, { children: "Cancel" })), jsx(Button, Object.assign({ onClick: handleRevokeSessions, size: "40", variant: "primary", loading: loading }, { children: "Sign Out" }))] })] })));
};

export { ConfirmRevokeAllSessionsModal as default };

import { jsx } from 'react/jsx-runtime';
import { useContext } from 'react';
import styled from 'styled-components';
import '../Context/index.js';
import { ToastContainer } from '../Toast/ToastContainer.js';
import { ToastsDisplay } from './Toasts.display.js';
import { ToastContext } from '../Context/ToastContext.js';

const MAX_VISIBLE_TOASTS = 2;
const ToastsContainer = props => {
    const { className, maxVisibleToasts = MAX_VISIBLE_TOASTS, } = props;
    const { dismissToast, toasts } = useContext(ToastContext);
    // Prevent showing more then the "maxVisibleToasts" at the time
    const initialToasts = toasts.slice(-maxVisibleToasts);
    return (jsx(ToastsDisplay, Object.assign({ className: className }, { children: initialToasts.map(toast => (jsx(ToastContainer, Object.assign({ id: toast.id, variant: toast.variant, autoDismiss: toast.autoDismiss, onDismiss: () => dismissToast(toast.id) }, { children: toast.message }), toast.id))) })));
};
const Toasts = styled(ToastsContainer) `
  position: fixed;
  top: 60px;
  right: 20px;
  width: 100%;

  /* Make the toasts on top of everything */
  z-index: ${({ theme }) => theme.zIndex[8]};
  pointer-events: none;
`;

export { Toasts, ToastsContainer };

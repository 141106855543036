import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import '../../Box/index.js';
import '../../Truncate/index.js';
import '../../Button/index.js';
import { DropdownItemDescription } from '../DropdownItemDescription.js';
import { buttonUnstyledStyles } from '../../Button/ButtonUnstyled.js';
import { truncateStyles } from '../../Truncate/Truncate.js';
import { withBox } from '../../Box/withBox.js';

const dropdownItemStyles = css(({ theme, intent, disabled, minWidth }) => css `
    ${buttonUnstyledStyles};
    ${truncateStyles};
    display: block;
    width: 100%;
    min-width: ${minWidth ? minWidth : '150px'};
    white-space: initial;

    padding: 8px 12px;

    color: ${disabled
    ? theme.colors.foreground.secondary.D
    : intent === 'negative'
        ? theme.colors.ui.links.negative
        : theme.colors.foreground.secondary.B};
    font-size: ${theme.fontSizes.D};
    font-weight: ${theme.fontWeights.regular};
    line-height: 1.4;
    text-align: left;

    border-radius: ${theme.radii.large};
    cursor: pointer;

    transition: background-color ${theme.transitions.duration[1]}
        ${theme.transitions.timing.easeOut},
      color ${theme.transitions.duration[1]}
        ${theme.transitions.timing.easeOutExponential};

    @media (hover: hover) {
      &:hover,
      &:focus-visible,
      &:active,
      &[aria-selected='true']:not([aria-disabled='true']) {
        background: ${theme.colors.background.tertiary.C};
        color: ${intent === 'negative'
    ? theme.colors.ui.links.negative
    : theme.colors.foreground.secondary.A};

        ${DropdownItemDescription} {
          color: ${theme.colors.foreground.secondary.B};
        }
      }
    }

    &[aria-disabled='true'] {
      color: ${theme.colors.foreground.secondary.D};
      pointer-events: none;
    }

    &.active,
    &.is-active, /* used for text editor */
    &[data-current] {
      background-color: ${theme.colors.sketch.F};
      color: ${theme.colors.sketch.A};

      font-weight: ${theme.fontWeights.medium};

      ${DropdownItemDescription} {
        color: ${theme.colors.foreground.secondary.D};
      }
    }
  `);
const ListItem = styled.li `
  list-style: none;
  padding: 0 8px;
`;
const DropdownItemAnchorStyle = styled.a `
  ${dropdownItemStyles};
`;
const DropdownItemNavLinkStyle = styled((_a) => {
    var rest = __rest(_a, []);
    return (jsx(NavLink, Object.assign({}, rest)));
}) `
  ${dropdownItemStyles};
`;
const DropdownItemStyle = styled(withBox('button')) `
  ${dropdownItemStyles};
`;
const DropdownItemWrapper = styled.span `
  ${dropdownItemStyles};
`;
const DropdownShortcutText = styled.span `
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  margin-left: 16px;
  float: right;

  @media (hover: none) and (pointer: coarse) {
    /* Hide this so mobile devices should not see it */
    display: none;
  }
`;

export { DropdownItemAnchorStyle, DropdownItemNavLinkStyle, DropdownItemStyle, DropdownItemWrapper, DropdownShortcutText, ListItem, dropdownItemStyles };

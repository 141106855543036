import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ReactComponent$4 } from '@sketch/icons/persons-two-16';
import { ReactComponent } from '@sketch/icons/person-16';
import { ReactComponent as ReactComponent$1 } from '@sketch/icons/plus-16';
import { ReactComponent as ReactComponent$2 } from '@sketch/icons/briefcase-16';
import { ReactComponent as ReactComponent$3 } from '@sketch/icons/lock-16';
import { Wrapper, IconWrapper } from './WorkspaceLogo.styles.js';

const ICON_TO_COMPONENT = {
    person: ReactComponent,
    plus: ReactComponent$1,
    briefcase: ReactComponent$2,
    lock: ReactComponent$3,
    people: ReactComponent$4,
};
/**
 *  WorkspaceLogoIcon
 *
 * This components has similar representation as the WorkspaceLogo without a src
 * It exists to prevent component duplicates and is currently used in as 2 flavours
 *
 * - A Plus symbol
 * - A Person symbol (Will be deprecated with the Personal Workspace)
 *
 * We should strive to match the layout implemented in https://www.sketch.com/s/5a711544-9ad3-40ed-b98c-a8e656b7ff42
 * and in case we update this we should warn the design team to update it.
 */
const WorkspaceLogoIcon = React.forwardRef(function WorkspaceLogoIcon(props, ref) {
    const { className, size, icon, 'data-testid': dataTestId = 'workspace-logo', onClick, UNSAFE_ADD_MARGIN } = props, rest = __rest(props, ["className", "size", "icon", 'data-testid', "onClick", "UNSAFE_ADD_MARGIN"]);
    const Icon = ICON_TO_COMPONENT[icon];
    return (jsx(Wrapper, Object.assign({ className: className, onClick: onClick, "data-testid": dataTestId, ref: ref, "$size": size, "$addRightMargin": UNSAFE_ADD_MARGIN }, rest, { children: jsx(IconWrapper, Object.assign({ "$size": size }, { children: jsx(Icon, {}) })) })));
});
var WorkspaceLogoIcon$1 = styled(WorkspaceLogoIcon) ``;

export { WorkspaceLogoIcon$1 as default };

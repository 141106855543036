import styled from 'styled-components'
import { BannerPageWrapper, DocumentHeader } from '@sketch/components'

export const BannerWrapper = styled(BannerPageWrapper)`
  /* Force the banner wrapper to be always on top */
  order: -1;
`

export const SearchBarWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;

  > div {
    width: 100%;
  }

  form {
    max-width: unset;
  }

  /* Force the DocumentHeader to be on top of the SearchBar if it's present */
  ~ ${DocumentHeader} {
    order: -1;
  }
`

export const NavbarTitle = styled.span.attrs({
  'data-testid': 'navbar-section-title',
})`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

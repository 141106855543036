import { __rest } from 'tslib';
import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import React, { useRef, useImperativeHandle, useEffect, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';
import { debounce } from 'debounce';
import { breakpoint } from '@sketch/global-styles';
import { useFocusTrap, FOCUSABLE_ELEMENTS } from '@sketch/utils';
import '../DisableBodyScroll/index.js';
import { OnboardingPanelHeader } from './OnboardingPanelHeader.js';
import { OnboardingPanelBody } from './OnboardingPanelBody.js';
import { DisableBodyScroll } from '../DisableBodyScroll/DisableBodyScroll.js';

const Container = styled.section(({ theme }) => css `
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: max-width 0.3s, height 0.3s;
    font-size: ${theme.fontSizes.C};

    max-height: 100%;
    transform: translate3d(0, 0, 0);

    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: none;

    border-radius: ${theme.radii.xxxlarge} ${theme.radii.xxxlarge} 0 0;
    background-color: ${theme.colors.background.secondary.A};
    background-clip: padding-box;

    box-shadow: 0 8px 16px 0 ${theme.colors.shadow.outer},
      0px 0px 0px 1px ${theme.colors.border.A};

    z-index: ${theme.zIndex[3]};

    ${breakpoint('xs') `
      bottom: 56px;
      left: 8px;
      max-width: 350px;
      border-radius: ${theme.radii.xxxlarge};
      margin: auto 0;
    `}

    .onboarding-panel-enter & {
      opacity: 0;
      transform: translateY(10px);
    }

    .onboarding-panel-enter-active & {
      opacity: 1;
      transform: translateY(0);
      transition: ${`
        opacity  ${theme.transitions.duration[1]},
        transform  ${theme.transitions.duration[2]} ${theme.transitions.timing.easeInOut}
    `};
    }

    .onboarding-panel-exit & {
      opacity: 1;
      transform: translateY(0);
    }

    .onboarding-panel-exit-active & {
      opacity: 0;
      transform: translateY(10px);
      transition: ${`
      opacity  ${theme.transitions.duration[2]},
      transform  ${theme.transitions.duration[1]} ${theme.transitions.timing.easeInOut}
    `};
    }

    & p {
      margin: 0;
      padding-bottom: 20px;
    }

    & p:last-child {
      padding-bottom: 0;
    }

    /* Ensure modals without a header have some spacing above the body */
    & > ${OnboardingPanelBody} {
      &:first-child {
        margin-top: 20px;
      }
    }

    /* stylelint-enable */
    user-select: text;
  `);
const MediaContainer = styled.div `
  width: 100%;
  margin-top: -4px;
`;
const InspectGif = styled.img `
  width: 100%;
`;
/**
 * This component is heavily based on the Modal component, but it's the behavior
 * is slightly different, as it appear and stays on the screen until the user
 * closes it, not preventing any interaction on the page from the user's side.
 */
const OnboardingPanel = React.forwardRef(function IntroductionPanel(_a, ref) {
    var { title, style, children, onUpdateScroll, cancelOnClickOutside = true, imageSrc, alt } = _a, otherProps = __rest(_a, ["title", "style", "children", "onUpdateScroll", "cancelOnClickOutside", "imageSrc", "alt"]);
    const containerRef = useRef(null);
    useImperativeHandle(ref, () => containerRef.current);
    useFocusTrap(containerRef);
    // Automatically focus on the first focusable element
    useEffect(() => {
        var _a;
        const firstItem = (_a = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(FOCUSABLE_ELEMENTS);
        if (firstItem) {
            firstItem.focus();
        }
    }, []);
    useLayoutEffect(() => {
        // used to notify the parent component if the modal has overflow
        const updateBodyHeight = () => {
            var _a, _b, _c, _d;
            const clientHeight = (_b = (_a = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) !== null && _b !== void 0 ? _b : 0;
            const scrollHeight = (_d = (_c = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _c === void 0 ? void 0 : _c.scrollHeight) !== null && _d !== void 0 ? _d : 0;
            onUpdateScroll && onUpdateScroll(clientHeight < scrollHeight);
        };
        updateBodyHeight();
        window.addEventListener('resize', () => debounce(updateBodyHeight, 250));
        return () => window.removeEventListener('resize', updateBodyHeight);
    }, [containerRef, onUpdateScroll]);
    return (jsxs(Fragment, { children: [jsx(DisableBodyScroll, {}), jsxs(Container, Object.assign({ role: "dialog", style: style }, otherProps, { ref: containerRef, "data-testid": "onboarding-panel" }, { children: [imageSrc && (jsx(MediaContainer, { children: jsx(InspectGif, { src: imageSrc, alt: alt }) })), title && jsx(OnboardingPanelHeader, { children: title }), children] }))] }));
});

export { OnboardingPanel };

import React, { useContext } from 'react';

const WebsocketContext = React.createContext(undefined);
function useWebsocket() {
    const contextValue = useContext(WebsocketContext);
    if (!contextValue) {
        throw new Error('useWebsocket must be used within a WebsocketProvider');
    }
    return contextValue;
}

export { WebsocketContext, useWebsocket };

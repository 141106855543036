import React, { FC } from 'react'

import {
  Button,
  Avatar,
  Link,
  Flex,
  Pill,
  Section,
  Tooltip,
  useModalContext,
} from '@sketch/components'

// Modal
import { InvitePartnerModal } from 'modules/workspace/modals/InvitePartnerModal'
import RemovePartnerModal from 'modules/workspace/modals/RemovePartnerModal'

import {
  Paragraph,
  PartnerInfoContainer,
  UserInfoContainer,
  SketchPartnerLabel,
  ExpiredWorkspaceBanner,
  Chevron,
} from './PartnersPanel.styles'

// GQL
import { WorkspaceMembershipFragment } from '@sketch/gql-types'

interface PartnersPanelProps {
  workspaceIdentifier: string
  isSubscriptionActive: boolean
  partner: WorkspaceMembershipFragment
}

const TooltipContent = ({ email }: { email?: string }) => {
  return (
    <>
      <Tooltip.Title>Email Address</Tooltip.Title>
      <Tooltip.Body>{email || '-'}</Tooltip.Body>
    </>
  )
}
export const PartnersPanel: FC<PartnersPanelProps> = ({
  workspaceIdentifier,
  isSubscriptionActive,
  partner,
}) => {
  const { showModal } = useModalContext()

  const text = (
    <div>
      <Paragraph>
        A Sketch Partner manages billing and Editor Seats on your behalf, but
        they cannot view or edit Sketch documents in your Workspace.
      </Paragraph>

      {partner && (
        <PartnerInfoContainer>
          <UserInfoContainer>
            <Avatar
              className="avatar"
              name={partner.user?.name!}
              src={partner.user?.avatar?.small || undefined}
            />
            <Flex>
              <span>{partner.user?.name}</span>
              <Tooltip
                placement="bottom-start"
                content={<TooltipContent email={partner.user?.email} />}
              >
                <Chevron />
              </Tooltip>
            </Flex>
          </UserInfoContainer>
          <div>
            <SketchPartnerLabel>Sketch Partner</SketchPartnerLabel>
            {!partner?.acceptedAt && <Pill variant="secondary">Pending</Pill>}
          </div>
          <Button
            onClick={() =>
              showModal(RemovePartnerModal, {
                partnerName: partner.user?.name!,
              })
            }
            size="32"
            variant="tertiary"
          >
            Remove…
          </Button>
        </PartnerInfoContainer>
      )}
    </div>
  )

  let action = null
  if (!partner) {
    action = (
      <Button
        disabled={!isSubscriptionActive}
        onClick={() => showModal(InvitePartnerModal, { workspaceIdentifier })}
        size="40"
        variant="secondary"
      >
        Invite Partner&hellip;
      </Button>
    )
  }

  return (
    <>
      <Section
        title="Sketch Partner"
        text={text}
        action={action}
        data-testid="partners-panel"
      />
      {!partner && !isSubscriptionActive && (
        <ExpiredWorkspaceBanner type="warning">
          To invite a Sketch Partner to an expired Workspace, please email{' '}
          <Link external href="mailto:partners@sketch.com" isUnderlined>
            partners@sketch.com
          </Link>
        </ExpiredWorkspaceBanner>
      )}
    </>
  )
}

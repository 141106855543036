import React, { CSSProperties } from 'react'
import { Color as ColorType } from 'modules/shares/types'
import rgbTo, { ColorFormat } from '../utils'
import Opacity from 'modules/shares/Inspector/Sidebar/components/Attribute/components/Opacity'
import { Container, ColorIcon, Text } from './ColorDot.styles'

export interface ColorProps extends ColorType {
  style?: CSSProperties
  displayOpacity?: boolean
  format?: ColorFormat
  $iconSize?: number
}

const Color: React.FC<ColorProps> = ({
  red,
  green,
  blue,
  alpha = 1,
  displayOpacity = true,
  format = ColorFormat.HEX,
}) => {
  const colorString = rgbTo(format, { red, green, blue, alpha })

  return (
    <Container>
      <ColorIcon red={red} green={green} blue={blue} alpha={alpha} />
      <Text className="ColorText">{colorString}</Text>
      {displayOpacity && format === ColorFormat.HEX && (
        <Opacity value={alpha} />
      )}
    </Container>
  )
}

export default Color

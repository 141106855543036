import {
  SubscribeToPlanState,
  SubscribeToPlanInitialState,
  SubscribeToPlanWithBilling,
} from 'modules/workspace/types'

export const isPlanSelectorStep = (
  step: string,
  state: SubscribeToPlanState
): state is SubscribeToPlanInitialState => step === 'PLAN_SELECT'

export const isBillingFormsStep = (
  step: string,
  state: SubscribeToPlanState
): state is SubscribeToPlanWithBilling => step === 'PAYMENT_DETAILS'

import styled, { css } from 'styled-components';
import { ReactComponent } from '@sketch/icons/chevrons-up-down-16';
import { ReactComponent as ReactComponent$2 } from '@sketch/icons/chevron-horizontal-12';
import { ReactComponent as ReactComponent$3 } from '@sketch/icons/chevron-vertical-12';
import { ReactComponent as ReactComponent$1 } from '@sketch/icons/chevrons-up-down-16-negative';

const StyledDefaultSelectorButton = styled.button `
  ${({ fullWidth, chevrons, backArrow, chevronVertical, theme: { colors, fontSizes, fontWeights, fonts, radii }, }) => css `
    position: relative;
    color: ${colors.foreground.secondary.C};
    font-size: ${fontSizes.D};
    font-weight: ${fontWeights.regular};
    font-family: ${fonts.buttons};
    line-height: 1.462; /* stylelint-disable-line scales/line-height */
    padding: 7px 12px 6px;
    user-select: none;
    cursor: pointer;
    appearance: none;
    border: none;
    border-radius: ${radii.large};
    background-color: transparent;

    &:hover {
      color: ${colors.foreground.secondary.A};
      background-color: ${colors.background.tertiary.C};
    }

    &:active {
      background-color: ${colors.background.tertiary.B};
    }

    &[disabled] {
      cursor: not-allowed;
      pointer-events: none;
      color: ${colors.foreground.secondary.D};
    }

    ${fullWidth &&
    css `
      display: block;
      width: 100%;
    `}

    ${(chevrons || backArrow || chevronVertical) &&
    css `
      padding-right: 28px;
    `}

    ${backArrow &&
    css `
      padding-left: 28px;
    `}
  `}
`;
const chevronsStyles = css `
  margin: auto;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 0.55)};
  width: 16px;
  height: 100%;
  position: absolute;
  top: 0;

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${StyledDefaultSelectorButton}:hover & {
    opacity: 1;
  }
`;
const SelectorButtonChevronsUpDown = styled(ReactComponent) `
  ${chevronsStyles};
  right: 8px;
`;
const SelectorButtonChevronsUpDownIconNegative = styled(ReactComponent$1) `
  ${chevronsStyles};
  right: 8px;
`;
const SelectorButtonChevronHorizontal = styled(ReactComponent$2) `
  ${chevronsStyles};
  left: 8px;
`;
const SelectorButtonChevronVertical = styled(ReactComponent$3) `
  ${chevronsStyles};
  right: 8px;
`;
const StyledHeaderSelectorButton = styled(StyledDefaultSelectorButton) `
  font-size: ${({ theme }) => theme.fontSizes.B};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  text-transform: uppercase;
`;
const StyledStrongSelectorButton = styled(StyledDefaultSelectorButton) `
  border: 1px solid ${({ theme }) => theme.colors.border.A};
`;

export { SelectorButtonChevronHorizontal, SelectorButtonChevronVertical, SelectorButtonChevronsUpDown, SelectorButtonChevronsUpDownIconNegative, StyledDefaultSelectorButton, StyledHeaderSelectorButton, StyledStrongSelectorButton };

import { DataProxy } from 'apollo-cache'

import {
  GetWebhookDestinationQuery,
  GetWebhookDestinationQueryVariables,
  GetWebhookDestinationDocument,
} from '@sketch/gql-types'

import { castError } from '@sketch/utils'
import { ErrorHandler } from '@sketch/tracing'

type WorkspaceWebhookConfig = GetWebhookDestinationQuery['workspace']

const get = (
  cache: DataProxy,
  workspaceIdentifier: string
): WorkspaceWebhookConfig | undefined => {
  try {
    return cache.readQuery<
      GetWebhookDestinationQuery,
      GetWebhookDestinationQueryVariables
    >({
      query: GetWebhookDestinationDocument,
      variables: { workspaceIdentifier },
    })?.workspace
  } catch (e) {
    const error = castError(e)
    // There is no way to recover here and callers are already expecting
    // `undefined` as a possible return type, so we ignore the error.
    ErrorHandler.ignore(error)
  }
}

const set = (
  cache: DataProxy,
  workspaceIdentifier: string,
  webhookConfig: WorkspaceWebhookConfig
): void => {
  cache.writeQuery<
    GetWebhookDestinationQuery,
    GetWebhookDestinationQueryVariables
  >({
    query: GetWebhookDestinationDocument,
    variables: { workspaceIdentifier },
    data: { __typename: 'RootQueryType', workspace: webhookConfig },
  })
}

export { get, set }

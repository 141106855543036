const MARKDOWN_CHANGE_PER_KEY = {
    b: '**',
    i: '_',
};
/**
 * SplitStringBySelection
 *
 * This function uses the selection parameters
 * and splits the text passed by the user into 3 strings:
 *
 * - Text Before selection
 * - Selected Text
 * - Text After selection
 *
 * @param {string} text - User text.
 * @param {number} selectionStart - Beginning of the user selection if any.
 * @param {number} selectionEnd - End of the user selection if any.
 *
 * @returns {Object} - Object with the text before selection, the selected text
 * and the text after selection.
 */
const splitStringBySelection = (text, selectionStart, selectionEnd) => {
    const textBeforeSelection = text.substring(0, selectionStart);
    const textAfterSelection = text.substring(selectionEnd);
    const selectedText = text.substring(selectionStart, selectionEnd);
    return {
        textBeforeSelection,
        textAfterSelection,
        selectedText,
    };
};
/**
 * ToggleTextWithChange
 *
 * This function adds or removes the {change} modifications from the string.
 *
 * @typedef {Object} ToggleTextResponse
 * @property {text} text - Updated text with the chabge.
 * @property {number} selectionStart - New selection start mark.
 * @property {number} selectionEnd - New selection end mark.
 *
 * @param {string} text - Text that will receive the modifications.
 * @param {string} change - Markdown modification bold(**) or italic (_).
 * @param {number} selectionStart - Beginning of the user selection if any.
 * @param {number} selectionEnd - End of the user selection if any.
 *
 * @returns {ToggleTextResponse} - An Object containing the new string with the markdown modifications
 * and the new selection cursor positions.
 */
const toggleTextWithChange = (text, change, selectionStart, selectionEnd) => {
    const { textBeforeSelection, selectedText, textAfterSelection, } = splitStringBySelection(text, selectionStart, selectionEnd);
    const textHasChange = textBeforeSelection.endsWith(change) &&
        textAfterSelection.startsWith(change);
    if (textHasChange) {
        const textAfterChange = textAfterSelection.substring(change.length);
        const textBeforeChange = textBeforeSelection.substring(0, textBeforeSelection.length - change.length);
        return {
            text: `${textBeforeChange}${selectedText}${textAfterChange}`,
            selectionStart: selectionStart - change.length,
            selectionEnd: selectionEnd - change.length,
        };
    }
    return {
        text: `${textBeforeSelection}${change}${selectedText}${change}${textAfterSelection}`,
        selectionStart: selectionStart + change.length,
        selectionEnd: selectionEnd + change.length,
    };
};
/**
 * GetChangeByEventKey
 *
 * This function returns the change based on the {eventkey}.
 *
 * @param {string} eventKey - Name of the event key.
 */
const getChangeByEventKey = eventKey => {
    return MARKDOWN_CHANGE_PER_KEY[eventKey];
};

export { getChangeByEventKey, toggleTextWithChange };

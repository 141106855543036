import React from 'react'

import UserDropdown from './UserDropdown'

import {
  useGetNewNotificationsCount,
  useUserProfile,
} from '@sketch/modules-common'

const UserNavbarSection: React.FC = () => {
  const { data } = useUserProfile(true)
  const newNotificationsCount = useGetNewNotificationsCount({
    fetchPolicy: 'cache-only',
  })

  return (
    <UserDropdown user={data?.me} unreadNotifications={newNotificationsCount} />
  )
}

export default UserNavbarSection

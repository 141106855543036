const COLOR_MATCH_REGEX = /^(#[a-fA-F0-9]{6}|#[a-fA-F0-9]{3})(?:$|[^a-zA-Z0-9#])/;
const LOCATE_COLOR_MATCH_REGEX = /(?:^|[^a-zA-Z0-9#])(#[a-fA-F0-9]{6}|#[a-fA-F0-9]{3})(?:$|\W)/;
function locateColor(value, fromIndex) {
    const regexMatch = value.match(LOCATE_COLOR_MATCH_REGEX);
    if (regexMatch) {
        return value.indexOf(regexMatch[1], fromIndex);
    }
}
/**
 * TokenizeColor.
 *
 * This function is responsible for finding the color block and converting to Color component.
 *
 * @param {Function} eat - Replace the match by an object.
 * @param {string} value - color value caught.
 * @param {boolean} silent - Whether to detect or consume.
 *
 * @returns {Object} - Returns a element of type color with the color value.
 */
function tokenizeColor(eat, value, silent) {
    const match = COLOR_MATCH_REGEX.exec(value);
    if (match) {
        if (silent) {
            return true;
        }
        return eat(match[1])({
            type: 'color',
            value: match[1],
        });
    }
}
tokenizeColor.notInLink = true;
tokenizeColor.locator = locateColor;
/**
 * Color plugin for the `remark-js`.
 */
function color() {
    const Parser = this.Parser;
    const tokenizers = Parser.prototype.inlineTokenizers;
    const methods = Parser.prototype.inlineMethods;
    tokenizers.color = tokenizeColor;
    /* Add color tokenizer before the text tokenizer */
    methods.splice(methods.indexOf('text'), 0, 'color');
}

export { color };

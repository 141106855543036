import { __rest } from 'tslib';
import React, { createElement } from 'react';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import styled from 'styled-components';
import { TableHead, TableHeaderStyle, TableStyle, TableHeaderCell, StatusHeader, StyledSortIcon, TableBody, TableRow, TableCell, StickyStyledTableCell, StickyStyledTableHeader } from './Table.styles.js';

const getClickHeaderProps = ({ sort, onHeaderClick, sortKey, }) => {
    if (!sort || !onHeaderClick || !sortKey)
        return undefined;
    return { onClick: () => onHeaderClick(sortKey) };
};
const TableHeader = styled(function TableHeader(props) {
    const { className, children } = props;
    return (jsx(TableHead, Object.assign({ className: className }, { children: jsx(TableHeaderStyle, { children: children }) })));
}) ``;
const TableComponent = styled(function TableComponent(props) {
    const { className, children, evenColumns, hideHeader, cellHeight, 'data-testid': dataTestId, } = props;
    return (jsx(TableStyle, Object.assign({ className: className, "data-testid": dataTestId, "$evenColumns": evenColumns, "$hideHeader": hideHeader, cellHeight: cellHeight }, { children: children })));
}) ``;
function TableSortableHeader(props) {
    const { className, header, onHeaderClick, sortDirection } = props;
    return (jsx(TableHead, Object.assign({ className: className }, { children: jsx(TableHeaderStyle, { children: header.map((item, index) => {
                const { label, sortKey, customCell: TableCell = TableHeaderCell, sort, iconVisible, } = item;
                return (createElement(TableCell, Object.assign({}, getClickHeaderProps({
                    sort,
                    onHeaderClick,
                    sortKey,
                }), { key: index, "$cursor": sort }), iconVisible ? (jsxs(StatusHeader, { children: [jsx(Fragment, { children: label }), jsx(StyledSortIcon, { transform: sortDirection === 'ASC' ? 'rotate(180) ' : undefined })] })) : (jsx(Fragment, { children: label }))));
            }) }) })));
}
function Table(props) {
    const { header, items, renderItem, renderItemKey, onHeaderClick, sortDirection, sortable = false, isLoading, loadingComponent } = props, tableProps = __rest(props, ["header", "items", "renderItem", "renderItemKey", "onHeaderClick", "sortDirection", "sortable", "isLoading", "loadingComponent"]);
    return (jsxs(TableComponent, Object.assign({}, tableProps, { children: [sortable ? (jsx(TableSortableHeader, { header: header, sortDirection: sortDirection, onHeaderClick: onHeaderClick })) : (jsx(TableHeader, { children: header.map(({ label, customCell: TableCell = TableHeaderCell }, index) => (jsx(TableCell, { children: label }, index))) })), jsx(TableBody, { children: isLoading
                    ? loadingComponent
                    : items.map((item, index) => (jsx(React.Fragment, { children: renderItem(item) }, (renderItemKey === null || renderItemKey === void 0 ? void 0 : renderItemKey(item)) || index))) })] })));
}
const TableComponents = {
    Table: TableComponent,
    TableHeader,
    TableHeaderCell,
    TableBody,
    TableRow,
    TableCell,
    TableCellSticky: StickyStyledTableCell,
    TableHeaderCellSticky: StickyStyledTableHeader,
};

export { Table, TableComponents };

/**
 * Border radius design tokens.
 *
 * For use with the following CSS properties:
 *
 *     border-radius
 *
 * @see https://system-ui.com/theme
 */
const radii = {
    base: '4px',
    small: '3px',
    medium: '5px',
    large: '6px',
    xlarge: '8px',
    xxlarge: '12px',
    xxxlarge: '16px',
    rounded: '999px',
};
/**
 * Border width design tokens.
 *
 * For use with the following CSS properties:
 *
 *     border-width
 *
 * @see https://system-ui.com/theme
 */
const borderWidth = [0, 1, 2, 3, 4];

export { borderWidth, radii };

import { breakpoint } from '@sketch/global-styles';
import styled, { css } from 'styled-components';
import { GenericSectionTitle, GenericSectionTitleSkeleton } from '@sketch/components';
import '../Insight/index.js';
import { Insight } from '../Insight/Insight.js';
import { Skeleton } from '../Insight/Insight.styles.js';

const itemStyles = css `
  width: 254px;
  margin-bottom: 24px;

  ${breakpoint('base', 'sm') `
    flex-shrink: 0;

    & + & {
      margin-left: 24px;
    }
  `}

  ${breakpoint('sm', 'md') `
    /* From the total width remove the margin between the items (40px) */
    width: calc((100% - 40px) / 2);
    margin-left: 40px;
    
    &:nth-child(odd) {
      margin-left: 0;
    }
  `}

  ${breakpoint('md') `
    /* From the total width remove the margin between the items (3 x 40px) */
    width: calc((100% - 120px) / 4);
    margin-left: 40px;
    
    &:nth-child(4n - 3) {
      margin-left: 0;
    }
  `}
`;
const Title = styled(GenericSectionTitle) `
  padding: 0 0 16px;
`;
const ItemWrapper = styled.div `
  display: flex;
  overflow-y: auto;
  max-width: 1400px;

  ${breakpoint('sm') `
    flex-wrap: wrap;
  `}
`;
const Item = styled(Insight) `
  ${itemStyles}
`;
const SkeletonTitle = styled(GenericSectionTitleSkeleton) `
  padding: 0 0 16px;
`;
const SkeletonItem = styled(Skeleton) `
  ${itemStyles}

  & + & {
    margin-left: 24px;
  }

  :last-child {
    margin-right: auto;
  }

  ${breakpoint('sm') `
      /* From the total width remove the margin between the items (40px) */
      & + & {
        margin-left: 40px;
      }
  `}
`;

export { Item, ItemWrapper, SkeletonItem, SkeletonTitle, Title };

import { jsx } from 'react/jsx-runtime';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import '../Button/index.js';
import { ButtonUnstyled } from '../Button/ButtonUnstyled.js';

const tabStyle = css(({ theme, selected }) => css `
    display: inline-block;

    padding: 8px 12px;

    color: ${selected
    ? theme.colors.sketch.A
    : theme.colors.foreground.secondary.C};
    background: ${selected ? theme.colors.sketch.F : undefined};

    font-family: ${theme.fonts.buttons};
    font-size: ${theme.fontSizes.D};

    text-align: center;
    user-select: none;
    white-space: nowrap;
    border-radius: ${theme.radii.large};

    &:hover {
      color: ${selected ? undefined : theme.colors.foreground.secondary.A};
    }
  `);
const TabButton = styled(ButtonUnstyled) `
  ${tabStyle};
`;
const TabDiv = styled.span `
  cursor: pointer;

  ${tabStyle};
`;
const TabLink = styled.a `
  ${tabStyle};
`;
const Tab = ({ children, selected = false, urlFragment, onTabSelected, className, isDisabled, externalUrl, 'data-testid': dataTestId, }) => (jsx("li", Object.assign({ className: className }, { children: urlFragment ? (jsx(NavLink, Object.assign({ to: `#${urlFragment}` }, { children: jsx(TabDiv, Object.assign({ selected: selected, onClick: onTabSelected }, { children: children })) }))) : externalUrl ? (jsx(TabLink, Object.assign({ href: externalUrl, target: "_blank" }, { children: children }))) : (jsx(TabButton, Object.assign({ "data-testid": dataTestId, selected: selected, onClick: onTabSelected, disabled: isDisabled }, { children: children }))) })));

export { Tab, TabButton, TabDiv, TabLink, tabStyle };

import React, { useState } from 'react'

import {
  Button,
  Flex,
  LoadingPlaceholder,
  Modal,
  ModalInjectedProps,
  Tooltip,
} from '@sketch/components'
import { ChangeBillingPlanPicker } from './ChangeBillingPlanPicker'
import { MonthlyToYearlyContent } from './MonthlyToYearlyContent'
import { YearlyToMonthlyContent } from './YearlyToMonthlyContent'

import { useToast } from '@sketch/toasts'

import {
  BillingPlanFragment,
  useGetAvailablePlansQuery,
  useChangePlanMutation,
  PaymentDetailsFragment,
  BillingSeatsInfoFragment,
} from '@sketch/gql-types'

import { QuestionMarkIcon, TooltipText } from './ChangeBillingPlanModal.styles'

const TOOLTIP_TEXT = (
  <>
    <TooltipText>
      <b>How Pricing Works</b>
    </TooltipText>
    <TooltipText>
      <b>Viewers</b> can browse, inspect and comment on documents in the web
      app. Adding Viewers is free!
    </TooltipText>
    <TooltipText>
      <b>Editors</b> can do all of the above, and create and edit documents in
      the Mac app. You can add more Editors to the Workspace later.
    </TooltipText>
  </>
)

interface ChangeBillingPlanModalProps extends ModalInjectedProps {
  currentPlan?: BillingPlanFragment
  customerId: string
  seats: BillingSeatsInfoFragment
  paymentDetails?: PaymentDetailsFragment
  onWorkspaceSettingRefresh: () => Promise<void>
}

export const ChangeBillingPlanModal: React.FC<ChangeBillingPlanModalProps> = ({
  hideModal,
  currentPlan,
  customerId,
  seats,
  paymentDetails,
  onWorkspaceSettingRefresh,
}) => {
  const { showToast } = useToast()
  const [selectedPlan, setSelectedPlan] = useState<
    BillingPlanFragment | undefined
  >(currentPlan)
  const [appliedDiscount, setAppliedDiscount] = useState<string | null>(null)

  // Queries and Mutations
  const { data, loading: loadingAvailablePlans } = useGetAvailablePlansQuery({
    variables: { customerId },
  })
  const [changePlan, { loading: loadingMutation }] = useChangePlanMutation({
    onError: 'show-toast',
    onCompleted: () => {
      onWorkspaceSettingRefresh()
      showToast(
        selectedPlan?.type === 'YEARLY'
          ? 'Your plan was updated'
          : 'Your plan will be updated in the next billing date'
      )
      hideModal()
    },
    variables: {
      input: {
        customerId,
        promotionCode: appliedDiscount,
      },
    },
    awaitRefetchQueries: true,
  })

  const plans = data?.availablePlans
    .filter(plan => plan.product === 'TEAMS')
    .sort(a => (a.type === 'MONTHLY' ? -1 : 0))

  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>Change Plan</Modal.Header>
      <Modal.Body>
        <Flex alignItems="center" pb={24}>
          You only pay for users who edit Sketch documents.{' '}
          <Tooltip tooltipContainerAs="span" content={TOOLTIP_TEXT}>
            <QuestionMarkIcon />
          </Tooltip>
        </Flex>
        {loadingAvailablePlans && (
          <Flex
            height={224}
            w="100%"
            justifyContent="center"
            alignItems="center"
          >
            <LoadingPlaceholder size="64px" />
          </Flex>
        )}
        {data && (
          <>
            <ChangeBillingPlanPicker
              onChange={setSelectedPlan}
              plans={plans || []}
              selectedPlan={selectedPlan}
              currentPlan={currentPlan}
            />
            {currentPlan?.type === 'YEARLY' &&
              selectedPlan?.type === 'MONTHLY' && <YearlyToMonthlyContent />}
          </>
        )}
      </Modal.Body>
      {currentPlan?.type === 'MONTHLY' && selectedPlan?.type === 'YEARLY' && (
        <MonthlyToYearlyContent
          selectedPlan={selectedPlan}
          customerId={customerId}
          seats={seats}
          paymentDetails={paymentDetails}
          discountCode={appliedDiscount}
          onValidDiscount={setAppliedDiscount}
          onClearDiscountCode={() => setAppliedDiscount(null)}
        />
      )}
      <Modal.Footer>
        <Button
          onClick={hideModal}
          variant="secondary"
          disabled={loadingMutation}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={
            loadingAvailablePlans || currentPlan?.id === selectedPlan?.id
          }
          onClick={() => changePlan()}
          loading={loadingMutation}
        >
          Change Plan
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

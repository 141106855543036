import { jsx, jsxs } from 'react/jsx-runtime';
import React from 'react';
import styled from 'styled-components';
import '../Tooltip/index.js';
import { StyledLink, Title, ArrowCircle, TitleContainer, SubTitle } from './GenericSectionTitle.styles.js';
import { Tooltip } from '../Tooltip/Tooltip.js';

const GenericSectionTitle = React.forwardRef(function GenericSectionTitle(props, ref) {
    const { className, title, titleLink, onTitleLinkClick, subTitle, categories, } = props;
    const renderTitle = () => {
        const hasSubtitleSibling = !!subTitle;
        if (titleLink && titleLink.text) {
            return (jsx(StyledLink, Object.assign({ target: "_blank", rel: "noreferrer", href: titleLink.url, onClick: onTitleLinkClick, external: true }, { children: jsx(Tooltip, Object.assign({ content: titleLink.text, placement: "right", spacing: "10px" }, { children: jsxs(Title, Object.assign({ "$withLink": true, "$hasSubtitleSibling": hasSubtitleSibling }, { children: [title, jsx(ArrowCircle, { width: "24", height: "24" })] })) })) })));
        }
        else if (titleLink) {
            return (jsx(StyledLink, Object.assign({ target: "_blank", rel: "noreferrer", href: titleLink.url, onClick: onTitleLinkClick, external: true }, { children: jsxs(Title, Object.assign({ "$withLink": true, "$hasSubtitleSibling": hasSubtitleSibling }, { children: [title, jsx(ArrowCircle, { width: "24", height: "24" })] })) })));
        }
        return jsx(Title, { children: title });
    };
    return (jsxs(TitleContainer, Object.assign({ className: className, ref: ref }, { children: [jsxs("div", { children: [subTitle && jsx(SubTitle, { children: subTitle }), renderTitle()] }), categories] })));
});
var GenericSectionTitle$1 = styled(GenericSectionTitle) ``;

export { GenericSectionTitle$1 as default };

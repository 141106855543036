import { useEffect, useMemo } from 'react'
import { useRouteMatch } from 'react-router'

import { keyWithoutModifier, shortcuts } from '@sketch/utils'
import { useVersioning } from 'modules/versioning'
import {
  useToggleSidebarTab,
  getActivePanels,
} from 'modules/shares/components/ShareSidebarTabContext'

/**
 * Sets these global shortcuts:
 * - `d` to toggle the document info panel
 * - `i` to toggle the inspector panel
 * - `c` to toggle the comments panel
 * - `v` to toggle the versions panel
 */
export const usePanelShortcuts = () => {
  const { path } = useRouteMatch()
  const { toggle } = useToggleSidebarTab()
  const { share } = useVersioning()

  const activePanels = useMemo(
    () =>
      getActivePanels({
        path,
        canInspect: share.userCanInspect,
        canComment: share.commentsEnabled,
      }),
    [path, share.userCanInspect, share.commentsEnabled]
  )

  useEffect(() => {
    const handlePanelKeyEvents = (event: KeyboardEvent) => {
      const aboutShortcut = keyWithoutModifier(shortcuts.about)(event)
      const inspectShortcut = keyWithoutModifier(shortcuts.inspect)(event)
      const commentShortcut = keyWithoutModifier(shortcuts.comment)(event)
      const versionShortcut = keyWithoutModifier(shortcuts.version)(event)

      if (activePanels.includes('About') && aboutShortcut) {
        toggle('About')
      } else if (activePanels.includes('Inspect') && inspectShortcut) {
        toggle('Inspect')
      } else if (activePanels.includes('Comment') && commentShortcut) {
        toggle('Comment')
      } else if (activePanels.includes('Version') && versionShortcut) {
        toggle('Version')
      }
    }

    window.addEventListener('keydown', handlePanelKeyEvents)

    return () => {
      window.removeEventListener('keydown', handlePanelKeyEvents)
    }
  }, [activePanels, toggle])
}

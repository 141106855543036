import styled from 'styled-components'
import { Text } from '@sketch/components'

export const NonMemberError = styled(Text).attrs({
  textStyle: 'copy.negative.standard.C',
})`
  padding-top: 8px;
  /* important and negative margin here to negate the space the message occupies */
  /* stylelint-disable-next-line */
  margin-bottom: -23px !important;
`

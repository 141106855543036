import { jsx, jsxs } from 'react/jsx-runtime';
import React from 'react';
import { Portal } from 'react-portal';
import { Modal } from '../Modal/index.js';
import '../ModalManager/index.js';
import { ModalBody, ModalFooter, CancelButton } from './ResponsiveDropdown.styles.js';
import { ModalTransition } from '../ModalManager/ModalTransition.js';

/**
 * This component has the visual representation of a modal
 * when rendered using the "useResponsiveDropdown" hook
 *
 * It has been implemented using as foundation the SelectDropdown code
 * https://github.com/sketch-hq/cloud-frontend/blob/4c7a4c26b63e085ea1aabdbd9d79c7b00342388b/src/modules/projects/components/SelectDropdown/SelectList/SelectList.tsx#L78-L95
 *
 * This component is in charge of:
 * - Adding a cancel button to hide the modal
 * - Hiding the modal when clicked outside
 */
const ModalContainer = React.forwardRef(function ModalContainer({ children, visible, onHide, style, useModalRoot, hideCancel }, ref) {
    return (jsx(ModalTransition, Object.assign({ show: visible }, { children: jsx(Portal, Object.assign({ node: useModalRoot ? document.getElementById('modal-root') : undefined }, { children: jsxs(Modal, Object.assign({ "data-testid": "popover-content", ref: ref, onCancel: onHide, style: style }, { children: [jsx(ModalBody, { children: children }), !hideCancel && (jsx(ModalFooter, { children: jsx(CancelButton, Object.assign({ variant: "tertiary", size: "40", fill: true, onClick: onHide }, { children: "Cancel" })) }))] })) })) })));
});

export { ModalContainer };

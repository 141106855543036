import React from 'react'
import Header, { Title, SubTitle } from '../Header'
import { AttributeList } from '../Attribute'
import TextAttribute from './TextAttribute'
import { TextAlignValue } from './TextAlignValue'
import { TextDecoration } from './TextDecoration'
import { TextTransform } from './TextTransform'
import Section from '../Section'
import Separator from '../Separator'
import Color from '../Color'
import { capitalize, roundUpTo } from '@sketch/utils'
import {
  ColorFormat,
  Style,
  TextAlignment,
  TextAttributes,
  TextAttributedString,
  TextProps,
  TextStyle,
  Color as ColorType,
} from 'modules/shares/types'
import { renderLineHeight } from './utils'
import { copySection, copyAlignment } from './copy'
import { Truncate } from '@sketch/components'
import FullCopyAttribute from '../FullCopyAttribute'
import { lightTheme as theme } from '@sketch/global-styles'
import { getDirtyAttributes } from 'modules/shares/Inspector/Sidebar/components/Style/DirtyIconTooltip'

const renderTextAttribute = (
  {
    font,
    color,
    letterSpacing,
    paragraphStyle,
    verticalAlignment,
    text,
    onColorFormatChange,
    colorFormat,
    originalSharedStyleValues,
    hideSeparators,
    decoration,
    transform,
  }: TextAttributes & {
    text: string
    originalSharedStyleValues?: TextStyle
    hideSeparators?: boolean
  } & ColorFormatModifier,
  i: number
) => {
  const { family, weight, weightName, size, style } = font || {}
  const displayedStyle = style && style !== 'normal' ? style : ''
  const { spacing, maxLineHeight, alignment } = paragraphStyle || {}

  const alignmentCopyValue = copyAlignment({
    verticalAlignment,
    paragraphStyle,
  })

  const sectionCopyValue = copySection({
    font,
    color,
    colorFormat,
    letterSpacing,
    paragraphStyle,
    verticalAlignment,
    decoration,
    transform,
  })

  const textOriginalStyles = originalSharedStyleValues?.text

  const dirtyHeaderAttributes =
    textOriginalStyles === null
      ? { originalValue: 'disabled', originalProperty: 'text style' }
      : undefined

  return (
    <div key={`TextStyle${i}`}>
      {' '}
      {!hideSeparators && <Separator />}
      <Section data-testid="inspector-sidebar-text">
        <Header
          copyValue={sectionCopyValue}
          dirtyAttributes={dirtyHeaderAttributes}
        >
          <Title>
            <Truncate
              width={theme.sidebar.rightWidth - 60}
            >{`Text: "${text}"`}</Truncate>
          </Title>
        </Header>
        <AttributeList>
          {family ? (
            <TextAttribute
              value={family}
              label="Typeface"
              dirtyAttributes={getDirtyAttributes({
                originalValueKey: 'family',
                valueToDisplay: textOriginalStyles?.font?.family ?? '',
                originalValues: textOriginalStyles?.font,
                labelToDisplay: 'Font Family',
              })}
            />
          ) : null}
          {weight && weightName ? (
            <TextAttribute
              value={`${capitalize(weightName)} ${capitalize(displayedStyle)} ${
                weightName !== weight.toString() ? `(${weight})` : ''
              }`}
              label="Weight"
              copyValue={weight}
              dirtyAttributes={getDirtyAttributes({
                originalValueKey: 'weight',
                valueToDisplay:
                  textOriginalStyles?.font?.weight?.toString() ?? '',
                originalValues: textOriginalStyles?.font,
              })}
            />
          ) : null}
          {size || size === 0 ? (
            <TextAttribute
              value={`${roundUpTo(size, 2)}px`}
              label="Size"
              copyValue={`${size}`}
              dirtyAttributes={getDirtyAttributes({
                originalValueKey: 'size',
                valueToDisplay: `${roundUpTo(
                  textOriginalStyles?.font?.size ?? 0,
                  2
                )}`,
                originalValues: textOriginalStyles?.font,
              })}
            />
          ) : null}
          {letterSpacing && roundUpTo(letterSpacing ?? 0, 2) !== '0' ? (
            <TextAttribute
              value={`${roundUpTo(letterSpacing, 2)}`}
              label="Letter Spacing"
              copyValue={`${letterSpacing}`}
              dirtyAttributes={getDirtyAttributes({
                originalValueKey: 'letterSpacing',
                valueToDisplay: `${roundUpTo(
                  textOriginalStyles?.letterSpacing ?? 0,
                  2
                )}`,
                originalValues: textOriginalStyles,
                labelToDisplay: 'Letter Spacing',
              })}
            />
          ) : null}
          {maxLineHeight ? (
            <TextAttribute
              value={`${renderLineHeight(
                maxLineHeight,
                size ? maxLineHeight / size : undefined
              )}`}
              label="Line Height"
              copyValue={`${maxLineHeight}`}
              dirtyAttributes={getDirtyAttributes({
                originalValueKey: 'maxLineHeight',
                valueToDisplay: `${roundUpTo(
                  textOriginalStyles?.paragraphStyle?.maxLineHeight ?? 0,
                  2
                )}`,
                originalValues: textOriginalStyles?.paragraphStyle,
                labelToDisplay: 'Line Height',
              })}
            />
          ) : null}
          {spacing ? (
            <TextAttribute
              value={`${roundUpTo(spacing, 2)}`}
              label="Paragraph"
              copyValue={`${spacing}`}
              dirtyAttributes={getDirtyAttributes({
                originalValueKey: 'spacing',
                valueToDisplay: `${roundUpTo(
                  textOriginalStyles?.paragraphStyle?.spacing ?? 0,
                  2
                )}`,
                originalValues: textOriginalStyles?.paragraphStyle,
                labelToDisplay: 'Paragraph',
              })}
            />
          ) : null}
          {decoration ? (
            <FullCopyAttribute
              value={
                <TextDecoration decoration={decoration} label="Decoration" />
              }
              copyValue={decoration}
              label="Decoration"
              dirtyAttributes={getDirtyAttributes({
                originalValueKey: 'decoration',
                valueToDisplay: decoration,
              })}
            />
          ) : null}
          {transform ? (
            <FullCopyAttribute
              value={<TextTransform transform={transform} label="Transform" />}
              copyValue={transform}
              label="Transform"
              dirtyAttributes={getDirtyAttributes({
                originalValueKey: 'transform',
                valueToDisplay: transform,
              })}
            />
          ) : null}
          {color ? (
            <Color
              {...color}
              onColorFormatChange={onColorFormatChange}
              colorFormat={colorFormat}
              dirtyAttributes={
                textOriginalStyles?.color
                  ? {
                      originalValue: textOriginalStyles.color as ColorType,
                      originalProperty: 'Color',
                    }
                  : undefined
              }
            />
          ) : null}
        </AttributeList>
        {alignment || verticalAlignment ? (
          <>
            <Header copyValue={alignmentCopyValue}>
              <SubTitle>Alignment</SubTitle>
            </Header>
            <AttributeList>
              {alignment ? (
                <FullCopyAttribute
                  value={
                    <TextAlignValue
                      alignment={alignment}
                      label="Horizontal Alignment"
                    />
                  }
                  copyValue={alignment}
                  label="Horizontal"
                  isTextStyle
                  dirtyAttributes={getDirtyAttributes({
                    originalValueKey: 'alignment',
                    valueToDisplay:
                      textOriginalStyles?.paragraphStyle?.alignment,
                    originalValues: textOriginalStyles?.paragraphStyle,
                    labelToDisplay: 'Horizontal Alignment',
                  })}
                />
              ) : null}
              {verticalAlignment ? (
                <FullCopyAttribute
                  value={
                    <TextAlignValue
                      alignment={verticalAlignment}
                      label="Vertical Alignment"
                    />
                  }
                  copyValue={verticalAlignment}
                  label="Vertical"
                  isTextStyle
                  dirtyAttributes={getDirtyAttributes({
                    originalValueKey: 'verticalAlignment',
                    valueToDisplay: textOriginalStyles?.verticalAlignment,
                    originalValues: textOriginalStyles,
                    labelToDisplay: 'Vertical Alignment',
                  })}
                />
              ) : null}
            </AttributeList>
          </>
        ) : null}
      </Section>
    </div>
  )
}

const attributesToArray = ({
  string,
  attributes,
  style,
  onColorFormatChange,
  colorFormat,
  hideSeparators,
}: TextAttributedString & { style?: Style } & ColorFormatModifier & {
    hideSeparators?: boolean
  }) => {
  let verticalAlignment:
    | TextAlignment.Top
    | TextAlignment.Middle
    | TextAlignment.Bottom
    | undefined = undefined
  if (style && style.text) {
    verticalAlignment = style.text.verticalAlignment
  }
  const result: Array<
    TextAttributes & {
      text: string
    } & ColorFormatModifier
  > = attributes.map(a => ({
    ...a.attributes,
    text: string.slice(a.location, a.location + a.length),
    verticalAlignment,
    onColorFormatChange,
    colorFormat,
    originalSharedStyleValues: style?.originalSharedStyleValues as TextStyle,
    hideSeparators,
  }))
  return result
}

type ColorFormatModifier = {
  onColorFormatChange: (f: ColorFormat) => void
  colorFormat: ColorFormat
}

export interface TextStyleProps extends TextProps, ColorFormatModifier {
  hideSeparators?: boolean
}

const isWhitespace = (string: string): boolean => {
  return /^\s+$/.test(string)
}

const Text = ({
  attributedString,
  style,
  onColorFormatChange,
  colorFormat,
  hideSeparators,
}: TextStyleProps) => (
  <>
    {attributesToArray({
      ...attributedString,
      style,
      onColorFormatChange,
      colorFormat,
      hideSeparators,
    })
      .filter(attribute => !isWhitespace(attribute.text))
      .map(renderTextAttribute)}
  </>
)

export default Text

import {
  useGetArtboardInDocumentWithUuidQuery,
  useShareForDocumentWithUuidQuery,
} from '@sketch/gql-types'
import { useVersioning } from 'modules/versioning'
import { SymbolMaster } from 'modules/inspector'

/**
 * Get information about the artboard that contains the symbol master.
 * We need this missing information to create the link to the symbol master
 * in the inspector.
 */
export function useFetchSymbolArtboard(symbolMaster: SymbolMaster) {
  const {
    isLoading: isLoadingDocumentIdentifier,
    documentIdentifier,
    shareIdentifier,
    versionShortId,
  } = useSymbolMasterDocument(symbolMaster)

  // If any of the variables aren't valid we skip the query
  const skipArtboardQuery = !documentIdentifier

  const symbolArtboardResponse = useGetArtboardInDocumentWithUuidQuery({
    variables: {
      documentId: documentIdentifier ?? '',
      artboardUuid: symbolMaster.artboardUuid,
    },
    skip: skipArtboardQuery,
  })

  if (skipArtboardQuery || symbolArtboardResponse.error) {
    // We need to be careful because if we had data in the cache for the ArtboardInDocumentWithUuidQuery
    // from previous variables, but we skip the query or the query fails after the variables changed,
    // we would return the old data.
    // If we haven't made the query or if it failed don't return any data from it.
    return {
      isLoading: isLoadingDocumentIdentifier,
      permanentArtboardShortId: undefined,
      shareIdentifier,
      versionShortId,
    }
  }

  const isLoading =
    isLoadingDocumentIdentifier || symbolArtboardResponse.loading

  const permanentArtboardShortId =
    symbolArtboardResponse.data?.artboardInDocumentWithUuid
      ?.permanentArtboardShortId

  return {
    isLoading,
    permanentArtboardShortId,
    shareIdentifier,
    versionShortId,
  }
}

/**
 * The symbol master does not know the actual document identifier. We need to fetch
 * it based on the information we have (document name and uuid).
 */
function useSymbolMasterDocument(symbolMaster: SymbolMaster) {
  const versionContext = useVersioning()

  const currentVersion = versionContext.loading
    ? null
    : versionContext.currentVersion

  const internalDocumentId =
    currentVersion?.document?.identifier ||
    versionContext.latestVersion?.identifier

  const documentName = symbolMaster.isForeign ? symbolMaster.documentName : ''
  const documentUuid = symbolMaster.isForeign ? symbolMaster.documentUuid : ''

  // To get the foreign symbol document identifier, we need to query for:
  // Share -> version -> document -> identifier
  const foreignSymbolShareResponse = useShareForDocumentWithUuidQuery({
    variables: {
      name: documentName,
      uuid: documentUuid,
    },
    skip: !documentName || !documentUuid,
  })

  if (symbolMaster.isForeign) {
    const foreignSymbolShare =
      foreignSymbolShareResponse?.data?.shareForDocumentWithUuid

    return {
      isLoading: foreignSymbolShareResponse.loading,
      documentIdentifier: foreignSymbolShare?.version?.document?.identifier,
      shareIdentifier: foreignSymbolShare?.identifier,
      versionShortId: foreignSymbolShare?.version?.shortId,
    }
  }

  return {
    isLoading: false,
    documentIdentifier: internalDocumentId,
    shareIdentifier: versionContext.share.identifier,
    versionShortId: versionContext.versionShortId,
  }
}

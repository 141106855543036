import { Point, Rect, RectEdge, Axis } from 'modules/shares/types'

const { min, max, abs } = Math

export const rectZero = (): Rect => ({ x: 0, y: 0, width: 0, height: 0 })
export const rectNull = (): Rect => ({
  x: Infinity,
  y: Infinity,
  width: 0,
  height: 0,
})
export const rectInfinite = (): Rect => ({
  x: -Number.MAX_VALUE / 2,
  y: -Number.MAX_VALUE / 2,
  width: Number.MAX_VALUE,
  height: Number.MAX_VALUE,
})

export const rectWidth = (rect: Rect): number => abs(rect.width)
export const rectHeight = (rect: Rect): number => abs(rect.height)
export const rectMinX = (rect: Rect): number => rect.x + min(rect.width, 0)
export const rectMinY = (rect: Rect): number => rect.y + min(rect.height, 0)
export const rectMaxX = (rect: Rect): number => rect.x + max(rect.width, 0)
export const rectMaxY = (rect: Rect): number => rect.y + max(rect.height, 0)
export const rectMidX = (rect: Rect): number => rect.x + rect.width / 2
export const rectMidY = (rect: Rect): number => rect.y + rect.height / 2

export const rectIsNull = (rect: Rect): boolean =>
  rect.x === Infinity || rect.y === Infinity
export const rectIsEmpty = (rect: Rect): boolean =>
  rectIsNull(rect) || rect.width === 0 || rect.height === 0

export const rectEqual = (r1: Rect, r2: Rect): boolean => {
  const rect1 = rectStandardized(r1)
  const rect2 = rectStandardized(r2)

  return (
    rect1.x === rect2.x &&
    rect1.y === rect2.y &&
    rect1.width === rect2.width &&
    rect1.height === rect2.height
  )
}

export const rectOffset = (
  { x, y, width, height }: Rect,
  dx: number,
  dy: number
): Rect => ({
  x: x + dx,
  y: y + dy,
  width,
  height,
})

export const rectInset = (rect: Rect, dx: number, dy: number): Rect => {
  if (rectIsNull(rect)) {
    return rect
  }

  const { x, y, width, height } = rectStandardized(rect)

  const newRect = {
    x: x + dx,
    y: y + dy,
    width: width - 2 * dx,
    height: height - 2 * dy,
  }

  if (newRect.width < 0 || newRect.height < 0) {
    return rectNull()
  }

  return newRect
}

export const rectUnion = (r1: Rect, r2: Rect): Rect => {
  if (rectIsNull(r1)) {
    return r2
  } else if (rectIsNull(r2)) {
    return r1
  }

  const rect1 = rectStandardized(r1)
  const rect2 = rectStandardized(r2)

  const minX = min(rectMinX(rect1), rectMinX(rect2))
  const minY = min(rectMinY(rect1), rectMinY(rect2))
  const maxX = max(rectMaxX(rect1), rectMaxX(rect2))
  const maxY = max(rectMaxY(rect1), rectMaxY(rect2))

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  }
}

export const rectStandardized = (rect: Rect): Rect => {
  if (rectIsNull(rect)) {
    return rectNull()
  }

  return {
    x: rectMinX(rect),
    y: rectMinY(rect),
    width: rectWidth(rect),
    height: rectHeight(rect),
  }
}

export const rectContainsRect = (rect1: Rect, rect2: Rect): boolean => {
  return rectEqual(rect1, rectUnion(rect1, rect2))
}

export const rectContainsRectOnAxis = (
  rect1: Rect,
  rect2: Rect,
  axis: Axis
): boolean => {
  switch (axis) {
    case 'x':
      return (
        rectMinX(rect2) >= rectMinX(rect1) && rectMaxX(rect2) <= rectMaxX(rect1)
      )
    case 'y':
      return (
        rectMinY(rect2) >= rectMinY(rect1) && rectMaxY(rect2) <= rectMaxY(rect1)
      )
  }
}

export const rectContainsPoint = (rect: Rect, point: Point): boolean =>
  point.x >= rect.x &&
  point.x <= rect.x + rect.width &&
  point.y >= rect.y &&
  point.y <= rect.y + rect.height

export const rectScale = (rect: Rect, factor: number): Rect => {
  if (rectEqual(rect, rectInfinite())) {
    return rectInfinite()
  }

  return {
    x: rect.x * factor,
    y: rect.y * factor,
    width: rect.width * factor,
    height: rect.height * factor,
  }
}

export const rectEdgeOpposite = (edge: RectEdge): RectEdge => {
  switch (edge) {
    case 'minX':
      return 'maxX'
    case 'maxX':
      return 'minX'
    case 'minY':
      return 'maxY'
    case 'maxY':
      return 'minY'
  }
}

export const rectMidOfEdge = (rect: Rect, edge: RectEdge): Point => {
  switch (edge) {
    case 'minX':
      return { x: rectMinX(rect), y: rectMidY(rect) }

    case 'maxX':
      return { x: rectMaxX(rect), y: rectMidY(rect) }

    case 'minY':
      return { x: rectMidX(rect), y: rectMinY(rect) }

    case 'maxY':
      return { x: rectMidX(rect), y: rectMaxY(rect) }
  }
}

export const rectEdgeAxis = (edge: RectEdge): Axis => {
  switch (edge) {
    case 'minX':
    case 'maxX':
      return 'x'

    case 'minY':
    case 'maxY':
      return 'y'
  }
}

export const rectIntersectsRect = (rect1: Rect, rect2: Rect): boolean =>
  rectMaxX(rect1) >= rectMinX(rect2) &&
  rectMaxX(rect2) >= rectMinX(rect1) &&
  rectMaxY(rect1) >= rectMinY(rect2) &&
  rectMaxY(rect2) >= rectMinY(rect1)

export const scalePoint = (point: Point, factor: number): Point => ({
  x: point.x * factor,
  y: point.y * factor,
})

export const rectIntersectsRectOnAxis = (
  rect1: Rect,
  rect2: Rect,
  axis: Axis
): boolean => {
  switch (axis) {
    case 'x':
      return (
        rectMaxX(rect1) >= rectMinX(rect2) && rectMaxX(rect2) >= rectMinX(rect1)
      )
    case 'y':
      return (
        rectMaxY(rect1) >= rectMinY(rect2) && rectMaxY(rect2) >= rectMinY(rect1)
      )
  }
}

export const midPointBetweenPoints = (point1: Point, point2: Point): Point => {
  return {
    x: (point1.x + point2.x) / 2,
    y: (point1.y + point2.y) / 2,
  }
}

import { getLCP as getLCP$1 } from 'web-vitals';
import { timestampsRef } from './OperationsTimestampLink.js';

const isVerifiedLCPValue = (metric, expectedOperations) => {
    if (expectedOperations === undefined) {
        return undefined;
    }
    if (expectedOperations === 'none') {
        return true;
    }
    const threshold = Math.min(Number.POSITIVE_INFINITY, ...Object.entries(timestampsRef)
        .filter(([opName]) => expectedOperations.includes(opName))
        .map(([, value]) => value));
    return metric.value > threshold;
};
const getLCP = (handler, expectedOperations) => {
    getLCP$1(metric => {
        handler({
            metric,
            isAfterReceivingData: isVerifiedLCPValue(metric, expectedOperations),
        });
    }, false);
};

export { getLCP };

import { useContext, useEffect } from 'react';
import { EventEmitterContext } from './EventEmitter.context.js';

const _useEventDispatch = (event) => {
    const { events } = useContext(EventEmitterContext);
    return (data) => {
        events.dispatch(event, data);
    };
};
const _useOnEvent = (event, callback) => {
    const { events } = useContext(EventEmitterContext);
    useEffect(() => {
        events.subscribe(event, callback);
        return () => events.unsubscribe(event, callback);
    }, [events, event, callback]);
};

export { _useEventDispatch, _useOnEvent };

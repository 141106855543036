import React from 'react'
import { ShareInfoFragment, WorkspaceMinimalFragment } from '@sketch/gql-types'
import {
  ShareActionsDropdown,
  ShareForShareDropdown,
} from 'modules/shares/components/ShareActionsDropdown'
import {
  ShareActionsDropdownTrashed,
  ShareForTrashedActionsDropdown,
} from 'modules/shares/components/ShareActionsDropdownTrashed'

import {
  DropdownButton,
  DropdownButtonEllipsis,
} from '../DocumentItem/DocumentItem.styles'

import { useForTablet, useResponsiveDropdown } from '@sketch/components'
import { useDuplicateShareTo } from '../../hooks/useDuplicateShareTo'
import { useDuplicateShareToDrafts } from '../../hooks/useDuplicateShareToDrafts'

export interface DocumentItemDropdownProps {
  share: ShareForShareDropdown &
    ShareForTrashedActionsDropdown &
    Pick<ShareInfoFragment, 'deletedAt'> & {
      __typename: 'Share' | 'TrashedShare'
    }
  workspaceIdentifier: string
  queryVariables: any
  workspaceStatus?: WorkspaceMinimalFragment['status']
}

export const DocumentItemDropdown = (props: DocumentItemDropdownProps) => {
  const { share, workspaceIdentifier, queryVariables, workspaceStatus } = props

  const version = share.version!

  // Both of these mutations are quite high in the component tree in relation to where its actually used.
  // This is because the dropdown is immediatly unmounted when clicked and this causes
  // the mutation onComplete to never trigger and cancel the duplicate flow mid way.
  const [duplicateShareToDrafts] = useDuplicateShareToDrafts({
    share,
    versionIdentifier: version.identifier,
    workspaceIdentifier,
  })

  const [duplicateShareTo] = useDuplicateShareTo({
    share,
    versionIdentifier: version.identifier,
    workspaceIdentifier,
  })

  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: ShareActionsDropdown,
    dropdownProps: {
      share,
      versions: { current: version, latest: version },
      isViewingLatestVersion: true,
      workspaceIdentifier,
      onDuplicateShareToDraftsClick: duplicateShareToDrafts,
      onDuplicateShareToClick: duplicateShareTo,
      queryVariables,
      workspaceStatus,
    },
    placement: 'bottom',
    usePortal: !isMobile,
    useModalRoot: isMobile,
  })

  const [
    contentForDeleteDropdown,
    buttonPropsDeleteDropdown,
  ] = useResponsiveDropdown({
    dropdown: ShareActionsDropdownTrashed,
    dropdownProps: {
      share,
    },
    placement: 'bottom-end',
    usePortal: true,
  })

  if (!share.deletedAt && share.__typename === 'Share') {
    return (
      <div onClick={event => event.stopPropagation()}>
        <DropdownButton {...buttonProps}>
          <DropdownButtonEllipsis aria-label="Document Settings" />
          <span className="sr-only">Document Settings</span>
        </DropdownButton>
        {content}
      </div>
    )
  }

  if (share.deletedAt) {
    return (
      <div onClick={event => event.stopPropagation()}>
        <DropdownButton {...buttonPropsDeleteDropdown}>
          <DropdownButtonEllipsis aria-label="Document Settings" />
          <span className="sr-only">Document Settings</span>
        </DropdownButton>
        {contentForDeleteDropdown}
      </div>
    )
  }

  return null
}

import React from 'react'
import styled from 'styled-components'

import { HighlightedText, pluralize, TimeAgo } from '@sketch/components'

import {
  ImageWrapper,
  GridWrapper,
  Name,
  DropdownButtonWrapper,
} from '../DocumentItem/DocumentItem.styles'
import {
  StyledSubtitleTextWrapper,
  StyledFolderIcon,
} from './ProjectItem.styles'
import {
  ProjectItemProps,
  getButtonProps,
  renderDropdown,
  renderPreview,
} from './utils'
import {
  handleDeletedAtTimeFormat,
  handleArchivedAtTimeFormat,
} from '../DocumentItem'
import { useSearch } from 'modules/shares/hooks/useSearch'

export const ProjectItemGridView = styled(function ProjectItemGridView(
  props: ProjectItemProps
) {
  const {
    archivedAt,
    className,
    name,
    documentsNumber = 0,
    deletedAt,
    renderDropdownItems,
    onClick,
  } = props

  const previewImage = renderPreview(props, 'grid')
  const a11yProps = onClick ? getButtonProps(onClick) : {}
  const { search } = useSearch()

  return (
    <GridWrapper
      className={className}
      title={name}
      data-testid="project-item"
      {...a11yProps}
    >
      <ImageWrapper>{previewImage}</ImageWrapper>
      <Name>
        <StyledFolderIcon aria-label="Folder Icon" />
        <HighlightedText search={search}>{name}</HighlightedText>
      </Name>
      <StyledSubtitleTextWrapper>
        <b className="shrink">
          {`${documentsNumber} ${pluralize(
            'Document',
            'Documents',
            documentsNumber
          )}`}
        </b>

        {archivedAt && (
          <div className="keep-size">
            <TimeAgo date={archivedAt} formatter={handleArchivedAtTimeFormat} />
          </div>
        )}

        {deletedAt && (
          <div className="keep-size">
            <TimeAgo date={deletedAt} formatter={handleDeletedAtTimeFormat} />
          </div>
        )}
      </StyledSubtitleTextWrapper>

      {renderDropdownItems && (
        <DropdownButtonWrapper>
          {renderDropdown(renderDropdownItems(), props.deletedAt)}
        </DropdownButtonWrapper>
      )}
    </GridWrapper>
  )
})``

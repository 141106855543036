import { jsx } from 'react/jsx-runtime';
import React, { useRef } from 'react';
import { EventEmitter } from './EventEmitter.js';

const EventEmitterContext = React.createContext({
    events: new EventEmitter(),
});
const EventEmitterProvider = ({ children }) => {
    const eventsRef = useRef(new EventEmitter());
    return (jsx(EventEmitterContext.Provider, Object.assign({ value: { events: eventsRef.current } }, { children: children })));
};

export { EventEmitterContext, EventEmitterProvider };

import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { IndexLayoutContent, RedirectBadge, SignUpForm, routes } from '@sketch/modules-common';
import { useForDesktop, Text, Link } from '@sketch/components';
import { isMac } from '@sketch/utils';
import { useSignUpFormContextForAnalytics } from './useSignUpFormContextForAnalytics.js';
import { RelatedContentLink, RelatedContentImg, RelatedContentTitle, RelatedContentDescription, FullWidthImage, Container, Column, Title, Subtitle, Feature, FeatureImg, RequiredOSVersion, OperatingSystemWarning, Line, TextSignUp } from './SignUpView.styles.js';
import { useThemeContext } from '@sketch/global-styles';
import { isBillingHidden, SKETCH_WEBSITE } from '@sketch/env-config';

const RelatedContent = ({ content }) => {
    const { relatedContent } = content;
    if (!relatedContent) {
        return null;
    }
    return (jsxs(RelatedContentLink, Object.assign({ target: "_blank", href: relatedContent.url, external: true }, { children: [jsx(RelatedContentImg, { src: relatedContent.thumbnail.src, alt: relatedContent.thumbnail.alt }), jsxs("div", { children: [jsx(RelatedContentTitle, { children: relatedContent.title }), jsx(RelatedContentDescription, { children: relatedContent.description })] })] })));
};
const HeroImage = ({ content }) => {
    const { heroImage } = content;
    if (!heroImage) {
        return null;
    }
    return jsx(FullWidthImage, { src: heroImage.src, alt: heroImage.alt });
};
const BaseSignUpView = ({ content, email, invitedUser, onCompleted, useOverrideLayoutProps, }) => {
    const { isDarkMode } = useThemeContext();
    const { state } = useLocation();
    const isDesktopAndBigger = useForDesktop();
    const showOSWarning = !isMac();
    const getTheme = useCallback((theme) => overriddenThemeFor(theme), []);
    useOverrideLayoutProps({ variant: 'orange', maxContainerWidth: '960px' });
    const formContextForAnalytics = useSignUpFormContextForAnalytics();
    return (jsx(ThemeProvider, Object.assign({ theme: getTheme }, { children: jsxs(IndexLayoutContent, Object.assign({ center: "horizontal", marginTop: true, paddingHorizontal: true }, { children: [(state === null || state === void 0 ? void 0 : state.from) && jsx(RedirectBadge, Object.assign({}, state)), jsxs(Container, { children: [jsxs(Column, { children: [jsx(Title, { children: content.title }), isDesktopAndBigger && jsx(Subtitle, { children: content.introduction }), content.features.map((feature, index) => (jsxs(Feature, { children: [jsx(FeatureImg, { src: feature.icon.src, alt: feature.icon.alt, isDarkModeActive: isDarkMode }), jsx("span", { children: feature.description })] }, index))), isDesktopAndBigger && (jsxs(Fragment, { children: [jsx(RelatedContent, { content: content }), jsx(HeroImage, { content: content })] })), !isBillingHidden && (jsxs(Text, Object.assign({ textStyle: "copy.secondary.standard.F", mt: "5" }, { children: [jsx(Link, Object.assign({ variant: "primary", isUnderlined: true, external: true, href: `${SKETCH_WEBSITE}/store/education`, target: "_blank", rel: "noopener noreferrer" }, { children: "Use Sketch for free" })), ' ', "if you\u2019re a student or educator."] })))] }), jsxs(Column, { children: [jsx(RequiredOSVersion, { children: "To run Sketch you\u2019ll need a macOS device with macOS Monterey (12.0.0) or newer." }), jsx(SignUpForm, { email: email, invitedUser: invitedUser, onCompleted: onCompleted, callToAction: content.callToAction, formContextForAnalytics: formContextForAnalytics }), showOSWarning && (jsx(OperatingSystemWarning, Object.assign({ "data-testid": "os-warning" }, { children: "To design with Sketch you'll need macOS Monterey (12.0.0) or later" }))), jsx(Line, {}), jsxs(TextSignUp, { children: ["Already have a Sketch account?", ' ', jsx(Link, Object.assign({ variant: "primary", to: {
                                                pathname: routes.SIGN_IN.create({}),
                                                state,
                                            }, isUnderlined: true }, { children: "Sign in" }))] })] })] })] })) })));
};
const overriddenThemeFor = (theme) => theme;

export { BaseSignUpView as default };

import { jsxs, jsx } from 'react/jsx-runtime';
import { useThemeContext } from '@sketch/global-styles';
import { useFlag } from '@sketch/modules-common';
import { Section, Text, Checkbox } from '@sketch/components';
import { Themes, ThemeOption, RadioButtonStyled, Thumbnail } from './AppearanceSettings.styles.js';
import { ReactComponent as ReactComponent$1 } from '@sketch/icons/appearance-settings-dark-mode';
import { ReactComponent } from '@sketch/icons/appearance-settings-light-mode';

const AppearanceSettings = () => {
    const isDarkModeToggleEnabled = useFlag('dark-mode-toggle');
    const { enableLightMode, enableDarkMode, toggleMatchSystem, theme, matchSystem, } = useThemeContext();
    if (!isDarkModeToggleEnabled) {
        return null;
    }
    return (jsxs(Section, Object.assign({ title: "Theme" }, { children: [jsx(Text, Object.assign({ mt: 0, mb: 6 }, { children: "Choose how Sketch will appear across all Workspaces." })), jsx(Checkbox, { checked: matchSystem, onChange: toggleMatchSystem, label: "Match to system settings" }), jsxs(Themes, { children: [jsxs(ThemeOption, Object.assign({ active: !matchSystem && theme === 'light', onClick: enableLightMode }, { children: [jsx(RadioButtonStyled, { disabled: matchSystem, name: "theme", label: "Light", value: "light", checked: theme === 'light', onChange: enableLightMode }), jsx(Thumbnail, { as: ReactComponent, disabled: matchSystem })] })), jsxs(ThemeOption, Object.assign({ active: !matchSystem && theme === 'dark', onClick: enableDarkMode }, { children: [jsx(RadioButtonStyled, { disabled: matchSystem, name: "theme", label: "Dark", value: "dark", checked: theme === 'dark', onChange: enableDarkMode }), jsx(Thumbnail, { as: ReactComponent$1, disabled: matchSystem })] }))] })] })));
};

export { AppearanceSettings as default };

import React, { useImperativeHandle, useRef } from 'react'

import { DefaultFeatureFlags } from '@sketch-hq/sketch-web-renderer'
import { ErrorMessage } from '@sketch/components'
import { IS_EMBEDDED } from '@sketch/constants'
import { WebRendererCanvas } from 'components/WebRendererCanvas'
import { useCanvasPresentationFile } from 'components/WebRendererCanvas/hooks'
import { useTheme } from 'styled-components'

import ArtboardAnnotationOverlay from '../ArtboardAnnotationOverlay'
import LoadingState from 'modules/shares/components/LoadingState'
import { PageCanvasError } from 'modules/shares/PageCanvasView/components/PageCanvasError'

import { ArtboardDetailInspectorOverlay } from '../ArtboardDetailInspectorOverlay'
import { useCanvasAnnotations } from 'modules/annotations/hooks'
import { useInspectorContext, useInspectorHoveredElement } from '../../hooks'

import * as S from './ArtboardDetailCanvas.styles'
import {
  ArtboardDetailInspectorContextMenu,
  useInspectorContextMenu,
} from '../ArtboardDetailInspectorContextMenu'
import { ArtboardDetailInspectorTestOverlay } from '../ArtboardDetailInspectorTestOverlay'
import { DocumentProcessing } from 'modules/collections/components/CollectionItemEmptyStateOld/CollectionItemEmptyState.styles'
import { getWebRenderCursor } from 'modules/inspector'
import { useObjectProxyAccumulator } from '@sketch/utils'
import { OriginContent } from 'modules/canvas/components'

interface ArtboardDetailCanvasProps {
  pageUUID: string
  artboardUUID: string
}

const ARTBOARD_DETAIL_CANVAS_FEATURE_FLAGS: Partial<
  typeof DefaultFeatureFlags
> = {
  canvasLayersEvents: true,
}

const IS_RUNNING_TESTS =
  process.env.JEST_WORKER_ID !== undefined || window.Cypress

export const ArtboardDetailCanvas = React.forwardRef<
  HTMLDivElement,
  ArtboardDetailCanvasProps
>(function ArtboardDetailCanvas({ pageUUID, artboardUUID }, ref) {
  const canvasContainerRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => canvasContainerRef.current!)

  const theme = useTheme()
  const {
    isInspectorActive,
    clickHandler,
    mouseMoveHandler,
    mouseDownHandle,
  } = useInspectorContext()

  const {
    share,
    page,
    versionShortId,
    hasError,
    isLoading,
    isRendering,
  } = useCanvasPresentationFile({
    pageUUID,
  })

  const {
    isPlacingDraftAnnotation,
    onMouseDown,
    onMouseUp,
    onTouchEnd,
    onTouchStart,
    activeAnnotation,
  } = useCanvasAnnotations()

  const {
    handleContextMenuEvent,
    contextMenuPosition,
    handleCloseContextMenu,
  } = useInspectorContextMenu(canvasContainerRef)
  const hoveredElement = useInspectorHoveredElement()

  const webRenderContainerProps = useObjectProxyAccumulator(
    { onContextMenu: handleContextMenuEvent },
    !isInspectorActive && {
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      onTouchStart,
    },
    isInspectorActive && {
      onClick: clickHandler,
      onMouseMove: mouseMoveHandler,
      onMouseDown: mouseDownHandle,
    }
  )

  if (isLoading) {
    return <LoadingState />
  }

  if (isRendering) {
    return (
      <ErrorMessage
        title="Rendering Canvas…"
        description="The canvas is rendering. When it’s ready, it will appear here."
        icon={<DocumentProcessing />}
      />
    )
  }

  if (hasError || !page) {
    return (
      <PageCanvasError
        description={
          <>
            There was a problem fetching the data. You can find more information
            on our <a href="https://status.sketch.com/">Status Page</a>.
          </>
        }
        showReloadCanvasAction
      />
    )
  }

  return (
    <S.Wrapper ref={canvasContainerRef}>
      <ArtboardDetailInspectorContextMenu
        contextMenuPosition={contextMenuPosition}
        onCloseMenu={handleCloseContextMenu}
      />
      <WebRendererCanvas
        pageUUID={pageUUID}
        artboardUUID={artboardUUID}
        page={page}
        versionShortId={versionShortId}
        shareIdentifier={share.identifier}
        backgroundColor={theme.colors.background.secondary.B}
        featureFlags={ARTBOARD_DETAIL_CANVAS_FEATURE_FLAGS}
        webRenderCursor={getWebRenderCursor(
          isPlacingDraftAnnotation,
          hoveredElement
        )}
        webRenderContainerProps={webRenderContainerProps.current}
      >
        {!IS_EMBEDDED && (
          <OriginContent>
            <ArtboardAnnotationOverlay
              permanentArtboardId={artboardUUID}
              parentWrapper={canvasContainerRef}
              activeAnnotationIdentifier={activeAnnotation}
            />
          </OriginContent>
        )}

        {isInspectorActive && (
          <>
            {IS_RUNNING_TESTS && <ArtboardDetailInspectorTestOverlay />}
            <ArtboardDetailInspectorOverlay
              canvasContainerRef={canvasContainerRef}
            />
          </>
        )}
      </WebRendererCanvas>
    </S.Wrapper>
  )
})

import { __rest } from 'tslib';
import { jsx, jsxs } from 'react/jsx-runtime';
import React, { useRef, useImperativeHandle, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import keycode from 'keycode';
import '../Box/index.js';
import { Form, Container, LoupeIconContainer, LoupeIcon, SearchInput, ClearButtonAndChildrenWrapper, ClearButton, CrossIcon } from './FilterOld.styles.js';
import { Flex } from '../Box/BoxSystem.js';

const FilterBase = React.forwardRef(function FilterBase(props, ref) {
    const { className, name, onBlur = () => { }, onChange = () => { }, onFocus = () => { }, onKeyDown = () => { }, onSubmit = () => { }, onClear = () => { }, placeholder = 'Search', value = '', width, children, isCWV, disabled } = props, rest = __rest(props, ["className", "name", "onBlur", "onChange", "onFocus", "onKeyDown", "onSubmit", "onClear", "placeholder", "value", "width", "children", "isCWV", "disabled"]);
    const inputRef = useRef(null);
    useImperativeHandle(ref, () => inputRef.current);
    // This will alow us to control the keyup handler on a global level. For
    // example, in cases where the input does not have focus.
    const eventListenerAdded = useRef(false);
    const [internalValue, setInternalValue] = useState(value);
    // Empty and close search if ESC key is clicked
    const onKeyUpHandler = useCallback(e => {
        var _a;
        if (e.keyCode !== keycode('escape'))
            return;
        e.preventDefault();
        setInternalValue('');
        onChange('');
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
    }, [onChange]);
    useEffect(() => {
        setInternalValue(value);
    }, [value]);
    useEffect(() => {
        if (internalValue && !eventListenerAdded.current) {
            document.addEventListener('keyup', onKeyUpHandler);
            eventListenerAdded.current = true;
        }
        return () => {
            if (!internalValue && eventListenerAdded.current) {
                document.removeEventListener('keyup', onKeyUpHandler);
                eventListenerAdded.current = false;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalValue]);
    return (jsx(Form, Object.assign({ className: className, width: width, onSubmit: e => {
            e.preventDefault();
            onSubmit(e);
        } }, rest, { children: jsxs(Container, { children: [jsx(LoupeIconContainer, Object.assign({ onClick: () => { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus(); } }, { children: jsx(LoupeIcon, { "data-testid": "loupe-icon" }) })), jsx(SearchInput, { ref: inputRef, name: name, type: "search", value: internalValue, onKeyDown: onKeyDown, "data-cwv-search-input": isCWV, placeholder: placeholder, disabled: disabled, onFocus: e => {
                        onFocus(e);
                    }, onBlur: e => {
                        if (!internalValue) {
                            onBlur(e);
                        }
                    }, onChange: e => {
                        setInternalValue(e.target.value);
                        onChange(e.target.value);
                    } }), jsxs(ClearButtonAndChildrenWrapper, { children: [internalValue && (jsx(ClearButton, Object.assign({ "aria-label": "Clear search", type: "button", onClick: () => {
                                var _a;
                                (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                                setInternalValue('');
                                onChange('');
                                onSubmit();
                                onClear();
                            } }, { children: jsx(Flex, Object.assign({ alignItems: "center" }, { children: jsx(CrossIcon, { "data-testid": "cross-icon" }) })) }))), children] })] }) })));
});
const Filter = styled(FilterBase) ``;

export { Filter };

import { jsx, jsxs } from 'react/jsx-runtime';
import { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import '../LoadingPlaceholder/index.js';
import { noop } from '@sketch/utils';
import { ReactComponent } from '@sketch/icons/image-slash-64';
import { ReactComponent as ReactComponent$1 } from '@sketch/icons/warning-triangle-64';
import { LoadingPlaceholder as LoadingPlaceholder$1 } from '../LoadingPlaceholder/LoadingPlaceholder.js';

const ImageBaseComponent = styled.img.withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => ['elementtiming'].includes(prop) || defaultValidatorFn(prop),
})(({ zoomFactor, objectFit = undefined }) => css `
    display: block;
    width: inherit;
    height: inherit;
    image-rendering: ${zoomFactor && zoomFactor > 1 && 'pixelated'};
    object-fit: ${objectFit};

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;

      /* To avoid a jump when switching between loading and successful state */
      width: 0;
    }
  `);
const overlayStyle = css `
  position: absolute;
  margin: auto;
`;
const Icon = styled.svg.attrs({
    role: 'img',
}) `
  ${overlayStyle}
  max-width: 64px;
  max-height: 64px;

  width: 100%;
  height: 100%;

  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.3;
  padding: 4px;
`;
const LoadingPlaceholder = styled(LoadingPlaceholder$1) `
  ${overlayStyle}
`;
const ImageContainer = styled.div.attrs(({ width, height }) => ({ style: { width, height } })) `
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Image = props => {
    const { alt, className, customImageElement: ImageComponent = ImageBaseComponent, onError = noop, onLoad = noop, loadingPlaceholder = jsx(LoadingPlaceholder, { size: "24px" }), src, srcSet, width, height, zoomFactor, objectFit, } = props;
    const hasSource = !!(src || srcSet);
    const [status, setStatus] = useState(hasSource ? 'loading' : 'no-image');
    const imageRef = useRef(null);
    useEffect(() => {
        if (src || srcSet) {
            if (imageRef.current && imageRef.current.complete) {
                setStatus('successful');
            }
            else {
                setStatus('loading');
            }
        }
        else {
            setStatus('no-image');
        }
    }, [src, srcSet]);
    const handleImageLoad = (event) => {
        setStatus('successful');
        onLoad(event);
    };
    const handleImageError = () => {
        setStatus('error');
        onError();
    };
    let overlayIcon = null;
    switch (status) {
        case 'error': {
            overlayIcon = jsx(Icon, { as: ReactComponent$1, "aria-label": "Error loading" });
            break;
        }
        case 'no-image': {
            overlayIcon = jsx(Icon, { as: ReactComponent, "aria-label": "No image" });
            break;
        }
        case 'loading': {
            overlayIcon = loadingPlaceholder;
            break;
        }
    }
    const imageSize = status === 'successful' ? { width, height } : {};
    const imageHidden = status !== 'successful';
    return (jsxs(ImageContainer, Object.assign({ "aria-busy": status === 'loading', className: className }, imageSize, { children: [overlayIcon, jsx(ImageComponent, { "aria-hidden": imageHidden, alt: alt, ref: imageRef, src: src, srcSet: srcSet, onLoad: handleImageLoad, onError: handleImageError, zoomFactor: zoomFactor, elementtiming: "image", objectFit: objectFit })] })));
};

export { Icon, Image, ImageBaseComponent, ImageContainer, overlayStyle };

import styled, { css } from 'styled-components';
import { Navbar } from '@sketch/components';
import { breakpoint } from '@sketch/global-styles';

const Wrapper = styled.div.attrs({ 'data-testid': 'index-layout' }) `
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  background: ${({ theme }) => theme.colors.background.secondary.A};
`;
const HeaderContainer = styled.div `
  width: 100%;
  height: 100%;
`;
const Header = styled(Navbar) `
  position: sticky;
  top: 0;
  padding: 0;
  z-index: 1;

  ${({ noBorder }) => noBorder &&
    css `
      border: none;
    `}
`;
const LogoWrapper = styled.div `
  display: flex;
  justify-content: center;
  width: 56px;
  border-right: 1px solid ${({ theme }) => theme.colors.border.B};

  ${breakpoint('base', 'sm') `
    border-right: none;
  `}
`;
const FooterWrapper = styled.div `
  footer {
    padding-top: 40px;
  }
`;
const Main = styled.main `
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;
const Content = styled.div `
  ${({ theme }) => css `
    background: ${theme.colors.background.secondary.A};
    display: flex;
    flex-direction: column;
    flex: 1;

    width: calc(100% - ${theme.sidebar.indexLayoutSidebar}px);

    margin-left: ${theme.sidebar.indexLayoutSidebar}px;

    /** In mobile, the sidebar disappears so we don't need the margin */
    ${breakpoint('base', 'sm') `
      margin-left: 0;
      width: 100%;
    `}
  `}
`;

export { Content, FooterWrapper, Header, HeaderContainer, LogoWrapper, Main, Wrapper };

const SIGN_UP_INITIAL_VALUES = {
    name: '',
    password: '',
    email: '',
    'terms-privacy-policy': false,
    'marketing-consent': false,
};
const TERMS_OF_SERVICE_PRIVACY_POLICY_ERROR = 'You need to agree to the Terms of Service and Privacy Policy to continue';

export { SIGN_UP_INITIAL_VALUES, TERMS_OF_SERVICE_PRIVACY_POLICY_ERROR };

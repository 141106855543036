function locateMention(value, fromIndex) {
    return value.indexOf('@', fromIndex);
}
/**
 * TokenizeMention.
 *
 * This function is responsible for finding the mention block and converting to text.
 *
 * @param {Function} eat - Replace the match by an object.
 * @param {string} value - Mention value caught.
 * @param {boolean} silent - Whether to detect or consume.
 *
 * @returns {Object} - Returns a element of type text with the mention.
 */
function tokenizeMention(eat, value, silent) {
    const match = /^@\[(.*?)\]\(user:(.*?)\)/.exec(value);
    if (match) {
        if (silent) {
            return true;
        }
        return eat(match[0])({
            type: 'strong',
            children: [
                {
                    type: 'text',
                    value: `@${match[1]}`,
                },
            ],
        });
    }
}
tokenizeMention.notInLink = true;
tokenizeMention.locator = locateMention;
/**
 * Mentions plugin for the `remark-js`.
 */
function mention() {
    const Parser = this.Parser;
    const tokenizers = Parser.prototype.inlineTokenizers;
    const methods = Parser.prototype.inlineMethods;
    tokenizers.mention = tokenizeMention;
    methods.splice(methods.indexOf('text'), 0, 'mention');
}

export { mention };

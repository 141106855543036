import React, { useState } from 'react'
import * as Sentry from '@sentry/browser'
import { useToast } from '@sketch/toasts'

import { countries } from '@sketch/constants'

import {
  confirmPendingSetupIntent,
  isPaymentMethodCard,
  isPaymentMethodInvoice,
  isWorkspaceSubscriptionActive,
} from 'modules/workspace/utils'

import Panel from '../Panel'
import { PaymentLogo, useModalContext, useStripe } from '@sketch/components'

import BillingInfoTable from '../BillingInfoTable'
import EditAddressDetailsModal from 'modules/workspace/modals/EditAddressDetailsModal'
import EditPaymentMethodModal from 'modules/workspace/modals/EditPaymentMethodModal'
import { VatStatus } from 'modules/workspace/components/VatStatus'

import {
  PaymentInformationWrapper,
  List,
  StyledButton,
  PaymentLogoWrapper,
  SCAWarningPill,
  CreditBalanceTextContainer,
  CreditLogoAmountContainer,
  PaymentInformationTitleContainer,
  StyledBillingInfoCell,
  StripeSecureInfo,
  LockIcon,
  LastDigits,
} from './PaymentInformationPanel.styles'

import {
  BillingDetailsFragment,
  PaymentDetailsFragment,
  BillingPlanFragment,
} from '@sketch/gql-types'

import { formatPriceShort } from '@sketch/utils'

// This is a valid use case of expansive types
// eslint-disable-next-line no-restricted-imports
import { BillingStatus } from '@sketch/gql-types/expansive'

const IGNORE_UI_REFRESH_STATUS = ['ACTIVE', 'TRIAL']

type Countries = keyof typeof countries

interface PaymentInformationPanelProps {
  customerId: string
  credits: number
  billingDetails: BillingDetailsFragment
  paymentDetails?: PaymentDetailsFragment
  subscriptionStatus: BillingStatus
  pendingSCAToken?: string
  isSsoActive?: boolean
  currentPlan?: BillingPlanFragment
  onWorkspaceSettingRefresh: () => Promise<void>
}

type BillingDetailsItemProps = {
  index: number
  item: string | null
}

const BillingDetailsItem: React.FC<BillingDetailsItemProps> = ({
  index,
  item,
}) => {
  if (index === 0)
    return <li key={index}>{<strong>{item}</strong> || <>&nbsp;</>}</li>
  return <li key={index}>{item || <>&nbsp;</>}</li>
}

/*
 * PaymentInformationPanel
 *
 * Renders the Payments Information panel inside the Billing page
 */
export const PaymentInformationPanel: React.FC<PaymentInformationPanelProps> = props => {
  const {
    customerId,
    billingDetails,
    paymentDetails,
    credits,
    subscriptionStatus,
    pendingSCAToken,
    isSsoActive,
    onWorkspaceSettingRefresh,
  } = props
  const { showModal } = useModalContext()
  const { showToast } = useToast()
  const { stripe } = useStripe()
  const [scaButtonLoading, setScaButtonLoading] = useState(false)
  const [hideVerifyButton, setHideVerifyButton] = useState(false)
  // Billing details
  const { name, email, address, taxId } = billingDetails

  const billingItems: (string | null)[] = [name, email]

  if (address) {
    const stateAndPostalCode = [address.state, address.postalCode].join(' ')
    const country = countries[address.country as Countries] || address.country

    billingItems.push(
      null, // A blank line separating email from address
      address?.line1,
      address?.city,
      stateAndPostalCode,
      country
    )
  }

  const onPaymentUpdated = async () => {
    /**
     * We need to refetch most of the workspace settings information
     * because when updating payment method you can now reactivate
     * a old workspace with a canceled subscription or validate a card
     * that had a pending 3D secure challenge.
     *
     * With these interactions the data returned by the API might be
     * different in a lot of parameters like the number of seats or the
     * plan renewal date in order, and instead of manually updating the
     * cache with this values we opted by refetching the queries again
     * preventing error prone information
     */

    if (IGNORE_UI_REFRESH_STATUS.includes(subscriptionStatus)) {
      /**
       * We can safely ignore these status because most of the workspaces
       * information won't change
       */
      return
    }

    /**
     * Once we have edited the Payment Details and received successful response,
     * the pendingSCAToken still gets returned in most cases. The Setup Intent
     * on stripe is not guaranteed to be closed before we receive the response from
     * the stripe JS libraries, therefore, we should manually hide the Verify Button
     * to avoid confusion.
     */

    setHideVerifyButton(true)
    await onWorkspaceSettingRefresh()
  }

  const renderEditCCButton =
    isWorkspaceSubscriptionActive(subscriptionStatus) ||
    (!isWorkspaceSubscriptionActive(subscriptionStatus) && isSsoActive)

  // Prepare the UI for each Payment method type
  let paymentContent = null
  if (
    paymentDetails &&
    isPaymentMethodCard(paymentDetails) &&
    pendingSCAToken &&
    !hideVerifyButton &&
    renderEditCCButton
  ) {
    const { id, last4Digits, brand, expiration } = paymentDetails
    paymentContent = (
      <>
        <StyledBillingInfoCell>
          <PaymentLogoWrapper>
            <PaymentLogo brand={brand} />
            <LastDigits>&nbsp; ···· {last4Digits}</LastDigits> Expires{' '}
            {expiration.month}/{String(expiration.year).slice(-2)}
          </PaymentLogoWrapper>
          <SCAWarningPill color="red" variant="primary">
            Verify card to complete payment
          </SCAWarningPill>
        </StyledBillingInfoCell>
        <BillingInfoTable.Cell>
          <StyledButton
            size="40"
            loading={scaButtonLoading}
            variant="primary"
            data-test="sca-verify-button"
            onClick={async () => {
              if (!stripe) {
                return
              }
              setScaButtonLoading(true)
              const response = await confirmPendingSetupIntent(
                stripe,
                pendingSCAToken,
                id
              )

              if (response && !response.error) {
                setHideVerifyButton(true)
                await onPaymentUpdated?.()
              } else {
                setScaButtonLoading(false)
                showToast(
                  'Unable to complete 3D Secure authentication. Try again, or use a different credit card',
                  'negative'
                )
                Sentry.withScope(scope => {
                  response &&
                    scope.setExtra('error', JSON.stringify(response.error))
                  Sentry.captureException('Error verifying card or payment')
                })
              }
            }}
          >
            Verify Card&hellip;
          </StyledButton>
          <StyledButton
            size="40"
            data-test="credit-card-button"
            onClick={() =>
              showModal(EditPaymentMethodModal, {
                customerId,
                onPaymentUpdated,
              })
            }
          >
            Edit&hellip;
          </StyledButton>
        </BillingInfoTable.Cell>
      </>
    )
  } else if (paymentDetails && isPaymentMethodCard(paymentDetails)) {
    const { last4Digits, brand, expiration } = paymentDetails
    paymentContent = (
      <>
        <BillingInfoTable.Cell>
          <PaymentLogoWrapper>
            <PaymentLogo brand={brand} />
            &nbsp; ···· {last4Digits} · Expires {expiration.month}/
            {String(expiration.year).slice(-2)}
          </PaymentLogoWrapper>
        </BillingInfoTable.Cell>
        <BillingInfoTable.Cell>
          {renderEditCCButton && (
            <StyledButton
              size="32"
              data-test="credit-card-button"
              onClick={() =>
                showModal(EditPaymentMethodModal, {
                  customerId,
                  onPaymentUpdated,
                })
              }
            >
              Edit&hellip;
            </StyledButton>
          )}
        </BillingInfoTable.Cell>
      </>
    )
  } else if (paymentDetails && isPaymentMethodInvoice(paymentDetails)) {
    paymentContent = (
      <BillingInfoTable.Cell>
        <PaymentLogoWrapper>
          <PaymentLogo brand="invoice" /> &nbsp;&nbsp;
          <strong>Invoice Payment</strong>
        </PaymentLogoWrapper>
      </BillingInfoTable.Cell>
    )
  }

  return (
    <PaymentInformationWrapper>
      <Panel.Title>
        <PaymentInformationTitleContainer>
          <div>Payment Details</div>
          <StripeSecureInfo>
            All transactions are secure and encrypted by&nbsp;
            <a
              target="_blank"
              href="https://stripe.com"
              rel="noopener noreferrer"
            >
              Stripe
            </a>
            <LockIcon />
          </StripeSecureInfo>
        </PaymentInformationTitleContainer>
      </Panel.Title>

      <Panel.Body>
        {credits !== 0 && (
          <BillingInfoTable.Row>
            <BillingInfoTable.HeadingCell>
              Credit Balance
            </BillingInfoTable.HeadingCell>
            <BillingInfoTable.Cell>
              <CreditBalanceTextContainer>
                <CreditLogoAmountContainer>
                  <PaymentLogo brand="credit" />
                  {formatPriceShort(credits)}
                </CreditLogoAmountContainer>
                <div>
                  We’ll always use your available credit balance before charging
                  your card.
                </div>
              </CreditBalanceTextContainer>
            </BillingInfoTable.Cell>
          </BillingInfoTable.Row>
        )}
        <BillingInfoTable>
          {paymentContent && (
            <BillingInfoTable.Row alignItems="center">
              <BillingInfoTable.HeadingCell>
                Payment Method
              </BillingInfoTable.HeadingCell>
              {paymentContent}
            </BillingInfoTable.Row>
          )}

          {paymentDetails && paymentDetails.type !== 'NONE' && (
            <BillingInfoTable.Row>
              <BillingInfoTable.HeadingCell>
                Billing Details
              </BillingInfoTable.HeadingCell>
              <BillingInfoTable.Cell>
                <List>
                  {billingItems.map((item, index) => (
                    <BillingDetailsItem key={index} index={index} item={item} />
                  ))}
                  {taxId && <VatStatus taxId={taxId} />}
                </List>
              </BillingInfoTable.Cell>
              {isWorkspaceSubscriptionActive(subscriptionStatus) &&
                paymentDetails &&
                isPaymentMethodCard(paymentDetails) && (
                  <BillingInfoTable.Cell>
                    <StyledButton
                      size="32"
                      data-test="billing-address-button"
                      onClick={() =>
                        showModal(EditAddressDetailsModal, {
                          customerId,
                          billingDetails,
                        })
                      }
                    >
                      Edit&hellip;
                    </StyledButton>
                  </BillingInfoTable.Cell>
                )}
            </BillingInfoTable.Row>
          )}
        </BillingInfoTable>
      </Panel.Body>
    </PaymentInformationWrapper>
  )
}

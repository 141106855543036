import { __awaiter } from 'tslib';
import { isMD5 } from '@sketch/utils';

const getAssetsVersion = () => __awaiter(void 0, void 0, void 0, function* () {
    const fileURL = '/static/assets-version.txt';
    let assetsVersion = null;
    try {
        const response = yield fetch(fileURL, {
            method: 'get',
            headers: {
                'Content-Type': 'text/plain',
            },
        });
        if (response.status < 300) {
            const responseText = yield response.text();
            const trimmedResponse = responseText ? responseText.trim() : '';
            // Cloud can return non existing pages as 200 with a `We cannot find the page you’re looking for.` content
            assetsVersion = isMD5(trimmedResponse) ? trimmedResponse : null;
        }
    }
    catch (error) {
        assetsVersion = null;
    }
    return assetsVersion;
});

export { getAssetsVersion };

import { useRef, useEffect } from 'react';

const MIN_TOUCH_OFFSET_X = 30;
const MAX_TOUCH_OFFSET_Y = 30;
const useDetectTouchSwipe = (ref, callback) => {
    const initialTouch = useRef(null);
    useEffect(() => {
        if (!ref.current) {
            return;
        }
        const elementRef = ref.current;
        const onTouchStart = (event) => {
            if (event.touches.length === 1) {
                initialTouch.current = event.touches[0];
            }
        };
        const onTouchEnd = (event) => {
            const finalTouch = event.changedTouches[0];
            if (!initialTouch.current || !finalTouch) {
                // If no touches there's no need to do event
                return;
            }
            if (Math.abs(initialTouch.current.clientY - finalTouch.clientY) >
                MAX_TOUCH_OFFSET_Y) {
                // Prevent the movement to execute if the user is for example scrolling
                return;
            }
            if (finalTouch.clientX >
                initialTouch.current.clientX + MIN_TOUCH_OFFSET_X) {
                // Left swipe
                callback('left-swipe');
            }
            else if (finalTouch.clientX <
                initialTouch.current.clientX - MIN_TOUCH_OFFSET_X) {
                // Right swipe
                callback('right-swipe');
            }
        };
        elementRef.addEventListener('touchstart', onTouchStart);
        elementRef.addEventListener('touchend', onTouchEnd);
        return () => {
            elementRef.removeEventListener('touchstart', onTouchStart);
            elementRef.removeEventListener('touchend', onTouchEnd);
        };
    }, [callback, ref]);
};

export { useDetectTouchSwipe };

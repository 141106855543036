import { dataIdFromObject } from '@sketch/graphql-cache';
import { noop } from '@sketch/utils';
import { updateEntityFromCache } from './apolloCache.js';

function createUpdater(scopeValidator, decrementTotalCount = true) {
    const updater = (value, additionalParameters) => {
        const { type, index, previousValues } = additionalParameters;
        const isInScope = previousValues.find(({ key }) => !!key && scopeValidator(key));
        if (isInScope && type === 'array' && index != null) {
            const array = value;
            const [{ key: lastKey, value: previousValue }] = previousValues.slice(-1);
            array.splice(index, 1);
            if (lastKey === 'entries' && decrementTotalCount) {
                // Get the cache root node
                const { value: cache } = previousValues[0];
                // Get the pagination object
                const pagination = cache[previousValue.meta.id];
                pagination.totalCount--;
            }
        }
    };
    return updater;
}
function removeFromPaginated(cache, toBeDelete, scopeValidator, decrementTotalCount = true) {
    if (typeof scopeValidator === 'function') {
        return updateEntityFromCache(cache, toBeDelete, createUpdater(scopeValidator, decrementTotalCount));
    }
    const parentCacheId = dataIdFromObject(scopeValidator);
    if (!parentCacheId) {
        return noop;
    }
    return updateEntityFromCache(cache, toBeDelete, createUpdater(key => key.includes(parentCacheId)));
}

export { createUpdater, removeFromPaginated };

import { jsxs, jsx } from 'react/jsx-runtime';
import { useIsNodeContentTruncated } from '@sketch/utils';
import '../../Breakpoint/index.js';
import '../../Truncate/index.js';
import { isInvitationRequested, badgeForMemberRole } from '../utils.js';
import { getNameOrEmailFromMembership } from './utils.js';
import { TooltipWithTruncatedContent, StyledTruncate, Subtitle } from './BaseListRow.styles.js';
import { Truncate } from '../../Truncate/Truncate.js';
import { Breakpoint } from '../../Breakpoint/Breakpoint.js';

const BaseListRowInfo = ({ title, subtitle, role, inviteStatus, isCurrentUser, }) => {
    const [isSubtitleTruncated, subtitleRef,] = useIsNodeContentTruncated();
    const [isTitleTruncated, titleRef,] = useIsNodeContentTruncated();
    const content = getNameOrEmailFromMembership(isCurrentUser, title, subtitle);
    const differentTitleAndSubtitle = content !== subtitle;
    const invitationRequested = inviteStatus
        ? isInvitationRequested(inviteStatus)
        : undefined;
    return (jsxs(Truncate, { children: [jsxs(TooltipWithTruncatedContent, Object.assign({ placement: "top", spacing: "10px", content: title, disabled: !isTitleTruncated }, { children: [jsx(StyledTruncate, Object.assign({ ref: titleRef, invitationRequested: invitationRequested }, { children: content })), jsx(Breakpoint, Object.assign({ on: "xs" }, { children: badgeForMemberRole({
                            role,
                            inviteStatus,
                        }) }))] })), subtitle && differentTitleAndSubtitle ? (jsx(TooltipWithTruncatedContent, Object.assign({ placement: "top", spacing: "10px", content: subtitle, disabled: !isSubtitleTruncated }, { children: jsx(Subtitle, Object.assign({ ref: subtitleRef }, { children: subtitle })) }))) : null] }));
};

export { BaseListRowInfo };

import { __rest } from 'tslib';
import { jsx, jsxs } from 'react/jsx-runtime';
import { useRef, useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from '@sketch/utils';
import './Content/index.js';
import '../Popper/index.js';
import uniqueId from 'lodash.uniqueid';
import { Popper } from '../Popper/Popper.js';
import { PopoverContent } from './Content/PopoverContent.js';

const accessibility = (visible) => ({
    'aria-haspopup': 'true',
    'aria-expanded': `${visible}`,
});
/**
 *
 * The Popover can be used instead of the Tooltip when you want more control in styling / content you want to display.
 *
 * It can have different placements, you can check all of them via the Properties table controls on Storybook.
 *
 */
const UnstyledPopover = (_a) => {
    var { popup, children, contentPadding = '0px', visible = false, spacing = '0px', fillContent = false, onClickOutside, usePortal = true, arrowComponent: Arrow } = _a, restProps = __rest(_a, ["popup", "children", "contentPadding", "visible", "spacing", "fillContent", "onClickOutside", "usePortal", "arrowComponent"]);
    const contentRef = useRef(null);
    const popoverRef = useRef(null);
    const updateRef = useRef(null);
    const [popoverId] = useState(uniqueId('popover'));
    useLayoutEffect(() => {
        var _a;
        (_a = updateRef.current) === null || _a === void 0 ? void 0 : _a.call(updateRef);
    }, [popup]);
    useOnClickOutside(contentRef, e => {
        // setTimeOut is needed in case we nest dropdowns, to delay this behavior
        // until the next tick (so we allow to the nested option item to be triggered)
        setTimeout(() => {
            onClickOutside === null || onClickOutside === void 0 ? void 0 : onClickOutside(e);
        }, 0);
    }, { includeRefs: [popoverRef] });
    return (jsx(Popper, Object.assign({ spacing: spacing, visible: visible, usePortal: usePortal }, accessibility(visible), restProps, { popup: (_a) => {
            var { rect, arrowRef, arrowStyle } = _a, popperProps = __rest(_a, ["rect", "arrowRef", "arrowStyle"]);
            const update = updateRef;
            update.current = popperProps.forceUpdate;
            return (jsx("span", Object.assign({ ref: popoverRef }, { children: jsxs(PopoverContent, Object.assign({ id: popoverId, fillContent: fillContent, fillWidth: rect ? `${rect.width}px` : undefined, contentPadding: contentPadding, "data-testid": "popover-content", role: "dialog" }, popperProps, { children: [Arrow && jsx(Arrow, { ref: arrowRef, style: arrowStyle }), popup] })) })));
        } }, { children: jsx("div", Object.assign({ ref: contentRef, "aria-haspopup": "dialog", "aria-expanded": visible, "aria-controls": popoverId }, { children: children })) })));
};
const Popover = styled(UnstyledPopover) ``;

export { Popover };

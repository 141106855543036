import { jsx } from 'react/jsx-runtime';
import { useTheme } from 'styled-components';
import { useForTablet } from '@sketch/components';
import OrangeVariant from '@sketch/icons/sidebar-orange.png';
import OrangeVariant2x from '@sketch/icons/sidebar-orange@2x.png';
import OrangeDarkVariant from '@sketch/icons/sidebar-orange-dark.png';
import OrangeDarkVariant2x from '@sketch/icons/sidebar-orange-dark@2x.png';
import { Image } from './Sidebar.styles.js';

const Sidebar = ({ variant }) => {
    const isTabletAndBigger = useForTablet();
    const theme = useTheme();
    const getVariantImage = () => {
        if (theme._name === 'dark') {
            switch (variant) {
                case 'orange': {
                    return {
                        base: OrangeDarkVariant,
                        size2: OrangeDarkVariant2x,
                    };
                }
                default: {
                    return null;
                }
            }
        }
        switch (variant) {
            case 'orange': {
                return {
                    base: OrangeVariant,
                    size2: OrangeVariant2x,
                };
            }
            default: {
                return null;
            }
        }
    };
    const variantImage = getVariantImage();
    if (!variantImage || !isTabletAndBigger)
        return null;
    return (jsx(Image, { alt: `${variant}-image`, src: variantImage.base, srcSet: `${variantImage.size2} 2x` }));
};

export { Sidebar as default };

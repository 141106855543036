import React, { useMemo, useState } from 'react'
import { Portal } from 'react-portal'
import { useThemeContext } from '@sketch/global-styles'
import { useGetWorkspacesQuery } from '@sketch/gql-types'
import {
  Separator,
  Dropdown,
  Checkbox,
  Box,
  Popover,
  useForTablet,
  Button,
  Modal,
  Tooltip,
} from '@sketch/components'
import {
  Wrapper,
  OptionsContent,
  StyledClockwiseArrowCircle,
  ResetButton,
  DatePicker,
  DatesWrapper,
  FakeBackdrop,
  FakeModal,
  FilterIcon,
  FilterButton,
  MobileWrapper,
} from './NotificationSearch.styles'
import { LocationFilter } from './LocationFilter'
import { useNotificationSearchContext } from '../../../context/NotificationSearchContext'
import { Searchbar as SearchBarOld } from './SearchbarOld/Searchbar'
import { Searchbar as SearchBarNew } from './Searchbar/Searchbar'
import { SearchOptionsObject } from './types'
import { useFlag } from '@sketch/modules-common'

// Check if any of the options are active so we can toggle the active state
// for the filters button
const checkForActiveOptions = (options: SearchOptionsObject) =>
  Object.values(options).some(option => {
    // For array options we check their length
    if (Array.isArray(option)) {
      return !!option.length
    }

    // Everything else we cast to boolean
    return !!option
  })

type OptionKey = keyof SearchOptionsObject

interface SearchMenuContentProps {
  workspaceIds: string[]
  isMobile?: boolean
}

const SearchMenuContent = ({
  workspaceIds,
  isMobile,
}: SearchMenuContentProps) => {
  const { options, setOptions, resetAll } = useNotificationSearchContext()
  const { isDarkMode } = useThemeContext()

  const handleOptionUpdate = (option: OptionKey) =>
    setOptions({ ...options, [option]: !options[option] })

  const handleDateUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
    dateType: 'from' | 'to'
  ) => setOptions({ ...options, [dateType]: e.target.value })

  return (
    <OptionsContent isMobile={isMobile}>
      <form
        onSubmit={e => {
          e.preventDefault()
          setOptions(options)
        }}
      >
        <Dropdown.Header>Only show</Dropdown.Header>
        <Box pl={5} pr={5} pb={4}>
          <ResetButton onClick={resetAll}>
            <StyledClockwiseArrowCircle />
            Reset
          </ResetButton>
          <Checkbox
            autoFocus
            value={options.comments ? 1 : 0}
            name="comments"
            checked={options.comments}
            data-testid="updates-comments"
            label="Comments"
            onChange={() => handleOptionUpdate('comments')}
          />
          <Checkbox
            value={options.replies ? 1 : 0}
            name="replies"
            checked={options.replies}
            data-testid="updates-replies"
            label="Replies"
            onChange={() => handleOptionUpdate('replies')}
          />
          <Checkbox
            value={options.mentions ? 1 : 0}
            name="mentions"
            checked={options.mentions}
            data-testid="updates-mentions"
            label="Mentions"
            onChange={() => handleOptionUpdate('mentions')}
          />
          <Checkbox
            value={options.downloadableAssets ? 1 : 0}
            name="downloadableAssets"
            checked={options.downloadableAssets}
            data-testid="updates-downloadableAssets"
            label="Downloadable Assets"
            onChange={() => handleOptionUpdate('downloadableAssets')}
          />
          <Checkbox
            value={options.starredUpdates ? 1 : 0}
            name="starredUpdates"
            checked={options.starredUpdates}
            data-testid="updates-starredUpdates"
            label="Starred Updates"
            onChange={() => handleOptionUpdate('starredUpdates')}
          />
        </Box>
        <Separator />
        <Dropdown.Header>Between</Dropdown.Header>
        <DatesWrapper>
          <DatePicker
            type="date"
            placeholder="From"
            aria-label="from"
            isDarkMode={!!isDarkMode}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleDateUpdate(e, 'from')
            }
          />
          <span>and</span>
          <DatePicker
            type="date"
            placeholder="To"
            aria-label="to"
            isDarkMode={!!isDarkMode}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleDateUpdate(e, 'to')
            }
          />
        </DatesWrapper>
        <Separator />
        <Dropdown.Header>Location</Dropdown.Header>
        <Box pl={5} pr={5} pb={4}>
          <LocationFilter workspaceIds={workspaceIds} />
        </Box>
      </form>
    </OptionsContent>
  )
}

const OptionsMobileContainer: React.FC<{
  onClose: () => void
  optionsVisible: boolean
}> = ({ onClose, children, optionsVisible }) => (
  <Portal>
    {/* had to fake a modal because of the search context */}
    <FakeModal isVisible={optionsVisible}>
      {children}
      <Modal.Footer>
        <Button buttonStyle="primary-32" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </FakeModal>
    <FakeBackdrop onClick={onClose} isVisible={optionsVisible} />
  </Portal>
)

export const NotificationSearch: React.FC<{
  disabled?: boolean
}> = ({ disabled }) => {
  // TODO: Remove FF "ui-refresh-24" when its released
  // https://github.com/orgs/sketch-hq/projects/326/views/1?pane=issue&itemId=65739330
  const isRefreshedUi = useFlag('ui-refresh-24')
  const { options } = useNotificationSearchContext()

  const hasOptionsEnabled = useMemo(() => checkForActiveOptions(options), [
    options,
  ])

  const Searchbar = isRefreshedUi ? SearchBarNew : SearchBarOld

  const [optionsVisible, setOptionsVisible] = useState(false)
  const isTabletAndBigger = useForTablet()

  const { data } = useGetWorkspacesQuery()
  const workspaces = data?.me?.workspaces || []
  const workspaceIds = workspaces.map(workspace => workspace.identifier)

  const handleCloseOptions = () => setOptionsVisible(false)
  const handleFilterClick = () => setOptionsVisible(prev => !prev)

  if (!isTabletAndBigger) {
    return (
      <>
        <MobileWrapper>
          <Tooltip
            content="Options"
            placement="bottom-start"
            visible={isTabletAndBigger}
          >
            <FilterButton
              data-testid="filter-dropdown"
              onClick={handleFilterClick}
              $isActive={optionsVisible || hasOptionsEnabled}
            >
              <FilterIcon />
            </FilterButton>
          </Tooltip>
          <Searchbar disabled={disabled} />
        </MobileWrapper>
        <OptionsMobileContainer
          onClose={handleCloseOptions}
          optionsVisible={optionsVisible}
        >
          <SearchMenuContent isMobile workspaceIds={workspaceIds} />
        </OptionsMobileContainer>
      </>
    )
  }

  return (
    <Wrapper>
      <Popover
        visible={optionsVisible}
        placement="bottom-start"
        onClickOutside={handleCloseOptions}
        popup={<SearchMenuContent workspaceIds={workspaceIds} />}
      >
        <Tooltip content="Options" placement="bottom" disabled={optionsVisible}>
          <FilterButton
            data-testid="filter-dropdown"
            onClick={handleFilterClick}
            $isActive={optionsVisible || hasOptionsEnabled}
          >
            <FilterIcon />
          </FilterButton>
        </Tooltip>
      </Popover>
      <Searchbar disabled={disabled} />
    </Wrapper>
  )
}

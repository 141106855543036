import React, { useEffect, useMemo } from 'react'
import { useTrackEvent } from '@sketch/modules-common'

import CommunityListingHeader from '../../components/CommunityListingHeader'
import CommunityPublicationGrid from '../../components/CommunityPublicationGrid'
import CommunityWrapper from '../../components/CommunityWrapper'

const DEFAULT_MODIFIERS = [
  'PERIOD' as const,
  'SORT' as const,
  'DOCUMENT_TYPE' as const,
]

interface CommunitySearchProps {
  search: string
}

const CommunitySearchPage = ({ search: searchParam }: CommunitySearchProps) => {
  const searchPreferences = useMemo(() => ({ search: searchParam }), [
    searchParam,
  ])

  // Analytics
  const { trackEvent } = useTrackEvent()

  useEffect(() => {
    trackEvent('COMMUNITY - Search Page', {
      type: 'load',
    })
  }, [trackEvent])

  return (
    <CommunityWrapper>
      <CommunityListingHeader
        title={`Search results for “${searchParam}”`}
        modifiers={DEFAULT_MODIFIERS}
      />
      <CommunityPublicationGrid
        analyticsId="search-page"
        preferences={searchPreferences}
      />
    </CommunityWrapper>
  )
}

export default CommunitySearchPage

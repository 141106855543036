import { __awaiter } from 'tslib';
import produce from 'immer';
import { get, set } from './personalTokensCache.js';
import { usePersonalTokenCreateMutation } from '@sketch/gql-types';

const useCreatePersonalToken = (props) => {
    const { onCompleted } = props;
    const [mutation] = usePersonalTokenCreateMutation({
        onError: 'show-toast',
        onCompleted: data => onCompleted(data.personalTokenCreate),
    });
    return (variables) => __awaiter(void 0, void 0, void 0, function* () {
        return yield mutation({
            variables,
            update: (cache, { data, errors }) => {
                if (!data || (errors && errors.length > 0))
                    return;
                const me = get(cache);
                if (!me)
                    return;
                const personalToken = data.personalTokenCreate.personalToken;
                const updatedMe = produce(me, user => {
                    user.personalTokens.unshift(personalToken);
                });
                set(cache, updatedMe);
            },
        });
    });
};

export { useCreatePersonalToken };

import React, { FC, useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { captureException } from '@sentry/browser'
import produce from 'immer'

import { loadMore } from '@sketch/components'
import {
  NotificationCreatedSubscription,
  NotificationCreatedDocument,
  useGetNotificationsQuery,
  GetNotificationsDocument,
  useNotificationMarkAllAsSeenMutation,
  useNotificationMarkAllAsReadMutation,
  useNotificationMarkAsReadMutation,
  GetUserNotificationsCountDocument,
} from '@sketch/gql-types'
import {
  notificationMarkAllAsReadOptimisticResponse,
  getNotificationsQueryOptions,
} from '@sketch/user'
import { setHasUnreadNotificationsToZero } from './utils'
import NotificationsList from './NotificationsList'
import { ErrorHandler } from '@sketch/tracing'
import NotificationsErrorBoundary, {
  NotificationsErrorDisplay,
} from './NotificationsErrorBoundary'
import { ParsedError } from '@sketch/graphql-apollo'

/**
 * COMPONENT
 */
const NotificationsListWithData: FC = () => {
  const client = useApolloClient()
  const [error, setError] = useState<ParsedError | null>(null)

  const {
    data,
    fetchMore,
    subscribeToMore,
    loading,
    error: networkError,
  } = useGetNotificationsQuery(getNotificationsQueryOptions)

  if (networkError) {
    // This error will be rendered on "NotificationsErrorBoundary"
    throw networkError
  }

  const refetchNotifications = () => {
    client.query({
      query: GetNotificationsDocument,
      ...getNotificationsQueryOptions,
    })
  }

  const notificationsEntriesPath = ['me', 'notifications', 'entries']
  const notificationsEntries = data?.me.notifications?.entries
  const meta = data?.me.notifications?.meta
  const userId = data?.me?.identifier

  // Mutations
  const [markAllNotificationAsSeen] = useNotificationMarkAllAsSeenMutation({
    redirectErrors: true,
    UNSAFE_ignoreResults: true,
    update: () => userId && setHasUnreadNotificationsToZero(client, userId),
    onError: setError,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GetUserNotificationsCountDocument }],
  })

  const [markAllNotificationsAsRead] = useNotificationMarkAllAsReadMutation({
    redirectErrors: true,
    onError: setError,
    optimisticResponse: {
      __typename: 'RootMutationType',
      ...notificationMarkAllAsReadOptimisticResponse,
    },
    UNSAFE_ignoreResults: true,
    refetchQueries: [{ query: GetNotificationsDocument }],
    awaitRefetchQueries: true,
  })
  const [markNotificationAsRead] = useNotificationMarkAsReadMutation({
    redirectErrors: true,
    onError: setError,
  })

  useEffect(() => {
    return () => {
      // Ensure up-to-date data by refetching notifications when leaving
      // Updates
      refetchNotifications()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    // TODO: review the error display UI https://github.com/sketch-hq/Cloud/issues/9314
    return <NotificationsErrorDisplay />
  }

  return (
    <NotificationsList
      loading={loading}
      entries={notificationsEntries}
      markAllNotificationAsSeen={markAllNotificationAsSeen}
      markAllNotificationsAsRead={markAllNotificationsAsRead}
      markNotificationAsRead={notificationId => {
        markNotificationAsRead({
          variables: { id: notificationId },
          optimisticResponse: {
            __typename: 'RootMutationType',
            notificationMarkAsRead: {
              __typename: 'NotificationMarkAsReadPayload',
              successful: true,
              notification: {
                __typename: 'CommentNotification',
                identifier: notificationId,
                isRead: true,
              },
              errors: [],
            },
          },
        })
      }}
      loadMore={loadMore(fetchMore, meta?.after!, notificationsEntriesPath)}
      subscribeToMore={() =>
        subscribeToMore<NotificationCreatedSubscription>({
          document: NotificationCreatedDocument,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData) return prev

            const newNotification = subscriptionData.data.notificationCreated
            if (!newNotification) {
              ErrorHandler.shouldNeverHappen(
                'There should be always a notification on notificationCreated payload'
              )
              return prev
            }

            return produce(prev, draftState => {
              draftState.me.notifications?.entries.unshift(newNotification)
            })
          },
          onError: captureException,
        })
      }
      hasUnreadNotifications={data?.me?.hasUnreadNotifications || false}
    />
  )
}

const NotificationsListWithDataGuard: FC = () => {
  // TODO: review the error display UI https://github.com/sketch-hq/Cloud/issues/9314
  return (
    <NotificationsErrorBoundary>
      <NotificationsListWithData />
    </NotificationsErrorBoundary>
  )
}

export default NotificationsListWithDataGuard

import styled from 'styled-components'
import { AsyncButton, Skeleton, UnstyledList } from '@sketch/components'
import { MarkAsReadIcon } from 'modules/activity/components/Notifications/NotificationListItem.styles'

export const Wrapper = styled.div`
  flex: 1;
  max-width: 660px;
`
export const WrapperList = styled.div<{ $isRefreshedUi: boolean }>`
  flex: 1;
  max-width: 100%;

  ${UnstyledList} {
    li:nth-child(odd) {
      background-color: ${({ theme, $isRefreshedUi }) =>
        $isRefreshedUi
          ? theme.colors.background.secondary.A
          : theme.colors.background.secondary.B};
    }
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

export const MarkAllAsReadButton = styled(AsyncButton).attrs({ size: '32' })`
  margin-left: auto;
  margin-right: 8px;
`

export const StyledMarkAsReadIcon = styled(MarkAsReadIcon)`
  margin-right: 8px;
`

export const StyledSkeleton = styled(Skeleton)`
  margin-left: auto;
  margin-right: 8px;
`

export const SearchWrapper = styled.div`
  width: 328px;
  flex: 1 1 auto;
`

export const EmptyStateWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`

import { jsxs, jsx } from 'react/jsx-runtime';
import { useState, useCallback } from 'react';
import { useToast } from '@sketch/toasts';
import { useForLargerThanMobile, Modal, Button } from '@sketch/components';
import './components/index.js';
import { StyledOrderedBreadcrumbs } from './Setup2FAModal.styles.js';
import { useSetupMfaMutation, useConfirmMfaSetupMutation, GetInitialUserDocument } from '@sketch/gql-types';
import { AuthAppsStep } from './components/AuthAppsStep.js';
import { QRCodeStep } from './components/QRCodeStep.js';
import { CodesStep } from './components/CodesStep.js';

const BREADCRUMBS = [
    { content: 'Install App' },
    { content: 'Scan QR Code' },
    { content: 'Verify' },
];
const MOBILE_BREADCRUMBS = [
    { content: 'Install' },
    { content: 'Scan' },
    { content: 'Verify' },
];
const NEXT_BUTTON_COPY_PER_STEP = [
    'Scan QR Code',
    'Enter Verification Code',
    'Finish',
];
/**
 * Setup2FAModal
 *
 * This modal guides the user to install an authenticator application
 * and set up the Two-Factor Authentication.
 *
 * Used in Account Settings
 *
 */
const Setup2FAModal = ({ hideModal }) => {
    var _a;
    const { showToast } = useToast();
    const isForLargerThanMobile = useForLargerThanMobile();
    const [step, setStep] = useState(0);
    const [recoveryCodes, setRecoveryCodes] = useState([]);
    const [verficationError, setVerificationError] = useState();
    // Returns a secret used to generate QR Code in the next step
    const [setupMfa, { data, loading: isLoadingSetupMfa }] = useSetupMfaMutation({
        onCompleted: () => {
            nextStep();
        },
        onError: ({ message }) => {
            showToast(message, 'negative');
        },
    });
    // Validates the Authenticator code the user inputs
    const [confirmMfaSetup, { loading: isLoadingConfirmMfa },] = useConfirmMfaSetupMutation({
        onCompleted: ({ confirmMfaSetup }) => {
            setRecoveryCodes(confirmMfaSetup.recoveryCodes.entries);
            nextStep();
        },
        onError: ({ message }) => {
            setVerificationError(message);
            showToast(message, 'negative');
        },
        refetchQueries: [{ query: GetInitialUserDocument }],
        awaitRefetchQueries: true,
    });
    const nextStep = () => {
        setStep(prev => {
            if (prev >= BREADCRUMBS.length - 1) {
                return prev;
            }
            return prev + 1;
        });
    };
    const handleNextStep = () => {
        // First Step
        // Run setupMfa and get the secret from the BE
        if (step === 0) {
            setupMfa();
            return;
        }
        // Last Step
        // The modal should close in the last step if we have the recovery codes
        if (step === 2 && !!recoveryCodes.length) {
            hideModal();
            return;
        }
        nextStep();
    };
    const handleVerificationCodeFilled = useCallback((code) => {
        // Clear error
        setVerificationError(undefined);
        // Code from the Authenticator App
        confirmMfaSetup({ variables: { totp: code } });
    }, [confirmMfaSetup]);
    const showNextButton = recoveryCodes.length || step !== 2;
    const showCancelButton = !recoveryCodes.length;
    const otpAuthUri = (_a = data === null || data === void 0 ? void 0 : data.setupMfa) === null || _a === void 0 ? void 0 : _a.otpAuthUri;
    return (jsxs(Modal, Object.assign({ onCancel: hideModal }, { children: [jsxs(Modal.Body, { children: [jsx(StyledOrderedBreadcrumbs, { currentCrumb: step, crumbs: isForLargerThanMobile ? BREADCRUMBS : MOBILE_BREADCRUMBS, showAllInMobile: true }), step === 0 && jsx(AuthAppsStep, {}), step === 1 && jsx(QRCodeStep, { otpAuthUri: otpAuthUri }), step === 2 && (jsx(CodesStep, { onFilled: handleVerificationCodeFilled, recoveryCodes: recoveryCodes, error: verficationError }))] }), jsxs(Modal.Footer, { children: [showCancelButton && (jsx(Button, Object.assign({ onClick: hideModal, disabled: isLoadingSetupMfa, loading: isLoadingConfirmMfa, size: "40" }, { children: "Cancel" }))), showNextButton && (jsx(Button, Object.assign({ variant: "primary", onClick: handleNextStep, loading: isLoadingSetupMfa || isLoadingConfirmMfa, size: "40" }, { children: NEXT_BUTTON_COPY_PER_STEP[step] })))] })] })));
};

export { Setup2FAModal };

import React from 'react'

import { ReactComponent as CollectionIcon } from '@sketch/icons/square-stack-16'

// This component shares the same util as ProjectItem
import { getButtonProps } from 'modules/shares/components/ProjectItem/utils'

import { pluralize } from '@sketch/components'

import { useCollectionDropShares } from 'modules/collections/hooks'

import {
  DroppableTableWrapper,
  DroppableTableWrapperDragTooltip,
} from './CollectionItem.styles'

// This component shares styles with DocumentItem
import {
  ImageWrapper,
  Name,
  StatusIcon,
  TableCell,
  NameWrapper,
} from 'modules/shares/components/DocumentItem/DocumentItem.styles'

import { CollectionItemProps } from './types'

export const CollectionItemListView = (props: CollectionItemProps) => {
  const {
    className,
    collection,
    onClick,
    projectIdentifier,
    workspaceIdentifier,
    renderDropdown,
  } = props

  const { isDraggedOver = false, ...dropEvents } = useCollectionDropShares({
    collection,
    projectIdentifier,
    workspaceIdentifier,
  })

  const a11yProps = onClick ? getButtonProps(onClick) : {}
  const dropdownContent = renderDropdown?.()
  const totalCount = collection.shareCount
  const pluralized = pluralize('Document', 'Documents', totalCount ?? 0)
  const numberOfDocuments =
    totalCount !== 0 ? `${totalCount} ${pluralized}` : `No Documents`

  return (
    <DroppableTableWrapper
      $isDraggedOver={isDraggedOver}
      className={className}
      title={collection.name}
      {...dropEvents}
      {...a11yProps}
    >
      <TableCell>
        {isDraggedOver && (
          <DroppableTableWrapperDragTooltip>
            Add to collection
          </DroppableTableWrapperDragTooltip>
        )}
        <NameWrapper>
          <ImageWrapper title={collection.name}>
            <StatusIcon
              as={CollectionIcon}
              aria-label={`Collection ${collection.name}`}
            />
          </ImageWrapper>
          <Name>{collection.name}</Name>
        </NameWrapper>
      </TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell>{numberOfDocuments}</TableCell>
      <TableCell>{dropdownContent}</TableCell>
    </DroppableTableWrapper>
  )
}

import { __awaiter, __rest } from 'tslib';
import { jsx, Fragment } from 'react/jsx-runtime';
import { useRef, useState, useCallback, useEffect } from 'react';

const DEFAULT_TIMEOUT = 3000;
const useQueryRetry = (result, props) => {
    const { retryMax = 5, retryIf = () => false, retryKey = ({ variables }) => JSON.stringify({ variables }), retryTimeout = DEFAULT_TIMEOUT, } = props;
    const retryCount = useRef(0);
    const [retryState, setRetryState] = useState('idle');
    const isRetryPending = useRef(false);
    const timerHandle = useRef(undefined);
    const startRetrying = useCallback((refetch) => {
        setRetryState('retrying');
        isRetryPending.current = true;
        const cleanup = () => {
            isRetryPending.current = false;
            if (timerHandle.current) {
                try {
                    window.clearTimeout(timerHandle.current);
                }
                finally {
                    timerHandle.current = undefined;
                }
            }
        };
        const invokeRefetch = () => __awaiter(void 0, void 0, void 0, function* () {
            retryCount.current++;
            let isDataValid;
            try {
                const result = yield refetch();
                const resultWithRetryInfo = Object.assign(Object.assign({}, result), { retryCount: retryCount.current, retryState });
                isDataValid = !retryIf(resultWithRetryInfo);
            }
            catch (_a) {
                isDataValid = false;
            }
            if (isDataValid) {
                setRetryState('success');
                cleanup();
            }
            else {
                if (retryCount.current < retryMax) {
                    timerHandle.current = window.setTimeout(invokeRefetch, retryTimeout);
                }
                else {
                    setRetryState('failed');
                    cleanup();
                }
            }
        });
        timerHandle.current = window.setTimeout(invokeRefetch, retryTimeout);
    }, [retryIf, retryMax, retryState, retryTimeout]);
    const cleanupDependency = retryKey && retryKey(result);
    useEffect(() => {
        return () => {
            const handle = timerHandle.current;
            retryCount.current = 0;
            setRetryState('idle');
            isRetryPending.current = false;
            if (handle) {
                window.clearTimeout(handle);
            }
            timerHandle.current = undefined;
        };
    }, [cleanupDependency]);
    const resultWithRetryInfo = Object.assign(Object.assign({}, result), { retryCount: retryCount.current, retryState });
    const isDataValid = !retryIf(resultWithRetryInfo);
    if (!result.loading &&
        !isDataValid &&
        !isRetryPending.current &&
        retryCount.current < retryMax) {
        startRetrying(result.refetch);
    }
    if (isDataValid && !result.loading && retryState !== 'success') {
        setRetryState('success');
    }
    return resultWithRetryInfo;
};
const QueryRetryComp = (props) => {
    const { children, result: originalResult } = props, retryProps = __rest(props, ["children", "result"]);
    const result = useQueryRetry(originalResult, retryProps);
    return (jsx(Fragment, { children: typeof children === 'function' && result
            ? children(Object.assign(Object.assign({}, result), retryProps))
            : null }));
};
/**
 * @deprecated, use useQueryRetry instead
 */
function withQueryRetry(
/*
 * seems that there is an issue with typings, propTypes are marked as required:
 * https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28249
 */
QueryComponent) {
    const QueryComp = QueryComponent;
    const RetryQuery = props => {
        const { children, retryMax = 5, retryIf = () => false, retryKey = ({ variables }) => JSON.stringify({ variables }), retryTimeout = DEFAULT_TIMEOUT } = props, rest = __rest(props, ["children", "retryMax", "retryIf", "retryKey", "retryTimeout"]);
        return (jsx(QueryComp, Object.assign({}, rest, { children: result => {
                return (jsx(QueryRetryComp, { retryIf: retryIf, retryMax: retryMax, retryKey: retryKey, retryTimeout: retryTimeout, 
                    // eslint-disable-next-line react/no-children-prop
                    children: children, result: result }));
            } })));
    };
    return RetryQuery;
}

export { useQueryRetry, withQueryRetry };

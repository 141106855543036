import { jsxs, jsx } from 'react/jsx-runtime';
import '../Footer/index.js';
import { Wrapper, HeaderContainer, Content, Main, FooterWrapper } from './IndexLayout.styles.js';
import { IndexLayoutHeader } from './IndexLayoutHeader.js';
import Sidebar from './Sidebar.js';
import { useCreateLayoutPortal, useLayoutOverrideProps } from '@sketch/utils';
import { Footer } from '../Footer/Footer.js';

const IndexLayout = (props) => {
    const [HeaderPortal, setPortalContainerRef] = useCreateLayoutPortal();
    const { getOverriddenProps, useOverrideLayoutProps, OverrideLayoutProps, } = useLayoutOverrideProps();
    const childrenProps = {
        HeaderPortal,
        useOverrideLayoutProps,
        OverrideLayoutProps,
    };
    const { className, disableLogoLink, isCreatingWorkspace, fullWidth, showFooter = true, noHeaderBorder, render, variant, maxContainerWidth, component: Component, } = getOverriddenProps(props);
    return (jsxs(Wrapper, Object.assign({ className: className }, { children: [jsx(IndexLayoutHeader, Object.assign({ noBorder: noHeaderBorder, disableLogoLink: disableLogoLink }, { children: jsx(HeaderContainer, { ref: ref => setPortalContainerRef(ref) }) })), variant && jsx(Sidebar, { variant: variant }), jsxs(Content, { children: [jsxs(Main, Object.assign({ isCreatingWorkspace: isCreatingWorkspace, fullWidth: fullWidth, maxContainerWidth: maxContainerWidth }, { children: [render && render(childrenProps), Component && jsx(Component, Object.assign({}, childrenProps))] })), showFooter && (jsx(FooterWrapper, { children: jsx(Footer, {}) }))] })] })));
};

export { IndexLayout };

import styled, { css } from 'styled-components'
import { light } from '@sketch/global-styles'

export type WorkspaceSidebarNoticeVariant = 'standard' | 'black'

export const WorkspaceSidebarNotice = styled.div``

export type WorkspaceSidebarNoticeContainerProps = {
  $variant: WorkspaceSidebarNoticeVariant
  $isDarkMode: boolean
  $isRefreshedUi: boolean
}

/**
 * Define css variables for descendants of the WorkspaceSidebarNoticeContainer to use to styles
 * the notice content based on the background color of the container.
 */
function setColorVariablesBasedOnVariant() {
  return css<WorkspaceSidebarNoticeContainerProps>`
    ${({ theme, $variant, $isDarkMode, $isRefreshedUi }) => {
      let arrowColor = theme.colors.border.B

      /*
        In this case we want the "Light" tertiary.B to actually make the background darker
        because our "Dark" tertiary.B is a very transparent white color which makes the background brighter
        */
      let backgroundColor = $isDarkMode
        ? light.colors.background.tertiary.B
        : $isRefreshedUi
        ? theme.colors.background.secondary.A
        : theme.colors.background.tertiary.D

      let textColor = theme.colors.foreground.secondary.A
      let linkColor = theme.colors.foreground.secondary.D
      let linkColorHover = theme.colors.foreground.secondary.C

      if ($variant === 'black') {
        backgroundColor = theme.colors.background.primary.B
        arrowColor = theme.colors.background.primary.C
        textColor = theme.colors.ui.tooltips.title
        linkColor = theme.colors.ui.tooltips.shortcutText
        linkColorHover = theme.colors.foreground.primary.A
      }

      return css`
        --notice__link-color: ${linkColor};
        --notice__link-color-hover: ${linkColorHover};
        --notice__text-color: ${textColor};
        --notice__arrow-color: ${arrowColor};
        --notice__background-color: ${backgroundColor};
      `
    }}
  `
}

export const WorkspaceSidebarNoticeContainer = styled.div<WorkspaceSidebarNoticeContainerProps>`
  ${setColorVariablesBasedOnVariant()}
  color: var(--notice__text-color);
  background-color: var(--notice__background-color);
  border-top: ${({ theme, $isRefreshedUi }) =>
    $isRefreshedUi ? `1px solid ${theme.colors.border.B}` : undefined};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.B};

  ${({ theme }) => `padding: ${theme.space[3]}px ${theme.space[4]}px;`}

  position: relative;

  /** Tooltip like rounded arrow */
  &::after {
    content: '';
    position: absolute;
    top: -5px;
    left: ${({ $isRefreshedUi }) => ($isRefreshedUi ? 33 : 23)}px;

    width: 9px;
    height: 9px;
    border-radius: 0 0 2px 0; /* stylelint-disable-line scales/radii */
    transform: rotate(-135deg);
    background: linear-gradient(
      -45deg,
      var(--notice__arrow-color) 50%,
      transparent 50%
    );
  }

  /*
   * In the black variant we want to add a black border covering the grey bottom border of the dropdown selector on top.
   * Without this we would see the grey border between the black container and the black arrow making it look like the arrow
   * is floating too far from its container.
   * This hack is also in the end much easier than having to lift the "variant" state to the dropdown selector for it to know if it needs
   * a grey border or not.
   */
  ${({ $variant }) =>
    $variant === 'black' &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        height: 1px;
        background-color: var(--notice__background-color);
        width: 100%;
      }
    `}
`

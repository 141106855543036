import { toggleTextWithChange, getChangeByEventKey } from './utils.js';
import './parsers/index.js';
import './tokenizers/index.js';
export { stripMarkdownParser } from './parsers/stripMarkdownParser.js';
export { color } from './tokenizers/color.js';
export { emoji } from './tokenizers/emoji.js';
export { link } from './tokenizers/link.js';
export { mention } from './tokenizers/mention.js';
export { newline } from './tokenizers/newline.js';

const markdown = {
    toggleTextWithChange,
    getChangeByEventKey,
};

export { markdown };

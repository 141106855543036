import React from 'react'

import { routes, useFlag } from '@sketch/modules-common'

import {
  getWorkspaceUserRolePermissions,
  workspaceSettingsPermissions,
  useCanSubscribeViaStripe,
} from 'modules/workspace/utils'

import { icons } from '../../icons'

import { useDiscover } from '@sketch/discover'

import { SkeletonSidebarList, SelectDropdownLinkItem } from '@sketch/components'

import HelpMenu from '../HelpMenu'
import DiscoverNewContentPill from '../DiscoverNewContentPill'
import SettingsMenu from '../SettingsMenu/SettingsMenu'

import { Wrapper } from './BottomMenu.styles'

import {
  useGetCustomerProviderQuery,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

import { useWorkspaceSettings } from 'modules/workspace/operations'

interface BottomMenuProps {
  workspace?: WorkspaceMinimalFragment
}

interface DerivedMenusProps {
  workspace: WorkspaceMinimalFragment
}

/**
 *
 * DerivedMenus
 *
 * These menus need some data to be computed and should
 * render a skeleton UI.
 *
 * They were separated from the "BottomMenu" because the "HelpMenu"
 * doesn't need a Skeleton UI and can be rendered straight away
 *
 */
const DerivedMenus: React.FC<DerivedMenusProps> = ({ workspace }) => {
  const { hasNewContent } = useDiscover()

  // Clear FF when its released
  // https://github.com/sketch-hq/Cloud/issues/16994
  const isCommunityCanvasFFEnabled = useFlag('community-canvas')

  const workspaceId = workspace.identifier
  // Some queries will be skipped if there is no customerId
  // so we default it to an empty string
  const customerId = workspace.customer?.identifier || ''

  const {
    isAdmin,
    canManageBillingSetting,
    isGuest,
  } = getWorkspaceUserRolePermissions(workspace)

  const customerProvider = useGetCustomerProviderQuery({
    variables: { customerId },
    skip: !(canManageBillingSetting && customerId),
  })

  const {
    data: { customer },
    loading: isWorkspaceSettingsLoading,
  } = useWorkspaceSettings(workspace)

  const { source } = useCanSubscribeViaStripe(
    workspace.identifier,
    workspace.customer?.identifier
  )

  const loading = customerProvider.loading || isWorkspaceSettingsLoading

  if (loading) {
    return <SkeletonSidebarList />
  }

  const {
    showWorkspaceSettingsBilling: showBilling,
    showWorkspaceSettingsGeneral: showGeneral,
    showWorkspaceSettingsPeople: showPeople,
    showWorkspacePermissionGroup: showPermissionGroup,
    showDiscover,
    showCommunity,
    showWorkspaceSettingsSSO,
  } = workspaceSettingsPermissions({
    workspace,
    customerProvider: customerProvider.data,
    customer,
    source,
  })

  const canRenderSettingsMenu = !isGuest
  const hasPeopleMenuOnly =
    !showBilling && !showGeneral && canRenderSettingsMenu

  const settingsMenuPermissions = {
    isAdmin,
    showWorkspaceSettingsBilling: showBilling,
    showWorkspaceSettingsGeneral: showGeneral,
    showWorkspaceSettingsPeople: showPeople,
    showWorkspacePermissionGroup: showPermissionGroup,
    showWorkspaceSettingsSSO,
  }

  return (
    <>
      {showDiscover && (
        <SelectDropdownLinkItem
          icon={icons.CompassIcon}
          text="Discover"
          to={routes.WORKSPACE_DISCOVER.create({ workspaceId })}
          additional={hasNewContent && <DiscoverNewContentPill />}
        />
      )}

      {isCommunityCanvasFFEnabled && showCommunity && (
        <SelectDropdownLinkItem
          icon={icons.CommunityIcon}
          text="Community"
          to={routes.COMMUNITY_CANVAS.create({})}
        />
      )}

      {hasPeopleMenuOnly ? (
        <SelectDropdownLinkItem
          icon={icons.EmailIcon}
          text="People"
          to={routes.WORKSPACE_SETTINGS_PEOPLE.create({ workspaceId })}
        />
      ) : (
        canRenderSettingsMenu && (
          <SettingsMenu
            workspace={workspace}
            permissions={settingsMenuPermissions}
          />
        )
      )}
    </>
  )
}

const BottomMenu: React.FC<BottomMenuProps> = ({ workspace }) => {
  // TODO: Remove FF "ui-refresh-24" when its released
  // https://github.com/orgs/sketch-hq/projects/326/views/1?pane=issue&itemId=65739330
  const isRefreshedUi = useFlag('ui-refresh-24')

  // Clear FF when its released
  // https://github.com/sketch-hq/Cloud/issues/16994
  const isCommunityCanvasFFEnabled = useFlag('community-canvas')

  const { hasNewContent } = useDiscover()

  const { isFinance, isPartner } = getWorkspaceUserRolePermissions(workspace)
  const isFinanceOrPartner = isFinance || isPartner

  return (
    <Wrapper data-testid="bottom-menu">
      {!isRefreshedUi && workspace && <DerivedMenus workspace={workspace} />}

      {isRefreshedUi && !isFinanceOrPartner && workspace && (
        <SelectDropdownLinkItem
          icon={icons.CompassIcon}
          text="Discover"
          to={routes.WORKSPACE_DISCOVER.create({
            workspaceId: workspace.identifier,
          })}
          additional={hasNewContent && <DiscoverNewContentPill />}
        />
      )}

      {isRefreshedUi && isCommunityCanvasFFEnabled && !isFinanceOrPartner && (
        <SelectDropdownLinkItem
          icon={icons.CommunityIcon}
          text="Community"
          to={routes.COMMUNITY_CANVAS.create({})}
        />
      )}

      <HelpMenu
        title="Help"
        userCanAdminister={workspace?.userCanAdminister}
        workspaceId={workspace?.identifier}
        workspaceName={workspace?.name}
      />
    </Wrapper>
  )
}

export default BottomMenu

import React, { useMemo } from 'react'
import { RouteProps } from '@sketch/modules-common'

import {
  PublicationQueryPreferencesFragment,
  useGetPublicationListingQuery,
} from '@sketch/gql-types'

import { GenericError } from '@sketch/components'

import CommunityWrapper from '../../components/CommunityWrapper'
import CommunityPublicationGrid from '../../components/CommunityPublicationGrid'
import CommunityListingHeader from '../../components/CommunityListingHeader'

import CommunityListingPagePlaceholder from './CommunityListingPagePlaceholder'

interface PreventiveCommunityGridProps {
  listIdentifier: string
  preferences: PublicationQueryPreferencesFragment
}

const PreventiveCommunityGrid: React.FC<PreventiveCommunityGridProps> = props => {
  const { listIdentifier } = props
  /**
   * When rendering the Listing page the props "allowPagination" and "limit"
   * need to be corrected given that we should show all items in these listings.
   * - Limit should be not set
   * - Allow pagination should be "true"
   */
  const preferences = useMemo(() => {
    const { allowPagination, limit, ...preferences } = props.preferences
    return { ...preferences, allowPagination: true, limit: null }
  }, [props.preferences])

  return (
    <CommunityPublicationGrid
      analyticsId={listIdentifier}
      preferences={preferences}
    />
  )
}

const CommunityListingPage = (
  props: RouteProps<'COMMUNITY_CANVAS_LISTING'>
) => {
  const {
    match: {
      params: { listIdentifier },
    },
  } = props

  const { data, loading, error } = useGetPublicationListingQuery({
    variables: { identifier: listIdentifier },
  })

  if (loading) {
    return <CommunityListingPagePlaceholder />
  }

  if (error || !data) {
    return <GenericError />
  }

  const { publicationListing } = data

  return (
    <CommunityWrapper>
      <CommunityListingHeader
        title={publicationListing.title}
        modifiers={publicationListing.modifiers}
        preferences={publicationListing.preferences}
      />
      <PreventiveCommunityGrid
        listIdentifier={listIdentifier}
        preferences={publicationListing.preferences}
      />
    </CommunityWrapper>
  )
}

export default CommunityListingPage

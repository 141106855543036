import { jsxs, jsx } from 'react/jsx-runtime';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useQueryParams, getIsForInAppPresentation, sourceToSsoSignInSource } from '@sketch/modules-common';
import { Spinner, Heading } from '@sketch/components';
import { useToast } from '@sketch/toasts';
import { useGetSsoStartUrl } from '../useGetSsoStartUrl.js';

const LoadingStateContainer = styled.div `
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  justify-content: center;
  flex-grow: 1;
  align-self: center;

  margin: 16px 0;
`;
const StyledSpinner = styled(Spinner) `
  display: flex;
  align-self: center;
`;
const Message = styled(Heading.H2) `
  margin: 32px auto;
`;
const RedirectingToIdentityProvider = () => {
    var _a;
    const { showToast } = useToast();
    const { source = 'sketch', team, shortname } = useQueryParams();
    const isForInAppPresentation = getIsForInAppPresentation();
    const { getUrl, response } = useGetSsoStartUrl({
        source: isForInAppPresentation
            ? 'sketch'
            : (_a = sourceToSsoSignInSource[source]) !== null && _a !== void 0 ? _a : 'sketch',
        onNetworkError: error => showToast(error, 'negative'),
    });
    const teamName = team || shortname;
    useEffect(() => {
        getUrl({ teamName: teamName });
    }, [getUrl, teamName]);
    if (response.url) {
        window.location.href = response.url;
    }
    return (jsxs(LoadingStateContainer, { children: [jsx(StyledSpinner, { size: "40px", primary: true }), jsx(Message, { children: "Redirecting to Identity Provider\u2026" })] }));
};

export { RedirectingToIdentityProvider };

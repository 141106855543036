import { __awaiter } from 'tslib';
import { jsx, Fragment } from 'react/jsx-runtime';
import { castError } from '@sketch/utils';
import { useState, useEffect } from 'react';
import { buildClientSchema } from 'graphql';
import { readSettings } from '../../settings/settings.localStorage.js';
import { hookUsageMetrics } from '@sketch/graphql-apollo/useQuery';

const usageMetricsRef = {
    current: { status: 'non-init' },
};
const getUsageMetrics = () => usageMetricsRef.current;
const getVendorsBundleName = () => __awaiter(void 0, void 0, void 0, function* () {
    /**
     * NOTE, there is a common confusion between `process.env.NODE_ENV` and `process.env.REACT_APP_ENV`
     *  - `process.env.NODE_ENV === 'production'` means that the app was built using `pnpm run build` instead of
     *     the `pnpm run start` command
     *  - `process.env.REACT_APP_ENV === 'production'` means that the app was built targeting production APIs
     *     or roughly speaking targeting www.sketch.com
     *
     * specifically here when we start the app using `pnpm run start` command, we always get the same bundle names,
     * and vendors name is always `'vendors~main.chunk.js'` so there is no need to load anything. However, when
     * we use `pnpm run build` now all bundles get their hashed filenames for which we need to get vendors-name.txt
     * */
    if (process.env.NODE_ENV === 'production') {
        const response = yield fetch('/static/vendors-name.txt', {
            method: 'get',
            headers: { 'Content-Type': 'text/plain' },
        });
        if (response.status < 300) {
            const responseText = yield response.text();
            const trimmedResponse = responseText ? responseText.trim() : '';
            return trimmedResponse;
        }
        throw new Error('could not load vendors bundle name');
    }
    else {
        return 'vendors~main.chunk.js';
    }
});
const getGraphQLSchema = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetch('/static/usage-metrics.schema.json', {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    });
    if (response.status < 300) {
        const introspectionQuery = (yield response.json()).data;
        const schema = buildClientSchema(introspectionQuery);
        return schema;
    }
    throw new Error('could not load GraphQL Schema');
});
const getCostsJson = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetch('/static/usage-metrics.costs.json', {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    });
    if (response.status < 300) {
        const costsRaw = (yield response.json());
        return costsRaw;
    }
    throw new Error('could not load Backend Costs JSON');
});
const loadUsageMetrics = () => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    // exclude this entire code block from the build bundle targeting staging or production
    if (process.env.REACT_APP_ENV === 'dev' ||
        process.env.REACT_APP_ENV === 'test') {
        // if usage metrics were already initialized, return it
        if (usageMetricsRef.current.status !== 'non-init')
            return usageMetricsRef.current;
        try {
            const storedDevToolsState = readSettings();
            const isEnabled = ((_a = storedDevToolsState.usageMetrics) === null || _a === void 0 ? void 0 : _a.isTurnedOn) &&
                ((_b = storedDevToolsState.general) === null || _b === void 0 ? void 0 : _b.isTurnedOn);
            if (!isEnabled) {
                usageMetricsRef.current = { status: 'not-enabled' };
                return usageMetricsRef.current;
            }
            const [usageMetricsModule, vendorsBundleName, graphqlSchema, costsRaw,] = yield Promise.all([
                import('../../usageMetrics/index.js'),
                getVendorsBundleName(),
                getGraphQLSchema(),
                getCostsJson(),
            ]);
            const usageMetrics = usageMetricsModule.createUsageMetrics({
                vendorsBundleName,
                graphqlSchema,
                costsRaw,
            });
            usageMetricsRef.current = {
                status: 'loaded',
                usageMetrics,
            };
            hookUsageMetrics((result, query) => {
                result.data = usageMetrics.proxyData(result.data, query);
            });
            // @ts-expect-error add global variable for debugging
            window.__usageMetrics = usageMetrics;
        }
        catch (e) {
            const err = castError(e);
            usageMetricsRef.current = { status: 'error', reason: err === null || err === void 0 ? void 0 : err.message };
            return usageMetricsRef.current;
        }
    }
    return { status: 'not-enabled' };
});
// It would be better to convert this to a provider
// however, it would require to change other global variables
// such as cache and apolloClient to factory functions and use those
// functions in React components.
// TODO: Review this component together with https://github.com/sketch-hq/Cloud/issues/8812
const UsageMetricsImport = ({ children }) => {
    const [metricsState, setMetricsState] = useState(usageMetricsRef.current);
    useEffect(() => {
        loadUsageMetrics().then(x => {
            setMetricsState(x);
        });
    }, []);
    if (process.env.REACT_APP_ENV === 'dev' ||
        process.env.REACT_APP_ENV === 'test') {
        if (metricsState.status === 'non-init') {
            return null;
        }
        return jsx(Fragment, { children: children });
    }
    return jsx(Fragment, { children: children });
};

export { UsageMetricsImport, getUsageMetrics };

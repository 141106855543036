import { __awaiter } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import { useState } from 'react';
import { Modal, Button } from '@sketch/components';
import { useToast } from '@sketch/toasts';
import '../../operations/index.js';
import { useDeletePersonalToken } from '../../operations/useDeletePersonalToken.js';

const DeleteTokenConfirmationModal = props => {
    const { token, hideModal } = props;
    const { showToast } = useToast();
    const truncatedDescription = token.description.length < 30
        ? token.description
        : `${token.description.slice(0, 29)}...`;
    const deleteToken = useDeletePersonalToken({
        onCompleted: () => {
            hideModal();
            const message = `“${truncatedDescription}” successfully deleted`;
            showToast(message);
        },
    });
    const [loading, setLoading] = useState(false);
    const handleConfirmDeleteClick = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        yield deleteToken({ identifier: token.identifier });
        setLoading(false);
    });
    return (jsxs(Modal, Object.assign({ title: "Delete this Token?", onCancel: hideModal }, { children: [jsxs(Modal.Body, { children: ["Deleting \u201C", token.description, "\u201D will instantly revoke access from all applications that use it.", jsx("br", {}), jsx("br", {}), "You can always create more personal access tokens."] }), jsxs(Modal.Footer, { children: [jsx(Button, Object.assign({ onClick: hideModal, disabled: loading }, { children: "Cancel" })), jsx(Button, Object.assign({ onClick: handleConfirmDeleteClick, disabled: loading, variant: "negative" }, { children: "Delete Token" }))] })] })));
};

export { DeleteTokenConfirmationModal as default };

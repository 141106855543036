import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useApolloClient } from 'react-apollo'
import { DataProxy } from 'apollo-cache'

import { RouteParams, routes } from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'
import { useCollectionSearchArgument } from './useCollectionSearchArgument'

// Operations
import {
  addCollectionToProject,
  addSharesToCollection,
  addSharesToProject,
  removeCollectionFromProject,
  removeSharesFromCollection,
  removeSharesFromProject,
  updateCollectionMetadata,
} from 'modules/collections/operations/utils'

import {
  useCollectionChangedSubscription,
  useCollectionCreatedSubscription,
  useShareCollectionChangedSubscription,
  useCollectionDeletedSubscription,
} from '@sketch/gql-types'

type CollectionRoute = RouteParams<'WORKSPACE_COLLECTION'>

interface UseCollectionSubscriptionsProps {
  projectIdentifier: string | undefined
}

export const useCollectionSubscriptions = ({
  projectIdentifier: initialProjectIdentifier,
}: UseCollectionSubscriptionsProps) => {
  const cache = useApolloClient()

  const [projectIdentifier, setProjectIdentifier] = useState(
    initialProjectIdentifier
  )

  // Ensure that `projectIdentifier` is updated whenever
  // `initialProjectIdentifier` changes
  useEffect(() => {
    if (initialProjectIdentifier) {
      setProjectIdentifier(initialProjectIdentifier)
    }
  }, [initialProjectIdentifier])

  // Only subscribe if:
  // - projectIdentifier is defined
  const shouldSubscribe = !!projectIdentifier

  useCollectionDeleted({
    cache,
    shouldSubscribe,
    projectIdentifier: projectIdentifier ?? '',
  })

  useShareCollectionChanged({
    cache,
    shouldSubscribe,
    projectIdentifier: projectIdentifier ?? '',
  })

  useCollectionChanged({
    cache,
    shouldSubscribe,
    projectIdentifier: projectIdentifier ?? '',
  })

  useCollectionCreated({
    cache,
    shouldSubscribe,
    projectIdentifier: projectIdentifier ?? '',
  })

  return { setProjectIdentifier }
}

interface SubscriptionProps {
  cache: DataProxy
  projectIdentifier: string
  shouldSubscribe: boolean
}

const useShareCollectionChanged = ({
  cache,
  projectIdentifier,
  shouldSubscribe,
}: SubscriptionProps) => {
  const search = useCollectionSearchArgument()

  useShareCollectionChangedSubscription({
    variables: { projectIdentifier },
    skip: !shouldSubscribe,
    onSubscriptionData: ({ subscriptionData }) => {
      const { data } = subscriptionData
      if (!data || !projectIdentifier) {
        return
      }

      const event = data.shareCollectionChanged

      if (event.__typename === 'ShareAddedToCollectionEvent') {
        const { share, collection } = event
        addSharesToCollection({
          cache,
          projectIdentifier,
          collection,
          search,
          sharesToAdd: [share],
          updateShareCount: false,
        })

        removeSharesFromProject(cache, projectIdentifier, [share])
      } else {
        const { share, collection, destinationProjectIdentifier } = event
        removeSharesFromCollection({
          cache,
          projectIdentifier,
          collectionIdentifier: collection.identifier,
          search,
          sharesToRemove: [share],
          updateShareCount: false,
        })

        if (
          destinationProjectIdentifier === projectIdentifier &&
          !share.deletedAt
        ) {
          addSharesToProject({
            cache,
            projectIdentifier,
            sharesToAdd: [share],
          })
        }
      }
    },
  })
}

const useCollectionChanged = ({
  cache,
  projectIdentifier,
  shouldSubscribe,
}: SubscriptionProps) => {
  useCollectionChangedSubscription({
    variables: { projectIdentifier },
    skip: !shouldSubscribe,
    onSubscriptionData: ({ subscriptionData }) => {
      const { data } = subscriptionData
      if (!data) {
        return
      }

      const { collection } = data.collectionChanged

      updateCollectionMetadata({
        cache,
        projectIdentifier,
        collection,
      })
    },
  })
}

const useCollectionCreated = ({
  cache,
  projectIdentifier,
  shouldSubscribe,
}: SubscriptionProps) => {
  useCollectionCreatedSubscription({
    variables: { projectIdentifier },
    skip: !shouldSubscribe,
    onSubscriptionData: ({ subscriptionData }) => {
      const { data } = subscriptionData
      if (!data || projectIdentifier === '') {
        return
      }

      const { collection } = data.collectionCreated
      addCollectionToProject({
        cache,
        projectIdentifier,
        collection,
      })
    },
  })
}

const useCollectionDeleted = ({
  cache,
  projectIdentifier,
  shouldSubscribe,
}: SubscriptionProps) => {
  const history = useHistory()
  const { showToast } = useToast()
  const { collectionId, workspaceId } = useParams<CollectionRoute>()

  useCollectionDeletedSubscription({
    variables: {
      projectIdentifier,
    },
    skip: !shouldSubscribe,
    onSubscriptionData: ({ subscriptionData }) => {
      const { data } = subscriptionData

      if (!data) {
        return
      }

      const { identifier: collectionIdentifier } = data.collectionDeleted

      const isInCollectionView = collectionId === collectionIdentifier

      removeCollectionFromProject({
        cache,
        collectionIdentifier,
        projectIdentifier,
      })

      if (isInCollectionView) {
        showToast(`Collection was removed`)

        history.push(
          routes.WORKSPACE_PROJECT.create({
            projectId: projectIdentifier,
            workspaceId,
          })
        )
      }
    },
  })
}

import styled, { css } from 'styled-components';
import { getDisplayName } from '@sketch/utils';
import { flex, flexWrap, flexDirection, justifyContent, alignItems, alignSelf, justifySelf } from 'styled-system';

const FlexCss = css `
  display: flex;
  ${flex}
  ${flexWrap}
  ${flexDirection}
  ${justifyContent}
  ${alignItems}
  ${alignSelf}
  ${justifySelf}
`;
function withFlex(Comp) {
    // eslint-disable-next-line no-unexpected-multiline
    const styledComp = styled(Comp) `
    ${FlexCss}
  `;
    styledComp.displayName = `withFlex(${getDisplayName(Comp)})`;
    return styledComp;
}

export { withFlex };

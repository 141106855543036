import {
  isSSOWorkspace,
  userCanEditInWorkspace,
  userCanUploadDocuments,
} from 'modules/workspace/utils'
import { ShareWithoutVersion } from 'modules/versioning/ShareVersionContext/ShareVersionContext'
import { DuplicationWorkspaceFragment } from '@sketch/gql-types'

interface GetEligibleWorkspacesForDuplicationOptions {
  hasAccessToWorkspaceCheckFn: (
    currentWorkspace: DuplicationWorkspaceFragment
  ) => boolean
}

export const getEligibleWorkspacesForDuplication = function (
  workspaces: DuplicationWorkspaceFragment[],
  sourceWorkspaceIdentifier: string,
  sharePublicAccessLevel: ShareWithoutVersion['publicAccessLevel'],
  options: GetEligibleWorkspacesForDuplicationOptions
) {
  const { hasAccessToWorkspaceCheckFn: hasAccessToWorkspace } = options
  const currentWorkspace = workspaces.find(
    workspace => workspace.identifier === sourceWorkspaceIdentifier
  )

  if (currentWorkspace) {
    const isEligibleWorkspace =
      userCanEditInWorkspace(currentWorkspace) &&
      userCanUploadDocuments(currentWorkspace) &&
      hasAccessToWorkspace(currentWorkspace)

    // If user is editor in the share workspace cannot
    // be duplicated into other workspaces
    if (isEligibleWorkspace) {
      return {
        userCanDuplicate: true,
        currentWorkspace,
        otherWorkspaces: [],
      }
    }

    // Shares belonging to SSO Workspaces can only be
    // duplicated into the same workspace unless it's public
    if (isSSOWorkspace(currentWorkspace) && sharePublicAccessLevel === 'NONE') {
      return {
        userCanDuplicate: false,
        currentWorkspace: null,
        otherWorkspaces: [],
      }
    }
  }

  // Return all active workspaces where user is an editor
  // and it's currently logged in into
  const otherWorkspaces = workspaces.filter(
    workspace =>
      userCanEditInWorkspace(workspace) &&
      userCanUploadDocuments(workspace) &&
      hasAccessToWorkspace(workspace)
  )

  return {
    userCanDuplicate: Boolean(otherWorkspaces.length),
    currentWorkspace: null,
    otherWorkspaces,
  }
}

import React from 'react'
import styled from 'styled-components'
import { Tooltip, Text } from '@sketch/components'
import { isOwnUser } from '../../utils'
import {
  PresenceList,
  PresenceListItem,
  PresenceListItemAvatar,
} from './PresenceTooltip.styles'
import { Presence } from 'modules/presence/types'
import { useUserProfile } from '@sketch/modules-common'

interface PresenceItemProps {
  presence: Presence
}

const PresenceItem = ({ presence }: PresenceItemProps) => {
  const { data } = useUserProfile()

  return (
    <PresenceListItem>
      <PresenceListItemAvatar
        src={presence!.user!.avatarUrl}
        name={presence!.user!.name!}
        size={24}
      />
      <Text
        ml={2}
        mt={0}
        mb={0}
        color="foreground.secondary.A"
        overflow="ellipsis"
      >
        {isOwnUser(presence, data!.me.identifier!) ? 'You' : presence.user.name}
      </Text>
    </PresenceListItem>
  )
}

interface PresenceTooltipProps {
  presences: Presence[]
  className?: string
  disabled?: boolean
}

const PresenceTooltipContent = ({
  presences,
  className,
}: PresenceTooltipProps) => {
  const presenceItems = presences.map((presence: Presence) => (
    <PresenceItem key={presence.user.identifier} presence={presence} />
  ))

  return <PresenceList className={className}>{presenceItems}</PresenceList>
}

const StyledPresenceTooltipContent = styled(PresenceTooltipContent)`
  max-width: 350px;
  max-height: 200px;
  padding: 0 16px 0 0;
  overflow-y: auto;
`

const PresenceTooltip: React.FC<PresenceTooltipProps> = props => {
  const { children, presences, className, disabled = false } = props

  return (
    <Tooltip
      className={className}
      disabled={disabled}
      scrollable={true}
      placement="top"
      contentStyle={{ paddingRight: 0 }}
      content={<StyledPresenceTooltipContent presences={presences} />}
    >
      {children}
    </Tooltip>
  )
}

export default PresenceTooltip
